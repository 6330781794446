import { parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { SemTalkLanguageCode } from "./application/tbase/Interface";
import { IMongoOption, mgGetItems, mgReset_Password, mgGetItem } from './SemTalkMongoServer';
import { SemTalkCookie } from "./ISemTalkCookie";
import { accessCookie } from "./application/semtalklistener/stgoto";
import { IVisioRDFS } from "./application/semtalklistener/visiordfsinterface";
import { SemTalkBaseConstant } from "./application/tbase/Interface";
import { ISemTalkOnline } from "./ISemTalkOnline";


export function formatdate(v: string, lang: SemTalkLanguageCode): string {
  try {
    switch (lang) {
      case SemTalkLanguageCode.German: {
        return format(parseISO(v), "dd.MM.yyyy kk:mm");
      }
    }
    return format(parseISO(v), "yyyy-MM-dd h:mm a");
  } catch (_e) {
    return v;
  }

}
export function absolutePosition(cell: mxCell): { x: number, y: number } {
  const dx = Math.round(cell.geometry.x);
  const dy = Math.round(cell.geometry.y);
  if (!cell.parent) {
    return { x: dx, y: dy };
  } else {
    if (!cell.parent.geometry) {
      return { x: dx, y: dy };
    } else {
      let parentpos = absolutePosition(cell.parent);
      return { x: parentpos.x + dx, y: parentpos.y + dy };
    }
  }
}

export async function ResetPasswort(usermail: any, mongo: IMongoOption, dbname: string, users: string, lang: string) {
  if (!usermail) return "Unable to reset Password";
  let userlist = await mgGetItems(mongo, dbname, users);
  for (let user of userlist) {
    if (user.emails[0].address.toLowerCase() === usermail.toLowerCase()) {
      console.log("user found");
      let chars = "0123456789abcdefghijklmnopqrstuvwxyz!_-#^*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let passwordLength = 12;
      let new_password = "";
      for (let i = 0; i <= passwordLength; i++) {
        let randomNumber = Math.floor(Math.random() * chars.length);
        new_password += chars.substring(randomNumber, randomNumber + 1);
      }

      let subject = "New account password for SemTalkOnline";
      let mailtext = 'Hello ' + usermail + ',\n\n this is your new password for the account: '
        + usermail + '\n New password: ' + new_password
        + '\n\nPlease click on this hyperlink to update the password in your account: '
        + '\n    __replyurl__'
        + '\n\nThank you for using SemTalkOnline!'
        + '\n\nThis mail was generated automatically.';
      if (lang === "de") {
        subject = "Neues Passwort für SemTalkOnline";
        mailtext = 'Hallo ' + usermail + ',\n\n das ist das neue Passwort für den Account: '
          + usermail + '\n Neues Passwort: ' + new_password
          + '\n\nBitte folgen Sie dem Link um das Passwort in Ihren Accout zu übernehmen: '
          + '\n    __replyurl__'
          + '\n\nDanke für das Benutzen von SemTalkOnline!'
          + '\n\nDiese E-Mail ist computergeneriert.';
      }
      let res0 = await mgReset_Password(mongo, usermail,
        new_password, user._id, mailtext, subject);
      if (res0) {
        return res0;
        //this.alert(res0, MessageBarType.info);
        // this.setState({ "success": true, "nosuccess": true, "errormsg": res });
      }
      return "Unable to reset Password";

    }
  }
  let res1 = "Sorry, I cannot change the password for " + usermail + " this email does not match an existing user.";
  return res1;
  //this.alert(res1, MessageBarType.info);
}

export interface IExportConnection {
  key: string | number;
  name: string;
  language: string;
  servicesservertype: string;
  servicesconstr: string;
  servicesite: string;
  reporting: boolean;
  library: string;
  servicedatabase: string;
  serviceserver: string;
  default: boolean;
  personal: boolean;
}

export async function getAllExportConnections(semtalk: IVisioRDFS | undefined, mongo: IMongoOption, userid: string, callback: ISemTalkOnline | null) {
  const cb = callback;
  let getValue = (cookie: SemTalkCookie, v: any) => {
    let val: any = undefined;
    if (semtalk) {
      let mv = semtalk.base.GetModelAttribute(
        SemTalkBaseConstant.CookiePrefix + cookie
      );
      if (mv !== undefined) {
        return mv;
      }
    }
    let localvalue = accessCookie(cookie);
    if (localvalue) {
      val = localvalue;
    } else {
      val = cb ? cb.GetDefaultValue(null, cookie, v) : v;
    }
    return val;
  };

  let exportConnectionsObject: IExportConnection[] = [];
  if (mongo.usemongo) {
    let curUser = await mgGetItem(mongo, mongo.dbname, mongo.users, userid);
    let connectionsProfileString = curUser.profile.exportconnections;
    if (connectionsProfileString && connectionsProfileString.length > 0) {
      exportConnectionsObject = JSON.parse(connectionsProfileString);

    }
  }
  let exportConnectionsString = getValue(
    SemTalkCookie.exportconnections, []);
  let connectionsCookies: IExportConnection[] = [];
  if (exportConnectionsString && exportConnectionsString.length > 0) {
    connectionsCookies = JSON.parse(exportConnectionsString);
  }

  if (connectionsCookies) {
    connectionsCookies.forEach(element => {
      let exists = exportConnectionsObject.find(item => item.key === element.name);
      if (!exists || exists.key !== element.name) exportConnectionsObject.push(element);
    });
  }

  let servicesconstr = getValue(
    SemTalkCookie.servicesconstr,
    ""
  );
  let servicesservertype = getValue(
    SemTalkCookie.servicesservertype,
    ""
  );
  let servicesserver = getValue(
    SemTalkCookie.servicesserver,
    ""
  );
  let servicessite = getValue(
    SemTalkCookie.servicessite,
    ""
  );
  let servicesdatabase = getValue(
    SemTalkCookie.servicesdatabase,
    mongo.dbname
  );

  let defaultExists = exportConnectionsObject.find(item => item.key === "Default");
  if (servicesserver.length > 0 && servicesconstr.length > 0 && servicesservertype.length > 0 &&
    servicessite.length > 0 && servicesdatabase.length > 0 && (!defaultExists || defaultExists.key !== "Default")) {
    exportConnectionsObject.push({
      servicesservertype: servicesservertype,
      servicesconstr: servicesconstr,
      name: "Default",
      key: "Default",
      language: "German",
      servicedatabase: servicesdatabase,
      servicesite: servicessite,
      reporting: false,
      serviceserver: "https://semtalkservicewebapp4.azurewebsites.net/api/semservice",
      library: "SDX",
      default: false,
      personal: false
    });
  }

  exportConnectionsObject.sort((a, b) => Number(b.default) - Number(a.default));

  return exportConnectionsObject;
}
