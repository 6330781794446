export function getParticipant(cell: mxCell): mxCell {
    if (cell === undefined) {
        // console.debug(cell);
        return cell;
    }
    if (!cell.parent) {
        return cell;
    } else {
        if (!cell.parent.geometry) {
            return cell;
        } else {
            return getParticipant(cell.parent);
        }
    }
}
export function findChildAt(shp: any, x: any, y: any) {
    x= Math.round(x);
    y= Math.round(y);
    for (let i in shp.children) {
      let c = shp.children[i];
      if (Math.round(c.geometry.x) === x &&
      Math.round(c.geometry.y) === y) {
        return c;
      }
    }
    return null;
  }
