import { SemTalkCookie } from "./ISemTalkCookie";
import { IMongoOption, mgGetValue, mgSetValue } from "./SemTalkMongoServer";
import { SPExplorer } from "./application/explorer/spexplorer";
import { accessCookie, removeCookie, setCookie } from "./application/semtalklistener/stgoto";
import { ISharePointSettings, IVisioRDFS } from "./application/semtalklistener/visiordfsinterface";

export function saveDefaultSettings(semtalk: IVisioRDFS,
    mongo: IMongoOption,
    sprops: ISharePointSettings
) {
    let badlist = [
        "autosaveSemTalk",
        "history",
        "historypos",
        "role",
        "SemTalkStatus",
        "quickedit",
        "shapestyle",
    ];
    let settings: any = {};
    for (const x in SemTalkCookie) {
        if (badlist.indexOf(x) > -1) continue;
        let v = accessCookie(x as SemTalkCookie);
        if (v) {
            settings[x] = v;
        }
    }
    if (mongo.usemongo && mongo.semuserlogin) {
        mgSetValue(
            mongo,
            mongo.dbname,
            "Support",
            "settings",
            "SemTalkCookie",
            settings
        );
        if (semtalk) {
            mgSetValue(
                mongo,
                mongo.dbname,
                "Support",
                "settings",
                "Ribbon",
                semtalk.getRibbon()
            );
        }
    } else {
        if (sprops.context) {
            let url =
                sprops.site + "/" + sprops.support + "/settings.json";
            let spinterface = new SPExplorer();
            spinterface.postTXT(sprops.context, url, settings);
        }
    }
}

export async function loadDefaultSettings(
    mongo: IMongoOption,
    sprops: ISharePointSettings,
    cookie: string
): Promise<any> {
    if (mongo.usemongo&& mongo.semuserlogin && !sprops.context && !mongo.iselectron) {
        try {
            let settings = await mgGetValue(
                mongo,
                mongo.dbname,
                "Support",
                "settings",
                cookie
            );
            return settings;
        } catch {
            return {};
        }
    } else {
        if (sprops.context) {
            let url =
                sprops.site + "/" + sprops.support + "/settings.json";
            let spinterface = new SPExplorer();
            let txt = await spinterface.getTXT(sprops.context, url);
            try {
                if (txt !== null) {
                    let settings = JSON.parse(txt);
                    return settings;
                }
            } catch (e) {
                console.debug(e);
                return {};
            }
        }
    }
    return {};
}

export function exportDefaultSettings(filename: string) {
    let badlist = [
        "autosaveSemTalk",
        "history",
        "historypos",
        "role",
        "SemTalkStatus",
        "quickedit",
        "shapestyle",
    ];
    let settings: any = {};
    for (const x in SemTalkCookie) {
        if (badlist.indexOf(x) > -1) continue;
        let v = accessCookie(x as SemTalkCookie);
        if (v) {
            settings[x] = v;
        }
    }
    let blob = JSON.stringify(settings);
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link: any = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

export function importDefaultSettings(handler: any) {
    // let sem = this.state.semtalk as IVisioRDFS;
    const inp: any = document.createElement("input");
    inp.setAttribute("type", `File`);
    inp.setAttribute("className", `hidden`);
    inp.setAttribute("multiple", "false");
    inp.setAttribute("accept", ".json");
    inp.onchange = (e: any) => {
        handler(e);
    };
    document.body.appendChild(inp);
    inp.click();
    inp.parentNode.removeChild(inp);
}

export function resetSettings() {
    let badlist = [
        "autosaveSemTalk",
        "history",
        "historypos",
        "role",
        "SemTalkStatus",
        "quickedit",
        "shapestyle",
    ];
    for (const x in SemTalkCookie) {
        if (badlist.indexOf(x) > -1) continue;
        removeCookie(x as SemTalkCookie);
    }
}

export function onImportSettingsHandler(event: any) {
    let f = event.target.files[0];
    let fr = new FileReader();
    fr.readAsText(f);
    fr.onload = async (_event) => {
        //Both "event.target.result" and "fr.result" contain the file's contents (because "event.target" is === "fr")
        const s = fr.result;
        if (s) {
            setCookie(SemTalkCookie.autosaveSemTalk, "");
            let settings = JSON.parse(s.toString());
            for (let x in SemTalkCookie) {
                setCookie(x as SemTalkCookie, settings[x]);
            }
        }
    };
}
export function GetSettingsDefaultValue (
    statedefaultsettings: any,
    defaultsettings: any,
    setting: SemTalkCookie,
    defaultvalue: any
  ): any {
    let settings: any = defaultsettings;
    if (defaultsettings === null || defaultsettings === "") {
      settings = statedefaultsettings;
    }
    if (settings) {
      if (setting in settings) {
        return settings[setting];
      } else {
        return defaultvalue;
      }
    } else {
      return defaultvalue;
    }
  }

