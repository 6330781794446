/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from 'react';
import { List, Stack } from 'office-ui-fabric-react';
import styles from './sidebar.module.scss';
import { ITSEditor } from "../ITSEditor";
import {
  ScrollablePane,
  // IScrollablePaneStyles,
  getTheme, mergeStyleSets
} from 'office-ui-fabric-react';
import { Sticky, StickyPositionType } from 'office-ui-fabric-react';
import { SemTalkStencil } from '../application/semtalklistener/visiordfsinterface';
import { SemTalkCookie } from '../../components/ISemTalkCookie';
import { accessCookie } from '../application/semtalklistener/stgoto';

// Should use: https://www.npmjs.com/package/@iconify/icons-bpmn !!!!!!!!!!!!

export interface ISideBarProps {
  editor: ITSEditor;
  stencil: SemTalkStencil;
  height: number;
  istriple: boolean;
}
export interface ISideBarState {
  stencil: any[];
  pairedstencil: { left: any, right: any }[];
  triplestencil: { left: any, right: any }[];
  stickystencil: { [sticky: string]: { left: any, right: any }[] };
  stickytriplestencil: { [sticky: string]: { left: any, middle: any, right: any }[] };
  editor: ITSEditor;
}
export class Sidebar extends React.Component<ISideBarProps, ISideBarState> {
  constructor(props: ISideBarProps) {
    super(props);
    console.debug("SideBar");
    this.props.editor.sidebar = this;
    this.state = {
      stencil: this.props.stencil,
      pairedstencil: [],
      triplestencil: [],
      editor: this.props.editor,
      stickystencil: {},
      stickytriplestencil: {},
    };
  }
  public LoadPairedStencil = (stencil: SemTalkStencil) => {
    let stickystencil: { [sticky: string]: { left: any, right: any }[] } = {};
    let stickyshapes: { [sticky: string]: any[] } = {};
    let p: { left: any, right: any }[] = [];
    let left = true;
    let row: any;
    if (stencil) {
      for (let mst of stencil) {
        if (mst.hidden !== true) {
          if (mst.sticky) {
            if (!stickyshapes[mst.sticky]) {
              stickyshapes[mst.sticky] = [];
            }
            if (!stickystencil[mst.sticky]) {
              stickystencil[mst.sticky] = [];
            }
            stickyshapes[mst.sticky].push(mst);
          } else {
            // stickyshapes["STRGENERAL"].push(mst);
          }
        }
      }
      for (let sticky in stickyshapes) {
        if (stickyshapes[sticky]) {
          for (let mst of stickyshapes[sticky]) {
            if (left) {
              row = { left: mst };
              left = false;
            } else {
              left = true;
              row.right = mst;
              stickystencil[sticky].push(row);
            }
          }
          if (!left) {
            stickystencil[sticky].push(row);
          }
        }
      }
      left = true;
      for (let mst of stencil) {
        if (mst.hidden !== true) {
          if (left) {
            row = { left: mst };
            left = false;
          } else {
            left = true;
            row.right = mst;
            p.push(row);
          }
        }
      }
      if (!left) {
        p.push(row);
      }
    }
    this.setState({
      stencil: stencil,
      pairedstencil: p,
      editor: this.props.editor,
      stickystencil: stickystencil,
    });
  }
  public LoadTripleStencil = (stencil: SemTalkStencil) => {
    let stickystencil: { [sticky: string]: { left: any, middle: any, right: any }[] } = {};
    let stickyshapes: { [sticky: string]: any[] } = {};
    let p: { left: any, middle: any, right: any }[] = [];
    let curr = 0;
    let row: any;
    if (stencil) {
      for (let mst of stencil) {
        if (mst.hidden !== true) {
          if (mst.sticky) {
            if (!stickyshapes[mst.sticky]) {
              stickyshapes[mst.sticky] = [];
            }
            if (!stickystencil[mst.sticky]) {
              stickystencil[mst.sticky] = [];
            }
            stickyshapes[mst.sticky].push(mst);
          } else {
            // stickyshapes["STRGENERAL"].push(mst);
          }
        }
      }
      for (let sticky in stickyshapes) {
        if (stickyshapes[sticky]) {
          for (let mst of stickyshapes[sticky]) {
            switch (curr) {
              case 0: {
                row = { left: mst };
                curr = 1;
                break;
              }
              case 1: {
                row.middle = mst;
                curr = 2;
                break;
              }
              case 2: {
                row.right = mst;
                curr = 0;
                stickystencil[sticky].push(row);
                break;
              }
            }
          }
          if (curr > 0) {
            curr = 0;
            stickystencil[sticky].push(row);
          }
        }
      }
      curr = 0;
      for (let mst of stencil) {
        if (mst.hidden !== true) {
          switch (curr) {
            case 0: {
              row = { left: mst };
              curr = 1;
              break;
            }
            case 1: {
              row.middle = mst;
              curr = 2;
              break;
            }
            case 2: {
              row.right = mst;
              curr = 0;
              p.push(row);
              break;
            }
          }
        }
      }
      if (curr > 0) {
        p.push(row);
      }
    }
    this.setState({
      stencil: stencil,
      triplestencil: p,
      editor: this.props.editor,
      stickytriplestencil: stickystencil,
    });
  }
  public componentDidMount() {
    //  this.handleSidebarItems();
    this.LoadStencil(this.props.stencil);
    this.onChange();
  }

  public LoadStencil = (stencil: SemTalkStencil) => {
    if (this.props.istriple) {
      this.LoadTripleStencil(stencil);
    } else {
      this.LoadPairedStencil(stencil);
    }
  }

  public componentDidUpdate() {
    // this.handleSidebarItems();
  }

  private handleSidebarItems() {
    const editor = this.state.editor;
    // console.debug("handleSidebarItems1");

    if (editor && editor.initSidebar) {
      const sidebarItems = document.querySelectorAll('.' + styles.customsidebarnode);
      const len = sidebarItems.length;
      let newSidebarItems: any[] = [];
      for (let i = 0; i < len; i++) {
        let item = sidebarItems[i];
        if (!item.classList.contains('has-inited')) {
          item.classList.add('has-inited');
          newSidebarItems.push(item);
        }
      }
      editor.initSidebar(newSidebarItems);
    }
  }

  public onChange = () => {
    // console.debug("handleSidebarItems");
    setTimeout(() => {
      this.handleSidebarItems();
    }, 1000);
  }
  private theme = getTheme();
  private classNames = mergeStyleSets({
    wrapper: {
      height: '60vh',
      position: 'relative',
      // height: "80%",
      // maxHeight: 'inherit',
    },
    pane: {
      // maxWidth: 400,
      border: '1px solid ' + this.theme.palette.themeLight,
    },
    sticky: {
      color: this.theme.palette.neutralDark,
      padding: '5px 20px 5px 10px',
      fontSize: '13px',
      borderTop: '1px solid ' + this.theme.palette.neutralDark,
      borderBottom: '1px solid ' + this.theme.palette.neutralDark,
    },
    textContent: {
      padding: '15px 10px',
    },
  });

  private onRenderRow(row: { left: any, right: any } | undefined, _index: number | undefined): JSX.Element {
    return (
      <Stack horizontal={true}>
        {row && row.left &&
          <div>
            <a
              key={"panel_a_" + row.left.key}
              className={"geItem " + styles.customsidebarnode + " common-panel-node"}
              data-shape-type={row.left.type}
              data-shape-name={row.left.key}
              data-shape-subkey={row.left.subkey}
              data-shape-label={row.left.name}
              data-shape-width={row.left.width}
              data-shape-height={row.left.height}
            >
              <div>
                {row.left.logo ? <img className={styles.sidebarnodeimage}
                  src={row.left.logo} alt={row.left.label} /> : row.left.key}
                <div className={styles.sidebarnodelabel}>
                  {row.left.label}
                </div>
              </div>
            </a>
          </div>
        }
        {row && row.right &&
          <div>
            <a
              key={"panel_a_" + row.right.key}
              className={"geItem " + styles.customsidebarnode + " common-panel-node"}
              data-shape-type={row.right.type}
              data-shape-name={row.right.key}
              data-shape-subkey={row.right.subkey}
              data-shape-label={row.right.label}
              data-shape-width={row.right.width}
              data-shape-height={row.right.height}
            >
              <div>
                {row.right.logo ? <img className={styles.sidebarnodeimage}
                  src={row.right.logo} alt={row.right.label} /> : row.right.key}
                <div className={styles.sidebarnodelabel}>
                  {row.right.label}
                </div>
              </div>
            </a>
          </div>
        }
      </Stack >
    );
  }
  private onRenderTripleRow(row: any | undefined, _index: number | undefined): JSX.Element {
    return (
      <Stack horizontal={true}>
        {row && row.left &&
          <div>
            <a
              key={"panel_a_" + row.left.key}
              className={"geItem " + styles.customsidebarnode + " common-panel-node"}
              data-shape-type={row.left.type}
              data-shape-name={row.left.key}
              data-shape-subkey={row.left.subkey}
              data-shape-label={row.left.name}
              data-shape-width={row.left.width}
              data-shape-height={row.left.height}
            >
              <div>
                {row.left.logo ? <img className={styles.sidebarnodeimage}
                  src={row.left.logo} alt={row.left.label} /> : row.left.key}
                <div className={styles.sidebarnodelabel}>
                  {row.left.label}
                </div>
              </div>
            </a>
          </div>
        }
        {row && row.middle &&
          <div>
            <a
              key={"panel_a_" + row.middle.key}
              className={"geItem " + styles.customsidebarnode + " common-panel-node"}
              data-shape-type={row.middle.type}
              data-shape-name={row.middle.key}
              data-shape-subkey={row.middle.subkey}
              data-shape-label={row.middle.name}
              data-shape-width={row.middle.width}
              data-shape-height={row.middle.height}
            >
              <div>
                {row.middle.logo ? <img className={styles.sidebarnodeimage}
                  src={row.middle.logo} alt={row.middle.label} /> : row.middle.key}
                <div className={styles.sidebarnodelabel}>
                  {row.middle.label}
                </div>
              </div>
            </a>
          </div>
        }
        {row && row.right &&
          <div>
            <a
              key={"panel_a_" + row.right.key}
              className={"geItem " + styles.customsidebarnode + " common-panel-node"}
              data-shape-type={row.right.type}
              data-shape-name={row.right.key}
              data-shape-subkey={row.right.subkey}
              data-shape-label={row.right.label}
              data-shape-width={row.right.width}
              data-shape-height={row.right.height}
            >
              <div>
                {row.right.logo ? <img className={styles.sidebarnodeimage}
                  src={row.right.logo} alt={row.right.label} /> : row.right.key}
                <div className={styles.sidebarnodelabel}>
                  {row.right.label}
                </div>
              </div>
            </a>
          </div>
        }
      </Stack >
    );
  }
  // private contentAreas: any[] = this.state.pairedstencil.map(this._onRenderRow);
  private renderSticky = (sticky: string, items: any[], bg: string): JSX.Element => (
    <div key={sticky} style={{ backgroundColor: bg }}>
      <Sticky stickyPosition={StickyPositionType.Both}>
        <div role="heading" aria-level={1} className={this.classNames.sticky}>
          {sticky}
        </div>
      </Sticky>
      <List items={items}
        onRenderCell={this.onRenderRow} />
    </div>
  )
  private renderTripleSticky = (sticky: string, items: any[], bg: string): JSX.Element => (
    <div key={sticky} style={{ backgroundColor: bg }}>
      <Sticky stickyPosition={StickyPositionType.Both}>
        <div role="heading" aria-level={1} className={this.classNames.sticky}>
          {sticky}
        </div>
      </Sticky>
      <List items={items}
        onRenderCell={this.onRenderTripleRow} />
    </div>
  )

  public render(): React.ReactElement<ISideBarProps> {
    let bg = accessCookie(SemTalkCookie.backgroundColor);
    if (bg === 'undefined' || bg === null) {
      bg = "white";
    }
    let contentAreas: any[] = [];
    if (!this.props.istriple) {
      for (let sticky in this.state.stickystencil) {
        let ss = this.state.stickystencil[sticky];
        if (ss) {
          contentAreas.push(this.renderSticky(sticky, ss, bg) as JSX.Element);
        }
      }
    } else {
      for (let sticky in this.state.stickytriplestencil) {
        let ss = this.state.stickytriplestencil[sticky];
        if (ss) {
          contentAreas.push(this.renderTripleSticky(sticky, ss, bg) as JSX.Element);
        }
      }
    }

    this.onChange();
    return (
      <div className={"J_Sidebar_Container " + styles.sidebarcontainer}>
        <div className={this.classNames.wrapper}>
          {((!this.props.istriple && this.state.stickystencil &&
            Object.keys(this.state.stickystencil).length > 1) ||
            (this.props.istriple && this.state.stickytriplestencil &&
              Object.keys(this.state.stickytriplestencil).length > 1)) &&
            <ScrollablePane>
              {contentAreas}
            </ScrollablePane>
          }
          {!this.props.istriple && this.state.stickystencil && Object.keys(this.state.stickystencil).length < 2 &&
            <ScrollablePane>
              <List items={this.state.pairedstencil}
                onRenderCell={this.onRenderRow} />
            </ScrollablePane>
          }
          {this.props.istriple && this.state.stickytriplestencil && Object.keys(this.state.stickytriplestencil).length < 2 &&
            <ScrollablePane>
              <List items={this.state.triplestencil}
                onRenderCell={this.onRenderTripleRow} />
            </ScrollablePane>
          }
        </div>
      </div>
    );
  }
}
