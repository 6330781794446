import { LabelSpec } from "./Label";

import { SemTalkVirtualInstance, SemTalkClass } from './Tbase';
// import { SemTalkSystemClass } from './SystemClass';
import { Utils } from "./utils";

// tslint:disable-next-line:ordered-imports
import {
  IObjectBase,
  ISemTalkObject,
  ISemTalkClass,
  ISemTalkMethod,
  ISemTalkMethodType,
  ISemTalkState,
  ISemTalkStateType,
  ISemTalkAttributeType,
  ISemTalkBusinessClass,
  ISemTalkComposition,
  SemTalkType,
  ModelAttribute,
  SemTalkComposeOrder,
  SemTalkBaseConstant,
  SemTalkID
} from './Interface';
import { ObjectBase } from './ObjectBase';


// tslint:disable:max-classes-per-file
// tslint:disable:variable-name

/* tslint:disable:member-ordering */
/* tslint:disable:forin */
export class SemTalkBusinessClass extends SemTalkClass implements ISemTalkBusinessClass {
  constructor(tb: IObjectBase, newname: string, id?: SemTalkID | null) {
    super(tb, newname, id);
    return this;
  }
  public Delete(): void {
    super.Delete();
    for (const l of this.Methods()) {
      l.Delete();
    }
  }

  public AllStateTypes(): ISemTalkStateType[] {
    const alist: ISemTalkStateType[] = [];
    const fnd: any = {};
    const fnda: any = {};
    for (const x of this.States()) {
      const atype = x.ClassOf();
      // if (fnda[atype.ObjectName] === undefined) {
      fnda[atype.ObjectName] = atype;
      alist.push(atype);
      // }
    }
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.SuperClasses()) {
        if (fnd[x.ObjectName] === undefined) {
          fnd[x.ObjectName] = x;
          if (x instanceof SemTalkBusinessClass) {
            for (const xs of x.States()) {
              if (x instanceof SemTalkBusinessClass) {
                const atypes = xs.ClassOf();
                if (fnda[atypes.ObjectName] === undefined) {
                  fnda[atypes.ObjectName] = atypes;
                  alist.push(atypes);
                }
              }
            }
          }
          allsuperclassesrec(x);
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }

  private _methods: { [id: string]: ISemTalkMethod } = {};
  public AddMethod(a: ISemTalkMethod): ISemTalkMethod {
    try {
      const mt: ISemTalkMethodType = a.ClassOf();
      this._methods[mt.ID + "_"] = a;
    } catch (e) {
      console.debug("AddMethod: ", e);
    }
    return a;
  }
  public RemoveMethod(a: ISemTalkMethod): void {
    delete this._methods[a.ClassOf().ID + "_"];
  }
  public Methods(): ISemTalkMethod[] {
    const alist: ISemTalkMethod[] = [];
    for (const index in this._methods) {
      const x = this._methods[index];
      alist.push(x);
    }
    return alist;
  }
  public FindMethod(attr: string | ISemTalkMethodType): ISemTalkMethod | null {
    let cla: ISemTalkMethodType | null;
    if (typeof attr === 'string') {
      cla = this.ObjectBase.FindMethodType(attr as string);
    } else {
      cla = attr as ISemTalkMethodType;
    }
    if (cla === null) {
      return null;
    } else {
      const a = this._methods[cla.ID + "_"];
      if (a === undefined) {
        return null;
      } else {
        return a;
      }
    }
  }
  public FindAMethod(attr: string | ISemTalkMethodType): ISemTalkMethod | null {
    let m = this.FindMethod(attr);
    if (m === null) {
      for (let s of this.SuperClasses()) {
        if (this.ObjectBase.IsBusinessClass(s)) {
          let m1 = (s as ISemTalkBusinessClass).FindAMethod(attr);
          if (m1) {
            return m1;
          }
        }
      }
    }
    return m;
  }
  public static getMethodType(ob: IObjectBase, attr: string | ISemTalkMethodType): ISemTalkMethodType {
    if (typeof attr === 'string') {
      const mt = ob.FindMethodType(attr as string);
      if (mt !== null) {
        return mt;
      } else {
        return new SemTalkMethodType(ob, attr as string);
      }
    } else {
      return attr as ISemTalkMethodType;
    }
  }
  public MakeMethod(attr: string | ISemTalkMethodType): ISemTalkMethod {
    const cla = SemTalkBusinessClass.getMethodType(this.ObjectBase, attr);
    // if (cla === null) { throw new Error("MakeMethod failed" + attr); }
    let a = this.FindMethod(cla);
    if (a === null) {
      a = new SemTalkMethod(this, cla);
      this._methods[cla.ID + "_"] = a;
      a.SetClass(cla);
    }
    return a;
  }
  public DeleteMethod(attr: string | ISemTalkMethodType): void {
    let ob = this.ObjectBase;
    let cla: ISemTalkMethodType | null;
    if (typeof attr === 'string') {
      cla = ob.FindMethodType(attr);
    } else {
      cla = attr as ISemTalkMethodType;
    }
    if (cla === null) {
      throw new Error("DeleteMethod failed: " + attr);
    }
    const at: ISemTalkMethod | null = this.FindMethod(cla);
    if (at) {
      at.Delete();
      delete this._methods[at.ClassOf().ID + "_"];
      this.Changed = new Date().toUTCString();
      this.ChangedBy = this.ObjectBase.User;
      this.ObjectBase.OnMethodDeleted(at);
    }
    // return this;

    //  const cla = SemTalkBusinessClass.getMethodType(this.ObjectBase, attr);
    // if (cla === null) { throw new Error("DeleteMethod failed: " + attr); }
    // const a = this.FindMethod(cla);
    // if (a !== null) { a.Delete(); }
    // // if (this._methods[cla.ID + "_"] !== undefined) {
    // //   this._methods[cla.ID + "_"].Delete();
    // // }
  }



  private _states: { [id: string]: ISemTalkState } = {};
  public AddState(a: ISemTalkState): ISemTalkState {
    try {
      const mt: ISemTalkStateType = a.ClassOf();
      this._states[mt.ID + "_"] = a;
    } catch (e) {
      console.debug("AddState: ", e);
    }
    return a;
  }
  public RemoveState(a: ISemTalkState): void {
    delete this._states[a.ClassOf().ID + "_"];
  }
  public FindState(attr: string | ISemTalkStateType): ISemTalkState | null {
    let cla: ISemTalkStateType | null;
    if (typeof attr === 'string') {
      cla = this.ObjectBase.FindStateType(attr as string);
    } else {
      cla = attr as ISemTalkStateType;
    }
    if (cla === null) {
      return null;
    } else {
      const a = this._states[cla.ID + "_"];
      if (a === undefined) {
        return null;
      } else {
        return a;
      }
    }
  }
  public FindAState(attr: string | ISemTalkStateType): ISemTalkState | null {
    let m = this.FindState(attr);
    if (m === null) {
      for (let s of this.SuperClasses()) {
        if (this.ObjectBase.IsBusinessClass(s)) {
          let m1 = (s as ISemTalkBusinessClass).FindAState(attr);
          if (m1) {
            return m1;
          }
        }
      }
    }
    return m;
  }


  public static getStateType(ob: IObjectBase, attr: string | ISemTalkStateType): ISemTalkStateType {
    if (typeof attr === 'string') {
      const mt = ob.FindStateType(attr as string);
      if (mt !== null) {
        return mt;
      } else {
        return new SemTalkStateType(ob, attr as string);
      }
    } else {
      return attr as ISemTalkStateType;
    }
  }

  public MakeState(attr: string | ISemTalkStateType): ISemTalkState {
    const cla = SemTalkBusinessClass.getStateType(this.ObjectBase, attr);
    // if (cla === null) { throw new Error("MakeState failed" + attr); }
    let a = this.FindState(cla);
    if (a === null) {
      a = new SemTalkState(this, cla);
      this._states[cla.ID + "_"] = a;
      a.SetClass(cla);
    }
    return a;
  }
  public DeleteState(attr: string | ISemTalkStateType): void {
    let ob = this.ObjectBase;
    let cla: ISemTalkStateType | null;
    if (typeof attr === 'string') {
      cla = ob.FindStateType(attr);
    } else {
      cla = attr as ISemTalkStateType;
    }
    if (cla === null) {
      throw new Error("DeleteState failed: " + attr);
    }
    const at: ISemTalkState | null = this.FindState(cla);
    if (at) {
      at.Delete();
      delete this._states[at.ClassOf().ID + "_"];
      this.Changed = new Date().toUTCString();
      this.ChangedBy = this.ObjectBase.User;
      this.ObjectBase.OnStateDeleted(at);
    }
  }

  public States(): ISemTalkState[] {
    const alist: ISemTalkState[] = [];
    for (const an in this._states) {
      const a = this._states[an];
      alist.push(a);
    }
    return alist;
  }

  public AllMethods(): ISemTalkMethod[] {
    const alist: ISemTalkMethod[] = [];
    const fnd: any = {};
    const fnda: any = {};
    for (const x of this.Methods()) {
      const atype = x.ClassOf();
      // if (fnda[atype.ObjectName] === undefined) {
      fnda[atype.ObjectName] = atype;
      alist.push(x);
      // }
    }
    function allsuperclassesrec(cl: ISemTalkBusinessClass) {
      for (const x of cl.SuperClasses()) {
        if (fnd[x.ObjectName] === undefined && (x instanceof SemTalkBusinessClass)) {
          fnd[x.ObjectName] = x;
          for (const xs of x.Methods()) {
            const atypes = xs.ClassOf();
            //  if (fnda[atypes.ObjectName] === undefined) {
            fnda[atypes.ObjectName] = atypes;
            alist.push(xs);
            //  }
          }
          allsuperclassesrec(x);
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }
  public AllMethodTypes(): ISemTalkMethodType[] {
    const alist: ISemTalkMethodType[] = [];
    const fnd: any = {};
    const fnda: any = {};
    for (const x of this.Methods()) {
      const atype = x.ClassOf();
      if (fnda[atype.ObjectName] === undefined) {
        fnda[atype.ObjectName] = atype;
        alist.push(atype);
      }
    }
    function allsuperclassesrec(cl: SemTalkBusinessClass) {
      for (const x of cl.SuperClasses()) {
        if ((fnd[x.ObjectName] === undefined) && (x instanceof SemTalkBusinessClass)) {
          fnd[x.ObjectName] = x;
          for (const xs of x.Methods()) {
            const atypes = xs.ClassOf();
            if (fnda[atypes.ObjectName] === undefined) {
              fnda[atypes.ObjectName] = atypes;
              alist.push(atypes);
            }
          }
          allsuperclassesrec(x);
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }
  public AllStates(): ISemTalkState[] {
    const alist: ISemTalkState[] = [];
    const fnd: any = {};
    const fnda: any = {};
    for (const x of this.States()) {
      const atype = x.ClassOf();
      // if (fnda[atype.ObjectName] === undefined) {
      fnda[atype.ObjectName] = atype;
      alist.push(x);
      // }
    }
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.SuperClasses()) {
        if (fnd[x.ObjectName] === undefined) {
          fnd[x.ObjectName] = x;
          if (x instanceof SemTalkBusinessClass) {
            for (const xs of x.States()) {
              const atypes = xs.ClassOf();
              if (fnda[atypes.ObjectName] === undefined) {
                fnda[atypes.ObjectName] = atypes;
                alist[alist.length] = xs;
              }
            }
          }
          allsuperclassesrec(x);
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }

  public UpdateLabel(mastername?: string): LabelSpec {
    const lbl = this.ObjectBase.MakeLabelSpec();
    // const lbl = super.UpdateLabel(mastername);
    lbl.newtxt = "";
    // let masternameU = mastername;
    if (mastername === undefined) {
      mastername = "";
    }
    mastername = Utils.MasterNoDot(mastername);
    if (lbl.isuml) { lbl.newtxt = ""; }

    let haslabels: boolean = false;
    const sc = this.SystemClass();
    if (sc !== null) {
      haslabels = sc.CollectBusinessClassLabels(lbl, this, mastername);
    }
    lbl.haslabels = haslabels;
    if (!haslabels && lbl.newtxt === "") {
      lbl.newtxt = this.ID2NameNsp();
    }
    // if (haslabels && !lbl.isuml && lbl.newtxt === "") {
    //   lbl.newtxt = this.ID2NameNsp();
    // }
    return lbl;
  }
  public Load(je: any): void {
    super.Load(je);
    //  const obj: ISemTalkBusinessClass = this;
    if (je.methods) {
      for (const i in je.methods) {
        const a: any = je.methods[i];
        // Der SemTalk Visio Export schreibt "name". SemTalk Online Export schreibt "class"
        let cl = a["class"];
        if (cl === undefined && a["name"] !== undefined) {
          cl = a["name"];
        }
        if (cl) {
          const an: SemTalkMethod = new SemTalkMethod(this, cl);
          an.Load(a);
        }
      }
    }
    if (je.states) {
      for (const ai in je.states) {
        const a: any = je.states[ai];
        // Der SemTalk Visio Export schreibt "name". SemTalk Online Export schreibt "class"
        let cl = a["class"];
        if (cl === undefined && a["name"] !== undefined) {
          cl = a["name"];
        }
        if (cl) {
          const an: SemTalkState = new SemTalkState(this, cl);
          an.Load(a);
        }
      }
    }
  }
  public LoadXML(element: Element): void {
    super.LoadXML(element);
    const methods = element.getElementsByTagName("Method");
    for (const i in methods) {
      const a = methods[i];
      if (a.parentNode === element && a.attributes && a.nodeName === "Method") {
        const name = a.attributes.getNamedItem("name")?.value;
        let aname = a.attributes.getNamedItem("class")?.value;
        if (aname === undefined) {
          aname = name;
        }
        if (aname) {
          const an = this.MakeMethod(aname);
          if (an) an.LoadXML(a);
        }
      }
    }
    const states = element.getElementsByTagName("State");
    for (const i in states) {
      const a = states[i];
      if (a.parentNode === element && a.attributes && a.nodeName === "State") {
        const name = a.attributes.getNamedItem("name")?.value;
        let aname = a.attributes.getNamedItem("class")?.value;
        if (aname === undefined) {
          aname = name;
        }
        if (aname) {
          const an = this.MakeState(aname);
          if (an) an.LoadXML(a);
        }
      }
    }
  }

  public Save(par: any[]): any {
    const el: any = super.Save(par);
    el.BusinessClass = "True";
    if (this._methods) {
      const ml: any[] = [];
      for (const l of this.Methods()) {
        if (l.ClassOf()) {
          l.Save(ml);
        }
      }
      el.methods = ml;
    }
    if (this._states) {
      const ml: any[] = [];
      for (const l of this.States()) {
        if (l.ClassOf()) {
          l.Save(ml);
        }
      }
      el.states = ml;
    }
    return el;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    super.SaveXML(xd, el);
    el.setAttribute("BusinessClass", "True");
    if (this._methods) {
      for (const l of this.Methods()) {
        if (l.ClassOf()) {
          let me = xd.createElement("Method");
          el.appendChild(me);
          l.SaveXML(xd, me);
        }
      }
    }
    if (this._states) {
      for (const l of this.States()) {
        if (l.ClassOf()) {
          let me = xd.createElement("State");
          el.appendChild(me);
          l.SaveXML(xd, me);
        }
      }
    }
  }
  public Clone(oth: ISemTalkBusinessClass) {
    super.Clone(oth);
    for (let a2 of oth.Methods()) {
      const aname = a2.ClassOf().ObjectName;
      if (!this.FindMethod(aname)) {
        const a = this.MakeMethod(aname);
        a.Clone(a2);
      }
    }
    for (let a2 of oth.States()) {
      const aname = a2.ClassOf().ObjectName;
      if (!this.FindState(aname)) {
        const a = this.MakeState(aname);
        a.Clone(a2);
      }
    }
  }

}

export class SemTalkStateType extends SemTalkClass implements ISemTalkStateType {
  constructor(tb: IObjectBase, newname: string, id?: SemTalkID | null) {
    super(tb, newname, id);
    this.ObjectType = SemTalkType.SemTalkStateType;
    if (tb.FindStateType(newname) === null) {
      tb._statetypes[this.ID + "_"] = this;
      this.ObjectBase.OnCreated(this);
    }
  }
  public Delete(): void {
    super.Delete();
    const tb = this.ObjectBase;
    for (const objid in tb._classes) {
      const s = tb._classes[objid];
      if (s instanceof SemTalkBusinessClass) {
        for (const attr of s.States()) {
          if (attr.ClassOf().ID === this.ID) {
            attr.Delete();
          }
        }
      }
    }
    delete this.ObjectBase._statetypes[this.ID + "_"];
    this.ObjectBase.OnStateTypeDeleted(this);
  }
}
export class SemTalkMethodType extends SemTalkClass implements ISemTalkMethodType {
  constructor(tb: IObjectBase, newname: string, id?: SemTalkID | null) {
    super(tb, newname, id);
    this.ObjectType = SemTalkType.SemTalkMethodType;
    if (tb.FindMethodType(newname) === null) {
      tb._methodtypes[this.ID + "_"] = this;
      this.ObjectBase.OnCreated(this);
    }
  }
  public Delete(): void {
    super.Delete();
    const tb = this.ObjectBase;
    for (const objid in tb._classes) {
      const s = tb._classes[objid];
      if (s instanceof SemTalkBusinessClass) {
        for (const attr of s.Methods()) {
          if (attr.ClassOf().ID === this.ID) {
            attr.Delete();
          }
        }
      }
    }
    delete this.ObjectBase._methodtypes[this.ID + "_"];
    this.ObjectBase.OnMethodTypeDeleted(this);
  }

}

export class SemTalkState extends SemTalkVirtualInstance implements ISemTalkState {
  constructor(obj: ISemTalkBusinessClass, newname: string | ISemTalkStateType) {
    super(SemTalkType.SemTalkState, obj);
    this.Owner = obj;
    const tb = obj.ObjectBase;
    const at1 = SemTalkBusinessClass.getStateType(tb, newname);
    this.SetClass(at1);
    obj.AddState(this);
    tb.OnStateAdded(this);
  }
  public Owner: ISemTalkBusinessClass;
  public get PropName(): string { return this.ClassOf().ID2Name; }
  public get PropType(): string { return ObjectBase.SemTalkTypeName(this.ObjectType); }
  public ClassOf(): ISemTalkStateType {
    return super.ClassOf() as ISemTalkStateType;
  }
  public Delete(): void {
    super.Delete();
    (this.Owner as ISemTalkBusinessClass).RemoveState(this);
    this.Owner.ObjectBase.OnStateDeleted(this);
    // this.Owner = null;
  }
  public Load(je: any): SemTalkState {
    const tb = this.Owner.ObjectBase;
    // Der SemTalk Visio Export schreibt "name". SemTalk Online Export schreibt "class"
    let cname = je["class"];
    if (cname === undefined && je["name"] !== undefined) {
      cname = je["name"];
    }
    const cl = SemTalkBusinessClass.getStateType(tb, cname);
    this.SetClass(cl);
    return this;
  }
}
export class SemTalkMethod extends SemTalkVirtualInstance implements ISemTalkMethod {
  constructor(obj: ISemTalkBusinessClass, newname: string | ISemTalkMethodType) {
    super(SemTalkType.SemTalkMethod, obj);
    this.Owner = obj;
    const tb = obj.ObjectBase;
    const at1 = SemTalkBusinessClass.getMethodType(obj.ObjectBase, newname);
    this.SetClass(at1);
    obj.AddMethod(this);
    tb.OnMethodAdded(this);
  }
  public Owner: ISemTalkBusinessClass;
  public get PropName(): string { return this.ClassOf().ID2Name; }
  public get PropType(): string { return ObjectBase.SemTalkTypeName(this.ObjectType); }
  public ClassOf(): ISemTalkMethodType {
    return super.ClassOf() as ISemTalkMethodType;
  }
  public Delete(): void {
    super.Delete();
    this.Owner.RemoveMethod(this);
    this.Owner.ObjectBase.OnMethodDeleted(this);
    // this.Owner = null;
  }
  public Load(je: any): SemTalkMethod {
    const tb = this.Owner.ObjectBase;
    // Der SemTalk Visio Export schreibt "name". SemTalk Online Export schreibt "class"
    let cname = je["class"];
    if (cname === undefined && je["name"] !== undefined) {
      cname = je["name"];
    }
    const cl = SemTalkBusinessClass.getMethodType(tb, cname);
    this.SetClass(cl);
    return this;
  }
}
export class SemTalkComposition implements ISemTalkComposition {
  constructor(own: ISemTalkObject, cls: ISemTalkBusinessClass, met: ISemTalkMethodType | null, sta: ISemTalkStateType | null,
    attr: ISemTalkAttributeType | null, oth?: ISemTalkBusinessClass | null, op?: string, isnotop?: boolean, cmpvalue?: any) {
    this.ComposedClass = cls;
    if (met !== null) { this.Method = met; }
    this.Owner = own;
    this.State = sta;
    this.Attribute = attr;
    if (oth !== undefined) {
      this.Other = oth;
    }
    if (op !== undefined) { this.Op = op; }
    if (isnotop !== undefined) { this.NotOp = isnotop; }
    if (cmpvalue !== undefined) { this.Value = cmpvalue; }
  }
  public Owner: ISemTalkObject;
  public ComposedClass: ISemTalkBusinessClass;
  public Method: ISemTalkMethodType | null = null;
  public State: ISemTalkStateType | null;
  public Other: ISemTalkBusinessClass | null = null;
  public Attribute: ISemTalkAttributeType | null;
  public Op: string | null = null;
  public NotOp: boolean | null = null;
  public Value: any;
  private _type: SemTalkType = SemTalkType.SemTalkComposition;

  public toString(): string {
    return "<" + ObjectBase.SemTalkTypeName(this._type) + this.Owner.ObjectName + ">";
  }

  public ID2NameNsp(): string {
    let forder: boolean = (this.Owner.ObjectBase.GetModelAttribute(ModelAttribute.forder) === SemTalkComposeOrder.NounVerb);
    if (this.Method && this.Method.ObjectName === SemTalkBaseConstant.SLHas) {
      // console.debug("has");
      forder = false;
    }
    if (forder) {
      let str = this.ComposedClass.ID2NameNsp();
      if (this.Attribute) {
        str = str + " " + this.Attribute.ID2NameNsp();
      }
      if (this.Method) {
        str = str + " " + this.Method.ID2NameNsp();
      }
      if (this.Op) {
        str = str + " " + this.Op;
      }
      if (this.State) {
        str = str + " " + this.State.ID2NameNsp();
      }
      if (this.Op) {
        str = str + " " + this.Op;
      }
      if (this.Value) {
        str = str + " " + this.Value;
      }
      return str;
    } else {
      let str = "";
      if (this.Method) {
        str = str + " " + this.Method.ID2NameNsp();
      }
      if (this.Attribute) {
        str = str + " " + this.Attribute.ID2NameNsp();
      }
      if (this.State) {
        if (this.Method) {
          str = str + " " + this.ComposedClass.ID2NameNsp();
          str = str + " " + this.State.ID2NameNsp();
        } else {
          str = this.ComposedClass.ID2NameNsp() + str + " " + this.State.ID2NameNsp();
        }
      } else {
        str = str + " " + this.ComposedClass.ID2NameNsp();
      }
      if (this.Op) {
        str = str + " " + this.Op;
      }
      if (this.Value) {
        str = str + " " + this.Value;
      }
      return str.trim();
    }
  }
  public Delete(): void {
    if (this.ComposedClass) {
      this.ComposedClass.RemoveInvComposition(this);
    }
    if (this.Other) {
      this.Other.RemoveInvComposition(this);
    }
    if (this.Method) {
      this.Method.RemoveInvComposition(this);
    }
    if (this.State) {
      this.State.RemoveInvComposition(this);
    }
    if (this.Attribute) {
      this.Attribute.RemoveInvComposition(this);
    }
    if (this.Owner && this.Owner.Composition() === this) {
      this.Owner.DeleteComposition();
    }
  }
  public Save(): any {
    const el: any = {};
    el.ObjectType = ObjectBase.SemTalkTypeName(this._type);
    el.fclass = this.ComposedClass.ObjectName;
    if (this.Method) {
      el.fmethod = this.Method.ObjectName;
    }
    if (this.Other) {
      el.fother = this.Other.ObjectName;
    }
    if (this.State) {
      el.feature = this.State.ObjectName;
      el.ftype = "1";
    }
    if (this.Attribute) {
      el.feature = this.Attribute.ObjectName;
      el.ftype = "2";
    }
    // if (this.Op) { }
    el.fop = this.Op;
    if (this.NotOp) {
      el.fnot = this.NotOp;
    }
    if (this.Value) {
      el.fvalue = this.Value;
    }
    return el;
  }
  public SaveXML(_xd: XMLDocument, el: HTMLElement): void {
    el.setAttribute("fclass", this.ComposedClass.ObjectName);
    el.setAttribute("froot", "");
    if (this.Method) {
      el.setAttribute("fmethod", this.Method.ObjectName);
    }
    if (this.Other) {
      el.setAttribute("fother", this.Other.ObjectName);
    }
    if (this.State) {
      el.setAttribute("feature", this.State.ObjectName);
      el.setAttribute("ftype", "1");
    }
    if (this.Attribute) {
      el.setAttribute("feature", this.Attribute.ObjectName);
      el.setAttribute("ftype", "2");
    }
    if (this.Op) {
      el.setAttribute("fop", String(this.Op));
    }
    if (this.NotOp) {
      el.setAttribute("fnot", String(this.NotOp));
    }
    if (this.Value) {
      el.setAttribute("fvalue", String(this.Value));
    }
  }
}
