import { ContextualMenuItemType, IContextualMenuItem } from "office-ui-fabric-react";
import { ISemTalkOnline, SemTalkOnlineCommand, SemTalkRole } from "./ISemTalkOnline";
import { IVisioRDFS, ResID, ResIDL, SemTalkBuiltInShape, SemTalkRibbon, SemTalkStyleAttribute } from "./application/semtalklistener/visiordfsinterface";
import { ISemTalkAssociationType, SemTalkAttachment, SemTalkBaseConstant, SemTalkRelation, SemTalkType } from "./application/tbase/Interface";
import { Process_ElementName } from "./application/semtalklistener/processInterface";
import { gotoDocument, gotoObject } from "./application/semtalklistener/stglobal";
import { SB_DiagramTypeName } from "./application/semtalklistener/subtask/sitebuilder/sbinterface";

export function ComputeMenu(callback: ISemTalkOnline, sem: IVisioRDFS, ribbon: SemTalkRibbon,
    role: SemTalkRole): { items: any[]; allitems: any } {


    // Hier sollte man auch ggf die ShortCuts setzen
    // Export Import aus subtask DLL erweitern
    let allitems: any = {};
    let items: any[] = [];
    if (sem) {
        let parseMenuItem = (ribbonitem: any): any => {
            let nitem: any = {};
            if (ribbonitem["key"]) {
                nitem["key"] = ribbonitem["key"];
                allitems[nitem["key"]] = nitem;
                if (ribbonitem["text"] && ribbonitem["text"].length > 0) {
                    nitem["text"] = sem.getResStr(ribbonitem["text"]).replace("&", "").replace(":", "");
                } else {
                    if (ribbonitem["textL"] && ribbonitem["textL"].length > 0) {
                        nitem["text"] = sem.getResStrListener(ribbonitem["textL"]).replace("&", "").replace(":", "");
                    }
                }
                if (ribbonitem["hidden"]) {
                    nitem["hidden"] = ribbonitem["hidden"];
                }
                // if (ribbonitem["context"]) {
                //     nitem["context"] = ribbonitem["context"];
                // }
                if (ribbonitem["role"]) {
                    let ribbonrole = ribbonitem["role"];
                    switch (ribbonrole) {
                        case SemTalkRole.admin: {
                            nitem["hidden"] = role !== ribbonrole;
                            break;
                        }
                        case SemTalkRole.metamodel: {
                            nitem["hidden"] =
                                role !== ribbonrole &&
                                role !== SemTalkRole.admin;
                            break;
                        }
                        case SemTalkRole.editor: {
                            nitem["hidden"] =
                                role !== ribbonrole &&
                                role !== SemTalkRole.metamodel &&
                                role !== SemTalkRole.admin;
                            break;
                        }
                        default: {
                            nitem["hidden"] =
                                role !== ribbonrole &&
                                role !== SemTalkRole.admin;
                            break;
                        }
                    }
                }
                if (ribbonitem["cacheKey"])
                    nitem["cacheKey"] = ribbonitem["cacheKey"];
                if (ribbonitem["split"]) nitem["split"] = true;
                if (ribbonitem["iconOnly"]) {
                    nitem["iconOnly"] = true;
                    if (ribbonitem["shortcut"]) {
                        nitem["text"] += "  [" + ribbonitem["shortcut"] + "]";
                    }
                }
                if (ribbonitem["iconProps"])
                    nitem["iconProps"] = ribbonitem["iconProps"];
                if (ribbonitem["subMenuProps"]) {
                    let subitems: any = [];
                    let items1 = ribbonitem["subMenuProps"]["items"];
                    for (let sitemindex in items1) {
                        let sitem = items1[sitemindex];
                        let nsubitem = parseMenuItem(sitem);
                        if (!nsubitem["hidden"]) {
                            subitems.push(nsubitem);
                        }
                    }
                    nitem["subMenuProps"] = { items: subitems };
                }
                if (ribbonitem["onClickCallback"]) {
                    nitem["onClick"] = () => {
                        callback.DoCommand(ribbonitem["onClickCallback"], {});
                    };
                }
                if (ribbonitem["onClickAddOn"]) {
                    nitem["onClick"] = () => {
                        callback.DoCommand(SemTalkOnlineCommand.AddonCommand, {
                            command: ribbonitem["onClickAddOn"],
                        });
                    };
                }
                if (ribbonitem["onClickState"]) {
                    nitem["onClick"] = () => {
                        let sname: string = ribbonitem["onClickState"];
                        let s: any = {};
                        s[sname] = true;
                        // if (ctrl) ctrl.setState(s);
                        callback.SetState(s);
                    };
                }
                if (ribbonitem["itemType"] && ribbonitem["itemType"] === 1) {
                    nitem["name"] = "-";
                    nitem["disabled"] = true;
                    nitem["itemType"] = ContextualMenuItemType.Divider;
                }
            }
            return nitem;
        };
        for (let ribbonitem of ribbon) {
            try {
                let mitem = parseMenuItem(ribbonitem);
                if (mitem) {
                    if (mitem["hidden"]) {
                        // console.debug(mitem);
                    } else {
                        items.push(mitem);
                    }
                }
            } catch (e) {
                console.debug(e);
            }
        }
    }
    return { items: items, allitems: allitems };
}
export function ContextMenu(
    callback: ISemTalkOnline,
    sem: IVisioRDFS,
    ribbon: SemTalkRibbon,
    role: SemTalkRole,
    islocked: boolean,
    isplanmaker: boolean,
    istoolbar: boolean,
    isanchored: boolean,
    isbpmn: boolean,
    cell: mxCell,
    ev: React.MouseEvent<HTMLElement>
): IContextualMenuItem[] {
    // if (cell && cell !== null && !cell.objectid) {
    //     return [];
    // }
    let menu: IContextualMenuItem[] = [];
    if (sem) {
        const res = ComputeMenu(callback, sem, ribbon, role);
        const allitems = res["allitems"];
        let getMenuItem = (key: string, onclick: undefined | (() => void)): any => {
            const nmenu = allitems[key];
            if (onclick && nmenu) nmenu["onClick"] = onclick;
            return nmenu;
        };
        ev.preventDefault(); // don't navigate
        let diagclassname = sem.page?.ClassOf().ObjectName;
        let isgeneric = diagclassname === SemTalkBaseConstant.SLGeneric;
        let isclass = sem.page?.ClassOf().IsClass;
        let issiteb = diagclassname === SB_DiagramTypeName.SiteDiagram;
        const ob = sem.base;
        const obj = cell ? ob.FindObjectByID(cell.objectid) : null;
        if (cell != null && !isplanmaker) {
            const sc = sem.visCellSystemClass(cell.objectid);
            let sel: boolean = false;
            let isassoc = false;
            if (sc) isassoc = ob.IsAssociationType(sc);
            if (sc && isassoc && sc.FindLabelForMaster("",
                ob.GetModelAttribute(Process_ElementName.SLInfoType) + "{ Group").length > 0) {
                sel = true;
            }
            if (sc && isclass && (sc as ISemTalkAssociationType).RelationType === SemTalkRelation.SemTalkProperty) {
                sel = true;
            }
            if (obj && isgeneric && ob.IsClass(obj)) {
                sel = true;
            }
            // if (!sc || (sc && ((!isassoc && !sc.BottomUp) || sel || isgeneric) &&
            if (sel || ((!sc || (sc &&
                ((!isassoc && !sc.BottomUp) || sel || isgeneric) &&
                sc.ObjectName !== SemTalkBaseConstant.SLComment)) &&
                (!isgeneric || isassoc)
            )) {
                if (obj && allitems["selectObject"]) {
                    menu.push(
                        getMenuItem("selectObject", () => {
                            callback.DoCommand(SemTalkOnlineCommand.SelectCell, cell);
                        })
                    );
                }
            }

            if (obj && !isanchored && allitems["editObject"]) {
                menu.push(
                    getMenuItem("editObject", () => {
                        callback.editCell(cell);
                    })
                );
            }

            if (cell.edge && !islocked) {
                menu.push({
                    key: "elbow",
                    text: sem.getResStrListener(ResIDL.STRELBOW),
                    iconProps: { iconName: "TurnRight" },
                    onClick: (): void => {
                        callback.DoCommand(SemTalkOnlineCommand.ElbowCell, cell);
                    },
                });
                menu.push({
                    key: "Waypoint",
                    text: sem.getResStrListener(ResIDL.STRELINSWP),
                    iconProps: { iconName: "BranchCommit" },
                    onClick: (): void => {
                        callback.DoCommand(SemTalkOnlineCommand.InsertWayPoint, cell);
                    },
                });
                menu.push({
                    key: "ClearWaypoint",
                    text: sem.getResStrListener(ResIDL.STRELDELWP),
                    iconProps: { iconName: "Line" },
                    onClick: (): void => {
                        callback.DoCommand(SemTalkOnlineCommand.RemoveWayPoint, cell);
                    },
                });
            }
            if (obj && sc && sc.Composeable && !islocked) {
                if (allitems["composeObject"]) {
                    menu.push(
                        getMenuItem("composeObject", () => {
                            gotoObject(cell.objectid);
                            callback.DoCommand(SemTalkOnlineCommand.ComposeObject, cell);
                        })
                    );
                }
            }
            if (allitems["style"]) {
                menu.push(
                    getMenuItem("style", () => {
                        callback.DoCommand(SemTalkOnlineCommand.ShapeStyle, [cell]);
                    })
                );
            }
            if (sc && sc.ObjectName === Process_ElementName.Swimlane) {
                if (allitems["swimlane"]) {
                    menu.push(getMenuItem("swimlane", undefined));
                }
            } else {
                if (!islocked) {
                    if (allitems["bringToFront"] && allitems["divider6a"]) {
                        menu.push(getMenuItem("divider6a", undefined));
                        menu.push(
                            getMenuItem("bringToFront", () => {
                                callback.DoCommand(SemTalkOnlineCommand.BringToFront, cell);
                            })
                        );
                    }
                    if (allitems["sendToBack"]) {
                        menu.push(
                            getMenuItem("sendToBack", () => {
                                callback.DoCommand(SemTalkOnlineCommand.SendToBack, cell);
                            })
                        );
                    }
                }
            }
            if (sem && sem.page &&
                !islocked && !isplanmaker && allitems["copyObject"]) {
                menu.push(
                    getMenuItem("copyObject", undefined)
                );
            }
            // (sc && sc.ObjectName === ob.GetModelAttribute(Process_ElementName.SLActivity))) {

            if (obj && (!islocked || sem.visCellRefinement(cell.objectid))) {
                if (sem.visCellClass(cell.objectid) ||
                    (sc && sc.Refineable)) {
                    if (allitems["refineObject"] && allitems["divider1"]) {
                        menu.push(
                            getMenuItem("divider1", undefined),
                            getMenuItem("refineObject", () => {
                                gotoObject(cell.objectid);
                                callback.DoCommand(SemTalkOnlineCommand.RefineObject, cell);
                            })
                        );
                    }
                }
            }
            if (obj && sem.visCellGoUp(cell.objectid)) {
                if (sem.visCellClass(cell.objectid) ||
                    (sc && sc.ObjectName === ob.GetModelAttribute(Process_ElementName.SLEvent))) {
                    if (allitems["upPage"] && allitems["divider1"]) {
                        menu.push(
                            getMenuItem("divider1", undefined),
                            getMenuItem("upPage", () => {
                                callback.DoCommand(SemTalkOnlineCommand.GoUp, cell);
                            })
                        );
                    }
                }
            }
            if (allitems["deleteShapes"] && !islocked) {
                menu.push(
                    getMenuItem("divider3", undefined),
                    getMenuItem("deleteShapes", undefined)
                );
            }
            if (obj && (isgeneric || isclass || issiteb) && allitems["deleteObject"] && !islocked) {
                menu.push(
                    getMenuItem("deleteObject", undefined)
                );
            }
            if (obj && isgeneric && allitems["toClass"] && allitems["toInstance"] && !islocked) {
                if (obj.ObjectType === SemTalkType.SemTalkInstance) {
                    menu.push(
                        getMenuItem("toClass", undefined)
                    );
                }
                if (obj.ObjectType === SemTalkType.SemTalkClass) {
                    menu.push(
                        getMenuItem("toInstance", undefined)
                    );
                    if (allitems["compactShape"] && cell.style.startsWith(SemTalkStyleAttribute.shape + "=" + SemTalkBuiltInShape.genericclass + ";")) {
                        menu.push(
                            getMenuItem("compactShape", undefined)
                        );
                    }
                }
            }

            if (obj && sem.page &&
                sem.page.ClassOf().ObjectName !== ob.GetModelAttribute(Process_ElementName.SLProc) &&
                !islocked) {
                if (allitems["expandObject"] && allitems["divider4"]) {
                    menu.push(
                        getMenuItem("divider4", undefined),
                        getMenuItem("expandObject", undefined)
                    );
                }
            }
        }

        if (obj) {
            const hlist = obj.Attachments();
            let infotype = sem.base.GetModelAttribute(Process_ElementName.SLInfoType);
            if (infotype) {
                for (let it of obj.LinkedObjects(infotype)) {
                    let alist = it.Attachments();
                    hlist.push(...alist);
                }
            }

            if (hlist.length > 0 && allitems["divider5"]) {
                menu.push(getMenuItem("divider5", undefined));
                for (let assoc of hlist) {
                    if (!assoc.ToObject.ID) continue;
                    let lbl: string = assoc.GetValue(SemTalkAttachment.label);
                    if (lbl !== null && lbl.length > 0) {
                    } else {
                        lbl = assoc.ToObject.ID2NameNsp();
                    }
                    let hn = assoc.ToObject.ObjectName;
                    menu.push({
                        key: assoc.ToObject.ID.toString(),
                        text: lbl,
                        iconProps: {
                            iconName: "Link",
                        },
                        // eslint-disable-next-line no-loop-func
                        onClick: (_evt: any): void => {
                            if (assoc.ObjectBase.IsDiagram(assoc.ToObject)) {
                                gotoDocument(assoc.ToObject.ID);
                                return;
                            }
                            if (hn.endsWith(".sdx")) {
                                if (callback.getSharePointContext()) {
                                    callback.LoadDocument(hn, true);
                                    return;
                                }
                                hn = "?model=" + hn;
                            }
                            const link: any = document.createElement("a");
                            link.href = hn;
                            link.setAttribute("target", "_blank");
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        },
                    });
                }
            }
            let st = allitems["style"];
            if (st) {
                let styles: any[] = sem.getDynStyles(obj, callback, [cell]);
                if (styles.length > 0) {
                    st.split = true;
                    st.subMenuProps = { "items": styles };
                }
            }
            let bt = allitems["editObject"];
            if (isbpmn && bt && obj) {
                let props: any[] = sem.getDynProps(obj);
                if (props.length > 0) {
                    bt.split = true;
                    bt.subMenuProps = { "items": props };
                }
            }
        } else {
            if (cell === null && allitems["pointer"] && allitems["move"] && allitems["panandzoom"]) {
                menu.push(
                    getMenuItem("pointer", undefined),
                    getMenuItem("move", undefined),
                    getMenuItem("panandzoom", undefined)
                );
                if (!islocked && !isplanmaker && allitems["stencil"]) {
                    menu.push(getMenuItem("stencil", undefined));
                }
                menu.push(getMenuItem("zoomPage", undefined));
                if (sem && sem.page &&
                    !islocked && !isplanmaker && allitems["divider5"] && allitems["insertPage"] &&
                    sem.page.ClassOf().ObjectName !== ob.GetModelAttribute(Process_ElementName.SLProc)) {
                    menu.push(
                        getMenuItem("divider5", undefined),
                        getMenuItem("insertPage", undefined)
                    );
                }
                if (sem && sem.page && allitems["pasteObject"] &&
                    !islocked && !isplanmaker) {
                    menu.push(
                        getMenuItem("pasteObject", undefined)
                    );
                }
                if (!islocked && !isplanmaker && allitems["newPage"]) {
                    menu.push(getMenuItem("newPage", undefined));
                }
                if (sem && sem.page && !islocked && !isplanmaker && allitems["layoutPage"] &&
                    sem.page.ClassOf().ObjectName !== ob.GetModelAttribute(Process_ElementName.SLProc)) {
                    menu.push(getMenuItem("layoutPage", undefined));
                }
                if (!istoolbar) {
                    menu.push({
                        key: "settings",
                        text: sem.getResStr(ResID.STROPTIONS),
                        iconProps: { iconName: "Settings" },
                        onClick: (): void => {
                            callback.DoCommand(SemTalkOnlineCommand.ShowOptions, cell);
                        },
                    });
                }
            }
        }
    }
    return menu;
}


export function GroupMenu(
    callback: ISemTalkOnline,
    sem: IVisioRDFS,
    ribbon: SemTalkRibbon,
    role: SemTalkRole,
    islocked: boolean,
    isplanmaker: boolean,
    _istoolbar: boolean,
    cell: mxCell,
    ev: React.MouseEvent<HTMLElement>
): IContextualMenuItem[] {

    let menu: IContextualMenuItem[] = [];
    if (sem) {
        const res = ComputeMenu(callback, sem, ribbon, role);
        const allitems = res["allitems"];
        let getMenuItem = (
            key: string,
            onclick: undefined | (() => void)
        ): any => {
            const nmenu = allitems[key];
            if (onclick && nmenu) nmenu["onClick"] = onclick;
            return nmenu;
        };
        ev.preventDefault(); // don't navigate
        if (cell != null && !isplanmaker && !islocked) {
            if (allitems["expandGroup"]) {
                menu.push(
                    getMenuItem("expandGroup", () => {
                        callback.DoCommand(SemTalkOnlineCommand.CollapseCell, [cell]);
                    })
                );
            }
            if (allitems["collapseGroup"]) {
                menu.push(
                    getMenuItem("collapseGroup", () => {
                        callback.DoCommand(SemTalkOnlineCommand.CollapseCell, [cell]);
                    })
                );
            }
            menu.push(getMenuItem("divider6", undefined));
            if (allitems["style"]) {
                menu.push(
                    getMenuItem("style", () => {
                        callback.DoCommand(SemTalkOnlineCommand.ShapeStyle, [cell]);
                    })
                );
            }
            menu.push(getMenuItem("divider6a", undefined));
            if (allitems["bringToFront"]) {
                menu.push(
                    getMenuItem("bringToFront", () => {
                        callback.DoCommand(SemTalkOnlineCommand.BringToFront, cell);
                    })
                );
            }
            if (allitems["sendToBack"]) {
                menu.push(
                    getMenuItem("sendToBack", () => {
                        callback.DoCommand(SemTalkOnlineCommand.SendToBack, cell);
                    })
                );
            }
            if (allitems["deleteShapes"] && !islocked) {
                menu.push(
                    getMenuItem("divider3", undefined),
                    getMenuItem("deleteShapes", undefined)
                );
            }
        }
    }
    return menu;
}
