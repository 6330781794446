import { SemTalkCookie } from "./ISemTalkCookie";
import { ISemTalkOnline } from "./ISemTalkOnline";
import { BPMNElement } from "./application/semtalklistener/subtask/bpmn/stbpmninterface/bpmn";
import { IVisioRDFS } from "./application/semtalklistener/visiordfsinterface";
import { IObjectBase, ISemTalkAssociationNode, SemTalkBaseConstant } from "./application/tbase/Interface";
import { absolutePosition } from "./utils";

async function updateNodes(callback: ISemTalkOnline, sem: IVisioRDFS, ob: IObjectBase) {
    let graph = sem?.graph as mxGraph;

    if (sem) {
        let mxg = ob.FindAttributeType(SemTalkBaseConstant.SLMXGAttribute);
        for (let d of ob.AllDiagrams()) {
            if (mxg) {
                d.DeleteAttribute(mxg);
            }
            if (d.Synonyms().length === 0 && d.ObjectName.endsWith("." + d.ID)) {
                if (!ob.FindObjectNamed(d.ObjectCaption)) {
                    d.RenameObject(d.ObjectCaption);
                }
            }
            await callback.loadPage(d.ID, sem, false);
            d.Horizontal = sem.tseditor.isHorizontal();
            let cells = graph.model.cells;
            for (const i in cells) {
                const cell: mxCell = cells[i];
                if (cell.shapeid) {
                    let nd = d.FindNodeOfShape(cell.shapeid);
                    if (nd) {
                        let style = cell.style;
                        let geo = cell.geometry;
                        nd.X = geo.getCenterX();
                        nd.Y = geo.getCenterY();
                        let key = cell.shapeKey;
                        nd.Width = geo.width;
                        nd.Height = geo.height;
                        // switch (key) {
                        //   case "document": {
                        //     key = "DataObject"
                        //   }
                        // }
                        if (!cell.isEdge() && cell.parent) {
                            let abs = absolutePosition(cell);
                            nd.absolutePosition = abs;
                            nd.ParentShapeID = cell.parent.shapeid;
                        }
                        nd.Style = style;
                        nd.Master = key;
                        if (cell.isEdge()) {
                            let assnd = nd as ISemTalkAssociationNode;
                            assnd.FromShape = cell.source.shapeid;
                            assnd.OtherShape = cell.target.shapeid;
                        }
                    }
                }
            }
        }
    }
}

export async function patchXML(callback: ISemTalkOnline, sem: IVisioRDFS, ob: IObjectBase) {
    let ext = ob.FindAttributeType(BPMNElement.extensionElements);
    let olist = ob.AllObjects();
    for (let obj of olist) {
        let n = obj.ObjectName;
        let n2 = n.replace(/"/g, '');
        n2 = n2.replace(/\n/g, '');
        if (n !== n2) {
            obj.RenameObject(n2);
        }
        if (ext) {
            obj.DeleteAttribute(ext);
        }
    }
    await updateNodes(callback, sem, ob);
    for (let ins of ob.AllInstances()) {
        for (let syn of ins.Synonyms()) {
            if (syn.Name === ins.ObjectName) {
                syn.Name = ins.ID2NameNspLan(syn.Language);
            }
        }
    }
    for (let m of ob.AllModelAttributes()) {
        if (m.startsWith(SemTalkBaseConstant.SLMXGPagePrefix)) {
            ob.DeleteModelAttribute(m);
        }
        if (m.startsWith(SemTalkBaseConstant.SLSVGPagePrefix)) {
            ob.DeleteModelAttribute(m);
        }
        if (m.startsWith(SemTalkBaseConstant.CookiePrefix + SemTalkCookie.toolbar)) {
            ob.DeleteModelAttribute(m);
        }
        if (m.startsWith(SemTalkBaseConstant.CookiePrefix + SemTalkCookie.ribbon)) {
            ob.DeleteModelAttribute(m);
        }
    }
}