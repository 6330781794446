export function MakeUnixValue(x: string): string {
    if (x === undefined) {
        return "";
    }
    let i: number;
    let szRet: string = "";
    let szVal: string;
    let ch: string;
    szVal = x;
    for (i = 1; (i <= szVal.length); i++) {
        ch = szVal.substr((i - 1), 1);
        switch (ch) {
            case " ":
                szRet += "_";
                break;
            case "ä":
                szRet += "ae";
                break;
            case "Ä":
                szRet += "Ae";
                break;
            case "ü":
                szRet += "ue";
                break;
            case "Ü":
                szRet += "Ue";
                break;
            case "ö":
                szRet += "oe";
                break;
            case '""':
                szRet += "_";
                break;
            case "Ö":
                szRet += "Oe";
                break;
            case ".":
                szRet += "_";
                break;
            case ",":
                szRet += "_";
                break;
            case ";":
                szRet += "_";
                break;
            case "(":
                szRet += "_";
                break;
            case ")":
                szRet += "_";
                break;
            case "-":
                szRet += "_";
                break;
            case "/":
                szRet += "_";
                break;
            case ":":
                szRet += "_";
                break;
            case "+":
                szRet += "_";
                break;
            case "*":
                szRet += "_";
                break;
            case "|":
                szRet += "_";
                break;
            case "<":
                szRet += "_";
                break;
            case ">":
                szRet += "_";
                break;
            case "?":
                szRet += "_";
                break;
            case "#":
                szRet += "_";
                break;
            case "ß":
                szRet += "ss";
                break;
            case "§":
                szRet += "ss";
                break;
            default:
                szRet += ch;
                break;
        }
    }
    return szRet;
}

export function converBase64toBlob(content: string, contentType: string): Blob {
    contentType = contentType || '';
    let sliceSize: number = 512;
    let byteCharacters: string = window.atob(content);
    let byteArrays: any[] = [
    ];
    for (let offset: number = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice: string = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers: any[] = new Array(slice.length);
        for (let i: number = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    let blob: Blob = new Blob(byteArrays, {
        type: contentType
    });
    return blob;
}