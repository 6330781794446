export enum SB_ElementName {
    List = "List",
    Library = "Library",
    Column = "Column",
    LookupColumn = "LookupColumn",
    Item = "Item",
    Document = "Document",
    Folder = "Folder",
    BooleanColumn = "BooleanColumn",
    ChoiceColumn = "ChoiceColumn",
    CalculatedColumn = "CalculatedColumn",
    CurrencyColumn = "CurrencyColumn",
    DateTimeColumn = "DateTimeColumn",
    NumberColumn = "NumberColumn",
    TextColumn = "TextColumn",
    PersonOrGroupColumn = "PersonOrGroupColumn",
    GeolocationColumn = "GeolocationColumn",
    HyperlinkOrPictureColumn = "HyperlinkOrPictureColumn",
    TermColumn = "TermColumn",
    ThumbnailColumn = "ThumbnailColumn"

}
export enum SB_AssociationName {
    hasColumn = "has Column",
    lookup = "lookup",
    infotype = "InformationType",
    parent = "parent",
    hasFolder = "has Folder"
}
export enum SB_DiagramTypeName {
    SiteDiagram = "Site Diagram"
}
export enum SB_TemplateName {
    genericList = "genericList",
    documentLibrary = "documentLibrary",
    tasks = "tasks",
    survey = "survey",
    links = "links",
    announcements = "announcements",
    contacts = "contacts"
}

export enum SB_AttributeName {
    list = "list",
    listcontentTypesEnabled = "list.contentTypesEnabled",
    listhidden = "list.hidden",
    listtemplate = "list.template",
    listtab = "list.tab",
    lookup = "lookup",
    lookuplistId = "lookup.listId",
    lookupcolumnName = "lookup.columnName",
    lookupallowMultipleValues = "lookup.allowMultipleValues",
    lookupallowUnlimitedLength = "lookup.allowUnlimitedLength",
    lookupprimaryLookupColumnId = "lookup.primaryLookupColumnId",
    name = "name",
    text = "text",
    textallowMultipleLines = "text.allowMultipleLines",
    textappendChangesToExistingText = "text.appendChangesToExistingText",
    textlinesForEditing = "text.linesForEditing",
    textmaxLength = "text.maxLength",
    texttextType = "text.textType",
    id = "id",
    type = "type",
    boolean = "boolean",
    calculated = "calculated",
    calculatedformat = "calculated.format",
    calculatedformula = "calculated.formula",
    calculatedoutputType = "calculated.outputType",
    choice = "choice",
    choicechoices = "choice.choices",
    choiceallowTextEntry = "choice.allowTextEntry",
    choiceallowMultipleValues = "choice.allowMultipleValues",
    choicedisplayAs = "choice.displayAs",
    currency = "currency",
    currencylocale = "currency.locale",
    columnGroup = "columnGroup",
    dateTime = "dateTime",
    dateTimeformat = "dateTime.format",
    dateTimedisplayAs = "dateTime.displayAs",
    defaultValue = "defaultValue",
    defaultValuevalue = "defaultValue.value",
    defaultValueformula = "defaultValue.formula",
    enforceUniqueValues = "enforceUniqueValues",
    geolocation = "geolocation",
    number = "number",
    numberdecimalPlaces = "number.decimalPlaces",
    numberdisplayAs = "number.displayAs",
    numberminimum = "number.minimum",
    numbermaximum = "number.maximum",
    personOrGroup = "personOrGroup",
    personOrGroupallowMultipleSelection = "personOrGroup.allowMultipleSelection",
    personOrGroupchooseFromType = "personOrGroup.chooseFromType",
    personOrGroupdisplayAs = "personOrGroup.displayAs",
    isDeletable = "isDeletable",
    hidden = "hidden",
    indexed = "indexed",
    propagateChanges = "propagateChanges",
    isReorderable = "isReorderable",
    isSealed = "isSealed",
    validation = "validation",
    validationformula = "validation.formula",
    validationdescriptions = "validation.descriptions",
    validationdefaultLanguage = "validation.defaultLanguage",
    hyperlinkOrPicture = "hyperlinkOrPicture",
    hyperlinkOrPictureisPicture = "hyperlinkOrPicture.isPicture",
    term = "term",
    termtermSet = "term.termSet",
    termparentTerm = "term.parentTerm",
    termallowMultipleValues = "term.allowMultipleValues",
    termshowFullyQualifiedName = "term.showFullyQualifiedName",
    readOnly = "readOnly",
    required = "required",
    sourceContentType = "sourceContentType",
    sourceContentTypeid = "sourceContentType.id",
    sourceContentTypename = "sourceContentType.name",
    thumbnail = "thumbnail",
    contentApprovalStatus = "contentApprovalStatus",
    parentId = "parentId",
    sealed = "sealed",
    description = "description",
    group = "group",
    isBuiltIn = "isBuiltIn",
    webUrl = "webUrl",
    feature = "feature",
    documentTemplate = "documentTemplate"
}
export enum SB_ColumnType {
    note = "note",
    text = "text",
    choice = "choice",
    number = "number",
    currency = "currency",
    dateTime = "dateTime",
    lookup = "lookup",
    boolean = "boolean",
    user = "user",
    personOrGroup = "personOrGroup",
    url = "url",
    calculated = "calculated",
    location = "location",
    geolocation = "geolocation",
    term = "term",
    thumbnail = "thumbnail",
    approvalStatus = "approvalStatus",
    unknownFutureValue = "unknownFutureValue",
    hyperlinkOrPicture = "hyperlinkOrPicture"
}

export enum SBchoicedisplayAs {
    checkBoxes = "checkBoxes",
    dropDownMenu = "dropDownMenu",
    radioButtons = "radioButtons"
}
// kann weg, weil  die Werte auch in dateTime sind
// export enum SBcalculatedformat {
//     dateOnly = "dateOnly",
//     dateTime = "dateTime"
// }
export enum SBcalculatedoutputType {
    boolean = "boolean",
    currency = "currency",
    dateTime = "dateTime",
    number = "number",
    text = "text"
}
export enum SBtexttextType {
    plain = "plain",
    richText = "richText"
}
export enum SBdateTimeformat {
    dateOnly = "dateOnly",
    dateTime = "dateTime"
}
export enum SBdateTimedisplayAs {
    default = "default",
    friendly = "friendly",
    standard = "standard"
}
export enum SBnumberdecimalPlaces {
    automatic = "automatic",
    none = "none",
    one = "one",
    two = "two",
    three = "three",
    four = "four",
    five = "five"
}
export enum SBnumberdisplayAs {
    number = "number",
    percentage = "percentage"
}
export enum SBpersonOrGroupchooseFromType {
    peopleAndGroups = "peopleAndGroups",
    peopleOnly = "peopleOnly"
}
export enum SBpersonOrGroupdisplayAs {
    account = "account",
    contentType = "contentType",
    created = "created",
    department = "department",
    firstName = "firstName",
    id = "id",
    lastName = "lastName",
    mobilePhone = "mobilePhone",
    name = "name",
    nameWithPictureAndDetails = "nameWithPictureAndDetails",
    nameWithPresence = "nameWithPresence",
    office = "office",
    pictureOnly36x36 = "pictureOnly36x36",
    pictureOnly48x48 = "pictureOnly48x48",
    pictureOnly72x72 = "pictureOnly72x72",
    sipAddress = "sipAddress",
    title = "title",
    userName = "userName",
    workEmail = "workEmail",
    workPhone = "workPhone"

}
export enum SemTalkOnlineSBCommand {
    UploadElementsFile = "UploadElementsFile",
    DownloadElementsFile = "DownloadElementsFile"
}
// account; contentType; created; department; firstName; lastName; mobilePhone; id; name; nameWithPictureAndDetails; nameWithPresence; office; pictureOnly36x36; pictureOnly48x48; pictureOnly72x72; sipAddress; title; userName; workEmail; workPhone
