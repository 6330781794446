export class Utils {
    public static MasterNoDot(mastername: string): string {
        mastername = mastername.trim();
        if (mastername === "Swimlane (vertical)" || mastername === "Swimlane (horizontal)") {
            mastername = "Swimlane";
        }
        if (mastername.indexOf(".") > 0) {
            const r: string = mastername.substr(mastername.lastIndexOf(".")+1);
            if (parseInt(r, 0) > 0 || r.length === 0) {
                mastername = mastername.substr(0, mastername.lastIndexOf("."));
            }
        }
        return mastername;
    }

}
