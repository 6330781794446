import React from "react";
import {
  IMongoOption, mgLogin_with_email_password, mglistDatabases, mgGetItems,
  // mgReset_Password
} from '../../SemTalkMongoServer';
import { Link } from "react-router-dom";
import {
  MessageBar, MessageBarType,
  Stack, StackItem, Text, TextField, DefaultButton, PrimaryButton,
  Dropdown, IDropdownOption, ChoiceGroup, IChoiceGroupOption
} from 'office-ui-fabric-react';
// import { VisioRDFS } from '../../application/semtalklistener/visiordfs';
// import { IVisioRDFS } from '../../application/semtalklistener/visiordfsinterface';
import { IResStr, ResStr } from '../../application/semtalklistener/resstr';
import { SemTalkRole } from "../../ISemTalkOnline";
import { SemTalkCookie } from "../../ISemTalkCookie";
import { DC, DCgetDays, DCgetFeatures } from "../../semtalkkey";
import { filterByAdmin } from './filterByAdmin';
import { accessCookie, setCookie } from "../../application/semtalklistener/stgoto";
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { showHelp } from "../../application/semtalklistener/help";
import { ResID } from "../../application/semtalklistener/visiordfsinterface";
import { ResetPasswort } from "../../utils";
import { ms365login } from "../../login";
import { SemTalkBaseConstant } from "../../application/tbase/Interface";


export interface ILoginProps {
  setToken: any;
  setcurrToken: any;
  mongo: IMongoOption;
  role: SemTalkRole | string;
  message: string;
}
export interface ILoginState {
  useridemail: string;
  password: string;
  dbname: string;
  role: SemTalkRole | string;
  repository: string;
  success: boolean;
  nosuccess: boolean;
  errormsg: string;
  msgbartype: MessageBarType;
  options: IDropdownOption[];
  // repoptions: IDropdownOption[];
  passwordtype: string;
  roleoptions: IChoiceGroupOption[];
  loadcomplete: boolean;
  ms365login: boolean;
}
export default class Login extends React.Component<ILoginProps, ILoginState> {
  private res: IResStr;
  private gl: any = accessCookie(SemTalkCookie.guilanguage);

  constructor(props: ILoginProps) {
    super(props);

    this.gl = accessCookie(SemTalkCookie.guilanguage);
    if (!this.gl) {
      this.gl = navigator.languages[1] || navigator.language;
      if (this.gl && this.gl.indexOf("-") > 0) {
        this.gl = this.gl.substring(0, this.gl.indexOf("-"));
      }
    }
    this.res = new ResStr(this.gl);

    let roleoptions: any[] = [
      { key: SemTalkRole.viewer, text: this.res.getResStr(ResID.STRVIEWER) },
      { key: SemTalkRole.editor, text: this.res.getResStr(ResID.STREDITOR) },
      { key: SemTalkRole.admin, text: this.res.getResStr(ResID.STRADMIN) },
      { key: SemTalkRole.metamodel, text: this.res.getResStr(ResID.STRMETAMODEL) },
    ];
    if (!roleoptions.find(x => x.key === this.props.role)) {
      roleoptions.push({ key: this.props.role, text: this.props.role });
    }

    let role = this.props.role;
    if (role === SemTalkRole.admin) {
      role = SemTalkRole.editor;
    }


    this.state = {
      useridemail: "",
      password: "",
      dbname: this.props.mongo.dbname,
      repository: this.props.mongo.repository,
      success: true,
      nosuccess: false,
      msgbartype: MessageBarType.info,
      errormsg: this.res.getResStr(ResID.STRLoginFailed),
      options: [],
      // repoptions: [],
      role: role,
      passwordtype: "password",
      roleoptions: roleoptions,
      loadcomplete: false,
      ms365login: false
    };
  }
  public componentDidMount() {
    this.initOptions(this.props.mongo.dbname, this.props.mongo.repository);
    if (this.props.message.length > 1) this.alert(this.props.message, MessageBarType.blocked);
  }

  private _onRoleChange = (_ev: any, option: IChoiceGroupOption | undefined) => {
    if (option !== undefined) {
      this.setState({ role: option.key as SemTalkRole });
    }
  }



  private async initOptions(dbname: string, repository: string) {
    // this.setState({ loadcomplete: false });

    const mg = this.props.mongo;
    let dblist = await mglistDatabases(mg, mg.semmongoconnectiontoken,
      mg.dbname, mg.documents);
    if (dblist.length === 0) {
      mg.semmongoserverurl = mg.semmongoserverurlBackup;
      this.alert("Switching to backup Database Service", MessageBarType.info);
      dblist = await mglistDatabases(mg, mg.semmongoconnectiontoken,
        mg.dbname, mg.documents);
      if (dblist.length === 0) {
        this.alert(this.res.getResStr(ResID.STRLoginNoDatabase), MessageBarType.error);
        setCookie(SemTalkCookie.usemongo, false);
        this.props.mongo.usemongo = false;
        this.props.mongo.semuserlogin = null;
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
    let options = [{ key: mg.defaultdatabase, text: "Default" }];
    // let options: any[] = [];
    for (let db of dblist) {
      if (db !== mg.defaultdatabase) {
        options.push({ key: db, text: db });
      }
    }
    options = await filterByAdmin(mg, "hidden_database", options);
    if (mg.dbname !== mg.defaultdatabase && options.find(x => x.key === mg.dbname) === undefined) {
      options.push({ key: mg.dbname, text: mg.dbname });
    }

    // let repdblist = await mglistDatabases(mg, mg.semmongoconnectiontoken,
    //   mg.dbname, mg.objects);
    // let repoptions = [{ key: mg.defaultrepository, text: "Default" }];
    // for (let db of repdblist) {
    //   if (db !== mg.defaultrepository) {
    //     repoptions.push({ key: db, text: db });
    //   }
    // }
    // repoptions = await filterByAdmin(mg, "hidden_repository", repoptions);

    let roleoptions: any[] = [];
    let roledblist = await mgGetItems(this.props.mongo, this.props.mongo.dbname, this.props.mongo.roles);
    if (roledblist === undefined || roledblist.length === 0) {
      roleoptions = [
        { key: SemTalkRole.viewer, text: this.res.getResStr(ResID.STRVIEWER) },
        { key: SemTalkRole.editor, text: this.res.getResStr(ResID.STREDITOR) },
        { key: SemTalkRole.metamodel, text: this.res.getResStr(ResID.STRMETAMODEL) },
        { key: SemTalkRole.publisher, text: this.res.getResStr(ResID.STRPUBLISHER) },
        { key: SemTalkRole.admin, text: this.res.getResStr(ResID.STRADMIN) },
      ];
    } else {
      for (let role of roledblist) {
        let key = role["key"];
        if (!key && role["RoleName"]) {
          key = role["RoleName"].toLowerCase();
          if (key === "administrator") {
            key = "admin";
          }
        }
        switch (key) {
          case "viewer":
            roleoptions.push({ key: SemTalkRole.viewer, text: this.res.getResStr(ResID.STRVIEWER) });
            break;
          case "editor":
            roleoptions.push({ key: SemTalkRole.editor, text: this.res.getResStr(ResID.STREDITOR) });
            break;
          case "metamodel":
            roleoptions.push({ key: SemTalkRole.metamodel, text: this.res.getResStr(ResID.STRMETAMODEL) });
            break;
          case "admin":
            roleoptions.push({ key: SemTalkRole.admin, text: this.res.getResStr(ResID.STRADMIN) });
            break;
          case "publisher":
            roleoptions.push({ key: SemTalkRole.publisher, text: this.res.getResStr(ResID.STRPUBLISHER) });
            break;
          default:
            roleoptions.push({ key: key, text: role["name"] });
            break;
        }
      }
    }

    this.setState({
      dbname: dbname,
      repository: repository,
      options: options,
      // repoptions: repoptions,
      roleoptions: roleoptions,
      loadcomplete: true
    });
  }
  private handleSubmit = async (e: any) => {
    if (e) e.preventDefault();
    if (this.state.useridemail && this.state.useridemail.length > 0 && this.state.password && this.state.password.length > 0) {
      if (this.state.useridemail.toLowerCase().toString().indexOf("@") > 0 && this.state.useridemail.toLowerCase().toString().indexOf(".") > 0) {
        this.login(this.state.dbname, this.state.useridemail, this.state.password);
      }
      else {
        this.alert(this.res.getResStr(ResID.STRLoginUserIsNotEMail), MessageBarType.blocked);
      }
    }
  }

  private login = async (dbname: string, userid: string, password: string
  ) => {
    try {
      const res: any = await mgLogin_with_email_password(this.props.mongo,
        dbname,
        userid,
        password);
      if (res.login_token !== undefined) {
        if (res.login_token.toString().length > 0) {
          let regkey = res.user_info.profile.regkey;
          //regkey = MakeKey("test", 365, true, true, false, true, true, true, false);
          if (regkey) {
            let key = DC(regkey);
            let days = Number(DCgetDays(key));
            if (days < 0) {
              this.alert(this.res.getResStr(ResID.STRLoginFailed) + " Your SemTalk Online License is expired!", MessageBarType.blocked);
              return;
            }
            let features = DCgetFeatures(key);
            console.log(features);
          }
          let rolelist = res.user_info.profile.roles;
          let roles: string[] = [];
          if (rolelist) {
            if (typeof rolelist === "string") {
              roles = rolelist.split(";");
            } else {
              roles = rolelist as string[];
            }
          }
          let isallowed: boolean = true;
          switch (this.state.role) {
            case SemTalkRole.admin: {
              isallowed = roles.includes(this.state.role);
              break;
            }
            case SemTalkRole.metamodel: {
              isallowed = roles.includes(this.state.role) ||
                roles.includes(SemTalkRole.admin);
              break;
            }
            case SemTalkRole.editor: {
              isallowed = roles.includes(this.state.role) ||
                roles.includes(SemTalkRole.admin) ||
                roles.includes(SemTalkRole.metamodel);
              break;
            }
            default:
              isallowed = roles.includes(this.state.role) ||
                roles.includes(SemTalkRole.admin);
              break;
          }
          if (isallowed) {
            setCookie(SemTalkCookie.dbname, this.state.dbname);
            setCookie(SemTalkCookie.repository, this.state.repository);
            setCookie(SemTalkCookie.role, this.state.role);
            res["username"] = this.state.useridemail;
            if (res && res.user_info && res.user_info.profile && res.user_info.profile.chatgptkey) {
              let key = res.user_info.profile.chatgptkey;
              if (key.length > 20) {
                setCookie(SemTalkCookie.chatgpt, key);
              }
            }
            this.props.setToken(res);
            this.props.setcurrToken(res);
          } else {
            this.alert(this.res.getResStr(ResID.STRLoginFailed) + " " + this.res.getResStr(ResID.STRROLENOTALLOWED), MessageBarType.blocked);
          }
        } else {
          this.alert(this.res.getResStr(ResID.STRLoginEmptyLoginToken), MessageBarType.blocked);
        }
      } else {
        this.alert(this.res.getResStr(ResID.STRLoginFailed), MessageBarType.blocked);
      }
    } catch (err) {
      let msg: string = "unknown";
      if (err && typeof err === "object") {
        const e1: any = err;
        msg = e1["message"];
        if (e1.response && e1.response.status) {
          switch (e1.response.status) {
            case 404: {
              msg = "404: Cannot connect to MongoDB";
              setCookie(SemTalkCookie.usemongo, false);
              this.props.mongo.usemongo = false;
              this.props.mongo.semuserlogin = null;
              setTimeout(() => {
                window.location.reload();
              }, 3000);
              break;
            }
            case 500: {
              msg = e1.response.data;
              if (msg.toLowerCase().startsWith("no such user")) {
                msg = "Login failed. Database: " + dbname + " Account: " + userid;
              }
              break;
            }
          }
        }
      }
      this.alert(msg, MessageBarType.error);
      // this.setState({ "success": false, "nosuccess": true, "errormsg": msg });
    }
  }



  // private togglePassword = () => {
  //   if (this.state.passwordtype === "password") {
  //     this.setState({
  //       passwordtype: "text"
  //     });
  //     return;
  //   }
  //   this.setState({
  //     passwordtype: "password"
  //   });
  // }

  private ResetPasswort = async (usermail: any) => {
    let message = await ResetPasswort(usermail, this.props.mongo, this.state.dbname, this.props.mongo.users, this.gl);
    this.alert(message, MessageBarType.info);

  }
  public alert = (msg: string, mtype: MessageBarType): void => {
    if (msg === "") {
      this.setState({ nosuccess: false, errormsg: msg });
    } else {
      // if (this.state.errormsg === "") {
      this.setState({
        nosuccess: true,
        errormsg: msg,
        msgbartype: mtype
      });
      // }
    }
  }
  private dom365login = async () => {
    let getValue = (cookie: SemTalkCookie, v: any): any => {
      let val: any = undefined;
      let localvalue = accessCookie(cookie);
      if (localvalue) {
        val = localvalue;
      } else {
        val = v;
      }
      return val;
    };
    let scopes: string[] = [
      // "User.Read",
      // "Sites.Read.All",
      // "Group.ReadWrite.All",
      // "Files.Read.All",
    ];
    let applicationid: string = getValue(
      SemTalkCookie.applicationid,
      "f2f7dcf0-0917-4911-b587-f0c941391758"
    ) as string;
    let authority: string = getValue(
      SemTalkCookie.authority,
      "https://login.microsoftonline.com/semtalk.onmicrosoft.com"
    ) as string;
    let res: any;
    if (window.location.origin === "http://localhost:3000") {
      res = await ms365login(
        "9eec9ef7-38a4-4b29-8ac5-b8b02564c78b",
        "https://login.microsoftonline.com/semtalk.onmicrosoft.com",
        scopes
      );
    } else {
      res = await ms365login(applicationid, authority, scopes);
    }
    if (res && res.account && SemTalkBaseConstant.SSOPWD) {
      this.setState({
        useridemail: res.account.username,
        password: SemTalkBaseConstant.SSOPWD,
        ms365login: true
      });
      this.login(this.state.dbname, res.account.username, SemTalkBaseConstant.SSOPWD);
    }
  }

  // private handleCancel = async (e: any) => {
  //   if (e) e.preventDefault();
  //   this.props.setcurrToken(null);
  //   this.props.mongo.semuserlogin = null;
  //   // window.history.replaceState(null, "", "/?usemongo=false");
  // }

  public render(): React.ReactElement {
    let divHostStyle: any = {
      // position: "fixed",
      // height: "100%", //this.props.height,
      // width: "80%", //this.props.width,
      padding: "20px"
    };
    const stackTokens = { childrenGap: 10 };
    let welcome = this.res.getResStr(ResID.STRINTRO0);
    if (document.baseURI.indexOf("citcomposer") > -1) {
      welcome = welcome.replace("SemTalk", "cit Composer");
    }
    const usemongosso = (accessCookie(SemTalkCookie.usemongosso) === "true");
    return (
      <div>
        {this.res &&
          <div>
            <Stack style={divHostStyle} tokens={stackTokens}>
              <StackItem>
                <Text variant="xxLarge">{welcome}</Text>
              </StackItem>
              {/* <StackItem>
                <Text variant="large">{"Please Log In or Sign up"}</Text>
              </StackItem> */}
              {!this.state.loadcomplete &&
                <ProgressIndicator label={this.res.getResStr(ResID.STRLOADDB)} description={""}
                  barHeight={5} />
              }
              {this.state.nosuccess && (
                <MessageBar
                  messageBarType={this.state.msgbartype}
                  onDismiss={(_event) => this.alert("", MessageBarType.info)}
                  dismissButtonAriaLabel="Close"
                >
                  {this.state.errormsg}
                </MessageBar>
              )}
              {this.state.loadcomplete &&
                <form>
                  <Stack tokens={stackTokens}>
                    <Stack tokens={stackTokens}>
                      <StackItem>
                        <Dropdown
                          placeholder={this.res.getResStr(ResID.STRSELECTDB)}
                          label={this.res.getResStr(ResID.STRSQLDATABASE).replace(":", "")}
                          selectedKey={this.state.dbname}
                          onChange={(_e: any, v?: any) => {
                            this.setState({ "dbname": v.key });
                          }}
                          options={this.state.options}
                        />
                      </StackItem>
                      <StackItem>
                        <TextField type="text" required label={this.res.getResStr(ResID.STRUSERID)
                          + " (Email)"}
                          value={this.state.useridemail}
                          onChange={(_e: any, v?: any) => this.setState({ "useridemail": v })} />
                      </StackItem>
                      {/* <StackItem>
                        <Checkbox checked={this.state.ms365login} label="Single Sign On"></Checkbox>
                      </StackItem> */}
                      {!this.state.ms365login &&
                        <StackItem>
                          <Link to="" onClick={() => this.ResetPasswort(this.state.useridemail)}>{this.res.getResStr(ResID.STRRESPWD)}</Link>
                        </StackItem>
                      }
                      {!this.state.ms365login &&
                        <Stack>
                          <StackItem>
                            <TextField
                              type="password"
                              canRevealPassword
                              required
                              label={this.res.getResStr(ResID.STRDLGPWD)}
                              onChange={(_e: any, v?: any) => this.setState({ "password": v })} />
                          </StackItem>
                        </Stack>
                      }
                      <StackItem>
                        <ChoiceGroup defaultSelectedKey={this.state.role} options={this.state.roleoptions}
                          onChange={this._onRoleChange}
                          label={this.res.getResStr(ResID.STRUSERROLE)} required={true} />
                      </StackItem>
                    </Stack>
                    <Stack tokens={stackTokens} horizontal>
                      <PrimaryButton disabled={!this.state.loadcomplete}
                        onClick={this.handleSubmit}
                        type="submit">{this.res.getResStr(ResID.STRDLGBOPTLO)}</PrimaryButton>
                      {usemongosso &&
                        <DefaultButton disabled={!this.state.loadcomplete}
                          onClick={() => { this.dom365login(); }}
                          text={"MS365 " + this.res.getResStr(ResID.STRLOGIN)}></DefaultButton>
                      }
                      {/* <DefaultButton type="submit" onClick={this.handleCancel}>{this.res.getResStr(ResID.STRDLGCMDCA)}</DefaultButton>
                    </Stack>
                    <Stack tokens={stackTokens} horizontal> */}
                      <DefaultButton><Link to="/signup" >{this.res.getResStr(ResID.STRREGISTER)}</Link></DefaultButton>
                      <DefaultButton><Link to="/backend">{this.res.getResStr(ResID.STRCONNECTION)}</Link></DefaultButton>
                      <DefaultButton onClick={() => { showHelp("SemTalk-Login", this.gl); }}>{this.res!.getResStr(ResID.STRHELP)}</DefaultButton>
                    </Stack>
                  </Stack>
                </form>
              }
            </Stack>
          </div>
        }
      </div>
    );
  }
}
