export enum EPC_ElementName {
    Function = "Function",
    Event = "Event",
    Personentyp = "Personentyp",
    Service = "Service",
    Entitytyp = "Entitytyp",
    Position = "Position",
    Class = "epc#Class",
    Fachbegriff = "Fachbegriff",
    Ressource = "Allgemeine Ressource",
    Prozesspfad = "Prozesspfad",
    Kennzahl = "Kennzahl",
    Risiko = "Risiko",
    Control = "Control",
    Anwendungssystemtyp = "Anwendungssystemtyp",
    XOR = "XOR",
    OR = "OR",
    AND = "AND",
    Wissenskategorie = "Wissenskategorie",
    HumanRes = "Human Resource",
    Operator = "Operator",
    Transaction = "Transaction",
    Package = "Package",
    EPCObject = "EPC Object",
    DataObject = "Data Object"
}
export enum EPC_AssociationName {
    aktiviert = "aktiviert",
    erzeugt = "erzeugt",
    führtaus = "führt aus",
    führtzu = "führt zu",
    hasTransaction = "has Transaction",
    hasOperation = "hasOperation",
    hasOutput = "hat Output",
    istInputfür = "ist Input für",
    istVorgängervon = "ist Vorgänger von",
    liefertInputfür = "liefert Input für",
    MessageType = "MessageType",
    suppliesOutputfor = "supplies Output for",
    verknüpft = "verknüpft",
    wirdausgewertetvon = "wird ausgewertet von",
    wirdbenötigtfür = "wird benötigt für",
    wirdverwendetfür = "wird verwendet für",
    wirdverwendetvon = "wird verwendet von"
}
export enum EPC_DiagramTypeName {
    BusinessObjectModel = "Business-Objekt Modell",
    OrgChart = "Organigramm",
    ResClassModel = "Sachmittelmodell",
    KnowlegeMap = "Wissenslandkarte",
    SystemModel = "Informationsträgermodell"

}