/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-useless-concat */
import React, { Suspense } from "react";
import {
  IComboBoxOption,
  Stack,
  StackItem,
} from "office-ui-fabric-react";
import { ITSEditor, zoomoption } from "./ITSEditor";
import { TSEditor } from "./tseditor/tseditor";
import { VisioRDFS } from "./application/semtalklistener/visiordfs";
import {
  IVisioRDFS,
  ITermSetItem,
  SemTalkNavigationEvent,
  SemTalkStencil,
  ISharePointSettings,
  SemTalkRibbon,
  SemTalkShape,
  SemTalkShapeType,
  SemTalkStyleAttribute,
  SemTalkBuiltInShape,
  ResID,
  ResIDL,
  ISharePointGraphSettings,
} from "./application/semtalklistener/visiordfsinterface";
import { SemTalkMaster } from "./application/SemTalkMaster";
import {
  refreshPage,
  refreshObject,
} from "./application/semtalklistener/refresh";

import { ObjectBase } from "./application/tbase/ObjectBase";
import { OB2JSON } from "./application/tbase/OB2JSON";
import { OB2XML } from "./application/tbase/OB2XML";

import { ms365login, ms365logout, ms365token } from "./login";
import { ISPExplorer } from "./application/explorer/spexplorerinterface";
import { SPExplorer } from "./application/explorer/spexplorer";
import {
  IMongoOption,
  mgGetDocument,
  mgSaveDocument,
  mgSaveDocumentAs,
  mgCheckDocument,
  mgGetValue,
  // mgCallService,
  mgGetItem,
  mgDeleteItem,
  mgLogout,
  mgSetValue,
  mgGetMetaData,
  mgSaveItem,
  mgLogin_with_email_password,
  mgGetItems,
  // mgInsertItems,
  // mgGetCollections,
  // mgGetMetaData
} from "./SemTalkMongoServer";

import { Process_ElementName } from "./application/semtalklistener/processInterface";

// import * as strings from 'SemTalkStrings';
import {
  getObject,
  gotoObject,
  gotoNode,
  gotoDocument,
  addCallBack,
  removeCallBack,
  gotoShape,
  gotoNodeByID,
  settingsChanged,
} from "./application/semtalklistener/stglobal";
import {
  addDocumentToHistory,
  IHistory,
  IHistoryEntry,
  isBackward,
  isForward,
  resetHistory,
} from "./SemTalkHistory";
import {
  ISemTalkDiagram,
  SemTalkType,
  ISemTalkObject,
  ISemTalkInstance,
  ISemTalkSystemClass,
  ISemTalkLabel,
  SemTalkRelation,
  ISemTalkDiagramType,
  ISemTalkAssociation,
  // IObjectBase,
  // ISemTalkBusinessClass,
  SemTalkBaseConstant,
  SemTalkLanguage,
  SemTalkLanguageCode,
  ModelAttribute,
  SemTalkComposeOrder,
  IObjectBase,
  SemTalkID,
  ISemTalkClass,
  SemTalkJSON,
  SemTalkAttachment,
  PrintPageFormat,
  // ISemTalkAssociationNode,
  // ISemTalkBusinessClass,
} from "./application/tbase/Interface";
import { SemTalkRole } from "./ISemTalkOnline";

import { PanelType, Panel } from "office-ui-fabric-react/lib/Panel";
import {
  ContextualMenu,
  IContextualMenuItem,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
} from "office-ui-fabric-react";
import { ProgressIndicator } from "office-ui-fabric-react/lib/ProgressIndicator";

import { Guid } from "guid-typescript";

import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { ISemTalkUndo } from "./ISemTalkUndo";
import { SemTalkUndo } from "./SemTalkUndo";
import { SemTalkOnlineCommand } from "./ISemTalkOnline";
import { SemTalkCookie } from "./ISemTalkCookie";
import ExportWord from "./controls/stwordexport/wordexport";
import ExportHTML from "./controls/stwordexport/htmlexport";
import ExportPNG from "./controls/stwordexport/pngexport";
import ExportJPG from "./controls/stwordexport/jpgexport";
import {
  ModelProperty,
} from "./SemTalkOptions";
import {
  ISemTalkServicesOptions,
  srvExport,
  srvGetReports,
  srvLog,
  srvResetPublished,
  srvUploadFile,
  srvUploadReport,
} from "./SemTalkServices";
import {
  accessCookie,
  // getDiagram,
  // removeCookie,
  setCookie,
  setSubsitePrefix,
} from "./application/semtalklistener/stgoto";
import { BPMNElement } from "./application/semtalklistener/subtask/bpmn/stbpmninterface/bpmn";
import { OWLExport } from "./application/tbase/exportowl";
import { exportJSONLD } from "./application/tbase/exportjsonld";
import { OWLImport } from "./application/tbase/importowl";
import ExportPDF from "./pdfexport";
import { GENERIC_SHAPES } from "./application/semtalklistener/generic-stencil";
import { updateRepository } from "./repository";
import {
  exportDefaultSettings, GetSettingsDefaultValue,
  importDefaultSettings, loadDefaultSettings,
  onImportSettingsHandler, resetSettings, saveDefaultSettings
} from "./settings";
import { ComputeMenu, ContextMenu, GroupMenu } from "./menu";
import { ISemTalkOnlineState } from "./ISemTalkOnlineState";
import { ISemTalkOnlineProps } from "./ISemTalkOnlineProps";
import { applyTheme } from "./theme";
import {
  renderAssocTypes, renderAttrTypes, renderChatgpt, renderCheckRepair,
  renderCompose, renderCreateDiagram, renderCustomize, renderDiagTypes,
  renderDiagram, renderDocuments, renderExpand, renderFacetSearch,
  renderFileOpen, renderForm, renderHeaderFooter, renderHelp, renderHierarchy, renderHyperlink,
  renderImages,
  renderImport, renderInsert, renderMethodTypes,
  renderCharting, renderImpressum, renderPrivacy,
  renderModelProperties,
  renderMongoUser,
  renderMongoUserManager,
  renderNavigation, renderOWLImport, renderOneDrive, renderOpenLink,
  renderPivot, renderPlanner, renderPrintPreview, renderPromptManager,
  renderPublish, renderQuickEdit, renderRelation, renderRenameObjects, renderReport,
  renderReportManager,
  renderRibbonEditor,
  renderSearch, renderSelect, renderSemantics, renderShapeStyle,
  renderShareDocument, renderSimulation, renderStateTypes, renderStencilEditor,
  renderStorageManager,
  renderTableEditor,
  renderTableImport, renderTasks, renderTermSet, renderSiteBuilder,
  renderTranslate, renderVersionControl, renderLayout, renderKeyManager, renderExportManager
} from "./render";
import { formatdate, IExportConnection } from "./utils";
import { ensureLabelWidth } from "./styles";
import { unzipdoc, zipdoc, getzipdoc } from "./zipdocument";
import { patchXML } from "./xmlexport";
import { Configuration, OpenAIApi } from "openai";
import { askQuestion, decodechatgpt, encodechatgpt } from "./chatgpt";
import copy from 'copy-to-clipboard';
import { DC } from "./semtalkkey";
import { Code2Language } from "./application/tbase/langtools";

// import SemTalkCommandBar from './controls/stcommandbar/SemTalkCommandBar';
// import SemTalkToolBar from "./controls/sttoolbar/SemTalkToolBar";
// import SemTalkBreadCrumbs from "./controls/stbreadcrumbs/SemTalkBreadCrumbs";

const SemTalkPlanner = React.lazy(
  () =>
    import(
      /* webpackChunkName: "planner" */
      "./controls/stplanner/SemTalkPlanner"
    )
);
const SemTalkSettings = React.lazy(
  () =>
    import(
      /* webpackChunkName: "settings" */
      "./controls/stsettings/SemTalkSettings"
    )
);
const SemTalkRepository = React.lazy(
  () =>
    import(
      /* webpackChunkName: "repository" */
      "./controls/strepository/SemTalkRepository"
    )
);
const SemTalkCommandBar = React.lazy(
  () =>
    import(
      /* webpackChunkName: "commandbar" */
      "./controls/stcommandbar/SemTalkCommandBar"
    )
);
const SemTalkToolBar = React.lazy(
  () =>
    import(
      /* webpackChunkName: "toolbar" */
      "./controls/sttoolbar/SemTalkToolBar"
    )
);
const SemTalkBreadCrumbs = React.lazy(
  () =>
    import(
      /* webpackChunkName: "breadcrumbs" */
      "./controls/stbreadcrumbs/SemTalkBreadCrumbs"
    )
);
const LoginIntern = React.lazy(
  () =>
    import(
      /* webpackChunkName: "loginintern" */
      "./controls/stlogin/LoginIntern"
    )
);
const LoginRole = React.lazy(
  () =>
    import(
      /* webpackChunkName: "loginrole" */
      "./controls/stlogin/Role"
    )
);
const SemTalkLogFile = React.lazy(
  () =>
    import(
      /* webpackChunkName: "logfile" */
      "./controls/stlogfile/SemTalkLogFile"
    )
);

initializeIcons(undefined, { disableWarnings: true });

export class SemTalkOnline extends React.Component<ISemTalkOnlineProps, ISemTalkOnlineState> {
  public callback: Guid;
  private isreadonly = false;
  // private ischeckedout2me = false;
  private diagramroot: ISemTalkSystemClass | null = null;
  private otherroot: ISemTalkSystemClass | null = null;
  private othertext: string | null = null;
  private stencil: SemTalkStencil = [];
  // private xmlshapes: HTMLCollection[] = [];
  private click_x: number = 100;
  private click_y: number = 100;
  private isclasses: boolean = false;
  public autosave: boolean = false;
  public checkouttoken_templist: string = "SDX_Temp";
  // private checkouttoken_tempid: number = 0;
  private filename: string = "";
  private electron_filename: string = "";
  private undoManger: ISemTalkUndo = new SemTalkUndo();
  private lzutf8: boolean = true;
  // private semantics_service = "https://qurator-embeddings.apps.osc.fokus.fraunhofer.de";
  // private semantics_service = "http://127.0.0.1:5000/";
  // private semantics_service = this.props.semantics.server;
  // private graphClient: any;
  private account: any = null;
  private showhyperlinkmarker: boolean = false;
  private sprops: ISharePointSettings;
  private gprops: ISharePointGraphSettings;
  private selectedfilename: string = "";
  private selectedfilenames: string[] = [];
  private selectedfiletype: string = ".sdx";
  private selectedexists: boolean = false;
  // private selectedlanguage: SemTalkLanguage = SemTalkLanguage.German;
  private exportConnection: IExportConnection;
  private publishBg: boolean = false;
  private selecteddiagname: string = "";
  private selecteddiagramtype: ISemTalkDiagramType | null = null;
  private selectedBackupfilename: string = "";
  private selectedBackupContent: SemTalkJSON = "";
  private selectedexternalob: IObjectBase | null = null;
  private is_goto_start_event: boolean = false;
  private is_goup: boolean = false;
  private mongodb_current_metadata: any = null;
  private modelproperties: any[] | null = null;
  private selectedImages: any[]= [];


  constructor(props: ISemTalkOnlineProps) {
    super(props);
    this.callback = Guid.create();
    console.debug("SemTalkOnline");

    let defaultcolumns = "FileLeafRef,ID,CheckoutUser/Title,Modified,Author/Title,Editor/Title,Created,Domain,Comment,Template";

    this.sprops = {
      site: ".",
      librarysite: ".",
      cdn: "https://semtalkportal45.azurewebsites.net/",
      context: this.props.context,
      support: "Support",
      templates: "Templates",
      library: "SDX",
      // "approved": "Approved"
      approved: "SDX",
      sharepointdocumentcolumns: defaultcolumns
    };
    if (this.sprops.context) {
      // this.sprops.site = "../..";
      this.sprops.site = props.site;
      this.sprops.librarysite = props.librarysite;
      if (!props.librarysite) {
        this.sprops.librarysite = props.site;
      }
    }
    // this.gprops.graphClient = this.props.graphClient;
    let template = this.props.template;
    let filename = this.props.model;
    if (template === "") {
      if (filename.endsWith(".stx")) {
        template = filename;
      } else {
        template = "bpmn20-2016.stx";
      }
    }
    if (template === "BPMN20.stx" || template === "bpmn20-2010.stx") {
      template = "bpmn20-2016.stx";
    }

    let subsiteprefix = this.props.subsiteprefix;
    if (subsiteprefix) {
      setSubsitePrefix(subsiteprefix);
    }
    this.gprops = {
      usegraph: true,
      sharepointlibrarysite: "",
      sharepointlibrary: "",
      sharepointdocumentcolumns: "",
      graphClient: this.props.graphClient,
      sharepointlibraryfixed: false
    };
    this.state = {
      semtalk: undefined,
      // filename: "",
      xmlgraph: null,
      hidePropertyDialog: true,
      hideCustomizeDialog: true,
      hideSelectDialog: true,
      hideTermSetDialog: true,
      hideSiteBuilderDialog: true,
      hideExpandDialog: true,
      hideRelationDialog: true,
      hideCreateDiagramDialog: true,
      hideInsertDialog: true,
      hideComposeDialog: true,
      hideTranslateDialog: true,
      hideTasksDialog: true,
      hidePlannerDialog: true,
      hideHyperlinkDialog: true,
      hideOpenLinkDialog: true,
      hideFormDialog: true,
      addoncommand: "",
      hideSubTaskDialog: true,
      //  hideFilePicker: true,
      hideFileOpen: true,
      hidePublishDialog: true,
      hideShapeStyle: true,
      hideHelp: true,
      // hidemgLoginDialog: this.props.mongo.semuserlogin !== null || !this.props.mongo.iselectron || !this.props.mongo.usemongo,
      hidemgLoginDialog: true,
      hideRoleDialog: true,
      hidemgUserProfilDialog: true,
      hideUserManager: true,
      hideReportManager: true,
      hidePromptManager: true,
      hideStorageManager: true,
      hideCheckRepair: true,
      hideSimulation: true,
      panelTypeSimulation: PanelType.medium,
      panelTypeDocuments: PanelType.medium,
      hidePrintPreview: true,
      hideChatgpt: true,
      hideHeaderFooter: true,
      hideImages: true,
      showNavigatorDialog: false,
      showPlannerDialog: false,
      showSearchDialog: false,
      hideFileOneDrive: true,
      hideDocuments: true,
      hideStencilEditor: true,
      hideRibbonEditor: true,
      hideRenameObjects: true,
      panelTypeStencilEditor: PanelType.medium,
      panelTypeRibbonEditor: PanelType.medium,
      panelTypeHeaderEditor: PanelType.medium,
      panelTypeImagesEditor: PanelType.medium,
      panelTypeExportConnectionEditor: PanelType.medium,
      panelTypeKeyManagerEditor: PanelType.medium,
      panelTypeShapeStyle: PanelType.smallFixedFar,
      hideImport: true,
      hideOWLImport: true,
      hideCharting: true,
      hidePrivacy: true,
      hideImpressum: true,
      panelTypeImport: PanelType.medium,
      panelTypeCharting: PanelType.medium,
      //   hideFolderPicker: false,
      hideShareDocument: true,
      // diaglist: [],
      diag: null,
      // showPivot: false,
      panelTypePivot: PanelType.medium,
      panelTypeNLP: PanelType.medium,
      showSearch: false,
      panelTypeSearch: PanelType.medium,
      showFacetSearch: false,
      showQuickEdit: false,
      panelQuickEdit: PanelType.large,
      showSettings: false,
      showModelProperties: false,
      showDiag: false,
      //showProcTable: false,
      showNav: false,
      panelTypeNav: PanelType.customNear,
      showHie: false,
      panelTypeHie: PanelType.customNear,
      panelTypeVer: PanelType.medium,
      showAssocTypes: false,
      showAttrTypes: false,
      showMethodTypes: false,
      showStateTypes: false,
      showDiagTypes: false,
      showRelTree: false,
      showRep: false,
      // showProp: false,
      showRepositoryTable: false,
      panelTypeRepositoryTable: PanelType.medium,
      showTableEditor: false,
      panelTypeTableEditor: PanelType.medium,
      panelTypeTermSet: PanelType.medium,
      panelTypeSiteBuilder: PanelType.medium,
      panelTypeAss: PanelType.smallFixedFar,
      panelTypeAtt: PanelType.smallFixedFar,
      panelTypeStorage: PanelType.medium,
      showTableImport: false,
      showSemantics: false,
      showOpen: true,
      redraw: false,
      shape: null,
      object: null,
      relation: null,
      filePickerResult: "",
      isopen: false,
      isnew: false,
      issaveas: false,
      isimport: false,
      ismerge: false,
      publishinglog: [],
      isdelete: false,
      spexplorercaption: "SaveAs",
      stencil: this.stencil,
      // xmlshapes: this.xmlshapes,
      showContextualMenu: false,
      cntpages: 1,
      template: template,
      openlinkurl: "",
      openlinkcaption: "",
      checkedOut: false,
      ischeckedout2me: false,
      mongo: this.props.mongo,
      language: this.props.language,
      isconnectionpoints: false,
      nosuccess: false,
      errormsg: "War wohl nix",
      msgbartype: MessageBarType.error,
      isLoading: "",
      ribbon: [],
      toolbar: [],
      islocked: this.props.role === SemTalkRole.viewer,
      isadmin: this.props.role === SemTalkRole.admin,
      ismetamodel: this.props.role === SemTalkRole.metamodel,
      watching: "",
      loaded: 0,
      showWelcome: false,
      hideGraph: false,
      defaultsettings: {},
      width: 2000,
      height: 1300,
      shapescale: 1,
      resizeall: false,
      // fontsize: fontsize,
      allownegativecoordinates: false,
      showBPMN: false,
      bpmnstencil: false,
      bpmnrules: false,
      bpmnsymbols: true,
      bpmneventtypes: true,
      bpmnsfmfrules: true,
      bpmnmultipleinterfaces: false,
      objectflow: false,
      showSimulation: false,
      usesimulationcoloring: false,
      showCommandBar: true,
      showToolBar: true,
      showBreadCrumbs: true,
      // showhyperlinkmarker: false,
      underlineRefinements: false,
      showlanguage: false,
      dockpanandzoom: true,
      docksearch: true,
      docknavigator: true,
      dockplanner: true,
      dockstencil: false,
      dockpivot: false,
      useDialogs: false,
      shapesleftside: false,
      isquickshapes: true,
      autorefresh: 0,
      azureAD: { clientID: "", authority: "", scopes: [] },
      // termset: {
      //   termset: "",
      //   termsetgroup: "",
      //   termsetroot: "",
      //   termsetassoc: "",
      // },
      // teams: { teamid: "", channelid: "", planid: "" },
      semantics: {
        server: "",
        corpus: "",
        distance: 0.7,
        tika: "",
      },
      // usegraph: true,
      isexport: false,
      spCheckInOut: true,
      sharepointrepository: "",
      // sharepointlibrary: "",
      // sharepointlibrarysite: "",
      sharepointdocumentcolumns: defaultcolumns,
      // sharepointlibraryfixed: true,
      semtalk_services: {
        server: "",
        site: "",
        database: "",
        sqlconnection: "",
        servertype: "",
        breports: true,
      },
      autorefreshreferences: false,
      guilanguage: this.props.guilanguage,
      editdialogwidth: this.props.editdialogwidth,
      loadedTmpName: template,
      isconfirmsaveas: false,
      isconfirmrepository: false,
      isconfirmdelete: false,
      isconfirmdeletepage: false,
      logourl: "",
      chatgpt: this.props.chatgpt,
      openai: null,
      panelTypeChatgpt: PanelType.customNear,
      headerfooterfields: [],
      showheaderfooterfields: false,
      printpageformat: "",
      printlandscape: false,
      selectedFilename: "",
      selectedFileContent: "",
      oneDriveSave: false,
      versionCount: "5",
      hideVersionControl: true,
      autosaverepository: false,
      loading: false,
      isplannerenabled: false,
      ispagesenabled: true,
      isreflexivelinks: false,
      autoextend: true,
      autoscroll: true,
      extendParentsOnMove: true,
      extendParentsOnAdd: true,
      resizecontainer: false,
      splitenabled: true,
      role: this.props.role,
      hideLayout: true,
      hideKeyManager: true,
      hideExportManager: true,
    };
    // try {
    //   this.getSettings(this.props.mongo);
    // } catch (_e) {
    //   this.resetSettings();
    // }
  }

  private getSettings = async (mongo: IMongoOption) => {
    let defaultsettings = await loadDefaultSettings(
      mongo,
      this.sprops,
      "SemTalkCookie"
    );
    let sem = this.state.semtalk;
    let getValue = (cookie: SemTalkCookie, v: any): any => {
      if (sem) {
        let mv = sem.base.GetModelAttribute(
          SemTalkBaseConstant.CookiePrefix + cookie
        );
        if (mv !== undefined) {
          return mv;
        }
      }
      let val: any = undefined;
      let localvalue = accessCookie(cookie);
      if (localvalue) {
        val = localvalue;
      } else {
        val = this.GetDefaultValue(defaultsettings, cookie, v);
      }
      return val;
    };
    let copyundefined = (cookie: SemTalkCookie) => {
      if (!accessCookie(cookie)) {
        let x = defaultsettings[cookie];
        if (x) {
          setCookie(cookie, x);
        }
      }
    };

    let width = parseInt(
      getValue(SemTalkCookie.width, (window as any).innerWidth)
    );
    if (width < 1920) width = 1920;
    let height = parseInt(
      getValue(SemTalkCookie.height, (window as any).innerHeight)
    );
    if (height < 1080) height = 1080;
    let shapescale = Number(getValue(SemTalkCookie.shapescale, "1"));
    let resizeall = getValue(SemTalkCookie.resizeall, "false") === "true";
    let allownegativecoordinates =
      getValue(SemTalkCookie.allownegativecoordinates, "false") === "true";
    let showbreadcrumbs =
      getValue(SemTalkCookie.showbreadcrumbs, "true") === "true";
    this.showhyperlinkmarker =
      getValue(SemTalkCookie.showhyperlinkmarker, "false") === "true";
    let bpmn = getValue(SemTalkCookie.bpmn, "false") === "true";
    let bpmnstencil = getValue(SemTalkCookie.bpmnstencil, "false") === "true";
    let bpmndialog = getValue(SemTalkCookie.bpmndialog, "false") === "true";
    let bpmnsymbols = getValue(SemTalkCookie.bpmnsymbols, "true") === "true";
    let bpmneventtypes = getValue(SemTalkCookie.bpmneventtypes, "true") === "true";
    let bpmnsfmfrules = getValue(SemTalkCookie.bpmnsfmfrules, "true") === "true";
    let bpmnmultipleinterfaces = getValue(SemTalkCookie.bpmnmultipleinterfaces, "false") === "true";

    let objectflow = getValue(SemTalkCookie.objectflow, "false") === "true";

    let refinements = getValue(SemTalkCookie.refinements, "false") === "true";
    let simulation = getValue(SemTalkCookie.simulation, "false") === "true";
    let showlanguage = getValue(SemTalkCookie.showlanguage, "false") === "true";
    let showmenubar = getValue(SemTalkCookie.showmenubar, "true") === "true";
    let showtoolbar = getValue(SemTalkCookie.showtoolbar, "true") === "true";
    let dockpanandzoom =
      getValue(SemTalkCookie.dockpanandzoom, "false") === "true";
    let docknavigator =
      getValue(SemTalkCookie.docknavigator, "false") === "true";
    let dockplanner = getValue(SemTalkCookie.dockplanner, "false") === "true";
    let dockstencil = getValue(SemTalkCookie.dockstencil, "false") === "true";
    let dockpivot = getValue(SemTalkCookie.dockpivot, "false") === "true";
    let usedialogs = getValue(SemTalkCookie.usedialogs, "false") === "true";
    let editdialogwidth = getValue(SemTalkCookie.editdialogwidth, "450px");
    let shapesleftside =
      getValue(SemTalkCookie.shapesleftside, "false") === "true";
    let autosaverepository =
      getValue(SemTalkCookie.autosaverepository, "false") === "true";
    let isquickshapes =
      getValue(SemTalkCookie.showquickshapes, "true") === "true";
    let autorefresh = parseInt(getValue(SemTalkCookie.autorefresh, 0));
    let applicationid: string = getValue(
      SemTalkCookie.applicationid,
      "f2f7dcf0-0917-4911-b587-f0c941391758"
    ) as string;
    let authority: string = getValue(
      SemTalkCookie.authority,
      "https://login.microsoftonline.com/semtalk.onmicrosoft.com"
    ) as string;
    let usegraph = getValue(SemTalkCookie.usegraph, "false") === "true";
    // let logourl: string = getValue(SemTalkCookie.logourl, "") as string;

    let scopes = [
      "User.Read",
      // "Sites.Read.All",
      // "Group.Read.All",
      // "Files.Read.All",
      // "Team.ReadBasic.All"
    ];
    let scopesstr = getValue(SemTalkCookie.scopes, scopes.join(";")) as string;
    // let scopes: string[] = ["User.Read"];
    if (scopesstr) scopes = scopesstr.split(";");
    let semantics_server: string = getValue(
      SemTalkCookie.semanticsserver,
      ""
    ) as string;
    let semantics_corpus: string = getValue(
      SemTalkCookie.semanticscorpus,
      "de_core_news_md"
    ) as string;
    let semantics_distance: string = getValue(
      SemTalkCookie.semanticsdistance,
      "0.7"
    ) as string;
    let tika_server: string = getValue(SemTalkCookie.tikaserver, "") as string;

    const defaultsite = "Modellierung";
    const graphsites = "/sites/semtalk.sharepoint.com:/sites/";
    const sitesurl = "https://semtalk.sharepoint.com/sites/";
    const defaultlib = "SDX";


    let sharepointrepository: string = getValue(
      SemTalkCookie.sharepointrepository,
      graphsites + defaultsite + ":/"
    ) as string;

    let sharepointlibrary: string = getValue(
      SemTalkCookie.sharepointlibrary,
      defaultlib
    ) as string;

    let sharepointlibrarysite: string = getValue(
      SemTalkCookie.sharepointlibrarysite,
      graphsites + defaultsite + ":/"
    ) as string;

    let defaultcolumns = "FileLeafRef,ID,CheckoutUser/Title,Modified,Author/Title,Editor/Title,Created,Domain,Comment,Template";
    let sharepointdocumentcolumns: string = getValue(
      SemTalkCookie.sharepointdocumentcolumns,
      defaultcolumns
    ) as string;

    let sharepointlibraryfixed: boolean = getValue(
      SemTalkCookie.sharepointlibraryfixed, "true") === "true";


    let sharepointoverrideswebpartsetting: boolean = getValue(
      SemTalkCookie.sharepointoverrideswebpartsetting, "true")
      === "true";

    if (sharepointoverrideswebpartsetting) {
      if (this.sprops.site === sitesurl + defaultsite &&
        sharepointrepository !== graphsites + defaultsite + ":/") {
        let site = sharepointrepository.substring(sharepointrepository.indexOf("/sites/") + "/sites/".length);
        site = site.substring(0, site.indexOf(":/"));
        this.sprops.site = this.sprops.site.replace(defaultsite, site);
        this.sprops.librarysite = this.sprops.site;
      }
      if (this.sprops.librarysite === sitesurl + defaultsite &&
        sharepointlibrarysite !== graphsites + defaultsite + ":/") {
        let site = sharepointlibrarysite.substring(sharepointlibrarysite.indexOf("/sites/") + "/sites/".length);
        site = site.substring(0, site.indexOf(":/"));
        this.sprops.librarysite = this.sprops.librarysite.replace(defaultsite, site);
      }
      if (this.sprops.library === defaultlib &&
        sharepointlibrary !== defaultlib) {
        this.sprops.library = sharepointlibrary;
      }
    }

    let chatgpt = this.props.chatgpt;
    if (chatgpt.length === 0) {
      chatgpt = getValue(
        SemTalkCookie.chatgpt,
        encodechatgpt("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")
      ) as string;
    }
    let servicesconstr = getValue(
      SemTalkCookie.servicesconstr,
      "j3Ex0PaYX9FPVebgHPYWrEkL/MB7sJBYZKYFJMBPgqbhftmY6CloPDZlHFIFQtavujVacjD2T4LYZSOqtS3ZGLK9/EBq9mZonU4NDbVH5imK3XCXXw3M+H/Q0vIQunmGJeetmKvUB/8pFlZnfQK/J56yfyB3sx0Qxl9y8mp1rjfTZcmWuPO1Pi53u2GhKzhezBY7bto7Z7IXntG9SE/V/mB/ts1kHsJLz+TBIA38wWiyLv4t2+hFgOP82BzFA+c8YQ8UayK4BLszR/mRROKo5KgkdlOlOu3hrJIY9aN8Yn8WGIYDyPYOnRprpFSUjnvEMOy7KqFzu1mi1yW6mBg+E8SJ4MkQ4VmJ9nPIPYqkAnI="
    );
    let servicesservertype = getValue(
      SemTalkCookie.servicesservertype,
      "mssql"
    );
    let servicesserver = getValue(
      SemTalkCookie.servicesserver,
      "https://semtalkservicewebapp4.azurewebsites.net/api/semservice"
    );
    let servicessite = getValue(
      SemTalkCookie.servicessite,
      "https://semtalkonline.semtalk.com"
    );
    let servicesdatabase = getValue(
      SemTalkCookie.servicesdatabase,
      mongo.dbname
    );
    let servicesbreports = getValue(SemTalkCookie.breports, "true") === "true";
    //let usemongo = getValue(SemTalkCookie.usemongo, "true") === "true";
    let usesimulationcoloring =
      getValue(SemTalkCookie.usesimulationcoloring, "true") === "true";
    let headerfooterfields = [];
    let fields = getValue(SemTalkCookie.headerfooterfields, undefined);
    if (fields) {
      headerfooterfields = JSON.parse(fields);
    }
    let showheaderfooterfields =
      getValue(SemTalkCookie.showheaderfooterfields, "false") === "true";
    // showheaderfooterfields=false;
    let printpageformat = getValue(SemTalkCookie.printpageformat, PrintPageFormat.custom);
    let printlandscape =
      getValue(SemTalkCookie.printlandscape, "false") === "true";
    let versionCount = "5";
    versionCount = getValue(SemTalkCookie.versioncount, "5");
    let isplannerenabled =
      getValue(SemTalkCookie.isplannerenabled, "false") === "true";
    let ispagesenabled =
      getValue(SemTalkCookie.pages, "0") !== "0";
    let isreflexivelinks =
      getValue(SemTalkCookie.isreflexivelinks, "false") === "true";
    let autoextend =
      getValue(SemTalkCookie.autoextend, "true") === "true";
    let autoscroll =
      getValue(SemTalkCookie.autoscroll, "true") === "true";
    let extendParentsOnMove =
      getValue(SemTalkCookie.extendParentsOnMove, "true") === "true";
    let extendParentsOnAdd =
      getValue(SemTalkCookie.extendParentsOnAdd, "true") === "true";
    let resizecontainer =
      getValue(SemTalkCookie.resizecontainer, "false") === "true";
    let splitenabled =
      getValue(SemTalkCookie.splitenabled, "true") === "true";

    if (Object.keys(defaultsettings).length > 0) {
      copyundefined(SemTalkCookie.language);
      copyundefined(SemTalkCookie.font);
      copyundefined(SemTalkCookie.fontsize);
      copyundefined(SemTalkCookie.bpmn);
      copyundefined(SemTalkCookie.bpmndialog);
      copyundefined(SemTalkCookie.bpmnsymbols);
      copyundefined(SemTalkCookie.breports);
      copyundefined(SemTalkCookie.guilanguage);
      copyundefined(SemTalkCookie.guifont);
      copyundefined(SemTalkCookie.autoComplete);
      copyundefined(SemTalkCookie.backgroundColor);
      copyundefined(SemTalkCookie.panzoom);
      copyundefined(SemTalkCookie.stencil);
      copyundefined(SemTalkCookie.pages);
      copyundefined(SemTalkCookie.navigator);
      copyundefined(SemTalkCookie.planner);
      copyundefined(SemTalkCookie.themeStyle);
      copyundefined(SemTalkCookie.iconcolor);
      copyundefined(SemTalkCookie.theme);
      copyundefined(SemTalkCookie.themeStyle);
      copyundefined(SemTalkCookie.userDefTheme);
      copyundefined(SemTalkCookie.approved);
      copyundefined(SemTalkCookie.editdialogwidth);
      // copyundefined(SemTalkCookie.logourl);
      copyundefined(SemTalkCookie.chatgpt);
      copyundefined(SemTalkCookie.usesimulationcoloring);
      copyundefined(SemTalkCookie.showheaderfooterfields);
      copyundefined(SemTalkCookie.usemongo);
      copyundefined(SemTalkCookie.versioncount);
      copyundefined(SemTalkCookie.isplannerenabled);
    }
    // if (!this.props.context) {
    applyTheme();
    // }

    let approved = accessCookie(SemTalkCookie.approved);
    if (!approved) {
      approved = "SDX";
    }
    if (approved && approved.length > 0) {
      this.sprops.approved = approved;
      mongo.approved = approved;
    }

    let services: ISemTalkServicesOptions = {
      server: servicesserver,
      site: servicessite,
      database: servicesdatabase,
      sqlconnection: servicesconstr,
      servertype: servicesservertype,
      breports: servicesbreports,
    };

    this.gprops.usegraph = usegraph;
    this.gprops.sharepointlibrary = sharepointlibrary;
    this.gprops.sharepointlibrarysite = sharepointlibrarysite;
    this.gprops.sharepointdocumentcolumns = sharepointdocumentcolumns;
    this.gprops.sharepointlibraryfixed = sharepointlibraryfixed;

    this.setState({
      width: width,
      height: height,
      shapescale: shapescale,
      resizeall: resizeall,
      // fontsize: fontsize,
      allownegativecoordinates: allownegativecoordinates,
      showBPMN: bpmndialog,
      bpmnstencil: bpmnstencil,
      bpmnrules: bpmn,
      bpmnsymbols: bpmnsymbols,
      bpmneventtypes: bpmneventtypes,
      bpmnsfmfrules: bpmnsfmfrules,
      bpmnmultipleinterfaces: bpmnmultipleinterfaces,
      objectflow: objectflow,
      showSimulation: simulation,
      usesimulationcoloring: usesimulationcoloring,
      showCommandBar: showmenubar,
      showToolBar: showtoolbar,
      showBreadCrumbs: showbreadcrumbs,
      // showhyperlinkmarker: showhyperlinkmarker,
      underlineRefinements: refinements,
      showlanguage: showlanguage,
      dockpanandzoom: dockpanandzoom,
      dockstencil: dockstencil,
      docknavigator: docknavigator,
      dockplanner: dockplanner,
      dockpivot: dockpivot,
      useDialogs: usedialogs,
      shapesleftside: shapesleftside,
      isquickshapes: isquickshapes,
      autorefresh: autorefresh,
      azureAD: {
        clientID: applicationid,
        authority: authority,
        scopes: scopes,
      },
      // termset: {
      //   termset: termset,
      //   termsetgroup: termsetgroup,
      //   termsetroot: termsetroot,
      //   termsetassoc: termsetassoc,
      // },
      // teams: { teamid: teamid, channelid: channelid, planid: planid },
      semantics: {
        server: semantics_server,
        corpus: semantics_corpus,
        distance: Number(semantics_distance),
        tika: tika_server,
      },


      // usegraph: usegraph,
      isexport: false,
      spCheckInOut: true,
      sharepointrepository: sharepointrepository,
      // sharepointlibrary: sharepointlibrary,
      // sharepointlibrarysite: sharepointlibrarysite,
      sharepointdocumentcolumns: sharepointdocumentcolumns,
      // sharepointlibraryfixed: sharepointlibraryfixed,
      autosaverepository: autosaverepository,
      semtalk_services: services,
      defaultsettings: defaultsettings,
      editdialogwidth: editdialogwidth,
      // logourl: logourl,
      chatgpt: chatgpt,
      headerfooterfields: headerfooterfields,
      showheaderfooterfields: showheaderfooterfields,
      printpageformat: printpageformat,
      printlandscape: printlandscape,
      versionCount: versionCount,
      isplannerenabled: isplannerenabled,
      ispagesenabled: ispagesenabled,
      isreflexivelinks: isreflexivelinks,
      autoextend: autoextend,
      autoscroll: autoscroll,
      extendParentsOnMove: extendParentsOnMove,
      extendParentsOnAdd: extendParentsOnAdd,
      resizecontainer: resizecontainer,
      splitenabled: splitenabled,

    });
  }

  public DoCommand(cmd: SemTalkOnlineCommand, args: any): boolean {
    switch (cmd) {
      case SemTalkOnlineCommand.NewDocument: {
        this.newDocument();
        break;
      }
      case SemTalkOnlineCommand.OpenDocument: {
        this.openDocument();
        break;
      }
      case SemTalkOnlineCommand.SaveDocument: {
        this.saveDocument();
        break;
      }
      case SemTalkOnlineCommand.SaveAsDocument: {
        this.saveAsDocument();
        break;
      }
      case SemTalkOnlineCommand.DeleteDocument: {
        this.deleteDocument();
        break;
      }
      case SemTalkOnlineCommand.CloseDocument: {
        this.closeDocument();
        break;
      }
      case SemTalkOnlineCommand.VersionControl: {
        this.versionControl();
        break;
      }
      case SemTalkOnlineCommand.AutoSave: {
        this.autoSave();
        break;
      }
      case SemTalkOnlineCommand.DownloadDocument: {
        this.download("", ".sdx");
        break;
      }
      case SemTalkOnlineCommand.UploadDocument: {
        this.upload(this.onOpenUploadChangeHandler, false, ".sdx");
        break;
      }
      case SemTalkOnlineCommand.ImportDocument: {
        this.importDocument();
        break;
      }
      case SemTalkOnlineCommand.MergeDocuments: {
        this.mergeDocuments();
        break;
      }
      case SemTalkOnlineCommand.ImportOWLFile: {
        this.importOWLFile(this.onImportOWLUploadChangeHandler, false);
        break;
      }
      case SemTalkOnlineCommand.ImportXMLFile: { this.uploadXMLFile(this.onUploadXMLHandler); break; }
      case SemTalkOnlineCommand.DownloadWordFile: {
        this.downloadWordFile();
        break;
      }
      case SemTalkOnlineCommand.DownloadHTMLFile: {
        this.downloadHTMLFile();
        break;
      }
      case SemTalkOnlineCommand.DownloadPNGFile: {
        this.downloadPNGFile();
        break;
      }
      case SemTalkOnlineCommand.DownloadPDFFile: {
        this.downloadPDFFile();
        break;
      }
      case SemTalkOnlineCommand.DownloadJPGFile: {
        this.downloadJPGFile();
        break;
      }
      case SemTalkOnlineCommand.DownloadOWLFile: {
        this.downloadOWLFile();
        break;
      }
      case SemTalkOnlineCommand.DownloadJSONLDFile: {
        this.downloadJSONLDFile();
        break;
      }
      case SemTalkOnlineCommand.DownloadXMLFile: {
        this.downloadXMLFile();
        break;
      }
      case SemTalkOnlineCommand.PublishXMLFile: {
        this.publishXMLFile();
        break;
      }
      case SemTalkOnlineCommand.ApproveDocument: {
        this.approveDocument();
        break;
      }
      case SemTalkOnlineCommand.CheckInDocument: {
        this.checkInDocument();
        break;
      }
      case SemTalkOnlineCommand.CheckOutDocument: {
        this.checkOutDocument();
        break;
      }
      case SemTalkOnlineCommand.DownloadSVGFile: {
        this.fileImage();
        break;
      }
      case SemTalkOnlineCommand.FileImage: {
        this.fileImage();
        break;
      }
      case SemTalkOnlineCommand.PrintPreview: {
        this.printPreview();
        break;
      }
      case SemTalkOnlineCommand.StartPanning: {
        this.startPanning();
        break;
      }
      case SemTalkOnlineCommand.StopPanning: {
        this.stopPanning();
        break;
      }
      case SemTalkOnlineCommand.TogglePanZoom: {
        this.togglePanZoom();
        break;
      }
      case SemTalkOnlineCommand.ToggleStencil: {
        this.toggleStencil();
        break;
      }
      case SemTalkOnlineCommand.ToggleNavigator: {
        this.toggleNavigator();
        break;
      }
      case SemTalkOnlineCommand.TogglePlanner: {
        this.ShowPlanner();
        break;
      }
      case SemTalkOnlineCommand.ToggleSearch: {
        // Wird z.Z. nicht verwendet
        this.toggleSearch();
        break;
      }
      case SemTalkOnlineCommand.TogglePages: {
        this.togglePages();
        break;
      }
      case SemTalkOnlineCommand.ToggleBackground: {
        this.toggleBackground();
        break;
      }
      case SemTalkOnlineCommand.TogglePageBreaks: {
        this.togglePageBreaks();
        break;
      }
      case SemTalkOnlineCommand.DeleteShapes: {
        this.deleteShapes();
        break;
      }
      case SemTalkOnlineCommand.SelectAll: {
        this.selectAll();
        break;
      }
      case SemTalkOnlineCommand.SendToBack: {
        if (this.state.semtalk) {
          let cell: any = args;
          if (!cell || Object.keys(cell).length === 0) {
            cell = this.state.semtalk!.currentShape();
          }
          this.sendToBack(cell);
        }
        break;
      }
      case SemTalkOnlineCommand.BringToFront: {
        if (this.state.semtalk) {
          let cell: any = args;
          if (!cell || Object.keys(cell).length === 0) {
            cell = this.state.semtalk!.currentShape();
          }
          this.bringToFront(cell);
        }
        break;
      }
      case SemTalkOnlineCommand.Undo: {
        this.Undo();
        break;
      }
      case SemTalkOnlineCommand.Redo: {
        this.Redo();
        break;
      }
      case SemTalkOnlineCommand.ShapeStyle: {
        this.showShapeStyle();
        break;
      }
      case SemTalkOnlineCommand.ConnectionPoints: {
        this.connectionPoints();
        break;
      }
      case SemTalkOnlineCommand.DistributeCellsVert: {
        this.distribute(true);
        break;
      }
      case SemTalkOnlineCommand.DistributeCellsHor: {
        this.distribute(false);
        break;
      }
      case SemTalkOnlineCommand.AlignHorizontalLeft: {
        this.align("AlignHorizontalLeft");
        break;
      }
      case SemTalkOnlineCommand.AlignHorizontalCenter: {
        this.align("AlignHorizontalCenter");
        break;
      }
      case SemTalkOnlineCommand.AlignHorizontalRight: {
        this.align("AlignHorizontalRight");
        break;
      }
      case SemTalkOnlineCommand.AlignVerticalTop: {
        this.align("AlignVerticalTop");
        break;
      }
      case SemTalkOnlineCommand.AlignVerticalCenter: {
        this.align("AlignVerticalCenter");
        break;
      }
      case SemTalkOnlineCommand.AlignVerticalBottom: {
        this.align("AlignVerticalBottom");
        break;
      }
      case SemTalkOnlineCommand.ZoomIn: {
        this.zoomIn();
        break;
      }
      case SemTalkOnlineCommand.ZoomOut: {
        this.zoomOut();
        break;
      }
      case SemTalkOnlineCommand.ZoomFit: {
        this.zoomFit();
        break;
      }
      case SemTalkOnlineCommand.ZoomActual: {
        this.zoomActual();
        break;
      }
      case SemTalkOnlineCommand.Center: {
        this.center();
        break;
      }
      case SemTalkOnlineCommand.Redraw: {
        this.redraw();
        break;
      }
      case SemTalkOnlineCommand.EditObject: {
        this.editObject(null);
        break;
      }
      case SemTalkOnlineCommand.SelectCell: {
        if (this.state.semtalk) {
          let cell: any = args;
          if (!cell) {
            cell = this.state.semtalk.currentShape();
          }
          this.selectCell(cell);
        }
        break;
      }
      case SemTalkOnlineCommand.InsertWayPoint: {
        if (this.state.semtalk) {
          let cell: any = args;
          if (!cell) {
            cell = this.state.semtalk.currentShape();
          }
          this.insertWayPointToCell(cell);
        }
        break;
      }
      case SemTalkOnlineCommand.RemoveWayPoint: {
        if (this.state.semtalk) {
          let cell: any = args;
          if (!cell) {
            cell = this.state.semtalk.currentShape();
          }
          this.removeWayPointsfromCell(cell);
        }
        break;
      }
      case SemTalkOnlineCommand.ElbowCell: {
        if (this.state.semtalk) {
          let cell: any = args;
          if (!cell) {
            cell = this.state.semtalk.currentShape();
          }
          this.elbowCell(cell);
        }
        break;
      }
      case SemTalkOnlineCommand.EditClass: {
        this.editClass();
        break;
      }
      case SemTalkOnlineCommand.DeleteObject: {
        this.deleteObject();
        break;
      }
      case SemTalkOnlineCommand.ToClass: {
        this.toClass();
        break;
      }
      case SemTalkOnlineCommand.ToInstance: {
        this.toInstance();
        break;
      }
      case SemTalkOnlineCommand.CompactShape: {
        this.compactShape();
        break;
      }
      case SemTalkOnlineCommand.DeleteHierarchy: {
        this.deleteHierarchy();
        break;
      }
      case SemTalkOnlineCommand.ComposeObject: {
        this.composeObject();
        break;
      }
      case SemTalkOnlineCommand.UnComposeObject: {
        this.uncomposeObject();
        break;
      }
      case SemTalkOnlineCommand.UpdateRepository: {
        // this.updateRepo();
        this.OpenConfirmDialog({ isconfirmrepository: true });
        break;
      }
      case SemTalkOnlineCommand.TranslateObject: {
        this.translateObject();
        break;
      }
      case SemTalkOnlineCommand.PlannerTasks: {
        this.plannerTasks();
        break;
      }
      case SemTalkOnlineCommand.Planner: {
        this.planner();
        break;
      }
      case SemTalkOnlineCommand.ConnectShapes: {
        this.connectShapes();
        break;
      }
      case SemTalkOnlineCommand.GroupShapes: {
        this.groupShapes();
        break;
      }
      case SemTalkOnlineCommand.UngroupShapes: {
        this.ungroupShapes();
        break;
      }
      case SemTalkOnlineCommand.HyperlinkObject: {
        this.hyperlinkObject();
        break;
      }
      case SemTalkOnlineCommand.RefineObject: {
        this.refineObject();
        break;
      }
      case SemTalkOnlineCommand.DetachObject: {
        this.detachObject();
        break;
      }
      case SemTalkOnlineCommand.GoBack: {
        this.goBackForward(-1);
        break;
      }
      case SemTalkOnlineCommand.GoForward: {
        this.goBackForward(1);
        break;
      }
      case SemTalkOnlineCommand.CustomizeObject: {
        this.customizeObject();
        break;
      }
      case SemTalkOnlineCommand.InsertSwimlane: {
        this.insertSwimlane(this.state.semtalk?.currentShape());
        break;
      }
      case SemTalkOnlineCommand.ToggleDirection: {
        this.toggleDirection(this.state.semtalk?.currentShape());
        break;
      }
      case SemTalkOnlineCommand.CollapseCell: {
        this.collapseCell(this.state.semtalk?.currentShape());
        break;
      }
      case SemTalkOnlineCommand.RefreshObject: {
        this.refreshObject();
        break;
      }
      case SemTalkOnlineCommand.RefreshPage: {
        this.refreshPage();
        break;
      }
      case SemTalkOnlineCommand.ReorderPages: {
        this.reorderPages();
        break;
      }
      case SemTalkOnlineCommand.ExpandObject: {
        this.expandObject();
        break;
      }
      case SemTalkOnlineCommand.EditPage: {
        this.editPage();
        break;
      }
      case SemTalkOnlineCommand.AddPage: {
        this.addPage();
        break;
      }
      case SemTalkOnlineCommand.DeletePage: {
        this.deletePage();
        break;
      }
      case SemTalkOnlineCommand.GoUp: {
        this.goUp();
        break;
      }
      case SemTalkOnlineCommand.Renumber: {
        this.renumber();
        break;
      }
      case SemTalkOnlineCommand.Insert: {
        this.insert();
        break;
      }
      case SemTalkOnlineCommand.Termstore: {
        this.termstore();
        break;
      }
      case SemTalkOnlineCommand.mxSwimlaneLayout: {
        this.doLayout("mxSwimlaneLayout", true, true, false);
        break;
      }
      case SemTalkOnlineCommand.mxHierarchicalLayout: {
        this.doLayout("mxHierarchicalLayout", true, true, false);
        break;
      }
      case SemTalkOnlineCommand.mxCompactTreeLayout: {
        this.doLayout("mxCompactTreeLayout", true, true, false);
        break;
      }
      case SemTalkOnlineCommand.mxCompactTreeLayoutV: {
        this.doLayout("mxCompactTreeLayout", true, false, false);
        break;
      }
      case SemTalkOnlineCommand.mxCompactTreeLayoutI: {
        this.doLayout("mxCompactTreeLayout", true, false, true);
        break;
      }
      case SemTalkOnlineCommand.mxCompactTreeLayoutVI: {
        this.doLayout("mxCompactTreeLayout", true, true, true);
        break;
      }
      case SemTalkOnlineCommand.mxFastOrganicLayout: {
        this.doLayout("mxFastOrganicLayout", true, true, false);
        break;
      }
      case SemTalkOnlineCommand.mxRadialTreeLayout: {
        this.doLayout("mxRadialTreeLayout", true, true, false);
        break;
      }
      case SemTalkOnlineCommand.mxCircleLayout: {
        this.doLayout("mxCircleLayout", true, true, false);
        break;
      }
      case SemTalkOnlineCommand.ShowHelp: {
        this.showHelp();
        break;
      }
      case SemTalkOnlineCommand.ShowForm: {
        this.showForm();
        break;
      }
      case SemTalkOnlineCommand.ShowOptions: {
        this.showOptions();
        break;
      }
      case SemTalkOnlineCommand.SelectDataLang: {
        this.selectDataLang(args, true);
        break;
      }
      // case SemTalkOnlineCommand.SelectGuiLang: { this.selectGuiLang(args); break; }
      // case SemTalkOnlineCommand.WordVectors: { this.WordVectors(); break; }
      // case SemTalkOnlineCommand.InvertedTaxonomy: { this.InvertedTaxonomy(); break; }
      case SemTalkOnlineCommand.ResetMetaModel: {
        this.ResetMetaModel();
        break;
      }
      case SemTalkOnlineCommand.AddonCommand: {
        this.AddonCommand(args);
        break;
      }
      case SemTalkOnlineCommand.mgLogin: {
        this.mgLogin();
        break;
      }
      case SemTalkOnlineCommand.mgLogout: {
        this.mgLogout();
        break;
      }
      case SemTalkOnlineCommand.mgUserProfile: {
        this.mgUserProfil();
        break;
      }
      case SemTalkOnlineCommand.mgUserManager: {
        this.mgUserManager();
        break;
      }
      case SemTalkOnlineCommand.ReportManager: {
        this.ReportManager();
        break;
      }
      case SemTalkOnlineCommand.PromptManager: {
        this.PromptManager();
        break;
      }
      case SemTalkOnlineCommand.StorageManager: {
        this.StorageManager();
        break;
      }
      case SemTalkOnlineCommand.MS365Login: {
        this.MS365Login([]);
        break;
      }
      case SemTalkOnlineCommand.MS365Role: {
        this.MS365Role();
        break;
      }
      case SemTalkOnlineCommand.MS365Logout: {
        this.MS365Logout();
        break;
      }
      case SemTalkOnlineCommand.AddShape: {
        this.addShape(args);
        break;
      }
      case SemTalkOnlineCommand.AddQuickShape: {
        this.insertQuickShape(args, null);
        break;
      }
      case SemTalkOnlineCommand.SelectLeft: {
        this.selectLeft();
        break;
      }
      case SemTalkOnlineCommand.SelectRight: {
        this.selectRight();
        break;
      }
      case SemTalkOnlineCommand.SelectUp: {
        this.selectUp();
        break;
      }
      case SemTalkOnlineCommand.SelectDown: {
        this.selectDown();
        break;
      }
      case SemTalkOnlineCommand.Speech: {
        this.speakObject();
        break;
      }
      case SemTalkOnlineCommand.LoadDefaultSettings: {
        this.setState({
          defaultsettings: loadDefaultSettings(
            this.state.mongo,
            this.sprops,
            "SemTalkCookie"
          ),
        });
        break;
      }
      case SemTalkOnlineCommand.SaveDefaultSettings: {
        if (this.state.semtalk) saveDefaultSettings(this.state.semtalk, this.props.mongo, this.sprops);
        break;
      }
      case SemTalkOnlineCommand.ExportDefaultSettings: {
        this._exportDefaultSettings();
        break;
      }
      case SemTalkOnlineCommand.ImportDefaultSettings: {
        importDefaultSettings(onImportSettingsHandler);
        break;
      }
      case SemTalkOnlineCommand.ResetSettings: {
        resetSettings();
        break;
      }
      case SemTalkOnlineCommand.CheckandRepair: {
        this.checkRepair();
        break;
      }
      case SemTalkOnlineCommand.Simulation: {
        this.showSimulation();
        break;
      }
      case SemTalkOnlineCommand.ShareDocument: {
        this.shareDocument();
        break;
      }
      case SemTalkOnlineCommand.TextField: {
        this.setTextField(args);
        break;
      }
      case SemTalkOnlineCommand.ChatGPT: {
        this.chatGPT();
        break;
      }
      case SemTalkOnlineCommand.SpeakChatGPT: {
        if (true) {
          let q = accessCookie(SemTalkCookie.chatgptquestion);
          if (!q) {
            q = "Wie kann ich den Prozess verbessern ?";
          }
          this.speakchatGPT(q);
        } else {
          this.speakObject();
        }
        break;
      }
      case SemTalkOnlineCommand.Images: {
        this.images();
        break;
      }
      case SemTalkOnlineCommand.HeaderFooter: {
        this.headerFooter();
        break;
      }
      case SemTalkOnlineCommand.ExportBackgroundSettings: {
        this.exportBackgroundSettings();
        break;
      }
      case SemTalkOnlineCommand.ImportBackgroundSettings: {
        this.importBackgroundSettings(this.onImportBackgroundSettingsHandler, args);
        break;
      }
      // case SemTalkOnlineCommand.ImportFIMFile: {
      //   this.importFIMFile(this.onImportFIMUploadChangeHandler, false);
      //   break;
      // }
      case SemTalkOnlineCommand.Documents: {
        this.documents();
        break;
      }
      case SemTalkOnlineCommand.EditStencil: {
        this.editStencil();
        break;
      }
      case SemTalkOnlineCommand.EditRibbon: {
        this.editRibbon();
        break;
      }
      case SemTalkOnlineCommand.RenameObjects: {
        this.renameObjects();
        break;
      }
      case SemTalkOnlineCommand.Search: {
        this.toggleSearch();
        break;
      }
      case SemTalkOnlineCommand.Code: {
        this.code(this.onUploadCodeHandler);
        break;
      }
      case SemTalkOnlineCommand.SetStyle: {
        this.setStyle(args);
        break;
      }
      case SemTalkOnlineCommand.Charting: {
        this.setState({ hideCharting: false });
        break;
      }
      case SemTalkOnlineCommand.Privacy: {
        this.setState({ hidePrivacy: false });
        break;
      }
      case SemTalkOnlineCommand.Impressum: {
        this.setState({ hideImpressum: false });
        break;
      }
      case SemTalkOnlineCommand.ModelProperties: {
        this.setState({ showModelProperties: true });
        break;
      }
      case SemTalkOnlineCommand.Copy: {
        this.copy();
        break;
      }
      case SemTalkOnlineCommand.Cut: {
        this.cut();
        break;
      }
      case SemTalkOnlineCommand.Paste: {
        this.paste();
        break;
      }
      case SemTalkOnlineCommand.SiteBuilder: {
        this.sitebuilder();
        break;
      }
      case SemTalkOnlineCommand.Layout: {
        this.setState({ hideLayout: false });
        break;
      }
      case SemTalkOnlineCommand.KeyManager: {
        this.setState({ hideKeyManager: false });
        break;
      }
      case SemTalkOnlineCommand.CustomLayout: {
        this.customlayout();
        break;
      }
      case SemTalkOnlineCommand.ExportManager: {
        this.setState({ hideExportManager: false });
        break;
      }
    }
    return true;
  }

  private ResetMetaModel = async () => {
    let sem = this.state.semtalk;
    if (sem) {
      await sem.patchSubTask(sem.base, this.state.resizeall);
    }
  }

  private AddonCommand = (args: any) => {
    let sem = this.state.semtalk;
    if (sem) {
      args["callback"] = this;
      sem.AddonCommand(args);
    }
  }

  private getRoleString(myrole: SemTalkRole, sem: IVisioRDFS | undefined): string {
    let role = "";
    if (sem) {
      if (myrole === SemTalkRole.viewer) role = sem.getResStr(ResID.STRVIEWER);
      if (myrole === SemTalkRole.editor) role = sem.getResStr(ResID.STREDITOR);
      if (myrole === SemTalkRole.admin) role = sem.getResStr(ResID.STRADMIN);
      if (myrole === SemTalkRole.metamodel) role = sem.getResStr(ResID.STRMETAMODEL);
      if (myrole === SemTalkRole.publisher) role = sem.getResStr(ResID.STRPUBLISHER);
      return role;
    } else {
      return myrole;
    }
  }
  private getUsername(): string {
    if (this.sprops.context) {
      return this.sprops.context.pageContext.user.displayName;
    }
    if (this.state.mongo &&
      this.state.mongo.usemongo &&
      this.state.mongo.semuserlogin) {
      return this.state.mongo.semuserlogin.user_info.profile.fullname;
    }
    // msalInstance
    if (this.account && this.account.name) {
      return this.account.name;
    }
    // teamsfxss && sharepoint
    if (this.account && this.account.displayName) {
      return this.account.displayName;
    }
    return "";
  }
  private getUserID(): string {
    if (this.sprops.context) {
      return this.sprops.context.pageContext.user.loginName;
    }
    if (this.state.mongo &&
      this.state.mongo.usemongo &&
      this.state.mongo.semuserlogin) {
      return this.state.mongo.semuserlogin.username;
    }
    // msalInstance
    if (this.account && this.account.username) {
      return this.account.username;
    }
    // sharepoint
    if (this.account && this.account.loginName) {
      return this.account.loginName;
    }
    // teamsfxss
    if (this.account && this.account.preferredUserName) {
      return this.account.preferredUserName;
    }
    return "";
  }
  private saveStatus = () => {
    let n = Date.now();
    let mongodb = "";
    let mg = this.state.mongo;
    if (mg.usemongo) {
      // mongodb = mg.dbname + "@" + mg.semmongoserverurl.replace("/api/", "");
      mongodb = mg.dbname + "@" + mg.semmongoconnectiontoken;
    }
    let status: any = {
      filename: this.GetFilename(),
      timeleft: n,
      template: this.state.template,
      mongodb: mongodb,
    };
    setCookie(SemTalkCookie.SemTalkStatus, JSON.stringify(status));
  }

  private onTabClose = (ev: any) => {
    ev.preventDefault();
    this.saveStatus();
    this.autoSave();
    console.debug("Tab Close");
    //  return ev.returnValue = 'Ohne Speichern beenden?'; //moderne browser erlauben keine custom messages mehr
    if (!this.state.mongo.iselectron && !this.state.islocked) {
      if (this.state.semtalk && this.state.semtalk.ismodified) {
        return (ev.returnValue = this.state.semtalk.getResStr(
          ResID.STRLEAVEWITHOUTSAVING
        )); //moderne browser erlauben keine custom messages mehr
      }
    }
    return "";
  }

  public SetFilename = (filename: string, islru: boolean) => {
    console.log("SetFilename", filename);
    this.filename = filename;
    this.electron_filename = "";
    // this.setState({ filename: filename });
    if (islru) {
      let lru = JSON.parse(accessCookie(SemTalkCookie.lru));
      if (!lru) {
        lru = [];
      }
      if (lru.length > 0) {
        if (typeof lru[0] === 'string') {
          lru = lru.map((x: string) => { return { "filename": x }; });
        }
      }
      let lruarray: any[] = lru as any[];
      let index = lruarray.findIndex(x => x["filename"] === filename);
      if (index > -1) {
        lruarray.splice(index, 1);
      }
      lruarray.unshift({ "filename": filename });
      const maxlen = 10;
      if (lruarray.length > maxlen) {
        lruarray.splice(maxlen);
      }
      setCookie(SemTalkCookie.lru, JSON.stringify(lruarray));
    }
  }

  public GetFilename = (): string => {
    // console.log("GetFilename", this.filename);
    return this.filename;
    // this.setState({ filename: filename });
  }

  public SetElectronFilename = (filename: string) => {
    console.log("SetElectronFilename", filename);
    this.filename = "";
    this.electron_filename = filename;
    // this.setState({ filename: filename });
  }

  public GetElectronFilename = (): string => {
    // console.log("GetFilename", this.filename);
    return this.electron_filename;
    // this.setState({ filename: filename });
  }

  // public graph: any;
  private mounted: boolean = false;
  public async componentDidMount() {
    try {
      let connTest = await mgGetItems(this.props.mongo, this.props.mongo.dbname, this.props.mongo.roles);
      if (connTest.length === 0) {
        this.props.mongo.semmongoserverurl = this.props.mongo.semmongoserverurlBackup;
      }
    } catch (_e) {

    }
    try {
      await this.getSettings(this.props.mongo);
    } catch (_e) {
      resetSettings();
    }
    this.mounted = true;
    // await mgBaseTest();
    addCallBack(this);
    // window.addEventListener("keydown", (ev) => { this.keyHandler(ev); });
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      this.onTabClose(ev);
    });
    if (this.props.shared) {
      await this.resumeSessionStatus(true, this.props.mongo, false, false, this.props.role);
      await this.openDocumentCallBack(this.props.model);
      if (this.state.semtalk) {
        await this.loadPage(this.props.page, this.state.semtalk, false);
      }
      if (this.props.shape.length > 0 && this.state.semtalk) {
        let shape = this.state.semtalk.FindShapeByShapeID(this.props.shape);
        if (shape) {
          let o = this.state.semtalk.base.FindObjectByID(shape.objectid);
          if (o) {
            let ndlist = o.Nodes();
            if (ndlist.length > 0) {
              gotoNodeByID(ndlist[0].ID);
            }
            gotoObject(shape.objectid);
            this.setState({ hidePropertyDialog: false });
          }
        }
      }
    } else {
      this.resumeSessionStatus(false, this.props.mongo, this.props.loadtemplate,
        this.props.loadrecent, this.props.role);
    }
  }
  private login = async (semtalk: IVisioRDFS, mongo: IMongoOption, userid: string, password: string,
    role: string) => {
    try {
      const res: any = await mgLogin_with_email_password(mongo,
        mongo.dbname,
        userid,
        password);
      if (res.login_token !== undefined) {
        if (res.login_token.toString().length > 0) {
          let regkey = res.user_info.profile.regkey;
          if (regkey) {
            // let key = MakeKey("ZIT-BB", 100, false, false, false);
            let days = Number(DC(regkey));
            if (days < 0) {
              this.alert(semtalk.getResStr(ResID.STRLoginFailed) + " Your SemTalk License is expired!", MessageBarType.blocked);
              return;
            }
          }
          let rolelist = res.user_info.profile.roles;
          let roles: string[] = [];
          if (rolelist) {
            if (typeof rolelist === "string") {
              roles = rolelist.split(";");
            } else {
              roles = rolelist as string[];
            }
          }
          let isallowed: boolean = true;
          switch (role) {
            case SemTalkRole.admin: {
              isallowed = roles.includes(role);
              break;
            }
            case SemTalkRole.metamodel: {
              isallowed = roles.includes(role) ||
                roles.includes(SemTalkRole.admin);
              break;
            }
            case SemTalkRole.editor: {
              isallowed = roles.includes(role) || roles.includes(SemTalkRole.admin) ||
                roles.includes(SemTalkRole.metamodel);
              break;
            }
            default:
              isallowed = roles.includes(role) ||
                roles.includes(SemTalkRole.admin);
              break;
          }
          if (isallowed) {
            // setCookie(SemTalkCookie.dbname, mongo.dbname);
            // setCookie(SemTalkCookie.repository, repository);
            // setCookie(SemTalkCookie.role, role);
            res["username"] = userid;
            if (res && res.user_info && res.user_info.profile && res.user_info.profile.chatgptkey) {
              let key = res.user_info.profile.chatgptkey;
              if (key.length > 20) {
                setCookie(SemTalkCookie.chatgpt, key);
              }
            }
            await this.reloadFromSettingsPanel();
            this.props.mongo.semuserlogin = res;
            // this.props.mongo.dbname = dbname;
            // this.props.mongo.repository = repository;
            this.props.setToken(res);
            this.props.setcurrToken(res);
          } else {
            // this.alert(semtalk.getResStr(ResID.STRLoginFailed) + " " + semtalk.getResStr(ResID.STRROLENOTALLOWED), MessageBarType.blocked);
            mongo.semuserlogin = null;
            return;
          }
        } else {
          // this.alert(semtalk.getResStr(ResID.STRLoginEmptyLoginToken), MessageBarType.blocked);
          mongo.semuserlogin = null;
          return;
        }
      } else {
        // this.alert(semtalk.getResStr(ResID.STRLoginFailed), MessageBarType.blocked);
        mongo.semuserlogin = null;
        return;
      }
    } catch (err) {
      let msg: string = "unknown";
      if (err && typeof err === "object") {
        const e1: any = err;
        msg = e1["message"];
        if (e1.response && e1.response.status) {
          switch (e1.response.status) {
            case 404: {
              msg = "404: Cannot connect to MongoDB";
              break;
            }
            case 500: {
              msg = e1.response.data;
              if (msg.toLowerCase().startsWith("no such user")) {
                // msg = "Login failed. Database: " + mongo.dbname + " Account: " + userid;
                console.debug("Login failed. Database: " + mongo.dbname + " Account: " + userid);
                mongo.semuserlogin = null;
                msg = "";
              }
              break;
            }
          }
        }
      }
      this.alert(msg, MessageBarType.error);
      return;
    }
  }
  public resumeSessionStatus = async (
    forcenew: boolean,
    mongo: IMongoOption,
    loadTemplate: boolean,
    loadRecent: boolean,
    role: string) => {
    let restored: boolean = false;
    let ischeckedout2me: boolean = this.state.ischeckedout2me;
    let ischeckedout: boolean = this.state.checkedOut;
    const ob = new ObjectBase();
    const o2j = new OB2JSON();
    let readonly: boolean = false;
    let pgid: SemTalkID | null = null;
    let modprop = this.props.model;
    if (modprop.length > 0 && modprop.indexOf(".") < 0) {
      modprop = modprop + ".sdx";
    }

    let gl = accessCookie(SemTalkCookie.guilanguage);
    if (!gl) gl = navigator.languages[1] || navigator.language;
    if (gl && gl.indexOf("-") > 0) {
      gl = gl.substring(0, gl.indexOf("-"));
    }
    let res: IVisioRDFS = new VisioRDFS(null, null);
    res.explorer = new SPExplorer();
    // res.scaleshapes = this.state.shapescale;
    res.init(null, gl);

    if (!this.sprops.context && (this.gprops.usegraph || window.location.hash === '#/tab')) {
      try {
        await this.MS365Login([]);
        console.log("MS365Login succesfull");
      } catch (e) {
        console.log("MS365Login ", e);
      }
    }
    if (this.props.context && !this.account) {
      this.account = this.props.context.pageContext.user;
    }

    if (this.account && mongo.usemongo && mongo.semuserlogin === null) {
      const usemongosso = (accessCookie(SemTalkCookie.usemongosso) === "true");
      if (usemongosso) {
        let pwd = SemTalkBaseConstant.SSOPWD;
        let userid = this.getUserID();
        this.login(res, mongo, userid, pwd, role);
        console.debug("SSO " + userid);
      }
    }



    let template = this.state.template;
    let filename = "";
    if (!forcenew && ((!loadTemplate && !loadRecent) || modprop.length === 0) && !mongo.iselectron) {
      // load autosaved
      let semtalkautosavestring = accessCookie(SemTalkCookie.autosaveSemTalk);
      if (!semtalkautosavestring) {
        semtalkautosavestring = "";
      }
      let sessionstatusstring = accessCookie(SemTalkCookie.SemTalkStatus);
      if (!sessionstatusstring) {
        sessionstatusstring = "";
      }
      let sessionstatus: any = null;
      if (sessionstatusstring) {
        try {
          sessionstatus = JSON.parse(sessionstatusstring);
        } catch (e) {
          this.alert("resumeSessionStatus: cannot parse sessionstatus", MessageBarType.severeWarning);
        }
      }
      if (sessionstatus && sessionstatus["template"] &&
        this.props.template === ""
      ) {
        template = sessionstatus["template"];
      }
      let mg = mongo;
      if (mg.usemongo) {
        // let mongodb = mg.dbname + "@" + mg.semmongoserverurl.replace("/api/", "");
        let mongodb = mg.dbname + "@" + mg.semmongoconnectiontoken;
        if (sessionstatus && sessionstatus["mongodb"] && sessionstatus["mongodb"] !== mongodb) {
          this.alert(res.getResStrListener(ResIDL.STRWRONGWORKSPACE) +
            mg.dbname + res.getResStrListener(ResIDL.STRSTARTINGFROMSCRATCH),
            MessageBarType.severeWarning);
          if (window.confirm(res.getResStr(ResID.STRLEAVEWITHOUTSAVING))) {
            semtalkautosavestring = "";
          } else {
            this.DoCommand(SemTalkOnlineCommand.mgLogin, {});
          }
        }
      }
      // console.debug(semtalkautosavestring.length);
      if (semtalkautosavestring && semtalkautosavestring.length > 50000000) {
        this.alert("Document seem to be very large...", MessageBarType.warning);
        // semtalkautosavestring = "";
      }
      if (semtalkautosavestring && semtalkautosavestring.length > 0) {
        // this.resumeSessionStatus
        if (sessionstatus && sessionstatus["timeleft"] && sessionstatus["filename"]) {
          filename = sessionstatus["filename"];
          document.title = filename;

          let u = this.getUsername();
          let d = new Date(sessionstatus["timeleft"]);
          if (filename.endsWith(".sdx") && !this.state.islocked) {
            this.alert(res.getResStr(ResID.STRHELLO) + " " + u + res.getResStr(ResID.STRRESUME) +
              sessionstatus["filename"] + res.getResStr(ResID.STRRESUMEFROM) + d + "'",
              MessageBarType.info
            );
          }
          if (mongo.usemongo && mongo.semuserlogin && filename.length > 0 && !filename.endsWith(".stx")) {
            try {
              let mres = await mgGetValue(mongo, mongo.dbname, mongo.documents,
                filename, ModelProperty.modified);
              if (mres) {
                let m = Date.parse(mres);
                if (m > sessionstatus["timeleft"]) {
                  let u1 = await mgGetValue(mongo, mongo.dbname, mongo.documents, filename, ModelProperty.modifiedby);
                  // let u = this.getUsername();
                  // if (u1 !== u) {
                  // die datei wurde gespeichert nachdem wir sie zuletzt geschlossen und im localstorage gespeichert haben.
                  //  müssten wir sie dann nicht lieber neu öffnen?
                  readonly = true;
                  document.title = filename + " (RO)";
                  this.alert(filename + res.getResStr(ResID.STRRESUMEMODIFIED) + u1 +
                    res.getResStr(ResID.STRRESUMEMODIFIEDAT) + new Date(m) +
                    res.getResStr(ResID.STRRESUMEMODIFIEDAFTER),
                    MessageBarType.severeWarning
                  );
                  // }
                }
              }
              let checkedoutdate = await mgGetValue(mongo, mongo.dbname, mongo.documents, filename, ModelProperty.checkedoutdate);
              if (checkedoutdate) {
                ischeckedout2me = false;
                let m = Date.parse(checkedoutdate);
                // Müsste man hier nicht berücksichtigen ob die localstorage älter oder jünger als der checkout ist??
                // if (m > sessionstatus["timeleft"]) {
                let u1 = await mgGetValue(mongo, mongo.dbname, mongo.documents, filename, ModelProperty.checkedoutuser);
                let u0 = this.getUsername();
                if (u1 !== u0) {
                  // die datei ist von jemand anderem ausgecheckt
                  this.isreadonly = true;
                  ischeckedout = true;
                  document.title = filename + " (RO)";
                  this.alert(filename + res.getResStr(ResID.STROPENCHECKOUTTO) +
                    u1 + res.getResStr(ResID.STRCHECKEDOUTAT) + new Date(m),
                    MessageBarType.severeWarning
                  );
                } else {
                  // die datei ist von mir ausgecheckt
                  document.title = filename + " (CO)";
                  ischeckedout2me = true;
                }
                // }
              }
            } catch (e) {
              this.alert("resumeSessionStatus mongodb: cannot parse metadata: " + (e as any).message, MessageBarType.error);
            }
            console.debug("Loading: " + this.props.model);
          } else {
            if (this.gprops.usegraph && this.gprops.graphClient) {
              if (filename.length > 0 && !filename.endsWith(".stx")) {
                try {
                  let meta: any = await this.metadocumentfromgraph(res, filename);
                  if (meta && meta.lastModifiedDateTime) {
                    let m = Date.parse(meta.lastModifiedDateTime);
                    if (m > sessionstatus["timeleft"]) {
                      let u1 = meta.lastModifiedBy!.user!.displayName;
                      readonly = true;
                      document.title = filename + " (RO)";
                      this.alert(filename + res.getResStr(ResID.STRRESUMEMODIFIED) + u1 +
                        res.getResStr(ResID.STRRESUMEMODIFIEDAT) + new Date(m) +
                        res.getResStr(ResID.STRRESUMEMODIFIEDAFTER),
                        MessageBarType.severeWarning
                      );
                    }
                  }
                  if (meta && meta.publication) {
                    if (meta.publication.level === "checkout" || meta.publication.checkedOutBy) {
                      let u1 = meta.publication.checkedOutBy!.user!.displayName;
                      let u0 = this.getUsername();
                      if (u1 !== u0) {
                        this.isreadonly = true;
                        document.title = filename + " (RO)";
                        this.alert(filename + res.getResStr(ResID.STROPENCHECKOUTTO) +
                          u1,
                          MessageBarType.severeWarning
                        );
                        ischeckedout = true;
                      } else {
                        // die datei ist von mir ausgecheckt
                        document.title = filename + " (CO)";
                        ischeckedout2me = true;
                      }
                      // }
                    }
                  }
                } catch (e) {
                }
              }
            } else {
              if (this.state.spCheckInOut && this.sprops.context) {
                if (filename.length > 0 && !filename.endsWith(".stx")) {
                  try {
                    let meta: any = await this.metadocumentfromsharepoint(res, filename);
                    if (meta.CheckoutUserId !== null) {
                      let user: any = await res.explorer.getUserById(
                        this.sprops.context,
                        this.sprops.site,
                        meta.CheckoutUserId
                      );
                      let u0 = this.getUsername();
                      // user.UserPrincipalName !== this.sprops.context.pageContext.user.loginName
                      if (user !== u0) {
                        document.title = filename + " (RO)";
                        ischeckedout = true;
                        this.isreadonly = true;
                      } else {
                        document.title = filename + " (CO)";
                        ischeckedout2me = true;
                        // isopen = true;
                        // console.debug("Same Name: " + user.UserPrincipalName);
                      }
                    }
                  } catch (e) {
                  }
                }
              }
            }
          }
        }
        console.debug("restoring");
        let s: any = {};
        try {
          s = JSON.parse(semtalkautosavestring);
          if (s) {
            pgid = s.currentpage;
            if (!readonly) {
              readonly = s.readonly;
            }
            // this.checkouttoken_tempid = s.tempId;
            filename = s.modname;
          } else {
            this.alert("resumeSessionStatus mongodb: cannot parse model.",
              MessageBarType.severeWarning);
          }
          //  console.debug(s);
        } catch (e) {
          this.alert("resumeSessionStatus mongodb: cannot parse model: " + (e as any).message,
            MessageBarType.severeWarning);
        }
        try {
          if (s["diags"]) {
            o2j.LoadJSON(ob, s);
            restored = true;
          }
        } catch (e) {
          this.alert("resumeSessionStatus mongodb: cannot load model:" + (e as any).message,
            MessageBarType.severeWarning);
        }
      }
    }
    if (!restored) {
      let txt = "";
      let s2: any = {};
      if (mongo.usemongo && mongo.semuserlogin) {
        if (modprop.length > 0) {
          filename = modprop;
          let checkedoutdate = await mgGetValue(mongo, mongo.dbname, mongo.documents, filename, "CheckedOutDate");
          if (checkedoutdate) {
            ischeckedout2me = false;
            let m = Date.parse(checkedoutdate);
            // Müsste man hier nicht berücksichtigen ob die localstorage älter oder jünger als der checkout ist??
            // if (m > sessionstatus["timeleft"]) {
            let u1 = await mgGetValue(mongo, mongo.dbname, mongo.documents, filename, "CheckedOutUser");
            let u = this.getUsername();
            if (u1 !== u) {
              // die datei ist von jemand anderem ausgecheckt
              this.isreadonly = true;
              document.title = filename + " (RO)";
              ischeckedout = true;
              this.alert(filename + res.getResStr(ResID.STROPENCHECKOUTTO) + u1 +
                res.getResStr(ResID.STRCHECKEDOUTAT) + new Date(m),
                MessageBarType.severeWarning
              );
            } else {
              // die datei ist von mir ausgecheckt
              document.title = filename + " (CO)";
              ischeckedout2me = true;
            }
            // }
          }
          let docs = mongo.documents;
          if (mongo.templates.length > 0 && modprop.endsWith(".stx")) {
            docs = mongo.templates;
          }
          let res1 = await mgGetDocument(mongo, docs, modprop);
          if (res1) {
            s2 = res1;
          }
          console.debug("Loading: " + this.props.model);
        } else {
          let res1 = await mgGetDocument(mongo, mongo.templates, template);
          if (res1) {
            s2 = res1;
          }
          console.debug("Loading: " + template);
        }
      }
      if ((!s2 || s2["ObjectType"] === undefined) && this.sprops.context) {
        let url = "";
        if (modprop.length > 0) {
          url = this.sprops.librarysite + "/" + this.sprops.library + "/" + this.props.model;
        } else {
          url = this.sprops.site + "/" + this.sprops.templates + "/" + template;
        }
        try {
          txt = await res.explorer.getTXT(this.sprops.context, url);
        } catch (e) {
          this.alert("resumeSessionStatus sharepoint: cannot download model:" + url + " " + (e as any).message,
            MessageBarType.severeWarning);
        }
        try {
          if (txt !== null) s2 = JSON.parse(txt);
        } catch (e) {
          this.alert("resumeSessionStatus sharepoint: cannot parse model:" + url + " " + (e as any).message,
            MessageBarType.severeWarning);
        }
        console.debug("Loading: " + url);
      }
      if ((!s2 || s2["ObjectType"] === undefined) &&
        !this.sprops.context && this.gprops.usegraph && this.gprops.graphClient) {
        if (modprop.length > 0 && !modprop.endsWith(".stx")) {
          try {
            s2 = await this.loaddocumentfromgraph(res, modprop);
            ischeckedout = s2.ischeckedout;
            ischeckedout2me = s2.ischeckedout2me;
          } catch (e) {
            this.alert("resumeSessionStatus graph: cannot load model:" + modprop + " " + (e as any).message,
              MessageBarType.severeWarning);
          }
        }
      }

      if (!s2 || s2["ObjectType"] === undefined) {
        if (modprop && modprop.length > 0 && !modprop.endsWith(".stx")) {
          this.alert(res.getResStrListener(ResIDL.STRCOULDNOTOPEN) + modprop,
            MessageBarType.warning
          );
        }
        let t2 = template;
        if (this.state.mongo.usemongo) {
          try {
            s2 = await mgGetDocument(this.state.mongo, this.state.mongo.templates, template);
          } catch (e) {
            this.alert("resumeSessionStatus mongo: cannot load template:" + template + " " + (e as any).message,
              MessageBarType.severeWarning);
          }

        } else {
          // Hier wird auch nicht nach templates in SharePoint oder Graph gesucht
          try {
            let response = await fetch("./Templates/" + t2);
            let txt1: string = await response.text();
            s2 = JSON.parse(txt1);
          } catch (e) {
            this.alert("resumeSessionStatus app: cannot load template: ./Templates/" + template + " " + (e as any).message,
              MessageBarType.severeWarning);
            console.debug(e);
            s2 = require("./bpmn20-2016.json");
          }
        }
        console.debug("Loading: " + template);
      }
      try {
        if (s2 && s2["diags"]) {
          o2j.LoadJSON(ob, s2);
        }
      } catch (e) {
        this.alert("resumeSessionStatus app: cannot load objectbase." + (e as any).message,
          MessageBarType.severeWarning);
      }
    }
    setTimeout(() => {
      this.alert("", MessageBarType.info);
    }, 20000);

    let pageprop = this.props.page;
    if (pageprop.length > 0) {
      let pg = ob.FindDiagramByName(pageprop);
      if (pg) {
        pgid = pg.ID;
      }
    }

    ob.ObjectName = ob.GetModelAttribute(ModelAttribute.modname);

    if (filename) {
      this.SetFilename(filename, false);
      if (this.isreadonly) {
        document.title = filename + " (RO)";
      } else {
        if (ischeckedout2me) {
          document.title = filename + " (CO)";
        } else {
          document.title = filename.replace(".stx", "");
        }
      }
    }
    try {
      await this.loadBase(ob, restored, pgid, readonly, res.explorer, filename);
    } catch (e) {
      this.alert("resumeSessionStatus: loadBase." + (e as any).message,
        MessageBarType.severeWarning);
    }
    if (loadTemplate) {
      if (this.state.semtalk) {
        try {
          await this.CreateDocument(this.state.semtalk, this.props.model, true);
        } catch (e) {
          this.alert("resumeSessionStatus: CreateDocument." + (e as any).message,
            MessageBarType.severeWarning);
        }
      }
    }
    if (true) this.initElectron(mongo);
    this.reloadDocument();
    let r: SemTalkRole = SemTalkRole.editor;
    if (role in SemTalkRole) {
      r = role as SemTalkRole;
    }

    this.setState({
      isLoading: "", role: r,
      ischeckedout2me: ischeckedout2me,
      checkedOut: ischeckedout
    });
  }

  public componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
  }
  public static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {
      errormsg: error.msg,
      msgbartype: MessageBarType.error,
    };
  }
  private initElectron = async (mongo: IMongoOption) => {
    let w: any = window;
    if (mongo.iselectron && w.api) {
      this.setState({ isadmin: true });
      // w.api.receive("fromMain", (data: any) => {
      //   console.log(`Received ${data} from main process`);
      // });
      // w.api.receive("onClickCallback", (data: any) => {
      //   this.DoCommand(data["command"], data["args"]);
      // });
      // w.api.receive("onClickState", (data: any) => {
      //   let s = {};
      //   let state: string = data["state"];
      //   s[state] = true;
      //   this.setState(s);
      // });
      // w.api.receive("onClickAddOn", (data: any) => {
      //   this.DoCommand(SemTalkOnlineCommand.AddonCommand, { "command": data["command"] });
      // });
      // w.api.receive("openDocument", (data: any) => {
      //   let fname: string = data["filename"];
      //   if (fname && data["data"]) {
      //     if (fname.endsWith(".sdx")) {
      //       let s = JSON.parse(data["data"]);
      //       setCookie(SemTalkCookie.autosaveSemTalk, "");
      //       this.loadDocumentFromJSON(data["filename"], s);
      //       this.filename = data["filename"];
      //     }
      //   }
      // });
      this.loadElectronSettings(mongo);
      let data = await w.api.getfiledata();
      if (data) {
        let fname: string = data["filename"];
        // alert("openwith: " + fname);
        if (fname && data["data"]) {
          if (fname.endsWith(".sdx")) {
            let s: SemTalkJSON = JSON.parse(data["data"]);
            setCookie(SemTalkCookie.autosaveSemTalk, "");
            await this.loadDocumentFromJSON(data["filename"], s, true);
          } else if (fname.endsWith(".bpmn")) {
          }
        }
      }
    }
  }

  public decode = (s: string): string => {
    if (this.lzutf8) {
      return VisioRDFS.decodelzutf8(s);
    }
    return VisioRDFS.decode(s);
  }

  public encode = (s: string): string => {
    if (this.lzutf8) {
      return VisioRDFS.encodelzutf8(s);
    }
    return VisioRDFS.encode(s);
  }

  private getmxGraphXML = (diag: ISemTalkDiagram): string => {
    let xml = diag.ObjectBase.GetModelAttribute(
      diag.GetValue(SemTalkBaseConstant.SLMXGAttribute)
    );
    if (xml === undefined) xml = "";
    let decoded = this.decode(xml);
    return decoded;
  }

  private loadBase = async (ob: ObjectBase, restored: boolean, pgid: SemTalkID | null,
    _readonly: boolean, spinterface: ISPExplorer, _filename: string): Promise<void> => {
    let sem = this.state.semtalk as IVisioRDFS;
    if (!sem) {
      sem = new VisioRDFS(ob, this.undoManger);
    }
    // const sem = new VisioRDFS(ob, this.undoManger);
    // sem.scaleshapes = this.state.shapescale;
    this.autosave = false;
    sem.IsAutoSave = false;
    sem.explorer = spinterface;
    sem.showhyperlinkmarker = this.showhyperlinkmarker;

    // if (filename !== "") {
    //   await this.checkReadOnly(sem, filename);
    sem.readonly = this.isreadonly;
    // }

    // if ((window as any).semtalk === undefined) {
    (window as any).semtalk = sem;
    // }

    ob.User = this.getUsername();

    sem.init(ob, this.state.guilanguage);
    let noev = sem.noevents;
    sem.noevents = true;
    if (!restored) {
      if (ob.GetModelAttribute(ModelAttribute.patch) !== "false") {
        await sem.patchSubTask(ob, this.state.resizeall);
      }
    }
    await sem.initSubTask(ob);
    if (!this.state.underlineRefinements) {
      ob.SetModelAttribute(ModelAttribute.underlineRefinements, "0");
    } else {
      ob.SetModelAttribute(ModelAttribute.underlineRefinements, "1");
    }

    sem.site = this.sprops.site;
    ob.SetModelAttribute(ModelAttribute.currentnsp, this.state.language);
    let mxencoding = ob.GetModelAttribute(ModelAttribute.mxencoding);
    this.lzutf8 = mxencoding === "1";

    // alert(this.props.language);
    if (this.state.language === SemTalkLanguage.German) {
      ob.SetModelAttribute(ModelAttribute.forder, SemTalkComposeOrder.NounVerb);
    } else {
      ob.SetModelAttribute(ModelAttribute.forder, SemTalkComposeOrder.VerbNoun);
    }
    sem.noevents = noev;

    let template = await sem.initStencils(
      ob,
      sem,
      this.state.guilanguage,
      this.sprops,
      this.state.bpmnrules,
      this.state.role,
      this.loadStencil
    );

    let diag: ISemTalkDiagram | null = null;
    // let mx = SemTalkBaseConstant.SLMXGPagePrefix + "0";
    // if (pgid && pgid !== null) {
    //   mx = SemTalkBaseConstant.SLMXGPagePrefix + pgid;
    // }
    // for (const d of ob.AllDiagrams()) {
    //   if (d.GetValue(SemTalkBaseConstant.SLMXGAttribute) === mx) {
    //     diag = d;
    //     break;
    //   }
    // }
    if (diag === null && pgid) {
      diag = ob.FindDiagramByID(pgid);
      // if (diag) {
      //   mx = SemTalkBaseConstant.SLMXGPagePrefix + diag.ID;
      // }
    }
    if (diag === null) {
      let diags = ob.AllDiagrams();
      if (diags.length > 0) {
        diag = diags[0];
      }
      if (this.props.model.length > 0 && !this.props.shared && this.props.page === '') {
        for (let d of diags) {
          if (d.GetValue(SemTalkBaseConstant.SLUserNumber) === "1") {
            diag = d;
            break;
          }
        }
      }
      if (diag === undefined) {
        diag = null;
      }
    }
    if (diag !== null) {
      let dclass = diag.ClassOf();
      this.stencil = sem.getStencil(dclass);
      if (diag.ObjectName === "http://www.semtalk.com/mxg#Page-1") {
        let syn = diag.FindSynonym(this.state.language);
        if (!syn) {
          let nn = diag.ClassOf().ObjectCaption + "-1";
          syn = diag.MakeSynonym(nn, this.state.language);
        }

        if (syn && syn.Name === "Page-1") {
          let nn = diag.ClassOf().ObjectCaption + "-1";
          diag.MakeSynonym(nn, this.state.language);
        }
        if (syn && syn.Name !== "Page-1" && ob.FindDiagram(syn.Name) === null) {
          let noev0 = sem.noevents;
          sem.noevents = true;
          diag.RenameObject(syn.Name);
          sem.noevents = noev0;
        }
      }

      // setCookie(SemTalkCookie.autosaveSemTalk, "");

      if (diag !== null) {
        // let xml = ob.GetModelAttribute(diag.GetValue(SemTalkBaseConstant.SLMXGAttribute));
        // let decoded = this.decode(xml);
        let decoded = this.getmxGraphXML(diag);

        let xml = this.patchEPC(decoded);

        if (xml.length === 0)
          xml =
            '<mxGraphModel><root><Diagram href="http://www.jgraph.com/" id="0" label="' +
            diag.ObjectCaption +
            '" diagid="' +
            diag.ID +
            '"></Diagram><Layer id="1" label="Default Layer"><mxCell parent="0"></mxCell></Layer></root></mxGraphModel>';

        sem.page = diag;
        // sem.masters=this.state.svgshapes;
        sem.masters = this.stencil;

        if (this.state.autorefreshreferences)
          refreshPage(sem, this.gprops.graphClient, this.state.mongo);
        let cntpages = sem.base.AllDiagrams().length;
        sem.resetRibbon(false);
        let rb = sem.getRibbon(undefined, undefined, this.state.islocked);

        // xml = sem.tseditor.getGraphXml();
        this.setState({
          xmlgraph: xml,
          diag: diag,
          semtalk: sem,
          stencil: this.stencil,
          // xmlshapes: this.xmlshapes,
          cntpages: cntpages,
          // diaglist: lis,
          template: template,
          // filename: filename,
          isLoading: sem.getResStrListener(ResIDL.STRDLGCMDRE) + "...",
          ribbon: rb.ribbon,
          toolbar: rb.toolbar,
        });
        sem.updateDiaglist();
        if (!restored) {
          sem.initPage(sem, diag);
        } else {
          if (sem.tseditor) {
            let ishoriz = sem.tseditor.isHorizontal();
            sem.horizontal = ishoriz;
          }
        }
        sem.patchHyperlink();
        gotoDocument(diag.ID);
        // this.SetProgressIndicator(sem.getResStrListener(ResIDL.STRDLGCMDRE) + "...");
        setTimeout(() => {
          sem.tseditor.refresh();
          if (sem.page && sem.page.Contents().length < 50) {
            sem.redrawLight(false);
          }
          this.SetProgressIndicator("");
        }, 10);
        // if (diag.Contents().length > 0) {
        //   this.zoomActual();
        // }
      }
    }
    // sem.hasVector = this.hasvector;
    // this.initVectors();
    this.autosave = true;
    sem.IsAutoSave = true;
    sem.ismodified = false;
    if (document.title.endsWith(" *")) {
      document.title = document.title.replace(" *", "");
    }
    if (sem) {
      sem.autoSave("", "");
    }
    sem.Initialized(this);
  }

  private selectDataLang = (lang: SemTalkLanguage, bredraw: boolean): void => {
    let sem = this.state.semtalk;
    if (lang && sem) {
      let ob = sem.base;
      let langcaption: string = lang;
      let langinst = ob.FindInstance(lang);
      if (langinst) {
        let guilang = Code2Language(sem.guilanguage);
        langcaption = langinst.ID2NameNspLan(guilang);
      }
      if (sem && sem.page && sem.page.Contents().length > 50) {
        this.SetProgressIndicator(sem.getResStrListener(ResIDL.STRUPDATINGLANG) + ": " + langcaption);
      }
      setTimeout(() => {
        if (sem) {
          sem.base.SetModelAttribute(ModelAttribute.currentnsp, lang);
          sem.base.SetModelAttribute(
            SemTalkBaseConstant.CookiePrefix + SemTalkCookie.language,
            lang
          );
          if (bredraw) {
            sem.redrawLight(true);
          }
          setCookie(SemTalkCookie.language, lang);
          this.setState({ language: lang });
          this.SetProgressIndicator("");
        }
      }, 10);
    }
  }

  private selectGuiLang = async (lang: SemTalkLanguageCode) => {
    if (lang) {
      let sem = this.state.semtalk;
      if (sem) {
        sem.guilanguage = lang;
      }
      this.setState({ guilanguage: lang });
    }
  }

  private insertSwimlane = (cell: any) => {
    let sem = this.state.semtalk;
    if (sem && cell) {
      if (!sem.tseditor.isHorizontal()) {
        sem.insertSwimlane(cell, 100, 100, 600, 200);
      } else {
        sem.insertSwimlane(cell, 100, 100, 200, 600);
      }
      this.autoSave();
    }
  }

  private toggleDirection = (cell: any) => {
    let sem = this.state.semtalk;
    if (sem) {
      sem.toggleDirection(cell);
      this.autoSave();
    }
  }

  // private releaseCheckoutToken = () => {
  //   if (!this.state.spCheckInOut && this.state.semtalk) {
  //     if (this.checkouttoken_templist && this.checkouttoken_tempid > 0) {
  //       try {
  //         let tdd = this.checkouttoken_tempid;
  //         this.checkouttoken_tempid = 0;
  //         if (this.sprops.context) {
  //           this.state.semtalk.explorer.deleteListItem(
  //             this.sprops.context,
  //             this.sprops.site,
  //             this.checkouttoken_templist,
  //             tdd
  //           );
  //         }
  //       } catch (e) {
  //         console.debug("releaseCheckoutToken:" + e);
  //       }
  //     }
  //   }
  // }

  // private checkReadOnly = async (
  //   sem: IVisioRDFS,
  //   filename: string
  // ): Promise<boolean> => {

  //   // let sem = this.state.semtalk as IVisioRDFS;
  //   let isopen = false;
  //   let isreadonly = this.isreadonly;

  //   if (this.state.spCheckInOut && this.sprops.context) {
  //     let query =
  //       "$select=FileLeafRef,ID,CheckoutUserId&$filter=FileLeafRef eq '" +
  //       filename +
  //       "'&$top=1";
  //     let lany: any = await sem.explorer.getListData(
  //       this.sprops.context,
  //       this.sprops.librarysite,
  //       this.sprops.library,
  //       query
  //     );
  //     let lil: any[] = lany.value;

  //     if (lil !== undefined && lil.length > 0) {
  //       if (lil[0].CheckoutUserId !== null) {
  //         let user: any = await sem.explorer.getUserById(
  //           this.sprops.context,
  //           this.sprops.site,
  //           lil[0].CheckoutUserId
  //         );
  //         if (
  //           user !==
  //           this.sprops.context.pageContext.user.loginName
  //           //  user.UserPrincipalName !==
  //           //   this.sprops.context.pageContext.user.loginName
  //         ) {
  //           document.title = filename + " (RO)";
  //           isreadonly = true;
  //         } else {
  //           document.title = filename + " (CO)";
  //           isopen = true;
  //           console.debug("Same Name: " + user.UserPrincipalName);
  //         }
  //       }
  //       //}))
  //     }
  //   }
  //   this.isreadonly = isreadonly;
  //   return isopen;
  // }

  private metadocumentfromsharepoint = async (
    sem: IVisioRDFS,
    filename: string
  ): Promise<any> => {

    if (this.state.spCheckInOut && this.sprops.context) {
      let query =
        "$select=FileLeafRef,ID,CheckoutUserId&$filter=FileLeafRef eq '" +
        filename +
        "'&$top=1";
      let lany: any = await sem.explorer.getListData(
        this.sprops.context,
        this.sprops.librarysite,
        this.sprops.library,
        query
      );
      let lil: any[] = lany.value;

      if (lil !== undefined && lil.length > 0) {
        return lil[0];
      }
    }
    return null;
  }

  private upload = async (handler: (event: any) => void, multiple: boolean, accept: string) => {
    let sem = this.state.semtalk as IVisioRDFS;
    const inp: any = document.createElement("input");
    inp.setAttribute("type", `File`);
    inp.setAttribute("className", `hidden`);
    inp.setAttribute("multiple", multiple ? "true" : "false");
    if (sem.template === "bpmn") {
      inp.setAttribute("accept", accept + ", .bpmn");
    } else {
      inp.setAttribute("accept", accept);
    }
    inp.onchange = (e: any) => {
      handler(e);
    };
    document.body.appendChild(inp);
    inp.click();
    inp.parentNode.removeChild(inp);
  }

  private importOWLFile = async (
    handler: (event: any) => void,
    multiple: boolean
  ) => {
    const inp: any = document.createElement("input");
    inp.setAttribute("type", `File`);
    inp.setAttribute("className", `hidden`);
    inp.setAttribute("multiple", multiple ? "true" : "false");
    inp.setAttribute("accept", ".owl");
    inp.onchange = (e: any) => {
      handler(e);
    };
    document.body.appendChild(inp);
    inp.click();
    inp.parentNode.removeChild(inp);
  }

  private onImportOWLUploadChangeHandler = (event: any) => {
    let f = event.target.files[0];
    let fr = new FileReader();
    fr.readAsText(f);
    let filename = f.name;
    let sem = this.state.semtalk as IVisioRDFS;
    fr.onload = async (_event) => {
      const s = fr.result;
      if (s && filename.endsWith(".owl")) {
        let resstr = sem.getResStr(ResID.STRIMPORTINGOWL) + filename;
        this.setState({ isLoading: resstr });
        let ob = new ObjectBase();
        OWLImport(this, ob, s.toString());
        setTimeout(() => {
          this.setState({ isLoading: "" });
        }, 5000);
        this.selectedexternalob = ob;
        this.setState({ "hideOWLImport": false });
        return;
      }
    };
  }

  private loadExternalFormat = async (filename: string, s: string) => {
    let sem = this.state.semtalk as IVisioRDFS;
    this.setState({
      isLoading: sem.getResStrListener(ResIDL.STRDLGCMDOP) + " " + filename,
      errormsg: "",
      nosuccess: false,
    });
    await sem.loadExternalFormat(this, s.toString(), this.CreateDocument);
    this.setState({
      isLoading: "",
      errormsg: "",
      nosuccess: false,
    });

    this.SetFilename(filename, false);
    document.title = filename;
  }

  private onOpenUploadChangeHandler = (event: any) => {
    let f = event.target.files[0];
    // console.log(f);
    let fr = new FileReader();
    fr.readAsText(f);
    let filename = f.name;
    fr.onload = async (_event) => {
      //Both "event.target.result" and "fr.result" contain the file's contents (because "event.target" is === "fr")
      const s = fr.result;
      // let sem = this.state.semtalk as IVisioRDFS;
      if (s && !filename.endsWith(".sdx")) {
        this.loadExternalFormat(filename, s.toString());
        return;
      }
      if (s) {
        let json = JSON.parse(s.toString());
        await this.loadDocumentFromJSON(filename, json, false);
      }
    };
    let sem = this.state.semtalk as IVisioRDFS;
    let resstr = sem.getResStr(ResID.STRUPLOADINGCOPY) + filename;
    // "Uploading a copy from your local machine: " + filename;
    this.alert(resstr, MessageBarType.info);
    setTimeout(() => {
      this.alert("", MessageBarType.info);
    }, 5000);
    document.title = filename;
  }
  private uploadXMLFile = async (handler: (event: any) => void,
  ) => {
    const inp: any = document.createElement('input');
    inp.setAttribute('type', `File`);
    inp.setAttribute('className', `hidden`);
    inp.setAttribute('multiple', false);
    inp.setAttribute('accept', ".xml");
    inp.onchange = (e: any) => {
      handler(e);
    };
    document.body.appendChild(inp);
    inp.click();
    inp.parentNode.removeChild(inp);
  }
  private onUploadXMLHandler = (event: any) => {
    let f = event.target.files[0];
    let filename = f.name;
    let fr = new FileReader();
    fr.readAsText(f);
    fr.onload = async (_event) => {
      //Both "event.target.result" and "fr.result" contain the file's contents (because "event.target" is === "fr")
      const s = fr.result;
      if (this.state.semtalk && s) {
        let sem = this.state.semtalk as IVisioRDFS;
        let resstr = sem.getResStr(ResID.STRIMPORTINGSEMTALKXML) + filename;
        this.setState({ isLoading: resstr });
        // sem.IsAutoSave = false;
        let tb2 = new ObjectBase();
        const o2x = new OB2XML();
        tb2._loading = true;
        o2x.LoadXML(tb2, s as string);
        tb2._loading = false;
        // const o2j = new OB2JSON();
        // o2j.Merge(sem.base, tb2, this.encode, this.decode, f,[]);
        setTimeout(() => {
          this.setState({ isLoading: "" });
        }, 5000);
        this.selectedexternalob = tb2;
        this.setState({ "hideOWLImport": false });
        // sem.IsAutoSave = true;
        // console.debug(ob0);
      }
    };
  }

  private code = (handler: (event: any) => void): void => {
    if (this.state.semtalk) {
      const inp: any = document.createElement('input');
      inp.setAttribute('type', `File`);
      inp.setAttribute('className', `hidden`);
      inp.setAttribute('multiple', false);
      inp.setAttribute('accept', ".sjs");
      inp.onchange = (e: any) => {
        handler(e);
      };
      document.body.appendChild(inp);
      inp.click();
      inp.parentNode.removeChild(inp);

    }
  }
  private copy = () => {
    let sem = this.state.semtalk as IVisioRDFS;
    let sel = sem.tseditor.getSelectionCells();
    sem.tseditor.Copy(sel);
  }
  private cut = () => {
    let sem = this.state.semtalk as IVisioRDFS;
    let sel = sem.tseditor.getSelectionCells();
    sem.tseditor.Cut(sel);
  }
  private paste = () => {
    let sem = this.state.semtalk as IVisioRDFS;
    sem.tseditor.Paste(this.click_x, this.click_y);
  }
  private onUploadCodeHandler = (event: any) => {
    let f = event.target.files[0];
    let fr = new FileReader();
    fr.readAsText(f);
    fr.onload = async (_event) => {
      const s = fr.result;
      if (this.state.semtalk && s) {
        let sem = this.state.semtalk as IVisioRDFS;
        // eslint-disable-next-line no-eval
        eval(s as string);
        if ((window as any).tmpsemtalkpatchpatch) {
          (window as any).tmpsemtalkpatchpatch(sem.base);
          delete (window as any).tmpsemtalkpatchpatch;
        }
      }
    };
  }

  private downloadWordFile = async () => {
    let sem = this.state.semtalk as IVisioRDFS;
    if (sem) {
      ExportWord(sem, this, this.GetFilename());
    }
  }

  private downloadHTMLFile = async () => {
    let sem = this.state.semtalk as IVisioRDFS;
    if (sem) {
      ExportHTML(sem);
    }
  }

  private downloadJPGFile = async () => {
    let sem = this.state.semtalk as IVisioRDFS;
    if (sem) {
      ExportJPG(sem);
    }
  }

  private downloadPNGFile = async () => {
    let sem = this.state.semtalk as IVisioRDFS;
    if (sem) {
      ExportPNG(sem);
    }
  }

  private downloadPDFFile = async () => {
    let sem = this.state.semtalk as IVisioRDFS;
    if (sem) {
      let format = accessCookie(SemTalkCookie.printpageformat);
      let landscape = accessCookie(SemTalkCookie.printlandscape);
      // ExportPNG(sem);
      let pages = sem.base.AllDiagrams();
      ExportPDF(pages, this, sem, this.GetFilename().replace(".sdx", ".pdf").replace(".stx", ".pdf"),
        format, landscape, true, false, 1, 1);
    }
  }

  private updateSVG = async (ob: IObjectBase) => {
    let sem = this.state.semtalk;
    if (sem) {
      for (let m of ob.AllModelAttributes()) {
        if (m.startsWith(SemTalkBaseConstant.SLSVGPagePrefix)) {
          ob.SetModelAttribute(m, undefined);
        }
      }
      for (let d of ob.AllDiagrams()) {
        await this.loadPage(d.ID, sem, false);
        // this.gotoPage(d.ID);
        ensureLabelWidth(sem.graph as mxGraph);
        let svg = sem.tseditor.exportSVG(sem.graph as mxGraph);
        // let encoded = this.encode(svg);
        let encoded = VisioRDFS.encodelzutf8(svg);
        // let decoded = VisioRDFS.decode(encoded);
        // console.debug(svg===decoded);
        let modattr: string =
          SemTalkBaseConstant.SLSVGPagePrefix + String(d.ID);
        ob.SetModelAttribute(modattr, encoded);
        ob.SetModelAttribute(
          SemTalkBaseConstant.SLSVGPagePrefix + SemTalkBaseConstant.SLSVGPagePostfix + String(d.ID),
          d.ObjectName
        );
        d.SetValue(SemTalkBaseConstant.SLSVGAttribute, modattr);
      }
    }
  }

  private downloadXMLFile = async () => {
    const sem = this.state.semtalk;
    if (sem) {
      let o2j = new OB2JSON();
      const objson = o2j.SaveJSON(sem.base);
      const ob = new ObjectBase();
      o2j.LoadJSON(ob, objson);
      let filename: string = this.GetFilename();
      if (filename.length === 0) {
        filename = "export";
      } else {
        filename = filename.replace(".sdx", "");
      }
      ob.SetModelAttribute("vsd", filename + ".vsd");

      await patchXML(this, sem, ob);

      filename += ".xml";
      const o2x = new OB2XML();
      let xml = o2x.SaveXML(ob, this.encode, this.decode);
      const url = window.URL.createObjectURL(new Blob([xml]));
      const link: any = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  }

  private downloadOWLFile = async () => {
    const sem = this.state.semtalk;
    if (sem) {
      const ob = sem.base;
      let filename: string = this.GetFilename();
      if (filename.length === 0) {
        filename = "export";
      } else {
        filename = filename.replace(".sdx", "");
      }
      filename = filename.toLocaleLowerCase();
      ob.SetModelAttribute("vsd", filename + ".vsd");
      let blob = '<?xml version="1.0" encoding="UTF-8"?>';
      // let blob = '';
      let xd = OWLExport(ob, "http://semtalk.com/ontology/" + filename);
      filename += ".owl";
      blob = blob + xd.documentElement.outerHTML;
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link: any = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  }

  private downloadJSONLDFile = async () => {
    const sem = this.state.semtalk;
    if (sem) {
      const ob = sem.base;
      let filename: string = this.GetFilename();
      if (filename.length === 0) {
        filename = "export";
      } else {
        filename = filename.replace(".sdx", "");
      }
      ob.SetModelAttribute("vsd", filename + ".vsd");
      let xd = exportJSONLD(
        ob,
        "http://semtalk.com/linkeddata/" + filename + "/a"
      );
      let blob = JSON.stringify(xd);
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link: any = document.createElement("a");
      link.href = url;
      filename += ".js";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  }

  private publishXMLFile = () => {
    if (true) this.publishDocuments();
  }

  public isApprovable = (): boolean => {
    const sem = this.state.semtalk;
    let filename = this.GetFilename();
    if (sem) {
      if (
        !sem.BeforeDocumentSave() ||
        filename.length === 0 ||
        filename.endsWith(".stx")
      ) {
        return false;
      }
    }
    const mongo = this.state.mongo;
    if (mongo.usemongo && !this.gprops.graphClient) {
      if (!mongo.approved || mongo.documents === mongo.approved) {
        return false;
      }
    }
    if (!mongo.usemongo && this.sprops.context && !this.gprops.graphClient) {
      if (
        !this.sprops.approved ||
        this.sprops.library === this.sprops.approved
      ) {
        return false;
      }
    }
    if (
      !mongo.usemongo &&
      !this.sprops.context &&
      this.gprops.usegraph &&
      this.gprops.graphClient
    ) {
      if (
        !this.sprops.approved ||
        this.sprops.library === this.sprops.approved
      ) {
        return false;
      }
    }
    return true;
  }
  private approveDocument = async () => {
    const sem = this.state.semtalk;
    let filename = this.GetFilename();
    if (sem) {
      if (!sem.BeforeDocumentSave() || filename.length === 0 || filename.endsWith(".stx")) {
        this.alert(sem.getResStr(ResID.STRERRAPPROVAL), MessageBarType.blocked);
        return;
      }
      if (!this.isApprovable()) {
        this.alert(sem.getResStr(ResID.STRERRAPPROVAL2), MessageBarType.blocked);

        return;
      }
      this.alert(filename + " " + sem.getResStr(ResID.STRINAPPROVAL), MessageBarType.info);
      const ob = sem.base;
      this.beginTransaction("approveDocument");
      const d = new Date();
      let u = this.getUsername();
      ob.SetModelAttribute(ModelProperty.approved, d);
      ob.SetModelAttribute(ModelProperty.approvedby, u);

      ob.SetModelAttribute(ModelAttribute.modname, filename);
      ob.ObjectName = filename;
      this.saveCurrentGraph();
      const o2j = new OB2JSON();
      let body = o2j.SaveJSON(ob);
      if (sem.page) body.currentpage = sem.page.ID;
      let resstr: string = "";

      const mongo = this.state.mongo;
      if (mongo.usemongo && !this.gprops.usegraph) {
        mgSetValue(mongo, mongo.dbname, mongo.documents, filename, ModelProperty.approved, d);
        mgSetValue(mongo, mongo.dbname, mongo.documents, filename, ModelProperty.approvedby, u);

        let lib = mongo.approved;
        let res: any = await mgSaveDocumentAs(mongo, lib, filename, body);
        if (res.status !== 201) {
          this.alert(
            res.status + " " + JSON.stringify(res.data),
            MessageBarType.error
          );
        } else {
          resstr =
            res.status + " " + filename + " " + sem.getResStr(ResID.STRSUCCAPPROVEDLIB) + lib;
          // this.alert(res.status + " " + JSON.stringify(res.data), MessageBarType.success);
        }
      }
      if (!mongo.usemongo && !this.gprops.usegraph && this.sprops.context) {
        let lib = this.sprops.approved;

        const url3 =
          this.sprops.librarysite +
          "/_api/Web/Lists/getByTitle('" +
          lib +
          "')/RootFolder/Files/Add(url='" +
          filename +
          "', overwrite=true)";
        let s = JSON.stringify(body);
        let res = await sem.explorer.postTXT(this.sprops.context, url3, s);
        if (res.status !== 201) {
          this.alert(
            res.status + " " + JSON.stringify(res.data),
            MessageBarType.error
          );
        } else {
          resstr =
            res.status + " " + filename + " " + sem.getResStr(ResID.STRSUCCAPPROVEDLIB) + this.sprops.library;
        }
      }
      if (!mongo.usemongo && this.gprops.usegraph && this.gprops.graphClient) {
        let driveid = "";
        let drives = await sem.explorer.fetchGraphItems(
          this.gprops.graphClient,
          this.gprops.sharepointlibrarysite + "drives"
        );
        for (let drive of drives) {
          if (drive.name === this.sprops.approved) {
            driveid = drive.id;
          }
        }
        if (driveid === "") return;
        let url = "/drives/" + driveid + "/root:/" + filename + ":/content";
        let data = JSON.stringify(body);
        // besser wäre: spexplorer.postGraphItem
        await this.gprops.graphClient.api(url).put(data);
      }

      // console.debug(txt2);
      if (sem) {
        sem.readonly = false;
      }
      // this.loadDocument(filename);
      this.endTransaction("approveDocument");
      this.alert(resstr, MessageBarType.success);
      setTimeout(() => {
        this.alert("", MessageBarType.success);
      }, 5000);
      if (sem) {
        sem.ismodified = false;
        if (document.title.endsWith(" *")) {
          document.title = document.title.replace(" *", "");
        }
      }
      this.resetFileDialog(filename);
    }
  }

  public setModelCookie(cookie: SemTalkCookie, val: any): void {
    if (this.state.semtalk) {
      let s = val;
      try {
        s = val.toString();
      } catch { }
      this.state.semtalk.base.SetModelAttribute(
        SemTalkBaseConstant.CookiePrefix + cookie,
        s
      );
    }
  }
  public removeModelCookie(cookie: SemTalkCookie): void {
    if (this.state.semtalk) {
      this.state.semtalk.base.DeleteModelAttribute(
        SemTalkBaseConstant.CookiePrefix + cookie
      );
    }
  }

  private loadModelSettings = async () => {
    //  "SemTalkCookie" ist ein key in dem Settings object
    let defaultsettings = await loadDefaultSettings(
      this.props.mongo,
      this.sprops,
      "SemTalkCookie"
    );
    let getValue = (cookie: SemTalkCookie, v: any): any => {
      if (this.state.semtalk) {
        let mv = this.state.semtalk.base.GetModelAttribute(
          SemTalkBaseConstant.CookiePrefix + cookie
        );
        if (mv !== undefined) {
          return mv;
        }
      }
      let val: any = undefined;
      let localvalue = accessCookie(cookie);
      if (localvalue) {
        val = localvalue;
      } else {
        val = this.GetDefaultValue(defaultsettings, cookie, v);
      }
      if (cookie === SemTalkCookie.printlandscape || cookie === SemTalkCookie.printpageformat) {
        this.setModelCookie(cookie, val);
      }
      return val;
    };

    let headerfooterfields = [];
    let fields = getValue(SemTalkCookie.headerfooterfields, undefined);
    if (fields) {
      headerfooterfields = JSON.parse(fields);
    }
    let showheaderfooterfields =
      getValue(SemTalkCookie.showheaderfooterfields, "false") === "true";
    let printlandscape =
      getValue(SemTalkCookie.printlandscape, "false") === "true";
    let printpageformat = getValue(SemTalkCookie.printpageformat, PrintPageFormat.custom);

    this.setState({
      headerfooterfields: headerfooterfields,
      showheaderfooterfields: showheaderfooterfields,
      printpageformat: printpageformat,
      printlandscape: printlandscape,
    });
  }

  private download = async (filename: string, filetype: string) => {
    let sem = this.state.semtalk;
    if (!filename || filename === "") {
      filename = this.GetFilename();
    }
    if (sem) {
      let blob: string = "";
      if (
        !filename.endsWith(".sdx") ||
        (filetype !== "" && filetype !== ".sdx")
      ) {
        filename = filename.replace(".sdx", filetype);
        blob = await sem.saveExternalFormat(this, filename, true);
      }
      if (
        blob.length === 0 ||
        filename.endsWith(".sdx") ||
        filename.endsWith(".stx")
      ) {
        if (filename === this.state.template || filename === "") {
          filename = sem.base.AllDiagrams()[0].ObjectCaption;
        }
        //  if (!filename) filename = "filename";
        if (!filename.endsWith(".sdx")) {
          filename = filename + ".sdx";
        }
        if (filename.endsWith(".stx")) {
          filename = filename.replace(".stx", ".sdx");
        }
        if (!filename.endsWith(".sdx")) {
          filename = filename + ".sdx";
        }
        this.saveCurrentGraph();
        const o2j = new OB2JSON();
        let body = o2j.SaveJSON(sem.base);
        if (sem.page) body.currentpage = sem.page.ID;
        if (sem.tempId) body.tempId = sem.tempId;
        blob = JSON.stringify(body);
      }

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link: any = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      // let resstr = "We try to download a copy to your local machine. You continue with: " + this.GetFilename();
      let resstr = sem.getResStr(ResID.STRDOWNLOADINGCOPY) + filename;
      this.alert(resstr, MessageBarType.info);
      setTimeout(() => {
        this.alert("", MessageBarType.info);
      }, 10000);
    }
  }

  private async mergeDocumentFromJSON(filename: string, s: any) {
    let sem = this.state.semtalk as IVisioRDFS;
    const ob = new ObjectBase();
    const o2j = new OB2JSON();
    o2j.LoadJSON(ob, s);

    sem.noevents = true;
    if (ob.GetModelAttribute(ModelAttribute.patch) !== "false") {
      await sem.patchSubTask(ob, this.state.resizeall);
    }
    o2j.Merge(sem.base, ob, this.encode, this.decode, filename, []);
    // console.debug(ob0);
    sem.noevents = false;
    sem.updateDiaglist();
    let diags = sem.base.AllDiagrams();
    let pg = diags[diags.length - 1];
    sem.tseditor.enableDragDrop();
    if (pg.ClassOf().ObjectName === sem.base.GetModelAttribute(Process_ElementName.SLProc)) {
      sem.tseditor.enableSwimlanes(sem.horizontal, false);
    }
    await this.loadPage(pg.ID, sem, false);
    this.setState({ cntpages: diags.length });
  }
  public LoadDocument = async (filename: string, usecache: boolean) => {
    // this.autosave = false;
    this.SetFilename(filename, true);
    this.ResetModelProperties();

    if (this.isreadonly === false) {
      //  this.checkInDocument();
      // this.releaseCheckoutToken();
    }
    if (!this.state.islocked) {
      resetHistory();
    }

    let sem = this.state.semtalk as IVisioRDFS;
    let s: SemTalkJSON = {};
    this.setState({
      isLoading: sem.getResStrListener(ResIDL.STRDLGCMDOP) + ": " + filename,
      errormsg: "",
      nosuccess: false,
    });
    const usemongo = this.state.mongo.usemongo;
    // let ischeckedout2me = false;
    if (usemongo) {
      s = await this.loaddocumentfrommongo(sem, filename, usecache);
    }
    if ((!s || s["ObjectType"] === undefined) && this.sprops.context) {
      s = await this.loaddocumentfromsharepoint(sem, filename);
    }
    if ((!s || s["ObjectType"] === undefined) &&
      !this.sprops.context && this.gprops.usegraph && this.gprops.graphClient) {
      s = await this.loaddocumentfromgraph(sem, filename);
    }
    if (s && Object.keys(s).length > 0 && s["ObjectType"] !== undefined) {
      await this.loadDocumentFromJSON(filename, s, false);
    }
    if (!s || Object.keys(s).length === 0) {
      this.setState({
        isLoading: "",
        ischeckedout2me: false,
        errormsg: sem.getResStrListener(ResIDL.STRCOULDNOTOPEN) + filename,
        nosuccess: true,
      });
    }
    settingsChanged();
  }

  private loaddocumentfrommongo = async (sem: IVisioRDFS, filename: string, usecache: boolean): Promise<any> => {
    let s: any = {};
    let mongo = this.state.mongo;
    // let ischeckedout2me = this.state.ischeckedout2me;
    try {
      if (mongo.usemongo && mongo.semuserlogin) {
        let res: any = null;
        if (usecache) {
          res = await unzipdoc(mongo, mongo.documents, filename);
        }
        if (!res) {
          res = await mgGetDocument(mongo, mongo.documents, filename);
        }
        if (res && Object.keys(res).length > 0) {
          s = res;
          let ischeckedout = false;
          let ischeckedout2me = false;
          let cres = await mgGetValue(mongo, mongo.dbname, mongo.documents, filename, ModelProperty.checkedoutdate);
          if (cres) {
            let m = Date.parse(cres);
            let u1 = await mgGetValue(mongo, mongo.dbname, mongo.documents, filename, ModelProperty.checkedoutuser);
            let u = this.getUsername();
            if (u1 !== u) {
              this.isreadonly = true;
              document.title = filename + " (RO)";
              this.alert(filename + sem.getResStr(ResID.STROPENCHECKOUTTO) + u1 +
                sem.getResStr(ResID.STRCHECKEDOUTAT) +
                new Date(m),
                MessageBarType.severeWarning
              );
              ischeckedout = true;
            } else {
              document.title = filename + " (CO)";
              ischeckedout2me = true;
            }
          }
          s.ischeckedout = ischeckedout;
          s.ischeckedout2me = ischeckedout2me;
          this.setState({ checkedOut: ischeckedout, ischeckedout2me: ischeckedout2me });

        }
        console.debug("Loading: " + filename);
      }
    } catch (e) {
      this.setState({
        isLoading: "",
        errormsg: "",
        nosuccess: true,
      });
      this.alert((e as any).message, MessageBarType.error);
    }
    return s;
  }

  private loaddocumentfromsharepoint = async (sem: IVisioRDFS, filename: string): Promise<any> => {
    let txt = "";
    const url: string =
      this.sprops.librarysite + "/" + this.sprops.library + "/" + filename;
    try {
      txt = await sem.explorer.getTXT(this.sprops.context, url);
      let s = JSON.parse(txt);
      console.debug("Loading: " + url);

      try {
        let ischeckedout = false;
        let ischeckedout2me = false;
        let meta: any = await this.metadocumentfromsharepoint(sem, filename);
        if (meta.CheckoutUserId !== null) {
          let user: any = await sem.explorer.getUserById(
            this.sprops.context,
            this.sprops.site,
            meta.CheckoutUserId
          );
          // user.UserPrincipalName !== this.sprops.context.pageContext.user.loginName
          let u0 = this.getUsername();
          // user.UserPrincipalName !== this.sprops.context.pageContext.user.loginName
          if (user !== u0) {
            document.title = filename + " (RO)";
            ischeckedout = true;
            this.isreadonly = true;
          } else {
            document.title = filename + " (CO)";
            ischeckedout2me = true;
            // isopen = true;
            // console.debug("Same Name: " + user.UserPrincipalName);
          }
        }
        s.ischeckedout = ischeckedout;
        s.ischeckedout2me = ischeckedout2me;
        this.setState({ checkedOut: ischeckedout, ischeckedout2me: ischeckedout2me });
      } catch (e) {
      }

      return s;
      //  console.debug(s);
    } catch (e) {
      console.debug("loaddocument. cannot parse doc to JSON!!" + e);
    }
    return {};
  }
  private loaddocumentfromgraph = async (sem: IVisioRDFS, filename: string): Promise<any> => {
    let driveid = "";
    let drives = await sem.explorer.fetchGraphItems(
      this.gprops.graphClient,
      this.gprops.sharepointlibrarysite + "drives"
    );
    let lib = this.gprops.sharepointlibrary;
    for (let drive of drives) {
      if (drive.name === lib) {
        driveid = drive.id;
      }
    }
    if (driveid === "") return;
    let url = "/drives/" + driveid + "/root:/" + filename;
    try {
      let driveitem = await sem.explorer.fetchGraphItem(
        this.gprops.graphClient,
        url
      );
      // console.debug(driveitem["@microsoft.graph.downloadUrl"]);
      let response = await fetch(driveitem["@microsoft.graph.downloadUrl"]);
      let s = await response.json();
      console.debug("Loading: " + url);
      let meta = await this.metadocumentfromgraph(sem, filename);
      let ischeckedout = false;
      let ischeckedout2me = false;
      if (meta && meta.publication) {
        if (meta.publication.level === "checkout" || meta.publication.checkedOutBy) {
          let u1 = meta.publication.checkedOutBy!.user!.displayName;
          let u0 = this.getUsername();
          if (u1 !== u0) {
            this.isreadonly = true;
            document.title = filename + " (RO)";
            this.alert(filename + sem.getResStr(ResID.STROPENCHECKOUTTO) +
              u1,
              MessageBarType.severeWarning
            );
            ischeckedout = true;
          } else {
            // die datei ist von mir ausgecheckt
            document.title = filename + " (CO)";
            ischeckedout2me = true;
          }
        }
      }
      s.ischeckedout = ischeckedout;
      s.ischeckedout2me = ischeckedout2me;
      this.setState({ checkedOut: ischeckedout, ischeckedout2me: ischeckedout2me });
      return s;
    } catch (e) {
      this.alert("loaddocumentfromgraph graph: cannot load json model:" + url + " " + (e as any).message,
        MessageBarType.severeWarning);
      console.debug("loaddocument. cannot parse doc to JSON!!" + e);
    }
    return {};
  }
  private metadocumentfromgraph = async (sem: IVisioRDFS, filename: string): Promise<any> => {
    let driveid = "";
    let drives = await sem.explorer.fetchGraphItems(
      this.gprops.graphClient,
      this.gprops.sharepointlibrarysite + "drives"
    );
    let lib = this.gprops.sharepointlibrary;
    for (let drive of drives) {
      if (drive.name === lib) {
        driveid = drive.id;
      }
    }
    if (driveid === "") return;
    let url = "/drives/" + driveid + "/root:/" + filename + "?select=lastModifiedDateTime,lastModifiedBy,publication";
    try {
      let driveitem = await sem.explorer.fetchGraphItem(this.gprops.graphClient, url);
      return driveitem;
    } catch (_e) {
      return null;
    }
  }
  private deletefromgraph = async (sem: IVisioRDFS, filename: string): Promise<any> => {
    let driveid = "";
    let drives = await sem.explorer.fetchGraphItems(
      this.gprops.graphClient,
      this.gprops.sharepointlibrarysite + "drives"
    );
    let lib = this.gprops.sharepointlibrary;
    for (let drive of drives) {
      if (drive.name === lib) {
        driveid = drive.id;
      }
    }
    if (driveid === "") return;
    let url = "/drives/" + driveid + "/root:/" + filename;
    try {
      let res = await sem.explorer.deleteGraphItemNoGet(this.gprops.graphClient, url);
      // let res = await sem.explorer.fetchGraphItem(this.gprops.graphClient, url);
      return res;
    } catch (_e) {
      return null;
    }
  }
  private reloadDocument = async (): Promise<void> => {
    if (this.state.autorefresh < 5000) {
      return;
    }
    let filename = this.GetFilename();
    if (filename !== "") {
      const mongo = this.state.mongo;
      let loaded = this.state.loaded;
      if (filename !== this.state.watching) {
        loaded = Date.now();
        this.setState({
          watching: filename,
          loaded: loaded,
          errormsg: "",
          nosuccess: false,
        });
      }
      if (mongo.usemongo && mongo.semuserlogin) {
        let mres = await mgGetValue(mongo, mongo.dbname, mongo.documents, filename, ModelProperty.modified);
        let dmod = Date.parse(mres);
        if (dmod > loaded + this.state.autorefresh) {
          this.alert(
            "Document is modified by another user. Reloading...",
            MessageBarType.severeWarning
          );
          await this.LoadDocument(filename, true);
        } else {
          const sem = this.state.semtalk;
          if (sem && sem.ismodified) {
            await this.saveDocument();
          }
        }
        let mres0 = await mgGetValue(mongo, mongo.dbname, mongo.documents, filename, ModelProperty.modified);
        let dmod0 = Date.parse(mres0);
        this.setState({ loaded: dmod0 });
      }
    }
    setInterval(() => this.reloadDocument(), this.state.autorefresh);
  }

  public loadDocumentFromJSON = async (
    filename: string,
    s: SemTalkJSON,
    iselectron: boolean
  ): Promise<void> => {
    let sem = this.state.semtalk as IVisioRDFS;

    if (!this.state.islocked) this.beginTransaction("loadDocument");
    const ob = new ObjectBase();
    const o2j = new OB2JSON();
    o2j.LoadJSON(ob, s);

    let m = ob.GetModelAttribute(ModelAttribute.modname);
    if (m.length === 0) {
      ob.SetModelAttribute(ModelAttribute.modname, filename);
      ob.ObjectName = filename;
    }

    sem.init(ob, this.state.guilanguage);

    // Dies ist die fragliche Stelle soll initSubTask immer beim laden gerufen werden?
    // 26.1.22: Ja weil sonst die Overridables nicht auf die aktuelle Vorlage gesetzt werden
    await sem.initSubTask(ob);

    ob.SetModelAttribute(ModelAttribute.currentnsp, this.state.language);
    if (this.state.language === SemTalkLanguage.German) {
      ob.SetModelAttribute(ModelAttribute.forder, SemTalkComposeOrder.NounVerb);
    } else {
      ob.SetModelAttribute(ModelAttribute.forder, "1");
    }
    let template = await sem.initStencils(
      ob,
      sem,
      this.state.guilanguage,
      this.sprops,
      this.state.bpmnrules,
      this.state.role,
      this.loadStencil
    );

    let diag: ISemTalkDiagram | null = null;

    if (diag === null) {
      let diags = ob.AllDiagrams();
      for (let d of diags) {
        if (!d.Visible) {
          d.Delete();
        }
      }
      diags = ob.AllDiagrams();
      if (diag === null) {
        for (let d of diags) {
          if (d.GetValue(SemTalkBaseConstant.SLUserNumber) === "1") {
            diag = d;
            break;
          }
        }
      }
      if (diag === null && s.currentpage) {
        diag = ob.FindDiagramByID(s.currentpage);
      }
      if (diag === null) {
        if (diags.length > 0) {
          diag = diags[0];
        }
      }
    }
    if (diag !== null) {
      let dclass = diag.ClassOf();
      this.stencil = sem.getStencil(dclass);
      sem.page = diag;
      let mxencoding = ob.GetModelAttribute(ModelAttribute.mxencoding);
      this.lzutf8 = mxencoding === "1";
      if (!this.lzutf8) {
        let diags = [...ob.AllDiagrams()];
        for (let d of diags) {
          let d2 = this.getmxGraphXML(d);
          if (d2) {
            if (sem.tseditor) {
              if (ob.GetModelAttribute(ModelAttribute.patch) !== "false") {
                await sem.patchSubTask(ob, this.state.resizeall);
              }
              d2 = this.patchMasters(d2, sem.tseditor, ob);
            }
            let mxg = d.GetValue(SemTalkBaseConstant.SLMXGAttribute);
            ob.SetModelAttribute(mxg, VisioRDFS.encodelzutf8(d2));
          } else {
            d.Delete();
          }
        }
      }
      ob.SetModelAttribute(ModelAttribute.mxencoding, "1");
      this.lzutf8 = true;

      // const g = ob.GetModelAttribute(diag.GetValue(SemTalkBaseConstant.SLMXGAttribute));
      // let decoded = this.decode(g);
      let decoded = this.getmxGraphXML(diag);

      // setCookie(SemTalkCookie.autosaveSemTalk, "");

      let xml = this.patchEPC(decoded);

      sem.tseditor.resetEditor(
        xml,
        diag.ObjectCaption,
        diag.ID,
        sem.horizontal,
        false
      );
      sem.setBundles();
      let ishoriz = sem.tseditor.isHorizontal();
      sem.horizontal = ishoriz;
      if (sem.horizontal !== sem.tseditor.isGraphHorizontal()) {
        sem.tseditor.toggleDirection(null);
      }
      if (sem.tseditor.isHorizontal() !== sem.tseditor.isGraphHorizontal()) {
        sem.tseditor.toggleDirection(null);
      }

      // sem.loadDiag(diag.ID);
      sem.masters = this.stencil;

      if (this.state.autorefreshreferences)
        refreshPage(sem, this.gprops.graphClient, this.state.mongo);
      // sem.EnsureSwimlaneContent();

      sem.readonly = this.isreadonly;
      sem.resetRibbon(false);
      let rb = sem.getRibbon(undefined, undefined, this.state.islocked);

      // let lis: { text: string, key: string }[] = [];
      // for (const dt of sem.base.AllDiagrams()) {
      //   lis.push({ text: dt.ObjectCaption, key: dt.ID });
      // }
      let cntpages = sem.base.AllDiagrams().length;
      if (iselectron) {
        this.SetElectronFilename(filename);
        sem.base.SetModelAttribute("sdx", filename);
      } else {
        this.SetFilename(filename, false);
      }
      xml = sem.tseditor.getGraphXml();
      this.setState({
        //   semtalk: sem,
        // filename: filename,
        xmlgraph: xml,
        diag: diag,
        stencil: this.stencil,
        cntpages: cntpages,
        // diaglist: lis,
        template: template,
        isLoading: "",
        ribbon: rb.ribbon,
        toolbar: rb.toolbar,
        // xmlshapes: this.xmlshapes
      });
      gotoDocument(diag.ID);

      if (sem.page && sem.page.Contents().length < 50) {
        sem.redrawLight(false);
      }
      sem.updateDiaglist();

      sem.patchHyperlink();

      this.selectedImages = [];

      if (!this.state.islocked) this.endTransaction("loadDocument");
      if (sem) {
        sem.ismodified = false;
      }

      if (this.isreadonly) {
        document.title = filename + " (RO)";
      } else {
        if (this.state.ischeckedout2me) {
          document.title = filename + " (CO)";
        } else {
          document.title = filename;
        }
        if (sem) {
          sem.autoSave("", "");
        }
      }
    }
  }


  public CreateDocument = async (sem: IVisioRDFS, template: string, initpage: boolean): Promise<void> => {
    // this.autosave = false;
    if (template === "") {
      if (this.props.template) {
        template = this.props.template;
      } else {
        template = this.state.template;
      }
    }
    this.beginTransaction("createDocument");
    const ob = new ObjectBase();
    ob.SetModelAttribute(ModelAttribute.mxencoding, "1");
    this.lzutf8 = true;

    this.ResetModelProperties();
    resetHistory();

    let s: any = {};
    let mongo = this.state.mongo;
    if (mongo.usemongo && mongo.semuserlogin) {
      let res = await mgGetDocument(mongo, mongo.templates, template);
      if (res) {
        s = res;
      }
    } else {
    }
    if ((!s || s["ObjectType"] === undefined) && this.sprops.context) {
      const url: string =
        this.sprops.site + "/" + this.sprops.templates + "/" + template;
      try {
        let txt = await sem.explorer.getTXT(this.sprops.context, url);
        s = JSON.parse(txt);
        // console.debug(s);
      } catch (e) {
        console.debug(e);
      }
      console.debug("Loading: " + url);
    }
    // Templates über Graph wird z.Z. nicht gesucht...

    if (!s || s["ObjectType"] === undefined) {
      let t2 = template;
      try {
        let response = await fetch("./Templates/" + t2);
        let txt: string = await response.text();
        s = JSON.parse(txt);
      } catch (e) {
        console.debug(e);
      }
    }

    const o2j = new OB2JSON();
    o2j.LoadJSON(ob, s);
    // sem.hasVector = this.hasvector;
    // this.initVectors();

    ob.User = this.getUsername();
    sem.init(ob, this.state.guilanguage);
    if (ob.GetModelAttribute(ModelAttribute.patch) !== "false") {
      await sem.patchSubTask(ob, this.state.resizeall);
    }
    await sem.initSubTask(ob);
    let noev = sem.noevents;
    sem.noevents = true;
    ob.SetModelAttribute(ModelAttribute.currentnsp, this.state.language);
    if (this.state.language === SemTalkLanguage.German) {
      ob.SetModelAttribute(ModelAttribute.forder, SemTalkComposeOrder.NounVerb);
    } else {
      ob.SetModelAttribute(ModelAttribute.forder, SemTalkComposeOrder.VerbNoun);
    }
    sem.noevents = noev;

    await sem.initStencils(ob, sem, this.state.guilanguage, this.sprops, this.state.bpmnrules,
      this.state.role, this.loadStencil);

    let diag: ISemTalkDiagram | null = null;
    let diags = ob.AllDiagrams();
    if (diags.length > 0) {
      diag = diags[0];
      if (diag) {
        let diag2 = (diag.ClassOf() as ISemTalkDiagramType).MakeInstance();
        sem.noevents = true;
        let modattr = SemTalkBaseConstant.SLMXGPagePrefix + diag2.ID;
        ob.SetModelAttribute(
          modattr,
          ob.GetModelAttribute(SemTalkBaseConstant.SLMXGPagePrefix + diag.ID)
        );
        // Ich sehe nicht wozu das sinnvoll ist...
        //  ob.SetModelAttribute(
        //   SemTalkBaseConstant.SLSVGPagePrefix + SemTalkBaseConstant.SLSVGPagePostfix + String(diag2.ID),
        //   diag2.ObjectName
        // );
        diag2.SetValue(SemTalkBaseConstant.SLMXGAttribute, modattr);
        ob.DeleteModelAttribute(SemTalkBaseConstant.SLMXGPagePrefix + diag.ID);
        ob.DeleteModelAttribute(
          SemTalkBaseConstant.SLMXGPagePrefix + SemTalkBaseConstant.SLSVGPagePostfix + diag.ID
        );
        sem.noevents = false;
        diag.Delete();
        diag = diag2;
        let dclass = diag.ClassOf();
        this.stencil = sem.getStencil(dclass);
        // if (diag.ObjectName === "http://www.semtalk.com/mxg#Page-1" || diag.ObjectName === "http://www.semtalk.com/mxg#FirstPage") {
        //   let nn = diag.ClassOf().ID2NameNsp() + "-1";
        //   diag.MakeSynonym(nn, this.state.language);
        // }

        sem.page = diag;
        // const g = ob.GetModelAttribute(diag.GetValue(SemTalkBaseConstant.SLMXGAttribute));
        // let decoded = this.decode(g);
        setCookie(SemTalkCookie.autosaveSemTalk, "");

        let decoded = this.getmxGraphXML(diag);
        let xml = decoded; // this.patchEPC(decoded);

        if (sem.tseditor) {
          sem.tseditor.resetEditor(decoded, diag.ObjectCaption, diag.ID, sem.horizontal, true);
          // sem.setBundles();
        }
        sem.masters = this.stencil;

        let cntpages = sem.base.AllDiagrams().length;
        if (diag && initpage) {
          sem.initPage(sem, diag);
        }
        sem.readonly = false;
        sem.resetRibbon(false);
        let rb = sem.getRibbon(undefined, undefined, this.state.islocked);
        this.SetFilename(template, false);
        xml = sem.tseditor.getGraphXml();
        this.setState({
          semtalk: sem,
          // filename: template,
          xmlgraph: xml,
          diag: diag,
          stencil: this.stencil,
          cntpages: cntpages,
          template: template,
          ribbon: rb.ribbon,
          toolbar: rb.toolbar,
        });
        gotoDocument(diag.ID);
        sem.updateDiaglist();
      }
    }
    this.endTransaction("createDocument");
    sem.Initialized(this);

    document.title = template.replace(".stx", "");
  }

  private deleteDocument = async () => {
    const sem = this.state.semtalk;
    if (sem) {
      let title: string = sem.getResStrListener(ResIDL.STRDLGCMDDE).replace("&", "");
      // if (!this.state.mongo.usemongo && !this.sprops.context) {
      //   return;
      // }
      this.setState({
        hideFileOpen: false,
        isopen: false,
        issaveas: false,
        isnew: false,
        isimport: false,
        ismerge: false,
        isdelete: true,
        spexplorercaption: title,
      });
    }
  }

  private versionControl = async () => {
    const sem = this.state.semtalk;
    if (sem) {
      let title: string = sem.getResStrListener(ResIDL.STRVERSIONCONTROL);
      this.setState({
        hideVersionControl: false,
        spexplorercaption: title,
      });
    }
  }

  private closeDocument = async () => {
    const sem = this.state.semtalk;
    if (sem) {
      if (sem.ismodified && !this.state.islocked) {
        if (!window.confirm(sem.getResStr(ResID.STRLEAVEWITHOUTSAVING))) {
          return;
        }
      }
      this.HidePlanner();
      if (this.props.template !== "") {
        setCookie(SemTalkCookie.autosaveSemTalk, "");
        this.CreateDocument(sem, this.props.template, true);
      } else {
        setCookie(SemTalkCookie.autosaveSemTalk, "");
        this.CreateDocument(sem, this.state.template, true);
      }
    }
  }

  public gotoPage = (diagid: SemTalkID): void => {
    this.autoSave();
    gotoDocument(diagid);
  }

  public componentWillUnmount() {
    removeCallBack(this);
    this.mounted = false;
  }
  public handleEvent = async (m: any): Promise<void> => {
    var mstr = JSON.stringify(m);
    await this.eventListener({ data: mstr });
  }

  private eventListener = async (e: any) => {
    if (!this.mounted) {
      return;
    }
    let md: any;
    try {
      md = JSON.parse(e.data);
      var mtype = md.type;
    } catch (e) {
      return;
    }
    switch (mtype) {
      case SemTalkNavigationEvent.gotoDocument: {
        if (this.state.loading) {
          return;
        }
        if (this.state.diag === null || md.diagid !== this.state.diag.ID) {
          // this.resetState();
          if (this.state.semtalk) {
            addDocumentToHistory(md.modelname, md.diagid);
            this.autoSave();
            await this.loadPage(md.diagid, this.state.semtalk, false);
            let diag = this.state.semtalk.base.FindDiagramByID(md.diagid);
            if (diag) {
              this.gotoStartEvent(diag);
            }

          }
        }
        break;
      }
      case SemTalkNavigationEvent.gotoNode: {
        if (this.state.loading) {
          return;
        }
        if (this.state.semtalk) {
          if (this.state.semtalk.page && this.state.semtalk.page.ID &&
            this.state.semtalk.page.ID !== md.diagid) {
            this.autoSave();
            await this.loadPage(md.diagid, this.state.semtalk, false);
          }
          this.state.semtalk.getMessage(e);
        }
        break;
      }
      case SemTalkNavigationEvent.alert: {
        this.alert(md.msg, md.type);
        break;
      }
    }
  }

  private loadStencil = async (
    template: string,
    url: string,
    spinterface: ISPExplorer
  ): Promise<SemTalkStencil> => {
    if (this.state.mongo.usemongo && this.state.mongo.semuserlogin) {
      let r: any = await mgGetDocument(
        this.state.mongo,
        this.state.mongo.stencils,
        template
      );
      // if (r === null) return [];
      if (Object.keys(r).length > 0) {
        let s = r;
        return s["masters"];
      }
    }
    if (this.sprops.context) {
      // const url: string = this.props.site + "/Support/" + this.properties.stencil + "-shapes.stx";
      let txt = await spinterface.getTXT(this.sprops.context, url);
      if (txt === null) {
        return [];
      }
      try {
        let r: any = JSON.parse(txt);
        // await mgSaveDocument(this.defaulturl, "Templates", template, r);
        // if (r === null) return [];
        return r["masters"];
        //  this.svgshapes = this.svgshapes["masters"];
      } catch (e) {
        console.debug(e);
        return [];
      }
    }
    try {
      let response = await fetch("./Templates/" + template);
      let txt: string = await response.text();
      let r = JSON.parse(txt);
      if (r === null) return [];
      return r["masters"];
    } catch (e) {
      console.debug(e);
    }
    return [];
  }

  private patchEPC = (xml: string): string => {
    return xml;
  }

  public GetShapeStyle = (
    key: string,
    shapeType: SemTalkShapeType
  ): { style: string; isedge: boolean } => {
    let sem = this.state.semtalk;
    if (sem) {
      return sem.GetShapeStyle(key, shapeType);
    } else {
      return { style: "Circle", isedge: false };
    }
  }

  private patchMasters = (
    xml: string,
    _tseditor: ITSEditor,
    ob: IObjectBase
  ): string => {
    if (this.stencil !== undefined) {
      // this.stencil.forEach((item) => {
      for (let itemid in this.stencil) {
        let item = this.stencil[itemid];
        let name: string = item.name;
        if (name === "Start Event") name = "Event";
        let key: string = item.key;
        let shapetype = item.type;
        //     // let key: string = item.key;
        //     let logo: string = item.logo;

        let s1: string = 'style="' + name + '"';
        // let s1a: string = 'style="' + key + '"';
        // let s2: string = 'shapeName="' + item.name + '"; shapeKey="' + item.key + '"; style="' + tseditor.getShapeStyle(name, shapetype)["style"] + '"';
        let s2: string =
          'style="' + this.GetShapeStyle(key, shapetype)["style"] + '"';
        let sysclass = ob.FindSystemClass(name);
        if (sysclass && sysclass.Style.length > 0) {
          s2 = 'style="' + sysclass.Style + '"';
        }
        let s1a: string = 'style="' + name + '.[0-9]+"';
        //     let s1b: string = 'style="' + name.replace(" ", "") + '"';
        //     let s1c: string = 'style="' + name.replace(" ", "") + '.[0-9]+"';
        //     let s2: string = 'style=SemTalkStyleAttribute.shape + "=image;html=1;whiteSpace=wrap;image=' + logo + '"';
        if (xml && name) {
          if (s1 !== s2) {
            xml = xml.replace(new RegExp(s1, "g"), s2);
          }
          xml = xml.replace(new RegExp(s1a, "g"), s2);
        }
        // if (s1a !== s2) {
        //   xml = xml.replace(new RegExp(s1, 'g'), s2);
        // }
        //       xml = xml.replace(new RegExp(s1a, 'g'), s2);
        //       xml = xml.replace(new RegExp(s1b, 'g'), s2);
        //       xml = xml.replace(new RegExp(s1c, 'g'), s2);
        // }
      }

      let s1e: string = 'style="' + "DynamicConnector" + '"';
      let s3e = "";
      xml = xml.replace(new RegExp(s1e, "g"), s3e);
      let s1d: string = 'style="' + "DynamischerVerbinder" + '"';
      xml = xml.replace(new RegExp(s1d, "g"), s3e);
      let sdse: string = 'style="' + "Data Source" + '"';
      xml = xml.replace(new RegExp(sdse, "g"), "style='shape=datastore'");

      xml = xml.replace(/[\r\n]+/g, "");
      const oParser = new DOMParser();
      const doc = oParser.parseFromString(xml, "application/xml");
      const oSerializer = new XMLSerializer();
      xml = oSerializer.serializeToString(doc);
    }
    return xml;
  }

  public loadPage = async (
    oid: SemTalkID,
    semtalk: IVisioRDFS,
    init: boolean
  ): Promise<void> => {
    if (semtalk) {
      this.setState({ loading: true });
      // semtalk.autoSave("","loadPage");

      const ob = semtalk.base;
      let diag = ob.FindDiagramByID(oid) as ISemTalkDiagram;
      let dclass = diag.ClassOf();
      this.stencil = semtalk.getStencil(dclass);

      semtalk.masters = this.stencil;
      if (semtalk.tseditor) {
        // semtalk.tseditor.configShapes(semtalk.tseditor.graph, this.stencil, this.xmlshapes);
        semtalk.tseditor.configShapes(semtalk.tseditor.graph, this.stencil);
        if (semtalk.tseditor.sidebar) {
          semtalk.tseditor.sidebar.LoadStencil(this.stencil);
        }
      }

      this.autosave = false;
      semtalk.IsAutoSave = false;
      let decoded = this.getmxGraphXML(diag);
      let xml = this.patchEPC(decoded);

      if (semtalk.tseditor) {
        semtalk.page = diag;
        semtalk.tseditor.resetEditor(
          xml,
          diag.ObjectCaption,
          diag.ID,
          semtalk.horizontal,
          false
        );
        semtalk.setBundles();
      }
      // semtalk.EnsureSwimlaneContent();
      semtalk.IsAutoSave = true;
      this.autosave = true;

      if (this.state.autorefreshreferences)
        refreshPage(semtalk, this.gprops.graphClient, this.state.mongo);
      if (this.state.showheaderfooterfields) {
        let props = await this.getModelProperties(this.filename);
        semtalk.updateTextFields(props);
      }
      await this.loadModelSettings();
      semtalk.page = null;

      // => autoSave
      semtalk.loadDiag(oid, init);

      xml = semtalk.tseditor.getGraphXml();
      this.setState({
        // semtalk: semtalk,
        xmlgraph: xml,
        diag: diag,
        loading: false
      });

      // this.gotoStartEvent(diag);
      let tit = document.title;
      if (tit.indexOf(":") > 0) {
        tit = tit.substring(0, tit.indexOf(": "));
      }
      tit = tit.replace(" *", "");
      document.title = tit + ": " + diag.ObjectCaption;
    }
  }

  public doubleClick = (cell: any): void => {
    if (this.state.islocked && cell.objectid) {
      this.editObject(cell.objectid);
    }
    // console.log('double click', cell);
  }

  public setEditor = (tseditor: ITSEditor): void => {
    let sem = this.state.semtalk;
    if (sem && this.stencil) {
      // console.debug(tseditor.graph);
      sem.graph = tseditor.graph;
      sem.tseditor = tseditor;
      sem.base.User = this.getUsername();
      // sem.horizontal = tseditor.isGraphHorizontal();

      // sem.tseditor.resetEditor(decoded, diag);
      // sem.tseditor.configShapes(sem.tseditor.graph, this.stencil, this.xmlshapes);
      if (sem.tseditor.sidebar) {
        sem.tseditor.configShapes(sem.tseditor.graph, this.stencil);
        sem.tseditor.sidebar.LoadStencil(this.stencil);
      }
      sem.tseditor.enableDragDrop();
      let pg = sem.page;
      if (pg && pg.ClassOf().ObjectName === sem.base.GetModelAttribute(Process_ElementName.SLProc)) {
        sem.tseditor.enableSwimlanes(sem.horizontal, false);
      }
      // sem.tseditor.enableLayout();

      // if (this.state.diag) {
      // this.loadObject(this.state.diag.ID, this.state.semtalk);
      // gotoDocument(this.state.diag.ID);
      // this.state.semtalk.loadDiag(this.state.diag.ID);
      // }
    }
  }

  public shapeAdded = (cell: mxCell): void => {
    let sem = this.state.semtalk;
    if (sem) {
      let graph = sem.graph;
      sem.visShapeAdded(cell, null);
      if (cell.objectid) {
        // gotoObject(cell.objectid);
        gotoObject(cell.objectid);
        graph.setSelectionCell(cell);
        // sem.shape = cell;
        if (this.state.hideComposeDialog) {
          let gl = [SemTalkMaster.MasterClass, SemTalkMaster.MasterSystemClass,
          SemTalkMaster.MasterInstance, SemTalkMaster.MasterUMLClass];
          const sc = sem.visCellSystemClass(cell.objectid);
          if (gl.indexOf(cell.shapeKey) > -1 ||
            (sc && sc.ObjectName === sem.base.GetModelAttribute(Process_ElementName.SLActivity))) {
            // console.debug(cell);
            sem.noevents = true;
            cell.value = " ";
            cell.label = " ";
            sem.tseditor.refreshCell(cell);
            sem.noevents = false;
            graph.setSelectionCell(cell);
            // (graph as any).startEditing();
            graph.startEditingAtCell(cell);
          }
        }
      }
    }
    // alert("shapeAdded");
    // gotoObject(cell.objectid);
  }

  public shapeDeleted = (cells: mxCell[]): void => {
    if (this.state.semtalk) {
      this.state.semtalk.visShapesDeleted(cells);
    }
  }

  public shapeExitedTextEdit = (cell: mxCell, newValue: any): void => {
    if (this.state.semtalk) {
      this.state.semtalk.visShapeExitedTextEdit(cell, newValue);
    }
  }

  public shapeMoveCells = (cells: mxCell[], clone: boolean): void => {
    if (cells.length > 0) {
      let geo = cells[0].geometry;
      this.click_x = geo.getCenterX();
      this.click_y = geo.getCenterY();
    }
    if (clone === true && this.state.semtalk) {
      this.state.semtalk.visShapesCloned(cells);
    }
    if (this.state.semtalk) {
      this.state.semtalk.visShapesMoved(cells);
    }
  }

  public shapeCopyCells = (_cells: mxCell[]): void => {
    // if (this.state.semtalk) {
    //   this.state.semtalk.visShapesCopied(cells);
    // }
  }

  private printPreview = () => {
    this.setState({ hidePrintPreview: false });
    // let sem = this.state.semtalk;
    // if (sem && sem.page) {
    //   sem.tseditor.printPreview(1, undefined, undefined, 20, 20, undefined, sem.page.ObjectCaption);
    // }
  }

  private chatGPT = () => {
    this.setState({ hideChatgpt: false });
  }
  private headerFooter = () => {
    this.setState({ hideHeaderFooter: false });
  }
  private images = () => {
    this.setState({ hideImages: false });
  }
  private documents = () => {
    this.setState({ hideDocuments: false });
  }
  private editStencil = () => {
    this.setState({ hideStencilEditor: false });
  }
  private editRibbon = () => {
    this.setState({ hideRibbonEditor: false });
  }
  private renameObjects = () => {
    this.setState({ hideRenameObjects: false });
  }
  private togglePageBreaks = (): void => {
    if (this.state.semtalk) this.state.semtalk.tseditor.togglePageBreaks();
  }

  private togglePanZoom = (): void => {
    if (this.state.semtalk) {
      this.state.semtalk.tseditor.togglePanZoom();
    }
  }

  private toggleStencil = (): void => {
    if (this.state.semtalk) {
      this.state.semtalk.tseditor.toggleStencil();
    }
  }

  private toggleNavigator = (): void => {
    if (this.state.semtalk) {
      this.state.semtalk.tseditor.toggleNavigator();
      this.setState({ showNavigatorDialog: !this.state.showNavigatorDialog });
    }
  }

  private ShowPlanner = (): void => {
    if (this.state.semtalk) {
      this.state.semtalk.tseditor.ShowPlanner();
      // this.setState({ showPlannerDialog: !this.state.showPlannerDialog });
      this.setState({ showPlannerDialog: true });
    }
  }
  private HidePlanner = (): void => {
    if (this.state.semtalk) {
      this.state.semtalk.tseditor.HidePlanner();
      // this.setState({ showPlannerDialog: !this.state.showPlannerDialog });
      this.setState({ showPlannerDialog: false });
    }
  }

  private toggleSearch = (): void => {
    // if (this.state.semtalk) {
    //   this.state.semtalk.tseditor.toggleSearch();
    this.setState({ showSearch: !this.state.showSearch });
    // }
  }

  private togglePages = (): void => {
    this.setState({ ispagesenabled: !this.state.ispagesenabled });
    if (this.state.semtalk) {
      this.state.semtalk.tseditor.togglePages();
    }
  }
  private toggleBackground = (): void => {
    this.setState({ showheaderfooterfields: !this.state.showheaderfooterfields });
    // }
  }
  public shapeConnectionAdded = (
    event: any,
    edge: mxCell,
    source: mxCell,
    target: mxCell
  ): void => {
    if (this.state.semtalk) {
      this.state.semtalk.visConnectionAdded(event, edge, source, target);
    }
  }

  public shapeConnectionsDeleted = (edge: mxCell): void => {
    if (this.state.semtalk) {
      this.state.semtalk.visConnectionsDeleted(edge);
    }
  }

  public shapeCellConnected = (edge: mxCell): void => {
    if (this.state.semtalk) {
      this.state.semtalk.visCellConnected(edge);
    }
  }

  // ------------------------------------------------------------------------------------

  private newDocument = (): void => {
    const sem = this.state.semtalk;
    if (sem) {
      if (sem.ismodified && !this.state.islocked) {
        if (!window.confirm(sem.getResStr(ResID.STRLEAVEWITHOUTSAVING))) {
          return;
        }
      }
      this.HidePlanner();
      if (this.props.template !== "") {
        setCookie(SemTalkCookie.autosaveSemTalk, "");
        this.CreateDocument(sem, this.props.template, true);
      } else {
        this.setState({
          hideFileOpen: false,
          isopen: false,
          isnew: true,
          issaveas: false,
          isimport: false,
          ismerge: false,
          isdelete: false,
          spexplorercaption: sem.getResStrListener(ResIDL.STRDLGCMDNE),
        });
      }
    }
  }

  public getFileInfo = async (fname: string): Promise<any> => {
    let w: any = window;
    if (this.state.mongo.iselectron && w.api) {
      if (fname) {
        let meta = await w.api.getfilemetadata({ filename: fname });
        // console.debug(meta);
        return meta;
      }
    }
    return {};
  }

  public openOneDrive = () => {
    this.setState({ hideFileOneDrive: false, oneDriveSave: false });
  }

  public importOneDrive = () => {
    this.setState({ hideFileOneDrive: false, oneDriveSave: false });
  }

  public saveOneDrive = async (filename: string, filetype: string) => {
    let sem = this.state.semtalk;
    if (!filename || filename === "") {
      filename = this.GetFilename();
    }
    if (sem) {
      let blob: string = "";
      if (
        !filename.endsWith(".sdx") ||
        (filetype !== "" && filetype !== ".sdx")
      ) {
        filename = filename.replace(".sdx", filetype);
        blob = await sem.saveExternalFormat(this, filename, true);
      }
      if (
        blob.length === 0 ||
        filename.endsWith(".sdx") ||
        filename.endsWith(".stx")
      ) {
        if (filename === this.state.template || filename === "") {
          filename = sem.base.AllDiagrams()[0].ObjectCaption;
        }
        //  if (!filename) filename = "filename";
        if (!filename.endsWith(".sdx")) {
          filename = filename + ".sdx";
        }
        if (filename.endsWith(".stx")) {
          filename = filename.replace(".stx", ".sdx");
        }
        if (!filename.endsWith(".sdx")) {
          filename = filename + ".sdx";
        }
        this.saveCurrentGraph();
        const o2j = new OB2JSON();
        let body = o2j.SaveJSON(sem.base);
        if (sem.page) body.currentpage = sem.page.ID;
        if (sem.tempId) body.tempId = sem.tempId;
        blob = JSON.stringify(body);
      }
      this.setState({
        hideFileOneDrive: false,
        oneDriveSave: true,
        hideFileOpen: true,
        selectedFilename: filename,
        selectedFileContent: blob,
      });
    }
  }

  public openlocalDocument = async (filetype: string, force: boolean) => {
    let w: any = window;
    let sem = this.state.semtalk;
    if (sem && sem.ismodified && !this.state.islocked && !force) {
      if (!window.confirm(sem.getResStr(ResID.STRLEAVEWITHOUTSAVING))) {
        return;
      }
    }
    this.ResetModelProperties();
    this.resetFileDialog("");
    if (this.state.mongo.iselectron && w.api) {
      let filters = [{ name: "SemTalk", extensions: ["sdx"] }];
      if (filetype === ".bpmn") {
        filters = [{ name: "BPMN", extensions: ["bpmn"] }];
      }
      if (filetype === "") {
        filters = [
          { name: "SemTalk", extensions: ["sdx"] },
          { name: "BPMN", extensions: ["bpmn"] },
        ];
      }
      const datal = await w.api.openDocument({
        dialog: {
          properties: ["openFile"],
          filters: filters,
        },
      });
      if (datal) {
        let data = datal[0];
        let fname: string = data["filename"];
        if (fname && data["data"]) {
          // if (fname.endsWith(".sdx")) {
          if (filetype === ".sdx" || filetype === "") {
            let s: SemTalkJSON = JSON.parse(data["data"]);
            setCookie(SemTalkCookie.autosaveSemTalk, "");
            await this.loadDocumentFromJSON(data["filename"], s, true);
            //   // } else {
            //   //   // if (fname.endsWith(".bpmn")) {
            //   //   this.loadExternalFormat(fname, data["data"]);
            //   // }
            // }
          }
        }
      }
      return;
    }
    await this.upload(this.onOpenUploadChangeHandler, false, ".sdx");
    this.HidePlanner();
    // }
  }
  private openDocument = (): void => {
    const sem = this.state.semtalk;
    if (sem) {
      if (
        !(this.state.mongo.usemongo && this.state.mongo.semuserlogin) &&
        !this.sprops.context &&
        !this.gprops.usegraph
      ) {
        // if (!this.props.mongo.usemongo && !this.sprops.context) {
        this.openlocalDocument("", false);
        this.HidePlanner();
        return;
      }
      if (sem.ismodified && !this.state.islocked) {
        if (!window.confirm(sem.getResStr(ResID.STRLEAVEWITHOUTSAVING))) {
          return;
        }
      }
      let hidenopen = false;
      if (this.props.restrictExtSharedMode) {
        hidenopen = true;
      }
      this.setState({
        hideFileOpen: hidenopen,
        isopen: true,
        isnew: false,
        issaveas: false,
        isimport: false,
        ismerge: false,
        isdelete: false,
        spexplorercaption: sem.getResStrListener(ResIDL.STRDLGCMDOP),
      });
    }
  }

  private resetFileDialog = (filename: string) => {
    this.SetFilename(filename, false);
    this.setState({
      isopen: false,
      isnew: false,
      issaveas: false,
      isimport: false,
      ismerge: false,
      isdelete: false,
      hideFileOpen: true,
      hideComposeDialog: true,
      hideTranslateDialog: true,
      hideCustomizeDialog: true,
      hideExpandDialog: true,
      hideCreateDiagramDialog: true,
      hideHelp: true,
      hideInsertDialog: true,
      hideOpenLinkDialog: true,
      hidePropertyDialog: true,
      hideRelationDialog: true,
      hideSelectDialog: true,
      hideShapeStyle: true,
      hideSimulation: true,
      hideHyperlinkDialog: true,
      hidePlannerDialog: true,
      hideCheckRepair: true,
      hideChatgpt: true,
      hideTermSetDialog: true,
      hideSiteBuilderDialog: true,
      hideImport: true,
      hideOWLImport: true,
      hideCharting: true,
      hideImpressum: true,
      hidePrivacy: true,
      ischeckedout2me: false,
      checkedOut: false
      // filename: filename
    });
    setCookie(SemTalkCookie.autosaveSemTalk, "");
    settingsChanged();
  }

  public openDocumentCallBack = async (filename: string) => {
    this.resetFileDialog(filename);
    this.HidePlanner();
    await this.LoadDocument(filename, true);
    let mg = this.state.mongo;
    if (mg.usemongo && mg.dbname !== "semtalkonline") {
      // let mongodb = mg.dbname + "@" + mg.semmongoserverurl.replace("/api/", "");
      let mongodb = mg.dbname;
      document.title = document.title + " [" + mongodb + "]";
    }
  }
  public deleteDocumentCallBack = async (filename: string) => {
    // this.resetFileDialog(filename);
    let mongo = this.state.mongo;
    let sem = this.state.semtalk;

    const usemongo = mongo.usemongo && this.state.mongo.semuserlogin !== null;
    this.setState({ ischeckedout2me: false });
    if (usemongo) {
      let documents = mongo.documents;
      if (mongo.templates.length > 0 && filename.endsWith(".stx")) {
        documents = mongo.templates;
      }
      // if (sem) {
      //   this.alert(sem.getResStr(ResID.STRFILEDELETE), MessageBarType.info);
      // }
      if (sem) {
        try {
          await mgDeleteItem(mongo, mongo.dbname, documents, filename);
          //Delete from Backup
          await mgDeleteItem(mongo, mongo.dbname, mongo.backup, filename);
          this.alert(filename + " " + sem.getResStr(ResID.STRFILEDELETED), MessageBarType.success);
          setTimeout(() => {
            this.alert("", MessageBarType.success);
          }, 5000);
        } catch (e) {
          this.alert((e as any).message, MessageBarType.error);
        }
      }
    } else {
      if (this.gprops.usegraph && this.gprops.graphClient) {
        if (filename.length > 0 && !filename.endsWith(".stx")) {
          try {
            if (sem) {
              let ischeckedout: boolean = false;
              try {
                let meta: any = await this.metadocumentfromgraph(sem, filename);
                if (meta && meta.publication) {
                  if (meta.publication.level === "checkout" || meta.publication.checkedOutBy) {
                    let u1 = meta.publication.checkedOutBy!.user!.displayName;
                    let u0 = this.getUsername();
                    if (u1 !== u0) {
                      this.alert(filename + sem.getResStr(ResID.STROPENCHECKOUTTO) +
                        u1,
                        MessageBarType.blocked
                      );
                      ischeckedout = true;
                    }
                    // }
                  }
                }
              } catch (e) {
              }
              if (!ischeckedout) {
                let res: any = await this.deletefromgraph(sem, filename);
                if (res === null) {
                  this.alert(filename + " " + sem.getResStr(ResID.STRFILEDELETED), MessageBarType.success);
                } else {
                  this.alert(res, MessageBarType.error);
                }
                setTimeout(() => {
                  this.alert("", MessageBarType.success);
                }, 5000);
              }
            }
          } catch (e) {
            this.alert((e as any).message, MessageBarType.error);
          }
        }
      }
    }

  }

  public restoreVersionCallBack = async (filename: string, content: SemTalkJSON) => {
    if (this.state.semtalk) {
      if (this.state.semtalk.ismodified && !this.state.islocked) {
        if (!window.confirm(this.state.semtalk.getResStr(ResID.STRLEAVEWITHOUTSAVING))) {
          return;
        }
      }
    }
    this.resetFileDialog(filename);
    await this.loadDocumentFromJSON(filename, content, false);
    let mg = this.state.mongo;
    if (mg.usemongo && mg.dbname !== "semtalkonline") {
      let mongodb = mg.dbname;
      document.title = document.title + " [" + mongodb + "]";
    }
    if (this.state.semtalk) {
      this.alert(this.state.semtalk.getResStr(ResID.STRVERSIONRESTORED), MessageBarType.success);
      setTimeout(() => {
        this.alert("", MessageBarType.success);
      }, 5000);
    }
  }

  public importlocalDocument = async () => {
    this.resetFileDialog("");
    let w: any = window;
    if (w.api && this.state.mongo.iselectron && w.api.openDocument) {
      const datal = await w.api.openDocument({
        dialog: {
          properties: ["openFile"],
          filters: [{ name: "SemTalk", extensions: ["sdx"] }],
        },
      });
      if (datal) {
        let data = datal[0];
        let fname: string = data["filename"];
        if (fname && data["data"]) {
          if (fname.endsWith(".sdx")) {
            let s = JSON.parse(data["data"]);
            setCookie(SemTalkCookie.autosaveSemTalk, "");
            this.mergeDocumentFromJSON(data["filename"], s);
          }
        }
      }
      return;
    }
    this.upload(this.onImportUploadChangeHandler, false, ".sdx");
  }

  private onImportUploadChangeHandler = (event: any) => {
    let f = event.target.files[0];
    console.log(f);
    let fr = new FileReader();
    fr.readAsText(f);
    let filename = f.name;
    fr.onload = async (_event) => {
      //Both "event.target.result" and "fr.result" contain the file's contents (because "event.target" is === "fr")
      const s = fr.result;
      if (s) {
        setCookie(SemTalkCookie.autosaveSemTalk, "");
        let json = JSON.parse(s.toString());
        this.mergeDocumentFromJSON(filename, json);
      }
    };
  }

  private importDocument = (): void => {
    const sem = this.state.semtalk;
    if (sem) {
      if (!this.state.mongo.usemongo && !this.sprops.context) {
        this.importlocalDocument();
        return;
      }

      this.setState({
        hideFileOpen: false,
        isopen: false,
        isnew: false,
        issaveas: false,
        isimport: true,
        ismerge: false,
        isdelete: false,
        spexplorercaption: sem.getResStrListener(ResIDL.STRDLGIMPORT),
      });
    }
  }

  public importDocumentCallBack = async (filename: string) => {
    this.SetFilename(filename, false);
    this.setState({
      isimport: false,
      hideFileOpen: true,
      hideComposeDialog: true,
      hideTranslateDialog: true,
      hideCustomizeDialog: true,
      hideExpandDialog: true,
      hideCreateDiagramDialog: true,
      hideHelp: true,
      hideInsertDialog: true,
      hideOpenLinkDialog: true,
      hidePropertyDialog: true,
      hideRelationDialog: true,
      hideSelectDialog: true,
      hideShapeStyle: true,
      hideTermSetDialog: true,
      hideSiteBuilderDialog: true,
      // filename: filename
      hideImport: false,
      hideOWLImport: true
    });
    setCookie(SemTalkCookie.autosaveSemTalk, "");
    // this.mergeDocument(filename);
  }

  private mergelocalDocuments = async () => {
    this.resetFileDialog("");
    let w: any = window;
    let startpages: any = {};
    const sem = this.state.semtalk;
    if (sem && w.api && this.state.mongo.iselectron && w.api.openDocument) {
      const datal = await w.api.openDocument({
        dialog: {
          properties: ["openFile", "multiSelections"],
          filters: [{ name: "SemTalk", extensions: ["sdx"] }],
        },
      });
      if (datal) {
        for (let data of datal) {
          let filename: string = data["filename"];
          if (filename && data["data"]) {
            if (filename.endsWith(".sdx")) {
              let s = JSON.parse(data["data"]);
              this.setState({ isLoading: "Merging: " + filename });
              const o2j = new OB2JSON();
              const ob = new ObjectBase();
              o2j.LoadJSON(ob, s);
              sem.noevents = true;
              if (ob.GetModelAttribute(ModelAttribute.patch) !== "false") {
                await sem.patchSubTask(ob, this.state.resizeall);
              }
              console.debug("Merging: " + filename);
              try {
                o2j.Merge(sem.base, ob, this.encode, this.decode, filename, []);
              } catch (e) {
                console.debug(e);
              }
              let pages = ob.AllDiagrams();
              let p0 = sem.base.FindDiagram(pages[0].ObjectName);
              if (pages.length > 0) {
                startpages[filename] = p0;
              }
              console.debug("Merged: " + filename);
              // console.debug(ob0);
              sem.noevents = false;
            }
          }
        }
        let attachments = sem.base.FindClass(SemTalkBaseConstant.SLAttachment)?.AllInstances();
        if (attachments) {
          for (let a of attachments) {
            let p = startpages[a.ObjectName];
            if (p) {
              for (let lin of a.InvLinks(SemTalkBaseConstant.SLhasAttachment, SemTalkRelation.SemTalkSystemRelation)) {
                let oth = lin.FromObject;
                const lnk: ISemTalkAssociation | null = oth.MakeAssociation(SemTalkBaseConstant.SLhasAttachment, p,
                  SemTalkRelation.SemTalkSystemRelation, undefined);
                lnk.SetValue(SemTalkAttachment.label, lin.GetValue(SemTalkAttachment.label));
                lnk.SetValue(SemTalkAttachment.namespace, lin.GetValue(SemTalkAttachment.namespace));
              }
              a.Delete();
            }
          }
        }

        // this.setState({ "isLoading": "" });
        // for (let c of sem.base.AllClasses()) {
        //   if (c.ExtRefinement && !c.Refinement) {
        //     let p = startpages[c.ExtRefinement];
        //     if (p) {
        //       c.Refinement = startpages[c.ExtRefinement];
        //     }
        //   }
        // }
        // for (let c of sem.base.AllInstances()) {
        //   if (c.ExtRefinement && !c.Refinement) {
        //     let p = startpages[c.ExtRefinement];
        //     if (p) {
        //       c.Refinement = startpages[c.ExtRefinement];
        //     }
        //   }
        // }
        // sem.updateDiaglist();
        // let diags = sem.base.AllDiagrams();
        // let pg = diags[diags.length - 1];
        // if (pg.ClassOf().ObjectName === sem.base.GetModelAttribute(Process_ElementName.SLProc)) {
        //   sem.tseditor.enableSwimlanes(sem.horizontal, false);
        // }
        // this.loadPage(pg.ID, sem);
        // this.setState({ cntpages: diags.length });
      }
      return;
    }
    this.alert(
      "Merging of multiple files from disk is not supported in the web version",
      MessageBarType.info
    );
    // this.upload(this.onMergeUploadChangeHandler, true);
  }

  private mergeDocuments = (): void => {
    const sem = this.state.semtalk;
    if (sem) {
      if (!this.state.mongo.usemongo && !this.sprops.context) {
        this.mergelocalDocuments();
        return;
      }

      this.setState({
        hideFileOpen: false,
        isopen: false,
        isnew: false,
        issaveas: false,
        isimport: false,
        ismerge: true,
        isdelete: false,
        spexplorercaption: sem.getResStrListener(ResIDL.STRDLGMERGE),
      });
    }
  }

  public mergeDocumentsCallBack = async (files: any[]): Promise<void> => {
    this.setState({
      ismerge: false,
      hideFileOpen: true,
      hideComposeDialog: true,
      hideTranslateDialog: true,
      hideCustomizeDialog: true,
      hideExpandDialog: true,
      hideCreateDiagramDialog: true,
      hideHelp: true,
      hideInsertDialog: true,
      hideOpenLinkDialog: true,
      hidePropertyDialog: true,
      hideRelationDialog: true,
      hideSelectDialog: true,
      hideShapeStyle: true,
      hideTermSetDialog: true,
      hideSiteBuilderDialog: true,
    });
    setCookie(SemTalkCookie.autosaveSemTalk, "");
    const sem = this.state.semtalk;
    let startpages: any = {};
    if (sem) {
      // let templ = this.state.template;
      for (let filename of files) {
        if (filename.endsWith(".sdx")) {
          // let filename = f.name;
          // await this.mergeDocument(f.name);

          const ob = new ObjectBase();
          let s: any = {};
          if (this.state.mongo.usemongo && this.state.mongo.semuserlogin) {
            console.debug("Load for Merging: " + filename);
            let res = await mgGetDocument(
              this.state.mongo,
              this.state.mongo.documents,
              filename
            );
            if (res) {
              s = res;
            }
          } else {
          }
          if ((!s || s["ObjectType"] === undefined) && this.sprops.context) {
            let txt = "";
            const url: string =
              this.sprops.librarysite + "/" + this.sprops.library + "/" + filename;
            try {
              txt = await sem.explorer.getTXT(this.sprops.context, url);
              s = JSON.parse(txt);
              console.debug("Merging: " + url);
              //  console.debug(s);
            } catch (e) {
              console.debug("mergedocument. cannot parse doc to JSON!!" + e);
            }
          }

          // if (s["Template"] === templ || s["Template"].replace(".vstx",".stx").replace(".vst",".stx") === templ) {
          this.setState({ isLoading: "Merging: " + filename });
          const o2j = new OB2JSON();
          o2j.LoadJSON(ob, s);
          sem.noevents = true;
          if (ob.GetModelAttribute(ModelAttribute.patch) !== "false") {
            await sem.patchSubTask(ob, this.state.resizeall);
          }
          console.debug("Merging: " + filename);
          try {
            o2j.Merge(sem.base, ob, this.encode, this.decode, filename, []);
          } catch (e) {
            console.debug(e);
          }
          let pages = ob.AllDiagrams();
          let p0 = sem.base.FindDiagram(pages[0].ObjectName);
          if (pages.length > 0) {
            startpages[filename] = p0;
          }
          console.debug("Merged: " + filename);
          // console.debug(ob0);
          sem.noevents = false;
          // }
        }
      }
      let attachments = sem.base
        .FindClass(SemTalkBaseConstant.SLAttachment)
        ?.AllInstances();
      if (attachments) {
        for (let a of attachments) {
          let p = startpages[a.ObjectName];
          if (p) {
            for (let lin of a.InvLinks(
              SemTalkBaseConstant.SLhasAttachment,
              SemTalkRelation.SemTalkSystemRelation
            )) {
              let oth = lin.FromObject;
              const lnk: ISemTalkAssociation | null = oth.MakeAssociation(
                SemTalkBaseConstant.SLhasAttachment, p,
                SemTalkRelation.SemTalkSystemRelation, undefined);
              lnk.SetValue(SemTalkAttachment.label, lin.GetValue(SemTalkAttachment.label));
              lnk.SetValue(SemTalkAttachment.namespace, lin.GetValue(SemTalkAttachment.namespace));
            }
            a.Delete();
          }
        }
      }

      this.setState({ isLoading: "" });
      for (let c of sem.base.AllClasses()) {
        if (c.ExtRefinement && !c.Refinement) {
          let p = startpages[c.ExtRefinement];
          if (p) {
            c.Refinement = startpages[c.ExtRefinement];
          }
        }
      }
      for (let c of sem.base.AllInstances()) {
        if (c.ExtRefinement && !c.Refinement) {
          let p = startpages[c.ExtRefinement];
          if (p) {
            c.Refinement = startpages[c.ExtRefinement];
          }
        }
      }
      sem.updateDiaglist();
      sem.tseditor.enableDragDrop();
      let diags = sem.base.AllDiagrams();
      let pg = diags[diags.length - 1];
      if (pg.ClassOf().ObjectName === sem.base.GetModelAttribute(Process_ElementName.SLProc)) {
        sem.tseditor.enableSwimlanes(sem.horizontal, false);
      }
      await this.loadPage(pg.ID, sem, false);
      this.setState({ cntpages: diags.length });
    }
  }

  private publishDocuments = (): void => {
    const sem = this.state.semtalk;
    if (sem) {
      this.setState({
        hidePublishDialog: false,
        isopen: false,
        isnew: false,
        issaveas: false,
        isimport: false,
        ismerge: false,
        isdelete: false,
        spexplorercaption: sem.getResStr(ResID.STRPUBLISH),
      });
    }
  }
  public publishDocumentsCallBack = async (
    files: any[],
    publishBg: boolean
  ): Promise<void> => {
    let log: any[] = [];
    let oldLanguage = this.state.language;
    let connection: IExportConnection = this.exportConnection;
    let pubLanguage = "German" as SemTalkLanguage;
    if (connection) {
      pubLanguage = connection.language as SemTalkLanguage;
      let services: ISemTalkServicesOptions = {
        server: connection.serviceserver,
        site: connection.servicesite,
        database: connection.servicedatabase,
        sqlconnection: connection.servicesconstr,
        servertype: connection.servicesservertype,
        breports: connection.reporting,
      };
      log = await srvLog(this.props.mongo, services);
      this.setState({
        hidePublishDialog: true,
        hideFileOpen: true,
        hideComposeDialog: true,
        hideTranslateDialog: true,
        hideCustomizeDialog: true,
        hideExpandDialog: true,
        hideCreateDiagramDialog: true,
        hideHelp: true,
        hideInsertDialog: true,
        hideOpenLinkDialog: true,
        hidePropertyDialog: true,
        hideRelationDialog: true,
        hideSelectDialog: true,
        hideShapeStyle: true,
        hideTermSetDialog: true,
        hideSiteBuilderDialog: true,
        hideGraph: !publishBg,
        publishinglog: log,
        islocked: true,
        language: pubLanguage,
        semtalk_services: services
      });

      setCookie(SemTalkCookie.autosaveSemTalk, "");
      const sem = this.state.semtalk;
      if (sem) {
        let pubLangStr = "";
        let pubLangInst = sem.base.FindInstance(pubLanguage);
        if (pubLangInst) {
          pubLangStr = pubLangInst.ID2NameNspLan(pubLanguage);
        } else {
          pubLangStr = pubLanguage;
        }
        this.alert(
          sem.getResStr(ResID.STRPUBLISHINGACTIVE) + ": " + pubLangStr + " (" + connection.name + ")",
          MessageBarType.info
        );
        setTimeout(() => {
          this.alert("", MessageBarType.info);
        }, 10000);
        srvResetPublished(this.state.mongo);
        let currfilename = this.GetFilename();
        if (currfilename === "") {
          currfilename = this.state.template;
        }
        // let templ = this.state.template;
        let c = 0;
        for (let filename of files) {
          if (filename.endsWith(".sdx")) {
            // let filename = f.name;
            // await this.mergeDocument(f.name);

            let s: any = {};
            if (this.state.mongo.usemongo && this.state.mongo.semuserlogin) {
              // console.debug("Load for Publishing: " + filename);
              let res = await mgGetDocument(
                this.state.mongo,
                this.state.mongo.documents,
                filename
              );
              if (res) {
                s = res;
              }
            } else {
            }
            if ((!s || s["ObjectType"] === undefined) && this.sprops.context) {
              let txt = "";
              const url: string =
                this.sprops.librarysite + "/" + this.sprops.library + "/" + filename;
              try {
                txt = await sem.explorer.getTXT(this.sprops.context, url);
                s = JSON.parse(txt);
                console.debug("Publishing: " + url);
                //  console.debug(s);
              } catch (e) {
                console.debug("Publishing. cannot parse doc to JSON!!" + e);
              }
            }

            if ((!s || s["ObjectType"] === undefined) && !this.sprops.context && this.gprops.usegraph && this.gprops.graphClient) {
              try {
                s = await this.loaddocumentfromgraph(sem, filename);
              } catch (e) {
                console.debug("Publishing. cannot parse doc to JSON!!" + e);
              }
            }

            // if (s["Template"] === templ || s["Template"].replace(".vstx",".stx").replace(".vst",".stx") === templ) {
            c++;
            setTimeout(async (cc) => {
              let l = this.state.publishinglog;
              if (l) {
                l.unshift({ entry: "Publishing: " + filename });
                this.setState({
                  isLoading: sem.getResStr(ResID.STRPUBLISHING) + " (" + cc + "/" + files.length + ") " + filename,
                  publishinglog: l,
                });
              }
            }, 1, c);

            this.ResetModelProperties();
            await this.loadDocumentFromJSON(filename, s, false);
            let ob = sem.base;
            await this.updateSVG(ob);

            // const o2j = new OB2JSON();
            // o2j.LoadJSON(ob, s);
            sem.noevents = true;
            // await sem.patchSubTask(ob, this.props.resizeall);
            console.debug("Publishing: " + filename);
            let plist = ob.AllDiagrams();
            if (plist.length > 0) {
              let diag = plist[0];
              if (diag) {
                let syn = diag.FindSynonym(this.state.language);
                let nn = diag.ClassOf().ID2NameNsp() + "-1";
                if (syn && (syn.Name === "Page-1" || syn.Name === nn)) {
                  diag.MakeSynonym(
                    filename[0].toUpperCase() +
                    filename.substr(1).replace(".sdx", ""),
                    this.state.language
                  );
                }
              }
            }
            //Change Object namespaces for Portal
            // let obj = ob.FindClass("Object");
            // if (obj) {
            //   let cl: ISemTalkClass = obj as ISemTalkClass;
            //   if (cl) {
            //     let list = cl.AllSubClasses();
            //     list.forEach(element => {
            //       sem.RenameObject(element, filename + "#" + element.ObjectCaption, null);
            //     });
            //   }
            // }
            for (let m of ob.AllModelAttributes()) {
              if (m.startsWith(SemTalkBaseConstant.SLMXGPagePrefix)) {
                ob.SetModelAttribute(m, undefined);
              }
            }
            for (let m of ob.AllAssociationTypes()) {
              m.InvName = "";
            }
            let extComponent = ob.FindSystemClass("ExternalComponent");
            if (extComponent) {
              for (let extc of extComponent.AllInstances()) {
                extc.SetValue("ExternalComponent64", null);
              }
            }
            let props = await this.getModelProperties(filename);
            if (props.length > 0) {
              let docinfo = ob.MakeClass("DocumentInfo");
              let mydocinfo = docinfo.MakeInstance("MyDocInfo");
              for (let prop of props) {
                mydocinfo.SetValue(prop["PropCaption"], prop["PropValue"]);
              }
            }

            const o2x = new OB2XML();
            let xml: string = o2x.SaveXML(ob, this.encode, this.decode, true);
            let filename2 = filename.replace(".sdx", "");
            filename2 += ".xml";
            let upMessage = await srvUploadFile(
              this.props.mongo,
              this.state.semtalk_services,
              filename2,
              xml,
              false,
              pubLanguage
            );
            if (upMessage === "Error") {
              upMessage = filename + " " + sem.getResStr(ResID.STRUPLOADEDERROR);
              this.alert(upMessage, MessageBarType.error);
            }
            if (upMessage === "Success") {
              upMessage = filename + " " + sem.getResStr(ResID.STRUPLOADED);
              this.alert(upMessage, MessageBarType.info);
            }

            console.debug("Publishing: " + upMessage);
            sem.noevents = false;
            // }
          }
        }
        if (this.state.semtalk_services.breports) {
          for (let report of await srvGetReports(this.state.mongo, "Reports")) {
            await srvUploadReport(
              this.state.mongo,
              this.state.semtalk_services,
              report["name"],
              report["query"],
              false,
              pubLanguage
            );
          }
        }
        let l1 = this.state.publishinglog;
        let m1 = sem.getResStr(ResID.STRMERGING);
        if (l1 && l1.length > 0) l1.unshift({ entry: m1 });
        this.setState({
          isLoading: m1,
          publishinglog: l1,
        });
        if (files.length > 0) {
          await srvExport(
            this.state.mongo,
            this.state.semtalk_services,
            pubLanguage
          );
          //window.open("https://semtalkportal.semtalk.com/", "_blank");
        }
        this.setState({
          isLoading: "",
          hideGraph: false,
          islocked: false,
          publishinglog: [],
          language: oldLanguage,
        });
        this.alert(sem.getResStr(ResID.STRPUBFINISHED), MessageBarType.info);
        setTimeout(() => {
          this.alert("", MessageBarType.info);
        }, 5000);
        if (currfilename.endsWith(".stx")) {
          await this.newDocumentCallBack(currfilename);
        } else {
          await this.LoadDocument(currfilename, true);
        }
      }
      this.setState({
        isLoading: "",
        hideGraph: false,
        islocked: false,
        publishinglog: [],
        language: oldLanguage,
      });
    }
  }

  private makeModelPropItem = (sem: IVisioRDFS | undefined, propname: string, v: string, items: any[], locals: string[]) => {
    if (sem) {
      let pv: string = "";
      if (v) pv = v.toString();
      let propcaption = propname;
      switch (propname) {
        case ModelProperty.createdby: {
          propcaption = sem.getResStrListener(ResIDL.STRDLGAUDIT1);
          break;
        }
        case ModelProperty.created: {
          propcaption = sem.getResStrListener(ResIDL.STRDLGAUDIT2);
          try {
            pv = formatdate(v, sem.guilanguage);
          } catch {
            pv = v;
          }
          break;
        }
        case ModelProperty.modifiedby: {
          propcaption = sem.getResStrListener(ResIDL.STRDLGAUDIT3);
          break;
        }
        case ModelProperty.modified: {
          propcaption = sem.getResStrListener(ResIDL.STRDLGAUDIT4);
          try {
            pv = formatdate(v, sem.guilanguage);
          } catch {
            pv = v;
          }
          break;
        }
        case ModelProperty.version: {
          propcaption = sem.getResStrListener(ResIDL.STRSAPEXCELVER);
          break;
        }
        case ModelProperty.checkedoutdate: {
          propcaption = sem.getResStr(ResID.STRCHECKEDOUTAT1);
          pv = formatdate(v, sem.guilanguage);
          break;
        }
        case ModelProperty.checkedoutuser: {
          propcaption = sem.getResStr(ResID.STRCHECKEDOUTBY1);
          break;
        }
        case ModelProperty.approved: {
          propcaption = sem.getResStr(ResID.STRAPPROVEDAT1);
          pv = formatdate(v, sem.guilanguage);
          break;
        }
        case ModelProperty.approvedby: {
          propcaption = sem.getResStr(ResID.STRAPPROVEDBY1);
          break;
        }
        case ModelProperty.state: {
          propcaption = sem.getResStrListener(ResIDL.STRSTATE);
          break;
        }
        case ModelProperty.domain: {
          propcaption = sem.getResStrListener(ResIDL.STRDOMAIN);
          break;
        }
        case ModelProperty.comment: {
          propcaption = sem.getResStrListener(ResIDL.STRCOMMENT);
          break;
        }
        case ModelProperty.template: {
          propcaption = sem.getResStrListener(ResIDL.STRDLGCMDTMP);
          break;
        }
        case ModelProperty.title: {
          propcaption = sem.getResStr(ResID.STRTITLE);
          break;
        }

        default: {
          if (sem.base) {
            let obj = sem.base.FindObject(
              SemTalkType.SemTalkAttributeType,
              propname
            );
            if (obj) propcaption = obj.ID2NameNsp();
          }
        }
      }
      let prop: any;
      prop = {
        ID: items.length,
        PropName: propname,
        PropCaption: propcaption,
        PropValue: pv,
        islocal: locals.indexOf(propname) > -1,
      };
      items.push(prop);
    }
    return items;
  }
  private getModelProperties = async (docname: string): Promise<any[]> => {
    // Es gibt auch TextFelder ausserhalb von header footer
    // if (this.state.showheaderfooterfields) {
    if (this.modelproperties) {
    } else {
      if (docname.length === 0) return [];
      this.modelproperties = await this._getModelProperties(this.state.semtalk, docname);
    }
    return this.modelproperties;
    // } else return [];
  }
  public ResetModelProperties() {
    this.modelproperties = null;
    this.mongodb_current_metadata = null;
  }
  private _getModelProperties = async (sem: IVisioRDFS | undefined, docname: string): Promise<any[]> => {
    if (sem) {
      let addDocumentProperties = (item: any): string[] => {
        let locals: string[] = [];
        let mp = sem.base.GetModelAttribute(ModelAttribute.modelProperties);
        if (mp) {
          let modelprops: any = {};
          try {
            modelprops = JSON.parse(mp);
            for (let propname in modelprops) {
              if (!item[propname]) {
                item[propname] = modelprops[propname];
                locals.push(propname);
              }
            }
          } catch (_e) {

          }
        }
        return locals;
      };
      let items: any[] = [];
      const mongo = this.state.mongo;
      if (mongo.usemongo && mongo.semuserlogin && docname && !this.gprops.usegraph) {
        // let docs = mongo.documents;
        if (mongo.templates.length > 0 && docname.endsWith(".stx")) {
          // docs = mongo.templates;
        }
        if (!this.mongodb_current_metadata) {
          this.mongodb_current_metadata = await this.getMongoDBDocumentMetaData(docname);
          // this.metadata = await mgGetItem(
          //   mongo,
          //   mongo.dbname,
          //   docs,
          //   docname
          // );
        }
        if (this.mongodb_current_metadata) {
          let locals = addDocumentProperties(this.mongodb_current_metadata);
          for (let propname in this.mongodb_current_metadata) {
            if (propname !== "_id" && propname !== "value" && propname !== "zip") {
              let v = this.mongodb_current_metadata[propname];
              items = this.makeModelPropItem(sem, propname, v, items, locals);
            }
          }
        }
      }
      if (!mongo.usemongo && docname && !this.gprops.usegraph &&
        this.sprops.context !== undefined &&
        this.sprops.librarysite !== undefined &&
        this.sprops.library !== undefined &&
        sem.explorer !== undefined) {
        console.debug("Open " + this.sprops.librarysite);

        // let lany: any = await this.state.spinterface.getListData(this.sprops.context, this.props.site, lib, "$select=FileLeafRef,ID,CheckoutUserId");
        let query = "$select=FileLeafRef,ID,CheckoutUser/Title,Modified,Author/Title,Editor/Title,Created,OData__UIVersionString,State" +
          "&$expand=CheckoutUser/Id, Editor/Id, Author/Id" +
          "&$filter=FileLeafRef eq '" + docname + "'";

        if (this.state.sharepointdocumentcolumns.length > 0) {
          query = "$select=" + this.state.sharepointdocumentcolumns +
            "&$expand=CheckoutUser/Id, Editor/Id, Author/Id" +
            "&$filter=FileLeafRef eq '" + docname + "'";

        }
        let lany: any = await sem.explorer.getListData(
          this.sprops.context,
          this.sprops.librarysite,
          this.sprops.library,
          query
        );
        if (lany["error"]) {
          this.alert(lany["error"]["message"], MessageBarType.error);
        }

        let litems: any[] = lany.value;
        // let columns = this.props.sharepointdocumentcolumns.split(",");
        if (litems !== undefined) {
          let spitem = litems[0];
          let locals = addDocumentProperties(spitem);

          if (spitem["FileLeafRef"]) {
            items = this.makeModelPropItem(sem, "Name", spitem["FileLeafRef"], items, locals);
          }
          if (spitem["Author"]) {
            items = this.makeModelPropItem(sem, ModelProperty.createdby,
              spitem["Author"]["Title"], items, locals);
          }
          if (spitem["Created"]) {
            items = this.makeModelPropItem(sem, ModelProperty.created,
              spitem["Created"], items, locals);
          }
          if (spitem["Editor"]) {
            items = this.makeModelPropItem(sem, ModelProperty.modifiedby,
              spitem["Editor"]["Title"], items, locals);
          }
          if (spitem["Modified"]) {
            items = this.makeModelPropItem(sem, ModelProperty.modified,
              spitem["Modified"], items, locals);
          }
          let columns = this.state.sharepointdocumentcolumns.split(",");
          let badlist = ["FileLeafRef", "Id", "ID", "name", "value", "zip"];
          for (let field of columns) {
            if (badlist.indexOf(field) > -1) continue;
            if (items.findIndex((x: any) => x.PropName === field) > -1) {
              continue;
            }
            let val = spitem[field];
            if (val) {
              items = this.makeModelPropItem(sem, field,
                val, items, locals);
            }
          }
        }
      }
      if (!mongo.usemongo && docname && this.gprops.usegraph &&
        this.gprops.graphClient) {
        console.debug("Open Graph" + this.gprops.sharepointlibrarysite);
        let spinterface = sem.explorer;
        let lib = this.gprops.sharepointlibrary;
        let site = this.gprops.sharepointlibrarysite;
        let cols = this.gprops.sharepointdocumentcolumns;

        let query = site + "lists/" + lib +
          "/items?$expand=fields&$filter=fields/FileLeafRef eq '" + docname + "'";

        let spitem: any = {};
        try {
          let res = await spinterface.fetchGraphItem(this.gprops.graphClient, query);
          if (res && res.length > 0) {
            spitem = res[0];
          }
        } catch (e) {
          console.debug(e);
          return items;
        }
        if (!spitem || !spitem["fields"]) {
          return items;
        }

        let locals = addDocumentProperties([]);
        let fields = spitem["fields"];
        if (fields && fields["FileLeafRef"]) {
          items = this.makeModelPropItem(sem, "Name", fields["FileLeafRef"], items, locals);
        }
        if (spitem["createdBy"]) {
          items = this.makeModelPropItem(sem, ModelProperty.createdby,
            spitem["createdBy"]["user"]["displayName"], items, locals);
        }
        if (spitem["createdDateTime"]) {
          items = this.makeModelPropItem(sem, ModelProperty.created,
            spitem["createdDateTime"], items, locals);
        }
        if (spitem["lastModifiedBy"]) {
          items = this.makeModelPropItem(sem, ModelProperty.modifiedby,
            spitem["lastModifiedBy"]["user"]["displayName"], items, locals);
        }
        if (spitem["lastModifiedDateTime"]) {
          items = this.makeModelPropItem(sem, ModelProperty.modified,
            spitem["lastModifiedDateTime"], items, locals);
        }
        let columns = cols.split(",");
        for (let field of columns) {
          if (field === "FileLeafRef") continue;
          if (items.findIndex((x: any) => x.PropName === field) > -1) {
            continue;
          }
          let val = fields[field];
          if (val) {
            items = this.makeModelPropItem(sem, field,
              val, items, locals);
          }
        }
      }

      return items;
    }
    return [];
  }



  public newDocumentCallBack = async (template: string) => {
    const sem = this.state.semtalk;
    this.SetFilename("", false);
    if (sem) {
      this.setState({
        hideFileOpen: true,
        hideComposeDialog: true,
        hideTranslateDialog: true,
        hideCustomizeDialog: true,
        hideExpandDialog: true,
        hideCreateDiagramDialog: true,
        hideHelp: true,
        hideInsertDialog: true,
        hideOpenLinkDialog: true,
        hidePropertyDialog: true,
        hideRelationDialog: true,
        hideSelectDialog: true,
        hideShapeStyle: true,
        hideTermSetDialog: true,
        hideSiteBuilderDialog: true,
        showWelcome: false,
        errormsg: "",
        nosuccess: false,
        // filename: "",
        loadedTmpName: template,
        ischeckedout2me: false,
        checkedOut: false
      });
      setCookie(SemTalkCookie.autosaveSemTalk, "");
      this.CreateDocument(sem, template, true);
      this.HidePlanner();
    }
  }

  public getDocumentJSON = () => {
    const sem = this.state.semtalk;
    if (sem) {
      let filename = this.GetFilename();
      const ob = sem.base;
      sem.base.SetModelAttribute(ModelAttribute.modname, filename);
      ob.ObjectName = filename;
      this.saveCurrentGraph();
      const o2j = new OB2JSON();
      let body = o2j.SaveJSON(ob);
      if (sem.page) {
        body.currentpage = sem.page.ID;
      }
      return body;
    }
    return {};
  }

  private saveDocument = async () => {
    const sem = this.state.semtalk;
    let w: any = window;
    let filename = this.GetFilename();
    if (sem && this.state.mongo.iselectron && w.api) {
      if (filename === "") {
        this.savelocalDocument("", "");
        return;
      }
      if (this.state.islocked) {
        return;
      }
      this.saveCurrentGraph();
      const o2j = new OB2JSON();
      let body = o2j.SaveJSON(sem.base);
      if (sem.page) body.currentpage = sem.page.ID;
      if (sem.tempId) body.tempId = sem.tempId;
      const blob: string = JSON.stringify(body);
      w.api.saveDocument({ filename: filename, data: blob });
      sem.ismodified = false;
      if (document.title.endsWith(" *")) {
        document.title = document.title.replace(" *", "");
      }
      return;
    }
    const mongo = this.state.mongo;
    if (!mongo.usemongo && !this.sprops.context && !this.gprops.usegraph) {
      this.download("", ".sdx");
      return;
    }
    // REVIEW!!!!!!
    // let fname = this.state.filename;
    // let fname = this.GetFilename();

    if (sem && (filename.endsWith(".sdx") || filename.endsWith(".bpmn"))) {
      if (sem.readonly) {
        this.alert(
          "Sorry, but this Document is ReadOnly!",
          MessageBarType.blocked
        );
        return;
      }
      if (!sem.BeforeDocumentSave()) {
        this.alert(
          "Sorry, but this Document cannot be saved yet!",
          MessageBarType.blocked
        );
        return;
      }

      const ob = sem.base;
      this.beginTransaction("saveDocument");
      // this.endTransaction("", "saveDocument");
      sem.base.SetModelAttribute(ModelAttribute.modname, filename);
      ob.ObjectName = filename;
      this.saveCurrentGraph();
      const o2j = new OB2JSON();
      let body = o2j.SaveJSON(ob);
      if (sem.page) body.currentpage = sem.page.ID;
      body.tempId = sem.tempId;

      this.setState({ isLoading: sem.getResStr(ResID.STRSAVE).replace("&", "") + ": " + filename });

      if (mongo.usemongo && mongo.semuserlogin && !this.gprops.usegraph) {

        //read Old Doc Version
        let oldisZip = false;
        let resOld = await getzipdoc(mongo, mongo.documents, filename);
        if (!resOld) {
          resOld = await mgGetDocument(mongo, mongo.documents, filename);
        } else {
          oldisZip = true;
        }
        //read Old Doc MetaData
        let olddocmetadata = await this.getMongoDBDocumentMetaData(filename);
        // if (!this.mongodb_current_metadata) {
        //   this.mongodb_current_metadata = await this.getMongoDBDocumentMetaData(filename);
        // }
        let item = olddocmetadata;
        // let item = this.getDocumentMetaData(docname);

        // let item = await mgGetItem(
        //   this.props.mongo,
        //   this.props.mongo.dbname,
        //   this.props.mongo.documents,
        //   filename
        // );

        let res: any = await mgSaveDocument(
          mongo,
          mongo.documents,
          filename,
          body
        );
        if (res.status !== 200) {
          this.alert(
            res.status + " " + JSON.stringify(res.data),
            MessageBarType.error
          );
        } else {
          this.setState({ isLoading: "" });
          this.alert(
            res.status +
            sem.getResStr(ResID.STRSAVETODB) + " " + 
            mongo.documents +
            "/" +
            filename,
            MessageBarType.success
          );
          setTimeout(() => {
            this.alert("", MessageBarType.info);
          }, 5000);

          //Backup old Document Version
          this.backupDocument(filename, resOld, item, oldisZip);
          zipdoc(mongo, mongo.documents, filename, body);
        }

      }
      if (!mongo.usemongo && !this.gprops.usegraph && this.sprops.context) {
        const url3 =
          this.sprops.site +
          "/_api/Web/Lists/getByTitle('" +
          this.sprops.library +
          "')/RootFolder/Files/Add(url='" +
          filename +
          "', overwrite=true)";
        let s = JSON.stringify(body);
        let res = await sem.explorer.postTXT(this.sprops.context, url3, s);
        if (res) {
          this.setState({ isLoading: "" });
          this.alert(
            sem.getResStr(ResID.STRSAVETOLIB) + " " +
            this.sprops.library +
            "/" +
            filename,
            MessageBarType.success
          );
        } // if (res.status !== 200) {
        //   this.alert(res.status + " " + JSON.stringify(res.data), MessageBarType.error);
        // } else {
        //   this.alert(res.status + " Sucessfully saved to Library: " + this.sprops.library + "/" + filename, MessageBarType.success);
        // }
        setTimeout(() => {
          this.alert("", MessageBarType.info);
        }, 10000);
      }
      if (!mongo.usemongo && this.gprops.usegraph && this.gprops.graphClient) {
        let driveid = "";
        let drives = await sem.explorer.fetchGraphItems(
          this.gprops.graphClient,
          this.gprops.sharepointlibrarysite + "drives"
        );
        let lib = this.gprops.sharepointlibrary;
        // this.sprops.library
        for (let drive of drives) {
          if (drive.name === lib) {
            driveid = drive.id;
          }
        }
        if (driveid === "") return;
        let url = "/drives/" + driveid + "/root:/" + filename + ":/content";
        let data = JSON.stringify(body);
        await this.gprops.graphClient.api(url).put(data);
      }
      this.endTransaction("saveDocument");
      sem.ismodified = false;
      if (document.title.endsWith(" *")) {
        document.title = document.title.replace(" *", "");
      }
      this.setState({ isLoading: "" });

      //console.debug(txt2);
    } else {
      this.saveAsDocument(sem!.getResStr(ResID.STRSAVE).replace("&", ""));
    }
    if (sem && this.state.autosaverepository) {
      updateRepository(sem.base, this.props.mongo, this.state.sharepointrepository,
        this, sem, this.gprops.graphClient, []);
    }
  }

  public backupDocument = async (filename: string, oldDoc: any, oldMetaData: any, isZip: boolean) => {
    let vAnz = 3;
    var vAnzC: number = +this.state.versionCount;
    if (vAnzC) vAnz = vAnzC;

    let doc = await mgGetItem(
      this.props.mongo,
      this.props.mongo.dbname,
      this.props.mongo.backup,
      filename
    );

    let c = 0;
    if (doc && doc["name"]) {
      // console.log(doc);
      c = doc["Versions"].length;
    } else {

      let js = { Created: oldMetaData["Created"], CreatedBy: oldMetaData["CreatedBy"], Versions: [] };
      await mgSaveItem(this.props.mongo, this.props.mongo.dbname, this.props.mongo.backup, filename, js);

    }
    if (c >= vAnz) {
      let oldDate;
      let m = await mgGetValue(this.props.mongo, this.props.mongo.dbname, this.props.mongo.backup, filename, "Versions");
      if (m) {
        for (let i = 0; i < vAnz; i++) {
          if (i === 0) {
            c = 0;
            oldDate = m[i]["Modified"];
          } else {
            if (oldDate > m[i]["Modified"]) {
              oldDate = oldDate = m[i]["Modified"];
              c = i;
            }
          }
        }
      }

    }
    let modified = oldMetaData["Modified"];
    if (!oldMetaData["Modified"]) {
      modified = oldMetaData["Created"];
    }
    let modifiedby = oldMetaData["ModifiedBy"];
    if (!oldMetaData["ModifiedBy"]) {
      modifiedby = oldMetaData["CreatedBy"];
    }
    mgSetValue(this.props.mongo, this.props.mongo.dbname, this.props.mongo.backup, filename, "Versions." + c + ".Modified", modified);
    mgSetValue(this.props.mongo, this.props.mongo.dbname, this.props.mongo.backup, filename, "Versions." + c + ".ModifiedBy", modifiedby);
    mgSetValue(this.props.mongo, this.props.mongo.dbname, this.props.mongo.backup, filename, "Versions." + c + ".value", oldDoc);
    mgSetValue(this.props.mongo, this.props.mongo.dbname, this.props.mongo.backup, filename, "Versions." + c + ".isZip", isZip);

  }

  public savelocalDocument = async (filename: string, filetype: string) => {
    if (filename === "") {
      filename = this.GetFilename();
    }
    // if (filename.indexOf(".") < 0) {
    //   filename = filename + filetype;
    // }
    this.resetFileDialog(filename);
    const sem = this.state.semtalk;
    if (sem) {
      const ob = sem.base;
      let p0 = ob.AllDiagrams()[0];
      if (p0) {
        if (p0.ObjectCaption === p0.ClassOf().ObjectCaption) {
          let fname = filename;
          if (fname.indexOf(".") > -1) {
            fname = fname.substring(0, fname.lastIndexOf("."));
          }
          if (fname !== p0.ObjectCaption) {
            if (!ob.FindObjectNamed(fname)) {
              sem.RenameDiagram(p0, fname);
            }
          }
        }
      }
    }
    let w: any = window;
    if (sem && this.state.mongo.iselectron && w.api) {
      let dialog = {
        defaultPath: this.GetFilename(),
        filters: [
          {
            name: "SemTalk",
            extensions: ["sdx"],
          },
        ],
      };
      this.saveCurrentGraph();
      let bpmnblob: string = "";
      if (sem.template === "bpmn") {
        dialog.filters.push({
          name: "BPMN",
          extensions: ["bpmn"],
        });
        bpmnblob = await sem.saveExternalFormat(this, filename, true);
      }
      let blob: string = "";
      const o2j = new OB2JSON();
      let body = o2j.SaveJSON(sem.base);
      if (sem.page) body.currentpage = sem.page.ID;
      if (sem.tempId) body.tempId = sem.tempId;
      blob = JSON.stringify(body);
      let res = await w.api.saveAsDocument({
        dialog: dialog,
        data: blob,
        bpmn: bpmnblob,
      });
      let newfilename = res["filename"];
      let newdata: any = JSON.parse(res["data"]);
      await this.loadDocumentFromJSON(newfilename, newdata, true);
      return;
    }
    // const mongo = this.state.mongo;
    // if (!mongo.usemongo && !this.sprops.context) {
    this.download(filename, filetype);
    // return;
    // }
  }

  private saveAsDocument = async (caption?: string) => {
    const sem = this.state.semtalk;
    if (sem) {
      let title: string = sem.getResStrListener(ResIDL.STRSAVEAS).replace("&", "");
      if (caption) {
        title = caption;
      }
      if (!this.state.mongo.usemongo &&
        !this.sprops.context &&
        !this.gprops.usegraph) {
        this.savelocalDocument("", "");
        return;
      }
      this.setState({
        hideFileOpen: false,
        isopen: false,
        issaveas: true,
        isnew: false,
        isimport: false,
        ismerge: false,
        isdelete: false,
        spexplorercaption: title,
      });
    }
  }

  public saveAsDocumentCallBack = async (filename: string, ext: string) => {
    const sem = this.state.semtalk;
    if (sem && filename.length > 0) {
      if (!sem.BeforeDocumentSave()) {
        this.alert(
          "Sorry, but this Document cannot be saved yet!",
          MessageBarType.blocked
        );
        return;
      }
      const ob = sem.base;
      this.beginTransaction("saveAsDocument");
      // if (!filename.endsWith(".sdx")) {
      //   filename = filename + ".sdx";
      // }
      if (filename.endsWith(".stx")) {
        filename = filename.replace(".stx", ext);
      }
      if (!filename.endsWith(ext)) {
        if (ext === ".stx" && filename.indexOf(".") > 0) {
          let fname = filename.substring(0, filename.lastIndexOf("."));
          filename = fname + ext;
        } else {
          filename = filename + ext;
        }
      }
      if (ext === ".stx") {
        ob.SetModelAttribute(ModelAttribute.patch, "false");
      }
      let oldname = ob.GetModelAttribute(ModelAttribute.modname);
      ob.SetModelAttribute(ModelAttribute.modname, filename);
      ob.ObjectName = filename;
      let p0 = ob.AllDiagrams()[0];
      if (p0) {
        if (p0.ObjectCaption === p0.ClassOf().ObjectCaption) {
          let fname = filename;
          if (fname.indexOf(".") > -1) {
            fname = fname.substring(0, fname.lastIndexOf("."));
            if (fname !== p0.ObjectCaption) {
              if (!ob.FindObjectNamed(fname)) {
                sem.RenameDiagram(p0, fname);
              }
            }
          }
        }
      }
      this.saveCurrentGraph();
      const o2j = new OB2JSON();
      let body = o2j.SaveJSON(ob);
      if (sem.page) body.currentpage = sem.page.ID;
      if (sem.tempId) body.tempId = sem.tempId;
      const mongo = this.state.mongo;
      let resstr: string = "";
      if (mongo.usemongo && mongo.semuserlogin && !this.gprops.usegraph) {
        let lib = mongo.documents;
        if (ext === ".stx") {
          lib = this.state.mongo.templates;
        }

        //read Old Doc Version
        let oldisZip = false;
        let resOld = await getzipdoc(mongo, mongo.documents, filename);
        if (!resOld) {
          resOld = await mgGetDocument(mongo, mongo.documents, filename);
        } else {
          oldisZip = true;
        }
        //read Old Doc MetaData
        if (!this.mongodb_current_metadata) {
          this.mongodb_current_metadata = await this.getMongoDBDocumentMetaData(oldname);
        }
        let mdOld = this.mongodb_current_metadata;
        let modelprops: any = {};
        let comment = "";
        let title = "";
        if (mdOld && mdOld["name"]) {
          modelprops = mdOld;
        }
        else {
          let mp = ob.GetModelAttribute(ModelAttribute.modelProperties);
          if (mp) {
            modelprops = JSON.parse(mp);
          }
        }
        for (let propname in modelprops) {
          if (propname === "Comment") {
            comment = modelprops[propname];
          }
          if (propname === "Title") {
            title = modelprops[propname];
          }

        }
        // let mdOld = await mgGetItem(
        //   this.props.mongo,
        //   this.props.mongo.dbname,
        //   this.props.mongo.documents,
        //   filename
        // );

        try {
          let res: any = await mgSaveDocumentAs(mongo, lib, filename, body);
          if (res.status !== 201) {
            this.alert(
              res.status + " " + JSON.stringify(res.data),
              MessageBarType.error
            );
          } else {
            let tpl = ob.GetModelAttribute(ModelAttribute.Template);
            if (ext === ".stx") {
              tpl = filename;
            }

            mgSetValue(
              this.props.mongo,
              this.props.mongo.dbname,
              lib,
              filename,
              "Template",
              tpl
            );
            mgSetValue(
              this.props.mongo,
              this.props.mongo.dbname,
              lib,
              filename,
              "Comment",
              comment
            );
            mgSetValue(
              this.props.mongo,
              this.props.mongo.dbname,
              lib,
              filename,
              "Title",
              title
            );
            resstr = sem.getResStr(ResID.STRSAVETODB) + " " + lib + "/" + filename;
            if (resOld && resOld["name"] && mdOld && mdOld["name"]) {
              //Backup old Document Version
              this.backupDocument(filename, resOld, mdOld, oldisZip);
            }
            zipdoc(mongo, lib, filename, body);
            this.ResetModelProperties();
          }
        } catch (e) {
          this.alert((e as any), MessageBarType.error);
        }
      }
      if (!mongo.usemongo && !this.gprops.usegraph && this.sprops.context) {
        let site = this.sprops.librarysite;
        let lib = this.sprops.library;
        if (ext === ".stx") {
          site = this.sprops.site;
          lib = this.sprops.templates;
        }
        const url3 =
          site +
          "/_api/Web/Lists/getByTitle('" +
          lib +
          "')/RootFolder/Files/Add(url='" +
          filename +
          "', overwrite=true)";
        let s = JSON.stringify(body);
        try {
          let res = await sem.explorer.postTXT(this.sprops.context, url3, s);
          if (res) {
            this.alert(
              sem.getResStr(ResID.STRSAVETOLIB) + " " +
              lib +
              "/" +
              filename,
              MessageBarType.success
            );
          } // if (res.status !== 201) {
          //   this.alert(res.status + " " + JSON.stringify(res.data), MessageBarType.error);
          // } else {
          //   resstr = res.status + " Sucessfully saved to Library: " + this.sprops.library + "/" + filename;
          // }
        } catch (e) {
          this.alert((e as any), MessageBarType.error);
        }
      }
      if (!mongo.usemongo && this.gprops.usegraph && this.gprops.graphClient) {
        let driveid = "";
        let drives = await sem.explorer.fetchGraphItems(
          this.gprops.graphClient,
          this.gprops.sharepointlibrarysite + "drives"
        );
        let lib = this.gprops.sharepointlibrary;
        // lib = this.sprops.library;
        for (let drive of drives) {
          if (drive.name === lib) {
            driveid = drive.id;
          }
        }
        if (driveid === "") return;
        let url = "/drives/" + driveid + "/root:/" + filename + ":/content";
        let data = JSON.stringify(body);
        try {
          await this.gprops.graphClient.api(url).put(data);
        } catch (e) {
          this.alert((e as any), MessageBarType.error);
        }
      }

      // console.debug(txt2);
      if (sem) {
        sem.readonly = false;
      }
      // this.loadDocument(filename);
      this.endTransaction("saveAsDocument");
      if (ext === ".stx") {
        if (sem) await this.CreateDocument(sem, filename, true);
      } else {
        this.LoadDocument(filename, false);
      }
      this.alert(resstr, MessageBarType.success);
      setTimeout(() => {
        this.alert("", MessageBarType.info);
      }, 5000);
      if (sem) {
        sem.ismodified = false;
        if (document.title.endsWith(" *")) {
          document.title = document.title.replace(" *", "");
        }
      }
      this.resetFileDialog(filename);
    }
  }

  public saveCurrentGraph = () => {
    let sem = this.state.semtalk;
    if (sem) {
      let pg = sem.page;
      if (pg !== null && pg !== undefined) {
        const ob = sem.base;
        let xml = sem.tseditor.getGraphXml();
        let encoded = this.encode(xml);
        ob.SetModelAttribute(
          SemTalkBaseConstant.SLMXGPagePrefix + pg.ID,
          encoded
        );
        pg.SetValue(
          SemTalkBaseConstant.SLMXGAttribute,
          SemTalkBaseConstant.SLMXGPagePrefix + pg.ID
        );
      }
    }
  }

  private addPage = () => {
    this.setState({ hideCreateDiagramDialog: false });
  }

  public newPage = async (
    pname: string,
    parent: ISemTalkObject | null,
    diagtype: ISemTalkDiagramType,
    init: boolean
  ): Promise<ISemTalkDiagram | null> => {
    let sem = this.state.semtalk;
    if (sem) {
      let pg = sem.page;
      if (pg !== null) {
        // pg.save();
        const ob = sem.base;
        let pgn = ob.FindDiagram(pname);
        if (pgn === null) {
          let newid = ob.NewID();
          if (pname === "") {
            pname = diagtype.ObjectCaption + "." + newid;
          }
          pgn = ob.MakeDiagram(
            diagtype,
            pname,
            SemTalkType.SemTalkDiagram,
            newid
          );
          if (parent !== null) {
            let noev = sem.noevents;
            sem.noevents = true;
            parent.Refinement = pgn;
            sem.noevents = noev;
          }
          pgn.SetValue(
            SemTalkBaseConstant.SLMXGAttribute,
            SemTalkBaseConstant.SLMXGPagePrefix + pgn.ID
          );
        }
        // this.endTransaction("", "newPage");
        setCookie(SemTalkCookie.autosaveSemTalk, "");

        sem.visPageAdded(pgn);
        sem.page = pgn;

        let decoded = this.getmxGraphXML(pgn);
        let xml = this.patchEPC(decoded);
        setCookie(SemTalkCookie.autosaveSemTalk, "");
        sem.tseditor.resetEditor(
          xml,
          pgn.ObjectCaption,
          pgn.ID,
          sem.horizontal,
          true
        );
        sem.setBundles();

        sem.loadDiag(pgn.ID, init);
        // if (init) {
        //   sem.initPage(pgn);
        // }
        if (xml === "") {
          xml = sem.tseditor.getGraphXml();
          let encoded = this.encode(xml);
          ob.SetModelAttribute(
            SemTalkBaseConstant.SLMXGPagePrefix + pgn.ID,
            encoded
          );
        }

        let cntpages = sem.base.AllDiagrams().length;
        this.setState({
          // xmlgraph: xml,
          diag: pgn,
          cntpages: cntpages,
          // diaglist: lis,
        });
        this.updateDiaglist();

        if (this.state.autorefreshreferences) {
          refreshPage(sem, this.gprops.graphClient, this.state.mongo);
        }
        addDocumentToHistory(this.filename, pgn.ID);
        await this.loadPage(pgn.ID, sem, init);
        if (parent && parent.ObjectType === SemTalkType.SemTalkClass) {
          sem.InsertObjects([parent], this.click_x, this.click_y, false, false, "", pgn, false);
        }
        gotoDocument(pgn.ID);
        return pgn;
      }
    }
    return null;
  }

  private deletePageConfirmed = (): void => {
    const sem = this.state.semtalk;
    if (sem) {
      this.beginTransaction("deletePage");
      const ob = sem.base;
      if (ob.AllDiagrams().length === 1) {
        this.alert(
          "The last page of a document cannot be deleted!",
          MessageBarType.blocked
        );
      } else {
        if (sem.page) {
          sem.page.Delete();
          this.updateDiaglist();
          const diag = ob.AllDiagrams()[0];
          gotoDocument(diag.ID);
        }
      }
      this.endTransaction("deletePage");
    }
  }

  private deletePage = (): void => {
    this.setState({ isconfirmdeletepage: !this.state.isconfirmdeletepage });
  }

  private updateDiaglist() {
    let sem = this.state.semtalk;
    if (sem) {
      sem.updateDiaglist();
    }
  }

  private stopPanning = () => {
    this.setState({ isconnectionpoints: false });
    let sem = this.state.semtalk;
    if (sem && sem.tseditor) sem.tseditor.stopPanning();
  }

  private startPanning = () => {
    this.setState({ isconnectionpoints: false });
    let sem = this.state.semtalk;
    if (sem && sem.tseditor) sem.tseditor.startPanning();
  }

  private connectionPoints = () => {
    let sem = this.state.semtalk;
    if (sem && sem.tseditor) sem.tseditor.connectionPoints();
    this.setState({ isconnectionpoints: true });
  }

  public isConnectionPoints = (): boolean => {
    return this.state.isconnectionpoints;
  }

  private zoomIn = () => {
    let sem = this.state.semtalk;
    if (sem && sem.tseditor) sem.tseditor.zoom(zoomoption.zoomin);
  }

  private zoomOut = () => {
    let sem = this.state.semtalk;
    if (sem && sem.tseditor) sem.tseditor.zoom(zoomoption.zoomout);
  }

  private zoomFit = () => {
    let sem = this.state.semtalk;
    if (sem && sem.tseditor) sem.tseditor.zoom(zoomoption.zoomfit);
  }

  private zoomActual = () => {
    let sem = this.state.semtalk;
    if (sem && sem.tseditor) sem.tseditor.zoom(zoomoption.zoomactual);
  }

  private center = () => {
    let sem = this.state.semtalk;
    if (sem && sem.tseditor) sem.tseditor.center();
  }

  private Undo = async () => {
    let sem = this.state.semtalk;
    if (sem && sem.tseditor && sem.tseditor.isUndo()) {
      sem.tseditor.Undo();
      return;
    }
    if (sem && sem.page && this.undoManger.IsUndo()) {
      let s1 = this.undoManger.GetUndo();
      if (s1 === "") return;
      let pid = sem.page.ID; // was ist wenn die Seite grade gelöscht wurde?
      console.debug("undo..");

      const ob = new ObjectBase();
      const o2j = new OB2JSON();
      let s: any = {};
      try {
        s = JSON.parse(s1);
        console.debug("Undo: ", s.transaction);
        o2j.LoadJSON(ob, s);
        sem.init(ob, this.state.guilanguage);
        // sem.hasVector = this.hasvector;
        // this.initVectors();
        // sem.initSubTask(ob);

        await this.loadPage(pid, sem, false);
      } catch (e) {
        console.debug(e);
      }

      // this.zoomActual();

      this.undoManger.Undo();
      sem.clearUndo();
    }
  }

  private Redo = async () => {
    let sem = this.state.semtalk;
    if (sem && sem.tseditor && sem.tseditor.isRedo()) {
      sem.tseditor.Redo();
      return;
    }
    let s1 = this.undoManger.GetRedo();
    if (sem && sem.page && s1 && s1.length > 0) {
      let pid = sem.page.ID; // was ist wenn die Seite grade gelöscht wurde?
      console.debug("redo..");

      const ob = new ObjectBase();
      const o2j = new OB2JSON();
      let s: any = {};
      try {
        s = JSON.parse(s1);
        console.debug("Redo: ", s.transaction);
      } catch (e) {
        console.debug(e);
      }
      o2j.LoadJSON(ob, s);
      sem.init(ob, this.state.guilanguage);
      // sem.hasVector = this.hasvector;
      // this.initVectors();

      await this.loadPage(pid, sem, false);

      // this.zoomActual();

      this.undoManger.Redo();
      sem.clearUndo();
    }
  }

  public beginTransaction = (reason: string): void => {
    let sem = this.state.semtalk;
    if (sem && this.autosave) {
      if (reason === "mxGraph") {
        // return;
      }
      let transaction = sem.currentTransaction();
      if (transaction === "") {
        sem.beginTransaction(reason);
      }
    }
  }

  public endTransaction = (reason: string): void => {
    let sem = this.state.semtalk;
    if (sem) {
      let transaction = sem.currentTransaction();
      if (this.autosave && transaction === reason) {
        // if (reason !== "mxGraph") {
        sem.endTransaction(reason);
        // }
        let xml = sem.tseditor.getGraphXml();
        this.setState({
          xmlgraph: xml,
        });
      } else {
        sem.ismodified = true;
        if (!document.title.endsWith(" *")) {
          document.title += " *";
        }
      }
    }
  }

  private autoSave = () => {
    if (this.state.islocked) return;
    let sem = this.state.semtalk;
    if (sem) {
      let xml = sem.tseditor.getGraphXml();
      sem.autoSave(xml, "CommandAutoSave");
    }
  }

  private popupMenuRef = null; //React.useRef(null);

  public shapeCreatePopupMenu = (
    _graph: mxGraph,
    _menu: mxPopupMenuHandler,
    cell: mxCell,
    evt: any,
    _edi: any
  ): void => {
    let sem = this.state.semtalk;
    if (evt && sem) {
      // this.click_x = evt.layerX;
      // this.click_y = evt.layerY;
      let mousePos: mxPoint = sem.tseditor.graph.getPointForEvent(evt, false);
      this.click_x = mousePos.x;
      this.click_y = mousePos.y;
      this.popupMenuRef = evt;
      this.onShowContextualMenu(cell, evt);
    }
  }

  public shapeSelectionChanged = (cell: mxCell | undefined, evt: any): void => {
    let e = evt.properties["event"];
    let sem = this.state.semtalk;
    if (e && sem) {
      // this.click_x = e.layerX;
      // this.click_y = e.layerY;
      let mousePos: mxPoint = sem.tseditor.graph.getPointForEvent(evt, false);
      this.click_x = mousePos.x;
      this.click_y = mousePos.y;
    }

    gotoShape(cell);

    if (cell && this.state.diag !== null) {
      if (sem) {
        let node = this.state.diag.FindNodeOfShape(sem.getMxId(cell));
        // if (node === null) {
        //   node = this.state.diag.FindNodeOfShape(this.state.semtalk.getMxId(cell.shapeid));
        // }
        if (node !== null) {
          let ob = sem.base;
          let inst = ob.FindInstanceByID(node.Model.ID);
          if (inst) {
            for (let role of inst.LinkedObjects(SemTalkBaseConstant.SLDisplays)) {
              gotoObject(role.ID);
              return;
            }
          }
          gotoNode(node.ID, node.Model.ID, node.Diagram.ID, node.ShapeID, false, false);

          if (this.state.role === SemTalkRole.viewer) {
            if (
              evt &&
              evt.properties.event &&
              evt.properties.event.ctrlKey) {
            } else {
              if (this.is_goto_start_event || this.is_goup) {
                this.is_goto_start_event = false;
                this.is_goup = false;
              } else {
                let obj = node.Model;
                if (obj.Refinement) {
                  gotoDocument(obj.Refinement.ID);
                  return;
                }
                let up = sem.visCellGoUp(obj.ID);
                if (up) {
                  for (let unode of up.Nodes()) {
                    gotoNode(unode.ID, unode.Model.ID, unode.Diagram.ID, unode.ShapeID);
                    return;
                  }
                }
              }
            }
          }
          // console.log(node);
          if (evt && evt.properties.event &&
            evt.properties.event.shiftKey === true &&
            sem.tseditor && !sem.tseditor.isEditing(cell)) {
            this.editCell(cell);
          }
        }
      }
    } else {
      // if (sem) {
      //   sem.shape = null;
      // }
    }
    // alert("shapeSelectionChanged");
    // gotoObject(cell.objectid);
  }

  private editPage = (): void => {
    if (this.state.diag !== null) {
      gotoObject(this.state.diag.ID);
      this.setState({ hidePropertyDialog: false });
    }
  }

  public editCell = (cell: mxCell): void => {
    const sem = this.state.semtalk;
    if (sem && sem.tseditor && !sem.tseditor.isEditing(cell)) {
      let obj = sem.base.FindInstanceByID(cell.objectid);
      if (obj !== null && sem.base.IsInstance(obj)) {
        switch (obj.ClassOf().ObjectName) {
          case sem.base.GetModelAttribute(ModelAttribute.SLComment): {
            const defof = sem.base.GetModelAttribute(ModelAttribute.SLCommentOf);
            for (let c of obj.LinkedObjects(defof, false, SemTalkRelation.SemTalkSystemRelation)) {
              gotoObject(c.ID);
              this.setState({ hidePropertyDialog: false });
              return;
            }
            return;
          }
          case SemTalkBaseConstant.SLSwimlane: {
            for (let c of obj.LinkedObjects(SemTalkBaseConstant.SLDisplays)) {
              gotoObject(c.ID);
              this.setState({ hidePropertyDialog: false });
              return;
            }
            return;
          }
          default: {
            gotoObject(cell.objectid);
            this.setState({ hidePropertyDialog: false });
          }
        }
        return;
      }
    }
    if (sem) {
      let obj = sem.base.FindClassByID(cell.objectid);
      if (obj !== null) {
        gotoObject(cell.objectid);
        this.setState({ hidePropertyDialog: false });
      }
    }
  }

  // private refineCell = (cell: mxCell) => {
  //   gotoObject(cell.objectid);
  //   this.refineObject();
  // }

  public editObject = (id: SemTalkID | null): void => {
    const sem = this.state.semtalk;
    if (sem) {
      if (id === null) {
        let cell = sem.currentShape();
        if (cell && cell.objectid && sem.tseditor && !sem.tseditor.isEditing(cell)) {
          this.editCell(cell);
          return;
        }
      } else {
        gotoObject(id);
        this.setState({ hidePropertyDialog: false });
      }
    }
    // this.setState({ hidePropertyDialog: false });
  }

  private customizeObject = () => {
    this.setState({ hideCustomizeDialog: false });
  }

  private refreshObject = async () => {
    const sem = this.state.semtalk;
    if (sem) {
      const oid: SemTalkID | null = getObject();
      if (oid !== null) {
        const obj = sem.base.FindObjectByID(oid);
        if (obj && obj.EditLink) {
          refreshObject(sem, obj, this.gprops.graphClient, this.state.mongo);
        }
      }
    }
  }

  private refreshPage = async () => {
    const sem = this.state.semtalk;
    if (sem) {
      refreshPage(sem, this.gprops.graphClient, this.state.mongo);
    }
  }

  private reorderPages = async () => {
    const sem = this.state.semtalk;
    if (sem) {
      sem.reorderPages();
    }
  }

  private speakObject = () => {
    const sem = this.state.semtalk;
    const oid: SemTalkID | null = getObject();
    if (sem && oid !== null) {
      const obj = sem.base.FindObjectByID(oid);
      if (obj) {
        let input = obj.ObjectCaption;
        if (Process_ElementName !== undefined) {
          for (let info of obj.LinkedObjects(sem.base.GetModelAttribute(Process_ElementName.SLInfoType))) {
            input += " " + info.ObjectCaption;
          }
        }
        for (let commented of obj.LinkedObjects(sem.base.GetModelAttribute(ModelAttribute.SLCommentOf),
          false, SemTalkRelation.SemTalkSystemRelation)) {
          input = commented.Comment;
        }
        var msg = new SpeechSynthesisUtterance(input);
        msg.lang =
          this.state.guilanguage + "-" + this.state.guilanguage.toUpperCase(); // 'de-DE';
        // msg.voice = 'native'; msg.voice = 'Google US English'; //  'Google UK English Female'
        // msg.voice = 'Google US English'
        msg.volume = 1;
        msg.rate = 1;
        msg.pitch = 1;
        try {
          window.speechSynthesis.cancel();
          window.speechSynthesis.speak(msg);
        } catch (e) {
          console.debug(e);
        }
      }
    }
  }
  private speakchatGPT = async (question: string) => {
    let openai = this.state.openai;
    if (openai === null) {
      if (this.state.chatgpt.length > 1) {
        const key = new Configuration({
          apiKey: decodechatgpt(this.state.chatgpt),
        });
        openai = new OpenAIApi(key);
        this.setState({ openai: openai });
      }
    }
    let sem = this.state.semtalk;
    if (sem && sem.page && openai) {

      let diag = sem.page;
      let sel = sem.tseditor.getSelectionCells();
      let txt = "";
      if (sel && sel.length > 0) {
        txt = sel.filter((shp) => shp.value).map((shp) => shp.value).join(", ");
      }
      let cap = txt;
      let pcap = "";
      if (sem.page) {
        pcap = sem.page.ObjectCaption;
        if (sem.page) {
          pcap += sem.getResStr(ResID.STRINCONTEXT) + sem.page.BreadCrumbs()
            .map((d) => d.ObjectCaption).join(", ");
        }
      }
      cap = cap.replace(/\n/g, "");
      question = question.replace("$SELECTION", cap);
      question = question.replace("$PAGE", pcap);

      let isprocess: boolean = (diag.ClassOf().ObjectName === SemTalkBaseConstant.SLGeneric);

      this.SetProgressIndicator(sem.getResStrListener(ResIDL.STRTHINKINGABOUT) + question);
      let sentences = sem.textGenerator(sem, sem.page, !isprocess);
      // sentences[sentences.length] = question;
      sentences.pop();
      let answer = "";
      answer = await askQuestion(openai, sentences, question);
      this.SetProgressIndicator("");
      if (answer) {
        let txt1 = answer;
        if (txt1.length > 200) {
          txt1 = txt1.substring(0, 199);
        }
        var msg = new SpeechSynthesisUtterance(txt);
        msg.lang = this.state.guilanguage + "-" + this.state.guilanguage.toUpperCase(); // 'de-DE';
        // msg.voice = 'native'; msg.voice = 'Google US English'; //  'Google UK English Female'
        // msg.voice = 'Google US English'
        msg.volume = 1;
        msg.rate = 1;
        msg.pitch = 1;
        try {
          // window.speechSynthesis.cancel();
          // window.speechSynthesis.speak(msg);
          // var r = setInterval(() => {
          //   console.log(window.speechSynthesis.speaking);
          //   if (!window.speechSynthesis.speaking) clearInterval(r);
          //   else window.speechSynthesis.resume();
          // }, 14000);

          window.speechSynthesis.cancel();
          window.speechSynthesis.speak(msg);
          copy(answer);
        } catch (e) {
          this.alert((e as any).message, MessageBarType.info);
        }
      }
    }
  }

  private editClass = () => {
    const sem = this.state.semtalk;
    if (sem) {
      const oid: SemTalkID | null = getObject();
      if (oid !== null) {
        const obj = sem.base.FindObjectByID(oid);
        if (obj !== null && sem.base.IsInstance(obj)) {
          let ins: ISemTalkInstance = obj as ISemTalkInstance;
          const cid: SemTalkID = ins.ClassOf().ID;
          this.editObject(cid);
        }
      }
    }
  }

  private refineObject = async () => {
    const sem = this.state.semtalk;
    if (sem && sem.page) {
      const oid: SemTalkID | null = getObject();
      let npg: ISemTalkDiagram | null = null;
      if (oid !== null) {
        const obj = sem.base.FindObjectByID(oid);
        let pgcl = sem.page.ClassOf();
        if (obj !== null) {
          if (obj.Refinement === null && obj.ExtRefinement) {
            if (sem.ismodified && !this.state.islocked) {
              if (!window.confirm(sem.getResStr(ResID.STRLEAVEWITHOUTSAVING))) {
                return;
              }
            }
            this.autoSave();
            this.LoadDocument(obj.ExtRefinement, true);
            return;
          }
          this.beginTransaction("refineObject");
          if (obj.Refinement === null) {
            if (obj.ExtRefinement === null) {
              npg = sem.base.FindDiagramByName(obj.ObjectCaption);
              if (npg !== null) {
                obj.Refinement = npg;
                this.autoSave();
                gotoDocument(npg.ID);
              } else {
                let sc = obj.SystemClass();
                if (sc?.ObjectCaption === obj.ObjectCaption) {
                  this.alert(
                    "'" +
                    sc.ObjectCaption +
                    "' is not a good name for a Refinement!",
                    MessageBarType.blocked
                  );
                } else {
                  this.autoSave();
                  npg = await this.newPage(obj.ObjectCaption, obj, pgcl, true);
                }
              }
            } else {
            }
          } else {
            this.autoSave();
            gotoDocument(obj.Refinement.ID);
            // npg = await this.newPage(obj.Refinement.ObjectName, null, pgcl, true);
          }
          // this.autosave = true;
          this.endTransaction("refineObject");
        }
      }
      // if (npg !== null) {
      //   if (!this.gotoStartEvent(npg)) {
      //     // gotoDocument(npg.ID);
      //   }
      // }
    }
  }

  private goUp = () => {
    const sem = this.state.semtalk;
    if (sem) {
      if (sem.page) {
        // for (let d of sem.page.InvRefinements()) {
        //   gotoDocument(d.ID);
        //   break;
        // }
        for (let o of sem.page.RefinedObjects()) {
          for (let node of o.Nodes()) {
            this.is_goup = true;
            gotoNode(
              node.ID,
              node.Model.ID,
              node.Diagram.ID,
              node.ShapeID,
              true
            );
            return;
          }
        }
      }
    }
  }

  private detachObject = () => {
    const sem = this.state.semtalk;
    if (sem) {
      this.beginTransaction("detachObject");
      const oid: SemTalkID | null = getObject();
      if (oid !== null) {
        const obj = sem.base.FindObjectByID(oid);
        if (obj !== null) {
          obj.Refinement = null;
        }
      }
      this.endTransaction("detachObject");
    }
  }

  private addShape = (
    shp: SemTalkShape): mxCell | null => {
    const sem = this.state.semtalk;
    if (sem && shp) {
      this.click_x += 50;
      this.click_y += 50;
      let shp2 = sem.tseditor!.createVertex(null, null, "", this.click_x, this.click_y, 100, 100,
        SemTalkStyleAttribute.shape + "=" + shp.key);
      sem.graph.setSelectionCell(shp2);
      sem.graph.startEditingAtCell(shp2);
    }
    return null;
  }
  public insertQuickShape = (
    shp: SemTalkShape,
    cell: mxCell | null
  ): mxCell | null => {
    const sem = this.state.semtalk;
    if (sem) {
      let shp2 = sem.insertQuickShape(shp, cell);
      if (shp2) {
        sem.graph.setSelectionCell(shp2);
        if (this.state.hideComposeDialog &&
          this.state.hidePropertyDialog &&
          !this.state.showTableEditor) {
          sem.graph.startEditingAtCell(shp2);
        }
      }
    }
    return null;
  }

  public isBackward = (): boolean => {
    return isBackward();
  }

  public isForward = (): boolean => {
    return isForward();
  }

  private goBackForward = (s: number) => {
    let f = accessCookie(SemTalkCookie.history);
    if (f) {
      let entries: IHistory = JSON.parse(f);
      let len: number = 1;
      if (accessCookie(SemTalkCookie.historypos) === null) {
        len = Object.keys(entries.history).length + s;
      } else {
        len = Number(accessCookie(SemTalkCookie.historypos)) + s;
      }
      let entry: IHistoryEntry = entries.history[len];
      if (entry) {
        setCookie(SemTalkCookie.historypos, String(len));
        if (entry.filename === entries.history[len - s].filename) {
          gotoDocument(entry.id);
        } else {
          if (entry.filename !== "") {
            this.LoadDocument(entry.filename, true);
          }
        }
      }
    }
  }

  private deleteShapes = () => {
    const sem = this.state.semtalk;
    if (sem) {
      this.beginTransaction("deleteShapes");
      sem.deleteShapes();
      this.endTransaction("deleteShapes");
    }
  }

  private selectAll = () => {
    const sem = this.state.semtalk;
    if (sem) {
      sem.tseditor.selectAll();
    }
  }

  private connectShapes = () => {
    const sem = this.state.semtalk;
    if (sem) {
      this.beginTransaction("connectShapes");
      const cellsSelected = sem.graph.getSelectionCells();
      const cnt = cellsSelected.length;
      let i = 0;
      if (cnt > 1) {
        while (i < cnt - 1) {
          let src = cellsSelected[i];
          let dst = cellsSelected[i + 1];
          let lnk = sem.createEdge(src, dst);
          if (src.objectid && dst.objectid) {
            sem.visConnectionAdded(undefined, lnk, src, dst);
          }
          i += 1;
        }
      }
      this.endTransaction("connectShapes");
    }
  }

  private bringToFront = (cell: mxCell | undefined) => {
    const sem = this.state.semtalk;
    if (sem && cell) {
      this.beginTransaction("bringToFront");
      // const cellsSelected = sem.graph.getSelectionCells();
      sem.tseditor.bringToFront([cell]);
      this.endTransaction("bringToFront");
    }
  }

  private sendToBack = (cell: mxCell | undefined) => {
    const sem = this.state.semtalk;
    if (sem && cell) {
      this.beginTransaction("sendToBack");
      // const cellsSelected = sem.graph.getSelectionCells();
      sem.tseditor.sendToBack([cell]);
      this.endTransaction("sendToBack");
    }
  }

  private groupShapes = () => {
    const sem = this.state.semtalk;
    if (sem) {
      this.beginTransaction("groupShapes");
      sem.tseditor.groupCells("group", "");
      this.endTransaction("groupShapes");
    }
  }

  private ungroupShapes = () => {
    const sem = this.state.semtalk;
    if (sem) {
      this.beginTransaction("ungroupShapes");
      const cellsSelected = sem.graph.getSelectionCells();
      sem.tseditor.ungroupCells(cellsSelected);
      this.endTransaction("ungroupShapes");
    }
  }

  private deleteObject = () => {
    const sem = this.state.semtalk;
    if (sem) {

      let sel: mxCell[] = sem.graph.getSelectionCells();
      this.beginTransaction("deleteObject");
      for (let cell of sel) {
        if (cell.objectid) {
          let oid = cell.objectid;
          const obj = sem.base.FindObjectByID(oid);
          if (obj !== null) {
            obj.Delete();
          }
        }
      }
      this.endTransaction("deleteObject");
    }
  }

  private deleteHierarchy = () => {
    const sem = this.state.semtalk;
    if (sem) {
      const oid: SemTalkID | null = getObject();
      if (oid !== null) {
        const obj = sem.base.FindObjectByID(oid);
        if (obj !== null) {
          this.beginTransaction("deleteHierarchy");
          let cla = obj as ISemTalkClass;
          let insts = cla.AllInstances();
          let subs = cla.AllSubClasses().reverse();
          for (let x of insts) {
            x.Delete();
          }
          for (let x of subs) {
            x.Delete();
          }
          this.endTransaction("deleteHierarchy");
          // }
        }
      }
    }
  }
  private toClass = () => {
    const sem = this.state.semtalk;
    if (sem) {
      const oid: SemTalkID | null = getObject();
      if (oid !== null) {
        // const shp = sem.currentShape();
        // if (shp && shp.objectid === oid) {
        //   sem.graph.removeCells([shp]);
        // }
        const obj = sem.base.FindInstanceByID(oid);
        if (obj !== null) {
          if (obj.ObjectType === SemTalkType.SemTalkInstance) {
            this.beginTransaction("toClass");
            let shapes: mxCell[] = [];
            for (let node of obj.Nodes()) {
              if (node.Diagram === sem.page) {
                let shp = sem.FindShapeByShapeID(node.ShapeID);
                if (shp && shp.objectid === obj.ID) {
                  shapes.push(shp);
                }
              }
            }
            let newclass = obj.ToClass();
            if (newclass) {
              for (let shp of shapes) {
                shp.objectid = newclass.ID;
                shp.shapeKey = SemTalkMaster.MasterClass;
                // shp.shapeLabel = SemTalkMaster.MasterClass;
                shp.shapeName = SemTalkMaster.MasterClass;
                shp.style = GENERIC_SHAPES[SemTalkMaster.MasterClass].style;
                sem.visShapeAdded(shp, newclass.ID);
              }
              sem.UpdateLabel(newclass);
            }
            this.endTransaction("toClass");
          }
        }
      }
    }
  }
  private toInstance = () => {
    const sem = this.state.semtalk;
    if (sem) {
      const oid: SemTalkID | null = getObject();
      if (oid !== null) {
        // const shp = sem.currentShape();
        // if (shp && shp.objectid === oid) {
        //   sem.graph.removeCells([shp]);
        // }
        const obj = sem.base.FindClassByID(oid);
        if (obj !== null) {
          if (obj.ObjectType === SemTalkType.SemTalkClass) {
            this.beginTransaction("toInstance");
            let shapes: mxCell[] = [];
            for (let node of obj.Nodes()) {
              if (node.Diagram === sem.page) {
                let shp = sem.FindShapeByShapeID(node.ShapeID);
                if (shp && shp.objectid === obj.ID) {
                  shapes.push(shp);
                }
              }
            }
            let newinst = obj.ToInstance();
            if (newinst) {
              for (let shp of shapes) {
                shp.objectid = newinst.ID;
                shp.shapeKey = SemTalkMaster.MasterInstance;
                shp.shapeName = SemTalkMaster.MasterInstance;
                shp.style = GENERIC_SHAPES[SemTalkMaster.MasterInstance].style;
                sem.visShapeAdded(shp, newinst.ID);
              }
              sem.UpdateLabel(newinst);
            }
            this.endTransaction("toInstance");
          }
        }
      }
    }
  }
  private compactShape = () => {
    const sem = this.state.semtalk;
    if (sem) {
      const shp = sem.currentShape();
      if (shp && shp.style.startsWith(SemTalkStyleAttribute.shape + "=" + SemTalkBuiltInShape.genericclass + ";")) {
        if (shp["hideattributes"]) {
          delete shp["hideattributes"];
        } else {
          shp["hideattributes"] = 1;
        }
        sem.redraw();
      }
    }
  }

  private redraw = async () => {
    const sem = this.state.semtalk;
    if (sem) {
      sem.EnsureSwimlaneContent();
      let props = await this.getModelProperties(this.filename);
      sem.updateTextFields(props);
      await this.loadModelSettings();
      sem.redraw();
      this.autoSave();
    }
  }

  public updateModelTextFields = async () => {
    const sem = this.state.semtalk;
    if (sem) {
      if (this.filename && !this.filename.endsWith(".stx")) {
        let props = await this.getModelProperties(this.filename);
        sem.updateTextFields(props);
        await this.loadModelSettings();
        this.autoSave();
      }
    }
  }

  // private composeCell = (cell: mxCell): void => {
  //   gotoObject(cell.objectid);
  //   this.setState({ hideComposeDialog: false });
  // }

  private composeObject = (): void => {
    const sem = this.state.semtalk;
    if (sem) {
      const oid: SemTalkID | null = getObject();
      if (oid !== null) {
        gotoObject(oid);
      }
      this.setState({ hideComposeDialog: false });
    }
  }

  public composeObjectCallback = (_oclass: ISemTalkClass): void => {
    //   const sem = this.state.semtalk;
    //   if (sem) {
    //   const ob = sem.base;
    // }
  }

  public uncomposeObject = (): void => {
    const sem = this.state.semtalk;
    if (sem) {
      const oid: SemTalkID | null = getObject();
      if (oid !== null) {
        const bas = sem.base;
        const ins = bas.FindInstanceByID(oid);
        if (ins !== null) {
          if (ins.ClassOf().Composition()) {
            this.beginTransaction("uncomposeObject");
            ins.ClassOf().Composition()?.Delete();
            this.endTransaction("uncomposeObject");
          }
        }
      }
    }
  }

  private translateObject = (): void => {
    const sem = this.state.semtalk;
    if (sem) {
      const oid: SemTalkID | null = getObject();
      if (oid !== null) {
        gotoObject(oid);
      }
      this.setState({ hideTranslateDialog: false });
    }
  }

  private plannerTasks = (): void => {
    const sem = this.state.semtalk;
    if (sem) {
      this.setState({ hideTasksDialog: false });
    }
  }

  private planner = (): void => {
    const sem = this.state.semtalk;
    if (sem) {
      this.setState({ hidePlannerDialog: false });
    }
  }

  private hyperlinkObject = (): void => {
    const sem = this.state.semtalk;
    if (sem) {
      const oid: SemTalkID | null = getObject();
      if (oid !== null) {
        gotoObject(oid);
      }
      this.setState({ hideHyperlinkDialog: false });
    }
  }

  private collapseCell = (cell: mxCell | undefined): void => {
    const sem = this.state.semtalk;
    if (sem && cell) {
      this.beginTransaction("collapseCell");
      sem.tseditor.toggleCollapse(cell);
      this.endTransaction("collapseCell");
    }
  }

  private elbowCell = (cell: mxCell): void => {
    const sem = this.state.semtalk;
    if (sem) {
      this.beginTransaction("elbowCell");
      sem.tseditor.elbowCell(cell);
      this.endTransaction("elbowCell");
    }
  }
  private setStyle = (args: any): void => {
    const sem = this.state.semtalk;
    if (sem) {
      this.beginTransaction("setStyle");
      sem.tseditor.setStyle(args);
      this.endTransaction("setStyle");
    }
  }
  private insertWayPointToCell = (cell: mxCell): void => {
    const sem = this.state.semtalk;
    if (sem) {
      this.beginTransaction("insertWayPointToCell");
      sem.tseditor.insertWayPointToCell(cell);
      this.endTransaction("insertWayPointToCell");
    }
  }

  private removeWayPointsfromCell = (cell: mxCell): void => {
    const sem = this.state.semtalk;
    if (sem) {
      this.beginTransaction("removeWayPointsfromCell");
      sem.tseditor.removeWayPointsfromCell(cell);
      this.endTransaction("removeWayPointsfromCell");
    }
  }

  private termstore = () => {
    const sem = this.state.semtalk;
    if (sem) {
      if (sem.page) {
        this.diagramroot = sem.page.ClassOf().Root;
        this.isclasses = sem.page.ClassOf().IsClass;
      }
      let cell = sem.currentShape();
      this.setState({
        hideTermSetDialog: false,
        shape: cell,
        object: null,
        relation: null,
      });
    }
  }
  private sitebuilder = () => {
    const sem = this.state.semtalk;
    if (sem) {
      let cell = sem.currentShape();
      this.setState({
        hideSiteBuilderDialog: false,
        shape: cell,
      });
    }
  }
  private customlayout = () => {
    const sem = this.state.semtalk;
    if (sem) {
      let cells = sem.selectedShapes();
      if (cells) {
        sem.Layout(cells);
      } else {
        sem.Layout();
      }
    }
  }

  private insert = () => {
    const sem = this.state.semtalk;
    if (sem) {
      if (sem.page) {
        let dt = sem.page.ClassOf();
        if (dt.ObjectName === SemTalkBaseConstant.SLGeneric) {
          this.isclasses = false;
        } else {
          this.isclasses = dt.IsClass;
        }
        this.diagramroot = sem.page.ClassOf().Root;
      }
      let cell = sem.currentShape();
      this.setState({
        hideInsertDialog: false,
        shape: cell,
        object: null,
        relation: null,
      });
    }
  }

  public InsertObject = (
    _semtalk: IVisioRDFS,
    shape: any,
    newobjs: ISemTalkObject[],
    expand: boolean
  ): void => {
    let sem = this.state.semtalk;
    if (sem && sem.page) {
      let s = "";
      if (shape) {
        s = shape.style;
      }
      this.beginTransaction("InsertObject");
      sem.InsertObjects(
        newobjs,
        this.click_x,
        this.click_y,
        expand,
        false,
        s,
        sem.page,
        false
      );
      this.endTransaction("InsertObject");
    }
    this._closeInsertDialog();
  }
  public InsertObjects = (
    newobjs: ISemTalkObject[],
    expand: boolean,
    reusenodes: boolean
  ): void => {
    let sem = this.state.semtalk;
    if (sem && sem.page) {
      this.beginTransaction("InsertObjects");
      sem.InsertObjects(
        newobjs,
        this.click_x,
        this.click_y,
        expand,
        false,
        '',
        sem.page,
        reusenodes
      );
      this.endTransaction("InsertObjects");
    }
  }
  private selectclass: boolean = false;
  private selectCell = (cell: mxCell | undefined): void => {
    // gotoObject(cell.objectid);
    this.selectclass = false;

    let sem = this.state.semtalk;
    if (sem && cell) {
      // let isgeneric =
      //   sem.page?.ClassOf().ObjectName === SemTalkBaseConstant.SLGeneric;
      const bas = sem.base;
      let ins = bas.FindInstanceByID(cell.objectid);
      const oth = bas.FindObjectByID(cell.objectid);
      // if (isgeneric && !bas.IsAssociation(oth)) return;
      // if (ins === null && oth === null && cell.value) {
      //   let txt = cell.value;
      //   cell.objectid = undefined;
      //   sem.visShapeAdded(cell, null);
      //   sem.visShapeExitedTextEdit(cell, txt);
      //   ins = bas.FindInstanceByID(cell.objectid);
      // }
      if (ins !== null) {
        const icl = ins.ClassOf();
        const scl = icl.SystemClass();
        const isgeneric0 =
          sem.page?.ClassOf().ObjectName === SemTalkBaseConstant.SLGeneric;
        if (scl !== null && !isgeneric0) {
          let lbl: ISemTalkLabel | null = null;
          if (scl.AllInstanceLabels().length > 0) {
            lbl = scl.AllInstanceLabels()[0];
          } else {
            for (const scc of scl.AllSuperClasses()) {
              if (lbl === null) {
                const slbls = (scc as ISemTalkSystemClass).AllInstanceLabels();
                if (slbls.length > 0) {
                  lbl = slbls[0];
                }
              }
            }
          }

          if (lbl !== null) {
            // console.debug("Label: " + lbl);
            let relname = lbl.Text;
            if (lbl.Text.indexOf("{") > -1) {
              relname = lbl.Text.substr(0, lbl.Text.indexOf("{"));
            }
            if (relname.indexOf(" (") > 0) {
              relname = relname.substr(0, relname.indexOf(" ("));
            }
            const relt = bas.FindAssociationType(relname);
            // console.debug(slinfotype);
            if (relt !== null) {
              const olist = scl.AllLinkedObjects(relname);
              if (olist.length > 0) {
                const ocl = olist[0] as ISemTalkSystemClass;
                this.selectclass =
                  ocl.ObjectName ===
                  bas.GetModelAttribute(Process_ElementName.SLInformation);
                // console.debug(ocl);
                this.otherroot = ocl;
                this.othertext = "";
                for (let o of ins.LinkedObjects(relname)) {
                  this.othertext = o.ObjectCaption;
                }
                this.setState({
                  hideRelationDialog: false,
                  shape: cell,
                  object: cell.objectid,
                  relation: relname,
                });
                return;
              }
            }
          }
        }
      } else {
        if (oth !== null) {
          this.selectclass = true;
        }
      }
      // sem.shape = cell; !!!!!!!!!!!!
      // this.saveGraph("","selectCell");
      this.setState({
        hideSelectDialog: false,
        shape: cell,
        object: cell.objectid,
        relation: null,
      });
    } else {
      this.setState({
        hideSelectDialog: false,
        shape: undefined,
        object: undefined,
        relation: null,
      });
    }
  }

  private ChangeRelation = (
    semtalk: IVisioRDFS,
    shape: any,
    newobjs: ISemTalkObject[],
    _expand: boolean
  ) => {
    if (semtalk && this.state.relation && newobjs.length > 0) {
      this.beginTransaction("ChangeRelation");
      let newobj = newobjs[0];
      const rel = this.state.relation;
      const objid = this.state.object;
      let obj = semtalk.base.FindInstanceByID(objid);
      if (obj) {
        for (const lnk of obj.Links(rel)) {
          if (lnk.ToObject.ObjectName !== newobj.ObjectName) {
            obj.DeleteAssociation(rel, lnk.ToObject);
          }
        }
        if (!obj.HasDirectLink(rel, newobj)) {
          obj.MakeAssociation(rel, newobj);
        }
        semtalk.UpdateLabel(obj);
      }
      if (rel === SemTalkBaseConstant.SLDisplays) {
        semtalk.UpdateSwimlaneContent(shape);
      }
      semtalk.tseditor.refreshCell(shape);
      //  this.state.semtalk.ChangeObject(shape, newobj);
      this.endTransaction("ChangeRelation");
    }
    this._closeRelationDialog();
  }

  private ChangeObject = (
    _semtalk: IVisioRDFS,
    shape: any,
    newobj: ISemTalkObject[],
    _expand: boolean
  ) => {
    const sem = this.state.semtalk;
    if (sem && shape) {
      this.beginTransaction("ChangeObject");
      sem.ChangeObject(shape, newobj);
      this.endTransaction("ChangeObject");
    }
    this._closeSelectDialog();
  }

  public ImportTermSet = (sysc: ISemTalkSystemClass, items: ITermSetItem[]) => {
    if (this.state.semtalk) {
      this.beginTransaction("ImportTermSet");
      this.state.semtalk.ImportTermSet(sysc, items, this.click_x, this.click_y);
      this.endTransaction("ImportTermSet");
    }
    this._closeTermSetDialog();
  }

  private ImportTermSetTree = (syscname: string, items: any[]) => {
    if (this.state.semtalk) {
      this.beginTransaction("ImportTermSet");
      this.state.semtalk.ImportTermSetTree(
        syscname,
        items,
        this.click_x,
        this.click_y
      );
      this.endTransaction("ImportTermSet");
    }
    this._closeTermSetDialog();
  }

  // private ImportSiteBuilder = (_syscname: string, _items: any[]) => {
  //   if (this.state.semtalk) {
  //     this.beginTransaction("ImportSiteBuilder");
  //     // this.state.semtalk.ImportSiteBuilder(
  //     //   syscname,
  //     //   items,
  //     //   this.click_x,
  //     //   this.click_y
  //     // );
  //     this.endTransaction("ImportTermSet");
  //   }
  //   this._closeSiteBuilderDialog();
  // }


  private ImportPlannerTasks = (syscname: string, items: any[]) => {
    if (this.state.semtalk) {
      this.beginTransaction("ImportPlannerTasks");
      this.state.semtalk.ImportPlannerTasks(syscname, items);
      this.endTransaction("ImportPlannerTasks");
    }
    // this._closeTermSetDialog();
  }

  private ExpandObject = (
    _semtalk: IVisioRDFS,
    shape: any,
    newlinks: ISemTalkAssociation[]
  ) => {
    if (this.state.semtalk) {
      this.beginTransaction("ExpandObject");
      this.state.semtalk.ExpandObject(shape, newlinks);
      this.endTransaction("ExpandObject");
    }
    // this._closeExpandDialog();
  }

  private ExpandSimilarObject = (
    _semtalk: IVisioRDFS,
    shape: any,
    newlinks: string[]
  ) => {
    if (this.state.semtalk) {
      this.beginTransaction("ExpandSimilarObject");
      this.state.semtalk.ExpandSimilarClass(shape, newlinks);
      this.endTransaction("ExpandSimilarObject");
    }
    // this._closeExpandDialog();
  }

  private createPage = async (
    _semtalk: IVisioRDFS,
    name: string,
    diagtype: ISemTalkDiagramType | null
  ) => {
    this._closeCreateDiagramDialog();
    if (this.state.semtalk) {
      const sem = this.state.semtalk;
      if (sem !== null && sem.page && name.length > 0) {
        this.beginTransaction("createPage");
        let pgcl: ISemTalkDiagramType;
        if (diagtype) {
          pgcl = diagtype;
        } else {
          pgcl = sem.page.ClassOf();
        }
        await this.newPage(name, null, pgcl, true);
        this.endTransaction("createPage");
      }
    }
  }

  public selectObject = () => {
    const sem = this.state.semtalk;
    this.selectclass = false;
    let objectid = getObject();
    if (sem) {
      let cell = sem.currentShape();
      this.setState({
        hideSelectDialog: false,
        shape: cell,
        object: objectid,
        relation: null,
      });
    }
  }

  private setTextField = async (
    tfelement: { key: string; label: string }[]
  ) => {
    const sem = this.state.semtalk;
    if (sem) {
      sem.setTextField(tfelement);
      let props = await this.getModelProperties(this.filename);
      sem.updateTextFields(props);
      await this.loadModelSettings();
      this.autoSave();
    }
  }

  public GetTextFieldsDiagramMenu = async () => {
    const sem = this.state.semtalk;
    if (sem) {
      let diag_items: any[] = [];
      diag_items.push({
        key: "PAGENAME",
        label: sem.getResStrListener(ResIDL.STRNAME),
      });
      diag_items.push({
        key: "PAGECLASS",
        label: sem.getResStrListener(ResIDL.STRDLHTMLOUTCLA),
      });
      diag_items.push({
        key: "PAGENUMBER",
        label: sem.getResStrListener(ResIDL.STRBONNUM).replace(":", ""),
      });
      diag_items.push({
        key: "PAGECOMMENT",
        label: sem.getResStrListener(ResIDL.STRCOMMENT),
      });
      let bl: string[] = [
        SemTalkBaseConstant.SLMXGAttribute,
        SemTalkBaseConstant.SLSVGAttribute,
        BPMNElement.extensionElements,
        SemTalkBaseConstant.SLUserNumber,
      ];
      if (sem.page) {
        for (let a of sem.page.AllAttributes()) {
          let aname = a.ClassOf().ObjectName;
          if (bl.indexOf(aname) > -1) continue;
          diag_items.push({ key: aname, label: a.ClassOf().ObjectCaption });
        }
      }
      return diag_items;
    }
    return [];
  }

  public GetTextFieldsModelMenu = async () => {
    const sem = this.state.semtalk;
    if (sem) {
      let model_items: any[] = [];
      if (this.filename === '' || this.filename.endsWith(".stx")) {
        return [];
      }
      let props = await this.getModelProperties(this.filename);
      for (let prop of props) {
        switch (prop.PropName) {
          case "name": {
            model_items.push({
              key: prop.PropName,
              label: sem.getResStrListener(ResIDL.STRNAME),
            });
            break;
          }
          case "Name": {
            model_items.push({
              key: prop.PropName,
              label: sem.getResStrListener(ResIDL.STRNAME),
            });
            break;
          }
          default: {
            model_items.push({
              key: prop.PropName,
              label: prop.PropCaption.replace(":", ""),
            });
          }
        }
      }
      return model_items;
    }
    return [];
  }

  private expandObject = () => {
    const sem = this.state.semtalk;
    // this.selectclass = false;
    let objectid = getObject();
    if (sem) {
      let cell = sem.currentShape();
      this.setState({
        hideExpandDialog: false,
        shape: cell,
        object: objectid,
        relation: null,
      });
    }
  }

  private renumber = () => {
    const sem = this.state.semtalk;
    if (sem) {
      let objectid = getObject();
      if (objectid) {
        let ins = sem.base.FindInstanceByID(objectid);
        if (ins) {
          this.beginTransaction("RenumberObject");
          sem.RenumberObject(ins, [], null);
          this.endTransaction("RenumberObject");
        }
      }
    }
  }

  public showShapeStyle = (): void => {
    if (this.state.semtalk) {
      this.setState({
        hideShapeStyle: false,
      });
    }
  }

  public showHelp = (page?: string): void => {
    let helpwiki: string =
      "https://github.com/SemTalkOnline/SemTalkOnline_EN/wiki/";
    let lng: string = this.state.guilanguage;
    if (lng) {
      switch (lng) {
        case SemTalkLanguageCode.German: {
          helpwiki = "https://github.com/SemTalkOnline/SemTalkOnline_DE/wiki/";
          break;
        }
        case SemTalkLanguageCode.English: {
          helpwiki = "https://github.com/SemTalkOnline/SemTalkOnline_EN/wiki/";
          break;
        }
        default: {
          helpwiki = "https://github.com/SemTalkOnline/SemTalkOnline_EN/wiki/";
          break;
        }
      }
    }

    let sem = this.state.semtalk;
    if (sem) {
      if (page) {
        sem.showHelp(page);
        return;
      }
      if (this.state.showSettings) {
        window.open(helpwiki + this.getHelpStr("General-Options"), "_blank");
        return;
      }
      if (!this.state.hideFileOpen) {
        window.open(
          helpwiki + this.getHelpStr("File-Pull-Down-Menu"),
          "_blank"
        );
        return;
      }
      if (!this.state.hideComposeDialog) {
        window.open(
          helpwiki + this.getHelpStr("Using-Vocabulary-to-Name-Objects"),
          "_blank"
        );
        return;
      }
      this.setState({
        hideHelp: false,
      });
    }
  }

  public getHelpStr = (str: string, lang?: SemTalkLanguageCode): string => {
    let help_SemTalk = require("./application/semtalklistener/SemTalkHelp.json");
    if (lang === undefined) {
      lang = this.state.guilanguage;
    }
    if (lang === SemTalkLanguageCode.English) {
      const s = help_SemTalk[str];
      if (s !== undefined) {
        return s.value;
      }
      return str;
    } else {
      const s = help_SemTalk[str];
      if (s !== undefined) {
        // if (lang !== "de") {
        //   if (lang === "sc") lang = "cn-simplified";
        //   if (lang === "tc") lang = "cn-traditional";
        // }
        let v = s[lang];
        if (v === undefined) {
          return s.value;
        }
        return v;
      } else {
        return str;
      }
    }
  }

  public showOptions = (): void => {
    this.setState({
      showSettings: true,
    });
  }

  private showForm = (): void => {
    if (this.state.semtalk) {
      this.setState({
        hideFormDialog: false,
      });
    }
  }

  public ShowSubTask = (addoncommand: string): void => {
    if (this.state.semtalk) {
      this.setState({
        addoncommand: addoncommand,
        hideSubTaskDialog: false,
      });
    }
  }

  private doLayout = (layoutname: any, animate: boolean, horizontal: boolean, invert: boolean) => {
    const sem = this.state.semtalk;
    if (sem) {
      this.beginTransaction("doLayout");
      sem.tseditor.doLayout(null, layoutname, sem.currentShape(), animate, horizontal, invert);
      this.endTransaction("doLayout");
    }
  }

  public isValid = (src: mxCell, dst: mxCell): boolean => {
    const sem = this.state.semtalk;
    if (sem) {
      return sem.isValid(src, dst);
    }
    return true;
  }

  private _closeSelectDialog = (): void => {
    this.setState({
      hideSelectDialog: true,
      shape: null,
      object: null,
      relation: null,
    });
  }

  private _closeTermSetDialog = (): void => {
    this.setState({
      hideTermSetDialog: true,
      shape: null,
      object: null,
      relation: null,
    });
  }

  private _closeRelationDialog = (): void => {
    this.setState({
      hideRelationDialog: true,
      shape: null,
      object: null,
      relation: null,
    });
  }

  private _closeCreateDiagramDialog = (): void => {
    this.setState({ hideCreateDiagramDialog: true });
  }

  private _closeInsertDialog = (): void => {
    this.setState({
      hideInsertDialog: true,
      shape: null,
      object: null,
      relation: null,
    });
  }

  public ParseMenu = (ribbon: SemTalkRibbon): { items: any; allitems: any } => {
    const sem = this.state.semtalk;
    if (sem) {
      return ComputeMenu(this, sem, ribbon, this.state.role);
    }
    return { items: [], allitems: {} };
  }
  public SetRibbon = (ribbon: SemTalkRibbon, toolbar: SemTalkRibbon) => {
    const sem = this.state.semtalk;
    if (sem) {
      sem.resetRibbon(true);
      sem.setRibbon(ribbon, toolbar);
      this.autoSave();
      this.setState({ ribbon: ribbon, toolbar: toolbar });
      // eslint-disable-next-line no-restricted-globals
      // window.location.reload();
    }
  }
  public ResetRibbon = () => {
    const sem = this.state.semtalk;
    if (sem) {
      sem.base.SetModelAttribute(SemTalkBaseConstant.CookiePrefix + SemTalkCookie.ribbon, null);
      sem.base.SetModelAttribute(SemTalkBaseConstant.CookiePrefix + SemTalkCookie.toolbar, null);
      sem.resetRibbon(true);
      this.autoSave();
      let rb = sem.getRibbon(undefined, undefined, this.state.islocked);
      this.setState({ ribbon: rb.ribbon, toolbar: rb.toolbar });
      window.location.reload();
    }
  }
  private menuItems: IContextualMenuItem[] = [];
  private onShowContextualMenu = (
    cell: mxCell,
    ev: any
  ) => {
    const sem = this.state.semtalk;
    if (cell && cell !== null && !cell.objectid) {
      if (cell.isGroupCell) {
        if (sem) {
          let menu = GroupMenu(this, sem, this.state.ribbon,
            this.state.role, this.state.islocked, this.props.isplanmaker, false, cell, ev);
          this.menuItems = menu;
          this.setState({ showContextualMenu: true });
        }
        return;
      }
    }
    if (sem) {
      let ribbon = this.state.ribbon;
      let menu = ContextMenu(this, sem, ribbon,
        this.state.role, this.state.islocked, this.props.isplanmaker,
        false, this.state.dockpivot, this.state.bpmnrules, cell, ev);
      this.menuItems = menu;
      this.setState({ showContextualMenu: true });
    }
  }

  private onHideContextualMenu = () => {
    this.setState({ showContextualMenu: false });
  }

  public getLabel = (cell: mxCell, tmp: string): string => {
    if (this.state.semtalk) {
      return this.state.semtalk.getLabel(cell, tmp);
    }
    return tmp;
  }

  public getTextLabel = (cell: mxCell, tmp: string): string => {
    if (this.state.semtalk) {
      return this.state.semtalk.getTextLabel(cell, tmp);
    }
    return tmp;
  }
  public getLinkLabel = (cell: mxCell, tmp: string): string => {
    if (this.state.semtalk) {
      return this.state.semtalk.getLinkLabel(cell, tmp);
    }
    return tmp;
  }

  public getTooltipForCell = (cell: mxCell): string => {
    if (this.state.semtalk) {
      const oid = cell["objectid"];
      if (oid) return this.state.semtalk.getTooltipForCell(oid);
    }
    return String(cell.value);
  }

  public isCellResizable = (cell: mxCell): boolean => {
    if (cell.vertex && cell.objectid &&
      cell.shapeKey !== SemTalkMaster.MasterSwimlane &&
      cell.shapeKey !== SemTalkMaster.MasterUMLClass) {
      if (cell.style && cell.style.indexOf(SemTalkStyleAttribute.resize + "=0;") > -1) {
        return false;
      }
    }
    return true;
  }

  public isCellEditable = (cell: mxCell): boolean => {
    if (cell.objectid && (!this.state.hideComposeDialog || this.state.showTableEditor)) {
      return false;
    }
    if (cell.style && cell.style.indexOf(SemTalkStyleAttribute.editable + "=0;") > -1) {
      return false;
    }
    if (cell.value && cell.value.indexOf("<br/>") > 0) {
      return false;
    }
    return true;
  }

  public isWrapping(_cell: mxCell): boolean {
    return true;
  }

  public quickShapes = (cell: mxCell): SemTalkStencil => {
    if (this.state.semtalk && this.state.isquickshapes) {
      let sem = this.state.semtalk;
      const oid = cell["objectid"];
      if (oid) {
        return sem.quickShapes(oid, this.stencil);
      }
    }
    return [];
  }

  public makeInstance = (class_cell: mxCell, inst_cell: mxCell): void => {
    const sem = this.state.semtalk;
    if (sem) {
      sem.makeInstance(class_cell, inst_cell);
      sem.graph.startEditingAtCell(inst_cell);
    }
  }

  public makeSubClass = (class_cell: mxCell, subcl_cell: mxCell): void => {
    const sem = this.state.semtalk;
    if (sem) {
      sem.makeSubClass(class_cell, subcl_cell);
      sem.graph.startEditingAtCell(subcl_cell);
    }
  }

  private align = (direction: string) => {
    const sem = this.state.semtalk;
    if (sem) {
      sem.tseditor.align(direction, undefined);
    }
  }

  private distribute = (direction: boolean) => {
    const sem = this.state.semtalk;
    if (sem) {
      sem.tseditor.distribute(direction, undefined);
    }
  }

  private checkOutDocument = () => {
    let sem = this.state.semtalk;
    if (sem) {
      if (this.state.mongo.usemongo && this.state.mongo.semuserlogin) {
        mgCheckDocument(this.state.mongo, this.state.mongo.documents, this.GetFilename(), false);
      } else {
        // this.storeToTempList(this.filename);
        if (this.gprops.usegraph && this.gprops.graphClient) {
          this.checkinoutdocumentfromgraph(sem, this.GetFilename(), "checkout", "check comment");
        } else {
          if (this.state.spCheckInOut && this.state.semtalk && this.sprops.context) {
            this.state.semtalk.explorer.spCheckOutDocument(this.sprops.context,
              this.sprops.librarysite,
              this.siteRelativeURL() + "/" + this.sprops.library,
              this.GetFilename());
          }
        }
      }
      // if (sem) {
      //   sem.readonly = false;
      // }
      this.setState({ checkedOut: false, ischeckedout2me: true });
      let filename = this.GetFilename();
      if (filename) {
        document.title = filename + " (CO)";
      }
      settingsChanged();
    }
  }

  public IsCheckedout2me = () => {
    return this.state.ischeckedout2me;
  }
  public IsCheckedout = () => {
    return this.state.checkedOut;
  }


  private checkinoutdocumentfromgraph = async (sem: IVisioRDFS, filename: string, checkinout: string, comment: string): Promise<any> => {
    let driveid = "";
    let drives = await sem.explorer.fetchGraphItems(
      this.gprops.graphClient,
      this.gprops.sharepointlibrarysite + "drives"
    );
    let lib = this.gprops.sharepointlibrary;
    for (let drive of drives) {
      if (drive.name === lib) {
        driveid = drive.id;
      }
    }

    let url0 = "/drives/" + driveid + "/root:/" + filename;
    let id0: string;
    try {
      let driveitem = await sem.explorer.fetchGraphItem(this.gprops.graphClient, url0);
      id0 = driveitem.id;
    } catch (_e) {
      return null;
    }

    if (driveid === "") return;
    let url = "/drives/" + driveid + "/items/" + id0 + "/" + checkinout;
    let body = {
      "comment": comment
    };
    try {
      let driveitem = await sem.explorer.postGraphItemNoGet(this.gprops.graphClient, url, body, null);
      return driveitem;
    } catch (_e) {
      return null;
    }
  }
  private checkInDocument = () => {
    // Sollte man nicht eigentlich vorher speichern?
    let sem = this.state.semtalk;
    if (sem) {
      if (this.state.mongo.usemongo && this.state.mongo.semuserlogin) {
        mgCheckDocument(this.state.mongo, this.state.mongo.documents, this.GetFilename(), true);
      } else {
        if (this.gprops.usegraph && this.gprops.graphClient) {
          this.checkinoutdocumentfromgraph(sem, this.GetFilename(), "checkin", "check comment");
        } else {
          if (this.state.spCheckInOut && this.sprops.context) {
            sem.explorer.spCheckInDocument(this.sprops.context,
              this.sprops.librarysite,
              this.siteRelativeURL() + "/" + this.sprops.library,
              this.GetFilename()
            );
          }
        }
        // let sem = this.state.semtalk;
        // if (sem) {
        //   sem.readonly = true;
        // }
      }
      this.setState({ checkedOut: false, ischeckedout2me: false });
      let filename = this.GetFilename();
      if (filename) {
        document.title = filename;
      }
      settingsChanged();
    }
  }
  private siteRelativeURL(): string {
    let site = this.sprops.librarysite;
    let s2: string = site.substring(site.indexOf("//") + 2);
    s2 = s2.substring(s2.indexOf("/"));
    return s2;
  }
  private fileImage = async () => {
    let sem = this.state.semtalk;
    if (sem) {

      let fname = this.GetFilename();
      if (fname.length === 0) fname = "export.sdx";

      if (fname.endsWith(".sdx")) {
        fname = fname.replace(".sdx", ".svg");
        ensureLabelWidth(sem.graph as mxGraph);
        let svg = sem.tseditor.exportSVG(sem.graph as mxGraph);

        // sem.tseditor.exportPDF(fname.replace(".sdx", ".pdf"), [svg]);

        const url = window.URL.createObjectURL(new Blob([svg]));
        const link: any = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fname);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        // }
      }
      // console.debug(svg);
    }
  }

  public alert = (msg: string, mtype: MessageBarType): void => {
    if (msg === "") {
      this.setState({ nosuccess: false, errormsg: msg });
    } else {
      // if (this.state.errormsg === "") {
      this.setState({
        nosuccess: true,
        errormsg: msg,
        msgbartype: mtype,
      });
      // }
    }
  }

  public keyPressed = async (evtobj: KeyboardEvent) => {
    const sem = this.state.semtalk;
    if (sem) {
      let code = evtobj.code;
      if (evtobj.shiftKey) {
        code = "Shift+" + code;
      }
      if (evtobj.altKey) {
        code = "Alt+" + code;
      }
      let menuitem = sem.getRibbon(undefined, undefined, this.state.islocked)
        .keys[code];
      if (menuitem["onClickCallback"]) {
        this.DoCommand(menuitem["onClickCallback"], {});
      }
    }
  }

  public hasKeyHandler = (evtobj: KeyboardEvent): boolean => {
    const sem = this.state.semtalk;
    if (sem) {
      let code = evtobj.code;
      if (code === 'ControlLeft') {
        return false;
      }
      if (evtobj.shiftKey) {
        code = "Shift+" + code;
      }
      if (evtobj.altKey) {
        code = "Alt+" + code;
      }
      let ribbon = sem.getRibbon(undefined, undefined, this.state.islocked);
      // console.debug(evtobj);
      return (ribbon.keys[code] !== undefined);
    }
    return false;
  }

  public selectLeft = (): void => {
    const sem = this.state.semtalk;
    if (sem && sem.page) {
      sem.selectLeft();
    }
  }

  public selectRight = (): void => {
    const sem = this.state.semtalk;
    if (sem && sem.page) {
      sem.selectRight();
    }
  }
  // public selectLeftOptions = (): {title: string, Object: ISemTalkObject}[] => {
  //   const sem = this.state.semtalk;
  //   if (sem) {
  //     return sem.selectLeftOptions();
  //   }
  //   return [];
  // }
  // public selectRightOptions = (): {title: string, Object: ISemTalkObject}[] => {
  //   const sem = this.state.semtalk;
  //   if (sem) {
  //     return sem.selectRightOptions();
  //   }
  //   return [];
  // }
  public selectUp = (): void => {
    const sem = this.state.semtalk;
    if (sem && sem.page) {
      sem.selectUp();
    }
  }

  public selectDown = (): void => {
    const sem = this.state.semtalk;
    if (sem && sem.page) {
      sem.selectDown();
    }
  }

  public selectPageDown = (): void => {
    const sem = this.state.semtalk;
    if (sem && sem.page) {
      sem.selectPageDown();
    }
  }

  public selectPageUp = (): void => {
    const sem = this.state.semtalk;
    if (sem && sem.page) {
      sem.selectPageUp();
    }
  }
  private gotoStartEvent = (diag: ISemTalkDiagram): boolean => {
    const sem = this.state.semtalk;
    if (sem && sem.page) {
      const base = sem?.base;
      let event = base.FindSystemClass(
        base.GetModelAttribute(Process_ElementName.SLEvent)
      );
      if (event) {
        for (let node of diag.Contents()) {
          if (base.IsInstance(node.Model) &&
            (node.Model as ISemTalkInstance).IsInstance(event)) {
            if (
              node.Model.InvLinkedObjects(
                sem.base.GetModelAttribute(Process_ElementName.SLControl)
              ).length === 0
            ) {
              this.is_goto_start_event = true;
              gotoNode(node.ID, node.Model.ID, node.Diagram.ID, node.ShapeID, true, false);
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  public MS365Login = async (required_scopes: string[]): Promise<any> => {
    // try {
    let gc: any = this.gprops.graphClient;
    if (this.sprops.context &&
      this.sprops.context.sdks &&
      this.sprops.context.sdks.microsoftTeams) {
      return this.gprops.graphClient;
    }
    // if (this.graphClient && required_scopes.length === 0) {
    //   return gc;
    // }
    if (window.location.origin === "http://localhost:3000") {
      let scopes = [
        "User.Read",
        // "Sites.Read.All",
        // "Group.Read.All",
        // "Files.Read.All", 
        // "Team.ReadBasic.All"
      ];
      for (let s of required_scopes) {
        if (!scopes.includes(s)) scopes.push(s);
      }
      let res = await ms365login(
        "9eec9ef7-38a4-4b29-8ac5-b8b02564c78b",
        "https://login.microsoftonline.com/semtalk.onmicrosoft.com",
        scopes
      );
      gc = res?.graphclient;
      this.account = res?.account;
    } else {
      if (this.state.azureAD) {
        let scopes = this.state.azureAD.scopes;
        // if (scopes.length === 0) {
        //   scopes = [
        //     "User.Read",
        //     "Sites.Read.All",
        //     "Group.ReadWrite.All",
        //     "Files.Read.All",
        //     // "Team.ReadBasic.All"
        //   ];
        // }
        for (let s of required_scopes) {
          if (!scopes.includes(s)) scopes.push(s);
        }
        let res = await ms365login(
          this.state.azureAD.clientID,
          this.state.azureAD.authority,
          scopes
        );
        gc = res?.graphclient;
        this.account = res?.account;
      }
    }
    this.gprops.graphClient = gc;
    // } catch (e) {

    // }
    return gc;
  }
  public MS365Token = async (required_scopes: string[]): Promise<any> => {
    // try {
    let token: any = null;
    if (window.location.origin === "http://localhost:3000") {
      let scopes = [
        "User.Read",
        // "Sites.Read.All",
        // "Group.ReadWrite.All",
        // "Files.Read.All",
      ];
      for (let s of required_scopes) {
        if (!scopes.includes(s)) scopes.push(s);
      }
      scopes = [
        "User.Read"
      ];
      // "https://login.microsoftonline.com/semtalk.onmicrosoft.com",
      token = await ms365token(
        "9eec9ef7-38a4-4b29-8ac5-b8b02564c78b",
        "https://login.microsoftonline.com/semtalk.onmicrosoft.com",
        scopes
      );
    } else {
      if (this.state.azureAD) {
        let scopes = this.state.azureAD.scopes;
        for (let s of required_scopes) {
          if (!scopes.includes(s)) scopes.push(s);
        }
        token = await ms365token(
          this.state.azureAD.clientID,
          this.state.azureAD.authority,
          scopes
        );
      }
    }
    return token;
  }

  public MS365Logout = async () => {
    // try {
    if (window.location.origin === "http://localhost:3000") {
      // gc = await this.login("9eec9ef7-38a4-4b29-8ac5-b8b02564c78b",
      //   "https://login.microsoftonline.com/semtalkdev.onmicrosoft.com",
      //   scopes);
      await ms365logout(
        "9eec9ef7-38a4-4b29-8ac5-b8b02564c78b",
        "https://login.microsoftonline.com/semtalk.onmicrosoft.com"
      );
    } else {
      if (this.state.azureAD) {
        await ms365logout(
          this.state.azureAD.clientID,
          this.state.azureAD.authority
        );
      }
    }
    this.gprops.graphClient = null;
    // } catch (e) {
  }
  public MS365Role = async () => {
    this.setState({ hideRoleDialog: false });
  }
  public getSharePointContext = (): any => {
    return this.sprops.context;
  }

  private mgLogin = async () => {
    this.setState({ hidemgLoginDialog: false });
  }

  private mgLogout = async () => {
    if (this.state.mongo.usemongo) {
      const semmongologintokencookiename = "mongo_token";
      const tokenString = sessionStorage.getItem(semmongologintokencookiename);
      if (tokenString) {
        const userToken = JSON.parse(tokenString);
        if (userToken && userToken.login_token) {
          let res = mgLogout(
            this.state.mongo,
            this.state.mongo.dbname,
            userToken.login_token
          );
          console.debug(res);
          sessionStorage.removeItem(semmongologintokencookiename);
          window.location.assign(document.URL);
        }
      }
    }
  }

  private mgUserProfil = async () => {
    let hidenopen = false;
    if (this.props.restrictExtSharedMode) {
      hidenopen = true;
    }
    this.setState({ hidemgUserProfilDialog: hidenopen });
  }


  private mgUserManager = async () => {
    this.setState({ hideUserManager: false });
  }

  private ReportManager = async () => {
    this.setState({ hideReportManager: false });
  }

  private PromptManager = async () => {
    this.setState({ hidePromptManager: false });
  }

  private StorageManager = async () => {
    this.setState({ hideStorageManager: false });
  }

  private _onClose = (s: any): void => {
    this.setState(s);
  }
  // public defaultFont = () => {
  //   return defaultFont;
  // }
  public getMongoDBDocumentMetaData = async (filename: string): Promise<any> => {
    const mongo = this.state.mongo;
    if (mongo.usemongo && mongo.semuserlogin) {
      let filter = { name: filename };
      let projection = { value: 0, zip: 0 };
      let item: any = {};
      let items: any = await mgGetMetaData(mongo, mongo.dbname, mongo.documents, filter, projection);
      if (items && items.length > 0) {
        item = items[0];
      }
      // let item: any = await mgGetItem(mongo, mongo.dbname, mongo.documents, filename);
      // delete item["value"];
      // delete item["zip"];
      // console.debug("getDocumentMetaData", item);
      return item;
    } else {
      console.debug("getMongoDBDocumentMetaData: This should never be reached");
      // if (this.sprops.context) {
      //   let sem = this.state.semtalk;
      //   if (sem) {
      //     let lany: any = sem.explorer.getListData(
      //       this.sprops.context,
      //       this.sprops.librarysite,
      //       this.sprops.library,
      // "$select=" + this.state.sharepointdocumentcolumns +
      //       "&$expand=CheckoutUser/Id, Editor/Id, Author/Id" +
      //       "&$filter=FileLeafRef eq '" +
      //       filename +
      //       "'"
      //     );
      //     return lany;
      //   }
      //   return {};
      // }
    }
  }

  public getDocumentsMetaData = async (): Promise<IComboBoxOption[]> => {
    let gettitle = (s: string) => {
      switch (s) {
        case "bpmn20-2016.stx":
          return "BPMN 2.0";
        case "cit_intelliForm_dialog.stx":
          return "CIT IntelliForm Dialog";
        case "epc.stx":
          return "EPC";
        case "ksa.stx":
          return "KSA";
        case "semtalk.stx":
          return "Ontology";
      }
      return s;
    };

    let items: IComboBoxOption[] = [];
    if (this.state.mongo.usemongo && this.state.mongo.semuserlogin) {
      let lib = this.props.mongo.documents;
      let docs: any[] = [];
      // if (!this.props.mongo.iselectron) {
      docs = await mgGetMetaData(
        this.props.mongo,
        this.props.mongo.dbname,
        lib, {}, { "_id": 1, "name": 1 }
      );
      // }
      docs.sort((a: any, b: any) => {
        return a.name.localeCompare(b.name);
      });
      for (let s of docs) {
        let item = {
          key: s["_id"] as string,
          filename: s["name"] as string,
          text: gettitle(s["name"]),
        };
        let fname: string = item.filename;
        if (
          fname === "" ||
          fname.indexOf(".sdx") > 0 ||
          fname.indexOf(".bpmn") > 0 ||
          fname.indexOf(".stx") > 0
        ) {
          items.push(item);
        }
      }
    }

    if (this.gprops.graphClient && this.sprops && this.state.semtalk) {
      let spinterface = this.state.semtalk.explorer;

      let lib = this.gprops.sharepointlibrary;
      // let lib = this.sprops.library;

      let query = this.gprops.sharepointlibrarysite + "lists/" + lib + "/items?";
      query = query + "expand=fields";
      let spitems = await spinterface.fetchGraphItems(
        this.gprops.graphClient,
        query
      );
      let documents = spitems.map((s) => {
        let name = "";
        if (s["fields"] && s["fields"]["FileLeafRef"]) {
          name = s["fields"]["FileLeafRef"];
        }
        let id = name;
        if (s["id"]) {
          id = s["id"];
        }
        return {
          key: id as string,
          filename: name as string,
          text: gettitle(name),
        };
      });
      for (let item of documents) {
        let fname: string = item.filename;
        if (
          fname === "" ||
          fname.indexOf(".sdx") > 0 ||
          fname.indexOf(".bpmn") > 0 ||
          fname.indexOf(".stx") > 0
        ) {
          items.push(item);
        }
      }
      items.sort((a: any, b: any) => {
        return a.filename.localeCompare(b.filename);
      });
    }

    return items;
  }
  private _exportDefaultSettings = () => {
    const filename = "settings.json";
    exportDefaultSettings(filename);
    let sem = this.state.semtalk;
    if (sem) {
      let resstr = sem.getResStr(ResID.STRDOWNLOADINGCOPY) + filename;
      this.alert(resstr, MessageBarType.info);
      setTimeout(() => {
        this.alert("", MessageBarType.info);
      }, 10000);
    }
  }

  public GetDefaultValue = (
    defaultsettings: any,
    setting: SemTalkCookie,
    defaultvalue: any
  ): any => {
    return GetSettingsDefaultValue(this.state.defaultsettings,
      defaultsettings, setting, defaultvalue);
  }

  private checkRepair = async () => {
    this.setState({ hideCheckRepair: false });
  }

  private showSimulation = async () => {
    this.setState({ hideSimulation: false });
  }

  private shareDocument = async () => {
    this.setState({ hideShareDocument: false });
  }

  private saveElectronSettings = async () => {
    let badlist = [
      "autosaveSemTalk",
      "history",
      "historypos",
      "role",
      "SemTalkStatus",
      "quickedit",
      "shapestyle",
    ];
    let settings: any = {};
    for (const x in SemTalkCookie) {
      if (badlist.indexOf(x) > -1) continue;
      let v = accessCookie(x as SemTalkCookie);
      if (v) {
        settings[x] = v;
      }
    }
    let w: any = window;
    const mongo = this.state.mongo;
    if (mongo.iselectron && w.api) {
      try {
        w.api.saveSettings({ settings: settings });
      } catch { }
    }
  }

  private loadElectronSettings = async (mongo: IMongoOption) => {
    let w: any = window;
    if (mongo.iselectron && w.api) {
      try {
        let settings = await w.api.getSettings({});
        let badlist = [
          "autosaveSemTalk",
          "history",
          "historypos",
          "role",
          "SemTalkStatus",
          "quickedit",
          "shapestyle",
        ];
        for (const x in SemTalkCookie) {
          if (badlist.indexOf(x) > -1) continue;
          let v = settings[x];
          if (v) {
            setCookie(x as SemTalkCookie, v);
          }
        }
      } catch { }
    }
  }

  private exportBackgroundSettings = () => {
    let settings: any = this.state.headerfooterfields;
    let blob = JSON.stringify(settings);
    const url = window.URL.createObjectURL(new Blob([blob]));
    const filename = "background.json";
    const link: any = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    let sem = this.state.semtalk;
    if (sem) {
      let resstr = sem.getResStr(ResID.STRDOWNLOADINGCOPY) + filename;
      this.alert(resstr, MessageBarType.info);
      setTimeout(() => {
        this.alert("", MessageBarType.info);
      }, 10000);
    }
  }

  private importBackgroundSettings = (handler: any, cbfunc: (headerfooterfields: any) => {}) => {
    // let sem = this.state.semtalk as IVisioRDFS;
    const inp: any = document.createElement("input");
    inp.setAttribute("type", `File`);
    inp.setAttribute("className", `hidden`);
    inp.setAttribute("multiple", "false");
    inp.setAttribute("accept", ".json");
    inp.onchange = (e: any) => {
      handler(e, cbfunc);
    };
    document.body.appendChild(inp);
    inp.click();
    inp.parentNode.removeChild(inp);
  }

  private onImportBackgroundSettingsHandler = (event: any, cbfunc: (headerfooterfields: any) => {}) => {
    let f = event.target.files[0];
    let fr = new FileReader();
    fr.readAsText(f);
    fr.onload = async (_event) => {
      const s = fr.result;
      if (s) {
        let settings = JSON.parse(s.toString());
        setCookie(SemTalkCookie.headerfooterfields, JSON.stringify(settings));
        this.setState({ headerfooterfields: settings });
        if (cbfunc) {
          cbfunc(settings);
        }
      }
    };
  }

  public SetSelectedFile(filename: string) {
    this.selectedfilename = filename;
  }
  public SetSelectedExists(exists: boolean) {
    this.selectedexists = exists;
  }
  public SetSelectedFiles(filenames: string[]) {
    this.selectedfilenames = filenames;
  }
  public SetSelectedFileType(filetype: string) {
    this.selectedfiletype = filetype;
  }
  public SetImages(images: any[]) {
    this.selectedImages = images;
  }
  public SetSelectedLanguage(_language: SemTalkLanguage) {
    // this.selectedlanguage = language;
  }
  public SetSelectedExportConnection(exportConnection: IExportConnection) {
    this.exportConnection = exportConnection;
  }
  public SetPublishBg(publishBg: boolean) {
    this.publishBg = publishBg;
  }
  public SetSelectedDiagramType(diagramtype: ISemTalkDiagramType) {
    this.selecteddiagramtype = diagramtype;
  }
  public SetSelectedDiagram(pagename: string) {
    this.selecteddiagname = pagename;
  }
  public SetSelectedBackupFile(filename: string, content: string) {
    this.selectedBackupfilename = filename;
    this.selectedBackupContent = content;
  }
  public SetSelectedExternalOb(ob: IObjectBase | null) {
    this.selectedexternalob = ob;
  }
  private DoConfirmedDialog = (fn: () => any, s: any) => {
    fn();
    this.CloseConfirmDialog(s);
  }
  private OpenConfirmDialog = (s: any) => {
    this.setState(s);
  }
  private CloseConfirmDialog = (s: any) => {
    this.setState(s);
  }
  public SetProgressIndicator = (status: string) => {
    this.setState({ isLoading: status });
  }
  private onRenderSemTalkNewDiagramFooterContent = () => (
    <div>
      <PrimaryButton
        onClick={() => {
          if (this.selecteddiagname.length > 0 && this.state.semtalk) {
            this.createPage(
              this.state.semtalk,
              this.selecteddiagname,
              this.selecteddiagramtype
            );
          }
          this._onClose({ hideCreateDiagramDialog: true });
        }}
        styles={{ root: { marginRight: 8 } }}
      >
        {this.state.semtalk!.getResStr(ResID.STRDLGCMDOK)}
      </PrimaryButton>
      <DefaultButton
        onClick={() => {
          this._onClose({ hideCreateDiagramDialog: true });
        }}
        styles={{ root: { marginRight: 8 } }}
      >
        {this.state.semtalk!.getResStr(ResID.STRDLGCMDCA)}
      </DefaultButton>
      <DefaultButton
        onClick={() => {
          if (this.state.semtalk) {
            this.state.semtalk.showHelp("Diagram-New");
          }
        }}
        styles={{ root: { marginRight: 8 } }}
      >
        {this.state.semtalk!.getResStr(ResID.STRHELP)}
      </DefaultButton>
    </div>
  )

  private onRenderSemTalkOpenFooterContent = () => (
    <div>
      <PrimaryButton
        onClick={() => {
          if (this.selectedfilename.length > 0) {
            if (this.state.isopen) {
              this.openDocumentCallBack(this.selectedfilename);
            }
            if (this.state.isnew) {
              this.newDocumentCallBack(this.selectedfilename);
            }
            if (this.state.isimport) {
              this.importDocumentCallBack(this.selectedfilename);
            }
            if (this.state.ismerge) {
              this.mergeDocumentsCallBack(this.selectedfilenames);
            }
            if (this.state.isdelete) {
              this.setState({ isconfirmdelete: true });
            }
            if (this.state.issaveas) {
              if (this.selectedexists) {
                //  existing....
                this.setState({ isconfirmsaveas: true });
              } else {
                this.saveAsDocumentCallBack(
                  this.selectedfilename,
                  this.selectedfiletype
                );
              }
            }
            if (!this.state.hidePublishDialog) {
              if (this.exportConnection && this.exportConnection !== null && this.exportConnection !== undefined) {
                this.publishDocumentsCallBack(
                  this.selectedfilenames,
                  this.publishBg
                );
                this.publishBg = false;
              } else {
                alert("Choose an export connection.");
              }
            }
          }
          this._onClose({ hideFileOpen: true });
        }}
        styles={{ root: { marginRight: 8 } }}
      >
        {this.state.semtalk!.getResStr(ResID.STRDLGCMDOK)}
      </PrimaryButton>
      {!this.state.isnew &&
        !this.state.isdelete &&
        !this.state.ismerge &&
        this.state.hidePublishDialog && (
          <DefaultButton
            onClick={() => {
              if (this.selectedfiletype.length > 0) {
                if (this.state.isopen) {
                  this.openlocalDocument(this.selectedfiletype, false);
                }
              }
              if (this.state.isimport) {
                this.importlocalDocument();
              }
              if (this.selectedfiletype.length > 0) {
                if (this.state.issaveas) {
                  // this.savelocalDocument(this.selectedfiletype);
                  this.savelocalDocument(
                    this.selectedfilename,
                    this.selectedfiletype
                  );
                }
              }
              this._onClose({
                hideFileOpen: true
              });
            }}
            styles={{ root: { marginRight: 8 } }}
          >{(this.state.issaveas && this.state.semtalk!.getResStr(ResID.STRFILEDOWNLOAD)) ||
            ((this.state.isopen || this.state.isimport) && this.state.semtalk!.getResStr(ResID.STRCOMPUTER))}</DefaultButton>
        )}
      {false &&
        !this.state.isnew &&
        !this.state.isdelete &&
        !this.state.ismerge &&
        this.state.hidePublishDialog && (
          <DefaultButton
            onClick={() => {
              if (this.selectedfiletype.length > 0) {
                if (this.state.isopen) {
                  this.openOneDrive();
                }
              }
              if (this.state.isimport) {
                this.importOneDrive();
              }
              if (this.selectedfiletype.length > 0) {
                if (this.state.issaveas) {
                  this.saveOneDrive(
                    this.selectedfilename,
                    this.selectedfiletype
                  );
                }
              }
              this._onClose({ hideFileOpen: true });
            }}
            styles={{ root: { marginRight: 8 } }}
          >
            {"OneDrive"}
          </DefaultButton>
        )}
      {this.state.isplannerenabled &&
        !this.state.isnew &&
        !this.state.isdelete &&
        !this.state.ismerge &&
        this.state.hidePublishDialog && (
          <DefaultButton
            onClick={() => {
              this.ShowPlanner();
              this._onClose({ hideFileOpen: true });
            }}
            styles={{ root: { marginRight: 8 } }}
          >
            {"Planner"}
          </DefaultButton>
        )}
      <DefaultButton
        onClick={() => {
          this._onClose({ hideFileOpen: true, hidePublishDialog: true });
        }}
        styles={{ root: { marginRight: 8 } }}
      >
        {this.state.semtalk!.getResStr(ResID.STRDLGCMDCA)}
      </DefaultButton>
      <DefaultButton
        onClick={() => {
          if (this.state.semtalk) {
            if (this.state.isnew) {
              this.state.semtalk.showHelp("File-New");
            }
            if (this.state.isopen) {
              this.state.semtalk.showHelp("File-Open");
            }
            if (this.state.issaveas) {
              this.state.semtalk.showHelp("File-Save");
            }
            if (this.state.isdelete) {
              this.state.semtalk.showHelp("File-Delete");
            }
          }
        }}
        styles={{ root: { marginRight: 8 } }}
      >
        {this.state.semtalk!.getResStr(ResID.STRHELP)}
      </DefaultButton>
    </div>
  )

  private onRenderSemTalkVersionControlFooterContent = () => (
    <div>
      <PrimaryButton
        onClick={() => {
          this.restoreVersionCallBack(this.selectedBackupfilename, this.selectedBackupContent);
          this._onClose({ hideVersionControl: true });
        }}
        styles={{ root: { marginRight: 8 } }}
      >
        {this.state.semtalk!.getResStr(ResID.STRVERSIONRESTORE)}
      </PrimaryButton>
      <DefaultButton
        onClick={() => {
          this._onClose({ hideVersionControl: true });
        }}
        styles={{ root: { marginRight: 8 } }}
      >
        {this.state.semtalk!.getResStr(ResID.STRDLGCMDCA)}
      </DefaultButton>
      <DefaultButton
        onClick={() => {
          if (this.state.semtalk) {
            this.state.semtalk.showHelp("Version-Control");

          }
        }}
        styles={{ root: { marginRight: 8 } }}
      >
        {this.state.semtalk!.getResStr(ResID.STRHELP)}
      </DefaultButton>

    </div>
  )

  private onRenderSettingsFooterContent = () => (
    <div>
      <PrimaryButton
        onClick={this.reloadFromSettingsPanel}
        styles={{ root: { marginRight: 8 } }}
      >
        {this.state.semtalk!.getResStr(ResID.STRDLGCMDOK)}
      </PrimaryButton>
      <DefaultButton
        onClick={() => {
          this.state.semtalk!.showHelp("Tools_SemTalk_Options");
        }}
        styles={{ root: { marginRight: 8 } }}
      >
        {this.state.semtalk!.getResStr(ResID.STRHELP)}
      </DefaultButton>
    </div>
  )

  public reloadFromSettingsPanel = async () => {
    let sem = this.state.semtalk;
    // if (sem) {
    //   this._onClose({
    //     showSettings: false,
    //     isLoading: sem.getResStr(ResID.STRREFRESH).replace("&", ""),
    //   });
    // } else {
    // this._onClose({ showSettings: false });
    // }
    this.setState({ showSettings: false });

    this.saveStatus();
    if (sem) {
      let xml = sem.tseditor.getGraphXml();
      sem.autoSave(xml, "reloadFromSettingsPanel");
    }

    // let semmongoserverurl = accessCookie(SemTalkCookie.semmongoserverurl);
    // if (!semmongoserverurl) {
    //   semmongoserverurl = "/api/";
    // }
    let semconnecttoken = accessCookie(SemTalkCookie.semconnecttoken);
    if (!semconnecttoken) semconnecttoken = "";

    // semmongoserverurl = "http://localhost:7071/api/";

    let db = accessCookie(SemTalkCookie.dbname);
    if (!db) db = "semtalkonline";
    let re = accessCookie(SemTalkCookie.repository);
    if (!re) re = "repository";

    let mongo = this.state.mongo;
    // mongo.semmongoserverurl = semmongoserverurl;
    mongo.semmongoconnectiontoken = semconnecttoken;
    mongo.dbname = db;
    mongo.repository = re;

    let usemongo: boolean = true;
    let usemongos = accessCookie(SemTalkCookie.usemongo);
    if (usemongos !== null) {
      usemongo = usemongos !== "false";
    }

    // in den settings hat sich die einstellung verändert
    let blogin = usemongo && !mongo.usemongo;
    if (!usemongo) {
      mongo.semuserlogin = null;
    }
    mongo.usemongo = usemongo;

    try {
      await this.getSettings(this.props.mongo);
    } catch (_e) {
      resetSettings();
    }
    if (mongo.iselectron) {
      await this.saveElectronSettings();
    }
    if (sem) {
      let modellang = sem.base.GetModelAttribute(
        SemTalkBaseConstant.CookiePrefix + SemTalkCookie.language
      );
      if (modellang !== undefined) {
        setCookie(SemTalkCookie.language, modellang);
      }
    }

    this.selectDataLang(accessCookie(SemTalkCookie.language), false);
    this.selectGuiLang(accessCookie(SemTalkCookie.guilanguage));
    await this.resumeSessionStatus(false, this.props.mongo, false, false, this.state.role);
    let bg = accessCookie(SemTalkCookie.backgroundColor);
    if (bg !== "undefined" || bg !== null) {
      document.body.style.backgroundColor = bg;
    }

    if (sem) {
      sem.tseditor.addHeaderFooter(this.state.headerfooterfields);
      // sem.tseditor.togglePanZoom();
      // sem.tseditor.togglePanZoom();
      if (sem.page) {
        await sem.initStencils(
          sem.base,
          sem,
          this.state.guilanguage,
          this.sprops,
          this.state.bpmnrules,
          this.state.role,
          this.loadStencil
        );
        let dclass = sem.page.ClassOf();
        this.stencil = sem.getStencil(dclass);
        sem.masters = this.stencil;
        if (sem.tseditor) {
          sem.tseditor.configShapes(sem.tseditor.graph, this.stencil);
          if (sem.tseditor.sidebar) {
            sem.tseditor.sidebar.LoadStencil(this.stencil);
            sem.tseditor.UpdatePortalStencil(this.stencil);
          }
        }
        this.setState({ stencil: this.stencil });
        settingsChanged();
      }
    }

    if (blogin && mongo.semuserlogin !== null) {
      this.DoCommand(SemTalkOnlineCommand.mgLogin, {});
    }

  }

  public IsMongo = (): boolean => {
    return this.state.mongo.usemongo;
  }

  public SetState(s: any) {
    this.setState(s);
  }

  private updateRepo = async () => {
    let sem = this.state.semtalk;
    if (sem) {
      this.SetProgressIndicator(sem.getResStr(ResID.STRREPOSITORYEXPORT));
      updateRepository(sem.base,
        this.state.mongo,
        this.state.sharepointrepository,
        this,
        sem,
        this.gprops.graphClient,
        []);
      this.SetProgressIndicator("");
    }
  }

  public GetRole(): string {
    return this.getRoleString(this.state.role, this.state.semtalk);
  }
  private renderPlanMaker = (semtalk: IVisioRDFS, mongo: IMongoOption) => {
    let divplanner: any = {
      padding: "50px",
    };
    const stackTokens = { childrenGap: 10 };
    let suspfallback = <div></div>;

    return (
      <div style={divplanner}>
        <Stack tokens={stackTokens}>
          <StackItem>
            <Suspense fallback={suspfallback}>
              <SemTalkPlanner
                title={semtalk
                  .getResStrListener(ResIDL.STRBONHRAC)
                  .replace(":", "")}
                semtalk={semtalk}
                callback={this}
                islocked={this.state.islocked}
                // graphClient={this.graphClient}
                // teams={this.state.teams}
                // azureAD={this.state.azureAD}
                // context={this.sprops.context}
                // callbackimport={this.ImportPlannerTasks}
                // sharepoint={this.sprops}
                // sharepointrepository={this.state.sharepointrepository}
                isplanmaker={this.props.isplanmaker}
                language={this.state.language}
                guilanguage={this.state.guilanguage}
                isusertask={false}
              />
            </Suspense>
          </StackItem>
          <StackItem>
            {!this.state.hideGraph && this.renderGraph(semtalk)}
          </StackItem>
        </Stack>
        {this.state.showSettings && this.renderSettings(semtalk, mongo, suspfallback)}
        <ContextualMenu
          items={this.menuItems}
          hidden={!this.state.showContextualMenu}
          target={this.popupMenuRef}
          onItemClick={this.onHideContextualMenu}
          onDismiss={this.onHideContextualMenu}
        />
      </div>
    );
  }
  private renderCommandBar = (semtalk: IVisioRDFS, suspfallback: JSX.Element) => {
    return (
      <Suspense fallback={suspfallback}>
        <SemTalkCommandBar
          semtalk={semtalk}
          callback={this}
          ribbon={this.state.ribbon}
          showlanguage={this.state.showlanguage}
          language={this.state.language}
          guilanguage={this.state.guilanguage}
          mongo={this.state.mongo}
          islocked={this.state.islocked}
          isadmin={this.state.isadmin}
          ischeckedout2me={this.state.ischeckedout2me}
          // role={this.getRoleString(this.state.role, this.state.semtalk)}
          username={this.getUsername()}
          usegraph={this.gprops.usegraph}
        />
      </Suspense>
    );
  }
  private renderToolBar = (semtalk: IVisioRDFS, suspfallback: JSX.Element) => {
    return (
      <Suspense fallback={suspfallback}>
        <SemTalkToolBar
          semtalk={semtalk}
          callback={this}
          ribbon={this.state.toolbar}
          mongo={this.state.mongo}
          showlanguage={this.state.showlanguage}
          language={this.state.language}
          guilanguage={this.state.guilanguage}
          ischeckedout2me={this.state.ischeckedout2me}
          ischeckedout={this.state.checkedOut}
          islocked={this.state.islocked}
          isadmin={this.state.isadmin}
          ismenu={this.state.showCommandBar}
          loading={this.state.loading}
          ispagesenabled={this.state.ispagesenabled}
          role={this.state.role}
        />
      </Suspense>
    );
  }
  private renderBreadCrumbs = (semtalk: IVisioRDFS, suspfallback: JSX.Element) => {
    return (this.state.cntpages > 1 ||
      this.GetFilename().length > 0) && (
        <Suspense fallback={suspfallback}>
          <SemTalkBreadCrumbs
            context={this.sprops.context}
            semtalk={semtalk}
            filename={this.GetFilename()}
          />
        </Suspense>
      );
  }
  private renderGraph = (semtalk: IVisioRDFS) => {
    let divHostStyle: any = {
      position: "fixed",
      // overflow: "hidden",
      flex: 3,
      height: "100%", //this.props.height,
      width: "100%", //this.props.width
    };
    let ispivot = this.state.dockpivot;
    return (<div id="iframeHost" style={divHostStyle}>
      {/* <GraphEditor/> */}
      {this.state.xmlgraph && semtalk && this.state.diag && (
        <TSEditor
          site={this.sprops.site}
          support={this.sprops.support}
          xmlgraph={this.state.xmlgraph}
          semtalk={semtalk}
          diagcaption={this.state.diag.ObjectCaption}
          diagid={this.state.diag.ID}
          width={this.state.width}
          height={this.state.height}
          stencil={this.stencil}
          callback={this}
          isconnectionpoints={true}
          islocked={this.state.islocked}
          shapesleftside={this.state.shapesleftside}
          dockpanandzoom={this.state.dockpanandzoom}
          docksearch={this.state.docksearch}
          dockstencil={this.state.dockstencil}
          dockpivot={this.state.dockpivot}
          editdialogwidth={this.state.editdialogwidth}
          panandzoomcaption={semtalk.getResStr(ResID.STRPANZOOM)}
          docknavigator={this.state.docknavigator}
          dockplanner={this.state.dockplanner}
          allownegativecoordinates={this.state.allownegativecoordinates}
          language={this.state.language}
          isplanmaker={this.props.isplanmaker}
          headerfooterfields={this.state.headerfooterfields}
          showheaderfooterfields={this.state.showheaderfooterfields}
          printlandscape={this.state.printlandscape}
          printpageformat={this.state.printpageformat}
          loading={this.state.loading}
          ispivot={ispivot}
          usegraph={false}
          role={this.state.role}
          spCheckInOut={false}
          mongo={this.props.mongo}
          showSimulation={this.state.showSimulation}
          showBPMN={this.state.showBPMN}
          sharepointlibrary={this.gprops.sharepointlibrary}
          sharepointlibrarysite={this.gprops.sharepointlibrarysite}
          sharepointdocumentcolumns={this.state.sharepointdocumentcolumns}
          reflexivelinks={this.state.isreflexivelinks}
          autoextend={this.state.autoextend}
          autoscroll={this.state.autoscroll}
          extendParentsOnMove={this.state.extendParentsOnMove}
          extendParentsOnAdd={this.state.extendParentsOnAdd}
          resizecontainer={this.state.resizecontainer}
          splitenabled={this.state.splitenabled}

        />)}
    </div>
    );
  }
  private renderSettings = (semtalk: IVisioRDFS, mongo: IMongoOption, suspfallback: JSX.Element) => {
    return (
      <Panel
        isOpen={this.state.showSettings}
        type={PanelType.medium}
        onDismiss={() => {
          this.reloadFromSettingsPanel();
        }}
        onRenderFooterContent={this.onRenderSettingsFooterContent}
        isFooterAtBottom={true}
        closeButtonAriaLabel={semtalk.getResStrListener(ResIDL.STRDLGCMDCL)}
      >
        <Suspense fallback={suspfallback}>
          <SemTalkSettings
            title={semtalk.getResStr(ResID.STROPTIONS)}
            semtalk={semtalk}
            callback={this}
            guilanguage={this.state.guilanguage}
            islocked={this.state.islocked}
            isadmin={this.state.isadmin}
            context={this.sprops.context}
            mongo={mongo}
            isplanmaker={this.props.isplanmaker}
            isbpmn={semtalk.template === "bpmn"}
          />
        </Suspense>
      </Panel>
    );
  }
  private renderRepository = (semtalk: IVisioRDFS, mongo: IMongoOption, suspfallback: JSX.Element) => {
    let repo = mongo.repository;
    if (!mongo.usemongo) {
      repo = "repository";
    }
    if (repo === "repository") repo = "Default";
    if (!mongo.usemongo && this.state.sharepointrepository) {
      repo = this.state.sharepointrepository;
      repo = repo.replace("/sites/", "");
      repo = repo.replace(":/", "/");
      repo = repo.replace(":/", "");
    }
    return (
      <Panel
        isOpen={this.state.showRepositoryTable}
        type={this.state.panelTypeRepositoryTable}
        customWidth={this.state.editdialogwidth}
        onDismiss={() => {
          this._onClose({ showRepositoryTable: false });
          for (let k in sessionStorage) {
            if (k.startsWith("mgGetItemNames_")) {
              sessionStorage.removeItem(k);
            }
          }
        }}
        headerText={
          semtalk.getResStr(ResID.STRREPOSITORY) + ": " + repo
        }
        closeButtonAriaLabel={semtalk.getResStrListener(
          "STRDLGCMDCL"
        )}
      >
        <Suspense fallback={suspfallback}>
          <SemTalkRepository
            mongo={mongo}
            context={this.sprops.context}
            site={this.sprops.site}
            semtalk={semtalk}
            callback={this}
            showSimulation={this.state.showSimulation}
            showBPMN={this.state.showBPMN}
            role={this.state.role}
            usegraph={this.gprops.usegraph}
            graphClient={this.gprops.graphClient}
            azureAD={this.state.azureAD}
            sharepointrepository={this.state.sharepointrepository}
          />
        </Suspense>
      </Panel>
    );
  }
  private renderSubTask = () => {
    return (<Panel
      isOpen={!this.state.hideSubTaskDialog}
      onDismiss={() => {
        this._onClose({ hideSubTaskDialog: true });
      }}
      isBlocking={false}
      type={PanelType.medium}
    >
      this.state.semtalk.renderSubTaskDialog(this.state.addoncommand)
    </Panel>);
  }
  private renderMongoLogin = (semtalk: IVisioRDFS, mongo: IMongoOption, suspfallback: JSX.Element) => {
    return (<Panel
      isOpen={!this.state.hidemgLoginDialog}
      onDismiss={() => {
        this._onClose({ hidemgLoginDialog: true });
      }}
      isBlocking={true}
      headerText={semtalk.getResStr(ResID.STRLOGIN).replace("&", "")}
      type={PanelType.smallFluid}
    >
      <Suspense fallback={suspfallback}>
        <LoginIntern
          setToken={this.props.setToken}
          setcurrToken={this.props.setcurrToken}
          mongo={mongo}
          dismiss={() => {
            this._onClose({ hidemgLoginDialog: true });
            this.autoSave();
            // window.location.reload();
            let role = accessCookie(SemTalkCookie.role);
            // this.setState({ role: role });
            this.resumeSessionStatus(false, mongo, false, false, role);
            setTimeout(() => {
              settingsChanged();
            }, 1000);
          }}
          role={this.state.role}
          callback={this}
        />
      </Suspense>
    </Panel>
    );
  }
  private renderRole = (semtalk: IVisioRDFS, mongo: IMongoOption, suspfallback: JSX.Element) => {
    return (<Panel
      isOpen={!this.state.hideRoleDialog}
      onDismiss={() => {
        this._onClose({ hideRoleDialog: true });
      }}
      isBlocking={true}
      headerText={semtalk.getResStr(ResID.STRLOGIN).replace("&", "")}
      type={PanelType.smallFluid}
    >
      <Suspense fallback={suspfallback}>
        <LoginRole
          mongo={mongo}
          dismiss={() => {
            this._onClose({ hideRoleDialog: true });
            let role = accessCookie(SemTalkCookie.role);
            this.setState({ role: role });
            setTimeout(() => {
              settingsChanged();
            }, 1000);
            // window.location.reload();
          }}
          role={this.state.role}
          callback={this}
        />
      </Suspense>
    </Panel>
    );
  }
  private renderConfirmSaveAs = (semtalk: IVisioRDFS) => {
    return (<Dialog
      hidden={!this.state.isconfirmsaveas}
      onDismiss={() => { this.CloseConfirmDialog({ isconfirmsaveas: false }); }}
      dialogContentProps={{
        type: DialogType.normal,
        title: this.selectedfilename,
        subText: semtalk.getResStrListener(ResIDL.STRFILEEXISTS),
      }}
      modalProps={{}}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            this.DoConfirmedDialog(
              async () => { this.saveAsDocumentCallBack(this.selectedfilename, this.selectedfiletype); },
              { isconfirmsaveas: false }
            );
          }}
          text={semtalk.getResStr(ResID.STRDLGCMDOK)}
        />
        <DefaultButton
          onClick={() => { this.CloseConfirmDialog({ isconfirmsaveas: false }); }}
          text={semtalk.getResStr(ResID.STRDLGCMDCA)}
        />
      </DialogFooter>
    </Dialog>
    );
  }
  private renderConfirmRepository = (semtalk: IVisioRDFS) => {
    return (<Dialog
      hidden={!this.state.isconfirmrepository}
      onDismiss={() => { this.CloseConfirmDialog({ isconfirmrepository: false }); }}
      dialogContentProps={{
        type: DialogType.normal,
        title: semtalk.getResStr(ResID.STRREPOSITORY),
        subText: semtalk.getResStr(ResID.STRCONFIRMSAVEREPO),
      }}
      modalProps={{}}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            this.DoConfirmedDialog(
              this.updateRepo,
              { isconfirmrepository: false }
            );
          }}
          text={semtalk.getResStr(ResID.STRDLGCMDOK)}
        />
        <DefaultButton
          onClick={() => { this.CloseConfirmDialog({ isconfirmrepository: false }); }}
          text={semtalk.getResStr(ResID.STRDLGCMDCA)}
        />
      </DialogFooter>
    </Dialog>
    );
  }
  private renderConfirmDelete = (semtalk: IVisioRDFS) => {
    return (<Dialog
      hidden={!this.state.isconfirmdelete}
      onDismiss={() => { this.CloseConfirmDialog({ isconfirmdelete: false }); }}
      dialogContentProps={{
        type: DialogType.normal,
        title: this.selectedfilename,
        subText:
          semtalk.getResStrListener(ResIDL.STRDELETEDOCUMENT),
      }}
      modalProps={{}}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            this.DoConfirmedDialog(
              async () => {
                await this.deleteDocumentCallBack(this.selectedfilename);
              },
              { isconfirmdelete: false }
            );
          }}
          text={semtalk.getResStr(ResID.STRDLGCMDOK)}
        />
        <DefaultButton
          onClick={() => { this.CloseConfirmDialog({ isconfirmdelete: false }); }}
          text={semtalk.getResStr(ResID.STRDLGCMDCA)}
        />
      </DialogFooter>
    </Dialog>
    );
  }
  private renderConfirmDeletePage = (semtalk: IVisioRDFS) => {
    return (<Dialog
      hidden={!this.state.isconfirmdeletepage}
      onDismiss={() => { this.CloseConfirmDialog({ isconfirmdeletepage: false }); }}
      dialogContentProps={{
        type: DialogType.normal,
        title: this.state.semtalk!.getResStrListener(ResIDL.STRDLHTMLOUTDIA),
        subText:
          this.state.semtalk!.getResStrListener(ResIDL.STRDELETEDIAGRAM),
      }}
      modalProps={{}}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            this.DoConfirmedDialog(
              this.deletePageConfirmed,
              { isconfirmdeletepage: false }
            );
          }}
          text={semtalk.getResStr(ResID.STRDLGCMDOK)}
        />
        <DefaultButton
          onClick={() => { this.CloseConfirmDialog({ isconfirmdeletepage: false }); }}
          text={semtalk.getResStr(ResID.STRDLGCMDCA)}
        />
      </DialogFooter>
    </Dialog>
    );
  }

  public render(): React.ReactElement<ISemTalkOnlineProps> {
    const semtalk = this.state.semtalk;
    const mongo = this.state.mongo;
    let suspfallback = <div></div>;

    if (semtalk) {
      if (this.props.isplanmaker) {
        return this.renderPlanMaker(semtalk, mongo);
      }
      let r = (
        <div>
          <Suspense fallback={suspfallback}>
            <div>
              <div>
                <Stack>
                  {this.state.showCommandBar && this.state.ribbon.length > 0 && this.renderCommandBar(semtalk, suspfallback)}
                  {this.state.showToolBar && this.state.toolbar.length > 0 && this.renderToolBar(semtalk, suspfallback)}
                  {this.state.nosuccess && (
                    <MessageBar
                      messageBarType={this.state.msgbartype}
                      onDismiss={(_event) =>
                        this.alert("", MessageBarType.info)
                      }
                      dismissButtonAriaLabel="Close"
                    >
                      {this.state.errormsg}
                    </MessageBar>
                  )}
                  {this.state.showBreadCrumbs && this.renderBreadCrumbs(semtalk, suspfallback)}

                  {(this.state.isLoading.length > 0) && (
                    <ProgressIndicator
                      label={this.state.isLoading}
                      description={""}
                    />
                  )}
                </Stack>
                {this.state.hideGraph && (
                  <SemTalkLogFile
                    items={this.state.publishinglog}
                  ></SemTalkLogFile>
                )}
                {!this.state.hideGraph && this.renderGraph(semtalk)}
              </div>
              <div>
                {this.state.showSearch && renderSearch(this, semtalk, this.state, this._onClose, suspfallback)}
                {this.state.showFacetSearch && renderFacetSearch(semtalk, mongo, this.state, this._onClose, suspfallback)}
                {this.state.showQuickEdit && renderQuickEdit(this, semtalk, mongo,
                  this.props.context, this.gprops, this.sprops, this.state, this._onClose, suspfallback)}
                {this.state.showSettings && this.renderSettings(semtalk, mongo, suspfallback)}
                {this.state.showModelProperties && renderModelProperties(this, semtalk, mongo,
                  this.props.context, this.gprops, this.sprops, this.state, this.state.role, this._onClose,
                  suspfallback)}
                {this.state.showDiag && renderDiagram(semtalk, this.state, this._onClose, suspfallback)}
                {this.state.showNav && renderNavigation(this, semtalk, this.state, this._onClose, suspfallback)}
                {this.state.showHie && renderHierarchy(this, semtalk, this.state, this._onClose, suspfallback)}
                {this.state.showAssocTypes && renderAssocTypes(this, semtalk, this.state, this._onClose, suspfallback)}
                {this.state.showAttrTypes && renderAttrTypes(this, semtalk, this.state, this._onClose, suspfallback)}
                {this.state.showMethodTypes && renderMethodTypes(this, semtalk, this.state, this._onClose, suspfallback)}
                {this.state.showStateTypes && renderStateTypes(this, semtalk, this.state, this._onClose, suspfallback)}
                {this.state.showDiagTypes && renderDiagTypes(this, semtalk, this.state, this._onClose, suspfallback)}
                {this.state.showRep && renderReport(semtalk, this.state, this._onClose, suspfallback)}
                {this.state.showRepositoryTable && this.renderRepository(semtalk, mongo, suspfallback)}
                {this.state.showTableEditor && renderTableEditor(this, semtalk, mongo, this.state, this.props.context,
                  this.gprops, this.props.role, this._onClose, suspfallback)}
                {this.state.showTableImport && renderTableImport(semtalk, mongo, this.state,
                  this.props.context, this.gprops, this.sprops, this._onClose, suspfallback)}
                {/* {this.state.showProp && this.renderPivot0(semtalk, mongo, suspfallback)} */}
                {this.state.showSemantics && renderSemantics(this, semtalk, mongo, this.state,
                  this.props.context, this.gprops, this.sprops, this._onClose, suspfallback)}
                {!this.state.hideHyperlinkDialog && renderHyperlink(semtalk, mongo, this.state, this.sprops,
                  this.gprops, this.props.context, this._onClose, suspfallback)}
                {!this.state.hideOpenLinkDialog && renderOpenLink(semtalk, this.state, this._onClose)}

                {!this.state.hideFileOpen && renderFileOpen(this, semtalk, mongo, this.state,
                  this.props.context, this.gprops, this.sprops, this.GetFilename(), this.props.guilanguage,
                  this.onRenderSemTalkOpenFooterContent, this._onClose, suspfallback)}

                {!this.state.hideVersionControl && renderVersionControl(this, semtalk, mongo, this.state,
                  this.props.context, this.gprops, this.sprops, this.GetFilename(),
                  this.props.guilanguage, this.onRenderSemTalkVersionControlFooterContent, this._onClose, suspfallback)}

                {!this.state.hideFileOneDrive && renderOneDrive(this, semtalk, mongo, this.state,
                  this.props.context, this.gprops, this.props.guilanguage, this._onClose, suspfallback)}

                {!this.state.hidePublishDialog && renderPublish(this, semtalk, mongo, this.state,
                  this.props.context, this.gprops, this.sprops, this.GetFilename(), this.props.guilanguage,
                  this.onRenderSemTalkOpenFooterContent, this._onClose, suspfallback)}

                {!this.state.hidePropertyDialog && renderPivot(this, semtalk, mongo, this.state,
                  this.props.context, this.gprops,
                  this.sprops,
                  this.state.sharepointdocumentcolumns,
                  this.state.role,
                  this._onClose, suspfallback)}

                {!this.state.hideCustomizeDialog && renderCustomize(semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideSelectDialog && renderSelect(semtalk, mongo, this.state,
                  this.props.context, this.sprops, this.gprops, this.selectclass, this.ChangeObject,
                  this._onClose, suspfallback)}
                {!this.state.hideTermSetDialog && renderTermSet(this, semtalk, this.state,
                  this.props.context, this.gprops, this.sprops, this.ImportTermSetTree, this._onClose, suspfallback)}
                {!this.state.hideSiteBuilderDialog && renderSiteBuilder(this, semtalk, this.state,
                  this.props.context, this.gprops, this.sprops, this._onClose, suspfallback)}

                {!this.state.hideExpandDialog && renderExpand(semtalk, mongo, this.state,
                  this.ExpandObject, this.ExpandSimilarObject, this._onClose, suspfallback)}
                {!this.state.hideRelationDialog && renderRelation(semtalk, mongo, this.state,
                  this.props.context, this.gprops, this.sprops, this.selectclass, this.otherroot,
                  this.othertext, this.ChangeRelation, this._onClose, suspfallback)}

                {!this.state.hideCreateDiagramDialog && renderCreateDiagram(this, semtalk, this.state,
                  this.createPage, this.onRenderSemTalkNewDiagramFooterContent, this._onClose, suspfallback)}

                {!this.state.hideInsertDialog && renderInsert(semtalk, mongo, this.state,
                  this.props.context, this.gprops, this.sprops, this.isclasses, this.diagramroot,
                  this.InsertObject, this._onClose, suspfallback)}
                {!this.state.hideComposeDialog && renderCompose(semtalk, mongo, this.state,
                  this.props.context, this.gprops, this.sprops, this.composeObjectCallback,
                  this._onClose, suspfallback)}
                {!this.state.hideTranslateDialog && renderTranslate(semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideTasksDialog && renderTasks(this, semtalk, this.state,
                  this.props.context, this.gprops, this.ImportPlannerTasks, this._onClose, suspfallback)}
                {!this.state.hidePlannerDialog && renderPlanner(this, semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideFormDialog && renderForm(semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideSubTaskDialog && this.renderSubTask()}
                {!this.state.hideShapeStyle && renderShapeStyle(this, semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideHelp && renderHelp(semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideRoleDialog && this.renderRole(semtalk, mongo, suspfallback)}
                {!this.state.hidemgLoginDialog && this.renderMongoLogin(semtalk, mongo, suspfallback)}
                {!this.state.hidemgUserProfilDialog &&
                  renderMongoUser(semtalk, this.state, mongo, this.state.role, this.autoSave, this._onClose, suspfallback)}
                {!this.state.hideUserManager &&
                  renderMongoUserManager(semtalk, this.state, mongo, this.autoSave, this._onClose, suspfallback)}
                {!this.state.hideCheckRepair && renderCheckRepair(this, semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideSimulation && renderSimulation(this, semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideDocuments && renderDocuments(this, semtalk,
                  mongo, this.state, this.gprops, this.sprops, this._onClose, suspfallback)}
                {!this.state.hideShareDocument && renderShareDocument(semtalk, mongo, this.state,
                  this.sprops, this.GetFilename(), this.getUsername(), this._onClose, suspfallback)}
                {!this.state.hidePrintPreview && renderPrintPreview(this, semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideChatgpt && renderChatgpt(this, semtalk, this.state, mongo, this._onClose, suspfallback)}
                {!this.state.hideHeaderFooter && renderHeaderFooter(this, semtalk, this.state, mongo, this._onClose, suspfallback)}
                {!this.state.hideImages && renderImages(this, semtalk, this.state, mongo, this._onClose, this.selectedImages, suspfallback)}
                {!this.state.hideReportManager && renderReportManager(semtalk, mongo, this.state, this._onClose, suspfallback)}
                {!this.state.hidePromptManager && renderPromptManager(semtalk, mongo, this.state, this._onClose, suspfallback)}
                {!this.state.hideStorageManager && renderStorageManager(this, semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideStencilEditor && renderStencilEditor(this, semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideRibbonEditor && renderRibbonEditor(this, semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideRenameObjects && renderRenameObjects(this, semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideImport && renderImport(this, semtalk, mongo, this.state, this.sprops, this.filename, this._onClose, suspfallback)}
                {!this.state.hideCharting && renderCharting(this, semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideImpressum && renderImpressum(semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hidePrivacy && renderPrivacy(semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideOWLImport && renderOWLImport(this, semtalk, mongo, this.state, this.sprops, this.filename,
                  this.selectedexternalob, this._onClose, suspfallback)}
                {!this.state.hideLayout && renderLayout(this, semtalk, this.state, this._onClose, suspfallback)}
                {!this.state.hideKeyManager && renderKeyManager(this, semtalk, this.state, mongo, this._onClose, suspfallback)}
                {!this.state.hideExportManager && renderExportManager(this, semtalk, this.state, mongo, this._onClose, suspfallback)}
              </div>
            </div>
          </Suspense>
          {this.state.isconfirmsaveas && this.renderConfirmSaveAs(semtalk)}
          {this.state.isconfirmrepository && this.renderConfirmRepository(semtalk)}
          {this.state.isconfirmdelete && this.renderConfirmDelete(semtalk)}
          {this.state.isconfirmdeletepage && this.renderConfirmDeletePage(semtalk)}
          <ContextualMenu
            items={this.menuItems}
            hidden={!this.state.showContextualMenu}
            target={this.popupMenuRef}
            onItemClick={this.onHideContextualMenu}
            onDismiss={this.onHideContextualMenu}
          />
        </div>
      );
      return r;
    } else {
      return <div></div>;
    }
  }
}
