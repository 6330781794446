 export enum SemTalkMaster {
  MasterSwimlane = "swimlane",
  MasterClass = "Class",
  MasterSystemClass = "SystemClass",
  MasterUMLClass = "UMLClass",
  MasterInstance = "Instance",
  MasterSubClassOf = "SubClassOf",
  MasterInstanceOf = "InstanceOf",
  MasterProperty = "Property",
  MasterComment = "Comment",
  MasterOrgUnit = "OrgUnit",
  MasterPosition = "Position",
  MasterRole = "Role",
  MasterPerson = "Person",
  MasterText = "Text",
  MasterImage = "Image",
  MasterAttributeType = "AttributeClass",
  MasterAssociationType = "AssociationClass",
  MasterDiagramType = "DiagramClass",  
  MasterDataStore = "datastore",
  MasterPhysResource = "PhysResource",
  MasterDocument = "Document",
  MasterAttachment = "Attachment"
}