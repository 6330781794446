import { BPMN_AssociationName } from "../semtalklistener/subtask/bpmn/bpmninterface";
import { EPC_ElementName } from "../semtalklistener/subtask/epc/epcinterface";
import { Process_ElementName } from "../semtalklistener/processInterface";
import {
  IObjectBase,
  ISemTalkNamedThing,
  ISemTalkSynonym,
  // ISemTalkSharePointObject,
  // ISemTalkSharePointInstance,
  // ISemTalkSharePointClass,
  ISemTalkObject,
  ILabelSpec,
  ISemTalkDiagram,
  ISemTalkComposition,
  ISemTalkAttribute,
  ISemTalkAttributeType,
  ISemTalkAssociation,
  ISemTalkAssociationType,
  ISemTalkClass,
  ISemTalkInstance,
  ISemTalkNode,
  ISemTalkBusinessClass,
  ISemTalkMethodType,
  ISemTalkStateType,
  ISemTalkVirtualInstance,
  ISemTalkSystemClass,
  ISemTalkSpecialization,
  SemTalkType, SemTalkRelation, SemTalkValueType,
  SemTalkBaseConstant,
  // SemTalkLanguage,
  ModelAttribute,
  SemTalkID,
  SemTalkAttachment
} from "./Interface";
import { GetLanguage } from './langtools';
import { ObjectBase } from './ObjectBase';

import { Utils } from "./utils";
import { SemTalkMaster } from "../SemTalkMaster";

// tslint:disable:max-classes-per-file
// tslint:disable:variable-name

/* tslint:disable:member-ordering */
/* tslint:disable:forin */

abstract class SemTalkNamedThing implements ISemTalkNamedThing {
  constructor(tb: IObjectBase, newname: string, otype: SemTalkType) {
    this._tbase = tb;
    this._name = newname;
    this._type = otype;
    // if (tb === null) {
    //   throw new Error("SemTalkNamedThing: Each object must have a objectbase!");
    // }
    // if (newname === null) {
    //   throw new Error("SemTalkNamedThing: Each object must have a name!");
    // }
    // if (otype === null) {
    //   throw new Error("SemTalkNamedThing: Each object must have a type!");
    // }
    return this;
  }
  public get ID(): SemTalkID { return ObjectBase.String2SemTalkID(""); }
  protected _name: string;
  public get ObjectName(): string {
    return this._name;
  }
  private _type: SemTalkType;
  private _tbase: IObjectBase;
  public get ObjectBase(): IObjectBase { return this._tbase; }

  private _label_synonyms: { [language: string]: ISemTalkSynonym } = {};
  private _alt_synonyms: ISemTalkSynonym[] = [];

  public MakeAltSynonym(name: string, lang: string): ISemTalkSynonym {
    let s = this.FindAltSynonym(name, lang);
    if (s === undefined) {
      s = new SemTalkSynonym(this as ISemTalkNamedThing, name, lang);
      this._alt_synonyms.push(s);
      s.Synonym = true;
    }
    return s;
  }
  public MakeSynonym(name: string, lang: string, isinv?: boolean): ISemTalkSynonym {
    if (name.includes("#")) {
      name = name.substr(name.indexOf("#") + 1);
    }
    let s = this.FindSynonym(lang, isinv);
    // tslint:disable-next-line: no-use-before-declare
    //this.DeleteSynonym(lang);
    let langname = lang.toString();
    if (s === undefined) {
      s = new SemTalkSynonym(this as ISemTalkNamedThing, name, lang, isinv);
      if (isinv !== undefined && isinv === true) {
        langname = "Inv " + langname;
      }
      this._label_synonyms[langname] = s;
      // console.debug("OnSynonymAdded: " + s.Owner.ObjectName + s);
      this.ObjectBase.OnSynonymAdded(s);
    } else {
      s.Name = name;
    }
    return s;
  }
  public DeleteSynonym(lang: string, isinv?: boolean): ISemTalkNamedThing {
    let langname = lang.toString();
    if (isinv !== undefined && isinv === true) {
      langname = "Inv " + langname;
    }
    const s = this._label_synonyms[langname];
    if (s !== undefined) {
      delete this._label_synonyms[langname];
      // s.Owner = null;
      // console.debug("OnSynonymDeleted: " + s.Owner.ObjectName + s);
      this.ObjectBase.OnSynonymDeleted(s);
    }
    return this;
  }
  public DeleteAltSynonym(name: string, lang: string): ISemTalkNamedThing {
    let s = this.FindAltSynonym(name, lang);
    if (s !== undefined) {
      this._alt_synonyms.splice(this._alt_synonyms.indexOf(s), 1);
    }
    return this;
  }
  public FindSynonym(lang: string, isinv?: boolean): ISemTalkSynonym | undefined {
    let langname = lang.toString();
    if (isinv !== undefined && isinv === true) {
      langname = "Inv " + langname;
    }
    return this._label_synonyms[langname];
  }
  public FindAltSynonym(name: string, lang: string): ISemTalkSynonym | undefined {
    for (let s of this._alt_synonyms) {
      if (s.Name === name && s.Language === lang) {
        return s;
      }
    }
    return undefined;
  }
  public Synonyms(): ISemTalkSynonym[] {
    const alist: ISemTalkSynonym[] = [];
    for (const lang in this._label_synonyms) {
      alist[alist.length] = this._label_synonyms[lang];
    }
    return alist;
  }
  public AltSynonyms(): ISemTalkSynonym[] {
    return this._alt_synonyms;
  }
  public AllSynonyms(): ISemTalkSynonym[] {
    const alist: ISemTalkSynonym[] = [];
    for (const lang in this._label_synonyms) {
      alist[alist.length] = this._label_synonyms[lang];
    }
    return alist;
  }
  public get ID2Name(): string {
    if (this._tbase.ShowNamespace()) {
      return this._name;
    }
    if (this._name && this._name.includes("#")) {
      return this._name.substr(this._name.indexOf("#") + 1);
    } else {
      return this._name;
    }
  }
  // public get ID2Namespace(): string {
  //   if (!this._name.includes("#")) {
  //     return "";
  //   } else {
  //     return this._name.substr(0, this._name.indexOf("#"));
  //   }
  // }
  public get ObjectType(): SemTalkType {
    return this._type;
  }
  public set ObjectType(val: SemTalkType) {
    this._type = val;
  }
  public toString(): string { return "<" + ObjectBase.SemTalkTypeName(this._type) + " " + this._name + ">"; }

  public CurrentName(lang: string): string {
    // let iso = SemTalkNamedThing._iso2languagename(lang);
    // if (iso === undefined && lang.indexOf("-") > 0)
    //   lang = SemTalkNamedThing._iso2languagename[lang.substr(0, lang.indexOf("-"))];
    // if (iso !== undefined) lang = iso;
    if (lang in this._label_synonyms) {
      return this._label_synonyms[lang].Name;
    }
    else {
      return this._name;
    }
  }
  public ID2NameNsp(): string {
    const lang: string = this._tbase.CurrentNsp;
    if (lang) {
      return this.ID2NameNspLan(lang);
    } else {
      return this.ObjectName;
    }
  }
  public get ObjectCaption(): string {
    return this.ID2NameNsp();
  }
  public ID2NameNspLan(lang: string): string {
    if (this._tbase.ShowNamespace()) {
      return this._name;
    }
    let res: string = this.CurrentName(lang);
    if (res.indexOf("#") > -1) {
      res = res.substr(res.indexOf("#") + 1);
    }
    return res;
  }
  public Load(je: any): void {
    if (je.synonyms) {
      for (const a of je.synonyms) {
        if (this.FindSynonym(a.namespace)) {
          this.MakeAltSynonym(a.name, a.namespace).Load(a);
        } else {
          this.MakeSynonym(a.name, a.namespace, (a.inverse === '1')).Load(a);
        }
      }
    }
  }
  public LoadXML(element: Element): void {
    const synonyms = element.getElementsByTagName("Synonym");
    for (const i in synonyms) {
      const syn = synonyms[i];
      if (syn.parentNode === element && syn.attributes && syn.nodeName === "Synonym") {
        const name = syn.attributes.getNamedItem("name")?.value;
        const namespace = syn.attributes.getNamedItem("namespace")?.value;
        if (namespace && name) {
          if (this.FindSynonym(namespace)) {
            this.MakeAltSynonym(name, namespace).LoadXML(syn);
          } else {
            const inverse = syn.attributes.getNamedItem("inverse")?.value;
            this.MakeSynonym(name, namespace, (inverse === '1')).LoadXML(syn);
          }
        }
      }
    }
  }
  public Save(par: any[]): any {
    const el: any = {};
    par.push(el);
    el.name = this._name;
    el.ObjectType = ObjectBase.SemTalkTypeName(this._type);
    if (this._label_synonyms !== null) {
      const syns: any[] = [];
      for (const l in this._label_synonyms) {
        const s = this._label_synonyms[l];
        s.Save(syns);
      }
      el.synonyms = syns;
    }
    return el;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    el.setAttribute("name", this._name);
    el.setAttribute("ObjectType", ObjectBase.SemTalkTypeName(this._type));
    if (this._label_synonyms !== null) {
      for (const l in this._label_synonyms) {
        const s = this._label_synonyms[l];
        let sy = xd.createElement("Synonym");
        el.appendChild(sy);
        s.SaveXML(xd, sy);
      }
    }
  }
  public Clone(oth: ISemTalkNamedThing) {
    for (let s2 of oth.Synonyms()) {
      let s2l = GetLanguage(s2.Language);
      if (s2l) {
        if (!this.FindSynonym(s2l, s2.Inverse)) {
          this.MakeSynonym(s2.Name, s2l, s2.Inverse);
        }
      }
    }
  }
}

export class SemTalkSynonym implements ISemTalkSynonym {
  constructor(owner: ISemTalkNamedThing, name: string, lang: string, isinv?: boolean, issyn?: boolean) {
    this.Owner = owner;
    this.Language = lang;
    this.Name = name;
    this._name = name;
    if (isinv !== undefined) {
      this.Inverse = isinv;
    }
    if (issyn !== undefined) {
      this.Synonym = issyn;
    }
  }
  private _name: string;
  public Owner: ISemTalkNamedThing;
  public Language: string;
  public get Name(): string { return this._name; }
  public set Name(s: string) {
    if (s.includes("#")) {
      s = s.substr(s.indexOf("#") + 1);
    }
    if (s !== this._name && !this.Synonym) {
      const oldname = this._name;
      this._name = s;
      if (this.Owner !== undefined) {
        // console.debug("OnSynonymRenamed: " + this.Owner.ObjectName + s);
        this.Owner.ObjectBase.OnSynonymRenamed(this, oldname);
      }
    }
  }
  public Inverse: boolean = false;
  public Synonym: boolean = false;
  public Comment: string | null = null;
  private _type = SemTalkType.SemTalkSynonym;

  public toString(): string { return "<" + ObjectBase.SemTalkTypeName(this._type) + " " + this.Name + " " + this.Language + ">"; }

  public Load(je: any): void {
    this.Inverse = (je.inverse === "1");
    this.Synonym = (je.synonym === "1");
    if (je.comment) {
      this.Comment = je.comment;
    }
  }
  public LoadXML(element: Element): void {
    if (element.attributes) {
      const inverse = element.attributes.getNamedItem("inverse")?.value;
      if (inverse) this.Inverse = (inverse === '1');
      const synonym = element.attributes.getNamedItem("synonym")?.value;
      if (synonym) this.Synonym = (synonym === '1');
      const comment = element.attributes.getNamedItem("comment")?.value;
      if (comment) {
        this.Comment = comment;
      }
    }
  }

  public Save(par: any[]): any {
    const el: any = {};
    el.ObjectType = "Synonym";
    el.name = this.Name;
    el.namespace = this.Language;
    if (this.Inverse) { el.inverse = "1"; }
    if (this.Synonym) { el.synonym = "1"; }
    if (this.Comment !== null) { el.comment = this.Comment; }
    par.push(el);
    return el;
  }
  public SaveXML(_xd: XMLDocument, el: HTMLElement): void {
    el.setAttribute("name", this.Name);
    el.setAttribute("namespace", this.Language);
    if (this.Inverse) { el.setAttribute("inverse", "1"); }
    if (this.Synonym) { el.setAttribute("synonym", "1"); }
    if (this.Comment !== null) { el.setAttribute("comment", this.Comment); }
  }
}





export abstract class SemTalkObject extends SemTalkNamedThing implements ISemTalkObject {
  public indeletion: boolean = false;
  constructor(tb: IObjectBase, newname: string, otype: SemTalkType, id?: SemTalkID | null) {
    super(tb, newname, otype);
    if (id === null || id === undefined) {
      this._id = tb.NewID();
    } else {
      let nid = Number(id);
      // const iid: number = 
      if (nid > tb.maxid) {
        tb.maxid = nid;
        // this._id = id;
      }
      this._id = id;
    }
    tb._objectids[this._id + "_"] = this;
    tb._objects[newname] = this;
    const d = new Date();
    this.Created = d.toUTCString();
    this.CreatedBy = this.ObjectBase.User;
    this.ObjectBase.OnCreated(this);
    return this;
  }
  public ID2NameNsp(): string {
    let c = this.Composition();
    if (c !== null) {
      let s: string = (c as ISemTalkComposition).ID2NameNsp();
      return s;
    } else {
      return super.ID2NameNsp();
    }
  }

  public RenameObject(value: string): void {
    // if (this.ObjectName===value) {
    //   return;
    // }
    const ob = this.ObjectBase;
    if (ob._objects[value] !== undefined) {
      throw new Error("set ObjectName: There is a different object named: " + value);
    }
    const oldname: string = this.ObjectName;
    const oldid2name: string = this.ID2Name;
    this._name = value;
    ob._objects[value] = this;

    const newname: string = value;
    const lang = GetLanguage(ob.GetModelAttribute(ModelAttribute.currentnsp));
    if (lang) {
      let syn = this.FindSynonym(lang);
      // if (syn)
      //     console.debug(oldname + " " + newname + " " + syn.Name);
      if (syn && (!syn.Synonym || (syn.Name === oldname || syn.Name === oldid2name))) {
        this.DeleteSynonym(lang);
        this.MakeSynonym(newname, lang);
      }
      delete ob._objects[oldname];
      this.Changed = new Date().toUTCString();
      this.ChangedBy = this.ObjectBase.User;
    }
    ob.OnRenamed(this, oldname);
  }
  public get ID2Namespace(): string {
    if (!this._name.includes("#")) {
      return "";
    } else {
      return this._name.substr(0, this._name.indexOf("#"));
    }
  }
  public set ID2Namespace(n: string) {
    if (n.includes("#")) {
      throw new Error("Namespace must not contain a hashmark: " + n);
    }
    if (this.ObjectName.indexOf("#") < 0) {
      if (n.length > 0 && n.trim() !== "")
        this.RenameObject(n + "#" + this.ObjectName);
    }
    else {
      if (n.length > 0 && n.trim() !== "") {
        this.RenameObject(n + "#" + this.ObjectName.substr(this.ObjectName.indexOf("#") + 1));
      } else {
        this.RenameObject(this.ObjectName.substr(this.ObjectName.indexOf("#") + 1));
      }
    }
  }
  public abstract SystemClass(): ISemTalkSystemClass | null;

  public abstract UpdateLabel(mastername?: string): ILabelSpec;

  private _comment: string | null = null;
  get Comment(): string {
    if (this._comment === null) { return ""; }
    else { return this._comment; }
  }
  set Comment(value: string) {
    let v: string | null = value;
    if (value === "") { v = null; }
    const old: string | null = this._comment;
    this._comment = v;
    this.Changed = new Date().toUTCString();
    this.ChangedBy = this.ObjectBase.User;
    this.ObjectBase.OnCommentChanged(this, old, v);
  }
  // private _comments: { [language: string]: string } = {};
  private _id: SemTalkID;
  public get ID(): SemTalkID { return this._id; }
  public SrcID: string = "";
  public EditLink: string | null = null;

  public IsReadOnly: boolean = false;
  public Ghosted: boolean = false;

  private _created: string = "";
  public get Created(): string { return this._created; }
  public set Created(s: string) { if (!this.ObjectBase._loading) this._created = s; }

  private _createdby: string = "";
  public get CreatedBy(): string { return this._createdby; }
  public set CreatedBy(s: string) { if (!this.ObjectBase._loading) this._createdby = s; }

  private _changed: string = "";
  public get Changed(): string { return this._changed; }
  public set Changed(s: string) {
    if (!this.ObjectBase._loading) this._changed = s;
  }

  private _changedby: string = "";
  public get ChangedBy(): string { return this._changedby; }
  public set ChangedBy(s: string) {
    if (!this.ObjectBase._loading) this._changedby = s;
  }

  public Color: string | null = null;
  public LinePattern: string | null = null;
  public LineWeight: string | null = null;
  public Location: string | null = null;
  //public ExtRefinement: string | null = null;
  //  public Reference: SemTalkSharepointObject | null = null; // new tbase.SemTalkSharepointObject()
  private _extrefinement: string | null = null;
  get ExtRefinement(): string | null {
    return this._extrefinement;
  }
  set ExtRefinement(value: string | null) {
    const old: string | null = this._extrefinement;

    if (value) {
      this._extrefinement = value as string;
    } else {
      this._extrefinement = null;
    }
    this.Changed = new Date().toUTCString();
    this.ChangedBy = this.ObjectBase.User;
    if (old) {
      this.ObjectBase.OnExtDetached(this, old);
    }
    if (value) {
      this.ObjectBase.OnExtRefined(this, value);
    }
  }

  private _refinement: ISemTalkDiagram | null = null;
  get Refinement(): ISemTalkDiagram | null {
    return this._refinement;
  }
  set Refinement(value: ISemTalkDiagram | null) {
    const old: ISemTalkDiagram | null = this._refinement;
    if (old) {
      old.RemoveInvRefinement(this);
    }
    if (value) {
      (value as ISemTalkDiagram).AddInvRefinement(this);
    }
    if (value) {
      this._refinement = value as ISemTalkDiagram;
    } else {
      this._refinement = null;
    }
    this.Changed = new Date().toUTCString();
    this.ChangedBy = this.ObjectBase.User;
    if (old) {
      this.ObjectBase.OnDetached(this, old);
    }
    if (value) {
      this.ObjectBase.OnRefined(this, value);
    }
  }

  private _composition: ISemTalkComposition | null = null;
  public Composition(): ISemTalkComposition | null {
    return this._composition;
  }
  public MakeComposition(cls: ISemTalkBusinessClass, met: ISemTalkMethodType | null, sta: ISemTalkStateType | null,
    attr: ISemTalkAttributeType | null, oth?: ISemTalkBusinessClass | null, op?: string,
    isnotop?: boolean, cmpvalue?: any): ISemTalkComposition {
    let c = this.ObjectBase.MakeComposition(this, cls, met, sta, attr, oth, op, isnotop, cmpvalue);
    this.SetComposition(c);
    this.Changed = new Date().toUTCString();
    this.ChangedBy = this.ObjectBase.User;
    this.ObjectBase.OnComposed(this);
    return c;
  }
  public DeleteComposition(): void {
    this.SetComposition(null);
    this.Changed = new Date().toUTCString();
    this.ChangedBy = this.ObjectBase.User;
    this.ObjectBase.OnUnComposed(this);
  }
  private SetComposition(c: ISemTalkComposition | null): ISemTalkComposition | null {
    if (this._composition !== null) {
      const cc = this._composition;
      this._composition = null;
      cc.Delete();
    }
    this._composition = c;
    if (c !== null) {
      c.Owner = this;
      if (c.ComposedClass) {
        c.ComposedClass.AddInvComposition(c);
      }
      if (c.Other) {
        c.Other.AddInvComposition(c);
      }
      if (c.Method) {
        c.Method.AddInvComposition(c);
      }
      if (c.State) {
        c.State.AddInvComposition(c);
      }
      if (c.Attribute) {
        c.Attribute.AddInvComposition(c);
      }
      this.ObjectBase.OnComposed(this);
    } else {
      this.ObjectBase.OnUnComposed(this);
    }
    return this._composition;
  }
  private _attributes: { [id: string]: ISemTalkAttribute } = {};
  // public AddAttribute(a: ISemTalkAttribute): ISemTalkAttribute {
  //   this._attributes[a.ClassOf().ID + "_"] = a;
  //   return a;
  // }
  public RemoveAttribute(a: ISemTalkAttribute): void {
    this.Changed = new Date().toUTCString();
    this.ChangedBy = this.ObjectBase.User;
    delete this._attributes[a.ClassOf().ID + "_"];
  }
  public FindAttribute(attr: string | ISemTalkAttributeType): ISemTalkAttribute | null {
    let cla: ISemTalkAttributeType | null;
    if (typeof attr === 'string') {
      cla = this.ObjectBase.FindAttributeType(attr as string);
    } else {
      cla = attr as ISemTalkAttributeType;
    }
    if (cla === null) {
      return null;
    } else {
      const a = this._attributes[cla.ID + "_"];
      if (a === undefined) {
        return null;
      } else {
        return a;
      }
    }
  }

  public static getAttributeType(ob: IObjectBase, attr: string | ISemTalkAttributeType): ISemTalkAttributeType {
    if (typeof attr === 'string') {
      const mt = ob.FindAttributeType(attr as string);
      if (mt !== null) {
        return mt;
      } else {
        return new SemTalkAttributeType(ob, attr as string);
      }
    } else {
      return attr as ISemTalkAttributeType;
    }
  }
  public MakeAttribute(attr: string | ISemTalkAttributeType, val: any): ISemTalkAttribute {
    const cla: ISemTalkAttributeType = SemTalkObject.getAttributeType(this.ObjectBase, attr);
    // if (cla === null) { throw new Error("MakeAttribute failed" + attr); }
    let a: ISemTalkAttribute | null = this.FindAttribute(attr);
    // if (val === undefined) {
    //   console.debug(val);
    // }
    if (a === null) {
      a = this.ObjectBase.MakeAttribute(this, cla, val);
      if (a) {
        this._attributes[cla.ID + "_"] = a;
        a.SetClass(cla);

      }
    } else {
      a.Value = val;
    }
    this.Changed = new Date().toUTCString();
    this.ChangedBy = this.ObjectBase.User;
    this.ObjectBase.OnAttributeAdded(a);
    return a;
  }
  // public NewAttribute(attr: string, val: any): ISemTalkAttribute | null {
  //   return this.MakeAttribute(attr, val);
  // }

  public DeleteAttribute(attr: string | ISemTalkAttributeType): ISemTalkObject {
    let ob = this.ObjectBase;
    let cla: ISemTalkAttributeType | null;
    if (typeof attr === 'string') {
      cla = ob.FindAttributeType(attr);
    } else {
      cla = attr as ISemTalkAttributeType;
    }
    if (cla === null) {
      throw new Error("DeleteAttribute failed: " + attr);
    }
    const at: ISemTalkAttribute | null = this.FindAttribute(cla);
    if (at) {
      at.Delete();
      delete this._attributes[at.ClassOf().ID + "_"];
      this.Changed = new Date().toUTCString();
      this.ChangedBy = this.ObjectBase.User;
      this.ObjectBase.OnAttributeDeleted(at);
    }
    return this;
  }
  public Attributes(): ISemTalkAttribute[] {
    const alist: ISemTalkAttribute[] = [];
    for (const an in this._attributes) {
      const a: ISemTalkAttribute = this._attributes[an];
      alist[alist.length] = a;
    }
    return alist;
  }
  public abstract AllAttributes(): ISemTalkAttribute[];
  public abstract GetAttributeOwner(attr: string | ISemTalkAttributeType): ISemTalkAttribute | null;

  public SetValue(attr: string | ISemTalkAttributeType, val: any): ISemTalkObject {
    // if (val === "") { val = null; }
    let a: ISemTalkAttribute | null = this.FindAttribute(attr);
    if (a !== null && val === null) {
      a.Delete();
    } else {
      if (a === null) {
        a = this.MakeAttribute(attr, val);
      } else {
        a.Value = val;
      }
    }
    return this;
  }
  public GetValue(attr: string | ISemTalkAttributeType): any {
    const a: ISemTalkAttribute | null = this.FindAttribute(attr);
    if (a === null) {
      return null;
    } else {
      if (a === undefined) {
        return null;
      } else {
        return a.Value;
      }
    }
  }
  public GetCurrentValue(attr: string | ISemTalkAttributeType, language?: string) {
    const a: ISemTalkAttribute | null = this.FindAttribute(attr);
    if (a === null) {
      return null;
    }
    else {
      if (a === undefined) {
        return null;
      } else {
        return a.CurrentValue(language);
      }
    }
  }



  private _associations: { [id: string]: ISemTalkAssociation } = {};
  private _invassociations: { [id: string]: ISemTalkAssociation } = {};
  public AddAssociation(a: ISemTalkAssociation): ISemTalkAssociation {
    this._associations[a.ID] = a;
    return a;
  }
  public RemoveAssociation(a: ISemTalkAssociation): ISemTalkAssociation {
    delete this._associations[a.ID];
    return a;
  }
  public AddInvAssociation(a: ISemTalkAssociation): ISemTalkAssociation {
    this._invassociations[a.ID] = a;
    return a;
  }
  public RemoveInvAssociation(a: ISemTalkAssociation): ISemTalkAssociation {
    delete this._invassociations[a.ID];
    return a;
  }
  public static getAssociationType(ob: IObjectBase, attr: string | ISemTalkAssociationType, ptype: SemTalkRelation, bcreate: boolean = true): ISemTalkAssociationType | null {
    let cla: ISemTalkAssociationType | null = null;
    if (typeof attr === 'string') {
      const at: ISemTalkAssociationType | null = ob.FindAssociationType(attr as string);
      if (at !== null) { cla = at as ISemTalkAssociationType; }
      if (cla === null && bcreate) {
        cla = ob.MakeAssociationType(ptype, attr as string);
      }
    } else {
      cla = attr as ISemTalkAssociationType;
    }
    return cla;
  }
  public FindAssociation(attr: string | ISemTalkAssociationType, toobj: ISemTalkObject, ptype?: SemTalkRelation | null): ISemTalkAssociation | null {
    let aname: string;
    if (typeof attr === 'string') { aname = attr; } else { aname = attr.ObjectName; }
    if (ptype === null || ptype === undefined) { ptype = SemTalkRelation.SemTalkProperty; }
    for (const a in this._associations) {
      const x: ISemTalkAssociation = this._associations[a];
      const r: ISemTalkAssociationType = x.ClassOf() as ISemTalkAssociationType;
      if (r.ObjectName === aname && r.RelationType === ptype && x.ToObject === toobj) { return x; }
    }
    return null;
  }
  public MakeAssociation(attr: string | ISemTalkAssociationType, toobj: ISemTalkObject, ptype?: SemTalkRelation | null, id?: SemTalkID,
    fn?: (cla: ISemTalkAssociationType, ptype: SemTalkRelation, fromobj: ISemTalkObject, toobj: ISemTalkObject, i?: SemTalkID) => ISemTalkAssociation): ISemTalkAssociation {
    if (ptype === null || ptype === undefined) {
      ptype = SemTalkRelation.SemTalkProperty;
    }
    let a: ISemTalkAssociation | null = this.FindAssociation(attr, toobj, ptype);
    if (a === null) {
      const tb = this.ObjectBase;
      // , id?: number | null
      if (id === null || id === undefined) {
        id = tb.NewID();
      } else {
        let iid: number = Number(id);
        if (iid > tb.maxid) {
          tb.maxid = iid;
        }
      }
      if (ptype === SemTalkRelation.SemTalkSubClassOf && tb.IsClass(this) && tb.IsClass(toobj)) {
        // tslint:disable-next-line: no-use-before-declare
        let me: any = this;
        a = new SemTalkSpecialization(me as ISemTalkClass, toobj as ISemTalkClass, id);
      }
      else {
        const cla: ISemTalkAssociationType = SemTalkObject.getAssociationType(this.ObjectBase, attr, ptype, true) as ISemTalkAssociationType;
        // tslint:disable-next-line: no-use-before-declare
        if (fn === undefined) {
          a = new SemTalkAssociation(cla, ptype, this, toobj, id);
        } else {
          a = fn(cla, ptype, this, toobj, id);
        }
        // a = new SemTalkAssociation(cla, ptype, this, toobj, id);
        if (id !== null && a !== null && a.ObjectName !== cla.ObjectName + "." + a.ID) {
          a.RenameObject(cla.ObjectName + "." + a.ID);
        }
      }
    }
    return a;
  }
  public DeleteAssociation(attr: string | ISemTalkAssociationType, toobj: ISemTalkObject): void {
    const a: ISemTalkAssociation | null = this.FindAssociation(attr, toobj, null);
    if (a !== null) {
      a.Delete();
    }
  }
  public Associations(): ISemTalkAssociation[] {
    const alist: ISemTalkAssociation[] = [];
    for (const a in this._associations) {
      alist[alist.length] = this._associations[a];
    }
    return alist;
  }
  public AssociationTypes(): ISemTalkAssociationType[] {
    const alist: ISemTalkAssociationType[] = [];
    const fnd: any = {};
    const ptype = SemTalkRelation.SemTalkProperty;
    for (const a in this._associations) {
      const x: ISemTalkAssociation = this._associations[a];
      const atype: ISemTalkAssociationType = x.ClassOf() as ISemTalkAssociationType;
      if (fnd[atype.ObjectName] === undefined && atype.RelationType === ptype) {
        fnd[atype.ObjectName] = atype;
        alist[alist.length] = atype;
      }
    }
    return alist;
  }
  public InvAssociations(): ISemTalkAssociation[] {
    const alist: ISemTalkAssociation[] = [];
    for (const a in this._invassociations) {
      alist[alist.length] = this._invassociations[a];
    }
    return alist;
  }
  public InvAssociationTypes(): ISemTalkAssociationType[] {
    const alist: ISemTalkAssociationType[] = [];
    const fnd: any = {};
    const ptype = SemTalkRelation.SemTalkProperty;
    for (const a in this._invassociations) {
      const x: ISemTalkAssociation = this._invassociations[a];
      const atype: ISemTalkAssociationType = x.ClassOf() as ISemTalkAssociationType;
      if (fnd[atype.ObjectName] === undefined && atype.RelationType === ptype) {
        fnd[atype.ObjectName] = atype;
        alist[alist.length] = atype;
      }
    }
    return alist;
  }
  public LinkedObjects(attr: string | ISemTalkAssociationType, rec?: boolean, ptype?: SemTalkRelation | null): ISemTalkObject[] {
    function _LinkedObjectsRec(obj: ISemTalkObject, aname1: string, ptype1: SemTalkRelation, alist1: ISemTalkObject[], fnd1: any): void {
      for (const x of obj.Associations()) {
        const r: ISemTalkAssociationType = x.ClassOf() as ISemTalkAssociationType;
        if (r.ObjectName === aname1 && r.RelationType === ptype1 && fnd1[x.ToObject.ID] === undefined) {
          alist1[alist1.length] = x.ToObject;
          fnd1[x.ToObject.ID] = x.ToObject.ID;
          _LinkedObjectsRec(x.ToObject, aname1, ptype1, alist1, fnd1);
        }
      }

    }
    let aname: string;
    if (typeof attr === 'string') { aname = attr; } else { aname = attr.ObjectName; }
    const alist: ISemTalkObject[] = [];
    if (ptype === null || ptype === undefined) { ptype = SemTalkRelation.SemTalkProperty; }
    const fnd: any = {};
    fnd[this.ID] = this.ID;
    for (const a in this._associations) {
      const x: ISemTalkAssociation = this._associations[a];
      const r: ISemTalkAssociationType = x.ClassOf() as ISemTalkAssociationType;
      if (r.ObjectName === aname && r.RelationType === ptype) {
        alist[alist.length] = x.ToObject;
        fnd[x.ToObject.ID] = x.ToObject.ID;
        if (rec) {
          _LinkedObjectsRec(x.ToObject, aname, ptype, alist, fnd);
        }
      }
    }

    return alist;
  }
  public InvLinkedObjects(attr: string | ISemTalkAssociationType, rec?: boolean, ptype?: SemTalkRelation | null): ISemTalkObject[] {
    function _InvLinkedObjectsRec(obj: ISemTalkObject, aname1: string, ptype1: SemTalkRelation, alist1: ISemTalkObject[], fnd1: any): void {
      for (const x of obj.InvAssociations()) {
        const r: ISemTalkAssociationType = x.ClassOf() as ISemTalkAssociationType;
        if (r.ObjectName === aname1 && r.RelationType === ptype1 && fnd1[x.FromObject.ID] === undefined) {
          alist1[alist1.length] = x.FromObject;
          fnd1[x.ToObject.ID] = x.FromObject.ID;
          _InvLinkedObjectsRec(x.FromObject, aname1, ptype1, alist1, fnd1);
        }
      }
    }
    let aname: string;
    if (typeof attr === 'string') { aname = attr; } else { aname = attr.ObjectName; }
    const alist: ISemTalkObject[] = [];
    if (ptype === null || ptype === undefined) { ptype = SemTalkRelation.SemTalkProperty; }
    const fnd: any = {};
    fnd[this.ID] = this.ID;
    for (const a in this._invassociations) {
      const x: ISemTalkAssociation = this._invassociations[a];
      const r: ISemTalkAssociationType = x.ClassOf() as ISemTalkAssociationType;
      if (r.ObjectName === aname && r.RelationType === ptype) {
        alist[alist.length] = x.FromObject;
        fnd[x.FromObject.ID] = x.FromObject.ID;
        if (rec) {
          _InvLinkedObjectsRec(x.FromObject, aname, ptype, alist, fnd);
        }
      }
    }
    return alist;
  }
  public Links(attr: string | ISemTalkAssociationType, ptype?: SemTalkRelation | null): ISemTalkAssociation[] {
    let aname: string;
    if (typeof attr === 'string') { aname = attr; } else { aname = attr.ObjectName; }
    const alist: ISemTalkAssociation[] = [];
    if (ptype === null || ptype === undefined) { ptype = SemTalkRelation.SemTalkProperty; }
    for (const a in this._associations) {
      const x: ISemTalkAssociation = this._associations[a];
      const atype: ISemTalkAssociationType = x.ClassOf() as ISemTalkAssociationType;
      if (x.ClassOf().ObjectName === aname && atype.RelationType === ptype) {
        alist[alist.length] = x;
      }
    }
    return alist;
  }
  public InvLinks(attr: ISemTalkAssociationType | string, ptype?: SemTalkRelation | null): ISemTalkAssociation[] {
    let aname: string;
    if (typeof attr === 'string') { aname = attr; } else { aname = attr.ObjectName; }
    const alist: ISemTalkAssociation[] = [];
    if (ptype === null || ptype === undefined) { ptype = SemTalkRelation.SemTalkProperty; }
    for (const a in this._invassociations) {
      const x: ISemTalkAssociation = this._invassociations[a];
      const atype: ISemTalkAssociationType = x.ClassOf() as ISemTalkAssociationType;
      if (x.ClassOf().ObjectName === aname && atype.RelationType === ptype) {
        alist[alist.length] = x;
      }
    }
    return alist;
  }

  public MapProperties(fn: (x: any) => void) {
    for (const an in this._attributes) {
      const a: ISemTalkAttribute = this._attributes[an];
      fn(a);
    }
    const ptype: SemTalkRelation = SemTalkRelation.SemTalkProperty;
    for (const a in this._associations) {
      const x: ISemTalkAssociation = this._associations[a];
      if (x.ClassOf().RelationType === ptype) {
        fn(x);
      }
    }
    for (const a in this._invassociations) {
      const x: ISemTalkAssociation = this._invassociations[a];
      if (x.ClassOf().RelationType === ptype) {
        fn(x);
      }
    }
  }
  public HasDirectLink(attr: ISemTalkAssociationType | string, other: ISemTalkObject, ptype?: SemTalkRelation | null): boolean {
    let aname: string;
    if (typeof attr === 'string') { aname = attr; } else { aname = attr.ObjectName; }
    if (ptype === null || ptype === undefined) { ptype = SemTalkRelation.SemTalkProperty; }
    for (const a in this._associations) {
      const x: ISemTalkAssociation = this._associations[a];
      const atype: ISemTalkAssociationType = x.ClassOf() as ISemTalkAssociationType;
      if (atype.ObjectName === aname && atype.RelationType === ptype && x.ToObject === other) {
        return true;
      }
    }
    return false;
  }
  public HasLink(attr: ISemTalkAssociationType | string, other: ISemTalkObject, ptype?: SemTalkRelation | null): boolean {
    let aname: string;
    if (typeof attr === 'string') { aname = attr; } else { aname = attr.ObjectName; }
    if (ptype === null || ptype === undefined) { ptype = SemTalkRelation.SemTalkProperty; }
    for (const a in this._associations) {
      const x: ISemTalkAssociation = this._associations[a];
      const atype: ISemTalkAssociationType = x.ClassOf() as ISemTalkAssociationType;
      if (atype.ObjectName === aname && atype.RelationType === ptype) {
        if (x.ToObject === other) {
          return true;
        }
        else {
          return x.ToObject.HasLink(attr, other, ptype);
        }
      }
    }
    return false;
  }

  public MakeAttachment(link: string, label?: string,
    nsp?: string, suburl?: string, typ?: string,
    exi?: string, fra?: string, nwi?: boolean, icon?: string): ISemTalkInstance | null {
    // if (aname.length === 0)
    //    aname = "#document#" + link;
    const aname: string = link;
    const ob = this.ObjectBase;
    let atti = ob.FindInstance(aname);
    if (atti === null) {
      let attc = ob.MakeClass(SemTalkBaseConstant.SLAttachment);
      atti = new SemTalkInstance(attc, aname, SemTalkType.SemTalkInstance, null);
    }
    // if (atti) {
    const lnk: ISemTalkAssociation | null = this.MakeAssociation(SemTalkBaseConstant.SLhasAttachment,
      atti, SemTalkRelation.SemTalkSystemRelation, undefined);
    if (lnk) {
      if (label) lnk.SetValue(SemTalkAttachment.label, label);
      if (icon) lnk.SetValue(SemTalkAttachment.icon, icon);
      if (nsp) lnk.SetValue(SemTalkAttachment.namespace, nsp);
      if (suburl) lnk.SetValue(SemTalkAttachment.subaddress, suburl);
      if (typ) lnk.SetValue(SemTalkAttachment.type, typ);
      if (fra) lnk.SetValue(SemTalkAttachment.frame, fra);
      if (exi) lnk.SetValue(SemTalkAttachment.extrainfo, exi);
      if (nwi) lnk.SetValue(SemTalkAttachment.newwindow, nwi);
      this.Changed = new Date().toUTCString();
      this.ChangedBy = this.ObjectBase.User;
      this.ObjectBase.OnAttachmentAdded(lnk);
    }
    // }
    return atti;
  }
  public Attachments(): ISemTalkAssociation[] {
    return this.Links(SemTalkBaseConstant.SLhasAttachment, SemTalkRelation.SemTalkSystemRelation);
  }

  public GetAttachment(name: string): ISemTalkAssociation | null {
    const llist: ISemTalkAssociation[] = this.Attachments();
    for (const atti of llist) {
      if (atti.ToObject.ObjectName === name) {
        return atti;
      }
    }
    return null;
  }
  public DeleteAttachment(name: string): void {
    const atti: ISemTalkAssociation | null = this.GetAttachment(name);
    if (atti !== null) {
      this.Changed = new Date().toUTCString();
      this.ChangedBy = this.ObjectBase.User;
      atti.Delete();
    }
  }

  private _nodes: { [id: string]: ISemTalkNode } = {};
  public Nodes(): ISemTalkNode[] {
    return Object.values(this._nodes);
    // const alist: ISemTalkNode[] = [];
    // for (const ai in this._nodes) {
    //   const a = this._nodes[ai];
    //   alist[alist.length] = a;
    // }
    // return alist;
  }

  public FindNode(d: ISemTalkDiagram, shapeid?: string): ISemTalkNode[] {
    const alist: ISemTalkNode[] = [];
    for (const ai in this._nodes) {
      const a: ISemTalkNode = this._nodes[ai];
      if (shapeid === undefined) {
        if (a.Diagram === d) {
          alist.push(a);
        }
      } else
        if (a.Diagram === d && a.ShapeID === shapeid) {
          alist.push(a);
        }
    }
    return alist;
  }
  public AddNode(n: ISemTalkNode): ISemTalkNode {
    this._nodes[n.Diagram.ID + "&" + n.ShapeID] = n;
    return n;
  }
  public RemoveNode(n: ISemTalkNode): void {
    delete this._nodes[n.Diagram.ID + "&" + n.ShapeID];
  }
  public Load(je: any): void {
    super.Load(je);
    const tb = this.ObjectBase;
    tb._loading = false;
    this.Created = je.created;
    this.Changed = je.changed;
    this.CreatedBy = je.createdBy;
    this.ChangedBy = je.changedBy;
    tb._loading = true;
    if (je.EditLink) { this.EditLink = je.EditLink; }
    if (je.SrcID) { this.SrcID = je.SrcID; }

    if (je.ReadOnly) { this.IsReadOnly = (je.ReadOnly === "1"); }
    if (je.color) { this.Color = je.color; }
    if (je.linepattern) { this.LinePattern = je.linepattern; }
    if (je.lineweight) { this.LineWeight = je.lineweight; }
    if (je.comment) { this.Comment = je.comment; }
    if (je.refinement) {
      const d = tb.FindDiagram(je.refinement);
      if (d) {
        this.Refinement = d;
      }
    }
    if (je.extrefinement) { this.ExtRefinement = je.extrefinement; }
    // const obj: SemTalkObject = this;
    if (je.composition) {
      const cmp: any = je.composition;
      let cl1: ISemTalkBusinessClass | null = null;
      if (cmp.fclass) {
        cl1 = (tb.FindBusinessClass(cmp.fclass) as ISemTalkBusinessClass);
      }
      let mt1: ISemTalkMethodType | null = null;
      if (cmp.fmethod) {
        const mt = tb.FindMethodType(cmp.fmethod);
        if (mt !== null) { mt1 = (mt as ISemTalkMethodType); }
      }
      let ot1: ISemTalkBusinessClass | null = null;
      if (cmp.fother !== undefined) { ot1 = tb.FindBusinessClass(cmp.fother) as ISemTalkBusinessClass; }
      let st1: ISemTalkStateType | null = null;
      let at1: ISemTalkAttributeType | null = null;
      if (cmp.feature !== undefined) {
        switch (cmp.ftype) {
          case "2":
            const at = tb.FindAttributeType(cmp.feature);
            if (at !== null) { at1 = at as ISemTalkAttributeType; }
            break;
          case "1":
            const st = tb.FindStateType(cmp.feature);
            if (st !== null) { st1 = st; }
            break;
        }
      }
      if (cl1) {
        // this.Composition = new SemTalkComposition(cl1, mt1, st1, at1, ot1, cmp.fop, cmp.fnot, cmp.fvalue);
        this.MakeComposition(cl1, mt1, st1, at1, ot1, cmp.fop, cmp.fnot, cmp.fvalue);
      }
    }
    if (je.attributes) {
      for (const ai in je.attributes) {
        const a: any = je.attributes[ai];
        // const an: SemTalkAttribute = new SemTalkAttribute(obj, a.name, a.defaultvalue);
        if (a.name !== "namespace" && a.name !== "type") {
          let aname = a.class;
          if (aname === undefined) {
            aname = a.name;
          }
          const an = this.MakeAttribute(aname, a.defaultvalue);
          if (an) an.Load(a);
        }
      }
    }
    if (je.associations) {
      for (const ai in je.associations) {
        const rel: any = je.associations[ai];
        if (rel.type !== SemTalkBaseConstant.SLSubClassOf) {
          let oth: ISemTalkObject | null = tb.FindInstance(rel.other);
          if (oth === null) {
            oth = tb.FindClass(rel.other);
          }
          // if (oth === null) {
          //   oth = tb.FindAssociationType(rel.other);
          // }
          if (oth !== null) {
            let a = this.MakeAssociation(rel.class, oth, ObjectBase.FindSemTalkRelationType(rel.type), rel.ID);
            a.Load(rel);
          } else {
            console.debug("Bad Other: " + rel);
          }
        }
      }
    }
    if (je.nodelist) {
      for (const ai in je.nodelist) {
        const x: any = je.nodelist[ai];
        const pg = tb.FindDiagram(x.page);
        if (pg !== null) {
          // const an: SemTalkNode = new SemTalkNode(pg, obj, x.id);
          if (pg.FindNodeOfShape(x.id)) {
            // console.debug(x);
          } else {
            const an = pg.MakeNode(this, x.id);
            an.Load(x);
          }
          // }
        }
      }
    }
  }
  public LoadXML(element: Element): void {
    super.LoadXML(element);
    const tb: IObjectBase = this.ObjectBase;
    if (element.attributes) {
      tb._loading = false;
      const created = element.attributes.getNamedItem("created")?.value;
      if (created) this.Created = created;
      const changed = element.attributes.getNamedItem("changed")?.value;
      if (changed) this.Changed = changed;
      const createdBy = element.attributes.getNamedItem("createdBy")?.value;
      if (createdBy) this.CreatedBy = createdBy;
      const changedBy = element.attributes.getNamedItem("changedBy")?.value;
      if (changedBy) this.ChangedBy = changedBy;

      tb._loading = true;
      const EditLink = element.attributes.getNamedItem("EditLink")?.value;
      if (EditLink) { this.EditLink = EditLink; }
      const SrcID = element.attributes.getNamedItem("SrcID")?.value;
      if (SrcID) { this.SrcID = SrcID; }
      const IsReadOnly = (element.attributes.getNamedItem("IsReadOnly")?.value === "1");
      if (IsReadOnly) { this.IsReadOnly = IsReadOnly; }
      const color = element.attributes.getNamedItem("color")?.value;
      if (color) { this.Color = color; }
      const linepattern = element.attributes.getNamedItem("linepattern")?.value;
      if (linepattern) { this.LinePattern = linepattern; }
      const lineweight = element.attributes.getNamedItem("lineweight")?.value;
      if (lineweight) { this.LineWeight = lineweight; }
      const comment = element.attributes.getNamedItem("comment")?.value;
      if (comment) { this.Comment = comment; }
      const refinement = element.attributes.getNamedItem("refinement")?.value;
      if (refinement) {
        const d: ISemTalkDiagram = tb.FindDiagram(refinement) as ISemTalkDiagram;
        this.Refinement = d;
      }
      const extrefinement = element.attributes.getNamedItem("extrefinement")?.value;
      if (extrefinement) {
        this.ExtRefinement = extrefinement;
      }
    }
    const compositions = element.getElementsByTagName("Composition");
    for (const i in compositions) {
      const cmp = compositions[i];
      if (cmp.parentNode === element && cmp.attributes && cmp.nodeName === "Composition") {

        const fclass = cmp.attributes.getNamedItem("fclass")?.value;
        let cl1: ISemTalkBusinessClass | null = null;
        if (fclass) {
          cl1 = (tb.FindBusinessClass(fclass) as ISemTalkBusinessClass);
        }

        let fmethod = cmp.attributes.getNamedItem("fmethod")?.value;
        let mt1: ISemTalkMethodType | null = null;
        if (fmethod) {
          const mt: ISemTalkMethodType | null = tb.FindMethodType(fmethod);
          if (mt !== null) { mt1 = mt as ISemTalkMethodType; }
        }

        let fother = cmp.attributes.getNamedItem("fother")?.value;
        let ot1: ISemTalkBusinessClass | null = null;
        if (fother !== undefined) { ot1 = tb.FindBusinessClass(fother) as ISemTalkBusinessClass; }

        let feature = cmp.attributes.getNamedItem("feature")?.value;
        let st1: ISemTalkStateType | null = null;
        let at1: ISemTalkAttributeType | null = null;
        if (feature !== undefined) {
          const ftype = cmp.attributes.getNamedItem("ftype")?.value;
          switch (ftype) {
            case "2":
              const at: any | null = tb.FindAttributeType(feature);
              if (at !== null) { at1 = at as ISemTalkAttributeType; }
              break;
            case "1":
              const st: any | null = tb.FindStateType(feature);
              if (st !== null) { st1 = st; }
              break;
          }
        }
        if (cl1) {
          const fop = cmp.attributes.getNamedItem("fop")?.value;
          const fnot = (cmp.attributes.getNamedItem("fnot")?.value === "1");
          const fvalue = cmp.attributes.getNamedItem("fvalue")?.value;
          this.MakeComposition(cl1, mt1, st1, at1, ot1, fop, fnot, fvalue);
        }
      }
    }
    const attributes = element.getElementsByTagName("Attribute");
    for (const i in attributes) {
      const a = attributes[i];
      if (a.parentNode === element && a.attributes && a.nodeName === "Attribute") {
        if (a.parentNode) {
          let par: any = a.parentNode;
          if (par && par["id"] !== element["id"]) {
            continue;
          }
        }
        const name = a.attributes.getNamedItem("name")?.value;
        if (name !== "namespace" && name !== "type") {
          let aname = a.attributes.getNamedItem("class")?.value;
          const defaultvalue = a.attributes.getNamedItem("defaultvalue")?.value;
          if (aname === undefined) {
            aname = name;
          }
          if (aname) {
            const an: ISemTalkAttribute | null = this.MakeAttribute(aname, defaultvalue);
            if (an) an.LoadXML(a);
          }
        }

      }
    }
    const associations = element.getElementsByTagName("Relation");
    for (const i in associations) {
      const rel = associations[i];
      if (rel.parentNode === element && rel.attributes && rel.nodeName === "Relation") {
        const type = rel.attributes.getNamedItem("type")?.value;
        if (type !== SemTalkBaseConstant.SLSubClassOf) {
          const other = rel.attributes.getNamedItem("other")?.value;
          if (other) {
            let oth: any = tb.FindInstance(other);
            if (oth === null) {
              oth = tb.FindClass(other);
            }
            if (oth !== null) {
              const rclass = rel.attributes.getNamedItem("class")?.value;
              const rtype = rel.attributes.getNamedItem("type")?.value;
              const id = rel.attributes.getNamedItem("ID")?.value;
              if (rclass && rtype && id) {
                const a = this.MakeAssociation(rclass, oth,
                  ObjectBase.FindSemTalkRelationType(rtype), ObjectBase.String2SemTalkID(id));
                a.LoadXML(rel);
              }
            } else {
              console.debug("Bad Other: " + rel);
            }
          }
        }
      }
    }
    const nodelist = element.getElementsByTagName("Node");
    for (const i in nodelist) {
      const x = nodelist[i];
      if (x.parentNode === element && x.attributes && x.nodeName === "Node") {
        const page = x.attributes.getNamedItem("page")?.value;
        if (page) {
          const pg = tb.FindDiagram(page);
          if (pg !== null) {
            const an = pg.MakeNode(this, x.id);
            an.LoadXML(x);
          }
        }
      }
    }
  }

  public Save(par: any[]): any {

    let el = super.Save(par);

    if (this.ID !== null) { el.ID = this.ID; }
    if (this.SrcID !== "") { el.SrcID = this.SrcID; }
    if (this.EditLink) { el.EditLink = this.EditLink; }
    if (this.IsReadOnly) { el.ReadOnly = "1"; }
    if (this.Color) { el.Color = this.Color; }
    if (this.LinePattern) { el.LinePattern = this.LinePattern; }
    if (this.LineWeight) { el.LineWeight = this.LineWeight; }

    if (this.Created) { el.created = this.Created; }
    if (this.Changed) { el.changed = this.Changed; }
    if (this.ChangedBy) { el.changedBy = this.ChangedBy; }
    if (this.CreatedBy) { el.createdBy = this.CreatedBy; }
    if (this.Comment) { el.comment = this.Comment; }

    if (this._refinement) {
      el.refinement = this._refinement.ObjectName;
    }
    if (this.ExtRefinement) { el.extrefinement = this.ExtRefinement; }

    if (Object.keys(this._attributes).length > 0) {
      const al: any[] = [];
      for (const ai in this._attributes) {
        const a: ISemTalkAttribute = this._attributes[ai];
        const sel: any = a.Save(al);
        sel.name = a.ClassOf().ObjectName + "." + ai.replace("_", "");
      }
      el.attributes = al;
    }
    if (this._associations) {
      const al: any[] = [];
      for (const ai in this._associations) {
        const a: ISemTalkAssociation = this._associations[ai];
        const sel = a.Save(al);
        // sel.name = ai;
        if (a.ToObject) { sel.other = a.ToObject.ObjectName; }
      }
      el.associations = al;
    }
    if (this._invassociations) {
      const al: any[] = [];
      for (const ai in this._invassociations) {
        const a: ISemTalkAssociation = this._invassociations[ai];
        const sel = a.Save(al);
        // sel.name = ai;
        if (a.FromObject) { sel.other = a.FromObject.ObjectName; }
      }
      el.invassociations = al;
    }
    if (this._nodes) {
      const nl: any[] = [];
      for (const ai in this._nodes) {
        const a: any = this._nodes[ai]; // SemTalkNode
        // const sel = 
        a.Save(nl);
        // sel.name = ai;
      }
      el.nodelist = nl;
    }
    const c = this.Composition();
    if (c) {
      el.composition = c.Save();
    }
    return el;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {

    super.SaveXML(xd, el);

    if (this.ID !== ObjectBase.String2SemTalkID("")) {
      el.setAttribute("guid", this.ID);
      el.setAttribute("ID", this.ObjectBase.convertID(this.ID));
    }
    if (this.SrcID !== "") { el.setAttribute("SrcID", this.SrcID); }
    if (this.EditLink) { el.setAttribute("EditLink", this.EditLink); }
    if (this.IsReadOnly) { el.setAttribute("ReadOnly", "1"); }
    if (this.Color) { el.setAttribute("Color", this.Color); }
    if (this.LinePattern) { el.setAttribute("LinePattern", this.LinePattern); }
    if (this.LineWeight) { el.setAttribute("LineWeight", this.LineWeight); }

    if (this.Created) { el.setAttribute("created", this.Created); }
    if (this.Changed) { el.setAttribute("changed", this.Changed); }
    if (this.ChangedBy) { el.setAttribute("changedBy", this.ChangedBy); }
    if (this.CreatedBy) { el.setAttribute("createdBy", this.CreatedBy); }
    if (this.Comment) { el.setAttribute("comment", this.Comment); }

    if (this._refinement) {
      el.setAttribute("refinement", this._refinement.ObjectName);
    }
    if (this.ExtRefinement) { el.setAttribute("extrefinement", this.ExtRefinement); }


    if (Object.keys(this._attributes).length > 0) {
      for (const ai in this._attributes) {
        const a: ISemTalkAttribute = this._attributes[ai];
        let at = xd.createElement("Attribute");
        el.appendChild(at);
        // at.setAttribute("name", a.ClassOf().ObjectName + "." + ai.replace("_",""));
        a.SaveXML(xd, at);
      }
    }
    if (this._associations) {
      for (const ai in this._associations) {
        const a: ISemTalkAssociation = this._associations[ai];
        let re = xd.createElement("Relation");
        el.appendChild(re);
        a.SaveXML(xd, re);
        const aname = a.ClassOf().ObjectName + "." + ai.replace("_", "");
        re.setAttribute("name", aname);
        if (a.ToObject) { re.setAttribute("other", a.ToObject.ObjectName); }
      }
    }
    if (this._invassociations) {
      for (const ai in this._invassociations) {
        const a: ISemTalkAssociation = this._invassociations[ai];
        let re = xd.createElement("InvRelation");
        el.appendChild(re);
        a.SaveXML(xd, re);
        // re.setAttribute("guid", a.ID);
        // re.setAttribute("ID", this.ObjectBase.convertID(a.ID));

        // if (a.ClassOf()) { re.setAttribute("text", a.ClassOf().ObjectName); }
        // re.setAttribute("type", ObjectBase.SemTalkRelationTypeName(a.RelationType));
        const aname = a.ClassOf().ObjectName + "." + ai.replace("_", "");
        re.setAttribute("name", aname);
        if (a.FromObject) { re.setAttribute("other", a.FromObject.ObjectName); }

      }
    }
    if (this._nodes) {
      for (const ai in this._nodes) {
        const a: ISemTalkNode = this._nodes[ai]; // SemTalkNode
        let nd = xd.createElement("Node");
        el.appendChild(nd);
        a.SaveXML(xd, nd);
      }
    }
    const c = this.Composition();
    if (c) {
      let nd = xd.createElement("Composition");
      el.appendChild(nd);
      c.SaveXML(xd, nd);
    }
  }

  public Clone(oth: ISemTalkObject) {
    super.Clone(oth);
    if (this.Comment.length === 0 && oth.Comment.length > 0) {
      this.Comment = oth.Comment;
    }
    if (oth.ExtRefinement) {
      this.ExtRefinement = oth.ExtRefinement;
    }
    for (let lnk of oth.Attachments()) {
      const label = lnk.GetValue(SemTalkAttachment.label);
      const nsp = lnk.GetValue(SemTalkAttachment.namespace);
      const suburl = lnk.GetValue(SemTalkAttachment.subaddress);
      const typ = lnk.GetValue(SemTalkAttachment.type);
      const fra = lnk.GetValue(SemTalkAttachment.frame);
      const exi = lnk.GetValue(SemTalkAttachment.extrainfo);
      const nwi = lnk.GetValue(SemTalkAttachment.newwindow);
      this.MakeAttachment(lnk.ToObject.ObjectName, label, nsp, suburl, typ, exi, fra, nwi);
    }

    this.Created = oth.Created;
    this.CreatedBy = oth.CreatedBy;
    for (let a2 of oth.Attributes()) {
      const aname = a2.ClassOf().ObjectName;
      if (!this.FindAttribute(aname)) {
        const a = this.MakeAttribute(aname, a2.Value);
        a.Clone(a2);
      }
    }
  }
  public Delete(): void {
    this.ObjectBase.OnBeforeDeleted(this);
    this.indeletion = true;
    for (const n in this._nodes) {
      this._nodes[n].Delete();
    }
    for (const a in this._associations) {
      this._associations[a].Delete();
    }
    for (const a in this._invassociations) {
      this._invassociations[a].Delete();
    }
    delete this.ObjectBase._objectids[this.ID + "_"];
    delete this.ObjectBase._objects[this.ObjectName];
    this.Changed = new Date().toUTCString();
    this.ChangedBy = this.ObjectBase.User;
    this.ObjectBase.OnDeleted(this);
  }



}

export abstract class SemTalkVirtualInstance implements ISemTalkVirtualInstance {

  constructor(t: SemTalkType, own: ISemTalkObject) {
    this._type = t;
    this.Owner = own;
  }
  private _virtualclass: ISemTalkClass;
  private _type: SemTalkType;
  public Owner: ISemTalkObject;
  public _propname: string | undefined = undefined;
  public get PropName(): string {
    if (this._propname === undefined) {
      throw (new Error("Undefined Virtual Instance"));
    }
    return this._propname;
  }
  public set PropName(s: string) { this._propname = s; }

  public SetClass(cla: ISemTalkClass): void {
    this._virtualclass = cla;
  }
  public ClassOf(): ISemTalkClass {
    // if (this._virtualclass === undefined) {
    //   // console.debug("Undefined Virtual Instance");
    //   throw (new Error("Undefined Virtual Instance"));
    // }
    return this._virtualclass;
  }
  public Delete(): void {
    // this._virtualclass = null;
  }
  public Save(par: any[]): any {
    const el: any = {};
    par.push(el);
    // if (this._virtualclass) {
    el.class = this._virtualclass.ObjectName;
    // } else {
    //   console.debug("Missing virtual class: ", this);
    // }
    return el;
  }
  public SaveXML(_xd: XMLDocument, el: HTMLElement): void {
    el.setAttribute("class", this._virtualclass.ObjectName);
    el.setAttribute("name", this._virtualclass.ObjectName);
  }
  // tslint:disable-next-line:no-empty
  public Load(_je: any): void {

  }
  public LoadXML(_element: HTMLElement): void {

  }
  public Clone(_oth: ISemTalkVirtualInstance): void {

  }
  public get ObjectType(): SemTalkType {
    return this._type;
  }
  // public set ObjectType(val: SemTalkType) {
  //   this._type = val;
  // }
  public toString(): string {
    // if (this._virtualclass === undefined) {
    //   return "Undefined Virtual Instance";
    // }
    return "<" + ObjectBase.SemTalkTypeName(this._type) + " " + this.ClassOf().ObjectName + ">";
  }
  //  this.ObjectBase.SemTalkTypeName(this.ObjectType)
}
export class SemTalkInstance extends SemTalkObject implements ISemTalkInstance {
  constructor(cla: ISemTalkClass, newname: string, otype: SemTalkType, id?: SemTalkID | null) {
    super(cla.ObjectBase, newname, otype, id);
    // if (tb.FindInstance(newname) === null) {
    this.ObjectBase._instances[this.ID + "_"] = this;
    // } else {
    //    super.Delete();
    //     throw new Error("SemTalkInstance: There is another SemTalkInstance named: " + newname);
    // }
    cla.AddInstance(this);
    this._class = cla;
    this.ObjectBase.OnClassChanged(this, null);
    return this;
  }
  private _class: ISemTalkClass;
  public SetClass(cla: ISemTalkClass): void {
    if (cla.ObjectName === this._class.ObjectName) {
      return;
    }
    let oldclass = this._class;
    const isanon = (this.ObjectName === this._class.ObjectName + '.' + this.ID);
    this._class.RemoveInstance(this);
    cla.AddInstance(this);
    this._class = cla;
    if (isanon) {
      this.RenameObject(this._class.ObjectName + '.' + this.ID);
    }
    this.Changed = new Date().toUTCString();
    this.ChangedBy = this.ObjectBase.User;
    this.ObjectBase.OnClassChanged(this, oldclass);
  }
  public ClassOf(): ISemTalkClass {
    return this._class;
  }
  public IsInstance(cla: ISemTalkClass): boolean {
    if (!this._class) return false;
    if (this._class.ID === cla.ID) { return true; }
    return this._class.IsParent(cla);
  }
  public SystemClass(): ISemTalkSystemClass | null {
    if (!this._class) return null;
    return this.ClassOf().SystemClass();
  }
  public IsValid(dst: ISemTalkObject, attr: ISemTalkAssociationType | string): boolean {
    let rel: ISemTalkAssociationType | null = null;
    let ob = dst.ObjectBase;

    if (typeof attr === 'string') {
      const at: ISemTalkAssociationType | null = this.ObjectBase.FindAssociationType(attr as string);
      if (at !== null) { rel = at as ISemTalkAssociationType; }
    } else {
      rel = attr as ISemTalkAssociationType;
    }
    if (this.ClassOf().ObjectName === SemTalkBaseConstant.SLThing) return true;
    if (ob.IsInstance(dst)) {
      if (((dst as SemTalkInstance).ClassOf().ObjectName === SemTalkBaseConstant.SLAttachment || ob.IsDiagram(dst)) &&
        rel !== null &&
        rel.ObjectName === SemTalkBaseConstant.SLhasAttachment)
        return true;
      if ((dst as SemTalkInstance).ClassOf().ObjectName === SemTalkBaseConstant.SLThing) return true;
    }
    if (rel === null) {
      // tslint:disable-next-line:no-console
      // console.debug("IsValid: " + this.ObjectName + " " + dst.ObjectName + "  No Association " + attr);
      return false;
    }
    const ots: SemTalkType = this.ObjectType;
    const otd: SemTalkType = dst.ObjectType;
    switch (rel.RelationType) {
      case SemTalkRelation.SemTalkSubClassOf:
        return false;
      case SemTalkRelation.SemTalkInstanceOf:
        if (otd === SemTalkType.SemTalkClass) { return true; }
        return false;
      case SemTalkRelation.SemTalkHiddenRelation:
        return false;
      case SemTalkRelation.SemTalkSystemRelation:
        switch (rel.ObjectName) {
          case ob.GetModelAttribute(ModelAttribute.SLCommentOf):
            return (this._class.ObjectName === ob.GetModelAttribute(ModelAttribute.SLComment));
          case BPMN_AssociationName.dataobject:
            return (this._class.ObjectName === ob.GetModelAttribute(Process_ElementName.SLDataObject));
          // case "sameAs":
          //   if (otd === ots) { return true; }
          //   return false;
          default:
            return false;
        }
      case SemTalkRelation.SemTalkProperty:
    }
    if (ots !== SemTalkType.SemTalkAssociation && otd === SemTalkType.SemTalkInstance) {
      const parents: ISemTalkClass[] = [];
      parents[parents.length] = this._class;
      const inslinks: ISemTalkObject[] = this.LinkedObjects(SemTalkBaseConstant.SLInstanceOf, false,
        SemTalkRelation.SemTalkInstanceOf);
      for (const x of inslinks) {
        parents[parents.length] = x as ISemTalkClass;
      }
      const dstparents: ISemTalkClass[] = [];
      const dstinst: ISemTalkInstance = dst as ISemTalkInstance;
      dstparents.push(dstinst.ClassOf());
      const dstinslinks: ISemTalkObject[] = dstinst.LinkedObjects(SemTalkBaseConstant.SLInstanceOf, false,
        SemTalkRelation.SemTalkInstanceOf);
      for (const x of dstinslinks) {
        dstparents.push(x as ISemTalkClass);
      }
      // let found: boolean = false;
      for (const pa of parents) {
        const olist: ISemTalkClass[] = pa.GetRelationOtherBases(rel, SemTalkRelation.SemTalkProperty);
        for (const own of olist) {
          if (dstinst.IsInstance(own)) {
            return true;
          }
          for (const r of dstparents) {
            if (r.ID === own.ID || r.IsParent(own)) {
              return true;
            }
          }
        }
      }
      const sups: ISemTalkClass[] = rel.SuperClasses();
      for (const reli in sups) {
        const srel = sups[reli] as ISemTalkAssociationType;
        if (this.IsValid(dst, srel)) {
          return true;
        }
      }
      // tslint:disable-next-line:no-console
      // console.debug("IsValid: " + this.ObjectName + " " + dst.ObjectName + "  Not valid " + attr);
      return false;
    } else {
      return true;
    }
  }
  public MakeAssociation(attr: string | ISemTalkAssociationType, toobj: ISemTalkObject, ptype?: SemTalkRelation | null, id?: SemTalkID,
    fn?: (cla: ISemTalkAssociationType, ptype: SemTalkRelation, fromobj: ISemTalkObject, toobj: ISemTalkObject, i?: SemTalkID) => ISemTalkAssociation): ISemTalkAssociation {
    if (ptype === null || ptype === undefined) { ptype = SemTalkRelation.SemTalkProperty; }
    const cla: ISemTalkAssociationType = SemTalkObject.getAssociationType(this.ObjectBase, attr, ptype, true) as ISemTalkAssociationType;
    //   if (this.ObjectBase._loading || this.IsValid(toobj, cla)) {
    if (this.ObjectBase._loading || this.IsValid(toobj, cla)) {
      // tslint:disable-next-line:no-console
      // console.debug("MakeAssociation: " + this.ObjectName + " " + toobj.ObjectName + " valid " + cla.ObjectName);
      return super.MakeAssociation(attr, toobj, ptype, id, fn);
    } else {
      throw new Error("MakeAssociation: " + attr + " is not valid between " + this.ObjectName + " and " + toobj.ObjectName);
    }
  }
  public toString(): string { return "<" + ObjectBase.SemTalkTypeName(this.ObjectType) + " " + this.ObjectName + " Class: " + this._class.ObjectName + ">"; }
  public GetValue(aname: string): any {
    const a: any = super.GetValue(aname);
    if (a === null || a === undefined) {
      const sc = this.ClassOf();
      if (sc) {
        const sa: any = sc.GetValue(aname);
        if (sa) {
          return sa;
        }
      }
      return null;
    }
    else { return a; }
  }

  public Delete(): void {
    // console.debug("Delete: "  + " " + this.ObjectName);
    super.Delete();
    delete this.ObjectBase._instances[this.ID + "_"];
    if (this._class) {
      this._class.RemoveInstance(this);
    }
    this.ObjectBase.OnInstanceDeleted(this);

    // this._class = null;
  }
  public Save(par: any[]): any {
    // let el= super.Save(par);
    let je = super.Save(par);
    // if (this.ClassOf) {
    je.class = this.ClassOf().ObjectName;
    // }
    return je;
  }
  public ToClass(): ISemTalkClass {
    let cl = this.ClassOf();

    let name = this.ObjectName;
    let json: any[] = [];
    this.Save(json);
    for (let instof of this.Links(SemTalkBaseConstant.SLInstanceOf, SemTalkRelation.SemTalkInstanceOf)) {
      instof.Delete();
    }
    // let com: ISemTalkObject | null = null;
    for (let comof of this.InvLinks(SemTalkBaseConstant.SLDefinitionOf, SemTalkRelation.SemTalkSystemRelation)) {
      // com = comof.FromObject;
      comof.Delete();
    }

    this.RenameObject("_" + this.ObjectName);
    let newcl = this.ObjectBase.MakeClass(name);
    let js = json[0];
    js.ObjectType = SemTalkBaseConstant.SLClass;
    newcl.Load(js);
    this.Delete();
    this.ObjectBase._loading = false;
    if (cl.ObjectName !== SemTalkBaseConstant.SLThing) {
      newcl.AddSubclassOf(cl);
    }
    return newcl;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    super.SaveXML(xd, el);
    el.setAttribute("class", this.ClassOf().ObjectName);
  }
  public ID2NameNsp(): string {
    if (this._name === null) { return ""; }
    // if (this._name.indexOf("#") < 0) return this._name;
    const ocl = this.ClassOf();
    if (ocl !== undefined) {
      const scl = ocl.SystemClass();
      if (scl !== null && scl.BottomUp) {
        return ocl.ID2NameNsp();
      }
      let ctxt = super.ID2NameNsp();
      if (ctxt.endsWith("." + String(this.ID))) {
        return ocl.ID2NameNsp();
      }
    }
    let txt = super.ID2NameNsp();
    if (txt.endsWith("." + String(this.ID))) {
      txt = txt.substring(0, txt.lastIndexOf("."));
    }
    return txt;
  }
  public RenameObject(value: string): void {
    const ocl = this.ClassOf();
    if (ocl !== undefined) {
      const scl = ocl.SystemClass();
      if (scl !== null && scl.BottomUp) {

      }
      if (scl !== null && !scl.BottomUp
        && scl.Prefix && value.indexOf("#") < 0) {
        let pref = scl.Prefix + "#";
        if (!value.startsWith(pref)) {
          value = pref + value;
        }

      }
    }
    super.RenameObject(value);
  }
  public AllAttributeTypes(): ISemTalkAttributeType[] {
    const alist: ISemTalkAttributeType[] = [];
    const fnd: any = {};
    const fnda: any = {};
    for (const x of this.Attributes()) {
      const atype = x.ClassOf();
      if (fnda[atype.ObjectName] === undefined) {
        fnda[atype.ObjectName] = atype;
        alist.push(atype);
      }
    }
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.SuperClasses()) {
        if (fnd[x.ObjectName] === undefined) {
          fnd[x.ObjectName] = x;
          for (const xs of x.Attributes()) {
            const atypes = xs.ClassOf();
            if (fnda[atypes.ObjectName] === undefined) {
              fnda[atypes.ObjectName] = atypes;
              alist.push(atypes);
            }
          }
          allsuperclassesrec(x);
        }
      }
    }
    for (const x of this.ClassOf().Attributes()) {
      const atype = x.ClassOf();
      if (fnda[atype.ObjectName] === undefined) {
        fnda[atype.ObjectName] = atype;
        alist.push(atype);
      }
    }
    allsuperclassesrec(this.ClassOf());
    return alist;
  }
  public AllAttributes(): ISemTalkAttribute[] {
    const alist: ISemTalkAttribute[] = [];
    const fnda: any = {};
    for (const x of this.Attributes()) {
      const atype = x.ClassOf();
      if (fnda[atype.ObjectName] === undefined) {
        fnda[atype.ObjectName] = atype;
        alist.push(x);
      }
    }
    for (const x of this.ClassOf().AllAttributes()) {
      const atype = x.ClassOf();
      if (fnda[atype.ObjectName] === undefined) {
        fnda[atype.ObjectName] = atype;
        alist.push(x);
      }
    }
    return alist;
  }
  public GetAttributeOwner(attr: string | ISemTalkAttributeType): ISemTalkAttribute | null {
    const a = this.FindAttribute(attr);
    if (a !== null) {
      return a;
    } else {
      return this.ClassOf().GetAttributeOwner(attr);
    }
  }
  public UpdateLabel(mastername?: string): ILabelSpec {
    const lbl = this.ObjectBase.MakeLabelSpec();
    // const lbl = super.UpdateLabel(mastername);
    lbl.newtxt = "";
    // let masternameU = mastername;
    if (mastername === undefined) {
      mastername = "";
    }
    let ob = this.ObjectBase;
    mastername = Utils.MasterNoDot(mastername);

    if (mastername === SemTalkMaster.MasterSubClassOf ||
      mastername === SemTalkBaseConstant.SLInstanceOf
      || mastername === ob.GetModelAttribute(ModelAttribute.SLCommentOf)) {
      lbl.newtxt = "";
      return lbl;
    }
    if (mastername === EPC_ElementName.AND || mastername === EPC_ElementName.OR
      || mastername === EPC_ElementName.XOR) {
      lbl.newtxt = "";
      return lbl;
    }
    const com = ob.GetModelAttribute(ModelAttribute.SLComment);
    if (com && com.length > 0 && mastername === com) {
      const defof = this.ObjectBase.GetModelAttribute(ModelAttribute.SLCommentOf);
      const olist = this.LinkedObjects(defof, false, SemTalkRelation.SemTalkSystemRelation);
      lbl.newtxt = "";
      if (olist.length > 0) {
        lbl.newtxt = olist[0].Comment;
      }
      return lbl;
    }

    let haslabels: boolean = false;
    const sc: ISemTalkSystemClass | null = this.ClassOf().SystemClass();
    if (sc !== null) {
      haslabels = sc.CollectInstLabels(lbl, this, mastername);
      lbl.haslabels = haslabels;
      if (haslabels === false) {
        for (const scc of sc.AllSuperClasses()) {
          let mn = mastername;
          if (mn === scc.ObjectName) {
            mn = "";
          }
          if (ob.IsSystemClass(scc)) {
            let s: ISemTalkSystemClass = scc as ISemTalkSystemClass;
            if (s.Labels(mn).length > 0) {
              haslabels = s.CollectInstLabels(lbl, this, mn);
              if (haslabels) {
                return lbl;
              }
            }
            // Es gelingt mir nicht einen Test zu bauen, der da hinkommt :-(
            // if (s.Labels().length > 0) {
            //   console.debug(s);
            //   haslabels = s.CollectInstLabels(lbl, this);
            //   if (haslabels) {
            //     return lbl;
            //   }
            // }
          }
        }
      }

    }
    if (!haslabels && lbl.newtxt === "") {
      lbl.newtxt = this.ID2NameNsp();
    }
    return lbl;
  }

}
// export class SemTalkSharePointInstance extends SemTalkInstance implements ISemTalkInstance, ISemTalkSharePointObject {
//   public UniqueID: number | null = null;
//   public URL: string | null = null;
//   public CTName: string | null = null;
//   public SPPrefix: string | null = null;
//   public RowID: number | null = null;
// }
export class SemTalkClass extends SemTalkObject implements ISemTalkClass {
  constructor(tb: IObjectBase, newname: string, id?: SemTalkID | null | undefined) {
    super(tb, newname, SemTalkType.SemTalkClass, id);
    // const fndbyname = tb.FindClass(newname)
    // if (fndbyname === null) {
    tb._classes[this.ID + "_"] = this;
    // } else {
    //   super.Delete();
    //     throw new Error("SemTalkClass: There is another SemTalkClass named: " + newname);
    // }
    return this;
  }
  // public IsSystemClass = false;
  private _instances: { [id: string]: ISemTalkInstance } = {};
  public AddInstance(a: ISemTalkInstance): ISemTalkInstance {
    this._instances[a.ID] = a;
    return a;
  }
  public RemoveInstance(a: ISemTalkInstance): void {
    delete this._instances[a.ID];
  }
  public RenameObject(value: string): void {
    const oldname = this.ID2Name;
    super.RenameObject(value);
    for (const i of this.Instances()) {
      if (i.ObjectName === oldname + "." + i.ID) {
        i.RenameObject(i.ClassOf().ID2Name + "." + i.ID);
      }
    }
  }

  public ID2NameNsp(): string {
    let txt = super.ID2NameNsp();
    if (txt.endsWith("." + String(this.ID))) {
      txt = txt.substring(0, txt.lastIndexOf("."));
      // if (txt === "Class" || txt === "SystemClass") {
      //   txt = "";
      // }
    }
    return txt;
  }

  public Delete(): void {
    this.ObjectBase.OnClassBeforeDeleted(this);

    this.indeletion = true;
    let sysc = this.SystemClass();
    if (sysc && sysc.ID !== this.ID) {
      for (let fromobj of this.SubClasses()) {
        fromobj.AddSubclassOf(sysc);
      }
    }

    super.Delete();
    delete this.ObjectBase._classes[this.ID + "_"];
    for (const cmp of this.InvCompositions()) {
      cmp.Delete();
    }
    for (const l of this.Instances()) {
      l.Delete();
    }
    this.ObjectBase.OnClassDeleted(this);
  }
  public ToInstance(): ISemTalkInstance {
    let cla = (this.ObjectBase.FindClass(SemTalkBaseConstant.SLThing) as ISemTalkClass);
    for (let c of this.SuperClasses()) {
      cla = c;
      break;
    }

    let name = this.ObjectName;
    let json: any[] = [];
    this.Save(json);
    for (let subof of this.Links(SemTalkBaseConstant.SLSubClassOf, SemTalkRelation.SemTalkSubClassOf)) {
      subof.Delete();
    }
    for (let supof of this.InvLinks(SemTalkBaseConstant.SLSubClassOf, SemTalkRelation.SemTalkSubClassOf)) {
      supof.Delete();
    }
    // let com: ISemTalkObject | null = null;
    for (let comof of this.InvLinks(SemTalkBaseConstant.SLDefinitionOf, SemTalkRelation.SemTalkSystemRelation)) {
      // com = comof.FromObject;
      comof.Delete();
    }

    this.RenameObject("_" + this.ObjectName);
    let newinst = this.ObjectBase.MakeInstance(cla, name, SemTalkType.SemTalkInstance);
    let js = json[0];
    js.ObjectType = SemTalkBaseConstant.SLInstance;
    newinst.Load(js);
    this.Delete();
    this.ObjectBase._loading = false;
    newinst.SetClass(cla);
    return newinst;
  }
  public GetCurrentValue(aname: string, lang?: string): any {
    const val: any = super.GetCurrentValue(aname, lang);
    if (val === null) {
      for (const x of this.SuperClasses()) {
        const cval: any = x.GetCurrentValue(aname, lang);
        if (cval) {
          return cval;
        }
      }
      return val;
    } else {
      return val;
    }
  }
  public UsageCount(): number {
    return this.Instances().length + this.SubClasses().length;
  }



  private _invcompositions: { [id: string]: ISemTalkComposition } = {};
  public AddInvComposition(a: ISemTalkComposition): ISemTalkComposition {
    // if (a.Owner !== null) {
    this._invcompositions[a.Owner.ID] = a;
    // }
    // else {
    //   throw new Error("AddInvComposition: Missing Composition Owner");
    // }
    return a;
  }
  public RemoveInvComposition(a: ISemTalkComposition): void {
    // if (a.Owner !== null) {
    delete this._invcompositions[a.Owner.ID];
    // }
    // else {
    //   throw new Error("RemoveInvComposition: Missing Composition Owenr");
    // }
  }
  public InvCompositions(): ISemTalkComposition[] {
    const alist: ISemTalkComposition[] = [];
    for (const i in this._invcompositions) {
      alist.push(this._invcompositions[i]);
    }
    return alist;
  }

  public AddSubclassOf(c: ISemTalkClass, id?: SemTalkID | null): ISemTalkSpecialization | null {
    const aname: string = SemTalkBaseConstant.SLSubClassOf;
    if (!this.HasDirectLink(aname, c, SemTalkRelation.SemTalkSubClassOf)) {
      if (!this.ObjectBase._loading) {
        if (this === c || c.IsParent(this)) {
          throw new Error("subClassOf Circles are not allowed!: " + this.ObjectName + " => " + c.ObjectName);
        }
        const rem: ISemTalkClass[] = [];
        for (const s of this.AllSuperClasses()) {
          if (c.IsParent(s)) {
            rem.push(s);
          }
        }
        for (const s of rem) {
          this.DeleteSubclassOf(s);
        }
      }
      const tb = this.ObjectBase;
      if (id === null || id === undefined) {
        id = tb.NewID();
      } else {
        const iid: number = Number(id);
        if (iid > tb.maxid) {
          tb.maxid = iid;
        }
        // id = iid;
      }
      return new SemTalkSpecialization(this, c, id);
    } else {
      return this.FindSpecialization(c);
    }
  }
  public DeleteSubclassOf(c: ISemTalkClass): void {
    const rel = this.FindSpecialization(c);
    if (rel) {
      rel.Delete();
    }
  }
  public FindSpecialization(toobj: ISemTalkClass): ISemTalkSpecialization | null {
    return this.FindAssociation(SemTalkBaseConstant.SLSubClassOf,
      (toobj as ISemTalkObject), SemTalkRelation.SemTalkSubClassOf) as ISemTalkSpecialization;
  }
  public SuperClasses(): ISemTalkClass[] {
    const alist: ISemTalkClass[] = [];
    const ptype = SemTalkRelation.SemTalkSubClassOf;
    for (const x of this.Associations()) {
      if (x.ClassOf().RelationType === ptype) {
        alist.push(x.ToObject as ISemTalkClass);
      }
    }
    return alist;
  }
  public AllSuperClasses(): ISemTalkClass[] {
    const alist: ISemTalkClass[] = [];
    const ptype = SemTalkRelation.SemTalkSubClassOf;
    const fnd: any = {};
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.Associations()) {
        if (x.ClassOf().RelationType === ptype) {
          if (fnd[x.ToObject.ObjectName] === undefined) {
            fnd[x.ToObject.ObjectName] = x.ToObject;
            alist.push(x.ToObject as ISemTalkClass);
            allsuperclassesrec(x.ToObject as ISemTalkClass);
          }
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }
  public IsParent(other: ISemTalkClass): boolean {
    if (this.ID === other.ID) { return false; }
    for (const s of this.SuperClasses()) {
      if (s.ID === other.ID) {
        return true;
      }
    }
    // tslint:disable-next-line:no-console
    // console.debug("Indirect parent: " + this.ObjectName + " " + other.ObjectName + " AllSuperClasses:" + this.AllSuperClasses());
    for (const s of this.AllSuperClasses()) {

      if (s.ID === other.ID) {
        return true;
      }
    }
    return false;
  }
  public SubClasses(): ISemTalkClass[] {
    const alist: ISemTalkClass[] = [];
    const ptype = SemTalkRelation.SemTalkSubClassOf;
    for (const x of this.InvAssociations()) {
      if (x.ClassOf().RelationType === ptype) {
        alist[alist.length] = x.FromObject as ISemTalkClass;
      }
    }
    return alist;
  }
  public AllSubClasses(): ISemTalkClass[] {
    const alist: ISemTalkClass[] = [];
    const fnd: any = {};
    const ptype = SemTalkRelation.SemTalkSubClassOf;
    function allsubclassesrec(cl: ISemTalkClass): void {
      for (const x of cl.InvAssociations()) {
        if (x.ClassOf().RelationType === ptype) {
          if (fnd[x.FromObject.ObjectName] === undefined) {
            fnd[x.FromObject.ObjectName] = x.FromObject;
            alist[alist.length] = x.FromObject as ISemTalkClass;
            allsubclassesrec(x.FromObject as ISemTalkClass);
          }
        }
      }
    }
    allsubclassesrec(this);
    return alist;
  }
  public get IsLeaf(): boolean {
    if (this.SubClasses().length + this.Instances().length > 0) {
      return false;
    }
    return true;
  }
  public MakeInstance(n?: string, t?: SemTalkType, i?: SemTalkID, fn?: (cla: ISemTalkClass, n: string, t: SemTalkType, i: SemTalkID) => ISemTalkInstance): ISemTalkInstance {
    const tb = this.ObjectBase;
    let existing: ISemTalkInstance | null = null;
    if (n)
      existing = tb.FindInstance(n);
    if (existing) {
      return existing;
    } else {
      const newid = tb.NewID();
      if (n === undefined) {
        n = this.ObjectName + "." + newid;
      }
      if (t === undefined) {
        t = SemTalkType.SemTalkInstance;
      }
      if (i === undefined) {
        i = newid;
      }
      if (fn === undefined) {
        const inst = new SemTalkInstance(this, n, t, i);
        return inst;
      } else {
        const inst = fn(this, n, t, i);
        return inst;
      }
    }
  }
  // public MakeSharePointInstance(n?: string, t?: SemTalkType, i?: string): ISemTalkSharePointInstance {
  //   const tb = this.ObjectBase;
  //   let existing: ISemTalkInstance | null = null;
  //   if (n)
  //     existing = tb.FindInstance(n);
  //   if (existing) {
  //     return existing as ISemTalkSharePointInstance;
  //   } else {
  //     tb.maxid = tb.maxid + 1;
  //     if (n === undefined) {
  //       n = this.ObjectName + "." + tb.maxid;
  //     }
  //     if (t === undefined) {
  //       t = SemTalkType.SemTalkInstance;
  //     }
  //     if (i === undefined) {
  //       i = tb.maxid;
  //     }
  //     const inst = new SemTalkSharePointInstance(this, n, t, i);
  //     return inst;
  //   }
  // }
  public MakeSubClass(n: string, i?: SemTalkID, fn?: (tb: IObjectBase, n: string, i?: SemTalkID) => ISemTalkClass): ISemTalkClass {
    const tb = this.ObjectBase;
    const s = tb.FindClass(n);
    let scla: ISemTalkClass;
    if (s !== null) {
      scla = s;
    } else {
      if (fn === undefined) {
        scla = tb.MakeClass(n, i);
      } else {
        scla = fn(tb, n, i);
      }
    }
    if (!scla.IsParent(this)) {
      scla.AddSubclassOf(this);
    }
    return scla;
  }
  // public MakeSharePointSubClass(n: string, _t?: SemTalkType, i?: string): ISemTalkSharePointClass {
  //   const tb = this.ObjectBase;
  //   const s = tb.FindClass(n);
  //   let scla: ISemTalkSharePointClass;
  //   if (s !== null) {
  //     scla = s as ISemTalkSharePointClass;
  //   } else {
  //     scla = tb.MakeSharePointClass(n, i);
  //   }
  //   if (!scla.IsParent(this)) {
  //     scla.AddSubclassOf(this);
  //   }
  //   return scla;
  // }
  public Instances(): ISemTalkInstance[] {
    const alist: ISemTalkInstance[] = [];
    for (const index in this._instances) {
      const x = this._instances[index];
      alist[alist.length] = x;
    }
    return alist;
  }
  public AllInstances(): ISemTalkInstance[] {
    const alist: ISemTalkInstance[] = this.Instances();
    const fnd: any = {};
    const ptype = SemTalkRelation.SemTalkSubClassOf;
    function allsubclassesrec(cl: ISemTalkClass): void {
      for (const x of cl.InvAssociations()) {
        if (x.ClassOf().RelationType === ptype) {
          if (fnd[x.FromObject.ObjectName] === undefined) {
            fnd[x.FromObject.ObjectName] = x.FromObject;
            const f: SemTalkClass = x.FromObject as SemTalkClass;
            const ilist = f.Instances();
            for (const i of ilist) {
              alist[alist.length] = i;
            }
            allsubclassesrec(x.FromObject as ISemTalkClass);
          }
        }
      }
    }
    allsubclassesrec(this);
    return alist;
  }
  public GetRelationOtherBases(rel: ISemTalkAssociationType, ptype?: SemTalkRelation | null): ISemTalkClass[] {
    let alist: ISemTalkClass[] = [];
    if (ptype === undefined || ptype === null) { ptype = SemTalkRelation.SemTalkProperty; }
    const clist = this.LinkedObjects(rel, false, ptype);
    if (clist.length === 0) {
      // let fnd: boolean = false;
      const sl = this.SuperClasses();
      for (const s of sl) {
        const glist = s.GetRelationOtherBases(rel, ptype);
        if (glist.length > 0) {
          alist = glist;
        }
        //  }
      }
    } else {
      for (const s of clist) {
        if (s.ObjectType === SemTalkType.SemTalkClass) {
          alist.push(s as ISemTalkClass);
        }
      }
    }
    return alist;
  }
  public GetInvRelationOtherBases(rel: ISemTalkAssociationType, ptype?: SemTalkRelation | null): ISemTalkClass[] {
    let alist: ISemTalkClass[] = [];
    if (ptype === undefined || ptype === null) { ptype = SemTalkRelation.SemTalkProperty; }
    const clist = this.InvLinkedObjects(rel.ObjectName, false, ptype);
    if (clist.length === 0) {
      // let fnd: boolean = false;
      const sl = this.SuperClasses();
      for (const s of sl) {
        const glist = s.GetInvRelationOtherBases(rel, ptype);
        if (glist.length > 0) {
          alist = glist;
        }
        //   }
      }
    } else {
      for (const s of clist) {
        if (s.ObjectType === SemTalkType.SemTalkClass) {
          alist.push(s as ISemTalkClass);
        }
      }
    }
    return alist;
  }
  public IsValid(dst: ISemTalkObject, rel: ISemTalkAssociationType) {
    const ots: SemTalkType = this.ObjectType;
    const otd: SemTalkType = dst.ObjectType;
    switch (rel.RelationType) {
      case SemTalkRelation.SemTalkSubClassOf:
        if (ots === otd) {
          if (ots !== SemTalkType.SemTalkInstance && ots !== SemTalkType.SemTalkAssociation) {
            return true;
          }
        }
        return false;
      case SemTalkRelation.SemTalkSystemRelation:
        break;
      // switch (rel.ObjectName) {
      //   case "disjointWith":
      //     if (ots === otd) {
      //       if (ots !== SemTalkType.SemTalkInstance && ots !== SemTalkType.SemTalkAssociation) {
      //         return true;
      //       }
      //     }
      //     return false;
      //   case "equivalentClass":
      //     if (ots === otd) {
      //       if (ots !== SemTalkType.SemTalkInstance && ots !== SemTalkType.SemTalkAssociation) {
      //         return true;
      //       }
      //     }
      //     return false;
      //   case "unionOf":
      //     if (this.ObjectName.indexOf("unionOf Class") === 0 && otd === SemTalkType.SemTalkClass) {
      //       return true;
      //     }
      //     return false;
      //   case "oneOf":
      //     if (this.ObjectName.indexOf("oneOf Class") === 0 && otd === SemTalkType.SemTalkInstance) {
      //       return true;
      //     }
      //     return false;
      //   case "intersectionOf":
      //     if (this.ObjectName.indexOf("intersectionOf Class") === 0 && otd === SemTalkType.SemTalkClass) {
      //       return true;
      //     }
      //     return false;
      //   case "complementOf":
      //     if (this.ObjectName.indexOf("complementOf Class") === 0 && otd === SemTalkType.SemTalkClass) {
      //       return true;
      //     }
      //     return false;
      //   case "distinctMembers":
      //     if (this.ObjectName.indexOf("AllDifferent Class") === 0 && otd === SemTalkType.SemTalkInstance) {
      //       return true;
      //     }
      //     return false;
      //   case "equivalentProperty":
      //     if (ots === otd) {
      //       if (ots === SemTalkType.SemTalkAssociationType) {
      //         return true;
      //       }
      //     }
      //     return false;
      //   case "sameAs":
      //     if (otd === ots) { return true; }
      //     return false;
      // }
      // return false;
      case SemTalkRelation.SemTalkProperty:
        break;
    }
    if (ots !== SemTalkType.SemTalkAssociation && otd === SemTalkType.SemTalkClass) {
      const parents: ISemTalkClass[] = [];
      parents.push(this);
      const dstparents: ISemTalkClass[] = [];
      const dstc: ISemTalkClass = dst as ISemTalkClass;
      dstparents.push(dstc);
      // let dstinslinks: SemTalkObject[] = dst.LinkedObjects(SemTalkBaseConstant.SLInstanceOf, false, SemTalkRelation.SemTalkSystemRelation)
      // for (let x of dstinslinks) dstparents[dstparents.length] = x;
      // let found: boolean = false;
      const isclassrelation: boolean = (rel.ToType === SemTalkType.SemTalkClass);
      for (const pa of parents) {
        for (const own of pa.GetRelationOtherBases(rel, SemTalkRelation.SemTalkProperty)) {
          if (isclassrelation) {
            if (dstc.IsParent(own)) {
              return true;
            }
          } else {
            for (const r of dstparents) {
              if (r.ID === own.ID || r.IsParent(own)) {
                return true;
              }
            }
          }
        }
      }
      for (const srel of rel.SuperClasses()) {
        if (this.IsValid(dst, srel as ISemTalkAssociationType)) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  }
  public AllValidClassRelations(dst: ISemTalkObject): ISemTalkAssociationType[] {
    if (this.ObjectName === SemTalkBaseConstant.SLThing ||
      dst.ObjectName === SemTalkBaseConstant.SLThing) {
      return this.ObjectBase.AllAssociationTypes().filter((x) =>
        x.RelationType === SemTalkRelation.SemTalkProperty);
    }
    const alist: ISemTalkAssociationType[] = [];
    const reltmp: any = {};
    const par = this.AllSuperClasses();
    par.unshift(this);

    for (const xobj of this.AllAssociationTypes()) {
      let found: boolean = false;
      const glist = this.GetRelationOtherBases(xobj, SemTalkRelation.SemTalkProperty);
      for (const own of glist) {
        if (!found) {
          if (dst.ObjectName === own.ObjectName) {
            found = true;
          }
          else {
            const dstc: SemTalkClass = dst as SemTalkClass;
            if (dstc.IsParent(own)) {
              found = true;
            }
          }
        }
      }
      if (found) {
        reltmp[xobj.ObjectName] = xobj;
      }
    }
    for (const ind in reltmp) {
      const r: ISemTalkAssociationType = reltmp[ind];
      alist[alist.length] = r;
      for (const s of r.AllSubClasses()) {
        alist.push(s as ISemTalkAssociationType);
      }
    }
    alist.sort((x, y) => x.ObjectCaption.localeCompare(y.ObjectCaption));
    return alist;
  }
  public GetValue(aname: string): any {
    const a: any = super.GetValue(aname);
    if (a === null || a === undefined) {
      const sl = this.SuperClasses();
      for (const sc of sl) {
        const sa: any = sc.GetValue(aname);
        if (sa) {
          return sa;
        }
      }
      return null;
    }
    else { return a; }
  }
  public SystemClass(): ISemTalkSystemClass | null {
    const ob = this.ObjectBase;

    function isSystemClass(obj: any): boolean {
      return ob.IsSystemClass(obj);
    }
    const suplist = this.SuperClasses();
    for (const s of suplist) {
      if (isSystemClass(s)) {
        //   sc = s;
        return s as ISemTalkSystemClass;
      }
      const slist2 = s.SuperClasses();
      for (const s2 of slist2) {
        // if ((s2.SuperClasses().length === 0) && isSystemClass(s2)) {
        if (isSystemClass(s2)) {
          // sc = s2;
          return s2 as ISemTalkSystemClass;
        }
      }
    }
    //   if (sc === this) {
    for (const s2 of this.AllSuperClasses()) {
      // if ((s2.SuperClasses().length === 0) && isSystemClass(s2)) {
      if (isSystemClass(s2)) {
        //  if (sc === this) {
        //    sc = s2;
        return s2 as ISemTalkSystemClass;
        //  }
      }
    }
    return null;
  }

  public AllAttributes(): ISemTalkAttribute[] {
    const alist: ISemTalkAttribute[] = [];
    const fnd: any = {};
    const fnda: any = {};
    for (const x of this.Attributes()) {
      const atype = x.ClassOf();
      if (fnda[atype.ObjectName] === undefined) {
        fnda[atype.ObjectName] = atype;
        alist.push(x);
      }
    }
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.SuperClasses()) {
        if (fnd[x.ObjectName] === undefined) {
          fnd[x.ObjectName] = x;
          for (const xs of x.Attributes()) {
            const atypes = xs.ClassOf();
            if (fnda[atypes.ObjectName] === undefined) {
              fnda[atypes.ObjectName] = atypes;
              alist.push(xs);
            }
          }
          allsuperclassesrec(x);
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }
  public GetAttributeOwner(attr: string | ISemTalkAttributeType): ISemTalkAttribute | null {
    const a = this.FindAttribute(attr);
    if (a !== null) {
      return a;
    } else {
      for (const x of this.SuperClasses()) {
        const aa = x.GetAttributeOwner(attr);
        if (aa !== null) return aa;
      }
    }
    return null;
  }
  public AllAttributeTypes(): ISemTalkAttributeType[] {
    const alist: ISemTalkAttributeType[] = [];
    const fnd: any = {};
    const fnda: any = {};
    for (const x of this.Attributes()) {
      const atype = x.ClassOf();
      if (fnda[atype.ObjectName] === undefined) {
        fnda[atype.ObjectName] = atype;
        alist.push(atype);
      }
    }
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.SuperClasses()) {
        if (fnd[x.ObjectName] === undefined) {
          fnd[x.ObjectName] = x;
          for (const xs of x.Attributes()) {
            const atypes = xs.ClassOf();
            if (fnda[atypes.ObjectName] === undefined) {
              fnda[atypes.ObjectName] = atypes;
              alist.push(atypes);
            }
          }
          allsuperclassesrec(x);
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }

  public AllAssociationTypes(): ISemTalkAssociationType[] {
    const alist: ISemTalkAssociationType[] = [];
    const fnd: any = {};
    const fnda: any = {};
    for (const x of this.Associations()) {
      const atype = x.ClassOf();
      if (fnda[atype.ObjectName] === undefined && atype.RelationType === SemTalkRelation.SemTalkProperty) {
        fnda[atype.ObjectName] = atype;
        alist.push(atype);
      }
    }
    const ptype: SemTalkRelation = SemTalkRelation.SemTalkSubClassOf;
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.Associations()) {
        const atype = x.ClassOf();
        if (atype.RelationType === ptype) {
          if (fnd[x.ToObject.ObjectName] === undefined) {
            fnd[x.ToObject.ObjectName] = x.ToObject;
            for (const xs of x.ToObject.Associations()) {
              const atypes = xs.ClassOf();
              if (fnda[atypes.ObjectName] === undefined && atypes.RelationType === SemTalkRelation.SemTalkProperty) {
                fnda[atypes.ObjectName] = atypes;
                alist.push(atypes);
              }
            }
            allsuperclassesrec(x.ToObject as ISemTalkClass);
          }
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }
  public AllInvAssociationTypes(): ISemTalkAssociationType[] {
    const alist: ISemTalkAssociationType[] = [];
    const fnd: any = {};
    const fnda: any = {};
    for (const x of this.InvAssociations()) {
      const atype = x.ClassOf();
      if (fnda[atype.ObjectName] === undefined && atype.RelationType === SemTalkRelation.SemTalkProperty) {
        fnda[atype.ObjectName] = atype;
        alist[alist.length] = atype;
      }
    }
    const ptype: SemTalkRelation = SemTalkRelation.SemTalkSubClassOf;
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.Associations()) {
        const atype = x.ClassOf();
        if (atype.RelationType === ptype) {
          if (fnd[x.ToObject.ObjectName] === undefined) {
            fnd[x.ToObject.ObjectName] = x.ToObject;
            for (const xs of x.ToObject.InvAssociations()) {
              const atypes = xs.ClassOf();
              if (fnda[atypes.ObjectName] === undefined && atypes.RelationType === SemTalkRelation.SemTalkProperty) {
                fnda[atypes.ObjectName] = atypes;
                alist.push(atypes);
              }
            }
            allsuperclassesrec(x.ToObject as ISemTalkClass);
          }
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }
  public AllAssociations(): ISemTalkAssociation[] {
    const alist: ISemTalkAssociation[] = [];
    const fnd: any = {};
    const fnda: any = {};
    for (const x of this.Associations()) {
      alist.push(x);
    }
    for (const x of this.Associations()) {
      const atype = x.ClassOf();
      if (fnda[atype.ObjectName] === undefined) {
        fnda[atype.ObjectName] = atype;
      }
    }
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.SuperClasses()) {
        if (fnd[x.ObjectName] === undefined) {
          fnd[x.ObjectName] = x;
          for (const xs of x.Associations()) {
            const atypes = xs.ClassOf();
            if (fnda[atypes.ObjectName] === undefined) {
              alist.push(xs);
            }
          }
          for (const xs of x.Associations()) {
            const atypes = xs.ClassOf();
            if (fnda[atypes.ObjectName] === undefined) {
              fnda[atypes.ObjectName] = atypes;
            }
          }
          allsuperclassesrec(x);
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }
  public AllInvAssociations(): ISemTalkAssociation[] {
    const alist: ISemTalkAssociation[] = [];
    const fnd: any = {};
    const fnda: any = {};
    for (const x of this.InvAssociations()) {
      alist.push(x);
    }
    for (const x of this.InvAssociations()) {
      const atype = x.ClassOf();
      if (fnda[atype.ObjectName] === undefined) {
        fnda[atype.ObjectName] = atype;
      }
    }
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.SuperClasses()) {
        if (fnd[x.ObjectName] === undefined) {
          fnd[x.ObjectName] = x;
          for (const xs of x.InvAssociations()) {
            const atypes = xs.ClassOf();
            if (fnda[atypes.ObjectName] === undefined) {
              alist.push(xs);
            }
          }
          for (const xs of x.InvAssociations()) {
            const atypes = xs.ClassOf();
            if (fnda[atypes.ObjectName] === undefined) {
              fnda[atypes.ObjectName] = atypes;
            }
          }
          allsuperclassesrec(x);
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }
  public AllLinkedObjects(aname: string, rec?: boolean, ptype?: SemTalkRelation | null): ISemTalkObject[] {
    const alist: ISemTalkObject[] = this.LinkedObjects(aname, rec, ptype);
    if (alist.length > 0) { return alist; }
    if (ptype === null || ptype === undefined) { ptype = SemTalkRelation.SemTalkProperty; }
    const fnd: any = {};
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.SuperClasses()) {
        if (fnd[x.ObjectName] === undefined) {
          fnd[x.ObjectName] = x;
          for (const xs of x.LinkedObjects(aname, rec, ptype)) {
            alist[alist.length] = xs;
          }
          if (alist.length === 0) {
            allsuperclassesrec(x);
          }
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }
  public AllInvLinkedObjects(aname: string, rec?: boolean, ptype?: SemTalkRelation | null): ISemTalkObject[] {
    const alist: ISemTalkObject[] = this.InvLinkedObjects(aname, rec, ptype);
    if (alist.length > 0) { return alist; }
    if (ptype === null || ptype === undefined) { ptype = SemTalkRelation.SemTalkProperty; }
    const fnd: any = {};
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.SuperClasses()) {
        if (!fnd[x.ObjectName]) {
          fnd[x.ObjectName] = x;
          for (const xs of x.InvLinkedObjects(aname, rec, ptype)) {
            alist[alist.length] = xs;
          }
          if (alist.length === 0) {
            allsuperclassesrec(x);
          }
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }
  public AllLinks(aname: string, _rec?: boolean, ptype?: SemTalkRelation | null): ISemTalkAssociation[] {
    const alist: ISemTalkAssociation[] = this.Links(aname, ptype);
    if (alist.length > 0) { return alist; }
    if (ptype === null || ptype === undefined) { ptype = SemTalkRelation.SemTalkProperty; }
    const fnd: any = {};
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.SuperClasses()) {
        if (fnd[x.ObjectName] === undefined) {
          fnd[x.ObjectName] = x;
          for (const xs of x.Links(aname, ptype)) {
            alist.push(xs);
          }
          if (alist.length === 0) {
            allsuperclassesrec(x);
          }
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }
  public AllInvLinks(aname: string, _rec?: boolean, ptype?: SemTalkRelation | null): ISemTalkAssociation[] {
    const alist: ISemTalkAssociation[] = this.InvLinks(aname, ptype);
    if (alist.length > 0) { return alist; }
    if (ptype === null || ptype === undefined) { ptype = SemTalkRelation.SemTalkProperty; }
    const fnd: any = {};
    function allsuperclassesrec(cl: ISemTalkClass) {
      for (const x of cl.SuperClasses()) {
        if (fnd[x.ObjectName] === undefined) {
          fnd[x.ObjectName] = x;
          for (const xs of x.InvLinks(aname, ptype)) {
            alist.push(xs);
          }
          if (alist.length === 0) {
            allsuperclassesrec(x);
          }
        }
      }
    }
    allsuperclassesrec(this);
    return alist;
  }
  public AllAttachments(): ISemTalkAssociation[] {
    return this.AllLinks(SemTalkBaseConstant.SLhasAttachment, false, SemTalkRelation.SemTalkSystemRelation);
  }
  public UpdateLabel(mastername?: string): ILabelSpec {
    //const lbl = super.UpdateLabel(mastername);
    const lbl = this.ObjectBase.MakeLabelSpec();
    lbl.newtxt = "";

    // let masternameU = mastername;
    if (mastername === undefined) {
      mastername = "";
    }
    mastername = Utils.MasterNoDot(mastername);

    if (lbl.isuml) { lbl.newtxt = ""; }

    let haslabels: boolean = false;
    const sc: ISemTalkSystemClass | null = (this as ISemTalkClass).SystemClass();
    if (sc !== null) {
      haslabels = sc.CollectClassLabels(lbl, this, mastername);
    }

    lbl.haslabels = haslabels;
    if (!haslabels && lbl.newtxt === "") {
      lbl.newtxt = this.ID2NameNsp();
    }
    return lbl;
  }
  public Load(je: any): void {
    super.Load(je);
    if (je.associations) {
      for (const i in je.associations) {
        const rel: any = je.associations[i];
        if (rel.type === SemTalkBaseConstant.SLSubClassOf) {
          let ot = this.ObjectBase.FindObject(this.ObjectType, rel.other);
          let oth: ISemTalkClass | null = null;
          if (ot) { oth = ot as ISemTalkClass; }
          if (oth) {
            // console.log("Class Load" + this.ObjectName + "->" + oth.ObjectName);
            let r = this.AddSubclassOf(oth, rel.ID);
            if (r) {
              r.Load(rel);
            }
          }
          // else {
          //   console.log("Class Load: Missing SuperClass: " + rel.other);
          //   throw new Error("Class Load: Missing SuperClass: " + rel.other);
          // }
        }
      }
    }
  }
  public LoadXML(element: Element): void {
    super.LoadXML(element);
    const tb: IObjectBase = this.ObjectBase;
    const associations = element.getElementsByTagName("Relation");
    for (const i in associations) {
      const rel = associations[i];
      if (rel.parentNode === element && rel.attributes) {
        const type = rel.attributes.getNamedItem("type")?.value;
        if (type === SemTalkBaseConstant.SLSubClassOf) {
          const other = rel.attributes.getNamedItem("other")?.value;
          if (other) {
            let oth: any = tb.FindInstance(other);
            if (oth === null) {
              oth = tb.FindClass(other);
            }
            if (oth !== null) {
              this.AddSubclassOf(oth);
            } else {
              console.debug("Bad Other: " + rel);
            }
          }
        }
      }
    }
  }

  public Save(par: any[]): any {
    return super.Save(par);
  }
  public Clone(oth: ISemTalkObject) {
    super.Clone(oth);
  }
}

// export class SemTalkSharePointClass extends SemTalkClass implements ISemTalkClass, ISemTalkSharePointObject {
//   public UniqueID: number | null = null;
//   public URL: string | null = null;
//   public CTName: string | null = null;
//   public SPPrefix: string | null = null;
//   public RowID: number | null = null;
// }
export class SemTalkAttributeType extends SemTalkClass implements ISemTalkAttributeType {
  constructor(tb: IObjectBase, newname: string, id?: SemTalkID | null) {
    super(tb, newname, id);
    this.ObjectType = SemTalkType.SemTalkAttributeType;
    if (tb.FindAttributeType(newname) === null) {
      tb._attrtypes[this.ID + "_"] = this;
      this.ObjectBase.OnCreated(this);
    }
  }
  public Delete(): void {
    super.Delete();
    const tb = this.ObjectBase;
    for (const objid in tb._objectids) {
      const s: ISemTalkObject = tb._objectids[objid];
      if (s !== null) {
        for (const attr of (s as ISemTalkObject).Attributes()) {
          if (attr.ClassOf().ID === this.ID) {
            attr.Delete();
          }
        }
      }
    }
    delete this.ObjectBase._attrtypes[this.ID + "_"];
    this.ObjectBase.OnAttributeTypeDeleted(this);
  }
  public Value: any = "";
  public Values: string[] = [];
  public Options: string[] = [];
  public ValueType: SemTalkValueType = SemTalkValueType.Symbol;
  public IsList: boolean = false;
  public Min: number | null = null;
  public Max: number | null = null;
  public SyncVisio: boolean = false;
  public Raise: boolean = true;
  public Required: boolean = false;
  public Group: string = "";
  public Weight: number = 0;
  public AllowFreeForm: boolean = true;

  public Load(je: any): void {
    super.Load(je);
    this.Value = je.defaultvalue;
    if (je.valuetype !== undefined) { this.ValueType = ObjectBase.FindSemTalkValueType(je.valuetype); }
    if (je.min !== undefined) { this.Min = je.min; }
    if (je.max !== undefined) { this.Max = je.max; }
    if (je.islist !== undefined) { this.IsList = (je.islist === "1"); }
    if (je.values !== undefined) { this.Values = je.values.split("; "); }
    if (je.options !== undefined) { this.Options = je.options.split("; "); }
    if (je.syncvisio !== undefined) { this.SyncVisio = (je.syncvisio === "1"); }
    if (je.raise !== undefined) { this.Raise = (je.raise === "1"); }
    if (je.required !== undefined) { this.Required = (je.required === "1"); }
    if (je.group !== undefined) { this.Group = je.group; }
    if (je.weight !== undefined) { this.Weight = je.weight; }
    if (je.allowfreeform !== undefined) { this.AllowFreeForm = (je.allowfreeform === "1"); }
  }
  public LoadXML(element: Element): void {
    super.LoadXML(element);
    if (element.attributes) {
      let defaultvalue = element.attributes.getNamedItem("defaultvalue")?.value;
      if (defaultvalue !== undefined) {
        this.Value = defaultvalue;
      }
      let valuetype = element.attributes.getNamedItem("valuetype")?.value;
      // const tb = this.ObjectBase;
      if (valuetype !== undefined) {
        this.ValueType = ObjectBase.FindSemTalkValueType(valuetype);
      }
      let min = element.attributes.getNamedItem("min")?.value;
      if (min !== undefined) { this.Min = Number(min); }
      let max = element.attributes.getNamedItem("max")?.value;
      if (max !== undefined) { this.Max = Number(max); }
      let islist = element.attributes.getNamedItem("islist")?.value;
      if (islist !== undefined) { this.IsList = (islist === "1"); }
      let values = element.attributes.getNamedItem("values")?.value;
      if (values !== undefined) {
        this.Values = values.split("; ");
      }
      let options = element.attributes.getNamedItem("options")?.value;
      if (options !== undefined) {
        this.Options = options.split("; ");
      }
      let required = element.attributes.getNamedItem("required")?.value;
      if (required !== undefined) { this.Required = (required === "1"); }
      let group = element.attributes.getNamedItem("group")?.value;
      if (group !== undefined) { this.Group = group; }
      let weight = element.attributes.getNamedItem("weight")?.value;
      if (weight !== undefined) { this.Weight = Number(weight); }
      let allowfreeform = element.attributes.getNamedItem("allowfreeform")?.value;
      if (allowfreeform !== undefined) { this.AllowFreeForm = (allowfreeform === "1"); }
    }
  }
  public Save(par: any[]): any {
    // const el: any = {};
    // // el.ObjectType = "AttributeType";
    // par[par.length] = el;
    // super.Save(el);
    let el = super.Save(par);

    if (this.Value !== undefined) el.defaultvalue = this.Value;
    if (this.ValueType !== undefined) el.valuetype = "http://www.semtalk.com/datatype#" + ObjectBase.SemTalkValueTypeName(this.ValueType);
    if (this.Min !== undefined) { el.min = this.Min; }
    if (this.Max !== undefined) { el.max = this.Max; }

    if (this.IsList) { el.islist = "1"; }
    if (this.Values !== undefined) {
      let v = "";
      for (const vv of this.Values) {
        v = v + vv + "; ";
      }
      if (v.length > 0) { v = v.substr(0, v.length - 2); }
      if (v !== "") { el.values = v; }
    }
    if (this.Options !== undefined) {
      let v = "";
      for (const vv of this.Options) {
        v = v + vv + "; ";
      }
      if (v.length > 0) { v = v.substr(0, v.length - 2); }
      if (v !== "") { el.options = v; }
    }
    if (this.SyncVisio) { el.syncvisio = "1"; }
    if (this.Raise) { el.raise = "1"; }
    if (this.Required) { el.required = "1"; }
    if (this.Weight) { el.weight = this.Weight; }
    if (this.Group) { el.group = this.Group; }
    if (!this.AllowFreeForm) { el.allowfreeform = "0"; }
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    super.SaveXML(xd, el);
    if (this.Value !== undefined) el.setAttribute("defaultvalue", this.Value);
    if (this.ValueType !== undefined) el.setAttribute("valuetype", "http://www.semtalk.com/datatype#" + ObjectBase.SemTalkValueTypeName(this.ValueType));
    if (this.Min !== undefined) { el.setAttribute("min", String(this.Min)); }
    if (this.Max !== undefined) { el.setAttribute("max", String(this.Max)); }

    if (this.IsList) { el.setAttribute("islist", "1"); }
    if (this.Values !== undefined) {
      let v = "";
      for (const vv of this.Values) {
        v = v + vv + "; ";
      }
      if (v.length > 0) { v = v.substr(0, v.length - 2); }
      if (v !== "") { el.setAttribute("values", v); }
    }
    if (this.Options !== undefined) {
      let v = "";
      for (const vv of this.Options) {
        v = v + vv + "; ";
      }
      if (v.length > 0) { v = v.substr(0, v.length - 2); }
      if (v !== "") { el.setAttribute("options", v); }
    }
    if (this.SyncVisio) { el.setAttribute("syncvisio", "1"); }
    if (this.Raise) { el.setAttribute("raise", "1"); }
    if (this.Required) { el.setAttribute("required", "1"); }
    if (this.Weight) { el.setAttribute("weight", String(this.Weight)); }
    if (this.Group) { el.setAttribute("group", this.Group); }
    if (!this.AllowFreeForm) { el.setAttribute("allowfreeform", "0"); }
  }


}


export class SemTalkAttribute extends SemTalkVirtualInstance implements ISemTalkAttribute {
  constructor(obj: ISemTalkObject, attr: string | ISemTalkAttributeType, val: any) {
    super(SemTalkType.SemTalkAttribute, obj);
    this._value = val;
    const tb = obj.ObjectBase;
    const at1 = SemTalkObject.getAttributeType(tb, attr);
    this.SetClass(at1);
    const ao = obj.GetAttributeOwner(attr);
    if (ao) {
      // console.debug(ao);
      this.Group = ao.Group;
      this.Required = ao.Required;
      this.Weight = ao.Weight;
      this.AllowFreeForm = ao.AllowFreeForm;
      if (val === null || val === undefined) {
        this._value = ao.Value;
      }
      if (ao.ValueType !== SemTalkValueType.Symbol) {
        this.ValueType = ao.ValueType;
      }
      this.Min = ao.Min;
      this.Max = ao.Max;
      this.Options = ao.Options;

    } else {

      // Dies kann z.Zeit gar nicht perGUI verändert werden, wird aber beim laden alter Dateien verwendet
      //  if (tb.IsClass(obj)) {
      this.Group = at1.Group;
      this.Required = at1.Required;
      this.Weight = at1.Weight;
      this.AllowFreeForm = at1.AllowFreeForm;
      // if (val === null || val === undefined) {
      //   this._value = at1.Value;
      // }
      // if (at1.ValueType !== SemTalkValueType.Symbol) {
      //   this.ValueType = at1.ValueType;
      // }
      this.Min = at1.Min;
      this.Max = at1.Max;
      this.Options = at1.Options;
      // }
    }
    tb.OnAttributeAdded(this);
  }
  private facets: any = {};
  private _value: any;
  public ValueType = SemTalkValueType.Symbol;
  //  private _class: SemTalkAttributeType = null;
  public Min: number | null = null;
  public Max: number | null = null;
  public IsList: boolean = false;
  public Values: any[] | null = null;
  public Options: string[] = [];
  public NoHTML: boolean = false;
  public Required: boolean = false;
  public ReadOnly: boolean = false;
  public Group: string;
  public Weight: number;
  public AllowFreeForm: boolean = true;

  public CurrentValue(lang?: string): any {
    let facet = "";
    if (lang === undefined) {
      let currentnsp = this.Owner.ObjectBase.CurrentNsp;
      if (currentnsp !== null) {
        facet = "facet_" + currentnsp;
      } else
        facet = "";
    } else {
      facet = "facet_" + lang;
    }
    if (facet !== "") {
      const v = this.facets[facet];
      if (v !== undefined) {
        return v;
      }
    }
    return this._value;
  }
  public SetFacetValue(facet: string, value: any): void {
    if (facet !== "") {
      this.facets[facet] = value;
    }
  }
  public GetFacetValue(facet: string): any {
    if (facet !== "") {
      return this.facets[facet];
    }
  }
  public ClassOf(): ISemTalkAttributeType {
    return super.ClassOf() as ISemTalkAttributeType;
  }
  public get Value(): any { return this._value; }
  public set Value(value: any) {
    const old: any = this._value;
    this._value = value;
    switch (this.ClassOf().ObjectName) {
      case SemTalkBaseConstant.SLUserNumber:
        this.Owner.ObjectBase.OnUserNumberChanged(this, old);
        break;
      case "Color":
        this.Owner.ObjectBase.OnColorChanged(this, old);
        break;
    }
    let currentnsp = this.Owner.ObjectBase.CurrentNsp;
    // if (currentnsp !== "") {
    const facet = "facet_" + currentnsp;
    this.facets[facet] = value;
    // }
    if (this.ClassOf().Raise) {
      this.Owner.ObjectBase.OnValueChanged(this, old);
    }
  }
  public get PropValue(): any { return this._value; }
  public get PropName(): string { return this.ClassOf().ID2Name; }
  public get PropType(): string { return ObjectBase.SemTalkTypeName(this.ObjectType); }
  public toString(): string { return "<" + ObjectBase.SemTalkTypeName(this.ObjectType) + " '" + this.ClassOf().ObjectName + "' Owner: '" + this.Owner.ObjectName + "'>"; }

  public Delete(): void {
    super.Delete();
    this.Owner.RemoveAttribute(this);
    if (this.ClassOf().Raise) {
      this.Owner.ObjectBase.OnAttributeDeleted(this);
    }
    // this.Owner = null;
    this._value = null;
  }
  public Load(je: any): SemTalkAttribute {
    const tb = this.Owner.ObjectBase;
    let aname: string = je.class;
    if (je.class === undefined) {
      aname = je.name;
    }
    if (aname !== undefined) {
      let cl: ISemTalkAttributeType = SemTalkObject.getAttributeType(tb, aname);
      this.SetClass(cl);
    } else {
      // tslint:disable-next-line:no-console
      // console.debug("Load Attribute: " + this.Owner.ObjectName + " " + je.class + " Missing Class " + JSON.stringify(je));
    }
    // if (je.defaultvalue !== undefined) {
    this._value = je.defaultvalue;
    // }
    if (je.valuetype !== undefined) {
      this.ValueType = ObjectBase.FindSemTalkValueType(je.valuetype);
    }
    if (this.ValueType === SemTalkValueType.Boolean) {
      if (this._value === "1" || this._value === "True") {
        this._value = true;
      }
      if (this._value === "0" || this._value === "False" || this._value === "") {
        this._value = false;
      }
    }
    if (je.min !== undefined) { this.Min = je.min; }
    if (je.max !== undefined) { this.Max = je.max; }
    if (je.islist !== undefined) { this.IsList = (je.islist === "1"); }
    if (je.values !== undefined) {
      this.Values = je.values.split("; ");
    }
    if (je.options !== undefined) {
      this.Options = je.options.split("; ");
    }
    if (je.nohtml !== undefined) { this.NoHTML = (je.nohtml === "1"); }
    if (je.required !== undefined) { this.Required = (je.required === "1"); }
    if (je.group !== undefined) { this.Group = je.group; }
    if (je.weight !== undefined) { this.Weight = je.weight; }
    if (je.allowfreeform !== undefined) { this.AllowFreeForm = (je.allowfreeform === "1"); }
    return this;
  }
  public LoadXML(element: HTMLElement): void {
    super.LoadXML(element);
    const tb = this.Owner.ObjectBase;
    if (element.attributes) {
      let aname = element.attributes.getNamedItem("class")?.value;
      if (aname === undefined) {
        aname = element.attributes.getNamedItem("name")?.value;
      }
      if (aname !== undefined) {
        let cl: ISemTalkAttributeType = SemTalkObject.getAttributeType(tb, aname);
        this.SetClass(cl);
      } else {
        // tslint:disable-next-line:no-console
        // console.debug("Load Attribute: " + this.Owner.ObjectName + " " + je.class + " Missing Class " + JSON.stringify(je));
      }
      let defaultvalue = element.attributes.getNamedItem("defaultvalue")?.value;
      if (defaultvalue !== undefined) {
        this._value = defaultvalue;
      }
      let valuetype = element.attributes.getNamedItem("valuetype")?.value;
      if (valuetype !== undefined) {
        this.ValueType = ObjectBase.FindSemTalkValueType(valuetype);
      }
      if (this.ValueType === SemTalkValueType.Boolean) {
        if (this._value === "1" || this._value === "True" || this._value === "") {
          this._value = true;
        }
        if (this._value === "0" || this._value === "False") {
          this._value = false;
        }
      }
      let min = element.attributes.getNamedItem("min")?.value;
      if (min !== undefined) { this.Min = Number(min); }
      let max = element.attributes.getNamedItem("max")?.value;
      if (max !== undefined) { this.Max = Number(max); }
      let islist = element.attributes.getNamedItem("islist")?.value;
      if (islist !== undefined) { this.IsList = (islist === "1"); }
      let values = element.attributes.getNamedItem("values")?.value;
      if (values !== undefined) {
        this.Values = values.split("; ");
      }
      let options = element.attributes.getNamedItem("options")?.value;
      if (options !== undefined) {
        this.Options = options.split("; ");
      }
      let nohtml = element.attributes.getNamedItem("nohtml")?.value;
      if (nohtml !== undefined) { this.NoHTML = (nohtml === "1"); }
      let required = element.attributes.getNamedItem("required")?.value;
      if (required !== undefined) { this.Required = (required === "1"); }
      let group = element.attributes.getNamedItem("group")?.value;
      if (group !== undefined) { this.Group = group; }
      let weight = element.attributes.getNamedItem("weight")?.value;
      if (weight !== undefined) { this.Weight = Number(weight); }
      let allowfreeform = element.attributes.getNamedItem("allowfreeform")?.value;
      if (allowfreeform !== undefined) { this.AllowFreeForm = (allowfreeform === "1"); }


    }
  }
  public Save(par: any[]): any {
    // el.ObjectType = this.Owner.ObjectBase.SemTalkTypeName(this.ObjectType);
    const el = super.Save(par);
    // if (el.class === undefined) {
    //   console.debug(this);
    //   el.class = this.ClassOf().ObjectName;
    // }
    // el.owner = this._owner._name;
    el.defaultvalue = this._value;
    if (this.Min) { el.min = this.Min; }
    if (this.Max) { el.max = this.Max; }
    if (this.IsList) { el.islist = "1"; }

    if (this.Values) {
      let v = "";
      let vl = this.Values;
      for (const i in vl) {
        v = v + vl[i] + "; ";
      }
      if (v.length > 0) { v = v.substr(0, v.length - 2); }
      if (v !== "") { el.values = v; }
    }
    if (this.Options) {
      let v = this.Options.join("; ");
      // for (const vv of this.Options) {
      //   v = v + vv + "; ";
      // }
      // if (v.length > 0) { v = v.substr(0, v.length - 2); }
      if (v !== "") { el.options = v; }
    }
    if (this.NoHTML) { el.nohtml = "1"; }
    if (this.Required) { el.required = "1"; }
    if (this.Weight) { el.weight = this.Weight; }
    if (this.Group) { el.group = this.Group; }
    if (!this.AllowFreeForm) { el.allowfreeform = "0"; }
    if (this.ValueType !== undefined && this.ValueType !== SemTalkValueType.Symbol) {
      // const ob = this.Owner.ObjectBase;
      // console.debug("Save VT");
      el.valuetype = "http://www.semtalk.com/datatype#" + ObjectBase.SemTalkValueTypeName(this.ValueType);
      if (this.ValueType === SemTalkValueType.Boolean) {
        if (this._value === true || this._value === "1" || this._value === "true" || this._value === "True") {
          el.defaultvalue = true;
        }
        if (this._value === false || this._value === "0" || this._value === "false" || this._value === "False") {
          el.defaultvalue = false;
        }
      }
    }
    return el;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {

    super.SaveXML(xd, el);

    if (this._value) el.setAttribute("defaultvalue", this._value);
    if (this.Min) { el.setAttribute("min", String(this.Min)); }
    if (this.Max) { el.setAttribute("max", String(this.Max)); }
    if (this.IsList) { el.setAttribute("islist", "1"); }

    if (this.Values) {
      let v = "";
      let vl = this.Values;
      for (const i in vl) {
        v = v + vl[i] + "; ";
      }
      if (v.length > 0) { v = v.substr(0, v.length - 2); }
      if (v !== "") { el.setAttribute("values", v); }
    }
    if (this.Options) {
      let v = this.Options.join("; ");
      // for (const vv of this.Options) {
      //   v = v + vv + "; ";
      // }
      // if (v.length > 0) { v = v.substr(0, v.length - 2); }
      if (v !== "") { el.setAttribute("options", v); }
    }
    if (this.NoHTML) { el.setAttribute("nohtml", "1"); }
    if (this.Required) { el.setAttribute("required", "1"); }
    if (this.Weight) { el.setAttribute("weight", String(this.Weight)); }
    if (this.Group) { el.setAttribute("group", this.Group); }
    if (!this.AllowFreeForm) { el.setAttribute("allowfreeform", "0"); }
    // if (this.ValueType !== undefined && this.ValueType !== SemTalkValueType.Symbol) {
    if (this.ValueType !== undefined) {
      // const ob = this.Owner.ObjectBase;
      // console.debug("Save VT");
      el.setAttribute("valuetype", "http://www.semtalk.com/datatype#" + ObjectBase.SemTalkValueTypeName(this.ValueType));
      if (this._value === true || this._value === "1" || this._value === "true" || this._value === "True") {
        el.setAttribute("defaultvalue", "1");
      }
      if (this._value === false || this._value === "0" || this._value === "false" || this._value === "False") {
        el.setAttribute("defaultvalue", "0");
      }
    }
  }
}
export class SemTalkAssociation extends SemTalkInstance implements ISemTalkAssociation {
  constructor(cla: ISemTalkAssociationType, reltype: SemTalkRelation, fromobj: ISemTalkObject, toobj: ISemTalkObject, id: SemTalkID) {
    super(cla, cla.ObjectName + "." + id, SemTalkType.SemTalkAssociation, id);

    this.ObjectType = SemTalkType.SemTalkAssociation;
    fromobj.AddAssociation(this);
    toobj.AddInvAssociation(this);
    this.FromObject = fromobj;
    this.ToObject = toobj;
    this.RelationType = reltype;
    this.FromObject.ObjectBase.OnAssociationAdded(this);
  }
  public ClassOf(): ISemTalkAssociationType { return super.ClassOf() as ISemTalkAssociationType; }
  public FromObject: ISemTalkObject;
  public ToObject: ISemTalkObject;
  public RelationType = SemTalkRelation.SemTalkProperty;
  public FromMin: number | null = null;
  public FromMax: number | null = null;
  public ToMin: number | null = null;
  public ToMax: number | null = null;
  public get PropValue(): ISemTalkObject { return this.ToObject; }
  public get PropInvValue(): ISemTalkObject { return this.FromObject; }
  public get PropValueName(): SemTalkID { return this.ToObject.ID; }
  public get PropValueCaption(): string { return this.ToObject.ID2NameNsp(); }
  public get PropInvValueCaption(): string { return this.FromObject.ID2NameNsp(); }
  public get PropName(): string { return this.ClassOf().ID2NameNsp(); }
  public get PropType(): string { return "Reference"; }
  public toString(): string {
    return "<" + ObjectBase.SemTalkTypeName(this.ObjectType) + " '" + this.ObjectName
      + "' From: '" + this.FromObject.ObjectName + "' To: '" + this.ToObject.ObjectName + "'>";
  }
  public Delete(): void {
    this.ObjectBase.OnAssociationBeforeDeleted(this);
    this.FromObject.RemoveAssociation(this);
    this.ToObject.RemoveInvAssociation(this);
    super.Delete();
    this.ObjectBase.OnAssociationDeleted(this);
  }
  public Save(par: any[]): any {
    const el = super.Save(par);
    // if (this.ClassOf()) { el.class = this.ClassOf().ObjectName; }
    if (this.ClassOf()) { el.text = this.ClassOf().ObjectName; }
    el.type = ObjectBase.SemTalkRelationTypeName(this.RelationType);
    el.ObjectType = ObjectBase.SemTalkTypeName(this.ObjectType);
    // par[par.length] = el;
    // el.owner = this._owner._name;
    return el;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    super.SaveXML(xd, el);
    if (this.ClassOf()) { el.setAttribute("text", this.ClassOf().ObjectName); }
    el.setAttribute("type", ObjectBase.SemTalkRelationTypeName(this.RelationType));
  }
  public UpdateLabel(mastername?: string): ILabelSpec {
    const lbl = super.UpdateLabel(mastername);
    if (lbl.newtxt === this.ID2NameNsp() || lbl.newtxt === this.ClassOf().ObjectCaption) {
      if (mastername === SemTalkBaseConstant.SLSubClassOf) {
        lbl.newtxt = "";
      } else {
        // if (this.FromObject.ObjectType !== SemTalkType.SemTalkClass && this.ToObject.ObjectType !== SemTalkType.SemTalkClass) {
        //   lbl.newtxt = "";
        // }
        if (!this.SystemClass()?.ShowLabel) {
          lbl.newtxt = "";
        }
      }
    }
    return lbl;
  }
}
export class SemTalkSpecialization extends SemTalkAssociation implements ISemTalkSpecialization {
  constructor(fromobj: ISemTalkClass, toobj: ISemTalkClass, id: SemTalkID) {
    const aname: string = SemTalkBaseConstant.SLSubClassOf;
    let cla: ISemTalkAssociationType | null = null;
    const at = fromobj.ObjectBase.FindAssociationType(aname);
    if (at !== null) { cla = at as ISemTalkAssociationType; }
    if (cla === null) {
      cla = fromobj.ObjectBase.MakeAssociationType(SemTalkRelation.SemTalkSubClassOf, aname);
      // cla.RelationType = SemTalkRelation.SemTalkSubClassOf;
    }
    super(cla, SemTalkRelation.SemTalkSubClassOf, fromobj, toobj, id);
    this.RelationType = SemTalkRelation.SemTalkSubClassOf;
    this.ObjectType = SemTalkType.SemTalkSpecialization;
    let fo = this.FromObject as ISemTalkClass;
    fo.ObjectBase.OnSubClassCreated(this);
  }
  public Delete(): void {
    let toobj = this.ToObject;
    let fromobj = this.FromObject as ISemTalkClass;
    let sysc = toobj.SystemClass();

    super.Delete();

    if (sysc && sysc.ID !== toobj.ID) {
      if (!fromobj.indeletion && !fromobj.IsParent(sysc)) {
        fromobj.AddSubclassOf(sysc);
      }
    }
    this.ObjectBase.OnSubClassDeleted(this);
  }

}
