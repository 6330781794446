export enum Process_ElementName {
    SLProc = "SLProc",
    SLClassModel = "SLClassModel",
    SLObjectModel = "SLObjectModel",
    SLValueChain = "SLValueChain",
    SLOrgChart = "SLOrgChart",
    SLActivity = "SLActivity",
    SLResource = "SLResource",
    SLPosition = "SLPosition",
    SLRole = "SLRole",
    SLOrgUnit = "SLOrgUnit",
    SLDataObject = "SLDataObject",
    SLPhysResource = "SLPhysResource",
    SLBuffer = "SLBuffer",
    SLControl = "SLControl",
    SLDecision = "SLDecision",
    SLMessageFlow = "SLMessageFlow",
    SLUses = "SLUses",
    SLInvUses = "SLInvUses",
    SLUsesPhys = "SLUsesPhys",
    SLPerson = "SLPerson",
    SLEntryPoint = "SLEntryPoint",
    SLExitPoint = "SLExitPoint",
    SLEvent = "SLEvent",
    SLStores = "SLStores",
    SLReads = "SLReads",
    SLInformation = "SLInformation",
    SLInfoType = "SLInfoType",
    SLOfftime = "Offtime",
    Swimlane = "Swimlane",
    SLGateway = "SLGateway",
    SLSystem = "SLSystem",
    SLRoot = "SLRoot",
    STTriggers = "STTriggers",
    STTriggeredBy = "STTriggeredBy",
    SLBreakpoint = "Breakpoint",
    SLDistribution = "SemTalkDistribution",
    hasofftime = "has offtime"

}
export enum SIM_AttributeTypeName {
    CostDriver = "CostDriver",
    Priority = "Priority",
    Strategy = "Strategy",
    DistributionType = "DistributionType",
    WorkTime = "WorkTime",
    WorkTime2 = "WorkTime2",
    WaitingTime = "WaitingTime",
    Restart = "Restart",
    Begin = "Begin",
    End = "End",
    Jobs = "Jobs",
    Period = "Period",
    SetupTime = "SetupTime",
    FixedCost = "FixedCost",
    VariableCost = "VariableCost",
    TransportationTime = "TransportationTime",
    Probability = "Probability",
    ConditionExpression = "ConditionExpression",
    AssignmentExpression = "AssignmentExpression",
    InitialContents = "InitialContents",
    FlushNumber = "FlushNumber",
    FlushTime = "FlushTime",
    InfoDepended = "InfoDepended",
    IsCopied = "IsCopied",
    Quantity = "Quantity",
    InputCondition = "InputCondition",
    OutputCondition = "OutputCondition",
    Capacity = "Capacity",
    active = "active",
    ValueFile = "ValueFile",
    UserNumber = "UserNumber",
    HumResNum = "HumResNum",
    unavail = "unavail",
    MatchAny = "MatchAny",
    MatchAnyAmount = "Amount",
    OfftimeFrom = "From",
    Script = "Script"
}
export enum SIM_StateAttributeTypeName {
    Running = "Running",
    Waiting = "Waiting",
    Syncing = "Syncing",
    Count = "Count"
}
export enum SIM_Strategy {
    FIFO = "FIFO",
    LIFO = "LIFO",
    LONGEST = "LONGEST",
    SHORTEST = "SHORTEST",
    ANY = "ANY"
}
export enum SIM_Distribution {
    // CONSTANT = "Constant Value",
    // EVEN = "Even distribution",
    // NORMAL = "Normal distribution",
    // EXPONENTIAL = "Exponential distribution",
    // ERLANG = "Erlang distribution"
    CONSTANT = "SIM_Distribution.CONSTANT",
    EVEN = "SIM_Distribution.EVEN",
    NORMAL = "SIM_Distribution.NORMAL",
    EXPONENTIAL = "SIM_Distribution.EXPONENTIAL",
    ERLANG = "SIM_Distribution.ERLANG",
    NEGBINOMIAL = "SIM_Distribution.NEGBINOMIAL" // "Negative Binomial
}
export enum SIM_Condition {
    AND = "AND",
    OR = "OR",
    XOR = "XOR",
    INFO = "INFO",
    SAND = "SAND"
}