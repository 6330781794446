import React from "react";
// import { Link } from "react-router-dom";
// import './Login.css';
import { IMongoOption } from '../../SemTalkMongoServer';
import { Link } from "react-router-dom";
import {
  Stack, StackItem,
  DefaultButton,
  TextField
} from 'office-ui-fabric-react';

// import { VisioRDFS } from '../../application/semtalklistener/visiordfs';
// import { IVisioRDFS } from '../../application/semtalklistener/visiordfsinterface';
import { IResStr, ResStr } from '../../application/semtalklistener/resstr';
import { SemTalkCookie } from "../../ISemTalkCookie";
import { accessCookie, setCookie } from "../../application/semtalklistener/stgoto";
import { ResID } from "../../application/semtalklistener/visiordfsinterface";

export interface IBackendProps {
  mongo: IMongoOption;
  // semmongoserverurl: string;
  // semmongoconnectiontoken: string;
}
export interface IBackendState {
  semconnecttoken: string;
  semmongoserverurl: string;
}
export default class Backend extends React.Component<IBackendProps, IBackendState> {
  private res: IResStr;
  constructor(props: IBackendProps) {
    super(props);
    let gl = accessCookie(SemTalkCookie.guilanguage);
    if (!gl) {
      gl = navigator.languages[1] || navigator.language;
      if (gl && gl.indexOf("-") > 0) {
        gl = gl.substring(0, gl.indexOf("-"));
      }
    }
    this.res = new ResStr(gl);
    // this.res.init(null, gl);
    let semconnecttoken = accessCookie(SemTalkCookie.semconnecttoken);
    let semmongoserverurl = accessCookie(SemTalkCookie.semmongoserverurl);
    let defurl = "/api/";
    if (!semmongoserverurl) {
      semmongoserverurl = defurl;
    }

    this.state = {
      semconnecttoken: semconnecttoken,
      semmongoserverurl: semmongoserverurl
    };
  }
  public render(): React.ReactElement {
    let divHostStyle: any = {
      // position: "fixed",
      // height: "100%", //this.props.height,
      // width: "80%", //this.props.width,
      padding: "20px"
    };
    const stackTokens = { childrenGap: 10 };
    return (
      <div>
        {this.res &&
          <div style={divHostStyle}>
            <form>
              <Stack tokens={stackTokens}>
                <StackItem>
                  <TextField value={this.state.semmongoserverurl} label={"MongoDB API"}
                    onChange={(_e: any, v?: any) => {
                      setCookie(SemTalkCookie.semmongoserverurl, v);
                      this.setState({ "semmongoserverurl": v });
                    }} />
                  <TextField value={this.state.semconnecttoken} label={"MongoDB " +
                    this.res.getResStr(ResID.STRCONNECTION)}
                    multiline rows={5}
                    onChange={(_e: any, v?: any) => {
                      setCookie(SemTalkCookie.semconnecttoken, v);
                      this.setState({ "semconnecttoken": v });
                    }} />
                </StackItem>
                <StackItem>
                  <Stack style={divHostStyle} tokens={stackTokens} horizontal>
                    <DefaultButton><Link to="">{this.res.getResStr(ResID.STRDLGCMDOK)}</Link></DefaultButton>
                    {/* <DefaultButton onClick={() => { this.res.showHelp("General-Backend"); }}>{this.res!.getResStr(ResID.STRHELP)}</DefaultButton> */}
                  </Stack>
                </StackItem>
              </Stack>
            </form>
          </div>
        }
      </div>
    );
  }
}
