// import { fetchGraph } from '../explorer/spexplorer';
// import { getListItem } from '../explorer/spexplorer';
//import { SPListItem } from '@microsoft/sp-page-context';
// import { MSGraphClient } from '@microsoft/sp-http';
//import { any } from '@microsoft/sp-webpart-base';

import { IVisioRDFS } from './visiordfsinterface';
import {
  ISemTalkBusinessClass,
  ISemTalkInstance,
  ISemTalkObject, SemTalkBaseConstant, SemTalkLanguage
} from '../tbase/Interface';
import { IMongoOption, mgGetItem } from '../../SemTalkMongoServer';
// import { alert } from '../semtalklistener/stglobal';
// import { MessageBarType } from 'office-ui-fabric-react';
import { Process_ElementName } from './processInterface';
import { GetLanguage } from '../tbase/langtools';


let _languages: string[] = [SemTalkLanguage.German,
SemTalkLanguage.English];

export function refreshPage(sem: IVisioRDFS,
  msgraphclient: any | undefined,
  mongo: IMongoOption) {
  if (sem.page) {
    if (sem.tseditor) {
      sem.tseditor.beginUpdate();
    }

    let di = sem.base.FindAssociationType(SemTalkBaseConstant.SLDisplays);
    let inf = sem.base.FindAssociationType(sem.base.GetModelAttribute(Process_ElementName.SLInfoType));
    for (let nd of sem.page.Contents()) {
      let fnd = false;
      let obj = nd.Model;
      if (obj.EditLink) {
        refreshObject(sem, obj, msgraphclient, mongo);
      } else {
        if (di) {
          for (let ro of obj.LinkedObjects(di)) {
            if (ro.EditLink) {
              refreshObject(sem, ro, msgraphclient, mongo);
              fnd = true;
            }
          }
        }
        if (!fnd) {
          if (inf) {
            for (let info of obj.LinkedObjects(inf)) {
              if (info.EditLink) {
                refreshObject(sem, info, msgraphclient, mongo);
                fnd = true;
              }
            }
          }
        }
      }
    }
    if (sem.tseditor) {
      sem.tseditor.endUpdate();
    }
    if (sem.tseditor) {
      sem.tseditor.refresh();
    }
  }
}
export function refreshObject(sem: IVisioRDFS, obj: ISemTalkObject,
  msgraphclient: any | undefined, mongo: IMongoOption) {

  if (obj.EditLink) {
    if (obj.EditLink.startsWith("mongo://") && mongo.usemongo && mongo.semuserlogin) {
      refreshMongoRepositoryObject(sem, mongo, obj);
    } else {
      if (obj.EditLink.indexOf("Web") < 0) {
        refreshGraphObject(sem, msgraphclient, obj);
      }
    }
  }
}

function updatesyns(obj: ISemTalkObject, title: string): boolean {
  let base = obj.ObjectBase;
  let upd = false;
  // const langclass = base.FindClass(SemTalkBaseConstant.SLLanguage);
  // let languages: string[] = [];
  // if (langclass) {
  //   languages = langclass.Instances().map((l) => l.ObjectName);
  // }
  let syn = obj.FindSynonym(base.CurrentNsp);
  if (syn && syn.Name !== title) {
    for (const l of _languages) {
      let syn2 = obj.FindSynonym(l);
      if (!syn2) {
        obj.MakeSynonym(title, l);
      }
    }
    upd = true;
  }
  return upd;
}
export async function refreshGraphObject(sem: IVisioRDFS, msgraphclient: any | undefined, obj: ISemTalkObject) {
  if (obj.EditLink && obj.EditLink.indexOf("Web") < 0) {
    let res;
    if (msgraphclient) {
      // res = await sem.explorer.fetchGraph(obj.EditLink + "/" + obj.ObjectName, "", "", msgraphclient);
      res = await sem.explorer.fetchGraph(obj.EditLink, "", "", msgraphclient);
    }
    if (res) {
      let upd = false;
      let title = "";
      if (obj.EditLink.indexOf("/lists/") > 0) {
        let spitem = res["fields"];
        if (!spitem) spitem = res;
        if (spitem["Comment"]) {
          obj.Comment = spitem["Comment"];
        }
        if (spitem["webUrl"]) {
          let url = spitem["webUrl"];
          if (!obj.GetAttachment(url)) {
            obj.MakeAttachment(url);
          }
        }
        let sysc = obj.SystemClass();
        if (sysc) {
          if (spitem["Class"] && obj.ObjectBase.IsInstance(obj)) {
            let cname = spitem["Class"];
            let cla = obj.ObjectBase.FindClass(cname);
            if (!cla) {
              cla = sysc.MakeSubClass(cname);
            }
            if (cla && cla.IsParent(sysc)) {
              (obj as ISemTalkInstance).SetClass(cla);
            }
          }
          for (const l of _languages) {
            if (spitem[l]) {
              // if (l === base.CurrentNsp) fnd = true;
              let ls = GetLanguage(l);
              if (ls) obj.MakeSynonym(spitem[l], ls);
            }
          }
          for (const a of sysc.AllAttributes().map(x => x.ClassOf().ObjectName)) {
            if (spitem[a]) {
              obj.SetValue(a, spitem[a]);
            }
          }
        }
        if (spitem["Title"]) {
          title = spitem["Title"];
        }
        if (spitem["displayName"]) {
          title = spitem["displayName"];
        }
        if (title.length > 0) {
          let u1 = updatesyns(obj, title);
          upd = upd || u1;
        }
        if (obj.ObjectBase.IsBusinessClass(obj)) {
          let oobj: ISemTalkBusinessClass = obj as ISemTalkBusinessClass;
          if ("Attribute" in spitem) {
            for (let a of spitem["Attribute"]) {
              let attr = a["LookupValue"];
              if (a["Title"]) attr = a["Title"];
              oobj.MakeAttribute(attr, null);
            }
          }
          if ("States" in spitem) {
            for (let a of spitem["States"]) {
              let sta = a["LookupValue"];
              if (a["Title"]) sta = a["Title"];
              oobj.MakeState(sta);
            }
          }
          if ("State" in spitem) {
            for (let a of spitem["State"]) {
              let sta = a["LookupValue"];
              if (a["Title"]) sta = a["Title"];
              oobj.MakeState(sta);
            }
          }
          if ("Method" in spitem) {
            for (let a of spitem["Method"]) {
              let meth = a["LookupValue"];
              if (a["Title"]) meth = a["Title"];
              oobj.MakeMethod(meth);
            }
          }
        }
      }

      if (obj.EditLink.startsWith("groups/")) {
        if (res.description &&
          obj.Comment !== res.description &&
          title !== res.description) {
          obj.Comment = res.description;
          upd = true;
        }
        let u1 = updatesyns(obj, res.displayName);
        upd = upd || u1;

      }
      if (upd) sem.UpdateByID(obj.ID);

    }
  }
}

export async function refreshMongoRepositoryObject(sem: IVisioRDFS, mongo: IMongoOption, obj: ISemTalkObject) {
  let sc = obj.SystemClass();
  if (sc !== null && obj.EditLink) {
    // try {
    let le = obj.EditLink;
    le = le.replace("mongo://repository/", "");
    if (le.indexOf("/") > 0) {
      let id = le.substring(le.lastIndexOf("/") + 1);
      let listname = sem.getSPListName(sc.ObjectName);
      let item = await mgGetItem(mongo, mongo.repository, listname, id);
      // console.debug(item);
      let upd = false;
      if (item["Comment"]) {
        if (obj.Comment !== item["Comment"]) {
          obj.Comment = item["Comment"];
          upd = true;
        }
      }
      if (item["URL"]) {
        let url = item["URL"];
        if (!obj.GetAttachment(url)) {
          obj.MakeAttachment(url);
        }
      }

      let sysc = obj.SystemClass();
      if (sysc) {
        if (item["Class"] && obj.ObjectBase.IsInstance(obj)) {
          let cname = item["Class"];
          let cla = obj.ObjectBase.FindClass(cname);
          if (!cla) {
            cla = sysc.MakeSubClass(cname);
          }
          if (cla && cla.IsParent(sysc)) {
            (obj as ISemTalkInstance).SetClass(cla);
          }
        }
        for (const a of sysc.AllAttributes().map(x => x.ClassOf().ObjectName)) {
          if (item[a]) {
            obj.SetValue(a, item[a]);
          }
        }
        if (obj.ObjectBase.IsBusinessClass(obj)) {
          let oobj: ISemTalkBusinessClass = obj as ISemTalkBusinessClass;
          if ("Attribute" in item) {
            for (let attr of item["Attribute"]) {
              oobj.MakeAttribute(attr, null);
            }
          }
          if ("States" in item) {
            for (let sta of item["States"]) {
              oobj.MakeState(sta);
            }
          }
          if ("State" in item) {
            for (let sta of item["State"]) {
              oobj.MakeState(sta);
            }
          }
          if ("Method" in item) {
            for (let meth of item["Method"]) {
              oobj.MakeMethod(meth);
            }
          }
        }
      }
      for (const l of _languages) {
        if (item[l]) {
          // if (l === base.CurrentNsp) fnd = true;
          let ls = GetLanguage(l);
          if (ls) obj.MakeSynonym(item[l], ls);
        }
      }
      // let syn = obj.FindSynonym(sem.base.CurrentNsp);
      // if (syn !== item["Title"]) {
      //   obj.MakeSynonym(item["Title"], sem.base.CurrentNsp);
      //   upd = true;
      // }
      if (item["name"]) {
        upd = updatesyns(obj, item["name"]);
      }
      if (upd) sem.UpdateByID(obj.ID);
    }
  }
}
export async function refreshSPRepositoryObject(sem: IVisioRDFS, context: any | undefined, site: string, obj: ISemTalkObject) {
  let sc = obj.SystemClass();
  if (sc !== null) {
    try {
      let le = obj.EditLink;
      if (le && le.indexOf("/") > 0) {
        le = le.substring(le.lastIndexOf("/") + 1);
        let listname = sem.getSPListName(sc.ObjectName);
        let lany: any;
        if (context) {
          lany = await sem.explorer.getListItem(context, site, listname, le);
          let li: any = lany;
          let upd = false;
          if (li["Comment"]) {
            if (obj.Comment !== li["Comment"]) {
              obj.Comment = li["Comment"];
              upd = true;
            }
          }
          if (li["URL"]) {
            let url = li["URL"];
            if (!obj.GetAttachment(url)) {
              obj.MakeAttachment(url);
            }
          }

          let syn = obj.FindSynonym(sem.base.CurrentNsp);
          if (syn !== li["Title"]) {
            obj.MakeSynonym(li["Title"], sem.base.CurrentNsp);
            upd = true;
          }
          if (upd) sem.UpdateByID(obj.ID);
        }
      }
    } catch (err) {
      let msg: string = "unknown";
      if (err && typeof err === "object") {
        const e: any = err;
        msg = e["message"];
        alert(msg);
      }
    }
  }
}
