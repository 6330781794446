// import { Graph, Cell, Edge } from "../graph/graph";
import {
  ISemTalkNode, IObjectBase, ISemTalkDiagram,
  ISemTalkObject,
  ISemTalkSystemClass,
  ISemTalkInstance,
  ISemTalkAssociation,
  ISemTalkAssociationType,
  ISemTalkDiagramType,
  ISemTalkClass,
  ILabelSpec,
  SemTalkLanguageCode,
  SemTalkID
} from '../tbase/Interface';
import { ITSEditor } from "../../ITSEditor";
// import { MSGraphClient } from '@microsoft/sp-http';
// import { any } from '@microsoft/sp-webpart-base';
// import { ITreeItem } from "@pnp/spfx-controls-react/lib/TreeView";
import { ISPExplorer } from '../explorer/spexplorerinterface';
import { Graph } from '../graph/graph';
import { ISemTalkUserTabProps } from '../../controls/stusertab/SemTalkUserTab';
import { MessageBarType } from 'office-ui-fabric-react';
import { ISemTalkOnline, SemTalkRole } from '../../ISemTalkOnline';

export enum SemTalkShapeType {
  general = "general",
  svg = "svg",
  png = "png",
  org = "org",
  image = "image",
  bpmn = "bpmn",
  epc = "epc",
  class = "class",
  generic = "generic",
  cit = "cit",
  sitebuilder = "sitebuilder"

}
export interface SemTalkShape {
  name: string;
  key: string;
  subkey?: string;
  role?: string;
  logo: string;
  icon?: string;
  width: number;
  height: number;
  type: SemTalkShapeType;
  label: string | undefined;
  hidden: boolean;
  sticky?: string;
}
export type SemTalkStencil = SemTalkShape[];


export enum SemTalkNavigationEvent {
  gotoDocument = "gotoDocument",
  gotoNode = "gotoNode",
  gotoObject = "gotoObject",
  gotoText = "gotoText",
  visSelectionChanged = "visSelectionChanged",
  alert = "alert",
  settings = "settings"
}

// tslint:disable-next-line:interface-name
export interface IVisioRDFS {
  noevents: boolean;
  //vispage: Graph;
  // editor: any;
  tseditor: ITSEditor;
  graph: mxGraph | Graph;
  explorer: ISPExplorer;
  // shape: any;
  masters: SemTalkStencil;
  redraw(): void;
  redrawLight(updatelabel: boolean): void;
  getMessage(e: any): any;

  template: string;
  horizontal: boolean;
  currentShape(): mxCell | undefined;
  loadDiag(id: SemTalkID, initpage: boolean): ISemTalkDiagram | null;
  getMxId(shape: mxCell): string;
  FindShapeByShapeID(shapeid: string): mxCell | null;
  FindShape(obj: ISemTalkObject): any | null;
  visCellSystemClass(oid: SemTalkID): ISemTalkSystemClass | null;
  visCellRefinement(oid: SemTalkID): ISemTalkDiagram | null;
  visCellGoUp(oid: SemTalkID): ISemTalkObject | null;
  visCellClass(oid: SemTalkID): ISemTalkClass | null;
  // visSelectionChanged(shape: mxCell): void;
  visShapesCloned(shapes: mxCell[]): void;
  visShapesMoved(shapes: mxCell[]): void;
  visShapesCopied(shapes: mxCell[]): void;
  EnsureClass(newid: string | null, mst: string, diagtype: ISemTalkDiagramType): ISemTalkClass;
  EnsureSystemClass(newid: string | null, mst: string, diagtype: ISemTalkDiagramType): ISemTalkSystemClass;
  EnsureInstance(newid: string | null, cl: ISemTalkSystemClass, mst: string, subkey: string): ISemTalkInstance;
  visShapeAdded(shape: mxCell, newid: string | null): ISemTalkNode | null;
  visShapesDeleted(shapes: mxCell[]): void;
  visShapeExitedTextEdit(shape: mxCell, label: string): void;
  makeInstance(class_cell: mxCell, inst_cell: mxCell): void;
  makeSubClass(class_cell: mxCell, inst_cell: mxCell): void;
  resetSubTaskStyle(s0: string): string;
  updateLabelStyle(obj: ISemTalkObject, lsp: ILabelSpec, style: string): string;
  UpdateLabel(obj: ISemTalkObject): void;
  UpdateDependents(obj: ISemTalkObject): void;
  UpdateByID(id: SemTalkID): void;
  masterName(key: string): string;
  MoveEdgesToShape(sel: mxCell, obj: ISemTalkObject, newobj: ISemTalkObject): void;
  RenameDiagram(obj: ISemTalkObject, label: string): void;
  RenameObject(obj: ISemTalkObject, label: string, shape: mxCell | null): void;
  NextUserNumber(u: any, pg: ISemTalkDiagram | null): string;
  RenumberObject(obj: ISemTalkInstance, visited: ISemTalkObject[], un: any): void;
  GetShapeStyle(shapeName: string, shapeType: SemTalkShapeType): { style: string, isedge: boolean };
  ChangeObject(shape: mxCell, newobj: ISemTalkObject[]): void;
  UpdateSwimlaneContent(shape: mxCell): void;
  EnsureSwimlaneContent(): void;
  createVertex(parent: mxCell | null, id: string | null, value: any, x: number, y: number,
    width: number, height: number, shapeStyle?: string): mxCell;
  createEdge(src: mxCell, dst: mxCell, shapeStyle?: string): mxCell;
  InsertObject(newobj: ISemTalkObject, x: number, y: number, shapeStyle: string,
    page: ISemTalkDiagram): mxCell | null;
  EnsureBORelation(ins: ISemTalkInstance, label: string, unique: boolean): void;
  isValid(src: mxCell, dst: mxCell): boolean;
  notSamePool(source: mxCell, target: mxCell, toggle: boolean): boolean;
  filterRelations(rels: ISemTalkAssociationType[],
    source: mxCell, target: mxCell, ctrl: boolean,
    sy: ISemTalkSystemClass, dsy: ISemTalkSystemClass,
    src: ISemTalkInstance, dst: ISemTalkInstance,
    considertasktye: boolean): ISemTalkAssociationType[];
  AllValidClassRelations(src: ISemTalkSystemClass, dst: ISemTalkSystemClass): ISemTalkAssociationType[];
  visConnectionAdded(evt: mxEventObject | undefined, link: mxCell, source: mxCell, target: mxCell): ISemTalkNode | null;
  visConnectionsDeleted(link: mxCell): void;
  visCellConnected(link: mxCell): void;
  visPageAdded(page: ISemTalkDiagram): void;
  visPageDeleted(page: ISemTalkDiagram): void;
  visPageChanged(page: ISemTalkDiagram): void;
  visIsSplitTarget(target: mxCell, cells: mxCell[], evt: any): boolean;

  base: IObjectBase;
  page: ISemTalkDiagram | null | undefined;
  getResStrListener(str: string, lang?: string): string;
  getResStr(str: string, lang?: string): string;
  getResKeys(): string[];
  getResKeysListener(): string[];
  getSPListName(bc: string): string;
  init(ob: IObjectBase | null, lan: string): void;
  patchSubTask(ob: IObjectBase, resizeall: boolean): Promise<void>;
  getOverridable(name: string): any;
  initSubTask(ob: IObjectBase): Promise<void>;
  initPage(sem: IVisioRDFS, diag: ISemTalkDiagram): void;
  insertSwimlane(cell: mxCell | null, x: number, y: number, width: number, height: number): mxCell | null;
  toggleDirection(cell: mxCell | null): void;
  swimlaneLayout(cell: mxCell | null): void;
  ExpandObject(shape: mxCell, newlinks: ISemTalkAssociation[]): void;
  ExpandSimilarClass(shape: mxCell, newlinks: string[]): void;
  // redrawLastShape(): void;
  beginTransaction(reason: string): void;
  endTransaction(reason: string): void;
  currentTransaction(): string;
  autoSave(mxgraphxml: string, reason: string): void;
  IsAutoSave: boolean;
  readonly: boolean;
  tempId: number;
  IsUndo(): boolean;
  IsRedo(): boolean;
  clearUndo(): void;

  InsertObjects(newobjs: ISemTalkObject[], x: number, y: number, expand_links: boolean, 
    expand_subclasses: boolean,
    shapeStyle: string, pg: ISemTalkDiagram, reusenodes: boolean): void;
  ImportTermSet(sysc: ISemTalkSystemClass, items: ITermSetItem[], x: number, y: number): void;
  ImportTermSetTree(syscname: string, items: any[], x: number, y: number): void;
  ImportPlannerTasks(syscname: string, items: any[]): void;

  getLabel(cell: mxCell, tmp: string): string;
  getTextLabel(cell: mxCell, tmp: string): string;
  getLinkLabel(cell: mxCell, tmp: string): string;
  getTooltipForCell(oid: string): string;
  quickShapes(oid: SemTalkID, stencil: SemTalkStencil): SemTalkStencil;
  insertQuickShape(m: SemTalkShape, cell: mxCell | null): mxCell | null;

  selectedShapes(): mxCell[];
  deleteShapes(): void;
  clearSelection(): void;
  updateDiaglist(): void;

  // refreshGraphObject(msgraphclient: MSGraphClient, obj: ISemTalkObject): void;
  // refreshRepositoryObject(context: any, site: string, obj: ISemTalkObject): void;
  // refreshPage(msgraphclient: MSGraphClient, context: any, site: string): void;
  // refreshObject(obj: ISemTalkObject, msgraphclient: MSGraphClient, context: any, site: string);
  initStencil(
    dtype: ISemTalkDiagramType,
    stencil: string, sprops: ISharePointSettings, extended: boolean,
    loadStencil: (template: string, url: string, spinterface: ISPExplorer) => Promise<SemTalkStencil>): Promise<SemTalkStencil>;

  initStencils(ob: IObjectBase, sem: IVisioRDFS, guilanguage: SemTalkLanguageCode,
    sprops: ISharePointSettings, bpmnrules: boolean,
    role: SemTalkRole,
    loadStencil: (template: string, url: string, spinterface: ISPExplorer) => Promise<SemTalkStencil>): Promise<string>;
  getStencils(): SemTalkStencil[];
  getStencil(diag: ISemTalkDiagramType): SemTalkStencil;
  renderUserTab(tab: string, args: any): React.ReactElement<ISemTalkUserTabProps>;
  captionUserTab(_tab: string): string;
  hasVector: (s: ISemTalkSystemClass) => {};
  BeforeDocumentSave(): boolean;
  getRibbon(ribbon?: SemTalkRibbon, toolbar?: SemTalkRibbon, islocked?: boolean): {
    "ribbon": SemTalkRibbon,
    "toolbar": SemTalkRibbon,
    "keys": { [keyEvent: string]: any }
  };
  setRibbon(ribbon: SemTalkRibbon, toolbar: SemTalkRibbon): void;
  AddonCommand(args: any): void;
  resetRibbon(cache: boolean): void;
  renderSubTaskDialog(addoncommand: string): any;
  alert(s: string, mtype: MessageBarType): void;
  ismodified: boolean;
  showHelp(page?: string, lng?: string): void;
  getFileInfo(fname: string): Promise<any | null>;
  getFile(fname: string): Promise<any | null>;
  openFile(fname: string): Promise<any | null>;
  isElectron(): boolean;
  selectLeft(): void;
  selectRight(): void;
  selectUp(): void;
  selectDown(): void;
  selectPageUp(): void;
  selectPageDown(): void;
  selectLeftOptions(): { title: string, Object: ISemTalkObject }[];
  selectRightOptions(): { title: string, Object: ISemTalkObject }[];
  scaleshapes: number;
  importQuickEditTable(cols: any[], rows: any[], systemclasses: any): void;
  patchHyperlink(): void;
  loadExternalFormat(callback: ISemTalkOnline, s: string, createdoc: any): Promise<void>;
  saveExternalFormat(callback: ISemTalkOnline, filename: string, currentpage: boolean): Promise<string>;
  showhyperlinkmarker: boolean;
  site: string;
  guilanguage: SemTalkLanguageCode;
  setTextField(tfelement: { key: string; label: string }[]): void;
  translateState(state: string): string;
  updateTextFields(props: any[]): void;
  reorderPages(): void;
  saveXML(encode: any, decode: any): string;
  textGenerator(sem: IVisioRDFS, diag: ISemTalkDiagram, ismodel: boolean): string[];
  getDynStyles(obj: ISemTalkObject, callback: ISemTalkOnline, cells: mxCell[] | null): any[];
  getDynProps(obj: ISemTalkObject): any[];
  EnsureObjectFlow(objectflow: boolean): void;
  imageBundles: any;
  setBundles(): void;
  getBundeledImages(): any[];
  removeBundles(images: any): void;
  getImageFromBundles(img: string): any;
  getOptionCaption(s: string, lang: SemTalkLanguageCode, base: IObjectBase): string;
  Initialized(callback: ISemTalkOnline): void;
  Layout(cells?: mxCell[]): void;
  shapeExpanded(obj: ISemTalkObject, shape: mxCell, newlinks: ISemTalkAssociation[]): void;    
}
export type ISharePointSettings = {
  site: string,
  librarysite: string;
  library: string,
  cdn: string,
  context: any,
  support: string,
  templates: string,
  approved: string,
  sharepointdocumentcolumns: string
};

export type ISharePointGraphSettings = {
  usegraph: boolean;
  graphClient: any;
  sharepointlibrarysite: string;
  sharepointlibrary: string,
  sharepointdocumentcolumns: string,
  sharepointlibraryfixed: boolean
};
export interface ITermSetItem {
  Term: any;
  ObjectName: string;
  ObjectCaption: string;
  Children: any[];
}
export enum SemTalkUIConstant {
  SLHidden = "SemTalkHidden",
  SemTalkUserTab = "SemTalkUserTab",
}

export enum SemTalkStyleAttribute {
  custom = "custom",
  edgeStyle = "edgeStyle",
  strokeColor = "strokeColor",
  fillColor = "fillColor",
  swimlaneFillColor = "swimlaneFillColor",
  fontColor = "fontColor",
  gradientColor = "gradientColor",
  strokeWidth = "strokeWidth",
  opacity = "opacity",
  fontSize = "fontSize",
  borderWidth = "borderWidth",
  image = "image",
  fontStyle = "fontStyle",
  isuml = "isuml",
  shape = "shape",
  align = "align",
  verticalAlign = "verticalAlign",
  verticalLabelPosition = "verticalLabelPosition",
  labelPosition = "labelPosition",
  labelWidth = "labelWidth",
  labelBackgroundColor = "labelBackgroundColor",
  rounded = "rounded",
  curved = "curved",
  dashed = "dashed",
  resize = "resize",
  autosize = "autosize",
  shadow = "shadow",
  elbow = "elbow",
  dashPattern = "dashPattern",
  startArrow = "startArrow",
  endArrow = "endArrow",
  wordbreak = "wordbreak",
  editable = "editable",
  labelMovable = "labelMovable",
  size = "size",
  fixedSize = "fixedSize",
  noEdgeStyle = "noEdgeStyle"
}

export enum SemTalkBuiltInShape {
  genericclass = "genericclass",
  umlclass = "umlclass"
}

export type SemTalkRibbon = any[];

export enum ResID {
  STRVIEWER = "STRVIEWER",
  STREDITOR = "STREDITOR",
  STRADMIN = "STRADMIN",
  STRMETAMODEL = "STRMETAMODEL",
  STRPUBLISHER = "STRPUBLISHER",
  STRLEAVEWITHOUTSAVING = "STRLEAVEWITHOUTSAVING",
  STRHELLO = "STRHELLO",
  STRRESUME = "STRRESUME",
  STRRESUMEFROM = "STRRESUMEFROM",
  STRRESUMEMODIFIED = "STRRESUMEMODIFIED",
  STRRESUMEMODIFIEDAT = "STRRESUMEMODIFIEDAT",
  STRRESUMEMODIFIEDAFTER = "STRRESUMEMODIFIEDAFTER",
  STROPENCHECKOUTTO = "STROPENCHECKOUTTO",
  STRCHECKEDOUTAT = "STRCHECKEDOUTAT",
  STRIMPORTINGOWL = "Importing OWL",
  STRUPLOADINGCOPY = "STRUPLOADINGCOPY",
  STRIMPORTINGSEMTALKXML = "Importing SemTalk XML: ",
  STRDOWNLOADINGCOPY = "STRDOWNLOADINGCOPY",
  STRVERSIONRESTORED = "STRVERSIONRESTORED",
  STRPUBLISH = "STRPUBLISH",
  STRCHECKEDOUTAT1 = "STRCHECKEDOUTAT1",
  STRCHECKEDOUTBY1 = "STRCHECKEDOUTBY1",
  STRAPPROVEDAT1 = "STRAPPROVEDAT1",
  STRAPPROVEDBY1 = "STRAPPROVEDBY1",
  STRNAME = "STRNAME",
  STRSAVE = "STRSAVE",
  STRDLGCMDOK = "STRDLGCMDOK",
  STRDLGCMDCA = "STRDLGCMDCA",
  STRDLGCMDNE = "STRDLGCMDNE",
  STRHELP = "STRHELP",
  STRVERSIONRESTORE = "STRVERSIONRESTORE",
  STRREFRESH = "STRREFRESH",
  STRREPOSITORYEXPORT = "STRREPOSITORYEXPORT",
  STRPANZOOM = "STRPANZOOM",
  STROPTIONS = "STROPTIONS",
  STRREPOSITORY = "STRREPOSITORY",
  STRSELECTREPO = "STRSELECTREPO",
  STRCONFIRMSAVEREPO = "STRCONFIRMSAVEREPO",
  STRLOGIN = "STRLOGIN",
  STRLOGOUT = "STRLOGOUT",
  STRGENERAL = "STRGENERAL",
  STRImage = "STRImage",
  STRTEXTTOOL = "STRTEXTTOOL",
  STRGENERATORFACTS = "STRGENERATORFACTS",
  STRGENERATORISA = "STRGENERATORISA",
  STRGENERATOREVERY = "STRGENERATOREVERY",
  STRElbowEdgeStyle = "STRElbowEdgeStyle",
  STROrthogonalEdgeStyle = "STROrthogonalEdgeStyle",
  STRloopRelationEdgeStyle = "STRloopRelationEdgeStyle",
  STRTopToBottomEdgeStyle = "STRTopToBottomEdgeStyle",
  STREntityRelationEdgeStyle = "STREntityRelationEdgeStyle",
  STRIsometricEdgeStyle = "STRIsometricEdgeStyle",
  STRLoopEdgeStyle = "STRLoopEdgeStyle",
  STRSideToSideEdgeStyle = "STRSideToSideEdgeStyle",
  STRSegmentEdgeStyle = "STRSegmentEdgeStyle",
  STRNONE = "STRNONE",
  STRSIMULATION = "STRSIMULATION",
  STRTask = "STRTask",
  STREvent = "STREvent",
  STRGateway = "STRGateway",
  STRCOMMENT = "STRCOMMENT",
  STRDataObject = "STRDataObject",
  STRSwimlane = "STRSwimlane",
  STRHumanResource = "STRHumanResource",
  STROrgUnit = "STROrgUnit",
  STRPerson = "STRPerson",
  STRPosition = "STRPosition",
  STRRole = "STRRole",
  STRObject = "STRObject",
  STRSequenceflow = "STRSequenceflow",
  STRhasmanager = "STRhasmanager",
  STRHASOFFTIME = "STRHASOFFTIME",
  STRINFOTYPE = "STRINFOTYPE",
  STRMEMBER = "STRMEMBER",
  STRUSES = "STRUSES",
  STRUSEPHYRESOURCE = "STRUSEPHYRESOURCE",
  STRSUPERIOROF = "STRSUPERIOROF",
  STRPERFORMS = "STRPERFORMS",
  STRADDRESS = "STRADDRESS",
  STRBegin = "STRBegin",
  STRCapacity = "STRCapacity",
  STRCITY = "STRCITY",
  STRCOST = "STRCOST",
  STRCostDriver = "STRCostDriver",
  STRCOUNTRY = "STRCOUNTRY",
  STRDistributionType = "STRDistributionType",
  STREnd = "STREnd",
  STRFAXNUMBER = "STRFAXNUMBER",
  STRFIRSTNAME = "STRFIRSTNAME",
  STRFixedCost = "STRFixedCost",
  STRFlushNumber = "STRFlushNumber",
  STRFlushTime = "STRFlushTime",
  STRJobs = "STRJobs",
  STRLASTNAME = "STRLASTNAME",
  STRPeriod = "STRPeriod",
  STRPHONENUM = "STRPHONENUM",
  STRPriority = "STRPriority",
  STRPROBABILITY = "STRPROBABILITY",
  STRQUANTITY = "STRQUANTITY",
  STRRestart = "STRRestart",
  STRSetupTime = "STRSetupTime",
  STRATTRIBUTES = "STRATTRIBUTES",
  STRSTATES = "STRSTATES",
  STRMETHODS = "STRMETHODS",
  STRStrategy = "STRStrategy",
  STRTRANSPORTATIONTIME = "STRTRANSPORTATIONTIME",
  STRUSERID = "STRUSERID",
  STRDLGPROXYUSER = "STRDLGPROXYUSER",
  STRRESPWD = "STRRESPWD",
  STRDLGPWD = "STRDLGPWD",
  STRRepeat = "STRRepeat",
  STRUserNumber = "STRUserNumber",
  STRVALUEFILE = "STRVALUEFILE",
  STRVariableCost = "STRVariableCost",
  STRWaitingTime = "STRWaitingTime",
  STRWorkTime = "STRWorkTime",
  STRWorkTime2 = "STRWorkTime2",
  STRZIP = "STRZIP",
  STRInitialContentS = "STRInitialContentS",
  STRGeneric = "STRGeneric",
  STRDATAOBJECT = "STRDATAOBJECT",
  STRINSTANCE = "STRINSTANCE",
  STRSystem = "STRSystem",
  STRBPMNElement = "STRBPMNElement",
  STRArtifact = "STRArtifact",
  STRAnnotation = "STRAnnotation",
  STRBreakpoint = "STRBreakpoint",
  STRFlowObject = "STRFlowObject",
  STRParticipant = "STRParticipant",
  STREntity = "STREntity",
  STRdataobjectOf = "STRdataobjectOf",
  STRMessageflow = "STRMessageflow",
  STRACTIVITY = "STRACTIVITY",
  STRACTIVE = "STRACTIVE",
  STRADHOC = "STRADHOC",
  STRADHOCORDERING = "STRADHOCORDERING",
  STRADHOCORDERING2 = "STRADHOCORDERING2",
  STRADOC = "STRADOC",
  STRBASENAME = "STRBASENAME",
  STRCOMPENSATION = "STRCOMPENSATION",
  STRCOMPLEXMIFLOWCONDITION = "STRCOMPLEXMIFLOWCONDITION",
  STRCONDITIONEXPRESSION = "STRCONDITIONEXPRESSION",
  STRDURATION = "STRDURATION",
  STREDIT = "STREDIT",
  STREVENTTYPE = "STREVENTTYPE",
  STRGATEWAYTYPE = "STRGATEWAYTYPE",
  STRIMPLEMENTATION = "STRIMPLEMENTATION",
  STRINSTANTIATE = "STRINSTANTIATE",
  STRInterface = "STRInterface",
  STRIORULES = "STRIORULES",
  STRISDEFAULT = "STRISDEFAULT",
  STRISO = "STRISO",
  STRISTRANSACTION = "STRISTRANSACTION",
  STRLOOPCONDITION = "STRLOOPCONDITION",
  STRLOOPCOUNTER = "STRLOOPCOUNTER",
  STRLOOPTYPE = "STRLOOPTYPE",
  STRLOOPMAXIMUM = "STRLOOPMAXIMUM",
  STRMICONDITION = "STRMICONDITION",
  STRMIFLOWCONDITION = "STRMIFLOWCONDITION",
  STRMIORDERING = "STRMIORDERING",
  STROPERATION = "STROPERATION",
  STRPERMMASK = "STRPERMMASK",
  STRRULENAME = "STRRULENAME",
  STRSCRIPT = "STRSCRIPT",
  STRSTARTQUANTITY = "STRSTARTQUANTITY",
  STRTASKTYPE = "STRTASKTYPE",
  STRTESTTIME = "STRTESTTIME",
  STRTIMECYCLE = "STRTIMECYCLE",
  STRTIMEDATE = "STRTIMEDATE",
  STRTRIGGERTYPE = "STRTRIGGERTYPE",
  STRLOOP = "STRLOOP",
  STRBPD = "STRBPD",
  STROrgChar = "STROrgChar",
  STRObjectDiagram = "STRObjectDiagram",
  STRBOTTOM = "STRBOTTOM",
  STRTOP = "STRTOP",
  STRLEFT = "STRLEFT",
  STRRIGHT = "STRRIGHT",
  STRCENTER = "STRCENTER",
  STRMIDDLE = "STRMIDDLE",
  STRGENERATORPROCESS = "STRGENERATORPROCESS",
  STRGENERATORSUBPROCESS = "STRGENERATORSUBPROCESS",
  STRGENERATORPARTICIPANT = "STRGENERATORPARTICIPANT",
  STRGENERATORSTART = "STRGENERATORSTART",
  STRGENERATOREXPLAIN = "STRGENERATOREXPLAIN",
  STRGENERATORSF = "STRGENERATORSF",
  STRGENERATORGATEWAY = "STRGENERATORGATEWAY",
  STRGENERATORCONDITION = "STRGENERATORCONDITION",
  STRGENERATOREVENT = "STRGENERATOREVENT",
  STRGENERATORTASK = "STRGENERATORTASK",
  STRGENERATORMF = "STRGENERATORMF",
  STRGENERATORCOMMENT = "STRGENERATORCOMMENT",
  STRGENERATORREADS = "STRGENERATORREADS",
  STRGENERATORINPUT = "STRGENERATORINPUT",
  STRGENERATORWRITES = "STRGENERATORWRITES",
  STRFunction = "STRFunction",
  STRXOR = "STRXOR",
  STRAND = "STRAND",
  STROR = "STROR",
  STREntitytyp = "STREntitytyp",
  STRClass = "STRClass",
  STRFachbegriff = "STRFachbegriff",
  STRService = "STRService",
  STRInformationsträger = "STRInformationsträger",
  STRWissenskategorie = "STRWissenskategorie",
  STRProcesspfad = "STRProcesspfad",
  STRSachmittel = "STRSachmittel",
  STROperator = "STROperator",
  STRTransaction = "STRTransaction",
  STRPackage = "STRPackage",
  STREPCOBJECT = "STREPCOBJECT",
  STRaktiviert = "STRaktiviert",
  STRerzeugt = "STRerzeugt",
  STRfuehrtaus = "STRfuehrtaus",
  STRfuehrtzu = "STRfuehrtzu",
  STRhasTransaction = "STRhasTransaction",
  STRhasOperation = "STRhasOperation",
  STRhatOutput = "STRhatOutput",
  STRistInputfuer = "STRistInputfuer",
  STRistVorgaengervon = "STRistVorgaengervon",
  STRliefertInputfuer = "STRliefertInputfuer",
  STRMESSAGETYPE = "STRMESSAGETYPE",
  STRSUPPOUTPUTFOR = "STRSUPPOUTPUTFOR",
  STRverknuepft = "STRverknuepft",
  STRwirdausgewertetvon = "STRwirdausgewertetvon",
  STRwirdbenoetigtfuer = "STRwirdbenoetigtfuer",
  STRwirdverwendetfuer = "STRwirdverwendetfuer",
  STRwirdverwendetvon = "STRwirdverwendetvon",
  STREPC = "STREPC",
  STRObjectModel = "STRObjectModel",
  STROrganigramm = "STROrganigramm",
  STRSachmittelmodell = "STRSachmittelmodell",
  STRWissenslandkarte = "STRWissenslandkarte",
  STRInformationsträgermodell = "STRInformationsträgermodell",
  STRWertschoepfungskette = "STRWertschoepfungskette",
  STRSYSTEM = "STRSYSTEM",
  STRUSER = "STRUSER",
  STRDIAGRAMS = "STRDIAGRAMS",
  STRMODEL = "STRMODEL",
  STRINCONTEXT = "STRINCONTEXT",
  STRASKCHATGTP = "STRASKCHATGTP",
  STRBACK = "STRBACK",
  STRCLEARANSWERS = "STRCLEARANSWERS",
  STRPROMPT = "STRPROMPT",
  STRQUESTION = "STRQUESTION",
  STRDRAGDROP = "STRDRAGDROP",
  STRCONTEXTFROMDIAG = "STRCONTEXTFROMDIAG",
  STRCONTEXTFROMMODEL = "STRCONTEXTFROMMODEL",
  STRCONTEXTFROMDIAGVISIBLE = "STRCONTEXTFROMDIAGVISIBLE",
  STRTEMPERATUR = "STRTEMPERATUR",
  STRSTART = "STRSTART",
  STRUNDO = "STRUNDO",
  STRREDO = "STRREDO",
  STRRectangle = "STRRectangle",
  STRCylinder = "STRCylinder",
  STREllipse = "STREllipse",
  STRDoubleEllipse = "STRDoubleEllipse",
  STRDataStore = "STRDataStore",
  STRCloud = "STRCloud",
  STRCrossbar = "STRCrossbar",
  STRRhombus = "STRRhombus",
  STRTriangle = "STRTriangle",
  STRUser = "STRUser",
  STRDLGWOPTCOMP = "STRDLGWOPTCOMP",
  STRSTYLE = "STRSTYLE",
  STRNAMESPACE = "STRNAMESPACE",
  STRPRINT = "STRPRINT",
  STROPEN = "STROPEN",
  STRTEXTFIELD = "STRTEXTFIELD",
  STRWidth = "STRWidth",
  STRHeight = "STRHeight",
  STRSize = "STRSize",
  STRFixedSize = "STRFixedSize",
  STRSYSTEMCLASS = "STRSYSTEMCLASS",
  STRLoginFailed = "STRLoginFailed",
  STRROLENOTALLOWED = "STRROLENOTALLOWED",
  STRLoginUserIsNotEMail = "STRLoginUserIsNotEMail",
  STRLoginPasswordnoMatch = "STRLoginPasswordnoMatch",
  STRLoginAccountCreated = "STRLoginAccountCreated",
  STRLoginAccountCreationFailed = "STRLoginAccountCreationFailed",
  STRLoginEmptyLoginToken = "STRLoginEmptyLoginToken",
  STRLoginNoDatabase = "STRLoginNoDatabase",
  STRUSERROLE = "STRUSERROLE",
  STRPRODKEY = "STRPRODKEY",
  STRCHANGEPWD = "STRCHANGEPWD",
  STROLDPWD = "STROLDPWD",
  STRNEWPWD = "STRNEWPWD",
  STRVERIFYPWD = "STRVERIFYPWD",
  STRDOCPROPERTIES = "STRDOCPROPERTIES",
  STRFORMAT = "STRFORMAT",
  STRNEWCLASS = "STRNEWCLASS",
  STRNEWINST = "STRNEWINST",
  STRWhite = "STRWhite",
  STRSilver = "STRSilver",
  STRGray = "STRGray",
  STRBlack = "STRBlack",
  STRLightblue = "STRLightblue",
  STRBlue = "STRBlue",
  STRNavy = "STRNavy",
  STRLightgreen = "STRLightgreen",
  STRDarkgreen = "STRDarkgreen",
  STRLightbrown = "STRLightbrown",
  STRGreen = "STRGreen",
  STRLightgray = "STRLightgray",
  STRLightyellow = "STRLightyellow",
  STRYellow = "STRYellow",
  STROrange = "STROrange",
  STRPaleOrange = "STRPaleOrange",
  STRSalmon = "STRSalmon",
  STRMaroon = "STRMaroon",
  STRPink = "STRPink",
  STRRed = "STRRed",
  STRPurple = "STRPurple",
  STRPAGESELECTOR = "STRPAGESELECTOR",
  STRMNUIMAGE = "STRMNUIMAGE",
  STRBORDER = "STRBORDER",
  STRATTACH = "STRATTACH",
  STRIMPORT = "STRIMPORT",
  STRDLGADDEXTOB = "STRDLGADDEXTOB",
  STRREPORT = "STRREPORT",
  STRDELETE = "STRDELETE",
  STRNEWLIST = "STRNEWLIST",
  STRNEWCOLUMN = "STRNEWCOLUMN",
  STRRIBBON = "STRRIBBON",
  STRTOOLBAR = "STRTOOLBAR",
  STRGUILANG = "STRGUILANG",
  STRSHOWTOOLBAR = "STRSHOWTOOLBAR",
  STRBREADCRUMBS = "STRBREADCRUMBS",
  STRQUICKSHAPES = "STRQUICKSHAPES",
  STRPANELSANDWINDOWS = "STRPANELSANDWINDOWS",
  STRPANELSONLY = "STRPANELSONLY",
  STRFONT = "STRFONT",
  STRFONTSIZE = "STRFONTSIZE",
  STRBOLD = "STRBOLD",
  STRITALIC = "STRITALIC",
  STRUNDERLINE = "STRUNDERLINE",
  STRWORDBREAK = "STRWORDBREAK",
  STRLABELMOVABLE = "STRLABELMOVABLE",
  STRDLGHEIGHT = "STRDLGHEIGHT",
  STRDLGWIDTH = "STRDLGWIDTH",
  STRRESIZE = "STRRESIZE",
  STRWELCOMESHOWINTRO = "STRWELCOMESHOWINTRO",
  STRWELCOMEOVERVIEW = "STRWELCOMEOVERVIEW",
  STRWELCOMECONTINUE = "STRWELCOMECONTINUE",
  STRWELCOMENEW = "STRWELCOMENEW",
  STRREFLEXIVELINKS = "STRREFLEXIVELINKS",
  STRAUTOEXTEND = "STRAUTOEXTEND",
  STRAUTOSCROLL ="STRAUTOSCROLL",
  STREXTENDPARENTSONMOVE = "STREXTENDPARENTSONMOVE",
  STREXTENDPARENTSONADD = "STREXTENDPARENTSONADD",
  STRRESIZECONTAINER = "STRRESIZECONTAINER",
  STRSPLITENABLED = "STRSPLITENABLED",
  STRAUTOSAVEREPO = "STRAUTOSAVEREPO",
  STRBPMN = "BPMN",
  STRBPMNSFMF = "STRBPMNSFMF",
  STRUNDERLINERF = "STRUNDERLINERF",
  STRVIEW = "STRVIEW",
  STRAUTOCOMPLETE = "STRAUTOCOMPLETE",
  STRSELRIBBONUSERDEF = "STRSELRIBBONUSERDEF",
  STRSQLDATABASE = "STRSQLDATABASE",
  STRSELECTDB = "STRSELECTDB",
  STRVERSIONCOUNT = "STRVERSIONCOUNT",
  STRCONNECTION = "STRCONNECTION",
  STRSITE = "STRSITE",
  STRAPPROVEDLIB = "STRAPPROVEDLIB",
  STRPASTE = "STRPASTE",
  STRNone = "STRNone",
  STRFlexArrow = "STRFlexArrow",
  STRLINK = "STRLINK",
  STRArrow = "STRArrow",
  STRSingleArrow = "STRSingleArrow",
  STRDoubleArrow = "STRDoubleArrow",
  STRStartArrow = "STRStartArrow",
  STREndArrow = "STREndArrow",
  STRClassic = "STRClassic",
  STRBlock = "STRBlock",
  STRBlockThin = "STRBlockThin",
  STROpen = "STROpen",
  STRDiamondThin = "STRDiamondThin",
  STROval = "STROval",
  STRDash = "STRDash",
  STRCircle = "STRCircle",
  STRCross = "STRCross",
  STRSHAPE = "STRSHAPE",
  STRPREDEFINEDSCHEMES = "STRPREDEFINEDSCHEMES",
  STREdgeStyle = "STREdgeStyle",
  STRSelectSth = "STRSelectSth",
  STRElbowVertical = "STRElbowVertical",
  STRDISABLEEDGESTYLE = "STRDISABLEEDGESTYLE",
  STRROUNDED = "STRROUNDED",
  STRCURVED = "STRCURVED",
  STRDASHED = "STRDASHED",
  STRAUTOSIZE = "Autosize",
  STRSHADOW = "STRSHADOW",
  STRDASHPATTERN = "STRDASHPATTERN",
  STRFONTCOLOR = "STRFONTCOLOR",
  STRFILLCOLOR = "STRFILLCOLOR",
  STRSWIMLANES = "STRSWIMLANES",
  STRGradient = "STRGradient",
  STROPACITY = "STROPACITY",
  STRSTROKE = "STRSTROKE",
  STRSTROKECOLOR = "STRSTROKECOLOR",
  STRLABELBACKGROUNDCOLOR = "STRLABELBACKGROUNDCOLOR",
  STRTEXT = "STRTEXT",
  STRALIGN = "STRALIGN",
  STRVERTICAL = "STRVERTICAL",
  STRPOSITION = "STRPOSITION",
  STRLABEL = "STRLABEL",
  STRDLGCMDCLEAR = "STRDLGCMDCLEAR",
  STRDLGLANG = "STRDLGLANG",
  STREMAIL = "STREMAIL",
  STRSELECTROLE = "STRSELECTROLE",
  STRVALIDITY = "STRVALIDITY",
  STRONLYMODEL = "STRONLYMODEL",
  STRONCEONLY = "STRONCEONLY",
  STRBOTTOMUP = "STRBOTTOMUP",
  STRCOMPOSEABLE = "STRCOMPOSEABLE",
  STRREFINABLE = "STRREFINABLE",
  STRHYPERLINKS = "STRHYPERLINKS",
  STRDLGBOPTSH = "STRDLGBOPTSH",
  STRDLGIDCOLUMN = "STRDLGIDCOLUMN",
  STRTYPE = "STRTYPE",
  STRUSER1 = "STRUSER1",
  STRUSER2 = "STRUSER2",
  STREXAMPLE = "STREXAMPLE",
  STRDEMO3 = "STRDEMO3",
  STRNEWSWIMLANE = "STRNEWSWIMLANE",
  STREDITSWIMLAND = "STREDITSWIMLAND",
  STRDELETESWIMLANE = "STRDELETESWIMLANE",
  STRUPLOADFILE = "STRUPLOADFILE",
  STRUPLOADEXCEL = "STRUPLOADEXCEL",
  STRTAXONOMY = "STRTAXONOMY",
  STRTAXONOMYTERMGROUP = "STRTAXONOMYTERMGROUP",
  STRTAXONOMYTERMSET = "STRTAXONOMYTERMSET",
  STRSITEBUILDER = "STRSITEBUILDER",
  STRAUTO = "STRAUTO",
  STRVERSIONSSPMESSAGE = "STRVERSIONSSPMESSAGE",
  STRVERSIONSEMPTY = "STRVERSIONSEMPTY",
  STRVERSIONCONTROLINFO = "STRVERSIONCONTROLINFO",
  STRPPTYPE = "STRPPTYPE",
  STRTOC = "STRTOC",
  STRPAGE = "STRPAGE",
  STRTABLE = "STRTABLE",
  STROBJECTS = "STROBJECTS",
  STRREPAIR = "STRREPAIR",
  STRDOCUMENTS = "Documents",
  STRSHARE = "STRSHARE",
  STRUserManager = "STRUserManager",
  STRSQLSERVER = "STRSQLSERVER",
  STRCOPY = "STRCOPY",
  STRINTRO0 = "STRINTRO0",
  STRLOADDB = "STRLOADDB",
  STRDLGBOPTLO = "STRDLGBOPTLO",
  STRREGISTER = "STRREGISTER",
  STRClearFormattingTitle = "STRClearFormattingTitle",
  STRParagraphSectionTitle = "STRParagraphSectionTitle",
  STRHYPERLINK = "STRHYPERLINK",
  STRFontStyleTitle = "STRFontStyleTitle",
  STRTEXTSTYLENORMAL = "STRTEXTSTYLENORMAL",
  STRTEXTSTYLEHEADERH2 = "STRTEXTSTYLEHEADERH2",
  STRTEXTSTYLEHEADERH3 = "STRTEXTSTYLEHEADERH3",
  STRTEXTSTYLEHEADERH4 = "STRTEXTSTYLEHEADERH4",
  STRTEXTSTYLEBLOCKQUOTE = "STRTEXTSTYLEBLOCKQUOTE",
  STRFontSizeTitle = "STRFontSizeTitle",
  STRStrikethroughTitle = "STRStrikethroughTitle",
  STRSuperscriptTitle = "STRSuperscriptTitle",
  STRSubscriptTitle = "STRSubscriptTitle",
  STRFontColorLabel = "STRFontColorLabel",
  STRAutomaticFontColor = "STRAutomaticFontColor",
  STRHighlightColorLabel = "STRHighlightColorLabel",
  STRNoColorHighlightColor = "STRNoColorHighlightColor",
  STRALIGNLEFT = "STRALIGNLEFT",
  STRALIGNCENTER = "STRALIGNCENTER",
  STRALIGNRIGHT = "STRALIGNRIGHT",
  STRAlignJustify = "STRAlignJustify",
  STRIncreaseIndentTitle = "STRIncreaseIndentTitle",
  STRDecreaseIndentTitle = "STRDecreaseIndentTitle",
  STRLISTBULLET = "STRLISTBULLET",
  STRLISTNUMBER = "STRLISTNUMBER",
  STRRemoveLinkLabel = "STRRemoveLinkLabel",
  STRFormattingPaneTitle = "STRFormattingPaneTitle",
  STRThemeColorsGroupName = "STRThemeColorsGroupName",
  STRHighlightColorsGroupName = "STRHighlightColorsGroupName",
  STRStandardColorsGroupName = "STRStandardColorsGroupName",
  STRCustomColorsGroupName = "STRCustomColorsGroupName",
  STRSOURCE = "STRSOURCE",
  STRTARGET = "STRTARGET",
  STRNOTEMPTY = "STRNOTEMPTY",
  STRBRODIAGNOTFND1 = "STRBRODIAGNOTFND1",
  STRCOMPUTER = "STRCOMPUTER",
  STRAPPLYFILE = "STRAPPLYFILE",
  STRRESET = "STRRESET",
  STRENABLEDISABLE = "STRENABLEDISABLE",
  STRFILEDOWNLOAD = "STRFILEDOWNLOAD",
  STRPUBLISHING = "STRPUBLISHING",
  STRMERGING = "STRMERGING",
  STRPUBFINISHED = "STRPUBFINISHED",
  STRPUBLISHINGACTIVE = "STRPUBLISHINGACTIVE",
  STRPUBLISHBG = "STRPUBLISHBG",
  STRSAVETODB = "STRSAVETODB",
  STRSAVETOLIB = "STRSAVETOLIB",
  STRUPLOADED = "STRUPLOADED",
  STRUPLOADEDERROR = "STRUPLOADEDERROR",
  STRPRINTFORMAT = "STRPRINTFORMAT",
  STRTITLE = "STRTITLE",
  STRKEYMANAGER = "STRKEYMANAGER",
  STRKEY = "STRKEY",
  STRNOKEYMANAGER = "STRNOKEYMANAGER",
  STRINVALIDKEY = "STRINVALIDKEY",
  STRFILEDELETED = "STRFILEDELETED",
  STRFILEDELETE = "STRFILEDELETE",
  STRINAPPROVAL = "STRINAPPROVAL",
  STRSUCCAPPROVEDLIST = "STRSUCCAPPROVEDLIST",
  STRSUCCAPPROVEDLIB = "STRSUCCAPPROVEDLIB",
  STRERRAPPROVAL = "STRERRAPPROVAL",
  STRERRAPPROVAL2 = "STRERRAPPROVAL2",
  STRKEYFEATURES = "STRKEYFEATURES",
  STRKEYACTIVEDAYS = "STRKEYACTIVEDAYS",
  STRKEYNOTUSEABLE = "STRKEYNOTUSEABLE",
  STRUNUSEDKEY = "STRUNUSEDKEY",
  STRLICENSEKEY = "STRLICENSEKEY",
  STRKEYASSIGNED = "STRKEYASSIGNED",
  STRKEYASSIGNED2 = "STRKEYASSIGNED2",
  STRDATABASETYPE = "STRDATABASETYPE",
  STRAPPROVEDLIBSPECIAL = "STRAPPROVEDLIBSPECIAL",
  STRPROFILE = "STRPROFILE",
  STRADDTOEXPORTCONNPROFILE = "STRADDTOEXPORTCONNPROFILE",
  STRDELTEFROMEXPORTCONNPROFILE = "STRDELTEFROMEXPORTCONNPROFILE",
  STRCONNNAMEEXISTS = "STRCONNNAMEEXISTS",
  STREXPORTCONN = "STREXPORTCONN",
  STREXPORTTOKEN = "STREXPORTTOKEN",
  STREXPORTCONNNAME ="STREXPORTCONNNAME",
  STRHEADERDELFROMFILE ="STRHEADERDELFROMFILE",
  STRHEADERFROMFILETEXT ="STRHEADERFROMFILETEXT",
  STRHEADERCHOOSE = "STRHEADERCHOOSE",
  STRHEADERFROMFILE = "STRHEADERFROMFILE",
  STRHEADERFROMCOOKIE = "STRHEADERFROMCOOKIE",
  STRHEADERSAVETODB = "STRHEADERSAVETODB",
  STRHEADERDELFROMDB = "STRHEADERDELFROMDB",
  STRHEADERADDNAME = "STRHEADERADDNAME",
  STRHEADERSAVED = "STRHEADERSAVED",
  STRHEADERDEL = "STRHEADERDEL",
  STRHEADERDBSAVED = "STRHEADERDBSAVED",
  STRHEADERDBDEL = "STRHEADERDBDEL",
  STRIMAGEMANAGER = "STRIMAGEMANAGER",
  STRIMGDELFROMFILE = "STRIMGDELFROMFILE",
  STRIMGDELFROMFILEMSG = "STRIMGDELFROMFILEMSG",
  STRSAVETOFILE = "STRSAVETOFILE",
  STRDELFROMFILE = "STRDELFROMFILE",
  STRDLGSIZE = "STRDLGSIZE",
  STRIMGMSG = "STRIMGMSG",
  STRIMGMSG2 = "STRIMGMSG2"
}
export enum ResIDL {
  STRELBOW = "STRELBOW",
  STRELINSWP = "STRELINSWP",
  STRELDELWP = "STRELDELWP",
  STRDLHTMLSEARCH = "STRDLHTMLSEARCH",
  STRDLHTMLOUTDIA = "STRDLHTMLOUTDIA",
  STRDLGDOC = "STRDLGDOC",
  STRDLGCMDCL = "STRDLGCMDCL",
  STRDLHTMLDIAGR = "STRDLHTMLDIAGR",
  STRDLGTABASS = "STRDLGTABASS",
  STRDLGTABATT = "STRDLGTABATT",
  STRDLGTABMET = "STRDLGTABMET",
  STRDLGTABSTA = "STRDLGTABSTA",
  STRDLGTABTRIGGER = "STRDLGTABTRIGGER",
  STRDLGEXPDEF = "STRDLGEXPDEF",
  STRATTRTYPE = "STRATTRTYPE",
  STRASSOCTYPE = "STRASSOCTYPE",
  STRDIAGTYPE = "STRDIAGTYPE",
  STRREPORT = "STRREPORT",
  STRVERSIONCONTROL = "STRVERSIONCONTROL",
  STRDLHTMLPROPS = "STRDLHTMLPROPS",
  STRSELECTSL = "STRSELECTSL",
  STREXPAND = "STREXPAND",
  STRDLGINSCAPTION = "STRDLGINSCAPTION",
  STRCOMPOSE = "STRCOMPOSE",
  STRTRANSLATE = "STRTRANSLATE",
  STRBONHRAC = "STRBONHRAC",
  STRPRINT = "STRPRINT",
  STRDLGTABBKPG = "STRDLGTABBKPG",
  STRSTENCIL = "STRSTENCIL",
  STRDLGLMENU = "STRDLGLMENU",
  STRRENAME = "STRRENAME",
  STRWRONGWORKSPACE = "STRWRONGWORKSPACE",
  STRSTARTINGFROMSCRATCH = "STRSTARTINGFROMSCRATCH",
  STRCOULDNOTOPEN = "STRCOULDNOTOPEN",
  STRDLGCMDRE = "STRDLGCMDRE",
  STRDLGCMDOP = "STRDLGCMDOP",
  STRDLGCMDDE = "STRDLGCMDDE",
  STRDLGCMDNE = "STRDLGCMDNE",
  STRDLGCMDED = "STRDLGCMDED",
  STRBROWSE = "STRBROWSE",
  STRDLGIMPORT = "STRDLGIMPORT",
  STRDLGMERGE = "STRDLGMERGE",
  STRDLGAUDIT1 = "STRDLGAUDIT1",
  STRDLGAUDIT2 = "STRDLGAUDIT2",
  STRDLGAUDIT3 = "STRDLGAUDIT3",
  STRDLGAUDIT4 = "STRDLGAUDIT4",
  STRSAPEXCELVER = "STRSAPEXCELVER",
  STRSTATE = "STRSTATE",
  STRDOMAIN = "STRDOMAIN",
  STRCOMMENT = "STRCOMMENT",
  STRDLGCMDTMP = "STRDLGCMDTMP",
  STRSAVEAS = "STRSAVEAS",
  STRNAME = "STRNAME",
  STRDLHTMLOUTCLA = "STRDLHTMLOUTCLA",
  STRBONNUM = "STRBONNUM",
  STRFILEEXISTS = "STRFILEEXISTS",
  STRDELETEDOCUMENT = "STRDELETEDOCUMENT",
  STRDELETEDIAGRAM = "STRDELETEDIAGRAM",
  STRBONSIMMMCONST = "STRBONSIMMMCONST",
  STRBONSIMMMEAVEN = "STRBONSIMMMEAVEN",
  STRBONSIMMMNORMA = "STRBONSIMMMNORMA",
  STRBONSIMMMEPONE = "STRBONSIMMMEPONE",
  STRBONSIMERLANG = "STRBONSIMERLANG",
  STRDLHTMLOUTSUP = "STRDLHTMLOUTSUP",
  STRDLHTMLOUTSUB = "STRDLHTMLOUTSUB",
  STRDLHTMLOUTINS = "STRDLHTMLOUTINS",
  STRDLGTABSYN = "STRDLGTABSYN",
  STRGERMAN = "STRGERMAN",
  STRENGLISH = "STRENGLISH",
  STRSPANISH = "STRSPANISH",
  STRJAPANESE = "STRJAPANESE",
  STRRUSSIAN = "STRRUSSIAN",
  STRFRENCH = "STRFRENCH",
  STSIMPLIFIEDCHINESE = "STSIMPLIFIEDCHINESE",
  STTRADITIONALCHINESE = "STTRADITIONALCHINESE",
  STRNOLANGUAGE = "STRNOLANGUAGE",
  STRERREXISTS = "STRERREXISTS",
  SRUNIFY1 = "SRUNIFY1",
  STRRELATION = "STRRELATION",
  STRINPROGRESS = "STRINPROGRESS",
  STRINAPPROVAL = "STRINAPPROVAL",
  STRINPUBLISHING = "STRINPUBLISHING",
  STRPUBLISHED = "STRPUBLISHED",
  STRINREVIEW = "STRINREVIEW",
  STRCOMPLETED = "STRCOMPLETED",
  STRDLGNASAAS = "STRDLGNASAAS",
  STRDLGNASTYP = "STRDLGNASTYP",
  STROBJSING = "STROBJSING",
  STRDLGCMDOK = "STRDLGCMDOK",
  STRDLGCMDCA = "STRDLGCMDCA",
  STRDLGASSOCAPTION = "STRDLGASSOCAPTION",
  STRDLGRELTYPEAGGRE = "STRDLGRELTYPEAGGRE",
  STRDLGRELTYPEINVN = "STRDLGRELTYPEINVN",
  STRDLGRELTYPESYMET = "STRDLGRELTYPESYMET",
  STRDLGRELTYPETRANS = "STRDLGRELTYPETRANS",
  STRDLGRELTYPECYCLE = "STRDLGRELTYPECYCLE",
  STRDLGRELTYPEMULTI = "STRDLGRELTYPEMULTI",
  STRDLGRELTYPEUNIQUE = "STRDLGRELTYPEUNIQUE",
  STRDLGRELTYPECONT = "STRDLGRELTYPECONT",
  STRDLGEXPCAPTION = "STRDLGEXPCAPTION",
  STRDLGALLOWFREEFORMATTR = "STRDLGALLOWFREEFORMATTR",
  STRDLGLCAPTION = "STRDLGLCAPTION",
  STRDLHTMLOUTLIN = "STRDLHTMLOUTLIN",
  STRDLGATTADDR = "STRDLGATTADDR",
  STRDLGCLATTTYP = "STRDLGCLATTTYP",
  STRDLGATTLABEL = "STRDLGATTLABEL",
  STRBONSIMCAPHOUR = "STRBONSIMCAPHOUR",
  STRBONSIMCAPMINU = "STRBONSIMCAPMINU",
  STRBONSIMCAPSECO = "STRBONSIMCAPSECO",
  STRDLGTABVAL = "STRDLGTABVAL",
  STRBONATTR1 = "STRBONATTR1",
  STRDLGEAVALUE = "STRDLGEAVALUE",
  STRLIST = "STRLIST",
  STRDLGEATYPE = "STRDLGEATYPE",
  STRDLGOWLMIN = "STRDLGOWLMIN",
  STRDLGOWLMAX = "STRDLGOWLMAX",
  STRDLGEACMDOPT = "STRDLGEACMDOPT",
  STRDLGREQUIREDATTR = "STRDLGREQUIREDATTR",
  STRDLGCLLAYOUTATTRGRP = "STRDLGCLLAYOUTATTRGRP",
  STRDLGCLLAYOUTWEIGHT = "STRDLGCLLAYOUTWEIGHT",
  STRPANEL = "STRPANEL",
  STRPANELCUSTOM = "STRPANELCUSTOM",
  STRPANELFLUID = "STRPANELFLUID",
  STRPANELSMALL = "STRPANELSMALL",
  STRPANELMEDIUM = "STRPANELMEDIUM",
  STRPANELLARGE = "STRPANELLARGE",
  STRPANELXLARGE = "STRPANELXLARGE",
  STRPANELLEFT = "STRPANELLEFT",
  STRDLGEDMETHCAPTION = "STRDLGEDMETHCAPTION",
  STRBONSTATE1 = "STRBONSTATE1",
  STRDIALOG = "STRDIALOG",
  STRBONDETAILS = "STRBONDETAILS",
  STRBONROOT = "STRBONROOT",
  STRDLGFINDCLASS = "STRDLGFINDCLASS",
  STRDLGINSERTINST = "STRDLGINSERTINST",
  STRDLGNEWSWIMLANES = "STRDLGNEWSWIMLANES",
  STRDLGINHERITANCE = "STRDLGINHERITANCE",
  STRDLGMMRELATIONS = "STRDLGMMRELATIONS",
  STRDLGAUTO = "STRDLGAUTO",
  STRUMLSHAPE = "STRUMLSHAPE",
  STRDLGPREFIX = "STRDLGPREFIX",
  STRBONCONDITION = "STRBONCONDITION",
  STRDLGTABAUD = "STRDLGTABAUD",
  STRDLGSPDOM = "STRDLGSPDOM",
  STRCHECKEDOUTTO = "STRCHECKEDOUTTO",
  STRFIND = "STRFIND",
  STRCUSTCOLUMNS = "STRCUSTCOLUMNS",
  STRDLGLANG = "STRDLGLANG",
  STRDLGTABREF = "STRDLGTABREF",
  STRDLGFINDATTACH = "STRDLGFINDATTACH",
  STRBACKGROUNDID = "STRBACKGROUNDID",
  STRBACKGROUNDORIENT = "STRBACKGROUNDORIENT",
  STRBACKGROUNDSECTION = "STRBACKGROUNDSECTION",
  STRDLGVISIBLE = "STRDLGVISIBLE",
  STRDLGEXPORT = "STRDLGEXPORT",
  STRBACKGROUNDDELETE = "STRBACKGROUNDDELETE",
  STRBACKGROUNDFOOTER = "STRBACKGROUNDFOOTER",
  STRBACKGROUNDHEADER = "STRBACKGROUNDHEADER",
  STRBACKGROUNDROOTELEMENTS = "STRBACKGROUNDROOTELEMENTS",
  STRBACKGROUNDXPOS = "STRBACKGROUNDXPOS",
  STRBACKGROUNDYPOS = "STRBACKGROUNDYPOS",
  STRCONVERTER = "STRCONVERTER",
  STRDLGMERGEINFO = "STRDLGMERGEINFO",
  STRDLGCLSUPERCL = "STRDLGCLSUPERCL",
  STRINVISIBLE = "STRINVISIBLE",
  STRDLGBROWSEINS = "STRDLGBROWSEINS",
  STRDLGMASTER = "STRDLGMASTER",
  STRDLGNAM = "STRDLGNAM",
  STRDLGDIAGDCLASS = "STRDLGDIAGDCLASS",
  STRDLGEXPSUP = "STRDLGEXPSUP",
  STRDLGEXPSUB = "STRDLGEXPSUB",
  STRDLGLNNSP = "STRDLGLNNSP",
  STRCLASSLABELSYN = "STRCLASSLABELSYN",
  STRDLGCOM = "STRDLGCOM",
  STRDLGLIN = "STRDLGLIN",
  STRDLGLPOST = "STRDLGLPOST",
  STRDLGUNUMBERATFRONT = "STRDLGUNUMBERATFRONT",
  STRNAMEANDUSERNUMBER = "STRNAMEANDUSERNUMBER",
  STRUSERNUMBERANDNAME = "STRUSERNUMBERANDNAME",
  STRDLGCLLAYOUTSHOWLBL = "STRDLGCLLAYOUTSHOWLBL",
  STRDLGLCLSA = "STRDLGLCLSA",
  STWFSTRDESTL = "STWFSTRDESTL",
  STRDLGFINDNAM = "STRDLGFINDNAM",
  STRTRUE = "STRTRUE",
  STRFALSE = "STRFALSE",
  STRFORMATLANDSCAPE = "STRFORMATLANDSCAPE",
  STRFORMATDRAWINGSIZE = "STRFORMATDRAWINGSIZE",
  STRGOUP = "STRGOUP",
  STRPREVIOUS = "STRPREVIOUS",
  STRREADOUT = "STRREADOUT",
  STRCONTINUE = "STRCONTINUE",
  STRREFINE = "STRREFINE",
  STRBROWSERHIERACHY = "STRBROWSERHIERACHY",
  STRDLGTABGEN = "STRDLGTABGEN",
  STRPLANNERSTATUSFINDINGPLAN = "STRPLANNERSTATUSFINDINGPLAN",
  STRPLANNERSTATUSLOADINGPROCESS = "STRPLANNERSTATUSLOADINGPROCESS",
  STRPLANNERSTATUSLOADINGTASKS = "STRPLANNERSTATUSLOADINGTASKS",
  STRPLANNERSTATUSREFRESHING = "STRPLANNERSTATUSREFRESHING",
  STRPLANNERSTATUSCREATINGPLAN = "STRPLANNERSTATUSCREATINGPLAN",
  STRPLANNERSTATUSCREATINGPROCESS = "STRPLANNERSTATUSCREATINGPROCESS",
  STRPLANNERSTATUSCREATINGTASKS = "STRPLANNERSTATUSCREATINGTASKS",
  STRPLANNERSTATUSLINKING = "STRPLANNERSTATUSLINKING",
  STRPLANNERSTATUSCREATINGTAB = "STRPLANNERSTATUSCREATINGTAB",
  STRTEAMSCHANNEL = "STRTEAMSCHANNEL",
  STRDLGCLNEWSTATE1 = "STRDLGCLNEWSTATE1",
  STRDIALOGPAGE = "STRDIALOGPAGE",
  STRUSERTASKONLY = "STRUSERTASKONLY",
  STRSHOWPAPERCLIP = "STRSHOWPAPERCLIP",
  STRSCALE = "STRSCALE",
  STRQMOUTPUT = "STRQMOUTPUT",
  STRQMINPUT = "STRQMINPUT",
  STRDLHTMLOUTEXT = "STRDLHTMLOUTEXT",
  STRDLHTMLEXTERNAL = "STRDLHTMLEXTERNAL",
  STRBBPADD = "STRBBPADD",
  STRDELETEOBJ = "STRDELETEOBJ",
  STRIMPORTDEFAULTREPOSITORY = "STRIMPORTDEFAULTREPOSITORY",
  STRDLGLLAYRESET = "STRDLGLLAYRESET",
  STRANCHORPANZOOM = "STRANCHORPANZOOM",
  STRANCHORSTENCIL = "STRANCHORSTENCIL",
  STRANCHORPIVOT = "STRANCHORPIVOT",
  STRDOCKNAVIGATOR = "STRDOCKNAVIGATOR",
  STRPLANNERINTEGRATION = "STRPLANNERINTEGRATION",
  STRDOCKPLANNER = "STRDOCKPLANNER",
  STRDIALOGVIEW = "STRDIALOGVIEW",
  STREDITDIALOGWIDTH = "STREDITDIALOGWIDTH",
  STRAUTOREFRESH = "STRAUTOREFRESH",
  STRBBPEXCELP = "STRBBPEXCELP",
  STRBPMNSTRONG = "STRBPMNSTRONG",
  STRBPMNLIGHT = "STRBPMNLIGHT",
  STRBPMNRULES = "STRBPMNRULES",
  STRSYMBOLS = "STRSYMBOLS",
  STRMULTPILEENTR = "STRMULTPILEENTR",
  STRSIMULATIONCHANGETASKCOLOR = "STRSIMULATIONCHANGETASKCOLOR",
  STRBONFLOW = "STRBONFLOW",
  STRDLGCURRLANG = "STRDLGCURRLANG",
  STRGUIFONT = "STRGUIFONT",
  STRICONCOLOR = "STRICONCOLOR",
  STRDLGTHEME = "STRDLGTHEME",
  STRCOPYDATABASE = "STRCOPYDATABASE",
  STRBONLBLCOP = "STRBONLBLCOP",
  STRBBPEXCELPS = "STRBBPEXCELPS",
  STREMBED = "STREMBED",
  STRBBPPOIDIA = "STRBBPPOIDIA",
  STRPREDEFINEDMAIL = "STRPREDEFINEDMAIL",
  STRFREEMAIL = "STRFREEMAIL",
  STR_Start = "STR_Start",
  STR_Stop = "STR_Stop",
  STRBONSIMRESTART = "STRBONSIMRESTART",
  STRBONSTATE = "STRBONSTATE",
  STRDLHTMLOUTSYN = "STRDLHTMLOUTSYN",
  STRDLGREA = "STRDLGREA",
  STRDLGHIDE = "STRDLGHIDE",
  STRDLGSEMIMPALL = "STRDLGSEMIMPALL",
  STRDLGMETAMOD = "STRDLGMETAMOD",
  STRNODES = "STRNODES",
  STRTABLEEDITORPASTECOLUMN = "STRTABLEEDITORPASTECOLUMN",
  STRDLHTMLFROM = "STRDLHTMLFROM",
  STRDLHTMLTO = "STRDLHTMLTO",
  STRDLGFINDINST = "STRDLGFINDINST",
  STRSUBCLASSES = "STRSUBCLASSES",
  STRDLGFINDOBJ = "STRDLGFINDOBJ",
  STRDLHTMLTABS = "STRDLHTMLTABS",
  STRDLGCMDEV = "STRDLGCMDEV",
  STRDLGLTABS = "STRDLGLTABS",
  STRBONPECAPNAME = "STRBONPECAPNAME",
  STRDLGINVERSE = "STRDLGINVERSE",
  STRDLGRELTYPERANGE = "STRDLGRELTYPERANGE",
  STRSELECTMASTER = "STRSELECTMASTER",
  STRDLGLSHAPES = "STRDLGLSHAPES",
  STRBONLBLHR = "STRBONLBLHR",
  STRCOLLAPSE = "STRCOLLAPSE",
  STRPLANNER = "STRPLANNER",
  STRDLGATTPROP = "STRDLGATTPROP",
  STRLIBRARY = "STRLIBRARY",
  STRTHINKINGABOUT = "STRTHINKINGABOUT",
  STRSTATISTICS = "STRSTATISTICS",
  STRBONSIMMMVAL = "STRBONSIMMMVAL",
  STRPRIVACY = "STRPRIVACY",
  STRIMPRESSUM = "STRIMPRESSUM",
  STRDLGFINDMET = "STRDLGFINDMET",
  STRAFFILIATION = "STRAFFILIATION",
  STROFFTIME = "STROFFTIME",
  STRSIMSTARTDATE = "STRSIMSTARTDATE",
  STRSIMSTARTTIME = "STRSIMSTARTTIME",
  STRSIMACTIVE = "STRSIMACTIVE",
  STRSIMSUNDAY = "STRSIMSUNDAY",
  STRSIMMONDAY = "STRSIMMONDAY",
  STRSIMTUESDAY = "STRSIMTUESDAY",
  STRSIMWEDNESDAY = "STRSIMWEDNESDAY",
  STRSIMTHURSDAY = "STRSIMTHURSDAY",
  STRSIMFRIDAY = "STRSIMFRIDAY",
  STRSIMSATURDAY = "STRSIMSATURDAY",
  STRSIMTIMEOFDAY = "STRSIMTIMEOFDAY",
  STRSIMLOG = "STRSIMLOG",
  STRSIMAGENDA = "STRSIMAGENDA",
  STRSIMJOBS = "STRSIMJOBS",
  STRSIMJOB = "STRSIMJOB",
  STRSIMCHARTS = "STRSIMCHARTS",
  STRSIMTIME = "STRSIMTIME",
  STRSIMEVENT = "STRSIMEVENT",
  STRSIMOBJECTS = "STRSIMOBJECTS",
  STRSIMTOTAL = "STRSIMTOTAL",
  STRSIMBUFFERCONTENT = "STRSIMBUFFERCONTENT",
  STRSIMWAITING = "STRSIMWAITING",
  STRSIMDONE = "STRSIMDONE",
  STRSIMRUNNING = "STRSIMRUNNING",
  STRSIMUSING = "STRSIMUSING",
  STRSIMRELEASING = "STRSIMRELEASING",
  STRSIMREMOVING = "STRSIMREMOVING",
  STRSIMREADING = "STRSIMREADING",
  STRSIMSTORING = "STRSIMSTORING",
  STRSIMJOIN = "STRSIMJOIN",
  STRSIMRESSOURCE = "STRSIMRESSOURCE",
  STRSIMENTRY = "STRSIMENTRY",
  STRSIMSTATE = "STRSIMSTATE",
  STRSIMIDLE = "STRSIMIDLE",
  STRSIMRESSOURCES = "STRSIMRESSOURCES",
  STRSIMSYSTEMS = "STRSIMSYSTEMS",
  STRSIMREQUEST = "STRSIMREQUEST",
  STRSIMREQUESTS = "STRSIMREQUESTS",
  STRSIMUSAGE = "STRSIMUSAGE",
  STRSIMAMOUNT = "STRSIMAMOUNT",
  STRSIMCOST = "STRSIMCOST",
  STRASSIGNMENT = "STRASSIGNMENT",
  STRSIMPROCSTATE = "STRSIMPROCSTATE",
  STRSITE = "STRSITE",
  STRDLGADDEXTFOLDER = "STRDLGADDEXTFOLDER",
  STRSINGLEDOCUMENTLIB = "STRSINGLEDOCUMENTLIB",
  STRLAYOUT = "STRLAYOUT",
  STRCOMPACTTREEHORIZONTAL = "STRCOMPACTTREEHORIZONTAL",
  STRCOMPACTTREEVERTICAL = "STRCOMPACTTREEVERTICAL",
  STRCOMPACTTREEHINERVSE = "STRCOMPACTTREEHINERVSE",
  STRCOMPACTTREEVINVERSE = "STRCOMPACTTREEVINVERSE",
  STRORGANIC = "STRORGANIC",
  STRRADIALTREE = "STRRADIALTREE",
  STRCIRCLE = "STRCIRCLE",
  STRCREATEREPOSITORY = "STRCREATEREPOSITORY",
  STRDLGATTCAPTION = "STRDLGATTCAPTION",
  STRBPMNTaskType = "STRBPMNTaskType",
  STRBPMNInstantiate = "STRBPMNInstantiate",
  STRBPMNImplementation = "STRBPMNImplementation",
  STRBPMNadhoc = "STRBPMNadhoc",
  STRBPMNCompensation = "STRBPMNCompensation",
  STRBPMNSubProcessOrdering = "STRBPMNSubProcessOrdering",
  STRBPMNScript = "STRBPMNScript",
  STRBPMNTriggerType = "STRBPMNTriggerType",
  STRBPMNIntermediateType = "STRBPMNIntermediateType",
  STRBPMNGatewayType = "STRBPMNGatewayType",
  STRUPDATINGLANG = "STRUPDATINGLANG",
  STRDLGEASELECTSTFILE = "STRDLGEASELECTSTFILE",
  STRTOOLS = "STRTOOLS"
}
