import {
  IObjectBase,
  ISemTalkDiagramType,
  ISemTalkSystemClass,
  ISemTalkObject,
  ISemTalkClass,
  ISemTalkInstance,
  ISemTalkDiagram,
  ISemTalkNode,
  ISemTalkAssociation,
  ISemTalkAssociationNode,
  SemTalkType,
  SemTalkRelation,
  SemTalkBaseConstant,
  ModelAttribute,
  SemTalkID,
  // ISemTalkNamedThing
} from './Interface';
import { ObjectBase } from './ObjectBase';


import { SemTalkInstance } from './Tbase';
import { SemTalkSystemClass } from './SystemClass';
import { SemTalkMaster } from '../SemTalkMaster';
import { round } from 'lodash';
import { SemTalkStencil } from '../semtalklistener/visiordfsinterface';

// tslint:disable:max-classes-per-file
// tslint:disable:variable-name
/* tslint:disable:member-ordering */
/* tslint:disable:forin */

export class SemTalkDiagDialog {
  public CheckEdit: boolean = false;
  public CheckInsert: boolean = false;
  public CheckFind: boolean = false;
  public CheckGoUp: boolean = false;
  public CheckRefresh: boolean = false;
}
export class SemTalkDiagramType extends SemTalkSystemClass implements ISemTalkDiagramType {
  constructor(tb: IObjectBase, newname: string, id?: SemTalkID | null | undefined) {
    super(tb, newname, id);
    this.ObjectType = SemTalkType.SemTalkDiagramType;
    if (tb.FindDiagramType(newname) === null) {
      tb._diagtypes[this.ID + "_"] = this;
      this.ObjectBase.OnCreated(this);
    }
  }
  public LocalDiagramDialog: SemTalkDiagDialog = new SemTalkDiagDialog();
  public Root: ISemTalkSystemClass | null = null;
  public InstanceRoot: ISemTalkSystemClass | null = null;
  public NewSLRoot: ISemTalkSystemClass | null = null;
  public UseInheritanceRelations: boolean = true;
  public UseAllRelationsOfSubclasses: boolean = true;

  public MasterNames: string[] = [SemTalkMaster.MasterInstance, SemTalkMaster.MasterComment,
  SemTalkMaster.MasterProperty];
  private _isclass: boolean = false;
  public set IsClass(b: boolean) {
    if (b) {
      if (this.MasterNames.indexOf(SemTalkMaster.MasterInstance) > -1) {
        this.MasterNames[this.MasterNames.indexOf(SemTalkMaster.MasterInstance)] = SemTalkMaster.MasterClass;
      }
    } else {
      if (this.MasterNames.indexOf(SemTalkMaster.MasterClass) > -1) {
        this.MasterNames[this.MasterNames.indexOf(SemTalkMaster.MasterClass)] = SemTalkMaster.MasterInstance;
      }
    }
    this._isclass = b;
  }
  public get IsClass(): boolean { return this._isclass; }

  public Auto: boolean = false;
  public Stencil: string | null = null;
  public CustomStencil: SemTalkStencil = [];
  public DiagramTypePrefix: string | null = null;
  public DoubleClick: string | null = null;
  public UMLShape: boolean = false;
  public Delete(): void {
    super.Delete();
    delete this.ObjectBase._diagtypes[this.ID + "_"];
    this.ObjectBase.OnDiagramTypeDeleted(this);
  }
  // public DiagramTypePrefix(o: ISemTalkNamedThing): string {
  //   if (this._prefix !== null) { return this._prefix + "#" + o.ObjectName; }
  //   // if (this.Root !== null) { return this.Root.GetPrefix() + "#" + o.ObjectName; }
  //   return o.ObjectName;
  // }
  public IsAllowedMaster(o: ISemTalkObject, master: string): boolean {
    if (this.MasterNames.indexOf(master) < 0) {
      if (this.IsClass && master === SemTalkMaster.MasterUMLClass) { return this.UMLShape; }
      return false;
    }
    if (this.IsClass && master === SemTalkMaster.MasterClass) { return !this.UMLShape; }
    let sc: ISemTalkSystemClass | null = null;
    if (this.ObjectBase.IsInstance(o)) {
      if (master === SemTalkMaster.MasterInstance) {
        if (this.Root === null) {
          return true;
        } else {
          return (o as ISemTalkInstance).IsInstance(this.Root);
        }
      }
      sc = (o as ISemTalkInstance).ClassOf().SystemClass();
    }
    if (this.ObjectBase.IsClass(o)) { sc = (o as ISemTalkClass).SystemClass(); }
    if (sc !== null) {

      const lbl = sc.Labels(master);
      if (lbl.length === 0) {
        return false;
      }

    } else {
      return false;
    }
    return true;
  }
  public AllowedMasters(o: ISemTalkObject): string[] {
    const m: string[] = [];
    if (!this.IsAllowed(o)) { return m; }
    let sc: ISemTalkSystemClass | null = null;
    if (this.ObjectBase.IsInstance(o)) {
      sc = (o as ISemTalkInstance).ClassOf().SystemClass();
    }
    if (this.ObjectBase.IsClass(o)) {
      sc = (o as ISemTalkClass).SystemClass();
    }
    if (this.IsClass) {
      //if (sc !== null) {
      for (const l of (sc as ISemTalkSystemClass).AllClassLabels()) {
        if (this.MasterNames.indexOf(l.Master) > -1) {
          m[m.length] = l.Master;
        }
      }
      // }
      // console.debug("MasterNames " + this.UMLShape + " " + this.MasterNames.indexOf("UML Class"));
      if (this.UMLShape && this.MasterNames.indexOf(SemTalkMaster.MasterUMLClass) !== -1) {
        m[m.length] = SemTalkMaster.MasterUMLClass;
      } else {
        if (this.MasterNames.indexOf(SemTalkMaster.MasterClass) !== -1) {
          m[m.length] = SemTalkMaster.MasterClass;
        }
      }
    } else {
      if (sc !== null) {
        for (const l of sc.AllInstanceLabels()) {
          if (this.MasterNames.indexOf(l.Master) > -1) {
            m[m.length] = l.Master;
          }
        }
      }
      if (this.MasterNames.indexOf("Instance") > -1) {
        m[m.length] = "Instance";
      }
    }
    return m;
  }
  public IsAllowed(o: ISemTalkObject): boolean {
    if (this.ObjectBase._loading) return true;
    if (this.ObjectName === SemTalkBaseConstant.SLGeneric) {
      return true;
    }
    if (this.IsClass) {
      if (this.ObjectBase.IsClass(o)) {
        if (this.Root !== null) {
          return (o as ISemTalkClass).IsParent(this.Root);
        } else return true;
      } else {
        //if (this.ObjectBase.IsInstance(o)) {
        if (this.InstanceRoot !== null && this.ObjectBase.IsInstance(o)) {
          return (o as ISemTalkInstance).IsInstance(this.InstanceRoot);
        } else
          return false;
        // } else
        // return false;
      }
    } else {
      if (this.ObjectBase.IsInstance(o)) {
        if (this.Root !== null) {
          // if (o.ObjectType === SemTalkType.SemTalkAssociation) {
          //   // hidden Relations not implemented yet
          //   return true;
          // } else
          return (o as ISemTalkInstance).IsInstance(this.Root);
        } else return true;
      } else return false;
    }
  }
  public FindMasters(mst: string): ISemTalkSystemClass[] {
    const m: ISemTalkSystemClass[] = [];
    // tslint:disable-next-line:no-empty
    if (this.IsClass) {
    } else {
      if (this.Root !== null) {
        let sl = this.Root.AllSystemSubClasses();
        sl.push(this.Root);
        for (const sc of sl) {
          if (sc.ObjectName === mst) {
            m[m.length] = sc;
            return m;
          }
          if (sc.Labels(mst).length > 0) {
            m[m.length] = sc;
          } else {
            for (const sy of sc.AllSynonyms()) {
              if (sy.Name === mst && m.indexOf(sc) < 0) {
                m[m.length] = sc;
              }
            }
          }
        }

      }
    }
    return m;
  }
  public Instances(): ISemTalkDiagram[] {
    const alist: ISemTalkDiagram[] = [];
    for (const x of super.Instances()) {
      alist.push(x as ISemTalkDiagram);
    }
    return alist;
  }
  public SubClasses(): ISemTalkDiagramType[] {
    const alist: ISemTalkDiagramType[] = [];
    for (const x of super.SubClasses()) {
      alist.push(x as ISemTalkDiagramType);
    }
    return alist;
  }
  public SuperClasses(): ISemTalkDiagramType[] {
    const alist: ISemTalkDiagramType[] = [];
    for (const x of super.SuperClasses()) {
      alist.push(x as ISemTalkDiagramType);
    }
    return alist;
  }
  public MakeInstance(n: string, t?: SemTalkType, i?: SemTalkID): ISemTalkDiagram {
    if (t === undefined) {
      t = SemTalkType.SemTalkDiagram;
    }
    let existing = this.ObjectBase.FindDiagram(n);
    if (existing !== null) {
      return existing;
    } else {
      return super.MakeInstance(n, t, i, (cla1: ISemTalkClass, n1: string, t1: SemTalkType, i1: SemTalkID) =>
        new SemTalkDiagram(cla1 as ISemTalkDiagramType, n1, t1, i1)) as ISemTalkDiagram;
    }
  }
  public GetValidDiagTypRefinementOf(): ISemTalkDiagramType[] {
    return this.LinkedObjects("RefinementOf", false, SemTalkRelation.SemTalkSystemRelation) as ISemTalkDiagramType[];
  }
  public GetValidDiagTypRefinements(): ISemTalkDiagramType[] {
    return this.InvLinkedObjects("RefinementOf", false, SemTalkRelation.SemTalkSystemRelation) as ISemTalkDiagramType[];
  }
  public AllRootDiagrams(): ISemTalkDiagram[] {
    const alist: ISemTalkDiagram[] = [];
    if (this.IsClass || this.ObjectName === SemTalkBaseConstant.SLGeneric) {
      for (const x of this.Instances()) {
        alist.push(x as ISemTalkDiagram);
      }
    } else {
      for (const x of this.Instances()) {
        if ((x as ISemTalkDiagram).InvRefinements().length === 0) {
          alist.push(x as ISemTalkDiagram);
        }
      }
    }
    return alist;
  }
  public Load(je: any): void {
    super.Load(je);
    if (je.root && this.ObjectBase.FindSystemClass(je.root) !== null) {
      this.Root = this.ObjectBase.FindSystemClass(je.root);
      // console.debug(this.Root);
    }
    if (this.ObjectBase.FindSystemClass(je.instanceroot) !== null) { this.InstanceRoot = this.ObjectBase.FindSystemClass(je.instanceroot); }
    if (this.ObjectBase.FindSystemClass(je.slroot) !== null) { this.NewSLRoot = this.ObjectBase.FindSystemClass(je.slroot); }
    this.UseInheritanceRelations = (je.UseInheritanceRelations !== "0");
    this.UseAllRelationsOfSubclasses = (je.UseAllRelationsOfSubclasses !== "0");
    this.IsClass = (je.type === "Class");
    this.Auto = (je.auto === "1");
    this.Stencil = je.stencil;
    if (je.customstencil) {
      this.CustomStencil = je.customstencil;
    }
    this.DiagramTypePrefix = je.prefix;
    this.DoubleClick = je.doubleclick;
    this.UMLShape = (je.UMLShape === "1");
    if (je.CheckEdit !== undefined) { this.LocalDiagramDialog.CheckEdit = je.CheckEdit; }
    if (je.CheckInsert !== undefined) { this.LocalDiagramDialog.CheckInsert = je.CheckInsert; }
    if (je.CheckFind !== undefined) { this.LocalDiagramDialog.CheckFind = je.CheckFind; }
    if (je.CheckGoUp !== undefined) { this.LocalDiagramDialog.CheckFind = je.CheckGoUp; }
    if (je.CheckRefresh !== undefined) { this.LocalDiagramDialog.CheckFind = je.CheckRefresh; }
  }
  public Save(par: any[]): any {
    const el: any = super.Save(par);
    el.ObjectType = "DiagramType";

    if (this.Root !== null) { el.root = this.Root.ObjectName; }
    if (this.InstanceRoot !== null) { el.instanceroot = this.InstanceRoot.ObjectName; }
    if (this.NewSLRoot !== null) { el.slroot = this.NewSLRoot.ObjectName; }
    if (this.UseInheritanceRelations) { el.UseInheritanceRelations = "1"; }
    if (this.IsClass) { el.type = "Class"; } else { el.type = "Instance"; }
    if (this.Auto) { el.auto = "1"; }
    el.stencil = this.Stencil;
    if (this.CustomStencil.length > 0) {
      el.customstencil = this.CustomStencil;
    }
    el.prefix = this.DiagramTypePrefix;
    el.doubleclick = this.DoubleClick;
    if (this.UMLShape) { el.UMLShape = "1"; }

    if (this.LocalDiagramDialog !== null) {
      el.CheckEdit = this.LocalDiagramDialog.CheckEdit;
      el.CheckInsert = this.LocalDiagramDialog.CheckInsert;
      el.CheckFind = this.LocalDiagramDialog.CheckFind;
      el.CheckGoUp = this.LocalDiagramDialog.CheckGoUp;
      el.CheckRefresh = this.LocalDiagramDialog.CheckRefresh;
    }
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    super.SaveXML(xd, el);
    if (this.Root) { el.setAttribute("root", this.Root.ObjectName); }
    if (this.InstanceRoot) { el.setAttribute("instanceroot", this.InstanceRoot.ObjectName); }
    if (this.NewSLRoot) { el.setAttribute("slroot", this.NewSLRoot.ObjectName); }
    if (this.UseInheritanceRelations) { el.setAttribute("UseInheritanceRelations", "1"); }
    if (this.IsClass) { el.setAttribute("type", "Class"); } else { el.setAttribute("type", "Instance"); }
    if (this.Auto) { el.setAttribute("auto", "1"); }
    if (this.Stencil) { el.setAttribute("stencil", this.Stencil); }
    if (this.DiagramTypePrefix) { el.setAttribute("prefix", this.DiagramTypePrefix); }
    if (this.DoubleClick) { el.setAttribute("doubleclick", this.DoubleClick); }
    if (this.UMLShape) { el.setAttribute("UMLShape", "1"); }

    if (this.LocalDiagramDialog) {
      el.setAttribute("CheckEdit", this.LocalDiagramDialog.CheckEdit ? "1" : "0");
      el.setAttribute("CheckInsert", this.LocalDiagramDialog.CheckInsert ? "1" : "0");
      el.setAttribute("CheckFind", this.LocalDiagramDialog.CheckFind ? "1" : "0");
      el.setAttribute("CheckGoUp", this.LocalDiagramDialog.CheckGoUp ? "1" : "0");
      el.setAttribute("CheckRefresh", this.LocalDiagramDialog.CheckRefresh ? "1" : "0");
    }
  }
}
export class SemTalkDiagram extends SemTalkInstance implements ISemTalkDiagram {
  constructor(cla: ISemTalkDiagramType, newname: string, otype: SemTalkType, id?: SemTalkID) {
    super(cla, newname, otype, id);
    //  if (this.ObjectBase.FindDiagram(newname) === null) {
    this.ObjectBase._diags[this.ID + "_"] = this;
    // } else {
    //   super.Delete();
    //   throw new Error("SemTalkDiagram: There is another SemTalkDiagram named: " + newname);
    // }
    this.ObjectBase.OnDiagramCreated(this);
    return this;
  }
  private _refinedby: { [ID: string]: ISemTalkObject } = {};
  public AddInvRefinement(n: ISemTalkObject): ISemTalkObject {
    this._refinedby[n.ID + "_"] = n;
    return n;
  }
  public RemoveInvRefinement(n: ISemTalkObject): void {
    delete this._refinedby[n.ID + "_"];
  }

  private _contents: { [shapeid: string]: ISemTalkNode } = {};
  public Visible: boolean = true;
  public Horizontal: boolean = true;
  public vid: number = 0;
  public get IsClass(): boolean { return this.ClassOf().IsClass; }
  public MakeNode(obj: ISemTalkObject, shpid: string, master: string): ISemTalkNode {
    if (master !== undefined) {
      if (!this.ClassOf().IsAllowedMaster(obj, master)) {
        console.debug("MakeNode: Bad master " + master);
        // throw new Error("MakeNode: Bad master " + master);
      }
    }
    if (this.ObjectBase.IsAssociation(obj)) {
      return this.ObjectBase.MakeAssociationNode(this, obj as ISemTalkAssociation, shpid, master);
    } else {
      if (!this.ClassOf().IsAllowed(obj)) {
        if (this.ObjectBase.IsInstance(obj) &&
          (obj as ISemTalkInstance).ClassOf().ObjectName ===
          this.ObjectBase.GetModelAttribute(ModelAttribute.SLComment)) {
        } else {
          // console.debug("MakeNode: Not allowed " + obj);
          throw new Error("MakeNode: Not allowed " + obj);
        }
      }
      return this.ObjectBase.MakeNode(this, obj, shpid, master);
    }
  }
  public ClassOf(): ISemTalkDiagramType {
    return super.ClassOf() as ISemTalkDiagramType;
  }
  public SystemClass(): ISemTalkDiagramType | null {
    const cl = this.ClassOf();
    const sc = cl.SystemClass();
    if (sc !== null)
      return sc as ISemTalkDiagramType;
    else
      return null;
  }
  public AddNode(n: ISemTalkNode): ISemTalkNode {
    // if (n.Diagram.ID !== this.ID) {
    //   throw new Error("AddNode: Node already belongs to a different Diagram: " + n);
    // }
    if (this._contents[n.ShapeID] !== undefined) {
      if (!this.ObjectBase._loading) {
        throw new Error("AddNode: Duplicate ShapeID: " + n);
      }
      // alert("AddNode: Duplicate ShapeID: " + n);
    }
    // if (!this.ObjectBase._loading) {
    //   if (!this.ClassOf().IsAllowed(n.Model)) {
    //     if (this.ObjectBase.IsInstance(n.Model)) {
    //       // tslint:disable-next-line:no-console
    //       // console.debug("AddNode: Not Allowed: " + n.Model + " Root:" + this.ClassOf().Root + " IsInstance:" + ((n.Model as ISemTalkInstance).ClassOf() as ISemTalkClass).IsParent((this.ClassOf().Root as ISemTalkClass)));
    //       throw new Error("AddNode: Not Allowed: " + n.Model + " Root:" + this.ClassOf().Root);
    //     } else {
    //       // tslint:disable-next-line:no-console
    //       // console.debug("AddNode: Not Allowed: " + n.Model + " Root:" + this.ClassOf().Root + " IsSubClass:" + (n.Model as ISemTalkClass).IsParent((this.ClassOf().Root as ISemTalkClass)));
    //       throw new Error("AddNode: Not Allowed: " + n.Model + " Root:" + this.ClassOf().Root);
    //     }
    //   }
    // }
    this._contents[n.ShapeID] = n;
    return n;
  }
  public RemoveNode(n: ISemTalkNode): void {
    if (this._contents[n.ShapeID] === undefined) {
      // throw new Error("RemoveNode: Missing Node: " + n); 
      // alert("RemoveNode: Missing Node: " + n);
      return;
    }
    delete this._contents[n.ShapeID];
  }
  public Contents(): ISemTalkNode[] {
    return Object.values(this._contents);
    // const alist: ISemTalkNode[] = [];
    // for (const shpid in this._contents) {
    //   const n = this._contents[shpid];
    //   alist.push(n);
    // }
    // return alist;
  }

  public Delete(): void {
    for (const n of this.Contents()) {
      // const m: ISemTalkObject = n.Model;
      n.Delete();
      // if (this.ObjectBase.IsInstance(m)) {
      //   const scl: ISemTalkSystemClass | null = (m as ISemTalkInstance).ClassOf().SystemClass();
      //   if (scl !== null && scl.OnceOnly) {
      //     m.Delete();
      //   }
      // }
    }
    for (const r of this.RefinedObjects()) {
      r.Refinement = null;
    }
    delete this.ObjectBase._diags[this.ID + "_"];
    super.Delete();
    this.ObjectBase.OnDiagramDeleted(this);
  }
  public FindNodeOf(obj: ISemTalkObject): ISemTalkNode[] {
    const alist: ISemTalkNode[] = [];
    for (const ndid in this._contents) {
      const n = this._contents[ndid];
      if (n.Model === obj) {
        alist.push(n);
      }
    }
    return alist;
  }
  public FindNodeOfShape(shpid: string): ISemTalkNode | null {
    for (const sid in this._contents) {
      const n = this._contents[sid];
      if (n.ShapeID === shpid) {
        return n;
      }
    }
    return null;
  }
  public RefinedObjects(): ISemTalkObject[] {
    const alist: ISemTalkObject[] = [];
    const fnd: any = {};
    for (const a in this._refinedby) {
      const x: ISemTalkObject = this._refinedby[a];
      if (fnd[x.ID] === undefined) {
        alist[alist.length] = x;
        fnd[x.ID] = x;
      }
    }
    return alist;
  }
  public InvRefinements(): ISemTalkDiagram[] {
    const alist: ISemTalkDiagram[] = [];
    const fnd: any = {};
    // How about RefinementOfClass ???
    for (const a in this._refinedby) {
      const x = this._refinedby[a];
      for (const nd of x.Nodes()) {
        if (fnd[nd.Diagram.ID] === undefined) {
          alist.push(nd.Diagram);
          fnd[nd.Diagram.ID] = nd.Diagram;
        }
      }
    }
    return alist;
  }
  public BreadCrumbs(): ISemTalkDiagram[] {
    // if (this.ClassOf().IsClass || this.ClassOf().ObjectName === SemTalkBaseConstant.SLGeneric) {
    //   return [this];
    // }
    let _BreadCrumbs = (par: ISemTalkDiagram, flist: string[]): ISemTalkDiagram[] => {
      if (flist.indexOf(par.ObjectName) > -1) {
        return [];
      }
      flist.push(par.ObjectName);
      const _ir = par.InvRefinements();
      const blist: ISemTalkDiagram[] = [par];
      for (let _par of _ir) {
        if (par.ID !== _par.ID)
          blist.push(..._BreadCrumbs(_par, flist));
      }
      return blist;
    };
    const alist: ISemTalkDiagram[] = [this];
    const ir = this.InvRefinements();
    for (let par of ir) {
      if (par.ID !== this.ID)
        alist.push(..._BreadCrumbs(par, [this.ObjectName]));
    }
    const blist0: ISemTalkDiagram[] = [this];
    for (let d of alist) {
      if (blist0.findIndex(x => x.ID === d.ID) < 0) {
        blist0.push(d);
      }
    }
    return blist0;
  }

  public Refinements(): ISemTalkDiagram[] {
    const alist: ISemTalkDiagram[] = [];
    for (const ndid in this._contents) {
      const n = this._contents[ndid];
      if (n.Model.Refinement !== null) {
        alist.push(n.Model.Refinement);
      }
      else {
        if (n.Model.ObjectType === SemTalkType.SemTalkInstance) {
          const cla = (n.Model as ISemTalkInstance).ClassOf();
          const scl = cla.SystemClass();
          if (scl !== null && scl.RefinementOfClass) {
            if (cla.Refinement !== null) {
              alist.push(cla.Refinement);
            }
          }
        }
      }
    }
    return alist;
  }
  public ExtRefinements(): string[] {
    const alist: string[] = [];
    for (const ndid in this._contents) {
      const n = this._contents[ndid];
      if (n.Model.ExtRefinement !== null) {
        let extref: string = n.Model.ExtRefinement;
        if (extref.indexOf("/XML/") > 0) {
          extref = extref.substr(extref.indexOf("/XML/") + 1);
        }
        alist.push(extref);
      }
      else
        if (n.Model.ObjectType === SemTalkType.SemTalkInstance) {
          const cla = (n.Model as ISemTalkInstance).ClassOf();
          const scl = cla.SystemClass();
          if (scl !== null && scl.RefinementOfClass) {
            if (cla.ExtRefinement !== null) {
              let extref2: string = cla.ExtRefinement;
              if (extref2.indexOf("/XML/") > 0) {
                extref2 = extref2.substr(extref2.indexOf("/XML/") + 1);
              }
              alist.push(extref2);
            }
          }
        }
    }
    return alist;
  }

  public NewUserNumber(): string {
    let n = 1;
    let s = "";
    for (const shpid in this._contents) {
      const o = this._contents[shpid].Model;
      const u = o.GetValue(SemTalkBaseConstant.SLUserNumber);
      if (u !== undefined && u !== null) {
        let us = u as string;
        if (us.indexOf(".") > 0) {
          if (s === "") {
            s = us.substr(0, us.lastIndexOf(".") + 1);
          }
          us = us.substr(us.lastIndexOf(".") + 1);
        }
        const ui = Number(us) + 1;
        if (ui > n) {
          n = ui;
        }
      }
    }
    if (s === "") {
      for (const o of this.RefinedObjects()) {
        const u = o.GetValue(SemTalkBaseConstant.SLUserNumber);
        if (u !== undefined) {
          s = u + ".";
        }
      }
    }
    s = s + String(n);
    return s;
  }
  public get IsLeaf(): boolean {
    for (const nd of this.Contents()) {
      if (nd.Model.Refinement !== null) {
        return false;
      }
    }
    return true;
  }

  public GetVariant(): ISemTalkDiagram {
    return this;
  }
  public toString(): string {
    return "<" + ObjectBase.SemTalkTypeName(this.ObjectType) + " " + this.ObjectName + " " + this.ClassOf().ObjectName + ">";
  }
  public Load(je: any) {
    super.Load(je);
    this.Visible = (je.visible !== "0");
    this.Horizontal = (je.horizontal === "1");
    this.vid = je.vid;
    // this.IsClass = (je.type === "Class");
  }
  public Save(par: any[]): any {
    const el: any = super.Save(par);
    // el.ObjectType = "Diagram";
    if (!this.Visible) { el.Visible = "0"; }
    if (this.Horizontal) { el.horizontal = "1"; }
    if (this.vid !== null) { el.vid = this.vid; }
    if (this.IsClass) { el.type = "Class"; }
    return el;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    super.SaveXML(xd, el);
    if (!this.Visible) { el.setAttribute("visible", "0"); }
    if (this.Horizontal) { el.setAttribute("horizontal", "1"); }
    if (this.vid) { el.setAttribute("vid", String(this.vid)); }
    if (this.IsClass) {
      el.setAttribute("type", "Class");
    } else {
      el.setAttribute("type", "Instance");
    }
  }
  public LoadXML(element: Element): void {
    super.LoadXML(element);
    if (element.attributes) {
      const Visible = (element.attributes.getNamedItem("visible")?.value === "1");
      if (!Visible) this.Visible = Visible;
      const Horizontal = (element.attributes.getNamedItem("horizontal")?.value === "1");
      if (!Horizontal) this.Horizontal = Horizontal;
      const vid = element.attributes.getNamedItem("vid")?.value;
      if (vid) this.vid = Number(vid);
      // const otype = (element.attributes.getNamedItem("type")?.value === "Class");
      // if (otype) this.IsClass = Number(vid);
    }
  }
}


export class SemTalkNode implements ISemTalkNode {
  constructor(diag: ISemTalkDiagram, obj: ISemTalkObject, shpid: string, master?: string) {
    this.Model = (obj as ISemTalkObject);
    this.Diagram = diag;
    this.ShapeID = shpid;
    if (master !== undefined) {
      this.Master = master;
    }
    // if (shpid.indexOf(".") > 0) {
    //   const idstr = shpid.substr(shpid.lastIndexOf(".") + 1);
    //   const shpcpount = Number(idstr);
    //   this.ID = diag.ID * 1000000 + shpcpount;
    // } else {
    //   if (shpid.indexOf("#") > 0) {
    //     const idstr = shpid.substr(shpid.lastIndexOf("#") + 1);
    //     const shpcpount = Number(idstr);
    //     this.ID = diag.ID * 1000000 + shpcpount;
    //   } else {
    //     throw("Missing NodeID: " + shpid);
    //   }
    // }
    this.ID = diag.ID.toString() + "%" + obj.ID.toString() + "%" + shpid;
    this.Diagram.AddNode(this);
    this.Model.AddNode(this);
    this.Model.ObjectBase.OnNodeCreated(obj, diag, shpid);
  }
  public Model: ISemTalkObject;
  public Diagram: ISemTalkDiagram;
  public ShapeID: string = "";
  public ParentShapeID: string | undefined = undefined;
  public absolutePosition: { x: number, y: number };
  public ID: string;
  public uid: string | null = null;
  public X: number | null = null;
  public Y: number | null = null;
  public Width: number | null = null;
  public Height: number | null = null;
  public Master: string | undefined = undefined;
  public Style: string | undefined = undefined;
  protected _type = SemTalkType.SemTalkNode;
  public get ObjectID(): SemTalkID { return this.Model.ID; }
  public get ObjectCaption(): string {
    return this.Model.ID2NameNsp();
  }
  // public get NodeShape(): string { return this.ShapeID; }
  // public get NodePage(): number { return this.Diagram.ID; }
  // public get DiagramName(): string { return this.Diagram.ID2NameNsp(); }
  public toString(): string {
    return "<" + ObjectBase.SemTalkTypeName(this._type) + " " + this.ID + " of '" + this.Model.ObjectName + "' " + this.ShapeID + ">";
  }
  public Delete(): void {
    const shpid = this.ShapeID;
    const obj = this.Model;
    const diag = this.Diagram;
    obj.RemoveNode(this);
    diag.RemoveNode(this);
    const ob = obj.ObjectBase;
    if (ob.IsInstance(obj) && obj.Nodes().length === 0) {
      const scl: ISemTalkSystemClass | null = (obj as ISemTalkInstance).ClassOf().SystemClass();
      if (scl !== null && scl.OnceOnly) {
        // console.debug("Delete: " + scl.ObjectName + " " + obj.ObjectName);
        (obj as ISemTalkInstance).Delete();
      }
    }
    if (ob.IsClass(obj) && obj.Nodes().length === 0 && (obj as ISemTalkClass).IsLeaf) {
      if ((obj.ObjectName === "SystemClass." + obj.ID) || (obj.ObjectName === "Class." + obj.ID)) {
        if (obj.Synonyms().length === 0) {
          obj.Delete();
        }
      }
    }
    // if (ob.IsInstance(obj) && !ob.IsAssociation(obj) && obj.Nodes().length === 0) {
    //   if ((obj.ObjectName === (obj as ISemTalkInstance).ClassOf().ObjectName + "." + obj.ID) || (obj.ObjectName === "Class." + obj.ID)) {
    //     if (obj.Synonyms().length === 0) {
    //       obj.Delete();
    //     }
    //   }
    // }
    obj.ObjectBase.OnNodeDeleted(obj, diag, shpid);
  }
  public Load(je: any) {
    this.uid = je.uid;
    this.X = Math.round(je.x);
    this.Y = Math.round(je.x);
    this.Width = Math.round(je.width);
    this.Height = Math.round(je.height);
    this.Master = je.master;
    this.Master = je.style;
  }
  public LoadXML(element: Element): void {
    if (element.attributes) {
      const uid = element.attributes.getNamedItem("uid")?.value;
      if (uid) this.uid = uid;
      const x = element.attributes.getNamedItem("x")?.value;
      if (x) this.X = Number(x);
      const y = element.attributes.getNamedItem("y")?.value;
      if (y) this.Y = Number(y);
      const width = element.attributes.getNamedItem("width")?.value;
      if (width) this.Width = Number(width);
      const height = element.attributes.getNamedItem("height")?.value;
      if (height) this.Height = Number(height);
      const master = element.attributes.getNamedItem("master")?.value;
      if (master) this.Master = master;
      const style = element.attributes.getNamedItem("style")?.value;
      if (style) this.Style = style;
    }
  }
  public Save(par: any[]): any {
    const el: any = {};
    el.ObjectType = ObjectBase.SemTalkTypeName(this._type);
    par.push(el);
    if (this.Diagram) { el.page = this.Diagram.ObjectName; }
    if (this.ShapeID) { el.id = this.ShapeID; }
    if (this.uid) { el.uid = this.uid; }
    if (this.X) { el.x = this.X; }
    if (this.Y) { el.y = this.Y; }
    if (this.Width) { el.width = this.Width; }
    if (this.Height) { el.height = this.Height; }
    if (this.Master !== undefined) { el.master = this.Master; }
    if (this.Style !== undefined) { el.style = this.Style; }
    return el;
  }
  public SaveXML(_xd: XMLDocument, el: HTMLElement): void {
    if (this.Diagram) { el.setAttribute("page", this.Diagram.ObjectName); }
    if (this.ShapeID) { el.setAttribute("id", this.ShapeID); }
    if (this.ParentShapeID) { el.setAttribute("parentid", this.ParentShapeID); }
    if (this.absolutePosition) { el.setAttribute("absolutepositionx", String(this.absolutePosition.x)); }
    if (this.absolutePosition) { el.setAttribute("absolutepositiony", String(this.absolutePosition.y)); }
    if (this.uid) { el.setAttribute("uid", this.uid); }
    if (this.X) { el.setAttribute("x", String(round(this.X))); }
    if (this.Y) { el.setAttribute("y", String(round(this.Y))); }
    if (this.Width) { el.setAttribute("width", String(round(this.Width))); }
    if (this.Height) { el.setAttribute("height", String(round(this.Height))); }
    if (this.Master) { el.setAttribute("master", this.Master); }
    if (this.Style) { el.setAttribute("style", this.Style); }
  }
  // tslint:disable-next-line:no-empty
  public ignore(): void { }

}
export class SemTalkAssociationNode extends SemTalkNode implements ISemTalkAssociationNode {
  constructor(diag: ISemTalkDiagram, obj: ISemTalkAssociation, shpid: string, master?: string) {
    super(diag, obj, shpid, master);
    this._type = SemTalkType.SemTalkAssociationNode;
  }
  public FromShape: string | undefined = undefined;
  public OtherShape: string | undefined = undefined;
  public BeginX: number | undefined = undefined;
  public BeginY: number | undefined = undefined;
  public EndX: number | undefined = undefined;
  public EndY: number | undefined = undefined;


  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    super.SaveXML(xd, el);
    if (el.attributes.getNamedItem("x")) {
      el.attributes.removeNamedItem("x");
    }
    if (el.attributes.getNamedItem("y")) {
      el.attributes.removeNamedItem("y");
    }
    if (this.FromShape) { el.setAttribute("fromshp", this.FromShape); }
    if (this.OtherShape) { el.setAttribute("othershp", this.OtherShape); }
    if (this.BeginX) { el.setAttribute("beginx", String(this.BeginX)); }
    if (this.BeginY) { el.setAttribute("beginy", String(this.BeginY)); }
    if (this.EndX) { el.setAttribute("endx", String(this.EndX)); }
    if (this.EndY) { el.setAttribute("endy", String(this.EndY)); }

  }
}
