import { OpenAIApi } from "openai";

export function decodechatgpt(token: string): string {
    if (token.length === 0) {
        token = "xsk-18UapbQs1gMRjXcuTcUFT3BlbkFJmUgZTUwhePWi0mwmdwqm";
    }
    if (token.length === 1) {
        return "";
    }
    if (token.substring(0, 1) !== "x") {
        return "";
    }
    return token.substring(1);
}
export function encodechatgpt(token: string): string {
    if (token.length > 0) {
        return "x" + token;
    } else {
        return token;
    }
}
export interface IChatCompletion {
    model: string;
    messages: any[];
    max_tokens: number;
    temperature: number;
}

export async function createChatCompletion(openai: OpenAIApi, options: IChatCompletion): Promise<string> {
    let answer: string | undefined = "";
    const response = await openai.createChatCompletion(options);
    if (response.data.choices &&
        response.data.choices[0] &&
        response.data.choices[0].message) {
        answer = response.data.choices[0].message["content"];
        if (!answer) {
            answer = "";
        }
        answer = answer.replace("\\n\\n", "");
    }
    return answer;
}

export async function askQuestion(openai: OpenAIApi, prompts: string[], question: string): Promise<string> {
    let txt = prompts.join(". ");
    let len = 4000 - question.length;
    if (len) {
        txt = txt.substring(0, len);
    }
    txt += " " + question;
    let answer = await createChatCompletion(openai, {
        model: "gpt-3.5-turbo",
        messages: [{ role: 'user', content: txt }],
        max_tokens: 1 * 2048,
        temperature: 1,
    });
    return answer;
}