import { SemTalkMaster } from "../SemTalkMaster";
import { SemTalkBuiltInShape, SemTalkStyleAttribute } from "./visiordfsinterface";

export const GENERIC_SHAPES: any = {};
GENERIC_SHAPES[SemTalkMaster.MasterClass] = {
  type: 'vertex',
  style: SemTalkStyleAttribute.shape + '=' +
    SemTalkBuiltInShape.genericclass + ';whiteSpace=wrap;' +
    SemTalkStyleAttribute.resize + '=1;html=1;' +
    SemTalkStyleAttribute.strokeWidth + '=2;' +
    SemTalkStyleAttribute.strokeColor + '=darkgray;' +
    SemTalkStyleAttribute.rounded + '=1',
};
GENERIC_SHAPES[SemTalkMaster.MasterSystemClass] = {
  type: 'vertex',
  style: SemTalkStyleAttribute.shape + '=' +
    SemTalkBuiltInShape.genericclass + ';whiteSpace=wrap;' +
    SemTalkStyleAttribute.resize + '=1;html=1;' +
    SemTalkStyleAttribute.strokeWidth + '=5;' +
    SemTalkStyleAttribute.strokeColor + '=black;' +
    SemTalkStyleAttribute.rounded + '=1;',
};
GENERIC_SHAPES[SemTalkMaster.MasterInstance] = {
  type: 'vertex',
  style: SemTalkStyleAttribute.shape + '=ellipse;whiteSpace=wrap;' +
    SemTalkStyleAttribute.resize + '=1;html=1;' +
    // SemTalkStyleAttribute.dashed + '=1;' +
    SemTalkStyleAttribute.strokeColor + '=lightgray;' +
    SemTalkStyleAttribute.strokeWidth + '=2;',
};
GENERIC_SHAPES[SemTalkMaster.MasterComment] = {
  type: 'vertex',
  style: 'shape=offPageConnector;verticalLabelPosition=top;verticalAlign=bottom;whiteSpace=wrap;html=1;',
};
GENERIC_SHAPES[SemTalkMaster.MasterAttachment] = {
  type: 'vertex',
  style: 'whiteSpace=wrap;html=1;',
};
GENERIC_SHAPES[SemTalkMaster.MasterAttributeType] = {
  type: 'vertex',
  style: SemTalkStyleAttribute.shape + '=' +
    SemTalkBuiltInShape.genericclass + ';' +
    SemTalkStyleAttribute.strokeColor + '=green;whiteSpace=wrap;' +
    SemTalkStyleAttribute.resize + '=1;html=1;' +
    SemTalkStyleAttribute.strokeWidth + '=5;',
};
GENERIC_SHAPES[SemTalkMaster.MasterAssociationType] = {
  type: 'vertex',
  style: SemTalkStyleAttribute.shape + '=' +
    SemTalkBuiltInShape.genericclass + ';' +
    SemTalkStyleAttribute.strokeColor + '=blue;whiteSpace=wrap;' +
    SemTalkStyleAttribute.resize + '=1;html=1;' +
    SemTalkStyleAttribute.strokeWidth + '=5;',
};
GENERIC_SHAPES[SemTalkMaster.MasterDiagramType] = {
  type: 'vertex',
  style: SemTalkStyleAttribute.shape + '=' +
    SemTalkBuiltInShape.genericclass + ';' +
    SemTalkStyleAttribute.strokeColor + '=red;whiteSpace=wrap;' +
    SemTalkStyleAttribute.resize + '=1;html=1;' +
    SemTalkStyleAttribute.strokeWidth + '=5;',
};
