import { SemTalkLanguageCode } from "../tbase/Interface";

export function showHelp(page: string, lng: string): void {
  let helpwiki: string = "https://github.com/SemTalkOnline/SemTalkOnline_EN/wiki/";
  // if (!lng) {
  //   lng = this.guilanguage;
  // }
  if (lng) {
    switch (lng) {
      case SemTalkLanguageCode.German: {
        helpwiki = "https://github.com/SemTalkOnline/SemTalkOnline_DE/wiki/";
        break;
      }
      case SemTalkLanguageCode.English: {
        helpwiki = "https://github.com/SemTalkOnline/SemTalkOnline_EN/wiki/";
        break;
      }
      default: {
        helpwiki = "https://github.com/SemTalkOnline/SemTalkOnline_EN/wiki/";
        break;
      }
    }
  }

  if (page) { window.open(helpwiki + getHelpStr(page, lng as SemTalkLanguageCode), "_blank"); return; }
}

function getHelpStr(str: string, lang: SemTalkLanguageCode): string {
  let help_SemTalk = require("./SemTalkHelp.json");
  // if (lang === undefined) {
  //   lang = this.guilanguage;
  // }
  if (lang === SemTalkLanguageCode.English) {
    const s = help_SemTalk[str];
    if (s !== undefined) {
      return s.value;
    }
    return str;
  } else {
    const s = help_SemTalk[str];
    if (s !== undefined) {
      let v = s[lang];
      if (v === undefined) {
        return s.value;
      }
      return v;
    } else {
      return str;
    }
  }
}
