import { IVisioRDFS } from '../../application/semtalklistener/visiordfsinterface';
import { ensureLabelWidth } from '../../styles';
import { patchSVGFont } from './loadimage';
import { converBase64toBlob } from './utils';

export default function ExportJPG(semtalk: IVisioRDFS) {
  ensureLabelWidth(semtalk.graph as mxGraph);
  let svg: any = semtalk.tseditor.exportSVGforPrint(semtalk.graph as mxGraph, false, false, 0, 1);
  let svgfix: string = patchSVGFont(svg);
  // let svg64 = btoa(svgfix);
  let svg64: string = encodeURIComponent(svgfix);
  // let b64Start = 'data:image/svg+xml;base64,';
  let b64Start: string = 'data:image/svg+xml;utf8,';
  let image64: string = b64Start + svg64;
  let image = new Image();
  image.onload = async () => {
    let canvas = document.createElement('canvas');
    let svgWidth: number = svg.width;
    let svgHeight: number = svg.height;
    canvas.width = svgWidth;
    canvas.height = svgHeight;
    let context = canvas.getContext('2d');
    if (context != null) {
      context.drawImage(image, 0, 0, svgWidth, svgHeight);
    }
    let img = canvas.toDataURL('image/jpeg');
    let blob = converBase64toBlob(img.replace("data:image/jpeg;base64,", ""), 'image/jpeg');

    // semtalk.tseditor.exportPDF(img);

    const url = window.URL.createObjectURL(new Blob([blob]),);
    const link: any = document.createElement('a');
    link.href = url;
    link.setAttribute('download', semtalk.page?.ObjectCaption + ".jpg",);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  image.src = image64;
}