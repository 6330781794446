// tslint:disable:no-empty-interface

import { SemTalkStencil } from "../semtalklistener/visiordfsinterface";

// tslint:disable:interface-name
export enum SemTalkType {
  SemTalkClass = 0,
  SemTalkInstance = 1,
  SemTalkAssociation = 2,
  SemTalkAttribute = 3,
  SemTalkState = 4,
  SemTalkMethod = 5,
  SemTalkAttributeType = 6,
  SemTalkStateType = 7,
  SemTalkMethodType = 8,
  SemTalkAssociationType = 9,
  SemTalkSpecialization = 10,
  SemTalkDiagram = 11,
  SemTalkDiagramType = 12,
  SemTalkNode = 13,
  SemTalkAssociationNode = 14,
  SemTalkTabSpec = 15,
  SemTalkGenericTabSpec = 16,
  SemTalkGenericAttributeTabSpec = 17,
  SemTalkLabel = 18,
  SemTalkClassLabel = 19,
  SemTalkSynonym = 20,
  SemTalkComposition = 21,
  SemTalkTabSpecListTabSpec = 22
}
export enum SemTalkRelation {
  SemTalkProperty = 0,
  SemTalkSubClassOf = 1,
  SemTalkInstanceOf = 2,
  SemTalkSystemRelation = 3,
  SemTalkHiddenRelation = 4
}
export enum SemTalkValueType {
  Symbol = 0,
  File = 1,
  Date = 2,
  Duration = 3,
  Float = 4,
  Integer = 5,
  Boolean = 6,
  Color = 7,
  Email = 8,
  Password = 9,
  URL = 10,
  Tel = 11,
  Script = 12
}
export enum SemTalkBaseConstant {
  SLClass = "Class",
  SLInstance = "Instance",
  SLDiagram = "Diagram",
  SLSubClassOf = "subClassOf_",
  SLInstanceOf = "instanceOf",
  SLConsistsOf = "consists of",
  SLHas = "has",
  SLGeneric = "Generic",
  SLAttachment = "Attachment",
  SLhasAttachment = "has Attachment",
  SLComment = "Comment",
  SLDefinitionOf = "definitionOf",
  SLLanguage = "SemTalkLanguage",
  SLDocumentInfo = "DocumentInfo",
  SLSwimlane = "Swimlane",
  SLDisplays = "displays",
  SLUserNumber = "UserNumber",
  SLEditLink = "EditLink",
  SLMXGAttribute = "mxg",
  SLMXGPagePrefix = "MXG_",
  SLSVGAttribute = "svg",
  SLSVGPagePrefix = "SVG_",
  SLSVGPagePostfix = "Page",
  SLHasDomain = "hasDomain",
  SLHasRange = "hasRange",
  SLInverseOf = "inverseOf",
  SLThing = "SemTalkThing",
  SLProperty = "Property",
  SLHiddenRelation = "HiddenRelation",
  CookiePrefix = "SemTalkOnline_",
  SSOPWD = "SSOPWD"
}
export enum PrintPageFormat {
  custom = "custom",
  letter = "letter",
  legal = "legal",
  tabloid = "tabloid",
  executive = "executive",
  a0 = "a0",
  a1 = "a1",
  a2 = "a2",
  a3 = "a3",
  a4 = "a4",
  a5 = "a5",
  a6 = "a6",
  a7 = "a7",
  b4 = "b4",
  b5 = "b5",
  b6 = "b6",
  b7 = "b7",
  x169 = "16-9",
  x1610 = "16-10",
  x43 = "4-3"
}
export enum SemTalkPanelType {
  custom = "custom",
  fluid = "fluid",
  small = "small",
  medium = "medium",
  large = "large",
  xlarge = "xlarge",
  customNear = "customNear"
}
export enum ModelAttribute {
  modname = "modname",
  currentnsp = "currentnsp",
  mxencoding = "mxencoding",
  // mxg ="mxg",
  forder = "forder",
  Template = "Template",
  ShowGenericDiagrams = "ShowGenericDiagrams",
  BPMNMode = "BPMNMode",
  notstr = "NOT ",
  SLComment = "SLComment",
  SLCommentOf = "SLCommentOf",
  // SLDefinitionOf = "definitionOf",
  underlineRefinements = "underlineRefinements",
  patch = "patch",
  showNamespace = "showNamespace",
  modelProperties = "Model_Properties"
}
export enum SemTalkConnectionPoint {
  North = 0,
  East = 1,
  South = 2,
  West = 3
}
export enum SemTalkLanguage {
  German = "German",
  English = "English",
  Spanish = "Spanish",
  Japanese = "Japanese",
  Russian = "Russian",
  French = "French",
  Traditional = "Chinese (Traditional)",
  Simplified = "Chinese (Simplified)",
  None = "No Language"
}
export type SemTalkID = string;
// export type SemTalkID = number;

export type SemTalkJSON = any;

export enum SemTalkLanguageCode {
  German = "de",
  English = "en",
  Spanish = "es",
  Japanese = "ja",
  Russian = "ru",
  French = "fr",
  Traditional = "cn-traditional",
  Simplified = "cn-simplified"
}
export enum SemTalkObjectBaseEvent {
  OnCreated = "SemTalk_OnCreated",
  OnBeforeDeleted = "SemTalk_OnBeforeDeleted",
  OnDeleted = "SemTalk_OnDeleted",
  OnRenamed = "SemTalk_OnRenamed",
  OnCommentChanged = "SemTalk_OnCommentChanged",
  OnRefined = "SemTalk_OnRefined",
  OnDetached = "SemTalk_OnDetached",
  OnExtRefined = "SemTalk_OnExtRefined",
  OnExtDetached = "SemTalk_OnExtDetached",
  OnComposed = "SemTalk_OnComposed",
  OnUnComposed = "SemTalk_OnUnComposed",
  OnAttributeAdded = "SemTalk_OnAttributeAdded",
  OnAttributeDeleted = "SemTalk_OnAttributeDeleted",
  OnAttributeRenamed = "SemTalk_OnAttributeRenamed",
  OnValueChanged = "SemTalk_OnValueChanged",
  OnUserNumberChanged = "SemTalk_OnUserNumberChanged",
  OnColorChanged = "SemTalk_OnColorChanged",
  OnStateAdded = "SemTalk_OnStateAdded",
  OnStateRenamed = "SemTalk_OnStateRenamed",
  OnStateDeleted = "SemTalk_OnStateDeleted",
  OnMethodAdded = "SemTalk_OnMethodAdded",
  OnMethodDeleted = "SemTalk_OnMethodDeleted",
  OnAssociationAdded = "SemTalk_OnAssociationAdded",
  OnAssociationDeleted = "SemTalk_OnAssociationDeleted",
  OnAssociationBeforeDeletedByID = "SemTalk_OnAssociationBeforeDeleted",
  OnAssociationBeforeDeleted = "SemTalk_OnAssociationBeforeDeleted",
  OnSubClassCreated = "SemTalk_OnSubClassCreated",
  OnSubClassDeleted = "SemTalk_OnSubClassDeleted",
  OnAttachmentAdded = "SemTalk_OnAttachmentAdded",
  OnAttachmentDeleted = "SemTalk_OnAttachmentDeleted",
  OnAttachmentRenamed = "SemTalk_OnAttachmentRenamed",
  OnNodeCreated = "SemTalk_OnNodeCreated",
  OnNodeDeleted = "SemTalk_OnNodeDeleted",
  OnClassBeforeDeleted = "SemTalk_OnClassBeforeDeleted",
  OnClassDeleted = "SemTalk_OnClassDeleted",
  OnInstanceDeleted = "SemTalk_OnInstanceDeleted",
  OnAttributeTypeDeleted = "OnAttributeTypeDeleted",
  OnAssociationTypeDeleted = "OnAssociationTypeDeleted",
  OnMethodTypeDeleted = "SemTalk_OnMethodTypeDeleted",
  OnStateTypeDeleted = "OnStateTypeDeleted",
  OnDiagramTypeDeleted = "OnDiagramTypeDeleted",
  OnDiagramDeleted = "SemTalk_OnDiagramDeleted",
  OnDiagramCreated = "SemTalk_OnDiagramCreated",
  OnSynonymAdded = "SemTalk_OnSynonymAdded",
  OnSynonymDeleted = "SemTalk_OnSynonymDeleted",
  OnSynonymRenamed = "SemTalk_OnSynonymRenamed",
  OnClassChanged = "SemTalk_OnClassChanged",
  OnLoaded = "SemTalk_OnLoaded",
  OnSaved = "SemTalk_OnSaved",
  OnBeforeSaved = "SemTalk_OnBeforeSaved",
  OnModelAttributeChanged = "SemTalk_OnModelAttributeChanged",
}
export enum SemTalkComposeOrder {
  "NounVerb" = "0",
  "VerbNoun" = "1"
}

export enum SemTalkAttachment {
  name = "name",
  label = "label",
  icon = "icon",
  namespace = "namespace",
  type = "type",
  subaddress = "subaddress",
  frame = "frame",
  extrainfo = "extrainfo",
  newwindow = "newwindow"
}
export enum SemTalkLayoutField {
  name = "Name",
  class = "Class",
  systemclass = "SystemClass",
  superclass = "SuperClasses",
  subclass = "SubClasses",
  fullname = "FullName",
  synonyms = "Synonyms",
  attributes = "Attributes",
  methods = "Methods",
  states = "States",
  comment = "Comment",
  links = "Links",
  postcondition = "PostCondition",
  usernumberatfront = "UserNumberAtFront",
  nameandusernumber = "NameAndUserNumber",
  usernumberandname = "UserNumberAndName",
  probability = "Probability",
  usernumber = "UserNumber"
}
export interface IObjectBase {
  _loading: boolean;
  _callbacks: any[];
  _objects: { [name: string]: ISemTalkObject };
  _objectids: { [id: string]: ISemTalkObject };
  _classes: { [id: string]: ISemTalkClass };
  _instances: { [id: string]: ISemTalkInstance };
  _attrtypes: { [id: string]: ISemTalkAttributeType };
  _statetypes: { [id: string]: ISemTalkStateType };
  _assoctypes: { [id: string]: ISemTalkAssociationType };
  _methodtypes: { [id: string]: ISemTalkMethodType };
  _diagtypes: { [id: string]: ISemTalkDiagramType };
  _diags: { [id: string]: ISemTalkDiagram };
  // _languages: string[];
  _currentnsp: string;
  LogAsync(msg: any, succeed?: () => void, nextfunction?: () => void): void;
  _modelattr: { [name: string]: string };

  ObjectName: string;
  User: string;
  ID: number;

  maxid: number;
  NewID(): SemTalkID;
  CurrentNsp: string;
  GetModelAttribute(name: string): string;
  SetModelAttribute(name: string, value: any): void;
  DeleteModelAttribute(name: string): void;
  AllModelAttributes(): string[];
  // AllLanguages(): string[];
  PostEvent(evt: any, obj: ISemTalkNamedThing | null, arg0?: any, arg1?: any, arg2?: any): void;
  // PostAny(m: any): void;
  Log(msg: string): void;
  // OnNodeCreated(n: SemTalkNode): void;
  // OnNodeDeleted(n: SemTalkNode): void;
  // PostEvent(evt: any, obj: SemTalkNamedThing | null, arg0?: any, arg1?: any, arg2?: any): void;
  // SemTalkTypeName(type: SemTalkType): string;
  // SemTalkConnectionPointName(con: SemTalkConnectionPoint): string;
  // FindObjectByID(id: number): SemTalkObject | null;
  // FindAssociationType(name: string): SemTalkAssociationType | null;
  OnCreated(n: ISemTalkObject): void;
  OnBeforeDeleted(obj: ISemTalkObject): void;
  OnDeleted(obj: ISemTalkObject): void;
  OnRenamed(obj: ISemTalkObject, oldname: string): void;
  OnCommentChanged(obj: ISemTalkObject, oldvalue: string | null, newvalue: string | null): void;
  OnRefined(obj: ISemTalkObject, old: ISemTalkDiagram): void;
  OnDetached(obj: ISemTalkObject, value: ISemTalkDiagram): void;
  OnExtRefined(obj: ISemTalkObject, old: string): void;
  OnExtDetached(obj: ISemTalkObject, value: string): void;
  OnComposed(obj: ISemTalkObject): void;
  OnUnComposed(obj: ISemTalkObject): void;
  OnAttributeAdded(obj: ISemTalkAttribute): void;
  OnAttributeDeleted(obj: ISemTalkAttribute): void;
  OnAttributeRenamed(obj: ISemTalkAttribute): void;
  OnValueChanged(obj: ISemTalkAttribute, oldvalue: any): void;
  OnUserNumberChanged(obj: ISemTalkAttribute, oldvalue: string): void;
  OnColorChanged(obj: ISemTalkAttribute, oldvalue: string): void;
  OnStateAdded(obj: ISemTalkState): void;
  OnStateRenamed(obj: ISemTalkState, oldvalue: any): void;
  OnStateDeleted(obj: ISemTalkState): void;
  OnMethodAdded(obj: ISemTalkMethod): void;
  OnMethodDeleted(obj: ISemTalkMethod): void;
  OnAssociationAdded(obj: ISemTalkAssociation): void;
  OnAssociationDeleted(obj: ISemTalkAssociation): void;
  OnAssociationBeforeDeletedByID(obj: ISemTalkAssociation): void;
  OnAssociationBeforeDeleted(obj: ISemTalkAssociation): void;
  OnSubClassCreated(obj: ISemTalkSpecialization): void;
  OnSubClassDeleted(obj: ISemTalkSpecialization): void;
  OnAttachmentAdded(obj: ISemTalkAssociation): void;
  OnAttachmentDeleted(obj: ISemTalkAssociation): void;
  OnAttachmentRenamed(obj: ISemTalkAssociation): void;
  OnNodeCreated(obj: ISemTalkObject, d: ISemTalkDiagram, s: string): void;
  OnNodeDeleted(obj: ISemTalkObject, d: ISemTalkDiagram, s: string): void;
  OnClassBeforeDeleted(obj: ISemTalkClass): void;
  OnClassDeleted(obj: ISemTalkClass): void;
  OnInstanceDeleted(obj: ISemTalkInstance): void;
  OnAttributeTypeDeleted(obj: ISemTalkAttributeType): void;
  OnAssociationTypeDeleted(obj: ISemTalkAssociationType): void;
  OnMethodTypeDeleted(obj: ISemTalkMethodType): void;
  OnStateTypeDeleted(obj: ISemTalkStateType): void;
  OnDiagramTypeDeleted(obj: ISemTalkDiagramType): void;

  OnDiagramDeleted(obj: ISemTalkDiagram): void;
  OnDiagramCreated(obj: ISemTalkDiagram): void;
  // OnDiagramClassChanged(obj: ISemTalkDiagram): void;
  OnSynonymAdded(obj: ISemTalkSynonym): void;
  OnSynonymDeleted(obj: ISemTalkSynonym): void;
  OnSynonymRenamed(obj: ISemTalkSynonym, oldname: string): void;
  OnClassChanged(ins: ISemTalkInstance, old: ISemTalkClass | null): void;
  OnLoaded(filename: string): void;
  OnSaved(filename: string): void;
  OnBeforeSaved(filename: string): void;
  OnModelAttributeChanged(attr: string, oldvalue: any, newvalue: any): void;

  FindAttributeType(name: string): ISemTalkAttributeType | null;
  FindStateType(name: string): ISemTalkStateType | null;
  FindMethodType(name: string): ISemTalkMethodType | null;
  FindAssociationType(name: string): ISemTalkAssociationType | null;
  FindDiagramType(name: string): ISemTalkDiagramType | null;
  FindClass(name: string): ISemTalkClass | null;
  FindClassByID(id: SemTalkID): ISemTalkClass | null;
  FindSystemClass(name: string): ISemTalkSystemClass | null;
  FindSystemClassByID(id: SemTalkID): ISemTalkSystemClass | null;
  FindBusinessClass(name: string): ISemTalkBusinessClass | null;
  FindInstance(name: string): ISemTalkInstance | null;
  FindInstanceByID(id: SemTalkID): ISemTalkInstance | null;
  FindDiagram(name: string): ISemTalkDiagram | null;
  FindDiagramByName(name: string): ISemTalkDiagram | null;
  FindDiagramByID(id: SemTalkID): ISemTalkDiagram | null;
  FindObject(tname: SemTalkType, name: string): ISemTalkObject | null;
  FindObjectNamed(name: string): ISemTalkObject | null;
  FindObjectByID(id: SemTalkID): ISemTalkObject | null;
  FindNodeByID(id: string): ISemTalkNode | null;
  AllClasses(): ISemTalkClass[];
  AllRootClasses(): ISemTalkClass[];
  AllReadOnlyClasses(): ISemTalkClass[];
  AllSystemClasses(): ISemTalkSystemClass[];
  AllDiagramTypes(): ISemTalkDiagramType[];
  AllMethodTypes(): ISemTalkMethodType[];
  AllAssociationTypes(): ISemTalkAssociationType[];
  AllAttributeTypes(): ISemTalkAttributeType[];
  AllStateTypes(): ISemTalkStateType[];
  AllInstances(): ISemTalkInstance[];
  AllDiagrams(): ISemTalkDiagram[];
  AllObjects(): ISemTalkObject[];
  // SemTalkRelationTypeName(r: SemTalkRelation): string;
  // FindSemTalkRelationType(s: string): SemTalkRelation;
  // SemTalkValueTypeName(r: SemTalkValueType): string;
  // FindSemTalkValueType(s: string): SemTalkValueType;
  // SemTalkTypeName(r: SemTalkType): string;
  // Load(s: string, nfn?: () => void): void;
  MakeClass(newname: string, id?: SemTalkID | null): ISemTalkClass;
  IsClass(obj: ISemTalkObject): boolean;
  MakeSystemClass(newname: string, id?: SemTalkID | null): ISemTalkSystemClass;
  // MakeSharePointClass(newname: string, id?: SemTalkID | null): ISemTalkSharePointClass;
  IsSystemClass(obj: ISemTalkObject): boolean;
  MakeBusinessClass(newname: string, id?: SemTalkID | null): ISemTalkBusinessClass;
  MakeComposition(own: ISemTalkObject, cls: ISemTalkBusinessClass, met: ISemTalkMethodType | null, sta: ISemTalkStateType | null,
    attr: ISemTalkAttributeType | null, oth?: ISemTalkBusinessClass | null, op?: string,
    isnotop?: boolean, cmpvalue?: any): ISemTalkComposition;
  IsBusinessClass(obj: ISemTalkObject): boolean;
  MakeInstance(cla: ISemTalkClass, newname: string, otype: SemTalkType, id?: SemTalkID | null): ISemTalkInstance;
  IsInstance(obj: ISemTalkObject): boolean;
  IsAssociation(obj: any): boolean;
  MakeAttributeType(newname: string, id?: SemTalkID | null): ISemTalkAttributeType;
  IsAttributeType(obj: ISemTalkObject): boolean;
  MakeAttribute(obj: ISemTalkObject, cla: ISemTalkAttributeType, val: any | null): ISemTalkAttribute;
  MakeMethodType(newname: string, id?: SemTalkID | null): ISemTalkMethodType;
  IsMethodType(obj: ISemTalkObject): boolean;
  MakeStateType(newname: string, id?: SemTalkID | null): ISemTalkStateType;
  IsStateType(obj: ISemTalkObject): boolean;
  IsDiagramType(obj: ISemTalkObject): boolean;
  MakeDiagramType(newname: string, id?: SemTalkID | null): ISemTalkDiagramType;
  IsAssociationType(obj: ISemTalkObject): boolean;
  MakeAssociationType(ptype: SemTalkRelation, newname: string, id?: SemTalkID | null): ISemTalkAssociationType;
  MakeNode(diag: ISemTalkDiagram, obj: ISemTalkObject, shpid: string, master?: string): ISemTalkNode;
  IsNode(obj: any): boolean;
  MakeAssociationNode(diag: ISemTalkDiagram, obj: ISemTalkAssociation, shpid: string, master: string): ISemTalkAssociationNode;
  IsAssociationNode(obj: any): boolean;
  MakeDiagram(cla: ISemTalkDiagramType, newname: string, otype?: SemTalkType, id?: SemTalkID): ISemTalkDiagram;
  IsDiagram(obj: ISemTalkObject): boolean;
  MakeLabelSpec(): ILabelSpec;
  SearchObjects(str: string): ISemTalkObject[];
  SearchNodes(str: string, paging_from?: number, paging_to?: number): ISemTalkNode[];
  SearchDiagram(diagid: SemTalkID, str: string): ISemTalkNode[];
  SetID2NumberConverter(enable: boolean): IID2NumericConverter | null;
  convertID(id: string): string;
  ShowNamespace(): boolean;
  SetShowNamespace(v: boolean): void;
}
export interface IID2NumericConverter {
  convertID(id: string): string;
  maxid: number;
  id2number: Map<string, string>;
}
export interface ISemTalkNamedThing {
  ID: SemTalkID;
  ObjectBase: IObjectBase;
  ID2Name: string;
  ID2NameNsp(): string;
  ObjectName: string;
  ObjectCaption: string;
  ObjectType: SemTalkType;
  //  CurrentName(): string;
  MakeSynonym(name: string, lang: string, isinv?: boolean): ISemTalkSynonym;
  MakeAltSynonym(name: string, lang: string): ISemTalkSynonym;
  FindSynonym(lang: string): ISemTalkSynonym | undefined;
  FindAltSynonym(name: string, lang: string): ISemTalkSynonym | undefined;
  DeleteSynonym(lang: string): ISemTalkNamedThing;
  DeleteAltSynonym(name: string, lang: string): ISemTalkNamedThing;
  Synonyms(): ISemTalkSynonym[];
  AltSynonyms(): ISemTalkSynonym[];
  ID2NameNspLan(lang: string): string;
  AllSynonyms(): ISemTalkSynonym[];
}

export interface ISemTalkObject extends ISemTalkNamedThing {
  ID: SemTalkID;
  ObjectType: SemTalkType;
  Comment: string;
  Refinement: ISemTalkDiagram | null;
  ExtRefinement: string | null;
  EditLink: string | null;
  ID2Namespace: string;
  ID2NameNsp(): string;
  Created: string;
  Changed: string;
  CreatedBy: string;
  ChangedBy: string;
  indeletion: boolean;
  SrcID: string;
  Color: string | null;
  Clone(c: ISemTalkObject): any;


  Save(par: any[]): any;
  Load(je: any): void;

  // ignore(): void;
  Delete(): void;
  Associations(): ISemTalkAssociation[];
  InvAssociations(): ISemTalkAssociation[];
  AssociationTypes(): ISemTalkAssociationType[];
  InvAssociationTypes(): ISemTalkAssociationType[];
  Attachments(): ISemTalkAssociation[];
  Attributes(): ISemTalkAttribute[];
  AllAttributes(): ISemTalkAttribute[];
  GetAttributeOwner(attr: string | ISemTalkAttributeType): ISemTalkAttribute | null;
  MapProperties(fn: (x: any) => void): void;
  FindAttribute(name: string | ISemTalkAttributeType): ISemTalkAttribute | null;
  MakeAttribute(name: string | ISemTalkAttributeType, value: any): ISemTalkAttribute;
  DeleteAttribute(name: string | ISemTalkAttributeType): void;
  MakeAssociation(name: string | ISemTalkAssociationType, oth: ISemTalkObject, type?: SemTalkRelation,
    id?: SemTalkID, fn?: (cla: ISemTalkAssociationType, ptype: SemTalkRelation,
      fromobj: ISemTalkObject, toobj: ISemTalkObject, i?: SemTalkID) => ISemTalkAssociation): ISemTalkAssociation;
  DeleteAssociation(attr: string | ISemTalkAssociationType, toobj: ISemTalkObject): void;
  GetValue(name: string): any;
  SetValue(name: string, value: any): any;
  GetCurrentValue(aname: string, lang?: string): any;
  // AddNode(n: SemTalkNode): SemTalkNode;
  // RemoveNode(n: SemTalkNode): void;
  LinkedObjects(attr: string | ISemTalkAssociationType, rec?: boolean, ptype?: SemTalkRelation | null): ISemTalkObject[];
  InvLinkedObjects(attr: string | ISemTalkAssociationType, rec?: boolean, ptype?: SemTalkRelation | null): ISemTalkObject[];
  Links(attr: string | ISemTalkAssociationType, ptype?: SemTalkRelation | null): ISemTalkAssociation[];
  InvLinks(attr: ISemTalkAssociationType | string, ptype?: SemTalkRelation | null): ISemTalkAssociation[];
  HasLink(attr: ISemTalkAssociationType | string, other: ISemTalkObject, ptype?: SemTalkRelation | null): boolean;
  HasDirectLink(attr: ISemTalkAssociationType | string, other: ISemTalkObject, ptype?: SemTalkRelation | null): boolean;
  // IsValid(dst: ISemTalkObject, attr: ISemTalkAssociationType | string): boolean;
  Nodes(): ISemTalkNode[];
  FindNode(d: ISemTalkDiagram, s?: string): ISemTalkNode[];
  UpdateLabel(mastername?: string): ILabelSpec;
  Composition(): ISemTalkComposition | null;
  MakeComposition(cls: ISemTalkBusinessClass, met: ISemTalkMethodType | null, sta: ISemTalkStateType | null,
    attr: ISemTalkAttributeType | null, oth?: ISemTalkBusinessClass | null, op?: string,
    isnotop?: boolean, cmpvalue?: any): ISemTalkComposition;
  DeleteComposition(): void;
  // SetComposition(a: ISemTalkComposition | null): ISemTalkComposition | null;
  RenameObject(value: string): void;
  RemoveAttribute(a: ISemTalkAttribute): void;
  AddNode(n: ISemTalkNode): ISemTalkNode;
  RemoveNode(a: ISemTalkNode): void;
  AddAssociation(a: ISemTalkAssociation): ISemTalkAssociation;
  AddInvAssociation(a: ISemTalkAssociation): ISemTalkAssociation;
  RemoveAssociation(a: ISemTalkAssociation): ISemTalkAssociation;
  RemoveInvAssociation(a: ISemTalkAssociation): ISemTalkAssociation;
  FindAssociation(attr: string | ISemTalkAssociationType, toobj: ISemTalkObject, ptype?: SemTalkRelation | null): ISemTalkAssociation | null;
  MakeAttachment(link: string, label?: string, nsp?: string, suburl?: string,
    typ?: string, exi?: string, fra?: string, nwi?: boolean, icon?: string): ISemTalkInstance | null;
  GetAttachment(name: string): ISemTalkAssociation | null;
  DeleteAttachment(name: string): void;
  SystemClass(): ISemTalkSystemClass | null;
}
export interface ISemTalkClass extends ISemTalkObject {
  // IsSystemClass: boolean;
  IsReadOnly: boolean;
  Load(c: any): any;
  LoadXML(c: Element): void;
  Save(c: any): ISemTalkClass;
  SaveXML(x: XMLDocument, c: HTMLElement): void;
  ToInstance(): ISemTalkInstance;
  // Clone(c: ISemTalkClass): any;
  SuperClasses(): ISemTalkClass[];
  SubClasses(): ISemTalkClass[];
  AllSuperClasses(): ISemTalkClass[];
  AllSubClasses(): ISemTalkClass[];
  AllAttributes(): ISemTalkAttribute[];
  AllAssociations(): ISemTalkAssociation[];
  AllInvAssociations(): ISemTalkAssociation[];
  AllLinkedObjects(aname: string, rec?: boolean, ptype?: SemTalkRelation | null): ISemTalkObject[];
  AllInvLinkedObjects(aname: string, rec?: boolean, ptype?: SemTalkRelation | null): ISemTalkObject[];
  AllAttachments(): ISemTalkAssociation[];
  AllLinks(aname: string): ISemTalkAssociation[];
  AllInvLinks(aname: string): ISemTalkAssociation[];
  SystemClass(): ISemTalkSystemClass | null;
  IsParent(s: ISemTalkClass): boolean;
  MakeInstance(n?: string, t?: SemTalkType, i?: SemTalkID): ISemTalkInstance;
  // MakeSharePointInstance(n?: string, t?: SemTalkType, i?: string): ISemTalkSharePointInstance;
  Instances(): ISemTalkInstance[];
  AllInstances(): ISemTalkInstance[];
  MakeSubClass(n: string, i?: SemTalkID): ISemTalkClass;
  // MakeSharePointSubClass(n: string, _t?: SemTalkType, i?: string): ISemTalkSharePointClass;
  DeleteSubclassOf(c: ISemTalkClass): void;
  // ClassLabels(mastername?: string): SemTalkClassLabel[];
  AllValidClassRelations(dst: ISemTalkObject): ISemTalkAssociationType[];
  GetRelationOtherBases(rel: ISemTalkAssociationType, ptype?: SemTalkRelation | null): ISemTalkClass[];
  GetInvRelationOtherBases(rel: ISemTalkAssociationType, ptype?: SemTalkRelation | null): ISemTalkClass[];
  GetCurrentValue(aname: string, lang?: string): any;
  AddSubclassOf(c: ISemTalkClass): ISemTalkSpecialization | null;
  AddInstance(a: ISemTalkInstance): ISemTalkInstance;
  RemoveInstance(a: ISemTalkInstance): void;
  AddInvComposition(a: ISemTalkComposition): ISemTalkComposition | null;
  InvCompositions(): ISemTalkComposition[];
  RemoveInvComposition(a: ISemTalkComposition): void;
  IsLeaf: boolean;
  AllAssociationTypes(): ISemTalkAssociationType[];
  AllInvAssociationTypes(): ISemTalkAssociationType[];
  AllAttributeTypes(): ISemTalkAttributeType[];
  UsageCount(): number;
  IsValid(dst: ISemTalkObject, attr: ISemTalkAssociationType | string): boolean;
  UpdateLabel(mastername?: string): ILabelSpec;
  FindSpecialization(toobj: ISemTalkClass): ISemTalkSpecialization | null;
}
export interface ISemTalkInstance extends ISemTalkObject {
  ClassOf(): ISemTalkClass;
  SetClass(cla: ISemTalkClass): void;
  Load(c: any): any;
  LoadXML(c: Element): void;
  Save(c: any[]): any;
  SaveXML(x: XMLDocument, c: HTMLElement): void;
  ToClass(): ISemTalkClass;
  // Clone(c: ISemTalkInstance): any;
  IsValid(dst: ISemTalkObject, attr: ISemTalkAssociationType | string): boolean;
  IsInstance(dst: ISemTalkClass): boolean;
  AllAttributeTypes(): ISemTalkAttributeType[];
  AllAttributes(): ISemTalkAttribute[];
  ID2NameNsp(): string;
  UpdateLabel(mastername?: string): ILabelSpec;
}
export interface ISemTalkVirtualInstance {
  Owner: ISemTalkObject;
  PropName: string;
  Load(c: any): any;
  LoadXML(c: Element): void;
  Save(par: any[]): any;
  SaveXML(x: XMLDocument, c: HTMLElement): void;
  Clone(c: ISemTalkVirtualInstance): any;
  ClassOf(): ISemTalkClass;
  SetClass(cla: ISemTalkClass): void;
  Delete(): void;
  ObjectType: SemTalkType;
}
export interface ISemTalkAssociation extends ISemTalkInstance {
  ToObject: ISemTalkObject;
  FromObject: ISemTalkObject;
  PropName: string;
  ClassOf(): ISemTalkAssociationType;
  RelationType: SemTalkRelation;
  // SaveInvXML(x: XMLDocument, c: HTMLElement): void;
}
export interface ISemTalkSpecialization extends ISemTalkAssociation {
}
export interface ISemTalkMethod extends ISemTalkVirtualInstance {
  ClassOf(): ISemTalkMethodType;
}
export interface ISemTalkAttribute extends ISemTalkVirtualInstance {
  PropName: string;
  Value: any;
  ClassOf(): ISemTalkAttributeType;
  Values: string[] | null;
  Options: string[];
  ValueType: SemTalkValueType;
  IsList: boolean;
  Min: number | null;
  Max: number | null;
  NoHTML: boolean;
  Required: boolean;
  ReadOnly: boolean;
  Group: string;
  Weight: number;
  AllowFreeForm: boolean;
  CurrentValue(facet?: string): any;
  SetFacetValue(facet: string, value: any): any;
  GetFacetValue(facet: string): any;
}
export interface ISemTalkState extends ISemTalkVirtualInstance {
  ClassOf(): ISemTalkStateType;
}
export interface ISemTalkSPListClass extends ISemTalkClass {
}
export interface ISemTalkSharePointObject {
  UniqueID: number | null;
  URL: string | null;
  CTName: string | null;
  SPPrefix: string | null;
  RowID: number | null;
}
export interface ISemTalkSharePointClass extends ISemTalkClass, ISemTalkSharePointObject {
}
export interface ISemTalkSharePointInstance extends ISemTalkInstance, ISemTalkSharePointObject {
}
export interface ISemTalkSystemClass extends ISemTalkClass {
  SystemClass(): ISemTalkSystemClass;
  BottomUp: boolean;
  Hide: boolean;
  OnceOnly: boolean;
  ShowLabel: boolean;
  Style: string;
  ShapeHTML: string;
  ShowAname: boolean;
  ShowCAname: boolean;
  ShowUserNumber: boolean;
  BuiltinInstLabels(): SemTalkLayoutField[];
  BuiltinClassLabels(): SemTalkLayoutField[];
  RefinementOfClass: boolean;
  Refineable: boolean;
  // HyperlinksEnabled: boolean;
  Composeable: boolean;
  SharepointGoodlist: string;
  Labels(mastername?: string): ISemTalkLabel[];
  CollectInstLabels(lbl: ILabelSpec, obj: ISemTalkInstance, mastername?: string): boolean;
  CollectClassLabels(lbl: ILabelSpec, obj: ISemTalkClass, mastername?: string): boolean;
  CollectBusinessClassLabels(lbl: ILabelSpec, obj: ISemTalkClass, mastername?: string): boolean;
  AllLabels(): ISemTalkLabel[];
  AllClassLabels(): ISemTalkClassLabel[];
  AllInstanceLabels(): ISemTalkLabel[];
  AllSystemSubClasses(): ISemTalkSystemClass[];
  MakeLabel(text: string, master: string): ISemTalkLabel;
  MakeClassLabel(text: string, master: string): ISemTalkClassLabel;
  ClassLabels(mastername?: string): ISemTalkClassLabel[];
  TabSpecDefinitions(): ISemTalkTabSpecs;
  FindLabelForMaster(master: string, text?: string): ISemTalkLabel[];
  FindClassLabelForMaster(master: string, text?: string): ISemTalkClassLabel[];
  InitInstDialog(): ISemTalkInstDialog;
  InstDialog: ISemTalkInstDialog | null;
  InstanceTabSpecs(): ISemTalkTabSpec[];
  TabSpecs(): ISemTalkTabSpec[];
  ClassTabSpecs(): ISemTalkTabSpec[];
  InstanceTabSpecs(): ISemTalkTabSpec[];
  FindTabSpec(aname: string): ISemTalkTabSpec | null;
  Prefix: string | null;
  GetPrefix(): string;
}
export interface ISemTalkTabSpec {
  Owner: ISemTalkTabSpecs;
  IsClass: boolean;
  Visible: boolean;
  Text: string;
  Delete(): void;
  ObjectType: SemTalkType;
  Load(a: any): any;
  LoadXML(c: Element): void;
  Save(a: any): any;
  SaveXML(x: XMLDocument, c: HTMLElement): void;
}
export interface ISemTalkGenericTabSpec extends ISemTalkTabSpec {
  Relname: string;
  Toobj: string;
  IsInst: boolean;
  IsInv: boolean;
  IsUni: boolean;
  // Group: string;
}
export interface ISemTalkGenericAttributeTabSpec extends ISemTalkTabSpec {
  Attributes: string[];
  Group: string;
}
export interface ISemTalkTabSpecListTabSpec extends ISemTalkTabSpec {
  TabSpecs: string[];
  Group: string;
}
export interface ISemTalkTabSpecs {
  Owner: ISemTalkSystemClass;
  ObjectBase: IObjectBase;
  TabSpecs(): ISemTalkTabSpec[];
  TabInstanceSpecs(): ISemTalkTabSpec[];
  TabClassSpecs(): ISemTalkTabSpec[];
  GenericTabSpecs(): ISemTalkGenericTabSpec[];
  GenericTabClassSpecs(): ISemTalkGenericTabSpec[];
  GenericAttributeTabSpecs(): ISemTalkGenericAttributeTabSpec[];
  GenericAttributeTabClassSpecs(): ISemTalkGenericAttributeTabSpec[];
  FindTabSpec(aname: string): ISemTalkTabSpec | null;
  MakeTabSpec(text: string, isclass: boolean): ISemTalkTabSpec;
  MakeGenericTabSpec(text: string, relname: string, toobj: string, isinst: boolean, isinv: boolean, isuni: boolean, isclass: boolean): ISemTalkGenericTabSpec;
  MakeGenericAttributeTabSpec(text: string, group: string, attributes: string[], isclass: boolean): ISemTalkGenericAttributeTabSpec;
  MakeTabSpecListTabSpec(text: string, group: string, tabspecs: string[], isclass: boolean): ISemTalkTabSpecListTabSpec;
  Delete(): void;
  Load(je: any): void;
  LoadXML(c: Element): void;
  Save(c: any): void;
  SaveXML(x: XMLDocument, c: HTMLElement): void;
  AddTabSpec(a: ISemTalkTabSpec): ISemTalkTabSpec;
  RemoveTabSpec(a: ISemTalkTabSpec): void;

}
export interface ISemTalkInstDialog {
  CheckGeneral: boolean;
  CheckNamespace: boolean;
  CheckClass: boolean;
  CheckMethods: boolean;
  CheckAttributes: boolean;
  CheckAssociations: boolean;
  CheckSynonyms: boolean;
  CheckCondition: boolean;
  CheckAttachments: boolean;
  CheckStates: boolean;
  CheckAudit: boolean;
}
export interface ILabelSpec {
  newtxt: string;
  newtxt0: string;
  newtxt1: string;
  newtxt2: string;
  newtxt3: string;
  newtxt4: string;
  newtxt5: string;
  newtxt6: string;
  newtxt7: string;
  newtxt8: string;
  newtxt9: string;
  newtxt10: string;
  isuml: boolean;
  slabel: boolean;
  nolayoutinfo: boolean;
  hasimage: boolean;
  haslabels: boolean;
  showimage: boolean;
  usegrphx: boolean;
  imgfile: string;
  col: string;
  pat: string;
  wei: string;
}
export interface ISemTalkLabel {
  Master: string;
  Owner: ISemTalkLabels;
  Text: string;
  Save(par: any[]): void;
  SaveXML(x: XMLDocument, c: HTMLElement): void;
  Delete(): void;
}
export interface ISemTalkClassLabel extends ISemTalkLabel {
}
export interface ISemTalkLabels {
  Owner: ISemTalkSystemClass;
  ObjectBase: IObjectBase;
  ShowAname: boolean;
  ShowCAname: boolean;
  ShowUserNumber: boolean;
  AllLabels(): ISemTalkLabel[];
  Labels(mastername: string | undefined): ISemTalkLabel[];
  ClassLabels(mastername: string | undefined): ISemTalkClassLabel[];
  MakeLabel(text: string, master: string): ISemTalkLabel;
  MakeClassLabel(text: string, master: string): ISemTalkClassLabel;
  builtinInstLabels: SemTalkLayoutField[];
  builtinClassLabels: SemTalkLayoutField[];
  builtinBusinesClassLabels: SemTalkLayoutField[];
  Delete(): void;
  Save(c: any): void;
  SaveXML(x: XMLDocument, c: HTMLElement): void;
  Load(c: any): void;
  LoadXML(c: Element): void;
  AllClassLabels(): ISemTalkClassLabel[];
  AllInstanceLabels(): ISemTalkLabel[];
  FindLabelForMaster(master: string, text?: string): ISemTalkLabel[];
  FindClassLabelForMaster(master: string, text?: string): ISemTalkClassLabel[];
  CollectClassLabels(lbl: ILabelSpec, obj: ISemTalkClass, mastername?: string): boolean;
  CollectBusinessClassLabels(lbl: ILabelSpec, obj1: ISemTalkClass, mastername?: string): boolean;
  CollectInstLabels(lbl: ILabelSpec, obj: ISemTalkInstance, mastername?: string): boolean;
  RemoveLabel(a: ISemTalkLabel): void;
  RemoveClassLabel(a: ISemTalkLabel): void;
}
export interface ISemTalkAssociationType extends ISemTalkSystemClass {
  RelationType: SemTalkRelation;
  FromType: SemTalkType;
  ToType: SemTalkType;
  InvName: string;
  Cyclic: boolean;
  Symmetric: boolean;
  Transitiv: boolean;
  IsAggregation: boolean;
  Unique: boolean;
  IsContainer: boolean;
  UniqueModel: boolean;
  Expandable: boolean;
  AllowFreeForm: boolean;
  IsCompositionAssociation: boolean;
  FromConnection: SemTalkConnectionPoint;
  ToConnection: SemTalkConnectionPoint;
}
export interface ISemTalkBusinessClass extends ISemTalkClass {
  Methods(): ISemTalkMethod[];
  States(): ISemTalkState[];
  MakeState(attr: string | ISemTalkStateType): ISemTalkState;
  FindState(attr: string | ISemTalkStateType): ISemTalkState | null;
  FindAState(attr: string | ISemTalkStateType): ISemTalkState | null;
  RemoveState(a: ISemTalkState): void;
  AddState(a: ISemTalkState): ISemTalkState;
  AllStates(): ISemTalkState[];
  AllStateTypes(): ISemTalkStateType[];
  MakeMethod(attr: string | ISemTalkMethodType): ISemTalkMethod;
  FindMethod(attr: string | ISemTalkMethodType): ISemTalkMethod | null;
  FindAMethod(attr: string | ISemTalkMethodType): ISemTalkMethod | null;
  RemoveMethod(a: ISemTalkMethod): void;
  AddMethod(a: ISemTalkMethod): ISemTalkMethod;
  AllMethods(): ISemTalkMethod[];
  AllMethodTypes(): ISemTalkMethodType[];
  DeleteMethod(attr: string | ISemTalkMethodType): void;
  DeleteState(attr: string | ISemTalkStateType): void;
}
export interface ISemTalkMethodType extends ISemTalkClass {
}
export interface ISemTalkAttributeType extends ISemTalkClass {
  Value: any;
  Values: string[];
  Options: string[];
  ValueType: SemTalkValueType;
  IsList: boolean;
  Min: number | null;
  Max: number | null;
  SyncVisio: boolean;
  Raise: boolean;
  Required: boolean;
  Group: string;
  Weight: number;
  AllowFreeForm: boolean;
}
export interface ISemTalkStateType extends ISemTalkClass {
}
export interface ISemTalkSynonym {
  Owner: ISemTalkNamedThing;
  Name: string;
  Language: string;
  Load(c: any): any;
  LoadXML(c: Element): void;
  Save(c: any): any;
  SaveXML(x: XMLDocument, c: HTMLElement): void;
  Inverse: boolean;
  Synonym: boolean;
  Comment: string | null;
}

export interface ISemTalkDiagram extends ISemTalkInstance {
  ClassOf(): ISemTalkDiagramType;
  SystemClass(): ISemTalkDiagramType | null;
  AddInvRefinement(o: ISemTalkObject): ISemTalkObject;
  RemoveInvRefinement(o: ISemTalkObject): void;
  MakeNode(obj: ISemTalkObject, shpid: string, master?: string): ISemTalkNode;
  Contents(): ISemTalkNode[];
  InvRefinements(): ISemTalkDiagram[];
  FindNodeOf(obj: ISemTalkObject): ISemTalkNode[];
  FindNodeOfShape(shpid: string): ISemTalkNode | null;
  IsLeaf: boolean;
  Refinements(): ISemTalkDiagram[];
  ExtRefinements(): string[];
  BreadCrumbs(): ISemTalkDiagram[];
  RefinedObjects(): ISemTalkObject[];
  GetVariant(): ISemTalkDiagram;
  NewUserNumber(): string;
  Visible: boolean;
  Horizontal: boolean;
}
export interface ISemTalkDiagramType extends ISemTalkSystemClass {
  IsClass: boolean;
  Root: ISemTalkSystemClass | null;
  InstanceRoot: ISemTalkSystemClass | null;
  NewSLRoot: ISemTalkSystemClass | null;
  UseInheritanceRelations: boolean;
  UseAllRelationsOfSubclasses: boolean;
  MakeInstance(n?: string, t?: SemTalkType, i?: SemTalkID): ISemTalkDiagram;
  Instances(): ISemTalkDiagram[];
  IsAllowedMaster(o: ISemTalkObject, s: string): boolean;
  IsAllowed(o: ISemTalkObject): boolean;
  FindMasters(mst: string): ISemTalkSystemClass[];
  GetValidDiagTypRefinementOf(): ISemTalkDiagramType[];
  GetValidDiagTypRefinements(): ISemTalkDiagramType[];
  AllRootDiagrams(): ISemTalkDiagram[];
  UMLShape: boolean;
  MasterNames: string[];
  AllowedMasters(o: ISemTalkObject): string[];
  DiagramTypePrefix: string | null;
  // DiagramTypePrefix(o: ISemTalkNamedThing): string;
  Auto: boolean;
  Stencil: string | null;
  CustomStencil: SemTalkStencil;
}
export interface ISemTalkNode {
  ID: string;
  Model: ISemTalkObject;
  Diagram: ISemTalkDiagram;
  ShapeID: string;
  ParentShapeID: string | undefined;
  absolutePosition: { x: number, y: number };
  Master: string | undefined;
  Style: string | undefined;
  Delete(): void;
  X: number | null;
  Y: number | null;
  Width: number | null;
  Height: number | null;
  Load(c: any): any;
  LoadXML(c: Element): void;
  Save(c: any): any;
  SaveXML(x: XMLDocument, c: HTMLElement): void;
  ObjectCaption: string;
}
export interface ISemTalkAssociationNode extends ISemTalkNode {
  FromShape: string | undefined;
  OtherShape: string | undefined;
  BeginX: number | undefined;
  BeginY: number | undefined;
  EndX: number | undefined;
  EndY: number | undefined;

}
export interface ISemTalkComposition {
  Owner: ISemTalkObject;
  ComposedClass: ISemTalkBusinessClass;
  Attribute: ISemTalkAttributeType | null;
  State: ISemTalkStateType | null;
  Method: ISemTalkMethodType | null;
  Other: ISemTalkBusinessClass | null;
  Delete(): void;
  Save(): any;
  SaveXML(x: XMLDocument, c: HTMLElement): void;
  Op: string | null;
  NotOp: boolean | null;
  Value: any;
  ID2NameNsp(): string;
}

export enum SemTalkAssignment {
  assignment = "=",
  increment = "+=",
  decrement = "-=",
  new = " = new",
  delete = "delete"
}
export enum SemTalkOperator {
  eq = '==',
  ne = '!=',
  gt = '>',
  lt = '<',
  ge = '>=',
  le = '<=',
  in = 'in',
  ni = 'not in',
  is = 'is',
  isnot = 'is not',
  // like = 'like',
  // notlike = 'not like',
  and = 'and',
  or = 'or',
  not = 'not',
  // between = 'between',
  // notbetween = 'not between',
  // inlist = 'inlist',
  // notinlist = 'not inlist',
  // inrange = 'inrange',
  // notinrange = 'not inrange'
}
export interface IExpression {
  var: string;
  op: SemTalkOperator | SemTalkAssignment;
  val: string;
}

