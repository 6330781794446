import { SemTalkMaster } from "../SemTalkMaster";
import { SemTalkBuiltInShape, SemTalkStyleAttribute } from "./visiordfsinterface";
export const CLASS_SHAPES: any = {};
CLASS_SHAPES[SemTalkMaster.MasterClass] = {
  type: 'vertex',
  style: SemTalkStyleAttribute.shape + '=' +
    SemTalkBuiltInShape.genericclass + ';whiteSpace=wrap;' +
    SemTalkStyleAttribute.resize + '=1;html=1;' +
    SemTalkStyleAttribute.strokeWidth + '=2;' +
    SemTalkStyleAttribute.rounded + '=1;',
};
CLASS_SHAPES[SemTalkMaster.MasterUMLClass] = {
  type: 'vertex',
  style: SemTalkStyleAttribute.shape + '=' +
    SemTalkBuiltInShape.umlclass + ';whiteSpace=wrap;' +
    SemTalkStyleAttribute.resize + '=1;html=1;' +
    SemTalkStyleAttribute.strokeWidth + '=2;',
};
CLASS_SHAPES[SemTalkMaster.MasterComment] = {
  type: 'vertex',
  style: 'whiteSpace=wrap;html=1;',
};
CLASS_SHAPES[SemTalkMaster.MasterAttachment] = {
  type: 'vertex',
  style: 'whiteSpace=wrap;html=1;',
};
