import * as stgoto from "./stgoto";
import {
  ISemTalkDiagram,
  ISemTalkObject, SemTalkID, SemTalkType
} from '../tbase/Interface';
import { IVisioRDFS, SemTalkNavigationEvent } from "./visiordfsinterface";
import { MessageBarType } from "office-ui-fabric-react";

export function gotoDocument(d: SemTalkID) {
  if (d === '0' || d !== stgoto.getDiagram()) {
    gotoDocumentInternal(d);
    const sem: IVisioRDFS = (window as any).semtalk;
    const bas = sem.base;
    const diag = bas.FindDiagramByID(d) as ISemTalkDiagram;

    if (!diag) {
      console.debug("GotoDocument bad Diagram ID: " + d);
    }
    if (diag) {
      let m: any = {};
      m.type = SemTalkNavigationEvent.gotoDocument;
      m.pagename = diag.ObjectName;
      const mod = diag.ObjectBase;
      m.modelname = mod.ObjectName;
      m.modelid = mod.ID;
      m.diagid = diag.ID;
      setDiagram(diag.ID);
      stgoto.post2Callbacks(m);
      // var mstr = JSON.stringify(m);
      // bas.Log(mstr);
      // setModel(mod.ID);
      // window.parent.postMessage(mstr, '*');
    }
  }
}
function gotoDocumentInternal(d: SemTalkID) {
  if (stgoto.getDiagram() !== d) {
    stgoto.setObject(null);
    // var pgid = 1;
    stgoto.setDiagram(d);
    // stclear.Clear('searchpageresults');
    // Doit();
    // DoitDiagram();
  }
}
//export function addCallBack(crtl: React.Component, _eventListener: EventListenerOrEventListenerObject): string {
export function addCallBack(crtl: React.Component): string {
  // window.addEventListener("message", _eventListener);
  return stgoto.addCallBack(crtl);
}
//export function removeCallBack(crtl: React.Component, _eventListener: EventListenerOrEventListenerObject): void {
export function removeCallBack(crtl: React.Component): void {
  // window.removeEventListener("message", _eventListener);
  return stgoto.removeCallBack(crtl);
}

export function getObject(): SemTalkID | null {
  return stgoto.getObject();
}
export function getDiagram(): SemTalkID | null {
  return stgoto.getDiagram();
}
export function setDiagram(_obj: SemTalkID): void {
  stgoto.setDiagram(_obj);
}
export function getShape(): string {
  return stgoto.getShape();
}
export function getContext(): string {
  return stgoto.getContext();
}

export function gotoObject(o: SemTalkID | null) {
  // console.log("gotoObject: ", o);
  if (stgoto.getObject() !== o) {
    stgoto.setObject(o);
    if (o !== null) {
      const sem: IVisioRDFS = (window as any).semtalk;
      const bas = sem.base;
      const obj = bas.FindObjectByID(o) as ISemTalkObject;
      if (obj !== null) {
        const mod = obj.ObjectBase;
        var m: any = {};
        m.type = SemTalkNavigationEvent.gotoObject;
        m.modelname = mod.ObjectName;
        m.objectname = obj.ObjectName;
        m.objectcaption = obj.ObjectCaption;
        m.modelid = mod.ID;
        m.objectid = obj.ID;
        // var mstr = JSON.stringify(m);
        stgoto.post2Callbacks(m);
        // bas.Log(mstr);
      }
    }
  }
}
export function gotoObjectByName(name: string) {
  const sem: IVisioRDFS = (window as any).semtalk;
  const bas = sem.base;
  const obj = bas.FindObject(SemTalkType.SemTalkClass, name) as ISemTalkObject;
  gotoObject(obj.ID);
}
export function gotoNodeByID(nd: string) {
  const sem: IVisioRDFS = (window as any).semtalk;
  const bas = sem.base;
  const n = bas.FindNodeByID(nd);
  if (n !== null) {
    gotoNode(n.ID, n.Model.ID, n.Diagram.ID, n.ShapeID);
  }
}

export async function gotoNode(_nd: string, val: SemTalkID, pg: SemTalkID, sh: string, select: boolean = false, center: boolean = true) {
  if (stgoto.getDiagram() !== pg) {
    gotoDocumentInternal(pg);
  }
  stgoto.setObject(val);
  //   stgoto.setDiagram(pg);
  // if (stgoto.getShape() !== sh && stgoto.getDiagram() === pg) {
  if (true) {
    stgoto.setShape(sh);
    const sem: IVisioRDFS = (window as any).semtalk;
    const bas = sem.base;
    const diag = bas.FindDiagramByID(pg);
    if (diag !== null) {
      const node = diag.FindNodeOfShape(sh);
      if (node !== null) {
        const obj = node.Model;
        const mod = obj.ObjectBase;

        var m: any = {};
        m.type = SemTalkNavigationEvent.gotoNode;
        m.pagename = diag.ObjectName;
        m.modelname = mod.ObjectName;
        m.objectname = obj.ObjectName;
        m.objectcaption = obj.ObjectCaption;
        m.modelid = mod.ID;
        m.objectid = obj.ID;
        // m.nodeid = node.ID;
        m.diagid = diag.ID;
        m.shapeid = sh;
        m.select = select;
        m.center = center;
        stgoto.post2Callbacks(m);
        // var mstr = JSON.stringify(m);
        //  bas.Log(mstr);
        // window.parent.postMessage(mstr, "*");
      }
    }
  }
}
export async function gotoShape(_cell: any) {
  var m: any = {};
  m.type = SemTalkNavigationEvent.visSelectionChanged;
  stgoto.post2Callbacks(m);
  var mstr = JSON.stringify(m);
  //  bas.Log(mstr);
  window.parent.postMessage(mstr, "*");
}
export async function gotoText(txt: string, filename: string) {
  var m: any = {};
  m.type = SemTalkNavigationEvent.gotoText;
  m.text = txt;
  m.filename = filename;

  stgoto.post2Callbacks(m);
}

export function showNode(_sh: string) {
}

export function alert(msg: string, mtype: MessageBarType) {
  var m: any = {};
  m.type = SemTalkNavigationEvent.alert;
  m.mtype = mtype;
  m.msg = msg;
  stgoto.post2Callbacks(m);
  // var mstr = JSON.stringify(m);
  // window.parent.postMessage(mstr, '*');
}
export function settingsChanged() {
  var m: any = {};
  m.type = SemTalkNavigationEvent.settings;
  m.objectid = getObject();
  stgoto.post2Callbacks(m);
  // var mstr = JSON.stringify(m);
  // window.parent.postMessage(mstr, '*');
}