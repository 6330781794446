import { SemTalkBaseConstant } from "../tbase/Interface";
import { SemTalkMaster } from "../SemTalkMaster";
import { SemTalkShapeType, SemTalkStencil } from "./visiordfsinterface";
export const CLASS_STENCIL: SemTalkStencil = [
  {
    name: SemTalkMaster.MasterClass,
    key: SemTalkMaster.MasterClass,
    logo: '/images/symbols/class.svg',
    width: 80,
    height: 60,
    type: SemTalkShapeType.class,
    label: "STRDLHTMLOUTCLA",
    hidden: false
  },
  {
    name: SemTalkBaseConstant.SLComment,
    key: SemTalkMaster.MasterComment,
    logo: '/images/symbols/comment.svg',
    width: 60,
    height: 60,
    type: SemTalkShapeType.class,
    label: "STRCOMMENT",
    hidden: false
  },
  {
    name: 'Text',
    key: 'Text',
    logo: '/images/symbols/textbox.svg',
    width: 60,
    height: 60,
    type: SemTalkShapeType.general,
    hidden: false,
    label: "Text"
  },
  {
    name: 'Image',
    key: 'Image',
    logo: '/images/symbols/image.svg',
    width: 60,
    height: 60,
    type: SemTalkShapeType.general,
    hidden: false,
    label: "Image"
  },
  {
    name: 'Attachment',
    key: 'Attachment',
    logo: '/images/symbols/attachment.svg',
    width: 40,
    height: 40,
    type: SemTalkShapeType.general,
    hidden: false,
    label: "Attachment"
  },
  {
    name: 'Connector',
    key: 'Connector',
    hidden: true,
    logo: '/images/symbols/connect.gif',
    width: 80,
    height: 60,
    type: SemTalkShapeType.general,
    label: "Connector"
  },
  {
    name: 'SubClassOf',
    key: SemTalkMaster.MasterSubClassOf,
    hidden: true,
    logo: '/images/symbols/arrow.gif',
    width: 80,
    height: 60,
    type: SemTalkShapeType.general,
    label: "SubClassOf"
  },
  {
    name: 'InstanceOf',
    key: SemTalkMaster.MasterInstanceOf,
    hidden: true,
    logo: '/images/symbols/arrow.gif',
    width: 80,
    height: 60,
    type: SemTalkShapeType.general,
    label: "InstanceOf"
  },
  {
    name: 'Property',
    key: SemTalkMaster.MasterProperty,
    hidden: true,
    logo: '/images/symbols/connect.gif',
    width: 80,
    height: 60,
    type: SemTalkShapeType.general,
    label: "Property"
  }
];