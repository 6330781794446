import {
  IObjectBase,
  ISemTalkObject,
  ISemTalkClass,
  ISemTalkInstance,
  ISemTalkAttributeType,
  ISemTalkStateType,
  ISemTalkAssociationType,
  ISemTalkMethodType,
  ISemTalkDiagramType,
  ISemTalkDiagram,
  ISemTalkNamedThing,
  ISemTalkAttribute,
  ISemTalkMethod,
  ISemTalkState,
  ISemTalkAssociation,
  ISemTalkSpecialization,
  ISemTalkSynonym,
  ISemTalkSystemClass,
  ISemTalkBusinessClass,
  ISemTalkComposition,
  SemTalkType, SemTalkRelation,
  ISemTalkNode,
  // ISemTalkSharePointClass,
  SemTalkValueType,
  SemTalkConnectionPoint,
  SemTalkBaseConstant,
  SemTalkLanguage,
  SemTalkObjectBaseEvent,
  ModelAttribute,
  SemTalkComposeOrder,
  SemTalkID,
  IID2NumericConverter
} from "./Interface";

// import { OB2JSON } from "./OB2JSON";

import {
  SemTalkClass, SemTalkInstance, SemTalkAssociation, SemTalkAttribute,
  SemTalkAttributeType
} from './Tbase';
import { SemTalkDiagram, SemTalkNode, SemTalkAssociationNode, SemTalkDiagramType } from './Node';
import { SemTalkBusinessClass, SemTalkMethodType, SemTalkStateType, SemTalkComposition } from './BusinessClass';
import { LabelSpec } from "./Label";
import { SemTalkSystemClass, SemTalkAssociationType } from './SystemClass';
import { Guid } from "guid-typescript";

// tslint:disable:max-classes-per-file
// tslint:disable:variable-name

/* tslint:disable:member-ordering */
/* tslint:disable:forin */

export class ID2NumericConverter {
  public maxid: number;
  public id2number: Map<string, string>;
  constructor(m: number) {
    this.maxid = m;
    this.id2number = new Map<string, string>();
  }

  public convertID(id: string): SemTalkID {
    if (isNaN(+id)) {
      if (this.id2number.has(id)) {
        return this.id2number.get(id) as string;
      } else {
        this.maxid += 1;
        this.id2number.set(id, this.maxid.toString());
        return this.maxid.toString();
      }
    } else {
      return id;
    }
  }
}
export class ObjectBase implements IObjectBase {
  public _objects: { [name: string]: ISemTalkObject } = {};
  public _objectids: { [id: string]: ISemTalkObject } = {};
  public _classes: { [id: string]: ISemTalkClass } = {};
  public _instances: { [id: string]: ISemTalkInstance } = {};
  public _attrtypes: { [id: string]: ISemTalkAttributeType } = {};
  public _statetypes: { [id: string]: ISemTalkStateType } = {};
  public _assoctypes: { [id: string]: ISemTalkAssociationType } = {};
  public _methodtypes: { [id: string]: ISemTalkMethodType } = {};
  public _diagtypes: { [id: string]: ISemTalkDiagramType } = {};
  public _diags: { [id: string]: ISemTalkDiagram } = {};
  public _callbacks: any[] = [];

  public ObjectName: string = "";
  public ID: number = 0;
  public _currentnsp: SemTalkLanguage;
  public get CurrentNsp(): SemTalkLanguage {
    return this._currentnsp;
  }

  public maxid: number = 0;
  public NewID(): SemTalkID {
    return ObjectBase.String2SemTalkID(Guid.create().toString());
  }

  public static String2SemTalkID(s: string): SemTalkID {
    // return parseInt(s);
    return s;
  }
  // private _showNamespace: boolean = false;
  public SetShowNamespace(v: boolean) {
    // this._showNamespace = v;
    let vs: string = "false";
    if (v) vs = "true";
    this.SetModelAttribute(ModelAttribute.showNamespace, vs);
  }
  public ShowNamespace(): boolean {
    return this.GetModelAttribute(ModelAttribute.showNamespace) === "true";
  }
  private id2number: ID2NumericConverter | null = null;
  public SetID2NumberConverter(enable: boolean): IID2NumericConverter | null {
    if (enable) {
      this.id2number = new ID2NumericConverter(this.maxid);
      return this.id2number;
    } else {
      let c = this.id2number;
      if (this.id2number !== null) {
        this.id2number = null;
      }
      return c;
    }
  }
  public convertID(id: string): SemTalkID {
    if (this.id2number === null) {
      return id;
    }
    return this.id2number.convertID(id);
  }

  public _loading: boolean = false;
  public User: string = "";

  constructor() {
    this.SetModelAttribute(ModelAttribute.forder, SemTalkComposeOrder.NounVerb);
    this.SetModelAttribute(ModelAttribute.Template, "semtalk.vst");
    this.SetModelAttribute(ModelAttribute.modname, "");
    // this._showNamespace = this.GetModelAttribute(ModelAttribute.showNamespace) === "true";

  }

  public PostEvent(evt: any, obj: ISemTalkNamedThing | null, arg0?: any, arg1?: any, arg2?: any): void {
    if (!this._loading) {
      const m: any = {};
      m.type = evt;
      m.modelname = this.ObjectName;
      if (obj !== null) {
        m.objectname = obj.ObjectName;
        let s: string;
        s = obj.ObjectCaption;
        m.objectcaption = s;
        m.objectid = obj.ID;
        m.semtalktype = obj.ObjectType;
      }
      m.modelid = this.ID;
      m.arg0 = arg0;
      m.arg1 = arg1;
      m.arg2 = arg2;
      const mstr = JSON.stringify(m);
      this.LogAsync(mstr);
      // if (typeof window !== 'undefined') {
      //   window.parent.postMessage(mstr, '*');
      // }
      for (const cb of this._callbacks) {
        cb.getMessage({ "data": mstr, "sender": this });
      }
    }
  }
  // public PostAny(m: any): void {
  //      const mstr = JSON.stringify(m);
  //     this.LogAsync(mstr);
  //     if (typeof window !== 'undefined') {
  //       window.parent.postMessage(mstr, '*');
  //     }
  //     for (const cb of this._callbacks) {
  //       cb.getMessage({ "data": mstr, "sender": this });
  //     }
  // }
  public Log(msg: any): void {
    // tslint:disable-next-line:no-console
    console.log(msg);
  }
  public LogAsync(_msg: any, _succeed?: () => void, _nextfunction?: () => void): void {
    // tslint:disable-next-line:no-console
    // console.log(msg);
  }

  public OnCreated(obj: ISemTalkObject): void { this.PostEvent(SemTalkObjectBaseEvent.OnCreated, obj, obj.ObjectType, obj.ID, null); }
  public OnBeforeDeleted(obj: ISemTalkObject): void { this.PostEvent(SemTalkObjectBaseEvent.OnBeforeDeleted, obj, null, null, null); }
  public OnDeleted(obj: ISemTalkObject): void { this.PostEvent(SemTalkObjectBaseEvent.OnDeleted, obj, null, null, null); }
  public OnRenamed(obj: ISemTalkObject, oldname: string): void { this.PostEvent(SemTalkObjectBaseEvent.OnRenamed, obj, oldname, null, null); }
  public OnCommentChanged(obj: ISemTalkObject, oldvalue: string | null, newvalue: string | null): void { this.PostEvent(SemTalkObjectBaseEvent.OnCommentChanged, obj, oldvalue, newvalue, null); }
  public OnRefined(obj: ISemTalkObject, old: ISemTalkDiagram): void { this.PostEvent(SemTalkObjectBaseEvent.OnRefined, obj, old.ObjectName, null, null); }
  public OnDetached(obj: ISemTalkObject, value: ISemTalkDiagram): void { this.PostEvent(SemTalkObjectBaseEvent.OnDetached, obj, value.ObjectName, null, null); }
  public OnExtRefined(obj: ISemTalkObject, old: string): void { this.PostEvent(SemTalkObjectBaseEvent.OnExtRefined, obj, old, null, null); }
  public OnExtDetached(obj: ISemTalkObject, value: string): void { this.PostEvent(SemTalkObjectBaseEvent.OnExtDetached, obj, value, null, null); }
  public OnComposed(obj: ISemTalkObject): void { this.PostEvent(SemTalkObjectBaseEvent.OnComposed, obj, null, null, null); }
  public OnUnComposed(obj: ISemTalkObject): void { this.PostEvent(SemTalkObjectBaseEvent.OnUnComposed, obj, null, null, null); }
  public OnAttributeAdded(obj: ISemTalkAttribute): void { this.PostEvent(SemTalkObjectBaseEvent.OnAttributeAdded, obj.Owner, obj.PropName, null, null); }
  public OnAttributeDeleted(obj: ISemTalkAttribute): void { this.PostEvent(SemTalkObjectBaseEvent.OnAttributeDeleted, obj.Owner, obj.PropName, null, null); }
  public OnAttributeRenamed(obj: ISemTalkAttribute): void { this.PostEvent(SemTalkObjectBaseEvent.OnAttributeRenamed, obj.Owner, obj.PropName, null, null); }
  public OnValueChanged(obj: ISemTalkAttribute, oldvalue: any): void { this.PostEvent(SemTalkObjectBaseEvent.OnValueChanged, obj.Owner, oldvalue, obj.Value, obj.ClassOf().ObjectName); }
  public OnUserNumberChanged(obj: ISemTalkAttribute, oldvalue: string): void { this.PostEvent(SemTalkObjectBaseEvent.OnUserNumberChanged, obj.Owner, oldvalue, obj.Value, null); }
  public OnColorChanged(obj: ISemTalkAttribute, oldvalue: string): void { this.PostEvent(SemTalkObjectBaseEvent.OnColorChanged, obj.Owner, oldvalue, obj.Value, null); }
  public OnStateAdded(obj: ISemTalkState): void { this.PostEvent(SemTalkObjectBaseEvent.OnStateAdded, obj.Owner, obj.PropName, null, null); }
  public OnStateRenamed(obj: ISemTalkState, oldvalue: any): void { this.PostEvent(SemTalkObjectBaseEvent.OnStateRenamed, obj.Owner, oldvalue, null, null); }
  public OnStateDeleted(obj: ISemTalkState): void { this.PostEvent(SemTalkObjectBaseEvent.OnStateDeleted, obj.Owner, obj.PropName, null, null); }
  public OnMethodAdded(obj: ISemTalkMethod): void { this.PostEvent(SemTalkObjectBaseEvent.OnMethodAdded, obj.Owner, obj.PropName, null, null); }
  public OnMethodDeleted(obj: ISemTalkMethod): void { this.PostEvent(SemTalkObjectBaseEvent.OnMethodDeleted, obj.Owner, null, null, null); }
  public OnAssociationAdded(obj: ISemTalkAssociation): void { this.PostEvent(SemTalkObjectBaseEvent.OnAssociationAdded, obj, obj.PropName, obj.ToObject.ObjectName, obj.ToObject.ID); }
  public OnAssociationDeleted(obj: ISemTalkAssociation): void { this.PostEvent(SemTalkObjectBaseEvent.OnAssociationDeleted, obj, obj.PropName, obj.FromObject.ID, obj.ToObject.ID); }
  public OnAssociationBeforeDeletedByID(obj: ISemTalkAssociation): void { this.PostEvent(SemTalkObjectBaseEvent.OnAssociationBeforeDeleted, obj, obj.PropName, null, null); }
  public OnAssociationBeforeDeleted(obj: ISemTalkAssociation): void { this.PostEvent(SemTalkObjectBaseEvent.OnAssociationBeforeDeleted, obj, obj.PropName, null, null); }
  public OnSubClassCreated(obj: ISemTalkSpecialization): void { this.PostEvent(SemTalkObjectBaseEvent.OnSubClassCreated, obj, obj.PropName, obj.ToObject.ObjectName, obj.ToObject.ID); }
  public OnSubClassDeleted(obj: ISemTalkSpecialization): void { this.PostEvent(SemTalkObjectBaseEvent.OnSubClassDeleted, obj, obj.PropName, obj.FromObject.ID, obj.ToObject.ID); }
  public OnAttachmentAdded(obj: ISemTalkAssociation): void { this.PostEvent(SemTalkObjectBaseEvent.OnAttachmentAdded, obj.FromObject, obj.PropName, obj.ToObject.ObjectName, null); }
  public OnAttachmentDeleted(obj: ISemTalkAssociation): void { this.PostEvent(SemTalkObjectBaseEvent.OnAttachmentDeleted, obj.FromObject, obj.PropName, obj.ToObject.ObjectName, null); }
  public OnAttachmentRenamed(obj: ISemTalkAssociation): void { this.PostEvent(SemTalkObjectBaseEvent.OnAttachmentRenamed, obj.FromObject, obj.PropName, obj.ToObject.ObjectName, null); }
  public OnNodeCreated(obj: ISemTalkObject, d: ISemTalkDiagram, s: string): void { this.PostEvent(SemTalkObjectBaseEvent.OnNodeCreated, obj, d.ObjectName, s, null); }
  public OnNodeDeleted(obj: ISemTalkObject, d: ISemTalkDiagram, s: string): void { this.PostEvent(SemTalkObjectBaseEvent.OnNodeDeleted, obj, d.ObjectName, s, null); }
  public OnClassBeforeDeleted(obj: ISemTalkClass): void { this.PostEvent(SemTalkObjectBaseEvent.OnClassBeforeDeleted, obj, null, null, null); }
  public OnClassDeleted(obj: ISemTalkClass): void { this.PostEvent(SemTalkObjectBaseEvent.OnClassDeleted, obj, null, null, null); }
  public OnInstanceDeleted(obj: ISemTalkInstance): void { this.PostEvent(SemTalkObjectBaseEvent.OnInstanceDeleted, obj, null, null, null); }
  public OnAttributeTypeDeleted(obj: ISemTalkAttributeType): void { this.PostEvent(SemTalkObjectBaseEvent.OnAttributeTypeDeleted, obj, null, null, null); }
  public OnAssociationTypeDeleted(obj: ISemTalkAssociationType): void { this.PostEvent(SemTalkObjectBaseEvent.OnAssociationTypeDeleted, obj, null, null, null); }
  public OnMethodTypeDeleted(obj: ISemTalkMethodType): void { this.PostEvent(SemTalkObjectBaseEvent.OnMethodTypeDeleted, obj, null, null, null); }
  public OnStateTypeDeleted(obj: ISemTalkStateType): void { this.PostEvent(SemTalkObjectBaseEvent.OnStateTypeDeleted, obj, null, null, null); }
  public OnDiagramTypeDeleted(obj: ISemTalkDiagramType): void { this.PostEvent(SemTalkObjectBaseEvent.OnDiagramTypeDeleted, obj, null, null, null); }
  public OnDiagramDeleted(obj: ISemTalkDiagram): void { this.PostEvent(SemTalkObjectBaseEvent.OnDiagramDeleted, obj, null, null, null); }
  public OnDiagramCreated(obj: ISemTalkDiagram): void { this.PostEvent(SemTalkObjectBaseEvent.OnDiagramCreated, obj, obj.ObjectType, obj.ID, null); }
  //  public OnDiagramClassChanged(obj: ISemTalkDiagram): void { this.PostEvent(SemTalkObjectBaseEvent.OnDiagramClassChanged, obj, obj.ObjectType, obj.ID, null); }
  public OnSynonymAdded(obj: ISemTalkSynonym): void { this.PostEvent(SemTalkObjectBaseEvent.OnSynonymAdded, obj.Owner, obj.Name, obj.Language, null); }
  public OnSynonymDeleted(obj: ISemTalkSynonym): void { this.PostEvent(SemTalkObjectBaseEvent.OnSynonymDeleted, obj.Owner, obj.Name, obj.Language, null); }
  public OnSynonymRenamed(obj: ISemTalkSynonym, oldname: string): void { this.PostEvent(SemTalkObjectBaseEvent.OnSynonymRenamed, obj.Owner, obj.Name, obj.Language, oldname); }
  public OnClassChanged(ins: ISemTalkInstance, old: ISemTalkClass | null): void { this.PostEvent(SemTalkObjectBaseEvent.OnClassChanged, ins, old ? old.ObjectName : null, null, null); }
  public OnLoaded(filename: string): void { this.PostEvent(SemTalkObjectBaseEvent.OnLoaded, null, filename, null, null); }
  public OnSaved(filename: string): void { this.PostEvent(SemTalkObjectBaseEvent.OnSaved, null, filename, null, null); }
  public OnBeforeSaved(filename: string): void { this.PostEvent(SemTalkObjectBaseEvent.OnBeforeSaved, null, filename, null, null); }
  public OnModelAttributeChanged(attr: string, oldvalue: any, newvalue: any): void { this.PostEvent(SemTalkObjectBaseEvent.OnModelAttributeChanged, null, attr, oldvalue, newvalue); }

  public GetModelAttribute(name: string): string {
    return this._modelattr[name];
  }
  public SetModelAttribute(name: string, value: any): void {
    const oldvalue = this._modelattr[name];
    if (name && value !== oldvalue) {
      this._modelattr[name] = value;
      switch (name) {
        case "currentnsp":
          this._currentnsp = value;
      }
      if (!name.startsWith(SemTalkBaseConstant.CookiePrefix) &&
        !name.startsWith(SemTalkBaseConstant.SLMXGPagePrefix) &&
        !name.startsWith(SemTalkBaseConstant.SLSVGPagePrefix)
      ) {
        this.OnModelAttributeChanged(name, oldvalue, value);
      }
    }
  }
  public DeleteModelAttribute(name: string): void {
    delete this._modelattr[name];
  }
  // public AllLanguages(): string[] {
  //   return this._languages;
  // }
  public AllModelAttributes(): string[] {
    let mlist: string[] = [];
    for (const i in this._modelattr) {
      //   mlist.push(this._modelattr[i]);
      mlist.push(i);
    }
    return mlist;
  }
  public _modelattr: { [name: string]: string } = {};

  public FindAttributeType(name: string): ISemTalkAttributeType | null {
    let x = Object.values(this._attrtypes).find(s => s.ObjectName === name);
    if (x) { return x; }
    return null;
  }
  public FindStateType(name: string): ISemTalkStateType | null {
    let x = Object.values(this._statetypes).find(s => s.ObjectName === name);
    if (x) { return x; }
    return null;
  }
  public FindMethodType(name: string): ISemTalkMethodType | null {
    let x = Object.values(this._methodtypes).find(s => s.ObjectName === name);
    if (x) { return x; }
    return null;
  }
  public FindAssociationType(name: string): ISemTalkAssociationType | null {
    let x = Object.values(this._assoctypes).find(s => s.ObjectName === name);
    if (x) { return x; }
    return null;
  }
  public FindDiagramType(name: string): ISemTalkDiagramType | null {
    let x = Object.values(this._diagtypes).find(s => s.ObjectName === name);
    if (x) { return x; }
    return null;
  }
  public FindClass(name: string): ISemTalkClass | null {
    let x = Object.values(this._classes).find(s => s.ObjectName === name);
    if (x) { return x; }
    return null;
  }
  public FindClassByID(id: SemTalkID): ISemTalkClass | null {
    const s: ISemTalkObject = this._objectids[id + "_"];
    if (s === undefined) { return null; }
    if (!this.IsClass(s)) { return null; }
    return s as ISemTalkClass;
  }
  public FindSystemClass(name: string): ISemTalkSystemClass | null {
    let x = Object.values(this._classes).find(s => s.ObjectName === name && s instanceof SemTalkSystemClass);
    if (x) { return x as ISemTalkSystemClass; }
    return null;
  }
  public FindSystemClassByID(id: SemTalkID): ISemTalkSystemClass | null {
    const s: ISemTalkObject = this._objectids[id + "_"];
    if (s === undefined) { return null; }
    if (!this.IsSystemClass(s)) { return null; }
    return s as ISemTalkSystemClass;
  }
  public FindBusinessClass(name: string): ISemTalkBusinessClass | null {
    let x = Object.values(this._classes).find(s => s.ObjectName === name && s instanceof SemTalkBusinessClass);
    if (x) { return x as ISemTalkBusinessClass; }
    return null;
  }
  public FindInstance(name: string): ISemTalkInstance | null {
    let x = Object.values(this._instances).find(s => s.ObjectName === name &&
      s.ObjectType !== SemTalkType.SemTalkDiagram);
    if (x) { return x; }
    return null;
  }

  public FindInstanceByID(id: SemTalkID): ISemTalkInstance | null {
    const s: ISemTalkObject = this._objectids[id + "_"];
    if (s === undefined) { return null; }
    if (!this.IsInstance(s)) { return null; }
    return s as ISemTalkInstance;
  }
  public FindDiagram(name: string): ISemTalkDiagram | null {
    let x = Object.values(this._diags).find(s => s.ObjectName === name);
    if (x) { return x; }
    return null;
  }
  public FindDiagramByName(name: string): ISemTalkDiagram | null {
    let x = Object.values(this._diags).find(s => s.ID2Name === name);
    if (x) { return x; }
    return null;
  }
  public FindDiagramByID(id: SemTalkID): ISemTalkDiagram | null {
    const s: ISemTalkDiagram = this._diags[id + "_"];
    if (s === undefined) { return null; }
    return s;
  }

  // public FindModelByIDAsync(id: string, fn: (x: ObjectBase) => void): void {
  //   fn(this);
  // }
  public FindObjectNamed(name: string): ISemTalkObject | null {
    const o: ISemTalkObject = this._objects[name];
    if (o === undefined) {
      return null;
    }
    else {
      return o;
    }
  }
  public FindObject(tname: SemTalkType, name: string): ISemTalkObject | null {
    if (name === "") { return null; }
    switch (tname) {
      case SemTalkType.SemTalkClass:
        return this.FindClass(name);
      case SemTalkType.SemTalkAttributeType:
        return this.FindAttributeType(name);
      case SemTalkType.SemTalkMethodType:
        return this.FindMethodType(name);
      case SemTalkType.SemTalkStateType:
        return this.FindStateType(name);
      case SemTalkType.SemTalkAssociationType:
        return this.FindAssociationType(name);
      case SemTalkType.SemTalkDiagram:
        return this.FindDiagram(name);
      case SemTalkType.SemTalkDiagramType:
        return this.FindDiagramType(name);
      default:
        const o: ISemTalkObject = this._objects[name];
        if (o === undefined) {
          return null;
        }
        else {
          return o;
        }
    }
  }
  public FindObjectByID(id: SemTalkID): ISemTalkObject | null {
    const s: ISemTalkObject = this._objectids[id + "_"];
    if (s === undefined) { return null; }
    return s;
  }
  public FindNodeByID(id: string): ISemTalkNode | null {
    for (const o in this._objects) {
      const x = this._objects[o];
      for (const n of x.Nodes()) {
        if (n.ID === id) {
          return n;
        }
      }
    }
    return null;
  }
  public AllClasses(): ISemTalkClass[] {
    return Object.values(this._classes).filter(x => x.ObjectType === SemTalkType.SemTalkClass);
  }
  public AllRootClasses(): ISemTalkClass[] {
    return Object.values(this._classes).filter(x => !this.IsAssociationType(x) &&
      !this.IsAttributeType(x) &&
      !this.IsMethodType(x) &&
      !this.IsStateType(x) &&
      !this.IsDiagramType(x) &&
      this.IsClass(x) && x.SuperClasses().length === 0);
  }

  // this.IsClass(x) && x.SuperClasses().filter(x=>{
  //   let ishidden=false;
  //   if (this.IsSystemClass(x) && (x as ISemTalkSystemClass).Hide) {
  //     return false;
  //   }
  //   return !ishidden;
  // }).length === 0);

  public AllReadOnlyClasses(): ISemTalkClass[] {
    return Object.values(this._classes).filter(x => x.ObjectType === SemTalkType.SemTalkClass && x.IsReadOnly);
  }
  public AllSystemClasses(): ISemTalkSystemClass[] {
    return Object.values(this._classes).filter(x => this.IsSystemClass(x)).map(x => x as ISemTalkSystemClass);
  }
  public AllDiagramTypes(): ISemTalkDiagramType[] {
    return Object.values(this._diagtypes);
  }
  public AllMethodTypes(): ISemTalkMethodType[] {
    return Object.values(this._methodtypes);
  }
  public AllAssociationTypes(): ISemTalkAssociationType[] {
    return Object.values(this._assoctypes);
  }

  public AllAttributeTypes(): ISemTalkAttributeType[] {
    return Object.values(this._attrtypes);
  }

  public AllStateTypes(): ISemTalkStateType[] {
    return Object.values(this._statetypes);
  }
  public AllInstances(): ISemTalkInstance[] {
    return Object.values(this._instances).filter(x => x.ObjectType === SemTalkType.SemTalkInstance);
  }
  public AllDiagrams(): ISemTalkDiagram[] {
    return Object.values(this._diags);
  }
  public AllObjects(): ISemTalkObject[] {
    return Object.values(this._objects);
  }
  public static SemTalkRelationTypeName(r: SemTalkRelation): string {
    switch (r) {
      case SemTalkRelation.SemTalkProperty:
        return SemTalkBaseConstant.SLProperty;
      case SemTalkRelation.SemTalkSubClassOf:
        return SemTalkBaseConstant.SLSubClassOf;
      case SemTalkRelation.SemTalkInstanceOf:
        return SemTalkBaseConstant.SLInstanceOf;
      case SemTalkRelation.SemTalkHiddenRelation:
        return SemTalkBaseConstant.SLHiddenRelation;
      case SemTalkRelation.SemTalkSystemRelation:
        return "SystemRelation";
    }
  }
  public static FindSemTalkRelationType(s: string): SemTalkRelation {
    switch (s) {
      case SemTalkBaseConstant.SLSubClassOf:
        return SemTalkRelation.SemTalkSubClassOf;
      case SemTalkBaseConstant.SLInstanceOf:
        return SemTalkRelation.SemTalkInstanceOf;
      case SemTalkBaseConstant.SLHiddenRelation:
        return SemTalkRelation.SemTalkHiddenRelation;
      case SemTalkBaseConstant.SLDefinitionOf:
        return SemTalkRelation.SemTalkSystemRelation;
      case "SystemRelation":
        return SemTalkRelation.SemTalkSystemRelation;
      default:
        return SemTalkRelation.SemTalkProperty;
    }
  }
  public static SemTalkTypeName(r: SemTalkType): string {
    switch (r) {
      case SemTalkType.SemTalkClass: return "Class";
      case SemTalkType.SemTalkInstance: return "Instance";
      case SemTalkType.SemTalkAssociation: return "Association";
      case SemTalkType.SemTalkAttribute: return "Attribute";
      case SemTalkType.SemTalkMethod: return "Method";
      case SemTalkType.SemTalkState: return "State";
      case SemTalkType.SemTalkAttributeType: return "AttributeType";
      case SemTalkType.SemTalkStateType: return "StateType";
      case SemTalkType.SemTalkMethodType: return "MethodType";
      case SemTalkType.SemTalkAssociationType: return "AssociationType";
      case SemTalkType.SemTalkSpecialization: return "Specialization";
      case SemTalkType.SemTalkDiagram: return "Diagram";
      case SemTalkType.SemTalkDiagramType: return "DiagramType";
      case SemTalkType.SemTalkNode: return "Node";
      case SemTalkType.SemTalkAssociationNode: return "AssociationNode";
      case SemTalkType.SemTalkTabSpec: return "TabSpec";
      case SemTalkType.SemTalkGenericTabSpec: return "GenericTabSpec";
      case SemTalkType.SemTalkGenericAttributeTabSpec: return "GenericAttributeTabSpec";
      case SemTalkType.SemTalkLabel: return "Label";
      case SemTalkType.SemTalkClassLabel: return "ClassLabel";
      case SemTalkType.SemTalkSynonym: return "Synonym";
      case SemTalkType.SemTalkComposition: return "Composition";
      case SemTalkType.SemTalkTabSpecListTabSpec: return "TabSpecListTabSpec";
      default: throw new Error(("SemTalkTypeName: Missing type: " + r));
    }
  }

  public static SemTalkConnectionPointName(con: SemTalkConnectionPoint): string {
    switch (con) {
      case SemTalkConnectionPoint.North: return "North";
      case SemTalkConnectionPoint.East: return "East";
      case SemTalkConnectionPoint.South: return "South";
      case SemTalkConnectionPoint.West: return "West";
    }
  }
  public static FindSemTalkValueType(s: string): SemTalkValueType {
    s = s.replace("http://www.semtalk.com/datatype#", "");
    switch (s) {
      case "Symbol": return SemTalkValueType.Symbol;
      case "File": return SemTalkValueType.File;
      case "Date": return SemTalkValueType.Date;
      case "Duration": return SemTalkValueType.Duration;
      case "Float": return SemTalkValueType.Float;
      case "Integer": return SemTalkValueType.Integer;
      case "Boolean": return SemTalkValueType.Boolean;
      case "Color": return SemTalkValueType.Color;
      case "Email": return SemTalkValueType.Email;
      case "Password": return SemTalkValueType.Password;
      case "URL": return SemTalkValueType.URL;
      case "Tel": return SemTalkValueType.Tel;
      case "Script": return SemTalkValueType.Script;
      default: return SemTalkValueType.Symbol;
    }
  }

  public static SemTalkValueTypeName(r: SemTalkValueType): string {
    switch (r) {
      case SemTalkValueType.Symbol: return "Symbol";
      case SemTalkValueType.File: return "File";
      case SemTalkValueType.Date: return "Date";
      case SemTalkValueType.Duration: return "Duration";
      case SemTalkValueType.Float: return "Float";
      case SemTalkValueType.Integer: return "Integer";
      case SemTalkValueType.Boolean: return "Boolean";
      case SemTalkValueType.Color: return "Color";
      case SemTalkValueType.Email: return "Email";
      case SemTalkValueType.Password: return "Password";
      case SemTalkValueType.URL: return "URL";
      case SemTalkValueType.Tel: return "Tel";
      case SemTalkValueType.Script: return "Script";
      default: throw new Error(("SemTalkValueTypeName: Missing type: " + r));
    }
  }

  public MakeClass(newname: string, id?: SemTalkID): ISemTalkClass {
    let existing = this.FindClass(newname);
    if (existing != null) {
      return existing;
    } else {
      return new SemTalkClass(this, newname, id);

    }
  }
  public IsClass(obj: ISemTalkObject): boolean {
    return obj instanceof SemTalkClass;
  }
  public MakeInstance(cla: ISemTalkClass, newname: string, otype: SemTalkType, id?: SemTalkID): ISemTalkInstance {
    let existing = this.FindInstance(newname);
    if (existing != null) {
      return existing;
    } else {
      return new SemTalkInstance(cla, newname, otype, id);
    }
  }
  public IsInstance(obj: any): boolean {
    return obj instanceof SemTalkInstance;
  }
  public IsAssociation(obj: any): boolean {
    return obj instanceof SemTalkAssociation;
  }
  public MakeSystemClass(newname: string, id?: SemTalkID | null | undefined): ISemTalkSystemClass {
    let existing = this.FindSystemClass(newname);
    if (existing != null) {
      return existing;
    } else {
      return new SemTalkSystemClass(this, newname, id);
    }
  }

  public IsSystemClass(obj: ISemTalkObject): boolean {
    return obj instanceof SemTalkSystemClass;
  }
  public MakeBusinessClass(newname: string, id?: SemTalkID | null): ISemTalkBusinessClass {
    let existing = this.FindBusinessClass(newname);
    if (existing != null) {
      return existing;
    } else {
      return new SemTalkBusinessClass(this, newname, id);
    }
  }
  public MakeComposition(own: ISemTalkObject, cls: ISemTalkBusinessClass, met: ISemTalkMethodType | null, sta: ISemTalkStateType | null,
    attr: ISemTalkAttributeType | null, oth?: ISemTalkBusinessClass | null, op?: string,
    isnotop?: boolean, cmpvalue?: any): ISemTalkComposition {
    return new SemTalkComposition(own, cls, met, sta, attr, oth, op, isnotop, cmpvalue);
  }
  public IsBusinessClass(obj: ISemTalkObject): boolean {
    return obj instanceof SemTalkBusinessClass;
  }
  public MakeMethodType(newname: string, id?: SemTalkID | null): ISemTalkMethodType {
    let existing = this.FindMethodType(newname);
    if (existing != null) {
      return existing;
    } else {
      return new SemTalkMethodType(this, newname, id);
    }
  }
  public IsMethodType(obj: ISemTalkObject): boolean {
    return obj instanceof SemTalkMethodType;
  }
  public MakeStateType(newname: string, id?: SemTalkID | null): ISemTalkStateType {
    let existing = this.FindStateType(newname);
    if (existing != null) {
      return existing;
    } else {
      return new SemTalkStateType(this, newname, id);
    }
  }
  public IsStateType(obj: ISemTalkObject): boolean {
    return obj instanceof SemTalkStateType;
  }
  public MakeAttributeType(newname: string, id?: SemTalkID | null): ISemTalkAttributeType {
    let existing = this.FindAttributeType(newname);
    if (existing != null) {
      return existing;
    } else {
      return new SemTalkAttributeType(this, newname, id);
    }
  }
  public IsAttributeType(obj: ISemTalkObject): boolean {
    return obj instanceof SemTalkAttributeType;
  }
  public MakeAssociationType(ptype: SemTalkRelation, newname: string, id?: SemTalkID | null): ISemTalkAssociationType {
    let existing = this.FindAssociationType(newname);
    if (existing != null) {
      return existing;
    } else {
      return new SemTalkAssociationType(this, ptype, newname, id);
    }
  }
  public MakeAttribute(obj: ISemTalkObject, cla: ISemTalkAttributeType, val: any | null): ISemTalkAttribute {
    return new SemTalkAttribute(obj, cla, val);
  }
  public IsAssociationType(obj: ISemTalkObject): boolean {
    return obj instanceof SemTalkAssociationType;
  }
  public MakeDiagramType(newname: string, id?: SemTalkID | null | undefined): ISemTalkDiagramType {
    let existing = this.FindDiagramType(newname);
    if (existing != null) {
      return existing;
    } else {
      return new SemTalkDiagramType(this, newname, id);
    }
  }
  public IsDiagramType(obj: ISemTalkObject): boolean {
    return obj instanceof SemTalkDiagramType;
  }
  public MakeNode(diag: ISemTalkDiagram, obj: ISemTalkObject, shpid: string, master?: string): ISemTalkNode {
    return new SemTalkNode(diag, obj, shpid, master);
  }
  public IsNode(obj: any): boolean {
    return obj instanceof SemTalkNode;
  }
  public MakeAssociationNode(diag: ISemTalkDiagram, obj: ISemTalkAssociation, shpid: string, master: string): SemTalkAssociationNode {
    return new SemTalkAssociationNode(diag, obj, shpid, master);
  }
  public IsAssociationNode(obj: any): boolean {
    return obj instanceof SemTalkAssociationNode;
  }
  public MakeDiagram(cla: ISemTalkDiagramType, newname: string, otype?: SemTalkType, id?: SemTalkID): ISemTalkDiagram {
    let existing = this.FindDiagram(newname);
    if (existing != null) {
      return existing;
    } else {
      let ot = SemTalkType.SemTalkDiagram;
      if (otype !== undefined)
        ot = otype;
      return new SemTalkDiagram(cla, newname, ot, id);
    }
  }
  public IsDiagram(obj: any): boolean {
    return obj instanceof SemTalkDiagram;
  }
  public MakeLabelSpec(): LabelSpec {
    return new LabelSpec();
  }

  public SearchObjects(str: string): ISemTalkObject[] {
    let res: ISemTalkObject[] = [];
    for (const obj of this.AllObjects())
      if (obj.ObjectCaption.indexOf(str) > -1 || obj.Comment.indexOf(str) > -1) {
        res.push(obj);
      }
    res = res.sort((a: ISemTalkObject, b: ISemTalkObject): number => { return a.ObjectCaption.localeCompare(b.ObjectCaption); });
    return res;
  }
  public SearchNodes(str: string, paging_from?: number, paging_to?: number): ISemTalkNode[] {
    let res: ISemTalkNode[] = [];
    if (paging_from !== undefined && paging_to !== undefined) {
      let cnt: number = -1;
      for (const diag of this.AllDiagrams()) {
        for (const nd of diag.Contents()) {
          if (nd.Model.ObjectCaption.indexOf(str) > -1 || nd.Model.Comment.indexOf(str) > -1) {
            cnt += 1;
            if (cnt >= paging_from) {
              res.push(nd);
            }
            if (cnt > paging_to) break;
          }
          if (cnt > paging_to) break;
        }
      }
    } else {
      for (const diag of this.AllDiagrams())
        for (const nd of diag.Contents()) {
          if (nd.Model.ObjectCaption.indexOf(str) > -1 || nd.Model.Comment.indexOf(str) > -1) {
            res.push(nd);
          }
        }
    }
    res = res.sort((a: ISemTalkNode, b: ISemTalkNode): number => { return a.Model.ObjectCaption.localeCompare(b.Model.ObjectCaption); });
    return res;
  }
  public SearchDiagram(diagid: SemTalkID, str: string): ISemTalkNode[] {
    let res: ISemTalkNode[] = [];
    const diag = this.FindDiagramByID(diagid);
    if (diag != null) {
      for (let nd of diag.Contents()) {
        if (nd.Model.ObjectCaption.indexOf(str) > -1 || nd.Model.Comment.indexOf(str) > -1) {
          res.push(nd);
        }
      }
    }
    res = res.sort((a: ISemTalkNode, b: ISemTalkNode): number => { return a.Model.ObjectCaption.localeCompare(b.Model.ObjectCaption); });
    return res;
  }

}
