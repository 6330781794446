
import { SemTalkLanguageCode } from '../tbase/Interface';
export interface IResStr {
  getResStrListener(str: string, lang?: SemTalkLanguageCode): string;
  getResStr(str: string, lang?: SemTalkLanguageCode): string;
  getResKeys(): string[];
  getResKeysListener(): string[];
  // showHelp(page?: string, lng?: string): void;
  // getHelpStr(str: string, lang?: SemTalkLanguageCode): string;
}
export class ResStr implements IResStr {
  public resx_SemList: any;
  public resx_SemTalk: any;
  private guilanguage: SemTalkLanguageCode;

  constructor(guilanguage: SemTalkLanguageCode) {
    this.guilanguage = guilanguage;
    this.resx_SemList = require("./SemList.json");
    this.resx_SemTalk = require("./SemTalk.json");
  }
  public getResStrListener(str: string, lang?: SemTalkLanguageCode): string {
    if (lang === undefined) {
      lang = this.guilanguage;
    }
    if (lang === SemTalkLanguageCode.English) {
      const s = this.resx_SemList[str];
      if (s !== undefined) {
        return s.value;
      }
      return str;
    } else {
      const s = this.resx_SemList[str];
      if (s !== undefined) {
        let v = s[lang];
        if (v === undefined) {
          return s.value;
        }
        return v;
      } else {
        return str;
      }
    }
  }
  public getResStr(str: string, lang?: SemTalkLanguageCode): string {
    if (lang === undefined) {
      lang = this.guilanguage;
    }
    if (lang === SemTalkLanguageCode.English) {
      const s = this.resx_SemTalk[str];
      if (s !== undefined) {
        return s.value;
      }
      return str;
    } else {
      const s = this.resx_SemTalk[str];
      if (s !== undefined) {
        // if (lang !== "de") {
        //   if (lang === "sc") lang = "cn-simplified";
        //   if (lang === "tc") lang = "cn-traditional";
        // }
        let v = s[lang];
        if (v === undefined) {
          return s.value;
        }
        return v;
      } else {
        return str;
      }
    }
  }
  public getResKeys(): string[] {
      return Object.keys(this.resx_SemTalk);
  }
  public getResKeysListener(): string[] {
    return Object.keys(this.resx_SemList);
}


}
