export enum SemTalkCookie {
  guilanguage = "guilanguage",
  language = "language",
  simulation = "simulation",
  simtimeoffs = "simtimeoffs", 
  bpmn = "bpmn",
  bpmnstencil = "bpmnstencil",
  bpmndialog = "bpmndialog",
  bpmnsymbols = "bpmnsymbols",
  bpmneventtypes = "bpmneventtypes",
  bpmnsfmfrules = "bpmnsfmfrules",
  bpmnmultipleinterfaces = "bpmnmultipleinterfaces",
  objectflow = "objectflow",
  refinements = "refinements",
  showlanguage = "showlanguage",
  showtoolbar = "showtoolbar",
  showmenubar = "showmenubar",
  showbreadcrumbs = "showbreadcrumbs",
  dockpanandzoom = "dockpanandzoom",
  docknavigator = "docknavigator",
  dockstencil = "dockstencil",
  dockplanner = "dockplanner",
  dockpivot = "dockpivot",
  usedialogs = "usedialogs",
  editdialogwidth = "editdialogwidth",
  shapesleftside = "shapesleftside",
  showquickshapes = "showquickshapes",
  allownegativecoordinates = "allownegativecoordinates",
  showhyperlinkmarker = "showhyperlinkmarker",
  semmongoserverurl = "semmongoserverurl",
  usemongo = "usemongo",
  usemongosso = "usemongosso",
  usegraph = "usegraph",
  semconnecttoken = "semconnecttoken",
  dbname = "dbname",
  repository = "repository",
  SemTalkStatus = "SemTalkStatus",
  autosaveSemTalk = "autosaveSemTalk",
  autoComplete = "autoComplete",
  shapestyle = "shapestyle",
  guifont = "guifont",
  font = "font",
  fontsize = "fontsize",
  semanticsserver = "semanticsserver",
  semanticscorpus = "semanticcorpus",
  semanticsdistance = "semanticsdistance",
  tikaserver = "tikaserver",
  applicationid = "applicationid",
  authority = "authority",
  scopes = "scopes",
  termsetgroup = "termsetgroup",
  termset = "termset",
  termsetroot = "termsetroot",
  termsetassoc = "termsetassoc",
  termseturl = "termseturl",
  m365group = "m365group",
  teamid = "teamid",
  channelid = "channelid",
  planid = "planid",
  theme = "theme",
  backgroundColor = "backgroundColor",
  userDefTheme = "userDefTheme",
  themeStyle = "themeStyle",
  iconcolor = "iconcolor",
  role = "role",
  autorefresh = "autorefresh",
  history = "history",
  historypos = "historypos",
  panzoom = "panzoom",
  panzoomX = "panzoomX",
  panzoomY = "panzoomY",
  pages = "pages",
  navigator = "navigator",
  navigatorX = "navigatorX",
  navigatorY = "navigatorY",
  navigatorW = "navigatorW",
  navigatorH = "navigatorH",
  planner = "planner",
  plannerX = "plannerX",
  plannerY = "plannerY",
  stencil = "stencil",
  stencilX = "stencilX",
  stencilY = "stencilY",
  search = "search",
  searchX = "searchX",
  searchY = "searchY",
  pivot = "pivot",
  pivotX = "pivotX",
  pivotY = "pivotY",
  width = "width",
  height = "height",
  shapescale = "shapescale",
  resizeall = "resizeall",
  quickedit = "quickedit",
  sharepointrepository = "sharepointrepository",
  sharepointlibrary = "sharepointlibrary",
  sharepointlibrarysite = "sharepointlibrarysite",
  sharepointdocumentcolumns = "sharepointdocumentcolumns",
  sharepointlibraryfixed = "sharepointlibraryfixed",
  sharepointoverrideswebpartsetting = "sharepointoverrideswebpartsetting",
  servicesconstr = "servicesconstr",
  servicesserver = "servicesserver",
  servicessite = "servicessite",
  servicesdatabase = "servicesdatabase",
  servicesservertype = "servicesservertype",
  breports = "breports",
  approved = "approved",
  printscale = "printscale",
  printpageformat = "printpageformat",
  printoverlays = "printoverlays",
  printcontrols = "printcontrols",
  printlandscape = "printlandscape",
  printtitle = "printtitle",
  printx0 = "printx0",
  printy0 = "printy0",
  printpageselector = "printpageselector",
  printborder = "printborder",
  filedialogcolumns = "filedialogcolumns",
  isusertask = "isusertask",
  // logourl = "logourl",
  chatgpt = "chatgpt",
  chatgptpagecontent = "chatgptpagecontent",
  chatgptmodelcontent = "chatgptmodelcontent",
  chatgptcontentvisible = "chatgptcontentvisible",
  chatgptquestion ="chatgptquestion",
  lru = "lru",
  showwelcome = "showwelcome",
  filename = "filename",
  usesimulationcoloring = "usesimulationcoloring",
  headerfooterfields = "headerfooterfields",
  showheaderfooterfields = "showheaderfooterfields",
  citfimConfig = "citfimConfig",
  semantictoolstext = "semantictoolstext",
  nlp_site = "nlp_site",
  nlp_library = "nlp_library",
  versioncount = "versioncount",
  autosaverepository = "autosaverepository",
  isplannerenabled = "isplannerenabled",
  ribbon = "ribbon",
  toolbar = "toolbar",
  isreflexivelinks = "isreflexivelinks",
  autoextend = "autoextend",
  autoscroll = "autoscroll",
  extendParentsOnMove = "extendParentsOnMove",
  extendParentsOnAdd = "extendParentsOnAdd",
  resizecontainer = "resizecontainer",
  splitenabled = "splitenabled",
  imagebundles = "imagebundles",
  layout = "layout",
  exportconnections = "exportconnections",
  semmongoserverurlBackup = "semmongoserverurlBackup"
}