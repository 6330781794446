import React from 'react';
import { ITSEditor } from "../ITSEditor";
import styles from './pages.module.scss';
import {
  Dropdown, IDropdownOption
} from 'office-ui-fabric-react';
import { SemTalkID } from '../application/tbase/Interface';


export interface IPagesProps {
  editor: ITSEditor;
  diaglist: { text: string, key: SemTalkID }[];
  diagid: SemTalkID;
  disabled: boolean | undefined;
}

export interface IPagesState {
  options: IDropdownOption[];
  objectid: SemTalkID;
}

export class Pages extends React.Component<IPagesProps, IPagesState> {
  constructor(props: IPagesProps) {
    super(props);
    console.debug("Pages");
    this.props.editor.pages = this;
    this.state = {
      objectid: this.props.diagid,
      options: this.props.diaglist,
   };
  }

  private _onOptionChange = (_ev: any, option: any, _index?: number | undefined, _value?: string | undefined): void => {
    if (option) {
      let n: SemTalkID = option.key as string;
    //  this.setState({ objectid: n });
    //  console.debug("pages: gotodocument" + n);
     this.props.editor.gotoPage(n);
    }
  }

  public UNSAFE_componentWillReceiveProps = (nextProps: any) => {
    this.setState({
      objectid: nextProps.diagid,
      options: nextProps.diaglist,
    });
  }

  public render(): React.ReactElement<IPagesProps> {
    return (
      <div className={styles.semTalkPages}>
        <Dropdown
          disabled={this.props.disabled}
          onChange={this._onOptionChange}
          options={this.state.options}
          selectedKey={this.state.objectid}
        />
      </div>
    );
  }
}
