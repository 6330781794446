/* eslint-disable no-restricted-globals */

import { SemTalkCookie } from "../../ISemTalkCookie";
import { SemTalkSimulationCookie } from "../simulation/interface";
import { SemTalkBaseConstant, SemTalkID } from "../tbase/Interface";


if ((window as any).goto_context === undefined) {
  (window as any).goto_context = {
    url: "",
    model: "-1",
    page: "-1",
    obj: "-1",
    shp: "",
    callbacks: {},
    stay: false,
    exdays: 10,
    site: "",
    subsite: ""
  };
} else {
  console.log((window as any).goto_context);
}
export function addCallBack(crtl: any): string {
  let cb: any = (window as any).goto_context.callbacks;
  cb[crtl.callback] = crtl;
  return crtl.callback;
}
export function removeCallBack(crtl: any): void {
  let cb: any = (window as any).goto_context.callbacks;
  delete cb[crtl.callback];
}
export async function post2Callbacks(evt: any): Promise<void> {
  let cb: any = (window as any).goto_context.callbacks;
  for (const guid in cb) {
    let ctrl = cb[guid];
    if (ctrl) {
      try {
        await ctrl.handleEvent(evt);
      } catch (_e) {
        
      }
    }
  }
}

export function getContext(): any {
  return (window as any).goto_context;
}
export function clearNode(): void {
  (window as any).goto_context.url = "";
  (window as any).goto_context.page = "-1";
}
export function clearObject(): void {
  (window as any).goto_context.url = "";
  (window as any).goto_context.model = "-1";
  (window as any).goto_context.page = "-1";
  (window as any).goto_context.obj = "-1";
}
export function getObject(): SemTalkID | null {
  // console.debug("getObject: ", (window as any).goto_context.obj);
  return (window as any).goto_context.obj;
}
export function setObject(_obj: SemTalkID | null) {
  if (_obj !== null) {
    // setCookie("contextobj", _obj.toString());
    (window as any).goto_context.obj = _obj;
  }
}
export function getModel(): number {
  return (window as any).goto_context.model;
}
export function setModel(_obj: number): void {
  if (_obj !== undefined) {
    // setCookie("contextmodel", _obj.toString());
    // if ((window as any).goto_context.stay) {
    (window as any).goto_context.model = _obj;
    // } else {
    //   (window as any).goto_context.model = -1;
    // }
  }
}
export function setStay(_stay: boolean): void {
  (window as any).goto_context.stay = _stay;
}
export function setSite(site: string): void {
  if (site !== undefined) {
    // setCookie("contextsite", site.toString());
    (window as any).goto_context.site = site;
  }
}
export function getDiagram(): SemTalkID | null {
  return (window as any).goto_context.page;
}
export function setDiagram(_obj: SemTalkID | null): void {
  if (_obj !== null) {
    // setCookie("contextpage", _obj.toString());
    (window as any).goto_context.page = _obj;
  }
}
// export function getURL(): string {
//   return (window as any).goto_context.url;
// }
// export function setURL(_obj: string) {
//   setCookie ("contexturl", _obj);
//  (window as any).goto_context.url = _obj;
// }
export function getShape(): string {
  return (window as any).goto_context.shp;
}
export function setShape(_obj: string) {
  if (_obj !== undefined) {
    // setCookie("contextshp", _obj);
    (window as any).goto_context.shp = _obj;
  }
}
// export function getConversationID(): string {
//   return (window as any).goto_context.conversationID;
// }
// export function setConversationID(_obj: string) {
//   setCookie("conversationID", _obj);
//   (window as any).goto_context.conversationID = _obj;
// }
// export function setCookie(cname: string, cvalue: string) {
//   let d = new Date();
//   d.setTime(d.getTime() + ((window as any).goto_context.exdays * 24 * 60 * 60 * 1000));
//   let expires = "expires=" + d.toUTCString();
//   //  document.cookie = (window as any).goto_context.site + "/" + cname + "=" + cvalue + ";" + expires + ";path=/";
//   document.cookie = window.location.pathname + "/" + cname + "=" + cvalue + ";" + expires + ";path=/";
// }
// export function accessCookie(cookieName: string): string {
//   // let name = (window as any).goto_context.site + "/" + cookieName + "=";
//   let name = window.location.pathname + "/" + cookieName + "=";
//   var allCookieArray: string[] = document.cookie.split(';');
//   for (var i: number = 0; i < allCookieArray.length; i++) {
//     let temp: string = allCookieArray[i].trim();
//     if (temp.indexOf(name) === 0)
//       return temp.substring(name.length, temp.length);
//   }
//   return "";
// }

export function setCookie(cname: SemTalkCookie, cvalue: any) {
  (window as any).localStorage.setItem((window as any).goto_context.subsite + SemTalkBaseConstant.CookiePrefix + cname, cvalue);
}
export function accessCookie(cookieName: SemTalkCookie | SemTalkSimulationCookie): any {
  return (window as any).localStorage.getItem((window as any).goto_context.subsite + SemTalkBaseConstant.CookiePrefix + cookieName);
}
export function removeCookie(cookieName: SemTalkCookie): any {
  return (window as any).localStorage.removeItem((window as any).goto_context.subsite + SemTalkBaseConstant.CookiePrefix + cookieName);
}

export function setSubsitePrefix(subsite: any) {
  (window as any).goto_context.subsite = subsite;
}