import {
    Document,
    Packer,
    Paragraph,
    Table,
    TableCell,
    TableRow,
    HeadingLevel,
    ImageRun,
    TextRun,
    ExternalHyperlink,
    TableOfContents,
    Footer,
    PageNumber,
    // PageBreak,
    Header,
    AlignmentType
} from 'docx';
import { gotoDocument } from '../../application/semtalklistener/stglobal';
import { IVisioRDFS, ResID, ResIDL } from "../../application/semtalklistener/visiordfsinterface";
// import { defaultFont } from '../../application/semtalklistener/visiordfs';
import { ISemTalkOnline } from "../../ISemTalkOnline";
import { SemTalkCookie } from "../../ISemTalkCookie";
import { ISemTalkDiagram, ISemTalkObject, SemTalkBaseConstant, ISemTalkAssociation, SemTalkAttachment } from '../../application/tbase/Interface';
import { Process_ElementName } from '../../application/semtalklistener/processInterface';
import { loadImage } from './loadimage';
import { MakeUnixValue, converBase64toBlob } from './utils';
import { ModelProperty } from '../../SemTalkOptions';
import { accessCookie } from '../../application/semtalklistener/stgoto';
import { formatdate } from '../../utils';
import { ensureLabelWidth } from '../../styles';

export default async function ExportWord(semtalk: IVisioRDFS, callback: ISemTalkOnline, filename: string) {
    let modTitle: string = filename.replace(".sdx", "").replace(".stx", "");
    if (modTitle === "" || modTitle === undefined || modTitle === null) {
        modTitle = "SemTalkOnline-WordExport";
    }
    let doc = await wordExport(semtalk, callback, filename);
    let fname: string = MakeUnixValue(modTitle) + ".docx";
    const b64string = await packword(doc);
    let blob = converBase64toBlob(b64string, 'application/msword');
    const url = window.URL.createObjectURL(new Blob([blob]),);
    const link: any = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fname,);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

}
function exportPageTable(semtalk: IVisioRDFS, d: ISemTalkDiagram): Table {
    let base = d.ObjectBase;
    let uses = base.GetModelAttribute(Process_ElementName.SLUses);
    let invuses = base.GetModelAttribute(Process_ElementName.SLInvUses);
    let infotype = base.GetModelAttribute(Process_ElementName.SLInfoType);

    const isproc = (d.ClassOf().ObjectName === base.GetModelAttribute(Process_ElementName.SLProc));

    let rows: TableRow[] = [];
    let tableheader: TableCell[] = [];

    if (isproc) {
        tableheader.push(new TableCell({
            children: [paragraph(semtalk.getResStrListener(ResIDL.STRBONNUM).replace(":", ""))],
        }));
    }
    tableheader.push(new TableCell({
        children: [paragraph(semtalk.getResStrListener(ResIDL.STRNAME))],
    }));
    if (isproc) {
        tableheader.push(new TableCell({
            children: [paragraph(semtalk.getResStr(ResID.STRPPTYPE).replace(":", ""))],
        }));
    }
    if (isproc) {
        tableheader.push(new TableCell({
            children: [paragraph(semtalk.getResStrListener(ResIDL.STRBONLBLHR).replace(":", ""))]
        }));
    }
    tableheader.push(new TableCell({
        children: [paragraph(semtalk.getResStr(ResID.STRCOMMENT))]
    }));
    tableheader.push(new TableCell({
        children: [paragraph(semtalk.getResStr(ResID.STRHYPERLINKS))]
    }));

    const tableHeader: TableRow = new TableRow({
        tableHeader: true,
        children: tableheader
    });
    rows.push(tableHeader);

    // let cnt: number = 1;

    for (let s of d.Contents()) {
        let cells: TableCell[] = [];
        if (!base.IsAssociation(s.Model)) {
            let obj = s.Model;
            if (base.IsInstance(obj)) {
                for (let r of obj.LinkedObjects(SemTalkBaseConstant.SLDisplays)) {
                    obj = r;
                    break;
                }
                if (infotype) {
                    for (let r of obj.LinkedObjects(infotype)) {
                        obj = r;
                        break;
                    }
                }
            }
            // cells.push(new TableCell({ "children": [paragraph(cnt.toString() + ".")] }));
            if (isproc) {
                cells.push(new TableCell({ "children": [paragraph(obj.GetValue(SemTalkBaseConstant.SLUserNumber))] }));
            }
            cells.push(new TableCell({ "children": [paragraph(obj.ObjectCaption)] }));

            if (isproc) {
                let scname = "";
                const sc = obj.SystemClass();
                if (sc) scname = sc.ObjectCaption;
                cells.push(new TableCell({ "children": [paragraph(scname)] }));
            }
            if (isproc) {
                let rlist: ISemTalkObject[] = [];
                if (uses) rlist.push(...obj.LinkedObjects(uses));
                if (invuses) rlist.push(...obj.LinkedObjects(invuses));
                cells.push(new TableCell({
                    "children":
                        rlist.map((a) => {
                            return paragraph(a.ObjectCaption);
                        })
                }));
            }
            cells.push(new TableCell({ "children": [paragraph(obj.Comment)] }));
            let alist: ISemTalkAssociation[] = [];
            alist.push(...obj.Attachments());
            if (s.Model !== obj) {
                alist.push(...s.Model.Attachments());
            }
            cells.push(new TableCell({
                "children":
                    alist.map((a) => {
                        let l = a.GetValue(SemTalkAttachment.label);
                        if (!l) l = a.ToObject.ObjectCaption;
                        return hyperlink(l, a.ToObject.ObjectName);
                    })
            }));

            rows.push(new TableRow({ "children": cells }));
            // cnt += 1;
        }
    }
    // let shapes = Object.values(this.res.tseditor.getAllCells());
    // for (let s of shapes) {
    //   if (s.shapeKey !== undefined || s.objectid !== undefined) {
    //     let iid: string = s.objectid;
    //     let s1: ISemTalkObject = this.base.FindObjectByID(iid);
    //     if (s1 !== null && !s.id.includes("edge")) {


    //       let curObjClassStr: string = "";
    //       let curObjClass: any = s1.SystemClass();
    //       if (curObjClass !== null) {
    //         curObjClassStr = curObjClass.ID2Name;
    //       }
    //       if (curObjClassStr.toLowerCase() !== 'sequence flow' && curObjClassStr.toLowerCase() !== 'message flow' && curObjClassStr.toLowerCase() !== 'dataobjectof_') {
    //         let curObjName: string = "";
    //         if (s1.ObjectCaption === "Swimlane" || s1.ObjectCaption === "Data Object") {
    //           curObjName = s.value;
    //         } else {
    //           curObjName = s1.ObjectCaption;
    //         }

    //         let attStr: string = "";
    //         for (let att of s1.Attachments()) {
    //           if (att !== null) {
    //             attStr += att.ToObject.ObjectCaption + "";
    //           }
    //         }
    //         let perStr: string = "";
    //         for (let per of s1.Associations()) {
    //           let to: any = per.ToObject;
    //           if (per.ObjectCaption === "uses hum.resource") {
    //             perStr += to.ObjectCaption + "; ";
    //           }
    //         }

    //         const tableObj: TableRow = new TableRow({
    //           children: [
    //             new TableCell({
    //               children: [new Paragraph(String(count) + ".")],
    //             }),
    //             new TableCell({
    //               children: [new Paragraph(curObjName)],
    //             }),
    //             new TableCell({
    //               children: [new Paragraph(curObjClassStr)],
    //             }),
    //             new TableCell({
    //               children: [new Paragraph(perStr)],
    //             }),
    //             new TableCell({
    //               children: [new Paragraph(s1.Comment)],
    //             }),
    //             new TableCell({
    //               children: [new Paragraph(attStr)],
    //             }),
    //           ]
    //         });
    //         tableContents.push(tableObj);
    //         count += 1;
    //       }
    //     }
    //   }
    // }
    return new Table({ rows: rows });
}
async function exportPageImage(semtalk: IVisioRDFS, d: ISemTalkDiagram): Promise<ImageRun> {
    let gr = semtalk.graph as mxGraph;
    // let div: any = document.getElementById('some-empty-div-on-the-page');
    // const g: any = document.createElement('a');
    // div.appendChild(g);
    // let xml = this.props.callback.getmxGraphXML(d);
    // gr = this.res.tseditor.renderNewGraphFromXml(xml, g);
    // if (!gr) {
    gotoDocument(d.ID);
    // }
    ensureLabelWidth(semtalk.graph as mxGraph);
    let svgJson: any = semtalk.tseditor.exportSVGforPrint(gr as mxGraph, false, false, 0, 1);
    // gotoDocument(d.ID);
    // await this.res.tseditor.gotoPage(d.ID);
    // let svgJson: any = this.res.tseditor.exportSVGforPNG(this.res.graph as mxGraph);
    const img: any = await loadImage(svgJson, 'image/png');
    // g.removeChild(g.children[0]);
    let buf: Buffer = Buffer.from(img.replace("data:image/png;base64,", ""), 'base64');
    let svgWidth: number = svgJson.width;
    let svgHeight: number = svgJson.height;
    if (svgWidth > 650) {
        let wp: number = ((650 * 100) / svgWidth);
        svgWidth = 650;
        svgHeight = Math.trunc((wp * svgHeight) / 100);
    }
    return new ImageRun({ data: buf, transformation: { width: svgWidth, height: svgHeight, }, });
}
function paragraph(txt: string, heading?: HeadingLevel): Paragraph {
    let ff0 = mxConstants.DEFAULT_FONTFAMILY;
    let ff = accessCookie(SemTalkCookie.font);
    if (!ff) ff = ff0;
    if (heading) {
        return new Paragraph({
            heading: heading,
            pageBreakBefore: true,
            children: [
                new TextRun({
                    text: txt,
                    bold: true,
                    font: ff,
                })]
        });
    } else {
        return new Paragraph({
            heading: heading,
            children: [
                new TextRun({
                    text: txt,
                    bold: false,
                    font: ff,
                })]
        });
    }
}
function hyperlink(txt: string, url: string): Paragraph {
    let ff0 = mxConstants.DEFAULT_FONTFAMILY;
    let ff = accessCookie(SemTalkCookie.font);
    // if (ff === 'undefined') ff = ff0;
    if (!ff) ff = ff0;
    return new Paragraph({
        children: [
            new ExternalHyperlink({
                children: [
                    new TextRun({
                        text: txt,
                        style: "Hyperlink",
                        font: ff,
                    }),
                ],
                link: url,
            }),
        ],
    });
}

function exportMetaDataTable(semtalk: IVisioRDFS, metadata: any): Table | null {
    let rows: TableRow[] = [];
    let badlist = ['_id', 'Id', 'ID', 'name', 'value', 'zip', 'FileLeafRef'];
    for (let v in metadata) {
        if (badlist.indexOf(v) > -1) continue;
        let propname = v;
        let pv = metadata[v];
        switch (propname) {
            case ModelProperty.createdby: {
                propname = semtalk.getResStrListener(ResIDL.STRDLGAUDIT1);
                break;
            }
            case ModelProperty.created: {
                propname = semtalk.getResStrListener(ResIDL.STRDLGAUDIT2);
                pv = formatdate(pv, semtalk.guilanguage);
                break;
            }
            case ModelProperty.modifiedby: {
                propname = semtalk.getResStrListener(ResIDL.STRDLGAUDIT3);
                break;
            }
            case ModelProperty.modified: {
                propname = semtalk.getResStrListener(ResIDL.STRDLGAUDIT4);
                pv = formatdate(pv, semtalk.guilanguage);
                break;
            }
            case ModelProperty.version: {
                propname = semtalk.getResStrListener(ResIDL.STRSAPEXCELVER);
                break;
            }
            case ModelProperty.checkedoutdate: {
                propname = semtalk.getResStr(ResID.STRCHECKEDOUTAT1);
                pv = formatdate(pv, semtalk.guilanguage);
                break;
            }
            case ModelProperty.checkedoutuser: {
                propname = semtalk.getResStr(ResID.STRCHECKEDOUTBY1);
                break;
            }
            case ModelProperty.approved: {
                propname = semtalk.getResStr(ResID.STRAPPROVEDAT1);
                pv = formatdate(pv, semtalk.guilanguage);
                break;
            }
            case ModelProperty.approvedby: {
                propname = semtalk.getResStr(ResID.STRAPPROVEDBY1);
                break;
            }
            default: {
                let cl = semtalk.base.FindClass(v);
                if (cl) {
                    propname = cl.ObjectCaption;
                } else {
                    propname = v;
                }
            }
        }
        rows.push(new TableRow({
            "children": [
                new TableCell({ "children": [paragraph(propname)] }),
                new TableCell({ "children": [paragraph(pv)] })]
        }));
    }
    if (rows.length > 0) {
        return new Table({ rows: rows });
    } else {
        return null;
    }
}


export async function wordExport(semtalk: IVisioRDFS, callback: ISemTalkOnline, filename: string): Promise<Document> {
    let modTitle: string = semtalk.base.ObjectName.replace(".sdx", "");
    if (modTitle === "" || modTitle === undefined || modTitle === null) {
        modTitle = "SemTalkOnline-WordExport";
    }
    let ff0 = mxConstants.DEFAULT_FONTFAMILY;
    let ff = accessCookie(SemTalkCookie.font);
    if (ff === 'undefined') ff = ff0;
    if (!ff) ff = ff0;

    let docbody: (Paragraph | Table)[] = [];

    docbody.push(new Paragraph({
        children: [
            new TextRun({
                text: modTitle,
                size: 28,
                bold: false,
                font: ff,
            })]
    }));

    docbody.push(new Paragraph({ text: "" }));
    docbody.push(new Paragraph({ text: "" }));

    docbody.push(new TableOfContents(semtalk.getResStr(ResID.STRTOC), {
        hyperlink: true,
        headingStyleRange: "1-5",
    }));
    // docbody.push(new Paragraph({ text: "", pageBreakBefore: true }));

    let metadata = await callback.getMongoDBDocumentMetaData(filename);
    if (metadata) {
        if (metadata.value) {
            metadata = metadata.value[0];
        }
        let tbl = exportMetaDataTable(semtalk, metadata);
        if (tbl !== null) {
            docbody.push(new Paragraph({ text: "" }));
            docbody.push(tbl);
        }
    }


    // let curDiag: any = this.res.page?.ID;
    for (let d of semtalk.base.AllDiagrams()) {
        docbody.push(paragraph(d.ObjectCaption, HeadingLevel.HEADING_1));
        // selOpt.push(new Paragraph({ text: "Diagram view:", heading: HeadingLevel.HEADING_2 }));
        docbody.push(new Paragraph({ text: "" }));
        docbody.push(new Paragraph({ text: "" }));

        try {
            let image: ImageRun = await exportPageImage(semtalk, d);
            docbody.push(new Paragraph({ children: [image] }));
            // g.parentNode.removeChild(g);
        } catch (e) {
        }
        docbody.push(new Paragraph({ text: "" }));
        docbody.push(new Paragraph({ text: "" }));
        docbody.push(paragraph(d.Comment));
        // selOpt.push(new Paragraph({ text: "Contents:", heading: HeadingLevel.HEADING_2 }));
        docbody.push(new Paragraph({ text: "", pageBreakBefore: true }));
        const table = exportPageTable(semtalk, d);
        docbody.push(table as Table);
    }
    const doc = new Document({
        creator: "SemTalk Online",
        title: modTitle,
        description: "SemTalk Online - Word Export",
        features: {
            updateFields: true,
        },
        sections: [{
            properties: {
                titlePage: true,
            },
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [
                                new TextRun(modTitle),
                                // new TextRun({
                                //     children: ["Page ", PageNumber.CURRENT],
                                // }),
                            ],
                        }),
                    ],
                }),
                // first: new Header({
                //   children: [
                //     new Paragraph({
                //       alignment: AlignmentType.RIGHT,
                //       children: [
                //         new TextRun({
                //           children: [this.props.semtalk.getResStr(ResID.STRPAGE), PageNumber.CURRENT, "/", PageNumber.TOTAL_PAGES],
                //         }),
                //       ],
                //     }),
                //   ],
                // }),
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [
                                // new TextRun("My Title "),
                                new TextRun({
                                    children: [semtalk.getResStr(ResID.STRPAGE), PageNumber.CURRENT, " / ", PageNumber.TOTAL_PAGES],
                                }),
                            ],
                        }),
                    ],
                }),
                // first: new Footer({
                //     children: [
                //         new Paragraph({
                //             alignment: AlignmentType.RIGHT,
                //             children: [
                //                 new TextRun("First Page Footer "),
                //                 new TextRun({
                //                     children: ["Page ", PageNumber.CURRENT],
                //                 }),
                //             ],
                //         }),
                //     ],
                // }),
            },
            children: docbody
        }]
    });
    return doc;
}

export async function packword(doc: Document): Promise<string> {
    //   // this.getPNG4DOCX(svgJson, docHeader, table, diagTitle);
    const b64string = await Packer.toBase64String(doc);
    return b64string;
}