export const bpmn: string = "bpmn:";
export const xmlns: string = "xmlns:";
export const bpmndi: string = "bpmndi:";
export const dc: string = "dc:";
export const omgdc: string = "omgdc:";
export const omgdi: string = "omgdi:";
export const di: string = "di:";
export const bioc: string = "bioc:";
export const color: string = "color:";
export const semtalkns: string = "semtalk:";
export const adonis: string ="adonis:";


export enum BPMNAssociation {
    sequenceFlow = "sequenceFlow",
    dataOutputAssociation = "dataOutputAssociation",
    dataInputAssociation = "dataInputAssociation",
    messageFlow = "messageFlow",
    association = "association" 
}
export enum BPMNElement {
    BPMNDiagram = "BPMNDiagram",
    BPMNPlane = "BPMNPlane",
    collaboration = "collaboration",
    process = "process",
    laneset = "laneSet",
    lane = "lane",
    childLaneSet = 'childLaneSet',
    flowNodeRef = "flowNodeRef",
    participant = "participant",
    sequenceFlow = "sequenceFlow",
    dataOutputAssociation = "dataOutputAssociation",
    dataInputAssociation = "dataInputAssociation",
    sourceRef = "sourceRef",
    targetRef = "targetRef",
    outgoing = "outgoing",
    incoming = "incoming",
    BPMNEdge = "BPMNEdge",
    messageFlow = "messageFlow",
    BPMNShape = "BPMNShape",
    Bounds = "Bounds",
    waypoint = "waypoint",
    dataObjectReference = "dataObjectReference",
    dataObject = "dataObject",
    dataStoreReference = "dataStoreReference",
    textAnnotation = "textAnnotation",
    text = "text",
    association = "association",
    complexGateway = "complexGateway",
    eventBasedGateway = "eventBasedGateway",
    inclusiveGateway = "inclusiveGateway",
    parallelGateway = "parallelGateway",
    exclusiveGateway = "exclusiveGateway",
    startEvent = "startEvent",
    endEvent = "endEvent",
    intermediateCatchEvent = "intermediateCatchEvent",
    intermediateThrowEvent = "intermediateThrowEvent",
    sendTask = "sendTask",
    userTask = "userTask",
    manualTask = "manualTask",
    businessRuleTask = "businessRuleTask",
    serviceTask = "serviceTask",
    scriptTask = "scriptTask",
    receiveTask = "receiveTask",
    adHocSubProcess = "adHocSubProcess",
    task = "task",
    subProcess = "subProcess",
    standardLoopCharacteristics = "standardLoopCharacteristics",
    multiInstanceLoopCharacteristics = "multiInstanceLoopCharacteristics",
    compensateEventDefinition = "compensateEventDefinition",
    errorEventDefinition = "errorEventDefinition",
    escalationEventDefinition = "escalationEventDefinition",
    linkEventDefinition = "linkEventDefinition",
    messageEventDefinition = "messageEventDefinition",
    conditionalEventDefinition = "conditionalEventDefinition",
    condition = "condition",
    signalEventDefinition = "signalEventDefinition",
    terminateEventDefinition = "terminateEventDefinition",
    timerEventDefinition = "timerEventDefinition",
    documentation = "documentation",
    extensionElements = "extensionElements"

}

export enum BPMNElementPrefix {
    BPMNDiagram = "BPMNDiagram_",
    collaboration = "Collaboration_",
    BPMNPlane = "BPMNPlane_",
    process = "Process_",
    laneset = "LaneSet_",
    lane = "Lane_",
    participant = "Participant_",
    sequenceFlow = "Flow_",
    messageFlow = "Flow_",
    dataObjectReference = "DataObjectReference_",
    dataObject = "DataObject_",
    dataStoreReference = "DataStoreReference_",
    textAnnotation = "TextAnnotation_",
    association = "Association_",
    triggertype = "TriggerTypeID_",
    dataInputAssociation = "DataInputAssociation_",
    dataOutputAssociation = "DataOutputAssociation_",
}
export enum BPMNAttribute {
    name = "name",
    id = "id",
    bpmnElement = "bpmnElement",
    isExecutable = "isExecutable",
    processRef = "processRef",
    sourceRef = "sourceRef",
    targetRef = "targetRef",
    x = "x",
    y = "y",
    height = "height",
    width = "width",
    isHorizontal = "isHorizontal",
    isExpanded = "isExpanded",
    isSequential = "isSequential",
    dataObjectRef = "dataObjectRef"
}

export enum SemTalkExtension {
    instance = 'instance',
    shape = 'shape',
    ID = 'id',
    Name = 'name',
    Type = 'type',
    Style = 'style',
    Class = 'class',
    SystemClass = 'systemclass',
    Refinement = 'refinement',
    ExtRefinement = 'extrefinement',
    Synonym = "synonym",
    Language = "language",
    Attachment = "attachment",
    Composition = 'composition',
    BusinessClass = 'businessclass',
    Method = 'method',
    Attribute = 'attribute',
    State = 'state',
    Label = 'label',
    Namespace = 'namespace',
    InfoType = 'infotype'
}