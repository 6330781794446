import {
    mxUtils,
    mxGraphModel

} from "mxgraph-js";
import { IObjectBase, ISemTalkAssociationType, ISemTalkAttributeType, ISemTalkMethodType, ISemTalkObject, ISemTalkStateType, ISemTalkSystemClass, SemTalkType } from "../application/tbase/Interface";
import { ObjectBase } from "../application/tbase/ObjectBase";
import { SemTalkAttributeType, SemTalkClass, SemTalkInstance } from "../application/tbase/Tbase";
import { Process_ElementName } from "../application/semtalklistener/processInterface";
import { SemTalkBusinessClass, SemTalkMethodType, SemTalkStateType } from "../application/tbase/BusinessClass";
function cellsToString(cells: mxCell[]) {
    var codec = new mxCodec();
    var model = new mxGraphModel();
    var parent = model.getChildAt(model.getRoot(), 0);

    for (var i = 0; i < cells.length; i++) {
        model.add(parent, cells[i]);
    }

    return mxUtils.getXml(codec.encode(model));
}

// Inserts the XML for the given cells into the text input for copy
export function copyCells(graph: mxGraph, cells: mxCell[]): string {
    if (cells.length > 0) {
        let clones = graph.cloneCells(cells);

        // Checks for orphaned relative children and makes absolute
        for (var i = 0; i < clones.length; i++) {
            let state = graph.view.getState(cells[i]);

            if (state != null) {
                var geo = graph.getCellGeometry(clones[i]);

                if (geo != null && geo.relative) {
                    geo.relative = false;
                    geo.x = state.x / state.view.scale - state.view.translate.x;
                    geo.y = state.y / state.view.scale - state.view.translate.y;
                }
            }
        }

        return cellsToString(clones);
        // textInput.value = cellsToString(clones);
    }

    // textInput.select();
    // return textInput.value;
    return "";
}

// Merges XML into existing graph and layers
export function importXml(graph: mxGraph, xml: string, dx: number, dy: number, absolute: boolean) {
    dx = (dx != null) ? dx : 0;
    dy = (dy != null) ? dy : 0;
    let cells: mxCell[] = [];

    try {
        const doc = mxUtils.parseXml(xml);
        const node = doc.documentElement;

        if (node != null) {
            const model = new mxGraphModel();
            const codec = new mxCodec(node.ownerDocument);
            codec.decode(node, model);

            var childCount = model.getChildCount(model.getRoot());
            var targetChildCount = graph.model.getChildCount(graph.model.getRoot());

            // Merges existing layers and adds new layers
            graph.model.beginUpdate();
            try {
                for (var i = 0; i < childCount; i++) {
                    let parent = model.getChildAt(model.getRoot(), i);

                    // Adds cells to existing layers if not locked
                    if (targetChildCount > i) {
                        // Inserts into active layer if only one layer is being pasted
                        const target = (childCount === 1) ? graph.getDefaultParent() : graph.model.getChildAt(graph.model.getRoot(), i);

                        if (!graph.isCellLocked(target)) {
                            const children1 = model.getChildren(parent);
                            let geo = graph.getCellGeometry(children1[0]);
                            if (absolute) {
                                cells = cells.concat(graph.importCells(children1, dx - geo.x, dy - geo.y, target));
                            } else {
                                cells = cells.concat(graph.importCells(children1, dx, dy, target));
                            }
                        }
                    }
                    else {
                        // Delta is non cascading, needs separate move for layers
                        parent = graph.importCells([parent], 0, 0, graph.model.getRoot())[0];
                        let children = graph.model.getChildren(parent);
                        let geo = graph.getCellGeometry(children[0]);
                        if (absolute) {
                            graph.moveCells(children, dx - geo.x, dy - geo.y, true);
                        } else {
                            graph.moveCells(children, dx, dy, true);
                        }
                    cells = cells.concat(children);

                    }
                }
            }
            finally {
                graph.model.endUpdate();
            }
        }
    }
    catch (e) {
        alert(e);
        throw e;
    }

    return cells;
}

// Cross-browser function to fetch text from paste events
export function extractDataFromEvent(evt: any) {
    let data: any = null;

    if (evt !== null) {
        const provider = (evt.dataTransfer) ? evt.dataTransfer : evt.clipboardData;

        if (provider != null) {
            let dm = (document as any)["documentMode"];
            if (dm === 10 || dm === 11) {
                data = provider.getData('Text');
            }
            else {
                data = (mxUtils.indexOf(provider.types, 'text/html') >= 0) ? provider.getData('text/html') : null;

                if (mxUtils.indexOf(provider.types, 'text/plain' && (data == null || data.length === 0))) {
                    data = provider.getData('text/plain');
                }
            }
        }
    }
    return data;
}

export function cloneObjects(base: IObjectBase, cells: mxCell[], objects: any) {
    let infotype = base.FindAssociationType(base.GetModelAttribute(Process_ElementName.SLInfoType));
    for (let cell of cells) {
        if (cell && cell.children) {
            cloneObjects(base, cell.children, objects);
        }
        if (cell.objectid) {
            let obj = base.FindObjectByID(cell.objectid);
            if (obj) {
                let js0: any[] = [];
                for (let lnk of obj.Attachments()) {
                    let att = lnk.ToObject;
                    let ajs: any[] = [];
                    att.Save(ajs);
                    objects[att.ID] = ajs[0];
                    let ljs: any[] = [];
                    lnk.Save(ljs);
                    objects[lnk.ID] = ljs[0];
                }
                if (infotype) {
                    for (let inf of obj.LinkedObjects(infotype)) {
                        let ijs: any[] = [];
                        inf.Save(ijs);
                        objects[inf.ID] = ijs[0];
                    }
                }
                let cid = "";
                if (base.IsInstance(obj)) {
                    let c = (obj as SemTalkInstance).ClassOf();
                    if (c.Composition()) {
                        let js: any[] = [];
                        c.Save(js);
                        objects[c.ID] = js[0];
                        cid = c.ID;
                    }
                }
                if (base.IsClass(obj)) {
                    for (let a of (obj as SemTalkClass).Attributes()) {
                        let c = a.ClassOf();
                        let js: any[] = [];
                        c.Save(js);
                        objects[c.ID] = js[0];

                    }
                }
                if (base.IsBusinessClass(obj)) {
                    for (let a of (obj as SemTalkBusinessClass).Methods()) {
                        let c = a.ClassOf();
                        let js: any[] = [];
                        c.Save(js);
                        objects[c.ID] = js[0];

                    }
                    for (let a of (obj as SemTalkBusinessClass).States()) {
                        let c = a.ClassOf();
                        let js: any[] = [];
                        c.Save(js);
                        objects[c.ID] = js[0];

                    }
                }
                obj.Save(js0);
                objects[obj.ID] = js0[0];
                if (cid.length > 0) {
                    objects[obj.ID].classid = cid;
                }
            }
        }
    }
    return objects;
}
export function parseObjects(base: IObjectBase, cells: mxCell[], objects: any) {
    if (Object.keys(objects).length === 0) {
        return;
    }
    let impbase = new ObjectBase();
    let dummy = impbase.MakeClass("Dummy");
    let attachment = base.MakeClass("Attachment");
    let dummies: any = {};
    for (let objectid of Object.keys(objects)) {
        let clone = objects[objectid];
        let clonetype = clone["ObjectType"];
        let cloneobj: ISemTalkObject | null = null;
        switch (clonetype) {
            case "Instance": {
                if (clone["class"] === "Attachment") {
                    if (base.FindInstance(clone["name"]) === null) {
                        new SemTalkInstance(attachment, clone["name"], SemTalkType.SemTalkInstance, clone["ID"]);
                    }
                    cloneobj = new SemTalkInstance(dummy, clone["name"], SemTalkType.SemTalkInstance, clone["ID"]);
                } else {
                    cloneobj = new SemTalkInstance(dummy, clone["name"], SemTalkType.SemTalkInstance, clone["ID"]);
                }
                break;
            }
            case "Association": {
                cloneobj = new SemTalkInstance(dummy, clone["name"], SemTalkType.SemTalkInstance, clone["ID"]);
                break;
            }
            case "AttributeType": {
                cloneobj = new SemTalkAttributeType(impbase, clone["name"], clone["ID"]);
                break;
            }
            case "MethodType": {
                cloneobj = new SemTalkMethodType(impbase, clone["name"], clone["ID"]);
                break;
            }
            case "StateType": {
                cloneobj = new SemTalkStateType(impbase, clone["name"], clone["ID"]);
                break;
            }
            case "Class": {
                if (clone.BusinessClass === 'True') {
                    cloneobj = new SemTalkBusinessClass(impbase, clone["name"], clone["ID"]);
                } else {
                    cloneobj = new SemTalkClass(impbase, clone["name"], clone["ID"]);
                }
                break;
            }
        }
        if (cloneobj) {
            dummies[objectid] = cloneobj;
        }
    }

    let infotype = base.FindAssociationType(base.GetModelAttribute(Process_ElementName.SLInfoType));
    let baseclass = base.FindSystemClass(base.GetModelAttribute(Process_ElementName.SLInformation));
    parseObjectsRec(base, cells, objects, dummies, infotype, baseclass);
}

function parseObjectsRec(base: IObjectBase, cells: mxCell[], objects: any,
    dummies: any, infotype: ISemTalkAssociationType | null, baseclass: ISemTalkSystemClass | null) {
    for (let cell of cells) {
        if (cell && cell.children) {
            parseObjectsRec(base, cell.children, objects, dummies, infotype, baseclass);
        }
        if (cell.orgid && cell.objectid) {
            let clone = objects[cell.orgid];
            if (clone) {
                let cloneobj: ISemTalkObject | null = base.FindObjectByID(cell.orgid);
                let newobj = base.FindObjectByID(cell.objectid);
                if (newobj && cloneobj === null) {
                    if (!cloneobj) {
                        cloneobj = dummies[cell.orgid];
                    }
                    if (cloneobj) {
                        cloneobj.Load(clone);
                    }
                    if (cloneobj) {
                        if (base.FindObject(newobj.ObjectType, cloneobj.ObjectName) === null) {
                            newobj.RenameObject(cloneobj.ObjectName);
                        }
                        newobj.Clone(cloneobj);
                        if (infotype && baseclass) {
                            for (let cloneinfo of cloneobj.LinkedObjects(infotype)) {
                                let info = base.FindBusinessClass(cloneinfo.ObjectName);
                                if (!info) {
                                    info = base.MakeBusinessClass(cloneinfo.ObjectName);
                                }
                                if (!info.IsParent(baseclass)) {
                                    info.AddSubclassOf(baseclass);
                                }
                                if (info && !newobj.HasDirectLink(infotype, info)) {
                                    newobj.MakeAssociation(infotype, info);
                                }
                            }
                        }
                        if (clone.classid && baseclass) {
                            if (base.IsInstance(newobj)) {
                                let cloneclass = objects[clone.classid];
                                let newobjclass = (newobj as SemTalkInstance).ClassOf();
                                if (!newobjclass.Composition()) {
                                    let cmp = cloneclass.composition;
                                    if (cmp) {
                                        console.debug(cmp);
                                        let fclassname = cmp.fclass;
                                        let fclass = base.FindBusinessClass(fclassname);
                                        if (!fclass) {
                                            fclass = base.MakeBusinessClass(fclassname);
                                            if (!fclass.IsParent(baseclass)) {
                                                fclass.AddSubclassOf(baseclass);
                                            }
                                        }
                                        let fmethod: ISemTalkMethodType | null = null;
                                        let fmethodname = cmp.fmethod;
                                        if (fmethodname) {
                                            if (!fclass.FindMethod(fmethodname)) {
                                                fclass.MakeMethod(fmethodname);
                                            }
                                            fmethod = base.FindMethodType(fmethodname);
                                        }

                                        let feature = cmp.feature;
                                        let ftype = cmp.ftype;
                                        let fstate: ISemTalkStateType | null = null;
                                        let fattr: ISemTalkAttributeType | null = null;
                                        if (feature) {
                                            if (ftype === '1') {
                                                if (!fclass.FindState(feature)) {
                                                    fclass.MakeState(feature);
                                                }
                                                fstate = base.FindStateType(feature);
                                            } else {
                                                if (!fclass.FindAttribute(feature)) {
                                                    fclass.MakeAttribute(feature, "");
                                                }
                                                fattr = base.FindAttributeType(feature);
                                            }
                                        }
                                        newobjclass.MakeComposition(fclass, fmethod, fstate, fattr);
                                    }
                                }
                            }

                        }
                    }
                    // }
                }
            }
        }
    }
}