import {
  IObjectBase, ISemTalkClass, ISemTalkDiagramType,
  ISemTalkInstance, ISemTalkObject, SemTalkRelation, SemTalkType,
  SemTalkBaseConstant,
  ModelAttribute,
  ISemTalkAssociation,
  SemTalkID,
  SemTalkJSON,
  ISemTalkBusinessClass,
  ISemTalkSystemClass
} from "./Interface";
// import * as base64 from 'base-64';
import { Guid } from "guid-typescript";
import { ObjectBase } from "./ObjectBase";
import { Process_ElementName } from "../semtalklistener/processInterface";


// hiermit könnte man XML lesen
// https://www.npmjs.com/package/xml2js

export class OB2JSON {
  private isSystemClass = (c: any): boolean => {
    switch (c.name) {
      case "Comment": return true;
      case "XOR": return true;
      case "OR": return true;
      case "AND": return true;
      case "epc#Class": return true;
    }
    return false;
  }
  public LoadJSON(tb: IObjectBase, json: SemTalkJSON, _fn?: () => void): void {
    // tslint:disable-next-line:no-var-keyword
    let inp = json;
    // console.debug("Loading");
    tb._loading = true;
    if (inp) {
      for (const v in inp) {
        switch (v) {
          case "classes":
          case "assoctypes":
          case "diagtypes":
          case "instances":
          case "diags":
          case "methodtypes":
          case "attrtypes":
          case "statetypes":
            break;
          case "MaxID":
            tb.maxid = Number(inp[v]);
            break;
          default:
            tb.SetModelAttribute(v, inp[v]);
        }
      }
      tb.ObjectName = tb.GetModelAttribute(ModelAttribute.modname);
      if (inp.classes !== undefined) {
        for (const c of inp.classes) {
          let cid: SemTalkID = c.ID;
          if (c.SystemClass === "True" || this.isSystemClass(c)) {
            const cs = tb.MakeSystemClass(c.name, cid);
            // tslint:disable-next-line:no-console
            console.assert(cs !== null);

          } else {
            if (c.BusinessClass === "True") {
              const cs = tb.MakeBusinessClass(c.name, cid);
              // tslint:disable-next-line:no-console
              console.assert(cs !== null);

            } else {
              const cs = tb.MakeClass(c.name, cid);
              // tslint:disable-next-line:no-console
              console.assert(cs !== null);
            }
          }
        }
      }
      if (inp.assoctypes !== undefined) {
        for (const c of inp.assoctypes) {
          let cid: SemTalkID = c.ID;
          if (!cid || cid === "0") {
            // if (!cid) {
            cid = tb.NewID();
          }
          let cs = tb.FindAssociationType(c.name);
          if (cs === null) {
            let rt = ObjectBase.FindSemTalkRelationType(c.type);
            if (c.name === SemTalkBaseConstant.SLInstanceOf) {
              //  console.debug("instanceOf");
              rt = SemTalkRelation.SemTalkInstanceOf;
              c.type = SemTalkBaseConstant.SLInstanceOf;
            }
            cs = tb.MakeAssociationType(rt, c.name, cid);
            console.assert(cs !== null);
          }
          if (cs && c.name === SemTalkBaseConstant.SLInstanceOf && cs.RelationType !== SemTalkRelation.SemTalkInstanceOf) {
            cs.RelationType = SemTalkRelation.SemTalkInstanceOf;
          }
        }
      }
      if (inp.methodtypes !== undefined) {
        for (const c of inp.methodtypes) {
          const cs = tb.MakeMethodType(c.name, c.ID);
          console.assert(cs !== null);
        }
      }
      if (inp.statetypes !== undefined) {
        for (const c of inp.statetypes) {
          const cs = tb.MakeStateType(c.name, c.ID);
          console.assert(cs !== null);
        }
      }
      if (inp.attrtypes !== undefined) {
        for (const c of inp.attrtypes) {
          const cs = tb.MakeAttributeType(c.name, c.ID);
          console.assert(cs !== null);
        }
      }
      if (inp.diagtypes !== undefined) {
        for (const c of inp.diagtypes) {
          const cs = tb.MakeDiagramType(c.name, c.ID);
          console.assert(cs !== null);
        }
      }
      if (inp.instances !== undefined) {
        for (const c of inp.instances) {
          const cl = tb.FindClass(c.class);
          console.assert(cl !== null);
          let cs = tb.MakeInstance((cl as ISemTalkClass), c.name, SemTalkType.SemTalkInstance, c.ID);
          console.assert(cs !== null);
        }
      }
      if (inp.diags !== undefined) {
        for (const c of inp.diags) {
          const cl = tb.FindDiagramType(c.class);
          if (cl !== null) {
            const cs = tb.MakeDiagram(cl, c.name, SemTalkType.SemTalkDiagram, c.ID);
            console.assert(cs !== null);
          }
        }
      }
      if (inp.diagtypes !== undefined) {
        for (const c of inp.diagtypes) {
          const at = tb.FindDiagramType(c.name);
          if (at !== null) {
            at.Load(c);
          }
        }
      }
      if (inp.attrtypes !== undefined) {
        for (const c of inp.attrtypes) {
          const at = tb.FindAttributeType(c.name);
          if (at !== null) {
            at.Load(c);
          }
        }
      }
      if (inp.statetypes !== undefined) {
        for (const c of inp.statetypes) {
          const at = tb.FindStateType(c.name);
          if (at !== null) {
            at.Load(c);
          }
        }
      }
      if (inp.methodtypes !== undefined) {
        for (const c of inp.methodtypes) {
          const at = tb.FindMethodType(c.name);
          if (at !== null) {
            at.Load(c);
          }
        }
      }
      if (inp.assoctypes !== undefined) {
        for (const c of inp.assoctypes) {
          const at = tb.FindAssociationType(c.name);
          if (at !== null) {
            at.Load(c);
          }
        }
      }
      if (inp.diags !== undefined) {
        for (const c of inp.diags) {
          const at = tb.FindDiagram(c.name);
          if (at !== null) {
            at.Load(c);
          }
        }
      }
      if (inp.classes !== undefined) {
        for (const c of inp.classes) {
          const cl = tb.FindClass(c.name);
          if (cl !== null) {
            cl.Load(c);
          }
        }
      }
      if (inp.instances !== undefined) {
        for (const c of inp.instances) {
          const at = tb.FindInstance(c.name);
          if (at !== null) {
            at.Load(c);
          }
        }
      }
    }
    tb._loading = false;
    // if (fn !== undefined) {
    //   fn();
    // }
  }

  public SaveJSON(tb: IObjectBase): SemTalkJSON {
    // tslint:disable:no-var-keyword
    // tslint:disable:prefer-const
    var doc: SemTalkJSON = {};
    for (const name of tb.AllModelAttributes()) {
      doc[name] = tb.GetModelAttribute(name);
    }
    // $.each(tb._modelattr, (name: string, val: any) => { doc[name] = val; });
    doc.MaxID = tb.maxid;

    var jclasses: any[] = [];
    for (const obj of tb.AllClasses()) {
      // if (obj.ObjectType === SemTalkType.SemTalkClass) {
      if (tb.IsClass(obj)) {
        obj.Save(jclasses);
      }
    }
    doc.classes = jclasses;
    // $.each(tb._classes, (_id: string, obj: tbase.SemTalkClass) => {
    //     if (obj.ObjectType === SemTalkType.SemTalkClass) { obj.Save(jclasses); }
    // });

    var jassoctypes: any[] = [];
    for (const obj of tb.AllAssociationTypes()) {
      obj.Save(jassoctypes);
    }
    // $.each(tb._assoctypes, (_id: string, obj: tbase.SemTalkAssociationType) => { obj.Save(jassoctypes); });
    doc.assoctypes = jassoctypes;

    var jdiagtypes: any[] = [];
    for (const obj of tb.AllDiagramTypes()) {
      obj.Save(jdiagtypes);
    }
    // $.each(tb._diagtypes, (id: string, obj: nodes.SemTalkDiagramType): any => { obj.Save(jdiagtypes); });
    doc.diagtypes = jdiagtypes;

    // tslint:disable-next-line:no-var-keyword
    var jinstances: any[] = [];
    for (const obj of tb.AllInstances()) {
      if (obj.ObjectType === SemTalkType.SemTalkInstance) {
        obj.Save(jinstances);
      }
    }
    // $.each(tb._instances, (id: string, obj: tbase.SemTalkInstance) => {
    //     if (obj.ObjectType === SemTalkType.SemTalkInstance) { obj.Save(jinstances); }
    // });
    doc.instances = jinstances;

    // tslint:disable-next-line:no-var-keyword
    var jdiags: any[] = [];
    for (const obj of tb.AllDiagrams()) {
      obj.Save(jdiags);
    }
    // $.each(tb._diags, (id: string, obj: nodes.SemTalkDiagram) => { obj.Save(jdiags); });
    doc.diags = jdiags;

    // tslint:disable-next-line:no-var-keyword
    var jmethodtypes: any[] = [];
    for (const obj of tb.AllMethodTypes()) {
      obj.Save(jmethodtypes);
    }
    //  $.each(tb._methodtypes, (id: string, obj: bbase.SemTalkMethodType) => { obj.Save(jmethodtypes); });
    doc.methodtypes = jmethodtypes;

    // tslint:disable-next-line:no-var-keyword
    var jstatetypes: any[] = [];
    for (const obj of tb.AllStateTypes()) {
      obj.Save(jstatetypes);
    }
    // $.each(tb._statetypes, (id: string, obj: bbase.SemTalkStateType) => { obj.Save(jstatetypes); });
    doc.statetypes = jstatetypes;

    // tslint:disable-next-line:no-var-keyword
    var jattrtypes: any[] = [];
    for (const obj of tb.AllAttributeTypes()) {
      obj.Save(jattrtypes);
    }
    // $.each(tb._attrtypes, (id: string, obj: tbase.SemTalkAttributeType) => { obj.Save(jattrtypes); });
    doc.attrtypes = jattrtypes;

    // var jfolders = [];
    // $.each(this._folders,  (id, obj) => { obj.Save(jfolders); });
    // doc.folders = jfolders;

    // tslint:enable:no-var-keyword
    // tslint:enable:prefer-const
    return doc;
  }

  private mapSystemClass(ob: IObjectBase, ob2: IObjectBase, sclass: ISemTalkSystemClass): ISemTalkSystemClass | null {
    let name = sclass.ObjectName;
    let info = ob2.GetModelAttribute(Process_ElementName.SLInformation);
    let buffer = ob2.GetModelAttribute(Process_ElementName.SLBuffer);
    switch (name) {
      case info: {
        name = ob.GetModelAttribute(Process_ElementName.SLInformation);
        break;
      }
      case buffer: {
        name = ob.GetModelAttribute(Process_ElementName.SLBuffer);
        break;
      }
    }
    return ob.FindSystemClass(name);
  }
  public Merge(ob: IObjectBase, ob2: IObjectBase, encode: any, decode: any,
    filename: string, goodlist: ISemTalkObject[]): any {
    let hasgoodlist: boolean = (goodlist.length > 0);
    ob._loading = true;
    if (!hasgoodlist) {
      for (let sc2 of ob2.AllDiagramTypes()) {
        let sc = ob.FindDiagramType(sc2.ObjectName);
        if (sc === null) {
          sc = ob.MakeDiagramType(sc2.ObjectName);
          sc.Clone(sc2);
        }
      }
      for (let sc2 of ob2.AllDiagrams()) {
        let sc2name = sc2.ObjectName;
        let sc0 = ob.FindDiagram(sc2name);
        if (sc0) {
          sc2name = sc2name.replace("http://www.semtalk.com/mxg#", "");
          sc2name = filename.replace(".sdx", "") + "#" + sc2name;
          sc2.RenameObject(sc2name);
        }
        let sc = ob.FindDiagram(sc2name);
        if (sc === null) {
          const cla = ob.FindDiagramType(sc2.ClassOf().ObjectName) as ISemTalkDiagramType;
          if (cla) {
            sc = ob.MakeDiagram(cla, sc2.ObjectName);
            sc.Clone(sc2);
            let mxg = sc2.GetValue(SemTalkBaseConstant.SLMXGAttribute);
            let encoded = ob2.GetModelAttribute(mxg);
            ob.SetModelAttribute(SemTalkBaseConstant.SLMXGPagePrefix + sc.ID, encoded);
            sc.SetValue(SemTalkBaseConstant.SLMXGAttribute, SemTalkBaseConstant.SLMXGPagePrefix + sc.ID);
          }
        } else {
          if (sc.Contents().length === 0) {
            // sc.DeleteAttribute(SemTalkBaseConstant.SLMXGAttribute);
            sc.Clone(sc2);
            let mxg = sc2.GetValue(SemTalkBaseConstant.SLMXGAttribute);
            let encoded = ob2.GetModelAttribute(mxg);
            // let decoded = base64.decode(encoded);
            // console.debug(decoded);
            ob.SetModelAttribute(SemTalkBaseConstant.SLMXGPagePrefix + sc.ID, encoded);
            sc.SetValue(SemTalkBaseConstant.SLMXGAttribute,
              SemTalkBaseConstant.SLMXGPagePrefix + sc.ID);
          }
        }
        if (sc) {
        }
      }
      for (let sc2 of ob2.AllAssociationTypes()) {
        let sc = ob.FindAssociationType(sc2.ObjectName);
        if (sc === null) {
          sc = ob.MakeAssociationType(sc2.RelationType, sc2.ObjectName);
          sc.Clone(sc2);
        }
      }
      for (let sc2 of ob2.AllSystemClasses()) {
        let sc = this.mapSystemClass(ob, ob2, sc2);
        if (sc === null) {
          sc = ob.MakeSystemClass(sc2.ObjectName);
          sc.Clone(sc2);
        }
      }
      for (let sc2 of ob2.AllAttributeTypes()) {
        let sc = ob.FindAttributeType(sc2.ObjectName);
        if (sc === null) {
          sc = ob.MakeAttributeType(sc2.ObjectName);
          sc.Clone(sc2);
        }
      }
      for (let sc2 of ob2.AllMethodTypes()) {
        let sc = ob.FindMethodType(sc2.ObjectName);
        if (sc === null) {
          sc = ob.MakeMethodType(sc2.ObjectName);
          sc.Clone(sc2);
        }
      }
      for (let sc2 of ob2.AllStateTypes()) {
        let sc = ob.FindStateType(sc2.ObjectName);
        if (sc === null) {
          sc = ob.MakeStateType(sc2.ObjectName);
          sc.Clone(sc2);
        }
      }
    }
    for (let sc2 of ob2.AllClasses()) {
      if (hasgoodlist && goodlist.indexOf(sc2) < 0) {
        continue;
      }
      if (ob2.IsSystemClass(sc2)) {
        continue;
      }
      let sc = ob.FindClass(sc2.ObjectName);
      if (sc === null) {
        if (ob2.IsBusinessClass(sc2)) {
          sc = ob.MakeBusinessClass(sc2.ObjectName);
        } else {
          sc = ob.MakeClass(sc2.ObjectName);
        }
        if (sc) {
          sc.Clone(sc2);
          if (sc2.Refinement) {
            let ref = ob.FindDiagram(sc2.Refinement.ObjectName);
            sc.Refinement = ref;
          }
        }
      }
    }
    for (let sc2 of ob2.AllClasses()) {
      if (hasgoodlist && goodlist.indexOf(sc2) < 0) {
        continue;
      }
      if (ob2.IsSystemClass(sc2)) {
        continue;
      }
      let sc = ob.FindClass(sc2.ObjectName);
      for (let ss2 of sc2.SuperClasses()) {
        let ssc: ISemTalkClass | null;
        if (ob2.IsSystemClass(ss2)) {
          ssc = this.mapSystemClass(ob, ob2, ss2 as ISemTalkSystemClass);
        } else {
          ssc = ob.FindClass(ss2.ObjectName);
        }
        if (sc && ssc && !ssc.FindSpecialization(sc)) {
          sc.AddSubclassOf(ssc);
        }
      }
      if (sc && !sc2.IsReadOnly) {
        for (let m of sc2.Attributes()) {
          let mname = m.ClassOf().ObjectName;
          if (!sc.FindAttribute(mname)) {
            let a = sc.MakeAttribute(mname, m.Value);
            a.ValueType = m.ValueType;
            a.Values = m.Values;
            a.Options = m.Options;
            a.Group = m.Group;
            a.Weight = m.Weight;
            a.AllowFreeForm = m.AllowFreeForm;
            a.IsList = m.IsList;
            a.Max = m.Max;
            a.Min = m.Min;
          }
        }
      }
      if (sc && ob2.IsBusinessClass(sc2)) {
        let scb = sc as ISemTalkBusinessClass;
        let sc2b = sc2 as ISemTalkBusinessClass;
        for (let m of sc2b.Methods()) {
          let mname = m.ClassOf().ObjectName;
          if (!scb.FindMethod(mname)) {
            scb.MakeMethod(mname);
          }
        }
        for (let m of sc2b.States()) {
          let mname = m.ClassOf().ObjectName;
          if (!scb.FindState(mname)) {
            scb.MakeState(mname);
          }
        }
      }
    }
    let instance_map: { [name: string]: ISemTalkInstance } = {};
    let mapping: { [oldid: string]: any } = {};
    let shapeids: { [newid: string]: string } = {};
    for (let sc2 of ob2.AllInstances()) {
      if (hasgoodlist && goodlist.indexOf(sc2) < 0) {
        continue;
      }
      const sys = sc2.SystemClass();
      // if (sys && !sys.OnceOnly) {
      let cla = ob.FindClass(sc2.ClassOf().ObjectName);
      if (ob2.IsSystemClass(sc2.ClassOf())) {
        cla = this.mapSystemClass(ob, ob2, sc2.ClassOf() as ISemTalkSystemClass);
      }
      let sc = ob.FindInstance(sc2.ObjectName);
      if (cla && (sc === null || (sys && sys.BottomUp))) {
        let iname = sc2.ObjectName;
        let id = sc2.ID;
        if (id && iname.endsWith("." + id.toString())) {
          const newid = ob.NewID();
          iname = iname.replace("." + id.toString(), "." + newid);
          id = newid;
        }
        sc = ob.MakeInstance(cla, iname, sc2.ObjectType, id);
        if (sc) {
          sc.Clone(sc2);
          if (sc2.Refinement) {
            let ref = ob.FindDiagram(sc2.Refinement.ObjectName);
            sc.Refinement = ref;
          }
        }
      }
      if (sc) {
        for (let m of sc2.Attributes()) {
          let mname = m.ClassOf().ObjectName;
          if (!sc.FindAttribute(mname)) {
            let a = sc.MakeAttribute(mname, m.Value);
            a.ValueType = m.ValueType;
            // a.Values = m.Values;
            // a.Options = m.Options;
            // a.Group = m.Group;
            // a.Weight = m.Weight;
            // a.AllowFreeForm = m.AllowFreeForm;
            // a.IsList = m.IsList;
            // a.Max = m.Max;
            // a.Min = m.Min;
          }
        }
      }
      if (sc2 && sc) {
        instance_map[sc2.ObjectName] = sc;
        mapping[sc2.ID + "_"] = sc;
      }
      // }
    }
    for (let sc2 of ob2.AllClasses()) {
      let me = ob.FindClass(sc2.ObjectName);
      if (me) {
        if (!hasgoodlist) {
          for (let nd2 of sc2.Nodes()) {
            let pg = ob.FindDiagram(nd2.Diagram.ObjectName);
            if (pg) {
              const oldshpid = nd2.ShapeID;
              const newshpid: string = Guid.create().toString();
              shapeids[newshpid] = oldshpid;
              pg.MakeNode(me, newshpid);
              // console.debug(nd);
            }
          }
        }
        for (let ass2 of sc2.Associations()) {
          let oth2 = ass2.ToObject;
          let acla = ass2.ClassOf().ObjectName;
          let oth: ISemTalkObject | undefined | null = ob.FindClass(oth2.ObjectName) as ISemTalkClass;
          if (oth === undefined) {
            oth = ob.FindInstance(oth2.ObjectName);
          }
          if (oth && !me.HasDirectLink(acla, oth)) {
            let ass: ISemTalkAssociation | null = null;
            let exists: boolean = false;
            if (acla === SemTalkBaseConstant.SLSubClassOf && ob.IsClass(oth)) {
              if (!me.IsParent(oth as ISemTalkClass)) {
                let ass2a = me.AddSubclassOf(oth as ISemTalkClass);
                if (ass2a) ass = ass2a;
              } else {
                exists = true;
              }
            } else {
              ass = me.MakeAssociation(acla, oth);
            }
            if (ass) {
              ass.Clone(ass2);
              for (let nd2 of ass2.Nodes()) {
                let pg = ob.FindDiagram(nd2.Diagram.ObjectName);
                if (pg) {
                  const oldshpid = nd2.ShapeID;
                  const newshpid: string = Guid.create().toString();
                  shapeids[newshpid] = oldshpid;
                  pg.MakeNode(ass, newshpid);
                  // console.debug(nd);
                }
              }
              mapping[ass2.ID + "_"] = ass;
            } else {
              if (!exists) {
                console.debug("Import: invalid relation: " + me.ObjectName + " " + acla + " " + oth.ObjectName);
              }
            }
          }
        }
      }
    }
    for (let sc2 of ob2.AllInstances()) {
      let me = instance_map[sc2.ObjectName];
      if (me === undefined) {
        me = ob.FindInstance(sc2.ObjectName) as ISemTalkInstance;
      }
      if (me) {
        if (!hasgoodlist) {
          for (let nd2 of sc2.Nodes()) {
            let pg = ob.FindDiagram(nd2.Diagram.ObjectName);
            if (pg) {
              const oldshpid = nd2.ShapeID;
              const newshpid: string = Guid.create().toString();
              shapeids[newshpid] = oldshpid;
              pg.MakeNode(me, newshpid);
              // console.debug(nd);
            }
          }
        }
        for (let ass2 of sc2.Associations()) {
          let oth2 = ass2.ToObject;
          let acla = ass2.ClassOf().ObjectName;
          let oth: ISemTalkObject | undefined | null = instance_map[oth2.ObjectName];
          if (oth === undefined) {
            oth = ob.FindInstance(oth2.ObjectName);
          }
          if (oth === null) {
            oth = ob.FindClass(oth2.ObjectName);
          }
          if (oth && !me.HasDirectLink(acla, oth)) {
            if (me.IsValid(oth, acla)) {
              // if (true) {
              let ass = me.MakeAssociation(acla, oth);
              ass.Clone(ass2);
              for (let nd2 of ass2.Nodes()) {
                let pg = ob.FindDiagram(nd2.Diagram.ObjectName);
                if (pg) {
                  const oldshpid = nd2.ShapeID;
                  const newshpid: string = Guid.create().toString();
                  shapeids[newshpid] = oldshpid;
                  pg.MakeNode(ass, newshpid);
                  // console.debug(nd);
                }
              }
              mapping[ass2.ID + "_"] = ass;
            } else {
              console.debug("Import: invalid relation: " + me.ObjectName + " " + acla + " " + oth!.ObjectName);
            }
          }
        }
      }
    }
    if (!hasgoodlist) {
      for (let sc2 of ob2.AllDiagrams()) {
        let sc = ob.FindDiagram(sc2.ObjectName);
        if (sc) {
          let mxg = sc.GetValue(SemTalkBaseConstant.SLMXGAttribute);
          let encoded = ob.GetModelAttribute(mxg);
          let decoded = decode(encoded);
          // console.debug(decoded);
          for (let oid in mapping) {
            let oids = 'objectid="' + oid.replace("_", "") + '"';
            if (decoded.indexOf(oids) > 0) {
              let newobj = mapping[oid];
              let oidn = 'objectid="' + newobj.ID + '"';
              decoded = decoded.replace(oids, oidn);
            }
          }
          for (let nd of sc.Contents()) {
            let shapeid = nd.ShapeID;
            const oldshpid = shapeids[shapeid];
            let oids = 'shapeid="' + oldshpid + '"';
            if (decoded.indexOf(oids) > 0) {
              let oidn = 'shapeid="' + shapeid + '"';
              decoded = decoded.replace(oids, oidn);
            }
          }
          encoded = encode(decoded);
          ob.SetModelAttribute(SemTalkBaseConstant.SLMXGPagePrefix + sc.ID, encoded);
        }
      }
      ob._loading = false;
      return mapping;
    }
  }
}
