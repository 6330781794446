import {
  IObjectBase,
  ILabelSpec,
  ISemTalkClass,
  ISemTalkAssociationType,
  ISemTalkInstance,
  ISemTalkSystemClass,
  ISemTalkInstDialog,
  ISemTalkTabSpecs,
  ISemTalkTabSpec,
  ISemTalkLabels,
  ISemTalkLabel,
  ISemTalkClassLabel,
  SemTalkRelation,
  SemTalkType,
  SemTalkConnectionPoint,
  ModelAttribute,
  SemTalkID,
  SemTalkLayoutField,
} from "./Interface";

import { SemTalkLabels } from "./Label";
import { SemTalkTabSpecs } from "./TabSpec";
import { SemTalkClass } from "./Tbase";
import { ObjectBase } from "./ObjectBase";

export class SemTalkInstDialog implements ISemTalkInstDialog {
  public CheckGeneral: boolean = true;
  public CheckNamespace: boolean = false;
  public CheckClass: boolean = false;
  public CheckMethods: boolean = false;
  public CheckAttributes: boolean = false;
  public CheckAssociations: boolean = false;
  public CheckSynonyms: boolean = false;
  public CheckCondition: boolean = false;
  public CheckAttachments: boolean = false;
  public CheckStates: boolean = false;
  public CheckAudit: boolean = true;
}
export class SemTalkClassDialog {
  public CheckGeneral: boolean = true;
  public CheckNamespace: boolean = true;
  public CheckMethods: boolean = false;
  public CheckAttributes: boolean = true;
  public CheckAssociations: boolean = true;
  public CheckSynonyms: boolean = true;
  public CheckAttachments: boolean = true;
  public CheckStates: boolean = false;
  public CheckAudit: boolean = true;
  public CheckSharePoint: boolean = false;
  public CheckRefinement: boolean = false;
  public CheckDSN: boolean = false;
}

export class SemTalkSystemClass extends SemTalkClass implements ISemTalkSystemClass {
  constructor(tb: IObjectBase, newname: string, id?: SemTalkID | null | undefined) {
    super(tb, newname, id);
    this._tabspecs = new SemTalkTabSpecs(this);
    this._labels = new SemTalkLabels(this);
    return this;
  }
  // public InstIcon: number = 4;
  // public ClassIcon: number = 0;
  public Hide: boolean = false;
  public OnceOnly: boolean = false;
  public BottomUp: boolean = false;
  public BottomUpSubclasses: boolean = false;
  public Composeable: boolean = false;
  public Refineable: boolean = true;
  public HyperlinksEnabled: boolean = false;
  public IsSystemClass = true;
  public ShowLabel: boolean = true;
  public Style: string = "";
  public ShapeHTML: string = "";
  public RefinementOfClass: boolean = false;
  public HideInClassDiagram: boolean = false;
  public InstDialog: SemTalkInstDialog | null = null;
  public ClassDialog: SemTalkClassDialog | null = null; // new tbase.SemTalkClassDialog()
  public SharepointGoodlist: string = "";
  public Prefix: string | null = null;

  public SystemClass(): ISemTalkSystemClass {
    return this;
  }
  public Delete(): void {
    this.ObjectBase.OnClassBeforeDeleted(this);
    super.Delete();
    delete this.ObjectBase._classes[this.ID + "_"];
    // for (const l of this.AllLabels()) { l.Delete(); }
    if (this._labels !== null) {
      this._labels.Delete();
    }
    // for (const l of this.TabSpecs()) { l.Delete(); }
    if (this._tabspecs !== null) {
      this._tabspecs.Delete();
    }
    this.ObjectBase.OnClassDeleted(this);
  }

  private _tabspecs: ISemTalkTabSpecs;
  public TabSpecDefinitions(): ISemTalkTabSpecs {
    return this._tabspecs;
  }
  public TabSpecs(): ISemTalkTabSpec[] {
    return this._tabspecs.TabSpecs();
  }
  public ClassTabSpecs(): ISemTalkTabSpec[] {
    return this._tabspecs.TabClassSpecs();
  }
  public InstanceTabSpecs(): ISemTalkTabSpec[] {
    return this._tabspecs.TabInstanceSpecs();
  }
  public FindTabSpec(aname: string): ISemTalkTabSpec | null {
    return this._tabspecs.FindTabSpec(aname);
  }

  private _labels: ISemTalkLabels;

  public Labels(mastername?: string): ISemTalkLabel[] {
    return this._labels.Labels(mastername);
  }
  public AllInstanceLabels(): ISemTalkLabel[] {
    return this._labels.AllInstanceLabels();
  }
  public ClassLabels(mastername?: string): ISemTalkClassLabel[] {
    return this._labels.ClassLabels(mastername);
  }
  public AllClassLabels(): ISemTalkClassLabel[] {
    return this._labels.AllClassLabels();
  }
  public MakeLabel(text: string, master: string): ISemTalkLabel {
    return this._labels.MakeLabel(text, master);
  }
  public MakeClassLabel(text: string, master: string): ISemTalkClassLabel {
    return this._labels.MakeClassLabel(text, master);
  }
  public AllLabels(): ISemTalkLabel[] {
    return this._labels.AllLabels();
  }
  public FindLabelForMaster(master: string, text?: string): ISemTalkLabel[] {
    return this._labels.FindLabelForMaster(master, text);
  }
  public FindClassLabelForMaster(master: string, text?: string): ISemTalkClassLabel[] {
    return this._labels.FindClassLabelForMaster(master, text);
  }
  public AllSystemSubClasses(): ISemTalkSystemClass[] {
    const alist: ISemTalkSystemClass[] = [];
    const fnd: any = {};
    const ptype = SemTalkRelation.SemTalkSubClassOf;
    function allsubclassesrec(cl: ISemTalkSystemClass): void {
      for (const x of cl.InvAssociations()) {
        if (x.ClassOf().RelationType === ptype && x.FromObject instanceof SemTalkSystemClass) {
          if (fnd[x.FromObject.ObjectName] === undefined) {
            fnd[x.FromObject.ObjectName] = x.FromObject;
            alist.push(x.FromObject as ISemTalkSystemClass);
            allsubclassesrec(x.FromObject as ISemTalkSystemClass);
          }
        }
      }
    }
    allsubclassesrec(this);
    return alist;
  }

  public get ShowAname(): boolean {
    return this._labels.ShowAname;
  }
  public set ShowAname(b: boolean) {
    this._labels.ShowAname = b;
  }
  public get ShowCAname(): boolean {
    return this._labels.ShowCAname;
  }
  public set ShowCAname(b: boolean) {
    this._labels.ShowCAname = b;
  }
  public get ShowUserNumber(): boolean {
    return this._labels.ShowUserNumber;
  }
  public set ShowUserNumber(b: boolean) {
    this._labels.ShowUserNumber = b;
  }
  public BuiltinInstLabels(): SemTalkLayoutField[] {
    return this._labels.builtinInstLabels;
  }
  public BuiltinClassLabels(): SemTalkLayoutField[] {
    return this._labels.builtinInstLabels;
  }
  public BuiltinBusinesClassLabels(): SemTalkLayoutField[] {
    return this._labels.builtinBusinesClassLabels;
  }
  public AllValidClassRelations(dst: ISemTalkSystemClass): ISemTalkAssociationType[] {
    // let bmode = this.ObjectBase.GetModelAttribute(ModelAttribute.BPMNMode);
    // if (bmode && bmode === "1") {
    //   const ob = this.ObjectBase;
    //   const task = ob.GetModelAttribute(Process_ElementName.SLActivity);
    //   const event = ob.GetModelAttribute(Process_ElementName.SLEvent);
    //   const gateway = ob.GetModelAttribute(Process_ElementName.SLDecision);
    //   const buffer = ob.GetModelAttribute(Process_ElementName.SLBuffer);
    //   // const participant = ob.GetModelAttribute(BPMN_ElementName.Participant);
    //   // const dataobject = ob.GetModelAttribute(Process_ElementName.SLDataObject);
    //   switch (this.ObjectName) {
    //     case SemTalkBaseConstant.SLSwimlane: {
    //       switch (dst.ObjectName) {
    //         case event: {
    //           if (dst.GetValue(BPMN_AttributeTypeName.EventType) === BPMN_EventTypeName.Start) {
    //             return [ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.SLMessageFlow)) as ISemTalkAssociationType];
    //           } else {
    //             return [];
    //           }
    //         }
    //         case SemTalkBaseConstant.SLSwimlane:
    //           if (this.ID!==dst.ID) {
    //             return [ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.SLMessageFlow)) as ISemTalkAssociationType];
    //           } else {
    //             return [];
    //           }
    //         case task:
    //         default:
    //           return [];
    //       }
    //     }
    //     case task: {
    //       switch (dst.ObjectName) {
    //         case gateway:
    //           return [ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.SLControl)) as ISemTalkAssociationType];
    //         case event:
    //         case task:
    //           return [ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.SLMessageFlow)) as ISemTalkAssociationType,
    //           ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.SLControl)) as ISemTalkAssociationType];
    //         case buffer:
    //           return [ob.FindAssociationType(BPMN_AssociationName.dataobject) as ISemTalkAssociationType];
    //         case SemTalkBaseConstant.SLSwimlane:
    //           return [ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.SLMessageFlow)) as ISemTalkAssociationType];
    //       }
    //       break;
    //     }
    //     case event: {
    //       switch (dst.ObjectName) {
    //         case gateway:
    //           return [ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.SLControl)) as ISemTalkAssociationType];
    //         case buffer:
    //           return [];
    //         case event:
    //         case task:
    //           return [ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.SLMessageFlow)) as ISemTalkAssociationType,
    //           ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.SLControl)) as ISemTalkAssociationType];
    //         case SemTalkBaseConstant.SLSwimlane:
    //           if (dst.GetValue(BPMN_AttributeTypeName.EventType) !== BPMN_EventTypeName.Start) {
    //             return [ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.SLMessageFlow)) as ISemTalkAssociationType];
    //           } else {
    //             return [];
    //           }
    //         // default:
    //         //   return [];
    //       }
    //       break;
    //     }
    //     case gateway: {
    //       switch (dst.ObjectName) {
    //         case gateway:
    //         case event:
    //         case task: {
    //           return [ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.SLControl)) as ISemTalkAssociationType];
    //         }
    //         default:
    //           return [];
    //       }
    //     }
    //     case buffer: {
    //       switch (dst.ObjectName) {
    //         case task:
    //           return [ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.SLMessageFlow)) as ISemTalkAssociationType,
    //           ob.FindAssociationType(BPMN_AssociationName.dataobject) as ISemTalkAssociationType];
    //         default:
    //           return [];
    //       }
    //     }
    //   }
    // }
    if (this.ObjectName === this.ObjectBase.GetModelAttribute(ModelAttribute.SLComment)) {
      const ob = this.ObjectBase;
      return [ob.FindAssociationType(ob.GetModelAttribute(ModelAttribute.SLCommentOf)) as ISemTalkAssociationType];
    }
    return super.AllValidClassRelations(dst);
  }

  public InitInstDialog(): ISemTalkInstDialog {
    this.InstDialog = new SemTalkInstDialog();
    return this.InstDialog;
  }
  public Load(je: any): void {
    super.Load(je);

    this.IsSystemClass = (je.SystemClass === "True");
    this.ShowLabel = (je.ShowLabel === "1");
    if (je.style) this.Style = je.style;
    if (je.html) this.ShapeHTML = je.html;
    this.Hide = (je.Hide === "1");
    this.OnceOnly = (je.OnceOnly === "1");
    this.BottomUp = (je.BottomUp === "1");
    this.BottomUpSubclasses = (je.BottomUpSubclasses === "1");
    this.Composeable = (je.Composeable === "1");
    this.Refineable = (je.Refineable === "1");
    this.RefinementOfClass = (je.RefinementOfClass === "1");
    this.HideInClassDiagram = (je.hideInClassDiagram === "1");

    if (je.LocalInstDialog === "1") {
      this.InstDialog = new SemTalkInstDialog();
      if (je.CheckGeneral !== undefined) { this.InstDialog.CheckGeneral = (je.CheckGeneral === "1"); }
      if (je.CheckNamespace !== undefined) { this.InstDialog.CheckNamespace = (je.CheckNamespace === "1"); }
      if (je.CheckClass !== undefined) { this.InstDialog.CheckClass = (je.CheckClass === "1"); }
      if (je.CheckMethods !== undefined) { this.InstDialog.CheckMethods = (je.CheckMethods === "1"); }
      if (je.CheckAttributes !== undefined) { this.InstDialog.CheckAttributes = (je.CheckAttributes === "1"); }
      if (je.CheckAssociations !== undefined) { this.InstDialog.CheckAssociations = (je.CheckAssociations === "1"); }
      if (je.CheckSynonyms !== undefined) { this.InstDialog.CheckSynonyms = (je.CheckSynonyms === "1"); }
      if (je.CheckCondition !== undefined) { this.InstDialog.CheckCondition = (je.CheckCondition === "1"); }
      if (je.CheckStates !== undefined) { this.InstDialog.CheckStates = (je.CheckStates === "1"); }
      if (je.CheckAudit !== undefined) { this.InstDialog.CheckAudit = (je.CheckAudit === "1"); }
      if (je.CheckAttachments !== undefined) { this.InstDialog.CheckAttachments = (je.CheckAttachments === "1"); }
    }
    if (je.LocalClassDialog === "1") {
      this.ClassDialog = new SemTalkClassDialog();
      if (je.CheckGeneralClass !== undefined) { this.ClassDialog.CheckGeneral = (je.CheckGeneralClass === "1"); }
      if (je.CheckNamespaceClass !== undefined) { this.ClassDialog.CheckNamespace = (je.CheckNamespaceClass === "1"); }
      if (je.CheckMethodsClass !== undefined) { this.ClassDialog.CheckMethods = (je.CheckMethodsClass === "1"); }
      if (je.CheckAttributesClass !== undefined) { this.ClassDialog.CheckAttributes = (je.CheckAttributesClass === "1"); }
      if (je.CheckAssociationsClass !== undefined) { this.ClassDialog.CheckAssociations = (je.CheckAssociationsClass === "1"); }
      if (je.CheckSynonymsClass !== undefined) { this.ClassDialog.CheckSynonyms = (je.CheckSynonymsClass === "1"); }
      if (je.CheckStatesClass !== undefined) { this.ClassDialog.CheckStates = (je.CheckStatesClass === "1"); }
      if (je.CheckAuditClass !== undefined) { this.ClassDialog.CheckAudit = (je.CheckAuditClass === "1"); }
      if (je.CheckSharePoint !== undefined) { this.ClassDialog.CheckSharePoint = (je.CheckSharePoint === "1"); }
      if (je.CheckRefinement !== undefined) { this.ClassDialog.CheckRefinement = (je.CheckRefinement === "1"); }
      if (je.CheckDSN !== undefined) { this.ClassDialog.CheckDSN = (je.CheckDSN === "1"); }
    }

    this._labels.Load(je);
    this._tabspecs.Load(je);
  }
  public LoadXML(element: Element): void {
    super.LoadXML(element);
    if (element.attributes) {
      // const tb = this.ObjectBase;
      let SystemClass = element.attributes.getNamedItem("SystemClass")?.value;
      if (SystemClass !== undefined) { this.IsSystemClass = (SystemClass === "True"); }
      let ShowLabel = element.attributes.getNamedItem("ShowLabel")?.value;
      if (ShowLabel !== undefined) { this.ShowLabel = (ShowLabel === "1"); }
      let style = element.attributes.getNamedItem("style")?.value;
      if (style !== undefined) { this.Style = style; }
      let html = element.attributes.getNamedItem("html")?.value;
      if (html !== undefined) { this.ShapeHTML = html; }
      let Hide = element.attributes.getNamedItem("Hide")?.value;
      if (Hide !== undefined) { this.Hide = (Hide === "1"); }
      let OnceOnly = element.attributes.getNamedItem("OnceOnly")?.value;
      if (OnceOnly !== undefined) { this.OnceOnly = (OnceOnly === "1"); }
      let BottomUp = element.attributes.getNamedItem("BottomUp")?.value;
      if (BottomUp !== undefined) { this.BottomUp = (BottomUp === "1"); }
      let BottomUpSubclasses = element.attributes.getNamedItem("BottomUpSubclasses")?.value;
      if (BottomUpSubclasses !== undefined) { this.BottomUpSubclasses = (BottomUpSubclasses === "1"); }
      let Composeable = element.attributes.getNamedItem("Composeable")?.value;
      if (Composeable !== undefined) { this.Composeable = (Composeable === "1"); }
      let Refineable = element.attributes.getNamedItem("Refineable")?.value;
      if (Refineable !== undefined) { this.Refineable = (Refineable === "1"); }
      let RefinementOfClass = element.attributes.getNamedItem("RefinementOfClass")?.value;
      if (RefinementOfClass !== undefined) { this.RefinementOfClass = (RefinementOfClass === "1"); }
      let HideInClassDiagram = element.attributes.getNamedItem("HideInClassDiagram")?.value;
      if (HideInClassDiagram !== undefined) { this.HideInClassDiagram = (HideInClassDiagram === "1"); }

      const LocalInstDialog = element.attributes.getNamedItem("LocalInstDialog")?.value;
      if (LocalInstDialog !== undefined && LocalInstDialog === "1") {
        this.InstDialog = new SemTalkInstDialog();
        const CheckGeneral = element.attributes.getNamedItem("CheckGeneral")?.value;
        if (CheckGeneral !== undefined) { this.InstDialog.CheckGeneral = (CheckGeneral === "1"); }
        const CheckNamespace = element.attributes.getNamedItem("CheckNamespace")?.value;
        if (CheckNamespace !== undefined) { this.InstDialog.CheckNamespace = (CheckNamespace === "1"); }
        const CheckClass = element.attributes.getNamedItem("CheckClass")?.value;
        if (CheckClass !== undefined) { this.InstDialog.CheckClass = (CheckClass === "1"); }
        const CheckMethods = element.attributes.getNamedItem("CheckMethods")?.value;
        if (CheckMethods !== undefined) { this.InstDialog.CheckMethods = (CheckMethods === "1"); }
        const CheckAttributes = element.attributes.getNamedItem("CheckAttributes")?.value;
        if (CheckAttributes !== undefined) { this.InstDialog.CheckAttributes = (CheckAttributes === "1"); }
        const CheckAssociations = element.attributes.getNamedItem("CheckAssociations")?.value;
        if (CheckAssociations !== undefined) { this.InstDialog.CheckAssociations = (CheckAssociations === "1"); }
        const CheckSynonyms = element.attributes.getNamedItem("CheckSynonyms")?.value;
        if (CheckSynonyms !== undefined) { this.InstDialog.CheckSynonyms = (CheckSynonyms === "1"); }
        const CheckCondition = element.attributes.getNamedItem("CheckCondition")?.value;
        if (CheckCondition !== undefined) { this.InstDialog.CheckCondition = (CheckCondition === "1"); }
        const CheckStates = element.attributes.getNamedItem("CheckStates")?.value;
        if (CheckStates !== undefined) { this.InstDialog.CheckStates = (CheckStates === "1"); }
        const CheckAudit = element.attributes.getNamedItem("CheckAudit")?.value;
        if (CheckAudit !== undefined) { this.InstDialog.CheckAudit = (CheckAudit === "1"); }
        const CheckAttachments = element.attributes.getNamedItem("CheckAttachments")?.value;
        if (CheckAttachments !== undefined) { this.InstDialog.CheckAttachments = (CheckAttachments === "1"); }
      }
      const LocalClassDialog = element.attributes.getNamedItem("LocalClassDialog")?.value;
      if (LocalClassDialog !== undefined && LocalClassDialog === "1") {
        this.ClassDialog = new SemTalkClassDialog();
        const CheckGeneralClass = element.attributes.getNamedItem("CheckGeneralClass")?.value;
        if (CheckGeneralClass !== undefined) { this.ClassDialog.CheckGeneral = (CheckGeneralClass === "1"); }
        const CheckNamespaceClass = element.attributes.getNamedItem("CheckNamespaceClass")?.value;
        if (CheckNamespaceClass !== undefined) { this.ClassDialog.CheckNamespace = (CheckNamespaceClass === "1"); }
        const CheckMethodsClass = element.attributes.getNamedItem("CheckMethodsClass")?.value;
        if (CheckMethodsClass !== undefined) { this.ClassDialog.CheckMethods = (CheckMethodsClass === "1"); }
        const CheckAttributesClass = element.attributes.getNamedItem("CheckAttributesClass")?.value;
        if (CheckAttributesClass !== undefined) { this.ClassDialog.CheckAttributes = (CheckAttributesClass === "1"); }
        const CheckSynonymsClass = element.attributes.getNamedItem("CheckSynonymsClass")?.value;
        if (CheckSynonymsClass !== undefined) { this.ClassDialog.CheckSynonyms = (CheckSynonymsClass === "1"); }
        const CheckStatesClass = element.attributes.getNamedItem("CheckStatesClass")?.value;
        if (CheckStatesClass !== undefined) { this.ClassDialog.CheckStates = (CheckStatesClass === "1"); }
        const CheckAuditClass = element.attributes.getNamedItem("CheckAuditClass")?.value;
        if (CheckAuditClass !== undefined) { this.ClassDialog.CheckAudit = (CheckAuditClass === "1"); }
        const CheckSharePoint = element.attributes.getNamedItem("CheckSharePoint")?.value;
        if (CheckSharePoint !== undefined) { this.ClassDialog.CheckSharePoint = (CheckSharePoint === "1"); }
        const CheckRefinement = element.attributes.getNamedItem("CheckRefinement")?.value;
        if (CheckRefinement !== undefined) { this.ClassDialog.CheckRefinement = (CheckRefinement === "1"); }
        const CheckDSN = element.attributes.getNamedItem("CheckDSN")?.value;
        if (CheckDSN !== undefined) { this.ClassDialog.CheckDSN = (CheckDSN === "1"); }
      }

    }
    this._labels.LoadXML(element);
    this._tabspecs.LoadXML(element);
  }

  public Save(par: any[]): any {
    let el = super.Save(par);

    if (this.IsSystemClass) { el.SystemClass = "True"; }
    if (this.ShowLabel) { el.ShowLabel = "1"; }
    if (this.Style.length > 0) el.style = this.Style;
    if (this.ShapeHTML.length > 0) el.html = this.ShapeHTML;

    if (this.Hide) { el.Hide = "1"; }
    if (this.OnceOnly) { el.OnceOnly = "1"; }
    if (this.BottomUp) { el.BottomUp = "1"; }
    if (this.BottomUpSubclasses) { el.BottomUpSubclasses = "1"; }
    if (this.Composeable) { el.Composeable = "1"; }
    if (this.Refineable) { el.Refineable = "1"; }
    if (this.RefinementOfClass) { el.RefinementOfClass = "1"; }
    if (this.HideInClassDiagram) { el.hideInClassDiagram = "1"; }

    if (this.InstDialog !== null && this.InstDialog !== undefined) {
      el.LocalInstDialog = "1";
      if (this.InstDialog.CheckGeneral) { el.CheckGeneral = "1"; }
      if (this.InstDialog.CheckNamespace) { el.CheckNamespace = "1"; }
      if (this.InstDialog.CheckClass) { el.CheckClass = "1"; }
      if (this.InstDialog.CheckMethods) { el.CheckMethods = "1"; }
      if (this.InstDialog.CheckAttributes) { el.CheckAttributes = "1"; }
      if (this.InstDialog.CheckAssociations) { el.CheckAssociations = "1"; }
      if (this.InstDialog.CheckSynonyms) { el.CheckSynonyms = "1"; }
      if (this.InstDialog.CheckCondition) { el.CheckCondition = "1"; }
      if (this.InstDialog.CheckStates) { el.CheckStates = "1"; }
      if (this.InstDialog.CheckAudit) { el.CheckAudit = "1"; }
      if (this.InstDialog.CheckAttachments) { el.CheckAttachments = "1"; }
    }
    if (this.ClassDialog !== null && this.ClassDialog !== undefined) {
      el.LocalClassDialog = "1";
      if (this.ClassDialog.CheckGeneral) { el.CheckGeneralClass = "1"; }
      if (this.ClassDialog.CheckNamespace) { el.CheckNamespaceClass = "1"; }
      if (this.ClassDialog.CheckMethods) { el.CheckMethodsClass = "1"; }
      if (this.ClassDialog.CheckAttributes) { el.CheckAttributesClass = "1"; }
      if (this.ClassDialog.CheckAssociations) { el.CheckAssociationsClass = "1"; }
      if (this.ClassDialog.CheckSynonyms) { el.CheckSynonymsClass = "1"; }
      if (this.ClassDialog.CheckStates) { el.CheckStatesClass = "1"; }
      if (this.ClassDialog.CheckAudit) { el.CheckAuditClass = "1"; }
      if (this.ClassDialog.CheckSharePoint) { el.CheckSharePoint = "1"; }
      if (this.ClassDialog.CheckRefinement) { el.CheckRefinement = "1"; }
      if (this.ClassDialog.CheckDSN) { el.CheckDSN = "1"; }
    }
    this._labels.Save(el);
    this._tabspecs.Save(el);

    return el;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    super.SaveXML(xd, el);
    if (this.IsSystemClass) { el.setAttribute("SystemClass", "True"); }
    if (this.ShowLabel) { el.setAttribute("ShowLabel", "1"); }
    if (this.Style.length > 0) el.setAttribute("style", this.Style);
    if (this.ShapeHTML.length > 0) el.setAttribute("html", this.ShapeHTML);

    if (this.Hide) { el.setAttribute("Hide", "1"); }
    if (this.OnceOnly) { el.setAttribute("OnceOnly", "1"); }
    if (this.BottomUp) { el.setAttribute("BottomUp", "1"); }
    if (this.BottomUpSubclasses) { el.setAttribute("BottomUpSubclasses", "1"); }
    if (this.Composeable) { el.setAttribute("Composeable", "1"); }
    if (this.Refineable) { el.setAttribute("Refineable", "1"); }
    if (this.RefinementOfClass) { el.setAttribute("RefinementOfClass", "1"); }
    if (this.HideInClassDiagram) { el.setAttribute("hideInClassDiagram", "1"); }

    if (this.InstDialog !== null && this.InstDialog !== undefined) {
      el.setAttribute("LocalInstDialog", "1");
      if (this.InstDialog.CheckGeneral) { el.setAttribute("CheckGeneral", "1"); }
      if (this.InstDialog.CheckNamespace) { el.setAttribute("CheckNamespace", "1"); }
      if (this.InstDialog.CheckClass) { el.setAttribute("CheckClass", "1"); }
      if (this.InstDialog.CheckMethods) { el.setAttribute("CheckMethods", "1"); }
      if (this.InstDialog.CheckAttributes) { el.setAttribute("CheckAttributes", "1"); }
      if (this.InstDialog.CheckAssociations) { el.setAttribute("CheckAssociations", "1"); }
      if (this.InstDialog.CheckSynonyms) { el.setAttribute("CheckSynonyms", "1"); }
      if (this.InstDialog.CheckCondition) { el.setAttribute("CheckCondition", "1"); }
      if (this.InstDialog.CheckStates) { el.setAttribute("CheckStates", "1"); }
      if (this.InstDialog.CheckAudit) { el.setAttribute("CheckAudit", "1"); }
      if (this.InstDialog.CheckAttachments) { el.setAttribute("CheckAttachments", "1"); }
    }
    if (this.ClassDialog !== null && this.ClassDialog !== undefined) {
      el.setAttribute("LocalClassDialog", "1");
      if (this.ClassDialog.CheckGeneral) { el.setAttribute("CheckGeneralClass", "1"); }
      if (this.ClassDialog.CheckNamespace) { el.setAttribute("CheckNamespaceClass", "1"); }
      if (this.ClassDialog.CheckMethods) { el.setAttribute("CheckMethodsClass", "1"); }
      if (this.ClassDialog.CheckAttributes) { el.setAttribute("CheckAttributesClass", "1"); }
      if (this.ClassDialog.CheckAssociations) { el.setAttribute("CheckAssociationsClass", "1"); }
      if (this.ClassDialog.CheckSynonyms) { el.setAttribute("CheckSynonymsClass", "1"); }
      if (this.ClassDialog.CheckStates) { el.setAttribute("CheckStatesClass", "1"); }
      if (this.ClassDialog.CheckAudit) { el.setAttribute("CheckAuditClass", "1"); }
      if (this.ClassDialog.CheckSharePoint) { el.setAttribute("CheckSharePoint", "1"); }
      if (this.ClassDialog.CheckRefinement) { el.setAttribute("CheckRefinement", "1"); }
      if (this.ClassDialog.CheckDSN) { el.setAttribute("CheckDSN", "1"); }
    }
    this._labels.SaveXML(xd, el);
    this._tabspecs.SaveXML(xd, el);
  }

  public CollectClassLabels(lbl: ILabelSpec, obj: ISemTalkClass, mastername?: string): boolean {
    return this._labels.CollectClassLabels(lbl, obj, mastername);
  }
  public CollectBusinessClassLabels(lbl: ILabelSpec, obj: ISemTalkClass, mastername?: string): boolean {
    return this._labels.CollectBusinessClassLabels(lbl, obj, mastername);
  }
  public CollectInstLabels(lbl: ILabelSpec, obj: ISemTalkInstance, mastername?: string): boolean {
    return this._labels.CollectInstLabels(lbl, obj, mastername);
  }
  public GetPrefix(): string {
    if (this.Prefix !== null) { return this.Prefix; }
    return this.ObjectName.substr(0, 2);
  }
}
export class SemTalkAssociationType extends SemTalkSystemClass implements ISemTalkAssociationType {
  constructor(tb: IObjectBase, ptype: SemTalkRelation, newname: string, id?: SemTalkID | null) {
    super(tb, newname, id);
    this.ObjectType = SemTalkType.SemTalkAssociationType;
    this.RelationType = ptype;
    if (tb.FindAssociationType(newname) === null) {
      tb._assoctypes[this.ID + "_"] = this;
      this.ObjectBase.OnCreated(this);
    }
  }
  public RelationType = SemTalkRelation.SemTalkProperty;
  public FromType = SemTalkType.SemTalkInstance;
  public ToType = SemTalkType.SemTalkInstance;
  public FromConnection = SemTalkConnectionPoint.South;
  public ToConnection = SemTalkConnectionPoint.North;
  private _invname: string | null = null;
  get InvName(): string {
    if (this._invname === null) {
      return "Inv " + this._name;
    } else { return this._invname; }
  }
  set InvName(val: string) {
    this._invname = val;
  }


  public Cyclic: boolean = true;
  public Symmetric: boolean = false;
  public Transitiv: boolean = false;
  public IsAggregation: boolean = false;
  public Unique: boolean = false;
  public IsContainer: boolean = false;
  public UniqueModel: boolean = false;
  public Expandable: boolean = true;
  public IsCompositionAssociation: boolean = false;
  public AllowFreeForm: boolean = true;

  public Delete(): void {
    super.Delete();
    delete this.ObjectBase._assoctypes[this.ID + "_"];
    this.ObjectBase.OnAssociationTypeDeleted(this);
  }
  public Load(je: any): void {
    super.Load(je);
    this.InvName = je.invname;
    this.Cyclic = (je.cyclic === "1");
    if (je.fromtype === "Class") { this.FromType = SemTalkType.SemTalkClass; }
    if (je.totype === "Class") { this.ToType = SemTalkType.SemTalkClass; }
    if (je.fromconnection) { this.FromConnection = Number(je.fromconnection); }
    if (je.toconnection) { this.ToConnection = Number(je.toconnection); }
    this.Symmetric = (je.symmetric === "1");
    this.Transitiv = (je.transitiv === "1");
    this.IsAggregation = (je.aggregate === "1");
    this.Unique = (je.unique === "1");
    this.IsContainer = (je.iscontainer === "1");
    this.UniqueModel = (je.uniquemodel === "1");
    this.IsCompositionAssociation = (je.composition === "1");
    this.Expandable = (je.expandable !== "0");
    this.AllowFreeForm = (je.allowfreeform !== "0");
    this.RelationType = ObjectBase.FindSemTalkRelationType(je.type);
  }
  public LoadXML(element: Element): void {
    super.LoadXML(element);
    if (element.attributes) {
      // const tb = this.ObjectBase;
      let invname = element.attributes.getNamedItem("invname")?.value;
      if (invname !== undefined) { this.InvName = invname; }
      let cyclic = element.attributes.getNamedItem("cyclic")?.value;
      if (cyclic !== undefined) { this.Cyclic = (cyclic === "1"); }
      let fromtype = element.attributes.getNamedItem("fromtype")?.value;
      if (invname !== undefined && fromtype === "Class") { this.FromType = SemTalkType.SemTalkClass; }
      let totype = element.attributes.getNamedItem("totype")?.value;
      if (totype !== undefined && totype === "Class") { this.ToType = SemTalkType.SemTalkClass; }
      let fromconnection = element.attributes.getNamedItem("fromconnection")?.value;
      if (fromconnection !== undefined) { this.FromConnection = Number(fromconnection); }
      let toconnection = element.attributes.getNamedItem("toconnection")?.value;
      if (toconnection !== undefined) { this.ToConnection = Number(toconnection); }
      let symmetric = element.attributes.getNamedItem("symmetric")?.value;
      if (symmetric !== undefined) { this.Symmetric = (symmetric === "1"); }
      let transitiv = element.attributes.getNamedItem("transitiv")?.value;
      if (transitiv !== undefined) { this.Transitiv = (transitiv === "1"); }
      let aggregate = element.attributes.getNamedItem("aggregate")?.value;
      if (aggregate !== undefined) { this.IsAggregation = (aggregate === "1"); }
      let unique = element.attributes.getNamedItem("unique")?.value;
      if (unique !== undefined) { this.Unique = (unique === "1"); }
      let iscontainer = element.attributes.getNamedItem("iscontainer")?.value;
      if (iscontainer !== undefined) { this.IsContainer = (iscontainer === "1"); }
      let uniquemodel = element.attributes.getNamedItem("uniquemodel")?.value;
      if (uniquemodel !== undefined) { this.UniqueModel = (uniquemodel === "1"); }
      let composition = element.attributes.getNamedItem("composition")?.value;
      if (composition !== undefined) { this.IsCompositionAssociation = (composition === "1"); }
      let expandable = element.attributes.getNamedItem("expandable")?.value;
      if (expandable !== undefined) { this.Expandable = (expandable === "1"); }
      let allowfreeform = element.attributes.getNamedItem("allowfreeform")?.value;
      if (allowfreeform !== undefined) { this.AllowFreeForm = (allowfreeform === "1"); }
      let rtype = element.attributes.getNamedItem("type")?.value;
      if (rtype !== undefined) { this.RelationType = ObjectBase.FindSemTalkRelationType(rtype); }
    }
  }

  public Save(par: any[]): any {
    const el = super.Save(par);
    el.ObjectType = "RelationType";
    el.invname = this.InvName;
    if (!this.Cyclic) { el.cyclic = "0"; }
    if (this.FromType === SemTalkType.SemTalkClass) { el.fromtype = "Class"; }
    if (this.ToType === SemTalkType.SemTalkClass) { el.totype = "Class"; }
    el.fromconnection = this.FromConnection;
    el.toconnection = this.ToConnection;
    if (this.Symmetric) { el.symmetric = "1"; }
    if (this.Transitiv) { el.transitiv = "1"; }
    if (this.IsAggregation) { el.aggregate = "1"; }
    if (this.Unique) { el.unique = "1"; }
    if (this.IsContainer) { el.iscontainer = "1"; }
    if (this.UniqueModel) { el.uniquemodel = "1"; }
    if (this.IsCompositionAssociation) { el.composition = "1"; }
    if (!this.Expandable) { el.expandable = "0"; }
    if (!this.AllowFreeForm) { el.allowfreeform = "0"; }
    el.type = ObjectBase.SemTalkRelationTypeName(this.RelationType);
    return el;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    super.SaveXML(xd, el);
    el.setAttribute("invname", this.InvName);
    if (!this.Cyclic) { el.setAttribute("cyclic", "0"); }
    if (this.FromType === SemTalkType.SemTalkClass) { el.setAttribute("fromtype", "Class"); }
    if (this.ToType === SemTalkType.SemTalkClass) { el.setAttribute("totype", "Class"); }
    el.setAttribute("fromconnection", String(this.FromConnection));
    el.setAttribute("toconnection", String(this.ToConnection));
    if (this.Symmetric) { el.setAttribute("symmetric", "1"); }
    if (this.Transitiv) { el.setAttribute("transitiv", "1"); }
    if (this.IsAggregation) { el.setAttribute("aggregate", "1"); }
    if (this.Unique) { el.setAttribute("unique", "1"); }
    if (this.IsContainer) { el.setAttribute("iscontainer", "1"); }
    if (this.UniqueModel) { el.setAttribute("uniquemodel", "1"); }
    if (this.IsCompositionAssociation) { el.setAttribute("composition", "1"); }
    if (!this.Expandable) { el.setAttribute("expandable", "0"); }
    if (!this.AllowFreeForm) { el.setAttribute("allowfreeform", "0"); }
    el.setAttribute("type", ObjectBase.SemTalkRelationTypeName(this.RelationType));
  }
}

