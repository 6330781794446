import { SemTalkMaster } from "../SemTalkMaster";
import { SemTalkShapeType, SemTalkStencil } from "./visiordfsinterface";
export const GENERIC_STENCIL: SemTalkStencil = [
  {
    name: SemTalkMaster.MasterClass,
    key: SemTalkMaster.MasterClass,
    logo: '/images/symbols/class.svg',
    width: 160,
    height: 60,
    type: SemTalkShapeType.generic,
    label: "STRDLHTMLOUTCLA",
    hidden: false
  },
  {
    name: SemTalkMaster.MasterSystemClass,
    key: SemTalkMaster.MasterSystemClass,
    logo: '/images/symbols/class.svg',
    width: 160,
    height: 60,
    type: SemTalkShapeType.generic,
    label: "Metamodel",
    // label: "STRSYSCLASS",
    hidden: false,
    role: "metamodel"
  },
  {
    name: SemTalkMaster.MasterInstance,
    key: SemTalkMaster.MasterInstance,
    hidden: false,
    logo: '/images/symbols/instance.svg',
    width: 160,
    height: 60,
    type: SemTalkShapeType.generic,
    label: "STRDLHTMLOUTINS"
  },
  {
    name: SemTalkMaster.MasterComment,
    key: SemTalkMaster.MasterComment,
    logo: '/images/symbols/comment.svg',
    width: 60,
    height: 60,
    type: SemTalkShapeType.generic,
    label: "STRCOMMENT",
    hidden: false
  },
  {
    name: 'Text',
    key: 'Text',
    logo: '/images/symbols/textbox.svg',
    width: 60,
    height: 60,
    type: SemTalkShapeType.general,
    hidden: false,
    label: "Text"
  },
  {
    name: 'Image',
    key: 'Image',
    logo: '/images/symbols/image.svg',
    width: 60,
    height: 60,
    type: SemTalkShapeType.general,
    hidden: false,
    label: "Image"
  },
  {
    name: 'Attachment',
    key: 'Attachment',
    logo: '/images/symbols/attachment.svg',
    width: 40,
    height: 40,
    type: SemTalkShapeType.general,
    hidden: false,
    label: "Attachment"
  },
  {
    name: 'Connector',
    key: 'Connector',
    hidden: true,
    logo: '/images/symbols/arrow.gif',
    width: 80,
    height: 60,
    type: SemTalkShapeType.general,
    label: "Connector"
  },
  {
    name: SemTalkMaster.MasterSubClassOf,
    key: SemTalkMaster.MasterSubClassOf,
    hidden: true,
    logo: '/images/symbols/arrow.gif',
    width: 80,
    height: 60,
    type: SemTalkShapeType.general,
    label: "STRDLHTMLOUTSUP"
  },
  {
    name: SemTalkMaster.MasterInstanceOf,
    key: SemTalkMaster.MasterInstanceOf,
    hidden: true,
    logo: '/images/symbols/arrow.gif',
    width: 80,
    height: 60,
    type: SemTalkShapeType.general,
    label: "STRDLGBROWSEINS"
  }, {
    name: SemTalkMaster.MasterProperty,
    key: SemTalkMaster.MasterProperty,
    hidden: true,
    logo: '/images/symbols/connect.gif',
    width: 80,
    height: 60,
    type: SemTalkShapeType.general,
    label: "Property"
  }, {
    name: SemTalkMaster.MasterAttributeType,
    key: SemTalkMaster.MasterAttributeType,
    logo: '/images/symbols/attribute.svg',
    width: 160,
    height: 60,
    type: SemTalkShapeType.generic,
    label: "Attribute",
    hidden: false,
    role: "metamodel"
  }, {
    name: SemTalkMaster.MasterAssociationType,
    key: SemTalkMaster.MasterAssociationType,
    logo: '/images/symbols/association.svg',
    width: 160,
    height: 60,
    type: SemTalkShapeType.generic,
    label: "Association",
    hidden: false,
    role: "metamodel"
  }, {
    name: SemTalkMaster.MasterDiagramType,
    key: SemTalkMaster.MasterDiagramType,
    logo: '/images/symbols/diagram.svg',
    width: 160,
    height: 60,
    type: SemTalkShapeType.generic,
    label: "Diagram",
    hidden: false,
    role: "metamodel"
  },
];