import {
  IObjectBase, SemTalkBaseConstant,
  SemTalkRelation, SemTalkType,
  ISemTalkClass,
  ISemTalkDiagramType,
  SemTalkID
} from "./Interface";
import { ObjectBase } from "./ObjectBase";
// import * as parser from 'fast-xml-parser';
// import { ObjectBase } from "./ObjectBase";


export class OB2XML {
  private isSystemClass = (c: string): boolean => {
    switch (c) {
      case "XOR": return true;
      case "OR": return true;
      case "AND": return true;
      case "epc#Class": return true;
    }
    return false;
  }
  public LoadXML(tb: IObjectBase, xmlstr: string): void {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(xmlstr, "text/xml");
    let obx = xmlDoc.getElementsByTagName("ObjectBase")[0];
    if (obx.attributes) {
      for (const i in obx.attributes) {
        let c = obx.attributes[i];
        switch (c.name) {
          case "MaxID":
            tb.maxid = Number(c.value);
            break;
          default:
            tb.SetModelAttribute(c.name, c.value);
        }
      }
    }

    let classes = obx.getElementsByTagName("Class");
    for (const i in classes) {
      let c = classes[i];
      if (c.attributes) {
        let cid: SemTalkID;
        const cidv = c.attributes.getNamedItem("ID")?.value;
        if (cidv) {
          cid = ObjectBase.String2SemTalkID(cidv);
        } else {
          cid = tb.NewID();
        }
        let name = c.attributes.getNamedItem("name")?.value;
        let systemClass = c.attributes.getNamedItem("SystemClass")?.value;
        let businessClass = c.attributes.getNamedItem("BusinessClass")?.value;
        let readOnly = c.attributes.getNamedItem("ReadOnly")?.value;
        if (readOnly && (readOnly === "1" || readOnly === "True")) {
          systemClass = "True";
        }
        if (name) {
          if (name.startsWith("Ob#") || name.startsWith("In#")) {
            businessClass = "True";
          }
        }
        if (name) {
          if (systemClass === "True" || this.isSystemClass(name)) {
            tb.MakeSystemClass(name, cid);
          } else {
            if (businessClass && businessClass === "True") {
              tb.MakeBusinessClass(name, cid);
            } else {
              tb.MakeClass(name, cid);
            }
          }
        }
      }
    }
    let attrtypes = obx.getElementsByTagName("AttributeType");
    for (const i in attrtypes) {
      let c = attrtypes[i];
      if (c.attributes) {
        let cid: SemTalkID;
        const cidv = c.attributes.getNamedItem("ID")?.value;
        if (cidv) {
          cid = ObjectBase.String2SemTalkID(cidv);
        } else {
          cid = tb.NewID();
        }
        let name = c.attributes.getNamedItem("name")?.value;
        if (name && cid) {
          tb.MakeAttributeType(name, cid);
        }
      }
    }
    let methodtypes = obx.getElementsByTagName("MethodType");
    for (const i in methodtypes) {
      let c = methodtypes[i];
      if (c.attributes) {
        let cid: SemTalkID;
        const cidv = c.attributes.getNamedItem("ID")?.value;
        if (cidv) {
          cid = ObjectBase.String2SemTalkID(cidv);
        } else {
          cid = tb.NewID();
        }
        let name = c.attributes.getNamedItem("name")?.value;
        if (name && cid) {
          tb.MakeMethodType(name, cid);
        }
      }
    }
    let statetypes = obx.getElementsByTagName("StateType");
    for (const i in statetypes) {
      let c = statetypes[i];
      if (c.attributes) {
        let cid: SemTalkID;
        const cidv = c.attributes.getNamedItem("ID")?.value;
        if (cidv) {
          cid = ObjectBase.String2SemTalkID(cidv);
        } else {
          cid = tb.NewID();
        }
        let name = c.attributes.getNamedItem("name")?.value;
        if (name && cid) {
          tb.MakeStateType(name, cid);
        }
      }
    }
    let assoctypes = obx.getElementsByTagName("RelationType");
    for (const i in assoctypes) {
      let c = assoctypes[i];
      if (c.attributes) {
        let cid: SemTalkID;
        const cidv = c.attributes.getNamedItem("ID")?.value;
        if (cidv) {
          cid = ObjectBase.String2SemTalkID(cidv);
        } else {
          cid = tb.NewID();
        }
        let name = c.attributes.getNamedItem("name")?.value;
        if (name && cid) {
          tb.MakeAssociationType(SemTalkRelation.SemTalkProperty, name, cid);
        }
      }
    }
    let sassoctypes = obx.getElementsByTagName("SystemRelation");
    for (const i in sassoctypes) {
      let c = sassoctypes[i];
      if (c.attributes) {
        let cid: SemTalkID;
        const cidv = c.attributes.getNamedItem("ID")?.value;
        if (cidv) {
          cid = ObjectBase.String2SemTalkID(cidv);
        } else {
          cid = tb.NewID();
        }
        let name = c.attributes.getNamedItem("name")?.value;
        if (name && cid) {
          switch (name) {
            case SemTalkBaseConstant.SLSubClassOf: {
              tb.MakeAssociationType(SemTalkRelation.SemTalkSubClassOf, SemTalkBaseConstant.SLSubClassOf, cid);
              break;
            }
            case SemTalkBaseConstant.SLInstanceOf: {
              tb.MakeAssociationType(SemTalkRelation.SemTalkInstanceOf, SemTalkBaseConstant.SLInstanceOf, cid);
              break;
            }
            default:
              tb.MakeAssociationType(SemTalkRelation.SemTalkSystemRelation, name, cid);
          }

        }
      }
    }

    let diagtypes = obx.getElementsByTagName("DiagramType");
    for (const i in diagtypes) {
      let c = diagtypes[i];
      if (c.attributes) {
        let cid: SemTalkID;
        const cidv = c.attributes.getNamedItem("ID")?.value;
        if (cidv) {
          cid = ObjectBase.String2SemTalkID(cidv);
        } else {
          cid = tb.NewID();
        }
        let name = c.attributes.getNamedItem("name")?.value;
        if (name && cid) {
          tb.MakeDiagramType(name, cid);
        }
      }
    }
    for (const i in diagtypes) {
      let c = diagtypes[i];
      if (c.attributes) {
        let name = c.attributes.getNamedItem("name")?.value;
        if (name) {
          const at = tb.FindDiagramType(name);
          if (at !== null) {
            at.LoadXML(c);
          }
        }
      }
    }
    let diagrams = obx.getElementsByTagName("Diagram");
    for (const i in diagrams) {
      let c = diagrams[i];
      if (c.attributes) {
        let cid: SemTalkID = ObjectBase.String2SemTalkID("");
        let a = c.attributes.getNamedItem("id");
        if (a) cid = ObjectBase.String2SemTalkID(a.value);
        if (!cid) {
          cid = tb.NewID();
        }
        let name = c.attributes.getNamedItem("name")?.value;
        let iclass = c.attributes.getNamedItem("class")?.value;
        if (name && cid && iclass) {
          const cl = tb.FindDiagramType(iclass);
          if (cl) {
            tb.MakeDiagram((cl as ISemTalkDiagramType), name, SemTalkType.SemTalkDiagram, cid);
          }
        }
      }
    }
    for (const i in attrtypes) {
      let c = attrtypes[i];
      if (c.attributes) {
        let name = c.attributes.getNamedItem("name")?.value;
        if (name) {
          const at = tb.FindAttributeType(name);
          if (at !== null) {
            at.LoadXML(c);
          }
        }
      }
    }
    for (const i in methodtypes) {
      let c = methodtypes[i];
      if (c.attributes) {
        let name = c.attributes.getNamedItem("name")?.value;
        if (name) {
          const at = tb.FindMethodType(name);
          if (at !== null) {
            at.LoadXML(c);
          }
        }
      }
    }
    for (const i in statetypes) {
      let c = statetypes[i];
      if (c.attributes) {
        let name = c.attributes.getNamedItem("name")?.value;
        if (name) {
          const at = tb.FindStateType(name);
          if (at !== null) {
            at.LoadXML(c);
          }
        }
      }
    }
    for (const i in assoctypes) {
      let c = assoctypes[i];
      if (c.attributes) {
        let name = c.attributes.getNamedItem("name")?.value;
        if (name) {
          const at = tb.FindAssociationType(name);
          if (at !== null) {
            at.LoadXML(c);
          }
        }
      }
    }
    for (const i in classes) {
      let c = classes[i];
      if (c.attributes) {
        let name = c.attributes.getNamedItem("name")?.value;
        if (name) {
          const at = tb.FindClass(name);
          if (at !== null) {
            at.LoadXML(c);
          }
        }
      }
    }
    let instances = obx.getElementsByTagName("Instance");
    for (const i in instances) {
      let c = instances[i];
      if (c.attributes) {
        let cid: SemTalkID;
        const cidv = c.attributes.getNamedItem("ID")?.value;
        if (cidv) {
          cid = ObjectBase.String2SemTalkID(cidv);
        } else {
          cid = tb.NewID();
        }
        let name = c.attributes.getNamedItem("name")?.value;
        let iclass = c.attributes.getNamedItem("class")?.value;
        if (name && cid && iclass) {
          const cl = tb.FindClass(iclass);
          console.assert(cl !== null);
          if (cl) {
            let cs = tb.MakeInstance((cl as ISemTalkClass), name, SemTalkType.SemTalkInstance, cid);
            console.assert(cs !== null);
          } else {
            console.log("Missing Class: " + iclass);
          }
        }
      }
    }
    for (const i in instances) {
      let c = instances[i];
      if (c.attributes) {
        let name = c.attributes.getNamedItem("name")?.value;
        if (name) {
          const at = tb.FindInstance(name);
          if (at !== null) {
            at.LoadXML(c);
          }
        }
      }
    }
  }

  public SaveXML(tb: IObjectBase, encode?: any, decode?: any, sortDiags?: boolean): string {
    tb.SetID2NumberConverter(true);
    let xd: XMLDocument = document.implementation.createDocument("semtalk", "", null);
    var doc = xd.createElement("ObjectBase");

    for (const name of tb.AllModelAttributes()) {
      if (!name.startsWith(SemTalkBaseConstant.SLMXGPagePrefix) &&
        !name.startsWith(SemTalkBaseConstant.SLSVGPagePrefix)) {
        try {
          doc.setAttribute(name, tb.GetModelAttribute(name));
        } catch (_e) {

        }
      }
    }

    for (const obj of tb.AllSystemClasses()) {
      // if (obj.ObjectType === SemTalkType.SemTalkClass) {
      if (tb.IsSystemClass(obj) && obj.ObjectType === SemTalkType.SemTalkClass) {
        let el = xd.createElement("Class");
        doc.appendChild(el);
        obj.SaveXML(xd, el);
      }
    }
    for (const obj of tb.AllClasses()) {
      if (!tb.IsSystemClass(obj) && obj.ObjectType === SemTalkType.SemTalkClass) {
        // if (tb.IsClass(obj)) {
        let el = xd.createElement("Class");
        doc.appendChild(el);
        obj.SaveXML(xd, el);
      }
    }
    for (const obj of tb.AllAssociationTypes()) {
      if (tb.IsAssociationType(obj)) {
        let el: any;
        if (obj.RelationType !== SemTalkRelation.SemTalkProperty) {
          el = xd.createElement("SystemRelation");
        } else {
          el = xd.createElement("RelationType");
        }
        doc.appendChild(el);
        obj.SaveXML(xd, el);
      }
    }
    for (const obj of tb.AllAttributeTypes()) {
      if (tb.IsAttributeType(obj)) {
        let el = xd.createElement("AttributeType");
        doc.appendChild(el);
        obj.SaveXML(xd, el);
      }
    }
    for (const obj of tb.AllDiagramTypes()) {
      if (tb.IsDiagramType(obj)) {
        let el = xd.createElement("DiagramType");
        doc.appendChild(el);
        obj.SaveXML(xd, el);
      }
    }
    for (const obj of tb.AllInstances()) {
      if (obj.ObjectType === SemTalkType.SemTalkInstance) {
        let el = xd.createElement("Instance");
        doc.appendChild(el);
        obj.SaveXML(xd, el);
      }
    }
    let pages = tb.AllDiagrams();
    if (sortDiags) {
      let p0 = pages[0];
      if (p0.GetValue(SemTalkBaseConstant.SLUserNumber) !== "") {
        pages = pages.sort((x, y) => {
          let un1 = x.GetValue(SemTalkBaseConstant.SLUserNumber);
          let un2 = y.GetValue(SemTalkBaseConstant.SLUserNumber);
          if (un1 && un2) {
            return un1.localeCompare(un2);
          } else {
            return x.ObjectCaption.localeCompare(y.ObjectCaption);
          }
        });
      }
    }

    for (const obj of pages) {
      let el = xd.createElement("Diagram");
      doc.appendChild(el);
      obj.SaveXML(xd, el);
    }
    for (const obj of tb.AllMethodTypes()) {
      if (tb.IsAttributeType(obj)) {
        let el = xd.createElement("MethodType");
        doc.appendChild(el);
        obj.SaveXML(xd, el);
      }
    }
    for (const obj of tb.AllStateTypes()) {
      if (tb.IsAttributeType(obj)) {
        let el = xd.createElement("StateType");
        doc.appendChild(el);
        obj.SaveXML(xd, el);
      }
    }
    let conv = tb.SetID2NumberConverter(false);
    if (conv) {
      doc.setAttribute("MaxID", conv.maxid.toString());
      if (encode && decode) {
        for (let sc of tb.AllDiagrams()) {
          if (sc) {
            let encodedmxg: any = null;
            let decodedmxg: any = null;
            let mxg = sc.GetValue(SemTalkBaseConstant.SLMXGAttribute);
            if (mxg) {
              encodedmxg = tb.GetModelAttribute(mxg);
              decodedmxg = decode(encodedmxg);
            }
            let svg = sc.GetValue(SemTalkBaseConstant.SLSVGAttribute);
            let encodedsvg: any = null;
            let decodedsvg: any = null;
            if (svg) {
              encodedsvg = tb.GetModelAttribute(svg);
              decodedsvg = decode(encodedsvg);
            }
            let newpageid = sc.ID;
            for (let [oid, newobj] of Array.from(conv.id2number)) {
              // console.debug(decoded);
              if (oid === sc.ID) {
                newpageid = newobj;
              }
              let oids = 'objectid="' + oid.replace("_", "") + '"';
              if (mxg && decodedmxg.indexOf(oids) > 0) {
                let oidn = 'objectid="' + newobj + '"';
                decodedmxg = decodedmxg.replace(oids, oidn);
              }
              let dids = 'diagid="' + oid + '"';
              if (mxg && decodedmxg.indexOf(dids) > 0) {
                let didn = 'diagid="' + newobj + '"';
                decodedmxg = decodedmxg.replace(dids, didn);
              }
              if (svg && decodedsvg.indexOf(oids) > 0) {
                let oidn = 'objectid="' + newobj + '"';
                decodedsvg = decodedsvg.replace(oids, oidn);
              }
            }
            if (mxg) {
              encodedmxg = encode(decodedmxg);
              doc.setAttribute(SemTalkBaseConstant.SLMXGPagePrefix + newpageid, encodedmxg);
            }
            if (svg) {
              encodedsvg = encode(decodedsvg);
              doc.setAttribute(SemTalkBaseConstant.SLSVGPagePrefix + newpageid, encodedsvg);
              doc.setAttribute(SemTalkBaseConstant.SLSVGPagePrefix + SemTalkBaseConstant.SLSVGPagePostfix + newpageid, sc.ObjectName);
            }
          }
        }
      }
    }

    // let blob: string = '<?xml version="1.0" encoding="UTF-8"?>';
    let blob: string = '';
    blob = blob + doc.outerHTML;
    blob = blob.replace(new RegExp("http://www.semtalk.com/mxg#", 'g'), "");
    // let tb2 = new ObjectBase();
    // this.LoadXML(tb2, blob);
    return blob;
  }
}
