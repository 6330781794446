import React from "react";
// import {
//   createTheme,
//   Customizations
// } from 'office-ui-fabric-react';
import "./App.css";

import {
  SemTalkLanguage,
  SemTalkLanguageCode,
} from "./components/application/tbase/Interface";
import {
  GetLanguageCode,
  GetLanguage,
} from "./components/application/tbase/langtools";
import Login from "./components/controls/stlogin/Login";
import { IMongoOption } from "./components/SemTalkMongoServer";
import { SemTalkOnline } from "./components/SemTalkOnline";
import { SemTalkRole } from "./components/ISemTalkOnline";
import { SemTalkCookie } from "./components/ISemTalkCookie";
import {
  accessCookie,
  setCookie,
} from "./components/application/semtalklistener/stgoto";
import { removeCookie } from "./components/application/semtalklistener/stgoto";
import SemTalkWelcome from "./components/controls/stwelcome/SemTalkWelcome";
// import {
//   mgGetItems
// } from './components/SemTalkMongoServer';

export interface IAppProps {
  setToken: any;
  getToken: any;
  mongo: IMongoOption;
  // semmongoserverurl: string;
  semmongologintokencookiename: string;
  // semmongoconnectiontoken: string;
  // pca: IPublicClientApplication;
  role: SemTalkRole | string;
}
export interface IAppState {
  currLoginToken: any;
  message: string;
  // valid: boolean;
  checkautoLogin: boolean;
}

export default class App extends React.Component<IAppProps, IAppState> {
  private valid: boolean = false;
  constructor(props: IAppProps) {
    super(props);
    document.title = `SemTalk Online`;
    this.state = {
      currLoginToken: null,
      // valid: false,
      message: "",
      checkautoLogin: true
    };
  }
  public setcurrToken = (token: any) => {
    this.setState({ currLoginToken: token });
  };
  public async componentDidMount() {
    let bg = accessCookie(SemTalkCookie.backgroundColor);
    if (bg !== "undefined" || bg !== null) {
      document.body.style.backgroundColor = bg;
    }

    // window.addEventListener("beforeunload", (ev) => { ev.preventDefault(); this.onTabClose(ev); });
  }
  // private onTabClose = (ev: any) => {
  //   ev.preventDefault();
  //   removeCookie(this.props.semmongologintokencookiename);
  // }
  public static getDerivedStateFromError(_error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
  }

  //login without password if shared for viewer
  private checkautoLogin = async (shareMail: string, endDate: string | undefined) => {
    // let sUsers = await mgGetItems(this.props.mongo, this.props.mongo.dbname, "shareUsers");
    // let authUsers = sUsers.map(x => {
    //   return x.name;
    // });
    let date: Date = new Date();
    if (this.props.role === SemTalkRole.viewer && (shareMail.length > 5 && shareMail.toLowerCase().toString().indexOf("@") > 0)) {
      // if (shareMail.length > 5 && authUsers.indexOf(shareMail) > -1) {
      if (shareMail.length > 5) {
        console.log("login without account");
        if (endDate !== undefined) {
          let eDate = new Date(endDate);
          if (date < eDate) {
            this.valid = true;
            this.setState({ checkautoLogin: false });
          } else {
            this.valid = false;
            this.setState({ checkautoLogin: false, message: "The validity duration of your Link is expired!" });
          }
        } else {
          this.valid = false;
          this.setState({ checkautoLogin: false, message: "The validity duration of your Link is expired!" });
        }
      } else {
        this.valid = false;
        this.setState({ checkautoLogin: false, message: "You are not authorized to automatically login via this link. Please use your account." });
      }
    }
  }

  public render(): React.ReactElement {
    let token = this.state.currLoginToken;
    if (!token) {
      token = this.props.getToken();
    }

    let lc0 = navigator.languages[1] || navigator.language;
    if (lc0.indexOf("-") > 0) {
      lc0 = lc0.substring(0, lc0.indexOf("-") - 1);
    }
    let blc = GetLanguageCode(lc0);
    let guilanguage: SemTalkLanguageCode = SemTalkLanguageCode.English;
    if (blc) {
      guilanguage = blc;
    }
    let language: SemTalkLanguage | undefined = SemTalkLanguage.English;
    if (guilanguage === SemTalkLanguageCode.German) {
      language = SemTalkLanguage.German;
    }
    // let cdn = "";
    let gl = GetLanguageCode(accessCookie(SemTalkCookie.guilanguage));
    if (gl) {
      guilanguage = gl;
    } else {
      setCookie(SemTalkCookie.guilanguage, guilanguage);
    }
    let l = GetLanguage(accessCookie(SemTalkCookie.language));
    if (l) {
      language = l;
    } else {
      setCookie(SemTalkCookie.language, language);
    }

    let semconnecttoken = accessCookie(SemTalkCookie.semconnecttoken);
    let mongo = this.props.mongo;
    if (!semconnecttoken) semconnecttoken = "";
    mongo.semmongoconnectiontoken = semconnecttoken;

    let semmongoserverurl = accessCookie(SemTalkCookie.semmongoserverurl);
    mongo.semmongoserverurl = semmongoserverurl;
    if (!semmongoserverurl) {
      semmongoserverurl = "/api/";
    }
    let iselectron: boolean = mongo.iselectron;
    let usemongo: boolean = mongo.usemongo;
    let dbname = mongo.dbname;
    let repository = mongo.repository;
    let modelname = "";
    let pagename = "";
    let shape = "";
    let endDate;
    let shareMail = "";
    let onlyModel = "";
    let template = "";
    let url = document.baseURI;
    let shared = false;
    // let url = "https://kind-flower-00727bb03.azurestaticapps.net/?guilanguage=en&language=English";
    if (url.indexOf("?") > 0) {
      let args = url.substr(url.indexOf("?") + 1);
      for (let param of args.split("&")) {
        let p = param.split("=")[0];
        let v = decodeURI(param.split("=")[1]);
        switch (p) {
          case "guilanguage": {
            let lc = GetLanguageCode(v);
            if (lc) guilanguage = lc;
            break;
          }
          case "language": {
            let lc = GetLanguage(v);
            if (lc) language = lc;
            break;
          }
          case "database": {
            dbname = v;
            break;
          }
          case "repository": {
            repository = v;
            break;
          }
          case "model": {
            modelname = v;
            let s2 = accessCookie(SemTalkCookie.SemTalkStatus);
            let sessionstatus: any;
            if (s2) {
              sessionstatus = JSON.parse(s2);
            }
            if (sessionstatus) {
              sessionstatus["filename"] = modelname;
              setCookie(SemTalkCookie.SemTalkStatus, JSON.stringify(sessionstatus));
              setCookie(SemTalkCookie.showwelcome, "false");
              removeCookie(SemTalkCookie.autosaveSemTalk);
              window.history.pushState({}, "", "/" + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split("?")[0]);
            }
            break;
          }
          case "page": {
            pagename = v;
            break;
          }
          // case "showcommandbar": {
          //   showmenubar = (v !== "false");
          //   break;
          // }
          case "iselectron": {
            iselectron = v !== "false";
            break;
          }
          case "usemongo": {
            usemongo = v !== "false";
            break;
          }
          case "template": {
            template = v;
            if (template === "cit_intelliForm_dialog.stx") {
              document.title = "cit Composer";
            }
            break;
          }
          case "share": {
            if (v.length > 0) {
              let b = Buffer.from(v, "base64").toString("utf8");
              let shareParams = b.split(",");
              // shareParams.forEach((s) => {
              for (let s of shareParams) {
                let shareParam = s.split(":");
                if (shareParam[0].length > 0 && shareParam[1].length > 0) {
                  if (shareParam[0] === "filename") {
                    modelname = shareParam[1];
                    shared = true;
                  }
                  if (shareParam[0] === "diagram") pagename = shareParam[1];
                  if (shareParam[0] === "shape") shape = shareParam[1];
                  if (shareParam[0] === "email") shareMail = shareParam[1];
                  if (shareParam[0] === "onlyModel") onlyModel = shareParam[1];
                  if (shareParam[0] === "enddate") endDate = shareParam[1].replace(/_/g, ":").replace(/-/g, ",");
                  if (shareParam[0] === "language") {
                    let lng = GetLanguage(shareParam[1]);
                    if (lng) language = lng;
                  }
                }
              }
              // if (this.props.role === SemTalkRole.editor) {
              //   window.history.replaceState(null, "", "/");
              // }
              // });
            }
          }
        }
      }
    }
    if (document.baseURI.indexOf("citcomposer") > -1) {
      template = "cit_intelliForm_dialog.stx";
      document.title = `cit Composer`;
    }
    mongo.usemongo = usemongo;
    mongo.dbname = dbname;
    mongo.repository = repository;
    mongo.iselectron = iselectron;

    let chatgpt = "";
    if (this.state.currLoginToken) {
      let user = this.state.currLoginToken.user_info.profile;
      if (user.chatgptkey) {
        chatgpt = user.chatgptkey;
      }
    }

    //when shared with link
    if (mongo.usemongo && this.state.checkautoLogin && shared && this.props.role === SemTalkRole.viewer) {
      this.checkautoLogin(shareMail, endDate);
    }

    if (!iselectron && mongo.usemongo && !this.valid) {
      if (!token) {
        mongo["semuserlogin"] = null;
        return (
          <Login
            setToken={this.props.setToken}
            setcurrToken={this.setcurrToken}
            mongo={mongo}
            role={this.props.role}
            message={this.state.message}
          />
        );
      } else {
        mongo["semuserlogin"] = token;
      }
    }
    if (token) {
      let user = token.user_info.profile;
      if (user.chatgptkey) {
        chatgpt = user.chatgptkey;
      }
    }
    if (shared) {
      if (this.props.role === SemTalkRole.editor) {
        window.history.replaceState(null, "", "/");
      }
    }
    let showWelcome = !shared && accessCookie(SemTalkCookie.showwelcome) !== "false";
    let filename = accessCookie(SemTalkCookie.filename);
    if (filename === "BPMN20.stx") {
      filename = "bpmn20-2016.stx";
    }
    // let showWelcome = false;
    const welcometoken = sessionStorage.getItem("semtalkonline_welcome");
    if (
      showWelcome &&
      !filename &&
      !welcometoken &&
      !iselectron &&
      modelname === ""
    ) {
      sessionStorage.setItem("semtalkonline_welcome", "shown");
      return <SemTalkWelcome
        role={this.props.role}
      />;
    }
    removeCookie(SemTalkCookie.filename);
    let loadtemplate = false;
    let loadrecent = false;
    if (filename) {
      if (filename !== "reload") {
        modelname = filename;
        if (filename.endsWith(".stx")) {
          loadtemplate = true;
        } else {
          loadrecent = true
        }
      }
      // if (filename.endsWith(".stx")) {
      //   template = filename;
      // }
    }

    let ro = accessCookie(SemTalkCookie.role);
    if (!ro || (shared && this.valid)) {
      ro = this.props.role;
    }

    let site = ".";
    let librarysite = ".";
    // servicesserver = "http://localhost:53707/api/semservice";
    // servicesserver = "https://semtalkservicewebapp4.azurewebsites.net/api/semservice";

    // servicesservertype = "mssql";
    // servicesconstr = "vDxHu4Ob5/XHL6qxj5PS2/8HtQWPR4Atj15zbkw9ns4tPyUnxnX1EXF242MMmYDMY5/Rg/59BHnLnE3Cnn7o8HFnGr+BuX1kMSKk+Zd1qhSVt5iTraF6Sw6hfwuNnMLp";
    // servicesconstr = "Server=PIRATB;Initial Catalog=SemTalkPortalDB;User Id=semtalk;Password=semtalk;Persist Security Info=False;MultipleActiveResultSets=False;Encrypt=False;TrustServerCertificate=True;Connection Timeout=30;"
    // servicesconstr = "Server=tcp:semtalkdbservereu.database.windows.net,1433;Initial Catalog=SemTalkPortalDB50;Persist Security Info=False;User ID=semtation@semtalkdbservereu;Password=SemTalk3!;MultipleActiveResultSets=False;Encrypt=True;TrustServerCertificate=False;Connection Timeout=30;"
    // servicesconstr = "j3Ex0PaYX9FPVebgHPYWrEkL/MB7sJBYZKYFJMBPgqbhftmY6CloPDZlHFIFQtavujVacjD2T4LYZSOqtS3ZGLK9/EBq9mZonU4NDbVH5imK3XCXXw3M+H/Q0vIQunmGJeetmKvUB/8pFlZnfQK/J56yfyB3sx0Qxl9y8mp1rjfTZcmWuPO1Pi53u2GhKzhezBY7bto7Z7IXntG9SE/V/mB/ts1kHsJLz+TBIA38wWiyLv4t2+hFgOP82BzFA+c8YQ8UayK4BLszR/mRROKo5KgkdlOlOu3hrJIY9aN8Yn8WGIYDyPYOnRprpFSUjnvEMOy7KqFzu1mi1yW6mBg+E8SJ4MkQ4VmJ9nPIPYqkAnI=";
    // servicesconstr = "Data Source=.\SQLEXPRESS;Initial Catalog=SemTalkPortalDB;Integrated Security=True;Pooling=False"

    // servicesservertype = "mysql";
    // servicesconstr = "server=127.0.0.1;port=3306;uid=semtation;pwd=semtalk;database=semtalkportaldb;SslMode=Preferred";
    // servicesconstr = "server=semtalkmysql.mysql.database.azure.com;port=3306;database=semtalkportaldb; Uid=semadmin@semtalkmysql; Pwd=SemTalk3!; SslMode=Preferred;";
    // servicesconstr = "avwIuTETxrRRpUp+nLWtT2auUEeAsh5EKret4Ot6oh4w3f4NwTfYcXkIHX2jZvEE0mTvo5Hx+ZfR6uWHEHk5N8FxNDAHYxiAqL75qNLmMYPVYYJf7zNoZnENm+QQBioFkJzy0J8I7cxzxCxcuik2Ce/ekiGL4vd0UJJgorcw8r4qHgSt7cUKWfDg3iulgqvo";

    // servicesservertype = "mongo";
    // servicesconstr = "mongodb://localhost:27017";
    // servicesconstr "TEqAPAmtSG1FJWGnOjQRQIsFAdMlVRWFGzU9eFOCAdk="
    // servicesconstr = "mongodb+srv://semtation:SemTalk3!@cluster2.kkbs7.mongodb.net";
    // servicesconstr = "KHYEsllxU1c8bUy6KbbNADhiYSoiHMmdE26ebqtPWkbxN9cnStlw7Q+2kFfZ1kDPihTYkZMXNH4+pHhGq4WjHLG5dCcICZ1N18yukheBiXg8kuDNIuUWYWiarDQmwGBz";

    // let services: ISemTalkServicesOptions = {
    //   "server": servicesserver,
    //   "site": servicessite,
    //   "database": servicesdatabase,
    //   "sqlconnection": servicesconstr,
    //   "servertype": servicesservertype,
    //   "breports": servicesbreports
    // };

    let restrictExtSharedMode = false;
    if (this.valid && onlyModel === "true" && ro === SemTalkRole.viewer) {
      restrictExtSharedMode = true;
    }

    return (
      <div className="App">
        {/* <MsalProvider instance={this.props.pca}> */}
        {/* <AuthenticatedTemplate> */}
        <SemTalkOnline
          site={site}
          librarysite={librarysite}
          model={modelname}
          loadtemplate={loadtemplate}
          loadrecent={loadrecent}
          page={pagename}
          shape={shape}
          guilanguage={guilanguage}
          language={language as SemTalkLanguage}
          mongo={mongo}
          setToken={this.props.setToken}
          setcurrToken={this.setcurrToken}
          role={ro}
          template={template}
          editdialogwidth={"400px"}
          isplanmaker={false}
          // usesimulationcoloring={usesimulationcoloring}
          shared={shared}
          restrictExtSharedMode={restrictExtSharedMode}
          chatgpt={chatgpt}
        ></SemTalkOnline>
        {/* </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <div>Please sign-in to see your profile information.</div>
          </UnauthenticatedTemplate> */}
        {/* </MsalProvider> */}
      </div>
    );
  }
}
