
/* tslint:disable:noImplicitAny */
/* eslint:disable:noImplicitAny */
import {
  mxEvent,
  mxCell,
  mxUtils,
  mxImageExport,
  mxRectangle,
  mxCellEditor,
  mxSvgCanvas2D

} from "mxgraph-js";
import { SemTalkStencil } from "../application/semtalklistener/visiordfsinterface";
import { SemTalkMaster } from "../application/SemTalkMaster";
import { ISemTalkOnline } from "../ISemTalkOnline";
import { absolutePosition } from "../utils";
// import { copyColorStyle } from "../styles";

var html4: any;
if (typeof html4 !== 'undefined') {
  html4.ATTRIBS['a::target'] = 0;
  html4.ATTRIBS['source::src'] = 0;
  html4.ATTRIBS['video::src'] = 0;
  // Would be nice for tooltips but probably a security risk...
  //html4.ATTRIBS['video::autoplay'] = 0;
  //html4.ATTRIBS['video::autobuffer'] = 0;
}
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */

// let keydownhandler: any[] = [];
export default {
  initPopupMenu(graph: any, menuFunc: any) {
    // Installs a popupmenu handler using local function (see below).
    graph.popupMenuHandler.factoryMethod = (menu: any, cell: any, evt: any) =>
      createPopupMenu(graph, menu, cell, evt); //eslint-disable-line

    // Function to create the entries in the popupmenu
    function createPopupMenu(graph1: any, menu: any, cell: any, evt: any) { //eslint-disable-line
      menuFunc && menuFunc(graph1, menu, cell, evt);
    }
  },

  handleHover(graph: mxGraph, callback: ISemTalkOnline) {
    function mxIconSet(this: any, state: any, quickshapes: SemTalkStencil, vertical: any): any {
      this.images = [];
      let dx = 0.2;
      let dy = 0;
      let cnt = 0;
      let self = this;
      for (let m of quickshapes) {
        let tit = m.name;
        if (m.label) {
          tit = m.label;
        }
        let mst = m.key;
        // var img0 = mxUtils.createImage(cdn + '/stencils/images/' + mst + '.png');
        // var img0 = mxUtils.createImage(cdn + mst + '.png');
        var img0 = mxUtils.createImage(m.logo);

        img0.setAttribute('title', tit);
        img0.style.position = 'absolute';
        img0.style.cursor = 'pointer';
        img0.style.width = '16px';
        img0.style.height = '16px';

        cnt += 1;
        const n = 18;
        if (vertical) {
          if (cnt > 3) {
            dx = dx + 1;
            dy = 0;
            cnt = 0;
          }
          img0.style.left = (state.x + state.width + dx * n) + 'px';
          img0.style.top = (state.y + n * dy) + 'px';
          dy += 1;
        } else {
          if (cnt > 3) {
            dy = dy + 1;
            dx = 0;
            cnt = 0;
          }
          img0.style.left = (state.x + dx * n) + 'px';
          img0.style.top = (state.y + state.height + n * dy) + 'px';
          dx += 1;
        }
        if (mst === "Connector" ||
          mst === SemTalkMaster.MasterSubClassOf ||
          mst === SemTalkMaster.MasterInstanceOf ||
          mst === SemTalkMaster.MasterProperty) {
          mxEvent.addGestureListeners(img0,
            mxUtils.bind(this, (evt: any) => {
              // var pt = mxUtils.convertPoint(graph.container,
              //   mxEvent.getClientX(evt), mxEvent.getClientY(evt));
              // graph.connectionHandler.start(this.state, pt.x, pt.y);
              (graph as any)["connectionMaster"] = mst;
              (graph as any).connectionHandler.start(state, state.x + state.width / 2, state.y + state.width / 2);
              graph.isMouseDown = true;
              (graph as any).isMouseTrigger = mxEvent.isMouseEvent(evt);
              mxEvent.consume(evt);
              self.destroy();
            })
          );
          // this.dragLeave(me1.getEvent(), this.currentState);
        } else {
          mxEvent.addGestureListeners(img0,
            mxUtils.bind(this, (evt: any) => {
              callback.insertQuickShape(m, state.cell);
              mxEvent.consume(evt);
              graph.refresh();
              self.destroy();
            })
          );
        }

        state.view.graph.container.appendChild(img0);
        this.images.push(img0);
      }
    }
    mxIconSet.prototype.destroy = function () {
      if (this.images != null) {
        for (let i = 0; i < this.images.length; i += 1) {
          const img = this.images[i];
          img.parentNode.removeChild(img);
        }
      }

      this.images = null;
    };

    // Defines the tolerance before removing the icons
    const iconTolerance = 40;

    // Shows icons if the mouse is over a cell
    (graph as any).addMouseListener({
      currentState: null,
      currentIconSet: null,
      //  currentIconSetCell: null,
      mouseDown(_sender: any, me: any) {
        // Hides icons on mouse down

        if (this.currentState === null) {
          if (this.currentIconSet != null) {
            this.currentIconSet.destroy();
            this.currentIconSet = null;
            this.currentIconSetCell = null;
          }
          // this.dragLeave(me.getEvent(), this.currentState);
          // this.currentState = null;
          //   if (this.currentIconSet != null) {
          //     this.currentIconSet.destroy();
          //     this.currentIconSet = null;
          //   }
          //  // callback.redrawLastShape();
          // if (this.currentIconSetCell !== null) {
          //   callback.shapeExitedTextEdit(this.currentIconSetCell, "");
          //   this.currentIconSetCell = null;
          // }

        } else {
          let state = this.currentState;
          let cell = state.cell;
          // if (this.currentIconSetCell && this.currentIconSetCell !== cell) {
          //   if (this.currentIconSet != null) {
          //     this.currentIconSet.destroy();
          //     this.currentIconSet = null;
          //     if (this.currentIconSetCell !== null) {
          //       callback.shapeExitedTextEdit(this.currentIconSetCell, "");
          //       this.currentIconSetCell = null;
          //     }
          //   }
          // }
          if (this.currentIconSet === null) {
            let quickshapes: any[] = [];
            if (callback && cell && cell.shapeKey !== SemTalkMaster.MasterSwimlane) {
              quickshapes = callback.quickShapes(cell);
            }
            if (quickshapes.length > 0) {
              //let vertical: boolean = (me.movementX > me.movementY);
              let vs = graph.getStylesheet().getCellStyle(mxConstants.SHAPE_SWIMLANE);
              let isv = vs[mxConstants.STYLE_HORIZONTAL];
              if (me.evt.ctrlKey) {
                isv = !isv;
              }
              let vertical: boolean = !isv;
              // console.debug("X: " + evt.movementX + " Y: " + evt.movementY);
              this.currentIconSet = new (mxIconSet as any)(state, quickshapes, vertical); //eslint-disable-line
              this.currentIconSetCell = cell;
            }
          }
        }
      },
      mouseMove(_sender: any, me: any) {
        let tmp;
        if (
          this.currentState != null &&
          (me.getState() === this.currentState || me.getState() == null)
        ) {
          const tol = iconTolerance;
          tmp = new mxRectangle( //eslint-disable-line
            me.getGraphX() - tol, //eslint-disable-line
            me.getGraphY() - tol,
            2 * tol,
            2 * tol
          );

          if (mxUtils.intersects(tmp, this.currentState)) { //eslint-disable-line
            //eslint-disable-line
            return;
          }
        }

        tmp = graph.view.getState(me.getCell());

        // Ignores everything but vertices
        if (graph.isMouseDown ||
          (tmp != null && !graph.getModel().isVertex(tmp.cell))) {
          tmp = null;
        }

        if (tmp !== this.currentState) {
          //if (this.currentState != null) {
          //this.dragLeave(me.getEvent(), this.currentState);
          //}

          this.currentState = tmp;

          if (this.currentState != null) {
            this.dragEnter(me.getEvent(), this.currentState);
          }
        }
      },
      mouseUp(_sender: any, _me: any) { }, //eslint-disable-line
      dragEnter(evt: any, state: any) {
        if (this.currentIconSet != null) {
          let cell0 = state.cell;
          if (cell0.shapeKey === SemTalkMaster.MasterSwimlane && this.currentIconSetCell.parent === cell0) {
            if (this.currentIconSetCell) {
              // let icx = this.currentIconSetCell.geometry.getCenterX();
              // let icy = this.currentIconSetCell.geometry.getCenterY();
              // console.debug(evt, icx, icy);
              return;
            }
            // let tmp;
            // if (
            //   this.currentState != null &&
            //   (me.getState() === this.currentState || me.getState() == null)
            // ) {
            //   const tol = iconTolerance;
            //   tmp = new mxRectangle( //eslint-disable-line
            //     me.getGraphX() - tol, //eslint-disable-line
            //     me.getGraphY() - tol,
            //     2 * tol,
            //     2 * tol
            //   );

            //   if (mxUtils.intersects(tmp, this.currentState)) { //eslint-disable-line
            //     //eslint-disable-line
            //     return;
            //   }
            // }    
          }
          this.currentIconSet.destroy();
          this.currentIconSet = null;
          if (this.currentIconSetCell !== null) {
            // callback.shapeExitedTextEdit(this.currentIconSetCell, "");
            this.currentIconSetCell = null;
          }
        }

        const {
          cell
        } = state;
        const {
          disabled
        } = cell;

        // // if (evt.ctrlKey && this.currentIconSet === null && !disabled) {
        if (this.currentIconSet === null && !disabled) {
          let quickshapes: any[] = [];
          if (callback && state.cell) {
            quickshapes = callback.quickShapes(state.cell);
          }
          if (quickshapes.length > 0) {
            // let vertical: boolean = (evt.movementX > evt.movementY);
            //let vertical: boolean = !evt.ctrlKey;
            // console.debug("X: " + evt.movementX + " Y: " + evt.movementY);
            let vs = graph.getStylesheet().getCellStyle(mxConstants.SHAPE_SWIMLANE);
            let isv = vs[mxConstants.STYLE_HORIZONTAL];
            if (evt.ctrlKey) {
              isv = !isv;
            }
            let vertical: boolean = !isv;
            this.currentIconSet = new (mxIconSet as any)(state, quickshapes, vertical); //eslint-disable-line
            this.currentIconSetCell = cell;
          }
        }

        // callback && callback(evt, state);
      },
      dragLeave(_evt: any, _state: any) { //eslint-disable-line
        // eslint-disable-line
        if (this.currentIconSet != null) {
          this.currentIconSet.destroy();
          this.currentIconSet = null;
          this.currentIconSetCell = null;
        }
      },
    });
  },


  vertexRenameListener(callback: any) {
    mxCell.prototype.valueChangedCallback = callback; //eslint-disable-line
    if (!mxCell.prototype.hasRewriteValueChanged) { //eslint-disable-line
      //eslint-disable-line
      mxCell.prototype.hasRewriteValueChanged = true; //eslint-disable-line

      const {
        valueChanged
      } = mxCell.prototype; //eslint-disable-line
      mxCell.prototype.valueChanged = function (newValue: any) { //eslint-disable-line
        //eslint-disable-line
        const {
          valueChangedCallback
        } = mxCell.prototype; //eslint-disable-line

        valueChanged.apply(this, [newValue]);
        valueChangedCallback && valueChangedCallback(this, newValue);
      };
    }
  },
  renameCell(newName: any, cell: any, graph: any) {
    cell.value = newName;
    graph.refresh(); // 重新渲染graph
  },


};


mxImageExport.prototype.drawCellState = function (state: any, canvas: any) {
  // Experimental feature
  var link = this.getLinkForCellState(state, canvas);

  if (link != null) {
    canvas.setLink(link);
  }

  // Paints the shape and text
  let cnt = canvas["root"].children.length;
  let cell = state.cell;
  this.drawShape(state, canvas);
  let cnt2 = canvas["root"].children.length - 1;
  if (cell && cell['shapeid'] && cell['objectid']) {
    if (cnt !== cnt2) {
      let i: number = 0;
      while (cnt + i < cnt2) {
        let svg = canvas["root"].children[cnt + i];
        svg.setAttribute('data-shapeid', cell['shapeid']);
        svg.setAttribute('data-objectid', cell['objectid']);
        svg.setAttribute('pointer-events', 'all');
        svg.setAttribute('geometry-x', cell['geometry'].x);
        svg.setAttribute('geometry-y', cell['geometry'].y);
        svg.setAttribute('geometry-height', cell['geometry'].height);
        svg.setAttribute('geometry-width', cell['geometry'].width);
        if (cell.parent && cell.parent['geometry']) {
          svg.setAttribute('geometry-sl-x', cell.parent['geometry'].x);
          svg.setAttribute('geometry-sl-y', cell.parent['geometry'].y);
        }
        // if (svg.nodeName === 'rect') {
        //   rectwidth = svg.getAttribute('width');
        //   rectheight = svg.getAttribute('height');
        //   rect = svg;
        // }
        i++;
      }
    } else {
      let svg = canvas["root"].children[cnt2];
      svg.setAttribute('data-shapeid', cell['shapeid']);
      svg.setAttribute('data-objectid', cell['objectid']);
      svg.setAttribute('pointer-events', 'all');
      svg.setAttribute('geometry-x', cell['geometry'].x);
      svg.setAttribute('geometry-y', cell['geometry'].y);
      svg.setAttribute('geometry-height', cell['geometry'].height);
      svg.setAttribute('geometry-width', cell['geometry'].width);
      if (cell.parent && cell.parent['geometry']) {
        svg.setAttribute('geometry-sl-x', cell.parent['geometry'].x);
        svg.setAttribute('geometry-sl-y', cell.parent['geometry'].y);
      }
      // if (svg.nodeName === 'rect') {
      // rectwidth = svg.getAttribute('width');
      // rectheight = svg.getAttribute('height');
      // rect = svg;
      // }
    }
  }
  this.drawText(state, canvas);
  // console.debug(rectwidth, rectheight);
  cnt2 = canvas["root"].children.length - 1;
  if (cell && cell['shapeid'] && cell['objectid']) {
    let svg = canvas["root"].children[cnt2];
    svg.setAttribute('data-shapeid', cell['shapeid']);
    svg.setAttribute('data-objectid', cell['objectid']);
    svg.setAttribute('pointer-events', 'all');
    svg.setAttribute('geometry-x', cell['geometry'].x);
    svg.setAttribute('geometry-y', cell['geometry'].y);
    svg.setAttribute('geometry-height', cell['geometry'].height);
    svg.setAttribute('geometry-width', cell['geometry'].width);
    if (cell.parent && cell.parent['geometry']) {
      svg.setAttribute('geometry-sl-x', cell.parent['geometry'].x);
      svg.setAttribute('geometry-sl-y', cell.parent['geometry'].y);
    }
    // if (svg.edge !== 1 && rect && rectwidth > 0 && rectheight > 0) {
    //   for (let f of svg.getElementsByTagName('foreignObject')) {
    //     const x = rect.getAttribute('x');
    //     const y = rect.getAttribute('y');
    //     svg.setAttribute('transform', "translate(" + x + "," + y + ")");
    //     f.setAttribute('width', rectwidth);
    //     f.setAttribute('height', rectheight);
    //   }
    // }
  }
  if (link != null) {
    canvas.setLink("");
  }
};


var mxCellEditorresize = mxCellEditor.prototype.resize;
mxCellEditor.prototype.resize = function () {
  mxCellEditorresize.apply(this, []);
  let state = this.graph.getView().getState(this.editingCell);
  if (state) {
    let cell = state.cell;
    if (this.graph.isSwimlane(cell)) {
      let vs = this.graph.getStylesheet().getCellStyle(mxConstants.SHAPE_SWIMLANE);
      let isv = vs[mxConstants.STYLE_HORIZONTAL];
      if (!isv) {
        let p = absolutePosition(cell);
        this.textarea.style.left = Math.round(p.x + 10 - this.textarea.style.width / 2) + "px";
        this.textarea.style.top = Math.round(state.getCenterY() - this.textarea.style.height / 2) + "px";
      }
    }
  }
};

mxSvgCanvas2D.prototype.updateStroke = function () {
  var s: any = this.state;
  // if (typeof s.strokeColor === "number") {
  //   console.debug(s.strokeColor);
  // }
  this.node.setAttribute('stroke', String(s.strokeColor).toLowerCase());

  if (s.alpha < 1 || s.strokeAlpha < 1) {
    this.node.setAttribute('stroke-opacity', String(Number(s["alpha"]) * Number(s["strokeAlpha"])));
  }

  var sw = this.getCurrentStrokeWidth();

  if (sw !== 1) {
    this.node.setAttribute('stroke-width', String(sw));
  }

  if (this.node.nodeName === 'path') {
    this.updateStrokeAttributes();
  }

  if (s.dashed) {
    this.node.setAttribute('stroke-dasharray', this.createDashPattern(
      ((s.fixDash) ? 1 : s.strokeWidth) * s.scale));
  }
};