import axios from 'axios';
// import { SemTalkID } from './application/tbase/Interface';
import { ModelProperty } from './SemTalkOptions';

export enum RestVerb {
  get = "get",
  post = "post",
  put = "put"

}

export interface IMongoOption {
  usemongo: boolean;
  semmongoserverurl: string;
  semmongoserverurlBackup: string;
  dbname: string;
  repository: string;
  backup: string;
  documents: string;
  templates: string;
  stencils: string;
  approved: string;
  semmongoconnectiontoken: string;
  semuserlogin: any;
  iselectron: boolean;
  defaultdatabase: string;
  defaultrepository: string;
  objects: string;
  roles: string;
  users: string;
}
export async function mgEncode(mongo: IMongoOption, connection: string, database: string): Promise<string> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return w.electron_mongo_api.encode(connection, database);
  }
  let url = mongo.semmongoserverurl + "databases/" + mongo.dbname + "/encode";
  // if (url.length === 0) url = defaulturl;
  try {
    let js = { "connect": connection, "database": database };
    let c = {
      "headers": {
        "Accept": 'application/json', "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*",
        "semtalk-token": mongo.semmongoconnectiontoken
      },
    };

    let res = await axios.post(url, js, c);
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      console.log("mgEncode: " + res.statusText);
      return "";
    }
  } catch (e) {
    console.error("mgEncode: " + e);
    return "";
  }
}
export async function mgGetItem(mongo: IMongoOption, dbname: string, listname: string, itemname: string, filter?: any): Promise<any> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    let item = await w.electron_mongo_api.find(dbname, listname, { "name": itemname }, mongo.semmongoconnectiontoken);
    if (item && item.length > 0) {
      return item[0];
    }
    return item.value;
  }
  let url = mongo.semmongoserverurl + "databases/" + dbname + "/collections/" + listname + "/items/";
  try {
    url = url + encodeURIComponent(itemname);
    let headers: any = {
      "Access-Control-Allow-Origin": "*",
      "semtalk-token": mongo.semmongoconnectiontoken
    };
    if (filter) {
      headers["filter"] = JSON.stringify(filter);
    }
    let c = { "headers": headers };

    let res = await axios.get(url, c);
    if (res.status === 200 && res.data && res.data.length > 0) {
      return res.data[0];
    } else {
      console.log("mgGetItem: " + res.statusText);
      return {};
    }
    // return res;
  } catch (e) {
    console.log("mgGetItem: " + e);
    return {};
  }
}


export async function mgGetDocument(mongo: IMongoOption, listname: string, itemname: string): Promise<any> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    let fileitem = await w.electron_mongo_api.find(mongo.dbname, listname, { "name": itemname }, mongo.semmongoconnectiontoken);
    if (fileitem && fileitem.length > 0) {
      return fileitem[0].value;
    }
    return fileitem.value;
  }
  let url = mongo.semmongoserverurl + "databases/" + mongo.dbname + "/collections/" + listname + "/items/";
  try {
    let c = {
      "headers": {
        "Access-Control-Allow-Origin": "*",
        "semtalk-token": mongo.semmongoconnectiontoken
      }
    };
    let res = await axios.get(url + encodeURIComponent(itemname), c);
    if (res.status === 200 && res.data && res.data.length > 0) {
      return res.data[0]["value"];
    } else {
      console.log("mgGetDocument: " + res.statusText);
      return {};
    }
    // return res;
  } catch (e) {
    console.log("mgGetDocument: " + e);
    return {};
  }
}
export async function mgGetItems(mongo: IMongoOption, dbname: string, listname: string, filter?: any): Promise<any[]> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return w.electron_mongo_api.find(dbname, listname, {}, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "databases/" + dbname + "/collections/" + listname + "/items/";
  //if (url.length === 0) url = defaulturl;
  try {
    let headers: any = {
      "Access-Control-Allow-Origin": "*",
      "semtalk-token": mongo.semmongoconnectiontoken
    };
    if (filter) {
      headers["filter"] = JSON.stringify(filter);
    }
    let c = { "headers": headers };
    let res = await axios.get(url + "*", c);
    if (res.status === 200 && res.data && res.data.length > 0) {
      return res.data;
    } else {
      console.log("mgGetItems: " + res.statusText);
      return [];
    }
    // return res;
  } catch (e) {
    console.log("mgGetItems: " + e);
    return [];
  }
}
export async function mgGetCollections(mongo: IMongoOption, dbname: string): Promise<any[]> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return w.electron_mongo_api.getCollectionItems(dbname, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "databases/" + dbname + "/items";
  //if (url.length === 0) url = defaulturl;
  try {
    let c = {
      "headers": {
        "Access-Control-Allow-Origin": "*",
        "semtalk-token": mongo.semmongoconnectiontoken
      }
    };
    let res = await axios.get(url, c);
    if (res.status === 200 && res.data && res.data.length > 0) {
      return res.data;
    } else {
      console.log("mgGetItems: " + res.statusText);
      return [];
    }
    // return res;
  } catch (e) {
    console.log("mgGetItems: " + e);
    return [];
  }
}
export async function mgInsertItems(mongo: IMongoOption, dbname: string, listname: string, items: any[]): Promise<any[]> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return w.electron_mongo_api.insert(dbname, listname, items, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "databases/" + dbname + "/collections/" + listname + "/items";
  let js = { "value": items };
  let c = {
    "headers": {
      "Accept": 'application/json', "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": "*",
      "semtalk-token": mongo.semmongoconnectiontoken
    },
  };

  let res = await axios.post(url, js, c
  );
  if ((res.status === 201 || res.status === 200) && res.data && res.data.length > 0) {
    return res.data;
  } else {
    console.log("mgInsertItems: " + res.statusText);
    return [];
  }
}
export async function mgSaveItem(mongo: IMongoOption, dbname: string, listname: string, itemname: string, jsoncontent: any, filter?: any): Promise<any> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return w.electron_mongo_api.save(dbname, listname, itemname, jsoncontent, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "databases/" + dbname + "/collections/" + listname + "/items";
  // let js = { "value": jsoncontent };
  let js = jsoncontent;
  let headers: any = {
    "Accept": 'application/json', "Content-Type": 'application/json',
    "Access-Control-Allow-Origin": "*",
    "semtalk-token": mongo.semmongoconnectiontoken
  };
  if (filter) {
    headers["filter"] = JSON.stringify(filter);
  }
  let c = { "headers": headers };

  let res = await axios.put(url + "/" + encodeURIComponent(itemname), js, c
  );
  if ((res.status === 201 || res.status === 200) && res.data && res.data.length > 0) {
    return res.data[0];
  } else {
    console.log("mgSaveItem: " + res.statusText);
    return {};
  }
}

export async function mgSaveDocument(mongo: IMongoOption, listname: string, itemname: string, jsoncontent: any): Promise<any> {
  const d = new Date();
  let u = "";
  if (mongo.semuserlogin) {
    u = mongo.semuserlogin.user_info.profile.fullname;
  }
  let js = {
    // "value": { "value": jsoncontent },
    "value": jsoncontent
  };
  (js as any)[ModelProperty.modified] = d;
  (js as any)[ModelProperty.modifiedby] = u;

  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return w.electron_mongo_api.save(mongo.dbname, listname, itemname, js, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "databases/" + mongo.dbname + "/collections/" + listname + "/items";
  // Achtung!!! mit Absicht doppelter value . Jetzt nicht mehr
  let c = {
    "headers": {
      "Accept": 'application/json', "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": "*",
      "semtalk-token": mongo.semmongoconnectiontoken
    },
  };
  let res = await axios.put(url + "/" + encodeURIComponent(itemname), js, c
  );
  if (res.status === 200 && res.data) {
    return res;
  }
  console.log("mgSaveDocument: " + res.statusText);
  return res;
}
export async function mgCheckDocument(mongo: IMongoOption, listname: string, itemname: string, checkin: boolean): Promise<any> {
  if (checkin) {
    mgUnsetValue(mongo, mongo.dbname, listname, itemname, ModelProperty.checkedoutdate);
    mgUnsetValue(mongo, mongo.dbname, listname, itemname, ModelProperty.checkedoutuser);
  } else {
    const d = new Date();
    let u = "";
    if (mongo.semuserlogin) {
      u = mongo.semuserlogin.user_info.profile.fullname;
    }
    mgSetValue(mongo, mongo.dbname, listname, itemname, ModelProperty.checkedoutdate, d);
    mgSetValue(mongo, mongo.dbname, listname, itemname, ModelProperty.checkedoutuser, u);
  }

  console.log("mgCheckDocument: ", itemname);
  return {};
}
export async function mgSaveDocumentAs(mongo: IMongoOption, listname: string, itemname: string, jsoncontent: any): Promise<any> {
  const d = new Date();
  let u = "";
  if (mongo.semuserlogin) {
    u = mongo.semuserlogin.user_info.profile.fullname;
  }

  let js = {
    // "value": { "value": jsoncontent },
    "value": jsoncontent
  };
  (js as any)[ModelProperty.created] = d;
  (js as any)[ModelProperty.createdby] = u;
  if (jsoncontent.Template) {
    (js as any)[ModelProperty.template] = jsoncontent.Template;
  }

  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return w.electron_mongo_api.insert(mongo.dbname, listname, itemname, js, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "databases/" + mongo.dbname + "/collections/" + listname + "/items";
  let c = {
    "headers": {
      "Accept": 'application/json', "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": "*",
      "semtalk-token": mongo.semmongoconnectiontoken
    },
  };

  let res = await axios.post(url + "/" + encodeURIComponent(itemname), js, c);
  return res;
}
export async function mgGetValue(mongo: IMongoOption, dbname: string, listname: string, itemname: string, attribute: any): Promise<any> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return w.electron_mongo_api.getvalue(mongo.dbname, listname, itemname, attribute, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "databases/" + dbname + "/collections/" + listname +
    "/items/" + encodeURIComponent(itemname) + "/attributes";
  let c = {
    "headers": {
      "Access-Control-Allow-Origin": "*",
      "semtalk-token": mongo.semmongoconnectiontoken
    }
  };
  let res = await axios.get(url + "/" + attribute, c);
  if (res.status === 200) {
    return res.data;
  } else {
    console.log("mgGetValue: " + res.statusText);
    return {};
  }
}
export async function mgSetValue(mongo: IMongoOption, dbname: string, listname: string, itemname: string, attribute: any, value: any): Promise<any> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return w.electron_mongo_api.setvalue(mongo.dbname, listname, itemname, attribute, value, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "databases/" + dbname + "/collections/" + listname + "/items/"
    + encodeURIComponent(itemname) + "/attributes/" + encodeURIComponent(attribute);
  let js = { "value": value };
  let c = {
    "headers": {
      "Accept": 'application/json', "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": "*",
      "semtalk-token": mongo.semmongoconnectiontoken
    },
  };

  let res = await axios.put(url, js, c);
  if (res.status === 200) {
    return res.data;
  } else {
    console.log("mgSetValue: " + res.statusText);
    return {};
  }
}
export async function mgDeleteItem(mongo: IMongoOption, dbname: string, listname: string, nameorid: string, filter?: any): Promise<any> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return w.electron_mongo_api.deletedocument(mongo.dbname, listname, { "name": nameorid }, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "databases/" + dbname + "/collections/" + listname + "/items";
  let headers: any = {
    "Access-Control-Allow-Origin": "*",
    "semtalk-token": mongo.semmongoconnectiontoken
  };
  if (filter) {
    headers["filter"] = JSON.stringify(filter);
  }
  let c = { "headers": headers };
  let res = await axios.delete(url + "/" + encodeURIComponent(nameorid), c);
  if ((res.status === 201 || res.status === 200) && res.data && res.data.length > 0) {
    return res.data[0];
  } else {
    console.log("mgDeleteItem: " + res.statusText);
    return {};
  }
}

export async function mgUnsetValue(mongo: IMongoOption, dbname: string, listname: string, itemname: string, attribute: any): Promise<any> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return w.electron_mongo_api.unsetvalue(mongo.dbname, listname, itemname, attribute, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "databases/" + dbname + "/collections/" + listname + "/items/"
    + encodeURIComponent(itemname) + "/attributes/" + encodeURIComponent(attribute);
  let c = {
    "headers": {
      "Accept": 'application/json', "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": "*",
      "semtalk-token": mongo.semmongoconnectiontoken
    },
  };

  let res = await axios.delete(url, c);
  if (res.status === 200) {
    return res.data;
  } else {
    console.log("mgUnsetValue: " + res.statusText);
    return {};
  }
}
export async function mgGetItemNames(mongo: IMongoOption, dbname: string, listname: string, filter?: any): Promise<any[]> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return await w.electron_mongo_api.findNames(dbname, listname, {}, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "databases/" + dbname + "/collections/" + listname + "/items";
  // if (url.length === 0) url = defaulturl;
  try {
    let headers: any = {
      "Access-Control-Allow-Origin": "*",
      "semtalk-token": mongo.semmongoconnectiontoken
    };
    if (filter) {
      headers["filter"] = JSON.stringify(filter);
    }
    let c = { "headers": headers };
    let res = await axios.get(url, c);
    if (res.status === 200 && res.data && res.data.length > 0) {
      return res.data;
    } else {
      console.log("mgGetItemNames: " + res.statusText);
      return [];
    }
  } catch (e) {
    console.error("mgGetItemNames: " + e);
    return [];
  }
}
export async function mgGetMetaData(mongo: IMongoOption, dbname: string, listname: string, filter: any, projection: any): Promise<any[]> {
  let w: any = window;
  // let projection = {
  //   "name": 1
  // };
  // (projection as any)[ModelProperty.modified] = 1;
  // (projection as any)[ModelProperty.modifiedby] = 1;
  // (projection as any)[ModelProperty.created] = 1;
  // (projection as any)[ModelProperty.createdby] = 1;
  // (projection as any)[ModelProperty.approved] = 1;
  // (projection as any)[ModelProperty.approvedby] = 1;
  // (projection as any)[ModelProperty.published] = 1;
  // (projection as any)[ModelProperty.publishedby] = 1;
  // (projection as any)[ModelProperty.checkedoutdate] = 1;
  // (projection as any)[ModelProperty.checkedoutuser] = 1;
  // (projection as any)[ModelProperty.template] = 1;
  // (projection as any)[ModelProperty.state] = 1;
  // (projection as any)[ModelProperty.domain] = 1;

  if (mongo.iselectron && w.electron_mongo_api) {
    return await w.electron_mongo_api.findMetaData(dbname, listname, filter, projection, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "databases/" + dbname + "/collections/" + listname + "/metadata";
  // if (url.length === 0) url = defaulturl;
  let js: any = { "database": dbname, "collection": listname, "project": projection };
  for (let f in filter) {
    js[f] = filter[f];
  }

  try {
    let c = {
      "headers": {
        "Accept": 'application/json', "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*",
        "semtalk-token": mongo.semmongoconnectiontoken
      },
    };
    let res = await axios.post(url, js, c);
    if (res.status === 200 && res.data && res.data.length > 0) {
      return res.data;
    } else {
      console.log("mgGetMetaData: " + res.statusText);
      return [];
    }
  } catch (e) {
    console.error("mgGetMetaData: " + e);
    return [];
  }
}
export async function mgCreate_database(mongo: IMongoOption, dbname: string, desturl: string | undefined, destdbname: string | undefined): Promise<any[]> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return await w.electron_mongo_api.clonedb(dbname, desturl, destdbname, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "databases/" + dbname + "/clonedb";
  // if (url.length === 0) url = defaulturl;
  try {
    let js: any = { "database": dbname, "destdbname": destdbname };
    if (desturl) {
      js["desturl"] = desturl;
    }
    if (destdbname) {
      js["destdbname"] = destdbname;
    }
    let c = {
      "headers": {
        "Accept": 'application/json', "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*",
        "semtalk-token": mongo.semmongoconnectiontoken
      },
    };

    let res = await axios.post(url, js, c);
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      console.log("mgCreate_database: " + res.statusText);
      return [];
    }
  } catch (e) {
    console.error("mgCreate_database: " + e);
    return [];
  }
}
export async function mgnewDatabaseName(mongo: IMongoOption, _connection: string, _database: string): Promise<any[]> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_api) {
    return w.electron_mongo_api.newdatabasename();
  }
  let url = mongo.semmongoserverurl + "newdatabasename";
  // if (url.length === 0) url = defaulturl;
  try {
    let c = {
      "headers": {
        "Accept": 'application/json', "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*",
        "semtalk-token": mongo.semmongoconnectiontoken
      },
    };

    let res = await axios.get(url, c);
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      console.log("newDatabaseName: " + res.statusText);
      return [];
    }
  } catch (e) {
    console.error("newDatabaseName: " + e);
    return [];
  }
}
export async function mglistDatabases(mongo: IMongoOption,
  connection: string, database: string, issemtalk: string): Promise<any[]> {
  let w: any = window;
  if (w.electron_mongo_api) {
    return await w.electron_mongo_api.listdatabases(mongo.dbname, issemtalk, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "listdatabases";
  // if (url.length === 0) url = defaulturl;
  try {
    let js = {
      "connect": connection,
      "database": database,
      "issemtalk": issemtalk
    };
    let c = {
      "headers": {
        "Accept": 'application/json', "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*",
        "semtalk-token": mongo.semmongoconnectiontoken
      },
    };

    let res = await axios.post(url, js, c);
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      console.log("mglistDatabases: " + res.statusText);
      return [];
    }
  } catch (e) {
    console.error("mglistDatabases: " + e);
    return [];
  }
}
export async function mgCallService(mongo: IMongoOption,
  args: any, service?: string, user?: string, pwd?: string): Promise<any> {
  let w: any = window;
  if (mongo.iselectron && w.api) {
    if (service === undefined) {
      service = process.env.similarityservice;
    }
    if (user === undefined) {
      user = process.env.similarityuser;
    }
    if (pwd === undefined) {
      pwd = process.env.similaritypwd;
    }
    return w.api.callservice(args, service, user, pwd);
  }
  let url = mongo.semmongoserverurl + "service";
  // if (url.length === 0) url = defaulturl;
  try {
    // let js = {
    //   "text": text
    // };
    let c = {
      "headers": {
        "Accept": 'application/json', "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*",
        "semtalk-token": mongo.semmongoconnectiontoken
      },
    };

    let res = await axios.post(url, args, c);
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      console.log("mgCallService: " + res.statusText);
      return [];
    }
  } catch (e) {
    console.error("mgCallService: " + e);
    return [];
  }
}
// ------------------------------------------------------

export async function mgLogin_with_email_password(mongo: IMongoOption, dbname: string, email: string, password: string): Promise<any[]> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_auth_api) {
    return await w.electron_mongo_auth_api.login_with_email_password(dbname, mongo.users,
      email, password, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "users/login_with_email_password";
  // if (url.length === 0) url = defaulturl;
  try {
    let js = {
      "dbname": dbname,
      "users": mongo.users,
      "email": email, "password": password
    };
    let c = {
      "headers": {
        "Accept": 'application/json', "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*",
        "semtalk-token": mongo.semmongoconnectiontoken
      },
    };

    let res = await axios.post(url, js, c);
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      console.log("mgLogin_with_email_password: " + res.statusText);
      return [];
    }
  } catch (e) {
    console.error("mgLogin_with_email_password: " + (e as any).message);
    throw e;
  }
}
export async function mgCreate_user(mongo: IMongoOption, email: string, fullname: string, regkey: string, password: string): Promise<any[]> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_auth_api) {
    return await w.electron_mongo_auth_api.create_user(mongo.dbname, mongo.users, email,
      fullname, password, regkey, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "users";
  // if (url.length === 0) url = defaulturl;
  try {
    let js = {
      "dbname": mongo.dbname, "users": mongo.users,
      "email": email, "fullname": fullname, "password": password, "regkey": regkey
    };
    let c = {
      "headers": {
        "Accept": 'application/json', "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*",
        "semtalk-token": mongo.semmongoconnectiontoken
      },
    };

    let res = await axios.post(url, js, c);
    if (res.status === 201 && res.data) {
      return res.data;
    } else {
      console.log("mgCreate_user: " + res.statusText);
      return [];
    }
  } catch (e) {
    console.error("mgCreate_user: " + e);
    throw e;
  }
}
export async function mgChange_Password(mongo: IMongoOption, email: string, old_password: string, regkey: string, new_password: string): Promise<any> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_auth_api) {
    let res: any = await w.electron_mongo_auth_api.login_with_email_password(mongo.dbname, mongo.users,
      email, old_password, mongo.semmongoconnectiontoken);
    return await w.electron_mongo_auth_api.update_password(mongo.dbname, mongo.users,
      res.login_token, old_password,
      new_password, mongo.semmongoconnectiontoken);
  }
  try {
    const res0: any = await mgLogin_with_email_password(mongo,
      mongo.dbname,
      email,
      old_password);
    if (res0.login_token !== undefined) {
      if (res0.login_token.toString().length > 0) {
      }
    }
  } catch (e) {
    console.debug(e);
    return (e as any).response;
  }
  let url = mongo.semmongoserverurl + "users/updatepassword";
  // if (url.length === 0) url = defaulturl;
  try {
    let js = {
      "dbname": mongo.dbname, "users": mongo.users,
      "email": email, "old_password": old_password,
      "new_password": new_password, "regkey": regkey
    };
    let c = {
      "headers": {
        "Accept": 'application/json', "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*",
        "semtalk-token": mongo.semmongoconnectiontoken
      },
    };

    let res = await axios.post(url, js, c);
    if (res.status === 201 && res) {
      return res;
    } else {
      console.log("mgChange_Password: " + res.statusText);
      return res;
    }
  } catch (e) {
    console.error("mgChange_Password: " + e);
    return (e as any).response;
  }
}
// export async function mgForgot_Password_send_token(mongo: IMongoOption, email: string, clientURL: string): Promise<any> {
//   let w: any = window;
//   if (mongo.iselectron && w.electron_mongo_auth_api) {
//     return await w.electron_mongo_auth_api.forgot_password_send_token(mongo.dbname,
//       mongo.users, email, mongo.semmongoconnectiontoken, clientURL);
//   }
//   let url = mongo.semmongoserverurl + "users/forgotpassword";
//   // if (url.length === 0) url = defaulturl;
//   try {
//     let js = {
//       "dbname": mongo.dbname,
//       "users": mongo.users,
//       "email": email,
//       "clientURL": clientURL
//     };
//     let c = {
//       "headers": {
//         "Accept": 'application/json', "Content-Type": 'application/json',
//         "Access-Control-Allow-Origin": "*",
//         "semtalk-token": mongo.semmongoconnectiontoken
//       },
//     };

//     let res = await axios.post(url, js, c);
//     if (res.status === 201) {
//       let result = await res;
//       return result;
//     } else {
//       console.log("mgForgot_Password: " + res.statusText);
//       return [];
//     }
//   } catch (e) {
//     console.error("mgForgot_Password: " + e);
//     throw e;
//   }
// }

// export async function mgForgot_Password_Update(mongo: IMongoOption, email: string, verify_token: string, new_password: string): Promise<any[]> {
//   let w: any = window;
//   if (mongo.iselectron && w.electron_mongo_auth_api) {
//     return await w.electron_mongo_auth_api.forgot_password_use_token(mongo.dbname, mongo.users,
//       email, verify_token, mongo.semmongoconnectiontoken, new_password);
//   }
//   let url = mongo.semmongoserverurl + "users/updateforgotpassword";
//   // if (url.length === 0) url = defaulturl;
//   try {
//     let js = {
//       "dbname": mongo.dbname,
//       "users": mongo.users,
//       "email": email,
//       "verifytoken": verify_token,
//       "password": new_password
//     };
//     let c = {
//       "headers": {
//         "Accept": 'application/json', "Content-Type": 'application/json',
//         "Access-Control-Allow-Origin": "*",
//         "semtalk-token": mongo.semmongoconnectiontoken
//       },
//     };

//     let res = await axios.post(url, js, c);
//     if (res.status === 201 && res.data) {
//       return res.data;
//     } else {
//       console.log("mgForgot_Password: " + res.statusText);
//       return [];
//     }
//   } catch (e) {
//     console.error("mgForgot_Password: " + e);
//     throw e;
//   }
// }
export async function mgReset_Password(mongo: IMongoOption, email: string,
  new_password: string, userid: string, mailtext: string, subject: string): Promise<any> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_auth_api) {
    return await w.electron_mongo_auth_api.forgot_password_use_token(mongo.dbname, mongo.users, email,
      userid, mongo.semmongoconnectiontoken, new_password);
  }
  let url = mongo.semmongoserverurl + "users/updateforgotpassword";
  // if (url.length === 0) url = defaulturl;
  let redirectUri = window.location.origin;
  if (redirectUri.endsWith("/")) {
    redirectUri = redirectUri.substr(0, redirectUri.length - 1);
  }
  redirectUri = redirectUri + "/api/";
  if (mongo.semmongoserverurl !== "/api/" && mongo.semmongoserverurl.length > 1) {
    redirectUri = mongo.semmongoserverurl;
  }
  try {
    let js = {
      "dbname": mongo.dbname,
      "users": mongo.users,
      "email": email,
      // "verifytoken": userid,
      "password": new_password,
      "replyurl": redirectUri,
      "mailtext": mailtext,
      "subject": subject
    };
    let c = {
      "headers": {
        "Accept": 'application/json', "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*",
        "semtalk-token": mongo.semmongoconnectiontoken
      },
    };

    let res = await axios.post(url, js, c);
    if (res.status === 201) {
      return res.data;
    } else {
      console.log("mgReset_Password: " + res.statusText);
      return res.status;
    }
  } catch (e) {
    console.error("mgReset_Password: " + e);
    return (e as any).message;
  }
}

export async function mgSend_Mail(mongo: IMongoOption, email: string, mailtext: string, subject: string): Promise<any[]> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_auth_api) {
    return await w.electron_mongo_auth_api.send_mail_link(email, mailtext, subject,
      mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "users/sendmaillink";
  // if (url.length === 0) url = defaulturl;
  try {
    let js = {
      "email": email,
      "mailtext": mailtext,
      "subject": subject
    };
    let c = {
      "headers": {
        "Accept": 'application/json', "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*"
      },
    };

    let res = await axios.post(url, js, c);
    if (res.status === 201 && res.data) {
      return res.data;
    } else {
      console.log("mgSend_Mail: " + res.statusText);
      return [];
    }
  } catch (e) {
    console.error("mgSend_Mail: " + e);
    throw e;
  }
}

export async function mgLogout(mongo: IMongoOption, dbname: string, login_token: string): Promise<any[]> {
  let w: any = window;
  if (mongo.iselectron && w.electron_mongo_auth_api) {
    return await w.electron_mongo_auth_api.logout(dbname, mongo.users,
      login_token, mongo.semmongoconnectiontoken);
  }
  let url = mongo.semmongoserverurl + "users/logout";
  // if (url.length === 0) url = defaulturl;
  try {
    let js = {
      "dbname": dbname,
      "users": mongo.users,
      "login_token": login_token
    };
    let c = {
      "headers": {
        "Accept": 'application/json', "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*",
        "semtalk-token": mongo.semmongoconnectiontoken
      },
    };

    let res = await axios.post(url, js, c);
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      console.log("mgLogout: " + res.statusText);
      return [];
    }
  } catch (e) {
    console.error("mgLogout: " + e);
    throw e;
  }
}


