import React from "react";
import { Link } from "react-router-dom";
import './Logout.css';

export interface ILogoutProps {
  semmongologintokencookiename: any;
}
export default class Logout extends React.Component<ILogoutProps> {
  constructor(props: any) {
    super(props);
    sessionStorage.removeItem(this.props.semmongologintokencookiename);
  }
  public render(): React.ReactElement {
    return (
      <div className="login-wrapper">
        <div>
          <h1>Sucessfully logged out!"</h1>
          <p></p>
          <Link to="/">Go back to home</Link>
          <p></p>
          <Link to="/signup">Signup new user</Link>
        </div>
      </div>
    );
  }
}


