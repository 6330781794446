import * as React from 'react';
// import styles from './SemTalkTasks.module.scss'
import { SelectionMode } from 'office-ui-fabric-react/lib/utilities/selection/index';
import {
  CommandBar,
  IDropdownOption,
  Dropdown,
  IColumn, List, DetailsList, CheckboxVisibility, DetailsListLayoutMode,
  Stack, StackItem, IconButton, Text,
  // MarqueeSelection,
  // ComboBox,
  IComboBoxOption,
  // IComboBox,
  DefaultButton,
  TextField,
  Label,
  MarqueeSelection,
  Toggle,
  // ScrollablePane,
  // ComboBox,
} from 'office-ui-fabric-react';
import {
  Selection,
  Spinner, SpinnerSize
} from 'office-ui-fabric-react';

import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import {
  ISemTalkGenericTabSpec,
  // ISemTalkGenericTabSpec,
  ISemTalkInstance, ISemTalkInstDialog, ISemTalkSystemClass,
  SemTalkAttachment,
  SemTalkBaseConstant,
  SemTalkLanguage, SemTalkLanguageCode, SemTalkRelation, SemTalkType, SemTalkValueType
} from "../../application/tbase/Interface";
import {
  PlannerPlan, PlannerTask,
  PlannerTaskDetails, PlannerPlanDetails,
  User, Group, Channel, PlannerBucket, PlannerChecklistItems, PlannerCategoryDescriptions,
  // PlannerCategoryDescriptions,
  // Channel
} from '@microsoft/microsoft-graph-types';

import { addCallBack, gotoDocument, gotoNode, gotoObject, removeCallBack } from '../../application/semtalklistener/stglobal';
import { Guid } from "guid-typescript";
import {
  // ISharePointSettings,
  IVisioRDFS, ResID, ResIDL, SemTalkNavigationEvent
} from '../../application/semtalklistener/visiordfsinterface';
import { ISPExplorer } from '../../application/explorer/spexplorerinterface';
// import { ms365login } from '../../login';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
// import { ISemTalkAzureOptions,
//   ISemTalkTeamsOptions
// } from '../../SemTalkOptions';
import { Process_ElementName } from '../../application/semtalklistener/processInterface';
import { ISemTalkOnline, PlannerAttribute, PlannerStatus, SemTalkOnlineCommand } from '../../ISemTalkOnline';
import { OB2JSON } from '../../application/tbase/OB2JSON';
import { Code2Language, GetLanguageCode } from '../../application/tbase/langtools';
import { BPMN_AttributeTypeName, BPMN_TaskType } from '../../application/semtalklistener/subtask/bpmn/bpmninterface';
import { SemTalkCookie } from '../../ISemTalkCookie';
import { accessCookie, setCookie } from '../../application/semtalklistener/stgoto';
// import styles from '../../SemTalkOnline.module.scss';
// import { IMicrosoftTeams, WebPartContext } from '@microsoft/sp-webpart-base';

enum PlannerObject {
  Category = "Planner_Category",
  hasCategory = "Planner_hasCategory",
  CheckListItem = "Planner_ChecklistItem",
  hasCheckItem = "Planner_hasCheckItem",
  Bucket = "Planner_Bucket",
  hasBucket = "Planner_hasBucket"
}




export interface ISemTalkPlannerProps {
  title?: string;
  description?: string;
  semtalk: IVisioRDFS;
  callback: ISemTalkOnline;
  islocked: boolean;
  isplanmaker: boolean;
  language: SemTalkLanguage;
  guilanguage: SemTalkLanguageCode;
  isusertask: boolean;
}

export interface ISemTalkPlannerState {
  objectid: number;
  joinedTeams: Group[];
  teamPlans: PlannerPlan[];
  teamChannels: Channel[];
  tasks: PlannerTask[];
  taskDetails: PlannerTaskDetails[];
  planBuckets: PlannerBucket[];
  planDetails: PlannerPlanDetails;
  usertasks: PlannerTask[];
  loading: boolean;
  planid: string | undefined | null;
  teamid: string | undefined;
  channelid: string | undefined | null;
  isnewplan: boolean;
  teamMembers: User[];
  spinterface: ISPExplorer;
  graphClient: any;
  template: string;
  templates: IComboBoxOption[];
  pages: IComboBoxOption[];
  pagename: string;
  language: SemTalkLanguage;
  isusertask: boolean;
}

export default class SemTalkPlanner extends React.Component<ISemTalkPlannerProps, ISemTalkPlannerState> {
  private _teamsContext: any;
  public callback: Guid;
  private rendering: boolean = false;
  private _selection: Selection;
  // private _task: PlannerTask | null = null;
  private teams: any | undefined = undefined;
  private newplanname: string = "";
  private newtabname: string = "";
  private langoptions: any[] = [];
  private spcontext: any = this.props.callback.getSharePointContext();

  constructor(props: ISemTalkPlannerProps) {
    super(props);
    console.log("SemTalkPlanner");
    this.callback = Guid.create();
    let teamid: string | undefined = undefined;
    let planid: string | undefined | null = null;
    let channelid: string | undefined | null = null;
    // if (this.props.teams) {
    //   teamid = this.props.teams.teamid;
    //   planid = this.props.teams.planid;
    //   channelid = this.props.teams.channelid;
    // }
    // teamid = "b066410a-5179-49fc-bcb0-de7a04d77a37";
    // channelid = "f916515a1687474b9120b16ceb8070eb";
    // planid = "o3fTr1YCYEa5OX-_J9qenpYAEYjF";

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.selectitem();
      }
    });
    let langoptions: any[] = [];
    if (this.props.guilanguage && this.props.isplanmaker) {
      let code = GetLanguageCode(this.props.guilanguage);
      if (this.props.semtalk && code) {
        let lan = Code2Language(code);
        const langclass = this.props.semtalk.base.FindClass(SemTalkBaseConstant.SLLanguage);
        if (langclass) {
          for (let lang of langclass.Instances()) {
            langoptions.push(
              {
                key: lang.ObjectName,
                text: lang.ID2NameNspLan(lan),
              }
            );

          }
        }
      }
    }
    this.langoptions = langoptions;

    this.state = {
      joinedTeams: [],
      teamChannels: [],
      tasks: [],
      taskDetails: [],
      usertasks: [],
      planBuckets: [],
      channelid: channelid,
      planid: planid,
      // diagid: null,
      // bucketid: null,
      // rolemappings: [],
      loading: false,
      // assignments: [],
      teamPlans: [],
      teamid: teamid,
      planDetails: {},
      isnewplan: this.props.isplanmaker,
      // isnewbucket: false,
      // isnewchannel: false,
      // diagname: "",
      teamMembers: [],
      objectid: 1188,
      graphClient: null,
      spinterface: this.props.semtalk.explorer,
      template: "",
      templates: [{ key: "", text: "" }],
      pages: [],
      pagename: "",
      language: this.props.language,
      isusertask: this.props.isusertask
    };
  }


  private mounted: boolean = false;

  public componentDidMount = async () => {

    console.debug("Planner componentDidMount");
    let semtalkonline = this.props.callback;
    let pz = accessCookie(SemTalkCookie.panzoom);
    if (this.props.isplanmaker && pz === '1') {
      this.props.callback.DoCommand(SemTalkOnlineCommand.TogglePanZoom, {});
      // setCookie(SemTalkCookie.panzoom, '0');
    }
    semtalkonline.CreateDocument(this.props.semtalk, "", false);
    // let required_scopes = ["User.Read",
    //   "Sites.Read.All",
    //   "Group.ReadWrite.All",
    //   "Tasks.ReadWrite",
    //   "Files.Read.All",
    //   "User.ReadBasic.All",
    //   "Team.ReadBasic.All"];
    let required_scopes: string[] = [];

    // Team.ReadBasic.All, TeamSettings.Read.All, TeamSettings.ReadWrite.All, User.Read.All, User.ReadWrite.All

    let gc = await this.props.callback.MS365Login(required_scopes);
    let items = await this.loadTemplates();
    await this.load(gc);
    this.setState({ graphClient: gc, templates: items });
    this.mounted = true;
    addCallBack(this);
  }
  public componentWillUnmount() {
    removeCallBack(this);
    this.mounted = false;
  }

  private async load(graphClient: any) {
    let teamid: string | undefined = undefined;
    let teamname: string | undefined = undefined;
    let planid: string | null = null;
    if (this.spcontext) {
      this.teams = this.spcontext.sdks.microsoftTeams;
    }

    if (this.teams) {
      teamid = this.teams.context.groupId;
      teamname = this.teams.context.teamName;
      if (teamid !== undefined) {
        await this.initialize(graphClient, teamid, planid, this.teams !== undefined, teamname);
        this.initstate(teamid, planid);
      }
    } else {
      await this.initialize(graphClient, teamid, planid, false, teamname);
      this.initstate(teamid, planid);
    }
  }
  private initstate(teamid: string | undefined, planid: string | null): void {
    this.setState({
      teamid: teamid,
      planid: planid,
      // loading: false,
    });
  }
  private fetchTeam = async (graphClient: any, teamname: string, planid: string | null) => {
    if (graphClient) {
      // this.setState({
      //   loading: true,
      // });
      const query = "groups?$filter=displayName eq '" + teamname + "'";
      let groups: Group[] = await this.state.spinterface.fetchGraphItems(graphClient, query);
      if (groups && groups.length > 0) {
        let teamid = groups[0].id;
        if (teamid) {
          this._teamsContext = "site";
          this.setState({
            // loading: false,
            teamid: teamid
          });
          if (!planid) {
            await this.fetchTeamPlans(graphClient, teamid);
          } else {
            await this.fetchPlanTasks(graphClient, planid);
            await this.fetchPlanBuckets(graphClient, planid);
            await this.fetchPlanDetails(graphClient, planid);
          }
          await this.fetchTeamMembers(graphClient, teamid);
          await this.fetchTeamChannels(graphClient, teamid);
        }
      } else {
        await this.fetchTeams(graphClient);
      }
    }
  }
  private fetchTeams = async (graphClient: any) => {
    if (graphClient) {
      // this.setState({
      //   loading: true,
      // });

      // const query = "me/joinedTeams";
      const query = "me/memberOf";
      let groups: any[] = await this.state.spinterface.fetchGraphItems(graphClient, query);
      groups = groups.filter(x => (x as any)["@odata.type"] === "#microsoft.graph.group");
      groups.sort((a: any, b: any) => {
        return a.displayName?.localeCompare(b.displayName);
      });
      this.setState({
        joinedTeams: groups,
        teamPlans: [],
        tasks: [],
        taskDetails: [],
        // loading: false
      });
    }
  }
  private fetchPlanTasks = async (graphClient: any, planid: string): Promise<{
    tasks: PlannerTask[],
    taskDetails: PlannerTaskDetails[],
  }> => {
    if (graphClient) {
      // this.setState({
      //   tasks: [],
      //   taskDetails: [],
      //   planid: planid,
      //   // bucketid: bucketid,
      //   // loading: true,
      // });

      // let filter = ""; //this._excludeTypes.map(type => `resourceVisualization/type ne '${type}'`).join(' and ');
      let query = "planner/plans/" + planid + "/tasks";
      // query += "?expand=createdBy/user/displayName,appliedCategories,description";
      let tasks: PlannerTask[] = await this.state.spinterface.fetchGraphItems(graphClient, query);
      let taskdetails: PlannerTaskDetails[] = [];
      for (let task of tasks) {
        if (task.id) {
          let details: PlannerTaskDetails = await this.fetchTaskDetails(graphClient, task.id);
          if (details) {
            taskdetails.push(details);
          }
        }
      }
      let res = {
        tasks: tasks,
        taskDetails: taskdetails,
      };
      this.setState(res);
      return res;
    }
    return {
      tasks: [],
      taskDetails: [],
    };
  }
  private fetchPlanBuckets = async (graphClient: any, planid: string): Promise<PlannerBucket[]> => {
    if (graphClient) {
      let query = "planner/plans/" + planid + "/buckets";
      let buckets: PlannerBucket[] = await this.state.spinterface.fetchGraphItems(graphClient, query);
      buckets.sort((a: any, b: any) => {
        return a.name.localeCompare(b.name);
      });
      // let bucketid: string | null = null;
      // if (buckets.length > 0 && buckets[0].id != null) {
      //   bucketid = buckets[0].id;
      //   await this.fetchPlanTasks(planid, bucketid);
      // }
      // this.setState({
      //   planBuckets: buckets,
      // });
      return buckets;
    }
    return [];
  }
  private fetchPlanDetails = async (graphClient: any, planid: string): Promise<PlannerPlanDetails> => {
    const query = "planner/plans/" + planid + "/details";
    let details: PlannerPlanDetails = await this.state.spinterface.fetchGraphItem(graphClient, query);
    return details;
  }
  private fetchTeamPlans = async (graphClient: any, teamid: string) => {
    if (graphClient) {
      let query = "groups/" + teamid + "/planner/plans";
      let plans: PlannerPlan[] = await this.state.spinterface.fetchGraphItems(graphClient, query);
      plans.sort((a: any, b: any) => {
        return a.title.localeCompare(b.title);
      });
      // let planid: string | null = null;
      // if (plans.length > 1 && plans[0].id != null) {
      //   let plan = plans[0];
      //   if (plan.id) {
      //     planid = plan.id;
      //     await this.fetchPlanTasks(planid);
      //     this.LoadPlanProcess(teamid, plan);
      //   }
      // } else {
      let semtalkonline = this.props.callback;
      let sem = this.props.semtalk;
      if (!this.props.isplanmaker) {
        semtalkonline.CreateDocument(sem, "", false);
      }
      this.setState({
        teamPlans: plans,
        tasks: [],
        taskDetails: [],
      });
    }
  }
  private fetchMyPlans = async (graphClient: any) => {
    if (graphClient) {
      let query = "me/planner/plans";
      let plans: PlannerPlan[] = await this.state.spinterface.fetchGraphItems(graphClient, query);
      plans.sort((a: any, b: any) => {
        return a.title.localeCompare(b.title);
      });
      // let planid: string | null = null;
      // if (plans.length > 1 && plans[0].id != null) {
      //   let plan = plans[0];
      //   if (plan.id) {
      //     planid = plan.id;
      //     await this.fetchPlanTasks(planid);
      //     this.LoadPlanProcess(teamid, plan);
      //   }
      // } else {
      let semtalkonline = this.props.callback;
      let sem = this.props.semtalk;
      if (!this.props.isplanmaker) {
        semtalkonline.CreateDocument(sem, "", false);
      }
      this.setState({
        teamPlans: plans,
        tasks: [],
        taskDetails: [],
      });
    }
  }
  private fetchTaskDetails = async (graphClient: any, taskid: string): Promise<PlannerTaskDetails> => {
    const query = "planner/tasks/" + taskid + "/details";
    let details: PlannerTaskDetails = await this.state.spinterface.fetchGraphItem(graphClient, query);
    return details;
  }
  private fetchTeamMembers = async (graphClient: any, teamid: string) => {
    if (graphClient) {
      let query = "groups/" + teamid + "/members?$expand=displayName";
      let members = await this.state.spinterface.fetchGraphItems(graphClient, query);
      members.sort((a: any, b: any) => {
        if (a.displayName && b.displayName) {
          return a.displayName.localeCompare(b.displayName);
        }
        return true;
      });
      this.setState({
        teamMembers: members,
      });
    }
  }
  private fetchTeamChannels = async (graphClient: any, teamid: string) => {
    if (graphClient) {
      let query = "teams/" + teamid + "/channels";
      let channels: Channel[] = await this.state.spinterface.fetchGraphItems(graphClient, query);
      channels.sort((a: any, b: any) => {
        return a.displayName.localeCompare(b.displayName);
      });
      this.setState({
        teamChannels: channels,
      });
    }
  }
  private addPlanTask = async (graphClient: any, planid: string, task: ISemTalkInstance) => {
    if (graphClient) {
      let query = "planner/tasks";
      let assignments = {};
      let cats = {
        "category1": true,
        "category2": false,
        "category3": false,
      };
      let posttask = {
        "planId": planid,
        "title": task.ObjectCaption,
        "assignments": assignments,
        "appliedCategories": cats
      };

      let buckets = task.LinkedObjects(PlannerObject.hasBucket);
      if (buckets.length > 0) {
        (posttask as any)["bucketId"] = buckets[0].SrcID;
      }

      await graphClient
        .api(query)
        .version("beta")
        .post(posttask)
        .then((res: any) => {
          this.addTaskDetail(graphClient, res.id, task);
          task.SrcID = res.id;
          // this.setState({ isnewitem: false });
          this.fetchPlanTasks(graphClient, planid);
          console.log("addPlanTask: ", res);
        }, (err: any) => {
          // this.setState({ isnewitem: false });
          console.log("addPlanTask: ", err);
        });
    }
  }
  private addTaskDetail = async (graphClient: any, taskid: string, semtask: ISemTalkInstance) => {
    // let att = semtask.Attachments();
    const refs = {};

    for (let assoc of semtask.Attachments()) {
      let hl = assoc.ToObject;
      if (!assoc.ToObject.ID) continue;
      let lbl: string = assoc.GetValue(SemTalkAttachment.label);
      if (lbl !== null && lbl.length > 0) {
      } else {
        lbl = assoc.ToObject.ID2NameNsp();
      }
      // Open Types cannot contain the following characters: ., :, %, @, # so they need to be encoded. Example is
      let hyperlink = hl.ObjectName;
      hyperlink = hyperlink.replace(new RegExp('\\%', 'g'), "%25");
      hyperlink = hyperlink.replace(new RegExp('\\.', 'g'), "%2E");
      hyperlink = hyperlink.replace(new RegExp('\\:', 'g'), "%3A");
      hyperlink = hyperlink.replace(new RegExp('\\@', 'g'), "%40");
      hyperlink = hyperlink.replace(new RegExp('\\#', 'g'), "%23");

      (refs as any)[hyperlink] = {
        "@odata.type": "microsoft.graph.plannerExternalReference",
        "alias": lbl,
      };
    }

    const checklist: PlannerChecklistItems = {};
    for (let lnk of semtask.Links(PlannerObject.hasCheckItem)) {
      let semcheck = lnk.ToObject;
      let checked = lnk.GetValue("Checked");
      let id = semcheck.SrcID;
      if (id.length === 0) {
        id = semcheck.ID;
      }
      (checklist as any)[id] = {
        "@odata.type": "microsoft.graph.plannerChecklistItem",
        "isChecked": checked,
        "title": semcheck.ObjectCaption
      };
    }
    const posttaskdetails = {
      "description": semtask.Comment,
      // "notes": semtask.Comment,
      "checklist": checklist,
      "references": refs,
    };
    const query = "planner/tasks/" + taskid + "/details";
    // https://stackoverflow.com/questions/43356336/cannot-update-taskdetails-in-microsoft-graph-explorer
    graphClient
      .api(query)
      .version("beta")
      .get((err: any, res: PlannerTaskDetails) => {
        if (err) {
          return;
        }
        if (res) {
          graphClient
            .api(query)
            .header("If-Match", (res as any)["@odata.etag"])
            .version("beta")
            .patch(posttaskdetails)
            .then((res2: PlannerTaskDetails) => {
              // this.setState({ isnewitem: false });
              console.log("addTaskDetails: ", res2);
            }, (err2: any) => {
              console.log("addTaskDetails: ", err2);
            });
        }
      });
  }
  private updatePlanTask = async (graphClient: any, _planid: string, task: ISemTalkInstance) => {
    if (graphClient) {
      let query = "planner/tasks/" + task.SrcID;
      let cats = {
      };
      let ob = task.ObjectBase;
      let category = ob.FindSystemClass(PlannerObject.Category);
      if (category) {
        for (let semcat of category.AllInstances()) {
          let cat = semcat.SrcID;
          if (cat.length > 0) {
            (cats as any)[cat] = false;
          }
        }
      }

      for (let semcat of task.LinkedObjects(PlannerObject.hasCategory)) {
        let cat = semcat.SrcID;
        if (cat.length > 0) {
          (cats as any)[cat] = true;
        }
      }
      let posttask = {
        "title": task.ObjectCaption,
        "appliedCategories": cats,
        // "percentComplete": task.GetValue(PlannerAttribute.percentComplete),
      };
      let percentComplete = task.GetValue(PlannerAttribute.percentComplete);
      if (percentComplete) (posttask as any)["percentComplete"] = percentComplete;

      graphClient
        .api(query)
        .version("beta")
        .get((err: any, res: PlannerTaskDetails) => {
          if (err) {
            return;
          }
          if (res) {
            graphClient
              .api(query)
              .version("beta")
              .header("If-Match", (res as any)["@odata.etag"])
              .patch(posttask)
              .then((res1: any) => {
                this.addTaskDetail(graphClient, task.SrcID, task);
                console.log("updatePlanTask: ", res1);
              }, (err1: any) => {
                console.log("updatePlanTask: ", err1);
              });
          }
        });
    }
  }

  private updatePlanDetails = async (graphClient: any, planid: string) => {
    if (graphClient) {
      let query = "planner/plans/" + planid + "/details";
      let sem = this.props.semtalk;
      let ob = sem.base;
      let cats: PlannerCategoryDescriptions = {};
      let category = ob.FindSystemClass(PlannerObject.Category);
      if (category) {
        for (let semcat of category?.AllInstances()) {
          let cat = semcat.SrcID;
          (cats as any)[cat] = semcat.ObjectCaption;
        }
      }
      let post = {
        "categoryDescriptions": cats,
      };

      graphClient
        .api(query)
        .version("beta")
        .get((err: any, res: PlannerTaskDetails) => {
          if (err) {
            return;
          }
          if (res) {
            graphClient
              .api(query)
              .version("beta")
              .header("If-Match", (res as any)["@odata.etag"])
              .patch(post)
              .then((res1: any) => {
                console.log("updatePlanDetails: ", res1);
              }, (err1: any) => {
                // this.setState({ isnewitem: false });
                console.log("updatePlanDetails: ", err1);
              });
          }
        });
    }
  }

  private initialize = async (graphClient: any, teamid: string | undefined, planid: string | null, isteams: boolean, teamname: string | undefined) => {
    // let pg = getDiagram();
    // if (this.props.searchmytasks) {
    //   await this.fetchUserTasks(null);
    // }
    if (isteams && teamid !== undefined) {
      if (!planid) {
        await this.fetchTeamPlans(graphClient, teamid);
      } else {
        // await this.fetchPlanBuckets(planid);
        // if (!pg)
        await this.fetchPlanTasks(graphClient, planid);
        await this.fetchPlanBuckets(graphClient, planid);
        await this.fetchPlanDetails(graphClient, planid);
      }
      await this.fetchTeamChannels(graphClient, teamid);
      await this.fetchTeamMembers(graphClient, teamid);
    } else {
      if (teamid) {
        if (!planid) {
          // await this.fetchTeamPlans(graphClient, teamid);
        } else {
          // await this.fetchPlanBuckets(planid);
          // if (!pg)
          await this.fetchPlanTasks(graphClient, planid);
          await this.fetchPlanBuckets(graphClient, planid);
          await this.fetchPlanDetails(graphClient, planid);
        }
        await this.fetchTeamPlans(graphClient, teamid);
        await this.fetchTeamChannels(graphClient, teamid);
        await this.fetchTeamMembers(graphClient, teamid);
      } else {
        if (!teamid && this.spcontext && teamname) {
          // let teamname = this.props.context.web.title;
          await this.fetchTeam(graphClient, teamname, planid);
        } else {
          await this.fetchTeams(graphClient);
          await this.fetchMyPlans(graphClient);
        }
      }
    }
  }

  private onOptionTeamChange = async (_evt: any, option: IDropdownOption | undefined, _index?: number, _value?: string) => {
    if (option && !this.rendering) {
      let teamid: string = option.key as string;
      console.debug("Team changing");
      if (teamid === "-1") {
        this.setState({
          teamPlans: [],
          tasks: [],
          taskDetails: [],
          // loading: true,
          teamid: undefined,
          planid: null,
        });
      } else {
        this.setState({
          teamid: teamid
        });
        let graphClient = this.state.graphClient;
        await this.fetchTeamMembers(graphClient, teamid);
        await this.fetchTeamPlans(graphClient, teamid);
        await this.fetchTeamChannels(graphClient, teamid);
      }
    }
  }
  private onOptionPlanChange = async (_event: any, option?: IDropdownOption, _index?: number, _value?: string) => {
    // if (option && !this.rendering && this.state.teamid) {
    if (option && !this.rendering) {
      let planid = option.key as string;
      console.debug("Plan changing");
      let semtalkonline = this.props.callback;
      if (planid === "-1") {
        let sem = this.props.semtalk;
        semtalkonline.CreateDocument(sem, "", false);
        this.setState({
          tasks: [],
          taskDetails: [],
          planid: null,
        });
      } else {
        let sem = this.props.semtalk;
        semtalkonline.SetProgressIndicator(sem.getResStrListener(ResIDL.STRPLANNERSTATUSFINDINGPLAN));
        let plan = this.state.teamPlans.find(x => x.id === planid);
        let graphClient = this.state.graphClient;
        let teamid = this.state.teamid;
        if (plan) {
          if (!teamid && plan.container && plan.container.containerId) {
            teamid = plan.container.containerId;
          }
          semtalkonline.SetProgressIndicator(sem.getResStrListener(ResIDL.STRPLANNERSTATUSLOADINGPROCESS));
          if (teamid) {
            await this.LoadPlanProcess(graphClient, teamid, plan);
            // await this.fetchTeamMembers(graphClient, teamid);
            await this.fetchTeamChannels(graphClient, teamid);
          }
        }
        semtalkonline.SetProgressIndicator(sem.getResStrListener(ResIDL.STRPLANNERSTATUSLOADINGTASKS));
        let res = await this.fetchPlanTasks(graphClient, planid);
        let buckets = await this.fetchPlanBuckets(graphClient, planid);
        let pres = await this.fetchPlanDetails(graphClient, planid);
        semtalkonline.SetProgressIndicator(sem.getResStrListener(ResIDL.STRPLANNERSTATUSREFRESHING));
        this.Refresh(res.tasks, res.taskDetails, pres, buckets);
        semtalkonline.SetProgressIndicator("");
        this.setState({
          planid: planid,
          planBuckets: buckets,
          teamid: teamid
        });
      }
    }
  }
  // private onOptionChannelChange = async (_event: any, option?: IDropdownOption, _index?: number, _value?: string) => {
  //   if (option && !this.rendering && this.state.teamid) {
  //     let channelid = option.key as string;
  //     console.debug("Channel changing", channelid);
  //   }
  // }
  public handleEvent = async (m: any): Promise<void> => {
    var mstr = JSON.stringify(m);
    this.eventListener({ data: mstr });
  }

  private eventListener = (e: any): void => {
    if (!this.mounted) {
      return;
    }
    let md: any;
    try {
      md = JSON.parse(e.data);
      var mtype = md.type;
    } catch (e) {
      return;
    }
    switch (mtype) {
      case SemTalkNavigationEvent.gotoDocument:
        this.loadTemplates();
        break;
    }
  }
  private _propscolumns: IColumn[] = [
    {
      key: 'Label',
      name: "strings.Label",
      fieldName: 'Label',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isMultiline: true,
      onRender: item => (
        <div>{item._label}</div>
      )
    },
    {
      key: 'Value',
      name: "strings.Value",
      fieldName: 'Value',
      minWidth: 100,
      // maxWidth: 180,
      isResizable: true,
      isMultiline: true,
      onRender: item => (
        <div>{item._value}</div>
      )
    }
  ];
  private _onRenderUserCell = (item: User | undefined, _index: number | undefined): JSX.Element => {
    if (item) {
      let usr: User = item;
      let cred = "";
      if (usr.givenName) cred = usr.givenName[0];
      if (usr.surname) cred = cred + usr.surname[0];



      let imageurl: string = "";
      if (this.spcontext) {
        imageurl = this.spcontext.pageContext.web.absoluteUrl + "/_layouts/15/userphoto.aspx?size=L&username=" + usr.mail;
      }
      // <Link key={item}>
      if (usr.displayName != null) {
        return <div>
          <Persona
            // {...examplePersona}
            imageUrl={imageurl}
            imageInitials={cred}
            size={PersonaSize.size48}
            text={usr.displayName}
            //  presence={PersonaPresence.online}
            hidePersonaDetails={false}
            imageAlt={usr.displayName + ", status is online"}
          /></div>;
      } else {
        return <div></div>;
      }
    } else {
      return <div></div>;
    }
  }

  private findUserByID = (id: string): User | null => {
    const usrs = this.state.teamMembers.filter((li) => li["id"] === id);
    if (usrs.length > 0) {
      return usrs[0];
    }
    return null;
  }
  // private setTask = (item: any): void => {
  //   this._task = item;
  // }
  private OpenPlanner = async (graphClient: any) => {
    let query = "organization";
    let orgs = await this.state.spinterface.fetchGraphItems(graphClient, query);
    let domain = "semtalk.onmicrosoft.com";
    if (orgs.length > 0 && orgs[0].verifiedDomains.length > 0) {
      domain = orgs[0].verifiedDomains[0].name;
    }
    let language = "en-US";
    switch (this.props.semtalk.guilanguage) {
      case SemTalkLanguageCode.German: {
        language = "de-de";
        break;
      }
    }
    let url = "https://tasks.office.com/" + domain + "/" + language + "/Home/";
    let task: PlannerTask | null = this._selection.getSelection()[0] as PlannerTask;
    if (task != null) {
      url += "Task/" + task.id;
    } else {
      if (this.state.teamid && this.state.planid)
        url += "Planner#/plantaskboard?groupId=" + this.state.teamid + "&planId=" + this.state.planid;
    }
    try {
      window.open(url, "_blank");
    } catch (_e) { }
    // }
  }
  private DeletePlan = (_ev: any): void => {
  }
  private Import = async (graphClient: any, teamid: string, plan: PlannerPlan) => {
    if (teamid && plan) {
      // await this.LoadPlanProcess(this.state.teamid, this.state.planid);
      let tasks: PlannerTask[] = this._selection.getSelection() as PlannerTask[] || [];
      if (tasks.length === 0) tasks = this.state.tasks;
      let details = this.state.taskDetails;
      let plandetails = this.state.planDetails;
      let buckets = this.state.planBuckets;
      this.props.semtalk.ImportPlannerTasks("Task", tasks);
      // if (this.props.callbackimport) {
      //   const cb2 = this.props.callbackimport;
      //   cb2("Task", tasks);
      this.Refresh(tasks, details, plandetails, buckets);
      this.SavePlanProcess(graphClient, teamid, plan);
      // }
    }
  }
  private Export = async (graphClient: any, teamid: string, plan: PlannerPlan) => {
    let planid = plan.id;
    if (planid && teamid) {
      this.updatePlanDetails(graphClient, planid);
      let sem = this.props.semtalk;
      let ob = sem.base;
      let actclass = ob.FindSystemClass(sem.base.GetModelAttribute(Process_ElementName.SLActivity));
      let activities: ISemTalkInstance[] = [];
      if (actclass && sem && sem.page) {
        for (let shp of sem.selectedShapes()) {
          let inst = sem.base.FindInstanceByID(shp["objectid"]);
          if (inst && inst.IsInstance(actclass)) {
            activities.push(inst);
          }
        }
        if (activities.length === 0) {
          activities = actclass.AllInstances();
          // for (let nd of sem.page.Contents()) {
          //   let inst = nd.Model;
          //   if (inst && (inst as ISemTalkInstance).IsInstance(actclass)) {
          //     activities.push(inst as ISemTalkInstance);
          //   }
          // }
        }
        for (let act of activities) {
          if (act.SrcID === "") {
            this.addPlanTask(graphClient, planid, act);
          } else {
            this.updatePlanTask(graphClient, planid, act);
          }
        }
      }
      await this.SavePlanProcess(graphClient, teamid, plan);
    }
  }
  private SavePlanProcess = async (graphClient: any, teamid: string, plan: PlannerPlan) => {
    if (plan) {
      let channel0 = await this.state.spinterface.fetchGraphItem(graphClient, "teams/" + teamid + "/primaryChannel");
      if (channel0 && channel0.id) {
        let f = await this.state.spinterface.fetchGraphItem(graphClient, "teams/" + teamid + "/channels/" + channel0.id + "/filesFolder");
        let doclib = f.parentReference;
        let driveid = doclib.driveId;

        let sem = this.props.semtalk;
        let ob = sem.base;
        let filename = plan.title + ".sdx";
        if (plan.id) {
          filename = plan.id + ".sdx";
        }
        this.props.callback.saveCurrentGraph();
        const o2j = new OB2JSON();
        let body = o2j.SaveJSON(ob);
        if (sem.page)
          body.currentpage = sem.page.ID;
        body.tempId = sem.tempId;
        let url = "/drives/" + driveid + "/root:/" + filename + ":/content";
        let data = JSON.stringify(body);
        await graphClient.api(url)
          .put(data);
      }
    }
  }
  private LoadPlanProcess = async (graphClient: any, teamid: string, plan: PlannerPlan) => {
    // let plan = this.state.teamPlans.find(x => x.id === planid);
    if (plan) {
      let channel0 = await this.state.spinterface.fetchGraphItem(graphClient, "teams/" + teamid + "/primaryChannel");
      if (channel0 && channel0.id) {
        let f = await this.state.spinterface.fetchGraphItem(graphClient, "teams/" + teamid + "/channels/" + channel0.id + "/filesFolder");
        let doclib = f.parentReference;
        let driveid = doclib.driveId;

        let sem = this.props.semtalk;
        // let ob = sem.base;
        let filename = plan.title + ".sdx";
        if (plan.id) {
          filename = plan.id + ".sdx";
        }

        let url = "/drives/" + driveid + "/root:/" + filename;
        try {
          let driveitem = await sem.explorer.fetchGraphItem(graphClient, url);
          if (driveitem) {
            // console.debug(driveitem["@microsoft.graph.downloadUrl"]);
            let response = await fetch(driveitem["@microsoft.graph.downloadUrl"]);
            let s = await response.json();
            console.debug("Loading: " + url);
            if (Object.keys(s).length > 0 && s["ObjectType"] !== undefined) {
              let semtalkonline = this.props.callback;
              await semtalkonline.loadDocumentFromJSON("Plan " + plan.title, s, false);
              // let tasks = this.state.tasks;
              // let details = this.state.taskDetails;
              // let plandetails = this.state.planDetails
              // this.Refresh(tasks, details, plandetails);
            }
          } else {
            let semtalkonline = this.props.callback;
            semtalkonline.CreateDocument(sem, "", false);
          }
        } catch (e) {
          let semtalkonline = this.props.callback;
          semtalkonline.CreateDocument(sem, "", false);
          console.debug("loaddocument. cannot parse doc to JSON!!" + e);
        }
      }
    }
  }

  private Patch = (actclass: ISemTalkSystemClass) => {
    let ob = actclass.ObjectBase;
    for (let key in PlannerAttribute) {
      let attr = actclass.FindAttribute((PlannerAttribute as any)[key]);
      if (!attr) {
        let a = actclass.MakeAttribute((PlannerAttribute as any)[key], "");
        a.Group = "Planner";
        if (key.indexOf("Time") >= 0) {
          a.ValueType = SemTalkValueType.Date;
        }
        let lbl = key.replace("Planner_", "");
        lbl = lbl[0].toUpperCase() + lbl.substr(1);
        let atype = a.ClassOf();
        atype.MakeSynonym(lbl, SemTalkLanguage.English);
        atype.MakeSynonym(lbl, SemTalkLanguage.German);
      }
    }
    if (actclass.TabSpecDefinitions().FindTabSpec("Planner") === null) {
      actclass.TabSpecDefinitions().MakeGenericAttributeTabSpec("Planner", "Planner", [], false);
    }
    let chklstitem = ob.FindSystemClass(PlannerObject.CheckListItem);
    if (!chklstitem) {
      chklstitem = ob.MakeSystemClass(PlannerObject.CheckListItem);
      chklstitem.MakeSynonym("Checkliste", SemTalkLanguage.German);
      chklstitem.MakeSynonym("Checklist", SemTalkLanguage.English);
      chklstitem.InitInstDialog();
      (chklstitem.InstDialog as ISemTalkInstDialog).CheckAssociations = true;
      (chklstitem.InstDialog as ISemTalkInstDialog).CheckSynonyms = true;
    }
    let checkrel = ob.FindAssociationType(PlannerObject.hasCheckItem);
    if (!checkrel) checkrel = ob.MakeAssociationType(SemTalkRelation.SemTalkProperty, PlannerObject.hasCheckItem);
    checkrel.SetValue("Checked", false);
    if (!actclass.HasDirectLink(checkrel, chklstitem)) {
      let lnk = actclass.MakeAssociation(checkrel, chklstitem);
      lnk.ClassOf().MakeSynonym("Checklistenelement", SemTalkLanguage.German);
      lnk.ClassOf().MakeSynonym("Checklist Item", SemTalkLanguage.English);
    }
    let tscheck = actclass.TabSpecDefinitions().FindTabSpec(PlannerObject.CheckListItem);
    if (tscheck === null) {
      tscheck = actclass.TabSpecDefinitions().MakeGenericTabSpec(PlannerObject.CheckListItem,
        checkrel.ObjectName, PlannerObject.CheckListItem, true, false, false, false);
      (tscheck as ISemTalkGenericTabSpec).Visible = false;
      // (tscheck as ISemTalkGenericTabSpec).Group = "Planner";
    }
    // (tscheck as ISemTalkGenericTabSpec).IsChecked = true;

    let category = ob.FindSystemClass(PlannerObject.Category);
    if (!category) {
      category = ob.MakeSystemClass(PlannerObject.Category);
      category.MakeSynonym("Bezeichnung", SemTalkLanguage.German);
      category.MakeSynonym("Label", SemTalkLanguage.English);
      category.InitInstDialog();
      (category.InstDialog as ISemTalkInstDialog).CheckAssociations = true;
      (category.InstDialog as ISemTalkInstDialog).CheckSynonyms = true;
    }
    let catrel = ob.FindAssociationType(PlannerObject.hasCategory);
    if (!catrel) catrel = ob.MakeAssociationType(SemTalkRelation.SemTalkProperty, PlannerObject.hasCategory);
    // catrel.SetValue("Checked", false);
    if (!actclass.HasDirectLink(catrel, category)) {
      let lnk = actclass.MakeAssociation(catrel, category);
      lnk.ClassOf().MakeSynonym("hat Bezeichnung", SemTalkLanguage.German);
      lnk.ClassOf().MakeSynonym("has Category", SemTalkLanguage.English);
    }
    let tscat = actclass.TabSpecDefinitions().FindTabSpec(PlannerObject.Category);
    if (tscat === null) {
      tscat = actclass.TabSpecDefinitions().MakeGenericTabSpec(PlannerObject.Category,
        catrel.ObjectName, PlannerObject.Category, true, false, false, false);
      // (tscat as ISemTalkGenericTabSpec).Group = "Planner";
      (tscat as ISemTalkGenericTabSpec).Visible = false;
    }

    let bucket = ob.FindSystemClass(PlannerObject.Bucket);
    if (!bucket) {
      bucket = ob.MakeSystemClass(PlannerObject.Bucket);
      bucket.MakeSynonym("Bucket", SemTalkLanguage.German);
      bucket.MakeSynonym("Bucket", SemTalkLanguage.English);
      bucket.InitInstDialog();
      (bucket.InstDialog as ISemTalkInstDialog).CheckAssociations = true;
      (bucket.InstDialog as ISemTalkInstDialog).CheckSynonyms = true;
    }
    let bucrel = ob.FindAssociationType(PlannerObject.hasBucket);
    if (!bucrel) bucrel = ob.MakeAssociationType(SemTalkRelation.SemTalkProperty, PlannerObject.hasBucket);
    bucrel.Unique = true;
    if (!actclass.HasDirectLink(bucrel, bucket)) {
      let lnk = actclass.MakeAssociation(bucrel, bucket);
      lnk.ClassOf().MakeSynonym("hat Bucket", SemTalkLanguage.German);
      lnk.ClassOf().MakeSynonym("has Bucket", SemTalkLanguage.English);
    }
    let tsbuc = actclass.TabSpecDefinitions().FindTabSpec(PlannerObject.Bucket);
    if (tsbuc === null) {
      tsbuc = actclass.TabSpecDefinitions().MakeGenericTabSpec(PlannerObject.Bucket,
        bucrel.ObjectName, PlannerObject.Bucket, true, false, true, false);
      // (tsbuc as ISemTalkGenericTabSpec).Group = "Planner";
      (tsbuc as ISemTalkGenericTabSpec).Visible = false;
    }
    actclass.TabSpecDefinitions().MakeTabSpecListTabSpec("Details", "",
      [PlannerObject.Bucket, PlannerObject.CheckListItem, PlannerObject.Category], false);
  }
  private colors = [
    { key: '', text: '' },
    { key: 'white', text: this.props.semtalk.getResStr(ResID.STRWhite) },
    { key: 'silver', text: this.props.semtalk.getResStr(ResID.STRSilver) },
    { key: 'grey', text: this.props.semtalk.getResStr(ResID.STRGray) },
    { key: 'black', text: this.props.semtalk.getResStr(ResID.STRBlack) },
    { key: 'lightblue', text: this.props.semtalk.getResStr(ResID.STRLightblue) },
    { key: 'blue', text: this.props.semtalk.getResStr(ResID.STRBlue) },
    { key: 'navy', text: this.props.semtalk.getResStr(ResID.STRNavy) },
    { key: 'lightgreen', text: this.props.semtalk.getResStr(ResID.STRLightgreen) },
    { key: 'green', text: this.props.semtalk.getResStr(ResID.STRGreen) },
    { key: 'lightgray', text: this.props.semtalk.getResStr(ResID.STRLightgray) },
    { key: 'gray', text: this.props.semtalk.getResStr(ResID.STRGray) },
    { key: 'lightyellow', text: this.props.semtalk.getResStr(ResID.STRLightyellow) },
    { key: 'yellow', text: this.props.semtalk.getResStr(ResID.STRYellow) },
    { key: 'orange', text: this.props.semtalk.getResStr(ResID.STROrange) },
    { key: 'salmon', text: this.props.semtalk.getResStr(ResID.STRSalmon) },
    { key: 'maroon', text: this.props.semtalk.getResStr(ResID.STRMaroon) },
    { key: 'pink', text: this.props.semtalk.getResStr(ResID.STRPink) },
    { key: 'red', text: this.props.semtalk.getResStr(ResID.STRRed) },
    { key: 'purple', text: this.props.semtalk.getResStr(ResID.STRPurple) }
  ];
  private getColorResStr = (key: string) => {
    let x = this.colors.find((c) => c.key === key);
    if (x) return x.text;
    return key;
  }

  private categoryColor = (cat: string) => {
    switch (cat) {
      case "category1": return "pink";
      case "category2": return "red";
      case "category3": return "yellow";
      case "category4": return "green";
      case "category5": return "blue";
      case "category6": return "purple";
      case "category7": return "orange";
      case "category8": return "lightgreen";
      case "category23": return "purple";
      case "category24": return "lightgray";
      case "category25": return "gray";
    }
    return "";
  }
  private Refresh = (tasks: PlannerTask[], details: PlannerTaskDetails[],
    planDetails: PlannerPlanDetails,
    buckets: PlannerBucket[]) => {
    this.setState({ loading: true });
    let sem = this.props.semtalk;
    let ob = sem.base;
    let usertask = this.state.isusertask;
    let actclass = sem.base.FindSystemClass(sem.base.GetModelAttribute(Process_ElementName.SLActivity));
    if (actclass) {
      this.Patch(actclass);
      let category = ob.FindSystemClass(PlannerObject.Category);
      if (planDetails && planDetails.categoryDescriptions && category) {
        let categories = category.AllInstances();
        for (let cat in planDetails.categoryDescriptions) {
          let label = (planDetails.categoryDescriptions as any)[cat];
          if (label === "white") {
            label = "";
          }
          let semcat = categories.find(x => x.SrcID === cat);
          if (!semcat) {
            semcat = category.MakeInstance(PlannerObject.Category + "_" + cat);
          }
          semcat.SrcID = cat;
          let color = this.categoryColor(cat);
          if (!label) {
            label = this.getColorResStr(color);
          }
          semcat.Color = color;
          if (label && label.length > 0) {
            semcat.MakeSynonym(label, this.state.language);
          } else {
          }
        }
      }
      let bucketclass = ob.FindSystemClass(PlannerObject.Bucket);
      let sembuckets: ISemTalkInstance[] = [];
      if (bucketclass) {
        sembuckets = bucketclass.AllInstances();
        for (let bucket of buckets) {
          let bucketid = bucket.id;
          if (bucketid) {
            let sembucket = sembuckets.find(x => x.SrcID === bucketid);
            if (!sembucket) {
              sembucket = bucketclass.MakeInstance(PlannerObject.Bucket + "_" + bucketid,
                SemTalkType.SemTalkInstance, bucketid);
            }
            sembucket.SrcID = bucketid;
            if (bucket.name) {
              sembucket.MakeSynonym(bucket.name, this.state.language);
            }
          }
        }
      }
      let chklstitem = ob.FindSystemClass(PlannerObject.CheckListItem) as ISemTalkSystemClass;
      let instances = actclass.AllInstances();
      for (let task of tasks) {
        let inst = instances.find((i) => i.SrcID === task.id);
        if (inst && (!usertask || inst.GetValue(BPMN_AttributeTypeName.TaskType) === BPMN_TaskType.User)) {
          let tsk: PlannerTask = task;
          let usrs: User[] = [];
          if (tsk.title) {
            this.props.semtalk.RenameObject(inst, tsk.title, null);
          }
          if (tsk.bucketId) {
            let sembucket = sembuckets.find(x => x.SrcID === tsk.bucketId);
            if (sembucket && !inst.HasDirectLink(PlannerObject.hasBucket, sembucket)) {
              inst.MakeAssociation(PlannerObject.hasBucket, sembucket);
            }
          }
          for (let detail of details) {
            if (detail.id === task.id) {
              if (detail.description) {
                inst.SetValue(PlannerAttribute.description, detail.description);
              }
              if (detail.checklist && chklstitem) {
                for (let checkid in detail.checklist) {
                  let checkitem = (detail.checklist as any)[checkid];
                  // let semcheck = ob.FindInstanceByID(checkitem);
                  let semcheck = chklstitem.AllInstances().find(x => x.SrcID === checkid);
                  if (!semcheck) {
                    semcheck = chklstitem.MakeInstance(PlannerObject.CheckListItem + "_" + checkid,
                      SemTalkType.SemTalkInstance, checkid);
                  }
                  semcheck.SrcID = checkid;
                  semcheck.MakeSynonym(checkitem.title, this.state.language);
                  let checked = checkitem.isChecked;
                  let rel = inst.FindAssociation(PlannerObject.hasCheckItem, semcheck);
                  if (!rel) {
                    rel = inst.MakeAssociation(PlannerObject.hasCheckItem, semcheck);
                  }
                  if (rel) {
                    rel.SetValue("Checked", checked);
                  }
                }
              }
              if (detail.references) {
                let refs = detail.references;
                for (let key in refs) {
                  let ref = (refs as any)[key];
                  let hyperlink = key;
                  hyperlink = hyperlink.replace(new RegExp('%2E', 'g'), ".");
                  hyperlink = hyperlink.replace(new RegExp('%3A', 'g'), ":");
                  hyperlink = hyperlink.replace(new RegExp('%40', 'g'), "@");
                  hyperlink = hyperlink.replace(new RegExp('%23', 'g'), "#");
                  hyperlink = hyperlink.replace(new RegExp('%25', 'g'), "%");
                  let lbl = "";
                  if (ref.alias) {
                    lbl = ref.alias;
                  }
                  inst.MakeAttachment(hyperlink, lbl);
                }
              }
            }
          }
          if (tsk.appliedCategories && category) {
            for (let cat in tsk.appliedCategories) {
              // let semcat = ob.FindInstance(PlannerObject.Category + "_" + cat);
              let semcat = category.AllInstances().find(x => x.SrcID === cat);
              if (semcat) {
                let rel = inst.FindAssociation(PlannerObject.hasCategory, semcat);
                if (!rel) {
                  inst.MakeAssociation(PlannerObject.hasCategory, semcat);
                }
              }
            }
          }
          if (tsk.assignments) {
            for (let u in tsk.assignments) {
              let usr = this.findUserByID(u);
              if (usr != null && usr.displayName != null) {
                usrs = usrs.concat(usr);
              }
              inst.SetValue(PlannerAttribute.assignments, usrs.map((u1) => u1.displayName).join(", "));
            }
          }
          // if (tsk.assigneePriority) {
          //   inst.SetValue(PlannerAttribute.priority, tsk.assigneePriority);
          // }
          let status: PlannerStatus = PlannerStatus.NotStarted;
          if (tsk.createdDateTime) {
            inst.SetValue(PlannerAttribute.createdDateTime, tsk.createdDateTime);
          }
          if (tsk.startDateTime) {
            status = PlannerStatus.InProgress;
            inst.SetValue(PlannerAttribute.startDateTime, tsk.startDateTime);
          }
          if (tsk.percentComplete) {
            if (tsk.percentComplete > 0) {
              status = PlannerStatus.InProgress;
            }
            if (tsk.percentComplete === 100) {
              status = PlannerStatus.Completed;
            }
            inst.SetValue(PlannerAttribute.percentComplete, tsk.percentComplete);
          }
          if (tsk.dueDateTime) {
            let now = new Date().toISOString();
            if (now > tsk.dueDateTime) {
              status = PlannerStatus.Late;
            }
            inst.SetValue(PlannerAttribute.dueDateTime, tsk.dueDateTime);
          }
          if (tsk.completedDateTime) {
            status = PlannerStatus.Completed;
            inst.SetValue(PlannerAttribute.completedDateTime, tsk.completedDateTime);
          }
          inst.SetValue(PlannerAttribute.status, status);
          sem.UpdateLabel(inst);
        }
      }
    }
    this.setState({ loading: false });
  }
  private _relativeDate = (crntDate: string | null | undefined): string | false => {
    if (crntDate === undefined)
      return "";
    if (crntDate == null) {
      return "";
    } else {
      const date = new Date((crntDate || "").replace(/-/g, "/").replace(/[TZ]/g, " "));
      const diff = (((new Date()).getTime() - date.getTime()) / 1000);
      const day_diff = Math.floor(diff / 86400);

      if (isNaN(day_diff) || day_diff < 0) {
        return false;
      }
      // eslint-disable-next-line no-mixed-operators
      return day_diff === 0 && (
        // eslint-disable-next-line no-mixed-operators
        (diff < 60) && "Just Now" ||
        // eslint-disable-next-line no-mixed-operators
        diff < 120 && "Minute" ||
        // eslint-disable-next-line no-mixed-operators
        diff < 3600 && `${Math.floor(diff / 60)} ${"Minutes Ago"}` ||
        // eslint-disable-next-line no-mixed-operators
        diff < 7200 && "Hour" ||
        // eslint-disable-next-line no-mixed-operators
        diff < 86400 && `${Math.floor(diff / 3600)} ${"Hours Ago"}`) ||
        // eslint-disable-next-line no-mixed-operators
        day_diff === 1 && "Day" ||
        // eslint-disable-next-line no-mixed-operators
        day_diff <= 30 && `${day_diff} ${"Days Ago"}` ||
        // eslint-disable-next-line no-mixed-operators
        day_diff > 30 && `${Math.ceil(day_diff / 7)} ${"Weeks Ago"}`;
    }
  }

  private cancelnew = (): void => {
    this.setState({ isnewplan: false });
  }
  private loadTemplates = async (): Promise<IComboBoxOption[]> => {
    let items: IComboBoxOption[] = [{ key: "", text: "" }];
    (items as any)[0]["filename"] = "";
    let items2 = await this.props.callback.getDocumentsMetaData();
    items.push(...items2);
    return items;
  }
  public CopyDocument = async (graphClient: any, _filename: string, docname: string) => {
    let teamid = this.state.teamid;
    try {
      let s = this.props.callback.getDocumentJSON();
      let channel0 = await this.state.spinterface.fetchGraphItem(graphClient, "teams/" + teamid + "/primaryChannel");
      if (channel0 && channel0.id) {
        let f = await this.state.spinterface.fetchGraphItem(graphClient, "teams/" + teamid + "/channels/" + channel0.id + "/filesFolder");
        let teamdoclib = f.parentReference;
        let teamdriveid = teamdoclib.driveId;
        let teamsurl = "/drives/" + teamdriveid + "/root:/" + docname + ":/content";
        let data = JSON.stringify(s);
        await graphClient.api(teamsurl)
          .put(data);
      }
    } catch (e) {
      console.debug("copydocument.  an error occured!!" + e);
    }
  }

  private AddPlannerTab = async (graphClient: any, teamid: string, channelid: string, planid: string, tabname: string) => {
    // The appId of the Planner app in Teams is d80f1e22-4f4e-4b0f-8977-a9561b7763a5.
    // You can use this value when creating a Planner tab using the Microsoft Graph API.
    // let tab = new TeamsTab

    // https://graph.microsoft.com/beta/teams/b066410a-5179-49fc-bcb0-de7a04d77a37/installedApps?$expand=teamsAppDefinition


    //   {
    //     "id": "YjA2NjQxMGEtNTE3OS00OWZjLWJjYjAtZGU3YTA0ZDc3YTM3IyNjb20ubWljcm9zb2Z0LnRlYW1zcGFjZS50YWIucGxhbm5lcg==",
    //     "teamsAppDefinition": {
    //         "id": "Y29tLm1pY3Jvc29mdC50ZWFtc3BhY2UudGFiLnBsYW5uZXIjIzEuMi44IyNQdWJsaXNoZWQ=",
    //         "teamsAppId": "com.microsoft.teamspace.tab.planner",
    //         "azureADAppId": "75efb5bc-18a1-4e7b-8a66-2ad2503d79c6",
    //         "displayName": "Tasks by Planner and To Do",
    //         "version": "1.2.8",
    //         "requiredResourceSpecificApplicationPermissions": [],
    //         "publishingState": "published",
    //         "shortdescription": "Stay organized across all your team and individual tasks.",
    //         "description": "Tasks makes it easy to stay organized across all your Planner and To Do tasks. Create, assign, and track tasks individually or collaboratively with your team, and see everything come together in one place.",
    //         "lastModifiedDateTime": null,
    //         "allowedInstallationScopes": "team,personal",
    //         "createdBy": null
    //     }
    // },


    let query = "organization";
    let orgs = await this.state.spinterface.fetchGraphItems(graphClient, query);
    let domain = "semtalk.onmicrosoft.com";
    if (orgs.length > 0 && orgs[0].verifiedDomains.length > 0) {
      domain = orgs[0].verifiedDomains[0].name;
    }
    let language = "en-US";
    switch (this.props.semtalk.guilanguage) {
      case SemTalkLanguageCode.German: {
        language = "de-de";
        break;
      }
    }
    let url = "https://tasks.office.com/" + domain + "/" + language + "/Home/";
    url += "PlannerFrame?page=7&planId=" + planid;
    let tab = {
      "DisplayName": tabname,
      "TeamsAppId": "com.microsoft.teamspace.tab.planner",
      "configuration": {
        "EntityId": "Planner",
        ContentUrl: url,
        WebsiteUrl: url
      }
    };
    let query2 = "teams/" + teamid + "/channels/" + channelid + "/tabs";
    await graphClient
      .api(query2)
      .version("beta")
      .post(tab)
      .then(async (res: any) => {
        console.debug(res);
      });
  }

  private addNewPlan = async (graphClient: any): Promise<any> => {
    let teamid = this.state.teamid;
    let channelid = this.state.channelid;
    if (this.newplanname.length > 0) {
      if (graphClient && teamid) {
        let query = "planner/plans";
        let postplan = {
          "container": {
            "url": "https://graph.microsoft.com/beta/groups/" + teamid
          },
          "title": this.newplanname,
        };
        // if (bucketid) posttask["bucketId"] = bucketid;
        let sem = this.props.semtalk;
        let semtalkonline = this.props.callback;
        semtalkonline.SetProgressIndicator(sem.getResStrListener(ResIDL.STRPLANNERSTATUSCREATINGPLAN));

        await graphClient
          .api(query)
          .version("beta")
          .post(postplan)
          .then(async (res: any) => {
            let planid = res.id;
            if (this.state.template) {
              semtalkonline.SetProgressIndicator(sem.getResStrListener(ResIDL.STRPLANNERSTATUSCREATINGPROCESS));
              await this.CopyDocument(graphClient, this.state.template, planid + ".sdx");
            }
            if (teamid) {
              // await this.SavePlanProcess(teamid, res);
              semtalkonline.SetProgressIndicator(sem.getResStrListener(ResIDL.STRPLANNERSTATUSFINDINGPLAN));
              await this.fetchTeamPlans(graphClient, teamid);
              semtalkonline.SetProgressIndicator(sem.getResStrListener(ResIDL.STRPLANNERSTATUSLOADINGPROCESS));
              await this.LoadPlanProcess(graphClient, teamid, res);
              semtalkonline.SetProgressIndicator(sem.getResStrListener(ResIDL.STRPLANNERSTATUSCREATINGTASKS));
              await this.Export(graphClient, teamid, res);
              semtalkonline.SetProgressIndicator(sem.getResStrListener(ResIDL.STRPLANNERSTATUSLINKING));
              await this.fetchPlanTasks(graphClient, planid);
              if (channelid) {
                semtalkonline.SetProgressIndicator(sem.getResStrListener(ResIDL.STRPLANNERSTATUSCREATINGTAB));
                await this.AddPlannerTab(graphClient, teamid, channelid, planid, this.newplanname);
              }
              semtalkonline.SetProgressIndicator("");
              // await this.LoadPlanProcess(teamid, res);
            }
            this.setState({
              planid: res.id,
              isnewplan: false
            });
          }, (err: any) => {
            // this.setState({ isnewitem: false });
            console.log("addPlanTask: ", err);
          });
      }
    }
    if (this.newtabname) {
      console.debug(this.newtabname);
    }
  }
  private _columns: IColumn[] = [
    {
      key: 'title',
      name: "strings.Task",
      fieldName: 'title',
      minWidth: 100,
      // maxWidth: 180,
      isResizable: true,
      isMultiline: true,
      onRender: item => {
        let tsk: PlannerTask = item;
        let usrs: User[] = [];
        if (tsk.assignments) {
          for (let u in tsk.assignments) {
            let usr = this.findUserByID(u);
            if (usr != null)
              usrs = usrs.concat(usr);
          }
        }
        let props: any[] = [];
        if (tsk.details && tsk.details.description) props.push({ "_label": "Description", "_value": tsk.details.description });
        if (tsk.createdDateTime) props.push({ "_label": "Created", "_value": this._relativeDate(tsk.createdDateTime) });
        if (tsk.startDateTime) props.push({ "_label": "Started", "_value": this._relativeDate(tsk.startDateTime) });
        if (tsk.percentComplete) props.push({ "_label": "Complete", "_value": tsk.percentComplete + " %" });
        if (tsk.dueDateTime) props.push({ "_label": "Due", "_value": this._relativeDate(tsk.dueDateTime) });
        if (tsk.completedDateTime) props.push({ "_label": "Completed", "_value": this._relativeDate(tsk.completedDateTime) });

        return (<div>
          <Stack horizontal horizontalAlign="space-between">
            <Stack horizontal>
              <Text variant={'xLarge'}>{tsk.title}</Text>
            </Stack>
            <StackItem align='end'>
              {/* <Separator> */}
              <IconButton iconProps={{ iconName: 'Help' }}
                aria-describedby="helpbutton"
                onClick={this.onHelp}></IconButton>
              {/* </Separator> */}
            </StackItem>
          </Stack>
          <List
            items={usrs}
            onRenderCell={this._onRenderUserCell}
          />
          <DetailsList
            items={props}
            compact={true}
            columns={this._propscolumns}
            selectionMode={SelectionMode.single}
            layoutMode={DetailsListLayoutMode.justified}
            // selection={this._selection}
            checkboxVisibility={CheckboxVisibility.hidden}
            isHeaderVisible={false}
          />
        </div >
        );
      }
    }
  ];
  private commands = [
    {
      key: 'export',
      name: this.props.semtalk.getResStr(ResID.STRSAVE).replace("&", ""),
      iconProps: {
        iconName: 'SaveAs'
      },
      onClick: (): void => {
        if (this.state.planid && this.state.teamid) {
          let plan = this.state.teamPlans.find(x => x.id === this.state.planid);
          if (plan) {
            this.Export(this.state.graphClient, this.state.teamid, plan);
          }
        }
      }
    },
    {
      key: 'Edit',
      name: "Planner",
      //  cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
      iconProps: {
        iconName: 'Edit'
      },
      onClick: (): void => {
        this.OpenPlanner(this.state.graphClient);
      }
    },
    {
      key: 'Add',
      name: this.props.semtalk.getResStrListener(ResIDL.STRDLGCMDNE),
      //  cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
      iconProps: {
        iconName: 'Add'
      },
      // disabled: !this.state.teamid,
      onClick: (): void => {
        this.setState({
          isnewplan: true,
          // newitemtitle: "",
          // newitemclass: null
        });
      }
    },
    {
      key: 'Delete',
      name: this.props.semtalk.getResStrListener(ResIDL.STRDLGCMDDE),
      iconProps: {
        iconName: 'Delete'
      },
      // disabled: !this.state.planid,
      onClick: this.DeletePlan
    },
    // {
    //   key: 'div',
    //   name: "|",
    // },
    {
      key: 'import',
      disabled: this.props.isplanmaker || this.props.islocked,
      name: this.props.semtalk.getResStrListener(ResIDL.STRDLGIMPORT).replace("&", ""),
      iconProps: {
        iconName: 'OpenFile'
      },
      onClick: (): void => {
        let plan = this.state.teamPlans.find(x => x.id === this.state.planid);
        if (plan && this.state.teamid) {
          this.Import(this.state.graphClient, this.state.teamid, plan);
        }
      }
    },
    // {
    //   key: 'div2',
    //   name: "|",
    // },
    {
      key: 'refresh',
      name: this.props.semtalk.getResStrListener(ResIDL.STRDLGCMDRE).replace("&", ""),
      iconProps: {
        iconName: 'Refresh'
      },
      onClick: (): void => {
        let tasks = this.state.tasks;
        let details = this.state.taskDetails;
        let plandetails = this.state.planDetails;
        let buckets = this.state.planBuckets;
        this.Refresh(tasks, details, plandetails, buckets);
      }
    },
  ];
  private onHelp = () => {
    this.props.semtalk.showHelp("Tasks");
  }
  private selectitem = (): void => {
    let sel = this._selection.getSelection();
    if (sel.length > 0) {
      let tsk = sel[0] as PlannerTask;
      if (tsk !== null) {
        let sem = this.props.semtalk;
        let actclass = sem.base.FindSystemClass(sem.base.GetModelAttribute(Process_ElementName.SLActivity));
        if (actclass) {
          let instances = actclass.AllInstances();
          let act = instances.find(x => x.SrcID === tsk.id);
          if (act) {
            for (let n of act.Nodes()) {
              gotoNode(n.ID, n.Model.ID, n.Diagram.ID, n.ShapeID);
            }
            gotoObject(act.ID);
          }
        }
      }
    }
  }
  public render(): React.ReactElement<ISemTalkPlannerProps> {
    this.rendering = true;
    // this._task = null;
    // const dropdownStyles: Partial<IDropdownStyles> = {
    //   dropdown: { width: 150 },
    // };
    // const stackTokens = { childrenGap: 10 };
    let siteTabTitle: string = '';
    let team_options: any[] = this.state.joinedTeams.map((d: Group) => {
      return {
        key: d.id,
        text: d.displayName
      };
    });
    let plan_options: any[] = [{ key: "-1", text: "" }];
    plan_options = plan_options.concat(this.state.teamPlans.map((d: PlannerPlan) => {
      return {
        key: d.id,
        text: d.title
      };
    }));
    let chan_options: any[] = [{ key: "-1", text: "" }];
    chan_options = chan_options.concat(this.state.teamChannels.map((d: Channel) => {
      return {
        key: d.id,
        text: d.displayName
      };
    }));
    const stackTokens = { childrenGap: 10 };
    // let plannerstyle = {};
    // if (this.props.isplanmaker) {
    //   plannerstyle = { "maxWidth": 350 };
    // }
    let r: React.ReactElement<ISemTalkPlannerProps>;
    r = <Fabric>
      {!this.props.isplanmaker &&
        <Stack tokens={stackTokens} horizontal horizontalAlign="space-between">
          <StackItem>
            <Label></Label>
          </StackItem>

          <StackItem align-items='end'>
            {/* <Separator> */}
            <IconButton iconProps={{ iconName: 'Settings' }}
              aria-describedby="helpbutton"
              onClick={() => {
                this.props.callback.DoCommand(SemTalkOnlineCommand.ShowOptions, {});
              }}
            ></IconButton>
            <IconButton iconProps={{ iconName: 'Help' }}
              aria-describedby="helpbutton"
              onClick={this.onHelp}></IconButton>
            {/* </Separator> */}
          </StackItem>
        </Stack>
      }
      <div style={{ "textAlign": "left", "minWidth": 300, padding: "20px" }}>
        {/* <ScrollablePane> */}
        {!this.props.isplanmaker &&
          <CommandBar
            items={this.commands}
          />
        }
        {/* <div style={plannerstyle}> */}
        <Stack tokens={stackTokens}>
          {/* {this.state.loading && */}
          <div>{siteTabTitle}
          </div>
          {/* } */}
          {this.state.loading && <Spinner label="" size={SpinnerSize.large} />
          }
          {this.state.isnewplan &&
            <StackItem>
              <TextField id="planTitle"
                required={true}
                label={"Plan " + this.props.semtalk.getResStrListener(ResIDL.STRDLGCLNEWSTATE1).replace(":", "")}
                onChange={(_ev, a) => {
                  if (a !== undefined) this.newplanname = a;
                }}></TextField>
            </StackItem>
          }
          {
            !this._teamsContext &&
            team_options.length > 1 &&
            <StackItem>
              <Dropdown
                label="Teams"
                selectedKey={this.state.teamid}
                onChange={this.onOptionTeamChange}
                options={team_options}
              />
            </StackItem>
          }
          {/* } */}
          {this.state.isnewplan &&
            <StackItem>
              <Dropdown id="stChannel" label={this.props.semtalk.getResStrListener(ResIDL.STRTEAMSCHANNEL)}
                options={chan_options}
                selectedKey={this.state.channelid}
                onChange={(_event: any, option?: IComboBoxOption, _index?: number, _value?: string) => {
                  if (option !== undefined) {
                    this.setState({
                      channelid: (option as any).key as string,
                    });
                  }
                }}></Dropdown>
            </StackItem>
          }
          {this.state.isnewplan &&
            <StackItem>
              <Dropdown id="stTemplate" label={this.props.semtalk.getResStrListener(ResIDL.STRDLGCMDTMP) + " " +
                this.props.semtalk.getResStr(ResID.STRREPOSITORY)}
                options={this.state.templates}
                // allowFreeform={true}
                //  autoComplete="on"
                onChange={async (_event: any, option?: IComboBoxOption, _index?: number, _value?: string) => {
                  if (option !== undefined) {
                    let filename = (option as any).filename as string;
                    await this.props.callback.openDocumentCallBack(filename);
                    // this.props.callback.DoCommand(SemTalkOnlineCommand.ZoomFit, {});
                    // this.props.callback.DoCommand(SemTalkOnlineCommand.TogglePanZoom, {});
                    let sempages = this.props.semtalk.base.AllDiagrams();
                    let page: string = "";
                    if (this.props.semtalk.page) page = this.props.semtalk.page.ObjectName;
                    let pages = sempages.map(x => {
                      return {
                        key: x.ObjectName, id: x.ID,
                        text: x.ObjectCaption
                      };
                    });
                    pages.sort((a: any, b: any) => {
                      return a.text.localeCompare(b.text);
                    });
                    this.setState({
                      template: filename,
                      pages: pages,
                      pagename: page
                    });
                  }
                }}></Dropdown>
            </StackItem>
          }
          {this.state.isnewplan &&
            <StackItem>
              <DefaultButton iconProps={{ iconName: 'OpenFile' }}
                aria-describedby="openfile"
                onClick={async () => {
                  await this.props.callback.openlocalDocument("", true);
                  await this.loadTemplates();
                  let sempages = this.props.semtalk.base.AllDiagrams();
                  let pages = sempages.map(x => { return { key: x.ObjectName, id: x.ID, text: x.ObjectCaption }; });
                  pages.sort((a: any, b: any) => {
                    return a.text.localeCompare(b.text);
                  });
                  this.setState({
                    pages: pages
                  });
                }}
              >{this.props.semtalk.getResStrListener(ResIDL.STRDLGCMDTMP) + " Computer"}</DefaultButton>
            </StackItem>
          }
          {this.state.isnewplan && this.state.pages.length > 0 && this.props.isplanmaker &&
            <StackItem>
              <Dropdown id="stPages" label={this.props.semtalk.getResStrListener(ResIDL.STRDIALOGPAGE)}
                options={this.state.pages}
                selectedKey={this.state.pagename}
                // allowFreeform={true}
                //  autoComplete="on"
                onChange={(_event: any, option?: IComboBoxOption, _index?: number, _value?: string) => {
                  if (option !== undefined) {
                    let pagename = option.key as string;
                    if (option.id) {
                      let pageid = option.id as string;
                      if (pageid) gotoDocument(pageid);
                    }
                    this.setState({
                      pagename: pagename,
                    });
                  }
                }}></Dropdown>
            </StackItem>
          }
          {this.state.isnewplan && this.props.isplanmaker &&
            <StackItem>
              <Dropdown label={this.props.semtalk.getResStrListener(ResIDL.STRDLGLANG).replace(":", "")}
                ariaLabel={""}
                selectedKey={this.state.language}
                options={this.langoptions}
                onChange={(_ev, a) => {
                  if (a !== undefined) {
                    if (this.props.callback) {
                      this.props.callback.DoCommand(SemTalkOnlineCommand.SelectDataLang, a.key as SemTalkLanguage); this.props.callback.SetSelectedLanguage(a.key as SemTalkLanguage);
                    }
                    this.setState({ language: a.key as SemTalkLanguage });
                  }
                }} />
            </StackItem>
          }
          {/* {this.state.isnewplan &&
            <StackItem>
              <TextField id="planChannel"
                required={true}
                label={"Tab"}
                onChange={(_ev, a) => {
                  if (a !== undefined) this.newtabname = a;
                }}>
              </TextField>
            </StackItem>
          } */}
          {!this.state.isnewplan && this.state.teamPlans.length > 0 &&
            <StackItem>
              <Dropdown
                label="Plans"
                selectedKey={this.state.planid}
                onChange={this.onOptionPlanChange}
                options={plan_options}
              />
            </StackItem>
          }
          {(this.state.planid || this.props.isplanmaker) &&
            <Toggle id="bpmnusertask"
              inlineLabel label={this.props.semtalk.getResStrListener(ResIDL.STRUSERTASKONLY)} checked={this.state.isusertask}
              onChange={(_ev: React.FormEvent<HTMLElement>, isChecked: boolean | undefined) => {
                if (isChecked !== undefined) {
                  if (isChecked) {
                    setCookie(SemTalkCookie.isusertask, true);
                    this.setState({ "isusertask": true });
                  } else {
                    setCookie(SemTalkCookie.isusertask, false);
                    this.setState({ "isusertask": false });
                  }
                }
              }}></Toggle>
          }
          {this.state.isnewplan &&
            <StackItem>
              <DefaultButton id="okButton" text={this.props.semtalk.getResStrListener(ResIDL.STRDLGCMDOK)}
                onClick={() => { this.addNewPlan(this.state.graphClient); }} />
              {!this.props.isplanmaker &&
                <DefaultButton id="cancelButton" text={this.props.semtalk.getResStrListener(ResIDL.STRDLGCMDCA)} onClick={this.cancelnew} />
              }
            </StackItem>
          }
          {!this.state.isnewplan && !this.props.isplanmaker &&
            <StackItem>
              {this.state.tasks && this.state.tasks.length > 0 &&
                <MarqueeSelection selection={this._selection}>
                  <DetailsList
                    items={this.state.tasks}
                    compact={true}
                    columns={this._columns}
                    selectionMode={SelectionMode.multiple}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selection={this._selection}
                    checkboxVisibility={CheckboxVisibility.onHover}
                    isHeaderVisible={false}
                  // onActiveItemChanged={this.setTask}
                  />
                </MarqueeSelection>
              }
            </StackItem>
          }
        </Stack>
        {/* </div> */}
        {/* </ScrollablePane> */}
      </div >
    </Fabric >;
    this.rendering = false;
    return r;
  }
}
