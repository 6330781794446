import { SemTalkLanguage } from './application/tbase/Interface';
import { IMongoOption, mgCallService, mgGetItems, mgGetMetaData, mgSetValue, mgUnsetValue } from './SemTalkMongoServer';
import { ModelProperty } from './SemTalkOptions';

export interface ISemTalkServicesOptions {
  server: string;
  site: string;
  database: string;
  sqlconnection: string;
  servertype: string;
  breports: boolean;
}

export async function srvUploadFile(mongo: IMongoOption,
  services: ISemTalkServicesOptions,
  filename: string, xml: string, dbexport: boolean, language: SemTalkLanguage): Promise<any> {
  let srv = JSON.parse(JSON.stringify(services));
  if (!dbexport) srv.sqlconnection = "";
  let url = srv.server + "/upload";
  const buf = Buffer.from(xml, 'utf8');
  const b64 = buf.toString('base64');
  let js: any = srv;
  js["files"] = [{
    "filename": filename,
    "content": b64
  }];
  js["reports"] = [];
  js["dbtype"] = services.servertype;
  js["breports"] = services.breports;
  js["settings"] = "";
  js["language"] = language;

  let config = {
    "headers": {
      "Accept": 'application/json',
      "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": "*"
    },
    "maxContentLength": 30000000,
    "maxBodyLength": 30000000
  };
  let args = {
    service: url,
    user: "",
    pwd: "",
    args: js,
    config: config
  };
  let res: any = await mgCallService(mongo, args, url, "", "");
  if (res && res === "Uploaded...") {
    if (mongo.usemongo && mongo.semuserlogin) {
      const d = new Date();
      await mgSetValue(mongo, mongo.dbname, mongo.documents, filename.replace(".xml", ".sdx"), "Published", d.toUTCString());
    }
    return "Success";
  } else {
    console.log("srvUpload: " + res);
    return "Error";
  }
}

export async function srvUploadReport(mongo: IMongoOption, services: ISemTalkServicesOptions,
  filename: string, xml: string, dbexport: boolean, language: SemTalkLanguage): Promise<any> {
  let srv = JSON.parse(JSON.stringify(services));
  if (!dbexport) srv.sqlconnection = "";
  let url = srv.server + "/upload";
  const buf = Buffer.from(xml, 'utf8');
  const b64 = buf.toString('base64');
  let js: any = srv;
  js["reports"] = [{
    "filename": filename,
    "content": b64
  }];
  js["files"] = [];
  js["dbtype"] = services.servertype;
  js["breports"] = services.breports;
  js["settings"] = "";
  js["language"] = language;

  let args = {
    service: url,
    user: "",
    pwd: "",
    args: js
  };
  let res: any = await mgCallService(mongo, args, url, "", "");
  if (res) {
    if (mongo.usemongo && mongo.semuserlogin) {
      const d = new Date();
      await mgSetValue(mongo, mongo.dbname, mongo.documents, filename.replace(".xml", ".sdx"), "Published", d.toUTCString());
    }
    return res;
  } else {
    console.log("srvUpload: " + res);
    return {};
  }
}

export async function srvGetReports(mongo: IMongoOption, lib: string): Promise<any[]> {
  let reports: any = [];
  if (mongo.usemongo && mongo.semuserlogin) {
    let docs = await mgGetItems(mongo, mongo.dbname, lib);
    for (let s of docs) {
      if (s && s["query"]) {
        let query = s["query"];
        if (!query.startsWith("<SemTalkExport")) {
          try {
            const buff2 = Buffer.from(query, 'base64');
            query = buff2.toString('utf8');
          } catch (_e) {
          }
        }
        reports.push({
          id: s["_id"],
          name: s["name"],
          query: query,
          language: s["language"]
        });
      }
    }
  }
  // if (!this.props.mongo.usemongo && this.props.context !== undefined
  //   && this.props.site !== undefined && this.props.library !== undefined) {
  //   let lib: string = "Reports";
  //   let lany: any = await this.state.spinterface.getListData(this.props.context, this.props.site, lib, "$select=FileLeafRef,ID,CheckoutUserId");
  //   let lil: any[] = lany.value;
  //   if (lil !== undefined) {
  //     let spitems = lil;
  //     reports = spitems.map((s) => {
  //       return { key: s["FileLeafRef"], text: s["FileLeafRef"] };
  //     });
  //   }
  // }
  return reports;
}
export async function srvResetPublished(mongo: IMongoOption) {
  if (mongo.usemongo && mongo.semuserlogin) {
    let lib = mongo.documents;
    let docs = await mgGetMetaData(mongo, mongo.dbname, lib, {}, { "name": 1 });
    for (let s of docs) {
      let filename = s["name"];
      await mgUnsetValue(mongo, mongo.dbname, mongo.documents, filename, ModelProperty.published);
    }
  }
}
export async function srvExport(mongo: IMongoOption,
  services: ISemTalkServicesOptions, language: string): Promise<any> {
  let srv = JSON.parse(JSON.stringify(services));
  let url = srv.server + "/upload";
  let js: any = srv;
  js["files"] = [];
  js["reports"] = [];
  js["dbtype"] = services.servertype;
  js["breports"] = services.breports;
  js["settings"] = "";
  js["language"] = language;


  let args = {
    service: url,
    user: "",
    pwd: "",
    args: js
  };

  let res: any = await mgCallService(mongo, args, url, "", "");
  if (res) {
    return res;
  } else {
    console.log("srvExport: " + res);
    return {};
  }
}

export async function srvLog(mongo: IMongoOption, services: ISemTalkServicesOptions): Promise<any> {
  let srv = JSON.parse(JSON.stringify(services));
  let url = srv.server + "/log";

  let args = {
    service: url,
    user: "",
    pwd: "",
    args: {}
  };

  let res: any = await mgCallService(mongo, args, url, "", "");
  if (res && res.length > 0) {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(res, "text/xml");
    let rt = xmlDoc.getElementsByTagName("SemTalkServiceLog")[0];
    let log: any[] = [];
    for (const i in rt.children) {
      const e = rt.children[i];
      log.push({ "entry": e.innerHTML });
    }
    return log;
  } else {
    console.log("srvLog: " + res);
    return {};
  }
}
