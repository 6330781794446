import { ISemTalkOnline } from "../../ISemTalkOnline";
import * as xpath from 'xpath-ts';
import { IObjectBase, ISemTalkAssociationType, ISemTalkAttributeType, ISemTalkClass, ISemTalkInstance, SemTalkBaseConstant, SemTalkRelation, SemTalkType, SemTalkValueType } from "./Interface";
import { Code2Language } from "./langtools";

export const rdf: string = "rdf:";
export const xmlns: string = "xmlns:";
export const owl: string = "owl:";
export const xml: string = "xml:";
export const xsd: string = "xsd:";
export const rdfs: string = "rdfs:";
export const semtalkns: string = "semtalk:";

interface IOntology {
    id: string;
    element: HTMLElement;
}
interface IAnnotationProperty {
    id: string;
    label: string;
    semtalk?: ISemTalkAttributeType;
    element: HTMLElement;
}
interface IObjectProperty {
    id: string;
    label: string;
    semtalk?: ISemTalkAssociationType;
    element: HTMLElement;
}
interface IDatatypeProperty {
    id: string;
    label: string;
    semtalk?: ISemTalkAttributeType;
    element: HTMLElement;
}
interface IClass {
    id: string;
    label: string;
    semtalk?: ISemTalkClass;
    element: HTMLElement;
}
interface INamedIndividual {
    id: string;
    label: string;
    semtalk?: ISemTalkInstance;
    element: HTMLElement;
}

export enum RDFElement {
    RDF = "RDF",
    about = "about",
    label = "label",
    subClassOf = "subClassOf",
    resource = "resource",
    datatype = "datatype",
    comment = "comment",
    type = "type",
    subPropertyOf = "subPropertyOf",
    domain = "domain",
    range = "range"
}
export enum OWLElement {
    Ontology = "Ontology",
    AnnotationProperty = "AnnotationProperty",
    ObjectProperty = "ObjectProperty",
    DatatypeProperty = "DatatypeProperty",
    Class = "Class",
    NamedIndividual = "NamedIndividual",
    Restriction = "Restriction",
    onProperty = "onProperty",
    someValuesFrom = "someValuesFrom",
    hasValue = "hasValue"
}
export function OWLImport(_callback: ISemTalkOnline, ob: IObjectBase, text: string) {
    const domparser = new DOMParser();
    let OWLXML = domparser.parseFromString(text, 'text/xml');

    let rdfelement: any = xpath.select("//" + rdf + RDFElement.RDF, OWLXML, true);

    let namespaces: any = {};

    for (let ns of rdfelement.attributes) {
        if (ns.nodeName && (ns.nodeName as string).startsWith("xmlns")) {
            namespaces[ns.localName] = ns;
        }
    }

    let ontologies: IOntology[] = [];
    getOntologies(ontologies, rdfelement);

    let annotationProperties: IAnnotationProperty[] = [];
    getAnnotationProperties(annotationProperties, rdfelement);

    let objectProperties: IObjectProperty[] = [];
    getObjectProperties(objectProperties, rdfelement);

    let datatypeProperties: IDatatypeProperty[] = [];
    getDatatypeProperties(datatypeProperties, rdfelement);

    let classes: IClass[] = [];
    getClasses(classes, rdfelement);

    let instances: INamedIndividual[] = [];
    getInstances(instances, rdfelement);

    for (let cl of objectProperties) {
        let label = cl.label;
        let id = cl.id;
        let semcl = ob.FindAssociationType(label);
        if (!semcl) {
            semcl = ob.MakeAssociationType(SemTalkRelation.SemTalkProperty, label, id);
        }
        cl.semtalk = semcl;
    }
    for (let cl of datatypeProperties) {
        let label = cl.label;
        let id = cl.id;
        let semcl = ob.FindAttributeType(label);
        if (!semcl) {
            semcl = ob.MakeAttributeType(label, id);
        }
        cl.semtalk = semcl;
    }
    for (let cl of datatypeProperties) {
        let semcl = cl.semtalk;
        if (semcl) {
            let labels: any = xpath.select(rdfs + RDFElement.label, cl.element, false);
            for (let label of labels) {
                if (label.attributes[xml + "lang"]) {
                    let lan = label.attributes[xml + "lang"].value;
                    if (lan) {
                        let semlan = Code2Language(lan);
                        let syn = label.textContent;
                        semcl.MakeSynonym(syn, semlan);
                    }
                }
            }
        }
        let subpropertyof: any = xpath.select(rdfs + RDFElement.subPropertyOf, cl.element, false);
        for (let subproperty of subpropertyof) {
            if (subproperty.attributes[rdf + RDFElement.resource]) {
                let superid = subproperty.attributes[rdf + RDFElement.resource].value;
                let superproperty = datatypeProperties.find(x => x.id === superid);
                if (superproperty) {
                    let supercl = superproperty.semtalk;
                    if (supercl && semcl) {
                        if (!semcl.IsParent(supercl)) {
                            semcl.AddSubclassOf(supercl);
                        }
                    }
                }
            }
        }
    }
    for (let cl of classes) {
        let label = cl.label;
        let id = cl.id;
        let semcl = ob.FindClass(label);
        if (!semcl) {
            semcl = ob.MakeClass(label, id);
        }
        cl.semtalk = semcl;
    }
    for (let cl of classes) {
        let semcl = cl.semtalk;
        let comment: any = xpath.select(rdfs + RDFElement.comment, cl.element, true);
        if (semcl && comment) {
            semcl.Comment = comment.textContent;
        }
        if (semcl) {
            let labels: any = xpath.select(rdfs + RDFElement.label, cl.element, false);
            for (let label of labels) {
                if (label.attributes[xml + "lang"]) {
                    let lan = label.attributes[xml + "lang"].value;
                    if (lan) {
                        let semlan = Code2Language(lan);
                        let syn = label.textContent;
                        semcl.MakeSynonym(syn, semlan);
                    }
                }
            }
        }

        let subclassof: any = xpath.select(rdfs + RDFElement.subClassOf, cl.element, false);
        for (let subclass of subclassof) {
            if (subclass.attributes[rdf + RDFElement.resource]) {
                let superid = subclass.attributes[rdf + RDFElement.resource].value;
                let superclass = classes.find(x => x.id === superid);
                if (superclass) {
                    let supercl = superclass.semtalk;
                    if (supercl && semcl) {
                        if (!semcl.IsParent(supercl) && !supercl.IsParent(semcl)) {
                            semcl.AddSubclassOf(supercl);
                        }
                    }
                }
            }
            let restrictions: any = xpath.select(owl + OWLElement.Restriction, subclass, false);
            for (let restriction of restrictions) {
                let onproperty: any = xpath.select(owl + OWLElement.onProperty, restriction, true);
                if (onproperty) {
                    let propertyid = onproperty.attributes[rdf + RDFElement.resource].value;
                    let hasvalue: any = xpath.select(owl + OWLElement.hasValue, restriction, true);
                    if (hasvalue && semcl) {
                        let value = hasvalue.textContent;
                        let datatype = hasvalue.attributes[rdf + RDFElement.datatype].value;
                        let property = datatypeProperties.find(x => x.id === propertyid);
                        if (property) {
                            let attr = property.semtalk;
                            if (attr) {
                                semcl.SetValue(attr.ObjectName, value);
                                let a = semcl.FindAttribute(attr.ObjectName);
                                if (a) {
                                    switch (datatype) {
                                        case "http://www.w3.org/2001/XMLSchema#integer": {
                                            a.ValueType = SemTalkValueType.Integer;
                                            a.Value = Number(value);
                                            break;
                                        }
                                        case "http://www.w3.org/2001/XMLSchema#boolean": {
                                            a.ValueType = SemTalkValueType.Boolean;
                                            a.Value = (value = 'true');
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    let somevaluesfrom: any = xpath.select(owl + OWLElement.someValuesFrom, restriction, true);
                    if (somevaluesfrom && semcl) {
                        let targetid = somevaluesfrom.attributes[rdf + RDFElement.resource].value;
                        let target = classes.find(x => x.id === targetid);
                        let property = objectProperties.find(x => x.id === propertyid);
                        if (property && target) {
                            let assoc = property.semtalk;
                            let toobj = target.semtalk;
                            if (assoc && toobj) {
                                if (!semcl.HasDirectLink(assoc, toobj)) {
                                    semcl.MakeAssociation(assoc, toobj);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    for (let cl of objectProperties) {
        let semcl = cl.semtalk;
        if (semcl) {
            let labels: any = xpath.select(rdfs + RDFElement.label, cl.element, false);
            for (let label of labels) {
                if (label.attributes[xml + "lang"]) {
                    let lan = label.attributes[xml + "lang"].value;
                    if (lan) {
                        let semlan = Code2Language(lan);
                        let syn = label.textContent;
                        semcl.MakeSynonym(syn, semlan);
                    }
                }
            }
        }
        let subpropertyof: any = xpath.select(rdfs + RDFElement.subPropertyOf, cl.element, false);
        for (let subproperty of subpropertyof) {
            if (subproperty.attributes[rdf + RDFElement.resource]) {
                let superid = subproperty.attributes[rdf + RDFElement.resource].value;
                let superproperty = objectProperties.find(x => x.id === superid);
                if (superproperty) {
                    let supercl = superproperty.semtalk;
                    if (supercl && semcl) {
                        if (!semcl.IsParent(supercl)) {
                            semcl.AddSubclassOf(supercl);
                        }
                    }
                }
            }
        }
        let domains: any = xpath.select(rdfs + RDFElement.domain, cl.element, false);
        for (let domain of domains) {
            if (domain.attributes[rdf + RDFElement.resource]) {
                let superid = domain.attributes[rdf + RDFElement.resource].value;
                let domainclasselement = classes.find(x => x.id === superid);
                if (domainclasselement) {
                    let domaincl = domainclasselement.semtalk;
                    if (domaincl && semcl) {
                        if (!semcl.HasDirectLink(SemTalkBaseConstant.SLHasDomain, domaincl)) {
                            semcl.MakeAssociation(SemTalkBaseConstant.SLHasDomain, domaincl);
                        }
                    }
                }
            }
        }
        let ranges: any = xpath.select(rdfs + RDFElement.range, cl.element, false);
        for (let range of ranges) {
            if (range.attributes[rdf + RDFElement.resource]) {
                let superid = range.attributes[rdf + RDFElement.resource].value;
                let rangeclasselement = classes.find(x => x.id === superid);
                if (rangeclasselement) {
                    let rangecl = rangeclasselement.semtalk;
                    if (rangecl && semcl) {
                        if (!semcl.HasDirectLink(SemTalkBaseConstant.SLHasRange, rangecl)) {
                            semcl.MakeAssociation(SemTalkBaseConstant.SLHasRange, rangecl);
                        }
                    }
                }
            }
        }
        let inverseofs: any = xpath.select(rdfs + RDFElement.domain, cl.element, false);
        for (let inverseof of inverseofs) {
            if (inverseof.attributes[rdf + RDFElement.resource]) {
                let invid = inverseof.attributes[rdf + RDFElement.resource].value;
                let invpropertyelement = objectProperties.find(x => x.id === invid);
                if (invpropertyelement) {
                    let invpropcl = invpropertyelement.semtalk;
                    if (invpropcl && semcl) {
                        if (!semcl.HasDirectLink(SemTalkBaseConstant.SLInverseOf, invpropcl)) {
                            semcl.MakeAssociation(SemTalkBaseConstant.SLInverseOf, invpropcl);
                        }
                    }
                }
            }
        }
    }

    let thing = ob.FindSystemClass(SemTalkBaseConstant.SLThing);
    for (let ins of instances) {
        let label = ins.label;
        let id = ins.id;
        let semin = ob.FindInstance(label);
        let cla: ISemTalkClass | null = null;
        if (thing) {
            cla = thing as ISemTalkClass;
        }
        let typeel: any = xpath.select(rdf + RDFElement.type, ins.element, true);
        if (typeel) {
            let classid = typeel.attributes[rdf + RDFElement.resource].value;
            let classel = classes.find(x => x.id === classid);
            if (classel && classel.semtalk) {
                cla = classel.semtalk;
            }
        }

        if (!semin && cla) {
            semin = ob.MakeInstance(cla, label, SemTalkType.SemTalkInstance, id);
        } else {
            if (semin && cla) {
                if (!semin.IsInstance(cla)) {
                    semin.SetClass(cla);
                }
            }
        }
        if (semin) {
            ins.semtalk = semin;
        }
    }

    for (let ins of instances) {
        let semin = ins.semtalk;
        if (semin) {
            let comment: any = xpath.select(rdfs + RDFElement.comment, ins.element, true);
            if (comment) {
                semin.Comment = comment.textContent;
            }
            let labels: any = xpath.select(rdfs + RDFElement.label, ins.element, false);
            for (let label of labels) {
                if (label.attributes[xml + "lang"]) {
                    let lan = label.attributes[xml + "lang"].value;
                    if (lan) {
                        let semlan = Code2Language(lan);
                        let syn = label.textContent;
                        semin.MakeSynonym(syn, semlan);
                    }
                }
            }
            let children = ins.element.children;
            for (let cldnr in children) {
                let cld = children[cldnr];
                let dt: IDatatypeProperty | undefined = undefined;
                let ot: IObjectProperty | undefined = undefined;
                if (cld && cld.tagName) {
                    if (cld.tagName.indexOf(":") > -1) {
                        let nsp = cld.tagName.substring(0, cld.tagName.lastIndexOf(":"));
                        if (nsp === "rdf" || nsp === "rdfs" || nsp === "owl") continue;
                        if (namespaces[nsp]) {
                            let ident: string = namespaces[nsp].nodeValue;
                            let uid = cld.tagName.substring(cld.tagName.lastIndexOf(":") + 1);
                            let id = ident + uid;
                            dt = datatypeProperties.find(x => x.id === id);
                            ot = objectProperties.find(x => x.id === id);
                        }
                    } else {
                        let id = namespaces["xmlns"].nodeValue + cld.tagName;
                        dt = datatypeProperties.find(x => x.id === id);
                        ot = objectProperties.find(x => x.id === id);

                    }
                }
                if (dt && dt.semtalk) {
                    let dataprop: any = cld;
                    let value = dataprop.textContent;
                    semin.SetValue(dt.semtalk.ObjectName, value);
                    let a = semin.FindAttribute(dt.semtalk.ObjectName);
                    if (a && dataprop.attributes[rdf + RDFElement.datatype]) {
                        let datatype = dataprop.attributes[rdf + RDFElement.datatype].value;
                        switch (datatype) {
                            case "http://www.w3.org/2001/XMLSchema#integer": {
                                a.ValueType = SemTalkValueType.Integer;
                                a.Value = Number(value);
                                break;
                            }
                            case "http://www.w3.org/2001/XMLSchema#boolean": {
                                a.ValueType = SemTalkValueType.Boolean;
                                a.Value = (value = 'true');
                                break;
                            }
                        }
                    }
                } else {
                    if (ot && ot.semtalk) {
                        let objprop: any = cld;
                        let otherid = objprop.attributes[rdf + RDFElement.resource].value;
                        if (otherid) {
                            let otherelement = instances.find(x => x.id === otherid);
                            if (otherelement && otherelement.semtalk) {
                                let other = otherelement.semtalk;
                                let assoc = ot.semtalk;
                                if (!semin.HasDirectLink(assoc, other)) {
                                    semin.ObjectBase._loading = true;
                                    semin.MakeAssociation(assoc, other);
                                    semin.ObjectBase._loading = false;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



function getOntologies(ontologies: IOntology[], rdfelement: HTMLElement) {
    let ontology: any = xpath.select(owl + OWLElement.Ontology, rdfelement, true);
    let id = ontology.attributes[rdf + RDFElement.about].value;
    ontologies.push({ id: id, element: ontology });
}
function getAnnotationProperties(annotationProperties: IAnnotationProperty[], rdfelement: HTMLElement) {
    let annotationproperties: any[] = xpath.select(owl + OWLElement.AnnotationProperty, rdfelement, false) as any[9];
    for (let annotationproperty of annotationproperties) {
        let label: any = xpath.select(rdfs + RDFElement.label, annotationproperty, true);
        let id = annotationproperty.attributes[rdf + RDFElement.about].value;
        let text = "";
        if (label) {
            text = label.textContent;
        } else {
            if (id.indexOf("#") > -1) {
                text = id.substring(id.indexOf("#") + 1);
            } else {
                if (id.indexOf("/") > -1) {
                    text = id.substring(id.lastIndexOf("/") + 1);
                }
            }
        }
        annotationProperties.push({ id: id, label: text, element: annotationproperty });
    }
}
function getObjectProperties(objectProperties: IObjectProperty[], rdfelement: HTMLElement) {
    let objectproperties: any[] = xpath.select(owl + OWLElement.ObjectProperty, rdfelement, false) as any[];
    for (let objectproperty of objectproperties) {
        let label: any = xpath.select(rdfs + RDFElement.label, objectproperty, true);
        let id = objectproperty.attributes[rdf + RDFElement.about].value;
        let text = "";
        if (label) {
            text = label.textContent;
        } else {
            if (id.indexOf("#") > -1) {
                text = id.substring(id.indexOf("#") + 1);
            } else {
                if (id.indexOf("/") > -1) {
                    text = id.substring(id.lastIndexOf("/") + 1);
                }
            }
        }
        objectProperties.push({ id: id, label: text, element: objectproperty });
    }
}
function getDatatypeProperties(datatypeProperties: IDatatypeProperty[], rdfelement: HTMLElement) {
    let datatypeproperties: any[] = xpath.select(owl + OWLElement.DatatypeProperty, rdfelement, false) as any;
    for (let datatypeproperty of datatypeproperties) {
        let label: any = xpath.select(rdfs + RDFElement.label, datatypeproperty, true);
        let id = datatypeproperty.attributes[rdf + RDFElement.about].value;
        let text = "";
        if (label) {
            text = label.textContent;
        } else {
            if (id.indexOf("#") > -1) {
                text = id.substring(id.indexOf("#") + 1);
            } else {
                if (id.indexOf("/") > -1) {
                    text = id.substring(id.lastIndexOf("/") + 1);
                }
            }
        }
        datatypeProperties.push({ id: id, label: text, element: datatypeproperty });
    }
}
function getClasses(classes: IClass[], rdfelement: HTMLElement) {
    let classelements: any[] = xpath.select(owl + OWLElement.Class, rdfelement, false) as any[];
    for (let classelement of classelements) {
        let label: any = xpath.select(rdfs + RDFElement.label, classelement, true);
        let id = classelement.attributes[rdf + RDFElement.about].value;
        let text = "";
        if (label) {
            text = label.textContent;
        } else {
            if (id.indexOf("#") > -1) {
                text = id.substring(id.indexOf("#") + 1);
            } else {
                if (id.indexOf("/") > -1) {
                    text = id.substring(id.lastIndexOf("/") + 1);
                }
            }
        }
        classes.push({ id: id, label: text, element: classelement });
    }
}
function getInstances(instances: INamedIndividual[], rdfelement: HTMLElement) {
    let instanceelements: any[] = xpath.select(owl + OWLElement.NamedIndividual, rdfelement, false) as any[];
    for (let instanceelement of instanceelements) {
        let label: any = xpath.select(rdfs + RDFElement.label, instanceelement, true);
        let id = instanceelement.attributes[rdf + RDFElement.about].value;
        let text = "";
        if (label) {
            text = label.textContent;
        } else {
            if (id.indexOf("#") > -1) {
                text = id.substring(id.indexOf("#") + 1);
            } else {
                if (id.indexOf("/") > -1) {
                    text = id.substring(id.lastIndexOf("/") + 1);
                }
            }
        }
        instances.push({ id: id, label: text, element: instanceelement });
    }
}