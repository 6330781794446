import { accessCookie } from "../../application/semtalklistener/stgoto";
import { SemTalkCookie } from "../../ISemTalkCookie";

export function patchSVGFont(svg: any): string {
    let ff0 = 'Helvetica, Arial, sans-serif';
    let ff = accessCookie(SemTalkCookie.font);
    if (ff === 'undefined') ff = ff0;
    if (!ff) ff = ff0;
    return svg.svg.replace(/;font-family:" segoe="" ui="" westeuropean",="" "segoe="" ui",="" -apple-system,="" blinkmacsystemfont,="" roboto,="" "helvetica="" neue",="" sans-serif"=""/gi, ";font-family:'" + ff + "'");
  } 
  
export async function loadImage (svg: any, fileType: string) {
    return new Promise((resolve, reject) => {
      let svgfix: string = patchSVGFont(svg);
      let svg64: string = encodeURIComponent(svgfix);
      let b64Start: string = 'data:image/svg+xml;utf8,';
      let image64: string = b64Start + svg64;
      const img = new Image();
      img.src = image64;
      img.onload = () => {
        let canvas = document.createElement('canvas');
        let svgWidth: number = svg.width;
        let svgHeight: number = svg.height;
        canvas.width = svgWidth;
        canvas.height = svgHeight;
        let context = canvas.getContext('2d');
        if (context != null) {
          context.drawImage(img, 0, 0, svgWidth, svgHeight);
        }
        resolve(canvas.toDataURL(fileType));
      };
      img.onerror = e => {
        reject(e);
      };
    });
  }