import { ISemTalkOnline } from './ISemTalkOnline';
import { IVisioRDFS } from './application/semtalklistener/visiordfsinterface';
import { patchSVGFont } from './controls/stwordexport/loadimage';
// import { converBase64toBlob } from './utils';
import { jsPDF } from "jspdf";
import { ensureLabelWidth } from './styles';
import { ISemTalkDiagram } from './application/tbase/Interface';


export default async function ExportPDF(pages: ISemTalkDiagram[], callback: ISemTalkOnline, sem: IVisioRDFS,
  filename: string, format: string, landscape: boolean,
  printoverlays: boolean, printcontrols: boolean,
  printborder: number, printscale: number) {
  // let b64Start = 'data:image/svg+xml;base64,';
  let b64Start: string = 'data:image/svg+xml;utf8,';
  let svg: any;
  let orientation: "portrait" | "p" | "l" | "landscape" | undefined = "portrait";
  if (landscape) {
    orientation = "landscape";
  }
  let scale = 0.5;

  let doc = new jsPDF(orientation, 'pt', format, false);
  // let cap = "Hello world!";

  let pgcnt = pages.length;

  const loadImage = async (imgsrc: any) => {
    return new Promise((resolve, _reject) => {
      let img = new Image();
      img.onload = async () => {
        if (svg) {
          let canvas = document.createElement('canvas');
          let svgWidth: number = svg.width;
          let svgHeight: number = svg.height;
          canvas.width = svgWidth;
          canvas.height = svgHeight;
          let context = canvas.getContext('2d');
          if (context != null) {
            context.drawImage(img, 0, 0, svgWidth, svgHeight);
          }
          let imgPNG = canvas.toDataURL();
          let dx = 20;
          let dy = 20;
          // doc.text(cap, 0, 0);
          try {
            doc.addImage(imgPNG, dx, dy, svgWidth * scale, svgHeight * scale);
          } catch (e) {
            console.debug(e);
          }
          pgcnt -= 1;
          if (pgcnt > 0) {
            doc.addPage(format, orientation);
          }
          // dy += svgHeight + 20;
        }
        resolve(true);
      };
      img.src = imgsrc;
    });
  };
  for (let d of pages) {
    await callback.loadPage(d.ID, sem, false);
    // cap = d.ObjectCaption;
    ensureLabelWidth(sem.graph as mxGraph);
    svg = sem.tseditor.exportSVGforPrint(sem.graph as mxGraph,
      printoverlays, printcontrols, printborder, printscale);
    let svgfix: string = patchSVGFont(svg);
    let svg64: string = encodeURIComponent(svgfix);
    let image64: string = b64Start + svg64;
    await loadImage(image64);
  }
  doc.save(filename);
}