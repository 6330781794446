import { SemTalkStyleAttribute } from "./application/semtalklistener/visiordfsinterface";

export function setStyleAttribute(s: string, aname: string, val: string): string {
  let laname = aname.toLowerCase();
  let s2 = "";
  let sl = s.split(";");
  let fnd: boolean = false;
  for (let kv of sl) {
    if (kv.length > 0) {
      if (kv.indexOf("=") < 0) {
        s2 = s2 + kv + ";";
      } else {
        let p = kv.split("=");
        let k = p[0];
        if (k.toLowerCase() === laname) {
          if (val.length > 0) {
            s2 = s2 + k + "=" + val + ";";
          }
          fnd = true;
        } else {
          s2 = s2 + kv + ";";
        }
      }
    }
  }
  if (!fnd && val.length > 0) {
    s2 = s2 + aname + "=" + val + ";";
  }
  return s2;
}
export function getStyleAttribute(s: string, aname: string): string {
  let laname = aname.toLowerCase();
  let sl = s.split(";");
  for (let kv of sl) {
    if (kv.length > 0) {
      if (kv.indexOf("=") < 0) {
      } else {
        let p = kv.split("=");
        let k = p[0];
        if (k.toLowerCase() === laname) {
          return kv.substring(kv.indexOf("=") + 1);
        }
      }
    }
  }
  return "";
}

export function copyColorStyle(src: mxCell, trg: mxCell) {
  let s0: string = src.style;
  if (s0.indexOf(";" + SemTalkStyleAttribute.custom + "=1") > 0) {

    let s: string = trg.style;
    let fillcolor = getStyleAttribute(s0, SemTalkStyleAttribute.fillColor);
    let fontcolor = getStyleAttribute(s0, SemTalkStyleAttribute.fontColor);
    let strokecolor = getStyleAttribute(s0, SemTalkStyleAttribute.strokeColor);
    let gradientcolor = getStyleAttribute(s0, SemTalkStyleAttribute.gradientColor);
    let dirty = false;
    if (fillcolor !== '') {
      s = setStyleAttribute(s, SemTalkStyleAttribute.fillColor, fillcolor);
      dirty = true;
    }
    if (gradientcolor !== '') {
      s = setStyleAttribute(s, SemTalkStyleAttribute.gradientColor, gradientcolor);
      dirty = true;
    }
    if (fontcolor !== '') {
      s = setStyleAttribute(s, SemTalkStyleAttribute.fontColor, fontcolor);
      dirty = true;
    }
    if (strokecolor !== '') {
      s = setStyleAttribute(s, SemTalkStyleAttribute.strokeColor, strokecolor);
      dirty = true;
    }
    if (dirty) {
      s = s + SemTalkStyleAttribute.custom + "=1;";
      trg.style = s;
    }
  }
}

export function ensureLabelWidth(graph: mxGraph) {
  let cells = graph.model.cells;
  for (const i in cells) {
    const cell = cells[i];
    let style = cell.style;
    if (style && style.indexOf(SemTalkStyleAttribute.verticalLabelPosition) > -1) {
      let lw = getStyleAttribute(style, SemTalkStyleAttribute.verticalLabelPosition);
      if (lw.length > 0) {
        let attr = SemTalkStyleAttribute.labelWidth;
        if (style.indexOf(attr) < 0) {
          let width = cell.geometry.width;
          let val = width * 2;
          style = setStyleAttribute(style, attr, String(val));
          cell.style = style;
          graph.refresh(cell);
        }
      }
    }
  }
}