
/* tslint:disable:noImplicitAny */
/* eslint:disable:noImplicitAny */

import {
  mxUtils,
  mxRectangle,
  mxCellRenderer,
  mxCylinder,
} from "mxgraph-js";// Document shape
import { SemTalkMaster } from "../../SemTalkMaster";

// mxCellRenderer.registerShape("step", mxStep);

// DataStore Shape, supports size style
function DataStoreShape(this: any) {
  mxCylinder.call(this);
}
mxUtils.extend(DataStoreShape, mxCylinder);

DataStoreShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any, isForeground: any) {
  // console.debug("datastorage");
  const dy = Math.min(h / 2, Math.round(h / 8) + this.strokewidth - 1);

  if ((isForeground && this.fill != null) || (!isForeground && this.fill == null)) {
    c.moveTo(0, dy);
    c.curveTo(0, 2 * dy, w, 2 * dy, w, dy);

    // Needs separate shapes for correct hit-detection
    if (!isForeground) {
      c.stroke();
      c.begin();
    }

    c.translate(0, dy / 2);
    c.moveTo(0, dy);
    c.curveTo(0, 2 * dy, w, 2 * dy, w, dy);

    // Needs separate shapes for correct hit-detection
    if (!isForeground) {
      c.stroke();
      c.begin();
    }

    c.translate(0, dy / 2);
    c.moveTo(0, dy);
    c.curveTo(0, 2 * dy, w, 2 * dy, w, dy);

    // Needs separate shapes for correct hit-detection
    if (!isForeground) {
      c.stroke();
      c.begin();
    }

    c.translate(0, -dy);
  }

  if (!isForeground) {
    c.moveTo(0, dy);
    c.curveTo(0, -dy / 3, w, -dy / 3, w, dy);
    c.lineTo(w, h - dy);
    c.curveTo(w, h + dy / 3, 0, h + dy / 3, 0, h - dy);
    c.close();
  }
};
DataStoreShape.prototype.getLabelMargins = function (rect: any) {
  return new mxRectangle(0, 2.5 * Math.min(rect.height / 2, Math.round(rect.height / 8) +
    this.strokewidth - 1) * this.scale, 0, 0);
};

mxCellRenderer.registerShape(SemTalkMaster.MasterDataStore, DataStoreShape);
