import { IVisioRDFS } from '../../application/semtalklistener/visiordfsinterface';
import { ISemTalkObject, SemTalkID } from '../../application/tbase/Interface';
import { ensureLabelWidth } from '../../styles';
import { loadImage } from './loadimage';
import { MakeUnixValue } from './utils';


export default async function ExportHTML (semtalk: IVisioRDFS) {
    let doc = document.implementation.createHTMLDocument('SemTalkOnline Export');
    let curDiag: any = semtalk.page?.ID;
    let base = semtalk.base;
    // let curDiagName: any = this.res.page?.ID2Name;
    let modtitel: string = base.ObjectName.replace(".sdx", "");
    if (modtitel === "" || modtitel === undefined || modtitel === null) {
      modtitel = "SemTalkOnline-WordExport";
    }

    for (let d of base.AllDiagrams()) {
      let diagTitle = doc.createElement("h1");
      let table = doc.createElement("table");
      let tablerow = doc.createElement("tr");
      let tableheader = doc.createElement("th");
      tableheader.innerText = "Nr.";
      tablerow.appendChild(tableheader);
      tableheader = doc.createElement("th");
      tableheader.innerText = "Name";
      tablerow.appendChild(tableheader);
      tableheader = doc.createElement("th");
      tableheader.innerText = "Type";
      tablerow.appendChild(tableheader);
      tableheader = doc.createElement("th");
      tableheader.innerText = "HumanResource";
      tablerow.appendChild(tableheader);
      tableheader = doc.createElement("th");
      tableheader.innerText = "Comment";
      tablerow.appendChild(tableheader);
      tableheader = doc.createElement("th");
      tableheader.innerText = "Hyperlinks";
      tablerow.appendChild(tableheader);
      table.appendChild(tablerow);
      diagTitle.innerText = d.ID2Name;

      await semtalk.tseditor.gotoPage(d.ID);
      let count: number = 1;
      let vispg = Object.values(semtalk.tseditor.getAllCells());
      for (let s of vispg) {
        if (s.shapeKey !== undefined || s.objectid !== undefined) {
          let iid: SemTalkID= s.objectid;
          let s1: ISemTalkObject | null = semtalk.base.FindObjectByID(iid);
          if (s1 !== null && !s.id.includes("edge")) {
            let curObjClassStr: string = "";
            let curObjClass: any = s1.SystemClass();
            if (curObjClass !== null) {
              curObjClassStr = curObjClass.ID2Name;
            }
            if (curObjClassStr.toLowerCase() !== 'sequence flow' && curObjClassStr.toLowerCase() !== 'message flow' && curObjClassStr.toLowerCase() !== 'dataobjectof_') {
              let curObjName: string = "";
              if (s1.ObjectCaption === "Swimlane" || s1.ObjectCaption === "Data Object") {
                curObjName = s.value;
              } else {
                curObjName = s1.ObjectCaption;
              }

              let attStr: string = "";
              for (let att of s1.Attachments()) {
                if (att !== null) {
                  attStr += att.ToObject.ObjectCaption + "";
                }
              }
              let perStr: string = "";
              for (let per of s1.Associations()) {
                let to: any = per.ToObject;
                if (per.ObjectCaption === "uses hum.resource") {
                  perStr += to.ObjectCaption + "; ";
                }
              }
              let tablerow1 = doc.createElement("tr");
              let tablecontent = doc.createElement("td");
              tablecontent.innerText = String(count) + ".";
              tablerow1.appendChild(tablecontent);
              tablecontent = doc.createElement("td");
              tablecontent.innerText = curObjName;
              tablerow1.appendChild(tablecontent);
              tablecontent = doc.createElement("td");
              tablecontent.innerText = curObjClassStr;
              tablerow1.appendChild(tablecontent);
              tablecontent = doc.createElement("td");
              tablecontent.innerText = perStr;
              tablerow1.appendChild(tablecontent);
              tablecontent = doc.createElement("td");
              tablecontent.innerText = s1.Comment;
              tablerow1.appendChild(tablecontent);
              tablecontent = doc.createElement("td");
              tablecontent.innerText = attStr;
              tablerow1.appendChild(tablecontent);
              table.appendChild(tablerow1);
              count += 1;
            }
          }
        }
      }
      doc.body.appendChild(diagTitle);
      let docHeader = doc.createElement('h2');
      docHeader.innerText = "Diagram view";
      doc.body.appendChild(docHeader);
      let imagediv = document.createElement("div");
      let image = document.createElement("img");
      ensureLabelWidth(semtalk.graph as mxGraph);
      let svgJson: any = semtalk.tseditor.exportSVGforPrint(semtalk.graph as mxGraph, false, false, 0, 1);
      const img: any = await loadImage(svgJson, 'image/png');
      image.setAttribute("src", img);
      imagediv.appendChild(image);
      doc.body.appendChild(imagediv);

      docHeader = doc.createElement('h2');
      docHeader.innerText = "Diagram content";
      doc.body.appendChild(docHeader);
      doc.body.appendChild(table);
    }
    const dllink: any = document.createElement('a');
    dllink.href = "data:text/html;charset=UTF-8," + encodeURIComponent(doc.documentElement.outerHTML);
    dllink.setAttribute('download', MakeUnixValue(modtitel) + ".html",);
    document.body.appendChild(dllink);
    dllink.click();
    dllink.parentNode.removeChild(dllink);
    await semtalk.tseditor.gotoPage(curDiag);
  }