import { Process_ElementName } from "../semtalklistener/processInterface";
import { SemTalkUIConstant } from "../semtalklistener/visiordfsinterface";
import {
  IObjectBase,
  ISemTalkClass,
  // SemTalkValueType,
  // ISemTalkAssociation,
  // ISemTalkAssociationType, ISemTalkDiagram, ISemTalkDiagramType,
  ISemTalkInstance,
  ISemTalkObject,
  SemTalkBaseConstant,
  // ISemTalkObject, ISemTalkSystemClass, SemTalkAttachment, SemTalkBaseConstant, SemTalkRelation
} from "./Interface";
export function exportJSONLD(base: IObjectBase, namespace: string) {
  if (!namespace.endsWith("#")) namespace += "#";

  let bl: string[] = [];
  bl.push(SemTalkBaseConstant.SLSubClassOf);
  bl.push(SemTalkBaseConstant.SLLanguage);
  bl.push(SemTalkBaseConstant.SLInstance);
  bl.push(SemTalkBaseConstant.SLComment);
  bl.push(SemTalkBaseConstant.SLThing);
  bl.push(SemTalkUIConstant.SLHidden);
  bl.push(SemTalkUIConstant.SemTalkUserTab);
  bl.push(Process_ElementName.SLDistribution);
  bl.push(Process_ElementName.SLBreakpoint);
  bl.push("ISO");
  bl.push("mxg");
  bl.push("HorizontalSwimlane");
  bl.push("SwimlaneWidth");
  bl.push("SwimlaneHeight");
  let cof = base.FindAssociationType(SemTalkBaseConstant.SLConsistsOf);
  if (cof && cof.Instances().length === 1) {
    bl.push(SemTalkBaseConstant.SLConsistsOf);
  }

  let bl0: string[] = [];
  bl0.push(SemTalkBaseConstant.SLSubClassOf);
  bl0.push(SemTalkBaseConstant.SLLanguage);
  bl0.push(SemTalkUIConstant.SLHidden);
  bl0.push(SemTalkBaseConstant.SLComment);
  // bl0.push(SemTalkBaseConstant.SLThing);
  bl0.push(SemTalkUIConstant.SLHidden);
  bl0.push(SemTalkUIConstant.SemTalkUserTab);
  bl0.push(Process_ElementName.SLDistribution);
  bl0.push(Process_ElementName.SLBreakpoint);

  function getid(o: ISemTalkObject): string {
    let id = o.ID.toString();
    if (id.length < 10) {
      id = "X000000000" + id;
    }
    return id;
  }

  let contextobj: any = {
    "rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
    "rdfs": "http://www.w3.org/2000/01/rdf-schema#",
    "xsd": "http://www.w3.org/2001/XMLSchema#",
    "schema": "http://schema.org/",
    "name": "http://schema.org/name",
    "my": namespace
  };
  for (let a of base.AllAttributeTypes()) {
    let aname = a.ObjectName;
    if (bl.indexOf(aname) > -1) continue;
    contextobj[a.ObjectName] = "my:" + a.ObjectName;
  }
  for (let a of base.AllAssociationTypes()) {
    let aname = a.ObjectName;
    if (bl.indexOf(aname) > -1) continue;
    contextobj[a.ObjectName] = {
      "id": "my:" + a.ObjectName,
      "@type": "@id"
    };
  }
  let context: any = { "@context": contextobj };
  let elements: any[] = [];
  // for (let node of diag.Contents()) {
  //   let obj = node.Model;

  //   if (base.IsInstance(obj) && !base.IsAssociation(obj)) {

  for (let obj of base.AllClasses()) {
    let aname = obj.ObjectName;
    if (bl.indexOf(aname) > -1) continue;
    let e: any = {
      // "@id": "my:" + obj.ObjectName,
      "@id": getid(obj),
      "name": obj.ObjectName,
      "@type": "rdfs:Class",
      "rdfs:label": obj.ObjectCaption,
      // "rdfs:subClassOf": (obj as ISemTalkClass).SuperClasses().map(x => { return { "@id": "my:" + x.ObjectName }; })
      "rdfs:subClassOf": (obj as ISemTalkClass).SuperClasses().map(x => { return { "@id": x.ID }; })
    };
    if (obj.Comment) {
      e["rdfs:comment"] = obj.Comment;
    }
    for (let a of (obj.Attributes())) {
      let aname0 = a.ClassOf().ObjectName;
      if (bl.indexOf(aname0) > -1) continue;
      e[aname0] = a.Value;
    }
    for (let a of (obj.Associations())) {
      let aname0 = a.ClassOf().ObjectName;
      if (bl.indexOf(aname0) > -1) continue;
      e[aname0] = {
        "@id": getid(a.ToObject),
        "@type": "@id"
      };
    }
    elements.push(e);
  }
  for (let obj of base.AllInstances()) {
    const cname = obj.ClassOf().ObjectName;
    if (bl0.indexOf(cname) > -1) continue;
    let e: any = {
      // "@id": "my:" + obj.ObjectName,
      "@id": getid(obj),
      "name": obj.ObjectName,
      // "@type": "my:" + (obj as ISemTalkInstance).ClassOf().ObjectName,
      "@type": getid((obj as ISemTalkInstance).ClassOf()),
      "rdfs:label": obj.ObjectCaption,
    };
    if (obj.Comment) {
      e["rdfs:comment"] = obj.Comment;
    }
    for (let a of (obj.Attributes())) {
      let aname = a.ClassOf().ObjectName;
      if (bl.indexOf(aname) > -1) continue;
      e[aname] = a.Value;
    }
    for (let a of (obj.Associations())) {
      let aname = a.ClassOf().ObjectName;
      if (bl.indexOf(aname) > -1) continue;
      e[aname] = {
        "@id": getid(a.ToObject),
        "@type": "@id"
      };
    }
    elements.push(e);
  }

  let graph = { "@graph": elements };
  return { ...context, ...graph };
}
