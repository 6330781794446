// import { MessageBarType } from "office-ui-fabric-react";
import { ISemTalkOnline } from "./ISemTalkOnline";
import { IMongoOption, mgGetCollections, mgGetItems, mgInsertItems, mgSaveItem } from "./SemTalkMongoServer";
import { ISPExplorer } from "./application/explorer/spexplorerinterface";
import { Process_ElementName } from "./application/semtalklistener/processInterface";
import { IVisioRDFS } from "./application/semtalklistener/visiordfsinterface";
import { IObjectBase, ISemTalkAttributeType, ISemTalkBusinessClass, ISemTalkClass, ISemTalkInstance, ISemTalkMethodType, ISemTalkObject, ISemTalkStateType, SemTalkBaseConstant, SemTalkType } from "./application/tbase/Interface";


export async function updateRepository(ob: IObjectBase, mongo: IMongoOption,
    sharepointrepository: string | undefined,
    callback: ISemTalkOnline,
    semtalk: IVisioRDFS,
    graphClient: any,
    collectionnames: string[]) {

    let ismongo: boolean = (mongo && mongo.usemongo && mongo.semuserlogin);
    let issharepoint: boolean = (!ismongo && graphClient);
    let spinterface = semtalk.explorer;

    if (collectionnames.length === 0) {
        if (issharepoint && sharepointrepository) {
            let query = sharepointrepository + "lists?hidden=false&template=genericList";
            let collections = await spinterface.fetchGraphItems(graphClient, query);
            collections = collections.filter(x => (x.list.hidden === false && x.list.template === "genericList"));
            collectionnames = collections.map(x => x["name"]);
        }
        if (ismongo) {
            let collections = await mgGetCollections(mongo, mongo.repository);
            collectionnames = collections.map(x => x["name"]);
        }
    }
    let root: ISemTalkClass | null = null;
    for (let collectionname of collectionnames) {
        let prefix = "";
        let items: any[] = [];
        if (ismongo) {
            items = await mgGetItems(mongo, mongo.repository, collectionname);
        }
        if (issharepoint) {
            let query = sharepointrepository + "lists/" + collectionname + "/items?expand=fields";
            items = await spinterface.fetchGraphItems(graphClient, query);
            items = items.map(x => x.fields);
        }

        let newitems: any[] = [];
        let objlist: ISemTalkObject[] = [];
        let isclass: boolean = false;
        let isinst: boolean = false;
        let isbusinessclass: boolean = false;
        let otype: SemTalkType = SemTalkType.SemTalkInstance;
        switch (collectionname) {
            case "Objects": {
                objlist = ob.AllClasses().filter(x => ob.IsBusinessClass(x));
                prefix = "Ob#";
                otype = SemTalkType.SemTalkClass;
                isbusinessclass = true;
                isclass = true;
                break;
            }
            case "Method": {
                objlist = ob.AllMethodTypes();
                objlist = objlist.filter(x => !(x as ISemTalkMethodType).IsReadOnly);
                otype = SemTalkType.SemTalkMethodType;
                break;
            }
            case "State": {
                objlist = ob.AllStateTypes();
                objlist = objlist.filter(x => !(x as ISemTalkStateType).IsReadOnly);
                otype = SemTalkType.SemTalkStateType;
                break;
            }
            case "Attribute": {
                objlist = ob.AllAttributeTypes();
                objlist = objlist.filter(x => !(x as ISemTalkAttributeType).IsReadOnly);
                otype = SemTalkType.SemTalkAttributeType;
                break;
            }
            case "Buffers": {
                root = ob.FindSystemClass(ob.GetModelAttribute(Process_ElementName.SLBuffer));
                otype = SemTalkType.SemTalkInstance;
                isinst = true;
                if (root) {
                    objlist = root.AllInstances();
                }
                break;
            }
            case "PhysResources": {
                root = ob.FindSystemClass(ob.GetModelAttribute(Process_ElementName.SLPhysResource));
                otype = SemTalkType.SemTalkInstance;
                isinst = true;
                if (root) {
                    objlist = root.AllInstances();
                }
                break;
            }
            case "HumanResources": {
                root = ob.FindSystemClass(ob.GetModelAttribute(Process_ElementName.SLResource));
                otype = SemTalkType.SemTalkInstance;
                isinst = true;
                if (root) {
                    objlist = root.AllInstances();
                }
                break;
            }
            default: {
                root = ob.FindClass(collectionname);
                if (root) {
                    isinst = true;
                    objlist = root.AllInstances();
                }
            }
        }
        callback.SetProgressIndicator(collectionname + " Repository: " + items.length + " Document: " + objlist.length);
        let slang = ob.FindClass(SemTalkBaseConstant.SLLanguage);
        for (let obj of objlist) {
            let exists = (items as any[]).find(x => x["_id"] === obj.ID);
            if (!exists) {
                exists = (items as any[]).find(x => x["name"] === obj.ID2Name);
            }
            if (!exists && issharepoint) {
                exists = (items as any[]).find(x => x["Title"] === obj.ID2Name);
            }
            if (!exists) {
                if (ismongo) {
                    let item = { "name": obj.ID2Name };
                    await addBody(obj, item, ob, slang, isinst, isclass, isbusinessclass, issharepoint,
                        sharepointrepository, spinterface, graphClient, collectionname, root);
                    newitems.push(item);
                }
                if (issharepoint) {
                    let item = { "Title": obj.ID2Name };
                    await addBody(obj, item, ob, slang, isinst, isclass, isbusinessclass, issharepoint,
                        sharepointrepository, spinterface, graphClient, collectionname, root);
                    newitems.push(item);
                }
            }
        }
        if (newitems.length > 0) {
            if (ismongo) {
                await mgInsertItems(mongo, mongo.repository, collectionname, newitems);
                items = await mgGetItems(mongo, mongo.repository, collectionname);
            }
            if (issharepoint) {
                let query = sharepointrepository + "lists/" + collectionname + "/items";
                for (const item of newitems) {
                    // Achtung await wartet nicht..
                    await spinterface.postGraphItem(graphClient, query, { "fields": item }, null);
                }
                query = sharepointrepository + "lists/" + collectionname + "/items?expand=fields";
                items = await spinterface.fetchGraphItems(graphClient, query);
                items = items.map(x => x.fields);
            }
        }
        for (let item of items) {
            let obj: ISemTalkObject | null;
            let id = item["_id"];
            obj = ob.FindObjectByID(id);
            if (!obj) {
                obj = ob.FindObject(otype, item["name"]);
            }
            if (!obj) {
                obj = ob.FindBusinessClass(prefix + item["name"]);
            } else {
                item["name"] = obj.ID2Name;
            }
            if (obj) {
                await addBody(obj, item, ob, slang, isinst, isclass, isbusinessclass, issharepoint,
                    sharepointrepository, spinterface, graphClient, collectionname, root);

                let filter: any = { "name": item.name };
                if (item._id) {
                    filter = { "id": item._id };
                }
                if (ismongo) {
                     // delete item["_id"];
                    await mgSaveItem(mongo, mongo.repository, collectionname, item["name"], item, filter);
                }
                if (issharepoint) {
                    let id0 = item.ID;
                    let query = sharepointrepository + "lists/" + collectionname + "/items/" + id0;
                    let body: any = JSON.stringify(JSON.parse(item));
                    delete body["ID"];
                    spinterface.patchGraphItem(graphClient, query, body);
                }

            }
        }
    }
    callback.SetProgressIndicator("");
}
async function addBody(obj: ISemTalkObject, item: any, ob: IObjectBase,
    slang: ISemTalkClass | null,
    isinst: boolean, isclass: boolean, isbusinessclass: boolean,
    issharepoint: boolean,
    sharepointrepository: string | undefined,
    spinterface: ISPExplorer,
    graphClient: any,
    collectionname: string,
    root: ISemTalkClass | null) {
    if (obj.Comment) {
        item["Comment"] = obj.Comment;
    }
    if (isinst) {
        let classname = (obj as ISemTalkInstance).ClassOf().ObjectName;
        if (classname !== collectionname && (!root || classname !== root?.ObjectName)) {
            item["Class"] = classname;
        }
    }
    if (slang) {
        for (let lang of slang.Instances()) {
            let syn = obj.FindSynonym(lang.ObjectName);
            if (syn && syn.Name !== item["name"] && syn.Name !== item["Title"]) {
                item[lang.ObjectName] = syn.Name;
            }
        }
    }
    if (isinst) {
        let syscla = obj.SystemClass();
        if (syscla) {
            let classname = syscla.ObjectName;
            if (classname !== collectionname && (!root || classname !== root?.ObjectName)) {
                item["SystemClass"] = classname;
            }
            for (let attr of syscla.AllAttributes()) {
                if (attr.Group !== 'Hidden') {
                    let acla = attr.ClassOf();
                    let val = obj.GetValue(acla.ObjectName);
                    if (val) {
                        item[acla.ObjectName] = val;
                    }
                }
            }
        }
    }
    let att = obj.Attachments();
    if (att.length > 0) {
        item["URI"] = att[0].ToObject.ObjectName;
    }

    if (isbusinessclass && ob.IsBusinessClass(obj)) {
        let bobj = obj as ISemTalkBusinessClass;
        let meth: string[] = item["Method"];
        if (!meth) meth = [];
        for (let m of bobj.Methods()) {
            let claname = m.ClassOf().ObjectName;
            if (meth.indexOf(claname) < 0) {
                meth.push(claname);
            }
        }
        if (meth.length > 0) {
            if (issharepoint && sharepointrepository) {
                let lookupids = await getlookupids(meth, "Method", spinterface, sharepointrepository, graphClient);
                item["MethodLookupId@odata.type"] = "Collection(Edm.String)";
                item["MethodLookupId"] = lookupids;
            } else {
                item["Method"] = meth;
            }
        }

        let sta: string[] = item["States"];
        if (!sta) sta = [];
        for (let m of bobj.States()) {
            let claname = m.ClassOf().ObjectName;
            if (sta.indexOf(claname) < 0) {
                sta.push(claname);
            }
        }
        if (sta.length > 0) {
            if (issharepoint && sharepointrepository) {
                let lookupids = await getlookupids(sta, "State", spinterface, sharepointrepository, graphClient);
                item["StatesLookupId@odata.type"] = "Collection(Edm.String)";
                item["StatesLookupId"] = lookupids;
            } else {
                item["States"] = sta;
            }
        }
    }

    if (isclass && ob.IsClass(obj)) {
        let bobj = obj as ISemTalkClass;

        let atr: string[] = item["Attribute"];
        if (!atr) atr = [];
        for (let m of bobj.Attributes()) {
            let claname = m.ClassOf().ObjectName;
            if (atr.indexOf(claname) < 0) {
                atr.push(claname);
            }
        }
        if (atr.length > 0)
            if (issharepoint && sharepointrepository) {
                let lookupids = await getlookupids(atr, "AttributeType", spinterface, sharepointrepository, graphClient);
                item["AttributeLookupId@odata.type"] = "Collection(Edm.String)";
                item["AttributeLookupId"] = lookupids;
            } else {
                item["Attribute"] = atr;
            }

        let sup: string[] = item["SuperClass"];
        if (!sup) sup = [];
        for (let m of bobj.SuperClasses()) {
            let claname = m.ObjectName;
            if (sup.indexOf(claname) < 0) {
                sup.push(claname);
            }
        }
        if (sup.length > 0)
            if (issharepoint && sharepointrepository) {
                let lookupids = await getlookupids(sup, "Objects", spinterface, sharepointrepository, graphClient);
                item["SuperClassLookupId@odata.type"] = "Collection(Edm.String)";
                item["SuperClassLookupId"] = lookupids;
            } else {
                item["SuperClass"] = sup;
            }
    }
}
async function getlookupids(names: string[], collection: string,
    spinterface: ISPExplorer, sharepointrepository: string, graphClient: any): Promise<string[]> {
    let ids: string[] = [];
    for (let name of names) {
        let query = sharepointrepository + "lists/" + collection + "/items?$expand=fields&filter=fields/Title eq '" + name + "'";
        let items = await spinterface.fetchGraphItem(graphClient, query);
        if (items && items.length > 0) {
            let item = items[0];
            if (item && item["fields"]) {
                item = item["fields"]; // SharePoint listitem
                ids.push(item["id"]);
            }
        }
    }
    return ids;
}
