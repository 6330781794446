/* tslint:disable:no-function-expression */

import {
	mxUtils,
	// mxRectangle,
	mxCellRenderer,
	mxActor,
	// mxShape,
} from "mxgraph-js";// Document shape
import { SemTalkBuiltInShape, SemTalkStyleAttribute } from "../visiordfsinterface";
// import { SemTalkCookie } from "../../../ISemTalkCookie";
// import { accessCookie } from "../stgoto";



function GenericClassShape(this: any) {
	mxActor.call(this);
	this.isRounded = true;
	this.strokewidth = 2;
	// this.strokeOpacity = 100;
}
mxUtils.extend(GenericClassShape, mxActor);

GenericClassShape.prototype.paintVertexShape = function (c: any, x: any, y: any, w: any, h: any) {
	let strokeColor = mxUtils.getValue(this.style, SemTalkStyleAttribute.strokeColor, '#000000');
	let strokeWidth = mxUtils.getValue(this.style, SemTalkStyleAttribute.strokeWidth, 2);
	let fillColor = mxUtils.getValue(this.style, SemTalkStyleAttribute.fillColor, '#ffffff');
	let isuml = mxUtils.getValue(this.style, SemTalkStyleAttribute.isuml, 1);

	c.setStrokeColor(strokeColor);
	// c.setStrokeColor('#000000');
	c.setStrokeWidth(strokeWidth);
	c.setFillColor(fillColor);
	let isgradient = false;
	if (this["style"][SemTalkStyleAttribute.gradientColor]) {
		isgradient = (this["style"][SemTalkStyleAttribute.gradientColor] !== '#ffffff');
	}
	if (isgradient) {
		let gradientColor = mxUtils.getValue(this.style, SemTalkStyleAttribute.gradientColor, '#ffffff');
		c.setGradient(fillColor, gradientColor);
	}

	c.translate(x, y);

	if (isuml !== 0) {
		// let rSize = 5;
		let rSize = 0;
		c.roundrect(0, 0, w, h, rSize, rSize);
		c.fillAndStroke();
		c.setShadow(false);
		this.redrawPath(c, x, y, w, h);
	} else {
		c.ellipse(0, 0, w, h);
		c.fillAndStroke();
	}

};

GenericClassShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
	let cell = this.state.cell;
	// let font: string = "Helvetica, Arial, sans-serif";
	// let fo = accessCookie(SemTalkCookie.font);
	// if (fo) font = fo;
	c.begin();
	c.setFontSize("10");
	c.setStrokeWidth(1);
	// let h1 = 10;
	// let size = mxUtils.getSizeForString("X", 10, font, w);
	let size = { x: 0, y: 0, width: 100, height: 12 };
	c.translate(0, h);
	if (cell && cell["attributes"]) {
		if (cell["hideattributes"] !== 1) {
			let attributes: string[] = cell["attributes"];
			let ms = attributes.join("\n");
			let h2 = 10 + size.height * attributes.length;
			c.translate(0, 0 - h2);
			c.text(5, 5, 0, 0, ms, 'left', 'top');
			c.rect(0, 0, w, h2);
			c.stroke();
		} else {
			let h2 = 10;
			c.translate(0, 0 - h2);
			c.rect(0, 0, w, h2);
			c.stroke();

		}
	}
};

mxCellRenderer.registerShape(SemTalkBuiltInShape.genericclass, GenericClassShape);
// console.debug("EpcPosition Shape registered");
