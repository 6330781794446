import { Customizations, createTheme } from "office-ui-fabric-react";
import { SemTalkCookie } from "./ISemTalkCookie";
import { accessCookie, setCookie } from "./application/semtalklistener/stgoto";

export function applyTheme() {
    let ff = accessCookie(SemTalkCookie.guifont);
    let theme: any;
    // if (ff === 'undefined') ff = defaultFont;
    if (!ff) ff = "Helvetica, Arial, sans-serif";
  
    let th = accessCookie(SemTalkCookie.themeStyle);
    let ic = accessCookie(SemTalkCookie.iconcolor);
    let icj: any = undefined;
    if (ic !== "undefined" && ic !== null && ic !== "") {
      switch (ic) {
        case "Black": {
          icj = {
            themePrimary: "#000000",
            themeDarkAlt: "#616161",
            themeDark: "#616161",
            themeDarker: "#8c8c8c",
          };
          break;
        }
        case "Green": {
          icj = {
            themePrimary: "#00c21d",
            themeDarkAlt: "#009316",
            themeDark: "#009316",
            themeDarker: "#006d10",
          };
          break;
        }
        case "Red": {
          icj = {
            themePrimary: "#e00004",
            themeDarkAlt: "#ab0003",
            themeDark: "#ab0003",
            themeDarker: "#7e0002",
          };
          break;
        }
        case "Blue": {
          icj = {
            themePrimary: "#0078d4",
            themeDarkAlt: "#005ba1",
            themeDark: "#005ba1",
            themeDarker: "#004377",
          };
          break;
        }
        case "Orange": {
          icj = {
            themePrimary: "#f09c00",
            themeDarkAlt: "#b67600",
            themeDark: "#b67600",
            themeDarker: "#865700",
          };
          break;
        }
        case "White": {
          icj = {
            themePrimary: "#ffffff",
            themeDarkAlt: "#f4f4f4",
            themeDark: "#f4f4f4",
            themeDarker: "#f8f8f8",
          };
          break;
        }
        default: {
          break;
        }
      }
    }
    if (th !== undefined && th !== null && th !== "") {
      let themeTemplate = JSON.parse(th);
      if (
        themeTemplate.themePrimary !== undefined &&
        themeTemplate.themePrimary !== null
      ) {
        if (icj !== undefined) {
          themeTemplate.themePrimary = icj["themePrimary"];
          themeTemplate.themeDark = icj["themeDark"];
          themeTemplate.themeDarker = icj["themeDarker"];
          themeTemplate.themeDarkAlt = icj["themeDarkAlt"];
        }
      }
      if (themeTemplate.white !== undefined && themeTemplate.white !== null) {
        let bg = themeTemplate.white;
        if (bg !== "undefined" && bg !== null && bg !== "") {
          setCookie(SemTalkCookie.backgroundColor, bg);
        }
      }
      if (themeTemplate !== "undefined" && themeTemplate !== null) {
        theme = createTheme({
          defaultFontStyle: { fontFamily: ff },
          palette: themeTemplate,
        });
      } else {
        if (icj !== undefined) {
          theme = createTheme({
            defaultFontStyle: { fontFamily: ff },
            palette: {
              themePrimary: icj["themePrimary"],
              themeDark: icj["themeDark"],
              themeDarker: icj["themeDarker"],
              themeDarkAlt: icj["themeDarkAlt"],
            },
          });
        } else {
          theme = createTheme({
            defaultFontStyle: { fontFamily: ff },
          });
        }
      }
    } else {
      if (icj !== undefined) {
        theme = createTheme({
          defaultFontStyle: { fontFamily: ff },
          palette: {
            themePrimary: icj["themePrimary"],
            themeDark: icj["themeDark"],
            themeDarker: icj["themeDarker"],
            themeDarkAlt: icj["themeDarkAlt"],
          },
        });
      } else {
        theme = createTheme({
          defaultFontStyle: { fontFamily: ff },
        });
      }
    }
    // if (ff !== ff0) {
    Customizations.applySettings({ theme });
    // }
  }