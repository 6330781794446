import { deflate, inflateSync } from "zlib";
import { IMongoOption, mgGetValue, mgSetValue } from "./SemTalkMongoServer";

export async function zipdoc(mongo: IMongoOption, lib: string, filename: string, body: any) {
  setTimeout(() => {
    const b = Buffer.from(JSON.stringify(body), 'utf8');
    deflate(b, (_err, buf) => {
      const si = buf.toString('base64');
      mgSetValue(mongo, mongo.dbname, lib, filename, "zip", si);
    });
  });
}

export async function unzipdoc(mongo: IMongoOption, lib: string, filename: string) {
  let zip = await mgGetValue(mongo, mongo.dbname, lib, filename, "zip");
  if (zip) {
    let doc = await unzipValue(zip);
    return doc;
  }
  return null;
}

export async function getzipdoc(mongo: IMongoOption, lib: string, filename: string) {
  let zip = await mgGetValue(mongo, mongo.dbname, lib, filename, "zip");
  if (zip) {
    return zip;
  }
  return null;
}

export async function unzipValue(body: any) {

  const b = Buffer.from(body, 'base64');
  const s = inflateSync(b).toString("utf8");
  if (s) {
    return JSON.parse(s);
  } else {
    return null;
  }

}

