import { Process_ElementName } from "../semtalklistener/processInterface";
import { SemTalkUIConstant } from "../semtalklistener/visiordfsinterface";
import {
    IObjectBase, ISemTalkObject, SemTalkBaseConstant, SemTalkLanguage, SemTalkRelation, SemTalkValueType,
    // ISemTalkAssociation,
    // ISemTalkAssociationType, ISemTalkDiagram, ISemTalkDiagramType, ISemTalkInstance,
    // ISemTalkObject, ISemTalkSystemClass, SemTalkAttachment, SemTalkBaseConstant, SemTalkRelation
} from "./Interface";
import { Language2Code } from "./langtools";
// import { SemTalkBaseConstant } from "./Interface";
// import { ISemTalkOnline } from "../../../../../ISemTalkOnline";

export const rdf: string = "rdf:";
export const xmlns: string = "xmlns:";
export const owl: string = "owl:";
export const xml: string = "xml:";
export const xsd: string = "xsd:";
export const rdfs: string = "rdfs:";
export const semtalkns: string = "semtalk:";

function getid(o: ISemTalkObject): string {
    let id = o.ID.toString();
    if (id.length < 10) {
        id = "X000000000" + id;
    }
    return id;
}
export function OWLExport(ob: IObjectBase, namespace: string): XMLDocument {

    const xd = document.implementation.createDocument("", "", null);
    const rdfelement = owldefs(xd, namespace);
    xd.appendChild(rdfelement);

    const ontology = xd.createElement(owl + "Ontology");
    ontology.setAttribute(rdf + "about", namespace);
    rdfelement.appendChild(ontology);

    // const c0 = xd.createComment("\nProperties\n");
    // rdfelement.appendChild(c0);
    let bl: string[] = [];
    bl.push(SemTalkBaseConstant.SLSubClassOf);
    bl.push(SemTalkBaseConstant.SLLanguage);
    bl.push(SemTalkBaseConstant.SLInstance);
    bl.push(SemTalkBaseConstant.SLComment);
    bl.push(SemTalkBaseConstant.SLThing);
    bl.push(SemTalkUIConstant.SLHidden);
    bl.push(SemTalkUIConstant.SemTalkUserTab);
    bl.push(Process_ElementName.SLDistribution);
    bl.push(Process_ElementName.SLBreakpoint);
    bl.push("ISO");
    bl.push("mxg");
    bl.push("HorizontalSwimlane");
    bl.push("SwimlaneWidth");
    bl.push("SwimlaneHeight");
    let cof = ob.FindAssociationType(SemTalkBaseConstant.SLConsistsOf);
    if (cof && cof.Instances().length === 1) {
        bl.push(SemTalkBaseConstant.SLConsistsOf);
    }

    let bl0: string[] = [];
    bl0.push(SemTalkBaseConstant.SLSubClassOf);
    bl0.push(SemTalkBaseConstant.SLLanguage);
    bl0.push(SemTalkUIConstant.SLHidden);
    bl0.push(SemTalkBaseConstant.SLComment);
    // bl0.push(SemTalkBaseConstant.SLThing);
    bl0.push(SemTalkUIConstant.SLHidden);
    bl0.push(SemTalkUIConstant.SemTalkUserTab);
    bl0.push(Process_ElementName.SLDistribution);
    bl0.push(Process_ElementName.SLBreakpoint);

    for (let a of ob.AllAttributeTypes()) {
        let aname = a.ObjectName;
        if (bl.indexOf(aname) > -1) continue;
        const element = xd.createElement(owl + "DatatypeProperty");
        element.setAttribute(rdf + "about", namespace + "/" + getid(a));
        const label = xd.createElement(rdfs + "label");
        const text = document.createTextNode(a.ObjectCaption);
        label.appendChild(text);
        element.appendChild(label);
        for (let syn of a.Synonyms()) {
            if (syn.Language) {
                let code = Language2Code(syn.Language as SemTalkLanguage);
                if (code) {
                    const slabel = xd.createElement(rdfs + "label");
                    slabel.setAttribute(xml + "lang", code);
                    const stext = document.createTextNode(syn.Name);
                    slabel.appendChild(stext);
                    element.appendChild(slabel);
                }
            }
        }
        rdfelement.appendChild(element);
    }
    for (let a of ob.AllAssociationTypes()) {
        let aname = a.ObjectName;
        if (bl.indexOf(aname) > -1) continue;
        if (a.RelationType !== SemTalkRelation.SemTalkProperty)
            if (aname !== SemTalkBaseConstant.SLhasAttachment) {
                continue;
            }
        const element = xd.createElement(owl + "ObjectProperty");
        element.setAttribute(rdf + "about", namespace + "/" + getid(a));
        const label = xd.createElement(rdfs + "label");
        const text = document.createTextNode(a.ObjectCaption);
        label.appendChild(text);
        element.appendChild(label);
        for (let syn of a.Synonyms()) {
            if (syn.Language) {
                let code = Language2Code(syn.Language as SemTalkLanguage);
                if (code) {
                    const slabel = xd.createElement(rdfs + "label");
                    slabel.setAttribute(xml + "lang", code);
                    const stext = document.createTextNode(syn.Name);
                    slabel.appendChild(stext);
                    element.appendChild(slabel);
                }
            }
        }
        rdfelement.appendChild(element);
    }

    // const c1 = xd.createComment("Classes");
    // rdfelement.appendChild(c1);




    for (let obj of ob.AllClasses()) {
        if (bl.indexOf(obj.ObjectName) > -1) continue;
        const element = xd.createElement(owl + "Class");
        element.setAttribute(rdf + "about", namespace + "/" + getid(obj));
        for (let sup of obj.SuperClasses()) {
            const supelement = xd.createElement(rdfs + "subClassOf");
            supelement.setAttribute(rdf + "resource", namespace + "/" + getid(sup));
            element.appendChild(supelement);
        }
        for (let a of (obj.Attributes())) {
            let val = a.Value;
            if (val) {
                let aname = a.ClassOf().ObjectName;
                if (bl.indexOf(aname) > -1) continue;

                const supelement = xd.createElement(rdfs + "subClassOf");
                const reselement = xd.createElement(owl + "Restriction");
                const property = xd.createElement(owl + "onProperty");
                property.setAttribute(rdf + "resource", namespace + "/" + getid(a.ClassOf()));
                const values = xd.createElement(owl + "hasValue");
                const value = document.createTextNode(a.Value);
                let dt = "http://www.w3.org/2001/XMLSchema#string";
                switch (a.ValueType) {
                    case SemTalkValueType.Integer: {
                        dt = "http://www.w3.org/2001/XMLSchema#integer";
                        break;
                    }
                    case SemTalkValueType.Boolean: {
                        dt = "http://www.w3.org/2001/XMLSchema#boolean";
                    }
                }
                values.setAttribute(rdf + "datatype", dt);
                values.appendChild(value);
                reselement.appendChild(property);
                reselement.appendChild(values);
                supelement.appendChild(reselement);
                element.appendChild(supelement);
            }
        }
        for (let a of (obj.Associations())) {
            let aname = a.ClassOf().ObjectName;
            if (bl.indexOf(aname) > -1) continue;

            const supelement = xd.createElement(rdfs + "subClassOf");
            const reselement = xd.createElement(owl + "Restriction");
            const property = xd.createElement(owl + "onProperty");
            property.setAttribute(rdf + "resource", namespace + "/" + getid(a.ClassOf()));
            const values = xd.createElement(owl + "someValuesFrom");
            values.setAttribute(rdf + "resource", namespace + "/" + getid(a.ToObject));
            reselement.appendChild(property);
            reselement.appendChild(values);
            supelement.appendChild(reselement);
            element.appendChild(supelement);
        }
        if (obj.Comment) {
            const comment = xd.createElement(rdfs + "comment");
            const text0 = document.createTextNode(obj.Comment);
            comment.appendChild(text0);
            element.appendChild(comment);
        }
        const label = xd.createElement(rdfs + "label");
        const text = document.createTextNode(obj.ObjectCaption);
        label.appendChild(text);
        element.appendChild(label);
        for (let syn of obj.Synonyms()) {
            if (syn.Language) {
                let code = Language2Code(syn.Language as SemTalkLanguage);
                if (code) {
                    const slabel = xd.createElement(rdfs + "label");
                    slabel.setAttribute(xml + "lang", code);
                    const stext = document.createTextNode(syn.Name);
                    slabel.appendChild(stext);
                    element.appendChild(slabel);
                }
            }
        }
        rdfelement.appendChild(element);
    }

    // const c2 = xd.createComment("Individuals");
    // rdfelement.appendChild(c2);

    for (let obj of ob.AllInstances()) {
        const cname = obj.ClassOf().ObjectName;
        if (bl0.indexOf(cname) > -1) continue;

        const element = xd.createElement(owl + "NamedIndividual");
        element.setAttribute(rdf + "about", namespace + "/" + getid(obj));
        if (cname !== SemTalkBaseConstant.SLInstance) {
            const rdftype = xd.createElement(rdf + "type");
            rdftype.setAttribute(rdf + "resource", namespace + "/" + getid(obj.ClassOf()));
            element.appendChild(rdftype);
        }
        for (let a of (obj.Attributes())) {
            let aname = a.ClassOf().ObjectName;
            if (bl.indexOf(aname) > -1) continue;
            const attr = xd.createElement(semtalkns + getid(a.ClassOf()));
            let dt = "http://www.w3.org/2001/XMLSchema#string";
            switch (a.ValueType) {
                case SemTalkValueType.Integer: {
                    dt = "http://www.w3.org/2001/XMLSchema#integer";
                    break;
                }
                case SemTalkValueType.Boolean: {
                    dt = "http://www.w3.org/2001/XMLSchema#boolean";
                }
            }
            attr.setAttribute(rdf + "datatype", dt);
            const value = document.createTextNode(a.Value);
            attr.appendChild(value);
            element.appendChild(attr);
        }
        for (let a of (obj.Associations())) {
            let aname = a.ClassOf().ObjectName;
            if (bl.indexOf(aname) > -1) continue;
            const assoc = xd.createElement(semtalkns + getid(a.ClassOf()));
            assoc.setAttribute(rdf + "resource", namespace + "/" + getid(a.ToObject));
            element.appendChild(assoc);
        }
        if (obj.Comment) {
            const comment = xd.createElement(rdfs + "comment");
            const text0 = document.createTextNode(obj.Comment);
            comment.appendChild(text0);
            element.appendChild(comment);
        }
        const label = xd.createElement(rdfs + "label");
        const text = document.createTextNode(obj.ObjectCaption);
        label.appendChild(text);
        element.appendChild(label);
        for (let syn of obj.Synonyms()) {
            if (syn.Language) {
                let code = Language2Code(syn.Language as SemTalkLanguage);
                if (code) {
                    const slabel = xd.createElement(rdfs + "label");
                    slabel.setAttribute(xml + "lang", code);
                    const stext = document.createTextNode(syn.Name);
                    slabel.appendChild(stext);
                    element.appendChild(slabel);
                }
            }
        }
        rdfelement.appendChild(element);
    }
    return xd;
}


function owldefs(xd: XMLDocument, namespace: string): HTMLElement {
    let rdfelement = xd.createElement(rdf + "RDF");
    rdfelement.setAttribute("xmlns", namespace + "#");
    rdfelement.setAttribute(xmlns + "base", namespace);
    rdfelement.setAttribute(xmlns + "owl", "http://www.w3.org/2002/07/owl#");
    rdfelement.setAttribute(xmlns + "rdf", "http://www.w3.org/1999/02/22-rdf-syntax-ns#");
    rdfelement.setAttribute(xmlns + "xml", "http://www.w3.org/XML/1998/namespace");
    rdfelement.setAttribute(xmlns + "xsd", "http://www.w3.org/2001/XMLSchema#");
    rdfelement.setAttribute(xmlns + "rdfs", "http://www.w3.org/2000/01/rdf-schema#");
    rdfelement.setAttribute(xmlns + "semtalk", namespace + "/");
    return rdfelement;
}
