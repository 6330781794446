import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from './authConfig';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType } from '@azure/msal-browser';
import { ensureClient } from './GraphService';
import {
  // TeamsFx,
  TeamsUserCredential,
  TeamsUserCredentialAuthConfig,
  createMicrosoftGraphClientWithCredential,
} from "@microsoft/teamsfx";
// import { Client } from "@microsoft/microsoft-graph-client";


export async function ms365login(clientId: string, authority: string, scopes: string[]) {

  try {
    if (window.location.hash === '#/tab' && process.env.REACT_APP_CLIENT_ID && process.env.REACT_APP_START_LOGIN_PAGE_URL) {
      const authConfig: TeamsUserCredentialAuthConfig = {
        clientId: process.env.REACT_APP_CLIENT_ID,
        initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
      };
      const credential = new TeamsUserCredential(authConfig);
      //       const authProvider = new TokenCredentialAuthenticationProvider(credential, { scopes: scope });
      // const graph = Client.initWithMiddleware({
      // authProvider: authProvider,
      // });
      // const teamsfx = new TeamsFx();
      // const credential = teamsfx.getCredential();
      if (credential) {
        // const accessToken = await credential.getToken(scopes);
        // console.debug(accessToken);

        const accessToken = await credential.getToken(scopes);
        // console.debug(accessToken);
        if (!accessToken || !accessToken.token) {
          await credential.login(scopes);
        }
   
        // console.debug(graphClient);
        // const profile = await graphClient.api("/me").get();
        // console.debug(profile);
        // return graphClient;
        // if (!(credential as any)["ssoToken"]) {
        //   try {
        //     let login = await teamsfx.login(scopes);
        //     console.debug(login);
        //   } catch (_e1) {
        //   }
        // }
        const graphclient1: any = createMicrosoftGraphClientWithCredential(credential, scopes);
        graphclient1.config.defaultVersion = "beta";

        let account0 = await credential.getUserInfo();
        return { graphclient: graphclient1, account: account0 };
      }
    }
  } catch (e) {

  }
  let msalInstance: IPublicClientApplication;
  // if ((window.location.toString() === "http://localhost:3000/")) {
  let redirectUri = window.location.origin;
  if (redirectUri.endsWith("/")) {
    redirectUri = redirectUri.substr(0, redirectUri.length - 1);
  }
  msalConfig.auth = {
    clientId: clientId,
    authority: authority,
    redirectUri: redirectUri,
    postLogoutRedirectUri: "/"
  };
  msalInstance = new PublicClientApplication(msalConfig);
  // } else {
  //   msalInstance = new PublicClientApplication(msalConfig);
  // }

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  let accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      msalInstance as PublicClientApplication,
      {
        account: accounts[0],
        scopes: scopes,
        interactionType: InteractionType.Popup
      }
    );
    let graphclient2 = ensureClient(authProvider);
    return { graphclient: graphclient2, account: accounts[0] };
  }

  let request: any = {
    scopes: scopes,
    prompt: "select_account",
    // loginHint: "user@semtalk.onmicrosoft.com",
    //  account: account
  };
  let account: AccountInfo | null = null;
  let graphclient: any = null;
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      if (payload.account) account = payload.account;
      request.account = account;
      msalInstance.setActiveAccount(account);
      // response = await this.msalInstance.acquireTokenSilent(request);
      const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        msalInstance as PublicClientApplication,
        {
          account: account!,
          scopes: scopes,
          interactionType: InteractionType.Popup
        }
      );
      graphclient = ensureClient(authProvider);
    }
  });

  let response: any = null;
  try {
    response = await msalInstance.acquireTokenSilent(request);
  } catch (e) {
    try {
      response = await msalInstance.acquireTokenPopup(request);
    } catch (e) {
    }
  }
  if (response) {
    // return response.accessToken;
    return { graphclient: graphclient, account: response.account };
  }
  return undefined;
}
export async function ms365logout(clientId: string, authority: string) {
  let msalInstance: IPublicClientApplication;
  let redirectUri = window.location.origin;
  if (redirectUri.endsWith("/")) {
    redirectUri = redirectUri.substr(0, redirectUri.length - 1);
  }
  msalConfig.auth = {
    clientId: clientId,
    authority: authority,
    redirectUri: redirectUri,
    postLogoutRedirectUri: "/"
  };
  msalInstance = new PublicClientApplication(msalConfig);
  let accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    const logoutRequest = {
      account: accounts[0],
      mainWindowRedirectUri: redirectUri,
    };
    await msalInstance.logoutPopup(logoutRequest);
  }
}
export async function ms365token(clientId: string, authority: string, scopes: string[]) {
  let msalInstance: IPublicClientApplication;

  // if ((window.location.toString() === "http://localhost:3000/")) {
  let redirectUri = window.location.origin;
  if (redirectUri.endsWith("/")) {
    redirectUri = redirectUri.substr(0, redirectUri.length - 1);
  }
  msalConfig.auth = {
    clientId: clientId,
    authority: authority,
    redirectUri: redirectUri,
    postLogoutRedirectUri: "/"
  };
  msalInstance = new PublicClientApplication(msalConfig);
  // } else {
  //   msalInstance = new PublicClientApplication(msalConfig);
  // }
  let request: any = {
    scopes: scopes,
    prompt: "select_account",
    // loginHint: "user@semtalk.onmicrosoft.com",
    //  account: account
  };

  let account: AccountInfo | null = null;
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      if (payload.account) account = payload.account;
      request.account = account;
      msalInstance.setActiveAccount(account);
    }
  });

  let response: any = null;
  try {
    response = await msalInstance.acquireTokenSilent(request);
  } catch (e) {
    try {
      response = await msalInstance.acquireTokenPopup(request);
    } catch (e) {
    }
  }
  if (response) {
    return response.accessToken;
  }
  return undefined;
}
