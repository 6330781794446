/* eslint-disable no-useless-concat */
import React, { Suspense } from 'react';
import ReactDOM from "react-dom";

import {
  Stack,
  StackItem
} from 'office-ui-fabric-react/lib/Stack';
// import { SemTalkPages } from '../controls/stpages/SemTalkPages';
import { ISemTalkOnline, SemTalkOnlineCommand, SemTalkRole } from '../ISemTalkOnline';
import { SemTalkCookie } from "../ISemTalkCookie";

import { Sidebar } from './sidebar';
import { Pages } from './pages';
import {
  getTheme, PanelType
  // ScrollablePane, ScrollbarVisibility
} from 'office-ui-fabric-react';
import {
  createTheme,
  Customizations
} from 'office-ui-fabric-react';

// import { Toolbar } from './toolbar';
/* eslint import/no-webpack-loader-syntax: off */
/* eslint  no-undef: off */
// import JSEditor from '../src/JSEditor';

import util from './tsutil';
// import './tsutil_bpmn.ts';
import { ITSEditor, LayoutSetting, zoomoption } from '../ITSEditor';
import './css/common.css';
import './css/explorer.css';


/* tslint:disable:noImplicitAny */
/* eslint:disable:noImplicitAny */

import styles from './tseditor.module.scss';

import {
  mxGraph,
  mxConstants,
  mxEdgeStyle,
  mxLayoutManager,
  mxGeometry,
  mxRubberband,
  mxDragSource,
  mxKeyHandler,
  mxCodec,
  mxClient,
  mxConnectionHandler,
  mxUtils,
  mxGraphModel,
  mxCell,
  mxShape,
  mxToolbar,
  mxEvent,
  mxImage,
  mxVertexHandler,
  mxPerimeter,
  mxUndoManager,
  mxClipboard,
  mxConstraintHandler,
  mxGraphHandler,
  mxEdgeHandler,
  mxPolyline,
  mxConnectionConstraint,
  mxPoint,
  mxCellState,
  mxRectangle,
  mxAutoSaveManager,
  mxRectangleShape,
  mxHexagon,
  mxCylinder,
  mxCellRenderer,
  mxActor,
  mxRhombus,
  mxArrow,
  mxDefaultKeyHandler,
  mxDefaultPopupMenu,
  mxDefaultToolbar,
  mxEditor,
  mxCellHighlight,
  mxCellMarker,
  mxCellTracker,
  mxEdgeSegmentHandler,
  mxElbowEdgeHandler,
  mxHandle,
  mxPanningHandler,
  mxPopupMenuHandler,
  mxSelectionCellsHandler,
  mxTooltipHandler,
  mxCellCodec,
  mxChildChangeCodec,
  mxCodecRegistry,
  mxDefaultKeyHandlerCodec,
  mxEditorCodec,
  mxGenericChangeCodec,
  mxGraphCodec,
  mxDefaultPopupMenuCodec,
  mxDefaultToolbarCodec,
  mxGraphViewCodec,
  mxModelCodec,
  mxObjectCodec,
  mxRootChangeCodec,
  mxStylesheetCodec,
  mxTerminalChangeCodec,
  mxParallelEdgeLayout,
  mxFastOrganicLayout,
  mxCircleLayout,
  mxCompactTreeLayout,
  mxCompositeLayout,
  mxEdgeLabelLayout,
  mxGraphLayout,
  mxPartitionLayout,
  mxRadialTreeLayout,
  mxStackLayout,
  mxHierarchicalLayout,
  mxSwimlaneLayout,
  mxGraphAbstractHierarchyCell,
  mxGraphHierarchyEdge,
  mxGraphHierarchyModel,
  mxGraphHierarchyNode,
  mxSwimlaneModel,
  mxCoordinateAssignment,
  mxHierarchicalLayoutStage,
  mxMedianHybridCrossingReduction,
  mxMinimumCycleRemover,
  mxSwimlaneOrdering,
  mxCellPath,
  mxArrowConnector,
  mxCloud,
  mxConnector,
  mxDoubleEllipse,
  mxEllipse,
  mxImageShape,
  mxLabel,
  mxLine,
  mxMarker,
  mxStencil,
  mxStencilRegistry,
  mxSwimlane,
  mxText,
  mxTriangle,
  mxAbstractCanvas2D,
  mxAnimation,
  mxDictionary,
  mxDivResizer,
  mxEffects,
  mxEventObject,
  mxEventSource,
  mxForm,
  mxGuide,
  mxImageBundle,
  mxImageExport,
  mxLog,
  mxMorphing,
  mxMouseEvent,
  mxObjectIdentity,
  mxPanningManager,
  mxPopupMenu,
  mxResources,
  mxSvgCanvas2D,
  mxUndoableEdit,
  // mxChildChange,
  mxUrlConverter,
  mxVmlCanvas2D,
  mxWindow,
  mxXmlCanvas2D,
  mxXmlRequest,
  mxCellEditor,
  mxCellOverlay,
  mxCellStatePreview,
  mxGraphSelectionModel,
  mxGraphView,
  mxMultiplicity,
  mxOutline,
  mxPrintPreview,
  mxStyleRegistry,
  mxSwimlaneManager,
  mxTemporaryCellStates,
  mxLanguage,
  // mxHierarchicalEdgeStyle
  // mxXmlUtils,
  // mxGraphComponent,
  // mxPngEncodeParam,
  // mxPngImageEncoder
} from "mxgraph-js";

import { GENERAL_SHAPES } from './general-shape';
import { MessageBarType } from 'office-ui-fabric-react';
import { ISemTalkDiagram, PrintPageFormat, SemTalkBaseConstant, SemTalkID, SemTalkLanguage } from '../application/tbase/Interface';
import SemTalkNavigator from '../controls/stnavigator/SemTalkNavigator';
import SemTalkPlanner from '../controls/stplanner/SemTalkPlanner';
import { ISharePointSettings, IVisioRDFS, ResIDL, SemTalkShapeType, SemTalkStyleAttribute } from '../application/semtalklistener/visiordfsinterface';
import { accessCookie, setCookie, removeCookie } from '../application/semtalklistener/stgoto';
import { IHeaderFooterField } from '../controls/stheader/SemTalkHeader';
// import SemTalkPivot from '../controls/stpivot/SemTalkPivot';
import { IMongoOption } from '../SemTalkMongoServer';
import { SemTalkMaster } from '../application/SemTalkMaster';
import {
  cloneObjects, copyCells, extractDataFromEvent, importXml,
  parseObjects
} from './clipboard';
// import { setStyleAttribute } from '../styles';
// import SemTalkTable from '../controls/sttable/SemTalkTable';
// import { defaultFont } from '../application/semtalklistener/visiordfs';
// import { SemTalkStencil } from '../application/semtalklistener/visiordfsinterface';

const SemTalkPivot = React.lazy(
  () =>
    import(/* webpackChunkName: "pivot" */ '../controls/stpivot/SemTalkPivot')
);
// const SemTalkPlanner = React.lazy(
//   () =>
//     import(
//       /* webpackChunkName: "planner" */
//       "../controls/stplanner/SemTalkPlanner"
//     )
// );

require('./css/common.css');
require('./css/explorer.css');

export interface ITSEditorProps {
  site: string;
  support: string;
  xmlgraph: string;
  callback: ISemTalkOnline;
  stencil: any;
  semtalk: IVisioRDFS;
  diagcaption: string;
  diagid: SemTalkID;
  width: number;
  height: number;
  isconnectionpoints: boolean;
  islocked: boolean;
  shapesleftside: boolean;
  dockpanandzoom: boolean;
  docknavigator: boolean;
  dockplanner: boolean;
  dockpivot: boolean;
  editdialogwidth: string;
  dockstencil: boolean;
  docksearch: boolean;
  panandzoomcaption: string;
  allownegativecoordinates: boolean;
  language: SemTalkLanguage;
  isplanmaker: boolean;
  ispivot: boolean;
  headerfooterfields: IHeaderFooterField[];
  showheaderfooterfields: boolean;
  printlandscape: boolean;
  printpageformat: string;
  loading: boolean;
  usegraph: boolean;
  sharepointlibrary: string;
  sharepointlibrarysite: string;
  sharepointdocumentcolumns: string;
  graphClient?: any;
  role: SemTalkRole;
  context?: any;
  sharepoint?: ISharePointSettings;
  spCheckInOut: boolean;
  mongo: IMongoOption;
  showSimulation: boolean;
  showBPMN: boolean;
  reflexivelinks: boolean;
  autoextend: boolean;
  autoscroll: boolean;
  extendParentsOnMove: boolean;
  extendParentsOnAdd: boolean;
  resizecontainer: boolean;
  splitenabled: boolean;
}
export interface ITSEditorState {
  // editor: JSEditor | null;
  // xmlgraph: string;
  //  semtalk: IVisioRDFS | undefined;
  //  diag: ISemTalkDiagram | null;
  ispanzoom: boolean;
  isstencil: boolean;
  ispages: boolean;
  isnavigator: boolean;
  isplanner: boolean;
  ispivot: boolean;
  // issearch: boolean;
  diaglist: { text: string, key: SemTalkID }[];
  // panzoomX: string;
  // panzoomY: string;
  horizontal: boolean;
  // pageFormat: string;
}
function setVariables() {
  // console.debug(window["mxGraphModel"]);
  window["mxGraph" as any] = mxGraph;
  window["mxParallelEdgeLayout" as any] = mxParallelEdgeLayout;
  window["mxConstants" as any] = mxConstants;
  window["mxEdgeStyle" as any] = mxEdgeStyle;
  window["mxLayoutManager" as any] = mxLayoutManager;
  window["mxRubberband" as any] = mxRubberband;
  window["mxDragSource" as any] = mxDragSource;
  window["mxGeometry" as any] = mxGeometry;
  window["mxKeyHandler" as any] = mxKeyHandler;
  window["mxCodec" as any] = mxCodec;
  window["mxClient" as any] = mxClient;
  window["mxConnectionHandler" as any] = mxConnectionHandler;
  window["mxUtils" as any] = mxUtils;
  window["mxGraphModel" as any] = mxGraphModel;
  window["mxCell" as any] = mxCell;
  window["mxShape" as any] = mxShape;
  window["mxToolbar" as any] = mxToolbar;
  window["mxEvent" as any] = mxEvent;
  window["mxImage" as any] = mxImage;
  window["mxFastOrganicLayout" as any] = mxFastOrganicLayout;
  window["mxVertexHandler" as any] = mxVertexHandler;
  window["mxPerimeter" as any] = mxPerimeter;
  window["mxUndoManager" as any] = mxUndoManager;
  window["mxClipboard" as any] = mxClipboard;
  window["mxConstraintHandler" as any] = mxConstraintHandler;
  window["mxGraphHandler" as any] = mxGraphHandler;
  window["mxEdgeHandler" as any] = mxEdgeHandler;
  window["mxPolyline" as any] = mxPolyline;
  window["mxConnectionConstraint" as any] = mxConnectionConstraint;
  window["mxPoint" as any] = mxPoint;
  window["mxCellState" as any] = mxCellState;
  window["mxRectangle" as any] = mxRectangle;
  window["mxAutoSaveManager" as any] = mxAutoSaveManager;
  window["mxRectangleShape" as any] = mxRectangleShape;
  window["mxHexagon" as any] = mxHexagon;
  window["mxCylinder" as any] = mxCylinder;
  window["mxCellRenderer" as any] = mxCellRenderer;
  window["mxActor" as any] = mxActor;
  window["mxRhombus" as any] = mxRhombus;
  window["mxArrow" as any] = mxArrow;
  window["mxDefaultKeyHandler" as any] = mxDefaultKeyHandler;
  window["mxDefaultPopupMenu" as any] = mxDefaultPopupMenu;
  window["mxDefaultToolbar" as any] = mxDefaultToolbar;
  window["mxEditor" as any] = mxEditor;
  window["mxCellHighlight" as any] = mxCellHighlight;
  window["mxCellMarker" as any] = mxCellMarker;
  window["mxCellTracker" as any] = mxCellTracker;
  window["mxEdgeSegmentHandler" as any] = mxEdgeSegmentHandler;
  window["mxElbowEdgeHandler" as any] = mxElbowEdgeHandler;
  window["mxHandle" as any] = mxHandle;
  window["mxPanningHandler" as any] = mxPanningHandler;
  window["mxPanningHandler" as any] = mxPanningHandler;
  window["mxPanningHandler" as any] = mxPanningHandler;
  window["mxChildChangeCodec" as any] = mxChildChangeCodec;
  window["mxPopupMenuHandler" as any] = mxPopupMenuHandler;
  window["mxSelectionCellsHandler" as any] = mxSelectionCellsHandler;
  window["mxTooltipHandler" as any] = mxTooltipHandler;
  window["mxCellCodec" as any] = mxCellCodec;
  window["mxCodecRegistry" as any] = mxCodecRegistry;
  window["mxDefaultKeyHandlerCodec" as any] = mxDefaultKeyHandlerCodec;
  window["mxEditorCodec" as any] = mxEditorCodec;
  window["mxGenericChangeCodec"] = mxGenericChangeCodec;
  window["mxGraphCodec" as any] = mxGraphCodec;
  window["mxDefaultPopupMenuCodec" as any] = mxDefaultPopupMenuCodec;
  window["mxDefaultToolbarCodec" as any] = mxDefaultToolbarCodec;
  window["mxGraphViewCodec" as any] = mxGraphViewCodec;
  window["mxModelCodec" as any] = mxModelCodec;
  window["mxObjectCodec" as any] = mxObjectCodec;
  window["mxRootChangeCodec" as any] = mxRootChangeCodec;
  window["mxStylesheetCodec" as any] = mxStylesheetCodec;
  window["mxTerminalChangeCodec" as any] = mxTerminalChangeCodec;
  window["mxCircleLayout" as any] = mxCircleLayout;
  window["mxCompactTreeLayout" as any] = mxCompactTreeLayout;
  window["mxCompositeLayout" as any] = mxCompositeLayout;
  window["mxEdgeLabelLayout" as any] = mxEdgeLabelLayout;
  window["mxGraphLayout" as any] = mxGraphLayout;
  window["mxPartitionLayout" as any] = mxPartitionLayout;
  window["mxRadialTreeLayout" as any] = mxRadialTreeLayout;
  window["mxStackLayout" as any] = mxStackLayout;
  window["mxHierarchicalLayout" as any] = mxHierarchicalLayout;
  window["mxSwimlaneLayout" as any] = mxSwimlaneLayout;
  window["mxGraphAbstractHierarchyCell" as any] = mxGraphAbstractHierarchyCell;
  window["mxGraphHierarchyEdge" as any] = mxGraphHierarchyEdge;
  window["mxGraphHierarchyModel" as any] = mxGraphHierarchyModel;
  window["mxGraphHierarchyNode" as any] = mxGraphHierarchyNode;
  window["mxSwimlaneModel" as any] = mxSwimlaneModel;
  window["mxCoordinateAssignment" as any] = mxCoordinateAssignment;
  window["mxHierarchicalLayoutStage" as any] = mxHierarchicalLayoutStage;
  window["mxMedianHybridCrossingReduction" as any] = mxMedianHybridCrossingReduction;
  window["mxMinimumCycleRemover" as any] = mxMinimumCycleRemover;
  window["mxSwimlaneOrdering" as any] = mxSwimlaneOrdering;
  window["mxCellPath" as any] = mxCellPath;
  window["mxArrowConnector" as any] = mxArrowConnector;
  window["mxCloud" as any] = mxCloud;
  window["mxConnector" as any] = mxConnector;
  window["mxDoubleEllipse" as any] = mxDoubleEllipse;
  window["mxEllipse" as any] = mxEllipse;
  window["mxImageShape" as any] = mxImageShape;
  window["mxLabel" as any] = mxLabel;
  window["mxLine" as any] = mxLine;
  window["mxMarker" as any] = mxMarker;
  window["mxStencil" as any] = mxStencil;
  window["mxStencilRegistry" as any] = mxStencilRegistry;
  window["mxSwimlane" as any] = mxSwimlane;
  window["mxText" as any] = mxText;
  window["mxTriangle" as any] = mxTriangle;
  window["mxAbstractCanvas2D" as any] = mxAbstractCanvas2D;
  window["mxAnimation" as any] = mxAnimation;
  window["mxDictionary" as any] = mxDictionary;
  window["mxDivResizer" as any] = mxDivResizer;
  window["mxEffects" as any] = mxEffects;
  window["mxEventObject" as any] = mxEventObject;
  window["mxEventSource" as any] = mxEventSource;
  window["mxForm" as any] = mxForm;
  window["mxGuide" as any] = mxGuide;
  window["mxImageBundle" as any] = mxImageBundle;
  window["mxImageExport" as any] = mxImageExport;
  window["mxLog" as any] = mxLog;
  window["mxMorphing" as any] = mxMorphing;
  window["mxMouseEvent" as any] = mxMouseEvent;
  window["mxObjectIdentity" as any] = mxObjectIdentity;
  window["mxPanningManager" as any] = mxPanningManager;
  window["mxPopupMenu" as any] = mxPopupMenu;
  window["mxResources" as any] = mxResources;
  window["mxSvgCanvas2D" as any] = mxSvgCanvas2D;
  window["mxUndoableEdit" as any] = mxUndoableEdit;
  window["mxUrlConverter" as any] = mxUrlConverter;
  window["mxVmlCanvas2D" as any] = mxVmlCanvas2D;
  window["mxArrow" as any] = mxArrow;
  window["mxArrow" as any] = mxArrow;
  window["mxWindow" as any] = mxWindow;
  window["mxXmlCanvas2D" as any] = mxXmlCanvas2D;
  window["mxXmlRequest" as any] = mxXmlRequest;
  window["mxCellEditor" as any] = mxCellEditor;
  window["mxCellOverlay" as any] = mxCellOverlay;
  window["mxCellStatePreview" as any] = mxCellStatePreview;
  window["mxGraphSelectionModel" as any] = mxGraphSelectionModel;
  window["mxGraphView" as any] = mxGraphView;
  window["mxMultiplicity" as any] = mxMultiplicity;
  window["mxOutline" as any] = mxOutline;
  window["mxPrintPreview" as any] = mxPrintPreview;
  window["mxStyleRegistry" as any] = mxStyleRegistry;
  window["mxSwimlaneManager" as any] = mxSwimlaneManager;
  window["mxTemporaryCellStates" as any] = mxTemporaryCellStates;
  window["mxLanguage" as any] = mxLanguage;
  // window["mxHierarchicalEdgeStyle" as any] = mxHierarchicalEdgeStyle;
}
function getAttribute(s: string, aname: string): string | undefined {
  let laname = aname.toLowerCase();
  let sl = s.split(";");
  for (let kv of sl) {
    if (kv.length > 0) {
      if (kv.indexOf("=") < 0) {
      } else {
        let p = kv.split("=");
        let k = p[0];
        if (k.toLowerCase() === laname) {
          return kv.substring(kv.indexOf("=") + 1);
        }
      }
    }
  }
  return undefined;
}

interface PortalProps {
  container: HTMLElement;
  contents: JSX.Element;
}
export const Portal = (props: PortalProps): React.ReactPortal => {
  return ReactDOM.createPortal(
    props.contents,
    props.container
  );
};

export class TSEditor extends React.Component<ITSEditorProps, ITSEditorState> implements ITSEditor {
  // private editor: Editor;
  // private mounted: boolean=false;
  // private graphContainerClickCount: number=0;
  // private empty: any;
  public graph: mxGraph;
  public sidebar: Sidebar;
  public pages: Pages;
  public containerEle: any;
  public swimlanemanager: mxSwimlaneManager;
  public stacklayout: mxStackLayout;
  // private droptarget: mxCell | null = null;
  private imageShapes: HTMLCollection[] = [];
  private keydownhandler: any[] = [];
  // private undoManager: mxUndoManager;
  // private outline: mxOutline;
  private outlinewnd: mxWindow | null = null;
  private stencilwnd: mxWindow | null = null;
  private stencilel: HTMLElement | null = null;
  private navigatorwnd: mxWindow | null = null;
  private navigatorel: HTMLElement | null = null;
  private plannerwnd: mxWindow | null = null;
  private plannerel: HTMLElement | null = null;
  private pivotwnd: mxWindow | null = null;
  private pivotel: HTMLElement | null = null;
  // private searchwnd: mxWindow | null = null;
  // private searchel: HTMLElement | null = null;
  private undoman: mxUndoManager = new mxUndoManager(); //eslint-disable-line
  private navigator: any = null;
  private planner: any = null;
  private pivot: any = null;
  private stencil: any = null;
  private stencil_portal: any = null;
  // private search: any = null;
  private initialformart: mxRectangle = mxGraph.prototype.pageFormat;
  private headerfooterfields: IHeaderFooterField[];
  private showheaderfooterfields: boolean;
  private printlandscape: boolean;
  private printpageformat: string;
  // private backgroundpageshape: mxRectangleShape | null = null;
  // private textcells: mxCell[] | null = null;


  private formats: any[] = [
    { key: PrintPageFormat.custom, text: mxResources.get("custom"), format: null },
    {
      key: PrintPageFormat.letter,
      text: 'US-Letter (8,5" x 11")',
      format: mxConstants.PAGE_FORMAT_LETTER_PORTRAIT,
    },
    {
      key: PrintPageFormat.legal,
      text: 'US-Legal (8,5" x 14")',
      format: new mxRectangle(0, 0, 850, 1400),
    },
    {
      key: PrintPageFormat.tabloid,
      text: 'US-Tabloid (11" x 17")',
      format: new mxRectangle(0, 0, 1100, 1700),
    },
    {
      key: PrintPageFormat.executive,
      text: 'US-Executive (7" x 10")',
      format: new mxRectangle(0, 0, 700, 1000),
    },
    {
      key: PrintPageFormat.a0,
      text: "A0 (841 mm x 1189 mm)",
      format: new mxRectangle(0, 0, 3300, 4681),
    },
    {
      key: PrintPageFormat.a1,
      text: "A1 (594 mm x 841 mm)",
      format: new mxRectangle(0, 0, 2339, 3300),
    },
    {
      key: PrintPageFormat.a2,
      text: "A2 (420 mm x 594 mm)",
      format: new mxRectangle(0, 0, 1654, 2336),
    },
    {
      key: PrintPageFormat.a3,
      text: "A3 (297 mm x 420 mm)",
      format: new mxRectangle(0, 0, 1169, 1654),
    },
    {
      key: PrintPageFormat.a4,
      text: "A4 (210 mm x 297 mm)",
      format: mxConstants.PAGE_FORMAT_A4_PORTRAIT,
    },
    {
      key: PrintPageFormat.a5,
      text: "A5 (148 mm x 210 mm)",
      format: new mxRectangle(0, 0, 583, 827),
    },
    {
      key: PrintPageFormat.a6,
      text: "A6 (105 mm x 148 mm)",
      format: new mxRectangle(0, 0, 413, 583),
    },
    {
      key: PrintPageFormat.a7,
      text: "A7 (74 mm x 105 mm)",
      format: new mxRectangle(0, 0, 291, 413),
    },
    {
      key: PrintPageFormat.b4,
      text: "B4 (250 mm x 353 mm)",
      format: new mxRectangle(0, 0, 980, 1390),
    },
    {
      key: PrintPageFormat.b5,
      text: "B5 (176 mm x 250 mm)",
      format: new mxRectangle(0, 0, 690, 980),
    },
    {
      key: PrintPageFormat.x169,
      text: "16:9 (1600 x 900)",
      format: new mxRectangle(0, 0, 900, 1600),
    },
    {
      key: PrintPageFormat.x1610,
      text: "16:10 (1920 x 1200)",
      format: new mxRectangle(0, 0, 1200, 1920),
    },
    {
      key: PrintPageFormat.x43,
      text: "4:3 (1600 x 1200)",
      format: new mxRectangle(0, 0, 1200, 1600),
    },
  ];

  private applyTheme() {
    let ff = accessCookie(SemTalkCookie.guifont);
    let theme: any = null;
    // if (ff === 'undefined') ff = defaultFont;
    if (!ff) ff = "Helvetica, Arial, sans-serif";

    let th = accessCookie(SemTalkCookie.themeStyle);
    let ic = accessCookie(SemTalkCookie.iconcolor);
    let icj: any = undefined;
    if (ic !== 'undefined' && ic !== null && ic !== "") {
      switch (ic) {
        case "Black": {
          icj = { "themePrimary": "#000000", "themeDarkAlt": '#616161', "themeDark": '#616161', "themeDarker": '#8c8c8c' };
          break;
        }
        case "Green": {
          icj = { "themePrimary": "#00c21d", "themeDarkAlt": '#009316', "themeDark": '#009316', "themeDarker": '#006d10' };
          break;
        }
        case "Red": {
          icj = { "themePrimary": "#e00004", "themeDarkAlt": '#ab0003', "themeDark": '#ab0003', "themeDarker": '#7e0002' };
          break;
        }
        case "Blue": {
          icj = { "themePrimary": "#0078d4", "themeDarkAlt": '#005ba1', "themeDark": '#005ba1', "themeDarker": '#004377' };
          break;
        }
        case "Orange": {
          icj = { "themePrimary": "#f09c00", "themeDarkAlt": '#b67600', "themeDark": '#b67600', "themeDarker": '#865700' };
          break;
        }
        case "White": {
          icj = { "themePrimary": "#ffffff", "themeDarkAlt": '#f4f4f4', "themeDark": '#f4f4f4', "themeDarker": '#f8f8f8' };
          break;
        }
        default: {
          break;
        }
      }
    }
    if (th !== undefined && th !== null && th !== "") {
      let themeTemplate = JSON.parse(th);
      if (themeTemplate.themePrimary !== undefined && themeTemplate.themePrimary !== null) {
        if (icj !== undefined) {
          themeTemplate.themePrimary = icj["themePrimary"];
          themeTemplate.themeDark = icj["themeDark"];
          themeTemplate.themeDarker = icj["themeDarker"];
          themeTemplate.themeDarkAlt = icj["themeDarkAlt"];
        }
      }
      if (themeTemplate.white !== undefined && themeTemplate.white !== null) {
        let bg = themeTemplate.white;
        if (bg !== 'undefined' && bg !== null && bg !== "") {
          setCookie(SemTalkCookie.backgroundColor, bg);
        }
      }
      if (themeTemplate !== 'undefined' && themeTemplate !== null) {
        theme = createTheme({
          defaultFontStyle: { fontFamily: ff },
          palette: themeTemplate
        });
      } else {
        if (icj !== undefined) {
          theme = createTheme({
            defaultFontStyle: { fontFamily: ff },
            palette: {
              themePrimary: icj["themePrimary"],
              themeDark: icj["themeDark"],
              themeDarker: icj["themeDarker"],
              themeDarkAlt: icj["themeDarkAlt"],
            }
          });
        } else {
          theme = createTheme({
            defaultFontStyle: { fontFamily: ff }
          });
        }
      }
    }
    else {
      if (icj !== undefined) {
        theme = createTheme({
          defaultFontStyle: { fontFamily: ff },
          palette: {
            themePrimary: icj["themePrimary"],
            themeDark: icj["themeDark"],
            themeDarker: icj["themeDarker"],
            themeDarkAlt: icj["themeDarkAlt"],
          }
        });
      } else {
        theme = createTheme({
          defaultFontStyle: { fontFamily: ff }
        });
      }
    }
    // if (ff !== ff0) {
    if (theme) {
      Customizations.applySettings({ theme });
    }
    // }

  }
  //public droptarget: any = null;

  constructor(props: ITSEditorProps) {
    super(props);

    console.debug("TSEditor");

    let support_lib = this.props.site;
    if (this.props.support && this.props.site !== ".") {
      support_lib += "/" + this.props.support;
    }
    mxWindow.prototype.closeImage = support_lib + '/images/close.gif';
    mxWindow.prototype.minimizeImage = support_lib + '/images/minimize.gif';
    mxWindow.prototype.maximizeImage = support_lib + '/images/maximize.gif';
    mxWindow.prototype.normalizeImage = support_lib + '/images/normalize.gif';

    mxClient.imageBasePath = support_lib + "/images";
    mxClient.mxLanguage = this.props.semtalk.guilanguage;
    this.applyTheme();

    let ispanzoom: boolean = accessCookie(SemTalkCookie.panzoom) === '1';
    let isstencil: boolean = accessCookie(SemTalkCookie.stencil) === '1';
    let ispages: boolean = accessCookie(SemTalkCookie.pages) !== '0';
    let isnavigator: boolean = accessCookie(SemTalkCookie.navigator) === '1';
    // let isplanner: boolean = accessCookie(SemTalkCookie.planner) === '1';
    let isplanner: boolean = false;
    let ispivot: boolean = accessCookie(SemTalkCookie.pivot) === '1';
    ispivot = this.props.ispivot;
    // let issearch: boolean = accessCookie(SemTalkCookie.search) === '1';

    if (isstencil && !this.props.dockstencil && !this.props.islocked) {
      this.showStencil();
    }

    this.state = {
      // editor: null,
      // xmlgraph: this.props.xmlgraph,
      // diag: this.props.diag,
      ispanzoom: ispanzoom,
      isstencil: isstencil,
      ispages: ispages,
      isnavigator: isnavigator,
      isplanner: isplanner,
      ispivot: ispivot,
      // issearch: issearch,
      diaglist: [],
      horizontal: false,
      //   semtalk: this.props.semtalk
      // panzoomX: "10px",
      // panzoomY: "20px"
      // pageFormat: PrintPageFormat.custom,
    };
  }
  public UpdatePage(graph?: mxGraph) {
    if (!graph) graph = this.graph;
    let format = this.props.printpageformat;
    if (format === undefined || format === "") {
      format = accessCookie(SemTalkCookie.printpageformat);
    }

    let landscape = this.props.printlandscape;
    if (landscape === undefined) {
      landscape = (accessCookie(SemTalkCookie.printlandscape) === "true");
    }
    let formatentry = this.formats.find((f => f["key"] === format));
    // console.debug(formatentry);
    // let format: mxRectangle | null = null;
    if (formatentry && formatentry.format) {
      let fr = mxRectangle.fromRectangle(formatentry.format);
      if (landscape) {
        let w = fr.width;
        fr.width = fr.height;
        fr.height = w;
      }
      graph.pageFormat = mxRectangle.fromRectangle(fr);
      graph.border = 10;
      mxGraph.prototype.pageFormat = fr;
    } else {
      mxGraph.prototype.pageFormat = this.initialformart;
      graph.pageFormat = this.initialformart;
    }
    if (format !== null && format !== PrintPageFormat.custom) {
      graph.minimumGraphSize = new mxRectangle(0, 0, graph.pageFormat.width, graph.pageFormat.height);
      graph.maximumGraphBounds = new mxRectangle(0, 0, graph.pageFormat.width, graph.pageFormat.height);
      graph.pageScale = 1;
      // graph.autoExtend = false;
      // graph.autoScroll = true;
      // graph.extendParentsOnMove = true;
      // graph.extendParentsOnAdd = true;
      graph.pageBreaksVisible = true;
    } else {
      graph.pageFormat = mxGraph.prototype.pageFormat;
      removeCookie(SemTalkCookie.printpageformat);
      removeCookie(SemTalkCookie.printlandscape);
      // (graph as any).minimumGraphSize = new mxRectangle(0, 0, graph.pageFormat.width, graph.pageFormat.height);
      // (graph as any).maximumGraphBounds = new mxRectangle(0, 0, graph.pageFormat.width, graph.pageFormat.height);
      (graph as any).minimumGraphSize = null;
      (graph as any).maximumGraphBounds = null;
      graph.pageScale = 1;
      this.graph.autoExtend = this.props.autoextend; // true;
      this.graph.ignoreScrollbars = true;
      this.graph.autoScroll = this.props.autoscroll; // true;
      this.graph.allowAutoPanning = true;
      // this.graph.autoExtend = true;
      // this.graph.autoScroll = true;
      this.graph.extendParentsOnMove = this.props.extendParentsOnMove; // true;
      this.graph.extendParentsOnAdd = this.props.extendParentsOnAdd; // true;
      // this.graph.extendParentsOnMove = true;
      // this.graph.extendParentsOnAdd = true;

    }
    this.graph.sizeDidChange();

  }

  public componentDidUpdate() {

    if ((JSON.stringify(this.headerfooterfields) !== JSON.stringify(this.props.headerfooterfields)) ||
      (this.showheaderfooterfields !== this.props.showheaderfooterfields) ||
      (this.printlandscape !== this.props.printlandscape) ||
      (this.printpageformat !== this.props.printpageformat)) {

      this.headerfooterfields = this.props.headerfooterfields;
      if (this.showheaderfooterfields !== this.props.showheaderfooterfields) {
        this.showheaderfooterfields = this.props.showheaderfooterfields;
        if (this.props.showheaderfooterfields) this.addHeaderFooter(this.headerfooterfields);
        if (!this.props.showheaderfooterfields) this.removeHeaderFooter();
      }
      this.printlandscape = this.props.printlandscape;
      this.printpageformat = this.props.printpageformat;
      this.UpdatePage(this.graph);
    }
  }

  // private mounted: boolean = false;
  public componentDidMount() {
    //const parser = new DOMParser();
    // let svgShapes: any = parser.parseFromString(this.props.svgshapes, 'text/xml');
    setVariables();
    this.headerfooterfields = this.props.headerfooterfields;
    this.showheaderfooterfields = this.props.showheaderfooterfields;
    this.printlandscape = this.props.printlandscape;
    this.printpageformat = this.props.printpageformat;
    let div: any = document.getElementById('some-empty-div-on-the-page');
    if (!div) return;
    div.innerHTML = "";
    const model: mxGraphModel = new mxGraphModel();
    // mxGraph.prototype["ordered"] = true;
    // mxGraph.prototype.pageFormat = mxConstants.PAGE_FORMAT_A4_LANDSCAPE;
    // mxGraph.prototype.pageFormat = new mxRectangle(0, 0, 1654, 1169); // A3 Landscape
    this.graph = new mxGraph(div, model);
    this.UpdatePage(this.graph);

    // this.cellCreatedFunc = this.cellCreatedFunc;
    // this.valueChangeFunc = this.valueChangeFunc;
    let support_lib = this.props.site;
    if (this.props.support && this.props.site !== ".") {
      support_lib += "/" + this.props.support;
    }
    // support_lib = "./Support";
    // mxGraph.prototype.collapsedImage = new mxImage(support_lib + '/images/collapsed.gif', 12, 12);
    // mxGraph.prototype.expandedImage = new mxImage(support_lib + '/images/expanded.gif', 12, 12);
    // mxGraph.prototype.collapseExpandResource = " !!! Collapse / Expand";
    // mxGraph.prototype.multigraph=false;
    this.graph.collapsedImage = new mxImage(support_lib + '/images/plus.png', 15, 15);
    this.graph.expandedImage = new mxImage(support_lib + '/images/minus.png', 15, 15);
    this.graph.collapseExpandResource = this.props.semtalk.getResStrListener(ResIDL.STRCOLLAPSE) +
      " / " + this.props.semtalk.getResStrListener(ResIDL.STRDLGEXPCAPTION);

    mxEvent.disableContextMenu(div); // eslint-disable-line
    mxVertexHandler.prototype.rotationEnabled = false; // eslint-disable-line
    mxConstants.VERTEX_SELECTION_STROKEWIDTH = 3;
    mxConstants.VERTEX_SELECTION_COLOR = '#000000';
    mxConstants.VERTEX_SELECTION_DASHED = false;
    mxConstants.LABEL_HANDLE_SIZE = 8;
    // mxConstants.DEFAULT_FONTSIZE = "18";
    // mxConstants.DEFAULT_FONTFAMILY = 'Helvetica, Arial, sans-serif';
    let ffs = accessCookie(SemTalkCookie.fontsize);
    if (ffs) mxConstants.DEFAULT_FONTSIZE = parseInt(ffs);
    if (mxConstants.DEFAULT_FONTSIZE < 6) mxConstants.DEFAULT_FONTSIZE = 11;
    let ff = accessCookie(SemTalkCookie.font);
    if (ff) mxConstants.DEFAULT_FONTFAMILY = ff;
    // mxConstants.WORD_WRAP = 'break-word';


    this.containerEle = div;

    //   let keyHandler = new mxKeyHandler(this.graph);
    // //  "e"
    //   keyHandler.bindKey(69, function(evt: any)
    //   {
    //     alert("xxx");
    //   });

    // mxConstants.POINTS = 1;
    // mxConstants.MILLIMETERS = 2;
    // mxConstants.INCHES = 3;
    // mxConstants.PIXELS_PER_MM = 3.937;
    // mxConstants.PIXELS_PER_INCH = 100;

    // mxConstants.SHADOW_OPACITY = 0.25;
    // mxConstants.SHADOWCOLOR = '#000000';
    // mxConstants.VML_SHADOWCOLOR = '#d0d0d0';
    // mxGraph.prototype.pageBreakColor = '#c0c0c0';
    // mxGraph.prototype.pageScale = 1;


    this.initEditor(this.graph, this.props.stencil);
    let width = this.props.width;
    let height = this.props.height;
    // this.graph.minimumGraphSize = { width: width, height: height };
    // // this.graph.setAutoSizeCells(true);


    let cb = this.props.callback;
    let mod = this.graph.model;
    this.graph.getLabel = function (cell: any) {
      let tmp = mxGraph.prototype.getLabel.apply(this, arguments); // "supercall"
      if (cb && cell.objectid) {
        return cb.getLabel(cell, tmp);
      }
      if (cell && cell.style && cell.style.startsWith("text;")) {
        return cb.getTextLabel(cell, tmp);
      }

      if (mod.isEdge(cell)) {
        return cb.getLinkLabel(cell, tmp);
      }

      return tmp;
    };

    this.graph.isCellResizable = (cell: any) => {
      if (this.props.islocked) return false;
      let geo = cell.geometry;
      // return geo === null || !geo.relative;
      if (this.props.callback && cell.objectid) {
        return this.props.callback.isCellResizable(cell);
      } else {
        return geo === null || geo === undefined || !geo.relative;
      }
    };

    this.graph.isWrapping = (state: any) => {
      if (this.props.callback && state.cell && state.cell.objectid) {
        return this.props.callback.isWrapping(state.cell);
      }
      let isedge = mod.isEdge(state.cell);
      return isedge;
    };

    this.graph.isLabelMovable = (cell: mxCell) => {
      if (this.props.islocked) return false;
      let style = cell.style;
      try {
        let res = !this.graph.isCellLocked(cell) &&
          ((this.graph.model.isEdge(cell) && (this.graph.edgeLabelsMovable || cell.value)) ||
            (this.graph.model.isVertex(cell) && (this.graph.vertexLabelsMovable ||
              getAttribute(style, SemTalkStyleAttribute.labelMovable) === "1")));
        return res;
      } catch {
        return false;
      }
    };

    this.graph.isCellEditable = (cell: mxCell) => {
      if (cell["header"]) return false;
      let editable = cb.isCellEditable(cell);
      return editable;
    };
    this.graph.isCellLocked = (cell: mxCell) => {
      if (cell["header"]) return true;
      return mxGraph.prototype.isCellLocked.apply(this.graph, arguments);
      // console.debug(cell);
      // return locked;
    };
    this.graph.isCellFoldable = (cell: mxCell) => {
      if (cell["header"]) return false;
      let editable = mxGraph.prototype.isCellFoldable.apply(this.graph, arguments);
      return editable;
    };
    this.graph.isCellSelectable = (cell: mxCell) => {
      if (cell["header"]) return false;
      let editable = mxGraph.prototype.isCellSelectable.apply(this.graph, arguments);
      return editable;
    };
    // const _getTooltipForCell = this.graph.getTooltipForCell;
    this.graph.getTooltipForCell = (cell: mxCell) => {
      if (this.props.callback) {
        return this.props.callback.getTooltipForCell(cell);
      }
      // return _getTooltipForCell(cell);
      return String(cell.value);
    };

    // this.initCustomPort('https://gw.alicdn.com/tfs/TB1PqwZzzDpK1RjSZFrXXa78VXa-200-200.png');
    // this.initCustomPort(this.props.site + '/Support/images/symbols/anchor.png');
    //  this.initCustomPort("." + '/Support/images/symbols/anchor.png');

    // let xml = accessCookie('autosaveXml');
    // if (xml === null || xml.length === 0) {
    //   xml = this.props.xmlgraph;
    // }
    let xml = this.props.xmlgraph;
    this.resetEditor(xml, this.props.diagcaption, this.props.diagid, false, false);

    // let width = this.props.width;
    // let height = this.props.height;
    // let containerEle = document.getElementById('some-empty-div-on-the-page');
    // if (containerEle) {
    //   containerEle.style.width = width + "px";
    //   containerEle.style.height = height + "px";
    // }
    // this.graph.setResizeContainer(false);

    // this.graph.pageFormat = mxConstants.PAGE_FORMAT_A4_PORTRAIT;
    // this.graph.pageFormat = mxConstants.PAGE_FORMAT_A4_LANDSCAPE;
    // this.graph.pageFormat = new mxRectangle(0, 0, 1654, 1169);

    // if (this.state.pageFormat) {
    //   this.graph.pageFormat = this.state.pageFormat;
    // }

    let containerEle = document.getElementById('some-empty-div-on-the-page');
    if (containerEle) {
      // this.graph.setResizeContainer(true);
      let wi: number = this.graph.pageFormat.width;
      let he: number = this.graph.pageFormat.height;
      if (wi < width) wi = width;
      if (he < height) he = height;
      containerEle.style.width = wi + "px";
      containerEle.style.height = he + "px";
      // this.graph.fit();
      this.graph.setResizeContainer(false);
      // this.graph.setResizeContainer(this.props.resizecontainer);
      containerEle.style.overflow = "hidden";
      // containerEle.style.overflow = "hidden";
      // containerEle.style.width = width + "px";
      // containerEle.style.height = height + "px";
      // containerEle.style.maxWidth = width + "px";
      // containerEle.style.maxHeight = height + "px";
      containerEle.addEventListener("dragover", (event) => {
        event.preventDefault();
      });

      // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
      containerEle.addEventListener("drop", (event) => {
        this.drop(event);
      });
      // if (containerEle.parentElement) containerEle.parentElement.addEventListener("paste", (event) => {
      //   this.paste(event);
      // });
    }
    // let format = accessCookie(SemTalkCookie.printpageformat);


    // this.graph.autoScroll = true;
    // this.graph.setAutoSizeCells(true);
    // this.graph.keepEdgesInForeground = true;
    this.graph.keepSelectionVisibleOnZoom = true;
    // this.graph.setSwimlaneNesting(true);
    // Enables return key to stop editing (use shift-enter for newlines)
    this.graph.setEnterStopsCellEditing(true);

    // this.graph.setEnabled(false);



    this.graph.edgeLabelsMovable = false;

    let locked = this.props.islocked;
    if (locked) {
      this.graph.cellsMovable = false;
      this.graph.cellsCloneable = false;
      this.graph.cellsEditable = false;
      this.graph.edgeLabelsMovable = false;
      this.graph.vertexLabelsMovable = false;
      this.graph.setConnectable(false);
    }
    // this.droptarget = null;
    if (containerEle) {
      containerEle.focus();
    }

    mxPanningManager.border = 20;
  }

  public updateDiaglist = (diaglist: { text: string, key: string, diag: ISemTalkDiagram }[]): void => {
    let tpl = this.props.semtalk.template;
    if (tpl !== "semtalk" && !(this.props.role === SemTalkRole.admin || this.props.role === SemTalkRole.metamodel)) {
      diaglist = diaglist.filter(x => x.diag.ClassOf().ObjectName !== SemTalkBaseConstant.SLGeneric);
    }
    this.setState({ diaglist: diaglist });
  }
  public configShapes = (graph: mxGraph, stencil: HTMLCollection[]): void => {
    const {
      stylesheet
    } = graph;
    const vertexStyle = stylesheet.getDefaultVertexStyle();
    vertexStyle[mxConstants.STYLE_STROKECOLOR] = '#B9BECC'; //eslint-disable-line
    vertexStyle[mxConstants.STYLE_FILLCOLOR] = '#ffffff'; //eslint-disable-line
    vertexStyle[mxConstants.STYLE_FONTCOLOR] = '#333'; //eslint-disable-line
    this.imageShapes = stencil;

    if (!this.props.islocked) {
      graph.setDropEnabled(true);
    }
  }
  public initEditor = (graph: mxGraph, stencil: HTMLCollection[]) => {

    graph.gridSize = 30;

    graph.setTooltips(true);
    graph.setAllowDanglingEdges(false);
    graph.setDisconnectOnMove(false);

    // Disables the built-in context menu
    mxEvent.disableContextMenu(this.containerEle); // eslint-disable-line
    if (mxClient.IS_QUIRKS) {
      document.body.style.overflow = 'hidden';
      new mxDivResizer(this.containerEle);
    }
    this.initGraph(graph);
    util.initPopupMenu(graph, this.menuFunc);
    this.initZoomConfig(graph);

    // config shapes
    this.configShapes(graph, stencil);
    this.keyListener(graph, this.keyFunc);
    if (!this.props.islocked) {
      this.handleMove(graph, this.moveCellsFunc);
      this.undoListener(graph, this.undoFunc);
      this.copyListener(graph, this.copyFunc);
      this.initClipboard();

      this.deleteListener(graph, this.deleteFunc);
      // connector handler
      this.connectorHandler(graph, this.isValid, this.props.callback);
      util.handleHover(graph, this.props.callback);
    }

    this.handleDoubleClick(graph, this.doubleClickFunc);
    this.handleClick(graph, this.clickFunc);
    this.handleChange(graph, this.selectFunc);
    // this.handleRefresh(graph, this.refreshFunc);

    if (!this.props.islocked) {
      this.handleConnect(graph, this.connectFunc);
      this.handleDisconnect(graph, this.disconnectFunc);
      this.handleCellConnected(graph, this.cellconnectedFunc);
      this.handleSplit(graph, this.splitFunc);
      this.initAutoSave(graph, this.autoSaveFunc);
      util.vertexRenameListener(this.valueChangeFunc);
    }
    this.outlinewnd = null;
    if (this.stencilwnd) {
      this.stencilwnd.setVisible(false);
    }
    this.stencilwnd = null;
    this.stencilel = null;
    this.navigatorwnd = null;
    this.navigatorel = null;
    this.plannerwnd = null;
    this.plannerel = null;
    // this.searchwnd = null;
    // this.searchel = null;

    let ispanzoom: boolean = accessCookie(SemTalkCookie.panzoom) !== '0';
    if (this.props.dockpanandzoom) {
      let outline = document.getElementById('outlineContainer');
      new mxOutline(graph, outline);
    } else {
      if (ispanzoom) {
        this.showOutline(graph, this.props.panandzoomcaption);
      } else {
        // if (this.outlinewnd) {
        //   this.outlinewnd.setVisible(false);
        // }
      }
    }
    let cookie = accessCookie(SemTalkCookie.stencil);
    let isstencil: boolean = (cookie === '1');
    if (!this.props.isplanmaker && cookie === null) {
      setCookie(SemTalkCookie.stencil, '1');
      this.UpdatePortalStencil(stencil);
      isstencil = true;
      this.setState({
        isstencil: true
      });
    }
    if (isstencil && !this.props.dockstencil && !this.props.islocked) {
      this.showStencil();
    } else {
      // if (this.stencilwnd) {
      //   this.stencilwnd.setVisible(false);
      // }
    }

    let isnavigator: boolean = accessCookie(SemTalkCookie.navigator) === '1';
    if (isnavigator && !this.props.docknavigator) {
      this.showNavigator();
    } else {
      // if (this.navigatorwnd) {
      //   this.navigatorwnd.setVisible(false);
      // }
    }
    if (!this.props.isplanmaker) {
      let isplanner: boolean = accessCookie(SemTalkCookie.planner) === '1';
      if (isplanner && !this.props.dockplanner) {
        this.showPlanner();
      } else {
        // if (this.plannerwnd) {
        //   this.plannerwnd.setVisible(false);
        // }
      }
    }
    if (!this.props.isplanmaker) {
      let ispivot: boolean = accessCookie(SemTalkCookie.pivot) === '1';
      if (ispivot && !this.props.dockpivot) {
        this.showPivot();
      } else {
        if (this.pivotwnd) {
          this.pivotwnd.setVisible(false);
        }
      }
    }
    // let issearch: boolean = accessCookie(SemTalkCookie.search) === '1';
    // if (issearch && !this.props.docksearch) {
    //   this.showSearch();
    // } else {
    //   // if (this.navigatorwnd) {
    //   //   this.navigatorwnd.setVisible(false);
    //   // }
    // }


    // graph.cellEditor.autoSize=false;
    // graph.cellEditor.getEditorBounds = function (state) {
    //   let result = mxCellEditor.prototype.getEditorBounds.apply(this, arguments);
    //   console.debug(state.cell);
    //   // if (this.graph.getModel().isEdge(state.cell)) {
    //   //   result.x = state.getCenterX() - result.width / 2;
    //   //   result.y = state.getCenterY() - result.height / 2;
    //   // }

    //   return result;
    // };
  }
  private setmxWindowIconTitleHandlers(wnd: any) {
    if (wnd.minimize) {
      wnd.minimize.setAttribute('title', this.props.semtalk.getResStrListener('STRMINIMIZE'));
    }
    wnd.addListener(mxEvent.MINIMIZE, (_e: any) => {
      if (wnd.minimize) {
        wnd.minimize.setAttribute('title', this.props.semtalk.getResStrListener('STRNORMALIZE'));
      }
    });
    wnd.addListener(mxEvent.NORMALIZE, (_e: any) => {
      if (wnd.minimize) {
        wnd.minimize.setAttribute('title', this.props.semtalk.getResStrListener('STRMINIMIZE'));
      }
    });
    if (wnd.resize) {
      wnd.resize.setAttribute('title', this.props.semtalk.getResStrListener('STRRESIZE'));
    }
    if (wnd.closeImg) {
      wnd.closeImg.setAttribute('title', this.props.semtalk.getResStrListener('STRCLOSE'));
    }
  }
  private theme = getTheme();

  private showStencil = () => {
    let create = this.stencilwnd == null;
    if (create) {
      let div = document.createElement('div');
      div.style.overflow = 'hidden';
      div.style.position = 'relative';
      div.style.width = '100%';
      div.style.height = '100%';
      div.style.background = this.theme.palette.white;
      let tit = this.props.semtalk.getResStrListener(ResIDL.STRSTENCIL).replace(":", "");
      let x = accessCookie(SemTalkCookie.stencilX);
      let y = accessCookie(SemTalkCookie.stencilY);
      if (x !== null) {
        x = parseInt(x);
      } else {
        x = 100;
      }
      if (y !== null) {
        y = parseInt(y);
      } else {
        y = 100;
      }
      x = Math.min(window.innerWidth - 250, x);
      y = Math.min(window.innerHeight - 500, y);
      let wnd = new mxWindow(tit,
        div, x, y, 220, 500, true, true);
      if (wnd) {
        this.stencilel = div;
        wnd.setClosable(true);
        wnd.setResizable(true);
        wnd.destroyOnClose = false;
        let ff = accessCookie(SemTalkCookie.guifont);
        if (ff) {
          wnd.title.style.fontFamily = ff;
        }
        wnd.title.style.background = this.theme.palette.white;
        this.setmxWindowIconTitleHandlers(wnd);
        wnd.addListener(mxEvent.CLOSE, (_e: any) => {
          let st = this.state.isstencil;
          if (st) {
            setCookie(SemTalkCookie.stencil, '0');
          } else {
            setCookie(SemTalkCookie.stencil, '1');
          }
          this.setState({
            isstencil: !st
          });
        });
        wnd.addListener(mxEvent.MOVE, (_e: any) => {
          let dx = Math.max(wnd.getX(), 0);
          let dy = wnd.getY();
          // console.debug(dx + " " +dy);
          if (dy < 100) {
            dy = 100;
            wnd.setLocation(dx, dy);
          }
          setCookie(SemTalkCookie.stencilX, dx);
          setCookie(SemTalkCookie.stencilY, dy);
        });
        this.stencilwnd = wnd;
      }
    }
    if (this.stencilwnd) {
      this.stencilwnd.setVisible(true);
    }

  }
  private showNavigator = () => {
    let create = this.navigatorwnd == null;
    if (create) {
      let div = document.createElement('div');
      div.style.overflow = 'hidden';
      div.style.position = 'relative';
      div.style.width = '100%';
      div.style.height = '100%';
      div.style.background = this.theme.palette.white;
      let tit = "Navigator";
      let x = accessCookie(SemTalkCookie.navigatorX);
      let y = accessCookie(SemTalkCookie.navigatorY);
      let w = accessCookie(SemTalkCookie.navigatorW);
      let h = accessCookie(SemTalkCookie.navigatorH);
      if (x !== null) {
        x = parseInt(x);
      } else {
        x = 100;
      }
      if (y !== null) {
        y = parseInt(y);
      } else {
        y = 100;
      }
      if (w !== null) {
        w = parseInt(w);
      } else {
        w = 240;
      }
      if (h !== null) {
        h = parseInt(h);
      } else {
        h = 280;
      }
      let wnd = new mxWindow(tit,
        div, x, y, w, h, true, true);
      if (wnd) {
        this.navigatorel = div;
        wnd.setClosable(true);
        wnd.setResizable(true);
        wnd.destroyOnClose = false;
        let ff = accessCookie(SemTalkCookie.guifont);
        if (ff) {
          wnd.title.style.fontFamily = ff;
        }
        wnd.title.style.background = this.theme.palette.white;
        this.setmxWindowIconTitleHandlers(wnd);

        wnd.addListener(mxEvent.CLOSE, (_e: any) => {
          let st = this.state.isnavigator;
          if (st) {
            setCookie(SemTalkCookie.navigator, '0');
          } else {
            setCookie(SemTalkCookie.navigator, '1');
          }
          this.setState({
            isnavigator: !st
          });
        });
        wnd.addListener(mxEvent.MOVE, (_e: any) => {
          let dx = Math.max(wnd.getX(), 0);
          let dy = wnd.getY();
          // console.debug(dx + " " +dy);
          if (dy < 100) {
            dy = 100;
            wnd.setLocation(dx, dy);
          }
          setCookie(SemTalkCookie.navigatorX, dx);
          setCookie(SemTalkCookie.navigatorY, dy);
        });
        wnd.addListener(mxEvent.RESIZE, (_e: any) => {
          // console.debug(e);
          setCookie(SemTalkCookie.navigatorW, Math.max(wnd.getElement().clientWidth, 100));
          setCookie(SemTalkCookie.navigatorH, Math.max(wnd.getElement().clientHeight, 100));
        });
        this.navigatorwnd = wnd;
      }
    }
    if (this.navigatorwnd) {
      this.navigatorwnd.setVisible(true);
    }
  }
  private showPlanner = () => {
    let create = (this.plannerwnd == null);
    if (create) {
      let div = document.createElement('div');
      div.style.overflow = 'hidden';
      div.style.position = 'relative';
      div.style.width = '100%';
      div.style.height = '100%';
      div.style.background = this.theme.palette.white;
      let tit = this.props.semtalk.getResStrListener(ResIDL.STRPLANNER);
      let x = accessCookie(SemTalkCookie.plannerX);
      let y = accessCookie(SemTalkCookie.plannerY);
      if (x !== null) {
        x = parseInt(x);
      } else {
        x = 100;
      }
      if (y !== null) {
        y = parseInt(y);
      } else {
        y = 100;
      }
      let wnd = new mxWindow(tit,
        div, x, y, 400, 800, true, true);
      if (wnd) {
        this.plannerel = div;
        wnd.setClosable(true);
        wnd.setResizable(true);
        wnd.destroyOnClose = false;
        let ff = accessCookie(SemTalkCookie.guifont);
        if (ff) {
          wnd.title.style.fontFamily = ff;
        }
        wnd.title.style.background = this.theme.palette.white;
        this.setmxWindowIconTitleHandlers(wnd);

        wnd.addListener(mxEvent.CLOSE, (_e: any) => {
          // let st = this.state.isplanner;
          // if (!st) {
          setCookie(SemTalkCookie.planner, '0');
          // } else {
          //   setCookie(SemTalkCookie.planner, '1');
          // }
          this.setState({
            isplanner: false
          });
        });
        wnd.addListener(mxEvent.MOVE, (_e: any) => {
          let dx = Math.max(wnd.getX(), 0);
          let dy = wnd.getY();
          // console.debug(dx + " " +dy);
          if (dy < 100) {
            dy = 100;
            wnd.setLocation(dx, dy);
          }
          setCookie(SemTalkCookie.plannerX, dx);
          setCookie(SemTalkCookie.plannerY, dy);
        });

        this.plannerwnd = wnd;
      }
    }
    if (this.plannerwnd) {
      this.plannerwnd.setVisible(true);
    }
  }
  private showPivot = () => {
    let create = (this.pivotwnd == null);
    if (create) {
      let div = document.createElement('div');
      div.style.overflow = 'hidden';
      div.style.position = 'relative';
      div.style.width = '100%';
      div.style.height = '100%';
      div.style.background = this.theme.palette.white;
      let tit = this.props.semtalk.getResStrListener(ResIDL.STRDLGATTPROP);
      let x = accessCookie(SemTalkCookie.pivotX);
      let y = accessCookie(SemTalkCookie.pivotY);
      if (x !== null) {
        x = parseInt(x);
      } else {
        x = 100;
      }
      if (y !== null) {
        y = parseInt(y);
      } else {
        y = 100;
      }
      let wnd = new mxWindow(tit,
        div, x, y, 300, 800, true, true);
      if (wnd) {
        this.plannerel = div;
        wnd.setClosable(true);
        wnd.setResizable(true);
        wnd.destroyOnClose = false;
        let ff = accessCookie(SemTalkCookie.guifont);
        if (ff) {
          wnd.title.style.fontFamily = ff;
        }
        wnd.title.style.background = this.theme.palette.white;
        this.setmxWindowIconTitleHandlers(wnd);

        wnd.addListener(mxEvent.CLOSE, (_e: any) => {
          let st = this.state.ispivot;
          if (!st) {
            setCookie(SemTalkCookie.pivot, '0');
          } else {
            setCookie(SemTalkCookie.pivot, '1');
          }
          this.setState({
            ispivot: false
          });
        });
        wnd.addListener(mxEvent.MOVE, (_e: any) => {
          setCookie(SemTalkCookie.pivotX, Math.max(wnd.getX(), 100));
          setCookie(SemTalkCookie.pivotY, Math.max(wnd.getY(), 100));
        });

        this.pivotwnd = wnd;
      }
    }
    if (this.pivotwnd) {
      this.pivotwnd.setVisible(true);
    }
  }

  // private showSearch = () => {
  //   let create = (this.searchwnd == null);
  //   if (create) {
  //     let div = document.createElement('div');
  //     div.style.overflow = 'hidden';
  //     div.style.position = 'relative';
  //     div.style.width = '100%';
  //     div.style.height = '100%';
  //     div.style.background = this.theme.palette.white;
  //     let tit = this.props.semtalk.getResStrListener(ResIDL.STRDLGFINDCAPTION);
  //     let x = accessCookie(SemTalkCookie.plannerX);
  //     let y = accessCookie(SemTalkCookie.plannerY);
  //     if (x !== null) {
  //       x = parseInt(x);
  //     } else {
  //       x = 100;
  //     }
  //     if (y !== null) {
  //       y = Math.max(parseInt(y), 100);
  //     } else {
  //       y = 100;
  //     }
  //     let wnd = new mxWindow(tit,
  //       div, x, y, 300, 800, true, true);
  //     if (wnd) {
  //       this.searchel = div;
  //       wnd.setClosable(true);
  //       wnd.setResizable(true);
  //       wnd.destroyOnClose = false;
  //       let ff = accessCookie(SemTalkCookie.guifont);
  //       if (ff) {
  //         wnd.title.style.fontFamily = ff;
  //       }
  //       wnd.title.style.background = this.theme.palette.white;
  //       this.setmxWindowIconTitleHandlers(wnd);

  //       wnd.addListener(mxEvent.CLOSE, (_e: any) => {
  //         // let st = this.state.isplanner;
  //         // if (!st) {
  //         setCookie(SemTalkCookie.search, '0');
  //         // } else {
  //         //   setCookie(SemTalkCookie.search, '1');
  //         // }
  //         this.setState({
  //           issearch: false
  //         });
  //       });
  //       wnd.addListener(mxEvent.MOVE, (_e: any) => {
  //         setCookie(SemTalkCookie.searchX, Math.max(wnd.getX(), 100));
  //         setCookie(SemTalkCookie.searchY, Math.max(wnd.getY(), 100));
  //       });

  //       this.searchwnd = wnd;
  //     }
  //   }
  //   if (this.searchwnd) {
  //     this.searchwnd.setVisible(true);
  //   }
  // }

  private showOutline = (graph: mxGraph, title: string) => {
    var create = this.outlinewnd == null;

    if (create) {
      var div = document.createElement('div');

      div.style.overflow = 'hidden';
      div.style.position = 'relative';
      div.style.width = '100%';
      div.style.height = '100%';
      div.style.background = this.theme.palette.white;
      div.style.cursor = 'move';
      // div.style.opacity = '0';

      // if (document.documentMode == 8) {
      //   div.style.filter = 'progid:DXImageTransform.Microsoft.alpha(opacity=100)';
      // }

      let x = accessCookie(SemTalkCookie.panzoomX);
      let y = accessCookie(SemTalkCookie.panzoomY);
      if (x !== null) {
        x = parseInt(x);
      } else {
        x = 300;
      }
      if (y !== null) {
        y = parseInt(y);
      } else {
        y = 300;
      }
      x = Math.min(window.innerWidth - 200, x);
      y = Math.min(window.innerHeight - 200, y);
      let wnd: mxWindow = new mxWindow(
        title,
        div, x, y, 200, 200, false);

      // Creates the outline in the specified div
      // and links it to the existing graph
      var outline = new mxOutline(graph, div);
      wnd.setClosable(true);
      wnd.setMinimizable(true);
      wnd.setResizable(true);
      wnd.destroyOnClose = false;

      let ff = accessCookie(SemTalkCookie.guifont);
      if (ff) {
        wnd.title.style.fontFamily = ff;
      }
      wnd.title.style.background = this.theme.palette.white;
      this.setmxWindowIconTitleHandlers(wnd);

      wnd.addListener(mxEvent.CLOSE, (_e: any) => {
        let st = this.state.ispanzoom;
        if (st) {
          setCookie(SemTalkCookie.panzoom, '0');
        } else {
          setCookie(SemTalkCookie.panzoom, '1');
        }
        this.setState({
          ispanzoom: !st
        });
      });
      wnd.addListener(mxEvent.MOVE, (_e: any) => {
        let dx = Math.max(wnd.getX(), 0);
        let dy = wnd.getY();
        // console.debug(dx + " " +dy);
        if (dy < 100) {
          dy = 100;
          wnd.setLocation(dx, dy);
        }
        setCookie(SemTalkCookie.panzoomX, dx);
        setCookie(SemTalkCookie.panzoomY, dy);
      });
      wnd.addListener(mxEvent.RESIZE_END, () => {
        outline.update();
      });

      this.outlinewnd = wnd;
      (this.outlinewnd as any)["outline"] = outline;
    }

    // Finally shows the outline
    if (this.outlinewnd) {
      this.outlinewnd.setVisible(true);
      (this.outlinewnd as any)["outline"].update(true);
    }
  }
  public suspendOutline = (hide: boolean) => {
    if (this.outlinewnd) {
      let outline: any = (this.outlinewnd as any)["outline"];
      if (outline) {
        if (hide) {
          outline.suspended = true;
        } else {
          outline.suspended = false;
          outline.update(true);
        }
      }
    }
  }

  private connectorHandler(graph: any, isvalidfn: any, callback: ISemTalkOnline) {
    if (!this.props.islocked) {
      graph.setConnectable(true);
      mxGraphHandler.prototype.guidesEnabled = true; //eslint-disable-line
      mxEdgeHandler.prototype.snapToTerminals = true;
      // Disables automatic handling of ports. This disables the reset of the
      // respective style in mxGraph.cellConnected. Note that this feature may
      // be useful if floating and fixed connections are combined.
      // graph.setPortsEnabled(true);
      graph.setPortsEnabled(false);
      // mxConstants.DEFAULT_HOTSPOT = 1;
      // mxConnectionHandler.prototype.connectImage = new mxImage('images/green-dot.gif', 14, 14);
    }

    // Disables floating connections (only connections via ports allowed)
    graph.connectionHandler.isConnectableCell = (cell: any): boolean => { //eslint-disable-line
      if (this.props.islocked) return false;
      if (cell && cell["header"]) return false;
      //eslint-disable-line
      return true;
    };
    // validation function.Need to set id for each cell.
    graph.connectionHandler.validateConnection = (source: any, target: any) => {
      if (this.props.islocked) return false;
      let isvalid: boolean = false;
      if (source !== null && target !== null) {
        if (target.shapeKey === "Comment") {
          return true;
        }
        if (source.shapeKey === "Comment" && source.id !== target.id) {
          return null;
        }
        //  Keine Kanten auf sich selbst. Hier könnte ein Setting sein
        if (!this.props.reflexivelinks && source.objectid === target.objectid) {
          return true;
        }
        isvalid = isvalidfn(source, target);
      }
      if (!source.objectid && !target.objectid) {
        return null;
      }
      if (isvalid) {
        return null;
      } else {
        // return "No valid relation found!";
        return true;
      }
    };
    // graph.connectionHandler.isValidTarget = (celsl: any) => {
    //   let isvalid: boolean = false;
    //   let src: any = null;
    //   if (this.state != null && this.model.isVertex(this.state.cell)) {
    //     src = this.state.cell;
    //   }
    //   if (src == null) {
    //     src = cell;
    //     isvalid = false;
    //   }
    //   if (src !== null && cell !== null) {
    //     if (src.objectid !== cell.objectid) {
    //       isvalid = callback(src.objectid, cell.objectid);
    //       // console.debug(src);
    //       // console.debug(cell);
    //       return isvalid;
    //     }
    //   }
    //   return false;
    // };
    // graph.connectionHandler.isValidTarget = (cell: any) => {
    //   let isvalid: boolean = false;
    //   let src: any = null;
    //   if (graph.state != null && graph.model.isVertex(graph.state.cell)) {
    //     src = graph.state.cell;
    //   }
    //   if (src == null) {
    //     src = cell;
    //     isvalid = false;
    //   }
    //   if (src !== null && cell !== null) {
    //     if (src.objectid !== cell.objectid) {
    //       isvalid = callback(src.objectid, cell.objectid);
    //       // console.debug(src);
    //       // console.debug(cell);
    //       return isvalid;
    //     }
    //   }
    //   return false;
    // };
    // edge animation

    // const selectCells = mxConnectionHandler.prototype.selectCells;

    // graph.connectionHandler.selectCells = function (edge: any, target: any) {
    //   var state = this.graph.view.getState(edge);

    //   state.shape.node.getElementsByTagName('path')[0].removeAttribute('visibility');
    //   state.shape.node.getElementsByTagName('path')[0].setAttribute('stroke-width', '6');
    //   state.shape.node.getElementsByTagName('path')[0].setAttribute('stroke', 'lightGray');
    //   state.shape.node.getElementsByTagName('path')[1].setAttribute('class', 'flow');

    //   // return selectCells.apply(this, arguments);
    //   return selectCells(edge, target);
    // };

    mxEdgeHandler.prototype.isConnectableCell = (cell: any) => { //eslint-disable-line
      if (this.props.islocked) return false;
      //eslint-disable-line
      // if (cell !== null) {
      //   console.debug(cell);
      // }
      return graph.connectionHandler.isConnectableCell(cell);
    };

    // Overridden to define per-shape connection points
    mxGraph.prototype.getAllConnectionConstraints = ( //eslint-disable-line
      terminal: any,
      source: any //eslint-disable-line
    ) => {

      if (!callback.isConnectionPoints()) {
        return [];
      }
      if (source) {
        return [];
      }
      //eslint-disable-line
      if (terminal && terminal.shape && terminal.shape.constraintsX) {
        // console.debug("XXX");
        return terminal.shape.constraintsX;
      }

      // return null;

      const {
        cell
      } = terminal;

      // const {
      //   pointType
      // } = cell;

      if (cell.disabled) {
        return [];
      }

      // let points: mxConnectionConstraint[] = [];

      // switch (pointType) {
      //   case 'top':
      //     points = [
      //       new mxConnectionConstraint(new mxPoint(0.5, 0), true), //eslint-disable-line
      //     ];
      //     break;
      //   case 'left':
      //     points = [
      //       new mxConnectionConstraint(new mxPoint(0, 0.5), true), //eslint-disable-line
      //     ];
      //     break;
      //   case 'right':
      //     points = [
      //       new mxConnectionConstraint(new mxPoint(1, 0.5), true), //eslint-disable-line
      //     ];
      //     break;
      //   case 'bottom':
      //     points = [
      //       new mxConnectionConstraint(new mxPoint(0.5, 1), true), //eslint-disable-line
      //     ];
      //     break;
      //   case 'none':
      //     points = [];
      //     break;
      //   default:
      //     points = [
      //       new mxConnectionConstraint(new mxPoint(0.5, 0.5), true), //eslint-disable-line
      //      // new mxConnectionConstraint(new mxPoint(0.25, 0), true), //eslint-disable-line
      //       new mxConnectionConstraint(new mxPoint(0.5, 0), true), //eslint-disable-line
      //       // new mxConnectionConstraint(new mxPoint(0.75, 0), true), //eslint-disable-line
      //       // new mxConnectionConstraint(new mxPoint(0, 0.25), true), //eslint-disable-line
      //       new mxConnectionConstraint(new mxPoint(0, 0.5), true), //eslint-disable-line
      //       // new mxConnectionConstraint(new mxPoint(0, 0.75), true), //eslint-disable-line
      //       // new mxConnectionConstraint(new mxPoint(1, 0.25), true), //eslint-disable-line
      //       new mxConnectionConstraint(new mxPoint(1, 0.5), true), //eslint-disable-line
      //       // new mxConnectionConstraint(new mxPoint(1, 0.75), true), //eslint-disable-line
      //       // new mxConnectionConstraint(new mxPoint(0.25, 1), true), //eslint-disable-line
      //       new mxConnectionConstraint(new mxPoint(0.5, 1), true), //eslint-disable-line
      //       // new mxConnectionConstraint(new mxPoint(0.75, 1), true), //eslint-disable-line
      //     ];
      //     break;
      // }

      // // return null;
      // return points;

    };

    // Defines the default constraints for all shapes
    // mxShape.prototype.constraints = [ //eslint-disable-line
    //   //eslint-disable-line
    //   new mxConnectionConstraint(new mxPoint(0.5, 0.5), true), //eslint-disable-line
    //   // new mxConnectionConstraint(new mxPoint(0.25, 0), true),
    //   new mxConnectionConstraint(new mxPoint(0.5, 0), true), //eslint-disable-line
    //   // new mxConnectionConstraint(new mxPoint(0.75, 0), true),
    //   // new mxConnectionConstraint(new mxPoint(0, 0.25), true),
    //   new mxConnectionConstraint(new mxPoint(0, 0.5), true), //eslint-disable-line
    //   // new mxConnectionConstraint(new mxPoint(0, 0.75), true),
    //   // new mxConnectionConstraint(new mxPoint(1, 0.25), true),
    //   new mxConnectionConstraint(new mxPoint(1, 0.5), true), //eslint-disable-line
    //   // new mxConnectionConstraint(new mxPoint(1, 0.75), true),
    //   // new mxConnectionConstraint(new mxPoint(0.25, 1), true),
    //   new mxConnectionConstraint(new mxPoint(0.5, 1), true), //eslint-disable-line
    //   // new mxConnectionConstraint(new mxPoint(0.75, 1), true)
    // ];
    // mxShape.prototype.constraints = null;  //eslint-disable-line

    // Edges have no connection points
    mxPolyline.prototype.constraints = null; //eslint-disable-line

    // Enables connect preview for the default edge style
    graph.connectionHandler.createEdgeState = () => {
      const edge = graph.createEdge(null, null, null, null, null);

      return new mxCellState(graph.view, edge, graph.getCellStyle(edge)); //eslint-disable-line
    };

    // Changes the default style for edges "in-place" and assigns
    // an alternate edge style which is applied in mxGraph.flip
    // when the user double clicks on the adjustment control point
    // of the edge. The ElbowConnector edge style switches to TopToBottom
    // if the horizontal style is true.
    // const style = graph.getStylesheet().getDefaultEdgeStyle();
    // style[mxConstants.STYLE_ROUNDED] = true; //eslint-disable-line
    // style[mxConstants.STYLE_EDGE] = mxEdgeStyle.ElbowConnector; // 备选：orthogonalEdgeStyle
    // style[mxConstants.STYLE_EDGE] = 'orthogonalEdgeStyle'; //eslint-disable-line
    // style[mxConstants.STYLE_STROKEWIDTH] = 1;

    // graph.alternateEdgeStyle = 'elbow=vertical';

    // Snaps to fixed points
    mxConstraintHandler.prototype.intersects = ( //eslint-disable-line
      icon: any,
      point: any,
      source: any,
      existingEdge: any
    ) => {
      //eslint-disable-line
      return !source || existingEdge || mxUtils.intersects(icon.bounds, point); //eslint-disable-line
    };
  }
  /**
  * init graph
  * @param {graph} config
  */
  private initGraph(graph: mxGraph) {
    // // Enables HTML labels
    graph.setHtmlLabels(true);
    graph.gridSize = 10;
    graph.allowNegativeCoordinates = this.props.allownegativecoordinates;
    if (!this.props.islocked) {

      graph.setConnectable(true);
      // graph.setPortsEnabled(false);
      graph.connectionHandler.createTarget = false;
      graph.setCellsCloneable(true);
      // let view = graph.getView();
      // let bg = graph.view.createBackgroundPageShape(graph.pageFormat);
      // if (bg) {
      //   bg.fill = 'red';
      //   bg.opacity = 20;
      //   this.backgroundpageshape = bg;
      //   console.debug(this.backgroundpageshape);
      //   bg.scale = view.scale;
      //   bg.isShadow = true;
      //   bg.dialect = view.graph.dialect;
      //   bg.init(view.getBackgroundPane());
      //   bg.redraw();
      //   // bg.destroy();
      //   // this.createVertex(bg,"X","X",10,10,20,30);
      // }

      graph.pageVisible = false;

      // graph.setBackgroundImage();
      // graph.border = 80;
      graph.getView().translate = new mxPoint(graph.border / 2, graph.border / 2);
      this.graph.setResizeContainer(false);
      // graph.setResizeContainer(this.props.resizecontainer);
      // graph.container.setAttribute('tabindex', '-1');
      graph.container.focus();
      new mxRubberband(graph);
    }
    graph.getView().translate = new mxPoint(graph.border / 2, graph.border / 2);
  }

  public preview(xml: any, div: string) {
    let outline = document.getElementById(div);
    return this.renderNewGraphFromXml(xml, outline);
    // new mxOutline(graph, outline);
  }

  public initSidebar = (sidebarItems: any[]) => {
    let graph = this.graph;

    if (!graph) return;
    if (this.props.islocked) return;

    // sidebarItems,
    let cellCreatedFunc = this.cellCreatedFunc;
    let callback = this.props.callback;

    sidebarItems
      &&
      sidebarItems.forEach((item: any) => {
        const width = item.getAttribute('data-shape-width');
        const height = item.getAttribute('data-shape-height');
        const shapeType = item.getAttribute('data-shape-type');
        const shapeName = item.getAttribute('data-shape-name');
        const shapeSubkey = item.getAttribute('data-shape-subkey');
        const shapeLabel = item.getAttribute('data-shape-label');
        const shapeContent = item.getAttribute('data-shape-content');

        let ss = callback.GetShapeStyle(shapeName, shapeType);
        let isEdge = ss.isedge;
        let shapeStyle = ss.style;

        this.createDragableItem({
          id: null,
          node: item,
          width,
          height,
          shapeName,
          shapeSubkey,
          shapeLabel,
          shapeContent,
          graph,
          isEdge,
          shapeStyle,
          cellCreatedFunc,
        });
      });
  }
  private createDragableItem(config: any) {
    const {
      graph,
      node,
      shapeName,
      shapeSubkey,
      shapeStyle,
      shapeLabel,
      // shapeContent,
      id,
      isEdge,
      cellCreatedFunc,
    } = config;

    let {
      width,
      height
    } = config;

    // console.debug(config);

    width = width * 1 || 130;
    height = height * 1 || 90;

    // Returns the graph under the mouse
    const graphF = (evt: any) => {
      const x1 = mxEvent.getClientX(evt); //eslint-disable-line
      const y1 = mxEvent.getClientY(evt); //eslint-disable-line
      const elt = document.elementFromPoint(x1, y1);

      if (mxUtils.isAncestorNode(graph.container, elt)) { //eslint-disable-line
        //eslint-disable-line
        return graph;
      }

      return null;
    };

    // Inserts a cell at the given location
    let funct = (graph2: any, evt: any, target: any, x2: number, y2: number) => {
      try {
        // is a edge
        if (isEdge) {
          const cell = new mxCell( //eslint-disable-line
            '',
            new mxGeometry(0, 0, width, height), //eslint-disable-line
            shapeStyle
          ); //eslint-disable-line
          cell.geometry.setTerminalPoint(new mxPoint(0, height), true); //eslint-disable-line
          cell.geometry.setTerminalPoint(new mxPoint(width, 0), false); //eslint-disable-line
          // cell.geometry.points = [new mxPoint(width/2, height/2), new mxPoint(0, 0)];
          cell.geometry.relative = true;
          cell.edge = true;
          cell.shapeKey = shapeName;
          // cell.shapeName = shapeLabel;
          cell.shapeName = shapeName;
          cell.shapeSubkey = shapeSubkey;
          cell.shapeLabel = shapeLabel;
          cell.id = id;

          const cells = graph2.importCells([cell], x2, y2 - evt.offsetY, target);

          if (cells != null && cells.length > 0) {
            graph2.scrollCellToVisible(cells[0]);
            graph2.setSelectionCells(cells);
          }

          cellCreatedFunc && cellCreatedFunc(cell);
        } else {
          // let parent = graph2.getDefaultParent();

          // let oth = graph.getCellAt(x2, y2, parent, true, false, getSwimlaneDropTarget);
          // if (oth) {
          //   parent = oth;
          //   x2 -= oth.geometry.x;
          //   y2 -= oth.geometry.y;
          //   // foo
          // }
          // //  console.debug(oth);
          // //  console.debug(parent);
          if (target && target.edge) {
            const cell = new mxCell( //eslint-disable-line
              '',
              new mxGeometry(0, 0, width, height), //eslint-disable-line
              shapeStyle);
            cell.vertex = true;
            //eslint-disable-line
            // const cell = graph2.insertVertex(
            //   null,
            //   id,
            //   shapeLabel,
            //   x2,
            //   y2,
            //   width,
            //   height,
            //   shapeStyle
            // );
            // console.debug(cell);
            cell.shapeKey = shapeName;
            cell.shapeName = shapeLabel;
            // cell.shapeName = shapeName;
            cell.shapeSubkey = shapeSubkey;
            cell.shapeLabel = shapeLabel;
            cellCreatedFunc && cellCreatedFunc(cell);
            if (graph.isSplitEnabled() && this.props.semtalk.visIsSplitTarget(target, [cell], evt)) {
              (this.graph as any).splitEdge(target, [cell], null, 0, 0);
              return;
            } else {
              this.graph.removeCells([cell], true);
            }
          }
          let parent = target;
          if (parent) {
            x2 -= parent.geometry.x;
            y2 -= parent.geometry.y;

            let targetparent = target.parent;
            if (targetparent && targetparent.geometry) {
              x2 -= targetparent.geometry.x;
              y2 -= targetparent.geometry.y;

              let targetparentparent = targetparent.parent;
              if (targetparentparent && targetparentparent.geometry) {
                x2 -= targetparentparent.geometry.x;
                y2 -= targetparentparent.geometry.y;
              }
            }
          } else {
            parent = graph2.getDefaultParent();
          }

          if (shapeName === SemTalkMaster.MasterSwimlane) {
            let vs = graph.getStylesheet().getCellStyle(mxConstants.SHAPE_SWIMLANE);
            let isv = vs[mxConstants.STYLE_HORIZONTAL];
            if (isv) {
              let w = width;
              width = height;
              height = w;

            }
          }

          if (parent) {
            const cell = graph2.insertVertex(
              parent,
              id,
              shapeLabel,
              x2,
              y2,
              width,
              height,
              shapeStyle
            );
            // console.debug(cell);
            cell.shapeKey = shapeName;
            cell.shapeName = shapeLabel;
            // cell.shapeName = shapeName;
            cell.shapeSubkey = shapeSubkey;
            cell.shapeLabel = shapeLabel;

            cellCreatedFunc && cellCreatedFunc(cell);
          } else {
            console.log('graph.getDefaultParent() 为 null');
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    // Disables built-in DnD in IE (this is needed for cross-frame DnD, see below)
    if (mxClient.IS_IE) { //eslint-disable-line
      //eslint-disable-line
      mxEvent.addListener(node, 'dragstart', (evt: any) => { //eslint-disable-line
        //eslint-disable-line
        evt.returnValue = false;
      });
    }

    // Creates the element that is being for the actual preview.
    const dragElt = document.createElement('div');
    dragElt.style.border = 'dashed black 1px';
    dragElt.style.width = `${width}px`;
    dragElt.style.height = `${height}px`;

    // Drag source is configured to use dragElt for preview and as drag icon
    // if scalePreview (last) argument is true. Dx and dy are null to force
    // the use of the defaults. Note that dx and dy are only used for the
    // drag icon but not for the preview.
    // const ds = mxUtils.makeDraggable( //eslint-disable-line
    //   //eslint-disable-line
    //   node,
    //   graphF,
    //   funct,
    //   dragElt,
    //   null,
    //   null,
    //   graph.autoscroll,
    //   true
    // );
    const getSwimlaneDropTarget = (_graph2: mxGraph, x: number, y: number,
      evt: any) => {
      let cell: mxCell | null = graph.getCellAt(x, y);
      if (cell) {
        // console.debug(cell);
        if (cell.shapeKey === "group" || cell.shapeKey === SemTalkMaster.MasterSwimlane) {
          return cell;
        } else {
          if (cell.edge) {
            if (this.props.semtalk.visIsSplitTarget(cell, [], evt)) {
              return cell;
            }
          }
          return null;
        }
      }
      return cell;
    };

    const ds = mxUtils.makeDraggable( //eslint-disable-line
      //eslint-disable-line
      node,
      graphF,
      funct,
      dragElt,
      null,
      null,
      graph.autoscroll,
      true,
      true,
      getSwimlaneDropTarget
    );
    // Redirects feature to global switch. Note that this feature should only be used
    // if the the x and y arguments are used in funct to insert the cell.
    ds.isGuidesEnabled = () => graph.graphHandler.guidesEnabled;

    // Restores original drag icon while outside of graph
    ds.createDragElement = mxDragSource.prototype.createDragElement; //eslint-disable-line
  }
  public initCustomPort = (pic: any): void => {
    mxConstraintHandler.prototype.pointImage = new mxImage(pic, 10, 10);
  }
  public zoom = (type: zoomoption): void => {
    const graph = this.graph;
    switch (type) {
      case 0:
        graph.zoomIn();
        break;
      case 1:
        graph.zoomOut();
        break;
      case 2:
        graph.fit(0);
        break;
      case 3:
        graph.zoomActual();
        break;
      default:
        break;
    }

    const cellsSelected = graph.getSelectionCells();
    if (cellsSelected.length > 0) {
      graph.scrollCellToVisible(cellsSelected[0], true);
    }
    // graph.refresh();
  }
  public resetView = (scale: number) => {
    this.graph.view.scaleAndTranslate(scale, 0, 0);
  }
  public center = (): void => {
    this.graph.center();
  }

  /*   * Constructor: mxPrintPreview
    *
    * Constructs a new print preview for the given parameters.
    *
    * Parameters:
    *
    * graph - <mxGraph> to be previewed.
    * scale - Optional scale of the output. Default is 1 / <mxGraph.pageScale>.
    * pageFormat - <mxRectangle> that specifies the page format (in pixels).
    * border - Border in pixels along each side of every page. Note that the
    * actual print function in the browser will add another border for
    * printing.
    * This should match the page format of the printer. Default uses the
    * <mxGraph.pageFormat> of the given graph.
    * x0 - Optional left offset of the output. Default is 0.
    * y0 - Optional top offset of the output. Default is 0.
    * borderColor - Optional color of the page border. Default is no border.
    * Note that a border is sometimes useful to highlight the printed page
    * border in the print preview of the browser.
    * title - Optional string that is used for the window title. Default
    * is 'Printer-friendly version'.
    * pageSelector - Optional boolean that specifies if the page selector
    * should appear in the window with the print preview. Default is true.
    */



  public printPreview = (scale?: number, pageFormat?: any, border?: number, x0?: number, y0?: number, _borderColor?: any, title?: any, pageSelector?: any) => {
    const graph = this.graph;
    const preview = new mxPrintPreview(graph, 1);
    preview.scale = (scale !== undefined) ? scale : 1 / graph.pageScale;
    // preview.scale = 0.25;
    preview.border = (border !== undefined) ? border : 0;
    let f = graph.pageFormat;
    // f = mxConstants.PAGE_FORMAT_A4_PORTRAIT;
    f = mxConstants.PAGE_FORMAT_A4_LANDSCAPE;

    preview.pageFormat = mxRectangle.fromRectangle((pageFormat !== undefined) ? pageFormat : f);
    preview.title = (title !== undefined) ? title : 'Printer-friendly version';
    preview.x0 = (x0 !== undefined) ? x0 : 0;
    preview.y0 = (y0 !== undefined) ? y0 : 0;
    // preview.borderColor = borderColor;
    preview.pageSelector = (pageSelector !== undefined) ? pageSelector : false;
    preview.printOverlays = true;
    preview.targetWindow = "_blank";
    // preview.border = 100;
    // preview.borderColor = "red";
    // preview.backgroundColor = "yellow";
    // preview.marginTop = 100;
    // preview.marginBottom = 100;

    preview.open();

    // var headerSize = 100;
    // var footerSize = 100;
    // // Removes header and footer from page height
    // graph.pageFormat.height -= headerSize + footerSize;

    // // Matches actual printer paper size and avoids blank pages
    // var scale = 0.5;

    // // Applies scale to page
    // var pf = mxRectangle.fromRectangle(graph.pageFormat || mxConstants.PAGE_FORMAT_A4_PORTRAIT);
    // pf.width = Math.round(pf.width * scale * graph.pageScale);
    // pf.height = Math.round(pf.height * scale * graph.pageScale);

    // // Finds top left corner of top left page
    // var bounds = mxRectangle.fromRectangle(graph.getGraphBounds());
    // bounds.x -= graph.view.translate.x * graph.view.scale;
    // bounds.y -= graph.view.translate.y * graph.view.scale;

    // var x0 = Math.floor(bounds.x / pf.width) * pf.width;
    // var y0 = Math.floor(bounds.y / pf.height) * pf.height;

    // var preview = new mxPrintPreview(graph, scale, pf, 0, -x0, -y0);
    // preview.marginTop = headerSize * scale * graph.pageScale;
    // preview.marginBottom = footerSize * scale * graph.pageScale;
    // preview.autoOrigin = false;

    // var oldRenderPage = preview.renderPage;
    // preview.renderPage = function (w, h, x, y, content, pageNumber) {
    //   var div = oldRenderPage.apply(this, arguments);

    //   var header = document.createElement('div');
    //   header.style.position = 'absolute';
    //   header.style.boxSizing = 'border-box';
    //   header.style.fontFamily = 'Arial,Helvetica';
    //   header.style.height = (this.marginTop - 10) + 'px';
    //   header.style.textAlign = 'center';
    //   header.style.verticalAlign = 'middle';
    //   header.style.marginTop = 'auto';
    //   header.style.fontSize = '12px';
    //   header.style.width = '100%';

    //   // Vertical centering for text in header/footer
    //   header.style.lineHeight = (this.marginTop - 10) + 'px';

    //   var footer: any = header.cloneNode(true);

    //   mxUtils.write(header, 'Page ' + pageNumber + ' - Header');
    //   header.style.borderBottom = '1px solid gray';
    //   header.style.top = '0px';

    //   mxUtils.write(footer, 'Page ' + pageNumber + ' - Footer');
    //   footer.style.borderTop = '1px solid gray';
    //   footer.style.bottom = '0px';

    //   div.firstChild.appendChild(footer);
    //   div.firstChild.appendChild(header);

    //   return div;
    // };

    // preview.open();
  }
  public togglePageBreaks = (): void => {
    const graph = this.graph;
    graph.pageBreaksVisible = !graph.pageBreaksVisible;
    graph.sizeDidChange();
  }
  public togglePanZoom = (): void => {
    let pz = this.state.ispanzoom;
    if (pz) {
      setCookie(SemTalkCookie.panzoom, '0');
    } else {
      setCookie(SemTalkCookie.panzoom, '1');
    }

    this.setState({
      ispanzoom: !pz
    });
    if (this.props.dockpanandzoom) {
      if (!pz) {
        // if(this.outlinewnd && this.outlinewnd!==null){
        //   this.outlinewnd.setVisible(false);
        //  (this.outlinewnd as any)["outline"].update(false);
        // }
        setTimeout(() => {
          let outline = document.getElementById('outlineContainer');
          new mxOutline(this.graph, outline);
        }, 100);
      }
    } else {
      if (!this.props.dockpanandzoom) {
        this.showOutline(this.graph, this.props.panandzoomcaption);
      }
    }
  }

  public toggleStencil = (): void => {
    let st = this.state.isstencil;
    if (st) {
      setCookie(SemTalkCookie.stencil, '0');
    } else {
      setCookie(SemTalkCookie.stencil, '1');
    }
    if (!st && !this.props.dockstencil && !this.props.islocked) {
      this.showStencil();
    } else {
      if (st && this.stencilwnd) {
        this.stencilwnd.setVisible(false);
      }
    }

    this.setState({
      isstencil: !st
    });
  }
  public toggleNavigator = (): void => {
    let st = this.state.isnavigator;
    if (st) {
      setCookie(SemTalkCookie.navigator, '0');
    } else {
      setCookie(SemTalkCookie.navigator, '1');
    }
    if (!st && !this.props.docknavigator) {
      this.showNavigator();
    } else {
      if (st && this.navigatorwnd) {
        this.navigatorwnd.setVisible(false);
      }
    }

    this.setState({
      isnavigator: !st
    });
  }
  public ShowPlanner = (): void => {
    let st = this.state.isplanner;
    if (this.props.isplanmaker) {
      st = true;
    }
    if (st) {
      // setCookie(SemTalkCookie.planner, '0');
    } else {
      setCookie(SemTalkCookie.planner, '1');
    }
    if (!st && !this.props.dockplanner) {
      this.showPlanner();
    } else {
      if (st && this.plannerwnd) {
        // this.plannerwnd.setVisible(false);
      }
    }

    this.setState({
      isplanner: true
    });
  }
  public HidePlanner = (): void => {
    // let st = this.state.isplanner;
    // if (this.props.isplanmaker) {
    //   st = true;
    // }
    // if (st) {
    setCookie(SemTalkCookie.planner, '0');
    // } else {
    // setCookie(SemTalkCookie.planner, '1');
    // }
    // if (!st && !this.props.dockplanner) {
    //   this.showPlanner();
    // } else {
    if (this.plannerwnd) {
      this.plannerwnd.setVisible(false);
    }
    // }

    this.setState({
      isplanner: false
    });
  }
  // public toggleSearch = (): void => {
  //   let st = this.state.issearch;
  //   if (st) {
  //     setCookie(SemTalkCookie.search, '0');
  //   } else {
  //     setCookie(SemTalkCookie.search, '1');
  //   }
  //   this.search = null;

  //   if (!st && !this.props.docksearch) {
  //     this.showSearch();
  //   } else {
  //     if (st && this.searchwnd) {
  //       this.searchwnd.setVisible(false);
  //     }
  //   }

  //   this.setState({
  //     issearch: !st
  //   });
  // }

  public togglePages = (): void => {
    let pg = this.state.ispages;
    if (pg) {
      setCookie(SemTalkCookie.pages, '0');
    } else {
      setCookie(SemTalkCookie.pages, '1');
    }
    this.setState({
      ispages: !pg
    });
  }
  public doFlow(edge: mxCell) {
    let state = this.graph.view.getState(edge);
    if (state) {
      state.shape.node.getElementsByTagName('path')[0].removeAttribute('visibility');
      state.shape.node.getElementsByTagName('path')[0].setAttribute('stroke-width', '6');
      state.shape.node.getElementsByTagName('path')[0].setAttribute('stroke', 'lightGray');
      // const flow: any = {
      //   "stroke-dasharray": 8,
      //   "animation": "dash 0.5s linear",
      //   "animation-iteration-count": "infinite"
      //   };
      // state.shape.node.getElementsByTagName('path')[1].setAttribute('style', flow);
      state.shape.node.getElementsByTagName('path')[1].setAttribute('class', 'flow');
    }
  }

  public updateStyle = (cell: mxCell, key: SemTalkStyleAttribute, value: string): void => {
    // return util.updateStyle(this.graph, cell, key, value);
    // updateStyle(graph: any, cell: any, key: any, value: any) {
    const model = this.graph.getModel();
    let newStyle = model.getStyle(cell);
    newStyle = mxUtils.setStyle(newStyle, key, value); //eslint-disable-line
    if (newStyle !== null)
      model.setStyle(cell, newStyle);
    // },
  }
  public groupCells = (groupId: string, labelName: string): any => {

    this.graph.groupCells = (group, border, cells: mxCell[]) => {
      if (cells === undefined) {
        cells = mxUtils.sortCells(this.getSelectionCells(), true);
      }

      cells = this.graph.getCellsForGroup(cells);

      if (group == null) {
        group = this.graph.createGroupCell(cells);
      }

      var bounds = this.graph.getBoundsForGroup(group, cells, border);

      if (cells.length > 0 && bounds != null) {
        // Uses parent of group or previous parent of first child
        var parent = this.graph.model.getParent(group);

        if (parent == null) {
          parent = this.graph.model.getParent(cells[0]);
        }

        this.graph.model.beginUpdate();
        try {
          // Checks if the group has a geometry and
          // creates one if one does not exist
          if (this.graph.getCellGeometry(group) == null) {
            this.graph.model.setGeometry(group, new mxGeometry());
          }

          // Adds the group into the parent
          var index = this.graph.model.getChildCount(parent);
          this.graph.cellsAdded([group], parent, index, undefined, undefined, false, false, false);

          // Adds the children into the group and moves
          index = this.graph.model.getChildCount(group);
          this.graph.cellsAdded(cells, group, index, undefined, undefined, false, false, false);
          this.graph.cellsMoved(cells, -bounds.x, -bounds.y, false, false, false);

          // Resizes the group
          this.graph.cellsResized([group], [bounds], false);

          this.graph.fireEvent(new mxEventObject(mxEvent.GROUP_CELLS,
            'group', group, 'border', border, 'cells', cells), this.graph);
        } finally {
          this.graph.model.endUpdate();
        }
      }

      return group;
    };

    const cellsGrouped = this.graph.getSelectionCells();

    const cell: mxCell = this.graph.groupCells(null, 20, cellsGrouped);
    cell.cellId = groupId + "." + cell.id;
    cell.value = labelName;
    cell.isGroupCell = true;
    let geo = cell.geometry;
    geo.width = geo.width + 30;
    geo.height = geo.height + 30;


    // cellsGrouped && cellsGrouped.forEach((item: any) => {
    //   item.isGrouped = true;
    // });

    if (cellsGrouped) {
      cell.shapeKey = "group";
      for (let item of cellsGrouped) {
        item.isGrouped = true;
        let igeo = item.geometry;
        igeo.x = igeo.x + 15;
        igeo.y = igeo.y + 15;
      }
    }

    return {
      groupCell: cell,
      cellsGrouped
    };
  }
  private handleUngroupCells = (cells: mxCell[]) => {
    cells && cells.forEach((cell) => {
      if (cell.isGroupCell) {
        cell.isGroupCell = false;
      }

      cell.children && cell.children.forEach((child: any) => {
        child.isGrouped = false;
      });
    });

    return cells;
  }
  private handleConnect = (graph: mxGraph, callbackFunc: any) => {
    if (this.props.islocked) return;
    graph.connectionHandler.addListener(mxEvent.CONNECT, (_sender: any, evt: any) => {
      let edge: mxCell = evt.getProperty('cell');
      let source: mxCell = graph.getModel().getTerminal(edge, true);
      let target: mxCell = graph.getModel().getTerminal(edge, false);

      // var style = graph.getCellStyle(edge);
      // var sourcePortId = style[mxConstants.STYLE_SOURCE_PORT];
      // var targetPortId = style[mxConstants.STYLE_TARGET_PORT];

      callbackFunc && callbackFunc(evt, edge, source, target);

      //  mxLog.show();
      //  mxLog.debug('connect', edge, source.id, target.id, sourcePortId, targetPortId);
    });
  }

  private handleDisconnect = (graph: mxGraph, callbackFunc: any) => {
    graph.connectionHandler.addListener(mxEvent.DISCONNECT, (_sender: any, evt: any) => {
      let edge: mxCell = evt.getProperty('cell');
      let source: mxCell = graph.getModel().getTerminal(edge, true);
      let target: mxCell = graph.getModel().getTerminal(edge, false);

      // var style = graph.getCellStyle(edge);
      // var sourcePortId = style[mxConstants.STYLE_SOURCE_PORT];
      // var targetPortId = style[mxConstants.STYLE_TARGET_PORT];

      callbackFunc && callbackFunc(edge, source, target);

      //  mxLog.show();
      //  mxLog.debug('connect', edge, source.id, target.id, sourcePortId, targetPortId);
    });
  }
  private handleCellConnected = (graph: mxGraph, callbackFunc: any) => {
    graph.addListener(mxEvent.CELL_CONNECTED, (_sender: any, evt: any) => {
      let edge: mxCell = evt.getProperty('edge');
      let source: mxCell = graph.getModel().getTerminal(edge, true);
      let target: mxCell = graph.getModel().getTerminal(edge, false);

      // var style = graph.getCellStyle(edge);
      // var sourcePortId = style[mxConstants.STYLE_SOURCE_PORT];
      // var targetPortId = style[mxConstants.STYLE_TARGET_PORT];

      callbackFunc && callbackFunc(evt, edge, source, target);

      //  mxLog.show();
      //  mxLog.debug('connect', edge, source.id, target.id, sourcePortId, targetPortId);
    });
  }
  private handleSplit = (graph: mxGraph, callbackFunc: any) => {
    if (this.props.islocked) return;
    graph.addListener(mxEvent.SPLIT_EDGE, (_sender: any, evt: any) => {
      let newedge: mxCell = evt.getProperty('newEdge');
      let edge: mxCell = evt.getProperty('edge');
      let cells: mxCell = evt.getProperty('cells');
      // let source: mxCell = graph.getModel().getTerminal(edge, true);
      // let target: mxCell = graph.getModel().getTerminal(edge, false);

      // var style = graph.getCellStyle(edge);
      // var sourcePortId = style[mxConstants.STYLE_SOURCE_PORT];
      // var targetPortId = style[mxConstants.STYLE_TARGET_PORT];

      callbackFunc && callbackFunc(evt, newedge, edge, cells[0]);

      //  mxLog.show();
      //  mxLog.debug('connect', edge, source.id, target.id, sourcePortId, targetPortId);
    });
  }

  private splitFunc = (_evt: any, newedge: mxCell, edge: mxCell, vertex: mxCell) => {
    this.graph.model.beginUpdate();
    let geo = vertex.getGeometry();
    let other: mxCell = newedge.source;
    let isinv = false;
    if (newedge.source.id === vertex.id) {
      other = newedge.target;
    }
    let other2 = edge.source;
    if (edge.source.id === vertex.id) {
      other2 = edge.target;
    }

    let horiz = this.isGraphHorizontal();
    // let dir = horiz;

    // let horiz = geo.x < ogeo.x;
    let rel = this.props.semtalk.base.FindInstanceByID(edge.objectid);
    let trav = other;
    let trav2 = other2;
    if (rel && rel.ClassOf().ObjectName === SemTalkBaseConstant.SLSubClassOf) {
      horiz = true;
      // dir = true;
      trav2 = other;
      trav = other2;
      isinv = true;
      // let tmp = other;
      // other = other2;
      // other2 = tmp;
    }

    let ogeo = other.getGeometry();
    let ogeo2 = other2.getGeometry();

    horiz = Math.abs(ogeo2.x - ogeo.x) < Math.abs(ogeo2.y - ogeo.y);
    let isleft = ogeo.x > ogeo2.x;
    let isup = ogeo.y > ogeo2.y;
    if (isinv) {
      isleft = !isleft;
      isup = !isup;
    }


    if (horiz) {
      geo.x = ogeo.x + ogeo.width / 2 - geo.width / 2;
    } else {
      geo.y = ogeo.y + ogeo.height / 2 - geo.height / 2;
    }

    let cells: mxCell[] = [];
    this.graph.traverse(trav2, false, (v: mxCell) => {
      if (cells.indexOf(v) > -1 || v === vertex || v === trav) {
        return false;
      } else {
        cells.push(v);
      }
      return true;
    });
    if (horiz) {
      if (isup) {
        this.graph.moveCells(cells, 0, - geo.height * 1.5, false);
        let y1 = ogeo.y + ogeo.height;
        let y2 = ogeo2.y - geo.height * 1.5;
        geo.y = y2 + (y1 - y2) / 2 - geo.height / 2;
      } else {
        this.graph.moveCells(cells, 0, geo.height * 1.5, false);
        let y1 = ogeo.y + ogeo.height;
        let y2 = ogeo2.y + geo.height * 1.5;
        geo.y = y1 + (y2 - y1) / 2 - geo.height / 2;
      }
    } else {
      if (isleft) {
        this.graph.moveCells(cells, - geo.width * 1.5, 0, false);
        let x1 = ogeo.x + ogeo.width;
        let x2 = ogeo2.x - geo.width * 1.5;
        geo.x = x2 + (x1 - x2) / 2 - geo.width / 2;
      } else {
        this.graph.moveCells(cells, geo.width * 1.5, 0, false);
        let x1 = ogeo.x + ogeo.width;
        let x2 = ogeo2.x + geo.width * 1.5;
        geo.x = x1 + (x2 - x1) / 2 - geo.width / 2;

      }
    }
    this.graph.model.endUpdate();
  }
  public unSplit = (vertex: mxCell, other2: mxCell, horiz: boolean, invrel: boolean) => {
    this.graph.model.beginUpdate();
    let geo = vertex.getGeometry();
    // let trav = other2;
    let other = vertex;

    if (invrel) {
      let tmp = other;
      other = other2;
      other2 = tmp;
    }

    let ogeo = other.getGeometry();
    let ogeo2 = other2.getGeometry();

    horiz = Math.abs(ogeo2.x - ogeo.x) < Math.abs(ogeo2.y - ogeo.y);
    let isleft = ogeo.x > ogeo2.x;
    let isup = ogeo.y > ogeo2.y;
    // if (invrel) {
    //   isleft = !isleft;
    //   isup = !isup;
    // }

    let cells: mxCell[] = [];
    this.graph.traverse(other2, false, (v: mxCell) => {
      if (cells.indexOf(v) > -1 || v === other) {
        return false;
      } else {
        cells.push(v);
      }
      return true;
    });
    if (horiz) {
      if (isup) {
        this.graph.moveCells(cells, 0, geo.height * 1.5, false);
      } else {
        this.graph.moveCells(cells, 0, 0 - geo.height * 1.5, false);
      }
    } else {
      if (isleft) {
        this.graph.moveCells(cells, geo.width * 1.5, 0, false);
      } else {
        this.graph.moveCells(cells, 0 - geo.width * 1.5, 0, false);
      }
    }
    this.graph.model.endUpdate();
  }


  /**
   * ungroup cells
   */
  public ungroupCells = (cells: mxCell[]): mxCell[] => {
    const tempCells = cells || this.graph.getSelectionCells();

    const groupCells: any[] = [];

    tempCells && tempCells.forEach((cell) => {
      if (cell.isGroupCell) {
        groupCells.push(cell);
      }

      cell.children && cell.children.forEach((child: any) => {
        if (child.isGroupCell) {
          groupCells.push(child);
        }
      });

      const {
        parent
      } = cell;

      if (parent && parent.isGroupCell) {
        groupCells.push(parent);
      }
    });

    this.handleUngroupCells(groupCells);

    return this.graph.ungroupCells(groupCells);
  }
  // get all cells selected
  public getSelectionCells = (): mxCell[] => {
    return this.graph.getSelectionCells();
  }

  /**
   * get xml of the graph
   */
  public getGraphXml = (): string => {
    const xml = this.getGraphXmlNode();
    const xmlStr = new XMLSerializer().serializeToString(xml); // eslint-disable-line
    return xmlStr;
  }
  /**
   * create vertex
   * @param {shapeLabel, x, y, width, height, shapeStyle} param0 shapeLabel, x, y, width, height, shapeStyle
   */
  public createVertex = (parent: mxCell | null, id: string | null, value: any, x: number, y: number, width: number, height: number, shapeStyle?: string): mxCell => {
    if (!parent) parent = this.graph.getDefaultParent();
    // if (this.droptarget) {
    //   parent = this.droptarget;
    // }
    const cell = this.graph.insertVertex(parent, id as any, value, x, y, width, height, shapeStyle);
    return cell;
  }

  /**
   * insert edge
   * @param {*} v1 cell 1
   * @param {*} v2 cell 2
   */
  public insertEdge = (v1: mxCell, v2: mxCell, style?: string): mxCell => {
    let parent = this.graph.getDefaultParent();
    // if (this.droptarget) {
    //   parent = this.droptarget;
    // }
    let e = this.graph.insertEdge(parent, "", '', v1, v2, style);
    this.graph.orderCells(true, [e]);
    return e;
  }
  public bringToFront = (cells: mxCell[]): void => {
    this.graph.orderCells(false, cells);
  }
  public sendToBack = (cells: mxCell[]): void => {
    this.graph.orderCells(true, cells);
  }
  /**
   * get cell by id
   * @param {*} id id
   */
  public getCellById = (id: string): mxCell | undefined => {
    let cells = this.getAllCells();
    for (const i in cells) {
      const c = cells[i];
      if (c.id === id) {
        return c as mxCell;
      }
    }
    return undefined;
  }

  /**
   * get all cells
   */
  public getAllCells = (): mxCell[] => {
    return this.graph.model.cells;
  }

  public getAllTextCells = (): mxCell[] => {
    // if (this.textcells) {
    //   return this.textcells;
    // }
    let tclist: mxCell[] = [];
    let cells = this.graph.model.cells;
    for (const i in cells) {
      const s = cells[i];
      if (s.textField) {
        tclist.push(s);
      }
    }
    // this.textcells = tclist;
    return tclist;
  }
  public getChildCells = (parent: mxCell, vertices?: boolean, edges?: boolean): mxCell[] => {
    return this.graph.getChildCells(parent, vertices, edges);
  }
  public removeCells = (cells: mxCell[], includeEdges: boolean): void => {
    this.graph.removeCells(cells, includeEdges);
  }
  public beginUpdate = () => {
    const model = this.graph.getModel();
    model.beginUpdate();
  }
  public endUpdate = () => {
    const model = this.graph.getModel();
    model.endUpdate();
  }
  public removeEventListeners = (): void => {
    for (let li of this.keydownhandler) {
      document.body.removeEventListener('keydown', li);
    }

    // document.body.removeEventListener('keydown', this.undoListenerFunc2);
    // document.body.removeEventListener('keydown', this.copyListenerFunc2);
    // document.body.removeEventListener('keydown', this.deleteListenerFunc2);
  }
  private initZoomConfig = (graph: mxGraph): void => {
    graph.keepSelectionVisibleOnZoom = true;
    graph.centerZoom = true;
  }


  /**
   * rename a cell label
   * @param {*} newName new name
   * @param {*} cell a cell
   */
  public renameCell = (newName: string, cell: mxCell): void => {
    cell.value = newName;
    this.graph.refresh(); // 重新渲染graph
  }
  public toggleCollapse = (cell: mxCell) => {
    const coll = cell.collapsed;
    this.graph.foldCells(!coll, false, [cell]);
    // this.refresh();
  }
  /**
   * refresh the graph
   */
  public refresh = (): void => {
    try {
      this.graph.refresh();
    } catch (e) {
      console.debug("refresh: ", e);
    }
  }
  public refreshCell = (cell: mxCell): void => {
    try {
      this.graph.refresh(cell);
    } catch (e) {
      console.debug("refreshCell: ", e);
    }
  }

  /**
   * clear selection in the graph
   */
  public clearSelection = (): void => {
    this.graph.clearSelection();
  }

  public startPanning = () => {
    this.graph.panningHandler.useLeftButtonForPanning = true;
    this.graph.panningHandler.ignoreCell = true;
    this.graph.container.style.cursor = 'move';
  }

  public stopPanning = (): void => {
    this.graph.panningHandler.useLeftButtonForPanning = false;
    this.graph.panningHandler.ignoreCell = false;
    this.graph.container.style.cursor = 'auto';
  }

  public connectionPoints = (): void => {
    this.graph.container.style.cursor = 'crosshair';
  }

  public elbowCell = (cell: mxCell) => {
    let s = cell.style;
    if (s) {
      const model = this.graph.getModel();
      model.beginUpdate();
      if (s.indexOf(SemTalkStyleAttribute.elbow + "=vertical") > -1) {
        this.updateStyle(cell, SemTalkStyleAttribute.elbow, "horizontal");
      } else {
        this.updateStyle(cell, SemTalkStyleAttribute.elbow, "vertical");
      }
      model.endUpdate();
    }
  }
  public setStyle = (stylechanges: any) => {
    const model = this.graph.getModel();

    let cells = stylechanges.cells;
    if (!cells) {
      cells = this.getSelectionCells();
    } else {
      delete stylechanges.cells;
    }
    stylechanges[SemTalkStyleAttribute.custom] = 1;
    model.beginUpdate();
    for (let cell of cells) {
      for (let style in stylechanges) {
        if (style in SemTalkStyleAttribute) {
          let value: SemTalkStyleAttribute = stylechanges[style];
          this.updateStyle(cell, style as SemTalkStyleAttribute, value);
        }
      }
    }
    model.endUpdate();
  }

  public clearUndo = (): void => {
    this.undoman.clear();
  }
  public isUndo = (): boolean => {
    return this.undoman.canUndo();
  }
  public isRedo = (): boolean => {
    return this.undoman.canRedo();
  }
  public Undo = (): void => {
    return this.undoman.undo();
  }
  public Redo = (): void => {
    return this.undoman.redo();
  }


  public resetEditor = (xml: string, diagcaption: string, diagid: SemTalkID, horizontal: boolean, bsetDirection: boolean) => {
    if (xml !== null)
      if (xml.length > 0) {
        //  xml = this.patchEPC(xml);
        xml = xml.replace(/\s\s+/g, '');
        // editor.renderGraphFromXml(xml);
        //  let x: string = '<mxGraphModel><root><Diagram href="http://www.jgraph.com/" id="0" label="' + ndiag.ObjectCaption + '" diagid="' + ndiag.ID + '"><mxCell id="2" value="Hello World" vertex="1"><mxGeometry x="20" y="20" width="80" height="30" as="geometry"/></mxCell></Diagram><Layer id="1" label="Default Layer"><mxCell parent="0"></mxCell></Layer></root></mxGraphModel>';
        // let x = '<mxGraphModel><mxCell id="0"><mxCell id="1" parent="0"></mxCell><mxCell id="1" parent="0"></mxCell><Event href="" id="2" label="Journal entry required.2316"><mxCell vertex="1" parent="1" shapeid="Sheet.22" objectid="2316" style="shape=image;html=1;whiteSpace=wrap;"><mxGeometry x="414" y="-1694" width="110" height="75" as="geometry"/></mxCell></Event></mxGraphModel>';
        //  editor.renderGraphFromXml(x);
        var doc2 = mxUtils.parseXml(xml);
        // var x = doc2.documentElement.firstChild.outerHTML;
        if (bsetDirection) {
          doc2.documentElement.setAttribute('horizontal', horizontal);
        }

        let x = doc2.documentElement.outerHTML;
        this.renderGraphFromXml(x);
      } else {
        // console.log(this.graph.getModel());
        // let x: string = this.empty;
        // x = x.replace('page="0"', 'page="0" diagid="' + ndiag.ID + '"');
        // let x: string = '<mxGraphModel><root><Diagram href="http://www.jgraph.com/" id="0" label="' + ndiag.ObjectCaption + '" diagid="' + ndiag.ID + '"><mxCell id="2" value="Hello World" vertex="1"><mxGeometry x="20" y="20" width="80" height="30" as="geometry"/></mxCell></Diagram><Layer id="1" label="Default Layer"><mxCell parent="0"></mxCell></Layer></root></mxGraphModel>';
        let x: string = '<mxGraphModel><root><Diagram href="http://www.jgraph.com/" id="0" label="' + diagcaption + '" diagid="' + diagid + '"></Diagram><Layer id="1" label="Default Layer"><mxCell parent="0"></mxCell></Layer></root></mxGraphModel>';
        //let x: string = "<root><mxCell id='0'></mxCell><mxCell id='1' parent='0'/></mxCell></root>";
        this.renderGraphFromXml(x);
      }
    // this.graph.orderCells(true, this.graph.getChildEdges(this.graph.getDefaultParent()));
    // Changes the zoom on mouseWheel events
    mxEvent.addMouseWheelListener((evt: any, up: any) => {
      if (!mxEvent.isConsumed(evt)) {
        let outlineWheel = false;
        let outline: any | null = null;
        if (this.outlinewnd) {
          outline = (this.outlinewnd as any)["outline"];
        }
        let element = mxEvent.getSource(evt);
        while (element !== null) {
          if (outline && element === outline.outline.view.canvas.ownerSVGElement) {
            outlineWheel = true;
            break;
          }
          try {
            if (element.className) {
              let cn = element.className.toString();
              if (cn === 'ms-SelectionZone') return;
              if (cn.startsWith("ms-ScrollablePane ")) {
                return;
              }
              if (cn.startsWith("ms-Panel-content ")) {
                return;
              }
              if (cn.startsWith("ms-Dialog-content ")) {
                return;
              }
              if (cn.startsWith("ms-Dropdown")) {
                return;
              }
              if (cn.startsWith("ms-Callout")) {
                return;
              }
              if (cn.startsWith("ms-Panel-scrollableContent")) {
                return;
              }

            }
          } catch (_e) {
          }
          element = element.parentNode;
        }


        if (mxEvent.isControlDown(evt) || mxEvent.isAltDown(evt) || outlineWheel) {
          if (up) {
            this.graph.zoomIn();
          } else {
            this.graph.zoomOut();
          }
        } else {
          let t = this.graph.view.getTranslate();
          let step = 60 / this.graph.view.scale;

          if (!mxEvent.isShiftDown(evt)) {
            this.graph.view.setTranslate(t.x, t.y + ((up) ? step : -step));
          }
          else {
            this.graph.view.setTranslate(t.x + ((up) ? -step : step), t.y);
          }
        }

        mxEvent.consume(evt);
      }
    });

    this.addHeaderFooter(this.props.headerfooterfields);
    this.props.callback.setEditor(this);
    this.clearUndo();
    this.props.semtalk.setBundles();
    // this.textcells = null;

    // this.refresh();
  }

  public addHeaderFooter = (headerfooterdefinition: IHeaderFooterField[]) => {
    let sem = this.props.semtalk;
    let header = this.getCellById('pageHeader');
    if (header) {
      this.graph.removeCells([header], false);
      header = undefined;
    }
    let footer = this.getCellById('pageFooter');
    if (footer) {
      this.graph.removeCells([footer], false);
      footer = undefined;
    }
    let makeVisible = (s: string): string => {
      // s = setStyleAttribute(s, SemTalkStyleAttribute.opacity, "100");
      // s = setStyleAttribute(s, SemTalkStyleAttribute.strokeWidth, "1");
      // s = setStyleAttribute(s, SemTalkStyleAttribute.strokeColor, "black");
      return s;
    };
    // if (!header || !footer) {
    // let headerfooterdefinition = this.props.headerfooterfields;
    let pagewidth = Number(this.graph.pageFormat.width);
    let pageheight = Number(this.graph.pageFormat.height);
    if (this.showheaderfooterfields && headerfooterdefinition) {
      let parent = this.graph.getDefaultParent();
      let headerdef = headerfooterdefinition.find(x => x.id === 'pageHeader');
      if (!header && headerdef) {
        // let hcell = new mxCell();
        // let layer = this.graph.model.add(parent, hcell);
        // 'https://upload.wikimedia.org/wikipedia/commons/e/ea/Logo_FH_Kiel.jpg'
        let dx = Math.max(Number(headerdef.x), this.graph.border);
        let dy = Math.max(Number(headerdef.y), this.graph.border);
        let width = pagewidth - dx * 2;

        let headerdeftext = sem.getResStr(headerdef.text);
        if (headerdef.textL) {
          headerdeftext = sem.getResStrListener(headerdef.textL);
        }
        if (!headerdeftext) headerdeftext = "";
        headerdeftext = headerdeftext.replace(":", "");
        let nheader = new mxCell(headerdeftext, new mxGeometry(dx, dy, width, Number(headerdef.height)));
        nheader.id = 'pageHeader';
        nheader.textField = headerdef.textfield;
        nheader.style = headerdef.style;
        if (headerdef.image) {
          nheader.style += "image=" + headerdef.image + ";";
        }
        nheader.style = makeVisible(nheader.style);
        nheader.connectable = false;
        nheader.setConnectable(false);
        nheader.setVertex(true);
        (nheader as any).locked = true;
        (nheader as any).header = true;

        for (let hdef of headerfooterdefinition) {
          if (hdef.id === 'pageHeader') continue;
          if (hdef.id === 'pageFooter') continue;
          if (hdef.section !== 'header') continue;
          let dx0 = Number(hdef.x);
          let shpwidth = Number(hdef.width);
          let shpheight = Number(hdef.height);
          if (hdef.orientation === "right") {
            let pagewidth0 = pagewidth - dx0 * 2;
            dx0 = pagewidth0 - dx0 - shpwidth;

          }
          let hdeftext = sem.getResStr(hdef.text);
          if (hdef.textL) {
            hdeftext = sem.getResStrListener(hdef.textL);
          }
          if (!hdeftext) hdeftext = "";
          hdeftext = hdeftext.replace(":", "");
          let v: mxCell = new mxCell(hdeftext, new mxGeometry(dx0, Number(hdef.y), shpwidth, shpheight));
          v.id = hdef.id;
          v.textField = hdef.textfield;
          v.connectable = false;
          v.setConnectable(false);
          v.setVertex(true);
          v.style = hdef.style;
          if (hdef.image) {
            v.style += "image=" + hdef.image + ";";
          }
          v.style = makeVisible(v.style);
          (v as any).locked = true;
          (v as any).header = true;
          this.graph.getModel().add(nheader, v);
        }
        this.graph.getModel().add(parent, nheader);
        this.props.semtalk.updateTextFields([]);
        this.sendToBack(nheader);
      }
      let footerdef = headerfooterdefinition.find(x => x.id === 'pageFooter');
      if (this.showheaderfooterfields && !footer && footerdef) {
        let dx = Math.max(footerdef.x, this.graph.border);
        let dy = Math.max(footerdef.y, this.graph.border);
        let width = this.graph.pageFormat.width - dx * 2;
        let height = pageheight - dy;
        let footerheight = Number(footerdef.height);
        let footerdeftext = sem.getResStr(footerdef.text);
        if (footerdef.textL) {
          footerdeftext = sem.getResStrListener(footerdef.textL);
        }
        if (!footerdeftext) footerdeftext = "";
        footerdeftext = footerdeftext.replace(":", "");
        let nfooter = new mxCell(footerdeftext, new mxGeometry(dx, height - footerheight, width, footerheight));
        nfooter.id = 'pageFooter';
        nfooter.textField = footerdef.textfield;
        nfooter.style = footerdef.style;
        if (footerdef.image) {
          nfooter.style += "image=" + footerdef.image + ";";
        }
        nfooter.style = makeVisible(nfooter.style);

        nfooter.connectable = false;
        nfooter.setConnectable(false);
        nfooter.setVertex(true);
        (nfooter as any).locked = true;
        (nfooter as any).header = true;

        for (let fdef of headerfooterdefinition) {
          if (fdef.id === 'pageHeader') continue;
          if (fdef.id === 'pageFooter') continue;
          if (fdef.section !== 'footer') continue;
          let dx0 = Number(fdef.x);
          let shpwidth = Number(fdef.width);
          let shpheight = Number(fdef.height);
          if (fdef.orientation === "right") {
            let pagewidth0 = pagewidth - dx0 * 2;
            dx0 = pagewidth0 - dx0 - shpwidth;

          }
          let fdeftext = sem.getResStr(fdef.text);
          if (fdef.textL) {
            fdeftext = sem.getResStrListener(fdef.textL);
          }
          if (!fdeftext) fdeftext = "";
          fdeftext = fdeftext.replace(":", "");
          let v: mxCell = new mxCell(fdeftext, new mxGeometry(dx0, Number(fdef.y), shpwidth, shpheight));
          v.id = fdef.id;
          v.textField = fdef.textfield;
          v.connectable = false;
          v.setConnectable(false);
          v.setVertex(true);
          v.style = fdef.style;
          if (fdef.image) {
            v.style += "image=" + fdef.image + ";";
          }
          v.style = makeVisible(v.style);

          (v as any).locked = true;
          (v as any).header = true;
          this.graph.getModel().add(nfooter, v);
        }
        this.graph.getModel().add(parent, nfooter);
        this.sendToBack(nfooter);
        this.props.callback.updateModelTextFields();
      }
    }
    // }
  }
  public removeHeaderFooter = () => {
    let header = this.getCellById('pageHeader');
    if (header) {
      this.graph.removeCells([header], false);
      header = undefined;
    }
    let footer = this.getCellById('pageFooter');
    if (footer) {
      this.graph.removeCells([footer], false);
      footer = undefined;
    }
  }

  public componentWillUnmount() {

    // remove event listeners when component will unmount
    // if (this.state.editor !== null) {
    //   this.state.editor.removeEventListeners();
    // }
    this.removeEventListeners();
  }

  /**
   * double click event callback
   */
  public doubleClickFunc = (cell: any) => {
    (this.props.callback as any).doubleClick(cell);
    // console.log('double click', cell);
  }

  public isEditing = (cell?: mxCell | undefined) => {
    return this.graph.isEditing(cell);
  }

  private cellCreatedFunc = (currentCell: any) => {
    if (this.props.callback) {
      this.props.callback.shapeAdded(currentCell);
    }

  }
  private deleteFunc = (cells: any) => {
    this.props.callback.shapeDeleted(cells);
    // console.log('cells deleted: ', cells);
  }
  private isValid = (src: any, dst: any): boolean => {
    return this.props.callback.isValid(src, dst);
    // console.log('cells deleted: ', cells);
  }

  private valueChangeFunc = (cell: any, newValue: any) => {
    this.props.callback.shapeExitedTextEdit(cell, newValue);
    // console.log(`new value: ${newValue}`);
  }

  private autoSaveFunc = (_mxgraphxml: string) => {
    // (window as any).autosaveXml = xml;
    // console.log("autoSaveFunc");

    // const oParser = new DOMParser(); // eslint-disable-line
    // const oDOM = oParser.parseFromString(xml, 'application/xml');
    // (window as any).autoSaveXmlDom = oDOM;

    // this.props.callback.beginTransaction("mxGraph");
    // this.props.callback.endTransaction("mxGraph");
    // this.props.semtalk.autoSave(mxgraphxml, "mxGraph");
  }

  // private selectedCell: mxCell;
  private clickFunc = (cell: mxCell, evt: mxEventObject) => {

    // console.debug(mxEvent.isShiftDown(evt));
    // console.debug(mxEvent.isControlDown(evt));
    this.props.callback.shapeSelectionChanged(cell, evt);
  }
  private selectFunc = (evtobj: mxEventObject) => {
    let c: mxCell | undefined;
    let a: mxCell[] = (evtobj.properties as any)["removed"];
    if (a) {
      if (a.length > 0) {
        c = a[0];
      }
    }
    this.props.callback.shapeSelectionChanged(c, evtobj);
  }

  private moveCellsFunc = (cells: mxCell[], clone: boolean) => {
    this.props.callback.shapeMoveCells(cells, clone);
  }
  // private addVertexFunc = (cell: any, evt: any) => {
  //   this.props.callback.addVertex(cell, evt);
  // }

  private undoFunc = (_histories: any) => {
    // console.log('undo', histories);
  }

  private copyFunc = (cells: mxCell[]) => {
    this.props.callback.shapeCopyCells(cells);
    // console.log('copy', cells);
  }

  private keyFunc = (evtobj: KeyboardEvent): void => {
    const callback = this.props.callback;
    const codes = ["F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10"];
    let code = evtobj.code;
    let trycode: boolean = evtobj.ctrlKey;
    if (!trycode) {
      trycode = codes.indexOf(code) > -1;
    }
    if (trycode) {
      if (callback && callback.hasKeyHandler(evtobj)) {
        mxEvent.consume(evtobj);
        callback.keyPressed(evtobj);
        return;
      }
    }

    const graph = this.graph;
    switch (evtobj.code) {
      case "F1": {
        mxEvent.consume(evtobj);
        this.props.callback.showHelp();
        break;
      }
      case "F2": {
        if (callback && callback.hasKeyHandler(evtobj)) {
          mxEvent.consume(evtobj);
          callback.keyPressed(evtobj);
        }
        break;
      }
      case "MetaLeft": {
        mxEvent.consume(evtobj);
        this.props.callback.showHelp();
        break;
      }
      case "KeyA": {
        if (evtobj.ctrlKey) {
          mxEvent.consume(evtobj);
          if (evtobj.metaKey) {
            graph.selectCells(true, false, graph.getDefaultParent(), false);
          } else {
            graph.selectAll(graph.getDefaultParent());
          }
        }
        break;
      }
      // case "KeyM": {
      //   if (evtobj.ctrlKey) {
      //     mxEvent.consume(evtobj);
      //     // graph.selectCells(true,false);
      //     this.align("AlignVerticalCenter", undefined);
      //   }
      //   break;
      // }
      case "ArrowLeft": {
        const cellsSelected = graph.getSelectionCells();
        // mxEvent.consume(evtobj);
        if (evtobj.shiftKey) {
          if (this.props.islocked) return;
          graph.moveCells(cellsSelected, 0 - graph.gridSize * 0.5, 0, false);
        } else {
          if (evtobj.ctrlKey) {
            callback.selectLeft();
          } else {
            if (this.props.islocked) return;
            graph.moveCells(cellsSelected, 0 - graph.gridSize, 0, false);
          }
        }
        break;
      }
      case "Tab": {
        mxEvent.consume(evtobj);
        if (evtobj.shiftKey) {
          callback.selectLeft();
        } else {
          callback.selectRight();
        }
        break;
      }
      case "ArrowRight": {
        const cellsSelected = graph.getSelectionCells();
        // mxEvent.consume(evtobj);
        if (evtobj.shiftKey) {
          if (this.props.islocked) return;
          graph.moveCells(cellsSelected, graph.gridSize * 0.5, 0, false);
        } else {
          if (evtobj.ctrlKey) {
            callback.selectRight();
          } else {
            if (this.props.islocked) return;
            graph.moveCells(cellsSelected, graph.gridSize, 0, false);
          }
        } break;
      }
      case "PageUp": {
        callback.selectPageUp();
        break;
      }
      case "ArrowUp": {
        const cellsSelected = graph.getSelectionCells();
        if (evtobj.shiftKey) {
          if (this.props.islocked) return;
          graph.moveCells(cellsSelected, 0, 0 - graph.gridSize, false);
        } else {
          if (evtobj.ctrlKey) {
            callback.selectUp();
          } else {
            if (this.props.islocked) return;
            graph.moveCells(cellsSelected, 0, 0 - graph.gridSize * 0.5, false);
          }
        }
        break;
      }
      case "PageDown": {
        callback.selectPageDown();
        break;
      }
      case "ArrowDown": {
        const cellsSelected = graph.getSelectionCells();
        if (evtobj.shiftKey) {
          if (this.props.islocked) return;
          graph.moveCells(cellsSelected, 0, graph.gridSize * 0.5, false);
        } else {
          if (evtobj.ctrlKey) {
            callback.selectDown();
          } else {
            if (this.props.islocked) return;
            graph.moveCells(cellsSelected, 0, graph.gridSize, false);
          }
        }
        break;
      }
    }
  }
  private connectFunc = (event: mxEventObject, edge: mxCell, source: mxCell, target: mxCell) => {
    if (!source.objectid && !target.objectid) {
      return;
    }
    let mst = (this.graph as any)["connectionMaster"];
    edge["type"] = mst;
    this.props.callback.shapeConnectionAdded(event, edge, source, target);
    //  console.log('connect', edge);
  }
  private disconnectFunc = (edge: mxCell, _source: mxCell, _target: mxCell) => {
    this.props.callback.shapeConnectionsDeleted(edge);
    //  console.log('disconnect', edge);
  }

  private cellconnectedFunc = (_event: mxEventObject, edge: mxCell, _source: mxCell, _target: mxCell) => {
    this.props.callback.shapeCellConnected(edge);
    // console.log("cellconnected", event, edge, source, target);
  }

  private menuFunc = (graph: mxGraph, menu: mxPopupMenuHandler, cell: mxCell, evt: PointerEvent) => {
    this.props.callback.shapeCreatePopupMenu(graph, menu, cell, evt, this);
  }
  // private animate: any = null;

  public gotoPage = (diagid: SemTalkID) => {
    this.props.callback.gotoPage(diagid);
  }
  public selectAll = (): void => {
    this.graph.selectAll(this.graph.getDefaultParent());
  }

  public doLayout = (node: any, layoutname: any, root: mxCell, animate: boolean, horizontal: boolean, invert: boolean) => {
    let graph = this.graph;
    // if (!parent) parent = graph.getDefaultParent();
    let parent = graph.getDefaultParent();
    let layoutsettings: LayoutSetting = {
      layout: SemTalkOnlineCommand.mxFastOrganicLayout as string,
      horizontal: true,
      invert: false,
      OrganicLayoutminDistanceLimit: 100,
      OrganicLayoutmaxDistanceLimit: 200,
      OrganicLayoutforceConstant: 100,
      OrganicLayoutinitialTemp: 100,
      OrganicLayoutmaxIterations: 0,
      OrganicLayoutuseInputOrigin: true,
      Layoutradius: 100,
      LayoutalignRanks: false,
      Layoutdeterministic: true,
      LayoutresetEdges: true,
      LayoutprefHozEdgeSep: 5,
      LayoutprefVertEdgeOff: 4,
      LayoutminEdgeJetty: 8,
      LayoutchannelBuffer: 4,
      LayoutmoveCircle: false,
      LayoutdisableEdgeStyle: false,
      LayoutlevelDistance: 10,
      LayoutnodeDistance: 20,
      LayoutedgeRouting: true,
      LayoutautoRadius: false,
      LayoutsortEdges: false,
      Layoutorientation: "north",
      LayoutintraCellSpacing: 30,
      LayoutinterRankCellSpacing: 100,
      LayoutinterHierarchySpacing: 60,
      LayoutparallelEdgeSpacing: 10,
      LayoutresizeParent: false,
      LayoutmaintainParentLocation: false,
      LayoutfineTuning: true,
      LayouttightenToSource: true,
      LayoutgroupPadding: 0,
      LayoutmoveParent: false,
      LayoutparentBorder: 0,
      LayoutmoveTree: false,
      LayouttraverseAncestors: true,
      LayoutedgeStyle: 2
    };
    let layoutcookie = accessCookie(SemTalkCookie.layout);
    if (layoutcookie) { layoutsettings = JSON.parse(layoutcookie); }

    let layout = new mxGraphLayout(graph);
    switch (layoutname) {
      case "mxCircleLayout": {
        layout = new mxCircleLayout(graph, layoutsettings.Layoutradius);
        layout.disableEdgeStyle = layoutsettings.LayoutdisableEdgeStyle;
        layout.radius = layoutsettings.Layoutradius;
        layout.alignRanks = layoutsettings.LayoutalignRanks;
        layout.resetEdges = layoutsettings.LayoutresetEdges;
        layout.moveCircle = layoutsettings.LayoutmoveCircle;
        break;
      }
      // case "mxParallelEdgeLayout": { layout = new mxParallelEdgeLayout(graph); break; }
      case "mxFastOrganicLayout": {
        let flayout: any = new mxFastOrganicLayout(graph);
        flayout.useInputOrigin = layoutsettings.OrganicLayoutuseInputOrigin;
        flayout.resetEdges = layoutsettings.LayoutresetEdges;
        flayout.disableEdgeStyle = layoutsettings.LayoutdisableEdgeStyle;
        flayout.forceConstant = layoutsettings.OrganicLayoutforceConstant;
        flayout.forceConstantSquared = layout.forceConstant * layout.forceConstant;
        flayout.minDistanceLimit = layoutsettings.OrganicLayoutminDistanceLimit;
        flayout.maxDistanceLimit = layoutsettings.OrganicLayoutmaxDistanceLimit;
        flayout.initialTemp = layoutsettings.OrganicLayoutinitialTemp;
        flayout.maxIterations = layoutsettings.OrganicLayoutmaxIterations;
        layout = flayout;
        // console.debug(layout);
        break;
      }
      case "mxCompactTreeLayout": {
        let clayout: any = new mxCompactTreeLayout(graph, layoutsettings.horizontal, layoutsettings.invert);
        // layout.root = root;
        if (!horizontal) {
          clayout.horizontal = horizontal;
        }
        if (invert) {
          clayout.invert = invert;
        }
        clayout.edgeRouting = layoutsettings.LayoutedgeRouting;
        clayout.alignRanks = layoutsettings.LayoutalignRanks;
        clayout.resizeParent = layoutsettings.LayoutresizeParent;
        clayout.maintainParentLocation = layoutsettings.LayoutmaintainParentLocation;
        clayout.groupPadding = layoutsettings.LayoutgroupPadding;
        // clayout.groupPaddingBottom
        // clayout.groupPaddingLeft
        // clayout.groupPaddingRight
        clayout.moveTree = layoutsettings.LayoutmoveTree;
        clayout.levelDistance = layoutsettings.LayoutlevelDistance;
        clayout.nodeDistance = layoutsettings.LayoutnodeDistance;
        clayout.resetEdges = layoutsettings.LayoutresetEdges;
        clayout.prefHozEdgeSep = layoutsettings.LayoutprefHozEdgeSep;
        clayout.prefVertEdgeOff = layoutsettings.LayoutprefVertEdgeOff;
        clayout.minEdgeJetty = layoutsettings.LayoutminEdgeJetty;
        clayout.channelBuffer = layoutsettings.LayoutchannelBuffer;
        clayout.sortEdges = layoutsettings.LayoutsortEdges;
        if (invert) clayout.resetEdges = true;
        layout = clayout;
        break;
      }
      // case "mxGraphLayout": { layout = new mxGraphLayout(graph); break; }
      case "mxRadialTreeLayout": {
        let rlayout: mxRadialTreeLayout = new mxRadialTreeLayout(graph);
        rlayout.levelDistance = layoutsettings.LayoutlevelDistance;
        rlayout.nodeDistance = layoutsettings.LayoutnodeDistance;
        rlayout.autoRadius = layoutsettings.LayoutautoRadius;
        rlayout.sortEdges = layoutsettings.LayoutsortEdges;
        layout = rlayout;
        break;
      }
      // case "mxEdgeLabelLayout": { layout = new mxEdgeLabelLayout(graph); break; }
      case "mxStackLayout": { layout = new mxStackLayout(graph, horizontal, 10, 20); break; }
      case "mxPartitionLayout": { layout = new mxPartitionLayout(graph, horizontal, 10, 20); break; }
      case "mxHierarchicalLayout": {
        let deterministic: boolean = layoutsettings.Layoutdeterministic;
        let hlayout: mxHierarchicalLayout = new mxHierarchicalLayout(graph, layoutsettings.Layoutorientation, deterministic);
        hlayout.resizeParent = layoutsettings.LayoutresizeParent;
        hlayout.maintainParentLocation = layoutsettings.LayoutmaintainParentLocation;
        hlayout.moveParent = layoutsettings.LayoutmoveParent;
        hlayout.parentBorder = layoutsettings.LayoutparentBorder;
        hlayout.intraCellSpacing = layoutsettings.LayoutintraCellSpacing;
        hlayout.interRankCellSpacing = layoutsettings.LayoutinterRankCellSpacing;
        hlayout.interHierarchySpacing = layoutsettings.LayoutinterHierarchySpacing;
        hlayout.parallelEdgeSpacing = layoutsettings.LayoutparallelEdgeSpacing;
        // hlayout.resetEdges = layoutsettings.LayoutresetEdges;
        hlayout.fineTuning = layoutsettings.LayoutfineTuning;
        hlayout.tightenToSource = layoutsettings.LayouttightenToSource;
        hlayout.disableEdgeStyle = layoutsettings.LayoutdisableEdgeStyle;
        hlayout.traverseAncestors = layoutsettings.LayouttraverseAncestors;
        hlayout.edgeStyle = layoutsettings.LayoutedgeStyle; // mxHierarchicalEdgeStyle.POLYLINE; // !!!!!!!!
        layout = hlayout;
        break;
      }
      case "mxSwimlaneLayout": {
        let shapes = this.getSelectionCells();
        if (shapes.length > 0) {
          this.swimlaneLayout(shapes[0]);
        }
        // parent = graph.getDefaultParent();
        // let lanes = parent.children.filter(x => x.shapeKey === SemTalkMaster.MasterSwimlane);
        // // let roots = layout.findRoots(parent,children);
        // mxSwimlaneLayout.prototype.resizeParent = true;
        // mxSwimlaneLayout.prototype.moveParent = true;
        // mxSwimlaneLayout.prototype.orientation = mxConstants.DIRECTION_NORTH;
        // layout = new mxSwimlaneLayout(graph, mxConstants.DIRECTION_NORTH);
        // layout.execute(parent, lanes);
        // mxSwimlaneLayout.prototype.orientation = mxConstants.DIRECTION_WEST;
        // layout = new mxSwimlaneLayout(graph, mxConstants.DIRECTION_WEST);
        // for (let l of lanes) {
        //   layout.execute(l, l.children);
        // }
        // let shapes = this.getSelectionCells();
        //   if (shapes.length > 0) {
        //     parent = shapes[0].getParent();
        //     // let children = parent.children.filter(x => x.vertex);
        //     layout = new mxSwimlaneLayout(graph, mxConstants.DIRECTION_WEST);
        //     // let roots = layout.findRoots(parent,children);
        //     layout.execute(parent, [shapes[0]]);
        //   }
        return;

      }

    }

    //- mxParallelEdgeLayout,
    //- mxFastOrganicLayout,
    //- mxCircleLayout,
    //- mxCompactTreeLayout,
    // mxCompositeLayout,
    // - mxEdgeLabelLayout,
    // - mxGraphLayout,
    // - mxPartitionLayout,
    // - mxRadialTreeLayout,
    // - mxStackLayout,
    // mxHierarchicalLayout,
    // mxSwimlaneLayout,
    if (layout) {
      graph.getModel().beginUpdate();
      try {
        // layout.forceConstant = 80;
        // console.debug(parent);
        if (node && layout.layout) {
          layout.layout(parent, root);
        } else {
          layout.execute(parent, root);
        }
      }
      catch (e) {
        graph.getModel().endUpdate();
        console.debug((e as any).message);
        // throw e;
      }
      finally {
        if (animate) {
          var morph = new mxMorphing(graph);
          morph.addListener(mxEvent.DONE, () => {
            graph.getModel().endUpdate();
          });

          morph.startAnimation();
        }
        else {
          graph.getModel().endUpdate();
        }
      }
    }
  }
  public isGraphHorizontal(): boolean {
    return ((this.graph as any)["horizontal"] === 'true');
  }
  public isHorizontal(): boolean {
    var vstyle = this.graph.getStylesheet().getCellStyle(mxConstants.SHAPE_SWIMLANE);
    let ishorizontal = vstyle[mxConstants.STYLE_HORIZONTAL];
    return ishorizontal;
  }
  public isPool = (cell: mxCell): boolean => {
    var model2 = this.graph.getModel();
    var parent1 = model2.getParent(cell);

    return parent1 !== null && model2.getParent(parent1) === model2.getRoot();
  }

  public enableDragDrop() {
    let graph = this.graph;
    if (graph.isEnabled() && !this.props.islocked) {
      graph.foldingEnabled = true;
      graph.setDropEnabled(true);
      graph.setSplitEnabled(this.props.splitenabled);

      // Returns true for valid drop operations

      graph.isValidDropTarget = (target: any, cells: any, evt: any) => {
        // this.droptarget = null;
        if (target.container) {
          //   this.droptarget = target;
          return true;
        }
        if (target.shapeKey && (target.shapeKey === SemTalkMaster.MasterSwimlane ||
          target.shapeKey === "group")) {
          // this.droptarget = target;
          return true;
        }
        // console.debug(target);
        if (graph.isSplitEnabled() && graph.isSplitTarget(target, cells, evt)) {
          return this.props.semtalk.visIsSplitTarget(target, cells, evt);
        }
        return false;
      };
      // Allows new connections but no dangling edges
      graph.setConnectable(true);
      graph.setAllowDanglingEdges(false);
    }
  }

  public enableSwimlanes(ishorizontal: boolean, _enforce: boolean) {
    let graph = this.graph;
    let model = graph.getModel();
    graph.swimlaneNesting = true;

    //style[mxConstants.STYLE_HORIZONTAL] = 0;

    let vstyle = graph.getStylesheet().getCellStyle(mxConstants.SHAPE_SWIMLANE);
    // let vstyle = mxUtils.clone(graph.getStylesheet().getDefaultVertexStyle());
    // aka new graph
    if (vstyle[mxConstants.STYLE_HORIZONTAL] === undefined) {
      let v = ishorizontal;
      let h = (graph as any)["horizontal"];
      if (h) {
        v = (h === 'true');
      }
      vstyle[mxConstants.STYLE_HORIZONTAL] = v;
    } else {
      // return;
    }

    // vstyle[mxConstants.STYLE_HORIZONTAL] = ishorizontal;
    let isvertical = !vstyle[mxConstants.STYLE_HORIZONTAL];
    // let isvertical = v;
    // vstyle[mxConstants.STYLE_HORIZONTAL] = false;

    vstyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_SWIMLANE;
    // vstyle[mxConstants.STYLE_VERTICAL_ALIGN] = 'middle';
    // vstyle[mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = 'lightblue';
    // vstyle[mxConstants.STYLE_FONTSIZE] = 11;
    // vstyle[mxConstants.STYLE_STARTSIZE] = 22;
    // if (ishorizontal) {
    //   //Horizontal
    //   vstyle[mxConstants.STYLE_HORIZONTAL] = false;
    // }
    // else {
    //   //Vertical
    //   vstyle[mxConstants.STYLE_HORIZONTAL] = true;
    // }

    // vstyle[mxConstants.STYLE_VERTICAL_ALIGN] = 'middle';
    // vstyle[mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = 'white';
    // vstyle[mxConstants.STYLE_FONTSIZE] = 11;
    vstyle[mxConstants.STYLE_STARTSIZE] = 30;
    // vstyle[mxConstants.STYLE_HORIZONTAL] = false;
    // vstyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    vstyle[mxConstants.STYLE_STROKEWIDTH] = 2;
    // vstyle[mxConstants.STYLE_STROKECOLOR] = 'black';
    // delete style[mxConstants.STYLE_FILLCOLOR];


    // vstyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    // vstyle[mxConstants.STYLE_STROKECOLOR] = 'black';
    //delete style[mxConstants.STYLE_FILLCOLOR];
    graph.getStylesheet().putCellStyle(mxConstants.SHAPE_SWIMLANE, vstyle);

    if (isvertical) {
      graph.alternateEdgeStyle = SemTalkStyleAttribute.elbow + '=horizontal';
    } else {
      graph.alternateEdgeStyle = SemTalkStyleAttribute.elbow + '=vertical';
    }
    // Adds automatic layout and various switches if the
    // graph is enabled


    // Adds new method for identifying a pool
    // Changes swimlane orientation while collapsed
    graph.model.getStyle = function (cell: any) {
      var style1 = mxGraphModel.prototype.getStyle.apply(this, arguments);

      if (graph.isCellCollapsed(cell)) {
        if (style1 !== null) {
          style1 += ';';
        }
        else {
          style1 = '';
        }
        let vs = graph.getStylesheet().getCellStyle(mxConstants.SHAPE_SWIMLANE);
        let isv = vs[mxConstants.STYLE_HORIZONTAL];
        if (!isv) {
          style1 += 'horizontal=1;align=left;spacingLeft=14;';
        } else {
          style1 += 'horizontal=0;align=top;spacingLeft=14;';
        }
      }

      return style1;
    };

    // Keeps widths on collapse/expand
    let foldingHandler = (_sender: any, evt: any) => {
      let cells = evt.getProperty('cells');
      let vs = graph.getStylesheet().getCellStyle(mxConstants.SHAPE_SWIMLANE);
      let isv = vs[mxConstants.STYLE_HORIZONTAL];

      for (let i = 0; i < cells.length; i++) {
        let cell = cells[i];
        if (this.graph.isSwimlane(cell)) {
          let geo = graph.model.getGeometry(cell);
          if (geo.alternateBounds !== null) {
            if (!isv) {
              geo.width = geo.alternateBounds.width;
            } else {
              geo.height = geo.alternateBounds.height;
            }
          }
        }
      }
    };

    graph.addListener(mxEvent.FOLD_CELLS, foldingHandler);


    // Applies size changes to siblings and parents
    this.swimlanemanager = new mxSwimlaneManager(graph, isvertical, true, true);
    // this.swimlanemanager = new mxSwimlaneManager(graph);

    // Creates a stack depending on the orientation of the swimlane
    this.stacklayout = new mxStackLayout(graph, !isvertical);

    // this.stacklayout.spacing = 30;
    // this.stacklayout.allowGaps = true;


    // Makes sure all children fit into the parent swimlane
    this.stacklayout.resizeParent = true;

    // Applies the size to children if parent size changes
    this.stacklayout.fill = true;

    // Only update the size of swimlanes
    this.stacklayout.isVertexIgnored = (vertex: any) => {
      let issl = graph.isSwimlane(vertex);
      return !issl;
    };

    // Keeps the lanes and pools stacked
    let layoutMgr = new mxLayoutManager(graph);

    layoutMgr.getLayout = (cell: any) => {
      // if (!model.isEdge(cell) && graph.getModel().getChildCount(cell) > 0 &&
      //   (model.getParent(cell) === model.getRoot() || this.isPool(cell))) {
      //   this.stacklayout.fill = this.isPool(cell);
      //   return this.stacklayout;
      // }
      if (!model.isEdge(cell) && graph.getModel().getChildCount(cell) > 0 && this.isPool(cell)) {
        let fill = this.isPool(cell);
        let resize = true;
        if (cell.parent.id === "1") {
          let slss = cell.children.filter((x: any) => this.graph.isSwimlane(x));
          if (slss.length === 1) {
            fill = false;
            resize = false;
            let sl: mxCell = slss[0] as mxCell;
            if (isvertical) {
              sl.geometry.height = cell.geometry.height;
            } else {
              sl.geometry.width = cell.geometry.width;
            }
          }
        }
        this.stacklayout.resizeParent = resize;
        this.stacklayout.fill = fill;
        return this.stacklayout;
      }
      return null;
    };

    // this.refresh();
  }

  public toggleDirection(shape: mxCell | null) {

    this.props.callback.beginTransaction("toggleDirection");

    let graph = this.graph;
    // let model = graph.getModel();

    var vstyle = graph.getStylesheet().getCellStyle(mxConstants.SHAPE_SWIMLANE);
    let ishorizontal = vstyle[mxConstants.STYLE_HORIZONTAL];
    ishorizontal = !ishorizontal;
    vstyle[mxConstants.STYLE_HORIZONTAL] = ishorizontal;
    // let isvertical = !ishorizontal;

    // if (isvertical) {
    //   vstyle[mxConstants.STYLE_FILLCOLOR] = 'yellow';
    //   vstyle[mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = 'yellow';
    // } else {
    //   vstyle[mxConstants.STYLE_FILLCOLOR] = 'green';
    //   vstyle[mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = 'green';
    // }
    // vstyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    // vstyle[mxConstants.STYLE_STROKECOLOR] = 'black';
    //delete style[mxConstants.STYLE_FILLCOLOR];
    graph.getStylesheet().putCellStyle(mxConstants.SHAPE_SWIMLANE, vstyle);
    if (ishorizontal) {
      graph.alternateEdgeStyle = 'elbow=horizontal';
    } else {
      graph.alternateEdgeStyle = 'elbow=vertical';
    }
    if (shape) {
      if (this.isPool(shape)) {
        let cells = shape.children.filter(x => this.graph.isSwimlane(x));
        for (let i = 0; i < cells.length; i++) {
          let lane = cells[i];
          let wl = lane.geometry.width;
          lane.geometry.width = lane.geometry.height;
          lane.geometry.height = Math.min(800, wl);
          (lane.geometry as any).alternateBounds = null;
        }
      }
      let w = shape.geometry.width;
      shape.geometry.width = shape.geometry.height;
      shape.geometry.height = Math.min(800, w);
      (shape.geometry as any).alternateBounds = null;
    }

    // Applies size changes to siblings and parents
    // this.swimlanemanager.setHorizontal(!isvertical);
    // this.swimlanemanager = new mxSwimlaneManager(graph, !isvertical, true, true);
    // this.swimlanemanager = new mxSwimlaneManager(graph, !ishorizontal);

    // Creates a stack depending on the orientation of the swimlane
    // this.stacklayout = new mxStackLayout(graph, isvertical);
    // Makes sure all children fit into the parent swimlane
    // this.stacklayout.resizeParent = true;

    // Applies the size to children if parent size changes
    // this.stacklayout.fill = true;

    // Creates a stack depending on the orientation of the swimlane
    this.stacklayout = new mxStackLayout(graph, ishorizontal);

    // Makes sure all children fit into the parent swimlane
    this.stacklayout.resizeParent = true;

    // Applies the size to children if parent size changes
    this.stacklayout.fill = false;

    // Only update the size of swimlanes
    this.stacklayout.isVertexIgnored = (vertex: any) => {
      return !graph.isSwimlane(vertex);
    };

    // var layoutMgr = new mxLayoutManager(graph);
    // layoutMgr.getLayout = (cell: any) => {
    //   if (!model.isEdge(cell) && graph.getModel().getChildCount(cell) > 0 &&
    //     (model.getParent(cell) === model.getRoot() || graph.isPool(cell))) {
    //     this.stacklayout.fill = graph.isPool(cell);
    //     return this.stacklayout;
    //     // return null;
    //   }
    //   return null;
    // };
    if (shape) {
      if (this.isPool(shape)) {
        this.stacklayout.execute(shape);
      }
    }

    graph.refresh();
    // this.enableSwimlanes(!ishorizontal, false);
    this.props.callback.endTransaction("toggleDirection");
  }

  public swimlaneLayout(shape: mxCell) {

    this.props.callback.beginTransaction("swimlaneLayout");

    let graph = this.graph;

    let ishorizontal = this.isHorizontal();

    // if (shape) {
    //   if (this.isPool(shape)) {
    //     let cells = shape.children.filter(x => this.graph.isSwimlane(x));
    //     for (let i = 0; i < cells.length; i++) {
    //       let lane = cells[i];
    //       let wl = lane.geometry.width;
    //       lane.geometry.width = lane.geometry.height;
    //       lane.geometry.height = Math.min(800, wl);
    //       lane.geometry.alternateBounds = null;
    //     }
    //   }
    // }

    let layout = new mxSwimlaneLayout(graph);

    if (!ishorizontal) {
      layout.orientation = mxConstants.DIRECTION_WEST;
    } else {
      layout.orientation = mxConstants.DIRECTION_NORTH;
    }
    layout.resizeParent = false;
    layout.moveParent = true;
    // layout.interRankCellSpacing = 20;
    layout.disableEdgeStyle = false;
    // layout.traverseAncestors = false;
    // let parent = graph.getDefaultParent();
    layout.maintainParentLocation = true;


    let children = this.graph.getChildCells(shape, true, false);
    // layout.execute(shape, children);
    this.mxSwimlaneLayoutexecute(layout, shape, children);

    // layout.execute(parent, [shape]);
    graph.refresh();
    // this.enableSwimlanes(!ishorizontal, false);
    this.props.callback.endTransaction("swimlaneLayout");
  }

  private mxSwimlaneLayoutexecute = (layout: any, parent: mxCell | null, swimlanes: mxCell[]) => {
    layout.parent = parent;
    let model = this.graph.model;
    layout.edgesCache = new mxDictionary();
    layout.edgeSourceTermCache = new mxDictionary();
    layout.edgesTargetTermCache = new mxDictionary();

    // If the roots are set and the parent is set, only
    // use the roots that are some dependent of the that
    // parent.
    // If just the root are set, use them as-is
    // If just the parent is set use it's immediate
    // children as the initial set

    if (swimlanes == null || swimlanes.length < 1) {
      // TODO indicate the problem
      return;
    }

    if (parent == null) {
      parent = model.getParent(swimlanes[0]);
    }

    //  Maintaining parent location
    layout.parentX = null;
    layout.parentY = null;

    if (parent !== layout.root && model.isVertex(parent) != null && layout.maintainParentLocation) {
      let geo = this.graph.getCellGeometry(parent);

      if (geo != null) {
        layout.parentX = geo.x;
        layout.parentY = geo.y;
      }
    }

    layout.swimlanes = swimlanes;
    let dummyVertices: mxCell[] = [];
    // Check the swimlanes all have vertices
    // in them
    for (var i = 0; i < swimlanes.length; i++) {
      let children = this.graph.getChildCells(swimlanes[i]);

      if (children == null || children.length === 0) {
        var vertex = this.graph.insertVertex(swimlanes[i], '1234567', "XXX", 0, 0, layout.dummyVertexWidth, 0);
        dummyVertices.push(vertex);
      }
    }

    model.beginUpdate();
    try {
      layout.run(parent);

      if (layout.resizeParent && !this.graph.isCellCollapsed(parent)) {
        this.graph.updateGroupBounds([parent], layout.parentBorder, layout.moveParent);
      }

      // Maintaining parent location
      if (layout.parentX != null && layout.parentY != null) {
        let geo = this.graph.getCellGeometry(parent);

        if (geo != null) {
          geo = geo.clone();
          geo.x = layout.parentX;
          geo.y = layout.parentY;
          model.setGeometry(parent, geo);
        }
      }

      this.graph.removeCells(dummyVertices, false);
    }
    finally {
      model.endUpdate();
    }
  }

  public alert(msg: string, mtype: MessageBarType): void {
    if (this.props.callback) {
      this.props.callback.alert(msg, mtype);
    }
  }

  private findItemFromArray(arr: any, query: any) {
    const key = Object.keys(query)[0];
    const value = query[key];

    let result;

    arr && arr.forEach((item: any) => {
      if (item && item[key] === value) {
        result = item;
      }
    });

    return result;
  }

  public getShapeStyle(shapeName: string, shapeType: string): { style: string, isedge: boolean } {
    // return util.getShapeStyle(shapeName, shapeType);
    // getShapeStyle(key: string, shapeType: string): { style: string, isedge: boolean } {
    let key = shapeName;
    let shapeStyle = key;
    let isedge: boolean = false;
    //console.debug("Type " + shapeType + " " + key);

    switch (shapeType) {
      // case 'svg': {
      //   shapeStyle = `shape=${key}`;
      //   break;
      // }
      case SemTalkShapeType.general: {
        if (GENERAL_SHAPES[key]) {
          if (GENERAL_SHAPES[key].type === 'edge') {
            isedge = true;
          }
          shapeStyle = GENERAL_SHAPES[key].style;
        }
        break;
      }
      case SemTalkShapeType.image: {
        const shape: any = this.findItemFromArray(this.imageShapes, {
          key: key,
        });
        shapeStyle = `${key}`;
        if (shape) {
          const img = shape.logo;
          shapeStyle = SemTalkStyleAttribute.shape + `=image;html=1;whiteSpace=wrap;imageAspect=0;image=${img}`;
        }
        break;
      }
      // case 'card': {
      //   shapeStyle = `${key}`;
      //   break;
      // }
    }
    return { style: shapeStyle, isedge: isedge };
  }

  public align(direction: string, cells: mxCell[] | undefined): void {
    if (!cells) {
      cells = this.getSelectionCells();
    }
    switch (direction) {
      case "AlignHorizontalLeft": { this.graph.alignCells(mxConstants.ALIGN_LEFT, cells); break; }
      case "AlignHorizontalCenter": { this.graph.alignCells(mxConstants.ALIGN_CENTER, cells); break; }
      case "AlignHorizontalRight": { this.graph.alignCells(mxConstants.ALIGN_RIGHT, cells); break; }
      case "AlignVerticalTop": { this.graph.alignCells(mxConstants.ALIGN_TOP, cells); break; }
      case "AlignVerticalCenter": { this.graph.alignCells(mxConstants.ALIGN_MIDDLE, cells); break; }
      case "AlignVerticalBottom": { this.graph.alignCells(mxConstants.ALIGN_BOTTOM, cells); break; }
    }
  }
  public distribute(direction: boolean, cells: mxCell[] | undefined): mxCell[] {
    if (cells == null) {
      cells = this.getSelectionCells();
    }

    if (cells != null && cells.length > 1) {
      let vertices: mxCellState[] = [];
      let max: null | number = null;
      let min: null | number = null;

      for (let i = 0; i < cells.length; i++) {
        if (this.graph.getModel().isVertex(cells[i])) {
          let state = this.graph.view.getState(cells[i]);

          if (state != null) {
            var tmp = (direction) ? state.getCenterX() : state.getCenterY();
            max = (max != null) ? Math.max(max, tmp) : tmp;
            min = (min != null) ? Math.min(min, tmp) : tmp;

            vertices.push(state);
          }
        }
      }

      if (vertices.length > 2) {
        vertices.sort((a, b) => {
          return (direction) ? a.x - b.x : a.y - b.y;
        });

        let t = this.graph.view.translate;
        let s = this.graph.view.scale;
        if (max == null) max = 0;
        if (min == null) min = 0;
        min = min / s - ((direction) ? t.x : t.y);
        max = max / s - ((direction) ? t.x : t.y);

        this.graph.getModel().beginUpdate();
        try {
          let dt = (max - min) / (vertices.length - 1);
          let t0 = min;

          for (let ii = 1; ii < vertices.length - 1; ii++) {
            let pstate = this.graph.view.getState(this.graph.model.getParent(vertices[ii].cell));
            let geo = this.graph.getCellGeometry(vertices[ii].cell);
            t0 += dt;

            if (geo != null && pstate != null) {
              geo = geo.clone();

              if (direction) {
                geo.x = Math.round(t0 - geo.width / 2) - pstate.origin.x;
              }
              else {
                geo.y = Math.round(t0 - geo.height / 2) - pstate.origin.y;
              }

              this.graph.getModel().setGeometry(vertices[ii].cell, geo);
            }
          }
        }
        finally {
          this.graph.getModel().endUpdate();
        }
      }
    }

    return cells;
  }

  public UpdatePortalStencil = (stencil: any) => {
    if ((this.state.isstencil || this.stencilel) && !this.stencil && !this.props.isplanmaker) {
      this.stencil = <div id="stencilContainer">
        <Sidebar key="sidebar" editor={this} stencil={stencil}
          height={this.props.height} istriple={false} />
      </div>;
      this.stencil_portal = <div id="stencilContainer">
        <Sidebar key="sidebar" editor={this} stencil={stencil}
          height={this.props.height} istriple={true} />
      </div>;
    }
    let st = this.state.isstencil;
    if (st && !this.props.dockstencil && !this.stencilwnd?.visible && !this.props.islocked) {
      this.showStencil();
    }
    if (this.props.dockstencil && this.stencilwnd) {
      this.stencilwnd.setVisible(false);
    }
  }

  public render() {
    // let divOutlineStyle = {
    //   top: this.state.panzoomY + "px",
    //   left: this.state.panzoomX + "px"
    //   // width: "100%" //this.props.width
    //   //style={divOutlineStyle}
    // };
    // let showcombo = parent.state.cntpages > 1;
    let diaglist = this.state.diaglist;
    const stackTokens = { childrenGap: 10 };
    let ffs = accessCookie(SemTalkCookie.fontsize);
    if (ffs) mxConstants.DEFAULT_FONTSIZE = parseInt(ffs);
    if (mxConstants.DEFAULT_FONTSIZE < 6) mxConstants.DEFAULT_FONTSIZE = 11;
    let ff = accessCookie(SemTalkCookie.font);
    if (ff) mxConstants.DEFAULT_FONTFAMILY = ff;

    let suspfallback = <div></div>;

    if ((this.state.isnavigator || this.navigatorel) && !this.navigator && !this.props.isplanmaker) {
      this.navigator = <SemTalkNavigator semtalk={this.props.semtalk}
        callback={this.props.callback} ></SemTalkNavigator >;
    }
    // if ((this.state.issearch || this.searchel) && !this.search && !this.props.isplanmaker) {
    //   this.search = <SemTalkTable semtalk={this.props.semtalk}
    //     callback={this.props.callback} ></SemTalkTable>;
    // }
    this.UpdatePortalStencil(this.props.stencil);
    // if (this.state.isplanner) {
    // let callback = this.props.callback;
    if ((this.state.isplanner || this.plannerel) && !this.planner && !this.props.isplanmaker) {
      this.planner = <SemTalkPlanner semtalk={this.props.semtalk}
        callback={this.props.callback}
        islocked={this.props.islocked}
        isplanmaker={false}
        isusertask={false}
        language={this.props.language}
        guilanguage={this.props.semtalk.guilanguage}
      ></SemTalkPlanner>;
    }
    let disable = false;
    if (this.props.loading) {
      disable = true;
    }
    const diveditStyle: any = {
      "textAlign": "left",
      "padding": "20px",
      "width": this.props.editdialogwidth
    };
    if ((this.state.ispivot || this.pivotel) && !this.pivot && !this.props.isplanmaker) {
      this.pivot = <div style={diveditStyle}>
        <Suspense fallback={suspfallback}>
          <SemTalkPivot
            context={this.props.context}
            callback={this.props.callback}
            semtalk={this.props.semtalk}
            usegraph={this.props.usegraph}
            role={this.props.role}
            graphClient={this.props.graphClient}
            sharepoint={this.props.sharepoint}
            sharepointlibrary={this.props.sharepointlibrary}
            sharepointlibrarysite={this.props.sharepointlibrarysite}
            sharepointdocumentcolumns={this.props.sharepointdocumentcolumns}
            navigateproperties={true}
            showGeneral={true}
            showAttributes={true}
            showExpressions={false}
            showAssocs={true}
            showSynonyms={true}
            showMethods={true}
            showStates={true}
            showInheritance={true}
            showInstances={true}
            showAudit={true}
            showdiagproperties={false}
            showAssociationType={true}
            showAttributeType={true}
            showDiagramType={true}
            showislist={true}
            showSimulation={this.props.showSimulation}
            showBPMN={this.props.showBPMN}
            stencil={this.props.stencil}
            spCheckInOut={this.props.spCheckInOut}
            mongo={this.props.mongo}
            islocked={this.props.islocked}
            isanchored={true}
            paneltype={PanelType.medium}
          ></SemTalkPivot>
        </Suspense>
      </div >;
    }

    const docked = <Stack tokens={stackTokens}>
      {this.state.ispages && diaglist.length > 1 &&
        <StackItem>
          <Pages disabled={disable} editor={this} diaglist={diaglist} diagid={this.props.diagid}></Pages>
        </StackItem>
      }
      {this.state.isnavigator && this.props.docknavigator && !this.props.isplanmaker &&
        <StackItem>
          {this.navigator}
        </StackItem>
      }
      {/* {this.state.issearch && this.props.docksearch && !this.props.isplanmaker &&
        <StackItem>
          {this.search}
        </StackItem>
      } */}
      {this.state.ispanzoom && this.props.dockpanandzoom &&
        <StackItem>
          <div id="outlineContainer" className={styles.outline}>
          </div>
          <div id="stencilContainerDummy"></div>
        </StackItem>
      }
      {this.state.isplanner && this.props.dockplanner && !this.props.isplanmaker &&
        <StackItem>
          {this.planner}
        </StackItem>
      }
      {!this.props.loading && this.state.ispivot && this.props.dockpivot && !this.props.isplanmaker &&
        <StackItem>
          {this.pivot}
        </StackItem>
      }
      {this.props.dockstencil && this.state.isstencil && !this.props.islocked && !this.props.isplanmaker &&
        <StackItem>
          {this.stencil}
        </StackItem>
      }
    </Stack>;

    return (
      <div>
        {
          this.state.isstencil && !this.props.dockstencil && this.stencilel && <Portal
            container={this.stencilel as HTMLElement}
            contents={this.stencil_portal}
          />
        }
        {
          !this.props.dockplanner && this.plannerel && <Portal
            container={this.plannerel as HTMLElement}
            contents={this.planner}
          />
        }
        {
          !this.props.docknavigator && this.navigatorel && <Portal
            container={this.navigatorel as HTMLElement}
            contents={this.navigator}
          />
        }
        {/* {!this.props.docksearch && this.searchel && <Portal
          container={this.searchel as HTMLElement}
          contents={this.search}
        />
        } */}
        <div className={"editor-container " + styles.editorcontainer}>
          <div className={"graph-inner-container " + styles.graphinnercontainer}>
            <Stack horizontal tokens={stackTokens}>
              {this.props.shapesleftside && docked}
              <div id='some-empty-div-on-the-page' ref="divGraph" className={"graph-content " + styles.graphcontent}
                key="graphcontent" />
              {!this.props.shapesleftside && docked}
            </Stack>
            {/* </div> */}
          </div>
        </div>
      </div >
    );
  }

  //   public xxx = (erXmlString) => {
  //     let doc = mxXmlUtils.parseXml(erXmlString);
  //     let codec = new mxCodec(doc);
  //     codec.decode(doc.getDocumentElement(), this.graph.getModel());

  //     let graphComponent = new mxGraphComponent(this.graph);
  //     let image = mxCellRenderer.createBufferedImage(graphComponent.getGraph(), null, 1, , graphComponent.isAntiAlias(), null, graphComponent.getCanvas());
  //     let param = mxPngEncodeParam.getDefaultEncodeParam(image);
  //     param.setCompressedText( ["mxGraphModel", erXmlString ]);

  //     FileOutputStream outputStream = new FileOutputStream(new File(filename));
  //     mxPngImageEncoder encoder = new mxPngImageEncoder(outputStream, param);

  //     if (image !== null) {
  //         encoder.encode(image);
  //         return image;
  //     }
  //     outputStream.close();
  //     return null;
  // }
  //   }
  public exportSVG = (graph: mxGraph) => {
    // let background = '#ffffff';

    let background = null;
    let scale: number = 1;
    let border: number = 1;

    let bounds = graph.getGraphBounds();
    let vs = graph.view.scale;

    // Prepares SVG document that holds the output
    let svgDoc = mxUtils.createXmlDocument();
    let root = (svgDoc.createElementNS !== null) ?
      svgDoc.createElementNS(mxConstants.NS_SVG, 'svg') : svgDoc.createElement('svg');

    if (background !== null) {
      if (root.style !== null) {
        root.style.backgroundColor = background;
      }
      else {
        root.setAttribute('style', 'background-color:' + background);
      }
    }

    if (svgDoc.createElementNS === null) {
      root.setAttribute('xmlns', mxConstants.NS_SVG);
      root.setAttribute('xmlns:xlink', mxConstants.NS_XLINK);
    }
    else {
      // KNOWN: Ignored in IE9-11, adds namespace for each image element instead. No workaround.
      root.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:xlink', mxConstants.NS_XLINK);
    }

    root.setAttribute('width', (Math.ceil(bounds.width * scale / vs) + 2 * border) + 'px');
    root.setAttribute('height', (Math.ceil(bounds.height * scale / vs) + 2 * border) + 'px');
    root.setAttribute('version', '1.1');

    // Adds group for anti-aliasing via transform
    let group = (svgDoc.createElementNS !== null) ?
      svgDoc.createElementNS(mxConstants.NS_SVG, 'g') : svgDoc.createElement('g');
    group.setAttribute('transform', 'translate(0.5,0.5)');
    root.appendChild(group);
    svgDoc.appendChild(root);

    // Renders graph. Offset will be multiplied with state's scale when painting state.
    let svgCanvas = new mxSvgCanvas2D(group);
    svgCanvas.translate(Math.floor((border / scale - bounds.x) / vs), Math.floor((border / scale - bounds.y) / vs));
    svgCanvas.scale(scale / vs);

    // Displayed if a viewer does not support foreignObjects (which is needed to HTML output)
    // svgCanvas.foAltText = '[Not supported by viewer]';
    svgCanvas.foEnabled = true;

    let imgExport: any = new mxImageExport();
    imgExport.includeOverlays = true;
    imgExport.includeControls = false;
    imgExport.drawState(graph.getView().getState(graph.model.root), svgCanvas);

    // let svg = '<?xml version="1.0" encoding="UTF-8"?>\n'
    // + '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">\n'
    // + mxUtils.getXml(root);
    // var a = document.createElement('a');
    // a.download = "image.svg";
    // a.href = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);
    // document.body.appendChild(a);
    // a.click();

    // let xml = encodeURIComponent(mxUtils.getXml(root));
    let xml = mxUtils.getXml(root);
    let ff = accessCookie(SemTalkCookie.font);
    if (!ff) ff = mxConstants.DEFAULT_FONTFAMILY;
    let svgfix: string = xml.replace(/;font-family:" segoe="" ui="" westeuropean",="" "segoe="" ui",="" -apple-system,="" blinkmacsystemfont,="" roboto,="" "helvetica="" neue",="" sans-serif"="/gi, ';font-family:"' + ff + '"');
    // svgfix = encodeURIComponent(svgfix);
    return svgfix;
    //  new mxXmlRequest('/Echo', 'filename=export.svg&format=svg' + '&xml=' + xml).simulate(document, '_blank');

    //  https://libraries.io/npm/svg2png
    // var buf = Buffer.from(xml, 'utf8');
  }
  // public exportFile = (format: any) => {
  //   let graph = this.graph;
  //   var bg = '#ffffff';
  //   var scale = 1;
  //   var b = 1;

  //   var imgExport = new mxImageExport();
  //   var bounds = graph.getGraphBounds();
  //   var vs = graph.view.scale;

  //   // New image export
  //   var xmlDoc = mxUtils.createXmlDocument();
  //   var root = xmlDoc.createElement('output');
  //   xmlDoc.appendChild(root);

  //   // Renders graph. Offset will be multiplied with state's scale when painting state.
  //   var xmlCanvas = new mxXmlCanvas2D(root);
  //   xmlCanvas.translate(Math.floor((b / scale - bounds.x) / vs), Math.floor((b / scale - bounds.y) / vs));
  //   xmlCanvas.scale(scale / vs);

  //   imgExport.drawState(graph.getView().getState(graph.model.root), xmlCanvas);

  //   // Puts request data together
  //   var w = Math.ceil(bounds.width * scale / vs + 2 * b);
  //   var h = Math.ceil(bounds.height * scale / vs + 2 * b);

  //   var xml = mxUtils.getXml(root);

  //   if (bg !== null) {
  //     bg = '&bg=' + bg;
  //   }

  //   new mxXmlRequest('/Export', 'filename=export.' + format + '&format=' + format +
  //     bg + '&w=' + w + '&h=' + h + '&xml=' + encodeURIComponent(xml)).simulate(document, '_blank');
  // }

  // public graphSVG = (graph: mxGraph): string => {
  //   var scale = 1;
  //   var b = 1;

  //   var imgExport = new mxImageExport();
  //   var bounds = graph.getGraphBounds();
  //   var vs = graph.view.scale;

  //   // New image export
  //   var xmlDoc = mxUtils.createXmlDocument();
  //   var root = xmlDoc.createElement('output');
  //   xmlDoc.appendChild(root);

  //   // Renders graph. Offset will be multiplied with state's scale when painting state.
  //   var xmlCanvas = new mxXmlCanvas2D(root);
  //   xmlCanvas.translate(Math.floor((b / scale - bounds.x) / vs), Math.floor((b / scale - bounds.y) / vs));
  //   xmlCanvas.scale(scale / vs);

  //   imgExport.drawState(graph.getView().getState(graph.model.root), xmlCanvas);

  //   return mxUtils.getXml(root) as string;
  // }

  public exportSVGforPrint = (graph: mxGraph,
    printoverlays: boolean, printcontrols: boolean,
    printborder: number, printscale: number) => {
    let background = '#ffffff';
    let scale = printscale;
    let border = printborder;

    let bounds = graph.getGraphBounds();
    let vs = graph.view.scale;
    let svgDoc = mxUtils.createXmlDocument();
    let root = (svgDoc.createElementNS !== null) ?
      svgDoc.createElementNS(mxConstants.NS_SVG, 'svg') : svgDoc.createElement('svg');

    root.setAttribute('style', 'background-color:' + background);
    root.setAttribute('xmlns', mxConstants.NS_SVG);
    let svgwidth: number = (Math.ceil(bounds.width * scale / vs) + 2 * border);
    let svgheight: number = (Math.ceil(bounds.height * scale / vs) + 2 * border);
    root.setAttribute('width', svgwidth + 'pt');
    root.setAttribute('height', svgheight + 'pt');
    root.setAttribute('version', '1.1');
    root.setAttribute('viewBox', "0 0 " + (Math.ceil(bounds.width * scale / vs) + 2 * border) + " " + (Math.ceil(bounds.height * scale / vs) + 2 * border));


    let group = (svgDoc.createElementNS !== null) ?
      svgDoc.createElementNS(mxConstants.NS_SVG, 'g') : svgDoc.createElement('g');
    group.setAttribute('transform', 'translate(0.5,0.5)');
    root.appendChild(group);
    svgDoc.appendChild(root);

    let svgCanvas = new mxSvgCanvas2D(group);
    svgCanvas.translate(Math.floor((border / scale - bounds.x) / vs), Math.floor((border / scale - bounds.y) / vs));
    svgCanvas.scale(scale / vs);
    svgCanvas.foEnabled = true;

    let imgExport = new mxImageExport();
    imgExport.includeOverlays = printoverlays;
    imgExport.includeControls = printcontrols;
    imgExport.drawState(graph.getView().getState(graph.model.root), svgCanvas);


    let xml = mxUtils.getXml(root);
    let retValue: any = { svg: xml, width: svgwidth, height: svgheight };
    return retValue;
  }

  // public exportPNG = () => {
  //   let graph= this.graph;
  //   mxCodec codec = new mxCodec(doc);
  //   codec.decode(doc.getDocumentElement(), graph.getModel());

  //   mxGraphComponent graphComponent = new mxGraphComponent(graph);
  //   BufferedImage image = mxCellRenderer.createBufferedImage(graphComponent.getGraph(), null, 1, Color.WHITE, graphComponent.isAntiAlias(), null, graphComponent.getCanvas());
  //   mxPngEncodeParam param = mxPngEncodeParam.getDefaultEncodeParam(image);
  //   param.setCompressedText(new string[] { "mxGraphModel", erXmlString });

  //   FileOutputStream outputStream = new FileOutputStream(new File(filename));
  //   mxPngImageEncoder encoder = new mxPngImageEncoder(outputStream, param);

  //   if (image !== null) {
  //       encoder.encode(image);
  //       return image;
  //   }
  //   outputStream.close();
  //   return null;
  // }

  public clearCellOverlays(shape: mxCell) {
    let graph = this.graph;
    try {
      graph.clearCellOverlays(shape);
    } catch (e) {

    }
  }
  public addOverlay(shp: mxCell, imgpath: string, valign: string, align: string, witdh: number, height: number,
    dx: number, dy: number, tooltip: string, cursor?: string | null, handler?: () => void) {
    let celloverlay = new mxCellOverlay(new mxImage(imgpath, witdh, height), tooltip);
    celloverlay.verticalAlign = valign;
    celloverlay.align = align;
    celloverlay.offset = new mxPoint(dx, dy);
    let overlay = this.graph.addCellOverlay(shp, celloverlay);
    if (cursor) overlay.cursor = cursor;
    if (handler) overlay.addListener(mxEvent.CLICK, (_sender: any, evt: any) => {
      mxEvent.consume(evt);
      handler();
    });
  }
  public addURLOverlay(shp: mxCell, imgpath: string, valign: string, align: string, witdh: number, height: number,
    dx: number, dy: number, tooltip: string, cursor: string | null, url: string) {
    let celloverlay = new mxCellOverlay(new mxImage(imgpath, witdh, height), tooltip);
    celloverlay.verticalAlign = valign;
    celloverlay.align = align;
    celloverlay.offset = new mxPoint(dx, dy);
    let overlay = this.graph.addCellOverlay(shp, celloverlay);
    if (cursor) overlay.cursor = cursor;
    if (url) overlay.addListener(mxEvent.CLICK, (_sender: any, evt: any) => {
      mxEvent.consume(evt);
      try {
        window.open(url, "_blank");
      } catch (e) {
        console.debug("addOverlay: " + e);
      }
    });
  }
  public removeWayPointsfromCell(cell: mxCell) {
    if (this.graph.getModel().isEdge(cell)) {
      let geo: mxGeometry = this.graph.getCellGeometry(cell);
      if (geo != null) {
        geo = geo.clone();
        geo.points = [];
        this.graph.getModel().setGeometry(cell, geo);
      }
    }
  }

  public insertWayPointToCell(cell: mxCell) {
    // let cell = this.graph.getSelectionCell();

    if (cell !== null && this.graph.getModel().isEdge(cell)) {
      let handler = this.graph.selectionCellsHandler.getHandler(cell);
      if (handler instanceof mxEdgeHandler) {
        let t = this.graph.view.translate;
        let s = this.graph.view.scale;
        let dx = t.x;
        let dy = t.y;
        let parent = this.graph.getModel().getParent(cell);
        let pgeo = this.graph.getCellGeometry(parent);

        while (this.graph.getModel().isVertex(parent) && pgeo !== null) {
          dx += pgeo.x;
          dy += pgeo.y;
          parent = this.graph.getModel().getParent(parent);
          pgeo = this.graph.getCellGeometry(parent);
        }

        let nx = this.graph.popupMenuHandler.triggerX;
        let ny = this.graph.popupMenuHandler.triggerY;

        let x = Math.round(this.graph.snap(nx / s - dx));
        let y = Math.round(this.graph.snap(ny / s - dy));
        handler.addPointAt(handler.state, x, y);
      }
    }
  }
  public insertWayPointToCellXY(cell: mxCell, nx: number, ny: number) {
    if (cell !== null && this.graph.getModel().isEdge(cell)) {
      let handler = this.graph.selectionCellsHandler.getHandler(cell);
      if (handler instanceof mxEdgeHandler) {
        let t = this.graph.view.translate;
        let s = this.graph.view.scale;
        let dx = t.x;
        let dy = t.y;
        let parent = this.graph.getModel().getParent(cell);
        let pgeo = this.graph.getCellGeometry(parent);

        while (this.graph.getModel().isVertex(parent) && pgeo !== null) {
          dx += pgeo.x;
          dy += pgeo.y;
          parent = this.graph.getModel().getParent(parent);
          pgeo = this.graph.getCellGeometry(parent);
        }

        let x = Math.round(this.graph.snap(nx / s - dx));
        let y = Math.round(this.graph.snap(ny / s - dy));
        handler.addPointAt(handler.state, x, y);
      }
    }
  }
  // public insertCenterWayPointToCell(cell: mxCell) {
  //   // let cell = this.graph.getSelectionCell();

  //   if (cell !== null && this.graph.getModel().isEdge(cell)) {
  //     let handler = this.graph.selectionCellsHandler.getHandler(cell);
  //     if (handler instanceof mxEdgeHandler) {
  //       let t = this.graph.view.translate;
  //       let s = this.graph.view.scale;
  //       let dx = t.x;
  //       let dy = t.y;
  //       let parent = this.graph.getModel().getParent(cell);
  //       let pgeo = this.graph.getCellGeometry(parent);

  //       while (this.graph.getModel().isVertex(parent) && pgeo !== null) {
  //         dx += pgeo.x;
  //         dy += pgeo.y;
  //         parent = this.graph.getModel().getParent(parent);
  //         pgeo = this.graph.getCellGeometry(parent);
  //       }

  //       let nx = this.graph.popupMenuHandler.triggerX;
  //       let ny = this.graph.popupMenuHandler.triggerY;
  //       let sgeo = this.graph.getCellGeometry(cell.source);
  //       let tgeo = this.graph.getCellGeometry(cell.source);
  //       // console.debug(sgeo);
  //       // console.debug(tgeo);
  //       // // nx=sgeo.x+ sgeo.width/2;
  //       // ny=sgeo.y+ sgeo.height/2;
  //       nx = (sgeo.x + tgeo.x) / 2;
  //       ny = (sgeo.y + tgeo.y) / 2;

  //       let x = Math.round(this.graph.snap(nx / s - dx));
  //       let y = Math.round(this.graph.snap(ny / s - dy));

  //       x = nx;
  //       y = ny;
  //       this.addWaypointToEdge(cell, x, y);

  //       // handler.addPointAt(handler.state, x, y);
  //     }
  //   }
  // }

  // private addWaypointToEdge = (edge: mxCell, x: number, y: number) => {
  //   const model = this.graph.getModel();
  //   const edgeGeometry = model.getGeometry(edge);

  //   if (!edgeGeometry) {
  //     console.error('Edge must have a valid geometry.');
  //     return;
  //   }

  //   // Create a new mxPoint to represent the waypoint
  //   const waypoint = new mxPoint(x, y);

  //   // Add the waypoint to the edge's geometry
  //   const waypoints = edgeGeometry.points;
  //   if (!waypoints) {
  //     edgeGeometry.points = [waypoint];
  //   } else {
  //     waypoints.push(waypoint);
  //   }

  //   // Update the edge's geometry in the model
  //   model.setGeometry(edge, edgeGeometry);
  // }

  private undoListener = (graph: mxGraph, callbackFunc: any) => {
    // Undo/redo
    // const undoManager = new mxUndoManager(); //eslint-disable-line

    // this.undoManager = undoManager;

    const listener = (_sender: any, evt: any) => {
      this.undoman.undoableEditHappened(evt.getProperty('edit'));
    };
    graph.getModel().addListener(mxEvent.UNDO, listener); //eslint-disable-line
    graph.getView().addListener(mxEvent.UNDO, listener); //eslint-disable-line

    let undoListenerFunc = (undoManager1: mxUndoManager, callbackFunc1: any, e: any): boolean => {
      if (e.target !== e.currentTarget) {
        return false;
      }

      const evtobj = window.event ? window.event : e;

      if (evtobj.keyCode === 90 && (evtobj.ctrlKey || evtobj.metaKey)) {
        let history = undoManager1.history;
        if (history.length < 1) {
          return true;
        }
        let evt: any = history[history.length - 1];
        for (let chg of evt.changes) {
          switch (chg.constructor.name) {
            case "mxChildChange": {
              // this.props.callback.shapeDeleted([chg.child]);
              break;
            }
            case "mxGeometryChange": {
              undoManager1.undo();
              break;
            }
            case "mxTerminalChange": {
              break;
            }
            case "mxValueChange": {
              // this.props.callback.shapeExitedTextEdit(chg.cell,chg.cell.value);
              break;
            }
          }
        }
        // console.debug(evt.name);
        callbackFunc1 && callbackFunc1(history);
        // undoManager.redo();
      }
      return true;
    };

    let undoListenerFunc2 = undoListenerFunc.bind(this, this.undoman, callbackFunc);
    this.keydownhandler.push(undoListenerFunc2);

    document.body.addEventListener('keydown', undoListenerFunc2);
  }

  private copyListener = (_graph: mxGraph, _callbackFunc: any) => {
    // let copyListenerFunc = (graph1: mxGraph, callbackFunc1: any, e: KeyboardEvent): boolean => {
    //   if (e.target !== e.currentTarget) {
    //     return false;
    //   }
    //   // const evtobj: KeyboardEvent = window.event ? window.event : e;
    //   const evtobj: KeyboardEvent = e;
    //   // command + c / ctrl + c
    //   if (evtobj.code === "KeyC" && (evtobj.ctrlKey || evtobj.metaKey)) {
    //     // console.log("Copy..");
    //     mxClipboard.copy(graph1); //eslint-disable-line
    //   } else if (evtobj.code === "KeyV" && (evtobj.ctrlKey || evtobj.metaKey)) { // command + v / ctrl + v
    //     // cells 复制元素的集合
    //     const cells = mxClipboard.paste(graph1); // eslint-disable-line
    //     callbackFunc1 && callbackFunc1(cells, evtobj);
    //   }
    //   return true;
    // };
    // let copyListenerFunc2 = copyListenerFunc.bind(this, graph, callbackFunc);
    // this.keydownhandler.push(copyListenerFunc2);
    // document.body.addEventListener('keydown', copyListenerFunc2);
  }
  private keyListener = (graph: mxGraph, callbackFunc: any) => {
    let keyListenerFunc = (_graph: mxGraph, callbackFunc1: any, e: KeyboardEvent) => {
      if (e.target !== e.currentTarget) {
        return false;
      }
      // const evtobj = window.event ? window.event : e;
      const evtobj: KeyboardEvent = e;
      callbackFunc1 && callbackFunc1(evtobj);
      return true;
    };

    let keyListenerFunc2 = keyListenerFunc.bind(this, graph, callbackFunc);
    this.keydownhandler.push(keyListenerFunc2);
    document.body.addEventListener('keydown', keyListenerFunc2);
  }
  private deleteListener = (graph: mxGraph, callbackFunc: any) => {
    let removeCells = graph.removeCells;
    graph.removeCells = function (cells: any) {
      let del: mxCell[] = [];
      for (let cell of cells) {
        del.push(cell);
        for (let ind in cell.edges) {
          let edge = cell.edges[ind];
          del.push(edge);
        }
      }
      const result = removeCells.apply(this, [cells, true]);
      callbackFunc && callbackFunc(del);
      return result;
    };
    let deleteListenerFunc = (graph1: any, e: any) => {
      // console.debug("deleteListenerFunc");
      if (
        !(e.target === e.currentTarget || graph1.container.contains(e.target))
      ) {
        // if (e.code !== "Delete") {
        return false;
        // }
      }

      const {
        editingCell
      } = graph1.cellEditor;

      const evtobj = window.event ? window.event : e;
      if (evtobj.keyCode === 46 || evtobj.keyCode === 8) {
        // 没有cell当前在编辑状态时才可删除
        if (!editingCell) {
          const cellsSelected = graph.getSelectionCells();
          // cellsSelected && cellsSelected.length && graph.removeCells(cellsSelected);

          const cellsSelectable: any[] = [];
          cellsSelected
            &&
            cellsSelected.forEach((cell: any) => {
              if (cell && !cell.disabled) {
                cellsSelectable.push(cell);
              }
            });

          cellsSelectable
            &&
            cellsSelectable.length &&
            graph1.removeCells(cellsSelectable, true);
        }
        // evtobj.consume();

      }
      return true;
    };

    let deleteListenerFunc2 = deleteListenerFunc.bind(this, graph);
    this.keydownhandler.push(deleteListenerFunc2);

    document.body.addEventListener('keydown', deleteListenerFunc2);
    return true;
  }

  private handleClick = (graph: mxGraph, clickFunc: any) => {
    graph.addListener(mxEvent.CLICK, (_sender: mxGraph, evt: mxEventObject) => { //eslint-disable-line
      //eslint-disable-line
      if (graph.isEditing()) {
        graph.stopEditing(false);
      }
      const cell: mxCell = evt.getProperty('cell');
      if (cell) {
        clickFunc && clickFunc(cell, evt);
      }
    });
  }
  private handleDoubleClick = (graph: mxGraph, callbackFunc: any) => {
    graph.addListener(mxEvent.DOUBLE_CLICK, (_sender: mxGraph, evt: mxEventObject) => {
      const cell: mxCell | undefined = evt.getProperty('cell');
      callbackFunc && callbackFunc(cell);
    });
  }
  private handleMove = (graph: mxGraph, callbackFunc: any) => {
    graph.addListener(mxEvent.MOVE_CELLS, (_sender: mxGraph, evt: mxEventObject) => {
      const cells: mxCell[] = evt.getProperty('cells');
      const clone = evt.getProperty('clone');
      // console.log('move', evt, sender);
      callbackFunc && callbackFunc(cells, (clone === true));
      evt.consume();
    });
  }
  private handleChange = (graph: mxGraph, callbackFunc: any) => {
    graph.getSelectionModel().addListener(mxEvent.CHANGE, (_sender: mxGraph, evt: mxEventObject) => {
      callbackFunc && callbackFunc(evt);
    });
  }
  // private handleRefresh = (graph: mxGraph, refreshFunc: any) => {
  //   graph.addListener(mxEvent.REFRESH, (_sender: mxGraph, evt: mxEventObject) => { //eslint-disable-line
  //     //eslint-disable-line
  //     const cell: mxCell = evt.getProperty('cell');
  //     if (cell) {
  //       refreshFunc && refreshFunc(cell, evt);
  //     }
  //   });
  // }
  private initAutoSave(graph: mxGraph, callbackFunc: any) {
    const mgr = new mxAutoSaveManager(graph); //eslint-disable-line
    mgr.autoSaveDelay = 10; // 自动保存延迟时间设为0
    mgr.save = () => {
      const xml = this.getGraphXmlNode();
      const formatedNode = this.formatXmlNode(xml);
      if (!formatedNode) {
        return false;
      }
      const xmlStr = new XMLSerializer().serializeToString(formatedNode); //eslint-disable-line
      // graph.xmlStr = xmlStr;
      callbackFunc && callbackFunc(xmlStr);
      return undefined;
    };
  }
  // check the xmlnode format to avoid error
  private formatXmlNode = (xmlNode: any) => {
    const rootEle = xmlNode && xmlNode.firstElementChild;

    let hasRoot = true;
    if (rootEle && rootEle.tagName === 'root') {
      hasRoot = true;
    }

    let hasIdO = false;
    if (rootEle && rootEle.firstElementChild && rootEle.firstElementChild.id === '0') {
      hasIdO = true;
    }
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    if (!(hasRoot && hasIdO)) {
      console.warn('xmlNode must have root node');
      return false;
    }

    const elements: any[] = rootEle.children;

    const idsArr: any[] = [];

    elements && Array.from(elements).forEach((element) => {
      const cellId: any = element && element.getAttribute('id');

      if (idsArr.indexOf(cellId) === -1) {
        idsArr.push(cellId);
      } else {
        console.warn('cell id is duplicated, delete the needless one', element);
        rootEle.removeChild(element);
      }

      if (element && element.getAttribute('vertex') === '1' && element.getAttribute('edge') === '1') {
        console.warn('cell\'s property vertex and edge cannot both be 1, set property edge to 0', element);
        element.setAttribute('edge', 0);
      }
    });

    return xmlNode;
  }
  /**
   * Returns the XML node that represents the current diagram.
   */
  private getGraphXmlNode = (ignoreSelection?: any) => {
    ignoreSelection = ignoreSelection != null ? ignoreSelection : true;
    let node: any = null;
    const graph = this.graph;

    if (ignoreSelection) {
      const enc = new mxCodec(mxUtils.createXmlDocument()); //eslint-disable-line
      node = enc.encode(this.graph.getModel());
    } else {
      let enc = (this.graph as any)["encodeCells"];
      if (enc) {
        node = enc(
          mxUtils.sortCells( //eslint-disable-line
            this.graph.model.getTopmostCells(
              //eslint-disable-line
              graph.getSelectionCells()
            )
          )
        );
      }
    }
    if (node !== null) {
      if (graph.view.translate.x !== 0 || graph.view.translate.y !== 0) {
        node.setAttribute('dx', Math.round(graph.view.translate.x * 100) / 100);
        node.setAttribute('dy', Math.round(graph.view.translate.y * 100) / 100);
      }

      node.setAttribute('grid', graph.isGridEnabled() ? '1' : '0');
      node.setAttribute('gridSize', graph.gridSize);
      node.setAttribute('guides', graph.graphHandler.guidesEnabled ? '1' : '0');
      node.setAttribute(
        'tooltips',
        graph.tooltipHandler.isEnabled() ? '1' : '0'
      );
      node.setAttribute(
        'connect',
        graph.connectionHandler.isEnabled() ? '1' : '0'
      );
      node.setAttribute('arrows', (graph as any)["connectionArrowsEnabled"] ? '1' : '0');
      node.setAttribute('fold', graph.foldingEnabled ? '1' : '0');
      node.setAttribute('page', graph.pageVisible ? '1' : '0');
      node.setAttribute('pageScale', graph.pageScale);
      // node.setAttribute('pageWidth', graph.pageFormat.width * 2);
      // node.setAttribute('pageHeight', graph.pageFormat.height * 4);

      if ((graph as any)["background"] != null) {
        node.setAttribute('background', (graph as any)["background"]);
      }
      let vs = graph.getStylesheet().getCellStyle(mxConstants.SHAPE_SWIMLANE);
      let isv = vs[mxConstants.STYLE_HORIZONTAL];
      if (isv === undefined) isv = false;
      node.setAttribute('horizontal', isv);
      let format = accessCookie(SemTalkCookie.printpageformat);
      if (format !== null) {
        node.setAttribute('printpageformat', format);
      }
      let landscape = accessCookie(SemTalkCookie.printlandscape);
      if (landscape !== null) {
        node.setAttribute('printlandscape', landscape);
      }
    }
    return node;
  }
  // 从 xml 渲染 graph
  private renderGraphFromXml = (xml: string): void => {
    // console.debug("render");
    const xmlDocument = mxUtils.parseXml(xml); //eslint-disable-line

    if (
      xmlDocument.documentElement != null &&
      xmlDocument.documentElement.nodeName === 'mxGraphModel'
    ) {
      const decoder = new mxCodec(xmlDocument); //eslint-disable-line
      const node = xmlDocument.documentElement;

      // const formatedNode = node;
      const formatedNode = this.formatXmlNode(node);

      if (!formatedNode) {
        // return false;
        return;
      }

      decoder.decode(formatedNode, this.graph.model);
      // console.log(this, this.graphToXML(graph));
      if (xmlDocument.documentElement["scale"]) {
        //  console.debug(xmlDocument.documentElement["scale"] + " => " + graph.view.scale);
      }
      if (xmlDocument.documentElement.attributes["horizontal"]) {
        // console.debug("horizontal => " + xmlDocument.documentElement.attributes["horizontal"].value);
        (this.graph as any)["horizontal"] = xmlDocument.documentElement.attributes["horizontal"].value;
      }
      // let format = xmlDocument.documentElement.attributes["printpageformat"];
      // if (format !== undefined) {
      //   setCookie(SemTalkCookie.printpageformat, format.value);
      // }
      // let landscape = xmlDocument.documentElement.attributes["printlandscape"];
      // if (landscape !== undefined) {
      //   setCookie(SemTalkCookie.printlandscape, (landscape.value === 'true'));
      // }
      this.UpdatePage();
    }
  }
  public renderNewGraphFromXml = (xml: string, div: any): mxGraph | undefined => {
    // console.debug("render");
    const xmlDocument = mxUtils.parseXml(xml); //eslint-disable-line

    if (
      xmlDocument.documentElement != null &&
      xmlDocument.documentElement.nodeName === 'mxGraphModel'
    ) {
      const decoder = new mxCodec(xmlDocument); //eslint-disable-line
      const node = xmlDocument.documentElement;

      // const formatedNode = node;
      const formatedNode = this.formatXmlNode(node);

      if (!formatedNode) {
        // return false;
        return;
      }
      const model: mxGraphModel = new mxGraphModel();
      let graph = new mxGraph(div, model);

      decoder.decode(formatedNode, graph.model);
      graph.setHtmlLabels(true);
      graph.gridSize = 10;

      return graph;
    }
    return;
  }
  // public destroyIconSet = (): void => {
  //     if (this.images !== []) {
  //       for (let i = 0; i < this.images.length; i += 1) {
  //         const img = this.images[i];
  //         img.parentNode.removeChild(img);
  //       }
  //     }
  //     this.images = [];
  //   };
  public setBundles = (images: any): void => {
    if (Object.keys(images).length > 0) {
      let bundle: any = null;
      for (let img in images) {
        let imagedata = this.graph.getImageFromBundles(img);
        if (!imagedata) {
          if (this.graph.imageBundles.length > 0) {
            bundle = this.graph.imageBundles[0];
          } else {
            bundle = new mxImageBundle();
            this.graph.addImageBundle(bundle);
          }
          let data = images[img];
          bundle.putImage(img, data);
        } else {
          if (imagedata !== images[img]) {
            bundle = this.graph.imageBundles[0];
            bundle.putImage(img, images[img]);
          }
        }
      }
    }
  }

  public removeBundles = (images: any): void => {
    if (Object.keys(images).length > 0) {
      let bundle: any = null;
      for (let img in images) {
        if (this.graph.getImageFromBundles(img)) {
          if (this.graph.imageBundles.length > 0) {
            bundle = this.graph.imageBundles[0];
            this.graph.removeImageBundle(bundle);
          }
        }
      }
    }
  }

  public setOverlayBundle = (images: any): void => {
    if (Object.keys(images).length > 0) {
      let bundle: any = null;
      if (this.graph.imageBundles.length > 0) {
        if (this.graph.imageBundles.length > 1) {
          bundle = this.graph.imageBundles[1];
        } else {
          bundle = new mxImageBundle();
          this.graph.addImageBundle(bundle);
        }
      } else {
        bundle = new mxImageBundle();
        this.graph.addImageBundle(bundle);
        bundle = new mxImageBundle();
        this.graph.addImageBundle(bundle);
      }
      for (let img in images) {
        if (!this.graph.getImageFromBundles(img)) {
          let data = images[img];
          bundle.putImage(img, data);
        }
      }
    }
  }
  public getBundeledImages(): any[] {
    let res: any[] = [];
    if (this.graph.imageBundles.length > 0) {
      let bundle = this.graph.imageBundles[0];
      for (let key in bundle.images) {
        res.push(key);
      }
    }
    return res;
  }
  public getImageFromBundles(img: string): any {
    return this.graph.getImageFromBundles(img);
  }


  // private lastPaste: string | null = null;
  private dx = 0;
  private dy = 0;
  private textInput: HTMLTextAreaElement;
  private gs: number = 10;

  private initClipboard = () => {
    //   // Focused but invisible textarea during control or meta key events
    this.textInput = document.createElement('textarea');
    mxUtils.setOpacity(this.textInput, 0);
    this.textInput.style.width = '1px';
    this.textInput.style.height = '1px';
    let restoreFocus = false;
    // this.gs = this.graph.gridSize;

    //   // Workaround for no copy event in IE/FF if empty
    this.textInput.value = ' ';

    // Shows a textarea when control/cmd is pressed to handle native clipboard actions
    mxEvent.addListener(document, 'keydown', (evt: any) => {
      // No dialog visible
      let source = mxEvent.getSource(evt);
      let graph = this.graph;

      let onpanel = this.onPanel(evt);
      if (onpanel) {
        return;
      }

      if (graph.isEnabled() && !graph.isMouseDown && !graph.isEditing() && source.nodeName !== 'INPUT') {
        if (evt.keyCode === 224 /* FF */ || (!mxClient.IS_MAC && evt.keyCode === 17 /* Control */) ||
          (mxClient.IS_MAC && (evt.keyCode === 91 || evt.keyCode === 93) /* Left/Right Meta */)) {
          // Cannot use parentNode for check in IE
          if (!restoreFocus) {
            // Avoid autoscroll but allow handling of events
            this.textInput.style.position = 'absolute';
            this.textInput.style.left = (graph.container.scrollLeft + 10) + 'px';
            this.textInput.style.top = (graph.container.scrollTop + 10) + 'px';
            graph.container.appendChild(this.textInput);

            restoreFocus = true;
            this.textInput.focus();
            this.textInput.select();
          }
        }
      }
    });

    // Restores focus on graph container and removes text input from DOM
    mxEvent.addListener(document, 'keyup', (evt: any) => {
      let onpanel = this.onPanel(evt);
      if (onpanel) {
        return;
      }
      if (restoreFocus && (evt.keyCode === 224 /* FF */ || evt.keyCode === 17 /* Control */ ||
        evt.keyCode === 91 || evt.keyCode === 93 /* Meta */)) {
        restoreFocus = false;
        let graph = this.graph;

        if (!graph.isEditing()) {
          graph.container.focus();
        }

        this.textInput.parentNode?.removeChild(this.textInput);
      } else {
        // this.keyFunc(evt);
      }
    });
    // Handles copy event by putting XML for current selection into text input
    mxEvent.addListener(this.textInput, 'copy', (evt: any) => {
      let onpanel = this.onPanel(evt);
      if (onpanel) {
        return;
      }
      if (this.graph.isEnabled() && !this.graph.isSelectionEmpty()) {
        let cells = this.graph.model.getTopmostCells(this.graph.getSelectionCells());
        this.copyorgid(cells);
        let cpstr = copyCells(this.graph, mxUtils.sortCells(cells));
        let objects: any = cloneObjects(this.props.semtalk.base, cells, {});
        let obstr = "$SemTalk$" + JSON.stringify(objects);
        this.textInput.value = cpstr + obstr;
        this.textInput.select();
        // this.lastPaste = cpstr;
        this.dx = 0;
        this.dy = 0;
      }
    });

    // Handles cut event by removing cells putting XML into text input
    mxEvent.addListener(this.textInput, 'cut', (evt: any) => {
      let onpanel = this.onPanel(evt);
      if (onpanel) {
        return;
      }
      if (this.graph.isEnabled() && !this.graph.isSelectionEmpty()) {
        let cells = this.graph.model.getTopmostCells(this.graph.getSelectionCells());
        this.Cut(cells);
      }
    });
    // Handles paste event by parsing and inserting XML
    mxEvent.addListener(this.textInput, 'paste', (evt: any) => {
      let onpanel = this.onPanel(evt);
      if (onpanel) {
        return;
      }
      // Clears existing contents before paste - should not be needed
      // because all text is selected, but doesn't hurt since the
      // actual pasting of the new text is delayed in all cases.
      this.textInput.value = '';

      if (this.graph.isEnabled()) {
        let xml: string = extractDataFromEvent(evt);
        if (xml != null && xml.length > 0) {
          this.pasteTextorXML(this.graph, xml, evt);
        }
        else {
          // Timeout for new value to appear
          window.setTimeout(() => {
            this.pasteTextorXML(this.graph, this.textInput.value, evt);
          }, 0);
        }
      }
      this.textInput.select();
    });
  }
  private onPanel(evt: any): boolean {
    let element = mxEvent.getSource(evt);
    while (element !== null) {
      // if (outline && element === outline.outline.view.canvas.ownerSVGElement) {
      //   outlineWheel = true;
      //   break;
      // }
      try {
        if (element.className) {
          let cn = element.className.toString();
          if (cn === 'ms-SelectionZone') return true;
          if (cn.startsWith("ms-ScrollablePane ")) {
            return true;
          }
          if (cn.startsWith("ms-Panel-content ")) {
            return true;
          }
          if (cn.startsWith("ms-Dialog-content ")) {
            return true;
          }
          if (cn.startsWith("ms-Dropdown")) {
            return true;
          }
          if (cn.startsWith("ms-Callout")) {
            return true;
          }
          if (cn.startsWith("ms-Panel-scrollableContent")) {
            return true;
          }

        }
      } catch (_e) {
      }
      element = element.parentNode;
    }
    return false;
  }
  private copyorgid(cells: mxCell[]) {
    for (let cell of cells) {
      if (cell.children) {
        this.copyorgid(cell.children);
      }
      if (cell.objectid) {
        cell.orgid = cell.objectid;
      }
    }
  }
  public Copy = (cells: mxCell[]) => {
    this.copyorgid(cells);
    let cpstr = copyCells(this.graph, mxUtils.sortCells(cells));
    let objects: any = cloneObjects(this.props.semtalk.base, cells, {});
    let obstr = "$SemTalk$" + JSON.stringify(objects);
    try {
      navigator.clipboard.writeText(cpstr + obstr);
    } catch (e) {
      console.debug(e);
    }
    this.textInput.value = cpstr + obstr;
    this.textInput.select();
    // this.lastPaste = cpstr;
    this.dx = 0;
    this.dy = 0;
  }
  public Cut = (cells: mxCell[]) => {
    this.copyorgid(cells);
    let objects: any = cloneObjects(this.props.semtalk.base, cells, {});
    let cpstr = copyCells(this.graph, this.graph.removeCells(cells, true));
    let obstr = "$SemTalk$" + JSON.stringify(objects);
    try {
      navigator.clipboard.writeText(cpstr + obstr);
    } catch (e) {
      console.debug(e);
    }
    this.textInput.value = cpstr + obstr;
    this.textInput.select();
    // this.lastPaste = cpstr;
    this.dx = 0;
    this.dy = 0;
  }
  public Paste = async (x: number, y: number) => {
    let txt = "";
    try {
      txt = await navigator.clipboard.readText();
    } catch (e) {
      console.debug(e);
    }
    this.textInput.value = txt;
    this.pasteTextorXML(this.graph, this.textInput.value, { x: x, y: y });
  }
  // Parses and inserts XML into graph
  private pasteTextorXML = (graph: mxGraph, text: string, evt: any) => {
    let txt: string = mxUtils.trim(text);
    // let x = graph.container.scrollLeft / graph.view.scale - graph.view.translate.x;
    // let y = graph.container.scrollTop / graph.view.scale - graph.view.translate.y;

    let abs: boolean = true;
    if (txt.length > 0) {
      // if (this.lastPaste !== txt) {
      //   this.lastPaste = txt;
      //   this.dx = evt.x;
      //   this.dy = evt.y;
      // }
      // else {
      //   this.dx += this.gs;
      //   this.dy += this.gs;
      // }
      if (evt.x && evt.y) {
        this.dx = evt.x;
        this.dy = evt.y;
      } else {
        abs = false;
        this.dx += this.gs;
        this.dy += this.gs;
      }
      // this.dx = 0;
      // this.dy = 0;

      // Standard paste via control-v
      if (txt.substring(0, 14) === '<mxGraphModel>') {
        let spos = txt.indexOf("$SemTalk$");
        let objects: any = {};
        if (spos > -1) {
          let obstr = txt.substring(spos + "$SemTalk$".length);
          objects = JSON.parse(obstr);
          txt = txt.substring(0, spos);
        }
        let cells = importXml(graph, txt, this.dx, this.dy, abs);
        parseObjects(this.props.semtalk.base, cells, objects);
        graph.setSelectionCells(cells);
        graph.scrollCellToVisible(graph.getSelectionCell());
      } else {
        this.pastenoedit(txt, evt);
      }
    }
  }

  // function drag(ev) {
  //   ev.dataTransfer.setData("text", ev.target.innerText); // save whatever text you want here
  // }

  private drop = (ev: any) => {
    ev.preventDefault();
    if (ev.dataTransfer) {
      let data = ev.dataTransfer.getData("text");
      if (data.length > 0) {
        this.pastetext(data, ev);
      }
    }
  }

  private lstpaste = "";
  private pastetext(data: string, ev: any) {

    // Gets the default parent for inserting new cells. This
    // is normally the first child of the root (ie. layer 0).
    var parent = this.graph.getDefaultParent();
    // this.graph.getModel().beginUpdate();

    let containerEle = document.getElementById('some-empty-div-on-the-page');
    if (containerEle) {
      var gridRect = containerEle.getBoundingClientRect();
      let sem = this.props.semtalk;

      if (ev.x && ev.y) {
        let targetX = ev.x - gridRect.x;
        let targetY = ev.y - gridRect.y;

        let cell: mxCell | null = this.graph.getCellAt(targetX, targetY);
        if (cell && cell.shapeKey !== "group" && cell.shapeKey !== SemTalkMaster.MasterSwimlane) {
          sem.visShapeExitedTextEdit(cell, data);
          return;
        }
      }
      try {
        this.graph.container.focus();
        let cellsSelected = this.graph.getSelectionCells();
        if (cellsSelected.length > 0) {
          let lst = cellsSelected[0];
          let shapestyle = lst.style;
          let w = lst.geometry.width;
          let h = lst.geometry.height;
          let targetX = lst.geometry.x + w * 1.5;
          let targetY = lst.geometry.y;
          let shp = this.createVertex(parent, null, data, targetX, targetY, w, h, shapestyle);
          shp.shapeName = lst.shapeName;
          shp.shapeKey = lst.shapeKey;
          sem.visShapeAdded(shp, null);
          sem.visShapeExitedTextEdit(shp, data);
          this.graph.setSelectionCell(shp);
        } else {
          let shapestyle = SemTalkStyleAttribute.shape + "=text;";
          let w = 300;
          let h = 30;
          let shp = this.createVertex(parent, null, data, 300, 300, w, h, shapestyle);
          this.lstpaste = "";
          this.graph.setSelectionCell(shp);
        }

      } finally {
        // Updates the display
        // this.graph.getModel().endUpdate();
      }
    }
  }
  private pastenoedit = async (data: string, ev: any) => {
    data = data.trim();
    let cellsSelected = this.graph.getSelectionCells();
    if (cellsSelected.length > 0) {
      let lst = cellsSelected[0];
      if (this.graph.isEditing(lst)) {
        return;
      }
    }
    if (data.length > 0 && data !== this.lstpaste) {
      this.lstpaste = data;
      this.graph.container.focus();
      this.pastetext(data, ev);
    }
    // }
  }
}
