/* tslint:disable:no-function-expression */

import {
	mxUtils,
	// mxRectangle,
	mxCellRenderer,
	mxActor,
	// mxShape,
} from "mxgraph-js";// Document shape
import { SemTalkBuiltInShape, SemTalkStyleAttribute } from "../visiordfsinterface";
// import { SemTalkCookie } from "../../../ISemTalkCookie";
// import { accessCookie } from "../stgoto";



function UMLClassShape(this: any) {
	mxActor.call(this);
	this.isRounded = true;
	this.strokewidth = 2;
	// this.strokeOpacity = 100;
}
mxUtils.extend(UMLClassShape, mxActor);

UMLClassShape.prototype.paintVertexShape = function (c: any, x: any, y: any, w: any, h: any) {
	let strokeColor = mxUtils.getValue(this.style, SemTalkStyleAttribute.strokeColor, '#000000');
	let strokeWidth = mxUtils.getValue(this.style, SemTalkStyleAttribute.strokeWidth, 2);
	let fillColor = mxUtils.getValue(this.style, SemTalkStyleAttribute.fillColor, '#ffffff');
	// let cell = this.state.cell;
	// let font: string = "Helvetica, Arial, sans-serif";
	// let fo = accessCookie(SemTalkCookie.font);
	// if (fo) font = fo;
	// if (cell && cell["attributes"]) {
	// 	let attributes: string[] = cell["attributes"];
	// 	let ms = attributes.join("<br>");
	// 	let size = mxUtils.getSizeForString(ms, 10, font, w);
	// 	h = h + size.height;
	// 	console.debug(size);
	// }
	// if (cell && cell["methods"]) {
	// 	let methods: string[] = cell["methods"];
	// 	let ms = "X";
	// 	let size = mxUtils.getSizeForString(ms, 10, font, w);
	// 	h = h + size.height*methods.length;
	// 	console.debug(size);
	// }


	c.setStrokeColor(strokeColor);
	// c.setStrokeColor('#000000');
	c.setStrokeWidth(strokeWidth);
	c.setFillColor(fillColor);
	let isgradient = false;
	if (this["style"][SemTalkStyleAttribute.gradientColor]) {
		isgradient = (this["style"][SemTalkStyleAttribute.gradientColor] !== '#ffffff');
	}
	if (isgradient) {
		let gradientColor = mxUtils.getValue(this.style, SemTalkStyleAttribute.gradientColor, '#ffffff');
		c.setGradient(fillColor, gradientColor);
	}

	c.translate(x, y);
	// let rSize = 5;
	let rSize = 0;

	c.roundrect(0, 0, w, h, rSize, rSize);
	c.fillAndStroke();

	c.setShadow(false);
	this.redrawPath(c, x, y, w, h);

};

UMLClassShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
	let cell = this.state.cell;
	// let font: string = "Helvetica, Arial, sans-serif";
	// let fo = accessCookie(SemTalkCookie.font);
	// if (fo) font = fo;
	c.begin();
	c.setFontSize("10");
	c.setStrokeWidth(1);
	let h1 = 10;
	// let size = mxUtils.getSizeForString("X", 10, font, w);
	let size = { x: 0, y: 0, width: 100, height: 12 };
	c.translate(0, 0);
	if (cell && cell["methods"]) {
		let methods: string[] = cell["methods"];
		let ms = methods.join("\n");
		h1 = 10 + size.height * methods.length;
		c.translate(0, h - h1);
		c.text(5, 5, 0, 0, ms, 'left', 'top');
		c.rect(0, 0, w, h1);
		c.stroke();
	}
	if (cell && cell["attributes"]) {
		let attributes: string[] = cell["attributes"];
		let ms = attributes.join("\n");
		let h2 = 10 + size.height * attributes.length;
		c.translate(0, 0 - h2);
		c.text(5, 5, 0, 0, ms, 'left', 'top');
		c.rect(0, 0, w, h2);
		c.stroke();
	}
	// c.text(2, 2 + h * 0.75, 0, 0, "Hello World3", 'left', 'top');
	// c.setStrokeWidth(1);
	// c.rect(0, h / 2, w, h / 4);
	// c.stroke();

	// c.text("XX", 5, 5)
	// c.stroke();
	// c.rect(w / 2, 0, w / 2, h / 2);
	// c.moveTo(w * 0, h * 0.8);
	// c.lineTo(w * 1.0, h * 0.8);
	// c.moveTo(w * 0, h * 0.9);
	// c.lineTo(w * 1.0, h * 0.9);
	// c.close();
	// c.stroke();
};

mxCellRenderer.registerShape(SemTalkBuiltInShape.umlclass, UMLClassShape);
// console.debug("EpcPosition Shape registered");
