/* eslint-disable no-useless-concat */
// import { SPHttpClient, ISPHttpClientOptions } from '@microsoft/sp-http';
// import { SPListItem } from '@microsoft/sp-page-context';
// import { MSGraphClient } from '@microsoft/sp-http';
// import { any } from '@microsoft/sp-webpart-base';
import { ISPExplorer } from "./spexplorerinterface";

export class SPExplorer implements ISPExplorer {
  public async spGetDocument(_ctx: any, _site: string, _listName: string, _filename: string): Promise<any> { return null; }
  public async spGetDocumentField(_ctx: any, _site: string, _listName: string, _filename: string, _fieldname: string): Promise<any> { return null; }
  public async spCheckOutDocument(_ctx: any, _site: string, _listName: string, _filename: string): Promise<any> { return null; }
  public async spCheckInDocument(_ctx: any, _site: string, _listName: string, _filename: string): Promise<any> { return null; }
  public async getListData(_ctx: any, _site: string, _listName: string, _query: string | undefined): Promise<any[]> { return []; }
  public async getListItem(_ctx: any, _site: string, _listName: string, _id: string): Promise<any | null> { return null; }
  public async addListItem(_ctx: any, _site: string, _listName: string | undefined, _title: string): Promise<any | null> { return null; }
  public async deleteListItem(_ctx: any, _site: string, _listName: string, _id: number): Promise<any> { return null; }
  public async getTXT(_ctx: any, _url: string): Promise<any> { return null; }
  public async postTXT(_ctx: any, _url: string, _body: any): Promise<any> { }
  public async getJSON(_ctx: any, _url: string): Promise<any> { return null; }
  public async postJSON(_ctx: any, _url: string, _body: any): Promise<any> { }
  public async getUserById(_ctx: any, _site: string, _userId: number): Promise<any> { }
  // public async spGetDocument(ctx: any, site: string, listName: string, filename: string): Promise<any> {
  //   let url = `${site}/_api/web/GetFileByServerRelativeUrl('${listName}/${filename}')`;
  //   const response = await ctx.spHttpClient
  //     .get(`${url}`, SPHttpClient.configurations.v1);
  //   if (response.status !== 200) {
  //     console.debug('getDocument: ' + response.statusText);
  //     return null;
  //   }
  //   else {
  //     return response.json();
  //   }
  // }
  // public async spGetDocumentField(ctx: any, site: string, listName: string, filename: string, _fieldname: string): Promise<any> {
  //   let url = `${site}/_api/web/GetFileByServerRelativeUrl('${listName}/${filename}?$select=*')`;
  //   const response = await ctx.spHttpClient
  //     .get(`${url}`, SPHttpClient.configurations.v1);
  //   if (response.status !== 200) {
  //     console.debug('getDocumentspGetDocumentField: ' + response.statusText);
  //     return null;
  //   }
  //   else {
  //     return response.json();
  //   }
  // }
  // public async spCheckOutDocument(ctx: any, site: string, listName: string, filename: string): Promise<any> {
  //   let options: ISPHttpClientOptions = {
  //     headers: {
  //       "Accept": "application/json",
  //       "Content-Type": "application/json",
  //       'odata-version': '',
  //       "IF-MATCH": "*",
  //       'X-HTTP-Method': 'PATCH'
  //     },
  //     body: ""
  //   };
  //   let url = `${site}/_api/web/GetFileByServerRelativeUrl('${listName}/${filename}')/CheckOut()`;
  //   const response = await ctx.spHttpClient
  //     .post(`${url}`, SPHttpClient.configurations.v1, options);
  //   if (response.status !== 200) {
  //     // console.debug(response);
  //     console.debug('checkOutDocument: ' + response.status);
  //     return null;
  //   }
  //   else {
  //     return await response.text();
  //   }
  // }
  // public async spCheckInDocument(ctx: any, site: string, listName: string, filename: string): Promise<any> {
  //   let options: ISPHttpClientOptions = {
  //     headers: {
  //       "Accept": "application/json",
  //       "Content-Type": "application/json",
  //       'odata-version': '',
  //       "IF-MATCH": "*",
  //       'X-HTTP-Method': 'PATCH'
  //     },
  //     body: ""
  //   };
  //   let url = `${site}/_api/web/GetFileByServerRelativeUrl('${listName}/${filename}')/CheckIn()`;
  //   const response = await ctx.spHttpClient
  //     .post(`${url}`, SPHttpClient.configurations.v1, options);
  //   if (response.status !== 200) {
  //     console.debug('checkInDocument: ' + response.statusText);
  //     return null;
  //   }
  //   else {
  //     return await response.text();
  //   }
  // }

  // public async getListData(ctx: any, site: string, listName: string, query: string | undefined): Promise<any[]> {
  //   if (query === undefined || query === "") {
  //     query = "?$select=Title";
  //   }
  //   if (query === "*") {
  //     query = "";
  //   } else {
  //     if (!query.startsWith("?")) {
  //       query = "?" + query;
  //     }
  //   }
  //   const response = await ctx.spHttpClient
  //     // .get(`${this.props.context.pageContext.web.absoluteUrl}/_api/web/lists/GetByTitle('${listName}')/items?${query}${flt}`,
  //     .get(`${site}/_api/web/lists/GetByTitle('${listName}')/items${query}`, SPHttpClient.configurations.v1);
  //   if (response.status === 404) {
  //     console.debug('getListData: ' + 'List not found: ' + listName);
  //     return [];
  //   }
  //   else {
  //     return response.json();
  //   }
  // }
  // public async getListItem(ctx: any, site: string, listName: string, id: string): Promise<any | null> {
  //   const response = await ctx.spHttpClient
  //     // .get(`${this.props.context.pageContext.web.absoluteUrl}/_api/web/lists/GetByTitle('${listName}')/items?${query}${flt}`,
  //     .get(`${site}/_api/web/lists/GetByTitle('${listName}')/items(${id})`, SPHttpClient.configurations.v1);
  //   if (response.status === 404) {
  //     console.debug('getListItem: ' + 'List not found.');
  //     return null;
  //   }
  //   else {
  //     return response.json();
  //   }
  // }

  // public async addListItem(ctx: any, site: string, listName: string | undefined, title: string): Promise<any | null> {
  //   let m = {
  //     Title: title
  //   };
  //   const spOpts: ISPHttpClientOptions = {

  //     body: JSON.stringify(m)

  //   };
  //   const response = await ctx.spHttpClient
  //     .post(site + "/_api/web/lists/GetByTitle('" + listName + "')/items", SPHttpClient.configurations.v1, spOpts);
  //   if (response.status === 404) {
  //     console.error('addListItem', new Error('List not found.'));
  //     return null;
  //   }
  //   else {
  //     return response.json();
  //   }
  // }
  // public async deleteListItem(ctx: any, site: string, listName: string, id: number): Promise<any> {
  //   const spOpts: ISPHttpClientOptions = {
  //     headers: {
  //       'Accept': 'application/json;odata=nometadata',
  //       'Content-type': 'application/json;odata=verbose',
  //       'odata-version': '',
  //       "IF-MATCH": "*",
  //       'X-HTTP-Method': 'DELETE'
  //     }
  //   };
  //   const response = await ctx.spHttpClient
  //     .post(site + "/_api/web/lists/GetByTitle('" + listName + "')/items(" + id + ")", SPHttpClient.configurations.v1, spOpts);
  //   if (response.status === 404) {
  //     console.error('deleteListItem', new Error('List not found.'));
  //     return {};
  //   }
  //   else {
  //     return null;
  //   }
  // }

  // public async getTXT(ctx: any, url: string): Promise<any> {
  //   const response = await ctx.spHttpClient
  //     .get(`${url}`, SPHttpClient.configurations.v1);
  //   if (response.status !== 200) {
  //     console.debug('getTXT: ' + response.statusText);
  //     return null;
  //   }
  //   else {
  //     let r = await response.text();
  //     return r;
  //   }
  // }
  // public async postTXT(ctx: any, url: string, body: any): Promise<any> {
  //   let options: ISPHttpClientOptions = {
  //     headers: {
  //       "Accept": "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     body: body
  //   };
  // public async getJSON(ctx: any, url: string): Promise<any> {
  //   const response = await ctx.spHttpClient
  //     .get(`${url}`, SPHttpClient.configurations.v1);
  //   if (response.status !== 200) {
  //     console.debug('getJSON: ' + response.statusText);
  //     return null;
  //   }
  //   else {
  //     let r = await response.json();
  //     return r;
  //   }
  // }
  // public async postJSON(ctx: any, url: string, body: any): Promise<any> {
  //   let options: ISPHttpClientOptions = {
  //     headers: {
  //       "Accept": "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     body: body
  //   };

  //   const response = await ctx.spHttpClient
  //     .post(`${url}`, SPHttpClient.configurations.v1, options);
  //   if (response.status !== 200) {
  //     console.debug('postJSON: ' + response.statusText);
  //     return null;
  //   }
  //   else {
  //     return await response.json();
  //   }
  // }

  //   const response = await ctx.spHttpClient
  //     .post(`${url}`, SPHttpClient.configurations.v1, options);
  //   if (response.status !== 200) {
  //     console.debug('postTXT: ' + response.statusText);
  //     return null;
  //   }
  //   else {
  //     return await response.text();
  //   }
  // }
  // public async getUserById(ctx: any, site: string, userId: number): Promise<any> {
  //   const response = await ctx.spHttpClient
  //     .get(`${site}/_api/web/GetUserById('${userId}')`, SPHttpClient.configurations.v1);
  //   if (response.status === 404) {
  //     console.debug('getUserId: ' + 'User not found.');
  //     return "";
  //   }
  //   if (response.status === 304) {
  //     console.debug('getUserId: ' + 'No Result.');
  //     return "";
  //   }
  //   else {
  //     let r = await response.json();
  //     // console.debug(r);
  //     return r.Title;
  //   }
  // }

  public async fetchGraph(query: string, filter: string, expand: string, graph: any): Promise<any> {
    try {
      return graph
        .api(query)
        .version("beta")
        .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
        .filter(filter)
        .expand(expand)
        .get();
    } catch (e) {
      throw ((e as any).message);
    }
  }
  public async fetchGraphItems(graphClient: any, query: string): Promise<any[]> {
    if (graphClient) {
      try {
        let res = await graphClient
          .api(query)
          .version("beta") // API is currently only available in BETA
          .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
          .get();
        return res.value;
      } catch (e) {
        return [];
      }
    } else return [];
  }
  public async fetchGraphItem(graphClient: any, query: string): Promise<any> {
    if (graphClient) {
      //  const filter = ""; //this._excludeTypes.map(type => `resourceVisualization/type ne '${type}'`).join(' and ');
      // console.debug("fetchGraphItem");
      try {
        let res = await graphClient
          .api(query)
          .version("beta") // API is currently only available in BETA
          .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
          .get();
        if (res.value) {
          return res.value;
        }
        return res;
      } catch (e) {
        console.debug((e as any).message);
        // throw ((e as any).message);
        return null;
      }
    } else return null;
  }
  public async postGraphItem(graphClient: any, query: string, body: any, resolve: any): Promise<any> {
    if (graphClient) {
      //  const filter = ""; //this._excludeTypes.map(type => `resourceVisualization/type ne '${type}'`).join(' and ');
      // console.debug("fetchGraphItem");
      await graphClient
        .api(query)
        .version("beta")
        .get(async (err: any, res: any) => {
          if (err) {
            return;
          }
          if (res) {
            await graphClient
              .api(query)
              .header("If-Match", res["@odata.etag"])
              .version("beta") // API is currently only available in BETA
              // .filter(`resourceVisualization/containerType eq 'Site' and ${filter}`)
              .post(body)
              .then((res2: any) => {
                if (res2) {
                  if (resolve) {
                    return resolve(res2);
                  }
                  return res2;
                } else {
                  return null;
                }
              }, (err2: any) => {
                alert(err2.message);
              });
          }
        });
    }
    return null;
  }
  public async postGraphItemNoGet(graphClient: any, query: string, body: any, resolve: any): Promise<any> {
    if (graphClient) {
      //  const filter = ""; //this._excludeTypes.map(type => `resourceVisualization/type ne '${type}'`).join(' and ');
      // console.debug("fetchGraphItem");
      await graphClient
        .api(query)
        .version("beta") // API is currently only available in BETA
        // .filter(`resourceVisualization/containerType eq 'Site' and ${filter}`)
        .post(body)
        .then((res2: any) => {
          if (res2) {
            if (resolve) {
              return resolve(res2);
            }
            return res2;
          } else {
            return null;
          }
        }, (err2: any) => {
          alert(err2.message);
        });
    }
    return null;
  }
  public async patchGraphItem(graphClient: any, query: string, body: any): Promise<any> {
    if (graphClient) {
      //  const filter = ""; //this._excludeTypes.map(type => `resourceVisualization/type ne '${type}'`).join(' and ');
      // console.debug("fetchGraphItem");
      await graphClient
        .api(query)
        .version("beta")
        .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
        .get(async (err: any, res: any) => {
          if (err) {
            return;
          }
          if (res) {
            await graphClient
              .api(query + "/fields")
              .header("If-Match", res["@odata.etag"])
              .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
              .version("beta") // API is currently only available in BETA
              // .filter(`resourceVisualization/containerType eq 'Site' and ${filter}`)
              .patch(body)
              .then((res2: any) => {
                if (res2) {
                  return res2;
                  // let result = res2;
                  // return resolve(result);
                } else {
                  return null;
                }
              }, (err2: any) => {
                alert(err2.message);
              });
          }
        });
    }
    return null;
  }
  public async deleteGraphItem(graphClient: any, query: string): Promise<any> {
    if (graphClient) {
      //  const filter = ""; //this._excludeTypes.map(type => `resourceVisualization/type ne '${type}'`).join(' and ');
      // console.debug("fetchGraphItem");
      await graphClient
        .api(query)
        .version("beta")
        .get((err: any, res: any) => {
          if (err) {
            return;
          }
          if (res) {
            graphClient
              .api(query)
              .header("If-Match", res["@odata.etag"])
              .version("beta") // API is currently only available in BETA
              .delete()
              .then((res2: any) => {
                if (res2) {
                  return res2;
                  // let result = res2;
                  // return resolve(result);
                } else {
                  return null;
                }
              }, (err2: any) => {
                alert(err2.message);
              });
          }
        });
    }
    return null;
  }
  public async deleteGraphItemNoGet(graphClient: any, query: string): Promise<any> {
    if (graphClient) {
      graphClient
        .api(query)
        .version("beta") // API is currently only available in BETA
        .delete()
        .then((res2: any) => {
          console.debug(res2);
          // if (res2) {
          //   return res2;
          // } else {
          //   return null;
          // }
        }, (err2: any) => {
          return err2.message;
        });
    }
    return null;
  }
  public async patchGraph(graphClient: any, query: string, body: any): Promise<any> {
    if (graphClient) {
      //  const filter = ""; //this._excludeTypes.map(type => `resourceVisualization/type ne '${type}'`).join(' and ');
      // console.debug("fetchGraphItem");
      await graphClient
        .api(query)
        .version("beta")
        .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
        .get(async (err: any, res: any) => {
          if (err) {
            return;
          }
          if (res) {
            await graphClient
              .api(query)
              .header("If-Match", res["@odata.etag"])
              .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
              .version("beta") // API is currently only available in BETA
              // .filter(`resourceVisualization/containerType eq 'Site' and ${filter}`)
              .patch(body)
              .then((res2: any) => {
                if (res2) {
                  return res2;
                  // let result = res2;
                  // return resolve(result);
                } else {
                  return null;
                }
              }, (err2: any) => {
                alert(err2.message);
              });
          }
        });
    }
    return null;
  }

}