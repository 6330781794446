/* eslint-disable no-useless-concat */
import * as base64 from 'base-64';
import * as graph from "../graph/graph";
import { OB2JSON } from "../tbase/OB2JSON";
import { Guid } from "guid-typescript";
import lzutf8 from 'lzutf8';
import "mxgraph-type-definitions";

import {
  IVisioRDFS, ITermSetItem, SemTalkNavigationEvent,
  SemTalkStencil, ISharePointSettings, SemTalkRibbon, SemTalkShape, SemTalkShapeType, SemTalkUIConstant, SemTalkStyleAttribute, SemTalkBuiltInShape, ResID, ResIDL
} from "../semtalklistener/visiordfsinterface";
// für jest:
import { mxConstants } from "mxgraph-js"; // mxEvent, mxCellOverlay, mxImage, mxEvent, mxPoint
import {
  IObjectBase,
  ISemTalkDiagram,
  ISemTalkObject,
  ISemTalkClass,
  ISemTalkSystemClass,
  ISemTalkInstance,
  ISemTalkNode,
  ISemTalkAssociation,
  ISemTalkLabel,
  SemTalkType,
  // ISemTalkAttribute,
  // ISemTalkAttributeType,
  // SemTalkValueType,
  SemTalkRelation,
  ISemTalkAssociationType,
  SemTalkBaseConstant,
  SemTalkLanguage, SemTalkLanguageCode,
  SemTalkObjectBaseEvent,
  ModelAttribute,
  ISemTalkDiagramType,
  ILabelSpec,
  SemTalkComposeOrder,
  ISemTalkBusinessClass,
  SemTalkID,
  SemTalkAttachment,
  ISemTalkAttributeType,
  // ISemTalkAttribute
  // ISemTalkMethodType
  // ISemTalkAssociationType
} from '../tbase/Interface';
import { Process_ElementName, SIM_AttributeTypeName } from './processInterface';
import './shapes/tsutil_DocumentShape';
import './shapes/tsutil_DataStoreShape';
import './shapes/tsutil_ClassShape';
import './shapes/tsutil_UMLClassShape';
import './shapes/tsutil_BasicShapes';


import { GetLanguage, Code2Language } from '../tbase/langtools';
import { IResStr, ResStr } from './resstr';
import { ISemTalkUndo } from '../../ISemTalkUndo';
import { DocumentStatus, ISemTalkOnline, SemTalkOnlineCommand, SemTalkRole } from '../../ISemTalkOnline';
import { SemTalkCookie } from '../../ISemTalkCookie';

import { Subtask } from './subtask';
// import { EPC_Subtask } from './subtask/epc/epcsubtask';
// import { BPMN_Subtask } from './subtask/bpmn/bpmnsubtask';
// import { citForms_Subtask } from './subtask/citforms/citformssubtask';


/* tslint:disable:member-ordering */
/* tslint:disable:no-empty */
import { ITSEditor } from '../../ITSEditor';
// import { setShape } from "./stgoto";
import { ISPExplorer } from "../explorer/spexplorerinterface";

import { MessageBarType } from 'office-ui-fabric-react';
import { CLASS_SHAPES } from './class-stencil';
import { GENERIC_SHAPES } from './generic-stencil';
import { ORGCHART_SHAPES } from './org-stencil';
import { SemTalkMaster } from "../SemTalkMaster";

import { GENERIC_STENCIL } from './Stencil_GENERIC';
import { CLASS_STENCIL } from './Stencil_CLASS';
import { UMLCLASS_STENCIL } from './Stencil_UMLCLASS';
import { round } from 'lodash';
import { copyColorStyle, getStyleAttribute, setStyleAttribute } from '../../styles';
// import { findChildAt, getParticipant } from '../../utils';
import { accessCookie, setCookie } from './stgoto';
import { OB2XML } from '../tbase/OB2XML';
import { showHelp } from './help';
import { ModelProperty } from '../../SemTalkOptions';
import { findChildAt, getParticipant } from './utils';
import { SB_SHAPES } from './subtask/sitebuilder/sb-stencil';
import { gotoObject } from './stglobal';

let EPC_Subtask: any = undefined;
let BPMN_Subtask: any = undefined;
let citForms_Subtask: any = undefined;
let SB_Subtask: any = undefined;

export class VisioRDFS implements IVisioRDFS {
  public graph: mxGraph | graph.Graph;
  public explorer!: ISPExplorer;
  public tseditor!: ITSEditor;
  public visdoc: { [id: string]: graph.Graph } = {};
  public masters: SemTalkStencil;
  public base!: IObjectBase;
  public page: ISemTalkDiagram | null | undefined;
  public guilanguage: SemTalkLanguageCode = SemTalkLanguageCode.German;
  private resstr: IResStr;
  public noevents: boolean = false;
  public site: string = "";
  public template: string = "";
  public horizontal: boolean = false;
  public showhyperlinkmarker: boolean = false;
  public IsAutoSave: boolean = false;
  private transaction: string = "";
  public readonly: boolean = false;
  public tempId: number = 0;
  private undoManger: ISemTalkUndo | null;
  private overridables: any = {};
  private highlight: mxCellHighlight | undefined;
  public hasVector = (_s: ISemTalkObject): any => undefined;
  public ismodified = false;
  public scaleshapes: number = 1;
  public validationonunserclasses: boolean = true;
  public imageBundles: any = {};


  // public CUSTOMPROC_STENCIL: SemTalkStencil = [];
  // public CLASS_STENCIL: SemTalkStencil = [];
  // public GENERIC_STENCIL: SemTalkStencil = [];
  // private PROC_STENCIL: SemTalkStencil = [];

  public stencil_registry: { [key: string]: SemTalkStencil } = {};
  // private overlays: any;

  constructor(base: IObjectBase | null, undoManger: ISemTalkUndo | null) {
    this.undoManger = undoManger;
    if (base) this.base = base;
    this.graph = new graph.Graph(this);
    this.registerOverridable("GetShapeStyle", this.GetShapeStyle);
    this.registerOverridable("initPage", this.initPage);
    this.registerOverridable("quickShapes", this.quickShapes);
    this.registerOverridable("EnsureInstance", this.EnsureInstance);
    this.registerOverridable("masterName", this.masterName);
    this.registerOverridable("isValid", this.isValid);
    this.registerOverridable("AllValidClassRelations", this.AllValidClassRelations);
    this.registerOverridable("filterRelations", this.filterRelations);
    this.registerOverridable("resetSubTaskStyle", this.resetSubTaskStyle);
    this.registerOverridable("updateLabelStyle", this.updateLabelStyle);
    this.registerOverridable("initStencil", this.initStencil);
    this.registerOverridable("setStencil", this.getStencil);
    this.registerOverridable("renderUserTab", this.renderUserTab);
    this.registerOverridable("captionUserTab", this.captionUserTab);
    this.registerOverridable("getLabel", this.getLabel);
    this.registerOverridable("BeforeDocumentSave", this.BeforeDocumentSave);
    this.registerOverridable("getRibbon", this.getRibbon);
    this.registerOverridable("AddonCommand", this.AddonCommand);
    this.registerOverridable("renderSubTaskDialog", this.renderSubTaskDialog);
    this.registerOverridable("selectLeft", this.selectLeft);
    this.registerOverridable("selectRight", this.selectRight);
    this.registerOverridable("selectLeftOptions", this.selectLeftOptions);
    this.registerOverridable("selectRightOptions", this.selectRightOptions);
    this.registerOverridable("selectUp", this.selectUp);
    this.registerOverridable("selectDown", this.selectDown);
    this.registerOverridable("selectPageUp", this.selectPageUp);
    this.registerOverridable("selectPageDown", this.selectPageDown);
    this.registerOverridable("importQuickEditTable", this.importQuickEditTable);
    this.registerOverridable("patchHyperlink", this.patchHyperlink);
    this.registerOverridable("loadExternalFormat", this.loadExternalFormat);
    this.registerOverridable("saveExternalFormat", this.saveExternalFormat);
    this.registerOverridable("ImportPlannerTasks", this.ImportPlannerTasks);
    this.registerOverridable("UpdateDependents", this.UpdateDependents);
    this.registerOverridable("saveXML", this.saveXML);
    this.registerOverridable("textGenerator", this.textGenerator);
    this.registerOverridable("getMessage", this.getMessage);
    this.registerOverridable("getDynStyles", this.getDynStyles);
    this.registerOverridable("getOptionCaption", this.getOptionCaption);
    this.registerOverridable("getDynProps", this.getDynProps);
    this.registerOverridable("EnsureObjectFlow", this.EnsureObjectFlow);
    this.registerOverridable("Initialized", this.Initialized);
    this.registerOverridable("Layout", this.Layout);
    this.registerOverridable("visIsSplitTarget", this.visIsSplitTarget);
    this.registerOverridable("shapeExpanded", this.shapeExpanded);



    return this;
  }

  public currentShape(): any | undefined {
    if (this.graph) {
      const sel = this.graph.getSelectionCells();
      if (sel && sel.length > 0) {
        return sel[0];
      }
    }
    return undefined;
  }
  public init(ob: IObjectBase | null, lan: SemTalkLanguageCode): void {
    console.debug("init VisioRDFS");
    if (this.graph) {
      if (this.graph instanceof graph.Graph) {
        this.graph.sem = this;
      }
    }
    this.noevents = true;
    if (ob) {
      ob._loading = true;
      this.base = ob;
      ob._callbacks.push(this);
      if (ob.GetModelAttribute(Process_ElementName.SLProc) === "eEPK")
        ob.SetModelAttribute(ModelAttribute.BPMNMode, "");
      let tpl = ob.GetModelAttribute(ModelAttribute.Template);
      if (tpl === "cit_intelliForm_dialog.vst")
        ob.SetModelAttribute(ModelAttribute.BPMNMode, "");
      if (tpl === "wss.vst" || tpl === "sitebuilder.vst")
        ob.SetModelAttribute(ModelAttribute.BPMNMode, "");
      let bundles = ob.GetModelAttribute(SemTalkCookie.imagebundles);
      if (bundles !== undefined) {
        try {
          this.imageBundles = JSON.parse(bundles);
        } catch (e) {
          console.debug(e);
        }
      }
    }


    this.guilanguage = lan;
    this.resstr = new ResStr(this.guilanguage);
    // this.resx_SemList = require("./SemList.json");
    // this.resx_SemTalk = require("./SemTalk.json");

    if (ob) {
      this.initComment();
      ob._loading = false;

      let bmode = this.base.GetModelAttribute(ModelAttribute.BPMNMode);

      let isbpmn = (bmode && bmode.length > 0);
      this.horizontal = false;
      if (isbpmn) {
        this.template = "bpmn";
      } else {
        let tpl = ob.GetModelAttribute(ModelAttribute.Template);
        if (tpl.indexOf("epc") > -1) {
          this.template = "epc";
        }
        if (tpl.indexOf("cit") > -1) {
          this.template = "cit";
        }
        if (tpl.indexOf("semtalk") > -1) {
          this.template = "semtalk";
        }
        if (tpl.indexOf("wss") > -1 || tpl.indexOf("sitebuilder") > -1) {
          this.template = "sitebuilder";
        }
      }
    }
    this._ribbon = null;
    this._toolbar = null;
    // this.overlays = require("./overlays.json");

    this.noevents = false;
  }

  public async patchSubTask(ob: IObjectBase, resizeall: boolean): Promise<void> {
    this.noevents = true;
    ob._loading = true;
    let bmode = this.base.GetModelAttribute(ModelAttribute.BPMNMode);
    let tpl = ob.GetModelAttribute(ModelAttribute.Template);
    let isbpmn = (bmode && bmode.length > 0);
    this.horizontal = false;
    if (isbpmn) {
      if (!BPMN_Subtask) {
        let res = await import('./subtask/bpmn/bpmnsubtask');
        BPMN_Subtask = res.default;
      }
      BPMN_Subtask.patch(this, resizeall);
      this.template = "bpmn";
    } else {
      if (tpl.indexOf("epc") > -1) {
        this.template = "epc";
        if (!EPC_Subtask) {
          let res = await import('./subtask/epc/epcsubtask');
          EPC_Subtask = res.default;
        }
        EPC_Subtask.patch(this, resizeall);
      } else {
        if (tpl.indexOf("ksa") > -1) {
          this.template = "ksa";
          Subtask.patch(this, resizeall);
        } else {
          if (tpl.indexOf("cit") > -1) {
            if (!citForms_Subtask) {
              let res = await import('./subtask/citforms/citformssubtask');
              citForms_Subtask = res.default;
            }
            citForms_Subtask.patch(this, resizeall);
          } else {
            if (tpl.indexOf("sitebuilder") > -1 || tpl.indexOf("wss.vst") > -1) {
              this.template = "sitebuilder";
              if (!SB_Subtask) {
                let res = await import('./subtask/sitebuilder/sbsubtask');
                SB_Subtask = res.default;
              }
              SB_Subtask.patch(this, resizeall);
            } else {
              Subtask.patch(this, resizeall);
            }
          }
        }
      }
    }
    ob._loading = false;
    this.noevents = false;
  }
  private registerOverridable(name: string, fn: any) {
    if (!this.overridables[name]) {
      this.overridables[name] = fn;
    }
  }
  public getOverridable(name: string): any {
    return this.overridables[name];
  }

  public BeforeDocumentSave(): boolean {

    return true;
  }

  public async initSubTask(_ob: IObjectBase): Promise<void> {

    this.GetShapeStyle = this.getOverridable("GetShapeStyle");
    this.initPage = this.getOverridable("initPage");
    this.quickShapes = this.getOverridable("quickShapes");
    this.EnsureInstance = this.getOverridable("EnsureInstance");
    this.masterName = this.getOverridable("masterName");
    this.isValid = this.getOverridable("isValid");
    this.AllValidClassRelations = this.getOverridable("AllValidClassRelations");
    this.filterRelations = this.getOverridable("filterRelations");
    this.resetSubTaskStyle = this.getOverridable("resetSubTaskStyle");
    this.updateLabelStyle = this.getOverridable("updateLabelStyle");
    this.initStencil = this.getOverridable("initStencil");
    this.getStencil = this.getOverridable("setStencil");
    this.renderUserTab = this.getOverridable("renderUserTab");
    this.captionUserTab = this.getOverridable("captionUserTab");
    this.getLabel = this.getOverridable("getLabel");
    this.BeforeDocumentSave = this.getOverridable("BeforeDocumentSave");
    this._ribbon = null;
    this._toolbar = null;
    this.getRibbon = this.getOverridable("getRibbon");
    this.AddonCommand = this.getOverridable("AddonCommand");
    this.renderSubTaskDialog = this.getOverridable("renderSubTaskDialog");
    this.selectLeft = this.getOverridable("selectLeft");
    this.selectRight = this.getOverridable("selectRight");
    this.selectLeftOptions = this.getOverridable("selectLeftOptions");
    this.selectRightOptions = this.getOverridable("selectRightOptions");
    this.selectUp = this.getOverridable("selectUp");
    this.selectDown = this.getOverridable("selectDown");
    this.selectPageUp = this.getOverridable("selectPageUp");
    this.selectPageDown = this.getOverridable("selectPageDown");
    this.importQuickEditTable = this.getOverridable("importQuickEditTable");
    this.patchHyperlink = this.getOverridable("patchHyperlink");
    this.loadExternalFormat = this.getOverridable("loadExternalFormat");
    this.saveExternalFormat = this.getOverridable("saveExternalFormat");
    this.ImportPlannerTasks = this.getOverridable("ImportPlannerTasks");
    this.UpdateDependents = this.getOverridable("UpdateDependents");
    this.saveXML = this.getOverridable("saveXML");
    this.textGenerator = this.getOverridable("textGenerator");
    this.getMessage = this.getOverridable("getMessage");
    this.getDynStyles = this.getOverridable("getDynStyles");
    this.getDynProps = this.getOverridable("getDynProps");
    this.getOptionCaption = this.getOverridable("getOptionCaption");
    this.EnsureObjectFlow = this.getOverridable("EnsureObjectFlow");
    this.visIsSplitTarget = this.getOverridable("visIsSplitTarget");
    this.Initialized = this.getOverridable("Initialized");
    this.Layout = this.getOverridable("Layout");
    this.shapeExpanded = this.getOverridable("shapeExpanded");


    switch (this.template) {
      case "bpmn":
        if (!BPMN_Subtask) {
          let res = await import('./subtask/bpmn/bpmnsubtask');
          BPMN_Subtask = res.default;
        }
        BPMN_Subtask.init(this);
        break;
      case "epc":
        if (!EPC_Subtask) {
          let res = await import('./subtask/epc/epcsubtask');
          EPC_Subtask = res.default;
        }
        EPC_Subtask.init(this);
        break;
      case "cit":
        if (!citForms_Subtask) {
          let res = await import('./subtask/citforms/citformssubtask');
          citForms_Subtask = res.default;
        }
        citForms_Subtask.init(this);
        break;
      case "ksa":
        this.alert("KSA is not yet implemented", MessageBarType.severeWarning);
        Subtask.init(this);
        break;
      case "sitebuilder":
        if (!SB_Subtask) {
          let res = await import('./subtask/sitebuilder/sbsubtask');
          SB_Subtask = res.default;
        }
        SB_Subtask.init(this);
        break; default:
        Subtask.init(this);
    }
  }
  public setBundles = () => {
    if (this.tseditor) {
      this.tseditor.setBundles(this.imageBundles);
      // if (this.overlays) {
      //   this.tseditor.setOverlayBundle(this.overlays);
      // }
      this.base.SetModelAttribute(SemTalkCookie.imagebundles, JSON.stringify(this.imageBundles));
    }
  }
  public getBundeledImages = (): any[] => {
    if (this.tseditor) {
      return this.tseditor.getBundeledImages();
    }
    return [];
  }
  public getImageFromBundles = (img: string): any => {
    let bundles = this.base.GetModelAttribute(SemTalkCookie.imagebundles);
    if (bundles !== undefined) {
      try {
        let bu = JSON.parse(bundles);
        return bu[img];

      } catch (e) {
        console.debug(e);
      }
    }

    // if (this.tseditor) {
    //   return this.tseditor.getImageFromBundles(img);
    // }
    return null;
  }
  public removeBundles = (images: any) => {
    if (this.tseditor) {
      this.tseditor.removeBundles(images);
      this.base.SetModelAttribute(SemTalkCookie.imagebundles, JSON.stringify(this.imageBundles));
    }
  }
  private initComment() {
    let comof = this.base.GetModelAttribute(ModelAttribute.SLCommentOf);
    if (comof === undefined) {
      comof = SemTalkBaseConstant.SLDefinitionOf;
      this.base.SetModelAttribute(ModelAttribute.SLCommentOf, comof);
    }
    if (this.base.FindAssociationType(comof) === null)
      this.base.MakeAssociationType(SemTalkRelation.SemTalkSystemRelation, comof);

    const f = this.base.FindAssociationType(comof);
    if (f) {
      f.IsReadOnly = true;
      if (f.Style.length === 0) {
        f.Style = SemTalkStyleAttribute.shape + "=flexArrow;" +
          SemTalkStyleAttribute.dashed + "=1;" +
          SemTalkStyleAttribute.endArrow + "=classic;html=1;" +
          SemTalkStyleAttribute.editable + "=0";
      }
    }

    let com = this.base.GetModelAttribute(ModelAttribute.SLComment);
    if (com === undefined) {
      com = SemTalkBaseConstant.SLComment;
      this.base.SetModelAttribute(ModelAttribute.SLComment, com);
    }
    let fc = this.base.FindSystemClass(com);
    if (fc === null) {
      fc = this.base.MakeSystemClass(com);
    }
    fc.IsReadOnly = true;
    if (fc.Style.length === 0 || fc.Style === "fillColor=yellow;shadow=1;strokeWidth=2;") {
      fc.Style = SemTalkStyleAttribute.fillColor + "=yellow;" +
        SemTalkStyleAttribute.shadow + "=1;" +
        SemTalkStyleAttribute.strokeWidth + "=2;" +
        SemTalkStyleAttribute.align + "=left";
    }
    this.patchAnnotation(this.base);
  }
  private patchAnnotation = (base: IObjectBase) => {
    let defof = base.GetModelAttribute(ModelAttribute.SLCommentOf);
    let SLANNOTATION = "Annotation";
    let f = base.FindClass(SLANNOTATION);
    if (f) {
      let com = base.FindClass(SemTalkBaseConstant.SLComment);
      if (com) {
        for (let obj of f.Instances()) {
          obj.SetClass(com);
          for (let o of obj.Links(defof, SemTalkRelation.SemTalkSystemRelation)) {
            for (let nd of o.Nodes()) {
              nd.Master = defof;
            }
          }

        }
      }
    }
    let ass = base.FindAssociationType(defof);
    if (ass) {
      ass.ShowLabel = false;
    }

  }

  public getResStrListener(str: string, lang?: SemTalkLanguageCode): string {
    if (lang === undefined) {
      lang = this.guilanguage;
    }
    return this.resstr.getResStrListener(str, lang);
  }
  public getResStr(str: string, lang?: SemTalkLanguageCode): string {
    if (lang === undefined) {
      lang = this.guilanguage;
    }
    return this.resstr.getResStr(str, lang);
  }
  public getResKeys(): string[] {
    return this.resstr.getResKeys();
  }
  public getResKeysListener(): string[] {
    return this.resstr.getResKeysListener();
  }
  public FindShapeByShapeID(shapeid: string): mxCell | null {
    if (this.tseditor) {
      let cells = this.tseditor.getAllCells();
      for (const i in cells) {
        const s = cells[i];
        if (this.getMxId(s) === shapeid || s.shapeid === shapeid || s.shapeid === "mx" + shapeid) {
          return s;
        }
      }
    }

    return null;
  }
  public FindShape(obj: ISemTalkObject): any | null {
    let cells: any[];
    if (this.tseditor) {
      cells = this.tseditor.getAllCells();
      //  cells = this.tseditor.getChildCells(null, true, true);
    } else {
      cells = (this.graph as graph.Graph).getChildCells(null, true, true);
    }
    //    for (const s of cells) {
    for (const i in cells) {
      const s = cells[i];
      if (s.objectid === obj.ID) {
        return s;
      }
    }
    return null;
  }

  private inheritStyle = (style: string, s0: string): string => {
    if (style.length > 0 && style !== s0) {
      let shpsettingsobj: any = {};
      for (const shpsetting of s0.split(";")) {
        if (shpsetting.length > 0) {
          const n = shpsetting.split("=")[0];
          shpsettingsobj[n] = shpsetting.substring(shpsetting.indexOf("=") + 1);
        }
      }
      let iscustom = shpsettingsobj[SemTalkStyleAttribute.custom] === "1";
      for (const syssetting of style.split(";")) {
        if (syssetting.length > 0) {
          const n = syssetting.split("=")[0];
          const v = syssetting.substring(syssetting.indexOf("=") + 1);
          if (n.length > 0) {
            if (!iscustom || shpsettingsobj[n] === undefined) {
              if (shpsettingsobj[n] !== v) {
                shpsettingsobj[n] = v;
              }
            }
          }
        }
      }
      s0 = "";
      for (let n in shpsettingsobj) {
        s0 += n + "=" + shpsettingsobj[n] + ";";
      }
    }
    return s0;
  }

  public resetSubTaskStyle = (s0: string): string => {
    return s0;
  }
  public updateLabelStyle = (_obj: ISemTalkObject, _lsp: ILabelSpec, style: string): string => {
    // Hook for subtask
    return style;
  }

  public UpdateLabel = (obj: ISemTalkObject) => {
    // if (this.lstUpdateLabel===obj.ObjectName) return;
    // this.lstUpdateLabel=obj.ObjectName;
    let isgeneric = this.page?.ClassOf().ObjectName === SemTalkBaseConstant.SLGeneric;

    let style = "";
    const sysclass = obj.SystemClass();
    const isclass = obj.ObjectBase.IsClass(obj);
    const issysclass = obj.ObjectBase.IsSystemClass(obj);
    if (!sysclass) {
      style = "";
      if (isgeneric) {
        if (this.base.IsClass(obj)) {
          style = this.GetShapeStyle(SemTalkMaster.MasterClass, SemTalkShapeType.generic).style;
          if (!this.page?.ClassOf().UMLShape) {
            style = setStyleAttribute(style, SemTalkStyleAttribute.isuml, "0");
          } else {
            style = setStyleAttribute(style, SemTalkStyleAttribute.isuml, "");

          }

        }
        if (obj.ObjectType === SemTalkType.SemTalkInstance) {
          let thing = obj.ObjectBase.FindSystemClass(SemTalkBaseConstant.SLThing);
          if (thing && thing.Style.length > 0) {
            style = thing.Style;
          } else {
            style = this.GetShapeStyle(SemTalkMaster.MasterInstance, SemTalkShapeType.generic).style;
          }
        }
      }
    }
    if (sysclass) {
      style = sysclass.Style;
    }
    if (style === undefined) {
      style = "";
    }
    const refinementstyle = SemTalkStyleAttribute.fontStyle + "=" + String(mxConstants.FONT_UNDERLINE + mxConstants.FONT_BOLD) + ";";
    let underlineRefinements: boolean = false;
    if (obj.Refinement !== null || obj.ExtRefinement !== null) {
      const refmodel = obj.ObjectBase.GetModelAttribute(ModelAttribute.underlineRefinements);
      if (refmodel === "1") {
        underlineRefinements = true;
      } else {
        // const ref = accessCookie(SemTalkCookie.refinements);
        // if (ref === 'true') {
        //   if (!style.endsWith(";")) style += ";";
        //   style += s2;
        // }
      }
    }

    if (issysclass) {
      const s3 = SemTalkStyleAttribute.strokeWidth + "=5;";
      if (style.length > 0 && !style.endsWith(";")) style += ";";
      style += s3;
    }

    let attachements: ISemTalkAssociation[] = [];
    attachements.push(...obj.Attachments());
    let base = obj.ObjectBase;
    let infotype = base.FindAssociationType(base.GetModelAttribute(Process_ElementName.SLInfoType));
    if (infotype) {
      for (let bo of obj.LinkedObjects(infotype)) {
        let alist = bo.Attachments();
        if (alist.length > 0) {
          attachements.push(...alist);
        }
      }
    }
    const showattachments: boolean = attachements.length > 0;
    for (const nd of obj.Nodes()) {
      if (nd.Diagram !== this.page) {
        continue;
      }

      // const lbl: stinterface.LabelSpec=obj.UpdateLabel(nd.Master);
      const shp = this.FindShapeByShapeID(nd.ShapeID);
      if (this.graph.isEditing(shp)) {
        break;
      }
      if (shp !== null) {
        if (this.tseditor) {
          if (shp.shapeKey !== "swimlane") {
            this.tseditor.clearCellOverlays(shp);
          }
        }
        if (this.showhyperlinkmarker && this.tseditor && showattachments && this.site.length > 0) {
          // let overlay = new mxCellOverlay(new mxImage(this.site + overlayPath + "information.png", 18, 18), 'Add child');
          let dx = 8;
          for (let att of attachements) {
            let lbl: string = att.GetValue(SemTalkAttachment.label);
            if (lbl !== null && lbl.length > 0) {
            } else {
              lbl = att.ToObject.ID2NameNsp();
            }
            let ico: string = att.GetValue(SemTalkAttachment.icon);
            if (ico !== null && ico.length > 0) {
              if (ico === "none") {
                continue;
              }
            } else {
              ico = "information";
            }
            let overlay: string = this.site + "/Support/images/overlays/" + ico + ".png";
            // if (att.ToObject.ObjectName.startsWith("http")) {
            this.tseditor.addURLOverlay(shp, overlay, mxConstants.ALIGN_TOP,
              mxConstants.ALIGN_LEFT, 18, 18, dx, 0, lbl, 'hand', att.ToObject.ObjectName);
            // }
            dx += 18;
            if (dx > 80) {
              break;
            }
          }
        }

        let s0: any = shp.style;
        if (s0 === undefined) s0 = style;
        if (s0["style"] !== undefined) s0 = s0["style"];
        s0 = s0.replace(refinementstyle, "");

        if (s0.indexOf('entryX') > 0 || s0.indexOf('entryY') > 0) {
          if (sysclass) s0 = sysclass.Style;
        }
        // inherit styles from sysclass in case the shape has no custom styles
        if (!isclass) {
          if (s0.indexOf(SemTalkStyleAttribute.custom) < 0) {
            s0 = this.inheritStyle(style, s0);
          }
        } else {
          // s0 = this.inheritStyle(style, s0, s2, obj);
          if (isgeneric && isclass && s0.indexOf(SemTalkStyleAttribute.custom) < 0) {
            s0 = setStyleAttribute(s0, SemTalkStyleAttribute.shape, SemTalkBuiltInShape.genericclass);
            if (style.indexOf(SemTalkStyleAttribute.isuml + "=0") > -1) {
              s0 = setStyleAttribute(s0, SemTalkStyleAttribute.isuml, "0");
            }
          }
          if (isgeneric && s0.indexOf(SemTalkStyleAttribute.edgeStyle) > 0) {
            s0 = setStyleAttribute(s0, SemTalkStyleAttribute.edgeStyle, "");
            s0 = s0.replace(SemTalkStyleAttribute.edgeStyle + "=;", "");
          }
        }
        const lsp = obj.UpdateLabel(nd.Master);

        // if (!isgeneric) {
        s0 = this.resetSubTaskStyle(s0);
        style = this.updateLabelStyle(obj, lsp, s0);
        if (underlineRefinements) {
          if (!style.endsWith(";")) style += ";";
          style += refinementstyle;
        }

        // }
        const noev = this.noevents;
        //  shp.label = nd.ObjectCaption;
        shp.objectid = nd.Model.ID;

        let classmasters: string[] = [SemTalkMaster.MasterClass,
        SemTalkMaster.MasterSystemClass,
        SemTalkMaster.MasterAttributeType,
        SemTalkMaster.MasterAssociationType,
        SemTalkMaster.MasterDiagramType,
        ];

        if (obj.ObjectType !== SemTalkType.SemTalkInstance && classmasters.indexOf(lsp.newtxt) > -1) {
          lsp.newtxt = "";
        }
        let sc = obj.SystemClass();
        if (sc && obj.ObjectType === SemTalkType.SemTalkInstance &&
          (lsp.newtxt === sc.ObjectCaption || lsp.newtxt === sc.ObjectName)) {
          lsp.newtxt = "";
        }

        this.noevents = true;
        if (lsp.newtxt !== undefined && lsp.newtxt.length === 0
          && lsp.newtxt0 && lsp.newtxt0.length > 0) {
          // this.editor.labelChanged(shp, lsp.newtxt0, null);
          // this.editor.renameCell(lsp.newtxt0, shp);
          if (obj.ObjectType === SemTalkType.SemTalkInstance &&
            lsp.newtxt0 === obj.SystemClass()?.ObjectName) {
            lsp.newtxt0 = "";
          }
          if (shp.value !== lsp.newtxt0) {
            shp.value = lsp.newtxt0;
          }

        } else {
          // this.editor.labelChanged(shp, lsp.newtxt, null);
          // this.editor.renameCell(lsp.newtxt, shp);
          if (shp.value !== lsp.newtxt) {
            shp.value = lsp.newtxt;
          }
        }
        if (lsp.newtxt1.length > 0) {
          shp.value += "\n" + lsp.newtxt1;
        }

        if (style.length > 0) {
          shp.style = style;
        } else {
          if (s0.indexOf(refinementstyle) > -1) {
            s0 = s0.replace(refinementstyle, '');
          }
          shp.style = s0;
        }
        if (this.base.IsAssociation(obj)) {
          let rel = obj as ISemTalkAssociation;
          let relcla = rel.ClassOf();
          if (relcla.ObjectName === SemTalkBaseConstant.SLSubClassOf ||
            relcla.ObjectName === SemTalkBaseConstant.SLInstanceOf) {
            shp.style = relcla.Style;
            shp.value = "";
            // let v =this.hasVector(rel.FromObject);
            // if (v) {
            //   let dst = v[rel.ToObject.ID2Name];
            //   if (dst) {
            //     if (shp.style.indexOf("strokeWidth=1") < 0) {
            //       shp.style += "strokeWidth=" + String((Number(dst)*5).toPrecision(1)) + ";";
            //     }
            //   }
            // }
          } else {
            if (isgeneric && shp.edge && !shp.style) {
              shp.style = SemTalkStyleAttribute.shape + "=flexArrow;" +
                SemTalkStyleAttribute.edgeStyle + "=loopRelationEdgeStyle;" +
                SemTalkStyleAttribute.curved + "=1;" +
                SemTalkStyleAttribute.endArrow + "=classic;html=1;" +
                SemTalkStyleAttribute.strokeWidth + "=2;";
            }
            let isthing = false;
            const ob = this.base;
            const src = rel.FromObject;
            const dst = rel.ToObject;
            if (ob.IsInstance(src) && (src as ISemTalkInstance).ClassOf().ObjectName === SemTalkBaseConstant.SLThing) {
              isthing = true;
            } else {
              if (ob.IsInstance(dst) && (dst as ISemTalkInstance).ClassOf().ObjectName === SemTalkBaseConstant.SLThing) {
                isthing = true;
              }
            }
            if (isthing || (ob.IsClass(src) && ob.IsClass(dst))) {  // && relcla.Style === ''
              if (!isgeneric) {
                shp.style = relcla.Style;
              }
              let sw = "2";
              if (rel.FindAttribute("similarity")) {
                let s = round(Number.parseFloat(rel.GetValue("similarity")) * 3, 0) + 1;
                sw = s.toString();
                shp.value = "";
              } else {
                shp.value = relcla.ID2NameNsp();
              }
              if (shp.style === "") {
                shp.style = SemTalkStyleAttribute.shape + "=flexArrow;" +
                  SemTalkStyleAttribute.endArrow + "=classic;html=1;" +
                  SemTalkStyleAttribute.fillColor + "=#ffffff;" +
                  SemTalkStyleAttribute.strokeWidth + "=" + sw + ";";
              } else {
                if (shp.style.indexOf(SemTalkStyleAttribute.strokeWidth) < 0) {
                  shp.style = shp.style + ";" +
                    SemTalkStyleAttribute.strokeWidth + "=" + sw + ";";
                }
              }
            }
          }
        }
        // }
        if (this.base.IsInstance(obj)) {
          let ins = obj as ISemTalkInstance;
          switch (ins.ClassOf().ObjectName) {
            case this.base.GetModelAttribute(ModelAttribute.SLComment): {
              const defof = this.base.GetModelAttribute(ModelAttribute.SLCommentOf);
              for (let c of obj.LinkedObjects(defof, false, SemTalkRelation.SemTalkSystemRelation)) {
                shp.value = c.Comment;
              }
              break;
            }
          }
        }
        shp.label = shp.value;
        if (this.hasVector && this.hasVector(obj)) {
          if (shp.style.indexOf(SemTalkStyleAttribute.shadow + "=1") < 0) {
            shp.style += SemTalkStyleAttribute.shadow + "=1;";
          }
        }
        if (shp.shapeKey === SemTalkMaster.MasterClass && isgeneric) {
          this.handleClassShape(shp, obj as ISemTalkClass);
        }
        if (shp.shapeKey === SemTalkMaster.MasterSystemClass && isgeneric) {
          this.handleClassShape(shp, obj as ISemTalkClass);
        }
        if (shp.shapeKey === SemTalkMaster.MasterUMLClass ||
          shp.style.indexOf(SemTalkStyleAttribute.shape + "=" + SemTalkBuiltInShape.umlclass) > -1) {
          if (this.base.IsBusinessClass(obj)) {
            this.handleUMLShape(shp, obj as ISemTalkBusinessClass);
          } else {
            this.handleClassShape(shp, obj as ISemTalkClass);
          }
        }
        if (shp.style.indexOf(SemTalkStyleAttribute.autosize + "=1") > -1) {
          // this.graph.updateCellSize(shp, false);
          let s = this.graph.getPreferredSizeForCell(shp);
          let s1 = shp.geometry;
          if (s) {
            shp.geometry.setRect(s1.x, s1.y, s1.width, Math.max(s.height, 40));
          }
        }
        this.noevents = noev;
      }
    }
  }
  public EnsureSwimlaneContent() {
    let shapes = this.tseditor.getAllCells();
    for (let i in shapes) {
      let s = shapes[i];
      if (s.shapeKey === SemTalkMaster.MasterSwimlane) {
        this.handleSwimlaneContent(s, shapes);
      }
    }
  }

  private handleSwimlaneContent(shp: mxCell, shapes: mxCell[]) {
    let geo = shp.geometry;
    let slshapes: mxCell[] = [];
    for (let i in shapes) {
      let s = shapes[i];
      let sgeo = s.geometry;
      if (sgeo && s.shapeid !== shp.shapeid && s.parent.id === '1' &&
        sgeo.x >= geo.x && sgeo.y >= geo.y &&
        sgeo.x + sgeo.width <= geo.x + geo.width &&
        sgeo.y + sgeo.height <= geo.y + geo.height) {
        slshapes.push(s);
      }
    }
    for (let s of slshapes) {
      // s.setParent(shp);
      // this.tseditor.graph.getModel().add(shp, s);
      console.debug(s);
    }
    if (slshapes.length > 0) {
      this.UpdateSwimlaneContent(shp);
    }
  }
  private handleUMLShape(shp: mxCell, obj: ISemTalkBusinessClass) {
    let mlist = (obj as ISemTalkBusinessClass).Methods().map(x => x.ClassOf().ObjectCaption);
    let alist = (obj as ISemTalkBusinessClass).Attributes().map(x => x.ClassOf().ObjectCaption);
    let slist = (obj as ISemTalkBusinessClass).States().map(x => x.ClassOf().ObjectCaption);
    alist.push(...slist);
    shp["methods"] = mlist.sort((x, y) => x.localeCompare(y));
    shp["attributes"] = alist.sort((x, y) => x.localeCompare(y));
    let font: string = "Helvetica, Arial, sans-serif";
    // // let fo = accessCookie(SemTalkCookie.font);
    // // if (fo) font = fo;
    let h = 24;
    let h1 = 0;
    let h2 = 0;
    let s1 = shp.geometry;
    let size = mxUtils.getSizeForString("X", 10, font, s1.width);
    // let size = { x: 0, y: 0, width: 100, height: 12 };

    if (shp && shp["attributes"]) {
      let attributes: string[] = shp["attributes"];
      h1 = 10 + size.height * attributes.length;
    }
    if (shp && shp["methods"]) {
      let methods: string[] = shp["methods"];
      h2 = 10 + size.height * methods.length;
    }
    shp.geometry.setRect(s1.x, s1.y, s1.width, h + h1 + h2);
  }
  private handleClassShape(shp: mxCell, obj: ISemTalkClass) {
    let style = shp.style;
    if (style.indexOf(SemTalkStyleAttribute.isuml + "=0") > -1) {
      return;
    }
    let alist = (obj as ISemTalkClass).Attributes().map(x => x.ClassOf().ObjectCaption);
    shp["attributes"] = alist.sort((x, y) => x.localeCompare(y));
    // let font: string = "Helvetica, Arial, sans-serif";
    // let fo = accessCookie(SemTalkCookie.font);
    // if (fo) font = fo;
    let h = 24;
    let h1 = 0;
    let h2 = 0;
    let s1 = shp.geometry;
    // let size = mxUtils.getSizeForString("X", 10, font, s1.width);
    let size = { x: 0, y: 0, width: 100, height: 12 };
    if (shp && shp["attributes"]) {
      if (shp["hideattributes"] !== 1) {
        let attributes: string[] = shp["attributes"];
        h1 = 10 + size.height * attributes.length;
      } else {
        h1 = 10;
      }
    }
    shp.geometry.setRect(s1.x, s1.y, s1.width, h + h1 + h2);
  }
  private UpdateLabelByID(id: SemTalkID) {
    const obj = this.base.FindObjectByID(id);
    if (obj !== null) {
      this.UpdateLabel(obj);
    }
  }
  public UpdateByID(id: SemTalkID) {
    if (this.noevents) { return; }
    const obj = this.base.FindObjectByID(id);
    if (this.tseditor) {
      this.tseditor.beginUpdate();
    }
    if (obj !== null) {
      this.UpdateLabel(obj);
      this.UpdateDependents(obj);
      // this.refreshShapes(obj);
    }
    if (this.tseditor) {
      this.tseditor.endUpdate();
      if (obj !== null) {
        this.refreshShapes(obj);
      }
      // this.tseditor.refresh();
    }
  }

  // private refreshShapes(obj: ISemTalkObject) {
  //   if (this.tseditor) {
  //     for (let nd of obj.Nodes()) {
  //       let shp = this.FindShapeByShapeID(nd.ShapeID);
  //       if (shp) {
  //         this.tseditor.refresh(shp);
  //       }
  //     }
  //   }
  // }

  // public DependendsCache: string[] | null = null;
  public UpdateDependents(obj: ISemTalkObject) {
    // if (this.DependendsCache) {
    //   if (this.DependendsCache.indexOf(obj.ID)>0) {
    //     return;
    //   } else {
    //     this.DependendsCache.push(obj.ID);
    //   }
    // }
    if (obj.ObjectBase.IsClass(obj)) {
      const cla = obj as ISemTalkClass;
      for (const ins of cla.Instances()) {
        this.UpdateLabel(ins);
        this.refreshShapes(ins);

      }
      for (const cmp of cla.InvCompositions()) {
        this.UpdateDependents(cmp.Owner);
        this.refreshShapes(cmp.Owner);

      }
    } else {
      if (obj.ObjectBase.IsAssociation(obj)) {
        this.UpdateLabel((obj as ISemTalkAssociation).FromObject);
        this.UpdateLabel((obj as ISemTalkAssociation).ToObject);
        this.refreshShapes((obj as ISemTalkAssociation).FromObject);
        this.refreshShapes((obj as ISemTalkAssociation).ToObject);
      }
    }
    for (const rel of obj.Associations()) {
      this.UpdateLabel(rel.ToObject);
      this.refreshShapes(rel.ToObject);
    }
    for (const rel of obj.InvAssociations()) {
      this.UpdateLabel(rel.FromObject);
      this.refreshShapes(rel.FromObject);
    }
  }

  public visCellSystemClass(oid: SemTalkID): ISemTalkSystemClass | null {
    const obj = this.base.FindObjectByID(oid);
    if (obj !== null) {
      if (this.base.IsClass(obj)) {
        return (obj as ISemTalkClass).SystemClass();
      } else {
        return (obj as ISemTalkInstance).ClassOf().SystemClass();
      }
    }
    return null;
  }
  public visCellRefinement(oid: SemTalkID): ISemTalkDiagram | null {
    const obj = this.base.FindObjectByID(oid);
    if (obj !== null) {
      return obj.Refinement;
    }
    return null;
  }
  public visCellGoUp(oid: SemTalkID): ISemTalkObject | null {
    const obj = this.base.FindObjectByID(oid);
    if (obj !== null) {
      let ob = this.base;
      let outtype = ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.STTriggers));
      if (outtype)
        for (let up of obj.LinkedObjects(outtype)) {
          return up;
        }
      let intype = ob.FindAssociationType(ob.GetModelAttribute(Process_ElementName.STTriggeredBy));
      if (intype)
        for (let up of obj.LinkedObjects(intype)) {
          return up;
        }
    }
    return null;
  }
  public visCellClass(oid: SemTalkID): ISemTalkClass | null {
    const obj = this.base.FindClassByID(oid);
    if (obj !== null) {
      return obj;
    }
    return null;
  }

  public masterName = (key: string): string => {
    if (this.masters)
      for (let m of this.masters) {
        if (m.key === key)
          return m.name;
        switch (key) {
          case "Class":
            return SemTalkMaster.MasterClass;
          case "UMLClass":
            return SemTalkMaster.MasterUMLClass;
          case "SystemClass":
            return SemTalkMaster.MasterSystemClass;
          case "Comment":
            return SemTalkMaster.MasterComment;
          case "Instance":
            return SemTalkMaster.MasterInstance;
          case "AttributeClass":
            return SemTalkMaster.MasterAttributeType;
          case "AssociationClass":
            return SemTalkMaster.MasterAssociationType;
          case "DiagramClass":
            return SemTalkMaster.MasterDiagramType;
          case "Attachment":
            return SemTalkMaster.MasterAttachment;
        }
      }
    return key;
  }
  public visShapesCloned(shapes: mxCell[]): void {
    if (this.noevents) { return; }
    if (shapes === undefined) { return; }

    this.beginTransaction("visShapesCloned");
    this.shapesClonedRec(shapes);
    this.endTransaction("visShapesCloned");
  }
  private shapesClonedRec(shapes: mxCell[]) {
    for (let c of shapes) {
      if (c === null) {
        continue;
      }
      if (c && c.children) {
        this.shapesClonedRec(c.children);
      }
      let value = c.value;
      let oid = c["objectid"];
      let oldcla: ISemTalkObject | null = null;
      const oldinst = this.base.FindInstanceByID(oid);
      if (c.isVertex()) {
        if (oldinst !== null) {
          const sc = oldinst.SystemClass();
          if (sc !== null) {
            if (c.shapeName === undefined) {
              c.shapeName = this.masterName(sc.ObjectName);
            }
            if (sc.OnceOnly) {
              c["objectid"] = undefined;
            }
          }
        } else {
          oldcla = this.base.FindObjectByID(oid);
          if (!oldcla) {
            c["objectid"] = undefined;
          } else {
            this.ChangeObject(c, [oldcla]);
          }
        }
        c["shapeid"] = undefined;
        this.visShapeAdded(c, null);
        let newoid = c["objectid"];
        if (newoid !== oid) {
          let newobj = this.base.FindInstanceByID(newoid);
          if (newobj && oldinst) {
            newobj.SetClass(oldinst.ClassOf());
            newobj.Clone(oldinst);
          } else {
            // const oldclass = this.base.FindClassByID(oid);
            // let newclass = this.base.FindClassByID(newoid);
            // if (newclass && oldclass) {
            //   this.UpdateLabel(newclass);
            // } else {
            if (value && !c.value && !oldinst && !oldcla) {
              let obj: ISemTalkObject | null = this.base.FindInstance(value);
              if (!obj) {
                obj = this.base.FindClass(value);
              }
              if (!obj) {
                obj = this.base.FindClass("Ob#" + value);
              }
              if (obj) {
                this.ChangeObject(c, [obj]);
              } else {
                this.visShapeExitedTextEdit(c, value);
              }
            }
            // }
          }
        }
      }
    }
    for (let c of shapes) {
      if (c.isEdge()) {
        let value = c.value;
        const oid = c["objectid"];
        const oldlnk = this.base.FindInstanceByID(oid);
        c["objectid"] = undefined;
        c["shapeid"] = undefined;
        if (c.shapeName === undefined) {
          if (oldlnk !== null) {
            const sc = oldlnk.SystemClass();
            if (sc !== null) {
              c.shapeName = this.masterName(sc.ObjectName);
            }
          }
        }
        this.visConnectionAdded(undefined, c, c.source, c.target);
        let newoid = c["objectid"];
        let newobj = this.base.FindInstanceByID(newoid);
        if (newobj && oldlnk) {
          newobj.Clone(oldlnk);
        } else {
          if (value && value !== c.value) {
            let at = this.base.FindAssociationType(value);
            if (at) {
              this.ChangeObject(c, [at]);
            } else {
              this.visShapeExitedTextEdit(c, value);
            }
          }
        }
      }
    }
  }
  private EnsureUses(c: mxCell) {
    let dis: ISemTalkAssociationType | null = null;
    let parent: mxCell = c.parent;

    if (parent && parent.id === '1') {
      if (this.tseditor) {
        let x = c.geometry.x;
        let y = c.geometry.y;
        const swimlane = this.tseditor.graph.getSwimlaneAt(x, y, parent);
        if (swimlane !== null) {
          parent = swimlane;
          // console.debug(swimlane);
        }
      }
    }
    if (parent && parent.id !== '1') {
      const oid = c["objectid"];
      const src = this.base.FindInstanceByID(oid);
      const rid = parent["objectid"];
      let trg: ISemTalkObject | null = null;
      const sla = this.base.FindInstanceByID(rid);
      if (sla !== null) {
        if (dis === null) dis = this.base.FindAssociationType(SemTalkBaseConstant.SLDisplays);
        if (dis !== null) {
          let lnks = sla.LinkedObjects(dis);
          if (lnks && lnks !== null && lnks.length === 1) {
            trg = lnks[0];
          }
        }
      }
      // let noev = this.noevents;
      // this.noevents = true;
      if (src && trg !== null) {
        let urel = this.base.GetModelAttribute(Process_ElementName.SLUses);
        let isinv = false;
        if (urel === undefined) {
          isinv = true;
          urel = this.base.GetModelAttribute(Process_ElementName.SLInvUses);
        }
        const ass = this.base.FindAssociationType(urel);
        if (ass)
          if (!isinv) {
            if (ass && !src.HasDirectLink(ass, trg)) {
              for (let lnk of src.Links(ass)) {
                if (lnk.ToObject.ObjectName !== trg.ObjectName) {
                  lnk.Delete();
                }
              }
              src.MakeAssociation(ass, trg);
            }
          } else {
            if (ass && !trg.HasDirectLink(ass, src)) {
              for (let lnk of src.InvLinks(ass)) {
                if (lnk.FromObject.ObjectName !== trg.ObjectName) {
                  lnk.Delete();
                }
              }
              trg.MakeAssociation(ass, src);
            }
          }
      }
      // this.noevents = noev;
    } else {
    }
  }
  public UpdateSwimlaneContent(shape: mxCell) {
    if (shape === null || shape === undefined) return;
    if (shape.children === null) return;
    let dis: ISemTalkAssociationType | null = this.base.FindAssociationType(SemTalkBaseConstant.SLDisplays);
    const rid = shape["objectid"];
    const sla = this.base.FindInstanceByID(rid);
    let trg: ISemTalkObject | null = null;
    if (sla !== null) {
      if (dis !== null) {
        let lnks = sla.LinkedObjects(dis);
        if (lnks && lnks !== null && lnks.length === 1) {
          trg = lnks[0];
        }
      }
    }
    const act = this.base.GetModelAttribute(Process_ElementName.SLActivity);
    let urel = this.base.GetModelAttribute(Process_ElementName.SLUses);
    let isinv = false;
    if (urel === undefined) {
      isinv = true;
      urel = this.base.GetModelAttribute(Process_ElementName.SLInvUses);
    }
    const ass = this.base.FindAssociationType(urel);
    if (ass)
      for (let c of shape.children) {
        if (c.isVertex())
          if (this.masterName(c.shapeKey) === act) {
            const oid = c["objectid"];
            const src = this.base.FindInstanceByID(oid);
            // let noev = this.noevents;
            // this.noevents = true;
            if (src && trg !== null) {
              if (!isinv) {
                if (ass && !src.HasDirectLink(ass, trg)) {
                  for (let lnk of src.Links(ass)) {
                    if (lnk.ToObject.ObjectName !== trg.ObjectName) {
                      lnk.Delete();
                    }
                  }
                  src.MakeAssociation(ass, trg);
                }
              } else {
                if (ass && !trg.HasDirectLink(ass, src)) {
                  for (let lnk of src.InvLinks(ass)) {
                    if (lnk.FromObject.ObjectName !== trg.ObjectName) {
                      lnk.Delete();
                    }
                  }
                  trg.MakeAssociation(ass, src);
                }
              }
            }
            // this.noevents = noev;
          }
      }
  }
  public visShapesMoved(shapes: mxCell[]): void {
    if (this.noevents) { return; }
    if (shapes === undefined) { return; }

    this.beginTransaction("visShapesMoved");
    this.shapesMovedRec(shapes);
    this.endTransaction("visShapesMoved");
  }
  private shapesMovedRec(shapes: mxCell[]) {
    let ass: ISemTalkAssociationType | null = null;
    let isinv = false;
    let dis: ISemTalkAssociationType | null = null;
    const actname = this.base.GetModelAttribute(Process_ElementName.SLActivity);
    // const act = this.base.FindSystemClass(actname);
    // if (act) {
    for (let c of shapes) {
      if (c.children) {
        this.shapesMovedRec(c.children);
      }
      if (c.isVertex())
        if (c.parent && c.parent.id !== '1') {
          let mname = this.masterName(c.shapeKey);
          if (mname === actname) {
            const oid = c["objectid"];
            const src = this.base.FindInstanceByID(oid);
            //  && src.IsInstance(act)
            if (src) {
              let rid = c.parent["objectid"];
              if (!rid && c.parent.parent) { rid = c.parent.parent["objectid"]; }
              if (!rid && c.parent.parent.parent) { rid = c.parent.parent.parent["objectid"]; }
              let trg: ISemTalkInstance | null = null;
              const sla = this.base.FindInstanceByID(rid);
              if (sla !== null) {
                if (dis === null) dis = this.base.FindAssociationType(SemTalkBaseConstant.SLDisplays);
                if (dis !== null) {
                  let lnks = sla.LinkedObjects(dis);
                  if (lnks && lnks !== null && lnks.length === 1) {
                    if (this.base.IsInstance(lnks[0])) {
                      trg = lnks[0] as ISemTalkInstance;
                    }
                  }
                }
              }
              // let noev = this.noevents;
              // this.noevents = true;
              if (src && trg !== null) {
                if (ass === null) {
                  let urel = this.base.GetModelAttribute(Process_ElementName.SLUses);
                  if (urel === undefined) {
                    isinv = true;
                    urel = this.base.GetModelAttribute(Process_ElementName.SLInvUses);
                  }
                  ass = this.base.FindAssociationType(urel);
                }
                if (ass)
                  if (!isinv) {
                    if (ass && !src.HasDirectLink(ass, trg)) {
                      for (let lnk of src.Links(ass)) {
                        if (lnk.ToObject.ObjectName !== trg.ObjectName) {
                          lnk.Delete();
                        }
                      }
                      if (src.IsValid(trg, ass)) {
                        src.MakeAssociation(ass, trg);
                      }
                    }
                  } else {
                    if (ass && !trg.HasDirectLink(ass, src)) {
                      for (let lnk of src.InvLinks(ass)) {
                        if (lnk.FromObject.ObjectName !== trg.ObjectName) {
                          lnk.Delete();
                        }
                      }
                      if (trg.IsValid(src, ass)) {
                        trg.MakeAssociation(ass, src);
                      }
                    }
                  }
              }
              // this.noevents = noev;
            } else {
            }
          }
        }
    }
    // }
  }
  public visShapesCopied(shapes: mxCell[]) {
    this.visShapesCloned(shapes);
  }
  public getMxId = (shape: mxCell): string => {
    if (shape === undefined) {
      // eslint-disable-next-line no-throw-literal
      throw ("getMxId");
    }
    if (shape.shapeid === undefined) {
      const g: string = Guid.create().toString();
      shape.shapeid = g;
      // return shape.mxObjectId;
    }
    return shape.shapeid;
  }
  public EnsureClass = (newid: string | null, mst: string, diagtype: ISemTalkDiagramType): ISemTalkClass => {
    if (newid === null) newid = this.base.NewID();
    let clobj = this.base.FindClassByID(newid);
    if (clobj) {
      return clobj;
    }
    let cname = mst + "." + newid;
    if (diagtype.Root !== null) {
      cname = diagtype.Root.GetPrefix() + "#" + cname;
      if (diagtype.Root.ObjectName === this.base.GetModelAttribute(Process_ElementName.SLInformation)) {
        clobj = this.base.MakeBusinessClass(cname, newid);
      } else {
        clobj = this.base.MakeClass(cname, newid);
      }
    } else {
      clobj = this.base.MakeClass(cname, newid);
    }
    if (diagtype.Root !== null) {
      if (!clobj.IsParent(diagtype.Root)) {
        clobj.AddSubclassOf(diagtype.Root);
      }
    }
    return clobj;
  }
  public EnsureSystemClass = (newid: string | null, mst: string, _diagtype: ISemTalkDiagramType): ISemTalkSystemClass => {
    if (newid === null) newid = this.base.NewID();
    let clobj = this.base.FindSystemClassByID(newid);
    if (clobj) {
      return clobj;
    }
    let cname = mst + "." + newid;
    return this.base.MakeSystemClass(cname, newid);
  }
  public EnsureInstance = (newid: string | null, cl: ISemTalkSystemClass, _mst: string, _subkey: string): ISemTalkInstance => {
    if (newid === null) newid = this.base.NewID();
    let inobj = this.base.FindInstanceByID(newid);
    if (inobj) {
      return inobj;
    }
    return this.base.MakeInstance(cl, cl.ObjectName + "." + newid, SemTalkType.SemTalkInstance, newid);
  }
  public EnsureAttributeType = (newid: string | null, mst: string): ISemTalkAttributeType => {
    if (newid === null) {
      newid = this.base.NewID();
    }
    //  else {
    //   let oldobj = this.base.FindObjectByID(newid);
    //   if (oldobj) {
    //     return (oldobj as ISemTalkAssociationType);
    //   }
    // }
    let cname = mst + "." + newid;
    let clobj = this.base.MakeAttributeType(cname, newid);
    return clobj;
  }
  public EnsureAssociationType = (newid: string | null, mst: string): ISemTalkAssociationType => {
    if (newid === null) {
      newid = this.base.NewID();
    }
    let cname = mst + "." + newid;
    let clobj = this.base.MakeAssociationType(SemTalkRelation.SemTalkProperty, cname, newid);
    return clobj;
  }
  public EnsureDiagramType = (newid: string | null, mst: string): ISemTalkDiagramType => {
    if (newid === null) {
      newid = this.base.NewID();
    }
    let cname = mst + "." + newid;
    let clobj = this.base.MakeDiagramType(cname, newid);
    return clobj;
  }
  private findNode = (obj: ISemTalkObject, pg: ISemTalkDiagram, shape: mxCell): ISemTalkNode | null => {
    let nd: ISemTalkNode | null = null;
    let nodes = obj.FindNode(pg, this.getMxId(shape));
    if (nodes.length > 0) {
      nd = nodes[0];
    }
    return nd;
  }
  public visShapeAdded = (shape: mxCell, newid: string | null): ISemTalkNode | null => {
    if (this.noevents) { return null; }
    let nd: ISemTalkNode | null = null;
    if (shape.shapeKey === undefined && shape.shapeName === undefined) {
      return null;
    }
    if (shape.shapeKey === SemTalkMaster.MasterSwimlane && shape.parent) {
      if (shape.parent.shapeKey === SemTalkMaster.MasterSwimlane) {
        if (shape.parent.children.length > 1) {
          let slss = shape.parent.children.filter((x: any) => x.shapeKey === SemTalkMaster.MasterSwimlane);
          if (slss.length === 1) {
            this.alert("There are Shapes on the pool shape. Move them to the new lane shape. Next time you might create the pool on the background and move the lane onto the pool!", MessageBarType.info);
          }

        }
      }
    }
    if (this.page !== null && this.page !== undefined) {
      const diagtype = this.page.ClassOf();
      // let shapename = shape.shapeName;
      // if (shapename === null) {
      //   shapename = shape.shapeKey;
      // }
      // let mst: string = this.masterName(shapename);
      let shapekey = shape.shapeKey;
      if (shapekey === null) {
        shapekey = shape.shapeName;
      }
      let mst: string = this.masterName(shapekey);
      const clist = diagtype.FindMasters(mst);
      if (mst === this.base.GetModelAttribute(ModelAttribute.SLComment) ||
        mst === SemTalkMaster.MasterAttachment) {
        let c = this.base.FindSystemClass(mst);
        if (c) clist.push(c);
      }
      let mst1 = mst;
      if (mst === SemTalkMaster.MasterUMLClass) {
        mst = SemTalkMaster.MasterClass;
      }
      if (clist.length < 1) {
        // this.graph.removeCells([shape], true);
        if (mst === SemTalkMaster.MasterClass ||
          mst === SemTalkMaster.MasterSystemClass ||
          mst === SemTalkMaster.MasterUMLClass ||
          mst === SemTalkMaster.MasterAttributeType ||
          mst === SemTalkMaster.MasterAssociationType ||
          mst === SemTalkMaster.MasterDiagramType ||
          mst === SemTalkMaster.MasterInstance) {
        } else {
          // this.alert("'" + mst + this.getResStrListener(ResIDL.STRNOTALLOWED2") + diagtype.ObjectCaption, MessageBarType.warning);
          return null;
        }
      }
      // shape.shapeid = this.getMxId(shape);

      this.beginTransaction("visShapeAdded");
      let obj: ISemTalkObject | null = null;
      // console.debug(shape);

      switch (mst) {
        case SemTalkMaster.MasterClass: {
          if (shape.objectid) {
            obj = this.base.FindObjectByID(shape.objectid);
          }
          if (!obj) {
            obj = this.EnsureClass(newid, mst, diagtype);
            shape.objectid = obj.ID;
          }
          nd = this.findNode(obj, this.page, shape);
          if (!nd) {
            nd = this.base.MakeNode(this.page, obj, this.getMxId(shape));
          }
          nd.Master = mst1;
          nd.X = Math.round(shape.geometry.x);
          nd.Y = Math.round(shape.geometry.y);
          break;
        }
        case SemTalkMaster.MasterSystemClass: {
          if (shape.objectid) {
            obj = this.base.FindObjectByID(shape.objectid);
          }
          if (!obj) {
            obj = this.EnsureSystemClass(newid, mst, diagtype);
            shape.objectid = obj.ID;
          }
          nd = this.findNode(obj, this.page, shape);
          if (!nd) {
            nd = this.base.MakeNode(this.page, obj, this.getMxId(shape));
          }
          nd.Master = mst1;
          nd.X = Math.round(shape.geometry.x);
          nd.Y = Math.round(shape.geometry.y);
          break;
        }
        case SemTalkMaster.MasterAttributeType: {
          if (shape.objectid) {
            obj = this.base.FindObjectByID(shape.objectid);
          }
          if (!obj) {
            obj = this.EnsureAttributeType(newid, mst);
          }
          shape.objectid = obj.ID;
          nd = this.findNode(obj, this.page, shape);
          if (!nd) {
            nd = this.base.MakeNode(this.page, obj, this.getMxId(shape));
          }
          nd.Master = mst1;
          nd.X = Math.round(shape.geometry.x);
          nd.Y = Math.round(shape.geometry.y);
          break;
        }
        case SemTalkMaster.MasterAssociationType: {
          if (shape.objectid) {
            obj = this.base.FindObjectByID(shape.objectid);
          }
          if (!obj) {
            obj = this.EnsureAssociationType(newid, mst);
            shape.objectid = obj.ID;
          }
          nd = this.findNode(obj, this.page, shape);
          if (!nd) {
            nd = this.base.MakeNode(this.page, obj, this.getMxId(shape));
          }
          nd.Master = mst1;
          nd.X = Math.round(shape.geometry.x);
          nd.Y = Math.round(shape.geometry.y);
          break;
        }
        case SemTalkMaster.MasterDiagramType: {
          if (shape.objectid) {
            obj = this.base.FindObjectByID(shape.objectid);
          }
          if (!obj) {
            obj = this.EnsureDiagramType(newid, mst);
            shape.objectid = obj.ID;
          }
          nd = this.findNode(obj, this.page, shape);
          if (!nd) {
            nd = this.base.MakeNode(this.page, obj, this.getMxId(shape));
          }
          nd.Master = mst1;
          nd.X = Math.round(shape.geometry.x);
          nd.Y = Math.round(shape.geometry.y);
          break;
        }
        case SemTalkMaster.MasterInstance: {
          let thing = this.base.FindSystemClass(SemTalkBaseConstant.SLThing);
          if (thing) {
            if (shape.objectid) {
              obj = this.base.FindObjectByID(shape.objectid);
            }
            if (!obj) {
              obj = this.EnsureInstance(newid, thing, mst, shape.shapeSubkey);
              shape.objectid = obj.ID;
            }
            if (thing.Style) {
              shape.style = thing.Style;
            }
            nd = this.findNode(obj, this.page, shape);
            if (!nd) {
              nd = this.base.MakeNode(this.page, obj, this.getMxId(shape));
            }
            nd.Master = mst;
            nd.X = Math.round(shape.geometry.x);
            nd.Y = Math.round(shape.geometry.y);
          }
          break;
        }
        default: {
          const cl = clist[0];
          if (shape.objectid === undefined && cl !== null) {
            if (newid !== null) {
              obj = this.base.FindObjectByID(newid);
            }
            if (!obj) {
              obj = this.EnsureInstance(newid, cl, mst, shape.shapeSubkey);
            }
            shape.objectid = obj.ID;
            if (!nd) {
              nd = this.base.MakeNode(this.page, obj, this.getMxId(shape));
            }
            nd.Master = mst;
            nd.X = Math.round(shape.geometry.x);
            nd.Y = Math.round(shape.geometry.y);
            // return nd;

            let isact = (cl.ObjectName === this.base.GetModelAttribute(Process_ElementName.SLActivity));
            if (isact && this.page) {
              const un = this.page.NewUserNumber();
              if (un.length > 0) {
                obj.SetValue(SemTalkBaseConstant.SLUserNumber, un);
              }
              this.EnsureUses(shape);
            }
          } else {
            // copy
            obj = this.base.FindObjectByID(shape.objectid);
            if (obj !== null) {
              // shape.shapeid = this.getMxId(shape);
              nd = this.base.MakeNode(this.page, obj, this.getMxId(shape));
              nd.Master = mst;
              nd.X = Math.round(shape.geometry.x);
              nd.Y = Math.round(shape.geometry.y);
              // return nd;
              let isact = (cl.ObjectName === this.base.GetModelAttribute(Process_ElementName.SLActivity));
              if (isact && this.page) {
                const un = this.page.NewUserNumber();
                if (un.length > 0) {
                  obj.SetValue(SemTalkBaseConstant.SLUserNumber, un);
                }
                // this.visShapesMoved([shape]);
              }
            }
          }
        }
      }
      if (obj !== null) {
        // this.vispage.labelChanged(shape, obj.ID2Name, null);
        if (this.tseditor) {
          this.tseditor.beginUpdate();
        }
        this.UpdateLabel(obj);
        if (this.tseditor) {
          this.tseditor.endUpdate();
          this.tseditor.refreshCell(shape);
        }
        this.base.PostEvent(SemTalkNavigationEvent.gotoObject, obj);
      }
      this.endTransaction("visShapeAdded");
    }
    return nd;
  }
  public makeInstance = (class_cell: mxCell, shape: mxCell) => {
    if (this.page !== null && this.page !== undefined) {
      let clid = class_cell.objectid;
      let cl: ISemTalkClass | null = this.base.FindClassByID(clid);
      let rel: ISemTalkAssociation | null = null;
      if (cl) {
        this.beginTransaction("makeInstance");
        const inst = cl.MakeInstance();
        let rt = this.base.FindAssociationType(SemTalkBaseConstant.SLInstanceOf);
        if (rt) {
          rt.RelationType = SemTalkRelation.SemTalkInstanceOf;
          rel = inst.MakeAssociation(rt, cl, SemTalkRelation.SemTalkInstanceOf);
        }
        shape.objectid = inst.ID;
        let sysc = cl.SystemClass();
        if (sysc && sysc.Style) {
          shape.style = sysc.Style;
        } else {
          let thing = cl.ObjectBase.FindSystemClass(SemTalkBaseConstant.SLThing);
          if (thing && thing.Style.length > 0) {
            shape.style = thing.Style;
          } else {
            shape.style = this.GetShapeStyle(SemTalkMaster.MasterInstance, SemTalkShapeType.generic).style;
          }

        }
        // shape.shapeid = this.getMxId(shape);
        this.base.MakeNode(this.page, inst, this.getMxId(shape), SemTalkMaster.MasterInstance);
        shape.value = inst.ID2NameNsp();
        this.UpdateLabel(inst);
        if (rel) this.ExpandObject(shape, [rel]);
        this.endTransaction("makeInstance");
      }
    }
  }
  public makeSubClass = (class_cell: mxCell, shape: mxCell) => {
    if (this.page !== null && this.page !== undefined) {
      const clid = class_cell.objectid;
      const cl: ISemTalkClass | null = this.base.FindClassByID(clid);
      if (this.page !== null && this.page !== undefined && cl) {
        this.beginTransaction("makeSubClass");
        const diagtype = this.page.ClassOf();
        const newid: SemTalkID = this.base.NewID();
        let cname = SemTalkBaseConstant.SLClass + "." + newid;
        let clobj: ISemTalkClass;
        if (diagtype.Root !== null) {
          cname = diagtype.Root.GetPrefix() + "#" + cname;
          if (diagtype.Root.ObjectName === this.base.GetModelAttribute(Process_ElementName.SLInformation)) {
            clobj = this.base.MakeBusinessClass(cname, newid);
          } else {
            clobj = this.base.MakeClass(cname, newid);
          }
        } else {
          clobj = this.base.MakeClass(cname, newid);
        }
        if (cl) {
          if (!clobj.IsParent(cl)) {
            clobj.AddSubclassOf(cl);
          }
        }
        shape.objectid = clobj.ID;
        // shape.shapeid = this.getMxId(shape);
        this.base.MakeNode(this.page, clobj, this.getMxId(shape), SemTalkMaster.MasterClass);
        shape.value = clobj.ID2NameNsp();
        this.UpdateLabel(clobj);
        let rel = clobj.FindSpecialization(cl);
        if (rel) this.ExpandObject(shape, [rel]);
        this.endTransaction("makeSubClass");
      }
    }
  }
  public DeleteNode(obj: ISemTalkObject, shapeid: string): void {
    let alist: ISemTalkNode[];
    if (this.page) {
      alist = obj.FindNode(this.page, shapeid);
      if (alist.length > 0) {
        alist[0].Delete();
      }
    }
  }
  public visShapesDeleted(shapes: mxCell[]): void {
    if (this.noevents) { return; }
    if (shapes === undefined) { return; }

    this.beginTransaction("visShapesDeleted");
    if (shapes.length === 3) {
      if (shapes[0].vertex && shapes[1].edge && shapes[2].edge) {
        let o0 = shapes[0];
        let lnk1 = shapes[1];
        let lnk2 = shapes[2];
        let rel1 = this.base.FindInstanceByID(lnk1.objectid);
        let rel2 = this.base.FindInstanceByID(lnk2.objectid);
        if (rel1 && rel2) {
          let crel1 = rel1.ClassOf().ObjectName;
          let crel2 = rel2.ClassOf().ObjectName;
          let sf = this.base.GetModelAttribute(Process_ElementName.SLControl);
          let sof = SemTalkBaseConstant.SLSubClassOf;
          if ((crel1 === sf && crel2 === sf) || (crel1 === sof && crel2 === sof)) {
            let horiz = this.tseditor.isGraphHorizontal();

            let o1 = lnk1.source;
            let inv = false;
            let invrel = false;
            if (o1 === o0) {
              o1 = lnk1.target;
              inv = true;
            }
            let o2 = lnk2.target;
            if (o2 === o0) {
              o2 = lnk2.source;
              inv = true;
            }
            if (crel1 === sof) {
              horiz = true;
              // inv = !inv;
              invrel = true;
            }

            let s = lnk2.style;
            this.shapesDeletedRec(shapes);
            if (inv) {
              let lnk3 = this.createEdge(o2, o1, s);
              this.visConnectionAdded(undefined, lnk3, o2, o1);
              this.tseditor.unSplit(o2, o1, horiz, invrel);

            } else {
              let lnk3 = this.createEdge(o1, o2, s);
              this.visConnectionAdded(undefined, lnk3, o1, o2);
              this.tseditor.unSplit(o1, o2, horiz, invrel);
            }
          }
        }
      }
    }
    this.shapesDeletedRec(shapes);
    this.endTransaction("visShapesDeleted");
  }

  private shapesDeletedRec(shapes: mxCell[]): void {
    for (const shape of shapes) {
      if (shape.children) {
        this.shapesDeletedRec(shape.children);
      }
      if (shape.objectid !== undefined) {
        // copy
        const sid: any = this.getMxId(shape);
        const obj = this.base.FindObjectByID(shape.objectid);
        if (obj !== null) {
          this.DeleteNode(obj, sid);
          if (this.base.IsAssociation(obj)) {
            const r = obj as ISemTalkAssociation;
            if (this.base.IsInstance(r.FromObject)) {
              const sc = (r.FromObject as ISemTalkInstance).ClassOf().SystemClass();
              if (sc !== null && sc.OnceOnly === true) {
                obj.Delete();
              }
            }
            if (this.base.IsInstance(r.ToObject)) {
              const dsc = (r.ToObject as ISemTalkInstance).ClassOf().SystemClass();
              if (dsc !== null && dsc.OnceOnly === true) {
                obj.Delete();
              }
            }
          } else {
            if (obj.Nodes().length === 0) {
              if (obj.ObjectName === obj.SystemClass()?.ObjectName + "." + obj.ID) {
                if (this.base.IsInstance(obj)) {
                  obj.Delete();
                }
              }
            }
          }
        }
      }
    }
    // this.endTransaction("visShapesDeleted");
  }
  // public EnsureRelation(ins: ISemTalkInstance, scl: ISemTalkClass, label: string, relt: ISemTalkAssociationType) {
  //   if (label.indexOf(".") > 0) {
  //     label = label.substring(0, label.indexOf("."));
  //   }
  //   const nsp = scl.GetPrefix();
  //   const cname = nsp + "#" + label;
  //   let cl = this.base.FindClass(cname);
  //   if (cl === null) {
  //     cl = this.base.MakeClass(cname);
  //   }
  //   if (cl !== null) {
  //     if (!cl.IsParent(scl)) {
  //       cl.AddSubclassOf(scl);
  //     }
  //     if (!ins.HasDirectLink(relt, cl)) {
  //       ins.MakeAssociation(relt, cl);
  //     }
  //   }
  // }
  public EnsureBORelation(ins: ISemTalkInstance, label: string, unique: boolean): void {
    // if (label.indexOf(".") > 0) {
    //   label = label.substring(0, label.indexOf("."));
    // }
    let info = this.base.GetModelAttribute(Process_ElementName.SLInformation);
    let scl = this.base.FindSystemClass(info);
    if (scl !== null) {
      let rel = this.base.GetModelAttribute(Process_ElementName.SLInfoType);
      let relt = this.base.FindAssociationType(rel);
      if (relt !== null) {
        const nsp = scl.GetPrefix();
        const cname = nsp + "#" + label;
        let lnks = ins.Links(relt);
        let cl = this.base.FindBusinessClass(cname);
        if (lnks.length === 1 && cl === null) {
          let icl = lnks[0].ToObject;
          const currnsp = this.base.CurrentNsp;
          let oldsynname = "";
          // if (oldsyn) oldsynname = oldsyn.Name;
          for (let syn of icl.Synonyms()) {
            if (syn.Name === icl.ID2Name && syn.Language === currnsp) {
              oldsynname = syn.Name;
            }
          }
          if (icl.ID2Name === oldsynname) {
            if (icl.InvLinks(relt).length === 1) {
              icl.RenameObject(cname);
              this.UpdateLabel(ins);
              return;
            }
          } else {
            let syn = icl.FindSynonym(currnsp);
            let oldid2name = icl.ID2Name;
            let oldname = icl.ObjectName;
            if (syn && (!syn.Synonym || (syn.Name === oldname || syn.Name === oldid2name))) {
              icl.DeleteSynonym(currnsp);
              icl.MakeSynonym(label, currnsp);
            } else {
              icl.MakeSynonym(label, currnsp);
            }
            this.UpdateLabel(ins);
            return;
          }
        }
        if (cl === null) {
          cl = this.base.MakeBusinessClass(cname);
        }
        if (cl !== null) {
          if (!cl.IsParent(scl)) {
            cl.AddSubclassOf(scl);
          }
          if (unique) {
            for (const lnk of ins.Links(relt)) {
              if (lnk.ToObject.ObjectName !== cl.ObjectName) {
                ins.DeleteAssociation(relt, lnk.ToObject);
              }
            }
          }
          if (!ins.HasDirectLink(relt, cl)) {
            ins.MakeAssociation(relt, cl);
          }
        }
      }
    }
  }
  public RenameDiagram(obj: ISemTalkDiagram, text: string) {
    if (obj.ObjectCaption === text) {
      obj.RenameObject(text);
      return;
    }
    this.beginTransaction("RenameDiagram");
    let nsp = obj.ID2Namespace;
    if (text.indexOf("#") < 0 && nsp.length > 0) {
      obj.RenameObject(nsp + "#" + text);
    } else {
      obj.RenameObject(text);
    }
    this.endTransaction("RenameDiagram");
    this.updateDiaglist();
  }
  public RenameObject(obj: ISemTalkObject, text: string, shape: mxCell | null) {
    this.beginTransaction("RenameObject");
    const ob = this.base;
    text = text.replace("\\n", "\n");
    // text = text.replace("\n","");

    // Unicode HTML    Description Example
    // https://stackoverflow.com/questions/65074277/how-to-use-invisible-character-to-split-a-text-and-how-to-get-its-js-representa

    // text= text.replace(" ", " ");
    text = text.replace("\u00a0", " ");
    text = text.trim();
    const currnsp = ob.CurrentNsp;
    if (ob.IsAssociation(obj)) {
      const ins = obj as ISemTalkAssociation;
      const rcl = ins.ClassOf();
      const scl = rcl.SystemClass();
      // let lbl: labels.SemTalkLabel;
      const src = ins.FromObject;
      const dst = ins.ToObject;
      let isthing = false;
      if (ob.IsInstance(src) && (src as ISemTalkInstance).ClassOf().ObjectName === SemTalkBaseConstant.SLThing) {
        isthing = true;
      } else {
        if (ob.IsInstance(dst) && (dst as ISemTalkInstance).ClassOf().ObjectName === SemTalkBaseConstant.SLThing) {
          isthing = true;
        }
      }
      if (isthing || (ob.IsClass(src) && ob.IsClass(dst))) {
        if (text.length > 0) {
          let relt = ob.FindAssociationType(text);
          if (relt === null) {
            relt = ob.MakeAssociationType(SemTalkRelation.SemTalkProperty, text);
          }
          ins.SetClass(relt);
          // ins.RenameObject(relt.ObjectName + "." + ins.ID);
          if (shape) {
            shape.style = relt.Style;
            if (shape.style === "") {
              shape.style = SemTalkStyleAttribute.shape + "=loopRelationEdgeStyle;" +
                SemTalkStyleAttribute.endArrow + "=classic;html=1;" +
                SemTalkStyleAttribute.fillColor + "=#ffffff;" +
                SemTalkStyleAttribute.strokeWidth + "=2;";
            }
          }
          if (this.page) {
            let diagtype = this.page.ClassOf();
            if (diagtype.Root !== null) {
              if (!(src as ISemTalkClass).IsParent(diagtype.Root)) {
                (src as ISemTalkClass).AddSubclassOf(diagtype.Root);
              }
            }
          }
        } else {
          let relt = ob.FindAssociationType(SemTalkBaseConstant.SLSubClassOf);
          if (relt) {
            ins.SetClass(relt);
            ins.RenameObject(relt.ObjectName + "." + ins.ID);
          }
        }
        this.UpdateLabel(ins);
      } else {
        if (scl !== null && scl.AllInstanceLabels().length === 1) {
          let lbl = scl.AllInstanceLabels()[0];
          const labelname = lbl.Text.substr(0, lbl.Text.indexOf("{"));
          if (labelname === ob.GetModelAttribute(Process_ElementName.SLInfoType)) {
            this.EnsureBORelation(ins, text, true);
            this.UpdateLabel(obj);
            if (this.tseditor) this.tseditor.refresh();
            this.endTransaction("RenameObject");
            return;
          }
          const attrt = ob.FindAttributeType(labelname);
          if (attrt !== null) {
            if (obj.GetValue(labelname) !== text) {
              obj.SetValue(labelname, text);
              this.UpdateLabel(obj);
            }
          }
        }
      }
      if (this.tseditor) {
        if (shape) {
          this.tseditor.refreshCell(shape);
        } else {
          this.refreshShapes(obj);
        }
      }
      this.endTransaction("RenameObject");
      return;
    }
    if (this.base.IsInstance(obj)) {
      // if (obj.ObjectCaption === text) {
      //   this.UpdateLabel(obj);
      //   this.refreshShapes(obj);
      //   // if (this.tseditor) this.tseditor.refresh();
      //   this.endTransaction("RenameObject");
      //   this.base.PostEvent(SemTalkNavigationEvent.gotoObject, obj);
      //   return;
      // }
      const ins = obj as ISemTalkInstance;
      const icl = ins.ClassOf() as ISemTalkClass;
      const scl = icl.SystemClass();
      let nsp = obj.ID2Namespace;
      if (nsp.length > 0) {
        nsp = nsp + "#";
      } else {
        if (scl && scl.Prefix) {
          nsp = scl.Prefix + "#";
        }
      }
      let oth = this.base.FindInstance(nsp + text);
      if (oth !== null && scl !== null && !oth.IsInstance(scl)) {
        oth = null;
      }
      // if (text.indexOf(".") > text.indexOf("#")) {
      //   text = text.substring(0, text.indexOf("."));
      // }
      if (oth === null) {
        if (scl !== null && scl.BottomUp) {
          if (icl.ObjectName === scl.ObjectName) {
            const nsp0 = scl.GetPrefix();
            const cname = nsp0 + "#" + text;
            let cl = this.base.FindClass(cname);
            if (cl === null) {
              cl = this.base.MakeClass(cname);
            }
            if (cl !== null) {
              if (!cl.IsParent(scl)) {
                cl.AddSubclassOf(scl);
              }
              if (ins.ClassOf() !== cl) {
                ins.SetClass(cl);
              }
            }
            if (obj.ObjectName !== text + "." + obj.ID) {
              obj.RenameObject(text + "." + obj.ID);
              this.UpdateLabel(obj);
            }
            if (icl !== cl && icl !== scl && icl.Instances().length === 0) {
              icl.Delete();
            }
          } else {
            // const oldsyn = icl.FindSynonym(currnsp);
            let oldsynname = "";
            // if (oldsyn) oldsynname = oldsyn.Name;
            for (let syn of icl.Synonyms()) {
              if (syn.Name === icl.ID2Name && syn.Language === currnsp) {
                oldsynname = syn.Name;
              }
            }
            if (icl.ID2Name === oldsynname) {
              const nsp0 = scl.GetPrefix();
              let newcla = nsp0 + "#" + text;
              if (icl.Instances().length < 2 && icl.Composition() === null &&
                ob.FindClass(newcla) === null) {
                icl.RenameObject(newcla);
              } else {
                const cname = newcla;
                let cl = this.base.FindClass(cname);
                if (cl === null) {
                  cl = this.base.MakeClass(cname);
                }
                if (cl !== null) {
                  if (!cl.IsParent(scl)) {
                    cl.AddSubclassOf(scl);
                  }
                  if (ins.ClassOf() !== cl) {
                    ins.SetClass(cl);
                  }
                }
                if (obj.ObjectName !== text + "." + obj.ID) {
                  obj.RenameObject(text + "." + obj.ID);
                  this.UpdateLabel(obj);
                }
                if (icl !== cl && icl !== scl && icl.Instances().length === 0) {
                  icl.Delete();
                }
              }
            } else {
              let syn = icl.FindSynonym(currnsp);
              let oldid2name = icl.ID2Name;
              let oldname = icl.ObjectName;
              // if (syn)
              //     console.debug(oldname + " " + newname + " " + syn.Name);
              if (syn && (!syn.Synonym || (syn.Name === oldname || syn.Name === oldid2name))) {
                icl.DeleteSynonym(currnsp);
                icl.MakeSynonym(text, currnsp);
              } else {
                icl.MakeSynonym(text, currnsp);
              }
              this.UpdateLabel(obj);
            }
          }
        } else {
          let lbl: ISemTalkLabel | null = null;
          if (scl !== null) {
            if (scl.AllInstanceLabels().length > 0) {
              lbl = scl.AllInstanceLabels()[0];
            }
            else {
              for (const scc of scl.AllSuperClasses()) {
                if (lbl === null) {
                  const slbls = (scc as ISemTalkSystemClass).AllInstanceLabels();
                  if (slbls.length > 0) {
                    lbl = slbls[0];
                  }
                }
              }
            }

            if (lbl !== null) {
              // console.debug("Label: " + lbl);
              let relname = lbl.Text;
              if (lbl.Text.indexOf("{") > -1) {
                relname = lbl.Text.substr(0, lbl.Text.indexOf("{"));
              }
              if (relname.indexOf(" (") > 0) {
                relname = relname.substr(0, relname.indexOf(" ("));
              }
              const relt = this.base.FindAssociationType(relname);
              // console.debug(slinfotype);
              if (relt !== null) {
                const olist = scl.AllLinkedObjects(relname);
                if (olist.length > 0) {
                  const slinfotype = this.base.GetModelAttribute(Process_ElementName.SLInfoType);
                  const ocl = olist[0] as ISemTalkSystemClass;
                  if (ocl.ObjectName === this.base.GetModelAttribute(Process_ElementName.SLInformation) && relname === slinfotype) {
                    this.EnsureBORelation(obj as ISemTalkInstance, text, true);
                  } else {
                    const oname = text;
                    let othc = this.base.FindInstance(oname);
                    if (othc === null) {
                      othc = this.base.MakeInstance(ocl, oname, SemTalkType.SemTalkInstance);
                    } else {
                      // this.alert(this.getResStrListener(ResIDL.STRERREXISTS) + ": " + oname, MessageBarType.error);
                      // this.UpdateLabel(obj);
                      // if (this.tseditor) this.tseditor.refresh();
                      // this.endTransaction("RenameObject");
                      // return;
                    }
                    if (!othc.IsInstance(ocl)) {
                      if (othc.ObjectType !== SemTalkType.SemTalkDiagram) {
                        this.alert(this.getResStrListener(ResIDL.STRERREXISTS) + ": " + oname, MessageBarType.error);
                        this.UpdateLabel(obj);
                        if (this.tseditor) this.tseditor.refresh();
                        this.endTransaction("RenameObject");
                        return;
                        //  throw new Error(this.getResStrListener(ResIDL.STRERREXISTS") + ": " + oname);
                      }
                    }
                    for (const lnk of obj.Links(relt)) {
                      if (lnk.ToObject.ObjectName !== othc.ObjectName) {
                        obj.DeleteAssociation(relt, lnk.ToObject);
                      }
                    }
                    if (!obj.HasDirectLink(relt, othc)) {
                      obj.MakeAssociation(relt, othc);
                    }

                  }
                  this.UpdateLabel(obj);
                  console.assert(ocl !== null, "Single Relation found");
                }
                // tslint:disable-next-line:no-console
                if (this.tseditor) this.tseditor.refresh();
                this.endTransaction("RenameObject");
                return;
              }
            }
          }

          if (obj.ObjectName !== nsp + text) {
            const lang = GetLanguage(this.base.GetModelAttribute(ModelAttribute.currentnsp));
            if (lang) {
              let syn = obj.FindSynonym(lang);
              let oldid2name = obj.ID2Name;
              let oldname = obj.ObjectName;
              // if (syn)
              //     console.debug(oldname + " " + newname + " " + syn.Name);
              if (syn && (!syn.Synonym || (syn.Name === oldname || syn.Name === oldid2name))) {
                obj.DeleteSynonym(lang);
                obj.MakeSynonym(text, lang);
              } else {
                obj.MakeSynonym(text, lang);
              }
              // eigentlich nur dann wenn obj.ObjectName === obj.ObjectCaption
              if (!this.base.FindObject(obj.ObjectType, nsp + text)) {
                obj.RenameObject(text);
                this.UpdateLabel(obj);
              }
            }
          }
        }
      } else {
        if (obj.ObjectType !== oth.ObjectType) {
          //  alert("There is a different object named: " + label);
        } else {
          if (shape && obj.ID !== oth.ID && this.page) {
            const shapeid = this.getMxId(shape);
            let noev = this.noevents;
            this.noevents = true;
            this.MoveEdgesToShape(shape, obj, oth);
            this.DeleteNode(obj, shapeid);
            if (obj.ObjectName === scl?.ObjectName + "." + obj.ID) {
              obj.Delete();
            }
            obj = oth;
            shape.objectid = oth.ID;
            // shape.shapeid = this.getMxId(shape);
            this.base.MakeNode(this.page, obj, this.getMxId(shape));
            this.UpdateLabel(obj);
            this.noevents = noev;
          }
        }
      }
    } else {
      // const lang: string = this.base.GetModelAttribute(ModelAttribute.currentnsp);

      let oldsynname = "";
      // if (oldsyn) oldsynname = oldsyn.Name;
      for (let syn1 of obj.Synonyms()) {
        if (syn1.Name === obj.ID2Name && syn1.Language === currnsp) {
          oldsynname = syn1.Name;
        }
      }
      let oldid2name = obj.ID2Name;
      let oldname = obj.ObjectName;
      if (obj.ID2Name === oldsynname || oldsynname === '' || obj.ID2Name.indexOf(".") > 0) {
        let nsp = obj.ID2Namespace;
        let newname = text;
        if (nsp.length > 0 && text.indexOf("#") < 0) {
          newname = nsp + "#" + text;
        }
        if (this.base.FindClass(newname) === null &&
          this.base.FindInstance(newname) === null &&
          this.base.FindDiagram(newname) === null &&
          this.base.FindAttributeType(newname) === null &&
          this.base.FindMethodType(newname) === null &&
          this.base.FindStateType(newname) === null &&
          this.base.FindAssociationType(newname) === null
        ) {
          let syn = obj.FindSynonym(currnsp);
          // if (syn)
          //     console.debug(oldname + " " + newname + " " + syn.Name);
          if (syn && (!syn.Synonym || (syn.Name === oldname || syn.Name === oldid2name))) {
            obj.DeleteSynonym(currnsp);
            obj.MakeSynonym(text, currnsp);
          } else {
            obj.MakeSynonym(text, currnsp);
          }
          obj.RenameObject(newname);
        } else {
          this.alert(this.getResStrListener(ResIDL.SRUNIFY1) + newname, MessageBarType.info);
          this.UpdateLabel(obj);
          if (this.tseditor) this.tseditor.refresh();
          this.endTransaction("RenameObject");
          return;
        }
      }
    }
    if (this.tseditor) this.refreshShapes(obj);
    this.endTransaction("RenameObject");
    if (obj !== null) {
      this.base.PostEvent(SemTalkNavigationEvent.gotoObject, obj);
    }
  }

  private refreshShapes(obj: ISemTalkObject): void {
    if (this.tseditor) {
      for (let nd of obj.Nodes()) {
        if (nd.Diagram === this.page) {
          let shp = this.FindShapeByShapeID(nd.ShapeID);
          if (shp) {
            this.tseditor.refreshCell(shp);
          }
        }
      }
    }
  }
  private refreshShapesByID(id: SemTalkID): void {
    if (this.tseditor) {
      let obj = this.base.FindObjectByID(id);
      if (obj) {
        for (let nd of obj.Nodes()) {
          if (nd.Diagram === this.page) {
            let shp = this.FindShapeByShapeID(nd.ShapeID);
            if (shp) {
              this.tseditor.refreshCell(shp);
            }
          }
        }
      }
    }
  }

  public NextUserNumber(u: any, pg: ISemTalkDiagram | null): string {
    let n = 1;
    let s = "";
    if (u !== undefined && u !== null) {
      let us = u as string;
      if (us.indexOf(".") > 0) {
        if (s === "") {
          s = us.substr(0, us.lastIndexOf(".") + 1);
        }
        us = us.substr(us.lastIndexOf(".") + 1);
      }
      const ui = Number(us) + 1;
      if (ui > n) {
        n = ui;
      }
    }
    if (pg && s === "") {
      for (const o of pg.RefinedObjects()) {
        const u2 = o.GetValue(SemTalkBaseConstant.SLUserNumber);
        if (u2 !== undefined) {
          s = u2 + ".";
        }
      }
    }
    s = s + String(n);
    return s;
  }
  public RenumberObject(obj: ISemTalkInstance, visited: ISemTalkObject[], un: any): { "visited": ISemTalkObject[], "un": any } {
    let ctrl = obj.ObjectBase.FindAssociationType(obj.ObjectBase.GetModelAttribute(Process_ElementName.SLControl));
    let mflo = obj.ObjectBase.FindAssociationType(obj.ObjectBase.GetModelAttribute(Process_ElementName.SLMessageFlow));
    let act = obj.ObjectBase.FindSystemClass(obj.ObjectBase.GetModelAttribute(Process_ElementName.SLActivity));
    let nodes = obj.Nodes();
    let pg: ISemTalkDiagram | null = null;
    if (nodes.length > 0) {
      pg = nodes[0].Diagram;
    }
    let un1 = obj.GetValue(SemTalkBaseConstant.SLUserNumber);
    if (un1) {
      un = un1;
    }
    if (ctrl && act) {
      for (let lo of obj.LinkedObjects(ctrl)) {
        if (visited.indexOf(lo) > -1) continue;
        visited.push(lo);
        if (lo.ObjectType === SemTalkType.SemTalkInstance) {
          let ins = lo as ISemTalkInstance;
          if (ins.IsInstance(act)) {
            un = this.NextUserNumber(un, pg);
            // console.debug(ins.ClassOf().ObjectName, un);
            ins.SetValue(SIM_AttributeTypeName.UserNumber, un);
          }
          let res = this.RenumberObject(lo as ISemTalkInstance, visited, un);
          un = res["un"];
          visited = res["visited"];
        }
      }
    }
    if (mflo && act) {
      for (let lo of obj.LinkedObjects(mflo)) {
        if (visited.indexOf(lo) > -1) continue;
        visited.push(lo);
        if (lo.ObjectType === SemTalkType.SemTalkInstance) {
          let ins = lo as ISemTalkInstance;
          if (ins.IsInstance(act)) {
            un = this.NextUserNumber(un, pg);
            // console.debug(ins.ClassOf().ObjectName, un);
            ins.SetValue(SIM_AttributeTypeName.UserNumber, un);
          }
          let res = this.RenumberObject(lo as ISemTalkInstance, visited, un);
          un = res["un"];
          visited = res["visited"];
        }
      }
    }
    return { "visited": visited, "un": un };
  }


  public ChangeObject = (shape: mxCell, newobjs: ISemTalkObject[]) => {
    if (newobjs.length > 1) return;
    let newobj = newobjs[0];
    // this.beginTransaction("", "ChangeObject");
    const semtalk = this;
    if (semtalk.noevents) { return; }
    if (shape === null) { return; }
    //const sc = this.visCellSystemClass(shape);
    const oid = shape.objectid;
    const bas = semtalk.base;
    if (oid !== undefined && semtalk.page) {
      let obj = bas.FindObjectByID(oid);
      if (obj !== null) {
        if (bas.IsInstance(obj) && (bas.IsClass(newobj) || bas.IsAssociationType(newobj))) {
          // console.debug("NewClass: " + obj.ObjectName);
          let inst = obj as ISemTalkInstance;
          inst.SetClass(newobj as ISemTalkClass);
          if (bas.IsAssociation(obj)) {
            let astyle = (newobj as ISemTalkAssociationType).Style;
            shape.style = astyle;
            if (shape.style === "") {
              shape.style = SemTalkStyleAttribute.shape + "=flexArrow;" +
                SemTalkStyleAttribute.edgeStyle + "=loopRelationEdgeStyle;" +
                SemTalkStyleAttribute.curved + "=1;" +
                SemTalkStyleAttribute.endArrow + "=classic;html=1;" +
                SemTalkStyleAttribute.strokeWidth + "=2;";
            }
            this.tseditor.refreshCell(shape);

            if (obj.ObjectName === SemTalkBaseConstant.SLSubClassOf && this.page && this.page.ClassOf().Root !== null) {
              let src = (obj as ISemTalkAssociation).FromObject;
              let rt = this.page.ClassOf().Root;
              if (rt && !(src as ISemTalkClass).IsParent(rt)) {
                (src as ISemTalkClass).AddSubclassOf(rt);
              }
            }
          }
          if (this.base.FindObject(newobj.ObjectType, newobj.ObjectName + "." + String(inst.ID)) === null) {
            let newname = newobj.ObjectName + "." + String(inst.ID);
            if (inst.ObjectName !== newname) {
              inst.RenameObject(newname);
            }
          }
          semtalk.UpdateLabel(inst);
          // console.debug("NewClass: " + inst.ClassOf().ObjectName);
        } else {
          const shapeid = this.getMxId(shape);
          let nd = semtalk.page.FindNodeOfShape(shapeid);
          //  console.debug(newobj.ObjectName);

          const noev = semtalk.noevents;
          semtalk.noevents = true;
          if (nd) nd.Delete();
          semtalk.noevents = noev;

          shape.objectid = newobj.ID;
          // shape.shapeid = this.getMxId(shape);
          bas.MakeNode(semtalk.page, newobj, this.getMxId(shape));
          semtalk.MoveEdgesToShape(shape, obj, newobj);
          if (bas.IsClass(obj)) {
            if (obj.ID2Name === SemTalkMaster.MasterClass + "." + obj.ID) {
              obj.Delete();
            }
          } else {
            let scl = obj.SystemClass();
            if (obj.ObjectName === scl?.ObjectName + "." + obj.ID) {
              obj.Delete();
            }
          }
          semtalk.UpdateLabel(newobj);
          if (semtalk.tseditor) semtalk.tseditor.refreshCell(shape);
        }
      }
    }
    // this.endTransaction("", "ChangeObject");
  }
  public GetShapeStyle = (key: string, shapeType: SemTalkShapeType): { style: string, isedge: boolean } => {
    let shapeStyle = key;
    let isedge: boolean = false;
    //console.debug("Type " + shapeType + " " + key);

    switch (shapeType) {
      case SemTalkShapeType.svg: {
        shapeStyle = SemTalkStyleAttribute.shape + "=" + key;
        break;
      }
      case SemTalkShapeType.class: {
        if (CLASS_SHAPES[key]) {
          if (CLASS_SHAPES[key].type === 'edge') {
            isedge = true;
          }
          shapeStyle = CLASS_SHAPES[key].style;
        }
        break;
      }
      case SemTalkShapeType.org: {
        if (ORGCHART_SHAPES[key]) {
          if (ORGCHART_SHAPES[key].type === 'edge') {
            isedge = true;
          }
          shapeStyle = ORGCHART_SHAPES[key].style;
        } else {
          shapeStyle = ORGCHART_SHAPES[SemTalkMaster.MasterPosition].style;
        }
        break;
      }

      case SemTalkShapeType.generic: {
        if (GENERIC_SHAPES[key]) {
          if (GENERIC_SHAPES[key].type === 'edge') {
            isedge = true;
          }
          shapeStyle = GENERIC_SHAPES[key].style;
          if (!this.page?.ClassOf().UMLShape) {
            shapeStyle += ";" + SemTalkStyleAttribute.isuml + "=0";
          }
        }
        break;
      }
      default: {
        if (this.tseditor) {
          return this.tseditor.getShapeStyle(key, shapeType);
        }
      }
    }
    return { style: shapeStyle, isedge: isedge };
  }

  // public InsertObject(newobj: ISemTalkObject, x: number, y: number): mxCell | null {
  //   if (this.base.IsClass(newobj)) {
  //     return this.InsertClass(newobj as ISemTalkClass,x,y)
  //   } else {
  //     return null;
  //   }
  // }

  public createVertex(parent: mxCell | null, id: string | null, value: any, x: number, y: number,
    width: number, height: number, shapeStyle?: string): mxCell {
    return this.tseditor.createVertex(parent, id, value, x, y, width, height, shapeStyle);
  }
  public createEdge(src: mxCell, dst: mxCell, shapeStyle?: string): mxCell {
    const e = this.tseditor.insertEdge(src, dst, shapeStyle);
    e.geometry.offset = new mxPoint(0, 8);
    return e;
  }
  public InsertObject(newobj: ISemTalkObject, x: number, y: number, shapeStyle: string,
    page: ISemTalkDiagram): mxCell | null {
    if (this.tseditor && this.page) {
      let style: { style: string, isedge: boolean };
      let key: SemTalkMaster = SemTalkMaster.MasterClass;

      let width = 80;
      let height = 60;
      if (this.base.IsSystemClass(newobj)) {
        if (newobj.ObjectType === SemTalkType.SemTalkClass) {
          style = this.GetShapeStyle(SemTalkMaster.MasterSystemClass, SemTalkShapeType.generic);
          key = SemTalkMaster.MasterSystemClass;
        } else {
          style = this.GetShapeStyle(SemTalkMaster.MasterSystemClass, SemTalkShapeType.generic);
          if (this.base.IsDiagramType(newobj)) {
            style = this.GetShapeStyle(SemTalkMaster.MasterDiagramType, SemTalkShapeType.generic);
            key = SemTalkMaster.MasterDiagramType;
          }
          if (this.base.IsAssociationType(newobj)) {
            style = this.GetShapeStyle(SemTalkMaster.MasterAssociationType, SemTalkShapeType.generic);
            key = SemTalkMaster.MasterAssociationType;
          }
        }
      } else {
        if (this.base.IsClass(newobj)) {
          if (newobj.ObjectType === SemTalkType.SemTalkClass) {
            const pgclass = page.ClassOf();
            if (pgclass.UMLShape) {
              style = this.GetShapeStyle(SemTalkMaster.MasterUMLClass, SemTalkShapeType.class);
              key = SemTalkMaster.MasterUMLClass;
            } else {
              style = this.GetShapeStyle(SemTalkMaster.MasterClass, SemTalkShapeType.class);
            }
          } else {
            style = this.GetShapeStyle(SemTalkMaster.MasterClass, SemTalkShapeType.class);
            if (this.base.IsAttributeType(newobj)) {
              style = this.GetShapeStyle(SemTalkMaster.MasterAttributeType, SemTalkShapeType.generic);
              key = SemTalkMaster.MasterAttributeType;
            }
          }
        } else {
          let sc = newobj.SystemClass();
          if (sc && ORGCHART_SHAPES[sc.ObjectName]) {
            style = this.GetShapeStyle(sc.ObjectName, SemTalkShapeType.org);
            if (sc.Style.length > 0) {
              style = { style: sc.Style, isedge: false };
            }
            key = sc.ObjectName as SemTalkMaster;
          } else {
            if (sc && SB_SHAPES[sc.ObjectName]) {
              style = this.GetShapeStyle(sc.ObjectName, SemTalkShapeType.sitebuilder);
              if (sc.Style.length > 0) {
                style = { style: sc.Style, isedge: false };
              }
              key = sc.ObjectName as SemTalkMaster;
            } else {
              if (shapeStyle.length > 0) {
                style = { style: shapeStyle, isedge: false };
                key = SemTalkMaster.MasterInstance;
              } else {
                let thing = this.base.FindSystemClass(SemTalkBaseConstant.SLThing);
                if (thing && thing.Style.length > 0) {
                  style = { style: thing.Style, isedge: false };
                } else {
                  style = this.GetShapeStyle(SemTalkMaster.MasterInstance, SemTalkShapeType.generic);
                }
                key = SemTalkMaster.MasterInstance;
                if (sc && ORGCHART_SHAPES[sc.ObjectName]) {
                  style = this.GetShapeStyle(sc.ObjectName, SemTalkShapeType.org);
                  if (sc.Style.length > 0) {
                    style = { style: sc.Style, isedge: false };
                  }
                  key = sc.ObjectName as SemTalkMaster;
                } else {
                }
              }
            }
          }
        }
      }
      let mname: string | undefined = undefined;
      let mst = this.masters.find(x1 => x1.key === key);
      if (mst) {
        width = mst.width;
        height = mst.height;
        mname = mst.name;
      }

      let noev = this.noevents;
      this.noevents = true;
      let shape = this.createVertex(null, null, newobj.ObjectCaption, x, y, width, height, style.style);
      this.noevents = noev;
      shape.objectid = newobj.ID;
      shape.shapeid = this.getMxId(shape);
      shape.shapeName = key;
      shape.shapeKey = key;
      this.base.MakeNode(page, newobj, this.getMxId(shape), mname);
      this.UpdateLabel(newobj);
      this.tseditor.refreshCell(shape);
      return shape;
    }
    return null;
  }

  public initPage(sem: IVisioRDFS, diag: ISemTalkDiagram) {
    let refined = diag.RefinedObjects();
    if (refined.length > 0) {
      let ref0 = refined[0];
      let sysc = ref0.SystemClass();
      if (!sysc || (sysc && !sysc.OnceOnly && !sysc.BottomUp)) {
        sem.InsertObject(ref0, 50, 50, "", diag);
      }
    }
    // console.debug(refined);
  }
  public insertSwimlane = (cell: mxCell | null, x: number, y: number, width: number, height: number): mxCell | null => {
    if (this.page && this.tseditor) {
      let sm = this.GetShapeStyle(SemTalkMaster.MasterSwimlane, SemTalkShapeType.general);
      // let parent = this.graph.getDefaultParent();
      let swshape = this.createVertex(cell, null, "", x * this.scaleshapes, y * this.scaleshapes, width * this.scaleshapes, height * this.scaleshapes, sm.style);
      swshape.shapeName = Process_ElementName.Swimlane;
      swshape.shapeKey = SemTalkMaster.MasterSwimlane;
      this.visShapeAdded(swshape, null);
      // this.tseditor.refresh();
      return swshape;
    }
    return null;
  }
  public toggleDirection(cell: mxCell | null) {
    if (this.tseditor) {
      // this.horizontal = !this.horizontal;
      this.tseditor.toggleDirection(cell);
    }
  }
  public swimlaneLayout(cell: mxCell | null) {
    if (this.tseditor) {
      this.tseditor.swimlaneLayout(cell);
    }
  }
  public ExpandObject(shape: mxCell, newlinks: ISemTalkAssociation[]) {
    if (this.tseditor && shape) {
      const oid = shape.objectid;
      const bas = this.base;

      let x = shape.geometry.x;
      let y = shape.geometry.y;
      let w = shape.geometry.width;
      let h = shape.geometry.height;
      x += w * 1.5;
      let estyle = 'strokeWidth=2;';

      if (oid !== undefined && this.page) {
        let dt = this.page.ClassOf();
        let obj = bas.FindObjectByID(oid);
        if (obj !== null) {
          for (let a of newlinks) {
            estyle = a.ClassOf().Style;
            if (a.FromObject === obj && dt.IsAllowed(a.ToObject)) {
              // let newobj = a.ToObject;
              let noev = this.noevents;
              this.noevents = true;
              let oshape = this.FindShape(a.ToObject);
              if (oshape === null) {
                oshape = this.InsertObject(a.ToObject, x, y, "", this.page);
                this.UpdateLabel(a.ToObject);
                y += h * 1.5;
              }
              let eshape = this.FindShape(a);
              if (eshape === null) {
                eshape = this.createEdge(shape, oshape, estyle);
                eshape.objectid = a.ID;
                eshape.shapeid = this.getMxId(eshape);
                let relcla = a.ClassOf();
                let nd = this.base.MakeAssociationNode(this.page, a, this.getMxId(eshape), relcla.ObjectName);
                nd.FromShape = this.getMxId(eshape.source);
                nd.OtherShape = this.getMxId(eshape.target);
              }
              this.noevents = noev;
            } else {
              if (a.ToObject === obj && dt.IsAllowed(a.FromObject)) {
                // let newobj = a.FromObject;
                let noev = this.noevents;
                this.noevents = true;
                let oshape = this.FindShape(a.FromObject);
                if (oshape === null) {
                  oshape = this.InsertObject(a.FromObject, x, y, "", this.page);
                  y += h * 1.5;
                }

                let eshape = this.FindShape(a);
                if (eshape === null) {
                  eshape = this.createEdge(oshape, shape, estyle);
                  eshape.objectid = a.ID;
                  eshape.shapeid = this.getMxId(eshape);
                  let relcla = a.ClassOf();
                  let nd = this.base.MakeAssociationNode(this.page, a, this.getMxId(eshape), relcla.ObjectName);
                  nd.FromShape = this.getMxId(eshape.source);
                  nd.OtherShape = this.getMxId(eshape.target);
                }
                this.noevents = noev;
              }
            }
            this.UpdateLabel(a);
            this.refreshShapes(a);
          }
          this.shapeExpanded(obj, shape, newlinks);
        }
        // this.saveGraph("","ExpandObject");
      }
    }
  }
  public shapeExpanded = (_obj: ISemTalkObject, _shape: mxCell, _newlinks: ISemTalkAssociation[]): void => {
  }

  public insertQuickShape(m: SemTalkShape, cell: mxCell | null): mxCell | null {
    if (cell === null) cell = this.currentShape();
    if (this.tseditor && cell) {
      this.beginTransaction("insertQuickShape");
      const geo = cell.geometry;
      let ss = this.GetShapeStyle(m.key, m.type);
      let shapeStyle = ss.style;
      let ncell: any;
      // let dist: number = 1.5;
      let width = geo.width;
      let height = geo.height;
      let x: number;
      let y: number;
      let par = cell.parent;
      if (!this.horizontal) {
        // width = Math.max(width, m.width);
        x = geo.x + geo.width + 50;
        y = geo.y + height / 2 - m.height / 2;
        let oth = findChildAt(par, x, y);
        while (oth !== null && oth !== par) {
          const geooth = oth.geometry;
          y += geooth.height + m.height / 2;
          oth = findChildAt(par, x, y);
        }
      } else {
        // height = Math.max(height, m.height);
        x = geo.x + width / 2 - m.width / 2;
        y = geo.y + geo.height + 40;
        let oth = findChildAt(par, x, y);
        while (oth !== null && oth !== par) {
          const geooth = oth.geometry;
          x += geooth.width + m.width / 2;
          oth = findChildAt(par, x, y);
        }
      }
      if (this.graph.isEditing(undefined)) {
        this.graph.stopEditing(false);
      }

      let mst = m.key;
      ncell = this.createVertex(par, null, mst, x, y,
        m.width, m.height, shapeStyle);
      ncell.shapeKey = m.key;
      ncell.shapeName = m.name;
      ncell.shapeSubkey = m.subkey;
      // let ecell: any;
      switch (m.name) {
        case SemTalkMaster.MasterInstance: {
          this.makeInstance(cell, ncell);
          break;
        }
        case SemTalkMaster.MasterClass: {
          this.makeSubClass(cell, ncell);
          break;
        }
        case SemTalkMaster.MasterUMLClass: {
          this.makeSubClass(cell, ncell);
          break;
        }
        default: {
          this.visShapeAdded(ncell, null);
          let estyle = 'strokeWidth=2;';
          let ecell = this.createEdge(cell, ncell, estyle);
          // graph.orderCells(true, [ecell]);
          this.visConnectionAdded(undefined, ecell, cell, ncell);
          copyColorStyle(cell, ncell);
          copyColorStyle(cell, ecell);
        }
      }
      // console.debug(cell);


      this.graph.scrollCellToVisible(ncell);
      this.graph.setSelectionCells([ncell]);
      this.endTransaction("insertQuickShape");
      return ncell;

    }
    return null;
  }
  public ExpandSimilarClass(shape: any, newlinks: string[]) {
    if (this.tseditor) {
      const oid = shape.objectid;
      const bas = this.base;
      let x = shape.geometry.x;
      let y = shape.geometry.y;
      let w = shape.geometry.width;
      let h = shape.geometry.height;
      x += w * 1.5;

      if (oid !== undefined && this.page) {
        let obj = bas.FindObjectByID(oid);
        if (obj !== null) {
          for (let a of newlinks) {
            let aclass = bas.FindClass(a);
            if (aclass === null) {
              let an: ISemTalkClass;
              let sc = obj.SystemClass();
              if (sc) {
                if (bas.IsBusinessClass(obj)) {
                  an = bas.MakeBusinessClass(a);
                  if (!an.IsParent(sc)) {
                    an.AddSubclassOf(sc);
                  }
                } else {
                  an = sc.MakeSubClass(a);
                }
              } else {
                an = bas.MakeClass(a);
              }
              if (an) {
                aclass = an;
              }
            }
            if (aclass) {
              // let newobj = a.ToObject;
              let noev = this.noevents;
              this.noevents = true;
              let oshape = this.FindShape(aclass);
              if (oshape === null) {
                oshape = this.InsertObject(aclass, x, y, shape.style, this.page);
                y += h * 1.5;
              }
              this.noevents = noev;
              this.UpdateLabel(aclass);
              this.tseditor.refreshCell(oshape);
            }
          }
        }
      }
    }
  }

  public MoveEdgesToShape(sel: mxCell, obj: ISemTalkObject, newobj: ISemTalkObject) {
    const noev: boolean = this.noevents;
    this.noevents = true;
    const delrel: ISemTalkAssociation[] = [];
    if (sel.edges !== null) {
      for (const relshp of sel.edges) {
        const relid = relshp.objectid;
        let rel = this.base.FindInstanceByID(relid) as ISemTalkAssociation;
        if (rel && rel.FromObject.ID === obj.ID) {
          const oth = rel.ToObject;
          const relcla = rel.ClassOf();
          delrel.push(rel);
          const newrel = newobj.MakeAssociation(relcla, oth);
          if (newrel && this.page) {
            const shapeid = this.getMxId(relshp);
            let oldnd = this.page.FindNodeOfShape(shapeid);
            if (oldnd) oldnd.Delete();
            relshp.objectid = newrel.ID;
            const nd = this.base.MakeAssociationNode(this.page, newrel, this.getMxId(relshp), relcla.ObjectName);
            nd.FromShape = this.getMxId(relshp.source);
            nd.OtherShape = this.getMxId(relshp.target);
          }
        }
        if (rel && rel.ToObject.ID === obj.ID) {
          const oth = rel.FromObject;
          const relcla = rel.ClassOf();
          delrel.push(rel);
          const newrel = oth.MakeAssociation(relcla, newobj);
          if (newrel && this.page) {
            const shapeid = this.getMxId(relshp);
            let oldnd = this.page.FindNodeOfShape(shapeid);
            if (oldnd) oldnd.Delete();
            relshp.objectid = newrel.ID;
            const nd = this.base.MakeAssociationNode(this.page, newrel, this.getMxId(relshp), relcla.ObjectName);
            nd.FromShape = this.getMxId(relshp.source);
            nd.OtherShape = this.getMxId(relshp.target);
          }
        }
      }
      for (const r of delrel) {
        r.Delete();
      }
    }

    this.noevents = noev;
  }
  public visShapeExitedTextEdit(shape: any, text: string) {
    if (this.noevents) { return; }
    if (shape === null || shape === undefined) { return; }
    this.beginTransaction("visShapeExitedTextEdit");
    let style = shape.style;
    if (style && getStyleAttribute(style, SemTalkStyleAttribute.shape) === "image") {
      if (getStyleAttribute(style, SemTalkStyleAttribute.image) === "" ||
        text.startsWith("http") ||
        text.startsWith("data:image")) {
        text = text.replace(";base64,", ",");
        if (style.indexOf(SemTalkStyleAttribute.custom) < 0) {
          style += SemTalkStyleAttribute.custom + "=1;";
        }
        shape.style = setStyleAttribute(style, SemTalkStyleAttribute.image, text);
        shape.value = "";
        if (this.tseditor) this.tseditor.refreshCell(shape);
      }
    }
    const oid = shape.objectid;
    if (oid !== undefined && text && text.length > 0) {
      let obj = this.base.FindObjectByID(oid);
      if (obj !== null) {
        if (this.base.IsInstance(obj)) {
          let ins = obj as ISemTalkInstance;
          switch (ins.ClassOf().ObjectName) {
            case this.base.GetModelAttribute(ModelAttribute.SLComment): {
              const defof = this.base.GetModelAttribute(ModelAttribute.SLCommentOf);
              for (let c of obj.LinkedObjects(defof, false, SemTalkRelation.SemTalkSystemRelation)) {
                if (c.Comment !== text) {
                  c.Comment = text;
                }
                this.endTransaction("visShapeExitedTextEdit");
                return;
              }
              break;
            }
          }
        }
        // if (this.tseditor) {
        //   this.tseditor.beginUpdate();
        // }

        this.RenameObject(obj, text, shape);
        if (shape.shapeKey === SemTalkMaster.MasterSwimlane) {
          this.UpdateSwimlaneContent(shape);
        }
        // if (this.tseditor) {
        //   this.tseditor.endUpdate();
        //   this.tseditor.refreshCell(shape);
        // }
      }
    } else {
      if (text === undefined || text.length === 0) {
        let obj = this.base.FindObjectByID(oid);
        if (obj !== null) {
          this.UpdateLabel(obj);
          if (this.tseditor) this.tseditor.refreshCell(shape);
        }
      }
      // if (shape.IsEdge && shape.source !== null && shape.target !== null) {
      //   alert(label);
      // }
    }
    this.endTransaction("visShapeExitedTextEdit");
  }

  public isValid = (srccell: mxCell, dstcell: mxCell): boolean => {

    const srcid = srccell.objectid;
    const dstid = dstcell.objectid;
    const src = this.base.FindInstanceByID(srcid);
    const dst = this.base.FindInstanceByID(dstid);
    // const ev = this.base.GetModelAttribute(Process_ElementName.SLEvent);
    if (src !== null && dst !== null) {
      if (this.page && this.page.ClassOf().Root === null) {
        return true;
      }
      const sc = src.ClassOf();
      const dc = dst.ClassOf();
      if (sc !== null && dc !== null) {
        if (sc.ObjectName === SemTalkBaseConstant.SLThing ||
          dc.ObjectName === SemTalkBaseConstant.SLThing) {
          return true;
        }
        if (dc.ObjectName === SemTalkBaseConstant.SLComment) {
          return false;
        }
        const ssy = sc.SystemClass();
        const dsy = dc.SystemClass();
        if (ssy !== null && dsy !== null) {
          if (this.AllValidClassRelations(ssy, dsy).length > 0) {
            return true;
          }
        }
      }
    } else {
      const sc = this.base.FindObjectByID(srcid);
      const dc = this.base.FindObjectByID(dstid);
      if (sc !== null && dc !== null) {
        if (this.base.IsAttributeType(sc)) {
          return this.base.IsAttributeType(dc);
        }
        if (this.base.IsAssociationType(sc)) {
          return (this.base.IsAssociationType(dc) || this.base.IsClass(dc));
        }
        if (this.base.IsDiagramType(sc)) {
          return this.base.IsClass(dc);
        }
        if (this.base.IsAttributeType(dc)) {
          return (this.base.IsAttributeType(sc));
        }
        if (this.base.IsAssociationType(dc)) {
          return this.base.IsAssociationType(sc);
        }
        if (this.base.IsDiagramType(dc)) return false;

        if (this.page && this.page.ClassOf().Root === null) {
          return true;
        }
        const ssy = sc.SystemClass();
        const dsy = dc.SystemClass();
        if (ssy !== null && dsy !== null) {
          let valid = (ssy.ID === dsy.ID);
          if (!valid)
            if (this.base.IsInstance(sc) &&
              (sc as ISemTalkInstance).ClassOf().ObjectName ===
              this.base.GetModelAttribute(ModelAttribute.SLComment)) {
              valid = true;
            }
          if (this.base.IsInstance(dc) &&
            (dc as ISemTalkInstance).ClassOf().ObjectName ===
            this.base.GetModelAttribute(ModelAttribute.SLComment)) {
            valid = false;
          }

          return valid;
        } else {
          return true;
        }
      }
    }
    return false;
  }
  public notSamePool = (source: mxCell, target: mxCell, toggle: boolean): boolean => {
    let fnd: boolean = true;
    // const model = this.graph.getModel();

    const sparent = source.parent;
    const tparent = target.parent;
    let samelaneornolane = (sparent.shapeid !== tparent.shapeid);
    if (samelaneornolane) {
      let p1 = getParticipant(source);
      let p2 = getParticipant(target);
      samelaneornolane = (p1.shapeid !== p2.shapeid);
    }
    fnd = samelaneornolane;
    // if (sparent.id !== '1' && tparent.id !== '1' && sparent.id === tparent.id) {
    //   fnd = false;
    // }

    // // const gsparent = sparent.parent;
    // // const gtparent = tparent.parent;
    // // if (fnd && gsparent.id !== '0' && gtparent.id !== '0' && gsparent.id === gtparent.id) {
    // //   fnd = false;
    // // }
    // if (fnd && source.objectid && source.objectid) {
    //   let ass = this.base.FindAssociationType(this.base.GetModelAttribute(SemTalkModellAttribute.SLUses));
    //   if (ass) {
    //     const src = this.base.FindInstanceByID(source.objectid);
    //     const dst = this.base.FindInstanceByID(target.objectid);
    //     if (src && dst) {
    //       let sl = src.LinkedObjects(ass);
    //       let dl = dst.LinkedObjects(ass);
    //       if (sl.length === 0 && dl.length === 0) {
    //         fnd = false;
    //       } else {
    //         if (sl.length > 0 && dl.length > 0) {
    //           if (sl[0] === dl[0]) {
    //             fnd = false;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    if (toggle) {
      return !fnd;
    } else {
      return fnd;
    }
  }
  public filterRelations = (rels: ISemTalkAssociationType[],
    _source: any, _target: any, _ctrl: boolean,
    _sy: ISemTalkSystemClass, _dsy: ISemTalkSystemClass,
    _src: ISemTalkInstance, _dst: ISemTalkInstance,
    _considertasktye: boolean): ISemTalkAssociationType[] => {
    return rels;
  }

  public AllValidClassRelations(src: ISemTalkSystemClass, dst: ISemTalkSystemClass): ISemTalkAssociationType[] {
    return src.AllValidClassRelations(dst);
  }
  public visIsSplitTarget = (target: mxCell, cells: mxCell[], _evt: any): boolean => {
    const relid = target.objectid;
    const rel = this.base.FindInstanceByID(relid);
    if (cells.length > 0 && rel) {
      const isscof = rel.ClassOf().ObjectName === SemTalkBaseConstant.SLSubClassOf;
      const objid = cells[0].objectid;
      const obj = this.base.FindClassByID(objid);
      if (obj && isscof) {
        return true;
      }
      return false;
    } else {
      if (rel && rel.ClassOf().ObjectName === SemTalkBaseConstant.SLSubClassOf) {
        return true;
      }
    }
    return false;
  }

  public visConnectionAdded(evt: mxEventObject | undefined, link: mxCell, source: mxCell, target: mxCell): ISemTalkNode | null {
    const considertasktye: boolean = false;
    if (this.noevents) { return null; }
    // console.debug(evt);
    this.beginTransaction("visConnectionAdded");
    let ctrl = false;
    if (evt) {
      let e = (evt.properties as any)["event"];
      ctrl = e.ctrlKey;
    }

    if (link.source !== null && link.target !== null) {
      if (!link.source.objectid && !link.target.objectid) {
        return null;
      }
      if (this.tseditor) {
        (this.graph as mxGraph).orderCells(true, [link]);
      }
      link.geometry.offset = new mxPoint(0, 8);

      const src = this.base.FindInstanceByID(link.source.objectid);
      const dst = this.base.FindInstanceByID(link.target.objectid);
      let addwaypoint = false;
      if (src !== null && dst !== null) {
        // const sf = this.base.GetModelAttribute(ModelAttribute.SLSequenceFlow);
        const sc = src.ClassOf();
        const dc = dst.ClassOf();
        if (sc !== null && dc !== null) {
          let rels: ISemTalkAssociationType[] = [];
          const sy = sc.SystemClass();
          const dsy = dc.SystemClass();
          if (sy?.ObjectName === SemTalkBaseConstant.SLThing || sy?.ObjectName === SemTalkBaseConstant.SLThing) {
            let co = this.base.FindAssociationType(SemTalkBaseConstant.SLConsistsOf);
            if (co) rels.push(co);
            addwaypoint = true;
          } else {
            if (this.validationonunserclasses) {
              rels = sc.AllValidClassRelations(dc);
            }
            if (!this.validationonunserclasses || rels.length === 0) {
              if (sy !== null && dsy !== null) {
                rels = sy.AllValidClassRelations(dsy);
              }
            }
          }
          if (sy && dsy) {
            rels = this.filterRelations(rels, source, target,
              ctrl, sy, dsy, src, dst, considertasktye);
          }

          let fnd = false;
          for (const rel of rels) {
            if (!fnd) {
              let relobj: ISemTalkAssociation | null = null;
              relobj = src.FindAssociation(rel, dst);
              if (relobj === null || relobj.Nodes().length === 0) {
                relobj = src.MakeAssociation(rel, dst);
              }
              // splitting
              link.shapeid = undefined;
              link.objectid = undefined;
              fnd = true;
              if (relobj !== null && this.page) {
                link.objectid = relobj.ID;
                // link.shapeid = "mxSheet." + link.id;
                // splitting
                const nd = this.base.MakeAssociationNode(this.page, relobj, this.getMxId(link), rel.ObjectName);
                nd.FromShape = this.getMxId(link.source);
                nd.OtherShape = this.getMxId(link.target);
                this.UpdateLabel(relobj);
                if (this.tseditor) {
                  this.tseditor.refreshCell(link);
                  if (addwaypoint) {
                    //   setTimeout(() => {
                    //     this.tseditor.insertCenterWayPointToCell(link);
                    //   }, 100);
                  }
                }
                this.endTransaction("visConnectionAdded");
                return nd;
              }
            }
          }
          if (!fnd) {
            (this.graph as mxGraph).removeCells([link], true);
          }
        }
      } else {
        const ob = this.base;
        const srccl = ob.FindObjectByID(source.objectid);
        const dstcl = ob.FindObjectByID(target.objectid);
        if (srccl !== null && dstcl !== null) {
          let relobj: ISemTalkAssociation | null = null;
          if (ob.IsClass(srccl) && ob.IsClass(dstcl)) {
            if (link["type"] === SemTalkBaseConstant.SLProperty) {
              for (let a of srccl.Associations()) {
                if (a.ToObject === dstcl && a.Nodes().length === 0) {
                  relobj = a;
                  break;
                }
              }
            }
            try {
              if (relobj === null) {
                if (ctrl || link["type"] === SemTalkBaseConstant.SLProperty) {
                  relobj = (srccl as ISemTalkClass).MakeAssociation(SemTalkBaseConstant.SLConsistsOf, dstcl);
                } else
                  relobj = (srccl as ISemTalkClass).AddSubclassOf(dstcl as ISemTalkClass);
              }
            } catch (_ex) {
              (this.graph as mxGraph).removeCells([link], true);
            }
          } else {
            if (ob.IsInstance(srccl) && (srccl as ISemTalkInstance).ClassOf().ObjectName ===
              ob.GetModelAttribute(ModelAttribute.SLComment)) {
              relobj = srccl.MakeAssociation(ob.GetModelAttribute(ModelAttribute.SLCommentOf),
                dstcl);
            } else {
              if (ob.IsInstance(srccl) && ob.IsClass(dstcl)) {
                (srccl as ISemTalkInstance).SetClass(dstcl as ISemTalkClass);
                let rt = this.base.FindAssociationType(SemTalkBaseConstant.SLInstanceOf);
                if (rt) {
                  rt.RelationType = SemTalkRelation.SemTalkInstanceOf;
                  relobj = srccl.FindAssociation(rt, dstcl, SemTalkRelation.SemTalkInstanceOf);
                  if (!relobj) {
                    relobj = srccl.MakeAssociation(rt, dstcl, SemTalkRelation.SemTalkInstanceOf);
                  }
                  // link.objectid = relobj.ID;
                }

              }
            }
          }
          // splitting
          link.shapeid = undefined;
          link.objectid = undefined;
          if (relobj !== null && this.page) {
            let rel = relobj.ClassOf();
            link.objectid = relobj.ID;
            const nd = ob.MakeAssociationNode(this.page, relobj, this.getMxId(link), rel.ObjectName);
            nd.FromShape = this.getMxId(link.source);
            nd.OtherShape = this.getMxId(link.target);
            this.UpdateLabel(relobj);
            if (this.tseditor) {
              this.tseditor.refreshCell(link);
            }
            this.endTransaction("visConnectionAdded");
            return nd;
          }
        }
      }
      // console.log("No valid relation: " + link);
      if (this.tseditor) {
        // this.tseditor.alert("No valid relation: " + link.source.value + " -> " + link.target.value, MessageBarType.info);
        (this.graph as mxGraph).removeCells([link], true);
      }
    }
    this.endTransaction("visConnectionAdded");
    return null;
  }
  public visConnectionsDeleted(link: any): void {
    if (this.noevents) { return; }
    // if (link.source !== null && link.target !== null) {
    const relobj = this.base.FindInstanceByID(link.objectid);
    if (relobj !== null) {
      relobj.Delete();
    }
    // }
    return;
  }
  public visCellConnected(link: mxCell): void {
    if (this.noevents) { return; }
    if (link.source !== null && link.target !== null) {
      const src = this.base.FindObjectByID(link.source.objectid);
      const dst = this.base.FindObjectByID(link.target.objectid);
      if (src !== null && dst !== null) {
        let oldrel: ISemTalkAssociation | null = null;
        if (link.objectid) {
          let rel = this.base.FindInstanceByID(link.objectid);
          if (rel) {
            oldrel = rel as ISemTalkAssociation;
            let noev = this.noevents;
            this.noevents = true;
            oldrel.Delete();
            this.noevents = noev;
            link.objectid = undefined;
          }
          let newnode = this.visConnectionAdded(undefined, link, link.source, link.target);
          if (oldrel) {
            if (newnode) {
              (newnode.Model as ISemTalkInstance).Clone(oldrel);
            }
          }
        }
      }
    }
  }
  public visPageAdded(page: ISemTalkDiagram) {
    if (this.noevents) { return; }
    this.page = page;
  }
  public visPageDeleted(_page: ISemTalkDiagram): void {
    if (this.noevents) { return; }
  }
  public visPageChanged(_page: ISemTalkDiagram): void {
    if (this.noevents) { return; }
  }

  public loadDiag(id: SemTalkID, initpage: boolean): ISemTalkDiagram | null {
    if (this.page === undefined || this.page === null || (this.page !== null && this.page.ID !== id)) {
      this.beginTransaction("loadDiag");
      this.page = this.base.FindDiagramByID(id);
      if (this.tseditor) {
        this.tseditor.beginUpdate();
        let cells: mxCell[] = this.tseditor.getAllCells();
        //    for (const s of cells) {
        let zombies: mxCell[] = [];
        for (const i in cells) {
          const s = cells[i];
          if (s.objectid && !this.base.FindObjectByID(s.objectid)) {
            zombies.push(s);
          }
        }
        if (zombies.length > 0) {
          console.debug("Zombies", zombies);
          this.tseditor.removeCells(zombies, true);
        }
      }
      if (this.page) {
        if (initpage) {
          this.initPage(this, this.page);
        }
        for (const nd of this.page.Contents()) {
          this.UpdateLabel(nd.Model);
        }
      }
      if (this.tseditor) {
        this.tseditor.endUpdate();
        this.tseditor.refresh();
      }
      this.endTransaction("loadDiag");
    }
    return this.page;
  }
  public redraw() {
    if (this.page !== undefined && this.page !== null) {
      if (this.tseditor) {
        this.tseditor.suspendOutline(true);
        this.tseditor.beginUpdate();
      }
      if (this.page) {
        for (const nd of this.page.Contents()) {
          this.UpdateLabel(nd.Model);
          this.UpdateDependents(nd.Model);

        }
      }
      if (this.tseditor) {
        this.tseditor.endUpdate();
        this.tseditor.refresh();
        this.tseditor.suspendOutline(false);
      }
    }
  }
  public redrawLight(updatelabel: boolean) {
    if (this.page !== undefined && this.page !== null) {
      if (this.tseditor) {
        this.tseditor.suspendOutline(true);
        this.tseditor.beginUpdate();
      }
      if (this.page) {
        let cont = this.page.Contents();
        // let i = 0;
        // let l = cont.length;
        for (const nd of cont) {
          let obj = nd.Model;
          // i += 1;
          // console.debug(i + "/" + l);
          if (updatelabel) {
            this.UpdateLabel(obj);
          }
          this.refreshShapes(obj);
        }
      }
      if (this.tseditor) {
        this.tseditor.endUpdate();
        this.tseditor.suspendOutline(false);
      }
    }
  }
  private eventlistener = (e: any): void => {
    if (this.noevents) return;
    let md: any;
    let mtype: any;
    try {
      md = JSON.parse(e.data);
      mtype = md.type;
    } catch (e) {
      return;
    }
    switch (mtype) {
      // case "loadSettings":
      //     if (md.settings !== undefined) {
      //         global.loadsettings(md.settings);
      //     }
      //     break;
      case SemTalkNavigationEvent.gotoDocument:
        this.beginTransaction(SemTalkNavigationEvent.gotoDocument);
        this.loadDiag(md.diagid, true);
        this.endTransaction(SemTalkNavigationEvent.gotoDocument);
        break;
      case SemTalkNavigationEvent.gotoNode: {
        if (this.page && md.diagid === this.page.ID) {
          const shpid = md.shapeid;
          const shp = this.FindShapeByShapeID(shpid);
          if (shp !== null) {
            //   console.debug(shp);
            if (!shp.isVisible()) {
              this.graph.scrollCellToVisible(shp, md.center);
            }
            let g: mxGraph = this.graph as mxGraph;

            if (md.select === true) {
              this.graph.setSelectionCell(shp);
            }
            if (!this.highlight) {
              this.highlight = new mxCellHighlight(g, '#000000', 5);
            }
            this.highlight.highlight(g.view.getState(shp));

            setTimeout(() => {
              if (this.highlight) {
                this.highlight.hide();
              }
            }, 2000);

            // var h2 = new mxCellHighlight(graph, '#ff0000', 2);
            // h2.highlight(graph.view.getState(cell2)));
            // let sel = this.selectedShapes();
            // let isselected = sel.find((s) => s.id === shp.id)
            // if (isselected === undefined) {
            //   sel.push(shp);
            //   this.graph.setSelectionCells(sel);
            // }
          } else {
            if (this.highlight) {
              this.highlight.hide();
            }
          }
        }
        break;
      }
      case SemTalkObjectBaseEvent.OnNodeCreated:
        this.beginTransaction(SemTalkObjectBaseEvent.OnNodeCreated);
        this.endTransaction(SemTalkObjectBaseEvent.OnNodeCreated);
        break;
      case SemTalkObjectBaseEvent.OnNodeDeleted: {
        if (!this.noevents) {
          this.beginTransaction(SemTalkObjectBaseEvent.OnNodeDeleted);
          const shpid = md.arg1;
          const shp = this.FindShapeByShapeID(shpid);
          if (shp !== null) {
            const noev = this.noevents;
            this.noevents = true;
            if (this.tseditor) this.tseditor.removeCells([shp], true);
            this.noevents = noev;
          }
          this.endTransaction(SemTalkObjectBaseEvent.OnNodeDeleted);
        }
        break;
      }
      case SemTalkObjectBaseEvent.OnClassChanged: {
        if (md.arg0) {
          this.UpdateByID(md.objectid);
          let isgeneric = this.page?.ClassOf().ObjectName === SemTalkBaseConstant.SLGeneric;
          if (isgeneric) {
            let inst = this.base.FindInstanceByID(md.objectid);
            if (inst) {
              let oldcla = this.base.FindClass(md.arg0);
              if (oldcla) {
                for (let lnk of inst.Associations()) {
                  if (lnk.ClassOf().ObjectName === SemTalkBaseConstant.SLInstanceOf) {
                    if (lnk.ToObject.ID === oldcla.ID) {
                      lnk.Delete();
                    }
                  }
                }
              }
              let cla = inst.ClassOf();
              let cland = this.FindShape(cla);
              let instnd = this.FindShape(inst);
              if (cland) {
                let rel = inst.FindAssociation(SemTalkBaseConstant.SLInstanceOf, cla, SemTalkRelation.SemTalkInstanceOf);
                if (!rel) {
                  rel = inst.MakeAssociation(SemTalkBaseConstant.SLInstanceOf, cla, SemTalkRelation.SemTalkInstanceOf);
                }
                this.ExpandObject(instnd, [rel]);
              }
            }
          }

        }
        //  this.saveGraph("");
        break;
      }
      case SemTalkObjectBaseEvent.OnCreated:
        this.ensureSynonym(md.objectid);
        break;
      case SemTalkObjectBaseEvent.OnRefined: {
        this.beginTransaction(SemTalkObjectBaseEvent.OnRefined);
        this.UpdateLabelByID(md.objectid);
        if (this.tseditor) this.tseditor.refresh();
        this.endTransaction(SemTalkObjectBaseEvent.OnRefined);
        break;
      }
      case SemTalkObjectBaseEvent.OnDetached: {
        this.beginTransaction(SemTalkObjectBaseEvent.OnDetached);
        this.UpdateLabelByID(md.objectid);
        if (this.tseditor) this.tseditor.refresh();
        this.endTransaction(SemTalkObjectBaseEvent.OnDetached);
        break;
      }
      case SemTalkObjectBaseEvent.OnExtRefined: {
        this.beginTransaction(SemTalkObjectBaseEvent.OnExtRefined);
        this.UpdateLabelByID(md.objectid);
        if (this.tseditor) this.tseditor.refresh();
        this.endTransaction(SemTalkObjectBaseEvent.OnExtRefined);
        break;
      }
      case SemTalkObjectBaseEvent.OnExtDetached: {
        this.beginTransaction(SemTalkObjectBaseEvent.OnExtDetached);
        this.UpdateLabelByID(md.objectid);
        if (this.tseditor) this.tseditor.refresh();
        this.endTransaction(SemTalkObjectBaseEvent.OnExtDetached);
        break;
      }
      case SemTalkObjectBaseEvent.OnValueChanged: {
        if (md.arg2 === SemTalkBaseConstant.SLMXGAttribute) {
          return;
        }
        if (md.arg2 === "type") {
          gotoObject("-1");
          gotoObject(md.objectid);
        }
        // if (md.arg3 === false) {
        //   return;
        // }
        this.UpdateLabelByID(md.objectid);
        this.refreshShapesByID(md.objectid);
        // if (this.tseditor) this.tseditor.refresh();
        //  this.saveGraph("");
        break;
      }
      case SemTalkObjectBaseEvent.OnAttributeAdded: {
        this.UpdateLabelByID(md.objectid);
        this.refreshShapesByID(md.objectid);
        break;
      }
      case SemTalkObjectBaseEvent.OnAttributeDeleted: {
        this.UpdateLabelByID(md.objectid);
        this.refreshShapesByID(md.objectid);
        break;
      }
      case SemTalkObjectBaseEvent.OnMethodAdded: {
        this.UpdateLabelByID(md.objectid);
        this.refreshShapesByID(md.objectid);
        break;
      }
      case SemTalkObjectBaseEvent.OnMethodDeleted: {
        this.UpdateLabelByID(md.objectid);
        this.refreshShapesByID(md.objectid);
        break;
      }
      case SemTalkObjectBaseEvent.OnStateAdded: {
        this.UpdateLabelByID(md.objectid);
        this.refreshShapesByID(md.objectid);
        break;
      }
      case SemTalkObjectBaseEvent.OnStateDeleted: {
        this.UpdateLabelByID(md.objectid);
        this.refreshShapesByID(md.objectid);
        break;
      }
      case SemTalkObjectBaseEvent.OnRenamed: {
        if (md.semtalktype !== undefined)
          switch (md.semtalktype) {
            case SemTalkType.SemTalkAssociation:
            case SemTalkType.SemTalkInstance:
            case SemTalkType.SemTalkAssociationType:
            case SemTalkType.SemTalkMethodType:
            case SemTalkType.SemTalkStateType:
            case SemTalkType.SemTalkAttributeType:
            case SemTalkType.SemTalkClass:
              {
                this.beginTransaction(SemTalkObjectBaseEvent.OnRenamed);
                this.UpdateByID(md.objectid);
                this.endTransaction(SemTalkObjectBaseEvent.OnRenamed);
                break;
              }
            case SemTalkType.SemTalkDiagram: {
              this.beginTransaction(SemTalkObjectBaseEvent.OnRenamed);
              this.updateTextFields([]);
              this.endTransaction(SemTalkObjectBaseEvent.OnRenamed);
              break;
            }
            default:
            // console.debug(md);
          }
        break;
      }
      case SemTalkObjectBaseEvent.OnCommentChanged: {
        const obj = this.base.FindObjectByID(md.objectid);
        if (obj !== null) {
          this.beginTransaction(SemTalkObjectBaseEvent.OnCommentChanged);
          const defof = this.base.GetModelAttribute(ModelAttribute.SLCommentOf);
          let bfnd = false;
          for (let c of obj.InvLinkedObjects(defof, false, SemTalkRelation.SemTalkSystemRelation)) {
            this.UpdateLabel(c);
            bfnd = true;
          }
          if (bfnd && this.tseditor) this.tseditor.refresh();
          if (obj.ObjectType === SemTalkType.SemTalkDiagram) {
            this.updateTextFields([]);
          }
          // this.saveGraph("");
          this.endTransaction(SemTalkObjectBaseEvent.OnCommentChanged);
        }
        break;
      }
      case SemTalkObjectBaseEvent.OnComposed: {
        this.beginTransaction(SemTalkObjectBaseEvent.OnComposed);
        this.UpdateByID(md.objectid);
        this.endTransaction(SemTalkObjectBaseEvent.OnComposed);
        break;
      }
      case SemTalkObjectBaseEvent.OnUnComposed: {
        this.beginTransaction(SemTalkObjectBaseEvent.OnUnComposed);
        this.UpdateByID(md.objectid);
        this.endTransaction(SemTalkObjectBaseEvent.OnUnComposed);
        break;
      }
      case SemTalkObjectBaseEvent.OnAssociationAdded: {
        this.beginTransaction(SemTalkObjectBaseEvent.OnAssociationAdded);
        this.UpdateByID(md.objectid);
        this.endTransaction(SemTalkObjectBaseEvent.OnAssociationAdded);
        break;
      }
      case SemTalkObjectBaseEvent.OnAssociationDeleted: {
        this.beginTransaction(SemTalkObjectBaseEvent.OnAssociationDeleted);
        let fromobj = md.arg1;
        let toobj = md.arg2;
        this.UpdateByID(fromobj);
        this.UpdateByID(toobj);
        this.endTransaction(SemTalkObjectBaseEvent.OnAssociationDeleted);
        break;
      }
      case SemTalkObjectBaseEvent.OnAttachmentAdded: {
        this.beginTransaction(SemTalkObjectBaseEvent.OnAttachmentAdded);
        this.UpdateByID(md.objectid);
        this.endTransaction(SemTalkObjectBaseEvent.OnAttachmentAdded);
        break;
      }
      case SemTalkObjectBaseEvent.OnAttachmentDeleted: {
        this.beginTransaction(SemTalkObjectBaseEvent.OnAttachmentDeleted);
        this.UpdateByID(md.objectid);
        this.endTransaction(SemTalkObjectBaseEvent.OnAttachmentDeleted);
        break;
      }
      case SemTalkObjectBaseEvent.OnSynonymAdded: {
        this.UpdateLabelByID(md.objectid); break;
      }
      case SemTalkObjectBaseEvent.OnSynonymDeleted: {
        this.UpdateLabelByID(md.objectid); break;
      }
      case SemTalkObjectBaseEvent.OnSynonymRenamed: {
        this.UpdateLabelByID(md.objectid); break;
      }
      case SemTalkObjectBaseEvent.OnUserNumberChanged: {
        this.UpdateLabelByID(md.objectid); break;
      }
      case SemTalkObjectBaseEvent.OnSubClassCreated: {
        if (md.arg0) {
          let isgeneric = this.page?.ClassOf().ObjectName === SemTalkBaseConstant.SLGeneric;
          if (isgeneric && this.transaction !== "visConnectionAdded") {
            let rel = (this.base.FindInstanceByID(md.objectid) as ISemTalkAssociation);
            if (rel) {
              let cla = rel.FromObject;
              let cland = this.FindShape(cla);
              let oth = rel.ToObject;
              let othnd = this.FindShape(oth);
              if (cland && othnd) {
                this.ExpandObject(cland, [rel]);
              }
            }
          }

        }
        //  this.saveGraph("");
        break;
      }
      case SemTalkObjectBaseEvent.OnSubClassDeleted:
        break;
      case SemTalkObjectBaseEvent.OnModelAttributeChanged:
        if (!this.noevents) {
          if (md.arg0 === "currentnsp") {
            let nv = md.arg2;
            if (nv === SemTalkLanguage.German) {
              this.base.SetModelAttribute(ModelAttribute.forder, SemTalkComposeOrder.NounVerb);
            } else {
              this.base.SetModelAttribute(ModelAttribute.forder, SemTalkComposeOrder.VerbNoun);
            }
            this.redraw();
          }
        }
        break;
    }
  }
  public getMessage = (e: any) => {
    this.eventlistener(e);
  }

  private ensureSynonym(oid: SemTalkID) {
    const cla = this.base.FindClassByID(oid);
    if (cla) {
      let newname = cla.ObjectName;
      let currnsp = this.base.CurrentNsp;
      // if (currnsp.length > 0 && (newname.indexOf(".") < 0 || newname.indexOf("#") < 0)) {
      if (currnsp.length > 0 && newname.indexOf("." + cla.ID) < 0) {
        let syn = newname;
        if (syn.indexOf("#") > 0) {
          syn = syn.substr(syn.indexOf("#") + 1);
        }
        cla.MakeSynonym(syn, currnsp);
      }
    }
  }

  public ensureCallBack(): void {
    const hostPageMessage: any = {};
    hostPageMessage.message = "getHostSemTalkHomePage";
    // hostPageMessage.senderId = stnavigation.senderId;
    const hostPageMessageString = JSON.stringify(hostPageMessage);
    try {
      window.parent.postMessage(hostPageMessageString, document.referrer);
    } catch (ex) {
      try {
        window.parent.postMessage(hostPageMessageString, document.referrer);
      } catch (ex1) { }
    }
  }

  public getSPListName(sname: string): string {
    let sbuf = this.base.GetModelAttribute(Process_ElementName.SLBuffer);
    let shum = this.base.GetModelAttribute(Process_ElementName.SLResource);
    let spos = this.base.GetModelAttribute(Process_ElementName.SLPosition);
    let srol = this.base.GetModelAttribute(Process_ElementName.SLRole);
    let sorg = this.base.GetModelAttribute(Process_ElementName.SLOrgUnit);
    let spers = this.base.GetModelAttribute(Process_ElementName.SLPerson);
    let sinf = this.base.GetModelAttribute(Process_ElementName.SLInformation);
    let sphys = this.base.GetModelAttribute(Process_ElementName.SLPhysResource);

    if (sname === sbuf) return "Buffers";
    if (sname === "SystemToolApplication") return "Buffers";
    if (sname === shum) return "HumanResources";
    if (sname === "OrgChartElement") return "HumanResources";
    if (sname === "Personentyp") return "HumanResources";
    if (sname === "Position") return "HumanResources";
    if (sname === "Role") return "HumanResources";
    if (sname === srol) return "HumanResources";
    if (sname === spos) return "HumanResources";
    if (sname === spers) return "HumanResources";
    if (sname === sorg) return "HumanResources";
    if (sname === sphys) return "PhysResources";
    if (sname === sinf) return "Objects";
    if (sname === "Method") return "Method";
    if (sname === "State") return "State";
    if (sname === "Attribute") return "AttributeType";
    return sname;
  }
  public currentTransaction(): string {
    return this.transaction;
  }
  public beginTransaction(reason: string) {
    if (this.IsAutoSave && this.transaction === "") {
      // console.debug("beginTransaction: " + reason);
      this.transaction = reason;
    }
  }

  public endTransaction(reason: string) {
    this.ismodified = true;
    if (!document.title.endsWith(" *")) {
      document.title += " *";
    }

    if (this.IsAutoSave && this.transaction === reason) {
      if (this.tseditor) {
        let mxgraphxml = this.tseditor.getGraphXml();
        if (mxgraphxml) {
          this.autoSave(mxgraphxml, reason);
        }
      }
      this.transaction = "";
    }
  }
  public autoSave(mxgraphxml: string, reason: string) {
    this.clearUndo();
    let pg = this.page;
    if (pg !== null && pg !== undefined) {
      if (reason === "") {
        reason = "AutoSaved";
      }
      const ob = this.base;
      if (this.tseditor && mxgraphxml === "") mxgraphxml = this.tseditor.getGraphXml();
      // console.debug(xml);
      if (mxgraphxml === "") {
        this.alert("Empty mxGraph!!", MessageBarType.error);
        return;
      }
      let encoded = VisioRDFS.encodelzutf8(mxgraphxml);
      let ld = ob._loading;
      ob._loading = true;
      ob.SetModelAttribute(SemTalkBaseConstant.SLMXGPagePrefix + pg.ID, encoded);
      pg.SetValue(SemTalkBaseConstant.SLMXGAttribute, SemTalkBaseConstant.SLMXGPagePrefix + pg.ID);
      ob._loading = ld;
      // this.setState({
      //   xmlgraph: xml,
      //   diag: pg
      // });
      const o2j = new OB2JSON();
      let tmp = o2j.SaveJSON(ob);
      tmp.transaction = reason;
      tmp.currentpage = pg.ID;
      tmp.tempId = this.tempId;
      tmp.readonly = this.readonly;
      //  console.debug(tmp);
      let s: string = "";
      try {
        s = JSON.stringify(tmp);
      } catch (e) {
        console.debug(e);
        return;
      }
      let s0 = accessCookie(SemTalkCookie.autosaveSemTalk);
      if (s === s0) {
        // console.debug("no autosaving needed");
      } else {
        //  console.debug("endUndoScope: " + reason);
        try {
          setCookie(SemTalkCookie.autosaveSemTalk, s);
        } catch (e) {
        }
        try {
          if (this.undoManger !== null &&
            reason !== "reloadFromSettingsPanel" &&
            reason !== "AutoSaved" &&
            reason !== "loadDiag")
            // this.undoManger.SaveUndo(s0);
            if (s0) {
              this.undoManger.SaveUndo(s0, reason);
            }
        } catch (e) {
        }
      }
      //   gotoDocument(pg.ID);
    }
  }

  public InsertObjects = (newobjs: ISemTalkClass[], x: number, y: number, expand_links: boolean, expand_subclasses: boolean,
    shapeStyle: string, pg: ISemTalkDiagram, reusenodes: boolean): void => {
    let dx = x + 30;
    let dy = y + 30;
    let i = 1;
    let cells: any = {};
    let k = 10;
    let l = newobjs.length;
    if (l > 1) {
      k = round(Math.sqrt(l));
    }
    let pid: string = "-1";
    if (reusenodes && pg) {
      pid = pg.ID;
    }
    for (let newobj of newobjs) {
      let onds = newobj.Nodes().filter(n => n.Diagram.ID === pid);
      if (onds.length < 1) {
        let cell = this.InsertObject(newobj, dx, dy, shapeStyle, pg);
        if (cell !== null) {
          let s: string = newobj.ID + "_";
          cells[s] = cell;
          let geo = cell.geometry;
          dx = dx + geo.width + 50;
          i += 1;
          if (i > k) {
            i = 1;
            dx = x + 30;
            dy = dy + geo.height + 50;
          }
        }
      }
    }
    if (expand_links) {
      let noev = this.noevents;
      this.noevents = true;
      for (let newobj of newobjs) {
        let cell = cells[newobj.ID + "_"];
        if (pg && cell) {
          for (let lnk of newobj.Associations()) {
            if (lnk.Nodes().filter(n => n.Diagram.ID === pg.ID).length === 0) {
              let oth = lnk.ToObject;
              let onds = oth.Nodes().filter(n => n.Diagram.ID === pg.ID);
              if (onds.length > 0) {
                let oshape = this.FindShape(oth);
                if (oshape) {
                  let estyle = lnk.ClassOf().Style;
                  let eshape = this.createEdge(cell, oshape, estyle);
                  eshape.objectid = lnk.ID;
                  eshape.shapeid = this.getMxId(eshape);
                  let relcla = lnk.ClassOf();
                  let nd = this.base.MakeAssociationNode(pg, lnk, this.getMxId(eshape), relcla.ObjectName);
                  nd.FromShape = this.getMxId(eshape.source);
                  nd.OtherShape = this.getMxId(eshape.target);
                  this.UpdateLabel(lnk);
                }
              }
            }
          }
          for (let lnk of newobj.InvAssociations()) {
            if (lnk.Nodes().filter(n => n.Diagram.ID === pg.ID).length === 0) {
              let oth = lnk.FromObject;
              let onds = oth.Nodes().filter(n => n.Diagram.ID === pg.ID);
              if (onds.length > 0) {
                let oshape = this.FindShape(oth);
                if (oshape) {
                  let estyle = lnk.ClassOf().Style;
                  let eshape = this.createEdge(oshape, cell, estyle);
                  eshape.objectid = lnk.ID;
                  eshape.shapeid = this.getMxId(eshape);
                  let relcla = lnk.ClassOf();
                  let nd = this.base.MakeAssociationNode(pg, lnk, this.getMxId(eshape), relcla.ObjectName);
                  nd.FromShape = this.getMxId(eshape.source);
                  nd.OtherShape = this.getMxId(eshape.target);
                  this.UpdateLabel(lnk);
                }
              }
            }
          }
          if (this.base.IsClass(newobj) && newobj.ObjectType === SemTalkType.SemTalkClass) {
            let newcl = newobj as ISemTalkClass;
            for (let ins of newcl.Instances()) {
              let onds = ins.Nodes().filter(n => n.Diagram.ID === pg.ID);
              if (onds.length > 0) {
                let oshape = this.FindShape(ins);
                if (oshape) {
                  let rt = this.base.FindAssociationType(SemTalkBaseConstant.SLInstanceOf);
                  if (rt) {
                    rt.RelationType = SemTalkRelation.SemTalkInstanceOf;
                    let lnk = ins.MakeAssociation(rt, newcl, SemTalkRelation.SemTalkInstanceOf);

                    let estyle = lnk.ClassOf().Style;
                    let eshape = this.createEdge(oshape, cell, estyle);
                    eshape.objectid = lnk.ID;
                    eshape.shapeid = this.getMxId(eshape);
                    let relcla = lnk.ClassOf();
                    let nd = this.base.MakeAssociationNode(pg, lnk, this.getMxId(eshape), relcla.ObjectName);
                    nd.FromShape = this.getMxId(eshape.source);
                    nd.OtherShape = this.getMxId(eshape.target);
                    this.UpdateLabel(lnk);
                  }
                }
              }
            }
          }
        }
        this.noevents = noev;
      }
    }
    if (expand_subclasses) {
      let ob = this.base;
      let sco = ob.FindAssociationType(SemTalkBaseConstant.SLSubClassOf);
      for (let newobj of newobjs) {
        let cell = cells[newobj.ID + "_"];
        if (cell && sco && ob.IsClass(newobj)) {
          let specs = (newobj as ISemTalkClass).Links(sco, SemTalkRelation.SemTalkSubClassOf);
          let invspecs = (newobj as ISemTalkClass).InvLinks(sco, SemTalkRelation.SemTalkSubClassOf);
          specs.push(...invspecs);
          this.ExpandObject(cell, specs);
        }
      }
    }
    if (this.tseditor) {
      this.tseditor.refresh();
    }
  }
  public ImportTermSet = (sysc: ISemTalkSystemClass, items: ITermSetItem[], x: number, y: number): void => {
    const bas = this.base;
    //    const sysc = this.state.sysclass;
    let htable: any = {};
    let ctable: any = {};
    let term: ISemTalkSystemClass;
    if (sysc !== undefined && sysc !== null) {
      let tname = sysc.GetPrefix() + "#" + "Term";
      term = bas.MakeSystemClass(tname);
      if (!term.IsParent(sysc)) {
        term.AddSubclassOf(sysc);
      }
    } else {
      let tname = "Term";
      term = bas.MakeSystemClass(tname);
    }
    // term.MakeAttribute("TermID", 0);
    let pg = this.page;
    if (pg) {
      for (let sel1 of items) {
        let t: ITermSetItem = sel1 as ITermSetItem;
        // let oname = t.ObjectName;
        let oname = t.ObjectName;
        if (oname.indexOf("#") < 0) {
          if (sysc !== undefined && sysc !== null) {
            oname = sysc.GetPrefix() + "#" + oname;
          } else {
            oname = term.GetPrefix() + "#" + oname;
          }
        }
        let obj = bas.FindClass(oname);
        if (obj !== null) {
          // if (obj.IsParent(sysc)) {
          //   cb(this.props.semtalk, this.props.semtalk.shape, obj);
          // } else {
          //   alert("You cannot select:" + oname);
          // }
        } else {
          if (bas.IsBusinessClass(sysc)) {
            let bc = bas.MakeBusinessClass(oname);
            if (bc !== null) {
              if (!bc.IsParent(term)) {
                bc.AddSubclassOf(term);
              }
              obj = bc;
            } else {
              obj = term.MakeSubClass(oname);
            }
          } else {
            obj = term.MakeSubClass(oname);
          }
        }
        if (t.Term.descriptions.length > 0)
          obj.Comment = t.Term.descriptions[0].description;
        for (let l of t.Term.labels) {
          let termlabel = l.name.replace('＂', " ");
          obj.MakeSynonym(termlabel, this.decodeLang(l.languageTag));
        }
        htable[t.ObjectName] = obj;
        ctable[t.ObjectName] = t.Children;
        // obj.SetValue("TermID", t.ObjectName);
      }
      for (let id in htable) {
        let obj = htable[id] as ISemTalkClass;
        for (let child of ctable[id]) {
          let cobj = htable[child.termid] as ISemTalkClass;
          if (obj !== null && cobj !== undefined && !cobj.IsParent(obj)) {
            cobj.AddSubclassOf(obj);
          }
        }
        // console.debug(obj);
      }
      let newobjs: any[] = [];
      for (let id in htable) {
        let obj = htable[id] as ISemTalkClass;
        newobjs.push(obj);
      }
      this.InsertObjects(newobjs, x, y, false, true, "", pg, true);
    }
  }
  public ImportTermSetTree = (syscname: string, items: any[], x: number, y: number): void => {
    const bas = this.base;
    let htable: any = {};
    let ctable: any = {};
    let tname = syscname;
    let baseclass = bas.FindClass(tname);
    if (!baseclass && syscname.length > 0) {
      baseclass = bas.MakeClass(tname);
    }
    let pg = this.page;
    if (pg) {
      for (let sel1 of items) {
        let t: any = sel1.data;
        if (!t) continue;
        // let oname = t.ObjectName;
        let termid = sel1.termid;
        let oname = sel1.label;
        let obj = bas.FindClassByID(termid);
        if (!obj) {
          obj = bas.FindClass(oname);
        }
        if (obj !== null) {
          // if (obj.IsParent(sysc)) {
          //   cb(this.props.semtalk, this.props.semtalk.shape, obj);
          // } else {
          //   alert("You cannot select:" + oname);
          // }
        } else {
          if (baseclass) {
            if (bas.IsBusinessClass(baseclass)) {
              let bc = bas.MakeBusinessClass(oname, termid);
              if (bc !== null) {
                if (!bc.IsParent(baseclass)) {
                  bc.AddSubclassOf(baseclass);
                }
                obj = bc;
              } else {
                obj = baseclass.MakeSubClass(oname, termid);
              }
            } else {
              obj = baseclass.MakeSubClass(oname, termid);
            }
          } else {
            obj = bas.MakeClass(oname, termid);
          }
        }
        if (t.descriptions && t.descriptions.length > 0) {
          obj.Comment = t.descriptions[0].description;
        }
        if (t.description) {
          obj.Comment = t.description;
        }
        for (let l of t.labels) {
          let termlabel = l.name.replace('＂', " ");
          obj.MakeSynonym(termlabel, this.decodeLang(l.languageTag));
        }
        htable[sel1.termid] = obj;
        ctable[sel1.termid] = sel1.children;
        // obj.SetValue("TermID", t.ObjectName);
      }
      for (let id in htable) {
        let obj = htable[id] as ISemTalkClass;
        if (ctable[id]) {
          for (let child of ctable[id]) {
            let ct = child.data;
            let cobj = htable[ct.id];
            if (obj !== null && cobj !== undefined && !cobj.IsParent(obj)) {
              cobj.AddSubclassOf(obj);
            }
          }
        }
        // console.debug(obj);
      }
      let newobjs: any[] = [];
      if (baseclass) {
        newobjs.push(baseclass);
      }
      for (let id in htable) {
        let obj = htable[id] as ISemTalkClass;
        newobjs.push(obj);
      }
      this.InsertObjects(newobjs, x, y, false, true, "", pg, true);
    }
  }
  public ImportPlannerTasks = (_syscname: string, _items: any[]): void => {
    // // console.debug(items);
    // const bas = this.base;
    // // let colname = syscname;
    // let dx = 100;
    // let taskmaster = this.masters.find(x => x.key === BPMN_ElementName.Task);
    // if (taskmaster) {
    //   dx = taskmaster.width * 1.5;
    // }
    // let tsk = this.base.FindSystemClass(BPMN_ElementName.Task);
    // if (!tsk) return;
    // let activities = tsk.AllInstances();
    // let fitems = items.filter(x => activities.find(y => y.ID === x.id) === undefined);
    // // let mst = this.masters.find(x => x.key === colname);
    // let mst = taskmaster;
    // if (mst && fitems.length > 0) {
    //   const swheight = 150;
    //   let sly = 50;
    //   let slx = 150;
    //   let lst: mxCell | null = null;
    //   for (let s of this.selectedShapes()) {
    //     lst = s;
    //     break;
    //   }
    //   let xpos: number = 0;
    //   let ypos: number = swheight / 2 - mst.height / 2;
    //   let swshape: mxCell | null = null;
    //   if (lst !== null) {
    //     swshape = lst.parent;
    //     xpos = lst.geometry.x;
    //     ypos = lst.geometry.y + lst.geometry.height / 2 - mst.height / 2;
    //   } else {
    //     let pashape = this.insertSwimlane(null, slx, sly, 600, swheight);
    //     swshape = this.insertSwimlane(pashape, slx, sly, 600, swheight);
    //   }
    //   let ss = this.GetShapeStyle(BPMN_ElementName.Task, SemTalkShapeType.bpmn);
    //   if (swshape) {
    //     let shapeStyle = ss.style;
    //     for (let item of fitems) {
    //       xpos += dx;
    //       if (item) {
    //         let shp = this.createVertex(swshape, "", "", xpos - mst.width / 2, ypos,
    //           mst.width, mst.height, shapeStyle);
    //         shp.shapeName = BPMN_ElementName.Task;
    //         shp.shapeKey = BPMN_ElementName.Task;
    //         let nd: any = this.visShapeAdded(shp, item.id);
    //         let obj1: ISemTalkObject | null;
    //         if (nd) {
    //           obj1 = bas.FindObjectByID(nd.ObjectID);
    //           if (obj1) {
    //             this.RenameObject(obj1, item.title, shp);
    //           }
    //         }
    //         if (lst) {
    //           let lnk = this.createEdge(lst, shp);
    //           this.visConnectionAdded(undefined, lnk, lst, shp);
    //           lst = shp;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  // private _languagedropdownOptions = [
  //   { key: SemTalkLanguageCode.English, text: SemTalkLanguage.English },
  //   { key: SemTalkLanguageCode.German, text: SemTalkLanguage.German },
  //   { key: SemTalkLanguageCode.Japanese, text: SemTalkLanguage.Japanese },
  //   { key: SemTalkLanguageCode.Spanish, text: SemTalkLanguage.Spanish },
  //   { key: SemTalkLanguageCode.Russian, text: SemTalkLanguage.Russian },
  //   { key: SemTalkLanguageCode.Simplified, text: SemTalkLanguage.Simplified },
  //   { key: SemTalkLanguageCode.Traditional, text: SemTalkLanguage.Traditional },
  //   { key: "sc", text: SemTalkLanguage.Simplified },
  //   { key: "tc", text: SemTalkLanguage.Traditional },
  // ];

  private decodeLang(s: string): SemTalkLanguage {
    // for (let lang of this._languagedropdownOptions) {
    //   if (lang.key === s || s.startsWith(lang.key + "-")) {
    //     return lang.text;
    //   }
    // }
    if (s.indexOf("-") === 2) {
      return Code2Language(s.substring(0, 2) as SemTalkLanguageCode);
    }
    return SemTalkLanguage.English;

  }
  public IsUndo = (): boolean => {
    if (this.tseditor && this.tseditor.isUndo()) return true;
    if (this.undoManger === undefined || this.undoManger === null) return false;
    return this.undoManger.IsUndo();
  }
  public IsRedo = (): boolean => {
    if (this.tseditor && this.tseditor.isRedo()) return true;
    if (this.undoManger === undefined || this.undoManger === null) return false;
    let s1: string | null = this.undoManger.GetRedo();
    if (s1 === null) return false;
    return (s1.length > 0);
  }
  public clearUndo = (): void => {
    if (this.tseditor) {
      this.tseditor.clearUndo();
    }
  }

  public quickShapes = (oid: SemTalkID, stencil: SemTalkStencil): SemTalkStencil => {
    const ob = this.base;
    let targets: string[] = [];
    const badlist = [SemTalkBaseConstant.SLComment, SemTalkBaseConstant.SLSwimlane,
    ob.GetModelAttribute(Process_ElementName.SLDataObject)];
    const src = ob.FindObjectByID(oid);
    if (src) {
      let sc: ISemTalkObject | null = src.SystemClass();
      if (sc === null) sc = src;
      if (sc && badlist.indexOf(sc.ObjectName) < 0) {
        // switch (sc.ObjectName) {
        //   default: {
        const diag = this.page;
        if (diag) {
          let isclass = diag.ClassOf().IsClass;
          let isuml = diag.ClassOf().UMLShape;
          let isgeneric = diag.ClassOf().ObjectName === SemTalkBaseConstant.SLGeneric;
          switch (src.ObjectType) {
            case SemTalkType.SemTalkClass: {
              if (isclass || isgeneric)
                if (isuml) {
                  targets.push(SemTalkMaster.MasterUMLClass);
                } else {
                  targets.push(SemTalkMaster.MasterClass);
                }
              if (isgeneric)
                targets.push(SemTalkMaster.MasterInstance);
              targets.push(SemTalkMaster.MasterSubClassOf);
              targets.push(SemTalkMaster.MasterProperty);
              break;
            }
            case SemTalkType.SemTalkInstance: {
              if (isgeneric) {
                // targets.push(SemTalkMaster.MasterInstance);
                targets.push(SemTalkMaster.MasterInstanceOf);
                targets.push(SemTalkMaster.MasterProperty);
              }
              break;
            }
          }
          //   }
          // }
        }
      }
    }
    let masters: any[] = [];
    for (let key of targets)
      for (let m of stencil) {
        if (m.name === key)
          masters.push(m);
      }
    return masters;
  }
  public selectedShapes(): mxCell[] {
    if (this.tseditor) {
      return this.tseditor.getSelectionCells();
    } else return [];
  }
  public clearSelection(): void {
    if (this.tseditor) {
      this.tseditor.clearSelection();
    }
  }

  public updateDiaglist(): void {
    let lis: { text: string, key: SemTalkID, unumber: number, diag: ISemTalkDiagram }[] = [];
    let alist = this.base.AllDiagrams();

    for (const dt of alist) {
      let txt = dt.ObjectCaption;
      let un = dt.GetValue(SemTalkBaseConstant.SLUserNumber);
      if (un) {
        txt = un + " " + txt;
      }
      lis.push({ text: txt, key: dt.ID, unumber: Number(un), diag: dt });
    }
    if (this.tseditor) {
      lis.sort((a, b): number => {
        if (a.unumber === 0) {
          return a.text.localeCompare(b.text);
        }
        return a.unumber - b.unumber;
      });
      this.tseditor.updateDiaglist(lis);
    }
  }
  public static decode(s: any): any {
    return base64.decode(s);
  }
  public static decodelzutf8(s: any): any {
    let ss = "";
    try {
      // var b = Buffer.from(s, 'base64');
      ss = lzutf8.decompress(s, { inputEncoding: "Base64" });
    } catch {
      try {
        ss = base64.decode(s);
      } catch {
        ss = "";
      }
    }
    return ss;
  }
  public static encode(s: any): any {
    return base64.encode(s);
  }
  public static encodelzutf8(s: any): any {
    let ss = lzutf8.compress(s, { outputEncoding: "Base64" });
    return ss;
  }
  public initStencil = async (
    _dtype: ISemTalkDiagramType, stencil: string, sprops: ISharePointSettings, _showBPMN: boolean,
    loadStencil: (template: string, url: string, spinterface: ISPExplorer) => Promise<SemTalkStencil>) => {
    const stencilurl: string = sprops.site + "/" + sprops.templates + "/" + stencil + "-shapes.ssx";
    let CUSTOMPROC_STENCIL = await loadStencil(stencil.replace(".stx", "") + "-shapes.ssx", stencilurl, this.explorer);
    return CUSTOMPROC_STENCIL;
  }
  public initStencils = async (ob: IObjectBase, sem: IVisioRDFS,
    guilanguage: SemTalkLanguageCode,
    sprops: ISharePointSettings, bpmnrules: boolean,
    role: SemTalkRole,
    loadStencil: (template: string, url: string, spinterface: ISPExplorer) => Promise<SemTalkStencil>) => {

    let template = ob.GetModelAttribute(ModelAttribute.Template);
    if (template === "BPMN20.vst" || template === "bpmn20-2010.vstx" || template === "bpmn20-2010.vst") {
      template = "bpmn20-2016.stx";
      ob.SetModelAttribute(ModelAttribute.Template, template);
    }
    let slproc = ob.GetModelAttribute(Process_ElementName.SLProc);
    if (template === "semtalk.vst") {
      if (slproc === "Business Process Diagram") template = "bpmn20-2016.vst";
      if (slproc === "eEPC") template = "epc.vst";
      ob.SetModelAttribute(ModelAttribute.Template, template);
    }
    template = template.replace(".vstx", "");
    template = template.replace(".vst", "");
    if (!template.endsWith(".stx")) template += ".stx";


    let support_lib = sprops.site + "/" + sprops.support;

    // let proc = ob.FindDiagramType(ob.GetModelAttribute(Process_ElementName.SLProc));

    this.stencil_registry = {};
    let general = this.getResStr(ResID.STRGENERAL);
    for (let dg of ob.AllDiagramTypes()) {
      // if (dg.ObjectName===proc?.ObjectName) continue;
      if (dg.ObjectName === SemTalkBaseConstant.SLGeneric) continue;
      let stc = await this.initStencil(dg, template, sprops, bpmnrules, loadStencil);
      let stencil: SemTalkStencil = JSON.parse(JSON.stringify(stc));
      this.stencil_registry[dg.ObjectName] = stencil;
      for (let mst of stencil) {
        if (mst.logo) {
          let l = mst.logo;
          if (l.indexOf("/images") === 0) {
            let cdn = sprops.cdn;
            let mtyp = mst.type;
            if (l.indexOf(".svg") > -1 && mtyp !== SemTalkShapeType.general &&
              mtyp !== SemTalkShapeType.svg &&
              mtyp !== SemTalkShapeType.png &&
              mtyp !== SemTalkShapeType.image &&
              cdn.length > 0 && sprops.context) {
              if (cdn === support_lib + "/images/symbols/")
                cdn = "https://semtalkportal45.azurewebsites.net/";
              let l0 = l.replace("/images", cdn + "Support/images");
              mst.logo = l0;
            } else {
              mst.logo = support_lib + l;
            }
          } else {
          }
          let sc = ob.FindSystemClass(mst.name);
          if (sc) {
            mst.label = sc.ID2NameNspLan(Code2Language(guilanguage));
          } else {
            switch (mst.name) {
              case "Image": { mst.label = this.getResStr(ResID.STRImage); break; }
              case "Text": { mst.label = this.getResStr(ResID.STRTEXTTOOL); break; }
              default: {
                mst.label = mst.name;
              }
            }
          }
        }
        if (mst.sticky) {
          mst.sticky = this.getResStr(mst.sticky);
        } else {
          mst.sticky = general;
        }
        if (mst.width) mst.width *= this.scaleshapes;
        if (mst.height) mst.height *= this.scaleshapes;
      }
    }

    let CLASS_STENCIL0: SemTalkStencil = [];
    this.stencil_registry[Process_ElementName.SLClassModel] = CLASS_STENCIL0;
    CLASS_STENCIL0.push(...JSON.parse(JSON.stringify(CLASS_STENCIL)));
    for (let mst of CLASS_STENCIL0) {
      if (mst.logo) {
        let l = mst.logo;
        if (l.indexOf("/images") === 0) {
          let cdn = sprops.cdn;
          let mtyp = mst.type;
          if (l.indexOf(".svg") > -1 && mtyp !== SemTalkShapeType.general &&
            mtyp !== SemTalkShapeType.svg &&
            mtyp !== SemTalkShapeType.png &&
            mtyp !== SemTalkShapeType.image &&
            cdn.length > 0 && sprops.context) {
            if (cdn === support_lib + "/images/symbols/")
              cdn = "https://semtalkportal45.azurewebsites.net/";
            let l0 = l.replace("/images", cdn + "Support/images");
            mst.logo = l0;
          } else {
            mst.logo = support_lib + l;
          }
        }
        let sc = ob.FindSystemClass(mst.name);
        if (sc) {
          mst.label = sc.ID2NameNspLan(Code2Language(guilanguage));
        } else {
          if (sem && mst.label) {
            mst.label = sem.getResStrListener(mst.label, guilanguage);
          } else {
            switch (mst.name) {
              case "Image": { mst.label = this.getResStr(ResID.STRImage); break; }
              case "Text": { mst.label = this.getResStr(ResID.STRTEXTTOOL); break; }
              default: {
                mst.label = mst.name;
              }
            }
          }
        }
      }
    }
    let UMLCLASS_STENCIL0: SemTalkStencil = [];
    this.stencil_registry[Process_ElementName.SLObjectModel] = UMLCLASS_STENCIL0;
    UMLCLASS_STENCIL0.push(...JSON.parse(JSON.stringify(UMLCLASS_STENCIL)));
    for (let mst of UMLCLASS_STENCIL0) {
      if (mst.logo) {
        let l = mst.logo;
        if (l.indexOf("/images") === 0) {
          let cdn = sprops.cdn;
          let mtyp = mst.type;
          if (l.indexOf(".svg") > -1 && mtyp !== SemTalkShapeType.general &&
            mtyp !== SemTalkShapeType.svg &&
            mtyp !== SemTalkShapeType.png &&
            mtyp !== SemTalkShapeType.image &&
            cdn.length > 0 && sprops.context) {
            if (cdn === support_lib + "/images/symbols/")
              cdn = "https://semtalkportal45.azurewebsites.net/";
            let l0 = l.replace("/images", cdn + "Support/images");
            mst.logo = l0;
          } else {
            mst.logo = support_lib + l;
          }
        }
        let sc = ob.FindSystemClass(mst.name);
        if (sc) {
          mst.label = sc.ID2NameNspLan(Code2Language(guilanguage));
        } else {
          if (sem && mst.label) {
            switch (mst.label) {
              case "Image": { mst.label = this.getResStr(ResID.STRImage); break; }
              case "Text": { mst.label = this.getResStr(ResID.STRTEXTTOOL); break; }
              // case "SubClassOf": { mst.label = this.getResStrListener(ResIDL.STRSPECIALIZATION"); break; }
              case "Property": { mst.label = this.getResStrListener(ResIDL.STRRELATION); break; }
              case "Connector": { mst.label = this.getResStrListener(ResIDL.STRRELATION); break; }
              default: {
                mst.label = sem.getResStrListener(mst.label, guilanguage);
              }
            }
          } else {
          }
        }
      }
    }
    let GENERIC_STENCIL0: SemTalkStencil = [];
    GENERIC_STENCIL0.push(...JSON.parse(JSON.stringify(GENERIC_STENCIL)));
    if (role !== SemTalkRole.admin && role !== SemTalkRole.metamodel) {
      let metamasters: string[] = [
        SemTalkMaster.MasterSystemClass,
        SemTalkMaster.MasterAttributeType,
        SemTalkMaster.MasterAssociationType,
        SemTalkMaster.MasterDiagramType,
      ];
      GENERIC_STENCIL0 = GENERIC_STENCIL0.filter(x => metamasters.indexOf(x.key) < 0);
    }
    this.stencil_registry[SemTalkBaseConstant.SLGeneric] = GENERIC_STENCIL0;
    for (let mst of GENERIC_STENCIL0) {
      if (mst.logo) {
        let l = mst.logo;
        if (l.indexOf("/images") === 0) {
          let cdn = sprops.cdn;
          let mtyp = mst.type;
          if (l.indexOf(".svg") > -1 && mtyp !== SemTalkShapeType.general &&
            mtyp !== SemTalkShapeType.svg &&
            mtyp !== SemTalkShapeType.png &&
            mtyp !== SemTalkShapeType.image &&
            cdn.length > 0 && sprops.context) {
            if (cdn === support_lib + "/images/symbols/")
              cdn = "https://semtalkportal45.azurewebsites.net/";
            let l0 = l.replace("/images", cdn + "Support/images");
            mst.logo = l0;
          } else {
            mst.logo = support_lib + l;
          }
        } else {
        }
        let sc = ob.FindSystemClass(mst.name);
        if (sc) {
          mst.label = sc.ID2NameNspLan(Code2Language(guilanguage));
        } else {
          if (sem && mst.label) {
            switch (mst.label) {
              case "Image": { mst.label = this.getResStr(ResID.STRImage); break; }
              case "Text": { mst.label = this.getResStr(ResID.STRTEXTTOOL); break; }
              // case "SubClassOf": { mst.label = this.getResStrListener(ResIDL.STRSPECIALIZATION); break; }
              case "Property": { mst.label = this.getResStrListener(ResIDL.STRRELATION); break; }
              case "Connector": { mst.label = this.getResStrListener(ResIDL.STRRELATION); break; }
              default: {
                mst.label = sem.getResStrListener(mst.label, guilanguage);
              }
            }
          } else {
          }
        }
      }
    }
    return template;
  }

  public getStencils = (): SemTalkStencil[] => {
    return Object.values(this.stencil_registry);
  }
  public getStencil = (dclass: ISemTalkDiagramType): SemTalkStencil => {
    let stencil: SemTalkStencil = [];
    let dclassname = dclass.ObjectName;
    if (dclass.CustomStencil.length > 0) {
      return dclass.CustomStencil;
    }
    const ob = dclass.ObjectBase;
    switch (dclassname) {
      case SemTalkBaseConstant.SLGeneric: {
        stencil = this.stencil_registry[SemTalkBaseConstant.SLGeneric];
        break;
      }
      case ob.GetModelAttribute(Process_ElementName.SLProc): {
        stencil = this.stencil_registry[dclassname];
        break;
      }
      // case cit_DiagramTypeName.Dialogmodell:
      //   stencil = this.stencil_registry[dclassname];
      //   break;
      default: {
        let isclass = dclass.IsClass;
        if (isclass) {
          if (dclass.UMLShape) {
            stencil = this.stencil_registry[Process_ElementName.SLObjectModel];
          } else {
            stencil = this.stencil_registry[Process_ElementName.SLClassModel];
          }
          // return this.stencil;
        } else {
          stencil = this.stencil_registry[dclassname];
        }
      }
    }
    return stencil;
  }
  public renderUserTab = (_tab: string, _args: any): any => {
    return "";
  }
  public captionUserTab = (_tab: string): string => {
    return "";
  }

  public deleteShapes = (): void => {
    this.beginTransaction("deleteShapes");
    let sel: any = this.graph.getSelectionCells();
    this.graph.removeCells(sel, true);
    this.endTransaction("deleteShapes");
  }
  public getLinkLabel = (cell: mxCell, tmp: string): string => {
    let width = 60;
    let style: string = cell.style;
    if (!style) {
      style = "";
    }
    let html = '<div style="width: $Widthpx; white-space:normal;">' + tmp + '</div>';
    if (style.indexOf(SemTalkStyleAttribute.labelWidth) > 0) {
      width = Number(getStyleAttribute(style, SemTalkStyleAttribute.labelWidth));
    }
    html = html.replace("$Width", String(width));
    return html;
  }

  public getTextLabel = (cell: mxCell, tmp: string): string => {
    let html = '<div style="margin-left:5px;margin-top:5px;margin-right:5px;margin-bottom:5px;height:$Heightpx; width:$Widthpx; text-align:left; white-space:normal;word-break: break-all' + '">' + "$Group" + '</div>';
    html = html.replace("$Width", String(Number(cell.geometry.width) - 10));
    html = html.replace("$Height", String(Number(cell.geometry.height)));
    html = html.replace("$Group", tmp);
    return html;
  }
  public getLabel = (cell: mxCell, tmp: string): string => {
    let style: string = cell.style;
    if (!style) {
      style = "";
    }
    let align = "";
    if (style.indexOf(SemTalkStyleAttribute.align + "=left") > 0) {
      align = ";text-align:left";
    }
    if (cell.vertex && cell.objectid && cell.shapeKey !== SemTalkMaster.MasterSwimlane) {
      // let html = '<div style="width: ' + "$Width" + 'px; white-space:normal;">' + "$Group" + '</div>';

      let width = Number(cell.geometry.width);
      if (style.indexOf(SemTalkStyleAttribute.labelWidth) > 0) {
        let w = Number(getStyleAttribute(style, SemTalkStyleAttribute.labelWidth));
        if (w >= 60) {
          width = w;
        }
      }

      let wordbreak = "white-space:normal;";
      if (style.indexOf(SemTalkStyleAttribute.wordbreak + "=1") > 0) {
        wordbreak = "white-space:normal;word-break: break-all;";
      }

      // let html = '<div style="min-width: ' + "$Width" + 'px;white-space:normal; padding: 5px 5px 5px 5px' + align + fontfamily + '">' + "$Group" + '</div>';
      // let html = '<div style="white-space:normal; padding: 5px 5px 5px 5px' + align + '">' + "$Group" + '</div>';
      // let html = '<div style="white-space:normal;' + align + '">' + "$Group" + '</div>';
      let html = '<div style="width: ' + "$Width" + 'px;' + wordbreak + 'padding: 5px 5px 5px 5px' + align + '">' + "$Group" + '</div>';
      if (width < 60) {
        // width = 80;
        // html = html.replace("$Width", String(width * 0.9));
        html = '<div style="white-space:normal;' + align + '">' + "$Group" + '</div>';
      } else {
        html = html.replace("$Width", String(width * 0.9));
      }

      // if (cell.shapeKey === SemTalkMaster.MasterComment) {
      //   html = '<div style="margin-left:5px;margin-top:5px;margin-right:5px;margin-bottom:5px;height:$Heightpx; width:$Widthpx; text-align:left;' + wordbreak + '">' + "$Group" + '</div>';
      //   html = html.replace("$Width", String(Number(cell.geometry.width) - 10));
      //   html = html.replace("$Height", String(Number(cell.geometry.height)));
      // }
      // if (obj) { } else {
      //   return tmp;
      // }
      let obj = this.visCellSystemClass(cell.objectid);
      if (obj && obj.ShapeHTML.length > 0) {
        html = obj.ShapeHTML;
      }
      let s: string = cell.style;
      if ((cell.shapeKey === SemTalkMaster.MasterUMLClass &&
        s.startsWith(SemTalkStyleAttribute.shape + "=" + SemTalkBuiltInShape.umlclass + ";")) ||
        (cell.shapeKey === SemTalkMaster.MasterClass && s.startsWith(SemTalkStyleAttribute.shape + "=" + SemTalkBuiltInShape.genericclass + ";") &&
          s.indexOf(SemTalkStyleAttribute.isuml + "=0") < 0) ||
        (cell.shapeKey === SemTalkMaster.MasterSystemClass && s.startsWith(SemTalkStyleAttribute.shape + "=" + SemTalkBuiltInShape.genericclass + ";") &&
          s.indexOf(SemTalkStyleAttribute.isuml + "=0") < 0)) {
        let cla = this.base.FindClassByID(cell.objectid);
        if (cla) {
          let claname = cla.ID2Name;
          if (claname === SemTalkMaster.MasterClass + "." + cla.ID) {
            claname = "";
          }
          if (claname === SemTalkMaster.MasterSystemClass + "." + cla.ID) {
            claname = "";
          }
          if (claname === cla.ID2Name) {
            claname = cla.ObjectCaption;
          }
          tmp = '<table align="top" style="width:100%; border-style:solid; border-width:0px;">' +
            '<tr align="center" style="vertical-align:top; padding: 10px 15px 10px 15px"><td><b>' +
            claname + '</b></td></tr>' +
            '</table>';
          html = '<div style="height:$Heightpx; width:$Widthpx;white-space:normal; padding: 5px 5px 5px 5px' + '">' + "$Group" + '</div>';
          html = html.replace("$Width", String(Number(cell.geometry.width)));
          html = html.replace("$Height", String(Number(cell.geometry.height)));
        }

      }
      html = html.replace("$Group", tmp);
      return html;
      //  return '<div style="width: ' + cell.geometry.width + 'px; white-space:normal;">' + tmp + '</div>';
    }
    if (!cell.vertex && cell.objectid) {
      let width = 60;
      let html = '<div style="width: $Widthpx; white-space:normal;' + align + '">' + tmp + '</div>';
      if (style.indexOf(SemTalkStyleAttribute.labelWidth) > 0) {
        width = Number(getStyleAttribute(style, SemTalkStyleAttribute.labelWidth));
      }
      html = html.replace("$Width", String(width));
      return html;
    }
    return tmp;
  }
  public getTooltipForCell = (oid: string): string => {
    let obj = this.base.FindObjectByID(oid);
    let txt = "";
    if (obj) {
      let sc = obj.SystemClass();
      if (sc) {
        let comment = this.base.GetModelAttribute(ModelAttribute.SLComment);
        switch (sc.ObjectName) {
          case Process_ElementName.Swimlane: {
            for (let res of obj.LinkedObjects(SemTalkBaseConstant.SLDisplays)) {
              obj = res;
              sc = obj.SystemClass();
              break;
            }
            break;
          }
          case comment: {
            let dof = this.base.GetModelAttribute(ModelAttribute.SLCommentOf);
            for (let res of obj.LinkedObjects(dof, false, SemTalkRelation.SemTalkSystemRelation)) {
              return res.Comment;
            }
            break;
          }

        }
        if (sc) {
          let cap = obj.ObjectCaption;
          let scap = sc.ObjectCaption;
          txt = scap;
          if (scap !== cap) {
            txt += ": " + cap;
          } else {
            let infotype = this.base.GetModelAttribute(Process_ElementName.SLInfoType);
            if (infotype) {
              for (let res of obj.LinkedObjects(infotype)) {
                let rcap = res.ObjectCaption;
                txt += ": " + rcap;
                break;
              }
            }
          }
          if (obj.Comment) {
            txt += "\n" + obj.Comment;
          }
        }
      }
    }
    return txt;
  }
  private _ribbonKeys: { [keyEvent: string]: any } = {};
  private _ribbon: SemTalkRibbon | null = null;
  private _toolbar: SemTalkRibbon | null = null;
  private _ribbon0: SemTalkRibbon | null = null;
  private _toolbar0: SemTalkRibbon | null = null;
  public getRibbon = (ribbon?: SemTalkRibbon, toolbar?: SemTalkRibbon, islocked?: boolean): { "ribbon": SemTalkRibbon, "toolbar": SemTalkRibbon, "keys": { [keyEvent: string]: any } } => {
    if (!this._ribbon) {
      const getRibbonKeys = (r: SemTalkRibbon) => {
        let funcKeys: { [keyEvent: string]: any } = {};
        let parseMenuItem = (jitem: any): any => {
          let nitem: any = {};
          if (jitem["key"]) {
            let ev = jitem["keyEvent"];
            if (ev && ev.length > 0) {
              funcKeys[ev] = jitem;
            }
            if (jitem["subMenuProps"]) {
              for (let sitem of jitem["subMenuProps"]["items"]) {
                parseMenuItem(sitem);
              }
            }
          }
          return nitem;
        };
        for (let jitem of r) {
          parseMenuItem(jitem);
        }
        return funcKeys;
      };
      if (!ribbon) {
        if (!islocked) {
          if (!this._ribbon0) {
            let local: any = this.base.GetModelAttribute(SemTalkBaseConstant.CookiePrefix + SemTalkCookie.ribbon);
            if (local) {
              this._ribbon0 = JSON.parse(local);
              this._ribbon = JSON.parse(JSON.stringify(this._ribbon0));
            } else {
              this._ribbon0 = require("./ribbon.json");
            }
          }
          this._ribbon = JSON.parse(JSON.stringify(this._ribbon0));
        } else {
          if (!this._ribbon0) {
            this._ribbon0 = require("./ribbon_locked.json");
          }
          this._ribbon = require("./ribbon_locked.json");
        }
      } else {
        this._ribbon = ribbon;
      }
      if (!toolbar) {
        if (!islocked) {
          if (!this._toolbar0) {
            let local: any = this.base.GetModelAttribute(SemTalkBaseConstant.CookiePrefix + SemTalkCookie.toolbar);
            if (local) {
              this._toolbar0 = JSON.parse(local);
              this._toolbar = JSON.parse(JSON.stringify(this._toolbar0));
            } else {
              this._toolbar0 = require("./toolbar.json");
            }
          }
          this._toolbar = JSON.parse(JSON.stringify(this._toolbar0));
        } else {
          if (!this._toolbar0) {
            this._toolbar0 = require("./toolbar_locked.json");
          }
          this._toolbar = JSON.parse(JSON.stringify(this._toolbar0));
        }
      } else {
        this._toolbar = toolbar;
      }
      if (this._ribbon) {
        this._ribbonKeys = getRibbonKeys(this._ribbon);
      }
    }
    if (this._ribbon && this._toolbar) {
      return { "ribbon": this._ribbon, "toolbar": this._toolbar, "keys": this._ribbonKeys };
    } else {
      return { "ribbon": [], "toolbar": [], "keys": this._ribbonKeys };
    }
  }
  public setRibbon = (ribbon: SemTalkRibbon, toolbar: SemTalkRibbon): void => {
    this._ribbon = ribbon;
    this._toolbar = toolbar;
    this.base.SetModelAttribute(SemTalkBaseConstant.CookiePrefix + SemTalkCookie.ribbon, JSON.stringify(ribbon));
    this.base.SetModelAttribute(SemTalkBaseConstant.CookiePrefix + SemTalkCookie.toolbar, JSON.stringify(toolbar));

    const getRibbonKeys = (r: SemTalkRibbon) => {
      let funcKeys: { [keyEvent: string]: any } = {};
      let parseMenuItem = (jitem: any): any => {
        let nitem: any = {};
        if (jitem["key"]) {
          let ev = jitem["keyEvent"];
          if (ev && ev.length > 0) {
            funcKeys[ev] = jitem;
          }
          if (jitem["subMenuProps"]) {
            for (let sitem of jitem["subMenuProps"]["items"]) {
              parseMenuItem(sitem);
            }
          }
        }
        return nitem;
      };
      for (let jitem of r) {
        parseMenuItem(jitem);
      }
      return funcKeys;
    };
    this._ribbonKeys = getRibbonKeys(ribbon);
  }
  public resetRibbon = (cache: boolean): void => {
    this._ribbon = null;
    this._toolbar = null;
    this._ribbonKeys = {};
    if (cache) {
      this._ribbon0 = null;
      this._toolbar0 = null;
    }
  }
  public AddonCommand = (args: any): void => {
    if (args["command"]) {
      alert(args["command"]);
      args["callback"].showSubTask(args["command"]);
    }
  }
  public renderSubTaskDialog = (addoncommand: string): any => {
    return "renderSubTaskDialog: " + addoncommand;
  }

  public alert(s: string, mtype: MessageBarType) {
    if (this.tseditor) {
      this.tseditor.alert(s, mtype);
    }
  }
  // public showHelp = (page?: string, lng?: string): void => {
  //   if (!lng) {
  //     lng = this.guilanguage;
  //   }
  //   this.resstr.showHelp(page, lng);
  // }
  public showHelp(page: string, lng?: string): void {
    if (!lng) {
      lng = this.guilanguage;
    }
    showHelp(page, lng);
    // let helpwiki: string = "https://github.com/SemTalkOnline/SemTalkOnline_EN/wiki/";
    // if (lng) {
    //   switch (lng) {
    //     case SemTalkLanguageCode.German: {
    //       helpwiki = "https://github.com/SemTalkOnline/SemTalkOnline_DE/wiki/";
    //       break;
    //     }
    //     case SemTalkLanguageCode.English: {
    //       helpwiki = "https://github.com/SemTalkOnline/SemTalkOnline_EN/wiki/";
    //       break;
    //     }
    //     default: {
    //       helpwiki = "https://github.com/SemTalkOnline/SemTalkOnline_EN/wiki/";
    //       break;
    //     }
    //   }
    // }

    // if (page) { window.open(helpwiki + this.getHelpStr(page), "_blank"); return; }
  }

  // private getHelpStr(str: string, lang?: SemTalkLanguageCode): string {
  //   let help_SemTalk = require("./SemTalkHelp.json");
  //   if (lang === undefined) {
  //     lang = this.guilanguage;
  //   }
  //   if (lang === SemTalkLanguageCode.English) {
  //     const s = help_SemTalk[str];
  //     if (s !== undefined) {
  //       return s.value;
  //     }
  //     return str;
  //   } else {
  //     const s = help_SemTalk[str];
  //     if (s !== undefined) {
  //       let v = s[lang];
  //       if (v === undefined) {
  //         return s.value;
  //       }
  //       return v;
  //     } else {
  //       return str;
  //     }
  //   }
  // }
  // public getHelpStr = (str: string, lang?: SemTalkLanguageCode): string => {
  //   if (lang === undefined) {
  //     lang = this.guilanguage;
  //   }
  //   return this.resstr.getHelpStr(str, lang);
  // }

  public getFileInfo = async (fname: string): Promise<any | null> => {
    if (fname) {
      let w: any = window;
      if (w.api && w.api.getfilemetadata) {
        let meta = await w.api.getfilemetadata({ "filename": fname });
        console.debug(meta);
        return meta;
      }
    }
    return null;
  }
  public getFile = async (fname: string): Promise<any | null> => {
    if (fname) {
      let w: any = window;
      if (w.api && w.api.getfilemetadata) {
        let file = await w.api.getfile({ "filename": fname });
        console.debug(file);
        return file;
      }
    }
    return null;
  }
  public openFile = async (fname: string): Promise<any | null> => {
    if (fname) {
      let w: any = window;
      if (w.api && w.api.openinstance) {
        let file = await w.api.openinstance({ "filename": fname });
        console.debug(file);
        return file;
      }
    }
    return null;
  }
  public isElectron = (): boolean => {
    let w: any = window;
    if (w.api && w.api.getfilemetadata) {
      return true;
    }
    return false;
  }

  public selectLeft = (): void => {
  }
  public selectRight = (): void => {
  }
  public selectLeftOptions = (): { title: string, Object: ISemTalkObject }[] => {
    return [];
  }
  public selectRightOptions = (): { title: string, Object: ISemTalkObject }[] => {
    return [];
  }
  public selectDown = (): void => {
  }
  public selectUp = (): void => {
  }
  public selectPageDown = (): void => {
  }
  public selectPageUp = (): void => {
  }
  public importQuickEditTable(_cols: any[], _rows: any[], _systemclasses: any): void {
  }
  public patchHyperlink = (): void => {
  }
  public loadExternalFormat = async (_callback: ISemTalkOnline, _s: string, _createdoc: any): Promise<void> => {
  }
  public saveExternalFormat = async (_callback: ISemTalkOnline, _filename: string, _currentpage: boolean): Promise<string> => {
    return "";
  }
  public setTextField = (tfelement: { key: string; label: string }[]): void => {
    let cell = this.currentShape();
    if (cell) {
      cell["textField"] = (tfelement as any)["key"];
      // console.debug(cell);
    }
  }

  public translateState(state: string): string {
    switch (state) {
      case DocumentStatus.InProgress: {
        return this.getResStrListener(ResIDL.STRINPROGRESS);
      }
      case DocumentStatus.InApproval: {
        return this.getResStrListener(ResIDL.STRINAPPROVAL);
      }
      case DocumentStatus.InPublishing: {
        return this.getResStrListener(ResIDL.STRINPUBLISHING);
      }
      case DocumentStatus.Published: {
        return this.getResStrListener(ResIDL.STRPUBLISHED);
      }
      case DocumentStatus.InReview: {
        return this.getResStrListener(ResIDL.STRINREVIEW);
      }
      case DocumentStatus.Completed: {
        return this.getResStrListener(ResIDL.STRCOMPLETED);
      }
      case DocumentStatus.Approved: {
        return this.getResStr(ResID.STRAPPROVEDAT1);
      }
    }
    return state;
  }
  public updateTextFields = (props: any[]): void => {
    // let cells: any[];
    if (this.tseditor) {
      this.tseditor.beginUpdate();
      let cells = this.tseditor.getAllTextCells();
      //    for (const s of cells) {
      for (const i in cells) {
        const s = cells[i];
        if (s.textField) {
          let tf = s.textField;
          switch (tf) {
            case "PAGENAME": {
              s.value = this.page?.ObjectCaption;
              break;
            }
            case "PAGECLASS": {
              s.value = this.page?.ClassOf().ObjectCaption;
              break;
            }
            case "PAGECOMMENT": {
              s.value = this.page?.Comment;
              break;
            }
            case "PAGENUMBER": {
              let un = this.page?.GetValue(SemTalkBaseConstant.SLUserNumber);
              if (un) {
                let pages = this.base.AllDiagrams().length.toString();
                s.value = un.toString() + "/" + pages;
              } else {
                s.value = "";
              }
              break;
            }
            default: {
              let v = props.find(x => x.PropName === tf);
              if (v) {
                switch (tf) {
                  case ModelProperty.state: {
                    if (v) {
                      s.value = this.translateState(v.PropValue);
                    } else {
                      s.value = "";
                    }
                    break;
                  }
                  default: {
                    if (v) {
                      s.value = v.PropValue;
                    } else {
                      s.value = "";
                    }
                    if (this.page?.FindAttribute(tf)) {
                      s.value = this.page.GetValue(tf);
                    }
                  }
                }
              }
            }
          }
          this.tseditor.refreshCell(s);
        }
      }
      this.tseditor.endUpdate();
    }
  }

  public reorderPages = () => {
    let un = this.base.FindAttributeType(SemTalkBaseConstant.SLUserNumber);
    if (un) {
      for (let dt of this.base.AllDiagramTypes()) {
        if (!dt.FindAttribute(un)) {
          dt.MakeAttribute(un, "");
        }
        let unumber: number = 0;
        for (let di of this.base.AllDiagrams()) {
          // let u = di.GetValue(un.ObjectName);
          // if (u.length > 0) {
          //   let u0 = parseInt(u);
          //   if (u0 > unumber) {
          //     unumber = (u0 + 1);
          //   }
          // } else {
          unumber += 1;
          di.SetValue(un.ObjectName, unumber.toString());
          // }
        }
        this.updateDiaglist();
      }
    }
  }

  public saveXML = (encode: any, decode: any): string => {
    const o2x = new OB2XML();
    return o2x.SaveXML(this.base, encode, decode);
  }

  public textGenerator = (_sem: IVisioRDFS, diag: ISemTalkDiagram, ismodel: boolean): string[] => {
    let text: string[] = [];
    text.push(this.getResStr(ResID.STRGENERATORFACTS) + ":");
    let base = this.base;
    let objects: ISemTalkObject[] = [];
    let classes = base.AllClasses();
    classes = classes.filter(x => !x.IsReadOnly);

    if (ismodel) {
      objects.push(...classes);
      objects.push(...base.AllInstances());
    } else {
      objects = diag.Contents().map(n => n.Model);
    }

    let badlist: string[] = [];
    badlist.push(SemTalkBaseConstant.SLSubClassOf);
    badlist.push(SemTalkBaseConstant.SLLanguage);
    badlist.push(SemTalkUIConstant.SLHidden);
    badlist.push(SemTalkBaseConstant.SLComment);
    badlist.push(SemTalkBaseConstant.SLThing);
    badlist.push(SemTalkUIConstant.SemTalkUserTab);
    badlist.push(Process_ElementName.SLDistribution);
    badlist.push(Process_ElementName.SLBreakpoint);
    let lan = base.FindClass(SemTalkBaseConstant.SLLanguage);
    if (lan) {
      for (let l of lan.Instances()) {
        badlist.push(l.ObjectName);
      }
    }
    objects = objects.filter(x => badlist.indexOf(x.ObjectName) < 0);

    for (let obj of objects) {
      if (base.IsInstance(obj) && !base.IsAssociation(obj)) {
        text.push(obj.ObjectCaption + " " + this.getResStr(ResID.STRGENERATORISA) + " " + (obj as ISemTalkInstance).ClassOf().ObjectCaption);
        if (obj.Comment) {
          text.push(obj.Comment);
        }
        for (let assoc of obj.Associations()) {
          text.push(assoc.FromObject.ObjectCaption + " " + assoc.ClassOf().ObjectCaption + " " + assoc.ToObject.ObjectCaption);
        }
      } else {
        if (base.IsClass(obj)) {
          for (let sup of (obj as ISemTalkClass).SuperClasses()) {
            text.push(this.getResStr(ResID.STRGENERATOREVERY) + " " + obj.ObjectCaption + " " + this.getResStr(ResID.STRGENERATORISA) + " " + sup.ObjectCaption);
          }
          if (obj.Comment) {
            text.push(obj.Comment);
          }
          for (let assoc of obj.Associations()) {
            text.push(assoc.FromObject.ObjectCaption + " " + assoc.ClassOf().ObjectCaption + " " + assoc.ToObject.ObjectCaption);
          }
        }
      }
    }
    if (diag.ClassOf().ObjectName === SemTalkBaseConstant.SLGeneric) {
      text.push("Welche Arten von $SELECTION gibt es in der Ontologie?");
    } else {
      text.push("Beschreibe mir $SELECTION");
    }
    return text;
  }
  public getDynStyles = (obj: ISemTalkObject, callback: ISemTalkOnline, cells: mxCell[] | null): any[] => {
    let styles: any[] = [];
    let sem = this;
    let ob = obj.ObjectBase;

    let isassoc = ob.IsAssociation(obj);
    if (isassoc) {
      styles.push({
        key: 'elbowEdgeStyle',
        text: sem.getResStr(ResID.STRElbowEdgeStyle),
        onClick: (): void => {
          callback.DoCommand(SemTalkOnlineCommand.SetStyle, { "edgeStyle": 'elbowEdgeStyle', "cells": cells });
        },
      });
      styles.push({
        key: 'orthogonalEdgeStyle',
        text: sem.getResStr(ResID.STROrthogonalEdgeStyle),
        onClick: (): void => {
          callback.DoCommand(SemTalkOnlineCommand.SetStyle, { "edgeStyle": 'orthogonalEdgeStyle', "cells": cells });
        },
      });
      styles.push({
        key: 'noneEdgeStyle',
        text: sem.getResStr(ResID.STRNONE),
        onClick: (): void => {
          callback.DoCommand(SemTalkOnlineCommand.SetStyle, { "edgeStyle": '', "cells": cells });
        },
      });
    }
    return styles;
  }
  public getDynProps = (_obj: ISemTalkObject): any[] => {
    let props: any[] = [];
    return props;
  }
  public getOptionCaption(s: string, lang: SemTalkLanguageCode, base: IObjectBase): string {
    let obj: ISemTalkObject | null = base.FindInstance(s);
    if (obj === null) { obj = base.FindAttributeType(s); }
    if (obj) {
      return obj.ID2NameNspLan(lang);
    }
    return s;
  }
  public EnsureObjectFlow = (_objectflow: boolean): void => {
  }
  public Initialized = (): void => {

  }
  public Layout = (_cells?: mxCell[]): void => {

  }

}
