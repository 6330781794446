import { SemTalkMaster } from "../SemTalkMaster";
import { SemTalkStyleAttribute } from "./visiordfsinterface";
export const ORGCHART_SHAPES: any = {};
ORGCHART_SHAPES[SemTalkMaster.MasterOrgUnit] = {
  type: 'vertex',
  style: SemTalkStyleAttribute.shape + '=rectangle;whiteSpace=wrap;' +
    SemTalkStyleAttribute.resize + '=0;html=1;' +
    SemTalkStyleAttribute.strokeWidth + '=2;',
};
ORGCHART_SHAPES[SemTalkMaster.MasterPosition] = {
  type: 'vertex',
  style: SemTalkStyleAttribute.shape + '=rectangle;whiteSpace=wrap;' +
    SemTalkStyleAttribute.resize + '=0;html=1;' +
    SemTalkStyleAttribute.strokeWidth + '=2;',
};
ORGCHART_SHAPES[SemTalkMaster.MasterRole] = {
  type: 'vertex',
  style: SemTalkStyleAttribute.shape + '=rectangle;whiteSpace=wrap;' +
    SemTalkStyleAttribute.resize + '=0;html=1;' +
    SemTalkStyleAttribute.strokeWidth + '=2;',
};
ORGCHART_SHAPES[SemTalkMaster.MasterPerson] = {
  type: 'vertex',
  style: SemTalkStyleAttribute.shape + '=rectangle;whiteSpace=wrap;' +
    SemTalkStyleAttribute.resize + '=0;html=1;' +
    SemTalkStyleAttribute.strokeWidth + '=2;',
};
ORGCHART_SHAPES[SemTalkMaster.MasterAttachment] = {
  type: 'vertex',
  style: 'whiteSpace=wrap;html=1;',
};
