import { Process_ElementName, SIM_AttributeTypeName } from '../semtalklistener/processInterface';
import {
  IObjectBase,
  ISemTalkLabels,
  ISemTalkSystemClass,
  ISemTalkLabel,
  ISemTalkClassLabel,
  ILabelSpec,
  ISemTalkClass,
  ISemTalkBusinessClass,
  ISemTalkInstance,
  ISemTalkAssociationType,
  SemTalkType,
  ISemTalkAttributeType,
  SemTalkBaseConstant,
  SemTalkLayoutField,
  ISemTalkDiagram,
  SemTalkOperator,
  IExpression,
  SemTalkAssignment,
  ISemTalkObject
} from './Interface';
import { ObjectBase } from './ObjectBase';

// import { SemTalkSystemClass } from './SystemClass';
// import { SemTalkAssociationType, SemTalkAttributeType } from './tbase';

// tslint:disable:max-classes-per-file
// tslint:disable:variable-name
/* tslint:disable:member-ordering */
/* tslint:disable:forin */


export class SemTalkLabels implements ISemTalkLabels {
  constructor(own: ISemTalkSystemClass) {
    //  this.ObjectBase = own.ObjectBase;
    this.Owner = own;
  }
  public Owner: ISemTalkSystemClass;
  public get ObjectBase(): IObjectBase { return this.Owner.ObjectBase; }
  private _labels: { [id: string]: ISemTalkLabel } = {};
  private _classlabels: { [id: string]: ISemTalkClassLabel } = {};
  public ShowAname: boolean = false;
  public ShowCAname: boolean = false;
  public ShowUserNumber: boolean = false;

  public Delete(): void {
    // for (const l of this.AllLabels()) {
    //   l.Delete();
    // }
  }

  public AddLabel(a: ISemTalkLabel): ISemTalkLabel {
    this._labels[a.Master + "*" + a.Text] = a;
    return a;
  }
  public RemoveLabel(a: ISemTalkLabel): void {
    delete this._labels[a.Master + "*" + a.Text];
  }
  public AddClassLabel(a: ISemTalkClassLabel): ISemTalkClassLabel {
    this._classlabels[a.Master + "*" + a.Text] = a;
    return a;
  }
  public RemoveClassLabel(a: ISemTalkClassLabel): void {
    delete this._classlabels[a.Master + "*" + a.Text];
  }
  public AllLabels(): ISemTalkLabel[] {
    const alist: ISemTalkLabel[] = [];
    for (const ai in this._labels) {
      const a = this._labels[ai];
      alist[alist.length] = a;
    }
    // for (const ai in this._classlabels) {
    //   const a = this._classlabels[ai];
    //   alist[alist.length] = a;
    // }
    return alist;
  }

  public Labels(mastername?: string): ISemTalkLabel[] {
    const alist: ISemTalkLabel[] = [];
    // let haslabel: boolean = false;
    for (const ai in this._labels) {
      // haslabel = true;
      const a: ISemTalkLabel = this._labels[ai];
      if (mastername === undefined) {
        // if (a.Master === null) {
        if (a.Master === "") {
          alist.push(a);
        }
      } else
        //   if (a.Master === "" || a.Master === mastername || this.Owner.ObjectName === a.Master) { // ?????
        // if (a.Master === mastername || (a.Master === "" && this.Owner.ObjectName === mastername)) {
        if (a.Master === mastername) {
          alist.push(a);
        }
    }
    // if (haslabel === false) {
    //     for (const sc of this.Owner.SuperClasses()) {
    //         if ((sc as tbase.SemTalkSystemClass).Labels(sc.ObjectName).length > 0) {
    //             return (sc as tbase.SemTalkSystemClass).Labels(sc.ObjectName);
    //         }
    //     }
    // }
    return alist;
  }
  public AllInstanceLabels(): ISemTalkLabel[] {
    const alist: ISemTalkLabel[] = [];
    for (const ai in this._labels) {
      const a: ISemTalkLabel = this._labels[ai];
      alist.push(a);
    }
    return alist;
  }

  public ClassLabels(mastername?: string): ISemTalkClassLabel[] {
    const alist: ISemTalkClassLabel[] = [];
    for (const ai in this._classlabels) {
      const a: ISemTalkLabel = this._classlabels[ai];
      if (mastername === undefined) {
        if (a.Master === "") {
          alist.push(a);
        }
      } else
        // if (a.Master === null || a.Master === mastername || this.Owner.ObjectName === mastername) { // ?????
        if (a.Master === mastername || (a.Master === "" && this.Owner.ObjectName === mastername)) {
          alist[alist.length] = a;
        }
    }
    return alist;
  }
  public AllClassLabels(): ISemTalkClassLabel[] {
    const alist: ISemTalkClassLabel[] = [];
    for (const ai in this._classlabels) {
      const a = this._classlabels[ai];
      alist.push(a);
    }
    return alist;
  }
  public FindLabelForMaster(master: string, text?: string): ISemTalkLabel[] {
    if (text !== undefined) {
      let l = this._labels[master + "*" + text];
      if (l !== undefined) {
        return [l];
      } else
        return [];
    } else {
      const alist: ISemTalkLabel[] = [];
      for (const ai in this._labels) {
        const a = this._labels[ai];
        if (a.Master === master) {
          alist.push(a);
        }
      }
      return alist;
    }
  }
  public FindClassLabelForMaster(master: string, text?: string): ISemTalkClassLabel[] {
    if (text !== undefined) {
      let l = this._classlabels[master + "*" + text];
      if (l !== undefined) {
        return [l];
      } else
        return [];
    } else {
      const alist: ISemTalkClassLabel[] = [];
      for (const ai in this._classlabels) {
        const a = this._classlabels[ai];
        if (a.Master === master) {
          alist.push(a);
        }
      }
      return alist;
    }
  }
  public MakeLabel(text: string, master: string): ISemTalkLabel {
    if (this._labels[master + "*" + text] !== undefined) { throw new Error("Duplicate Label :" + master + "*" + text); }
    if (text.indexOf("{") < 1) {
      // throw new Error("Bad Label no '{' :" + text);
    }

    const lbl: ISemTalkLabel = new SemTalkLabel(this, text, master);
    this.AddLabel(lbl);
    return lbl;
  }
  public MakeClassLabel(text: string, master: string): ISemTalkClassLabel {
    if (this._classlabels[master + "*" + text] !== undefined) { throw new Error("Duplicate Label :" + master + "*" + text); }
    // if (text.indexOf("{") < 1) {
    //     throw new Error("Bad Label no '{' :" + text);
    // }
    const lbl: ISemTalkClassLabel = new SemTalkClassLabel(this, text, master);
    this.AddClassLabel(lbl);
    return lbl;
    //}
  }

  public static SetField(lbl: ILabelSpec, lpos: string, txt: string): void {
    switch (lpos) {
      case " Group":
        if (lbl.newtxt === "") { lbl.newtxt = txt; } else { lbl.newtxt = lbl.newtxt + "<br/>" + txt; }
        break;
      case " 0":
        if (lbl.newtxt0 === "") { lbl.newtxt0 = txt; } else { lbl.newtxt0 = lbl.newtxt0 + "<br/>" + txt; }
        break;
      case " 1":
        if (lbl.newtxt1 === "") { lbl.newtxt1 = txt; } else { lbl.newtxt1 = lbl.newtxt1 + "<br/>" + txt; }
        break;
      case " 2":
        if (lbl.newtxt2 === "") { lbl.newtxt2 = txt; } else { lbl.newtxt2 = lbl.newtxt2 + "<br/>" + txt; }
        break;
      case " 3":
        if (lbl.newtxt3 === "") {
          lbl.newtxt3 = txt;
        } else {
          lbl.newtxt3 = lbl.newtxt3 + "<br/>" + txt;
        }
        break;
      case " 4":
        if (lbl.newtxt4 === "") { lbl.newtxt4 = txt; } else { lbl.newtxt4 = lbl.newtxt4 + "<br/>" + txt; }
        break;
      case " 5":
        if (lbl.newtxt5 === "") {
          lbl.newtxt5 = txt;
        } else {
          lbl.newtxt5 = lbl.newtxt5 + "<br/>" + txt;
        }
        break;
      case " 6":
        if (lbl.newtxt6 === "") { lbl.newtxt6 = txt; } else { lbl.newtxt6 = lbl.newtxt6 + "<br/>" + txt; }
        break;
      case " 7":
        if (lbl.newtxt7 === "") { lbl.newtxt7 = txt; } else { lbl.newtxt7 = lbl.newtxt7 + "<br/>" + txt; }
        break;
      case " 8":
        if (lbl.newtxt8 === "") { lbl.newtxt8 = txt; } else { lbl.newtxt8 = lbl.newtxt8 + "<br/>" + txt; }
        break;
      case " 9":
        if (lbl.newtxt9 === "") { lbl.newtxt9 = txt; } else { lbl.newtxt9 = lbl.newtxt9 + "<br/>" + txt; }
        break;
      case " 10":
        if (lbl.newtxt10 === "") { lbl.newtxt10 = txt; } else { lbl.newtxt10 = lbl.newtxt10 + "<br/>" + txt; }
        break;
    }
  }


  public CollectClassLabels(lbl: ILabelSpec, obj: ISemTalkClass, mastername?: string): boolean {
    //   if (this._classlabels != null) {
    let attributes = obj.AllAttributeTypes();

    for (const l of this.ClassLabels(mastername)) {
      lbl.haslabels = true;
      let lx: string = l.Text;
      let lpos: string = " Group";
      let idx = lx.indexOf("{");
      if (idx > -1) {
        lpos = lx.substr(idx + 1);
        lx = lx.substr(0, idx);
      }
      // if (lx.indexOf("{") > -1) {
      //   lpos = lx.substr(lx.indexOf("{") + 1);
      //   lx = lx.substr(0, lx.indexOf("{"));
      // }
      if (lx.indexOf("(") > -1) {
        lx = lx.substr(0, lx.indexOf("(") - 1);
      }
      switch (lx) {
        case SemTalkLayoutField.name:
          {
            SemTalkLabels.SetField(lbl, lpos, obj.ID2NameNsp());
            break;
          }
        case SemTalkLayoutField.fullname:
          {
            SemTalkLabels.SetField(lbl, lpos, obj.ObjectName);
            break;
          }
        case SemTalkLayoutField.synonyms:
          {
            for (const s of obj.Synonyms()) {
              if (s.Language !== "") {
                SemTalkLabels.SetField(lbl, lpos, s.Language + ": " + s.Name);
              } else {
                SemTalkLabels.SetField(lbl, lpos, s.Name);
              }
            }
            break;
          }
        case SemTalkLayoutField.systemclass: {
          const sc = obj.SystemClass() as ISemTalkSystemClass;
          // if (sc !== null) {
          SemTalkLabels.SetField(lbl, lpos, sc.ID2NameNsp());
          // }
          break;
        }
        case SemTalkLayoutField.superclass:
          {
            for (const s of obj.SuperClasses()) {
              SemTalkLabels.SetField(lbl, lpos, s.ID2NameNsp());
            }
            break;
          }
        case SemTalkLayoutField.subclass:
          {
            for (const s of obj.SubClasses()) {
              SemTalkLabels.SetField(lbl, lpos, s.ID2NameNsp());
            }
            break;
          }
        case SemTalkLayoutField.links:
          {
            for (const s of obj.Attachments()) {
              SemTalkLabels.SetField(lbl, lpos, s.ToObject.ID2NameNsp());
            }
            break;
          }
        case SemTalkLayoutField.comment: {
          SemTalkLabels.SetField(lbl, lpos, obj.Comment);
          break;
        }
        default: {
          let fnd: boolean = false;
          const attr = attributes.find((a) => a.ObjectName === lx);
          //  const attr = obj.FindAttribute(lx);
          if (attr !== undefined) {
            fnd = true;
            if (this.ShowCAname) {
              SemTalkLabels.SetField(lbl, lpos, attr.ID2NameNsp() + ": " + obj.GetValue(lx));
            } else {
              SemTalkLabels.SetField(lbl, lpos, obj.GetValue(lx));
            }
          }
          if (!fnd) {
            for (const s of obj.LinkedObjects(lx)) {
              fnd = true;
              if (this.ShowCAname) {
                const a = obj.ObjectBase.FindAssociationType(lx) as ISemTalkAssociationType;
                // if (a != null) {
                SemTalkLabels.SetField(lbl, lpos, a.ID2NameNsp() + ": " + s.ID2NameNsp());
                // } else {
                //   SemTalkLabels.SetField(lbl, lpos, s.ID2NameNsp());
                // }
              } else {
                SemTalkLabels.SetField(lbl, lpos, s.ID2NameNsp());
              }
            }
            // }
            // if (!fnd) {
            for (const s of obj.InvLinkedObjects(lx)) {
              fnd = true;
              if (this.ShowCAname) {
                const a = obj.ObjectBase.FindAssociationType(lx) as ISemTalkAssociationType;
                // if (a != null) {
                SemTalkLabels.SetField(lbl, lpos, a.ID2NameNsp() + ": " + s.ID2NameNsp());
                // } else {
                //   SemTalkLabels.SetField(lbl, lpos, s.ID2NameNsp());
                // }
              } else {
                SemTalkLabels.SetField(lbl, lpos, s.ID2NameNsp());
              }
            }
          }
        }

      }
    }
    return lbl.haslabels;
    // } else {
    //   return false;
    // }
    //    return false;
  }
  public CollectBusinessClassLabels(lbl: ILabelSpec, obj: ISemTalkBusinessClass, mastername?: string): boolean {
    // return this._labels.CollectClassLabels(lbl, obj, mastername);
    // const obj = obj1.ObjectBase.FindBusinessClass(obj1.ObjectName);

    // if (this._labels != null && obj !== null) {
    this.CollectClassLabels(lbl, obj, mastername);
    for (const l of this.ClassLabels(mastername)) {
      lbl.haslabels = true;
      let lx: string = l.Text;
      let lpos: string = " Group";
      let idx = lx.indexOf("{");
      if (idx > -1) {
        lpos = lx.substr(idx + 1);
        lx = lx.substr(0, idx);
      }
      // if (lx.indexOf("{") > -1) {
      //   lpos = lx.substr(lx.indexOf("{") + 1);
      //   lx = lx.substr(0, lx.indexOf("{"));
      // }
      if (lx.indexOf("(") > -1) {
        lx = lx.substr(0, lx.indexOf("(") - 1);
      }
      switch (lx) {
        case SemTalkLayoutField.methods:
          {
            for (const s of obj.Methods()) {
              SemTalkLabels.SetField(lbl, lpos, s.ClassOf().ID2NameNsp());
            }
            break;
          }
        case SemTalkLayoutField.attributes:
          {
            for (const s of obj.Attributes()) {
              SemTalkLabels.SetField(lbl, lpos, s.ClassOf().ID2NameNsp());
            }
            break;
          }
        case SemTalkLayoutField.states:
          {
            for (const s of obj.States()) {
              SemTalkLabels.SetField(lbl, lpos, s.ClassOf().ID2NameNsp());
            }
            break;
          }
      }
    }
    return lbl.haslabels;
    // } else {
    //   return false;
    // }
  }

  public builtinInstLabels: SemTalkLayoutField[] = [SemTalkLayoutField.name,
  SemTalkLayoutField.fullname,
  SemTalkLayoutField.synonyms,
  SemTalkLayoutField.links,
  SemTalkLayoutField.comment,
  SemTalkLayoutField.class,
  SemTalkLayoutField.systemclass,
  SemTalkLayoutField.probability,
  SemTalkLayoutField.usernumber,
  SemTalkLayoutField.usernumberatfront,
  SemTalkLayoutField.nameandusernumber,
  SemTalkLayoutField.usernumberandname];

  public builtinClassLabels: SemTalkLayoutField[] = [
    SemTalkLayoutField.name,
    SemTalkLayoutField.fullname,
    SemTalkLayoutField.synonyms,
    SemTalkLayoutField.links,
    SemTalkLayoutField.comment,
    SemTalkLayoutField.systemclass,
    SemTalkLayoutField.subclass,
    SemTalkLayoutField.superclass];

  public builtinBusinesClassLabels: SemTalkLayoutField[] = [SemTalkLayoutField.methods,
  SemTalkLayoutField.attributes,
  SemTalkLayoutField.states];

  public CollectInstLabels(lbl: ILabelSpec, obj: ISemTalkInstance, mastername?: string): boolean {
    //  if (this._labels != null) {
    let lbls: ISemTalkLabel[] = this.Labels(mastername);

    if (lbls.length === 0) {
      lbls = this.Labels();
    }
    let attributes: ISemTalkAttributeType[] = [];
    if (lbls.length > 0) attributes = obj.AllAttributeTypes();

    for (const l of lbls) {
      lbl.haslabels = true;
      let lx: string = l.Text;
      let lpos: string = " Group";
      let idx = lx.indexOf("{");
      if (idx > -1) {
        lpos = lx.substr(idx + 1);
        lx = lx.substr(0, idx);
      }
      // if (lx.indexOf("{") > -1) {
      //   lpos = lx.substr(lx.indexOf("{") + 1);
      //   lx = lx.substr(0, lx.indexOf("{"));
      // }
      if (lx.indexOf("(") > -1) {
        lx = lx.substr(0, lx.indexOf("(") - 1);
      }
      switch (lx) {
        case SemTalkLayoutField.name:
          {
            SemTalkLabels.SetField(lbl, lpos, obj.ID2NameNsp());
            break;
          }
        case SemTalkLayoutField.fullname:
          {
            SemTalkLabels.SetField(lbl, lpos, obj.ObjectName);
            break;
          }
        case SemTalkLayoutField.synonyms:
          {
            for (const s of obj.Synonyms()) {
              if (s.Language !== "") {
                SemTalkLabels.SetField(lbl, lpos, s.Language + ": " + s.Name);
              } else {
                SemTalkLabels.SetField(lbl, lpos, s.Name);
              }
            }
            break;
          }
        case SemTalkLayoutField.links:
          {
            for (const s of obj.Attachments()) {
              SemTalkLabels.SetField(lbl, lpos, s.ToObject.ID2NameNsp());
            }
            break;
          }
        case SemTalkLayoutField.comment: {
          SemTalkLabels.SetField(lbl, lpos, obj.Comment);
          break;
        }
        case SemTalkLayoutField.class: {
          SemTalkLabels.SetField(lbl, lpos, obj.ClassOf().ID2NameNsp());
          break;
        }
        case SemTalkLayoutField.systemclass: {
          const c = obj.ClassOf();
          // if (c !== null) {
          const sc = c.SystemClass() as ISemTalkSystemClass;
          // if (sc !== null) {
          SemTalkLabels.SetField(lbl, lpos, sc.ID2NameNsp());
          // }
          // }
          break;
        }
        case SemTalkLayoutField.probability: {
          SemTalkLabels.SetField(lbl, lpos, obj.GetValue(SIM_AttributeTypeName.Probability) + "%");
          break;
        }
        case SemTalkLayoutField.usernumber: {
          const un: string | null = obj.GetValue(SemTalkBaseConstant.SLUserNumber) as string;
          if (lpos === " Group" && un !== null) {
            SemTalkLabels.SetField(lbl, lpos, obj.ClassOf().ID2NameNsp() + "." + un);
          } else {
            if (un !== null) {
              SemTalkLabels.SetField(lbl, lpos, un);
            }
          }
          break;
        }
        case SemTalkLayoutField.usernumberatfront: {
          const un: string = obj.GetValue(SemTalkBaseConstant.SLUserNumber) as string;
          if (lpos === " Group" && un !== "") {
            SemTalkLabels.SetField(lbl, lpos, un + " " + obj.ClassOf().ID2NameNsp());
          } else {
            SemTalkLabels.SetField(lbl, lpos, un);
          }
          break;
        }
        case SemTalkLayoutField.nameandusernumber: {
          const un: string = obj.GetValue(SemTalkBaseConstant.SLUserNumber) as string;
          if (un !== "" && un !== null) {
            SemTalkLabels.SetField(lbl, lpos, obj.ClassOf().ID2NameNsp() + "." + un);
          } else {
            SemTalkLabels.SetField(lbl, lpos, obj.ClassOf().ID2NameNsp());
          }
          break;
        }
        case SemTalkLayoutField.usernumberandname: {
          const un: string = obj.GetValue(SemTalkBaseConstant.SLUserNumber) as string;
          if (un !== "" && un !== null) {
            SemTalkLabels.SetField(lbl, lpos, un + " " + obj.ClassOf().ID2NameNsp());
          } else {
            SemTalkLabels.SetField(lbl, lpos, obj.ClassOf().ID2NameNsp());
          }
          break;
        }
        default: {
          let fnd: boolean = false;
          const attr = attributes.find((a) => a.ObjectName === lx);
          //  const attr = obj.FindAttribute(lx);
          if (attr !== undefined) {
            fnd = true;
            let v = obj.GetValue(lx);
            if (v === null) v = "";
            let sv: string = String(v);
            if (sv.startsWith("[{") && sv.endsWith("}]")) {
              v = this.parseExpression(sv, obj);
            }
            if (this.ShowAname) {
              SemTalkLabels.SetField(lbl, lpos, attr.ID2NameNsp() + ": " + v);
            } else {
              SemTalkLabels.SetField(lbl, lpos, v);
            }
          }
          if (!fnd) {
            let ob = obj.ObjectBase;
            let trg = ob.GetModelAttribute(Process_ElementName.STTriggers);
            let trgb = ob.GetModelAttribute(Process_ElementName.STTriggeredBy);
            if (lx === trg || lx === trgb) {
              let event = ob.FindSystemClass(ob.GetModelAttribute(Process_ElementName.SLEvent));
              if (obj && event && obj.IsInstance(event)) {
                let links = obj.LinkedObjects(lx);
                if (links.length > 0) {
                  let sourcediag: ISemTalkDiagram | null = null;
                  let targetdiag: ISemTalkDiagram | null = null;
                  for (let nd of obj.Nodes()) {
                    sourcediag = nd.Diagram;
                    let refs = nd.Diagram.RefinedObjects();
                    if (refs.length > 0) {
                      if (ob.IsInstance(refs[0])) {
                        let refinedtask = refs[0] as ISemTalkInstance;
                        for (let nd2 of refinedtask.Nodes()) {
                          targetdiag = nd2.Diagram;
                          break;
                        }
                      }
                    }
                    break;
                  }
                  if (sourcediag && targetdiag) {
                    const sc = obj.SystemClass() as ISemTalkSystemClass;
                    for (const s of links) {
                      if (s.Nodes().find(x => x.Diagram === targetdiag)) {
                        fnd = true;
                        if (this.ShowAname === true) {
                          const a = obj.ObjectBase.FindAssociationType(lx) as ISemTalkAssociationType;
                          // if (a != null) {
                          SemTalkLabels.SetField(lbl, lpos, a.ID2NameNsp() + ": " + s.ID2NameNsp());
                          // } else {
                          //   SemTalkLabels.SetField(lbl, lpos, s.ID2NameNsp());
                          //}
                        } else {
                          if (sc) {
                            let l0 = lbl.newtxt;
                            if (l0 === sc.ID2NameNsp()) {
                              lbl.newtxt = '';
                            }
                          }
                          SemTalkLabels.SetField(lbl, lpos, s.ID2NameNsp());
                        }
                      }
                      // break;
                    }
                  }
                }
              }
            } else {
              for (const s of obj.LinkedObjects(lx)) {
                fnd = true;
                if (this.ShowAname === true) {
                  const a = obj.ObjectBase.FindAssociationType(lx) as ISemTalkAssociationType;
                  // if (a != null) {
                  SemTalkLabels.SetField(lbl, lpos, a.ID2NameNsp() + ": " + s.ID2NameNsp());
                  // } else {
                  //   SemTalkLabels.SetField(lbl, lpos, s.ID2NameNsp());
                  //}
                } else {
                  SemTalkLabels.SetField(lbl, lpos, s.ID2NameNsp());
                }
              }
              // }
              // if (!fnd) {
              for (const s of obj.InvLinkedObjects(lx)) {
                fnd = true;
                if (this.ShowAname === true) {
                  const a = obj.ObjectBase.FindAssociationType(lx) as ISemTalkAssociationType;
                  //if (a != null) {
                  SemTalkLabels.SetField(lbl, lpos, a.ID2NameNsp() + ": " + s.ID2NameNsp());
                  // } else {
                  //   SemTalkLabels.SetField(lbl, lpos, s.ID2NameNsp());
                  //}
                } else {
                  SemTalkLabels.SetField(lbl, lpos, s.ID2NameNsp());
                }
              }
            }
          }
        }
      }
    }
    return lbl.haslabels;
    // } else {
    //   return false;
    // }
  }
  private parseExpression = (sv: string, obj: ISemTalkObject): string => {
    let v = "";
    let expression: IExpression[] = [];
    try {
      expression = JSON.parse(sv);
    } catch (e) {
    }
    if (Array.isArray(expression)) {
      v = "";
      for (let o of expression) {
        if (o["var"] !== undefined && o["op"] !== undefined && o["val"] !== undefined) {
          let op = o["op"];
          if (obj.ObjectBase.IsAssociation(obj)) {
            op = (SemTalkOperator as any)[op];
          } else {
            op = (SemTalkAssignment as any)[op];
          }
          v += o["var"] + " " + op + " " + o["val"] + " ";
        }
      }
    }
    return v;
  }
  public Load(je: any): void {

    this.ShowAname = (je.ShowAName === "1");
    this.ShowCAname = (je.ShowCAName === "1");
    this.ShowUserNumber = (je.ShowUserNumber === "1");

    for (const i in je.labels) {
      const a1: any = je.labels[i];
      let m = a1.master;
      const an1 = new SemTalkLabel(this, a1.name, m);
      this.AddLabel(an1);
      an1.Load(a1);
    }
    for (const i in je.classlabels) {
      const a1: any = je.classlabels[i];
      const an1 = new SemTalkClassLabel(this, a1.name, a1.master);
      this.AddClassLabel(an1);
      an1.Load(a1);
    }
    //}
  }
  public LoadXML(_c: Element): void {

  }

  public Save(par: any): void {
    // const el: any = {};
    // el.ObjectType = "Class";
    // par[par.length] = el;
    // if (this._labels != null) {
    if (this.ShowAname) { par.ShowAname = "1"; }
    if (this.ShowCAname) { par.ShowCAname = "1"; }
    if (this.ShowUserNumber) { par.ShowUserNumber = "1"; }

    const ml: any[] = [];
    for (const l of this.AllLabels()) { l.Save(ml); }
    par.labels = ml;
    //}
    // if (this._classlabels != null) {
    const ml2: any[] = [];
    for (const l of this.AllClassLabels()) {
      // console.debug(l.Master);
      l.Save(ml2);
    }
    par.classlabels = ml2;
    // }
    // if (par.classlabels !== undefined && par.classlabels.length>0)
    //   console.debug(par);
    // return el;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    if (this.ShowAname) { el.setAttribute("ShowAname", "1"); }
    if (this.ShowCAname) { el.setAttribute("ShowCAname", "1"); }
    if (this.ShowUserNumber) { el.setAttribute("ShowUserNumber", "1"); }
    for (const l of this.AllLabels()) {
      let sy = xd.createElement("Label");
      el.appendChild(sy);
      l.SaveXML(xd, sy);
    }
    for (const l of this.AllClassLabels()) {
      let sy = xd.createElement("ClassLabel");
      el.appendChild(sy);
      l.SaveXML(xd, sy);
    }
  }
}
export class SemTalkLabel implements ISemTalkLabel {
  constructor(owner: SemTalkLabels, text: string, mst: string) {
    this.Owner = owner;
    this.Master = mst;
    this.Text = text;
    return this;
  }
  public Owner: ISemTalkLabels;
  public Master: string;
  public Text: string;
  public _type = SemTalkType.SemTalkLabel;
  public Delete(): void {
    this.Owner.RemoveLabel(this);
  }
  // tslint:disable-next-line:no-empty
  public Load(_je: any): void {
  }
  public LoadXML(_c: Element): void {
  }

  public Save(par: any): void {
    const el: any = {};
    el.ObjectType = ObjectBase.SemTalkTypeName(this._type);
    el.name = this.Text;
    el.master = this.Master;
    par.push(el);
  }
  public SaveXML(_xd: XMLDocument, el: HTMLElement): void {
    el.setAttribute("name", this.Text);
    if (this.Master && this.Master.length > 0) {
      el.setAttribute("master", this.Master);
    }
  }
}
export class SemTalkClassLabel extends SemTalkLabel implements ISemTalkClassLabel {
  constructor(owner: SemTalkLabels, text: string, mst: string) {
    super(owner, text, mst);
    owner.AddClassLabel(this);
    return this;
  }
  // public Owner: ISemTalkLabels;
  // public Master: string;
  // public Text: string;
  public _type = SemTalkType.SemTalkClassLabel;
  public Delete(): void {
    this.Owner.RemoveClassLabel(this);
  }
}
export class LabelSpec implements ILabelSpec {
  public newtxt: string = "";
  public newtxt0: string = "";
  public newtxt1: string = "";
  public newtxt2: string = "";
  public newtxt3: string = "";
  public newtxt4: string = "";
  public newtxt5: string = "";
  public newtxt6: string = "";
  public newtxt7: string = "";
  public newtxt8: string = "";
  public newtxt9: string = "";
  public newtxt10: string = "";
  public isuml: boolean = false;
  public slabel: boolean = false;
  public nolayoutinfo: boolean = false;
  public hasimage: boolean = false;
  public haslabels: boolean = false;
  public showimage: boolean = false;
  public usegrphx: boolean = true;
  public imgfile: string = "";
  public col: string = "";
  public pat: string = "";
  public wei: string = "";
}

