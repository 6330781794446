export function MakeKey(text: string, days: number, role: boolean, feature1: boolean,
    feature2: boolean, feature3: boolean, feature4: boolean,
     feature5: boolean, feature6: boolean): string {
    let txt = EncDate(days, role, feature1, feature2, feature3, feature4, feature5, feature6);
    txt = txt + "-";
    txt = txt + text + "-";
    let n = CheckSum(txt) * 161;
    return txt + n.toString();
}
function CheckSum(txt: string): number {
    let z = "z".charCodeAt(0);
    // let e = "1".charCodeAt(0);
    let n: number = 0;
    for (let i = 0; i < txt.length; i++) {
        let c: number = txt.charCodeAt(i);
        if (c > z) {
            c = z;
        }
        // if (c < e) {
        //     c = e;
        // }
        n = n + c * c;
    }
    return n;
}

function EncDate(days: number, role: boolean, feature1: boolean,
    feature2: boolean, feature3: boolean, feature4: boolean, feature5: boolean,
     feature6: boolean): string {
    let d2 = new Date(Date.now() + 86400000 * days);
    let m = (d2.getMonth() + 1).toString();
    if (m.length < 2) {
        m = "0" + m;
    }
    let d = d2.getDate().toString();
    if (d.length < 2) {
        d = "0" + d;
    }
    let ft = 1;
    if (role) {
        ft = 2;
    }
    if (feature2) {
        if (ft === 1) {
            ft = 8;
        }
        if (ft === 2) {
            ft = 3;
        }
    }
    if (feature3) {
        ft = 7;
        if (role) {
            ft = 5;
        }
        if (feature2) {
            ft = 6;
        }
        if (role && feature2) {
            ft = 4;
        }
    }
    //Role Editor or Admin
    let r = 0;
    if (role) r = 1 ;
    //Feature X
    let f1 = 0;
    if (feature1) f1 = 1 + r;
    //Feature A
    let f2 = 0;
    if (feature2) f2 = 1 + r;
    //extras
    let f3 = 0;
    if (feature3) f3 = 1 + r;
    //Simulation
    let f4 = 0;
    if (feature4) f4 = 1 + r;
    // Planner
    let f5 = 0;
    if (feature5) f5 = 1 + r;
    //Termstore
    let f6 = 0;
    if (feature6) f6 = 1 + r;
    
    
    let featureNumbers = f1.toString() + f2.toString() + f3.toString() + f4.toString() + f5.toString() + f6.toString();
    
    

    let dt: number = parseInt(ft.toString() + m + d2.getFullYear().toString() + d + featureNumbers.toString()) * 43;
    console.log(parseInt(ft.toString() + m + d2.getFullYear().toString() + d + featureNumbers.toString()));
    return dt.toString();
}
export function DC(vkey: string): string {
    if (vkey.indexOf("-") < 0) return "";
    let cs = vkey.substring(vkey.lastIndexOf("-") + 1);
    if (parseInt(cs) < 0) return "";

    let bd = vkey.substring(0, vkey.lastIndexOf("-") + 1);
    if (bd.length === 0) return "";

    let n = CheckSum(bd) * 161;
    if (n !== parseInt(cs)) return "";

    if (bd.indexOf("-") < 0) return "";
    let key = bd.substring(0, bd.indexOf("-"));
    if (key.length > 0) {
        key = (parseInt(key) / 43).toString();
        return key;
        /*let feature1: boolean = false;
        let feature2: boolean = false;
        let featureDemo: boolean = false;
        let ft = parseInt(key.toString()[0]);
        switch (ft) {
            case 1: { feature1 = false; feature2 = false; featureDemo = false; break; }
            case 2: { feature1 = true; feature2 = false; featureDemo = false; break; }
            case 3: { feature1 = true; feature2 = true; featureDemo = false; break; }
            case 4: { feature1 = true; feature2 = true; featureDemo = true; break; }
            case 5: { feature1 = true; feature2 = false; featureDemo = true; break; }
            case 6: { feature1 = false; feature2 = true; featureDemo = true; break; }
            case 7: { feature1 = false; feature2 = false; featureDemo = true; break; }
            case 8: { feature1 = false; feature2 = true; featureDemo = false; break; }
        }
        console.debug(feature1, feature2, featureDemo);
        key = key.substring(1, key.length);
        let month = parseInt(key.substring(0, 2))-1;
        let year = parseInt(key.substring(2, 6));
        let date = parseInt(key.substring(6, 8));
        let features = parseInt(key.substring(8, 16));
        console.log("features" + features);
        let exp = new Date(year, month, date);
        let now = Date.now();
        let diff = exp.getTime() - now;
        return Math.round(diff / 86400000);*/
    }
    return "";
}

export function DCgetDays(key: string): number {
    try{
        key = key.substring(1, key.length);
    let month = parseInt(key.substring(0, 2))-1;
    let year = parseInt(key.substring(2, 6));
    let date = parseInt(key.substring(6, 8));
    let exp = new Date(year, month, date);
    let now = Date.now();
    let diff = exp.getTime() - now;
    return Math.round(diff / 86400000);
    }catch{
        return -1;
    }
    
}

export function DCgetFeatures(key: string): any[] {

    key = key.substring(1, key.length);
    let features = key.substring(8, 14);
    console.log("features" + features);
    
    const featureArray: { feature: string; active: boolean }[]=[];
    if(features==="23"){//Old SemTalk Key Indicator
        featureArray.push({feature: 'isadmin', active: false });
        for(let i = 1;i<=6;i++) {
            featureArray.push({feature: 'f' + i, active: true });
        }
    }else{
        let isRoleSet = false;
        for(let i = 1;i<=features.length;i++) {
            (features[i-1]==="1" || features[i-1]==="2") ? featureArray.push({feature: 'f' + i, active: true }) : featureArray.push({feature: 'f' + i, active: false });
            if(!isRoleSet){
                if(features[i-1]==="2"){
                    featureArray.push({feature: 'isadmin', active: true });
                    isRoleSet = true;
                }
                if(features[i-1]==="1"){
                    featureArray.push({feature: 'isadmin', active: false });
                    isRoleSet = true;
                } 
            } 
            
        }
    }
    
    return featureArray;
}

export function DCgetFeaturesAsString(features: any[]): string {
    let featureString ="";

    features.forEach(element => {
        if(element.active === true)
        {
            featureString = featureString + element.feature + ",";
        }
    });
    return featureString;
}
