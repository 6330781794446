import { IComboBoxOption, MessageBarType } from "office-ui-fabric-react";
import { IVisioRDFS, SemTalkRibbon, SemTalkShape, SemTalkShapeType, SemTalkStencil } from "./application/semtalklistener/visiordfsinterface";
import { IObjectBase, ISemTalkClass, ISemTalkDiagram, ISemTalkDiagramType, ISemTalkObject, SemTalkID, SemTalkJSON, SemTalkLanguage} from "./application/tbase/Interface";
import { SemTalkCookie } from "./ISemTalkCookie";
import { ITSEditor } from "./ITSEditor";
import { IMongoOption } from "./SemTalkMongoServer";
import { IExportConnection } from "./utils";
// import { ISemTalkDiagram, SemTalkLanguage } from "./application/tbase/Interface";

export enum SemTalkOnlineCommand {
  NewDocument = "NewDocument",
  OpenDocument = "OpenDocument",
  SaveDocument = "SaveDocument",
  SaveAsDocument = "SaveAsDocument",
  DeleteDocument = "DeleteDocument",
  CloseDocument = "CloseDocument",
  AutoSave = "AutoSave",
  UploadDocument = "UploadDocument",
  DownloadDocument = "DownloadDocument",
  ImportDocument = "ImportDocument",
  MergeDocuments = "MergeDocuments",
  ImportOWLFile = "ImportOWLFile",
  ImportXMLFile = "ImportXMLFile",
  DownloadWordFile = "DownloadWordFile",
  DownloadHTMLFile = "DownloadHTMLFile",
  DownloadXMLFile = "DownloadXMLFile",
  DownloadPNGFile = "DownloadPNGFile",
  DownloadPDFFile = "DownloadPDFFile",
  DownloadJPGFile = "DownloadJPGFile",
  DownloadOWLFile = "DownloadOWLFile",
  DownloadJSONLDFile = "DownloadJSONLDFile",
  DownloadSVGFile = "DownloadSVGFile",
  PublishXMLFile = "PublishXMLFile",
  CheckInDocument = "CheckInDocument",
  CheckOutDocument = "CheckOutDocument",
  FileImage = "FileImage",
  PrintPreview = "PrintPreview",
  StartPanning = "StartPanning",
  StopPanning = "StopPanning",
  TogglePanZoom = "TogglePanZoom",
  ToggleStencil = "ToggleStencil",
  ToggleNavigator = "ToggleNavigator",
  TogglePlanner = "TogglePlanner",
  ToggleSearch = "ToggleSearch",
  TogglePages = "TogglePages",
  ToggleBackground = "ToggleBackground",
  DeleteShapes = "DeleteShapes",
  SelectAll = "SelectAll",
  SendToBack = "SendToBack",
  BringToFront = "BringToFront",
  Undo = "Undo",
  Redo = "Redo",
  ShapeStyle = "ShapeStyle",
  ConnectionPoints = "ConnectionPoints",
  DistributeCells = "DistributeCells",
  DistributeCellsVert = "DistributeCellsVert",
  DistributeCellsHor = "DistributeCellsHor",
  AlignHorizontalLeft = "AlignHorizontalLeft",
  AlignHorizontalCenter = "AlignHorizontalCenter",
  AlignHorizontalRight = "AlignHorizontalRight",
  AlignVerticalTop = "AlignVerticalTop",
  AlignVerticalCenter = "AlignVerticalCenter",
  AlignVerticalBottom = "AlignVerticalBottom",
  ZoomIn = "ZoomIn",
  ZoomOut = "ZoomOut",
  ZoomFit = "ZoomFit",
  ZoomActual = "ZoomActual",
  Center = "Center",
  Redraw = "Redraw",
  TogglePageBreaks = "TogglePageBreaks",
  EditObject = "EditObject",
  SelectCell = "SelectCell",
  InsertWayPoint = "InsertWayPoint",
  RemoveWayPoint = "RemoveWayPoint",
  ElbowCell = "ElbowCell",
  EditClass = "EditClass",
  DeleteObject = "DeleteObject",
  ToClass = "ToClass",
  ToInstance = "ToInstance",
  CompactShape = "CompactShape",
  DeleteHierarchy = "DeleteHierarchy",
  ComposeObject = "ComposeObject",
  UnComposeObject = "UnComposeObject",
  UpdateRepository = "UpdateRepository",
  TranslateObject = "TranslateObject",
  PlannerTasks = "PlannerTasks",
  Planner = "Planner",
  ConnectShapes = "ConnectShapes",
  HyperlinkObject = "HyperlinkObject",
  RefineObject = "RefineObject",
  DetachObject = "DetachObject",
  GoBack = "GoBack",
  GoForward = "GoForward",
  CustomizeObject = "CustomizeObject",
  InsertSwimlane = "InsertSwimlane",
  ToggleDirection = "ToggleDirection",
  CollapseCell = "CollapseCell",
  RefreshObject = "RefreshObject",
  RefreshPage = "RefreshPage",
  ReorderPages = "ReorderPages",
  ExpandObject = "ExpandObject",
  EditPage = "EditPage",
  AddPage = "AddPage",
  DeletePage = "DeletePage",
  GoUp = "GoUp",
  Renumber = "Renumber",
  Insert = "Insert",
  Termstore = "Termstore",
  SiteBuilder = "SiteBuilder",
  mxSwimlaneLayout = "mxSwimlaneLayout",
  mxHierarchicalLayout = "mxHierarchicalLayout",
  mxCompactTreeLayout = "mxCompactTreeLayout",
  mxCompactTreeLayoutV = "mxCompactTreeLayoutV",
  mxCompactTreeLayoutI = "mxCompactTreeLayoutI",
  mxCompactTreeLayoutVI = "mxCompactTreeLayoutVI",
  mxFastOrganicLayout = "mxFastOrganicLayout",
  mxRadialTreeLayout = "mxRadialTreeLayout",
  mxCircleLayout = "mxCircleLayout",
  SelectDataLang = "SelectDataLang",
  SelectGuiLang = "SelectGuiLang",
  ShowHelp = "ShowHelp",
  ShowForm = "ShowForm",
  ShowOptions = "ShowOptions",
  ResetMetaModel = "ResetMetaModel",
  AddonCommand = "AddonCommand",
  WordVectors = "WordVectors",
  InvertedTaxonomy = "InvertedTaxonomy",
  MS365Login = "MS365Login",
  MS365Role = "MS365Role",
  MS365Logout = "MS365Logout",
  mgLogin = "mgLogin",
  mgLogout = "mgLogout",
  mgUserProfile = "mgUserProfile",
  mgUserManager = "mgUserManager",
  GroupShapes = "GroupShapes",
  UngroupShapes = "UngroupShapes",
  AddShape = "AddShape",
  AddQuickShape = "AddQuickShape",
  SetStyle = "SetStyle",
  SelectLeft = "SelectLeft",
  SelectRight = "SelectRight",
  SelectUp = "SelectUp",
  SelectDown = "SelectDown",
  Speech = "Speech",
  ReportManager = "ReportManager",
  LoadDefaultSettings = "LoadDefaultSettings",
  SaveDefaultSettings = "SaveDefaultSettings",
  ExportDefaultSettings = "ExportDefaultSettings",
  ImportDefaultSettings = "ImportDefaultSettings",
  ResetSettings = "ResetSettings",
  CheckandRepair = "CheckandRepair",
  ApproveDocument = "ApproveDocument",
  Simulation = "Simulation",
  ShareDocument = "ShareDocument",
  TextField = "TextField",
  ChatGPT = "ChatGPT",
  SpeakChatGPT = "SpeakChatGPT",
  PromptManager = "PromptManager",
  StorageManager = "StorageManager",
  HeaderFooter = "HeaderFooter",
  Images = "Images",
  ExportBackgroundSettings = "ExportBackgroundSettings",
  ImportBackgroundSettings = "ImportBackgroundSettings",
  Documents = "Documents",
  VersionControl = "VersionControl",
  EditStencil = "EditStencil",
  EditRibbon = "EditRibbon",
  RenameObjects = "RenameObjects",
  Search = "Search",
  Code = "Code",
  Charting = "Charting",
  Impressum = "Impressum",
  Privacy = "Privacy",
  ModelProperties = "ModelProperties",
  Copy = "Copy",
  Cut = "Cut",
  Paste = "Paste",
  Layout = "Layout",
  KeyManager = "KeyManager",
  CustomLayout = "CustomLayout",
  ExportManager = "ExportManager",
}
export interface ISemTalkOnline {
  // filename: string;
  GetFilename(): string;
  SetFilename(filename: string, islru: boolean): void;
  GetElectronFilename(): string;
  SetElectronFilename(filename: string): void;
  DoCommand(cmd: SemTalkOnlineCommand, args: any): void;
  ParseMenu(ribbon: SemTalkRibbon): { "items": any[], "allitems": any };
  SetRibbon(ribbon: SemTalkRibbon, toolbar: SemTalkRibbon): void;
  ResetRibbon(): void;
  GetTextFieldsDiagramMenu(): any;
  GetTextFieldsModelMenu(): any;
  // GetTextFieldsModelAttributeMenu(): any;
  ShowSubTask(addoncommand: string): void;
  resumeSessionStatus(forcenew: boolean, mongo: IMongoOption,
    loadtemplate: boolean, loadrecent: boolean, role: string): void;
  getLabel(cell: mxCell, tmp: string): string;
  getTextLabel(cell: mxCell, tmp: string): string;
  getLinkLabel(cell: mxCell, tmp: string): string;
  getTooltipForCell(cell: mxCell): string;
  isCellResizable(cell: mxCell): boolean;
  isCellEditable(cell: mxCell): boolean;
  isWrapping(_cell: mxCell): boolean;
  GetShapeStyle(key: string, shapeType: SemTalkShapeType): { style: string, isedge: boolean };
  setEditor(tseditor: ITSEditor): void;
  shapeCreatePopupMenu(_graph: mxGraph, _menu: mxPopupMenuHandler, cell: mxCell, evt: PointerEvent, _edi: ITSEditor): void;
  shapeAdded(cell: mxCell): void;
  shapeDeleted(cells: mxCell[]): void;
  isValid(src: mxCell, dst: mxCell): boolean;
  shapeExitedTextEdit(cell: mxCell, newValue: any): void;
  shapeSelectionChanged(cell: mxCell | undefined, evt: mxEventObject): void;
  shapeConnectionAdded(event: mxEventObject, edge: mxCell, source: mxCell, target: mxCell): void;
  shapeConnectionsDeleted(edge: mxCell): void;
  shapeCellConnected(edge: mxCell): void;
  shapeMoveCells(cells: mxCell[], clone: boolean): void;
  shapeCopyCells(cells: mxCell[]): void;
  // selectCell(cell: mxCell): void;
  editCell(cell: mxCell): void;
  // composeCell(cell: mxCell): void;
  quickShapes(cell: mxCell): SemTalkStencil;
  insertQuickShape(m: SemTalkShape, cell: mxCell | null): mxCell | null;
  makeInstance(class_cell: mxCell, inst_cell: mxCell): void;
  makeSubClass(class_cell: mxCell, subcl_cell: mxCell): void;
  editObject(id: SemTalkID): void;
  // selectDataLang(lang: SemTalkLanguage): void;
  keyPressed(evtobj: KeyboardEvent): void;
  hasKeyHandler(evtobj: KeyboardEvent): boolean;
  selectLeft(): void;
  selectRight(): void;
  selectUp(): void;
  selectDown(): void;
  selectPageUp(): void;
  selectPageDown(): void;

  showShapeStyle(cells: mxCell[]): void;
  showHelp(page?: string): void;
  gotoPage(diagid: SemTalkID): void;
  newPage(pname: string, parent: ISemTalkObject | null, diagtype: ISemTalkDiagramType, init: boolean): Promise<ISemTalkDiagram | null>;
  alert(msg: string, mtype: MessageBarType): void;
  beginTransaction(reason: string): void;
  endTransaction(reason: string): void;
  // updateDiaglist(): void;
  openlocalDocument(filetype: string, force: boolean): void;
  savelocalDocument(filename: string, filetype: string): void;
  openDocumentCallBack(filename: string): void;
  newDocumentCallBack(template: string): void;
  importDocumentCallBack(filename: string): void;
  mergeDocumentsCallBack(files: any[]): Promise<void>;
  publishDocumentsCallBack(files: any[], publishBg: boolean): Promise<void>;
  importlocalDocument(): void;
  loadDocumentFromJSON(filename: string, s: SemTalkJSON, iselectron: boolean): Promise<void>;
  saveAsDocumentCallBack(filename: string, ext: string): void;
  deleteDocumentCallBack(filename: string): void;
  restoreVersionCallBack(filename: string, content: string): void;
  // getmxGraphXML(diag: ISemTalkDiagram): string;
  // defaultFont(): string;
  getMongoDBDocumentMetaData(filename: string): any;
  isConnectionPoints(): boolean;
  isForward(): boolean;
  isBackward(): boolean;
  isApprovable(): boolean;
  composeObjectCallback(oclass: ISemTalkClass): void;
  loadPage(oid: SemTalkID, semtalk: IVisioRDFS, init: boolean): Promise<void>;
  saveCurrentGraph(): void;
  encode(s: string): string;
  decode(s: string): string;
  IsMongo(): boolean;
  GetDefaultValue(settings: any, setting: SemTalkCookie, defaultvalue: any): any;
  CreateDocument(sem: IVisioRDFS, template: string, initpage: boolean): Promise<void>;
  LoadDocument(filename: string, usecache: boolean): Promise<void>;
  SetSelectedFile(filename: string): void;
  SetSelectedFiles(filenames: string[]): void;
  SetSelectedBackupFile(filename: string, content: string): void;
  SetSelectedExternalOb(ob: IObjectBase | null): void;
  SetSelectedFileType(filetype: string): void;
  SetSelectedExists(exists: boolean): void;
  SetSelectedDiagram(diagname: string): void;
  SetSelectedDiagramType(diagramtype: ISemTalkDiagramType): void;
  SetSelectedLanguage(language: SemTalkLanguage): void; 
  SetSelectedExportConnection(exportConnection: IExportConnection): void;
  SetImages(images: any[]): void; 
  SetPublishBg(publishBg: boolean): void;
  getDocumentJSON(): any;
  getDocumentsMetaData(): Promise<IComboBoxOption[]>;
  MS365Login(required_scopes: string[]): Promise<any>;
  // MS365Logout(required_scopes: string[]): Promise<any>;
  MS365Token(required_scopes: string[]): Promise<any>;
  getSharePointContext(): any;
  SetProgressIndicator(status: string): void;
  updateModelTextFields(): void;
  SetState(s: any): void;
  InsertObject(_semtalk: IVisioRDFS, _shape: any, newobjs: ISemTalkObject[], expand: boolean): void;
  InsertObjects(newobjs: ISemTalkObject[], expand: boolean, reusenodes: boolean): void;
  ResetModelProperties(): void;
  setModelCookie(cookie: SemTalkCookie, val: any): void;
  removeModelCookie(cookie: SemTalkCookie): void;
  reloadFromSettingsPanel(): void;
  GetRole(): string;
  IsCheckedout2me(): boolean;
  IsCheckedout(): boolean;
}
export interface ISemTalkSemantics {
  server: string;
  corpus: string;
  distance: number;
  tika: string;
}

export enum SemTalkRole {
  admin = "admin",
  publisher = "publisher",
  metamodel = "metamodel",
  editor = "editor",
  viewer = "viewer"
}

export enum PlannerAttribute {
  assignments = "Planner_assignments",
  // id = "Planner_id",
  // title = "Planner_title",
  bucketId = "Planner_bucketid",
  priority = "Planner_priority",
  completedBy = "Planner_completedBy",
  completedDateTime = "Planner_completedDateTime",
  createdBy = "Planner_createdBy",
  createdDateTime = "Planner_createdDateTime",
  description = "Planner_description",
  dueDateTime = "Planner_dueDateTime",
  // hasDescription = "Planner_hasDescription",
  percentComplete = "Planner_percentComplete",
  // planId = "Planner_planId",
  startDateTime = "Planner_startDateTime",
  status = "Planner_status",
}

export enum PlannerStatus {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Late = "Late",
  Completed = "Completed",
}

export enum DocumentStatus {
  InApproval = "InApproval",
  InProgress = "InProgress",
  InPublishing = "InPublishing",
  Published = "Published",
  InReview = "InReview",
  Completed = "Completed",
  Approved = "Approved"
}