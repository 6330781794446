import React from "react";
// import { Link } from "react-router-dom";
import {
  Fabric, MessageBar, MessageBarType, TextField, Text, Stack, StackItem,
  DefaultButton, PrimaryButton,
  Dropdown, IDropdownOption
} from 'office-ui-fabric-react';
// import { IIconProps } from 'office-ui-fabric-react';


// import { Link } from "react-router-dom";
import './Login.css';
import { IMongoOption, mgCreate_user, mglistDatabases, mgSend_Mail, mgSetValue, mgGetItem, mgSaveItem } from '../../SemTalkMongoServer';
// import { VisioRDFS } from '../../application/semtalklistener/visiordfs';
// import { IVisioRDFS } from '../../application/semtalklistener/visiordfsinterface';
import { IResStr, ResStr } from '../../application/semtalklistener/resstr';
import { SemTalkCookie } from "../../ISemTalkCookie";
// const addFriendIcon: IIconProps = { iconName: 'AddFriend' };
import { filterByAdmin } from './filterByAdmin';
import { accessCookie } from "../../application/semtalklistener/stgoto";
import { ResID, ResIDL } from "../../application/semtalklistener/visiordfsinterface";
import { ms365login } from "../../login";
import { encodechatgpt } from "../../chatgpt";
import { SemTalkBaseConstant } from "../../application/tbase/Interface";
import { DC, DCgetDays } from "../../semtalkkey";

export interface ISignupProps {
  setToken: any;
  mongo: IMongoOption;
}
export interface ISignupState {
  dbname: string;
  useridemail: string;
  fullname: string;
  affiliation: string;
  registrationkey: string;
  password: string;
  password2: string;
  success: boolean;
  nosuccess: boolean;
  message: string;
  options: IDropdownOption[];
  ms365login: boolean;
  chatgptkey: string;
}
export default class Signup extends React.Component<ISignupProps, ISignupState> {
  private res: IResStr;
  constructor(props: ISignupProps) {
    super(props);
    let gl = accessCookie(SemTalkCookie.guilanguage);
    if (!gl) {
      gl = navigator.languages[1] || navigator.language;
      if (gl && gl.indexOf("-") > 0) {
        gl = gl.substring(0, gl.indexOf("-"));
      }
    }
    this.res = new ResStr(gl);
    // this.res.init(null, gl);

    const mg = this.props.mongo;
    this.state = {
      dbname: mg.defaultdatabase,
      useridemail: "",
      fullname: "",
      affiliation: "",
      password: "",
      password2: "",
      registrationkey: "",
      success: false,
      nosuccess: false,
      message: "",
      options: [{ key: mg.defaultdatabase, text: "Default" }],
      ms365login: false,
      chatgptkey: ""
    };
  }

  public componentDidMount() {
    this.init();
  }

  private async init() {
    const mg = this.props.mongo;
    let options = [{ key: mg.defaultdatabase, text: "Default" }];
    let dblist = await mglistDatabases(mg, mg.semmongoconnectiontoken, mg.dbname, mg.documents);
    // console.debug(dblist);
    for (let db of dblist) {
      if (db !== mg.defaultdatabase) {
        options.push({ key: db, text: db });
      }
    }
    options = options.filter(x => x.key !== "Empty");
    options = await filterByAdmin(mg, "hidden_database", options);
    if (mg.dbname !== mg.defaultdatabase && options.find(x => x.key === mg.dbname) === undefined) {
      options.push({ key: mg.dbname, text: mg.dbname });
    }

    let dbn = mg.defaultdatabase;
    if (options.find(x => x.key === mg.dbname)) {
      dbn = mg.dbname;
    }

    this.setState({
      dbname: dbn,
      useridemail: "",
      fullname: "",
      password: "",
      password2: "",
      registrationkey: "",
      success: false,
      nosuccess: false,
      message: "",
      options: options,
    });
  }
  private handleSubmit = async (e: any) => {
    e.preventDefault();
    if (this.state.password !== this.state.password2) {
      this.setState({
        "success": false, "nosuccess": true,
        message: this.res.getResStr(ResID.STRLoginPasswordnoMatch)
      });
      return;
    }
    if (this.state.useridemail?.indexOf("@") === -1 || this.state.useridemail?.indexOf(".") === -1) {
      this.setState({
        "success": false, "nosuccess": true,
        message: this.res.getResStr(ResID.STRLoginUserIsNotEMail)
      });
      return;
    }
    if (this.state.registrationkey !== "") {
      let tkey = DC(this.state.registrationkey);
      let tdays = DCgetDays(tkey);
      if (tdays > 1) {
        let exists = await this.keyExists(this.state.registrationkey);
        if (exists) {
          this.setState({
            "success": false, "nosuccess": true,
            message: this.res.getResStr(ResID.STRKEYNOTUSEABLE),
          });
          return;
        }
      } else {
        this.setState({
          "success": false, "nosuccess": true,
          message: this.res.getResStr(ResID.STRINVALIDKEY),
        });
        return;
      }
    }
    if (this.state.useridemail && this.state.password && this.state.fullname) {
      try {
        let mongo = this.props.mongo;
        mongo.dbname = this.state.dbname;
        const res: any = await mgCreate_user(mongo,
          this.state.useridemail,
          this.state.fullname,
          "",
          this.state.password);
        if (res !== undefined) {
          let roles: string[] = ['editor', 'viewer'];
          mgSetValue(this.props.mongo, this.props.mongo.dbname, this.props.mongo.users, res._id,
            "profile.roles", roles);
          mgSetValue(this.props.mongo, this.props.mongo.dbname, this.props.mongo.users, res._id,
            "profile.ms365login", this.state.ms365login);
          mgSetValue(this.props.mongo, this.props.mongo.dbname, this.props.mongo.users, res._id,
            "profile.affiliation", this.state.affiliation);
          let ekey = "";
          if (this.state.chatgptkey.length > 0) {
            ekey = encodechatgpt(this.state.chatgptkey);
          }
          await mgSetValue(this.props.mongo, this.props.mongo.dbname, this.props.mongo.users, res._id,
            "profile.chatgptkey", ekey);
          let js = { id: this.state.registrationkey, userid: res._id };
          try {
            await mgSaveItem(this.props.mongo, this.props.mongo.dbname, "KeyManager", this.state.registrationkey, js);
          } catch (e) {
            console.debug(e);
          }
          let subject = "SemTalkOnline Signup: " + this.state.useridemail + "(" + this.state.fullname + ")";
          let mailtext = "";
          mailtext += "useridemail\n";
          mailtext += this.state.useridemail + "\n";
          mailtext += "fullname\n";
          mailtext += this.state.fullname + "\n";
          mailtext += "registrationkey\n";
          mailtext += this.state.registrationkey + "\n";
          mailtext += "ms365login\n";
          mailtext += this.state.ms365login + "\n";
          mailtext += "affiliation\n";
          mailtext += this.state.affiliation + "\n";

          mgSend_Mail(this.props.mongo, "sales@semtalk.com", mailtext, subject);

          this.setState({
            "success": true, "nosuccess": false,
            message: this.res.getResStr(ResID.STRLoginAccountCreated)
          });
          // this.props.setToken(res);
          // this.props.setcurrToken(res);
        } else {
          this.setState({
            "success": false, "nosuccess": true,
            message: this.res.getResStr(ResID.STRLoginAccountCreationFailed)
          });
        }
      }
      catch (err) {
        let msg: string = "unknown";
        if (err && typeof err === "object") {
          const e1: any = err;
          msg = e1["message"];
        }
        this.setState({ "success": false, "nosuccess": true, message: msg });
      }
    }
  }

  private m365login = async () => {
    let getValue = (cookie: SemTalkCookie, v: any): any => {
      let val: any = undefined;
      let localvalue = accessCookie(cookie);
      if (localvalue) {
        val = localvalue;
      } else {
        val = v;
      }
      return val;
    };
    let scopes = [
      "User.Read",
      // "Sites.Read.All",
      // "Group.ReadWrite.All",
      // "Files.Read.All",
    ];
    let applicationid: string = getValue(
      SemTalkCookie.applicationid,
      "f2f7dcf0-0917-4911-b587-f0c941391758"
    ) as string;
    let authority: string = getValue(
      SemTalkCookie.authority,
      "https://login.microsoftonline.com/semtalk.onmicrosoft.com"
    ) as string;
    let res: any;
    if (window.location.origin === "http://localhost:3000") {
      let scopes: string[] = [
        // "User.Read",
        // "Sites.Read.All",
        // "Group.ReadWrite.All",
        // "Files.Read.All",
      ];
      res = await ms365login(
        "9eec9ef7-38a4-4b29-8ac5-b8b02564c78b",
        "https://login.microsoftonline.com/semtalk.onmicrosoft.com",
        scopes
      );
    } else {
      res = await ms365login(applicationid, authority, scopes);
    }
    if (res && res.account && SemTalkBaseConstant.SSOPWD) {
      // let pwd = SemTalkBaseConstant.SSOPWD
      let pwd = SemTalkBaseConstant.SSOPWD;
      this.setState({
        useridemail: res.account.username,
        fullname: res.account.name,
        password: pwd,
        password2: pwd,
        ms365login: true
      });
    }
    // console.debug(res);
  }

  private async keyExists(key: string): Promise<boolean> {
    let regKey = false;
    let findKey = await mgGetItem(this.props.mongo, this.props.mongo.dbname, "KeyManager", key);
    if (findKey && findKey !== null) {
      if (findKey && findKey.id) {
        regKey = true;
      }
    }
    return regKey;
  }

  public render(): React.ReactElement {
    let divHostStyle: any = {
      // position: "fixed",
      // height: "100%", //this.props.height,
      // width: "80%", //this.props.width,
      padding: "20px"
    };
    let welcome = "SemTalk Online";
    if (document.baseURI.indexOf("citcomposer") > -1) {
      welcome = "cit Composer";
    }
    const stackTokens = { childrenGap: 10 };
    const usemongosso = (accessCookie(SemTalkCookie.usemongosso) === "true");
    return (
      <Fabric>
        {this.res &&
          <Stack style={divHostStyle} tokens={stackTokens}>
            <StackItem>
              <Text variant="xxLarge">{welcome}</Text>
            </StackItem>
            <StackItem>
              <Text variant="large">{this.res.getResStr(ResID.STRREGISTER)}</Text>
            </StackItem>
            <StackItem>
              {!this.state.success && this.state.nosuccess && (
                <MessageBar
                  // Setting this to error, blocked, or severeWarning automatically sets the role to "alert"
                  messageBarType={MessageBarType.error}
                // Or you could set the role manually, IF an alert role is appropriate for the message
                // role="alert"
                >
                  {this.state.message}
                </MessageBar>
              )}
              {!this.state.success &&
                <form onSubmit={this.handleSubmit}>
                  <Stack tokens={stackTokens}>
                    <StackItem>
                      <Dropdown
                        placeholder="Select a Database"
                        label={this.res.getResStr(ResID.STRSQLDATABASE).replace(":", "")}
                        defaultSelectedKey={this.state.dbname}
                        onChange={(_e: any, v?: any) => {
                          this.setState({ "dbname": v.key });
                        }}
                        options={this.state.options}
                      />
                    </StackItem>
                    <StackItem>
                      <TextField required label={this.res.getResStr(ResID.STRUSERID) + " (Email)"}
                        value={this.state.useridemail}
                        autoComplete="off"
                        placeholder="max@mustermann.org"
                        onChange={(_e: any, v?: any) => this.setState({ "useridemail": v })}
                      />
                    </StackItem>
                    <StackItem>
                      <TextField required label={this.res.getResStr(ResID.STRDLGPROXYUSER)}
                        value={this.state.fullname}
                        autoComplete="off"
                        placeholder="Max Mustermann"
                        onChange={(_e: any, v?: any) => this.setState({ "fullname": v })}
                      />
                    </StackItem>
                    {/* <StackItem>
                      <Checkbox checked={this.state.ms365login} label="Single Sign On"></Checkbox>
                    </StackItem> */}
                    {!this.state.ms365login &&
                      <StackItem>
                        <TextField type="text"
                          value={this.state.password}
                          label={this.res.getResStr(ResID.STRDLGPWD)} required
                          autoComplete="off"
                          onChange={(_e: any, v?: any) => this.setState({ "password": v })} />
                      </StackItem>
                    }
                    {!this.state.ms365login &&
                      <StackItem>
                        <TextField type="text"
                          value={this.state.password2}
                          label={this.res.getResStr(ResID.STRRepeat) + " " + this.res.getResStr(ResID.STRDLGPWD)} required
                          autoComplete="off"
                          onChange={(_e: any, v?: any) => this.setState({ "password2": v })} />
                      </StackItem>
                    }
                    <StackItem>
                      <TextField id="affiliation" type="text" label={this.res.getResStrListener(ResIDL.STRAFFILIATION)}
                        value={this.state.affiliation}
                        autoComplete="off"
                        onChange={(_e: any, v?: any) => this.setState({ affiliation: v })} />
                    </StackItem>
                    <StackItem>
                      <TextField label={this.res.getResStr(ResID.STRPRODKEY)}
                        autoComplete="off"
                        onChange={(_e: any, v?: any) => this.setState({ "registrationkey": v })} />
                    </StackItem>
                    <StackItem>
                      <TextField id="chatgptkey" label={"ChatGPT"}
                        defaultValue={this.state.chatgptkey}
                        autoComplete="off"
                        // type="password"
                        // canRevealPassword
                        onChange={(_e: any, v?: any) => this.setState({ "chatgptkey": v })} />
                    </StackItem>
                    <StackItem>
                      <Stack tokens={stackTokens} horizontal>
                        <PrimaryButton disabled={!(this.state.useridemail && this.state.password &&
                          this.state.password2 && this.state.fullname)} type="submit"
                          text={this.res.getResStr(ResID.STRDLGCMDOK)}></PrimaryButton>
                        {usemongosso &&
                          <DefaultButton type="submit" onClick={() => { this.m365login(); }} text={"MS365 " + this.res.getResStr(ResID.STRLOGIN)}></DefaultButton>
                        }
                        {/* <DefaultButton><Link to="/">{this.res.getResStr(ResID.STRDLGCMDCA)}</Link></DefaultButton> */}
                        {/* <DefaultButton onClick={() => { this.res.showHelp("Create-SemTalk-Online-Account"); }}>{this.res!.getResStr(ResID.STRHELP)}</DefaultButton> */}
                      </Stack>
                    </StackItem>
                  </Stack>
                </form>
              }
            </StackItem>
            {this.state.success &&
              <Stack style={divHostStyle} tokens={stackTokens}>
                <StackItem>
                  <MessageBar
                    messageBarType={MessageBarType.success}>
                    {this.state.message}
                  </MessageBar>
                </StackItem>
                <StackItem>
                  {/* <DefaultButton><Link to="/">{this.res.getResStr(ResID.STRDLGBOPTLO)}</Link></DefaultButton> */}
                </StackItem>
              </Stack>
            }
          </Stack>
        }
      </Fabric >
    );
  }
}
