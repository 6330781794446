import React from "react";
import {
  Selection,
  IColumn,
  DetailsList, DetailsListLayoutMode,
  Stack, StackItem, IconButton, TooltipHost, SelectionMode, ISelection, CheckboxVisibility,
} from 'office-ui-fabric-react';
// import { VisioRDFS } from '../../application/semtalklistener/visiordfs';
// import { IVisioRDFS } from '../../application/semtalklistener/visiordfsinterface';
import { ISemTalkOnline, SemTalkOnlineCommand } from "../../ISemTalkOnline";
import { IVisioRDFS, ResIDL } from "../../application/semtalklistener/visiordfsinterface";
import { ISemTalkObject } from "../../application/tbase/Interface";
// import { SemTalkCookie } from "../../ISemTalkOnline";

export interface ISemTalkNavigatorProps {
  callback: ISemTalkOnline;
  semtalk: IVisioRDFS;
}
interface IOption {
  Object: ISemTalkObject;
  title: string;
}
export interface ISemTalkNavigatorState {
  items: IOption[];
}
export default class SemTalkNavigator extends React.Component<ISemTalkNavigatorProps, ISemTalkNavigatorState> {
  private _selection: ISelection;
  constructor(props: ISemTalkNavigatorProps) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => { this.selectitem(); }
    });
    this.init();

    this.state = {
      items: [],
    };
  }
  private selectitem = (): void => {
    let sel = this._selection.getSelection();
    if (sel.length > 0) {
      let item = this._selection.getSelection()[0] as IOption;
      //console.debug(item);
      if (item !== null) {
        this.setState({ items: [] });
        this.gotoFirstShapeOf(this.props.semtalk, item.Object);
        // gotoObject(item.Object.ID);
      }
    }
  }
  private _columns: IColumn[] = [
    {
      key: 'Value',
      name: "Value",
      fieldName: 'Value',
      minWidth: 150,
      // maxWidth: 180,
      isResizable: true,
      onRender: (item: IOption, _index?: number, _column?: IColumn) => {
        let aname = item.Object.ObjectCaption;
        return <span>{aname}</span>;
      }
    }
  ];
  private async init() {
  }
  private gotoFirstShapeOf = (sem: IVisioRDFS, obj: ISemTalkObject): boolean => {
    for (let nd of obj.Nodes()) {
      if (nd.Diagram === sem.page) {
        let oshape = sem.FindShape(obj);
        sem.graph.scrollCellToVisible(oshape);
        sem.graph.setSelectionCell(oshape);
        return true;
      }
    }
    return false;
  }

  private getOptions = (right: boolean): void => {
    const sem = this.props.semtalk;
    let cell = sem.currentShape();
    if (cell) {
      let obj = sem.base.FindInstanceByID(cell.objectid);
      if (obj !== null && sem.base.IsInstance(obj)) {
        let items: any = [];
        if (right) {
          items = this.props.semtalk.selectRightOptions();
        } else {
          items = this.props.semtalk.selectLeftOptions();
        }
        this.setState({ items: items });
        return;
      }
    }
    this.setState({ items: [] });
  }
  public render(): React.ReactElement {
    const sem = this.props.semtalk;
    const stackTokens = { childrenGap: 10 };
    return (
      <div>
        <Stack tokens={stackTokens}>
          <Stack horizontal tokens={stackTokens}>
            <StackItem>
              <IconButton></IconButton>
              {/* <IconButton iconProps={{ iconName: 'StatusCircleQuestionMark' }}
                onClick={() => { 
                  let items: any = this.props.callback.DoCommand(SemTalkOnlineCommand.SelectLeftOptions, {}); 
                  this.setState({ items: items as IOption[] });
                  }}>LeftSibling</IconButton> */}
            </StackItem>
            <StackItem>
              <TooltipHost content={sem.getResStrListener(ResIDL.STRGOUP)}>
                <IconButton iconProps={{ iconName: 'Up' }}
                  onClick={() => {
                    this.setState({ items: [] });
                    this.props.callback.DoCommand(SemTalkOnlineCommand.SelectUp, {});
                  }}>Up</IconButton>
              </TooltipHost>
            </StackItem>
            <StackItem>
            </StackItem>
          </Stack>
          <Stack horizontal tokens={stackTokens}>
            <StackItem>
              <TooltipHost content={sem.getResStrListener(ResIDL.STRPREVIOUS)}>
                <IconButton iconProps={{ iconName: 'Back' }}
                  onClick={() => {
                    this.props.callback.DoCommand(SemTalkOnlineCommand.SelectLeft, {});
                    this.getOptions(false);
                  }}>Left</IconButton>
              </TooltipHost>
            </StackItem>
            <StackItem>
              <TooltipHost content={sem.getResStrListener(ResIDL.STRREADOUT)}>
                <IconButton iconProps={{ iconName: 'ReadOutLoud' }}
                  onClick={() => { this.props.callback.DoCommand(SemTalkOnlineCommand.Speech, {}); }}>Speech</IconButton>
              </TooltipHost>
            </StackItem>
            <StackItem>
              <TooltipHost content={sem.getResStrListener(ResIDL.STRCONTINUE)}>
                <IconButton iconProps={{ iconName: 'Forward' }}
                  onClick={() => {
                    this.props.callback.DoCommand(SemTalkOnlineCommand.SelectRight, {});
                    this.getOptions(true);
                  }}>Right</IconButton>
              </TooltipHost>
            </StackItem>
          </Stack>
          <Stack horizontal tokens={stackTokens}>
            <StackItem>
              <IconButton></IconButton>
            </StackItem>
            <StackItem>
              <TooltipHost content={sem.getResStrListener(ResIDL.STRREFINE)}>
                <IconButton iconProps={{ iconName: 'Down' }}
                  onClick={() => {
                    this.setState({ items: [] });
                    this.props.callback.DoCommand(SemTalkOnlineCommand.SelectDown, {});
                  }}>Down</IconButton>
              </TooltipHost>
            </StackItem>
            <StackItem>
              <IconButton></IconButton>
            </StackItem>
          </Stack>
          <StackItem>
            {this.state.items.length > 1 &&
              <DetailsList
                items={this.state.items}
                compact={false}
                columns={this._columns}
                selectionMode={SelectionMode.single}
                layoutMode={DetailsListLayoutMode.justified}
                selection={this._selection}
                checkboxVisibility={CheckboxVisibility.hidden}
                isHeaderVisible={false}
              />
            }
          </StackItem>
        </Stack>
      </div >
    );
  }
}