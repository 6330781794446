import React from "react";
import {
  Checkbox,
  DefaultButton,
  PrimaryButton,
  Stack, StackItem, Text, mergeStyleSets,
} from 'office-ui-fabric-react';
// import { ISemTalkOnline } from "../../ISemTalkOnline";
import { VisioRDFS } from '../../application/semtalklistener/visiordfs';
import { IVisioRDFS, ResID } from '../../application/semtalklistener/visiordfsinterface';
import { SemTalkCookie } from "../../ISemTalkCookie";
import { accessCookie, setCookie } from "../../application/semtalklistener/stgoto";
import { SemTalkRole } from "../../ISemTalkOnline";
// import { Link } from "react-router-dom";

export interface ISemTalkWelcomeProps {
  // semtalk: IVisioRDFS;
  // callback: ISemTalkOnline;
  role: string;
}
export interface ISemTalkWelcomeState {
  template: string;
  filename: string;
  lru: any[];
  showwelcome: boolean;
}
export default class SemTalkWelcome extends React.Component<ISemTalkWelcomeProps, ISemTalkWelcomeState> {
  private res: IVisioRDFS;
  constructor(props: ISemTalkWelcomeProps) {
    super(props);
    let gl = accessCookie(SemTalkCookie.guilanguage);
    if (!gl) {
      gl = navigator.languages[1] || navigator.language;
      if (gl && gl.indexOf("-") > 0) {
        gl = gl.substring(0, gl.indexOf("-"));
      }
    }
    this.res = new VisioRDFS(null, null);
    this.res.init(null, gl);
    let template = "";
    let filename = "";
    // let s1 = accessCookie(SemTalkCookie.autosaveSemTalk);
    let s2 = accessCookie(SemTalkCookie.SemTalkStatus);
    let sessionstatus: any;
    if (s2) {
      sessionstatus = JSON.parse(s2);
    }
    if (sessionstatus && sessionstatus["template"]) {
      template = sessionstatus["template"];
    }
    if (sessionstatus && sessionstatus["filename"]) {
      filename = sessionstatus["filename"];
    }
    let lru = JSON.parse(accessCookie(SemTalkCookie.lru));
    if (!lru) lru = [];
    if (lru.length > 0) {
      if (typeof lru[0] === 'string') {
        lru = lru.map((x: string) => { return { "filename": x }; });
      }
    }

    this.state = {
      template: template,
      filename: filename,
      lru: lru,
      showwelcome: true
    };
  }
  private classNames = mergeStyleSets({
    column: {
      width: window.innerWidth / 3,
      padding: "100px"
    },
    entry: {
      "padding-left": "50px",
      width: window.innerWidth / 3 - 50,
      // height: 30,
      border: 0,
      "text-align": "left"
    },
    hide: {
    }
  });
  private gettitle = (s: string) => {
    switch (s) {
      case "bpmn20-2016.stx": return "BPMN 2.0";
      case "cit_intelliForm_dialog.stx": return "CIT IntelliForm Dialog";
      case "epc.stx": return "EPC";
      case "ksa.stx": return "KSA";
      case "semtalk.stx": return "Ontology";
    }
    return s;
  }
  public render(): React.ReactElement {
    // let divHostStyle: any = {
    //   padding: "20px"
    // };
    // let welcome = this.res.getResStr(ResID.STRINTRO0);
    // let welcome = "SemTalk Online";
    // if (document.baseURI.indexOf("citcomposer") > -1) {
    //   welcome = welcome.replace("SemTalk", "cit Composer");
    // }
    const stackTokens = { childrenGap: 10 };

    let i = 10001;
    let openitems: any[] = [];
    openitems.push(<StackItem key={i.toString()} align="start"><PrimaryButton
      className={this.classNames.entry}
      onClick={() => {
        this.welcomeCallBack("reload");
      }}>{this.res.getResStr(ResID.STRWELCOMECONTINUE) + " " + this.state.filename}</PrimaryButton></StackItem>
    );
    if (this.props.role !== SemTalkRole.viewer) {
      i += 1;
      openitems.push(<StackItem key={i.toString()} align="start"><DefaultButton className={this.classNames.entry} onClick={() => {
        this.welcomeCallBack(this.state.template);
      }}>{this.res.getResStr(ResID.STRWELCOMENEW) + ": " + this.gettitle(this.state.template)}</DefaultButton></StackItem>);
    }
    let recentitems: JSX.Element[] = [];
    for (let entry of this.state.lru) {
      let l: string = entry["filename"];
      if (l.length > 0 && !l.endsWith(".stx")) {
        i += 1;
        recentitems.push(<StackItem key={i.toString()} align="start"><DefaultButton className={this.classNames.entry}
          onClick={() => {
            this.welcomeCallBack(l);
          }}>{l}</DefaultButton></StackItem>);
      }
    }
    let helpitems: JSX.Element[] = [];
    i += 1;
    helpitems.push(<StackItem key={i.toString()} align="start"><DefaultButton className={this.classNames.entry} onClick={() => {
      this.res.showHelp("SemTalk-Overview");
    }}>{this.res.getResStr(ResID.STRWELCOMEOVERVIEW)}</DefaultButton></StackItem>);

    return (
      <div>
        <Stack tokens={stackTokens} horizontal>
          <StackItem>
            <Stack tokens={stackTokens} className={this.classNames.column}>
              <StackItem align="start">
                <Text variant={"xxLarge"}>Start</Text>
              </StackItem>
              {openitems}
              <StackItem align="start">
                <Text variant={"xxLarge"}>Recent</Text>
              </StackItem>
              {recentitems}
              <StackItem align="start" className={this.classNames.hide}>
                <Checkbox defaultChecked={this.state.showwelcome}
                  label={this.res.getResStr(ResID.STRWELCOMESHOWINTRO)}
                  onChange={(_ev: any, isChecked: boolean | undefined) => {
                    if (isChecked !== undefined) {
                      this.setState({ "showwelcome": isChecked });
                    }
                  }}>
                </Checkbox>
              </StackItem>
            </Stack>
          </StackItem>
          <StackItem>
            <Stack tokens={stackTokens} className={this.classNames.column}>
              {/* <StackItem >
                <Text variant="xxLarge">{welcome}</Text>
              </StackItem> */}
              <StackItem align="start">
                <Text variant={"xxLarge"}>{ }Walkthroughs</Text>
              </StackItem>
              {helpitems}
            </Stack>
          </StackItem>
        </Stack>
      </div>
    );
  }
  // public hidewelcomeCallBack = () => {
  //   this.setState({ showwelcome: false });
  // }
  public welcomeCallBack = async (filename: string) => {
    setCookie(SemTalkCookie.showwelcome, this.state.showwelcome);
    setCookie(SemTalkCookie.filename, filename);
    window.location.reload();
  }
}



