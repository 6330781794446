import {
  mxActor,
  mxArrow,
  mxCellRenderer,
  mxCloud,
  mxConnectionConstraint,
  mxConnector,
  mxCylinder,
  mxDoubleEllipse,
  mxEdgeStyle,
  mxEllipse,
  mxHexagon,
  mxImageShape,
  mxLabel,
  mxLine,
  mxMarker,
  mxPerimeter,
  mxPoint,
  mxRectangle,
  mxRectangleShape,
  mxRhombus,
  mxStyleRegistry,
  mxSwimlane,
  mxTriangle,
  mxUtils
} from "mxgraph-js";
import { SemTalkStyleAttribute } from "../visiordfsinterface";

function CubeShape(this: any) {
  mxCylinder.call(this);
}
mxUtils.extend(CubeShape, mxCylinder);
CubeShape.prototype.size = 20;
CubeShape.prototype.redrawPath = function (path: any, _x: any, _y: any, w: any, h: any, isForeground: any) {
  const s = Math.max(0, Math.min(w, Math.min(h, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size)))));

  if (isForeground) {
    path.moveTo(s, h);
    path.lineTo(s, s);
    path.lineTo(0, 0);
    path.moveTo(s, s);
    path.lineTo(w, s);
    path.end();
  } else {
    path.moveTo(0, 0);
    path.lineTo(w - s, 0);
    path.lineTo(w, s);
    path.lineTo(w, h);
    path.lineTo(s, h);
    path.lineTo(0, h - s);
    path.lineTo(0, 0);
    path.close();
    path.end();
  }
};
CubeShape.prototype.getLabelMargins = function (_rect: any) {
  if (mxUtils.getValue(this.style, 'boundedLbl', false)) {
    const s = parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size)) * this.scale;

    return new mxRectangle(s, s, 0, 0);
  }

  return null;
};

mxCellRenderer.registerShape('cube', CubeShape);

//   --------------------------------------------------------

const tan30 = Math.tan(mxUtils.toRadians(30));
const tan30Dx = (0.5 - tan30) / 2;

// Cube Shape, supports size style
function IsoRectangleShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(IsoRectangleShape, mxActor);
IsoRectangleShape.prototype.size = 20;
IsoRectangleShape.prototype.redrawPath = (path: any, _x: any, _y: any, w: any, h: any) => {
  const m = Math.min(w, h / tan30);

  path.translate((w - m) / 2, (h - m) / 2 + m / 4);
  path.moveTo(0, 0.25 * m);
  path.lineTo(0.5 * m, m * tan30Dx);
  path.lineTo(m, 0.25 * m);
  path.lineTo(0.5 * m, (0.5 - tan30Dx) * m);
  path.lineTo(0, 0.25 * m);
  path.close();
  path.end();
};

mxCellRenderer.registerShape('isoRectangle', IsoRectangleShape);

//   --------------------------------------------------------

// Cube Shape, supports size style
function IsoCubeShape(this: any) {
  mxCylinder.call(this);
}
mxUtils.extend(IsoCubeShape, mxCylinder);
IsoCubeShape.prototype.size = 20;
IsoCubeShape.prototype.redrawPath = (path: any, _x: any, _y: any, w: any, h: any, isForeground: any) => {
  const m = Math.min(w, h / (0.5 + tan30));

  if (isForeground) {
    path.moveTo(0, 0.25 * m);
    path.lineTo(0.5 * m, (0.5 - tan30Dx) * m);
    path.lineTo(m, 0.25 * m);
    path.moveTo(0.5 * m, (0.5 - tan30Dx) * m);
    path.lineTo(0.5 * m, (1 - tan30Dx) * m);
    path.end();
  } else {
    path.translate((w - m) / 2, (h - m) / 2);
    path.moveTo(0, 0.25 * m);
    path.lineTo(0.5 * m, m * tan30Dx);
    path.lineTo(m, 0.25 * m);
    path.lineTo(m, 0.75 * m);
    path.lineTo(0.5 * m, (1 - tan30Dx) * m);
    path.lineTo(0, 0.75 * m);
    path.close();
    path.end();
  }
};

mxCellRenderer.registerShape('isoCube', IsoCubeShape);

//   --------------------------------------------------------

// Note Shape, supports size style
function NoteShape(this: any) {
  mxCylinder.call(this);
}
mxUtils.extend(NoteShape, mxCylinder);
NoteShape.prototype.size = 30;
NoteShape.prototype.redrawPath = function (path: any, _x: any, _y: any, w: any, h: any, isForeground: any) {
  const s = Math.max(0, Math.min(w, Math.min(h, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size)))));

  if (isForeground) {
    path.moveTo(w - s, 0);
    path.lineTo(w - s, s);
    path.lineTo(w, s);
    path.end();
  } else {
    path.moveTo(0, 0);
    path.lineTo(w - s, 0);
    path.lineTo(w, s);
    path.lineTo(w, h);
    path.lineTo(0, h);
    path.lineTo(0, 0);
    path.close();
    path.end();
  }
};

mxCellRenderer.registerShape('note', NoteShape);


// Note Shape, supports size style
function SwitchShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(SwitchShape, mxActor);
SwitchShape.prototype.redrawPath = (c: any, _x: any, _y: any, w: any, h: any) => {
  const curve = 0.5;
  c.moveTo(0, 0);
  c.quadTo(w / 2, h * curve, w, 0);
  c.quadTo(w * (1 - curve), h / 2, w, h);
  c.quadTo(w / 2, h * (1 - curve), 0, h);
  c.quadTo(w * curve, h / 2, 0, 0);
  c.end();
};

mxCellRenderer.registerShape('switch', SwitchShape);

// Folder Shape, supports tabWidth, tabHeight styles
function FolderShape(this: any) {
  mxCylinder.call(this);
}
mxUtils.extend(FolderShape, mxCylinder);
FolderShape.prototype.tabWidth = 60;
FolderShape.prototype.tabHeight = 20;
FolderShape.prototype.tabPosition = 'right';
FolderShape.prototype.redrawPath = function (path: any, _x: any, _y: any, w: any, h: any, isForeground: any) {
  const dx = Math.max(0, Math.min(w, parseFloat(mxUtils.getValue(this.style, 'tabWidth', this.tabWidth))));
  const dy = Math.max(0, Math.min(h, parseFloat(mxUtils.getValue(this.style, 'tabHeight', this.tabHeight))));
  const tp = mxUtils.getValue(this.style, 'tabPosition', this.tabPosition);

  if (isForeground) {
    if (tp === 'left') {
      path.moveTo(0, dy);
      path.lineTo(dx, dy);
    }
    // Right is default
    else {
      path.moveTo(w - dx, dy);
      path.lineTo(w, dy);
    }

    path.end();
  } else {
    if (tp === 'left') {
      path.moveTo(0, 0);
      path.lineTo(dx, 0);
      path.lineTo(dx, dy);
      path.lineTo(w, dy);
    }
    // Right is default
    else {
      path.moveTo(0, dy);
      path.lineTo(w - dx, dy);
      path.lineTo(w - dx, 0);
      path.lineTo(w, 0);
    }

    path.lineTo(w, h);
    path.lineTo(0, h);
    path.lineTo(0, dy);
    path.close();
    path.end();
  }
};

mxCellRenderer.registerShape('folder', FolderShape);

// Card shape
function CardShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(CardShape, mxActor);
CardShape.prototype.size = 30;
CardShape.prototype.isRoundable = () => {
  return true;
};
CardShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const s = Math.max(0, Math.min(w, Math.min(h, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size)))));
  const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
  this.addPoints(c, [new mxPoint(s, 0), new mxPoint(w, 0), new mxPoint(w, h), new mxPoint(0, h), new mxPoint(0, s)],
    this.isRounded, arcSize, true);
  c.end();
};

mxCellRenderer.registerShape('card', CardShape);

// Tape shape
function TapeShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(TapeShape, mxActor);
TapeShape.prototype.size = 0.4;
TapeShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const dy = h * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const fy = 1.4;

  c.moveTo(0, dy / 2);
  c.quadTo(w / 4, dy * fy, w / 2, dy / 2);
  c.quadTo(w * 3 / 4, dy * (1 - fy), w, dy / 2);
  c.lineTo(w, h - dy / 2);
  c.quadTo(w * 3 / 4, h - dy * fy, w / 2, h - dy / 2);
  c.quadTo(w / 4, h - dy * (1 - fy), 0, h - dy / 2);
  c.lineTo(0, dy / 2);
  c.close();
  c.end();
};

TapeShape.prototype.getLabelBounds = function (rect: any) {
  if (mxUtils.getValue(this.style, 'boundedLbl', false)) {
    let size = mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size);
    let w = rect.width;
    let h = rect.height;

    if (this.direction == null ||
      this.direction === mxConstants.DIRECTION_EAST ||
      this.direction === mxConstants.DIRECTION_WEST) {
      let dy = h * size;

      return new mxRectangle(rect.x, rect.y + dy, w, h - 2 * dy);
    }

    let dx = w * size;

    return new mxRectangle(rect.x + dx, rect.y, w - 2 * dx, h);
  }

  return rect;
};

mxCellRenderer.registerShape('tape', TapeShape);

// Document shape
function DocumentShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(DocumentShape, mxActor);
DocumentShape.prototype.size = 0.3;
DocumentShape.prototype.getLabelMargins = function (rect: any) {
  if (mxUtils.getValue(this.style, 'boundedLbl', false)) {
    return new mxRectangle(0, 0, 0, parseFloat(mxUtils.getValue(
      this.style, SemTalkStyleAttribute.size, this.size,
    )) * rect.height);
  }

  return null;
};
DocumentShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const dy = h * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const fy = 1.4;

  c.moveTo(0, 0);
  c.lineTo(w, 0);
  c.lineTo(w, h - dy / 2);
  c.quadTo(w * 3 / 4, h - dy * fy, w / 2, h - dy / 2);
  c.quadTo(w / 4, h - dy * (1 - fy), 0, h - dy / 2);
  c.lineTo(0, dy / 2);
  c.close();
  c.end();
};

mxCellRenderer.registerShape('document', DocumentShape);

const cylinderGetCylinderSize = mxCylinder.prototype.getCylinderSize;

mxCylinder.prototype.getCylinderSize = function (_x: any, _y: any, _w: any, h: any) {
  let size = mxUtils.getValue(this.style, SemTalkStyleAttribute.size);

  if (size != null) {
    return h * Math.max(0, Math.min(1, size));
  }

  return cylinderGetCylinderSize.apply(this, arguments);
};

mxCylinder.prototype.getLabelMargins = function (rect: any) {
  if (mxUtils.getValue(this.style, 'boundedLbl', false)) {
    const size = mxUtils.getValue(this.style, SemTalkStyleAttribute.size, 0.15) * 2;

    return new mxRectangle(0, Math.min(this.maxHeight * this.scale, rect.height * size), 0, 0);
  }

  return null;
};

// Parallelogram shape
function ParallelogramShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(ParallelogramShape, mxActor);
ParallelogramShape.prototype.size = 0.2;
ParallelogramShape.prototype.isRoundable = () => {
  return true;
};
ParallelogramShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const dx = w * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
  this.addPoints(c, [new mxPoint(0, h), new mxPoint(dx, 0), new mxPoint(w, 0), new mxPoint(w - dx, h)],
    this.isRounded, arcSize, true);
  c.end();
};

mxCellRenderer.registerShape('parallelogram', ParallelogramShape);

// Trapezoid shape
function TrapezoidShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(TrapezoidShape, mxActor);
TrapezoidShape.prototype.size = 0.2;
TrapezoidShape.prototype.isRoundable = () => {
  return true;
};
TrapezoidShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const dx = w * Math.max(0, Math.min(0.5, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
  this.addPoints(c, [new mxPoint(0, h), new mxPoint(dx, 0), new mxPoint(w - dx, 0), new mxPoint(w, h)],
    this.isRounded, arcSize, true);
};

mxCellRenderer.registerShape('trapezoid', TrapezoidShape);

// Curly Bracket shape
function CurlyBracketShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(CurlyBracketShape, mxActor);
CurlyBracketShape.prototype.size = 0.5;
CurlyBracketShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  c.setFillColor(null);
  const s = w * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
  this.addPoints(c, [new mxPoint(w, 0), new mxPoint(s, 0), new mxPoint(s, h / 2),
  new mxPoint(0, h / 2), new mxPoint(s, h / 2), new mxPoint(s, h),
  new mxPoint(w, h)
  ], this.isRounded, arcSize, false);
  c.end();
};

mxCellRenderer.registerShape('curlyBracket', CurlyBracketShape);

// Process Shape
function ProcessShape(this: any) {
  mxRectangleShape.call(this);
}
mxUtils.extend(ProcessShape, mxRectangleShape);
ProcessShape.prototype.size = 0.1;
ProcessShape.prototype.isHtmlAllowed = () => {
  return false;
};
ProcessShape.prototype.getLabelBounds = function (rect: any) {
  if (mxUtils.getValue(this.state.style, mxConstants.STYLE_HORIZONTAL, true) ===
    (this.direction == null ||
      this.direction === mxConstants.DIRECTION_EAST ||
      this.direction === mxConstants.DIRECTION_WEST)) {
    const w = rect.width;
    const h = rect.height;
    const r = new mxRectangle(rect.x, rect.y, w, h);

    let inset = w * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));

    if (this.isRounded) {
      const f = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE,
        mxConstants.RECTANGLE_ROUNDING_FACTOR * 100) / 100;
      inset = Math.max(inset, Math.min(w * f, h * f));
    }

    r.x += Math.round(inset);
    r.width -= Math.round(2 * inset);

    return r;
  }

  return rect;
};
ProcessShape.prototype.paintForeground = function (c: any, x: any, y: any, w: any, h: any) {
  let inset = w * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));

  if (this.isRounded) {
    const f = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE,
      mxConstants.RECTANGLE_ROUNDING_FACTOR * 100) / 100;
    inset = Math.max(inset, Math.min(w * f, h * f));
  }

  // Crisp rendering of inner lines
  inset = Math.round(inset);

  c.begin();
  c.moveTo(x + inset, y);
  c.lineTo(x + inset, y + h);
  c.moveTo(x + w - inset, y);
  c.lineTo(x + w - inset, y + h);
  c.end();
  c.stroke();
  mxRectangleShape.prototype.paintForeground.apply(this, arguments);
};

mxCellRenderer.registerShape('process', ProcessShape);

// Transparent Shape
function TransparentShape(this: any) {
  mxRectangleShape.call(this);
}
mxUtils.extend(TransparentShape, mxRectangleShape);
TransparentShape.prototype.paintBackground = (c: any, x: any, y: any, w: any, h: any) => {
  c.setFillColor(mxConstants.NONE);
  c.rect(x, y, w, h);
  c.fill();
};
TransparentShape.prototype.paintForeground = (_c: any, _x: any, _y: any, _w: any, _h: any) => { };

mxCellRenderer.registerShape('transparent', TransparentShape);

// Callout shape
function CalloutShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(CalloutShape, mxHexagon);
CalloutShape.prototype.size = 30;
CalloutShape.prototype.position = 0.5;
CalloutShape.prototype.position2 = 0.5;
CalloutShape.prototype.base = 20;
CalloutShape.prototype.getLabelMargins = function () {
  return new mxRectangle(0, 0, 0, parseFloat(mxUtils.getValue(
    this.style, SemTalkStyleAttribute.size, this.size,
  )) * this.scale);
};
CalloutShape.prototype.isRoundable = () => {
  return true;
};
CalloutShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
  const s = Math.max(0, Math.min(h, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const dx = w * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, 'position', this.position))));
  const dx2 = w * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, 'position2', this.position2))));
  const base = Math.max(0, Math.min(w, parseFloat(mxUtils.getValue(this.style, 'base', this.base))));

  this.addPoints(c, [new mxPoint(0, 0), new mxPoint(w, 0), new mxPoint(w, h - s),
  new mxPoint(Math.min(w, dx + base), h - s), new mxPoint(dx2, h),
  new mxPoint(Math.max(0, dx), h - s), new mxPoint(0, h - s)
  ],
    this.isRounded, arcSize, true, [4]);
};

mxCellRenderer.registerShape('callout', CalloutShape);
// Callout Perimeter
mxPerimeter.CalloutPerimeter = (bounds: any, vertex: any, next: any, orthogonal: any) => {
  return mxPerimeter.RectanglePerimeter(mxUtils.getDirectedBounds(bounds, new mxRectangle(0, 0, 0,
    Math.max(0, Math.min(bounds.height, parseFloat(mxUtils.getValue(vertex.style, SemTalkStyleAttribute.size,
      CalloutShape.prototype.size)) * vertex.view.scale))),
    vertex.style), vertex, next, orthogonal);
};

mxStyleRegistry.putValue('calloutPerimeter', mxPerimeter.CalloutPerimeter);



// Step shape
function StepShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(StepShape, mxActor);
StepShape.prototype.size = 0.1;
StepShape.prototype.fixedSize = 10;
StepShape.prototype.isRoundable = () => {
  return true;
};
StepShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const fixed = mxUtils.getValue(this.style, SemTalkStyleAttribute.fixedSize, '0') !== '0';
  const s = (fixed) ? Math.max(0, Math.min(w,
    parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size,
      this.fixedSize)))) :
    w * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
  this.addPoints(c, [new mxPoint(0, 0), new mxPoint(w - s, 0), new mxPoint(w, h / 2), new mxPoint(w - s, h),
  new mxPoint(0, h), new mxPoint(s, h / 2)
  ], this.isRounded, arcSize, true);
  c.end();
};

mxCellRenderer.registerShape('step', StepShape);

// Hexagon shape
function HexagonShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(HexagonShape, mxHexagon);
HexagonShape.prototype.size = 0.25;
HexagonShape.prototype.isRoundable = () => {
  return true;
};
HexagonShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const s = w * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
  this.addPoints(c, [new mxPoint(s, 0), new mxPoint(w - s, 0), new mxPoint(w, 0.5 * h), new mxPoint(w - s, h),
  new mxPoint(s, h), new mxPoint(0, 0.5 * h)
  ], this.isRounded, arcSize, true);
};

mxCellRenderer.registerShape('hexagon', HexagonShape);

// Plus Shape
function PlusShape(this: any) {
  mxRectangleShape.call(this);
}
mxUtils.extend(PlusShape, mxRectangleShape);
PlusShape.prototype.isHtmlAllowed = () => {
  return false;
};
PlusShape.prototype.paintForeground = function (c: any, x: any, y: any, w: any, h: any) {
  const border = Math.min(w / 5, h / 5) + 1;

  c.begin();
  c.moveTo(x + w / 2, y + border);
  c.lineTo(x + w / 2, y + h - border);
  c.moveTo(x + border, y + h / 2);
  c.lineTo(x + w - border, y + h / 2);
  c.end();
  c.stroke();
  mxRectangleShape.prototype.paintForeground.apply(this, arguments);
};

mxCellRenderer.registerShape('plus', PlusShape);

// Overrides painting of rhombus shape to allow for double style
const mxRhombusPaintVertexShape = mxRhombus.prototype.paintVertexShape;
mxRhombus.prototype.getLabelBounds = function (this: any, rect: any) {
  if (this.style.double === 1) {
    const margin = (Math.max(2, this.strokewidth + 1) * 2 + parseFloat(
      this.style[mxConstants.STYLE_MARGIN] || 0,
    )) * this.scale;

    return new mxRectangle(rect.x + margin, rect.y + margin,
      rect.width - 2 * margin, rect.height - 2 * margin);
  }

  return rect;
};
mxRhombus.prototype.paintVertexShape = function (c: any, x: any, y: any, w: any, h: any) {
  mxRhombusPaintVertexShape.apply(this, arguments);

  if (!this.outline && this.style.double === 1) {
    const margin = Math.max(2, this.strokewidth + 1) * 2 +
      parseFloat(this.style[mxConstants.STYLE_MARGIN] || 0);
    x += margin;
    y += margin;
    w -= 2 * margin;
    h -= 2 * margin;

    if (w > 0 && h > 0) {
      c.setShadow(false);

      // Workaround for closure compiler bug where the lines with x and y above
      // are removed if arguments is used as second argument in call below.
      mxRhombusPaintVertexShape.apply(this, [c, x, y, w, h]);
    }
  }
};

// CompositeShape
function ExtendedShape(this: any) {
  mxRectangleShape.call(this);
}
mxUtils.extend(ExtendedShape, mxRectangleShape);
ExtendedShape.prototype.isHtmlAllowed = () => {
  return false;
};
ExtendedShape.prototype.getLabelBounds = function (rect: any) {
  if (this.style.double === 1) {
    const margin = (Math.max(2, this.strokewidth + 1) + parseFloat(
      this.style[mxConstants.STYLE_MARGIN] || 0,
    )) * this.scale;

    return new mxRectangle(rect.x + margin, rect.y + margin,
      rect.width - 2 * margin, rect.height - 2 * margin);
  }

  return rect;
};

ExtendedShape.prototype.paintForeground = function (c: any, x: any, y: any, w: any, h: any) {
  if (this.style != null) {
    if (!this.outline && this.style.double === 1) {
      const margin = Math.max(2, this.strokewidth + 1) + parseFloat(this.style[mxConstants.STYLE_MARGIN] || 0);
      x += margin;
      y += margin;
      w -= 2 * margin;
      h -= 2 * margin;

      if (w > 0 && h > 0) {
        mxRectangleShape.prototype.paintBackground.apply(this, arguments);
      }
    }

    c.setDashed(false);

    let counter = 0;
    let shape: any = null;

    do {
      shape = mxCellRenderer.defaultShapes[this.style[`symbol${counter}`]];

      if (shape != null) {
        const align = this.style[`symbol${counter}Align`];
        const valign = this.style[`symbol${counter}VerticalAlign`];
        const width = this.style[`symbol${counter}Width`];
        const height = this.style[`symbol${counter}Height`];
        let spacing = this.style[`symbol${counter}Spacing`] || 0;
        let vspacing = this.style[`symbol${counter}VSpacing`] || spacing;
        const arcspacing = this.style[`symbol${counter}ArcSpacing`];

        if (arcspacing != null) {
          const arcSize = this.getArcSize(w + this.strokewidth, h + this.strokewidth) * arcspacing;
          spacing += arcSize;
          vspacing += arcSize;
        }

        let x2 = x;
        let y2 = y;

        if (align === mxConstants.ALIGN_CENTER) {
          x2 += (w - width) / 2;
        } else if (align === mxConstants.ALIGN_RIGHT) {
          x2 += w - width - spacing;
        } else {
          x2 += spacing;
        }

        if (valign === mxConstants.ALIGN_MIDDLE) {
          y2 += (h - height) / 2;
        } else if (valign === mxConstants.ALIGN_BOTTOM) {
          y2 += h - height - vspacing;
        } else {
          y2 += vspacing;
        }

        c.save();

        // Small hack to pass style along into subshape
        const tmp = new shape();
        // TODO: Clone style and override settings (eg. strokewidth)
        tmp.style = this.style;
        shape.prototype.paintVertexShape.call(tmp, c, x2, y2, width, height);
        c.restore();
      }

      counter++;
    }
    while (shape != null);
  }

  // Paints glass effect
  mxRectangleShape.prototype.paintForeground.apply(this, arguments);
};

mxCellRenderer.registerShape('ext', ExtendedShape);

// Manual Input shape
function ManualInputShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(ManualInputShape, mxActor);
ManualInputShape.prototype.size = 30;
ManualInputShape.prototype.isRoundable = () => {
  return true;
};
ManualInputShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const s = Math.min(h, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size)));
  const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
  this.addPoints(c, [new mxPoint(0, h), new mxPoint(0, s), new mxPoint(w, 0), new mxPoint(w, h)],
    this.isRounded, arcSize, true);
  c.end();
};

mxCellRenderer.registerShape('manualInput', ManualInputShape);

// Internal storage
function InternalStorageShape(this: any) {
  mxRectangleShape.call(this);
}
mxUtils.extend(InternalStorageShape, mxRectangleShape);
InternalStorageShape.prototype.dx = 20;
InternalStorageShape.prototype.dy = 20;
InternalStorageShape.prototype.isHtmlAllowed = () => {
  return false;
};
InternalStorageShape.prototype.paintForeground = function (c: any, x: any, y: any, w: any, h: any) {
  mxRectangleShape.prototype.paintForeground.apply(this, arguments);
  let inset = 0;

  if (this.isRounded) {
    const f = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE,
      mxConstants.RECTANGLE_ROUNDING_FACTOR * 100) / 100;
    inset = Math.max(inset, Math.min(w * f, h * f));
  }

  const dx = Math.max(inset, Math.min(w, parseFloat(mxUtils.getValue(this.style, 'dx', this.dx))));
  const dy = Math.max(inset, Math.min(h, parseFloat(mxUtils.getValue(this.style, 'dy', this.dy))));

  c.begin();
  c.moveTo(x, y + dy);
  c.lineTo(x + w, y + dy);
  c.end();
  c.stroke();

  c.begin();
  c.moveTo(x + dx, y);
  c.lineTo(x + dx, y + h);
  c.end();
  c.stroke();
};

mxCellRenderer.registerShape('internalStorage', InternalStorageShape);

// Internal storage
function CornerShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(CornerShape, mxActor);
CornerShape.prototype.dx = 20;
CornerShape.prototype.dy = 20;

// Corner
CornerShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const dx = Math.max(0, Math.min(w, parseFloat(mxUtils.getValue(this.style, 'dx', this.dx))));
  const dy = Math.max(0, Math.min(h, parseFloat(mxUtils.getValue(this.style, 'dy', this.dy))));

  // const s = Math.min(w / 2, Math.min(h, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
  this.addPoints(c, [new mxPoint(0, 0), new mxPoint(w, 0), new mxPoint(w, dy), new mxPoint(dx, dy),
  new mxPoint(dx, h), new mxPoint(0, h)
  ], this.isRounded, arcSize, true);
  c.end();
};

mxCellRenderer.registerShape('corner', CornerShape);

// Crossbar shape
function CrossbarShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(CrossbarShape, mxActor);

CrossbarShape.prototype.redrawPath = (c: any, _x: any, _y: any, w: any, h: any) => {
  c.moveTo(0, 0);
  c.lineTo(0, h);
  c.end();

  c.moveTo(w, 0);
  c.lineTo(w, h);
  c.end();

  c.moveTo(0, h / 2);
  c.lineTo(w, h / 2);
  c.end();
};

mxCellRenderer.registerShape('crossbar', CrossbarShape);

// Internal storage
function TeeShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(TeeShape, mxActor);
TeeShape.prototype.dx = 20;
TeeShape.prototype.dy = 20;

// Corner
TeeShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const dx = Math.max(0, Math.min(w, parseFloat(mxUtils.getValue(this.style, 'dx', this.dx))));
  const dy = Math.max(0, Math.min(h, parseFloat(mxUtils.getValue(this.style, 'dy', this.dy))));
  // const w2 = Math.abs(w - dx) / 2;

  // const s = Math.min(w / 2, Math.min(h, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
  this.addPoints(c, [new mxPoint(0, 0), new mxPoint(w, 0), new mxPoint(w, dy), new mxPoint((w + dx) / 2, dy),
  new mxPoint((w + dx) / 2, h), new mxPoint((w - dx) / 2, h), new mxPoint((w - dx) / 2, dy),
  new mxPoint(0, dy)
  ], this.isRounded, arcSize, true);
  c.end();
};

mxCellRenderer.registerShape('tee', TeeShape);

// // Arrow
// function SingleArrowShape(this: any) {
//   mxActor.call(this);
// }
// mxUtils.extend(SingleArrowShape, mxActor);
// SingleArrowShape.prototype.arrowWidth = 0.3;
// SingleArrowShape.prototype.arrowSize = 0.2;
// SingleArrowShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
//   const aw = h * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, 'arrowWidth', this.arrowWidth))));
//   const as = w * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, 'arrowSize', this.arrowSize))));
//   const at = (h - aw) / 2;
//   const ab = at + aw;

//   const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
//   this.addPoints(c, [new mxPoint(0, at), new mxPoint(w - as, at), new mxPoint(w - as, 0), new mxPoint(w, h / 2),
//   new mxPoint(w - as, h), new mxPoint(w - as, ab), new mxPoint(0, ab)
//   ],
//     this.isRounded, arcSize, true);
//   c.end();
// };
// mxCellRenderer.registerShape('singleArrow', SingleArrowShape);
// SingleArrowShape.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0, 0.5), false),
// new mxConnectionConstraint(new mxPoint(1, 0.5), false)
// ];
// // Arrow
// function DoubleArrowShape(this: any) {
//   mxActor.call(this);
// }
// mxUtils.extend(DoubleArrowShape, mxActor);
// DoubleArrowShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
//   const aw = h * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, 'arrowWidth', SingleArrowShape.prototype.arrowWidth))));
//   const as = w * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, 'arrowSize', SingleArrowShape.prototype.arrowSize))));
//   const at = (h - aw) / 2;
//   const ab = at + aw;

//   const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
//   this.addPoints(c, [new mxPoint(0, h / 2), new mxPoint(as, 0), new mxPoint(as, at), new mxPoint(w - as, at),
//   new mxPoint(w - as, 0), new mxPoint(w, h / 2), new mxPoint(w - as, h),
//   new mxPoint(w - as, ab), new mxPoint(as, ab), new mxPoint(as, h)
//   ],
//     this.isRounded, arcSize, true);
//   c.end();
// };

// mxCellRenderer.registerShape('doubleArrow', DoubleArrowShape);
// DoubleArrowShape.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0, 0.5), false),
// new mxConnectionConstraint(new mxPoint(1, 0.5), false)
// ];

// Data storage
function DataStorageShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(DataStorageShape, mxActor);
DataStorageShape.prototype.size = 0.1;
DataStorageShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const s = w * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));

  c.moveTo(s, 0);
  c.lineTo(w, 0);
  c.quadTo(w - s * 2, h / 2, w, h);
  c.lineTo(s, h);
  c.quadTo(s - s * 2, h / 2, s, 0);
  c.close();
  c.end();
};

mxCellRenderer.registerShape('dataStorage', DataStorageShape);



// Loop limit
function LoopLimitShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(LoopLimitShape, mxActor);
LoopLimitShape.prototype.size = 20;
LoopLimitShape.prototype.isRoundable = () => {
  return true;
};
LoopLimitShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const s = Math.min(w / 2, Math.min(h, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
  this.addPoints(c, [new mxPoint(s, 0), new mxPoint(w - s, 0), new mxPoint(w, s * 0.8), new mxPoint(w, h),
  new mxPoint(0, h), new mxPoint(0, s * 0.8)
  ], this.isRounded, arcSize, true);
  c.end();
};

mxCellRenderer.registerShape('loopLimit', LoopLimitShape);

// Off page connector
function OffPageConnectorShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(OffPageConnectorShape, mxActor);
OffPageConnectorShape.prototype.size = 3 / 8;
OffPageConnectorShape.prototype.isRoundable = () => {
  return true;
};
OffPageConnectorShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const s = h * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const arcSize = mxUtils.getValue(this.style, mxConstants.STYLE_ARCSIZE, mxConstants.LINE_ARCSIZE) / 2;
  this.addPoints(c, [new mxPoint(0, 0), new mxPoint(w, 0), new mxPoint(w, h - s), new mxPoint(w / 2, h),
  new mxPoint(0, h - s)
  ], this.isRounded, arcSize, true);
  c.end();
};

mxCellRenderer.registerShape('offPageConnector', OffPageConnectorShape);

// Internal storage
function TapeDataShape(this: any) {
  mxEllipse.call(this);
}
mxUtils.extend(TapeDataShape, mxEllipse);
TapeDataShape.prototype.paintVertexShape = function (c: any, x: any, y: any, w: any, h: any) {
  mxEllipse.prototype.paintVertexShape.apply(this, arguments);

  c.begin();
  c.moveTo(x + w / 2, y + h);
  c.lineTo(x + w, y + h);
  c.end();
  c.stroke();
};

mxCellRenderer.registerShape('tapeData', TapeDataShape);

// OrEllipseShape
function OrEllipseShape(this: any) {
  mxEllipse.call(this);
}
mxUtils.extend(OrEllipseShape, mxEllipse);
OrEllipseShape.prototype.paintVertexShape = function (c: any, x: any, y: any, w: any, h: any) {
  mxEllipse.prototype.paintVertexShape.apply(this, arguments);

  c.setShadow(false);
  c.begin();
  c.moveTo(x, y + h / 2);
  c.lineTo(x + w, y + h / 2);
  c.end();
  c.stroke();

  c.begin();
  c.moveTo(x + w / 2, y);
  c.lineTo(x + w / 2, y + h);
  c.end();
  c.stroke();
};

mxCellRenderer.registerShape('orEllipse', OrEllipseShape);

// SumEllipseShape
function SumEllipseShape(this: any) {
  mxEllipse.call(this);
}
mxUtils.extend(SumEllipseShape, mxEllipse);
SumEllipseShape.prototype.paintVertexShape = function (c: any, x: any, y: any, w: any, h: any) {
  mxEllipse.prototype.paintVertexShape.apply(this, arguments);
  const s2 = 0.145;

  c.setShadow(false);
  c.begin();
  c.moveTo(x + w * s2, y + h * s2);
  c.lineTo(x + w * (1 - s2), y + h * (1 - s2));
  c.end();
  c.stroke();

  c.begin();
  c.moveTo(x + w * (1 - s2), y + h * s2);
  c.lineTo(x + w * s2, y + h * (1 - s2));
  c.end();
  c.stroke();
};

mxCellRenderer.registerShape('sumEllipse', SumEllipseShape);

// SortShape
function SortShape(this: any) {
  mxRhombus.call(this);
}
mxUtils.extend(SortShape, mxRhombus);
SortShape.prototype.paintVertexShape = function (c: any, x: any, y: any, w: any, h: any) {
  mxRhombus.prototype.paintVertexShape.apply(this, arguments);

  c.setShadow(false);
  c.begin();
  c.moveTo(x, y + h / 2);
  c.lineTo(x + w, y + h / 2);
  c.end();
  c.stroke();
};

mxCellRenderer.registerShape('sortShape', SortShape);

// CollateShape
function CollateShape(this: any) {
  mxEllipse.call(this);
}
mxUtils.extend(CollateShape, mxEllipse);
CollateShape.prototype.paintVertexShape = (c: any, x: any, y: any, w: any, h: any) => {
  c.begin();
  c.moveTo(x, y);
  c.lineTo(x + w, y);
  c.lineTo(x + w / 2, y + h / 2);
  c.close();
  c.fillAndStroke();

  c.begin();
  c.moveTo(x, y + h);
  c.lineTo(x + w, y + h);
  c.lineTo(x + w / 2, y + h / 2);
  c.close();
  c.fillAndStroke();
};

mxCellRenderer.registerShape('collate', CollateShape);

// DimensionShape
function DimensionShape(this: any) {
  mxEllipse.call(this);
}
mxUtils.extend(DimensionShape, mxEllipse);
DimensionShape.prototype.paintVertexShape = (c: any, x: any, y: any, w: any, h: any) => {
  // Arrow size
  const al = 10;
  const cy = y + h - al / 2;

  c.begin();
  c.moveTo(x, y);
  c.lineTo(x, y + h);
  c.moveTo(x, cy);
  c.lineTo(x + al, cy - al / 2);
  c.moveTo(x, cy);
  c.lineTo(x + al, cy + al / 2);
  c.moveTo(x, cy);
  c.lineTo(x + w, cy);

  // Opposite side
  c.moveTo(x + w, y);
  c.lineTo(x + w, y + h);
  c.moveTo(x + w, cy);
  c.lineTo(x + w - al, cy - al / 2);
  c.moveTo(x + w, cy);
  c.lineTo(x + w - al, cy + al / 2);
  c.end();
  c.stroke();
};

mxCellRenderer.registerShape('dimension', DimensionShape);

// PartialRectangleShape
function PartialRectangleShape(this: any) {
  mxEllipse.call(this);
}
mxUtils.extend(PartialRectangleShape, mxEllipse);
PartialRectangleShape.prototype.paintVertexShape = function (c: any, x: any, y: any, w: any, h: any) {
  if (!this.outline) {
    c.setStrokeColor(null);
  }

  mxRectangleShape.prototype.paintBackground.apply(this, arguments);

  if (this.style != null) {
    c.setStrokeColor(this.stroke);
    c.rect(x, y, w, h);
    c.fill();

    c.begin();
    c.moveTo(x, y);

    if (mxUtils.getValue(this.style, 'top', '1') === '1') {
      c.lineTo(x + w, y);
    } else {
      c.moveTo(x + w, y);
    }

    if (mxUtils.getValue(this.style, 'right', '1') === '1') {
      c.lineTo(x + w, y + h);
    } else {
      c.moveTo(x + w, y + h);
    }

    if (mxUtils.getValue(this.style, 'bottom', '1') === '1') {
      c.lineTo(x, y + h);
    } else {
      c.moveTo(x, y + h);
    }

    if (mxUtils.getValue(this.style, 'left', '1') === '1') {
      c.lineTo(x, y - this.strokewidth / 2);
    }

    c.end();
    c.stroke();
  }
};

mxCellRenderer.registerShape('partialRectangle', PartialRectangleShape);

// LineEllipseShape
function LineEllipseShape(this: any) {
  mxEllipse.call(this);
}
mxUtils.extend(LineEllipseShape, mxEllipse);
LineEllipseShape.prototype.paintVertexShape = function (c: any, x: any, y: any, w: any, h: any) {
  mxEllipse.prototype.paintVertexShape.apply(this, arguments);

  c.setShadow(false);
  c.begin();

  if (mxUtils.getValue(this.style, 'line') === 'vertical') {
    c.moveTo(x + w / 2, y);
    c.lineTo(x + w / 2, y + h);
  } else {
    c.moveTo(x, y + h / 2);
    c.lineTo(x + w, y + h / 2);
  }

  c.end();
  c.stroke();
};

mxCellRenderer.registerShape('lineEllipse', LineEllipseShape);

// Delay
function DelayShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(DelayShape, mxActor);
DelayShape.prototype.redrawPath = (c: any, _x: any, _y: any, w: any, h: any) => {
  const dx = Math.min(w, h / 2);
  c.moveTo(0, 0);
  c.lineTo(w - dx, 0);
  c.quadTo(w, 0, w, h / 2);
  c.quadTo(w, h, w - dx, h);
  c.lineTo(0, h);
  c.close();
  c.end();
};

mxCellRenderer.registerShape('delay', DelayShape);

// Cross Shape
function CrossShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(CrossShape, mxActor);
CrossShape.prototype.size = 0.2;
CrossShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const m = Math.min(h, w);
  const size = Math.max(0, Math.min(m, m * parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const t = (h - size) / 2;
  const b = t + size;
  const l = (w - size) / 2;
  const r = l + size;

  c.moveTo(0, t);
  c.lineTo(l, t);
  c.lineTo(l, 0);
  c.lineTo(r, 0);
  c.lineTo(r, t);
  c.lineTo(w, t);
  c.lineTo(w, b);
  c.lineTo(r, b);
  c.lineTo(r, h);
  c.lineTo(l, h);
  c.lineTo(l, b);
  c.lineTo(0, b);
  c.close();
  c.end();
};

mxCellRenderer.registerShape('cross', CrossShape);

// Display
function DisplayShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(DisplayShape, mxActor);
DisplayShape.prototype.size = 0.25;
DisplayShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  const dx = Math.min(w, h / 2);
  const s = Math.min(w - dx, Math.max(0, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))) * w);

  c.moveTo(0, h / 2);
  c.lineTo(s, 0);
  c.lineTo(w - dx, 0);
  c.quadTo(w, 0, w, h / 2);
  c.quadTo(w, h, w - dx, h);
  c.lineTo(s, h);
  c.close();
  c.end();
};

mxCellRenderer.registerShape('display', DisplayShape);

// FilledEdge shape
function FilledEdge(this: any) {
  mxConnector.call(this);
}
mxUtils.extend(FilledEdge, mxConnector);

FilledEdge.prototype.origPaintEdgeShape = FilledEdge.prototype.paintEdgeShape;
FilledEdge.prototype.paintEdgeShape = function (c: any, pts: any, rounded: any) {
  // Markers modify incoming points array
  const temp: any[] = [];

  for (let i = 0; i < pts.length; i++) {
    temp.push(mxUtils.clone(pts[i]));
  }

  // paintEdgeShape resets dashed to false
  const dashed = c.state.dashed;
  const fixDash = c.state.fixDash;
  FilledEdge.prototype.origPaintEdgeShape.apply(this, [c, temp, rounded]);

  if (c.state.strokeWidth >= 3) {
    const fillClr = mxUtils.getValue(this.style, SemTalkStyleAttribute.fillColor, null);

    if (fillClr != null) {
      c.setStrokeColor(fillClr);
      c.setStrokeWidth(c.state.strokeWidth - 2);
      c.setDashed(dashed, fixDash);

      FilledEdge.prototype.origPaintEdgeShape.apply(this, [c, pts, rounded]);
    }
  }
};

// Registers the link shape
mxCellRenderer.registerShape('filledEdge', FilledEdge);

// Implements custom colors for shapes
// if (typeof StyleFormatPanel !== 'undefined') {
//   (function () {
//     const styleFormatPanelGetCustomColors = StyleFormatPanel.prototype.getCustomColors;

//     StyleFormatPanel.prototype.getCustomColors = function () {
//       const ss = this.format.getSelectionState();
//       const result = styleFormatPanelGetCustomColors.apply(this, arguments);

//       if (ss.style.shape == 'umlFrame') {
//         result.push({
//           title: mxResources.get('laneColor'),
//           key: SemTalkStyleAttribute.swimlaneFillColor,
//           defaultValue: '#ffffff'
//         });
//       }

//       return result;
//     };
//   }());
// }

// Registers and defines the custom marker
mxMarker.addMarker('dash', (c: any, _shape: any, _type: any, pe: any, unitX: any, unitY: any, size: any, _source: any, sw: any, _filled: any) => {
  let nx = unitX * (size + sw + 1);
  let ny = unitY * (size + sw + 1);

  return () => {
    c.begin();
    c.moveTo(pe.x - nx / 2 - ny / 2, pe.y - ny / 2 + nx / 2);
    c.lineTo(pe.x + ny / 2 - 3 * nx / 2, pe.y - 3 * ny / 2 - nx / 2);
    c.stroke();
  };
});

// Registers and defines the custom marker
mxMarker.addMarker('cross', (c: any, _shape: any, _type: any, pe: any, unitX: any, unitY: any, size: any, _source: any, sw: any, _filled: any) => {
  let nx = unitX * (size + sw + 1);
  let ny = unitY * (size + sw + 1);

  return () => {
    c.begin();
    c.moveTo(pe.x - nx / 2 - ny / 2, pe.y - ny / 2 + nx / 2);
    c.lineTo(pe.x + ny / 2 - 3 * nx / 2, pe.y - 3 * ny / 2 - nx / 2);
    c.moveTo(pe.x - nx / 2 + ny / 2, pe.y - ny / 2 - nx / 2);
    c.lineTo(pe.x - ny / 2 - 3 * nx / 2, pe.y - 3 * ny / 2 + nx / 2);
    c.stroke();
  };
});

function circleMarker(c: any, _shape: any, _type: any, pe: any, unitX: any, unitY: any, size: any, _source: any, sw: any, filled: any) {
  // const a = size / 2;
  // var size = size + sw;

  const pt = pe.clone();

  pe.x -= unitX * (2 * size + sw);
  pe.y -= unitY * (2 * size + sw);

  unitX *= (size + sw);
  unitY *= (size + sw);

  return () => {
    c.ellipse(pt.x - unitX - size, pt.y - unitY - size, 2 * size, 2 * size);

    if (filled) {
      c.fillAndStroke();
    } else {
      c.stroke();
    }
  };
}

mxMarker.addMarker('circle', circleMarker);
// mxMarker.addMarker('circlePlus', function (c: any, _shape: any, _type: any, pe: any, unitX: any, unitY: any, size: any, _source: any, sw: any, _filled: any) {
//   const pt = pe.clone();
//   const fn = circleMarker.apply(this, arguments);
//   const nx = unitX * (size + 2 * sw); // (size + sw + 1);
//   const ny = unitY * (size + 2 * sw); // (size + sw + 1);

//   return function () {
//     fn.apply(this, arguments);

//     c.begin();
//     c.moveTo(pt.x - unitX * (sw), pt.y - unitY * (sw));
//     c.lineTo(pt.x - 2 * nx + unitX * (sw), pt.y - 2 * ny + unitY * (sw));
//     c.moveTo(pt.x - nx - ny + unitY * sw, pt.y - ny + nx - unitX * sw);
//     c.lineTo(pt.x + ny - nx - unitY * sw, pt.y - ny - nx + unitX * sw);
//     c.stroke();
//   };
// });

mxMarker.addMarker('async', (c: any, _shape: any, _type: any, pe: any, unitX: any, unitY: any, size: any, source: any, sw: any, filled: any) => {
  // The angle of the forward facing arrow sides against the x axis is
  // 26.565 degrees, 1/sin(26.565) = 2.236 / 2 = 1.118 ( / 2 allows for
  // only half the strokewidth is processed ).
  let endOffsetX = unitX * sw * 1.118;
  let endOffsetY = unitY * sw * 1.118;

  unitX *= (size + sw);
  unitY *= (size + sw);

  let pt = pe.clone();
  pt.x -= endOffsetX;
  pt.y -= endOffsetY;

  let f = 1;
  pe.x += -unitX * f - endOffsetX;
  pe.y += -unitY * f - endOffsetY;

  return () => {
    c.begin();
    c.moveTo(pt.x, pt.y);

    if (source) {
      c.lineTo(pt.x - unitX - unitY / 2, pt.y - unitY + unitX / 2);
    } else {
      c.lineTo(pt.x + unitY / 2 - unitX, pt.y - unitY - unitX / 2);
    }

    c.lineTo(pt.x - unitX, pt.y - unitY);
    c.close();

    if (filled) {
      c.fillAndStroke();
    } else {
      c.stroke();
    }
  };
});

function createOpenAsyncArrow(widthFactor: any) {
  widthFactor = (widthFactor != null) ? widthFactor : 2;

  return (c: any, _shape: any, _type: any, pe: any, unitX: any, unitY: any, size: any, source: any, sw: any, _filled: any) => {
    unitX *= (size + sw);
    unitY *= (size + sw);

    const pt = pe.clone();

    return () => {
      c.begin();
      c.moveTo(pt.x, pt.y);

      if (source) {
        c.lineTo(pt.x - unitX - unitY / widthFactor, pt.y - unitY + unitX / widthFactor);
      } else {
        c.lineTo(pt.x + unitY / widthFactor - unitX, pt.y - unitY - unitX / widthFactor);
      }

      c.stroke();
    };
  };
}

mxMarker.addMarker('openAsync', createOpenAsyncArrow(2));


let isoHVector = new mxPoint(1, 0);
let isoVVector = new mxPoint(1, 0);

const alpha1 = mxUtils.toRadians(-30);

const cos1 = Math.cos(alpha1);
const sin1 = Math.sin(alpha1);

isoHVector = mxUtils.getRotatedPoint(isoHVector, cos1, sin1);

const alpha2 = mxUtils.toRadians(-150);

const cos2 = Math.cos(alpha2);
const sin2 = Math.sin(alpha2);

isoVVector = mxUtils.getRotatedPoint(isoVVector, cos2, sin2);

mxEdgeStyle.IsometricConnector = (state: any, source: any, target: any, points: any, result: any) => {
  const view = state.view;
  let pt = (points != null && points.length > 0) ? points[0] : null;
  const pts = state.absolutePoints;
  let p0 = pts[0];
  let pe = pts[pts.length - 1];

  if (pt != null) {
    pt = view.transformControlPoint(state, pt);
  }

  if (p0 == null) {
    if (source != null) {
      p0 = new mxPoint(source.getCenterX(), source.getCenterY());
    }
  }

  if (pe == null) {
    if (target != null) {
      pe = new mxPoint(target.getCenterX(), target.getCenterY());
    }
  }

  const a1 = isoHVector.x;
  const a2 = isoHVector.y;

  const b1 = isoVVector.x;
  const b2 = isoVVector.y;

  const elbow = mxUtils.getValue(state.style, SemTalkStyleAttribute.elbow, 'horizontal') === 'horizontal';

  if (pe != null && p0 != null) {
    let last = p0;

    function isoLineTo(x: any, y: any, ignoreFirst: any) {
      const c1 = x - last.x;
      const c2 = y - last.y;

      // Solves for isometric base vectors
      const h = (b2 * c1 - b1 * c2) / (a1 * b2 - a2 * b1);
      const v = (a2 * c1 - a1 * c2) / (a2 * b1 - a1 * b2);

      if (elbow) {
        if (ignoreFirst) {
          last = new mxPoint(last.x + a1 * h, last.y + a2 * h);
          result.push(last);
        }

        last = new mxPoint(last.x + b1 * v, last.y + b2 * v);
        result.push(last);
      } else {
        if (ignoreFirst) {
          last = new mxPoint(last.x + b1 * v, last.y + b2 * v);
          result.push(last);
        }

        last = new mxPoint(last.x + a1 * h, last.y + a2 * h);
        result.push(last);
      }
    }

    if (pt == null) {
      pt = new mxPoint(p0.x + (pe.x - p0.x) / 2, p0.y + (pe.y - p0.y) / 2);
    }

    isoLineTo(pt.x, pt.y, true);
    isoLineTo(pe.x, pe.y, false);
  }
};

mxStyleRegistry.putValue('isometricEdgeStyle', mxEdgeStyle.IsometricConnector);

//  let graphCreateEdgeHandler = Graph.prototype.createEdgeHandler;
//  Graph.prototype.createEdgeHandler = function (state, edgeStyle) {
//  	if (edgeStyle == mxEdgeStyle.IsometricConnector) {
//  		let handler = new mxElbowEdgeHandler(state);
//  		handler.snapToTerminals = false;

//  		return handler;
//  	}

//  	return graphCreateEdgeHandler.apply(this, arguments);
//  };

// Defines connection points for all shapes
IsoRectangleShape.prototype.constraints = [];
IsoCubeShape.prototype.constraints = [];
CalloutShape.prototype.constraints = [];
mxRectangleShape.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0.25, 0), true),
new mxConnectionConstraint(new mxPoint(0.5, 0), true),
new mxConnectionConstraint(new mxPoint(0.75, 0), true),
new mxConnectionConstraint(new mxPoint(0, 0.25), true),
new mxConnectionConstraint(new mxPoint(0, 0.5), true),
new mxConnectionConstraint(new mxPoint(0, 0.75), true),
new mxConnectionConstraint(new mxPoint(1, 0.25), true),
new mxConnectionConstraint(new mxPoint(1, 0.5), true),
new mxConnectionConstraint(new mxPoint(1, 0.75), true),
new mxConnectionConstraint(new mxPoint(0.25, 1), true),
new mxConnectionConstraint(new mxPoint(0.5, 1), true),
new mxConnectionConstraint(new mxPoint(0.75, 1), true)
];
mxEllipse.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0, 0), true), new mxConnectionConstraint(new mxPoint(1, 0), true),
new mxConnectionConstraint(new mxPoint(0, 1), true), new mxConnectionConstraint(new mxPoint(1, 1), true),
new mxConnectionConstraint(new mxPoint(0.5, 0), true), new mxConnectionConstraint(new mxPoint(0.5, 1), true),
new mxConnectionConstraint(new mxPoint(0, 0.5), true), new mxConnectionConstraint(new mxPoint(1, 0.5))
];
mxLabel.prototype.constraints = mxRectangleShape.prototype.constraints;
mxImageShape.prototype.constraints = mxRectangleShape.prototype.constraints;
mxSwimlane.prototype.constraints = mxRectangleShape.prototype.constraints;
PlusShape.prototype.constraints = mxRectangleShape.prototype.constraints;
NoteShape.prototype.constraints = mxRectangleShape.prototype.constraints;
CardShape.prototype.constraints = mxRectangleShape.prototype.constraints;
CubeShape.prototype.constraints = mxRectangleShape.prototype.constraints;
FolderShape.prototype.constraints = mxRectangleShape.prototype.constraints;
InternalStorageShape.prototype.constraints = mxRectangleShape.prototype.constraints;
DataStorageShape.prototype.constraints = mxRectangleShape.prototype.constraints;
TapeDataShape.prototype.constraints = mxEllipse.prototype.constraints;
OrEllipseShape.prototype.constraints = mxEllipse.prototype.constraints;
SumEllipseShape.prototype.constraints = mxEllipse.prototype.constraints;
LineEllipseShape.prototype.constraints = mxEllipse.prototype.constraints;
ManualInputShape.prototype.constraints = mxRectangleShape.prototype.constraints;
DelayShape.prototype.constraints = mxRectangleShape.prototype.constraints;
DisplayShape.prototype.constraints = mxRectangleShape.prototype.constraints;
LoopLimitShape.prototype.constraints = mxRectangleShape.prototype.constraints;
OffPageConnectorShape.prototype.constraints = mxRectangleShape.prototype.constraints;
mxCylinder.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0.15, 0.05), false),
new mxConnectionConstraint(new mxPoint(0.5, 0), true),
new mxConnectionConstraint(new mxPoint(0.85, 0.05), false),
new mxConnectionConstraint(new mxPoint(0, 0.3), true),
new mxConnectionConstraint(new mxPoint(0, 0.5), true),
new mxConnectionConstraint(new mxPoint(0, 0.7), true),
new mxConnectionConstraint(new mxPoint(1, 0.3), true),
new mxConnectionConstraint(new mxPoint(1, 0.5), true),
new mxConnectionConstraint(new mxPoint(1, 0.7), true),
new mxConnectionConstraint(new mxPoint(0.15, 0.95), false),
new mxConnectionConstraint(new mxPoint(0.5, 1), true),
new mxConnectionConstraint(new mxPoint(0.85, 0.95), false)
];

mxActor.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0.5, 0), true),
new mxConnectionConstraint(new mxPoint(0.25, 0.2), false),
new mxConnectionConstraint(new mxPoint(0.1, 0.5), false),
new mxConnectionConstraint(new mxPoint(0, 0.75), true),
new mxConnectionConstraint(new mxPoint(0.75, 0.25), false),
new mxConnectionConstraint(new mxPoint(0.9, 0.5), false),
new mxConnectionConstraint(new mxPoint(1, 0.75), true),
new mxConnectionConstraint(new mxPoint(0.25, 1), true),
new mxConnectionConstraint(new mxPoint(0.5, 1), true),
new mxConnectionConstraint(new mxPoint(0.75, 1), true)
];
SwitchShape.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0, 0), false),
new mxConnectionConstraint(new mxPoint(0.5, 0.25), false),
new mxConnectionConstraint(new mxPoint(1, 0), false),
new mxConnectionConstraint(new mxPoint(0.25, 0.5), false),
new mxConnectionConstraint(new mxPoint(0.75, 0.5), false),
new mxConnectionConstraint(new mxPoint(0, 1), false),
new mxConnectionConstraint(new mxPoint(0.5, 0.75), false),
new mxConnectionConstraint(new mxPoint(1, 1), false)
];
TapeShape.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0, 0.35), false),
new mxConnectionConstraint(new mxPoint(0, 0.5), false),
new mxConnectionConstraint(new mxPoint(0, 0.65), false),
new mxConnectionConstraint(new mxPoint(1, 0.35), false),
new mxConnectionConstraint(new mxPoint(1, 0.5), false),
new mxConnectionConstraint(new mxPoint(1, 0.65), false),
new mxConnectionConstraint(new mxPoint(0.25, 1), false),
new mxConnectionConstraint(new mxPoint(0.75, 0), false)
];

// Step Perimeter
mxPerimeter.StepPerimeter = (bounds: any, vertex: any, next: any, orthogonal: any) => {
  const fixed = mxUtils.getValue(vertex.style, SemTalkStyleAttribute.fixedSize, '0') !== '0';
  let size = (fixed) ? StepShape.prototype.fixedSize : StepShape.prototype.size;

  if (vertex != null) {
    size = mxUtils.getValue(vertex.style, SemTalkStyleAttribute.size, size);
  }

  const x = bounds.x;
  const y = bounds.y;
  const w = bounds.width;
  const h = bounds.height;

  const cx = bounds.getCenterX();
  const cy = bounds.getCenterY();

  const direction = (vertex != null) ? mxUtils.getValue(
    vertex.style, mxConstants.STYLE_DIRECTION,
    mxConstants.DIRECTION_EAST,
  ) : mxConstants.DIRECTION_EAST;
  let points;

  if (direction === mxConstants.DIRECTION_EAST) {
    let dx = (fixed) ? Math.max(0, Math.min(w, size)) : w * Math.max(0, Math.min(1, size));
    points = [new mxPoint(x, y), new mxPoint(x + w - dx, y), new mxPoint(x + w, cy),
    new mxPoint(x + w - dx, y + h), new mxPoint(x, y + h),
    new mxPoint(x + dx, cy), new mxPoint(x, y)
    ];
  } else if (direction === mxConstants.DIRECTION_WEST) {
    let dx = (fixed) ? Math.max(0, Math.min(w, size)) : w * Math.max(0, Math.min(1, size));
    points = [new mxPoint(x + dx, y), new mxPoint(x + w, y), new mxPoint(x + w - dx, cy),
    new mxPoint(x + w, y + h), new mxPoint(x + dx, y + h),
    new mxPoint(x, cy), new mxPoint(x + dx, y)
    ];
  } else if (direction === mxConstants.DIRECTION_NORTH) {
    let dy = (fixed) ? Math.max(0, Math.min(h, size)) : h * Math.max(0, Math.min(1, size));
    points = [new mxPoint(x, y + dy), new mxPoint(cx, y), new mxPoint(x + w, y + dy),
    new mxPoint(x + w, y + h), new mxPoint(cx, y + h - dy),
    new mxPoint(x, y + h), new mxPoint(x, y + dy)
    ];
  } else {
    let dy = (fixed) ? Math.max(0, Math.min(h, size)) : h * Math.max(0, Math.min(1, size));
    points = [new mxPoint(x, y), new mxPoint(cx, y + dy), new mxPoint(x + w, y),
    new mxPoint(x + w, y + h - dy), new mxPoint(cx, y + h),
    new mxPoint(x, y + h - dy), new mxPoint(x, y)
    ];
  }

  const p1 = new mxPoint(cx, cy);

  if (orthogonal) {
    if (next.x < x || next.x > x + w) {
      p1.y = next.y;
    } else {
      p1.x = next.x;
    }
  }

  return mxUtils.getPerimeterPoint(points, p1, next);
};
mxStyleRegistry.putValue('stepPerimeter', mxPerimeter.StepPerimeter);
StepShape.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0.25, 0), true),
new mxConnectionConstraint(new mxPoint(0.5, 0), true),
new mxConnectionConstraint(new mxPoint(0.75, 0), true),
new mxConnectionConstraint(new mxPoint(0.25, 1), true),
new mxConnectionConstraint(new mxPoint(0.5, 1), true),
new mxConnectionConstraint(new mxPoint(0.75, 1), true),
new mxConnectionConstraint(new mxPoint(0, 0.25), true),
new mxConnectionConstraint(new mxPoint(0, 0.5), true),
new mxConnectionConstraint(new mxPoint(0, 0.75), true),
new mxConnectionConstraint(new mxPoint(1, 0.25), true),
new mxConnectionConstraint(new mxPoint(1, 0.5), true),
new mxConnectionConstraint(new mxPoint(1, 0.75), true)
];
mxLine.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0, 0.5), false),
new mxConnectionConstraint(new mxPoint(0.25, 0.5), false),
new mxConnectionConstraint(new mxPoint(0.75, 0.5), false),
new mxConnectionConstraint(new mxPoint(1, 0.5), false)
];


mxDoubleEllipse.prototype.constraints = mxEllipse.prototype.constraints;
mxRhombus.prototype.constraints = mxEllipse.prototype.constraints;
mxTriangle.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0, 0.25), true),
new mxConnectionConstraint(new mxPoint(0, 0.5), true),
new mxConnectionConstraint(new mxPoint(0, 0.75), true),
new mxConnectionConstraint(new mxPoint(0.5, 0), true),
new mxConnectionConstraint(new mxPoint(0.5, 1), true),
new mxConnectionConstraint(new mxPoint(1, 0.5), true)
];
// Hexagon Perimeter 2 (keep existing one)
mxPerimeter.HexagonPerimeter2 = (bounds: any, vertex: any, next: any, orthogonal: any) => {
  let size = HexagonShape.prototype.size;

  if (vertex != null) {
    size = mxUtils.getValue(vertex.style, SemTalkStyleAttribute.size, size);
  }

  const x = bounds.x;
  const y = bounds.y;
  const w = bounds.width;
  const h = bounds.height;

  const cx = bounds.getCenterX();
  const cy = bounds.getCenterY();

  const direction = (vertex != null) ? mxUtils.getValue(
    vertex.style, mxConstants.STYLE_DIRECTION,
    mxConstants.DIRECTION_EAST,
  ) : mxConstants.DIRECTION_EAST;
  const vertical = direction === mxConstants.DIRECTION_NORTH ||
    direction === mxConstants.DIRECTION_SOUTH;
  let points;

  if (vertical) {
    const dy = h * Math.max(0, Math.min(1, size));
    points = [new mxPoint(cx, y), new mxPoint(x + w, y + dy), new mxPoint(x + w, y + h - dy),
    new mxPoint(cx, y + h), new mxPoint(x, y + h - dy),
    new mxPoint(x, y + dy), new mxPoint(cx, y)
    ];
  } else {
    const dx = w * Math.max(0, Math.min(1, size));
    points = [new mxPoint(x + dx, y), new mxPoint(x + w - dx, y), new mxPoint(x + w, cy),
    new mxPoint(x + w - dx, y + h), new mxPoint(x + dx, y + h),
    new mxPoint(x, cy), new mxPoint(x + dx, y)
    ];
  }

  const p1 = new mxPoint(cx, cy);

  if (orthogonal) {
    if (next.x < x || next.x > x + w) {
      p1.y = next.y;
    } else {
      p1.x = next.x;
    }
  }

  return mxUtils.getPerimeterPoint(points, p1, next);
};

mxStyleRegistry.putValue('hexagonPerimeter2', mxPerimeter.HexagonPerimeter2);

mxHexagon.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0.375, 0), true),
new mxConnectionConstraint(new mxPoint(0.5, 0), true),
new mxConnectionConstraint(new mxPoint(0.625, 0), true),
new mxConnectionConstraint(new mxPoint(0, 0.25), true),
new mxConnectionConstraint(new mxPoint(0, 0.5), true),
new mxConnectionConstraint(new mxPoint(0, 0.75), true),
new mxConnectionConstraint(new mxPoint(1, 0.25), true),
new mxConnectionConstraint(new mxPoint(1, 0.5), true),
new mxConnectionConstraint(new mxPoint(1, 0.75), true),
new mxConnectionConstraint(new mxPoint(0.375, 1), true),
new mxConnectionConstraint(new mxPoint(0.5, 1), true),
new mxConnectionConstraint(new mxPoint(0.625, 1), true)
];
mxCloud.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0.25, 0.25), false),
new mxConnectionConstraint(new mxPoint(0.4, 0.1), false),
new mxConnectionConstraint(new mxPoint(0.16, 0.55), false),
new mxConnectionConstraint(new mxPoint(0.07, 0.4), false),
new mxConnectionConstraint(new mxPoint(0.31, 0.8), false),
new mxConnectionConstraint(new mxPoint(0.13, 0.77), false),
new mxConnectionConstraint(new mxPoint(0.8, 0.8), false),
new mxConnectionConstraint(new mxPoint(0.55, 0.95), false),
new mxConnectionConstraint(new mxPoint(0.875, 0.5), false),
new mxConnectionConstraint(new mxPoint(0.96, 0.7), false),
new mxConnectionConstraint(new mxPoint(0.625, 0.2), false),
new mxConnectionConstraint(new mxPoint(0.88, 0.25), false)
];
// Parallelogram Perimeter
mxPerimeter.ParallelogramPerimeter = (bounds: any, vertex: any, next: any, orthogonal: any) => {
  let size = ParallelogramShape.prototype.size;

  if (vertex != null) {
    size = mxUtils.getValue(vertex.style, SemTalkStyleAttribute.size, size);
  }

  const x = bounds.x;
  const y = bounds.y;
  const w = bounds.width;
  const h = bounds.height;

  const direction = (vertex != null) ? mxUtils.getValue(
    vertex.style, mxConstants.STYLE_DIRECTION,
    mxConstants.DIRECTION_EAST,
  ) : mxConstants.DIRECTION_EAST;
  const vertical = direction === mxConstants.DIRECTION_NORTH ||
    direction === mxConstants.DIRECTION_SOUTH;
  let points;

  if (vertical) {
    const dy = h * Math.max(0, Math.min(1, size));
    points = [new mxPoint(x, y), new mxPoint(x + w, y + dy),
    new mxPoint(x + w, y + h), new mxPoint(x, y + h - dy), new mxPoint(x, y)
    ];
  } else {
    const dx = w * Math.max(0, Math.min(1, size));
    points = [new mxPoint(x + dx, y), new mxPoint(x + w, y),
    new mxPoint(x + w - dx, y + h), new mxPoint(x, y + h), new mxPoint(x + dx, y)
    ];
  }

  const cx = bounds.getCenterX();
  const cy = bounds.getCenterY();

  const p1 = new mxPoint(cx, cy);

  if (orthogonal) {
    if (next.x < x || next.x > x + w) {
      p1.y = next.y;
    } else {
      p1.x = next.x;
    }
  }

  return mxUtils.getPerimeterPoint(points, p1, next);
};

mxStyleRegistry.putValue('parallelogramPerimeter', mxPerimeter.ParallelogramPerimeter);
ParallelogramShape.prototype.constraints = mxRectangleShape.prototype.constraints;



// Trapezoid Perimeter
mxPerimeter.TrapezoidPerimeter = (bounds: any, vertex: any, next: any, orthogonal: any) => {
  let size = TrapezoidShape.prototype.size;

  if (vertex != null) {
    size = mxUtils.getValue(vertex.style, SemTalkStyleAttribute.size, size);
  }

  const x = bounds.x;
  const y = bounds.y;
  const w = bounds.width;
  const h = bounds.height;

  const direction = (vertex != null) ? mxUtils.getValue(
    vertex.style, mxConstants.STYLE_DIRECTION,
    mxConstants.DIRECTION_EAST,
  ) : mxConstants.DIRECTION_EAST;
  let points;

  if (direction === mxConstants.DIRECTION_EAST) {
    let dx = w * Math.max(0, Math.min(1, size));
    points = [new mxPoint(x + dx, y), new mxPoint(x + w - dx, y),
    new mxPoint(x + w, y + h), new mxPoint(x, y + h), new mxPoint(x + dx, y)
    ];
  } else if (direction === mxConstants.DIRECTION_WEST) {
    let dx = w * Math.max(0, Math.min(1, size));
    points = [new mxPoint(x, y), new mxPoint(x + w, y),
    new mxPoint(x + w - dx, y + h), new mxPoint(x + dx, y + h), new mxPoint(x, y)
    ];
  } else if (direction === mxConstants.DIRECTION_NORTH) {
    let dy = h * Math.max(0, Math.min(1, size));
    points = [new mxPoint(x, y + dy), new mxPoint(x + w, y),
    new mxPoint(x + w, y + h), new mxPoint(x, y + h - dy), new mxPoint(x, y + dy)
    ];
  } else {
    let dy = h * Math.max(0, Math.min(1, size));
    points = [new mxPoint(x, y), new mxPoint(x + w, y + dy),
    new mxPoint(x + w, y + h - dy), new mxPoint(x, y + h), new mxPoint(x, y)
    ];
  }

  const cx = bounds.getCenterX();
  const cy = bounds.getCenterY();

  const p1 = new mxPoint(cx, cy);

  if (orthogonal) {
    if (next.x < x || next.x > x + w) {
      p1.y = next.y;
    } else {
      p1.x = next.x;
    }
  }

  return mxUtils.getPerimeterPoint(points, p1, next);
};
mxStyleRegistry.putValue('trapezoidPerimeter', mxPerimeter.TrapezoidPerimeter);
TrapezoidShape.prototype.constraints = mxRectangleShape.prototype.constraints;

DocumentShape.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0.25, 0), true),
new mxConnectionConstraint(new mxPoint(0.5, 0), true),
new mxConnectionConstraint(new mxPoint(0.75, 0), true),
new mxConnectionConstraint(new mxPoint(0, 0.25), true),
new mxConnectionConstraint(new mxPoint(0, 0.5), true),
new mxConnectionConstraint(new mxPoint(0, 0.75), true),
new mxConnectionConstraint(new mxPoint(1, 0.25), true),
new mxConnectionConstraint(new mxPoint(1, 0.5), true),
new mxConnectionConstraint(new mxPoint(1, 0.75), true)
];
mxArrow.prototype.constraints = null;
TeeShape.prototype.constraints = null;
CornerShape.prototype.constraints = null;
CrossbarShape.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0, 0), false),
new mxConnectionConstraint(new mxPoint(0, 0.5), false),
new mxConnectionConstraint(new mxPoint(0, 1), false),
new mxConnectionConstraint(new mxPoint(0.25, 0.5), false),
new mxConnectionConstraint(new mxPoint(0.5, 0.5), false),
new mxConnectionConstraint(new mxPoint(0.75, 0.5), false),
new mxConnectionConstraint(new mxPoint(1, 0), false),
new mxConnectionConstraint(new mxPoint(1, 0.5), false),
new mxConnectionConstraint(new mxPoint(1, 1), false)
];


CrossShape.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0, 0.5), false),
new mxConnectionConstraint(new mxPoint(1, 0.5), false),
new mxConnectionConstraint(new mxPoint(0.5, 0), false),
new mxConnectionConstraint(new mxPoint(0.5, 1), false)
];


