import { SemTalkStyleAttribute } from "../../visiordfsinterface";

export const SB_SHAPES: any = {
  List: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=datastore;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  Library: {
    style: SemTalkStyleAttribute.shape + '=datastore;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;fillColor=lightyellow;',
  },
  LookupColumn: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sblookupcolumn;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;fillColor=lightgreen;',
  },
  TextColumn: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbtextcolumn;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  NumberColumn: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbnumbercolumn;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  ChoiceColumn: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbchoicecolumn;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  BooleanColumn: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbbooleancolumn;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  DateTimeColumn: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbdatetimecolumn;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  CalculatedColumn: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbcalculatedcolumn;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  CurrencyColumn: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbcurrencycolumn;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  PersonOrGroupColumn: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbpersonorgroupcolumn;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  GeolocationColumn: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbgeolocationcolumn;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  HyperlinkOrPictureColumn: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbhyperlinkorpicturecolumn;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  TermColumn: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbtermcolumn;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  ThumbnailColumn: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbthumbnailcolumn;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  Folder: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbfolder;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  Item: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbcontenttype;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;',
  },
  Document: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=sbdocument;whiteSpace=wrap;' +
      SemTalkStyleAttribute.resize + '=0;html=1;fillColor=lightyellow;',
  }
};
