import { ISemTalkUndo } from './ISemTalkUndo';
import { inflate, deflate, inflateSync } from 'zlib';
import { SemTalkCookie } from './ISemTalkCookie';
import { accessCookie, setCookie } from './application/semtalklistener/stgoto';

export class SemTalkUndo implements ISemTalkUndo {
  private undo = {
    'autosaveSemTalkRedo': '',
    'autosaveSemTalkUndo0': '',
    'autosaveSemTalkUndo1': '',
    'autosaveSemTalkUndo2': '',
    'autosaveSemTalkUndo3': '',
    'autosaveSemTalkUndo4': '',
    'autosaveSemTalkUndo5': '',
    'autosaveSemTalkUndo6': '',
    'autosaveSemTalkUndo7': '',
    'autosaveSemTalkUndo8': '',
    'autosaveSemTalkUndo9': ''
  };

  public IsUndo = (): boolean => {
    let s = this.undo['autosaveSemTalkUndo0'];
    return (s !== undefined && s.length > 0);
  }
  public GetUndo = (): string => {
    const s1 = this.undo['autosaveSemTalkUndo0'];
    const b = Buffer.from(s1, 'base64');
    const s = inflateSync(b).toString("utf8");
    return s;
    // return this.undo['autosaveSemTalkUndo0'];
  }
  public Undo = () => {
    this.undo['autosaveSemTalkRedo'] = accessCookie(SemTalkCookie.autosaveSemTalk);
    let s1 = this.undo['autosaveSemTalkUndo0'];
    let b = Buffer.from(s1, 'base64');
    inflate(b, (_err, bi) => {
      const s1i = bi.toString("utf8");
      setCookie(SemTalkCookie.autosaveSemTalk, s1i);
      this.undo['autosaveSemTalkUndo0'] = this.undo['autosaveSemTalkUndo1'];
      this.undo['autosaveSemTalkUndo1'] = this.undo['autosaveSemTalkUndo2'];
      this.undo['autosaveSemTalkUndo2'] = this.undo['autosaveSemTalkUndo3'];
      this.undo['autosaveSemTalkUndo3'] = this.undo['autosaveSemTalkUndo4'];
      this.undo['autosaveSemTalkUndo4'] = this.undo['autosaveSemTalkUndo5'];
      this.undo['autosaveSemTalkUndo5'] = this.undo['autosaveSemTalkUndo6'];
      this.undo['autosaveSemTalkUndo6'] = this.undo['autosaveSemTalkUndo7'];
      this.undo['autosaveSemTalkUndo7'] = this.undo['autosaveSemTalkUndo8'];
      this.undo['autosaveSemTalkUndo8'] = this.undo['autosaveSemTalkUndo9'];
      this.undo['autosaveSemTalkUndo9'] = '';
    });
  }
  public SaveUndo = (s: string, _r: string) => {
    // console.debug(s.length);
    setTimeout(() => {
      var b = Buffer.from(s, 'utf8');
      deflate(b, (_err, buf) => {
        this.undo['autosaveSemTalkUndo9'] = this.undo['autosaveSemTalkUndo8'];
        this.undo['autosaveSemTalkUndo8'] = this.undo['autosaveSemTalkUndo7'];
        this.undo['autosaveSemTalkUndo7'] = this.undo['autosaveSemTalkUndo6'];
        this.undo['autosaveSemTalkUndo6'] = this.undo['autosaveSemTalkUndo5'];
        this.undo['autosaveSemTalkUndo5'] = this.undo['autosaveSemTalkUndo4'];
        this.undo['autosaveSemTalkUndo4'] = this.undo['autosaveSemTalkUndo3'];
        this.undo['autosaveSemTalkUndo3'] = this.undo['autosaveSemTalkUndo2'];
        this.undo['autosaveSemTalkUndo2'] = this.undo['autosaveSemTalkUndo1'];
        this.undo['autosaveSemTalkUndo1'] = this.undo['autosaveSemTalkUndo0'];
        const si = buf.toString('base64');
        // console.debug(si.length);
        this.undo['autosaveSemTalkUndo0'] = si;
        // console.debug(r + ":", this.undo);
      });
    });
  }
  public GetRedo = (): string => {
    return this.undo['autosaveSemTalkRedo'];
  }
  public Redo = (): void => {
    const s = this.undo['autosaveSemTalkRedo'];
    this.undo['autosaveSemTalkRedo'] = '';
    this.SaveUndo(s, "Redo");
  }
}

