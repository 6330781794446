import { IMongoOption, mgGetItems } from '../../SemTalkMongoServer';

export async function filterByAdmin(mg: IMongoOption, list: string,
  options: { key: string, text: string }[]): Promise<{ key: string, text: string }[]> {
  let adminlists = await mgGetItems(mg, "semtalkonline_admin", "database_admin");
  let badlist: string[] = [];
  if (adminlists.length > 0) {
    let adminlist = adminlists[0];
    if (adminlist && adminlist[list]) {
      badlist = adminlist[list];
    }
    options = options.filter(x => badlist.indexOf(x.key) < 0);
  }
  return options;
}