import "mxgraph-type-definitions";
import { IVisioRDFS } from "../semtalklistener/visiordfsinterface";

/* tslint:disable:member-ordering */
/* tslint:disable:forin */
/* tslint:disable:no-empty */

export class Cell {
  constructor(parent: mxCell | null, mxObjectId: string, value: any, x: number | null, y: number | null, width: number | null, height: number | null, _style?: any, _relative?: boolean) {
    // this.id = id;
    this.mxObjectId = mxObjectId;
    if (value.nodeName !== undefined) {
      this.shapeName = value.nodeName;
    }
    // this.objectid = Number(mxObjectId);
    // if (mxObjectId.indexOf("Sheet.") === 0) {
    //   this.shapeid = mxObjectId;
    // } else {
    //   this.shapeid = "mxSheet." + mxObjectId;
    // }
    this.value = value;
    this.parent = parent;
    this.label = value.name;
    this.geometry = { "x": x, "y": y, "width": width, "height": height };
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }
  public oned: boolean = false;
  public mxObjectId: string;
  //  public id: string;
  public geometry: any;
  private _shapeid: any;
  public get shapeid(): string {
    if (this._shapeid !== undefined)
      return this._shapeid;
    return this.mxObjectId;
  }
  public set shapeid(s: string) {
    this._shapeid = s;
  }
  public objectid: string = "-1";
  public label: string;
  public parent: mxCell | null = null;
  public x: number | null;
  public y: number | null;
  public width: number | null;
  public height: number | null;
  public value = { name: "", nodeName: "" };
  public shapeName: string | null = null; // mastername
  public shapeKey: string | null = null;
  public edges: Edge[] = [];
  public Delete(): void {
  }
}
// tslint:disable-next-line:max-classes-per-file
export class Edge extends Cell {
  constructor(parent: mxCell | null, id: string, value: any, source: Cell, target: Cell, style: any) {
    super(parent, id, value, null, null, null, null, style);
    this.source = source;
    this.target = target;
    source.edges.push(this);
    target.edges.push(this);
  }
  public source: Cell;
  public target: Cell;
  public oned: boolean = true;
  public Delete(): void {
    this.source.edges.splice(this.source.edges.indexOf(this), 1);
    this.target.edges.splice(this.target.edges.indexOf(this), 1);
  }
}

// tslint:disable-next-line:max-classes-per-file
export class Model {
  public beginUpdate(): void { }
  public endUpdate(): void { }
}

export class Graph {
  public sem!: IVisioRDFS;
  private model = new Model();
  // tslint:disable-next-line:variable-name
  private _cells: { [id: string]: Cell } = {};
    // tslint:disable-next-line:variable-name
  private _updates: Cell[] = [];
  public getModel(): any { return this.model; }
  public refresh(): void { }
  public getChangeRequest(): Cell | undefined {
    return this._updates.shift();
  }
  constructor(sem: IVisioRDFS) {
    this.sem=sem;
  }
  public DeleteCell(c: Cell): void {
    delete this._cells[c.shapeid];
    c.Delete();
  }
  public getDefaultParent(): any {
    return null;
  }
  public insertVertex(_parent: mxCell | null, _id: string | null, _value: any, _x: number | null, _y: number | null, _width: number | null, _height: number | null, _style?: any, _relative?: boolean) {
  }
  public getChildCells(_parent: Cell | null, vertices: boolean, edges: boolean): Cell[] {
    const cl: Cell[] = [];
    for (const ci in this._cells) {
      const c = this._cells[ci];
      const isedge = c instanceof Edge;
      if ((isedge && edges) || (!isedge && vertices))
        cl.push(c);
    }
    return cl;
  }
  public labelChanged(_shp: mxCell, _value: string, _trigger: any) {
    // shp.label = value;
    // this.sem.visShapeExitedTextEdit(shp, value);
    // if (this.sem.noevents === false) {
    //   this._updates.push(shp);
    // }
  }
  public getLabel(shape: Cell): string {
    return shape.label;
  }
  public scrollCellToVisible(_shp: mxCell) {
  }
  public setSelectionCells(_shps: mxCell[]) {
    // this.sem.visSelectionChanged(shps[0]);
  }
  public getSelectionCells(): mxCell[] {
    return [];
  }
  public removeCells(shps: mxCell[], _includeEdges: boolean) {
    for (const s of shps) {
      delete this._cells[s.shapeid];
    }
    this.sem.visShapesDeleted(shps);
  }

  public insertEdge(_parent: mxCell | null, _id: string | null, _value: any, _source: mxCell, _target: mxCell, _style: any) {
    // if (id !== null && id.indexOf(".") > 0) {
    //   id = id.substr(id.indexOf(".") + 1);
    // }
    // const idi: number = Number(id);
    // if (idi >= this.maxcellid) {
    //   this.maxcellid = idi;
    // }
    // if (id === null || idi === 0) {
    //   this.maxcellid = this.maxcellid + 1;
    //   id = "mxSheet." + this.maxcellid.toString();
    // } else {
    //   id = "mxSheet." + id;
    // }
    // const e = new Edge(parent, id, value, source, target, style);
    // this._cells[e.shapeid] = e;
    // this.sem.visConnectionAdded(null, e, source, target);
    // return e;
    // // if (this._cells[e.shapeid] !== undefined) {
    // //   return e;
    // // } else {
    // //   return null;
    // // }
  }

  public isEditing(_shape: any): boolean {
    return false;
  }
  public stopEditing(_shape: any): boolean {
    return false;
  }
  public beginUpdate(): void { }
  public endUpdate(): void { }

  public setSelectionCell(_shp: mxCell) {
  }
  public startEditingAtCell(_shp: mxCell) {
  }

  public updateCellSize(_shp: mxCell, _ignoreChidren: boolean) {}
  public getPreferredSizeForCell(_shp: mxCell){}

  public container: any;
}
