import { SemTalkStyleAttribute } from "../application/semtalklistener/visiordfsinterface";

export const GENERAL_SHAPES: any = {
  Rectangle: {
    type: 'vertex',
    style: SemTalkStyleAttribute.rounded + '=0;whiteSpace=wrap;html=1;',
  },
  'Rounded Rectangle': {
    type: 'vertex',
    style: SemTalkStyleAttribute.rounded + '=1;whiteSpace=wrap;html=1;',
  },
  Text: {
    type: 'vertex',
    style: 'text;html=1;' + SemTalkStyleAttribute.strokeColor + '=none;' +
      SemTalkStyleAttribute.fillColor + '=none;' +
      SemTalkStyleAttribute.align + '=center;' +
      SemTalkStyleAttribute.verticalAlign + '=middle;whiteSpace=wrap;' +
      SemTalkStyleAttribute.rounded + '=0;',
  },
  Image: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=image;imageAspect=1;aspect=variable;' +
      SemTalkStyleAttribute.verticalLabelPosition + '=bottom;' +
      SemTalkStyleAttribute.verticalAlign + '=top;',
  },
  Eclipse: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=ellipse;whiteSpace=wrap;html=1;',
  },
  Square: {
    type: 'vertex',
    style: 'whiteSpace=wrap;html=1;aspect=fixed;',
  },
  Circle: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=ellipse;whiteSpace=wrap;html=1;aspect=fixed;',
  },
  Process: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=process;whiteSpace=wrap;html=1;backgroundOutline=1;',
  },
  Diamond: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=rhombus;whiteSpace=wrap;html=1;',
  },
  Parallelogram: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=parallelogram;perimeter=parallelogramPerimeter;whiteSpace=wrap;html=1;',
  },
  Triangle: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=triangle;whiteSpace=wrap;html=1;',
  },
  Cylinder: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=cylinder;whiteSpace=wrap;html=1;boundedLbl=1;backgroundOutline=1;',
  },
  Cloud: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=cloud;whiteSpace=wrap;html=1;',
  },
  Document: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=document;whiteSpace=wrap;html=1;boundedLbl=1;',
  },
  'Internal Storage': {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=internalStorage;whiteSpace=wrap;html=1;backgroundOutline=1;',
  },
  Cube: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=cube;whiteSpace=wrap;html=1;boundedLbl=1;backgroundOutline=1;',
  },
  Step: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=step;perimeter=stepPerimeter;whiteSpace=wrap;html=1;' +
      SemTalkStyleAttribute.fixedSize + '=1;',
  },
  Trapezoid: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=trapezoid;perimeter=trapezoidPerimeter;whiteSpace=wrap;html=1;',
  },
  Tape: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=tape;whiteSpace=wrap;html=1;',
  },
  Note: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=note;whiteSpace=wrap;html=1;backgroundOutline=1;',
  },
  Card: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=card;whiteSpace=wrap;html=1;',
  },
  Callout: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=callout;whiteSpace=wrap;html=1;perimeter=calloutPerimeter;',
  },
  Actor: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=umlActor;' +
      SemTalkStyleAttribute.verticalLabelPosition + '=bottom;labelBackgroundColor=#ffffff;' +
      SemTalkStyleAttribute.verticalAlign + '=top;html=1;outlineConnect=0;',
  },
  Hexagon: {
    type: 'vertex',
    style: SemTalkStyleAttribute.shape + '=hexagon;perimeter=hexagonPerimeter2;whiteSpace=wrap;html=1;',
  },
  Curve: {
    type: 'edge',
    style: SemTalkStyleAttribute.edgeStyle + '=elbowEdgeStyle;' +
      SemTalkStyleAttribute.curved + '=1;' +
      SemTalkStyleAttribute.endArrow + '=classic;html=1;',
  },
  'Bidirectional Arrow': {
    type: 'edge',
    style: SemTalkStyleAttribute.shape + '=flexArrow;' +
      SemTalkStyleAttribute.edgeStyle + '=loopEdgeStyle;' +
      SemTalkStyleAttribute.endArrow + '=classic;' +
      SemTalkStyleAttribute.startArrow + '=classic;html=1;' +
      SemTalkStyleAttribute.fillColor + '=#ffffff;',
  },
  Arrow: {
    type: 'edge',
    style: SemTalkStyleAttribute.shape + '=flexArrow;' +
      SemTalkStyleAttribute.edgeStyle + '=loopEdgeStyle;' +
      SemTalkStyleAttribute.endArrow + '=classic;html=1;' +
      SemTalkStyleAttribute.fillColor + '=#ffffff;',
  },
  Link: {
    type: 'edge',
    style: SemTalkStyleAttribute.shape + '=link;' +
      SemTalkStyleAttribute.edgeStyle + '=loopEdgeStyle;html=1;',
  },
  'Dashed Line': {
    type: 'edge',
    style: SemTalkStyleAttribute.edgeStyle + '=loopEdgeStyle;' +
      SemTalkStyleAttribute.endArrow + '=none;' +
      SemTalkStyleAttribute.dashed + '=1;html=1;',
  },
  Line: {
    type: 'edge',
    style: SemTalkStyleAttribute.edgeStyle + '=loopEdgeStyle;' +
      SemTalkStyleAttribute.endArrow + '=none;html=1;',
  },
  'Bidirectional Connector': {
    type: 'edge',
    style: SemTalkStyleAttribute.edgeStyle + '=loopEdgeStyle;' +
      SemTalkStyleAttribute.endArrow + '=classic;html=1;',
  },
  'Directional Connector': {
    type: 'edge',
    style: SemTalkStyleAttribute.edgeStyle + '=loopEdgeStyle;' +
      SemTalkStyleAttribute.endArrow + '=classic;html=1;',
  },
};
