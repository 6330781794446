
/* tslint:disable:noImplicitAny */
/* eslint:disable:noImplicitAny */

import {
  mxUtils,
  mxRectangle,
  mxCellRenderer,
  mxActor,
} from "mxgraph-js";// Document shape
import { SemTalkStyleAttribute } from "../visiordfsinterface";

function DocumentShape(this: any) {
  mxActor.call(this);
}
mxUtils.extend(DocumentShape, mxActor);
DocumentShape.prototype.size = 0.3;
DocumentShape.prototype.getLabelMargins = function (rect: any) {
  if (mxUtils.getValue(this.style, 'boundedLbl', false)) {
    return new mxRectangle(0, 0, 0, parseFloat(mxUtils.getValue(
      this.style, SemTalkStyleAttribute.size, this.size
    )) * rect.height);
  }

  return null;
};
DocumentShape.prototype.redrawPath = function (c: any, _x: any, _y: any, w: any, h: any) {
  console.debug("document");
  const dy = h * Math.max(0, Math.min(1, parseFloat(mxUtils.getValue(this.style, SemTalkStyleAttribute.size, this.size))));
  const fy = 1.4;

  c.moveTo(0, 0);
  c.lineTo(w, 0);
  c.lineTo(w, h - dy / 2);
  c.quadTo(w * 3 / 4, h - dy * fy, w / 2, h - dy / 2);
  c.quadTo(w / 4, h - dy * (1 - fy), 0, h - dy / 2);
  c.lineTo(0, dy / 2);
  c.close();
  c.end();
};
// console.debug("document registered");

mxCellRenderer.registerShape('document', DocumentShape);
