// import { StringContainer } from "docx";
import { MessageBarType } from "office-ui-fabric-react";
import { ISemTalkDiagram, SemTalkID } from "./application/tbase/Interface";
import { IHeaderFooterField } from "./controls/stheader/SemTalkHeader";

export enum zoomoption {
  zoomin = 0,
  zoomout = 1,
  zoomfit = 2,
  zoomactual = 3
}
// import { ISemTalkDiagram} from '../application/tbase/Interface';

export interface ITSEditor {
  graph: mxGraph;
  sidebar: any;
  pages: any;
  initSidebar(sidebarItems: any): any;
  configShapes(graph: mxGraph, stencil: any[]): void;
  initCustomPort(pic: any): any;
  zoom(type: zoomoption): void;
  center(): void;
  resetView(scale: number): any;
  printPreview(scale?: number, pageFormat?: any, border?: number,
    x0?: number, y0?: number, borderColor?: any, title?: any, pageSelector?: any): any;
  togglePageBreaks(): void;
  togglePanZoom(): void;
  toggleStencil(): void;
  toggleNavigator(): void;
  ShowPlanner(): void;
  HidePlanner(): void;
  // toggleSearch(): void;
  togglePages(): void;
  toggleDirection(shape: mxCell | null): void;
  swimlaneLayout(shape: mxCell | null): void;
  doFlow(edge: mxCell): void;
  updateStyle(cell: mxCell, key: string, value: string): void;
  groupCells(groupId: any, labelName: string): mxCell;
  ungroupCells(cells: mxCell[]): mxCell[];
  getGraphXml(): string;
  getSelectionCells(): mxCell[];
  createVertex(parent: mxCell | null, id: string | null, value: any, x: number, y: number,
    width: number, height: number, shapeStyle?: string): mxCell;
  insertEdge(v1: mxCell, v2: mxCell, style?: string): mxCell;
  bringToFront(cells: mxCell[]): void;
  sendToBack(cells: mxCell[]): void;
  getCellById(id: string): mxCell | undefined;
  getAllCells(): mxCell[];
  getAllTextCells(): mxCell[];
  getChildCells(parent: mxCell, vertices?: boolean, edges?: boolean): mxCell[];
  removeCells(cells: mxCell[], includeEdges: boolean): void;
  beginUpdate(): void;
  endUpdate(): void;
  removeEventListeners(): void;
  renameCell(newName: string, cell: mxCell): void;
  toggleCollapse(cell: mxCell): any;
  elbowCell(cell: mxCell): any;
  setStyle(style: any): void;
  insertWayPointToCell(cell: mxCell): any;
  insertWayPointToCellXY(cell: mxCell, nx: number, ny: number): any;
  // insertCenterWayPointToCell(cell: mxCell): any;
  removeWayPointsfromCell(cell: mxCell): any;
  refresh(): void;
  refreshCell(cell: mxCell): void;
  clearSelection(): any;
  startPanning(): any;
  stopPanning(): any;
  connectionPoints(): void;
  resetEditor(xml: string, diagcaption: string, diagid: SemTalkID, horizontal: boolean, bsetDirection: boolean): any;
  isGraphHorizontal(): boolean;
  isHorizontal(): boolean;
  // setHorizontal(isv: boolean): void;
  // updateDiagramData(data: any): any;
  enableDragDrop(): any;
  enableSwimlanes(ishorizontal: boolean, enforce: boolean): any;
  toggleDirection(shape: mxCell | null): any;
  // enableLayout();
  doLayout(node: any, layoutname: any, parent: any, animate: boolean, horizontal: boolean, invert: boolean): any;
  getShapeStyle(shapeName: string, shapeType: string): { style: string, isedge: boolean };
  align(direction: string, cells: mxCell[] | undefined): any;
  distribute(direction: boolean, cells: mxCell[] | undefined): mxCell[];
  exportSVG(graph: mxGraph): any;
  exportSVGforPrint(graph: mxGraph, printoverlays: boolean, printcontrols: boolean, printborder: number, printscale: number): any;
  // exportPDF(filename: string, images: any[]): void;
  // exportFile(format: string): any;
  clearCellOverlays(shape: any): any;
  addOverlay(shp: any, imgpath: any, valign: string, align: string, witdh: number, height: number, dx: number, dy: number,
    tooltip: string, cursor?: string | null, handler?: () => void): any;
  addURLOverlay(shp: any, imgpath: any, valign: string, align: string, witdh: number, height: number, dx: number, dy: number,
    tooltip: string, cursor: string | null, url: string): any;
  gotoPage(diagid: SemTalkID): any;
  updateDiaglist(diaglist: { text: string, key: SemTalkID, diag: ISemTalkDiagram }[]): any;
  alert(msg: string, mtype: MessageBarType): void;
  clearUndo(): void;
  isUndo(): boolean;
  isRedo(): boolean;
  Undo(): void;
  Redo(): void;
  preview(xml: any, div: string): mxGraph | undefined;
  renderNewGraphFromXml(xml: string, div: any): mxGraph | undefined;
  selectAll(): void;
  isEditing(cell?: mxCell | undefined): boolean;
  UpdatePage(graph: mxGraph | undefined): void;
  UpdatePortalStencil(stencil: any): void;
  addHeaderFooter(headerfooterdefinition: IHeaderFooterField[]): void;
  removeHeaderFooter(): void;
  setBundles(bundles: any): void;
  getBundeledImages(): any[];
  getImageFromBundles(img: string): any;
  removeBundles(bundles: any): void;
  setOverlayBundle(bundles: any): void;
  Copy(cells: mxCell[]): void;
  Cut(cells: mxCell[]): void;
  Paste(x: number, y: number): void;
  unSplit(vertex: mxCell, other2: mxCell, horizontal: boolean, invrel: boolean): void;
  suspendOutline(hide: boolean): void;
}
export interface LayoutSetting {
  layout: string;
  horizontal: boolean;
  invert: boolean;
  OrganicLayoutminDistanceLimit: number;
  OrganicLayoutmaxDistanceLimit: number;
  OrganicLayoutforceConstant: number;
  OrganicLayoutinitialTemp: number;
  OrganicLayoutmaxIterations: number;
  OrganicLayoutuseInputOrigin: boolean;
  Layoutradius: number;
  LayoutalignRanks: boolean;
  LayoutresetEdges: boolean;
  LayoutprefHozEdgeSep: number;
  LayoutprefVertEdgeOff: number;
  LayoutminEdgeJetty: number;
  LayoutchannelBuffer: number;
  LayoutmoveCircle: boolean;
  LayoutdisableEdgeStyle: boolean;
  LayouttraverseAncestors: boolean;
  LayoutedgeStyle: number;
  LayoutresizeParent: boolean;
  Layoutdeterministic: boolean;
  LayoutmaintainParentLocation: boolean;
  LayoutgroupPadding: number;
  LayoutmoveTree: boolean;
  LayoutmoveParent: boolean;
  LayoutfineTuning: boolean;
  LayouttightenToSource: boolean;
  LayoutparentBorder: number;
  LayoutlevelDistance: number;
  LayoutnodeDistance: number;
  LayoutedgeRouting: boolean;
  LayoutautoRadius: boolean;
  LayoutsortEdges: boolean;
  Layoutorientation: string;
  LayoutintraCellSpacing: number;
  LayoutinterRankCellSpacing: number;
  LayoutinterHierarchySpacing: number;
  LayoutparallelEdgeSpacing: number;
}
