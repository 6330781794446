import React, { Suspense } from "react";
import { ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, Panel, PanelType, PrimaryButton, ResponsiveMode, themeRulesStandardCreator } from "office-ui-fabric-react";
import { ISemTalkOnlineState } from "./ISemTalkOnlineState";
import { ISharePointGraphSettings, ISharePointSettings, IVisioRDFS, ResID, ResIDL } from "./application/semtalklistener/visiordfsinterface";
import { ISemTalkOnline, SemTalkRole } from "./ISemTalkOnline";
import { IMongoOption } from "./SemTalkMongoServer";
import { IObjectBase, ISemTalkAssociation, ISemTalkClass, ISemTalkDiagramType, ISemTalkObject, ISemTalkSystemClass, SemTalkLanguageCode } from "./application/tbase/Interface";

const SemTalkPivot = React.lazy(
  () =>
    import(/* webpackChunkName: "pivot" */ "./controls/stpivot/SemTalkPivot")
);
const SemTalkAbout = React.lazy(
  () =>
    import(/* webpackChunkName: "about" */ "./controls/stabout/SemTalkAbout")
);
const SemTalkShapeStyle = React.lazy(
  () =>
    import(
      /* webpackChunkName: "shape" */
      "./controls/stshapestyle/SemTalkShapeStyle"
    )
);
const SemTalkTable = React.lazy(
  () =>
    import(/* webpackChunkName: "search" */ "./controls/sttable/SemTalkTable")
);
const SemTalkFacetSearch = React.lazy(
  () =>
    import(
      /* webpackChunkName: "facetsearch" */
      "./controls/stfacetsearch/SemTalkFacetSearch"
    )
);
const SemTalkQuickEdit = React.lazy(
  () =>
    import(
      /* webpackChunkName: "quickedit" */
      "./controls/stquickedit/SemTalkQuickEdit"
    )
);
const SemTalkModelProperty = React.lazy(
  () =>
    import(
      /* webpackChunkName: "model" */
      "./controls/stmodelproperty/SemTalkModelProperty"
    )
);
const SemTalkDiagramTab = React.lazy(
  () =>
    import(
      /* webpackChunkName: "diagram" */
      "./controls/stdiagram/SemTalkDiagram"
    )
);
const SemTalkNavigation = React.lazy(
  () =>
    import(
      /* webpackChunkName: "navigation" */
      "./controls/stnavigation/SemTalkNavigation"
    )
);
const SemTalkObjects = React.lazy(
  () =>
    import(
      /* webpackChunkName: "objects" */
      "./controls/stobject/SemTalkObjects"
    )
);
const SemTalkAssociationTypes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "assoctypes" */
      "./controls/stassociationtypes/SemTalkAssociationTypes"
    )
);
const SemTalkAttributeTypes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "attrtypes" */
      "./controls/stattributetypes/SemTalkAttributeTypes"
    )
);
const SemTalkMethodTypes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "methods" */
      "./controls/stmethodtypes/SemTalkMethodTypes"
    )
);
const SemTalkStateTypes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "states" */
      "./controls/ststatetypes/SemTalkStateTypes"
    )
);
const SemTalkDiagramTypes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "diagramtypes" */
      "./controls/stdiagramtypes/SemTalkDiagramTypes"
    )
);
const SemTalkTableEditor = React.lazy(
  () =>
    import(
      /* webpackChunkName: "tableeditor" */
      "./controls/sttableeditor/SemTalkTableEditor"
    )
);
const SemTalkTableImport = React.lazy(
  () =>
    import(
      /* webpackChunkName: "tableimport" */
      "./controls/sttableimport/SemTalkTableImport"
    )
);
const SemTalkSemantics = React.lazy(
  () =>
    import(
      /* webpackChunkName: "similarity" */
      "./controls/stsemantics/SemTalkSemantics"
    )
);
const SemTalkAttachmentTab = React.lazy(
  () =>
    import(
      /* webpackChunkName: "attachment" */
      "./controls/stattachments/SemTalkAttachments"
    )
);
const SemTalkOpen = React.lazy(
  () =>
    import(/* webpackChunkName: "open" */ "./controls/stfileopen/SemTalkOpen")
);
const SemTalkVersionControl = React.lazy(
  () =>
    import(
      /* webpackChunkName: "version" */
      "./controls/stversioncontrol/SemTalkVersionControl"
    )
);
const SemTalkOneDrive = React.lazy(
  () =>
    import(
      /* webpackChunkName: "onedrive" */
      "./controls/stonedrive/SemTalkOneDrive"
    )
);
const SemTalkCustomize = React.lazy(
  () =>
    import(
      /* webpackChunkName: "customize" */
      "./controls/stcustomize/SemTalkCustomize"
    )
);
const SemTalkSelect = React.lazy(
  () =>
    import(
      /* webpackChunkName: "select" */
      "./controls/stselect/SemTalkSelect"
    )
);
const SemTalkTermSet = React.lazy(
  () =>
    import(
      /* webpackChunkName: "termset" */
      "./controls/sttermset/SemTalkTermSet"
    )
);
const SemTalkSiteBuilder = React.lazy(
  () =>
    import(
      /* webpackChunkName: "sitebuilder" */
      "./controls/stsitebuilder/SemTalkSiteBuilder"
    )
);
const SemTalkExpand = React.lazy(
  () =>
    import(/* webpackChunkName: "expand" */ "./controls/stexpand/SemTalkExpand")
);
const SemTalkCreateDiagram = React.lazy(
  () =>
    import(
      /* webpackChunkName: "creatediagram" */
      "./controls/stdiagram_new/SemTalkCreateDiagram"
    )
);
const SemTalkInsert = React.lazy(
  () =>
    import(/* webpackChunkName: "insert" */ "./controls/stinsert/SemTalkInsert")
);
const SemTalkCompose = React.lazy(
  () =>
    import(
      /* webpackChunkName: "compose" */
      "./controls/stcompose/SemTalkCompose"
    )
);
const SemTalkTranslate = React.lazy(
  () =>
    import(
      /* webpackChunkName: "translate" */
      "./controls/sttranslate/SemTalkTranslate"
    )
);
const SemTalkTasks = React.lazy(
  () =>
    import(/* webpackChunkName: "tasks" */ "./controls/sttasks/SemTalkTasks")
);
const SemTalkPlanner = React.lazy(
  () =>
    import(
      /* webpackChunkName: "planner" */
      "./controls/stplanner/SemTalkPlanner"
    )
);
const SemTalkForm = React.lazy(
  () => import(/* webpackChunkName: "form" */ "./controls/stform/SemTalkForm")
);
const SemTalkUsers = React.lazy(
  () =>
    import(/* webpackChunkName: "users" */ "./controls/stusers/SemTalkUsers")
);
const UserProfile = React.lazy(
  () =>
    import(
      /* webpackChunkName: "userprofile" */
      "./controls/stlogin/UserProfile"
    )
);
const SemTalkCheckRepair = React.lazy(
  () =>
    import(
      /* webpackChunkName: "checkrepair" */
      "./controls/stcheckrepair/SemTalkCheckRepair"
    )
);
const SemTalkSimulation = React.lazy(
  () =>
    import(
      /* webpackChunkName: "simulation" */
      "./controls/stsimulation/SemTalkSimulation"
    )
);
const SemTalkShare = React.lazy(
  () =>
    import(/* webpackChunkName: "share" */ "./controls/stshare/SemTalkShare")
);
const SemTalkPrintOptions = React.lazy(
  () =>
    import(
      /* webpackChunkName: "printoptions" */
      "./controls/stprintoptions/SemTalkPrintOptions"
    )
);
const Chatgpt = React.lazy(
  () => import(/* webpackChunkName: "chatgpt" */ "./controls/stchatgpt/Chatgpt")
);
const SemTalkHeaderFooterTab = React.lazy(
  () => import(/* webpackChunkName: "headerfooter" */ "./controls/stheader/SemTalkHeader")
);
const SemTalkImagesTab = React.lazy(
  () => import(/* webpackChunkName: "images" */ "./controls/stimages/SemTalkImages")
);


const SemTalkReports = React.lazy(
  () =>
    import(
      /* webpackChunkName: "reports" */
      "./controls/streports/SemTalkReports"
    )
);
const SemTalkPrompts = React.lazy(
  () =>
    import(
      /* webpackChunkName: "prompts" */
      "./controls/stprompts/SemTalkPrompts"
    )
);
const SemTalkStorage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "storage" */
      "./controls/ststorage/SemTalkStorage"
    )
);
const SemTalkStencilTab = React.lazy(
  () =>
    import(
      /* webpackChunkName: "stencil" */
      "./controls/ststencil/SemTalkStencilTab"
    )
);
const SemTalkRibbon = React.lazy(
  () =>
    import(
      /* webpackChunkName: "printoptions" */
      "./controls/stribbon/SemTalkRibbon"
    )
);
const SemTalkRename = React.lazy(
  () =>
    import(
      /* webpackChunkName: "rename" */
      "./controls/strename/SemTalkRename"
    )
);
const SemTalkImport = React.lazy(
  () =>
    import(/* webpackChunkName: "import" */ "./controls/stimport/SemTalkImport")
);
const SemTalkCharting = React.lazy(
  () =>
    import(/* webpackChunkName: "charting" */ "./controls/stcharting/SemTalkCharting")
);

const SemTalkImpressum = React.lazy(
  () =>
    import(/* webpackChunkName: "impressum" */ "./controls/stimpressum/SemTalkImpressum")
);

const SemTalkPrivacy = React.lazy(
  () =>
    import(/* webpackChunkName: "privacy" */ "./controls/stprivacy/SemTalkPrivacy")
);
const SemTalkLayout = React.lazy(
  () =>
    import(/* webpackChunkName: "layout" */ "./controls/stlayout/SemTalkLayout")
);
const SemTalkKeyManager = React.lazy(
  () =>
    import(/* webpackChunkName: "keyManager" */ "./controls/stkeymanager/SemTalkKeyManager")
);
const SemTalkExportManager = React.lazy(
  () =>
    import(/* webpackChunkName: "keyManager" */ "./controls/stexportmanager/SemTalkExportManager")
);


export function renderSearch(callback: ISemTalkOnline, semtalk: IVisioRDFS, state: ISemTalkOnlineState,
  onclose: (state: any) => void, suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showSearch}
      type={state.panelTypeSearch}
      customWidth={state.editdialogwidth}
      isBlocking={false}
      onDismiss={() => onclose({ showSearch: false })}
      headerText={semtalk.getResStrListener(ResIDL.STRDLHTMLSEARCH)}
      closeButtonAriaLabel={semtalk.getResStrListener(
        "STRDLGCMDCL"
      )}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkTable semtalk={semtalk}
          title={semtalk.getResStrListener(ResIDL.STRDLHTMLSEARCH)}
          callback={callback}
          ondismiss={() => onclose({ showSearch: false })}
        />
      </Suspense>
    </Panel>
  );
}
export function renderFacetSearch(semtalk: IVisioRDFS, mongo: IMongoOption, state: ISemTalkOnlineState,
  onclose: (state: any) => void, suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showFacetSearch}
      type={PanelType.extraLarge}
      isBlocking={false}
      onDismiss={() => onclose({ showFacetSearch: false })}
      headerText={semtalk.getResStrListener(ResIDL.STRDLHTMLSEARCH)}
      closeButtonAriaLabel={semtalk.getResStrListener(
        "STRDLGCMDCL"
      )}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkFacetSearch mongo={mongo} />
      </Suspense>
    </Panel>
  );
}
export function renderQuickEdit(callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  context: any,
  graph: ISharePointGraphSettings,
  sharepoint: ISharePointSettings,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void, suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showQuickEdit}
      type={state.panelQuickEdit}
      isBlocking={false}
      onDismiss={() => onclose({ showQuickEdit: false })}
      headerText={"Quick Edit"}
      closeButtonAriaLabel={semtalk.getResStrListener(
        "STRDLGCMDCL"
      )}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkQuickEdit
          semtalk={semtalk}
          callback={callback}
          mongo={mongo}
          context={context}
          usegraph={graph.usegraph}
          graphClient={graph.graphClient}
          sharepoint={sharepoint}
        ></SemTalkQuickEdit>
      </Suspense>
    </Panel>
  );
}
export function renderModelProperties(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  context: any,
  graph: ISharePointGraphSettings,
  sharepoint: ISharePointSettings,
  state: ISemTalkOnlineState,
  role: SemTalkRole,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showModelProperties}
      type={PanelType.medium}
      onDismiss={() =>
        onclose({ showModelProperties: false })
      }
      // headerText={"Model Properties"}
      closeButtonAriaLabel={semtalk.getResStrListener(
        "STRDLGCMDCL"
      )}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkModelProperty
          title={semtalk.getResStr(ResID.STRDOCPROPERTIES)}
          callback={callback}
          semtalk={semtalk}
          context={context}
          site={sharepoint.site}
          mongo={mongo}
          library={sharepoint.library}
          islocked={state.islocked}
          docname={semtalk.base.ObjectName}
          usegraph={graph.usegraph}
          sharepointlibrarysite={graph.sharepointlibrarysite}
          sharepointlibrary={graph.sharepointlibrary}
          sharepointdocumentcolumns={state.sharepointdocumentcolumns}
          graphClient={graph.graphClient}
          role={role}
        //  filter={this.props.filter} token={this.props.token} service={this.props.service}
        />
      </Suspense>
    </Panel>
  );
}
export function renderDiagram(semtalk: IVisioRDFS, state: ISemTalkOnlineState,
  onclose: (state: any) => void, suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showDiag}
      type={PanelType.medium}
      onDismiss={() => onclose({ showDiag: false })}
      headerText={semtalk.getResStrListener(ResIDL.STRDLHTMLOUTDIA)}
      closeButtonAriaLabel={semtalk.getResStrListener(ResIDL.STRDLGCMDCL)}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkDiagramTab
          semtalk={semtalk}
          islocked={state.islocked}
        />
      </Suspense>
    </Panel>
  );
}
export function renderShapeStyle(callback: ISemTalkOnline,
  semtalk: IVisioRDFS, state: ISemTalkOnlineState,
  onclose: (state: any) => void, suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideShapeStyle}
    onDismiss={() => {
      onclose({ hideShapeStyle: true });
    }}
    customWidth={state.editdialogwidth}
    isBlocking={false}
    // headerText={semtalk.getResStr(ResID.STRSTYLE)}
    type={state.panelTypeShapeStyle}
  >
    {semtalk && (
      <Suspense fallback={suspfallback}>
        <SemTalkShapeStyle
          callback={callback}
          semtalk={semtalk}
          isadmin={state.isadmin}
          ismetamodel={state.ismetamodel}
          paneltype={state.panelTypeShapeStyle}
        />
      </Suspense>
    )}
  </Panel>
  );
}
export function renderHelp(semtalk: IVisioRDFS, state: ISemTalkOnlineState,
  onclose: (state: any) => void, suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideHelp}
    onDismiss={() => {
      onclose({ hideHelp: true });
    }}
    isBlocking={false}
    headerText={semtalk.getResStr(ResID.STRHELP).replace("&", "")}
    type={PanelType.smallFixedFar}
  >
    {semtalk && (
      <Suspense fallback={suspfallback}>
        <SemTalkAbout />
      </Suspense>
    )}
  </Panel>
  );
}
export function renderNavigation(callback: ISemTalkOnline, semtalk: IVisioRDFS, state: ISemTalkOnlineState,
  onclose: (state: any) => void, suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showNav}
      type={state.panelTypeNav}
      customWidth={state.editdialogwidth}
      isBlocking={false}
      onDismiss={() => onclose({ showNav: false })}
      closeButtonAriaLabel={semtalk.getResStrListener(ResIDL.STRDLGCMDCL)}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkNavigation
          title={semtalk.getResStrListener(ResIDL.STRDLHTMLDIAGR)}
          semtalk={semtalk}
          callback={callback}
          islocked={state.islocked}
          role={state.role}
        />
      </Suspense>
    </Panel>
  );
}
export function renderHierarchy(callback: ISemTalkOnline, semtalk: IVisioRDFS, state: ISemTalkOnlineState,
  onclose: (state: any) => void, suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showHie}
      type={state.panelTypeHie}
      isBlocking={false}
      onDismiss={() => onclose({ showHie: false })}
      customWidth={state.editdialogwidth}
      closeButtonAriaLabel={semtalk.getResStrListener(
        "STRDLGCMDCL"
      )}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkObjects
          title={semtalk.getResStr(ResID.STROBJECTS)}
          semtalk={semtalk}
          callback={callback}
          islocked={state.islocked}
          isadmin={state.isadmin}
          ismetamodel={state.ismetamodel}
        />
      </Suspense>
    </Panel>
  );
}
export function renderAssocTypes(callback: ISemTalkOnline, semtalk: IVisioRDFS, state: ISemTalkOnlineState,
  onclose: (state: any) => void, suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showAssocTypes}
      type={state.panelTypeAss}
      customWidth={state.editdialogwidth}
      isBlocking={false}
      onDismiss={() => onclose({ showAssocTypes: false })}
      closeButtonAriaLabel={semtalk.getResStrListener(ResIDL.STRDLGCMDCL)}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkAssociationTypes
          title={semtalk.getResStrListener(ResIDL.STRDLGTABASS)}
          semtalk={semtalk}
          callback={callback}
        />
      </Suspense>
    </Panel>
  );
}
export function renderAttrTypes(callback: ISemTalkOnline, semtalk: IVisioRDFS, state: ISemTalkOnlineState,
  onclose: (state: any) => void, suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showAttrTypes}
      type={state.panelTypeAtt}
      customWidth={state.editdialogwidth}
      isBlocking={false}
      onDismiss={() => onclose({ showAttrTypes: false })}
      closeButtonAriaLabel={semtalk.getResStrListener(ResIDL.STRDLGCMDCL)}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkAttributeTypes
          title={semtalk.getResStrListener(ResIDL.STRDLGTABATT)}
          semtalk={semtalk}
          callback={callback}
        />
      </Suspense>
    </Panel>
  );
}
export function renderMethodTypes(callback: ISemTalkOnline, semtalk: IVisioRDFS, state: ISemTalkOnlineState,
  onclose: (state: any) => void, suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showMethodTypes}
      type={PanelType.smallFixedFar}
      isBlocking={false}
      onDismiss={() => onclose({ showMethodTypes: false })}
      closeButtonAriaLabel={semtalk.getResStrListener(ResIDL.STRDLGCMDCL)}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkMethodTypes
          title={semtalk.getResStrListener(ResIDL.STRDLGTABMET)}
          semtalk={semtalk}
          callback={callback}
        />
      </Suspense>
    </Panel>
  );
}
export function renderStateTypes(callback: ISemTalkOnline, semtalk: IVisioRDFS, state: ISemTalkOnlineState,
  onclose: (state: any) => void, suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showStateTypes}
      type={PanelType.smallFixedFar}
      isBlocking={false}
      onDismiss={() => onclose({ showStateTypes: false })}
      closeButtonAriaLabel={semtalk.getResStrListener(ResIDL.STRDLGCMDCL)}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkStateTypes
          title={semtalk.getResStrListener(ResIDL.STRDLGTABSTA)}
          semtalk={semtalk}
          callback={callback}
        />
      </Suspense>
    </Panel>
  );
}
export function renderDiagTypes(callback: ISemTalkOnline, semtalk: IVisioRDFS, state: ISemTalkOnlineState,
  onclose: (state: any) => void, suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showDiagTypes}
      type={PanelType.smallFixedFar}
      isBlocking={false}
      onDismiss={() => onclose({ showDiagTypes: false })}
      closeButtonAriaLabel={semtalk.getResStrListener(ResIDL.STRDLGCMDCL)}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkDiagramTypes
          title={semtalk.getResStrListener(ResIDL.STRDIAGTYPE)}
          semtalk={semtalk}
          callback={callback}
        />
      </Suspense>
    </Panel>
  );
}
export function renderReport(semtalk: IVisioRDFS, state: ISemTalkOnlineState,
  onclose: (state: any) => void, _suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showRep}
      type={PanelType.medium}
      isBlocking={false}
      onDismiss={() => onclose({ showRep: false })}
      headerText={semtalk.getResStrListener(ResIDL.STRREPORT)}
      closeButtonAriaLabel={semtalk.getResStrListener(ResIDL.STRDLGCMDCL)}
    ></Panel>
  );
}
export function renderTableEditor(callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption, state: ISemTalkOnlineState,
  context: any,
  graph: ISharePointGraphSettings,
  role: SemTalkRole,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showTableEditor}
      type={state.panelTypeTableEditor}
      customWidth={state.editdialogwidth}
      isBlocking={false}
      onDismiss={() => {
        onclose({ showTableEditor: false });
      }}
      headerText={semtalk.getResStr(ResID.STRTABLE)}
      closeButtonAriaLabel={semtalk.getResStrListener(
        "STRDLGCMDCL"
      )}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkTableEditor
          semtalk={semtalk}
          callback={callback}
          mongo={mongo}
          context={context}
          usegraph={graph.usegraph}
          graphClient={graph.graphClient}
          showSimulation={state.showSimulation}
          showBPMN={state.showBPMN}
          role={role}
        />
      </Suspense>
    </Panel>
  );
}

// Nicht im Ribbon
export function renderTableImport(semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  context: any,
  graphClient: any,
  sharepoint: ISharePointSettings,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showTableImport}
      type={PanelType.large}
      onDismiss={() => onclose({ showTableImport: false })}
      headerText={"Table Import"}
      closeButtonAriaLabel={semtalk.getResStrListener(
        "STRDLGCMDCL"
      )}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkTableImport
          context={context}
          semtalk={semtalk}
          graphClient={graphClient}
          library={sharepoint.library}
          templates={sharepoint.templates}
          mongo={mongo}
        />
      </Suspense>
    </Panel>
  );
}
export function renderSemantics(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  context: any,
  graph: ISharePointGraphSettings,
  sharepoint: ISharePointSettings,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={state.showSemantics}
      type={state.panelTypeNLP}
      isBlocking={false}
      onDismiss={() => onclose({ showSemantics: false })}
      // headerText={"Semantic Tools"}
      customWidth={"600px"}
      closeButtonAriaLabel={semtalk.getResStrListener(
        "STRDLGCMDCL"
      )}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkSemantics
          semtalk={semtalk}
          callback={callback}
          mongo={mongo}
          islocked={state.islocked}
          semantics={state.semantics}
          usegraph={graph.usegraph}
          graphClient={graph.graphClient}
          context={context}
          sharepoint={sharepoint}
        />
      </Suspense>
    </Panel>
  );
}
export function renderHyperlink(semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  sharepoint: ISharePointSettings,
  graph: ISharePointGraphSettings,
  context: any,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={!state.hideHyperlinkDialog}
      onDismiss={() => onclose({ hideHyperlinkDialog: true })}
      headerText={semtalk.getResStr(ResID.STRHYPERLINKS)}
      isBlocking={false}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkAttachmentTab
          semtalk={semtalk}
          newitem={false}
          islocked={state.islocked}
          mongo={mongo}
          sharepoint={sharepoint}
          usegraph={graph.usegraph}
          sharepointlibrarysite={graph.sharepointlibrarysite}
          sharepointlibrary={graph.sharepointlibrary}
          graphClient={graph.graphClient}
          context={context}
        ></SemTalkAttachmentTab>
      </Suspense>
    </Panel>
  );
}
export function renderOpenLink(semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void) {
  return (
    <Panel
      isOpen={!state.hideOpenLinkDialog}
      onDismiss={() =>
        onclose({ hideOpenLinkDialog: true })
      }
      isBlocking={false}
      type={PanelType.smallFixedFar}
      headerText={semtalk.getResStr(ResID.STRMNUIMAGE)}
    >
      <div>
        <a href={state.openlinkurl} target="_blank" rel="noreferrer">
          {state.openlinkcaption}
        </a>
      </div>
    </Panel>
  );
}
export function renderFileOpen(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  context: any,
  graph: ISharePointGraphSettings,
  sharepoint: ISharePointSettings,
  filename: string,
  guilanguage: SemTalkLanguageCode,
  footer: () => JSX.Element,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={!state.hideFileOpen}
      onDismiss={() => onclose({ hideFileOpen: true })}
      isBlocking={true}
      type={PanelType.medium}
      headerText={
        semtalk.getResStr(ResID.STRDLGADDEXTOB).replace(":", "") +
        state.selectedFilename +
        ": " +
        state.spexplorercaption
      }
      onRenderFooterContent={
        // this.onRenderSemTalkOpenFooterContent
        footer
      }
    >
      <Suspense fallback={suspfallback}>
        <SemTalkOpen
          context={context}
          semtalk={semtalk}
          callback={callback}
          opencallback={callback}
          sharepoint={sharepoint}
          isopen={state.isopen}
          isnew={state.isnew}
          issaveas={state.issaveas}
          isimport={state.isimport}
          ismerge={state.ismerge}
          ispublish={false}
          isdelete={state.isdelete}
          title={state.spexplorercaption}
          filename={filename}
          template={state.template}
          // templist={this.checkouttoken_templist}
          spCheckInOut={state.spCheckInOut}
          mongo={mongo}
          usegraph={graph.usegraph}
          sharepointlibrarysite={graph.sharepointlibrarysite}
          sharepointlibrary={graph.sharepointlibrary}
          graphClient={graph.graphClient}
          isadmin={state.isadmin}
          language={state.language}
          guilanguage={guilanguage}
          ismetamodel={state.ismetamodel}
          // sharepointlibraryfixed={state.sharepointlibraryfixed}
          sharepointdocumentcolumns={state.sharepointdocumentcolumns}
        />
      </Suspense>
    </Panel>
  );
}
export function renderVersionControl(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  context: any,
  graph: ISharePointGraphSettings,
  sharepoint: ISharePointSettings,
  filename: string,
  guilanguage: SemTalkLanguageCode,
  footer: () => JSX.Element,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={!state.hideVersionControl}
      onDismiss={() => onclose({ hideVersionControl: true })}
      isBlocking={true}
      type={state.panelTypeVer}
      headerText={
        semtalk.getResStrListener(ResIDL.STRVERSIONCONTROL) +
        ": " +
        filename
      }
      onRenderFooterContent={
        footer
        // this.onRenderSemTalkVersionControlFooterContent
      }
    >
      <Suspense fallback={suspfallback}>
        <SemTalkVersionControl
          context={context}
          semtalk={semtalk}
          callback={callback}
          opencallback={callback}
          title={state.spexplorercaption}
          filename={filename}
          mongo={mongo}
          graph={graph}
          // usegraph={graph.usegraph}
          // graphClient={graph.graphClient}
          isadmin={state.isadmin}
          language={state.language}
          guilanguage={guilanguage}
          spSite={sharepoint.site}
        />
      </Suspense>
    </Panel>
  );
}
export function renderOneDrive(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  context: any,
  graphClient: any,
  // sharepoint: ISharePointSettings,
  // filename: string,
  guilanguage: SemTalkLanguageCode,
  // footer: ()=> JSX.Element,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={!state.hideFileOneDrive}
      onDismiss={() => onclose({ hideFileOneDrive: true })}
      isBlocking={true}
      type={PanelType.medium}
      headerText={
        semtalk.getResStr(ResID.STRDLGADDEXTOB).replace(":", "") +
        " " +
        state.selectedFilename +
        ": " +
        state.spexplorercaption
      }
    //onRenderFooterContent={this.onRenderSemTalkOpenFooterContent}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkOneDrive
          context={context}
          semtalk={semtalk}
          callback={callback}
          file={state.selectedFileContent}
          title={state.spexplorercaption}
          filename={state.selectedFilename}
          mongo={mongo}
          graphClient={graphClient}
          guilanguage={guilanguage}
          azureAD={state.azureAD}
          issave={state.oneDriveSave}
        />
      </Suspense>
    </Panel>
  );
}
export function renderPublish(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  context: any,
  graph: ISharePointGraphSettings,
  sharepoint: ISharePointSettings,
  filename: string,
  guilanguage: SemTalkLanguageCode,
  footer: () => JSX.Element,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={!state.hidePublishDialog}
      onDismiss={() => onclose({ hidePublishDialog: true })}
      isBlocking={true}
      type={PanelType.medium}
      headerText={semtalk
        .getResStr(ResID.STRPUBLISH)
        .replace(":", "")}
      onRenderFooterContent={
        footer
      }
    >
      <Suspense fallback={suspfallback}>
        <SemTalkOpen
          context={context}
          semtalk={semtalk}
          callback={callback}
          opencallback={callback}
          sharepoint={sharepoint}
          isopen={false}
          isnew={false}
          issaveas={false}
          isimport={false}
          ismerge={false}
          ispublish={true}
          isdelete={false}
          title={state.spexplorercaption}
          filename={filename}
          template={state.template}
          // templist={this.checkouttoken_templist}
          spCheckInOut={state.spCheckInOut}
          mongo={mongo}
          usegraph={graph.usegraph}
          sharepointlibrary={graph.sharepointlibrary}
          sharepointlibrarysite={graph.sharepointlibrarysite}
          graphClient={graph.graphClient}
          isadmin={state.isadmin}
          language={state.language}
          guilanguage={guilanguage}
          ismetamodel={state.ismetamodel}
          // sharepointlibraryfixed={state.sharepointlibraryfixed}
          sharepointdocumentcolumns={state.sharepointdocumentcolumns}
        />
      </Suspense>
    </Panel>
  );
}
export function renderPivot(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  context: any,
  graph: ISharePointGraphSettings,
  sharepoint: ISharePointSettings,
  // sharepointlibrary: string,
  // sharepointlibrarysite: string,
  sharepointdocumentcolumns: string,
  role: SemTalkRole,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  if (!state.useDialogs) {
    return (<Panel
      isOpen={!state.hidePropertyDialog}
      onDismiss={() => {
        onclose({ hidePropertyDialog: true });
      }}
      isBlocking={false}
      type={state.panelTypePivot}
      customWidth={state.editdialogwidth}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkPivot
          context={context}
          callback={callback}
          semtalk={semtalk}
          usegraph={graph.usegraph}
          role={role}
          graphClient={graph.graphClient}
          sharepoint={sharepoint}
          sharepointlibrary={graph.sharepointlibrary}
          sharepointlibrarysite={graph.sharepointlibrarysite}
          sharepointdocumentcolumns={sharepointdocumentcolumns}
          navigateproperties={true}
          showGeneral={true}
          showAttributes={true}
          showExpressions={false}
          showAssocs={true}
          showSynonyms={true}
          showMethods={true}
          showStates={true}
          showInheritance={true}
          showInstances={true}
          showAudit={true}
          showdiagproperties={false}
          showAssociationType={true}
          showAttributeType={true}
          showDiagramType={true}
          showislist={true}
          showSimulation={state.showSimulation}
          showBPMN={state.showBPMN}
          stencil={state.stencil}
          spCheckInOut={state.spCheckInOut}
          mongo={mongo}
          islocked={state.islocked}
          isanchored={false}
          paneltype={state.panelTypePivot}
        />
      </Suspense>
    </Panel>);
  } else {
    return (
      <Dialog
        isOpen={!state.hidePropertyDialog}
        onDismiss={() => {
          onclose({
            hidePropertyDialog: themeRulesStandardCreator,
          });
        }}
        dialogContentProps={{
          type: DialogType.close,
          title: semtalk.getResStrListener(ResIDL.STRDLHTMLPROPS),
        }}
        minWidth={500}
        responsiveMode={ResponsiveMode.large}
        modalProps={{
          isBlocking: false,
          dragOptions: {
            moveMenuItemText: "Move",
            closeMenuItemText: "Close",
            menu: ContextualMenu,
            keepInBounds: true,
          },
          isModeless: false,
          styles: { main: { width: 650, minHeight: 650 } },
        }}
      >
        <Suspense fallback={suspfallback}>
          <SemTalkPivot
            context={context}
            callback={callback}
            semtalk={semtalk}
            usegraph={graph.usegraph}
            role={role}
            graphClient={graph.graphClient}
            sharepoint={sharepoint}
            sharepointlibrary={graph.sharepointlibrary}
            sharepointlibrarysite={graph.sharepointlibrarysite}
            sharepointdocumentcolumns={sharepointdocumentcolumns}
            navigateproperties={true}
            showGeneral={true}
            showAttributes={true}
            showExpressions={false}
            showAssocs={true}
            showSynonyms={true}
            showMethods={true}
            showStates={true}
            showInheritance={true}
            showInstances={true}
            showAudit={true}
            showdiagproperties={false}
            showAssociationType={true}
            showAttributeType={true}
            showDiagramType={true}
            showislist={true}
            showSimulation={state.showSimulation}
            showBPMN={state.showBPMN}
            stencil={state.stencil}
            spCheckInOut={state.spCheckInOut}
            mongo={mongo}
            islocked={state.islocked}
            isanchored={true}
            paneltype={state.panelTypePivot}
          />
        </Suspense>
      </Dialog>
    );
  }
}
export function renderCustomize(
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideCustomizeDialog}
    onDismiss={() => {
      onclose({ hideCustomizeDialog: true });
    }}
    isBlocking={false}
    type={PanelType.medium}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkCustomize
        semtalk={semtalk}
        showSystemClass={true}
        showAssociationType={true}
        showAttributeType={true}
        showDiagramType={true}
      />
    </Suspense>
  </Panel>
  );
}
const SemTalkDocuments = React.lazy(
  () =>
    import(/* webpackChunkName: "share" */ "./controls/stdocuments/SemTalkDocuments")
);

export function renderSelect(
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  context: any,
  // graphClient: any,
  sharepoint: ISharePointSettings,
  graph: ISharePointGraphSettings,
  isclasses: boolean,
  callbackfn: (
    _semtalk: IVisioRDFS,
    shape: any,
    newobj: ISemTalkObject[],
    _expand: boolean
  ) => void,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  if (!state.useDialogs) {
    return (
      <Panel
        isOpen={!state.hideSelectDialog}
        onDismiss={() => {
          onclose({ hideSelectDialog: true });
        }}
        isBlocking={true}
        type={PanelType.smallFixedFar}
      >
        <Suspense fallback={suspfallback}>
          <SemTalkSelect
            context={context}
            title={semtalk.getResStrListener(ResIDL.STRSELECTSL)}
            semtalk={semtalk}
            usegraph={graph.usegraph}
            graphClient={graph.graphClient}
            callback={callbackfn}
            // site={this.sprops.site}
            sharepoint={sharepoint}
            isexport={state.isexport}
            systemclass={null}
            defaulttext={null}
            isclasses={isclasses}
            focus={state.object}
            mongo={mongo}
            sharepointrepository={state.sharepointrepository}
            ondismiss={() => {
              onclose({ hideSelectDialog: true });
            }}

          />
        </Suspense>
      </Panel>
    );
  } else {
    return (
      <Dialog
        isOpen={!state.hideSelectDialog}
        onDismiss={() => {
          onclose({ hideSelectDialog: true });
        }}
        dialogContentProps={{ type: DialogType.close }}
        // isBlocking={false}
        minWidth={500}
        responsiveMode={ResponsiveMode.medium}
        modalProps={{
          isBlocking: false,
          dragOptions: {
            moveMenuItemText: "Move",
            closeMenuItemText: "Close",
            menu: ContextualMenu,
            keepInBounds: true,
          },
          isModeless: false,
          styles: { main: { width: 350, minHeight: 650 } },
        }}
      >
        <Suspense fallback={suspfallback}>
          <SemTalkSelect
            context={context}
            title={semtalk.getResStrListener(ResIDL.STRSELECTSL)}
            semtalk={semtalk}
            usegraph={graph.usegraph}
            graphClient={graph.graphClient}
            callback={callbackfn}
            sharepoint={sharepoint}
            isexport={state.isexport}
            systemclass={null}
            defaulttext={null}
            isclasses={isclasses}
            focus={state.object}
            mongo={mongo}
            sharepointrepository={state.sharepointrepository}
            ondismiss={() => {
              onclose({ hideSelectDialog: true });
            }}
          />
        </Suspense>
      </Dialog>
    );
  }
}

export function renderTermSet(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  context: any,
  graphClient: any,
  sharepoint: ISharePointSettings,
  callbackfn: (sysc: string, items: any[]) => void,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideTermSetDialog}
    onDismiss={() => {
      onclose({ hideTermSetDialog: true });
    }}
    type={state.panelTypeTermSet}
    isBlocking={false}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkTermSet
        context={context}
        graphClient={graphClient}
        azureAD={state.azureAD}
        semtalk={semtalk}
        callback={callback}
        callbackimport={callbackfn}
        site={sharepoint.site}
        isexport={false}
        focus={state.object}
        language={state.language}
        guilanguage={state.guilanguage}
      />
    </Suspense>
  </Panel>
  );
}
export function renderSiteBuilder(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  context: any,
  graphClient: any,
  sharepoint: ISharePointSettings,
  // callbackfn: (sysc: string, items: any[]) => void,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideSiteBuilderDialog}
    onDismiss={() => {
      onclose({ hideSiteBuilderDialog: true });
    }}
    type={state.panelTypeSiteBuilder}
    isBlocking={false}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkSiteBuilder
        context={context}
        graphClient={graphClient}
        azureAD={state.azureAD}
        semtalk={semtalk}
        callback={callback}
        // callbackimport={callbackfn}
        site={sharepoint.site}
      />
    </Suspense>
  </Panel>
  );
}
export function renderExpand(
  // callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  // context: any,
  // graphClient: any,
  // sharepoint: ISharePointSettings,
  // isclasses: boolean,
  callbackfn: (_semtalk: IVisioRDFS,
    shape: any,
    newlinks: ISemTalkAssociation[])
    => void,
  callbacksimfn: (_semtalk: IVisioRDFS,
    shape: any,
    newlinks: string[]) => void,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (
    <Panel
      isOpen={!state.hideExpandDialog}
      onDismiss={() => {
        onclose({ hideExpandDialog: true });
      }}
      isBlocking={false}
      type={PanelType.smallFixedFar}
    >
      <Suspense fallback={suspfallback}>
        <SemTalkExpand
          title={semtalk.getResStrListener(ResIDL.STREXPAND)}
          semtalk={semtalk}
          callback={callbackfn}
          simcallback={callbacksimfn}
          semantics={state.semantics}
          focus={state.object}
          mongo={mongo}
          ondismiss={() => {
            onclose({ hideExpandDialog: true });
          }}
        />
      </Suspense>
    </Panel>);
}
export function renderRelation(
  // callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  context: any,
  graph: ISharePointGraphSettings,
  sharepoint: ISharePointSettings,
  isclasses: boolean,
  systemclass: ISemTalkSystemClass | null,
  defaulttext: string | null,
  callbackfn: (
    _semtalk: IVisioRDFS,
    shape: any,
    newobj: ISemTalkObject[],
    _expand: boolean
  ) => void,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  if (!state.useDialogs) {
    return (
      <Panel
        isOpen={!state.hideRelationDialog}
        onDismiss={() => {
          onclose({ hideRelationDialog: true });
        }}
        isBlocking={true}
        type={PanelType.smallFixedFar}
      >
        <Suspense fallback={suspfallback}>
          <SemTalkSelect
            context={context}
            semtalk={semtalk}
            title={semtalk.getResStrListener(ResIDL.STRSELECTSL)}
            usegraph={graph.usegraph}
            graphClient={graph.graphClient}
            callback={callbackfn}
            sharepoint={sharepoint}
            isexport={state.isexport}
            systemclass={systemclass}
            defaulttext={defaulttext}
            isclasses={isclasses}
            focus={"-1"}
            mongo={mongo}
            sharepointrepository={state.sharepointrepository}
            ondismiss={() => {
              onclose({ hideRelationDialog: true });
            }}
          />
        </Suspense>
      </Panel>
    );
  } else {
    return (
      <Dialog
        isOpen={!state.hideRelationDialog}
        onDismiss={() => {
          onclose({ hideRelationDialog: true });
        }}
        dialogContentProps={{ type: DialogType.close }}
        // isBlocking={false}
        minWidth={500}
        responsiveMode={ResponsiveMode.medium}
        modalProps={{
          isBlocking: false,
          dragOptions: {
            moveMenuItemText: "Move",
            closeMenuItemText: "Close",
            menu: ContextualMenu,
            keepInBounds: true,
          },
          isModeless: false,
          styles: { main: { width: 350, minHeight: 650 } },
        }}
      >
        <Suspense fallback={suspfallback}>
          <SemTalkSelect
            context={context}
            semtalk={semtalk}
            title={semtalk.getResStrListener(ResIDL.STRSELECTSL)}
            usegraph={graph.usegraph}
            graphClient={graph.graphClient}
            callback={callbackfn}
            sharepoint={sharepoint}
            // site={this.sprops.site}
            isexport={state.isexport}
            systemclass={systemclass}
            defaulttext={defaulttext}
            isclasses={isclasses}
            focus={"-1"}
            mongo={mongo}
            sharepointrepository={state.sharepointrepository}
            ondismiss={() => {
              onclose({ hideRelationDialog: true });
            }}
          />
        </Suspense>
      </Dialog>
    );
  }
}
export function renderCreateDiagram(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  callbackfn: (
    _semtalk: IVisioRDFS,
    name: string,
    diagtype: ISemTalkDiagramType | null
  ) => void,
  footer: () => JSX.Element,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideCreateDiagramDialog}
    onDismiss={() => {
      onclose({ hideCreateDiagramDialog: true });
    }}
    isBlocking={true}
    headerText={
      semtalk.getResStrListener(ResIDL.STRDLHTMLOUTDIA) +
      ": " +
      semtalk.getResStr(ResID.STRDLGCMDNE).replace("&", "")
    }
    type={PanelType.smallFixedFar}
    onRenderFooterContent={
      footer
      // this.onRenderSemTalkNewDiagramFooterContent
    }
  >
    <Suspense fallback={suspfallback}>
      <SemTalkCreateDiagram
        semtalk={semtalk}
        callbackfn={callbackfn}
        callback={callback}
        diagram={semtalk.page}
        role={state.role}
      />
    </Suspense>
  </Panel>
  );
}
export function renderInsert(
  // callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  context: any,
  graph: ISharePointGraphSettings,
  sharepoint: ISharePointSettings,
  isclasses: boolean,
  systemclass: ISemTalkSystemClass | null,
  callbackfn: (
    _semtalk: IVisioRDFS,
    shape: any,
    newobjs: ISemTalkObject[],
    expand: boolean
  ) => void,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideInsertDialog}
    onDismiss={() => {
      onclose({ hideInsertDialog: true });
    }}
    isBlocking={true}
    type={PanelType.medium}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkInsert
        title={semtalk.getResStrListener(ResIDL.STRDLGINSCAPTION)}
        context={context}
        semtalk={semtalk}
        usegraph={graph.usegraph}
        graphClient={graph.graphClient}
        callback={callbackfn}
        isexport={state.isexport}
        systemclass={systemclass}
        isclasses={isclasses}
        isinsert={true}
        focus={null}
        sharepoint={sharepoint}
        mongo={mongo}
        sharepointrepository={state.sharepointrepository}
        ondismiss={() => {
          onclose({ hideInsertDialog: true });
        }}
      />
    </Suspense>
  </Panel>
  );
}
export function renderCompose(
  // callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  context: any,
  graph: ISharePointGraphSettings,
  sharepoint: ISharePointSettings,
  callbackfn: (
    _oclass: ISemTalkClass
  ) => void,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  if (!state.useDialogs) {
    return (
      <Panel
        isOpen={!state.hideComposeDialog}
        onDismiss={() => {
          onclose({ hideComposeDialog: true });
        }}
        isBlocking={false}
        type={PanelType.smallFixedFar}
      >
        <Suspense fallback={suspfallback}>
          <SemTalkCompose
            title={semtalk.getResStrListener(ResIDL.STRCOMPOSE)}
            context={context}
            semtalk={semtalk}
            usegraph={graph.usegraph}
            graphClient={graph.graphClient}
            sysclassbusinessobject={null}
            sysclasscomposed={null}
            callback={callbackfn}
            sharepoint={sharepoint}
            mongo={mongo}
            sharepointrepository={state.sharepointrepository}
          />
        </Suspense>
      </Panel>
    );
  } else {
    return (
      <Dialog
        isOpen={!state.hideComposeDialog}
        onDismiss={() => {
          onclose({ hideComposeDialog: true });
        }}
        dialogContentProps={{ type: DialogType.close }}
        // isBlocking={false}
        minWidth={500}
        responsiveMode={ResponsiveMode.medium}
        modalProps={{
          isBlocking: false,
          dragOptions: {
            moveMenuItemText: "Move",
            closeMenuItemText: "Close",
            menu: ContextualMenu,
            keepInBounds: true,
          },
          isModeless: false,
          styles: { main: { width: 350, minHeight: 650 } },
        }}
      >
        <Suspense fallback={suspfallback}>
          <SemTalkCompose
            title={semtalk.getResStrListener(ResIDL.STRCOMPOSE)}
            context={context}
            semtalk={semtalk}
            callback={callbackfn}
            usegraph={graph.usegraph}
            graphClient={graph.graphClient}
            sysclassbusinessobject={null}
            sysclasscomposed={null}
            sharepoint={sharepoint}
            mongo={mongo}
            sharepointrepository={state.sharepointrepository}
          />
        </Suspense>
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              onclose({ hideComposeDialog: true });
            }}
            styles={{ root: { marginRight: 8 } }}
          >
            {semtalk!.getResStr(ResID.STRDLGCMDOK)}
          </PrimaryButton>
          <DefaultButton
            onClick={() => {
              semtalk!.showHelp("Compose");
            }}
            styles={{ root: { marginRight: 8 } }}
          >
            {semtalk!.getResStr(ResID.STRHELP)}
          </DefaultButton>
        </DialogFooter>
      </Dialog>
    );
  }
}
export function renderTranslate(
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideTranslateDialog}
    onDismiss={() => {
      onclose({ hideTranslateDialog: true });
    }}
    isBlocking={false}
    type={PanelType.smallFixedFar}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkTranslate
        title={semtalk.getResStrListener(ResIDL.STRTRANSLATE)}
        semtalk={semtalk}
        islocked={state.islocked}
      />
    </Suspense>
  </Panel>
  );
}
export function renderTasks(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  context: any,
  graphClient: any,
  callbackfn: (
    syscname: string, items: any[]
  ) => void,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideTasksDialog}
    onDismiss={() => {
      onclose({ hideTasksDialog: true });
    }}
    isBlocking={false}
    type={PanelType.medium}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkTasks
        title={semtalk
          .getResStrListener(ResIDL.STRBONHRAC)
          .replace(":", "")}
        callback={callback}
        semtalk={semtalk}
        islocked={state.islocked}
        graphClient={graphClient}
        azureAD={state.azureAD}
        context={context}
        callbackimport={callbackfn}
      />
    </Suspense>
  </Panel>
  );
}
export function renderPlanner(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hidePlannerDialog}
    onDismiss={() => {
      onclose({ hidePlannerDialog: true });
    }}
    isBlocking={false}
    type={PanelType.medium}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkPlanner
        title={semtalk
          .getResStrListener(ResIDL.STRBONHRAC)
          .replace(":", "")}
        semtalk={semtalk}
        callback={callback}
        islocked={state.islocked}
        isplanmaker={false}
        language={state.language}
        guilanguage={state.guilanguage}
        isusertask={false}
      />
    </Suspense>
  </Panel>
  );
}
export function renderForm(
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideFormDialog}
    onDismiss={() => {
      onclose({ hideFormDialog: true });
    }}
    isBlocking={false}
    type={PanelType.medium}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkForm
        semtalk={semtalk}
      />
    </Suspense>
  </Panel>
  );
}
export function renderMongoUser(
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  mongo: IMongoOption,
  role: SemTalkRole,
  autosave: () => void,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hidemgUserProfilDialog}
    onDismiss={() => {
      onclose({ hidemgUserProfilDialog: true });
    }}
    isBlocking={true}
    headerText={semtalk.getResStr(ResID.STRUser)}
    type={PanelType.medium}
  >
    {semtalk && (
      <Suspense fallback={suspfallback}>
        <UserProfile
          semtalk={semtalk}
          mongo={mongo}
          role={role}
          dismiss={() => {
            onclose({ hidemgUserProfilDialog: true });
            autosave();
            window.location.reload();
          }}
        />
      </Suspense>
    )}
  </Panel>
  );
}
export function renderMongoUserManager(
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  mongo: IMongoOption,
  autosave: () => void,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  let dbname = mongo.dbname;
  if (dbname === mongo.defaultdatabase) {
    dbname = "Default";
  }
  return (<Panel
    isOpen={!state.hideUserManager}
    onDismiss={() => {
      onclose({ hideUserManager: true });
    }}
    isBlocking={true}
    headerText={semtalk.getResStr(ResID.STRUserManager) + "     (" + semtalk.getResStr(ResID.STRSQLDATABASE) + " " + dbname + ")"}
    type={PanelType.medium}
  >
    {semtalk && (
      <Suspense fallback={suspfallback}>
        <SemTalkUsers
          semtalk={semtalk}
          mongo={mongo}
          dismiss={() => {
            onclose({ hideUserManager: true });
            autosave();
            window.location.reload();
          }}
        />
      </Suspense>
    )}
  </Panel>
  );
}
export function renderCheckRepair(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideCheckRepair}
    onDismiss={() => {
      onclose({ hideCheckRepair: true });
    }}
    isBlocking={false}
    headerText={semtalk.getResStr(ResID.STRREPAIR).replace("&", "")}
    type={PanelType.medium}
  >
    {semtalk && (
      <Suspense fallback={suspfallback}>
        <SemTalkCheckRepair semtalk={semtalk} callback={callback} />
      </Suspense>
    )}
  </Panel>
  );
}
export function renderSimulation(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideSimulation}
    onDismiss={() => {
      onclose({ hideSimulation: true });
    }}
    isBlocking={false}
    headerText={semtalk.getResStr(ResID.STRSIMULATION)}
    type={state.panelTypeSimulation}
    customWidth={state.editdialogwidth}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkSimulation
        semtalk={semtalk}
        callback={callback}
        usecolor={state.usesimulationcoloring}
      />
    </Suspense>
  </Panel>
  );
}
export function renderDocuments(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  graph: ISharePointGraphSettings,
  sharepoint: ISharePointSettings,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (< Panel
    isOpen={!state.hideDocuments
    }
    onDismiss={() => {
      onclose({ hideDocuments: true });
    }}
    isBlocking={false}
    headerText={semtalk.getResStr(ResID.STRDOCUMENTS)}
    type={state.panelTypeDocuments}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkDocuments
        semtalk={semtalk}
        callback={callback}
        usegraph={graph.usegraph}
        graphClient={graph.graphClient}
        sharepoint={sharepoint}
        mongo={mongo}
        semantics={state.semantics}
        site={""}
        library={""}
      />
    </Suspense>
  </Panel >
  );
}
export function renderShareDocument(
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  sharepoint: ISharePointSettings,
  filename: string,
  username: string,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideShareDocument}
    onDismiss={() => {
      onclose({ hideShareDocument: true });
    }}
    isBlocking={false}
    headerText={semtalk.getResStr(ResID.STRSHARE)}
    type={PanelType.smallFixedFar}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkShare
        semtalk={semtalk}
        language={state.language}
        sharepoint={sharepoint}
        site={sharepoint.site}
        filename={filename}
        diagram={state.diag}
        mongo={mongo}
        username={username}
      />
    </Suspense>
  </Panel>
  );
}
export function renderPrintPreview(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hidePrintPreview}
    onDismiss={() => {
      onclose({ hidePrintPreview: true });
    }}
    isBlocking={false}
    headerText={semtalk.getResStrListener(ResIDL.STRPRINT)}
    type={PanelType.medium}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkPrintOptions callback={callback} semtalk={semtalk}
        title={semtalk.getResStrListener(ResIDL.STRPRINT)}
        ondismiss={() => {
          onclose({ hidePrintPreview: true });
        }} />
    </Suspense>
  </Panel>);
}
export function renderChatgpt(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  mongo: IMongoOption,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideChatgpt}
    onDismiss={() => {
      onclose({ hideChatgpt: true });
    }}
    isBlocking={false}
    headerText={"ChatGPT"}
    type={state.panelTypeChatgpt}
    // customWidth={this.state.editdialogwidth}
    customWidth={"600px"}
  >
    <Suspense fallback={suspfallback}>
      <Chatgpt
        apiKey={state.chatgpt}
        semtalk={semtalk}
        callback={callback}
        mongo={mongo}
        semantics={state.semantics}
        openai={state.openai}
      />
    </Suspense>
  </Panel>
  );
}
export function renderHeaderFooter(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  _mongo: IMongoOption,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideHeaderFooter}
    onDismiss={() => {
      onclose({ hideHeaderFooter: true });
    }}
    isBlocking={false}
    headerText={semtalk.getResStrListener(ResIDL.STRDLGTABBKPG)}
    type={state.panelTypeHeaderEditor}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkHeaderFooterTab
        title={semtalk.getResStrListener(ResIDL.STRDLGTABBKPG)}
        callback={callback}
        semtalk={semtalk}
        mongo={_mongo}
        isadmin={state.isadmin}
        ondismiss={() => {
          onclose({ hideHeaderFooter: true });
        }}
      >
      </SemTalkHeaderFooterTab>

    </Suspense>
  </Panel>
  );
}
export function renderImages(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  _mongo: IMongoOption,
  onclose: (state: any) => void,
  selectedImages: any[],
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideImages}
    onDismiss={() => {
      onclose({ hideImages: true });
    }}
    isBlocking={false}
    headerText={semtalk.getResStr(ResID.STRIMAGEMANAGER)}
    type={state.panelTypeImagesEditor}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkImagesTab
        title={semtalk.getResStr(ResID.STRIMAGEMANAGER)}
        callback={callback}
        semtalk={semtalk}
        selectedImages= {selectedImages}
        ondismiss={() => {
          onclose({ hideImages: true });
        }}
      >
      </SemTalkImagesTab>

    </Suspense>
  </Panel>
  );
}
export function renderReportManager(
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideReportManager}
    onDismiss={() => {
      onclose({ hideReportManager: true });
    }}
    isBlocking={true}
    headerText={semtalk.getResStr(ResID.STRREPORT).replace("&", "")}
    type={PanelType.medium}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkReports semtalk={semtalk} mongo={mongo} />
    </Suspense>
  </Panel>
  );
}
export function renderPromptManager(
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hidePromptManager}
    onDismiss={() => {
      onclose({ hidePromptManager: true });
    }}
    isBlocking={true}
    headerText={semtalk.getResStr(ResID.STRPROMPT).replace("&", "")}
    type={PanelType.medium}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkPrompts semtalk={semtalk} mongo={mongo} />
    </Suspense>
  </Panel>
  );
}
export function renderStorageManager(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideStorageManager}
    onDismiss={() => {
      onclose({ hideStorageManager: true });
    }}
    isBlocking={true}
    headerText={"Storage Manager"}
    type={state.panelTypeStorage}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkStorage
        semtalk={semtalk}
        callback={callback}
      />
    </Suspense>
  </Panel>
  );
}
export function renderStencilEditor(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideStencilEditor}
    onDismiss={() => onclose({ hideStencilEditor: true })}
    isBlocking={true}
    type={state.panelTypeStencilEditor}
    headerText={
      semtalk.getResStrListener(ResIDL.STRSTENCIL).replace(":", "")
    }
  >
    <Suspense fallback={suspfallback}>
      <SemTalkStencilTab
        title={semtalk.getResStrListener(ResIDL.STRSTENCIL).replace(":", "")}
        semtalk={semtalk}
        callback={callback}
        ondismiss={() => onclose({ hideStencilEditor: true })}
      />
    </Suspense>
  </Panel>
  );
}
export function renderRibbonEditor(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideRibbonEditor}
    onDismiss={() => onclose({ hideRibbonEditor: true })}
    isBlocking={false}
    type={state.panelTypeRibbonEditor}
    headerText={
      semtalk.getResStrListener(ResIDL.STRDLGLMENU)
    }
  >
    <Suspense fallback={suspfallback}>
      <SemTalkRibbon
        ondismiss={() => onclose({ hideRibbonEditor: true })}
        title={semtalk.getResStrListener(ResIDL.STRDLGLMENU)}
        semtalk={semtalk}
        callback={callback}
      />
    </Suspense>
  </Panel>
  );
}
export function renderRenameObjects(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideRenameObjects}
    onDismiss={() => onclose({ hideRenameObjects: true })}
    isBlocking={false}
    type={PanelType.medium}
    headerText={
      semtalk.getResStrListener(ResIDL.STRRENAME)
    }
  >
    <Suspense fallback={suspfallback}>
      <SemTalkRename
        semtalk={semtalk}
        callback={callback}
      />
    </Suspense>
  </Panel>
  );
}
export function renderImport(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  sharepoint: ISharePointSettings,
  filename: string,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideImport}
    onDismiss={() => onclose({ hideImport: true })}
    isBlocking={false}
    type={state.panelTypeImport}
    headerText={
      semtalk.getResStrListener(ResIDL.STRDLGIMPORT)
    }
  >
    <Suspense fallback={suspfallback}>
      <SemTalkImport
        semtalk={semtalk}
        callback={callback}
        filename={filename}
        title={semtalk.getResStrListener(ResIDL.STRDLGIMPORT)}
        mongo={mongo}
        sharepoint={sharepoint}
        isowl={false}
        owlob={null}
        ondismiss={() => onclose({ hideImport: true })}
      />
    </Suspense>
  </Panel>
  );
}
export function renderOWLImport(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  mongo: IMongoOption,
  state: ISemTalkOnlineState,
  sharepoint: ISharePointSettings,
  filename: string,
  externalob: IObjectBase | null,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideOWLImport}
    onDismiss={() => onclose({ hideOWLImport: true })}
    isBlocking={false}
    type={state.panelTypeImport}
    headerText={
      "OWL " + semtalk.getResStrListener(ResIDL.STRDLGIMPORT)
    }
  >
    <Suspense fallback={suspfallback}>
      <SemTalkImport
        semtalk={semtalk}
        callback={callback}
        filename={filename}
        title={"OWL " + semtalk.getResStrListener(ResIDL.STRDLGIMPORT)}
        mongo={mongo}
        sharepoint={sharepoint}
        isowl={true}
        owlob={externalob}
        ondismiss={() => onclose({ hideOWLImport: true })}
      />
    </Suspense>
  </Panel>
  );
}

export function renderCharting(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideCharting}
    onDismiss={() => onclose({ hideCharting: true })}
    isBlocking={false}
    type={state.panelTypeCharting}
    customWidth={state.editdialogwidth}
    headerText={
      semtalk.getResStrListener(ResIDL.STRSTATISTICS)
    }
  >
    <Suspense fallback={suspfallback}>
      <SemTalkCharting
        semtalk={semtalk}
        islocked={state.islocked}
        callback={callback}
      // ondismiss={() => onclose({ hideCharting: true })}
      />
    </Suspense>
  </Panel>
  );
}

export function renderImpressum(
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideImpressum}
    onDismiss={() => onclose({ hideImpressum: true })}
    isBlocking={false}
    headerText={
      "Impressum"
    }
  >
    <Suspense fallback={suspfallback}>
      <SemTalkImpressum
        semtalk={semtalk}
      />
    </Suspense>
  </Panel>
  );
}

export function renderPrivacy(
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hidePrivacy}
    onDismiss={() => onclose({ hidePrivacy: true })}
    isBlocking={false}
    type={state.panelTypeCharting}
    headerText={
      semtalk.getResStrListener(ResIDL.STRPRIVACY)
    }
  >
    <Suspense fallback={suspfallback}>
      <SemTalkPrivacy
        semtalk={semtalk}

      />
    </Suspense>
  </Panel>
  );
}

export function renderLayout(
  callback: ISemTalkOnline,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideLayout}
    onDismiss={() => onclose({ hideLayout: true })}
    isBlocking={false}
    type={PanelType.medium}
    headerText={
      semtalk.getResStrListener(ResIDL.STRLAYOUT)
    }
  >
    <Suspense fallback={suspfallback}>
      <SemTalkLayout
        semtalk={semtalk}
        callback={callback}
      />
    </Suspense>
  </Panel>
  );
}
export function renderKeyManager(
  callback: ISemTalkOnline | null,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  mongo: IMongoOption,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideKeyManager}
    onDismiss={() => {
      onclose({ hideKeyManager: true });
    }}
    isBlocking={false}
    headerText={semtalk.getResStr(ResID.STRKEYMANAGER)}
    type={state.panelTypeKeyManagerEditor}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkKeyManager
        title={semtalk.getResStr(ResID.STRKEYMANAGER)}
        semtalk={semtalk}
        mongo={mongo}
        ondismiss={() => {
          onclose({ hideKeyManager: true });
        }}
        callback={callback}
      >
      </SemTalkKeyManager>

    </Suspense>
  </Panel>
  );

}
export function renderExportManager(
  callback: ISemTalkOnline | null,
  semtalk: IVisioRDFS,
  state: ISemTalkOnlineState,
  _mongo: IMongoOption,
  onclose: (state: any) => void,
  suspfallback: JSX.Element) {
  return (<Panel
    isOpen={!state.hideExportManager}
    onDismiss={() => {
      onclose({ hideExportManager: true });
    }}
    isBlocking={false}
    headerText={semtalk.getResStr(ResID.STREXPORTCONN)}
    type={state.panelTypeExportConnectionEditor}
  >
    <Suspense fallback={suspfallback}>
      <SemTalkExportManager
        semtalk={semtalk}
        callback={callback}
        guilanguage={state.guilanguage}
        isadmin={state.isadmin}
        mongo={_mongo}
      >
      </SemTalkExportManager>

    </Suspense>
  </Panel>
  );
}



