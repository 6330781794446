import {
  ISemTalkSystemClass, SemTalkBaseConstant,
  SemTalkLanguage, SemTalkLanguageCode,
  SemTalkRelation, ModelAttribute,
  ISemTalkInstDialog, ISemTalkAssociationType,
  SemTalkAttachment
  // ISemTalkAttribute,
  // ISemTalkAttributeType,
  // SemTalkValueType
} from '../tbase/Interface';

import { IVisioRDFS, ResID, ResIDL, SemTalkStyleAttribute, SemTalkUIConstant } from './visiordfsinterface';
import { GENERIC_SHAPES } from './generic-stencil';
import { SemTalkMaster } from '../SemTalkMaster';

export class Subtask {
  public static patch(sem: IVisioRDFS, _resizeall: boolean) {
    const base = sem.base;
    // Shouldnt we always create "Generic"
    const g = base.FindDiagramType(SemTalkBaseConstant.SLGeneric);
    if (g !== null) {
      g.IsClass = true;
      g.MakeSynonym("Ontology", SemTalkLanguage.English);
      g.MakeSynonym("Ontologie", SemTalkLanguage.German);
      g.Stencil = "class.vss";
      g.IsReadOnly = true;
      g.UMLShape = false;
    }
    base.SetModelAttribute(ModelAttribute.ShowGenericDiagrams, "True");

    let h = base.MakeSystemClass(SemTalkUIConstant.SLHidden);
    h.Hide = true;
    Subtask.ensureLanguages(sem, h);

    {
      let attachmentclass = base.FindSystemClass(SemTalkBaseConstant.SLAttachment);
      if (!attachmentclass) {
        attachmentclass = base.MakeSystemClass(SemTalkBaseConstant.SLAttachment);
        attachmentclass.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGATTCAPTION, SemTalkLanguageCode.German), SemTalkLanguage.German);
        attachmentclass.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGATTCAPTION, SemTalkLanguageCode.English), SemTalkLanguage.English);
        attachmentclass.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGATTCAPTION, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
        attachmentclass.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGATTCAPTION, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
        attachmentclass.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGATTCAPTION, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
        attachmentclass.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGATTCAPTION, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
        attachmentclass.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGATTCAPTION, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
        let id: ISemTalkInstDialog = attachmentclass.InitInstDialog();
        id.CheckAttachments = true;
        attachmentclass.Refineable = false;
        attachmentclass.IsReadOnly = true;
      }
      if (attachmentclass) {
        for (let attachment of attachmentclass.Instances()) {
          let lbl = attachment.GetValue(SemTalkAttachment.label);
          let atype = attachment.GetValue(SemTalkAttachment.type);
          let namespace = attachment.GetValue(SemTalkAttachment.type);
          if (lbl || atype || namespace) {
            for (let oth of attachment.InvLinks(SemTalkBaseConstant.SLhasAttachment, SemTalkRelation.SemTalkSystemRelation)) {
              if (!oth.GetValue(SemTalkAttachment.label)) {
                oth.SetValue(SemTalkAttachment.label, lbl);
              }
              if (!oth.GetValue(SemTalkAttachment.type)) {
                oth.SetValue(SemTalkAttachment.type, atype);
              }
              if (!oth.GetValue(SemTalkAttachment.namespace)) {
                oth.SetValue(SemTalkAttachment.namespace, namespace);
              }
            }
          }
        }
        if (attachmentclass.Style.length === 0) {
          attachmentclass.Style = "shape=offPageConnector;verticalLabelPosition=top;verticalAlign=bottom";
        }
      }

      let thing = base.FindSystemClass(SemTalkBaseConstant.SLThing);
      if (!thing) {
        thing = base.MakeSystemClass(SemTalkBaseConstant.SLThing);
        let id: ISemTalkInstDialog = thing.InitInstDialog();
        id.CheckClass = true;
        id.CheckAttributes = true;
        id.CheckAssociations = true;
        id.CheckSynonyms = true;
      }
      thing.IsReadOnly = true;
      if (!thing.HasDirectLink(SemTalkBaseConstant.SLConsistsOf, thing)) {
        thing.MakeAssociation(SemTalkBaseConstant.SLConsistsOf, thing);
      }
      if (GENERIC_SHAPES[SemTalkMaster.MasterInstance] && (thing.Style.length === 0 || thing.Style === "fillColor=lightgray;strokeWidth=2;html=1")) {
        thing.Style = GENERIC_SHAPES[SemTalkMaster.MasterInstance].style;
      }
      // thing.Hide = true;
      // thing.AddSubclassOf(h);
      thing.MakeSynonym(sem.getResStr(ResID.STRINSTANCE, SemTalkLanguageCode.German), SemTalkLanguage.German);
      thing.MakeSynonym(sem.getResStr(ResID.STRINSTANCE, SemTalkLanguageCode.English), SemTalkLanguage.English);
      thing.MakeSynonym(sem.getResStr(ResID.STRINSTANCE, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      thing.MakeSynonym(sem.getResStr(ResID.STRINSTANCE, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      thing.MakeSynonym(sem.getResStr(ResID.STRINSTANCE, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      thing.MakeSynonym(sem.getResStr(ResID.STRINSTANCE, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      thing.MakeSynonym(sem.getResStr(ResID.STRINSTANCE, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
    }

    {
      let comment = base.FindSystemClass(SemTalkBaseConstant.SLComment);
      if (!comment) {
        comment = base.MakeSystemClass(SemTalkBaseConstant.SLComment);
      }
      if (comment.Style.length === 0) {
        comment.Style = SemTalkStyleAttribute.fillColor + "=yellow;" +
          SemTalkStyleAttribute.shadow + "=1;" +
          SemTalkStyleAttribute.strokeWidth + "=1;html=1";
      }
      comment.IsReadOnly = true;
      // comment.Hide = true;
      // comment.AddSubclassOf(h);
      comment.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.German), SemTalkLanguage.German);
      comment.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.English), SemTalkLanguage.English);
      comment.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      comment.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      comment.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      comment.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      comment.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
    }

    let comof = base.GetModelAttribute(ModelAttribute.SLCommentOf);
    if (comof === undefined) {
      comof = SemTalkBaseConstant.SLDefinitionOf;
      base.SetModelAttribute(ModelAttribute.SLCommentOf, comof);
    }
    if (base.FindAssociationType(comof) === null) {
      base.MakeAssociationType(SemTalkRelation.SemTalkSystemRelation, comof);
    }

    let f: ISemTalkSystemClass | null;
    f = base.FindAssociationType(comof);
    if (f && f.Style.length === 0) {
      f.Style = SemTalkStyleAttribute.shape + "=flexArrow;" +
        SemTalkStyleAttribute.dashed + "=1;" +
        SemTalkStyleAttribute.endArrow + "=classic;html=1;" +
        SemTalkStyleAttribute.editable + "=0";
    }
    if (f) {
      f.IsReadOnly = true;
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.German), SemTalkLanguage.German);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.English), SemTalkLanguage.English);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRCOMMENT, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);

    }



    f = base.FindAssociationType(SemTalkBaseConstant.SLSubClassOf);
    if (f) {
      f.IsReadOnly = true;
      if (f && f.Style.length === 0) {
        f.Style = SemTalkStyleAttribute.shape + "=flexArrow;" +
          SemTalkStyleAttribute.dashed + "=1;" +
          SemTalkStyleAttribute.dashPattern + "=5 5; " +
          SemTalkStyleAttribute.endArrow + "=classic;html=1;" +
          SemTalkStyleAttribute.fillColor + "=#ffffff;" +
          SemTalkStyleAttribute.strokeWidth + "=2;" +
          SemTalkStyleAttribute.editable + "=0;";
      }
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTSUP, SemTalkLanguageCode.German), SemTalkLanguage.German);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTSUP, SemTalkLanguageCode.English), SemTalkLanguage.English);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTSUP, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTSUP, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTSUP, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTSUP, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTSUP, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
    }
    f = base.FindAssociationType(SemTalkBaseConstant.SLInstanceOf);
    if (f) {
      f.IsReadOnly = true;
      (f as ISemTalkAssociationType).RelationType = SemTalkRelation.SemTalkInstanceOf;
      if (f && f.Style.length === 0) {
        f.Style = SemTalkStyleAttribute.shape + "=flexArrow;" +
          SemTalkStyleAttribute.dashed + "=1;" +
          SemTalkStyleAttribute.dashPattern + "=2 2;" +
          SemTalkStyleAttribute.endArrow + "=classic;html=1;" +
          SemTalkStyleAttribute.fillColor + "=#ffffff;" +
          SemTalkStyleAttribute.strokeWidth + "=2;" +
          SemTalkStyleAttribute.editable + "=0;";
      }
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTINS, SemTalkLanguageCode.German), SemTalkLanguage.German);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTINS, SemTalkLanguageCode.English), SemTalkLanguage.English);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTINS, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTINS, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTINS, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTINS, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      f.MakeSynonym(sem.getResStrListener(ResIDL.STRDLHTMLOUTINS, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
    }
    let COOF = SemTalkBaseConstant.SLConsistsOf;
    f = base.FindAssociationType(COOF);
    if (!f) {
      f = base.MakeAssociationType(SemTalkRelation.SemTalkProperty, COOF);
      f.MakeSynonym(COOF, SemTalkLanguage.English);
      f.MakeSynonym("besteht aus", SemTalkLanguage.German);
    }
    f.IsReadOnly = true;

    let MXG = SemTalkBaseConstant.SLMXGAttribute;
    let a = base.FindAttributeType(MXG);
    if (!a) a = base.MakeAttributeType(MXG);
    a.Raise = false;
    a.IsReadOnly = true;

    a = base.FindAttributeType(SemTalkAttachment.icon);
    if (!a) a = base.MakeAttributeType(SemTalkAttachment.icon);
    a.IsReadOnly = true;
    a = base.FindAttributeType(SemTalkAttachment.label);
    if (!a) a = base.MakeAttributeType(SemTalkAttachment.label);
    a.IsReadOnly = true;
    a = base.FindAttributeType(SemTalkAttachment.type);
    if (!a) a = base.MakeAttributeType(SemTalkAttachment.type);
    a.IsReadOnly = true;



  }
  public static init(_sem: IVisioRDFS) {
  }
  private static ensureLanguages(sem: IVisioRDFS, h: ISemTalkSystemClass) {
    const base = sem.base;
    let l = base.MakeSystemClass(SemTalkBaseConstant.SLLanguage);
    l.Hide = false;
    l.IsReadOnly = true;
    // l.AddSubclassOf(h);
    if (l.IsParent(h)) {
      l.DeleteSubclassOf(h);
    }
    {
      l.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGTABSYN, SemTalkLanguageCode.German), SemTalkLanguage.German);
      l.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGTABSYN, SemTalkLanguageCode.English), SemTalkLanguage.English);
      l.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGTABSYN, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      l.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGTABSYN, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      l.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGTABSYN, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      l.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGTABSYN, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      l.MakeSynonym(sem.getResStrListener(ResIDL.STRDLGTABSYN, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
      let li = base.FindInstance(SemTalkLanguage.German);
      if (!li) {
        li = l.MakeInstance(SemTalkLanguage.German);
      }
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRGERMAN, SemTalkLanguageCode.German), SemTalkLanguage.German);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRGERMAN, SemTalkLanguageCode.English), SemTalkLanguage.English);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRGERMAN, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRGERMAN, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRGERMAN, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRGERMAN, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRGERMAN, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
    }
    {
      let li = base.FindInstance(SemTalkLanguage.English);
      if (!li) {
        li = l.MakeInstance(SemTalkLanguage.English);
      }
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRENGLISH, SemTalkLanguageCode.German), SemTalkLanguage.German);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRENGLISH, SemTalkLanguageCode.English), SemTalkLanguage.English);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRENGLISH, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRENGLISH, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRENGLISH, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRENGLISH, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRENGLISH, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
    }
    {
      let li = base.FindInstance(SemTalkLanguage.Spanish);
      if (!li) {
        li = l.MakeInstance(SemTalkLanguage.Spanish);
      }
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRSPANISH, SemTalkLanguageCode.German), SemTalkLanguage.German);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRSPANISH, SemTalkLanguageCode.English), SemTalkLanguage.English);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRSPANISH, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRSPANISH, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRSPANISH, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRSPANISH, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRSPANISH, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
    }
    {
      let li = base.FindInstance(SemTalkLanguage.Japanese);
      if (!li) {
        li = l.MakeInstance(SemTalkLanguage.Japanese);
      }
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRJAPANESE, SemTalkLanguageCode.German), SemTalkLanguage.German);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRJAPANESE, SemTalkLanguageCode.English), SemTalkLanguage.English);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRJAPANESE, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRJAPANESE, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRJAPANESE, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRJAPANESE, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRJAPANESE, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
    }
    {
      let li = base.FindInstance(SemTalkLanguage.Russian);
      if (!li) {
        li = l.MakeInstance(SemTalkLanguage.Russian);
      }
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRRUSSIAN, SemTalkLanguageCode.German), SemTalkLanguage.German);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRRUSSIAN, SemTalkLanguageCode.English), SemTalkLanguage.English);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRRUSSIAN, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRRUSSIAN, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRRUSSIAN, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRRUSSIAN, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRRUSSIAN, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
    }
    {
      let li = base.FindInstance(SemTalkLanguage.French);
      if (!li) {
        li = l.MakeInstance(SemTalkLanguage.French);
      }
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRFRENCH, SemTalkLanguageCode.German), SemTalkLanguage.German);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRFRENCH, SemTalkLanguageCode.English), SemTalkLanguage.English);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRFRENCH, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRFRENCH, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRFRENCH, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRFRENCH, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRFRENCH, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
    }
    {
      let li = base.FindInstance(SemTalkLanguage.Simplified);
      if (!li) {
        li = l.MakeInstance(SemTalkLanguage.Simplified);
      }
      li.MakeSynonym(sem.getResStrListener(ResIDL.STSIMPLIFIEDCHINESE, SemTalkLanguageCode.German), SemTalkLanguage.German);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STSIMPLIFIEDCHINESE, SemTalkLanguageCode.English), SemTalkLanguage.English);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STSIMPLIFIEDCHINESE, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STSIMPLIFIEDCHINESE, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STSIMPLIFIEDCHINESE, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STSIMPLIFIEDCHINESE, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STSIMPLIFIEDCHINESE, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
    }
    {
      let li = base.FindInstance(SemTalkLanguage.Traditional);
      if (!li) {
        li = l.MakeInstance(SemTalkLanguage.Traditional);
      }
      li.MakeSynonym(sem.getResStrListener(ResIDL.STTRADITIONALCHINESE, SemTalkLanguageCode.German), SemTalkLanguage.German);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STTRADITIONALCHINESE, SemTalkLanguageCode.English), SemTalkLanguage.English);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STTRADITIONALCHINESE, SemTalkLanguageCode.Spanish), SemTalkLanguage.Spanish);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STTRADITIONALCHINESE, SemTalkLanguageCode.Japanese), SemTalkLanguage.Japanese);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STTRADITIONALCHINESE, SemTalkLanguageCode.Russian), SemTalkLanguage.Russian);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STTRADITIONALCHINESE, SemTalkLanguageCode.Traditional), SemTalkLanguage.Traditional);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STTRADITIONALCHINESE, SemTalkLanguageCode.Simplified), SemTalkLanguage.Simplified);
    }
    {
      let li = base.FindInstance(SemTalkLanguage.None);
      if (!li) {
        li = l.MakeInstance(SemTalkLanguage.None);
      }
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRNOLANGUAGE, SemTalkLanguageCode.German), SemTalkLanguage.German);
      li.MakeSynonym(sem.getResStrListener(ResIDL.STRNOLANGUAGE, SemTalkLanguageCode.English), SemTalkLanguage.English);
      let nol = base.FindInstance("None");
      if (nol && nol.IsInstance(l)) {
        nol.Delete();
      }

    }
  }
}