import { ISemTalkObject, SemTalkID } from "./application/tbase/Interface";

export interface ISemTalkTeamsOptions {
    teamid?: string;
    channelid?: string | null;
    planid?: string | null;
    rolemappings?: string | null;
}

export interface ISemTalkAzureOptions {
    clientID: string;
    authority: string;
    scopes: string[];
}

export enum ModelProperty {
    name = "name",
    created = "Created",
    createdby = "CreatedBy",
    modified = "Modified",
    modifiedby = "ModifiedBy",
    published = "Published",
    publishedby = "PublishedBy",
    approved = "Approved",
    approvedby = "ApprovedBy",
    checkedoutdate = "CheckedOutDate",
    checkedoutuser = "CheckedOutUser",
    version = "Version",
    state = "State",
    domain = "Domain",
    template = "Template",
    comment = "Comment",
    title = "Title"
}

export interface IExternalObject {
    Object: ISemTalkObject | null;
    ObjectID: SemTalkID;
    ObjectName: string;
    ObjectCaption: string;
    ObjectClass: string | null;
    ObjectType: string | null;
    ObjectSystemClassCaption: string;
    ObjectDescription: string | null;
    NodesCount: string;
    EditLink: string | null;
    ReferenceType: string;
    SPItem: any | null;
    MGItem: any | null;
}

