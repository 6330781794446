import {
  IObjectBase, ISemTalkTabSpecs, ISemTalkTabSpec, ISemTalkTabSpecListTabSpec,
  ISemTalkGenericTabSpec, ISemTalkGenericAttributeTabSpec, ISemTalkSystemClass,
  SemTalkType
} from './Interface';
import { ObjectBase } from './ObjectBase';



export class SemTalkTabSpecs implements ISemTalkTabSpecs {
  constructor(own: ISemTalkSystemClass) {
    this.Owner = own;
  }
  public Owner: ISemTalkSystemClass;
  public get ObjectBase(): IObjectBase { return this.Owner.ObjectBase; }

  private _tabspecs: { [id: string]: ISemTalkTabSpec } = {};
  public AddTabSpec(a: ISemTalkTabSpec): ISemTalkTabSpec {
    this._tabspecs[a.Text + "_" + a.IsClass] = a;
    return a;
  }
  public RemoveTabSpec(a: ISemTalkTabSpec): void {
    if (this._tabspecs[a.Text + "_" + a.IsClass]) {
      delete this._tabspecs[a.Text + "_" + a.IsClass];
    } else {
      delete this._tabspecs[a.Text + "_true"];
    }
  }
  public Delete(): void {
    for (const l of this.TabSpecs()) {
      l.Delete();
    }
  }
  public Load(je: any): void {
    if (je.tabspecs != null) {
      for (const i in je.tabspecs) {
        const a2: any = je.tabspecs[i];
        switch (a2.ObjectType) {
          case "TabSpec": {
            let an = new SemTalkTabSpec(this, a2.name, false);
            if (an !== null) {
              an.Load(a2);
            }
          }
            break;
          case "GenericTabSpec": {
            let an = new SemTalkGenericTabSpec(this, a2.name, a2.relname, a2.toobj, (a2.isinst === "1"), (a2.isinv === "1"), (a2.isuni === "1"), false);
            if (an !== null) {
              an.Load(a2);
            }
          }
            break;
          case "GenericAttributeTabSpec": {
            let an = new SemTalkGenericAttributeTabSpec(this, a2.name, a2.group, a2.attributes, false);
            if (an !== null) {
              an.Load(a2);
            }
          } break;
        }
      }
    }
  }
  public LoadXML(element: HTMLElement): void {
    const tabspecs = element.getElementsByTagName("TabSpec");
    for (const i in tabspecs) {
      const tab = tabspecs[i];
      if (tab.attributes) {

        const name = tab.attributes.getNamedItem("name")?.value;
        const ObjectType = tab.attributes.getNamedItem("ObjectType")?.value;
        if (name) {
          switch (ObjectType) {
            case "TabSpec": {
              let an = new SemTalkTabSpec(this, name, false);
              if (an !== null) {
                an.LoadXML(tab);
              }
              break;
            }
            case "GenericTabSpec": {
              const relname = tab.attributes.getNamedItem("relname")?.value;
              const toobj = tab.attributes.getNamedItem("toobj")?.value;
              if (relname && toobj) {
                let an = new SemTalkGenericTabSpec(this, name, relname, toobj,
                  true, false, false, false);
                if (an !== null) {
                  an.LoadXML(tab);
                }
              }
              break;
            }
            case "GenericAttributeTabSpec": {
              let an = new SemTalkGenericAttributeTabSpec(this, name, "", [], false);
              if (an !== null) {
                an.LoadXML(tab);
              }
              break;
            }
          }
        }
      }
    }
  }
  public Save(el: any): any {
    if (this._tabspecs != null) {
      const ml: any[] = [];
      const tl = this.TabSpecs();
      // if (tl.length>0)
      //   console.debug(this._tabspecs);
      for (const x of tl) {
        x.Save(ml);
      }
      el.tabspecs = ml;
    }
    return el;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    if (this._tabspecs != null) {
      const tl = this.TabSpecs();
      for (const x of tl) {
        let sy = xd.createElement("TabSpec");
        el.appendChild(sy);
        x.SaveXML(xd, sy);
      }

    }
  }
  public TabSpecs(): ISemTalkTabSpec[] {
    const alist: ISemTalkTabSpec[] = [];
    for (const index in this._tabspecs) {
      const x = this._tabspecs[index];
      alist.push(x);
    }
    return alist;
  }
  public TabInstanceSpecs(): ISemTalkTabSpec[] {
    const alist: ISemTalkTabSpec[] = [];
    for (const index in this._tabspecs) {
      const x = this._tabspecs[index];
      if (!x.IsClass && x.ObjectType !== SemTalkType.SemTalkGenericTabSpec && x.ObjectType !== SemTalkType.SemTalkGenericAttributeTabSpec) {
        alist[alist.length] = x;
      }
    }
    return alist;
  }

  public FindTabSpec(aname: string): ISemTalkTabSpec | null {
    for (const index in this._tabspecs) {
      const a = this._tabspecs[index];
      if (a.Text === aname) { return a; }
    }
    return null;
  }
  public TabClassSpecs(): ISemTalkTabSpec[] {
    const alist: ISemTalkTabSpec[] = [];
    for (const index in this._tabspecs) {
      const x = this._tabspecs[index];
      if (x.IsClass && x.ObjectType !== SemTalkType.SemTalkGenericTabSpec && x.ObjectType !== SemTalkType.SemTalkGenericAttributeTabSpec) {
        alist[alist.length] = x;
      }
    }
    return alist;
  }
  public GenericTabSpecs(): ISemTalkGenericTabSpec[] {
    const alist: ISemTalkGenericTabSpec[] = [];
    for (const index in this._tabspecs) {
      const x = this._tabspecs[index];
      if (!x.IsClass && x.ObjectType === SemTalkType.SemTalkGenericTabSpec) {
        alist[alist.length] = x as SemTalkGenericTabSpec;
      }
    }
    return alist;
  }
  public GenericTabClassSpecs(): ISemTalkGenericTabSpec[] {
    const alist: ISemTalkGenericTabSpec[] = [];
    for (const index in this._tabspecs) {
      const x = this._tabspecs[index];
      if (x.IsClass && x.ObjectType === SemTalkType.SemTalkGenericTabSpec) {
        alist[alist.length] = x as SemTalkGenericTabSpec;
      }
    }
    return alist;
  }
  public GenericAttributeTabSpecs(): ISemTalkGenericAttributeTabSpec[] {
    const alist: SemTalkGenericAttributeTabSpec[] = [];
    for (const index in this._tabspecs) {
      const x = this._tabspecs[index];
      if (!x.IsClass && x.ObjectType === SemTalkType.SemTalkGenericAttributeTabSpec) {
        alist[alist.length] = x as SemTalkGenericAttributeTabSpec;
      }
    }
    return alist;
  }
  public GenericAttributeTabClassSpecs(): ISemTalkGenericAttributeTabSpec[] {
    const alist: SemTalkGenericAttributeTabSpec[] = [];
    for (const index in this._tabspecs) {
      const x = this._tabspecs[index];
      if (x.IsClass && x.ObjectType === SemTalkType.SemTalkGenericAttributeTabSpec) {
        alist[alist.length] = x as SemTalkGenericAttributeTabSpec;
      }
    }
    return alist;
  }
  public MakeTabSpec(text: string, isclass: boolean): ISemTalkTabSpec {
    let a = this.FindTabSpec(text);
    if (a === null) {
      // tslint:disable-next-line: no-use-before-declare
      a = new SemTalkTabSpec(this, text, isclass);
    }
    if (a.IsClass !== isclass) {
      throw new Error(("MakeTabSpec: There is already a TabSpec of different type named: " + text));
    }
    return a;
  }
  public MakeGenericTabSpec(text: string, relname: string, toobj: string, isinst: boolean, isinv: boolean, isuni: boolean, isclass: boolean): SemTalkGenericTabSpec {
    let a = this.FindTabSpec(text);
    if (a === null) {
      // tslint:disable-next-line: no-use-before-declare
      a = new SemTalkGenericTabSpec(this, text, relname, toobj, isinst, isinv, isuni, isclass);
    } else {
      a.IsClass = isclass;
      if (a.ObjectType === SemTalkType.SemTalkGenericTabSpec) {
        (a as SemTalkGenericTabSpec).IsInst = isinst;
        (a as SemTalkGenericTabSpec).IsInv = isinv;
        (a as SemTalkGenericTabSpec).IsUni = isuni;
        (a as SemTalkGenericTabSpec).Toobj = toobj;
      }
    }
    // if (a.IsClass !== isclass || a.ObjectType !== SemTalkType.SemTalkGenericTabSpec) {
    //   throw new Error(("MakeGenericTabSpec: There is already a TabSpec of different type named: " + text));
    // }
    return a as SemTalkGenericTabSpec;
  }
  public MakeGenericAttributeTabSpec(text: string, group: string, attributes: string[], isclass: boolean): ISemTalkGenericAttributeTabSpec {
    let a = this.FindTabSpec(text);
    if (a == null) {
      // tslint:disable-next-line: no-use-before-declare
      a = new SemTalkGenericAttributeTabSpec(this, text, group, attributes, isclass);
    } else {
    }
    // if (a.IsClass !== isclass || a.ObjectType !== SemTalkType.SemTalkGenericAttributeTabSpec) {
    //     (a as SemTalkGenericTabSpec).IsInst = isinst;
    //     (a as SemTalkGenericTabSpec).IsInv = isinv;
    //     (a as SemTalkGenericTabSpec).IsUni = isuni;
    //     (a as SemTalkGenericTabSpec).Toobj = toobj;
    //   // throw new Error(("MakeGenericAttributeTabSpec: There is already a TabSpec of different type named: " + text));
    // } else {
    let ats: ISemTalkGenericAttributeTabSpec = a as ISemTalkGenericAttributeTabSpec;
    ats.Group = group;
    ats.Attributes = attributes;
    // }
    return a as ISemTalkGenericAttributeTabSpec;
  }
  public MakeTabSpecListTabSpec(text: string, group: string, tabspecs: string[], isclass: boolean): ISemTalkTabSpecListTabSpec {
    let a = this.FindTabSpec(text);
    if (a == null) {
      // tslint:disable-next-line: no-use-before-declare
      a = new SemTalkTabSpecListTabSpec(this, text, group, tabspecs, isclass);
    } else {
    }
    if (a.IsClass !== isclass || a.ObjectType !== SemTalkType.SemTalkTabSpecListTabSpec) {
      throw new Error(("MakeGenericAttributeTabSpec: There is already a TabSpec of different type named: " + text));
    } else {
      let ats: ISemTalkTabSpecListTabSpec = a as ISemTalkTabSpecListTabSpec;
      ats.Group = group;
      ats.TabSpecs = tabspecs;
    }
    return a as ISemTalkTabSpecListTabSpec;
  }

}
export class SemTalkTabSpec implements ISemTalkTabSpec {
  constructor(owner: ISemTalkTabSpecs, text: string, isclass: boolean) {
    this.Owner = owner;
    this.IsClass = isclass;
    this.Visible = true;
    this.Text = text;
    owner.AddTabSpec(this);
  }
  public Owner: ISemTalkTabSpecs;
  public IsClass: boolean;
  public Text: string;
  public ObjectType = SemTalkType.SemTalkTabSpec;
  public Visible: boolean;

  public Delete(): void {
    this.Owner.RemoveTabSpec(this);
    // delete this.Owner._tabspecs[this.Text + "&" + this.IsClass];
  }
  public toString(): string {
    return "<" + ObjectBase.SemTalkTypeName(this.ObjectType) + " " + this.Text + " isclass: " + this.IsClass + ">";
  }
  public Load(je: any): void {
    this.Text = je.name;
    this.IsClass = false;
    // this.IsClass = (je.class === "True");
    this.Visible = (je.visible !== "False");
  }
  public Save(par: any[]): any {
    const el: any = {};
    el.ObjectType = ObjectBase.SemTalkTypeName(this.ObjectType);
    par.push(el);
    el.name = this.Text;
    // if (this.IsClass) { el.class = "True"; }
    if (!this.Visible) { el.visible = "False"; }
    return el;
  }
  public SaveXML(_xd: XMLDocument, el: HTMLElement): void {
    el.setAttribute("name", this.Text);
    // if (this.IsClass) { el.setAttribute("class", "True"); }
    if (!this.Visible) { el.setAttribute("visible", "False"); }
  }
  public LoadXML(element: Element): void {
    if (element.attributes) {
      const name = element.attributes.getNamedItem("name")?.value;
      if (name) this.Text = name;
      this.IsClass = false;
      // const isclass = (element.attributes.getNamedItem("class")?.value === "True");
      // if (isclass) this.IsClass = isclass;
      const visible = (element.attributes.getNamedItem("visible")?.value === "True");
      if (!visible) this.Visible = visible;
    }
  }
}
export class SemTalkGenericTabSpec extends SemTalkTabSpec implements ISemTalkGenericTabSpec {
  constructor(owner: ISemTalkTabSpecs, text: string, relname: string, toobj: string, isinst: boolean, isinv: boolean, isuni: boolean, isclass: boolean) {
    super(owner, text, isclass);
    this.ObjectType = SemTalkType.SemTalkGenericTabSpec;
    this.Relname = relname;
    this.Toobj = toobj;
    this.IsInst = isinst;
    this.IsInv = isinv;
    this.IsUni = isuni;
  }
  public Relname: string;
  public Toobj: string;
  public IsInst: boolean;
  public IsInv: boolean;
  public IsUni: boolean;
  // public Group: string;

  public Load(je: any): void {
    super.Load(je);
    this.Relname = je.relname;
    this.IsInst = (je.isinst === "1");
    this.IsInv = (je.isinv === "1");
    this.IsUni = (je.isuni === "1");
    this.Toobj = je.Toobj;
    // this.Group = je.group;
  }
  public LoadXML(element: Element): void {
    super.LoadXML(element);
    if (element.attributes) {
      const relname = element.attributes.getNamedItem("relname")?.value;
      if (relname) this.Relname = relname;
      const isinst = (element.attributes.getNamedItem("isinst")?.value === "1");
      if (isinst) this.IsInst = isinst;
      const isinv = (element.attributes.getNamedItem("isinv")?.value === "1");
      if (isinv) this.IsInv = isinv;
      const isuni = (element.attributes.getNamedItem("isuni")?.value === "1");
      if (isuni) this.IsUni = isuni;
      const Toobj = element.attributes.getNamedItem("Toobj")?.value;
      if (Toobj) this.Toobj = Toobj;
      // const Group = element.attributes.getNamedItem("Group")?.value;
      // if (Group) this.Group = Group;
    }
  }

  public Save(par: any[]): any {
    const el: any = super.Save(par);
    el.ObjectType = ObjectBase.SemTalkTypeName(this.ObjectType);
    // par[par.length] = el;
    el.relname = this.Relname;
    if (this.IsInst) { el.isinst = "1"; }
    if (this.IsInv) { el.isinv = "1"; }
    if (this.IsUni) { el.isuni = "1"; }
    if (this.Toobj) { el.Toobj = this.Toobj; }
    // if (this.Group) { el.Group = this.Group; }
    return el;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    super.SaveXML(xd, el);
    el.setAttribute("relname", this.Relname);
    if (this.IsInst) { el.setAttribute("isinst", "1"); }
    if (this.IsInv) { el.setAttribute("isinv", "1"); }
    if (this.IsUni) { el.setAttribute("isuni", "1"); }
    if (this.Toobj) { el.setAttribute("Toobj", this.Toobj); }
    // if (this.Group) { el.setAttribute("Group", this.Group); }
  }
}
export class SemTalkGenericAttributeTabSpec extends SemTalkTabSpec implements ISemTalkGenericAttributeTabSpec {
  constructor(owner: ISemTalkTabSpecs, text: string, group: string, attributes: string[], isclass: boolean) {
    super(owner, text, isclass);
    this.ObjectType = SemTalkType.SemTalkGenericAttributeTabSpec;
    this.Group = group;
    this.Attributes = attributes;
  }
  public Group: string;
  public Attributes: string[];
  public Load(je: any): void {
    super.Load(je);
    this.Group = je.group;
    this.Attributes = je.attributes;
  }
  public LoadXML(element: Element): void {
    super.LoadXML(element);
    if (element.attributes) {
      const group = element.attributes.getNamedItem("group")?.value;
      if (group) this.Group = group;
      const attributes = element.attributes.getNamedItem("attributes")?.value;
      if (attributes) this.Attributes = attributes.split(",");
      const Group = element.attributes.getNamedItem("Group")?.value;
      if (Group) this.Group = Group;
    }
  }


  public Save(par: any[]): any {
    const el: any = super.Save(par);
    el.ObjectType = ObjectBase.SemTalkTypeName(this.ObjectType);
    // par[par.length] = el;
    el.group = this.Group;
    el.attributes = this.Attributes;
    return el;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    super.SaveXML(xd, el);
    el.setAttribute("group", this.Group);
    if (this.Attributes) {
      let a: string = "";
      if (Array.isArray(this.Attributes)) {
        a = this.Attributes.join(', ');
      } else {
        a = this.Attributes;
      }
      el.setAttribute("attributes", a);
    }
  }
}

export class SemTalkTabSpecListTabSpec extends SemTalkTabSpec implements ISemTalkTabSpecListTabSpec {
  constructor(owner: ISemTalkTabSpecs, text: string, group: string, tabspecs: string[], isclass: boolean) {
    super(owner, text, isclass);
    this.ObjectType = SemTalkType.SemTalkTabSpecListTabSpec;
    this.Group = group;
    this.TabSpecs = tabspecs;
  }
  public Group: string;
  public TabSpecs: string[];
  public Load(je: any): void {
    super.Load(je);
    this.Group = je.group;
    this.TabSpecs = je.tabspecs;
  }
  public LoadXML(element: Element): void {
    super.LoadXML(element);
    if (element.attributes) {
      const group = element.attributes.getNamedItem("group")?.value;
      if (group) this.Group = group;
      const tabspecs = element.attributes.getNamedItem("tabspecs")?.value;
      if (tabspecs) this.TabSpecs = tabspecs.split(",");
      const Group = element.attributes.getNamedItem("Group")?.value;
      if (Group) this.Group = Group;
    }
  }


  public Save(par: any[]): any {
    const el: any = super.Save(par);
    el.ObjectType = ObjectBase.SemTalkTypeName(this.ObjectType);
    // par[par.length] = el;
    el.group = this.Group;
    el.tabspecs = this.TabSpecs;
    return el;
  }
  public SaveXML(xd: XMLDocument, el: HTMLElement): void {
    super.SaveXML(xd, el);
    el.setAttribute("group", this.Group);
    if (this.TabSpecs) {
      let a: string = "";
      if (Array.isArray(this.TabSpecs)) {
        a = this.TabSpecs.join(', ');
      } else {
        a = this.TabSpecs;
      }
      el.setAttribute("tabspecs", a);
    }
  }
}

