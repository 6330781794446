import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import Signup from './components/controls/stlogin/Signup';
// import CloneDatabase from './components/controls/stlogin/Database';
import Logout from './components/controls/stlogout/Logout';
// import Options from './components/controls/stlogin/Options';
import Backend from './components/controls/stlogin/Backend';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { IMongoOption } from './components/SemTalkMongoServer';
// import UpdatePassword from './components/controls/stlogin/UpdatePassword';
//import ForgotPassword from './components/controls/stlogin/ForgotPassword';
// import ForgotPasswordVerify from './components/controls/stlogin/ForgotPasswordVerify';
import { SemTalkRole } from './components/ISemTalkOnline';
import { SemTalkCookie } from './components/ISemTalkCookie';
import {
  accessCookie, setCookie, setSubsitePrefix,
  // setCookie 
} from './components/application/semtalklistener/stgoto';

// Prefix der vor alle SemTalkCookies gesetzt wird um mehre Apps in derselben Site unterscheiden zu können
// => mehrere Webparts in der selben Site oder in mehreren Channels desselben Teams
setSubsitePrefix("");
// let showlogin: boolean = true;
let usemongo: boolean = true;
let usemongos = accessCookie(SemTalkCookie.usemongo);
if (usemongos !== null) {
  usemongo = (usemongos !== "false");
}

let semmongoserverurl = accessCookie(SemTalkCookie.semmongoserverurl);
let semmongoserverurlBackup = accessCookie(SemTalkCookie.semmongoserverurlBackup);
if (!semmongoserverurl) {
  semmongoserverurl = "/api/";
}
// foo bar
// semmongoserverurl = "https://semmongo.azurewebsites.net/api/";
semmongoserverurlBackup = "https://semmongo4.azurewebsites.net/api/";

// semmongoserverurl = "http://localhost:7071/api/";
// semmongoserverurl = "/api/";
// semmongoserverurl = "http://localhost:3001/";

setCookie(SemTalkCookie.semmongoserverurl, semmongoserverurl);

const semmongologintokencookiename = "mongo_token";

let semconnecttoken = accessCookie(SemTalkCookie.semconnecttoken);
if (!semconnecttoken) semconnecttoken = "";

// "mongodb://localhost:27017" test
// semconnecttoken = "aef864f1cb11a4d5b9cd6421b56c113c7f7c9f17efeee15e1c03d3c67d55d6a832eb757e6e7a53cb9b1d54c74e73d291102e64b62818fb4cfd3feb6c6e76b12c54ef";
//  "mongodb://ssss:xxxx%21@semtalkmongodb.westeurope.azurecontainer.io:27017"
// semconnecttoken = "aef864f1cb11a4d5b9cd6421b56c113c7f7c9f17efeefe541216deda7b49cca853b9281b383414dada4e11e65f77cc8053782da8221be842f536aa75676cab2c03e05d3fc9e4b1798a869e7519643451e9687436cf07c154167985d81fb38a";

// "mongodb+srv://ssss:xxxx!@cluster0.pumvg.mongodb.net/semtalkonline"
// semconnecttoken = "aef864f1cb11a4d5b9cd6421b56c113c7f7c9f06b3b3fb0b504dcccb7f52c3e669b32b750a3d12bd9e104b950d52c298476732a03f45a15de439f2652c72b02711fd562d82a4b577d0879e7b026b2c5bef687d2d8f0b8c13"
// semconnecttoken = "";

// mongodb+srv://semtation:<password>@pck.dtukqxk.mongodb.net
// semconnecttoken = "aef864f1cb11a4d5b9cd6421b56c113c7f7c9f06b3b3fb0b504dcccb7f52c3e669b32b750a3d12bd9e104b950d52c298476732a03f45a15de439f2652c72b02711fd562d82a4b5e77d0879e7b026b2c5bef687d2d8f0b8c13"

// semconnecttoken = "aef864f1cb11a4d5b9cd6421b56c113c7f7c9f06b3b3fb0b504dcccb7f52c3e669b32b752a3d129d9e104be65c71cada566033ae3c0de403fc3bea656d7bbd6718f7466dd1"

// SemTalk Services Token mit SServices Admin Portal gemacht:
// mongodb+srv://semtation:<password>@pck.dtukqxk.mongodb.net/semtalkonline_semtalk_com"
// KHYEsllxU1c8bUy6KbbNAPFlXXufzBkRPEfBN7w0O+/OSyES4eZykj8pSeWbXGdhG6T/5i+2Zf88viLnVh3r3Tbp25iyzoIoeT+xGjePZYxO4G939bRNR7NuhIhQ6OUN

// setCookie(SemTalkCookie.semmongoserverurl, semmongoserverurl);
setCookie(SemTalkCookie.semconnecttoken, semconnecttoken);

let db = accessCookie(SemTalkCookie.dbname);
if (!db) db = "semtalkonline";
let re = accessCookie(SemTalkCookie.repository);
if (!re) re = "repository";


function setToken(userToken: any) {
  sessionStorage.setItem(semmongologintokencookiename, JSON.stringify(userToken));
}

function getToken() {
  const tokenString = sessionStorage.getItem(semmongologintokencookiename);
  if (tokenString) {
    const userToken = JSON.parse(tokenString);
    return userToken;
  }
}
let mongo: IMongoOption = {
  usemongo: usemongo,
  semmongoserverurl: semmongoserverurl,
  semmongoserverurlBackup: semmongoserverurlBackup,
  documents: "SDX",
  backup: "SDX_backup",
  templates: "Templates",
  stencils: "Stencils",
  // approved: "Approved",
  approved: "SDX",
  semuserlogin: null,
  semmongoconnectiontoken: semconnecttoken,
  dbname: db,
  repository: re,
  iselectron: false,
  defaultdatabase: "semtalkonline",
  defaultrepository: "repository",
  objects: "Objects",
  roles: "Roles",
  users: "Users"
};
let ro = accessCookie(SemTalkCookie.role);
if (!ro) ro = SemTalkRole.viewer;

let url = document.baseURI;
if (url.indexOf("?") > 0) {
  let args = url.substr(url.indexOf("?") + 1);
  for (let param of args.split("&")) {
    let p = param.split("=")[0];
    let v = decodeURI(param.split("=")[1]);
    switch (p) {
      case "iselectron": {
        mongo.iselectron = (v !== "false");
        // showlogin=false;
        break;
      }
      case "usemongo": {
        mongo.usemongo = (v !== "false");
        break;
      }
      case "share": {

        if (v.length > 0) {

          let b = Buffer.from(v, 'base64').toString('utf8');
          let shareParams = b.split(",");
          for (let s of shareParams) {
            let shareParam = s.split(":");
            if (shareParam[0].length > 0) {
              if (shareParam[0] === "db") mongo.dbname = shareParam[1];
              if (shareParam[0] === "server") mongo.semmongoconnectiontoken = shareParam[1];
              if (shareParam[0] === "role") ro = shareParam[1];
            }
          }
          // window.history.replaceState(null, "", "/");
        }
      }
    }
  }
}

if (mongo.iselectron && mongo.semmongoserverurl === "/api/") {
  mongo.semmongoserverurl = "https://semmongo4.azurewebsites.net/api/";
  setCookie(SemTalkCookie.semmongoserverurl, semmongoserverurl);
}


if (mongo.iselectron) {
  ro = SemTalkRole.editor;
  ReactDOM.render(
    <App setToken={setToken}
      getToken={getToken}
      mongo={mongo}
      semmongologintokencookiename={semmongologintokencookiename}
      role={ro} />,
    document.getElementById('root'));
} else {
  ReactDOM.render(
    <BrowserRouter>
      <Switch>
        <Route path="/" exact >
          <App setToken={setToken}
            getToken={getToken}
            mongo={mongo}
            semmongologintokencookiename={semmongologintokencookiename}
            role={ro} />
        </Route>
        <Route path="/logout" exact> <Logout semmongologintokencookiename={semmongologintokencookiename} /></Route>
        <Route path="/signup" exact> <Signup mongo={mongo} setToken={setToken} /></Route>
        {/* <Route path="/updatepassword" exact> <UpdatePassword mongo={mongo} setToken={setToken} /></Route> */}
        {/* <Route path="/forgotpassword" exact> <ForgotPassword mongo={mongo} setToken={setToken} /></Route> */}
        {/* <Route path="/forgotpasswordverify"> <ForgotPasswordVerify mongo={mongo} setToken={setToken} /></Route> */}
        {/* <Route path="/clonedb" exact> <CloneDatabase mongo={mongo}  header={true}/></Route> */}
        {/* <Route path="/options" exact> <Options mongo={mongo} /></Route> */}
        <Route path="/backend" exact> <Backend mongo={mongo} /></Route>
      </Switch>
    </BrowserRouter>,
    document.getElementById('root'));
}

document.title = `SemTalk Online`;
